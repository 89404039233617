import { IEntity } from 'store/Search/reducers'

export enum KnownEntities {
  PERSON = 'PERSON',
  LOCATION = 'LOCATION',
  ORGANIZATION = 'ORGANIZATION'
}

export interface CustomEntity {
  key: KnownEntities
  score: number
  widgetkey: string
  widgetTypes: string[]
}

const subCategoryMapping = [
  {
    key: KnownEntities.PERSON,
    categories: ['Person', 'PersonType', 'Skill'],
    widgetKey: 'people',
    widgetTypes: ['left', 'right']
  },
  {
    key: KnownEntities.LOCATION,
    categories: ['Location'],
    widgetKey: 'news',
    widgetTypes: ['left', 'right']
  },
  {
    key: KnownEntities.ORGANIZATION,
    categories: ['Organization'],
    widgetKey: 'news',
    widgetTypes: ['left', 'right']
  }
]

export const getWidgetsForEntities = (entities: IEntity[] | null) => {
  const mappedEntities = buildEntities(entities)

  const result = { left: '', right: '' }

  mappedEntities.forEach((mappedEntity: CustomEntity) => {
    if (!result.left && mappedEntity.widgetTypes.indexOf('left') !== -1) {
      result.left = mappedEntity.widgetkey
    } else if (
      !result.right &&
      mappedEntity.widgetTypes.indexOf('right') !== -1 &&
      result.left !== mappedEntity.widgetkey
    ) {
      result.right = mappedEntity.widgetkey
    }
  })

  return result
}

export const buildEntities = (entities: IEntity[] | null): CustomEntity[] => {
  let mappedEntities: CustomEntity[] = []

  if (entities && Array.isArray(entities)) {
    entities.forEach((entity: IEntity) => {
      const category = subCategoryMapping.find((subCategory) => {
        return subCategory.categories.indexOf(entity.category) !== -1
      })

      if (category) {
        const mappedEntity = mappedEntities.find(
          (entity: CustomEntity) => entity.key === category.key
        )
        if (mappedEntity) {
          mappedEntity.score = mappedEntity.score + entity.confidenceScore
        } else {
          mappedEntities.push({
            key: category.key,
            score: entity.confidenceScore,
            widgetkey: category.widgetKey,
            widgetTypes: category.widgetTypes
          })
        }
      }
    })
  }

  mappedEntities = mappedEntities.sort((a: CustomEntity, b: CustomEntity) => {
    return b.score - a.score
  })

  return mappedEntities
}
