import { Action, AnyAction } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { Store } from '..'
import * as Config from 'config'
import AuthStore from '../Auth'
import {
  renewAuthorizationToken,
  getGraphAuthToken,
  getSPOnlineToken
} from 'utils/token'
import { trackException, trackUserSettings } from 'utils/tracking'
import {
  getUserSettingsFromLocalStorage,
  IUserSetting,
  IUserSettingStore,
  saveUserSettingsToLocalStorage
} from 'utils/userSettings'
import {
  fetchFindConfigurationCriticalError,
  fetchFindConfigurationFailure,
  fetchFindConfigurationNotFound,
  fetchFindConfigurationSuccess
} from 'store/Settings/actions'
import { parseUserJWTToken } from 'utils/people'

export enum UserSettingsActionTypes {
  FETCH_USERSETTINGS_REQUEST = 'userSettings/FETCH_USERSETTINGS_REQUEST',
  FETCH_USERSETTINGS_SUCCESS = 'userSettings/FETCH_USERSETTINGS_SUCCESS',
  FETCH_USERSETTINGS_NOTFOUND = 'userSettings/FETCH_USERSETTINGS_NOTFOUND',
  FETCH_USERSETTINGS_FAILURE = 'userSettings/FETCH_USERSETTINGS_FAILURE',
  UPSERT_USERSETTINGS_SUCCESS = 'userSettings/UPSERT_USERSETTINGS_SUCCESS',
  RESET_USER_SETTINGS = 'userSettings/RESET_USER_SETTINGS',
  FETCH_USERSETTINGS_FROM_LOCALSTORAGE_SUCCESS = 'FETCH_USERSETTINGS_FROM_LOCALSTORAGE'
}

export type IFetchUserSettingsRequest = Action<UserSettingsActionTypes>

export interface IFetchUserSettingsFailure
  extends Action<UserSettingsActionTypes> {
  payload: {
    failureUpn: string
    failureDisplayName: string
    failureUserSettingsFromLocalStorage: any
  }
}
export interface IFetchUserSettingsSuccess
  extends Action<UserSettingsActionTypes> {
  payload: {
    response: any
    userUpn: string
    userDisplayName: string
  }
}
export interface IFetchUserSettingsNotFound
  extends Action<UserSettingsActionTypes> {
  payload: {
    upn: string
    displayName: string
    userSettingsFromLocalStorage: any
  }
}

export interface IUpsertUserSettingsSuccess
  extends Action<UserSettingsActionTypes> {
  payload: {
    upSertUserSettingsResponse: any
    upSertInitialLoaded: boolean
  }
}

export type IResetUserSettings = Action<UserSettingsActionTypes>

export const fetchUserSettingsRequest = (): IFetchUserSettingsRequest => ({
  type: UserSettingsActionTypes.FETCH_USERSETTINGS_REQUEST
})

export const fetchUserSettingsSuccess = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  response: any,
  userUpn: string,
  userDisplayName: string
): IFetchUserSettingsSuccess => ({
  type: UserSettingsActionTypes.FETCH_USERSETTINGS_SUCCESS,
  payload: { response, userUpn, userDisplayName }
})

export interface IFetchUserSettingsFromLocalStorageSuccess
  extends Action<UserSettingsActionTypes> {
  payload: {
    localStorageResponse: any
    localStorageUpn: string
    localStorageDisplayName: string
  }
}

export const fetchUserSettingsFromLocalStorageSuccess = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  localStorageResponse: any,
  localStorageUpn: string,
  localStorageDisplayName: string
): IFetchUserSettingsFromLocalStorageSuccess => ({
  type: UserSettingsActionTypes.FETCH_USERSETTINGS_FROM_LOCALSTORAGE_SUCCESS,
  payload: {
    localStorageResponse,
    localStorageUpn,
    localStorageDisplayName
  }
})

export const fetchUserSettingsNotFound = (
  upn: string,
  displayName: string,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  userSettingsFromLocalStorage: any
): IFetchUserSettingsNotFound => ({
  type: UserSettingsActionTypes.FETCH_USERSETTINGS_NOTFOUND,
  payload: { upn, displayName, userSettingsFromLocalStorage }
})

export const fetchUserSettingsFailure = (
  failureUpn: string,
  failureDisplayName: string,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  failureUserSettingsFromLocalStorage: any
): IFetchUserSettingsFailure => ({
  type: UserSettingsActionTypes.FETCH_USERSETTINGS_FAILURE,
  payload: {
    failureUpn,
    failureDisplayName,
    failureUserSettingsFromLocalStorage
  }
})

export const UpSertSetUserSettingsSuccess = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  upSertUserSettingsResponse: any,
  upSertInitialLoaded: boolean
): IUpsertUserSettingsSuccess => ({
  type: UserSettingsActionTypes.UPSERT_USERSETTINGS_SUCCESS,
  payload: {
    upSertUserSettingsResponse: upSertUserSettingsResponse,
    upSertInitialLoaded: upSertInitialLoaded
  }
})

export const fetchUserSettings = (
  userUpn: string,
  userDisplayName: string
  // eslint-disable-next-line @typescript-eslint/ban-types
): ThunkAction<Promise<void>, Store, {}, AnyAction> => {
  return async (
    // eslint-disable-next-line @typescript-eslint/ban-types
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => Store
  ) => {
    if (Config.LOCAL_DEVELOPMENT.toString() === 'false') {
      // don't make this silent token calls in case the page runs in the qa enviroment
      if (
        Config.ACTIVE_DIRECTORY_CLIENT_ID.toString() !==
        'c89837b1-8ac3-4a64-a7c3-556cd7c591f9'
      ) {
        // Get and check authentication token
        const aadInfo = AuthStore.selectors.getAADInfo(getState())

        await getGraphAuthToken(aadInfo.instance, aadInfo.accounts)
        await getSPOnlineToken(aadInfo.instance, aadInfo.accounts)
      }
    }
    dispatch(fetchUserSettingsRequest())

    // Get the userSettings from local storage
    // this is only done for testing proposes
    // so the usersettings can be overwriten from local storage
    const userSettingsFromLocalStorage = await getUserSettingsFromLocalStorage()

    try {
      const configApiUrl = `${Config.APIM_BASE_URL}usersettingsapi/getusersettings?scope=FindConfiguration`

      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        return
      }

      const configData = await fetch(configApiUrl, {
        method: 'GET',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${esToken}`
        }
      })

      if (configData.status !== 200) {
        trackException(
          'Error in fetching find configuration',
          new Error('Error in data object after fetching find configuration')
        )
        if (configData.status === 502) {
          dispatch(fetchFindConfigurationCriticalError())
          return
        } else {
          dispatch(fetchFindConfigurationFailure())
        }
      }

      const configResult = await configData.json()
      const configCount = configResult._count

      if (configCount !== 1) {
        dispatch(fetchFindConfigurationNotFound())
      }

      const findConfigurationResult = {
        ...configResult.Documents[0]
      }
      dispatch(fetchFindConfigurationSuccess(findConfigurationResult))

      // Check if the UseLocalSettings is set to true
      // if so directly return the userSettings from localstorage
      if (
        userSettingsFromLocalStorage &&
        userSettingsFromLocalStorage.UseLocalSettings
      ) {
        dispatch(
          fetchUserSettingsFromLocalStorageSuccess(
            userSettingsFromLocalStorage,
            userUpn,
            userDisplayName
          )
        )
        return
      }

      const apiUrl = `${Config.APIM_BASE_URL}usersettingsapi/getusersettings?upn=${userUpn}&scope=KPMGFind`

      const data = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${esToken}`
        }
      })

      if (data.status !== 200) {
        dispatch(
          fetchUserSettingsFailure(
            userUpn,
            userDisplayName,
            userSettingsFromLocalStorage
          )
        )
        trackException(
          'Error in fetching user settings',
          new Error('Error in data object after fetching user settings')
        )
        return
      }

      const result = await data.json()

      const count = result._count

      if (count !== 1) {
        dispatch(
          fetchUserSettingsNotFound(
            userUpn,
            userDisplayName,
            userSettingsFromLocalStorage
          )
        )
        return
      }

      const userSettingResult = result.Documents[0]

      // Get profile picture
      let graphToken = ''
      if (Config.LOCAL_DEVELOPMENT.toString() === 'false') {
        graphToken = await getGraphAuthToken(aadInfo.instance, aadInfo.accounts)
      }

      if (userUpn && Config.LOCAL_DEVELOPMENT.toString() === 'false') {
        const graphAPIUrl = `${Config.APIM_BASE_URL}msgraphapi/getprofilepicture?upn=${userUpn}`

        const pictureResponse = await fetch(graphAPIUrl, {
          method: 'GET',
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
            Authorization: `Bearer ${graphToken}`,
            JWTToken: esToken
          }
        })

        if (
          pictureResponse &&
          pictureResponse.ok &&
          (!userSettingsFromLocalStorage ||
            !userSettingsFromLocalStorage.ForceNoUpdate)
        ) {
          const pictureBlob = await pictureResponse.blob()
          userSettingResult.PictureUrl = URL.createObjectURL(pictureBlob)
        }
      }

      // Parse JWT Token into User Settings
      if (
        !userSettingsFromLocalStorage ||
        !userSettingsFromLocalStorage.ForceNoUpdate
      ) {
        parseUserJWTToken(userSettingResult, esToken, findConfigurationResult)
      }

      dispatch(
        fetchUserSettingsSuccess(userSettingResult, userUpn, userDisplayName)
      )
    } catch (error) {
      dispatch(
        fetchUserSettingsFailure(
          userUpn,
          userDisplayName,
          userSettingsFromLocalStorage
        )
      )
      trackException('Error in fetching user settings action', error)
    }
  }
}

export const upSertUserSettings = (
  userSettings: IUserSetting,
  changed = true
  // eslint-disable-next-line @typescript-eslint/ban-types
): ThunkAction<Promise<void>, Store, {}, AnyAction> => {
  return async (
    // eslint-disable-next-line @typescript-eslint/ban-types
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => Store
  ) => {
    try {
      trackUserSettings(userSettings, changed)

      // In case user settings are not fetched initially or the local settings are used,
      // don't save them back to cosmos db
      // (in case local settings are enabled, store changes in local storage)
      if (!userSettings.initialLoaded || userSettings.UseLocalSettings) {
        if (!userSettings.initialLoaded) {
          trackException(
            'Error in upsert user settings',
            new Error('UserSettings not loaded skip saving to database')
          )
        }

        // in case the usersettings are from the localStorage
        // save back the changes
        if (userSettings.UseLocalSettings) {
          saveUserSettingsToLocalStorage(userSettings)
        }

        dispatch(
          UpSertSetUserSettingsSuccess(userSettings, userSettings.initialLoaded)
        )
        return
      }

      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        return
      }

      const apiUrl = `${Config.APIM_BASE_URL}usersettingsapi/upsertusersettings?upn=${userSettings.upn}&scope=KPMGFind`

      const userSettingsForStore: IUserSettingStore = {
        id: userSettings.id,
        Version: userSettings.Version,
        EnablePrefiltering: userSettings.EnablePrefiltering,
        EnableWidgetPrefiltering: userSettings.EnableWidgetPrefiltering,
        PlayTour: userSettings.PlayTour,
        StorageVersion: userSettings.StorageVersion,
        Country: userSettings.Country,
        FirstLogin: userSettings.FirstLogin,
        City: userSettings.City,
        Function: userSettings.Function,
        Department: userSettings.Department,
        PinFilters: userSettings.PinFilters,
        Language: userSettings.Language,
        LanguageManuallySeleted: userSettings.LanguageManuallySeleted,
        DataSourceOrder: userSettings.DataSourceOrder,
        DataSourceDisabled: userSettings.DataSourceDisabled,
        IntroductionShown: userSettings.IntroductionShown,
        FeedbackShortShowed: userSettings.FeedbackShortShowed,
        FeedbackLongShowed: userSettings.FeedbackLongShowed,
        PremiumEnabled: userSettings.PremiumEnabled,
        TrackingAccepted: userSettings.TrackingAccepted,
        LastVisits: userSettings.LastVisits,
        FeedbackShortShownTime: userSettings.FeedbackShortShownTime,
        FeedbackLongDelayed: userSettings.FeedbackLongDelayed,
        FeedbackLongDelayedOn: userSettings.FeedbackLongDelayedOn,
        FeedbackShortDelayed: userSettings.FeedbackShortDelayed,
        FeedbackShortDelayedOn: userSettings.FeedbackShortDelayedOn,
        PopupList: userSettings.PopupList,
        CognitiveSearchEnabled: userSettings.CognitiveSearchEnabled,
        EntityRecognitionEnabled: userSettings.EntityRecognitionEnabled,
        ShowCognitiveMessage: userSettings.ShowCognitiveMessage,
        DisabledWidgets: userSettings.DisabledWidgets,
        OpenLinksInNewTab: userSettings.OpenLinksInNewTab
      }

      const data = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${esToken}`
        },
        body: JSON.stringify(userSettingsForStore)
      })

      if (data.status !== 200 && data.status !== 201) {
        trackException(
          'Error in upsert user settings',
          new Error('Error in data object after upsert user settings')
        )
        return
      }

      const result = await data.json()

      // in case of successfull save in the cosmos db
      // also update the userSettings in the local storage
      saveUserSettingsToLocalStorage(userSettings)

      dispatch(UpSertSetUserSettingsSuccess(result, true))
    } catch (error) {
      trackException('Error in upsert user settings action', error)
    }
  }
}

export const resetUserSettings = (): IResetUserSettings => ({
  type: UserSettingsActionTypes.RESET_USER_SETTINGS
})

export type UserSettingsActions =
  | IFetchUserSettingsRequest
  | IFetchUserSettingsFailure
  | IFetchUserSettingsSuccess
  | IFetchUserSettingsNotFound
  | IResetUserSettings
  | IUpsertUserSettingsSuccess
  | IFetchUserSettingsFromLocalStorageSuccess
