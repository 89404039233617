import { Action } from 'redux'
import { v4 as uuidv4 } from 'uuid'

export interface ActionMetaData {
  transactionId: string
  transactionType: string
  isStartAction?: boolean
}

export interface CustomAction<T = any> extends Action {
  type: T
  metaData: ActionMetaData
}

export const getActionMetaData = (
  datasource: string,
  extendedTypeIdentifier?: string
): ActionMetaData => {
  const customUUID = uuidv4()
  const actionMetaData: ActionMetaData = {
    transactionId: customUUID,
    transactionType: `${datasource.toUpperCase()}${
      extendedTypeIdentifier ? `_${extendedTypeIdentifier.toUpperCase()}` : ''
    }`
  }

  return actionMetaData
}
