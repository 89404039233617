import { Action, AnyAction } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { Store } from '..'
import * as Config from 'config'
import AuthStore from '../Auth'
import { renewAuthorizationToken } from 'utils/token'
import { trackEvents, trackException } from 'utils/tracking'
import { FetchWithCache } from 'utils/api'
import { ContactUsTile } from 'components/models/ContactUsTile'

export enum ContactUsActionTypes {
  FETCH_CONTACT_US_TILES_SUCCESS = 'contactUs/FETCH_CONTACT_US_TILES_SUCCESS',
  FETCH_CONTACT_US_TILES_FAILURE = 'contactUs/FETCH_CONTACT_US_TILES_FAILURE'
}

export type IFetchContactUsTilesFailure = Action<ContactUsActionTypes>
export const fetchContactUsTilesFailure = (): IFetchContactUsTilesFailure => ({
  type: ContactUsActionTypes.FETCH_CONTACT_US_TILES_FAILURE
})

export interface IFetchContactUsTilesSuccess
  extends Action<ContactUsActionTypes> {
  payload: {
    contactUsTileResponse: any
  }
}
export const fetchContactUsTilesSuccess = (
  contactUsTileResponse: ContactUsTile[]
): IFetchContactUsTilesSuccess => ({
  type: ContactUsActionTypes.FETCH_CONTACT_US_TILES_SUCCESS,
  payload: { contactUsTileResponse }
})

export const fetchContactUsTiles = (
  language: string
): ThunkAction<Promise<void>, Store, unknown, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => Store
  ) => {
    try {
      trackEvents('getContactUsTiles', {})

      const apiUrl = `${Config.APIM_BASE_URL}contactusapi/getcontactustiles?language=${language}&scope=KPMGFind`

      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        return
      }

      const result: ContactUsTile[] = []

      const data = await FetchWithCache(apiUrl, {
        method: 'GET',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${esToken}`
        }
      })

      if (!data || (data && data.hasError)) {
        dispatch(fetchContactUsTilesFailure())
        trackException(
          'Error in fetching contact us options',
          new Error('Error in data object after fetching contact us options')
        )
        return Promise.resolve()
      }

      const results = data.responseJSON

      if (!results || !results.Documents) {
        dispatch(fetchContactUsTilesFailure())
        trackException(
          'Error in getting documents out of contact us options',
          new Error('Error in data object after fetching contact us options')
        )
        return Promise.resolve()
      }

      results.Documents.forEach((co: ContactUsTile) => {
        if (co.scope === 'KPMGFind' || co.scope === 'All') result.push(co)
      })

      result.sort((a: ContactUsTile, b: ContactUsTile) => {
        return a.order > b.order ? 1 : -1
      })
      dispatch(fetchContactUsTilesSuccess(result))
    } catch (error) {
      dispatch(fetchContactUsTilesFailure())
      trackException('Error in fetching contact us options', error)
    }
  }
}

export type ContactUsActions =
  | IFetchContactUsTilesSuccess
  | IFetchContactUsTilesFailure
