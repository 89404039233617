import React from 'react'
import CompanySnapshotWidget from 'components/contents/resultpages/widgets/CompanySnapshotWidget'
import { FetchWithCacheAndTracking } from 'utils/api'
import { latinize } from '../latinize'
import * as Config from 'config'
import { IAADState } from 'store/Auth/reducers'
import { renewAuthorizationToken } from 'utils/token'

export interface ITRSearchItem {
  PI: string
  CommonName: string
  OrganizationWebsite: string
  ReutersIndustryClassification: string
  ICBSchemeName: string
  OAOrgTypeName: string
  OrganisationTypeName: string
  MktCapCompanyUsd: string
  OrganizationStatus: string
  RegistrationAuthorityName: string
  Ticker: string
  PrimaryRIC: string
  ParentOrganisationName: string
}

/**
 * Generate Company Snapshot widget based on search query
 * @param searchQuery The search query
 */
export async function generateCompanySnapshotWidget(
  searchQuery: string,
  aadInfo: IAADState
): Promise<React.ReactElement | null> {
  try {
    if (!searchQuery || searchQuery === '' || searchQuery === '""') {
      return null
    }

    // Get and check authentication token
    const esToken = await renewAuthorizationToken(
      aadInfo.accessToken,
      aadInfo.instance,
      aadInfo.accounts
    )

    // Fetch Company Information from TR
    const results: ITRSearchItem[] = await getTRCompanyInfo(
      searchQuery,
      esToken
    )

    if (!results || results.length === 0) {
      return null
    }

    const filteredResults = results.filter(
      (res: ITRSearchItem) =>
        res.PI &&
        res.PI !== '' &&
        res.CommonName &&
        res.CommonName !== '' &&
        res.Ticker &&
        res.Ticker !== '' &&
        res.PrimaryRIC &&
        res.PrimaryRIC !== ''
    )

    if (filteredResults && filteredResults.length > 0) {
      return (
        <CompanySnapshotWidget
          searchQuery={searchQuery}
          result={filteredResults[0]}
        />
      )
    } else {
      return null
    }
  } catch (rejectedValue) {
    return null
  }
}

async function getTRCompanyInfo(
  searchQuery: string,
  authToken: string
): Promise<ITRSearchItem[]> {
  searchQuery = searchQuery.replaceAll('"', '').slice()
  const apiUrl = `${
    Config.APIM_BASE_URL
  }marketresearchapi/postsearch?query=${encodeURIComponent(
    latinize(searchQuery)
  )}`

  const response = await FetchWithCacheAndTracking(apiUrl, {
    method: 'POST',
    headers: {
      accept: 'application/json',
      'content-type': 'application/json',
      'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
      Authorization: `Bearer ${authToken}`
    }
  })

  let results
  if (response && !response.hasError && response.responseJSON) {
    const responseJSON = response.responseJSON
    results = responseJSON && responseJSON.Items ? responseJSON.Items : []
  }
  return results
}
