import React, { Dispatch, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { Typography, Grid, Box, Link } from '@mui/material'
import { trackEvents } from 'utils/tracking'
import { Store } from 'store'
import UserSettingsStore from 'store/UserSettings'
import SettingsStore from 'store/Settings'
import { kpmgCountrySupportUrls } from 'constants/kpmgCountrySupportUrls'
import { getCurrentScope, removeQueryParam } from '../../utils/queryParams'
import { useLocation, useNavigate } from 'react-router-dom'
import { getStylesFooter } from 'styles/footer/Footer'
import { useReactRouterQueryStringInterface } from 'utils/useQueryState'
import queryString from 'query-string'
import { useFilter } from 'utils/filters'

interface FooterProps {
  setShowFaq: Dispatch<boolean>
  setImproveResultsModal: Dispatch<boolean>
  setShowContactUs: Dispatch<boolean>
  userDisplayName: string
  setTourOpen: Dispatch<any>
}

type AllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  FooterProps

function Footer(props: AllProps) {
  const navigateFunction = useNavigate()
  const classes = getStylesFooter()
  const {
    setShowFaq,
    setImproveResultsModal,
    userSettings,
    userDisplayName,
    setShowSettings,
    setShowContactUs,
    deviceSettings,
    setTourOpen
  } = props
  const [country, setCountry] = useState('')
  const [city, setCity] = useState('')
  const [helpUrl, setHelpUrl] = useState('')
  const [currentLocation, setCurrentLocation] = useState('/')
  const location = useLocation()
  const { getQueryString } = useReactRouterQueryStringInterface()
  const [, setCurrentPage] = useFilter('page')

  useEffect(() => {
    setCurrentLocation(location.pathname ? location.pathname : '/')
  }, [location])

  useEffect(() => {
    const chooseHelpLink = (country: string) => {
      const kpmgCountrySupportItem = kpmgCountrySupportUrls.find(
        (item) => item.country.toLowerCase() === country.toLowerCase()
      )
      if (kpmgCountrySupportItem && kpmgCountrySupportItem.url)
        setHelpUrl(kpmgCountrySupportItem.url)
    }

    if (userSettings) {
      if (userSettings.Country) {
        setCountry(userSettings.Country)
        chooseHelpLink(userSettings.Country)
      }
      if (userSettings.City) {
        setCity(userSettings.City)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSettings.Country, userSettings.City])

  const forceIntranetDatasource = () => {
    const currentScope = getCurrentScope(false)

    if (currentScope !== 'intranet') {
      const qs = queryString.parse(getQueryString()) || {}
      qs.page = '1'
      setCurrentPage('1')

      navigateFunction(`/results/all/?${queryString.stringify(qs)}`)
    }
  }

  return (
    <footer
      className={currentLocation === '/' ? 'footer footer_sticky' : 'footer'}
    >
      <Box className={classes.box}>
        <Grid
          container
          justifyContent={
            deviceSettings.renderMobile ? 'center' : 'space-between'
          }
          style={deviceSettings.renderMobile ? { flexDirection: 'column' } : {}}
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <Typography
              id={'txt-user-info-01'}
              color="textSecondary"
              variant="body1"
              className={classes.text}
            >
              {userDisplayName} | {country} | {city}
            </Typography>
          </Grid>
          <Grid item data-tut="reactour__footer_links">
            <Typography
              variant="body1"
              color="textSecondary"
              className={classes.text}
            >
              <Link
                id={'txt-setting-01'}
                className={classes.link}
                component="button"
                color="inherit"
                onClick={() => {
                  setShowSettings(true)
                  trackEvents('footerLink', { page: 'Settings' })
                  removeQueryParam(navigateFunction, 'cntx')
                }}
              >
                <FormattedMessage
                  id="settings_button"
                  defaultMessage="Settings"
                />
              </Link>{' '}
              |{' '}
              <Link
                id={'txt-contact-01'}
                className={classes.link}
                component="button"
                color="inherit"
                onClick={() => {
                  setShowContactUs(true)
                  trackEvents('footerLink', { page: 'Contact' })
                  removeQueryParam(navigateFunction, 'cntx')
                }}
              >
                <FormattedMessage
                  id="contact_button"
                  defaultMessage="Contact"
                />
              </Link>{' '}
              |{' '}
              <Link
                id={'txt-help-01'}
                className={classes.link}
                component="button"
                color="inherit"
                {...(helpUrl ? { href: helpUrl } : {})}
                onClick={() => {
                  if (!helpUrl) setShowFaq(true)

                  trackEvents('footerLink', { page: 'Help' })
                  removeQueryParam(navigateFunction, 'cntx')
                }}
              >
                <FormattedMessage id="help_button" defaultMessage="Help" />
              </Link>{' '}
              {!deviceSettings.renderMobile &&
                !deviceSettings.isMobile &&
                currentLocation !== '/' && (
                  <>
                    |{' '}
                    <Link
                      id={'txt-tour-01'}
                      className={classes.link}
                      component="button"
                      color="inherit"
                      onClick={() => {
                        removeQueryParam(navigateFunction, 'cntx')

                        //goto intranet
                        forceIntranetDatasource()

                        //scroll to top
                        window.scrollTo(0, 0)

                        //open tour
                        setTourOpen(true)

                        trackEvents('footerLink', { page: 'Tour' })
                      }}
                    >
                      <FormattedMessage
                        id="tour_button"
                        defaultMessage="Tour"
                      />
                    </Link>{' '}
                  </>
                )}
              |{' '}
              <Link
                id={'txt-privacy-01'}
                {...(deviceSettings.openLinksInNewTab === true
                  ? { target: '_blank', rel: 'noreferrer' }
                  : {})}
                className={classes.link}
                color="inherit"
                href="https://home.kpmg/xx/en/home/misc/privacy.html"
                onClick={() => {
                  trackEvents('footerLink', { page: 'Privacy' })
                  removeQueryParam(navigateFunction, 'cntx')
                }}
              >
                <FormattedMessage
                  id="data_privacy_button"
                  defaultMessage="Data Privacy"
                />
              </Link>{' '}
              {!deviceSettings.isMobile && (
                <>
                  |{' '}
                  <Link
                    id={'txt-improve-results-01'}
                    className={classes.link}
                    component="button"
                    color="inherit"
                    onClick={() => {
                      setImproveResultsModal(true)
                      trackEvents('footerLink', { page: 'Improve Results' })
                      removeQueryParam(navigateFunction, 'cntx')
                    }}
                  >
                    <FormattedMessage
                      id="improve_results"
                      defaultMessage="Improve Results"
                    />
                  </Link>{' '}
                </>
              )}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </footer>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    userSettings: UserSettingsStore.selectors.getUserSettings(state),
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    setShowSettings: (ss: boolean) =>
      dispatch(SettingsStore.actions.setShowSettings(ss))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer)
