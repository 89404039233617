import React, { useMemo } from 'react'
import ReactPlaceholder from 'react-placeholder'
import { Box, Fade } from '@mui/material'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import 'Placeholder.css'
import { Store } from 'store'
import ResultsAlexStore from 'store/ResultsAlex'
import { AlexResult } from 'components/contents/results/AlexResult'
import Pagination from '../Pagination'
import { trackException } from 'utils/tracking'
import SearchSuggestions from 'components/contents/common/SearchSuggestions'
import { IDeviceSetting } from 'utils/deviceSettings'
import FeaturedResults from './FeaturedResults'
import LeftWidgetContainer from './widgetscontainer/LeftWidgetContainer'
import { getStylesResults } from 'styles/contents/resultpages/Results'
import { useFilter } from 'utils/filters'
import createDOMPurify from 'dompurify'

interface ResultsAlexMobileProps {
  searchQuery: string
  deviceSettings: IDeviceSetting
  sanitizeAlexResult: any
}

type AllMobileProps = ReturnType<typeof mapStateToProps> &
  ResultsAlexMobileProps

function ResultsAlexMobile(props: AllMobileProps) {
  const {
    hasResultsBeenFetched,
    resultsCount,
    featuredResults,
    resultsCombined,
    searchQuery,
    deviceSettings,
    sanitizeAlexResult
  } = props
  const DOMPurify = createDOMPurify(window)
  const resultClasses = getStylesResults()
  const intl = useIntl()
  const [currentPage] = useFilter('page', '1')

  const returnAllItems = (type: string) => {
    if (!resultsCombined || resultsCombined.length < 1) {
      return []
    }

    const items = []
    try {
      const firstResults =
        resultsCombined.length < 4 ? resultsCombined.length : 3

      if (type === 'first') {
        for (let i = 0; i < firstResults; i++) {
          items.push(
            <AlexResult
              result={sanitizeAlexResult(resultsCombined[i])}
              key={i}
              index={i}
              searchQuery={DOMPurify.sanitize(searchQuery)}
              deviceSettings={deviceSettings}
            />
          )
        }
      } else {
        // Todo: remove workaround for manuals after paging is working
        for (
          let i = firstResults;
          i < resultsCombined.length && i < (Number(currentPage) || 1) * 25;
          i++
        ) {
          items.push(
            <AlexResult
              result={sanitizeAlexResult(resultsCombined[i])}
              key={i}
              index={i}
              searchQuery={DOMPurify.sanitize(searchQuery)}
              deviceSettings={deviceSettings}
            />
          )
        }
      }
    } catch (error) {
      trackException(
        'Error returning all items in ResultsAlexMobile.tsx',
        error
      )
    }
    return items
  }

  const firstItems = useMemo(
    () => returnAllItems('first'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resultsCombined, intl]
  )
  const restItems = useMemo(
    () => returnAllItems('rest'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resultsCombined, intl]
  )

  return (
    <Box className={resultClasses.container}>
      <Box className={resultClasses.resultsContainer}>
        <SearchSuggestions
          wildCardActive={false}
          resultCount={resultsCount}
          resultsFetchend={hasResultsBeenFetched}
        />

        <ReactPlaceholder
          ready={
            hasResultsBeenFetched || Number(currentPage) > 1 ? true : false
          }
          type="text"
          rows={4}
          showLoadingAnimation={true}
          className={resultClasses.placeholder}
        >
          <Fade
            in={hasResultsBeenFetched || Number(currentPage) > 1 ? true : false}
            timeout={600}
          >
            <Box>
              {resultsCombined && resultsCount > 0 && (
                <FeaturedResults featuredResults={featuredResults} />
              )}
              {firstItems}
            </Box>
          </Fade>
        </ReactPlaceholder>
        {Number(currentPage) === 1 && (
          <LeftWidgetContainer
            resultCount={resultsCount}
            hasResultsBeenFetched={hasResultsBeenFetched}
          />
        )}
        <ReactPlaceholder
          ready={hasResultsBeenFetched}
          type="text"
          rows={4}
          showLoadingAnimation={true}
          className={resultClasses.placeholder}
        >
          <Fade in={hasResultsBeenFetched} timeout={600}>
            <Box>
              {restItems}
              {resultsCount != null &&
                resultsCount > 0 &&
                hasResultsBeenFetched && (
                  <Pagination
                    {...props}
                    totalRecords={resultsCount}
                    pageLimit={25}
                    pageNeighbours={4}
                  />
                )}
            </Box>
          </Fade>
        </ReactPlaceholder>
        <ReactPlaceholder
          ready={hasResultsBeenFetched ? hasResultsBeenFetched : false}
          type="text"
          rows={4}
          showLoadingAnimation={true}
          className={resultClasses.placeholder}
        >
          <Box />
        </ReactPlaceholder>
      </Box>
    </Box>
  )
}

const mapStateToProps = (state: Store, props: ResultsAlexMobileProps) => {
  return {
    featuredResults: ResultsAlexStore.selectors.getFeaturedResults(state),
    resultsCombined: ResultsAlexStore.selectors.getResultsCombined(state),
    resultsCount: ResultsAlexStore.selectors.getResultCount(state),
    executionTime: ResultsAlexStore.selectors.getExecutionTime(state),
    hasResultsBeenFetched:
      ResultsAlexStore.selectors.hasResultsBeenFetched(state)
  }
}

export default connect(mapStateToProps)(ResultsAlexMobile)
