import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
export interface IScrollToTopProps {
  searchQuery: string
}

// https://reactrouter.com/web/guides/scroll-restoration
export default function ScrollToTop(props: IScrollToTopProps): JSX.Element {
  const { searchQuery } = props
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname, searchQuery])

  return <></>
}
