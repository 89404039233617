import React, { Dispatch, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import queryString from 'query-string'
import {
  Box,
  Typography,
  Tooltip,
  Autocomplete,
  TextField,
  Popper
} from '@mui/material'
import { withStyles } from '@mui/styles'
import Paper from '@mui/material/Paper'
import SearchIcon from '@mui/icons-material/Search'
import LensBlurIcon from '@mui/icons-material/LensBlur'
import AccessTime from '@mui/icons-material/AccessTime'
import ArrowUpward from '@mui/icons-material/ArrowUpward'
import Clear from '@mui/icons-material/Clear'
import ArrowBack from '@mui/icons-material/ArrowBack'
import highlightText from 'utils/highlightText'
import { useDebounce } from 'utils/debounce'
import { Store } from 'store'
import SearchStore from 'store/Search'
import UserSettingsStore from 'store/UserSettings'
import SettingsStore from 'store/Settings'
import { useReactRouterQueryStringInterface } from 'utils/useQueryState'
import { removeQueryParam, getCurrentScope } from 'utils/queryParams'
import { FormattedMessage, useIntl } from 'react-intl'
import { searchDebounceTime } from 'constants/constants'
import { getStylesSearchField } from 'styles/contents/SearchField'
import { IUserSetting, usePrevious } from 'utils/userSettings'
import { useFilter } from 'utils/filters'
import { cognitiveVerticalPaths } from 'constants/cognitiveVerticals'
import { KPMGFindGlobalVariables } from 'store/KPMGFindGlobalVariables'
import {
  getLanguageLCID,
  updateHistory,
  updateHistoryForAlex,
  updateHistoryForKnowledgeExchange,
  updateHistoryForOffice365
} from 'utils/searchField'

const StyledArrowUpward = withStyles((theme) => ({
  root: {
    transform: 'rotate(315deg)',
    fill: '#757575'
  },
  checked: {}
}))(ArrowUpward)

export interface SearchFieldProps {
  navBar?: any
  match?: any
  dimensions?: any
  onSearchValueChange?: any
  searchHistory?: any
  searchButtons?: any
  resetSearchValue?: boolean
  setSearchModalOpen?: (value: boolean) => void
}

interface SearchFieldSuggestion {
  suggestion: string
  type: string
}

type AllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  SearchFieldProps

function SearchField(props: AllProps) {
  const {
    navBar,
    fetchAutocomplete,
    autoSuggestData,
    storeSearchHistory,
    onSearchValueChange,
    removeHistory,
    searchHistory,
    searchButtons,
    userSettings,
    fetchSearchHistory,
    deviceSettings,
    currentDataSources,
    setUserSettings,
    resetSearchValue,
    findConfiguration,
    setSearchModalOpen,
    useCognitiveSearch,
    setUseCognitiveSearch
  } = props

  const classes = getStylesSearchField()
  const navigateFunction = useNavigate()
  const intl = useIntl()

  const pathComponents = window.location.hash.split('?')

  const [parsedSearchTerm, setSearchTermFilter] = useFilter('q')
  const [, setCurrentPage] = useFilter('page')
  const searchFieldContainerRef = useRef(null)

  const [open, setOpen] = React.useState(false)
  const isAutoCompleteValueHighlighted = useRef(false)

  const [searchfieldDimensions, setDimension] = useState('top')

  const [searchTerm, setSearchTerm] = useState('') //executed searchterm
  const debouncedSearchTerm = useDebounce(searchTerm, searchDebounceTime)
  const [value, setValue] = useState(debouncedSearchTerm || '') //current input value

  const [highlightedValue, setHighlightedValue] = useState('') //highlight text

  const [searchInputFocus, setSearchInputFocus] = useState(false) //is input focused
  const [searchModal, setSearchModal] = useState(false) //mobile fullscreen
  const [suggestions, setSuggestions] = useState<SearchFieldSuggestion[]>([]) //suggestions list

  const previousPinFilters = usePrevious(userSettings.PinFilters)
  const [cognitiveSearchVisible, setCognitiveSearchVisible] = useState(true)
  const [showAnimationEffect, setShowAnimationEffect] = useState(true)

  if (
    showAnimationEffect === true &&
    previousPinFilters !== userSettings.PinFilters
  )
    setShowAnimationEffect(false)

  const { getQueryString } = useReactRouterQueryStringInterface()

  useEffect(() => {
    const queryParams = queryString.parse(getQueryString()) || {}
    // Bug 1806101: Removed setting of the searchTerm in the global variables, otherwise the tracking of
    // feature results, adwords, widgets and click events can contain a wrongly temp changed value of the
    // searchterm
    queryParams.q = null
    KPMGFindGlobalVariables.setEnabledFilters(queryParams)
  }, [getQueryString])

  useEffect(() => {
    let timeout: any
    if (searchModal) {
      document.body.setAttribute(
        'style',
        'position: fixed; overscroll-behavior: contain;'
      )
    } else {
      // hack to fix magnification on iphones
      document.body.setAttribute('style', 'display: unset')
      timeout = setTimeout(() => {
        document.body.setAttribute('style', '')
      }, 300)
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchModal])

  useEffect(() => setDimension(props.dimensions), [props.dimensions])

  useEffect(() => {
    if (
      !resetSearchValue &&
      parsedSearchTerm &&
      parsedSearchTerm.length > 0 &&
      parsedSearchTerm !== debouncedSearchTerm &&
      parsedSearchTerm !== value &&
      ((value === '' && !debouncedSearchTerm) || value === debouncedSearchTerm)
    ) {
      setSearchTerm(parsedSearchTerm)
      setValue(parsedSearchTerm)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parsedSearchTerm])

  function onPopCustom() {
    const parsedSearchTermPopState = queryString.parse(
      window.location.hash.split('?').pop() || ''
    ).q
    if (parsedSearchTermPopState) {
      setSearchTerm(parsedSearchTermPopState as string)
      setValue(parsedSearchTermPopState)
    }
  }

  window.onpopstate = (e: any) => {
    onPopCustom()
  }

  useEffect(() => {
    fetchSearchHistory(userSettings.upn)

    return () => {
      // This code runs when component is unmounted
      window.removeEventListener('onpopstate', onPopCustom, false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const performSearch = (value: any, isAutoSuggest = false) => {
    if (!value) {
      return
    }

    setOpen(false)
    setSearchModal(false)
    setSearchInputFocus(false)
    if (setSearchModalOpen) setSearchModalOpen(false)
    setSearchTerm(value)
    setValue(value)
    setHighlightedValue(value)
    // this will be done every time a search is performed
    // it will also add the searchquery to the top 10 search results
    // in the cosmos db
    storeSearchHistory(value, userSettings)
    removeQueryParam(navigateFunction, 'cntx')

    // set the autosuggest value
    // this will be used in the content fetch to
    // set the property for tracking the search
    KPMGFindGlobalVariables.setIsAutoSuggestQuery(isAutoSuggest)

    // update the query string for the query and reset the page to 1
    const qs = queryString.parse(getQueryString()) || {}
    qs.page = '1'
    qs.q = value
    setCurrentPage('1')
    setSearchTermFilter(value)

    let currentScope = getCurrentScope(false)

    if (currentScope === 'intranet') {
      currentScope = 'all'
    }
    if (currentScope.indexOf('office365') >= 0) {
      // For office 365 an push in the history for mails and calender must
      // be explicitly set. Scope is office365 and it is not possible
      // to make this difference in the scope itself.
      updateHistoryForOffice365(
        navigateFunction,
        queryString.stringify(qs),
        pathComponents
      )
    } else if (currentScope.indexOf('knowledgeexchange') >= 0) {
      updateHistoryForKnowledgeExchange(
        navigateFunction,
        queryString.stringify(qs),
        pathComponents
      )
    } else if (currentScope.indexOf('alex') >= 0) {
      updateHistoryForAlex(
        navigateFunction,
        queryString.stringify(qs),
        pathComponents
      )
    } else {
      updateHistory(
        navigateFunction,
        queryString.stringify(qs),
        currentDataSources,
        currentScope
      )
    }
  }

  /**
   * generate suggestions list
   */
  const filterDownSuggestions = () => {
    let newSuggestions: any[] = suggestions

    const searchHistoryFilteredBySearchTerm = searchHistory
      .filter(
        (pastSearch: string) =>
          pastSearch.indexOf && pastSearch.indexOf(value) !== -1
      )
      .map((pastSearch: string) => ({
        suggestion: pastSearch,
        type: 'history'
      }))
      .slice(0, 5)
    newSuggestions =
      value && value.length > 0
        ? [
            ...searchHistoryFilteredBySearchTerm,
            ...autoSuggestData
              .filter((suggestion: SearchFieldSuggestion) => {
                if (
                  searchHistoryFilteredBySearchTerm.find(
                    (lookupSuggestion: SearchFieldSuggestion) =>
                      lookupSuggestion.suggestion === suggestion.suggestion
                  )
                ) {
                  /* If autocomplete from server is similar to previous search terms - do not show it */
                  return false
                }
                return true
              })
              .filter(
                (suggestion: SearchFieldSuggestion) =>
                  suggestion.suggestion &&
                  suggestion.suggestion
                    .toLowerCase()
                    .indexOf(value.toLowerCase()) !== -1
              )
          ]
        : suggestions

    if (!value || value.length === 0) {
      newSuggestions = searchHistoryFilteredBySearchTerm
    }

    if (newSuggestions.length === 0) setOpen(false)
    setSuggestions(newSuggestions)
  }

  const languageLCID = getLanguageLCID(userSettings)
  useEffect(
    () => {
      if (debouncedSearchTerm && debouncedSearchTerm !== '') {
        setValue(debouncedSearchTerm)
        fetchAutocomplete(
          debouncedSearchTerm,
          languageLCID,
          userSettings.Language
        )
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedSearchTerm, languageLCID]
  )

  useEffect(
    () => {
      if (
        searchInputFocus ||
        (debouncedSearchTerm && debouncedSearchTerm !== '')
      ) {
        filterDownSuggestions()
      }
      if (deviceSettings.renderMobile) {
        setTimeout(() => {
          window.scrollTo(0, 0)
        }, 300)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [autoSuggestData, searchHistory, searchInputFocus, value]
  )

  useEffect(
    () => {
      const currentLocation = window.location.hash.toLocaleLowerCase()
      let cognitiveVisible = false
      cognitiveVerticalPaths.map((path: string) => {
        if (currentLocation.indexOf(path) >= 0) {
          cognitiveVisible = true
        }
        return null
      })
      setCognitiveSearchVisible(cognitiveVisible)
      setShowAnimationEffect(true)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [window.location.hash]
  )

  useEffect(
    () => {
      setShowAnimationEffect(true)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [useCognitiveSearch]
  )

  //handle open/close suggestions via result count
  useEffect(() => {
    if (suggestions.length === 0 && open) setOpen(false)
    if (suggestions.length !== 0 && !open && searchInputFocus) setOpen(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suggestions])

  //enable modal on mobile
  useEffect(() => {
    if (open && deviceSettings.renderMobile) {
      setSearchModal(true)
      if (setSearchModalOpen) setSearchModalOpen(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  /**
   * OnKeyDown - input
   * @param event
   */
  const onKeyDown = (event: any) => {
    if (event) {
      switch (event.key) {
        case 'Enter': {
          if (
            event.target &&
            event.target.value &&
            event.target.value !== '' &&
            !isAutoCompleteValueHighlighted.current
          ) {
            onChange(event.target.value)
            performSearch(event.target.value, false)
            setOpen(false)
          }
          break
        }
      }
    }
  }

  /**
   * On searchterm change
   * @param valueInput
   */
  const onChange = (valueInput: any) => {
    setSearchTerm(valueInput)
    setValue(valueInput)
    setHighlightedValue(valueInput)
    if (onSearchValueChange) {
      onSearchValueChange(valueInput)
    }
  }

  /**
   * Render input component
   * @param inputProps
   * @returns
   */
  const renderInputComponent = (inputProps: any) => {
    inputProps.inputProps.className =
      inputProps.inputProps.className +
      ` ${
        (searchfieldDimensions !== 'start' || deviceSettings.renderMobile) &&
        !searchModal
          ? classes.searchInput
          : ''
      }`
    return (
      <div className={classes.fullScreenInputComponent}>
        {deviceSettings.renderMobile && searchModal && (
          <ArrowBack
            className={classes.fullScreenBackIcon}
            onClick={() => {
              setSearchModal(false)
              if (setSearchModalOpen) setSearchModalOpen(false)
              setOpen(false)
            }}
          />
        )}
        <TextField
          variant="standard"
          style={
            deviceSettings.renderMobile && searchModal
              ? {
                  paddingLeft: '10px',
                  flexDirection: 'initial'
                }
              : { flexDirection: 'initial' }
          }
          {...{
            ...inputProps,
            ...(searchfieldDimensions === 'start'
              ? { autofocus: true }
              : { autofocus: false })
          }}
          autoCapitalize={'false'}
          onFocus={() => {
            filterDownSuggestions()
            setSearchInputFocus(true)
            if (deviceSettings.renderMobile) {
              setSearchModal(true)
              if (setSearchModalOpen) setSearchModalOpen(true)
            }
          }}
          onBlur={() => {
            setSearchInputFocus(false)
          }}
          onKeyDown={onKeyDown}
        />
        {deviceSettings.renderMobile && searchModal && (
          <>
            <button
              {...{ 'aria-label': 'Clear Search' }}
              className={classes.fullScreenClearButton}
              onClick={() => {
                setValue('')
                if (onSearchValueChange) {
                  onSearchValueChange('')
                }
              }}
            >
              <span>×</span>
            </button>
            <SearchIcon
              className={`${classes.fullScreenSearchIcon} ${
                open && classes.searchIconHighlightedNoBorder
              }`}
              onClick={() => {
                performSearch(value)
              }}
            />
          </>
        )}
      </div>
    )
  }

  /**
   * Render suggestions list as overlay
   * @param props
   * @returns
   */
  const searchFieldPopper = function (props: any) {
    let width = 1
    if (searchFieldContainerRef && searchFieldContainerRef.current)
      width = (searchFieldContainerRef.current as any).clientWidth + 2 //2px border

    let yOffset = 0
    let classDimensions =
      searchfieldDimensions === 'start'
        ? classes.startContainer
        : classes.topContaine
    if (deviceSettings.renderMobile) {
      classDimensions =
        searchfieldDimensions === 'start'
          ? classes.startContainerMobile
          : classes.topContainerMobile
      yOffset = 1
    }

    return (
      <Popper
        id="popper"
        {...{
          ...props,
          className:
            props.className +
            ` ${classDimensions} ${classes.suggestionListContainer}`
        }}
        style={{ width: width }}
        placement="bottom-start"
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [
                searchfieldDimensions === 'start' ? -45 : -1 + yOffset, //start page
                searchfieldDimensions === 'normal' ? -6 : 0 + yOffset //stcky on top
              ]
            }
          }
        ]}
      />
    )
  }

  /**
   * Add search buttons into list
   * @param props
   * @param ref
   * @returns
   */
  const VirtualizedListComp = (props: any, ref: any) => (
    <ul ref={ref} {...props}>
      {props.children}
      {searchButtons}
    </ul>
  )
  const ListboxComponent =
    !deviceSettings.renderMobile && searchButtons
      ? React.forwardRef(VirtualizedListComp)
      : undefined

  /**
   * Render searchbar suggestion option
   * @param props
   * @param suggestion
   * @returns
   */
  const renderSuggestion = (props: any, suggestion: SearchFieldSuggestion) => {
    const Icon = suggestion.type === 'history' ? AccessTime : SearchIcon

    return (
      <Box
        {...{
          ...props,
          className: props.className + ` ${classes.suggestionContainer}`
        }}
      >
        <span className={classes.suggestionContentContainerWrapper}>
          <span className={classes.suggestionContentContainer}>
            <Icon
              className={
                deviceSettings.renderMobile
                  ? classes.suggestionIconMobile
                  : classes.suggestionIcon
              }
            />
            <span
              className={`${classes.suggestionText} ${
                suggestion.type === 'history' && classes.suggestionHistory
              }`}
            >
              {highlightText(suggestion.suggestion, highlightedValue, false)}
            </span>
          </span>
        </span>
        {suggestion.type === 'history' && (
          <span
            className={`${classes.suggestionRemove}`}
            onClick={(event) => {
              //prevent suggestion selection on click
              event.stopPropagation()

              //remove history item
              handleRemoveHistoryItem(suggestion.suggestion)
            }}
            style={deviceSettings.renderMobile ? { paddingRight: 20 } : {}}
          >
            {deviceSettings.renderMobile ? (
              <Clear />
            ) : (
              <Typography className={classes.removeLabel}>
                <FormattedMessage
                  id="autosuggest_remove_btn"
                  defaultMessage="Remove"
                />
              </Typography>
            )}
          </span>
        )}
        {suggestion.type !== 'history' && deviceSettings.renderMobile && (
          <span
            className={`${classes.suggestionRemove}`}
            onClick={(event) => {
              copyToSearchBar(suggestion.suggestion)
              setSearchTerm(suggestion.suggestion)
            }}
            style={deviceSettings.renderMobile ? { paddingRight: 20 } : {}}
          >
            <StyledArrowUpward />
          </span>
        )}
      </Box>
    )
  }
  /**
   * Remove history item
   * @param searchQuery
   */
  const handleRemoveHistoryItem = (searchQuery: string) => {
    removeHistory(searchQuery, userSettings.upn)
    filterDownSuggestions()
  }

  const copyToSearchBar = (searchQuery: string) => (e: any) => {
    e.stopPropagation()
    setValue(searchQuery)
  }

  return (
    <Paper
      className={`search-field ${
        searchfieldDimensions === 'normal'
          ? classes.scrollDimensions
          : classes.topDimensions
      } ${navBar ? classes.paperNavBar : classes.paper} ${
        searchfieldDimensions === 'start' ? classes.paperBasics : ''
      } ${open && classes.noBottomRadius} ${
        deviceSettings.renderMobile && searchModal
          ? classes.fullScreenSearch
          : ''
      }`}
      ref={searchFieldContainerRef}
      style={
        open ? { borderBottomRightRadius: 0, borderBottomLeftRadius: 0 } : {}
      }
    >
      {searchfieldDimensions === 'start' && window.innerWidth >= 1024 && (
        <SearchIcon className={classes.searchIcon} />
      )}
      <Autocomplete
        id={'inp-search-01'}
        freeSolo={true}
        open={open}
        noOptionsText={''}
        onOpen={() => {
          if (suggestions.length !== 0) {
            setOpen(true)
          }
        }}
        onClose={(event: React.SyntheticEvent, reason: string) => {
          isAutoCompleteValueHighlighted.current = false
          setOpen(false)
        }}
        classes={{
          paper: deviceSettings.renderMobile
            ? classes.paperSuggestionContainerMobile
            : classes.paperSuggestionContainer,
          option: classes.option
        }}
        sx={{
          width: '100%'
        }}
        isOptionEqualToValue={(
          option: SearchFieldSuggestion,
          value: SearchFieldSuggestion
        ) => {
          return option.suggestion === value.suggestion
        }}
        getOptionLabel={(option: string | SearchFieldSuggestion) =>
          typeof option === 'string' ? option : option ? option.suggestion : ''
        }
        options={suggestions}
        value={{ suggestion: value, type: 'current' }} //suggestion list value
        onChange={(
          event: any,
          value: string | SearchFieldSuggestion | null
        ) => {
          const newValue =
            typeof value === 'string' ? value : value ? value.suggestion : ''

          const isAutoSuggest =
            typeof value !== 'string' && value?.type === 'server'
          if (newValue) {
            performSearch(newValue, isAutoSuggest)
          }
          filterDownSuggestions()
        }}
        inputValue={value} //input value
        onInputChange={(event, newInputValue) => {
          onChange(newInputValue)
          filterDownSuggestions()
        }}
        renderInput={(params) => {
          return renderInputComponent(params)
        }}
        renderOption={(props: any, option: any) => {
          return renderSuggestion(props, option)
        }}
        PopperComponent={searchFieldPopper}
        ListboxComponent={ListboxComponent}
        onHighlightChange={(
          event: any,
          value: string | SearchFieldSuggestion | null
        ) => {
          const newValue =
            typeof value === 'string' ? value : value ? value.suggestion : ''

          if (newValue && !isAutoCompleteValueHighlighted.current) {
            isAutoCompleteValueHighlighted.current = true
          } else if (!newValue && isAutoCompleteValueHighlighted.current) {
            isAutoCompleteValueHighlighted.current = false
          }
        }}
      />

      {window.innerWidth < 825 &&
        searchfieldDimensions !== 'start' &&
        !searchModal && (
          <span
            {...{ 'aria-label': 'Clear Search' }}
            className={classes.clearIcon}
            role="button"
            onClick={() => {
              setValue('')
              if (onSearchValueChange) {
                onSearchValueChange('')
              }
            }}
          >
            ×
          </span>
        )}
      {(window.innerWidth < 1024 ||
        (searchfieldDimensions !== 'start' && window.innerWidth > 825)) &&
        !searchModal && (
          <>
            {cognitiveSearchVisible &&
              findConfiguration &&
              findConfiguration.CognitiveSearchEnabled && (
                <Tooltip
                  title={
                    useCognitiveSearch
                      ? intl.formatMessage({
                          id: 'cognitive_button_tooltip_disable',
                          defaultMessage: 'Disable AI improved querying'
                        })
                      : intl.formatMessage({
                          id: 'cognitive_button_tooltip_enable',
                          defaultMessage: 'Enable AI improved querying'
                        })
                  }
                  placement="top"
                >
                  <span
                    tabIndex={0}
                    onClick={() => {
                      const newEnabledState = !useCognitiveSearch
                      setUseCognitiveSearch(newEnabledState)
                      userSettings.ShowCognitiveMessage = true
                      setUserSettings(userSettings)
                      performSearch(value)
                    }}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        const newEnabledState = !useCognitiveSearch
                        setUseCognitiveSearch(newEnabledState)
                        userSettings.ShowCognitiveMessage = true
                        setUserSettings(userSettings)
                        performSearch(value)
                      }
                    }}
                  >
                    <LensBlurIcon
                      className={
                        previousPinFilters === userSettings.PinFilters &&
                        showAnimationEffect
                          ? [
                              useCognitiveSearch
                                ? classes.cognitiveIconEnabled
                                : classes.cognitiveIcon,
                              classes.cognitiveIconAnimation
                            ].join(' ')
                          : useCognitiveSearch
                            ? classes.cognitiveIconEnabled
                            : classes.cognitiveIcon
                      }
                    />
                  </span>
                </Tooltip>
              )}
            <SearchIcon
              tabIndex={0}
              className={`${classes.searchIconHighlighted} ${
                open && classes.searchIconHighlightedNoBorder
              } ${
                searchfieldDimensions === 'start' &&
                classes.searchIconHighlightedOnStartPage
              }`}
              onClick={() => {
                performSearch(value)
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  performSearch(value)
                }
              }}
            />
          </>
        )}
    </Paper>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    autoSuggestData: SearchStore.selectors.getAutocompleteResults(state),
    searchHistory: SearchStore.selectors.getHistory(state),
    userSettings: UserSettingsStore.selectors.getUserSettings(state),
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state),
    currentDataSources: SettingsStore.selectors.getCurrentDataSources(state),
    findConfiguration: SettingsStore.selectors.getFindConfiguration(state),
    useCognitiveSearch: SettingsStore.selectors.getUseCognitiveSearch(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    fetchAutocomplete: (
      searchQuery: string,
      lcidCode: string,
      lanCode: string
    ) =>
      dispatch(
        SearchStore.actions.fetchAutocomplete(searchQuery, lcidCode, lanCode)
      ),
    fetchSearchHistory: (upn: string) =>
      dispatch(SearchStore.actions.fetchHistory(upn)),
    storeSearchHistory: (searchQuery: string, userSettings: IUserSetting) =>
      dispatch(SearchStore.actions.storeHistoryItem(searchQuery, userSettings)),
    removeHistory: (searchQuery: string, upn: string) =>
      dispatch(SearchStore.actions.removeHistoryItem(searchQuery, upn)),
    setUserSettings: (userSettings: IUserSetting) =>
      dispatch(UserSettingsStore.actions.upSertUserSettings(userSettings)),
    setUseCognitiveSearch: (enable: boolean) =>
      dispatch(SettingsStore.actions.setUseCognitiveSearch(enable, enable))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchField)
