import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesFeedbackShort(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles() {
  return makeStyles((theme: Theme) => ({
    feedbackDialogTitle: {
      color: '#ffffff',
      textAlign: 'center',
      background: '#004e98',
      verticalAlign: 'center'
    },
    description: {
      color: '#ffffff'
    },
    thumbsContainer: {
      display: 'flex',
      flexDirection: 'row',
      height: 82,
      paddingTop: 30,
      alignItems: 'space-around',
      justifyContent: 'center'
    },
    feedbackActions: {
      background: '#f6f7fA',
      color: '#ffffff',
      padding: '18px 14px',
      justifyContent: 'space-between'
    },
    thumbButton: {
      height: 55,
      width: 55,
      padding: 0
    },
    thumbButtonClicked: {
      background: 'Silver !important'
    },
    thumbIcon: {
      height: 50,
      width: 50
    },
    textField: {
      width: '100%'
    },
    dialogContent: {
      background: '#f6f7fA'
    },
    dialogDescription: {
      marginTop: 18
    },
    dialogDescriptionText: {
      display: 'flex',
      justifyContent: 'center',
      color: '#424242',
      fontSize: '16px',
      textAlign: 'center',
      [`${theme.breakpoints.down(1024)}`]: {
        fontSize: '14px'
      }
    },
    dialogSubmitText: {
      display: 'flex',
      color: 'lightgrey',
      fontSize: '12px',
      [`${theme.breakpoints.down(1024)}`]: {
        margin: 'auto 1em auto auto',
        fontSize: 11
      }
    },
    dialogContainer: {
      height: 'auto',
      '& .MuiDialog-paperScrollPaper': {
        maxHeight: '100%',
        width: 450
      }
    },
    dialogCancelButton: {
      marginRight: 10
    },
    dialogActions: {
      [`${theme.breakpoints.down(1024)}`]: {
        display: 'flex',
        justifyContent: 'flex-end',
        flex: 1,
        marginLeft: '0px !important'
      },
      flexShrink: 0,
      '&> button:first-child': {
        marginRight: '8px'
      }
    },
    dialogSubmitTextBreak: {
      [`${theme.breakpoints.down(1024)}`]: {
        display: 'none'
      }
    }
  }))
}
