import React, { Dispatch, useState } from 'react'
import { connect } from 'react-redux'
import { Button, Grid } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import { getStylesFilterMenuItemsDateRange } from 'styles/contents/FIlterMenuItemsDateRange'
import { useIntl } from 'react-intl'
import { useFilter } from 'utils/filters'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { Store } from 'store'
import SettingsStore from 'store/Settings'
import { useDebounce } from 'utils/debounce'

export interface FilterMenuItemsDateRangeProps {
  filter: FilterCategory
  setCurrentPage: Dispatch<any>
  handleClose: any
  option: any
  modifiedRangeFilters: any
}

type AllProps = ReturnType<typeof mapStateToProps> &
  FilterMenuItemsDateRangeProps

function FilterMenuItemsDateRange(props: AllProps): JSX.Element {
  const {
    filter,
    setCurrentPage,
    handleClose,
    option,
    modifiedRangeFilters,
    deviceSettings
  } = props

  const [, setFilterUrlValue] = useFilter(filter.key, '')
  const [dateFrom, setDateFrom] = useState(
    modifiedRangeFilters.from ? new Date(modifiedRangeFilters.from) : null
  )
  const debouncedDateFrom = useDebounce(dateFrom, 500)
  const [dateTo, setDateTo] = useState(
    modifiedRangeFilters.to ? new Date(modifiedRangeFilters.to) : null
  )
  const debouncedDateTo = useDebounce(dateTo, 500)
  const intl = useIntl()

  const isCustomRangeSelectionEnabled = (): boolean => {
    if (
      modifiedRangeFilters.from &&
      modifiedRangeFilters.to &&
      modifiedRangeFilters.from <= modifiedRangeFilters.to
    ) {
      return true
    }

    if (
      (modifiedRangeFilters.from && !modifiedRangeFilters.to) ||
      (modifiedRangeFilters.to && !modifiedRangeFilters.from)
    ) {
      return true
    }

    return false
  }

  const [customRangeEnabled, setCustomRangeEnabled] = useState<boolean>(
    isCustomRangeSelectionEnabled()
  )

  const classes = getStylesFilterMenuItemsDateRange()

  const handleChangeDate = (
    dateValue: Date | null,
    target: 'to' | 'from'
  ): void => {
    let newDate = ''
    if (dateValue && dateValue.toString() !== 'Invalid Date') {
      const offset = dateValue.getTimezoneOffset()
      const newDateValue = new Date(dateValue.getTime() - offset * 60 * 1000)
      newDate = newDateValue.toISOString().slice(0, 10)

      if (target === 'from') {
        modifiedRangeFilters.from = newDate
        setDateFrom(dateValue)
      }
      if (target === 'to') {
        modifiedRangeFilters.to = newDate
        setDateTo(dateValue)
      }
      setCustomRangeEnabled(isCustomRangeSelectionEnabled())
    } else {
      if (target === 'from') {
        modifiedRangeFilters.from = null
        if (dateValue === null) setDateFrom(modifiedRangeFilters.from)
      }
      if (target === 'to') {
        modifiedRangeFilters.to = null
        if (dateValue === null) setDateTo(modifiedRangeFilters.to)
      }
      setCustomRangeEnabled(isCustomRangeSelectionEnabled())
    }
  }

  const handleCustomRangeSelection = () => {
    if (customRangeEnabled) {
      handleClose()

      option['selected'] = true
      setFilterUrlValue(
        `${option.key}(${
          modifiedRangeFilters.from ? modifiedRangeFilters.from : ''
        },${modifiedRangeFilters.to ? modifiedRangeFilters.to : ''})`,
        {
          method: 'push'
        }
      )

      // reset pagination
      setCurrentPage(1)
    }
  }

  return (
    <MenuItem
      disableTouchRipple
      disableRipple
      className={classes.datePickerMenuItem}
    >
      <Grid container>
        <Grid
          item
          xs={12}
          onKeyDown={(event) => {
            if (event.key === 'Tab') {
              event.stopPropagation()
            }
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              format="yyyy-MM-dd"
              value={debouncedDateFrom || null}
              onChange={(date: Date | null) => {
                handleChangeDate(date, 'from')
              }}
              label={
                deviceSettings.isMobile
                  ? ''
                  : intl.formatMessage({
                      id: 'date_time_select_placeholder_from',
                      defaultMessage: 'From'
                    })
              }
              maxDate={dateTo ? dateTo : undefined}
              slotProps={{
                field: {
                  clearable: true,
                  onClear: () => handleChangeDate(null, 'from')
                },
                textField: {
                  variant: 'standard',
                  className: classes.datePicker
                }
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid
          item
          xs={12}
          style={{ marginTop: '6px' }}
          onKeyDown={(event) => {
            if (event.key === 'Tab') {
              event.stopPropagation()
            }
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              format="yyyy-MM-dd"
              value={debouncedDateTo || null}
              onChange={(date: Date | null) => {
                handleChangeDate(date, 'to')
              }}
              label={
                deviceSettings.isMobile
                  ? ''
                  : intl.formatMessage({
                      id: 'date_time_select_placeholder_to',
                      defaultMessage: 'To'
                    })
              }
              minDate={dateFrom ? dateFrom : undefined}
              slotProps={{
                field: {
                  clearable: true,
                  onClear: () => handleChangeDate(null, 'to')
                },
                textField: {
                  variant: 'standard',
                  className: classes.datePicker
                }
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} className={classes.dateTimeButtonContainer}>
          <Button
            id={'btn-applydate-01'}
            className={
              customRangeEnabled
                ? classes.applyDateButton
                : classes.applyDateButtonDisabled
            }
            onClick={handleCustomRangeSelection}
            disabled={!customRangeEnabled}
          >
            {intl.formatMessage({
              id: 'last_modified_apply_range',
              defaultMessage: 'Apply dates'
            })}
          </Button>
        </Grid>
      </Grid>
    </MenuItem>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state)
  }
}

export default connect(mapStateToProps)(FilterMenuItemsDateRange)
