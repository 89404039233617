import { FeaturedResult } from 'components/models/FeaturedResult'
import { Reducer, combineReducers } from 'redux'
import {
  ResultsKnowledgeExchangeActions,
  ResultsKnowledgeExchangeActionTypes,
  IFetchSuccess,
  IFetchSuccessCombined,
  IFetchFiltersSuccess,
  IFetchRequest
} from './actions'
import { ISynonymsApplied } from 'components/models/SynonymsApplied'

import {
  HitsResponse,
  OpenSourceResponse,
  CatalogSourceResponse
} from 'utils/knowledgeExchange'

export interface IExtendedResults {
  synonymsApplied: ISynonymsApplied[]
  hasResultsBeenFetched: boolean
}

export interface IResultsKnowledgeExchangeType<T> {
  hasError: boolean
  hasResultsBeenFetched: boolean
  results: HitsResponse<T>[]
  resultsCombined: HitsResponse<T>[]
  resultsCombinedQuery: string
  resultCount: number
  executionTime: number
  currentPage: number
  filters: {
    currentQuery: string
    filetype?: Array<any>
    sector: Array<any>
    contenttype: Array<any>
    function: Array<any>
    serviceline: Array<any>
    subserviceline: Array<any>
    corridor: Array<any>
    kxcountry: Array<any>
    solution: Array<any>
    hasResultsBeenFetched: boolean
    hasError: boolean
  }
  featuredResults: FeaturedResult[]
}

export interface IResultsKnowledgeExchangeStore {
  open: IResultsKnowledgeExchangeType<OpenSourceResponse>
  catalog: IResultsKnowledgeExchangeType<CatalogSourceResponse>
  extendedResults: IExtendedResults
}

const initialResultFiltersState = (type: string) => ({
  currentQuery: '',
  ...(type === 'open' ? { filetype: [] } : {}),
  sector: [],
  contenttype: [],
  function: [],
  serviceline: [],
  subserviceline: [],
  corridor: [],
  kxcountry: [],
  solution: [],
  hasResultsBeenFetched: false,
  hasError: false
})

const openInitialState: IResultsKnowledgeExchangeType<OpenSourceResponse> = {
  hasError: false,
  hasResultsBeenFetched: false,
  resultCount: -1,
  results: [],
  resultsCombined: [],
  resultsCombinedQuery: '',
  filters: initialResultFiltersState('open'),
  currentPage: 1,
  executionTime: 0,
  featuredResults: []
}

const openReducers: Reducer<
  IResultsKnowledgeExchangeType<OpenSourceResponse>,
  ResultsKnowledgeExchangeActions
> = (
  state: IResultsKnowledgeExchangeType<OpenSourceResponse> = openInitialState,
  action:
    | { type: 'SET_CURRENT_PAGE'; payload: any }
    | ResultsKnowledgeExchangeActions
) => {
  switch (action.type) {
    case ResultsKnowledgeExchangeActionTypes.INITIALIZE_RESULTS_KNOWLEDGE_EXCHANGE:
      if (!(action as any).payload) {
        return openInitialState
      }
      const { resultsKnowledgeExchange } = (action as any).payload
      return {
        ...state,
        currentPage: resultsKnowledgeExchange.open.currentPage || 1
      }
    case ResultsKnowledgeExchangeActionTypes.FETCH_REQUEST:
      const { resetCombined, searchQuery } = (action as IFetchRequest).payload

      if (action.meta.type === 'open') {
        return {
          ...state,
          hasError: false,
          hasResultsBeenFetched: false,
          executionTime: 0,
          resultCount: -1,
          results: [],
          resultsCombined: resetCombined ? [] : state.resultsCombined,
          resultsCombinedQuery: searchQuery,
          featuredResults: resetCombined ? [] : state.featuredResults
        }
      }
      return state
    case ResultsKnowledgeExchangeActionTypes.FETCH_FAILURE:
      if (action.meta.type === 'open') {
        return {
          ...state,
          hasError: true,
          hasResultsBeenFetched: false,
          filters: {
            ...initialResultFiltersState('open'),
            hasResultsBeenFetched: true,
            hasError: true
          }
        }
      }
      return state
    case ResultsKnowledgeExchangeActionTypes.FETCH_SUCCESS:
      const { response, featuredResults } = (action as IFetchSuccess).payload
      if (action.meta.type === 'open') {
        return {
          ...state,
          hasError: false,
          hasResultsBeenFetched: true,
          executionTime: response.executionTime,
          resultCount: response.resultCount,
          results: response.knowledgeExchangeResults,
          currentPage: response.currentPage,
          featuredResults:
            response.currentPage === 1 ? featuredResults : state.featuredResults
        }
      }
      return state
    case ResultsKnowledgeExchangeActionTypes.FETCH_SUCCESS_COMBINED:
      const { response: responseCombined } = (action as IFetchSuccessCombined)
        .payload
      if (action.meta.type === 'open') {
        return {
          ...state,
          hasError: false,
          resultsCombined: responseCombined.resetCombined
            ? responseCombined.knowledgeExchangeResults
            : [
                ...state.resultsCombined,
                ...responseCombined.knowledgeExchangeResults
              ]
        }
      }
      return state

    case ResultsKnowledgeExchangeActionTypes.FETCH_FILTERS_REQUEST:
      if (action.meta.type === 'open') {
        return {
          ...state,
          hasError: false,
          filters: {
            ...initialResultFiltersState('open'),
            hasResultsBeenFetched: false,
            hasError: false
          }
        }
      }
      return state
    case ResultsKnowledgeExchangeActionTypes.FETCH_FILTERS_SUCCESS:
      const { refinerResult } = (action as IFetchFiltersSuccess).payload
      if (action.meta.type === 'open') {
        return {
          ...state,
          hasError: false,
          filters: {
            hasResultsBeenFetched: true,
            hasError: false,
            currentQuery: refinerResult.currentQuery,
            filetype: refinerResult.filetype,
            sector: refinerResult.sector,
            contenttype: refinerResult.contenttype,
            function: refinerResult.function,
            serviceline: refinerResult.serviceline,
            subserviceline: refinerResult.subserviceline,
            corridor: refinerResult.corridor,
            kxcountry: refinerResult.country,
            solution: refinerResult.solution
          }
        }
      }
      return state
    default:
      return state
  }
}

const catalogInitialState: IResultsKnowledgeExchangeType<CatalogSourceResponse> =
  {
    hasError: false,
    hasResultsBeenFetched: false,
    resultCount: -1,
    results: [],
    resultsCombined: [],
    resultsCombinedQuery: '',
    filters: initialResultFiltersState('catalog'),
    currentPage: 1,
    executionTime: 0,
    featuredResults: []
  }

const catalogReducers: Reducer<
  IResultsKnowledgeExchangeType<CatalogSourceResponse>,
  ResultsKnowledgeExchangeActions
> = (
  state: IResultsKnowledgeExchangeType<CatalogSourceResponse> = catalogInitialState,
  action:
    | { type: 'SET_CURRENT_PAGE'; payload: any }
    | ResultsKnowledgeExchangeActions
) => {
  switch (action.type) {
    case ResultsKnowledgeExchangeActionTypes.INITIALIZE_RESULTS_KNOWLEDGE_EXCHANGE:
      if (!(action as any).payload) {
        return catalogInitialState
      }
      const { resultsKnowledgeExchange } = (action as any).payload
      return {
        ...state,
        currentPage: resultsKnowledgeExchange.catalog.currentPage || 1
      }
    case ResultsKnowledgeExchangeActionTypes.FETCH_REQUEST:
      const { resetCombined, searchQuery } = (action as IFetchRequest).payload

      if (action.meta.type === 'catalog') {
        return {
          ...state,
          hasError: false,
          hasResultsBeenFetched: false,
          executionTime: 0,
          resultCount: -1,
          results: [],
          resultsCombined: resetCombined ? [] : state.resultsCombined,
          resultsCombinedQuery: searchQuery,
          featuredResults: resetCombined ? [] : state.featuredResults
        }
      }
      return state
    case ResultsKnowledgeExchangeActionTypes.FETCH_FAILURE:
      if (action.meta.type === 'catalog') {
        return {
          ...state,
          hasError: true,
          hasResultsBeenFetched: false,
          filters: {
            ...initialResultFiltersState('catalog'),
            hasResultsBeenFetched: true,
            hasError: true
          }
        }
      }
      return state
    case ResultsKnowledgeExchangeActionTypes.FETCH_SUCCESS:
      const { response, featuredResults } = (action as IFetchSuccess).payload
      if (action.meta.type === 'catalog') {
        return {
          ...state,
          hasError: false,
          hasResultsBeenFetched: true,
          executionTime: response.executionTime,
          resultCount: response.resultCount,
          results: response.knowledgeExchangeResults,
          currentPage: response.currentPage,
          featuredResults:
            response.currentPage === 1 ? featuredResults : state.featuredResults
        }
      }
      return state
    case ResultsKnowledgeExchangeActionTypes.FETCH_SUCCESS_COMBINED:
      const { response: responseCombined } = (action as IFetchSuccessCombined)
        .payload
      if (action.meta.type === 'catalog') {
        return {
          ...state,
          hasError: false,
          resultsCombined: responseCombined.resetCombined
            ? responseCombined.knowledgeExchangeResults
            : [
                ...state.resultsCombined,
                ...responseCombined.knowledgeExchangeResults
              ]
        }
      }
      return state

    case ResultsKnowledgeExchangeActionTypes.FETCH_FILTERS_REQUEST:
      if (action.meta.type === 'catalog') {
        return {
          ...state,
          hasError: false,
          filters: {
            ...initialResultFiltersState('catalog'),
            hasResultsBeenFetched: false,
            hasError: false
          }
        }
      }
      return state
    case ResultsKnowledgeExchangeActionTypes.FETCH_FILTERS_SUCCESS:
      const { refinerResult } = (action as IFetchFiltersSuccess).payload
      if (action.meta.type === 'catalog') {
        return {
          ...state,
          hasError: false,
          filters: {
            hasResultsBeenFetched: true,
            hasError: false,
            currentQuery: refinerResult.currentQuery,
            sector: refinerResult.sector,
            contenttype: refinerResult.contenttype,
            function: refinerResult.function,
            serviceline: refinerResult.serviceline,
            subserviceline: refinerResult.subserviceline,
            corridor: refinerResult.corridor,
            kxcountry: refinerResult.country,
            solution: refinerResult.solution
          }
        }
      }
      return state
    default:
      return state
  }
}

const extendedResultsInitialState: IExtendedResults = {
  synonymsApplied: [],
  hasResultsBeenFetched: false
}

const extendedResultsReducer: Reducer<
  IExtendedResults,
  ResultsKnowledgeExchangeActions
> = (
  state: IExtendedResults = extendedResultsInitialState,
  action: ResultsKnowledgeExchangeActions
) => {
  switch (action.type) {
    case ResultsKnowledgeExchangeActionTypes.FETCH_REQUEST:
      if (action.meta.type === 'open') {
        const { resetCombined } = (action as any).payload
        return {
          ...state,
          hasResultsBeenFetched: false,
          synonymsApplied: resetCombined ? [] : state.synonymsApplied
        }
      }
      return state
    case ResultsKnowledgeExchangeActionTypes.FETCH_FAILURE:
      if (action.meta.type === 'open') {
        return {
          ...state,
          hasResultsBeenFetched: true,
          synonymsApplied: []
        }
      }
      return state
    case ResultsKnowledgeExchangeActionTypes.FETCH_SUCCESS:
      if (action.meta.type === 'open') {
        const { response, synonymsApplied } = (action as IFetchSuccess).payload

        return {
          ...state,
          hasResultsBeenFetched: true,
          synonymsApplied:
            response.currentPage === 1 ? synonymsApplied : state.synonymsApplied
        }
      }
      return state
    default:
      return state
  }
}

export default combineReducers({
  open: openReducers,
  catalog: catalogReducers,
  extendedResults: extendedResultsReducer
})
