import React from 'react'
import { Box, Link } from '@mui/material'
import { trackAdClick, trackAdFeedbackClick, trackEvents } from 'utils/tracking'
import { getStylesAds } from 'styles/contents/resultpages/Ads'
import { useFilter } from 'utils/filters'
import createDOMPurify from 'dompurify'
import { AdResult } from 'components/models/AdResult'
import { KPMGFindGlobalVariables } from 'store/KPMGFindGlobalVariables'
import TooltipTitle from '../common/TooltipTitle'
import { useIntl } from 'react-intl'
import { IDeviceSetting } from 'utils/deviceSettings'
import { enterpriseSearchEmail } from 'constants/constants'
import highlightText, { prepareHighlightWords } from 'utils/highlightText'

type Props = {
  adItem: AdResult
  deviceSettings: IDeviceSetting
  incorrectLinkUri?: string
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
  searchQuery?: string
}

const Ads = ({ adItem, deviceSettings, onClick }: Props): any => {
  const [searchQuery] = useFilter('q', '')
  const DOMPurify = createDOMPurify(window)
  const intl = useIntl()
  if (!adItem) {
    return null
  }
  const sanitizedAdItem: AdResult = {
    id: DOMPurify.sanitize(adItem.id, {
      USE_PROFILES: { html: false }
    }),
    title: DOMPurify.sanitize(adItem.title, {
      USE_PROFILES: { html: false }
    }),
    text: DOMPurify.sanitize(adItem.text, {
      USE_PROFILES: { html: true },
      ADD_ATTR: ['target']
    }),
    link: DOMPurify.sanitize(adItem.link, {
      USE_PROFILES: { html: false }
    }),
    link_text: DOMPurify.sanitize(adItem.link_text, {
      USE_PROFILES: { html: false }
    }),
    image: DOMPurify.sanitize(adItem.image, {
      USE_PROFILES: { html: false }
    }),
    flavour: adItem.flavour
      ? DOMPurify.sanitize(adItem.flavour, {
          USE_PROFILES: { html: false }
        })
      : null,
    foundBySearchTerm: adItem.foundBySearchTerm
      ? DOMPurify.sanitize(adItem.foundBySearchTerm, {
          USE_PROFILES: { html: false }
        })
      : null
  }

  const trackAdClickEvent = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    if (!sanitizedAdItem) return
    const el = event.target as HTMLElement

    let isLinkTag = null
    if (el && el.tagName === 'A') {
      isLinkTag = el
    } else if (el && el.parentElement && el.parentElement.tagName === 'A') {
      isLinkTag = el.parentElement
    }

    if (isLinkTag) {
      const link = isLinkTag.getAttribute('href')
      trackAdClick(sanitizedAdItem, link, event?.button === 2 ? true : false)
    }
  }

  const handleClick = (event: any) => {
    if (onClick) {
      onClick(event)
    }
    trackAdClickEvent(event)
  }

  const parsedText = new DOMParser().parseFromString(adItem.text, 'text/html')

  let adwordData = 'Id: ' + adItem.id
  if (adItem.link_text && adItem.link_text.length > 0) {
    adwordData += '\nLink text: ' + adItem.link_text
  }
  if (adItem.link && adItem.link.length > 0) {
    adwordData += '\nLink: ' + adItem.link
  }
  adwordData +=
    '\nTitle: ' + adItem.title + '\nText: ' + parsedText.body.textContent || ''

  const incorrectLink = {
    address: enterpriseSearchEmail,
    subject: intl.formatMessage({
      id: 'adwords_correct_data_subject',
      defaultMessage: '[Content Feedback: AdWords]'
    }),
    body: intl
      .formatMessage({
        id: 'adwords_correct_data_body',
        defaultMessage:
          'Hi Team,\n\nI came across an inaccuracy in the AdWord data. The right AdWord data is:\n\n{adData}\n\n'
      })
      .replaceAll('\\n', '\n')
      .replace('{adData}', adwordData)
  }

  const incorrectLinkUri = `mailto:${incorrectLink.address}?subject=${encodeURI(
    incorrectLink.subject
  )}&body=${encodeURIComponent(incorrectLink.body)}`

  const { flavour } = sanitizedAdItem

  switch (flavour) {
    case 'small':
      return (
        <SmallAds
          adItem={sanitizedAdItem}
          deviceSettings={deviceSettings}
          incorrectLinkUri={incorrectLinkUri}
          onClick={handleClick}
          searchQuery={searchQuery}
        />
      )
    case 'large':
      return (
        <LargeAds
          adItem={sanitizedAdItem}
          deviceSettings={deviceSettings}
          incorrectLinkUri={incorrectLinkUri}
          onClick={handleClick}
          searchQuery={searchQuery}
        />
      )
    default:
      // no flavour specified, pick one...

      /*
      const chosenFlavour = ['small', 'medium', 'large'][
        Math.round((3 - 1) * Math.random())
      ]
      */

      const chosenFlavour = 'small'

      return (
        <Ads
          adItem={{
            ...sanitizedAdItem,
            flavour: chosenFlavour as 'small' | 'large'
          }}
          deviceSettings={deviceSettings}
        />
      )
  }
}

const SmallAds = ({
  adItem,
  deviceSettings,
  incorrectLinkUri,
  onClick,
  searchQuery
}: Props) => {
  const linkUrl: string = incorrectLinkUri ? incorrectLinkUri : ''
  const classes = getStylesAds()
  const DOMPurify = createDOMPurify(window)
  const currentTab = KPMGFindGlobalVariables.getCurrentTab()
  const intl = useIntl()

  React.useEffect(() => {
    if (adItem) {
      const distinct = KPMGFindGlobalVariables.getAdwordDistinct(
        parseInt(adItem.id)
      )

      const params = {
        title: adItem ? adItem.title : '',
        link: adItem ? adItem.link : '',
        searchTerm: searchQuery,
        tab: currentTab,
        adFlavour: 'small',
        distinct: distinct
      }
      trackEvents('adWords-shown', params)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(adItem), searchQuery, currentTab])

  let highlightedAdWordText = adItem.text
    ? DOMPurify.sanitize(adItem.text.trim())
    : ''

  const parsedtext = new DOMParser().parseFromString(
    highlightedAdWordText,
    'text/html'
  ).body.textContent

  if (parsedtext === highlightedAdWordText) {
    highlightedAdWordText = highlightText(
      highlightedAdWordText,
      prepareHighlightWords(
        DOMPurify.sanitize(searchQuery),
        highlightedAdWordText,
        []
      ),
      true
    )
  }

  return (
    <Box
      className={classes.advertisementArea}
      onClick={onClick}
      onAuxClick={onClick}
    >
      <div className={classes.advertisementTextContainer}>
        <div className={classes.smallAdsTopRow}>
          {/* Image place holders here */}
          {adItem.image && (
            <img
              className={classes.smallAdsIcon}
              src={adItem.image}
              alt="Ads"
            />
          )}
          <div className={classes.smallAdsTitle}>{adItem.title}</div>
        </div>
        <div
          className={classes.advertisementText}
          dangerouslySetInnerHTML={{
            __html: deviceSettings.openLinksInNewTab
              ? highlightedAdWordText.replaceAll('<a ', '<a target="_blank" ')
              : highlightedAdWordText
          }}
        />
        <a
          className={classes.smallAdsLink}
          href={adItem.link}
          target={deviceSettings.openLinksInNewTab ? '_blank' : '_self'}
          rel={deviceSettings.openLinksInNewTab ? 'noreferrer' : undefined}
        >
          <div>{adItem.link_text}</div>
        </a>
        <div className={`${classes.widgetFooterLink} ${classes.footerGrid}`}>
          <Link
            data-interception={'off'}
            underline={'hover'}
            href={linkUrl}
            {...(deviceSettings.openLinksInNewTab === true
              ? { target: '_blank', rel: 'noreferrer' }
              : {})}
            onClick={() => {
              trackAdFeedbackClick(adItem, linkUrl, false)
            }}
            {...(!deviceSettings.isMobile
              ? {
                  onAuxClick: (event: any) => {
                    trackAdFeedbackClick(adItem, linkUrl, true)
                  }
                }
              : {})}
          >
            <TooltipTitle
              title={intl.formatMessage({
                id: 'adwords_correct_data',
                defaultMessage: 'Information incorrect? Let us know!'
              })}
              additionalClass={`${classes.widgetFooter} ${classes.link}`}
              singleLine={true}
              useCssOnly={true}
            />
          </Link>
        </div>
      </div>
    </Box>
  )
}

const LargeAds = ({
  adItem,
  deviceSettings,
  incorrectLinkUri,
  onClick,
  searchQuery
}: Props) => {
  const linkUrl: string = incorrectLinkUri ? incorrectLinkUri : ''
  const classes = getStylesAds()
  const DOMPurify = createDOMPurify(window)
  const currentTab = KPMGFindGlobalVariables.getCurrentTab()
  const intl = useIntl()

  let highlightedAdWordText = adItem.text
    ? DOMPurify.sanitize(adItem.text.trim())
    : ''

  const parsedtext = new DOMParser().parseFromString(
    highlightedAdWordText,
    'text/html'
  ).body.textContent

  if (parsedtext === highlightedAdWordText) {
    highlightedAdWordText = highlightText(
      highlightedAdWordText,
      prepareHighlightWords(
        DOMPurify.sanitize(searchQuery),
        highlightedAdWordText,
        []
      ),
      true
    )
  }

  const hideTextContainer =
    (adItem.title == null || adItem.title.length <= 0) &&
    (adItem.text == null || adItem.text.length <= 0) &&
    (adItem.link == null || adItem.link.length <= 0)

  React.useEffect(() => {
    if (adItem) {
      const distinct = KPMGFindGlobalVariables.getAdwordDistinct(
        parseInt(adItem.id)
      )
      const params = {
        title: adItem ? adItem.title : '',
        link: adItem ? adItem.link : '',
        searchTerm: searchQuery,
        tab: currentTab,
        adFlavour: 'large',
        distinct: distinct
      }
      trackEvents('adWords-shown', params)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(adItem), searchQuery, currentTab])
  return (
    <Box
      className={classes.advertisementArea}
      onClick={onClick}
      onAuxClick={onClick}
    >
      <img
        className={classes.largeAdsRow}
        src={adItem.image}
        alt="Ads"
        style={{ borderRadius: hideTextContainer ? 8 : undefined }}
      />
      {!hideTextContainer && (
        <div className={classes.advertisementTextContainer}>
          {adItem.title != null && adItem.title.length > 0 && (
            <div className={classes.largeAdsTitle}>{adItem.title}</div>
          )}
          {adItem.text != null && adItem.text.length > 0 && (
            <div
              className={classes.advertisementText}
              dangerouslySetInnerHTML={{
                __html: deviceSettings.openLinksInNewTab
                  ? highlightedAdWordText.replaceAll(
                      '<a ',
                      '<a target="_blank" '
                    )
                  : highlightedAdWordText
              }}
            />
          )}
          {adItem.link != null && adItem.link.length > 0 && (
            <a
              className={classes.smallAdsLink}
              href={adItem.link}
              target={deviceSettings.openLinksInNewTab ? '_blank' : '_self'}
              rel={deviceSettings.openLinksInNewTab ? 'noreferrer' : undefined}
            >
              <div> {adItem.link_text}</div>
            </a>
          )}
          <div className={`${classes.widgetFooterLink} ${classes.footerGrid}`}>
            <Link
              data-interception={'off'}
              underline={'hover'}
              href={linkUrl}
              {...(deviceSettings.openLinksInNewTab === true
                ? { target: '_blank', rel: 'noreferrer' }
                : {})}
              onClick={() => {
                trackAdClick(adItem, linkUrl, false)
              }}
              {...(!deviceSettings.isMobile
                ? {
                    onAuxClick: (event: any) => {
                      trackAdClick(adItem, linkUrl, true)
                    }
                  }
                : {})}
            >
              <TooltipTitle
                title={intl.formatMessage({
                  id: 'adwords_correct_data',
                  defaultMessage: 'Information incorrect? Let us know!'
                })}
                additionalClass={`${classes.widgetFooter} ${classes.link}`}
                singleLine={true}
                useCssOnly={true}
              />
            </Link>
          </div>
        </div>
      )}
    </Box>
  )
}
export default Ads
