import React from 'react'
import { Button, ButtonGroup, Chip } from '@mui/material'
import PulseLoader from 'react-spinners/PulseLoader'
import { IChatRequest } from 'components/models/Chat'
import {
  getStylesChatMessage,
  getThemeChatMessage
} from 'styles/contents/resultpages/chat/ChatMessage'
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt'
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DownloadIcon from '@mui/icons-material/Download'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

export interface ChatMessageProps {
  type: 'request' | 'answer'
  item: IChatRequest
}

export default function ChatMessage(props: ChatMessageProps): JSX.Element {
  const { type, item } = props
  const classes = getStylesChatMessage()
  const theme = getThemeChatMessage()

  const buildAnswer = (answer: string) => {
    return (
      <div className={classes.answerContentItem}>
        <ReactMarkdown children={answer} remarkPlugins={[remarkGfm]} />
      </div>
    )
  }

  return (
    <>
      {item.Answer && (
        <div
          className={classes.container}
          style={{
            alignSelf: type === 'request' ? 'flex-end' : 'flex-start'
          }}
        >
          <Chip
            color={'default'}
            size={'medium'}
            variant={type === 'request' ? 'filled' : 'outlined'}
            sx={theme.messageSx}
            label={
              type === 'request' ? (
                <div className={classes.requestContent}>{item.Request}</div>
              ) : (
                <div className={classes.answerContentWrapper}>
                  {item.Answer.AnswerFetched && item.Answer.AnswerResponse && (
                    <div
                      className={`${classes.answerActionGroupWrapper} answerActionGroupWrapper`}
                    >
                      <ButtonGroup
                        className={classes.answerActionGroup}
                        size="small"
                        variant="contained"
                        aria-label="outlined primary button group"
                      >
                        <Button //TODO onClick={}
                        >
                          <ThumbUpOffAltIcon fontSize={'small'} />
                        </Button>
                        <Button //TODO onClick={}
                        >
                          <ThumbDownOffAltIcon fontSize={'small'} />
                        </Button>
                        <Button
                        //TODO onClick={}
                        >
                          <ContentCopyIcon fontSize={'small'} />
                        </Button>
                        <Button
                        //TODO onClick={}
                        >
                          <DownloadIcon fontSize={'small'} />
                        </Button>
                      </ButtonGroup>
                    </div>
                  )}
                  <section>
                    {item.Answer.AnswerFetched && item.Answer.AnswerResponse ? (
                      <div className={classes.answerContent}>
                        {buildAnswer(item.Answer.AnswerResponse)}
                      </div>
                    ) : (
                      <div className={classes.answerContent}>
                        <PulseLoader speedMultiplier={0.5} size={5} />
                      </div>
                    )}
                  </section>
                </div>
              )
            }
          />
        </div>
      )}
    </>
  )
}
