import { FeaturedResult } from 'components/models/FeaturedResult'
import { ISynonymsApplied } from 'components/models/SynonymsApplied'
import { Reducer } from 'redux'

import {
  ResultsOneIntranetActions,
  ResultsOneIntranetActionTypes,
  IFetchSuccess,
  IFetchSeoSuccess,
  IFetchSuccessCombined
} from './actions'
import { IOneIntranetResult } from 'components/models/OneIntranetResult'
import { ISeoResult } from 'components/models/SeoResult'

export interface IOneIntranetResultStore {
  queryResults: Array<IOneIntranetResult>
  synonymsApplied: Array<ISynonymsApplied>
}
export interface IResultsOneIntranetStore {
  hasResultsBeenFetched: boolean
  hasResultsSeoBeenFetched: boolean
  featuredResults: FeaturedResult[]
  results: IOneIntranetResultStore
  resultsCombined: IOneIntranetResultStore
  resultsSeo: ISeoResult
  resultsCombinedQuery: string
  totalRowCount: number
  lastRow: number
  resultSeoCount: number
  hasError: boolean
  executionTime: number
}
const initialResultState = () => ({
  queryResults: [],
  synonymsApplied: []
})

const initialSeoResultState = () => ({
  title: '',
  resultBody: '',
  date: '',
  resultUrl: '',
  lastModifiedDate: '',
  fileName: '',
  updateLink: ''
})

const initialState: IResultsOneIntranetStore = {
  hasResultsBeenFetched: false,
  hasResultsSeoBeenFetched: false,
  featuredResults: [],
  results: initialResultState(),
  resultsCombined: initialResultState(),
  resultsSeo: initialSeoResultState(),
  resultsCombinedQuery: '',
  resultSeoCount: -1,
  totalRowCount: 0,
  lastRow: 0,
  hasError: false,
  executionTime: 0
}

const reducers: Reducer<IResultsOneIntranetStore, ResultsOneIntranetActions> = (
  state: IResultsOneIntranetStore = initialState,
  action:
    | {
        type: ResultsOneIntranetActionTypes.INITIALIZE_RESULTS_ONEINTRANET
        payload: any
      }
    | ResultsOneIntranetActions
) => {
  switch (action.type) {
    case ResultsOneIntranetActionTypes.INITIALIZE_RESULTS_ONEINTRANET:
      if (!(action as any).payload) {
        return initialState
      }
      const { resultsOneIntranet } = (action as any).payload
      return {
        ...state,
        lastRow: resultsOneIntranet.lastRow || 0
      }
    case ResultsOneIntranetActionTypes.FETCH_REQUEST:
      const { resetCombined, searchQuery } = (action as any).payload
      return {
        ...state,
        hasResultsBeenFetched: false,
        executionTime: 0,
        resultCount: -1,
        totalRowCount: 0,
        results: initialResultState(),
        resultsCombined: resetCombined
          ? initialResultState()
          : state.resultsCombined,
        lastRow: resetCombined ? 0 : state.lastRow,
        resultsCombinedQuery: searchQuery
      }
    case ResultsOneIntranetActionTypes.FETCH_SEO_REQUEST:
      return {
        ...state,
        hasResultsSeoBeenFetched: false,
        resultsSeo: initialSeoResultState()
      }
    case ResultsOneIntranetActionTypes.FETCH_FAILURE:
      return {
        ...state,
        hasResultsBeenFetched: true,
        totalRowCount: 0
      }
    case ResultsOneIntranetActionTypes.FETCH_SEO_FAILURE:
      return {
        ...state,
        hasResultsSeoBeenFetched: true,
        resultSeoCount: 0
      }
    case ResultsOneIntranetActionTypes.FETCH_SUCCESS:
      const { response, featuredResults, currentPage } = (
        action as IFetchSuccess
      ).payload
      return {
        ...state,
        hasResultsBeenFetched: true,
        executionTime: response.executionTime,
        totalRowCount: response.totalRowCount,
        featuredResults:
          currentPage === 1 ? featuredResults : state.featuredResults,
        results: {
          queryResults: response.queryResults,
          synonymsApplied: response.synonymsApplied
        },
        lastRow: response.lastRow,
        hasError: response.hasError
      }
    case ResultsOneIntranetActionTypes.FETCH_SUCCESS_COMBINED:
      const { response: responseCombined } = (action as IFetchSuccessCombined)
        .payload
      return {
        ...state,
        resultsCombined: {
          queryResults: responseCombined.queryResults,
          synonymsApplied: responseCombined.synonymsApplied
        },
        lastRow: responseCombined.lastRow,
        hasError: responseCombined.hasError
      }
    case ResultsOneIntranetActionTypes.FETCH_SEO_SUCCESS:
      const { responseSeo } = (action as IFetchSeoSuccess).payload
      return {
        ...state,
        hasResultsSeoBeenFetched: true,
        resultSeoCount: responseSeo.resultSeoCount,
        resultsSeo: {
          title: responseSeo.title,
          resultBody: responseSeo.resultBody,
          date: responseSeo.date,
          resultUrl: responseSeo.resultUrl,
          lastModifiedDate: responseSeo.lastModifiedDate,
          fileName: responseSeo.fileName,
          updateLink: responseSeo.updateLink
        }
      }
    default:
      return state
  }
}

export default reducers
