/**
 * Build multi select filter string for office 365
 */
export function buildFilesRefinerForOffice365(filterValues: string[]): string {
  if (filterValues.length > 1) return `(${filterValues.join(' OR ')})`
  else return filterValues[0]
}

/**
 * Build multi select filter string for office 365
 */
export function buildTypeRefinerForSource(filterValues: string[]): string {
  let typeFilterString = ''

  filterValues.forEach((value: string) => {
    typeFilterString = typeFilterString
      ? typeFilterString + '%2C%22' + value + '%22'
      : '%22' + value + '%22'
  })

  return typeFilterString ? '+and+type+in+(' + typeFilterString + ')' : ''
}

/**
 * Build multi select filter string for knowledge exchange
 */
export function buildRefinerForKnowledgeExchange(
  filterValues: string[]
): string {
  if (filterValues.length > 1) return `${filterValues.join('|')}`
  else return filterValues[0]
}

const refinerToHexRepresentation = (refinerValue: string): string => {
  if (!refinerValue) return ''
  const resultArray = []

  const refinerValueEncoded = encodeURIComponent(refinerValue)

  for (let n = 0, l = refinerValueEncoded.length; n < l; n++) {
    if (refinerValueEncoded[n] === '%') {
      if (n + 2 >= refinerValueEncoded.length) {
        return refinerValue
      }
      resultArray.push(
        refinerValueEncoded.substring(n + 1, n + 3).toLowerCase()
      )
      n = n + 2
    } else {
      let hex = Number(refinerValueEncoded.charCodeAt(n)).toString(16)

      if (hex.length === 1) {
        hex = `0${hex}`
      }

      resultArray.push(hex)
    }
  }
  return `ǂǂ${resultArray.join('')}`
}

const buildRefinerForPeople = (filterValues: string[], refinerName: string) => {
  let refinerString = ''

  const refinerTokens = filterValues.map((filterText: string) => {
    return `"${refinerToHexRepresentation(filterText)}"`
  })

  if (filterValues.length > 1) refinerString = `OR(${refinerTokens.join(',')})`
  else refinerString = refinerTokens[0]

  return `${refinerName}:${refinerString}`
}

/**
 * Build multi select filter string for people filters
 */
export function buildCountryRefinerForPeople(filterValues: string[]): string {
  return buildRefinerForPeople(filterValues, 'RefinableString59')
}

/**
 * Build multi select filter string for city filter in people datasource
 */
export function buildCityRefinerForPeople(filterValues: string[]): string {
  return buildRefinerForPeople(filterValues, 'RefinableString60')
}

/**
 * Build multi select filter string for department filter in people datasource
 */
export function buildDepartmentRefinerForPeople(
  filterValues: string[]
): string {
  return buildRefinerForPeople(filterValues, 'Department')
}

/**
 * Build multi select filter string for job title filter in people datasource
 */
export function buildJobTitleRefinerForPeople(filterValues: string[]): string {
  return buildRefinerForPeople(filterValues, 'JobTitle')
}
