import React from 'react'
import * as Config from 'config'
import { ResultRightWidgetGenerator } from './interfaces'
import RightPeopleWidget from 'components/contents/resultpages/widgets/RightPeopleWidget'
import { trackException } from 'utils/tracking'
import { FetchWithCache } from 'utils/api'
import { getSPOnlineToken, renewAuthorizationToken } from 'utils/token'
import {
  IOneIntranetPeopleQuery,
  removeStopWordsFromQuery
} from 'utils/oneIntranet'
import { IDeviceSetting } from 'utils/deviceSettings'
import { IUserSetting } from 'utils/userSettings'
import { IFindConfiguration } from 'store/Settings/reducers'
import { IPeopleResult } from 'components/models/PeopleResult'
import { ISynonymsApplied } from 'components/models/SynonymsApplied'
import { IAADState } from 'store/Auth/reducers'

export const rightPeopleWidgetGenerator: ResultRightWidgetGenerator = {
  fetch(
    aadInfo: IAADState,
    searchQuery: string,
    deviceSettings: IDeviceSetting,
    userSettings: IUserSetting,
    findConfiguration: IFindConfiguration,
    useCognitiveSearch: boolean
  ): Promise<{
    queryResults: IPeopleResult[]
    synonymsApplied: ISynonymsApplied[]
  }> {
    return new Promise<{
      queryResults: IPeopleResult[]
      synonymsApplied: ISynonymsApplied[]
    }>((resolve, reject) => {
      const fetchPeople = async () => {
        if (!searchQuery || searchQuery === '' || searchQuery === '""') {
          trackException(
            'Error in fetching People right widget in rightPeopleWidget.tsx',
            new Error('Empty query. Please specify a valid query text')
          )
          reject()
          return
        }

        let primaryResults = []

        let spoToken = ''
        if (Config.LOCAL_DEVELOPMENT.toString() === 'false') {
          spoToken = await getSPOnlineToken(aadInfo.instance, aadInfo.accounts)
        }

        const apiUrl = `${Config.APIM_BASE_URL}oneintranetapi/postpeople`
        const bodyObject: IOneIntranetPeopleQuery = {
          rowlimit: 20,
          rowsperpage: 20,
          querytext: searchQuery,
          startRow: 0,
          trimduplicates: false,
          dynamicRefiners: '',
          genericRefiners: [],
          cognitiveEnabled: false,
          processFeaturedResults: false,
          featuredResultsRowLimit: '3',
          featuredResultsOrigin: 'KPMGFind',
          country: userSettings.Country ? userSettings.Country : '',
          city: userSettings.City ? userSettings.City : '',
          function: userSettings.Function ? userSettings.Function : ''
        }

        // Get and check authentication token
        const esToken = await renewAuthorizationToken(
          aadInfo.accessToken,
          aadInfo.instance,
          aadInfo.accounts
        )

        if (
          useCognitiveSearch &&
          findConfiguration.CognitiveSearchEnabled &&
          esToken !== ''
        ) {
          let searchQueryLanguage = ''
          const detectLanguageApiUrl = `${
            Config.APIM_BASE_URL
          }searchapi/detectlanguage?searchQuery=${encodeURIComponent(
            searchQuery
          )}`

          const languageResponse = await FetchWithCache(detectLanguageApiUrl, {
            method: 'POST',
            headers: {
              accept: 'application/json',
              'content-type': 'application/json',
              'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
              Authorization: `Bearer ${esToken}`
            }
          })
          if (
            languageResponse &&
            !languageResponse.hasError &&
            languageResponse.responseJSON
          ) {
            const languageJSON = languageResponse.responseJSON
            if (languageJSON && languageJSON.responseCode === 200) {
              searchQueryLanguage = languageJSON.language
            }
          }
          bodyObject.querytext = removeStopWordsFromQuery(
            searchQuery,
            searchQueryLanguage,
            useCognitiveSearch,
            findConfiguration.CognitiveSearchEnabled
          )
          bodyObject.cognitiveEnabled = true
        }

        const body = JSON.stringify(bodyObject)

        const response = await FetchWithCache(
          apiUrl,
          {
            method: 'POST',
            headers: {
              'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
              'content-type': 'application/json',
              Authorization: `Bearer ${spoToken}`,
              'Authorization-OnPrem': `${esToken}`
            },
            body: body
          },
          undefined,
          true,
          'widget_people',
          undefined,
          searchQuery
        )

        let results
        if (!response || response.hasError || !response.responseJSON) {
          if (
            !response ||
            !response.errorResponse ||
            response.errorResponse.responseCode !== 499
          ) {
            trackException(
              'Error in fetching People right widget in rightPeopleWidget.tsx',
              new Error(
                response && response.errorResponse
                  ? response.errorResponse.responseCode +
                    ':' +
                    response.errorResponse.message
                  : ''
              )
            )
          }
          reject()
          return
        } else {
          results = response.responseJSON
        }

        primaryResults =
          results && results.QueryResults ? results.QueryResults : []

        primaryResults = primaryResults.map((singleResult: IPeopleResult) => {
          singleResult.pictureUrl = `${Config.GLOBAL_OI_TOKEN_ENDPOINT}${singleResult.pictureUrl}`
          return singleResult
        })

        if (!primaryResults || primaryResults.length < 3) {
          reject()
          return
        }

        resolve({
          queryResults: primaryResults,
          synonymsApplied: results.SynonymsApplied
        })
      }
      try {
        fetchPeople()
      } catch (error) {
        trackException(
          'Error in fetching People right widget in rightPeopleWidget.tsx',
          error
        )
        reject()
      }
    })
  },
  generate(
    results: {
      queryResults: IPeopleResult[]
      synonymsApplied: ISynonymsApplied[]
    },
    searchQuery: string
  ): Promise<React.ReactElement> {
    return new Promise<React.ReactElement>((resolve, reject) => {
      const generatePeople = () => {
        let content = results.queryResults

        if (!content) {
          reject()
          return
        }

        if (content.length > 10) {
          content = results.queryResults.slice(0, 10)
        }

        let hasContent = false
        for (let i = 0; i < content.length; i++) {
          if (content[i].displayName !== '') {
            hasContent = true
            break
          }
        }
        if (!hasContent) {
          reject()
          return
        }

        resolve(
          <RightPeopleWidget
            searchQuery={searchQuery}
            peopleResults={content}
            synonymsApplied={results.synonymsApplied}
          />
        )
      }

      try {
        generatePeople()
      } catch (error) {
        trackException(
          'Error in generating People right widget in rightPeopleWidget.tsx',
          error
        )
        reject()
      }
    })
  }
}
