import { ContactUsTile } from 'components/models/ContactUsTile'
import { Reducer } from 'redux'
import {
  ContactUsActions,
  ContactUsActionTypes,
  IFetchContactUsTilesSuccess
} from './actions'

export interface IContactUsStore {
  contactUsTiles: ContactUsTile[]
  hasBeenFetched: boolean
  hasError: boolean
}

const initialState: IContactUsStore = {
  contactUsTiles: [],
  hasBeenFetched: false,
  hasError: false
}

const reducers: Reducer<IContactUsStore, ContactUsActions> = (
  state: IContactUsStore = initialState,
  action: ContactUsActions
) => {
  switch (action.type) {
    case ContactUsActionTypes.FETCH_CONTACT_US_TILES_FAILURE:
      return {
        ...state,
        hasBeenFetched: true,
        hasError: true
      }

    case ContactUsActionTypes.FETCH_CONTACT_US_TILES_SUCCESS:
      const { contactUsTileResponse } = (action as IFetchContactUsTilesSuccess)
        .payload

      return {
        ...state,
        contactUsTiles: contactUsTileResponse,
        hasBeenFetched: true,
        hasError: false
      }

    default:
      return state
  }
}

export default reducers
