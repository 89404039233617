import { Reducer } from 'redux'
import {
  SearchActions,
  SearchActionTypes,
  IFetchSuccess,
  IStoreHistory,
  IFetchSearchHistorySuccess,
  IFetchAutoCorrectSuccess
} from './actions'

export interface IEntity {
  text: string
  category: string
  subcategory: string
  offset: number
  length: number
  confidenceScore: number
}

export interface ISearchStore {
  hasResultsBeenFetched: boolean
  results: {
    autoSuggestData: string[]
  }
  resultCount: number
  hasError: boolean
  executionTime: number
  history: string[]
  spellCorrectedSearchQuery: string
  entities: IEntity[]
  hasSearchDataBeenFetched: boolean
}

const initialState: ISearchStore = {
  hasResultsBeenFetched: false,
  results: {
    autoSuggestData: []
  },
  hasError: false,
  resultCount: -1,
  executionTime: 0,
  history: [],
  spellCorrectedSearchQuery: '',
  entities: [],
  hasSearchDataBeenFetched: false
}

const reducers: Reducer<ISearchStore, SearchActions> = (
  state: ISearchStore = initialState,
  action: SearchActions
) => {
  switch (action.type) {
    case SearchActionTypes.FETCH_AUTOCOMPLETE_REQUEST:
      return {
        ...state,
        hasResultsBeenFetched: false,
        hasError: false
      }
    case SearchActionTypes.FETCH_AUTOCOMPLETE_FAILURE:
      return {
        ...state,
        hasResultsBeenFetched: false,
        hasError: true
      }
    case SearchActionTypes.FETCH_AUTOCOMPLETE_SUCCESS:
      const { response } = (action as IFetchSuccess).payload
      return {
        ...state,
        hasResultsBeenFetched: true,
        hasError: false,
        results: {
          autoSuggestData: response.autoSuggestData.map(
            (suggestion: string) => ({
              suggestion,
              type: 'server'
            })
          )
        }
      }
    case SearchActionTypes.FETCH_AUTOCORRECT_REQUEST:
      return {
        ...state,
        spellCorrectedSearchQuery: '',
        entities: [],
        hasSearchDataBeenFetched: false,
        hasError: false
      }
    case SearchActionTypes.FETCH_AUTOCORRECT_FAILURE:
      return {
        ...state,
        spellCorrectedSearchQuery: '',
        entities: [],
        hasSearchDataBeenFetched: true,
        hasError: true
      }
    case SearchActionTypes.FETCH_AUTOCORRECT_SUCCESS:
      const { responseAutoCorrect, responseEntities } = (
        action as IFetchAutoCorrectSuccess
      ).payload
      return {
        ...state,
        hasSearchDataBeenFetched: true,
        hasError: false,
        spellCorrectedSearchQuery: responseAutoCorrect,
        entities: responseEntities
      }
    case SearchActionTypes.FETCH_SEARCH_HISTORY_SUCCESS: {
      const { searchHistoryData } = (
        action as unknown as IFetchSearchHistorySuccess
      ).payload
      return {
        ...state,
        history: searchHistoryData
      }
    }
    case SearchActionTypes.STORE_HISTORY_ITEM: {
      const { searchQuery } = (action as IStoreHistory).payload
      if (state.history.find((historyQuery) => historyQuery === searchQuery)) {
        return state
      }

      return {
        ...state,
        history: [searchQuery, ...(state.history || [])].slice(0, 100)
      }
    }
    case SearchActionTypes.REMOVE_HISTORY_ITEM: {
      const { searchQuery } = (action as IStoreHistory).payload

      return {
        ...state,
        history: state.history.filter((query) => query !== searchQuery)
      }
    }
    default:
      return state
  }
}

export default reducers
