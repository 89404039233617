import React, {
  useCallback,
  useState,
  useEffect,
  useMemo,
  Dispatch
} from 'react'
import { connect } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'
import ReactPlaceholder from 'react-placeholder'
import { Box, Fade, Grid, ListItemIcon, Typography } from '@mui/material'
import 'Placeholder.css'
import { Store } from 'store'
import ResultMetaDataStore from 'store/ResultMetaData'
import ResultsEngagementsStore from 'store/ResultsEngagements'
import UserSettingsStore from 'store/UserSettings'
import SettingsStore from 'store/Settings'
import PageStay from 'components/HOC/PageStay'
import ErrorPage from 'components/contents/common/ErrorPage'
import TextResult from 'components/contents/results/TextResult'
import RightTopWidgetContainer from 'components/contents/resultpages/widgetscontainer/RightTopWidgetContainer'
import ResultsEngagementMobile from './ResultsEngagementsMobile'
import Pagination from '../Pagination'
import { trackException } from 'utils/tracking'
import { useReactRouterQueryStringInterface } from 'utils/useQueryState'
import { authorizeResultPage } from 'utils/authorization'
import createDOMPurify from 'dompurify'
import { dateFormatOptions, dataSourcesTabs } from 'constants/constants'
import SearchSuggestions from 'components/contents/common/SearchSuggestions'
import FeaturedResults from './FeaturedResults'
import LeftWidgetContainer from './widgetscontainer/LeftWidgetContainer'
import RightWidgetContainer from './widgetscontainer/RightWidgetContainer'
import { getStylesResults } from 'styles/contents/resultpages/Results'
import { useFilter } from 'utils/filters'
import { getStylesErrorPage } from 'styles/contents/common/ErrorPage'
import { IUserSetting } from 'utils/userSettings'
import { ErrorOutline } from '@mui/icons-material'
import { CognitiveMessage } from '../common/CognitiveMessage'
import { KPMGFindGlobalVariables } from 'store/KPMGFindGlobalVariables'
import { formatDisplayBody } from 'utils/intranet'
import { IResult } from 'store/ResultsEngagements/reducers'
import { useLocation } from 'react-router-dom'

export interface ResultsEngagementsProps {}

type AllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  ResultsEngagementsProps

function ResultsEngagements(props: AllProps) {
  const {
    featuredResults,
    results,
    resultsCombined,
    hasError,
    totalRowCount,
    lastRow,
    hasResultsBeenFetched,
    hasErrorStore,
    userSettings,
    deviceSettings,
    findConfiguration,
    setUserSettings,
    useCognitiveSearch
  } = props

  const [cognitiveSearchEnabled, setCognitiveSearchEnabled] =
    useState(useCognitiveSearch)
  const [showCognitiveMessage, setShowCognitiveMessage] = useState(
    userSettings.ShowCognitiveMessage
  )

  const DOMPurify = createDOMPurify(window)
  const resultClasses = getStylesResults()
  const errorClasses = getStylesErrorPage()
  const { getQueryString } = useReactRouterQueryStringInterface()
  const intl = useIntl()
  const queryStringFromUrl = getQueryString()
  const [currentPage] = useFilter('page', '1')
  const [searchQuery] = useFilter('q')
  const location = useLocation()

  useEffect(() => {
    KPMGFindGlobalVariables.setCurrentTab(dataSourcesTabs.engagements)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(
    () => {
      setCognitiveSearchEnabled(useCognitiveSearch)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [useCognitiveSearch]
  )

  useEffect(
    () => {
      setShowCognitiveMessage(userSettings.ShowCognitiveMessage)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userSettings.ShowCognitiveMessage]
  )

  const setup = useCallback(() => {
    try {
      authorizeResultPage(
        'Engagements',
        userSettings,
        deviceSettings,
        DOMPurify.sanitize(location && location.search ? location.search : '')
      )
    } catch (error) {
      trackException(
        'Error in fetching results in ResultsEngagements.tsx',
        error
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryStringFromUrl])

  useEffect(() => {
    setup()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryStringFromUrl])

  const returnAllItems = (type: string, results: IResult) => {
    if (!results || !results.queryResults || results.queryResults.length < 1) {
      return []
    }

    const items = []

    try {
      const firstResults =
        results.queryResults.length < 4 ? results.queryResults.length : 3

      if (type === 'first') {
        for (let i = 0; i < firstResults; i++) {
          const currentDate = results.queryResults[i].Date
            ? intl.formatDate(
                DOMPurify.sanitize(results.queryResults[i].Date),
                dateFormatOptions
              )
            : ''
          const currentBody =
            DOMPurify.sanitize(results.queryResults[i].Body) || ''
          const displayBody = formatDisplayBody(currentDate, currentBody, intl)
          items.push(
            <TextResult
              title={DOMPurify.sanitize(results.queryResults[i].Title)}
              body={displayBody}
              url={DOMPurify.sanitize(results.queryResults[i].Path)}
              key={i}
              index={i}
              siteTitle={DOMPurify.sanitize(results.queryResults[i].SiteTitle)}
              featured={false}
              showContextMenu={false}
              serverRedirectedURL={DOMPurify.sanitize(
                results.queryResults[i].Path
              )}
              searchQuery={DOMPurify.sanitize(searchQuery)}
              deviceSettings={deviceSettings}
              synonymsApplied={results.synonymsApplied}
            />
          )
        }
      } else {
        for (let i = firstResults; i < results.queryResults.length; i++) {
          const currentDate = results.queryResults[i].Date
            ? intl.formatDate(
                DOMPurify.sanitize(results.queryResults[i].Date),
                dateFormatOptions
              )
            : ''
          const currentBody =
            DOMPurify.sanitize(results.queryResults[i].Body) || ''
          const displayBody = formatDisplayBody(currentDate, currentBody, intl)
          items.push(
            <TextResult
              title={DOMPurify.sanitize(results.queryResults[i].Title)}
              body={displayBody}
              url={DOMPurify.sanitize(results.queryResults[i].Path)}
              key={i}
              index={i}
              siteTitle={DOMPurify.sanitize(results.queryResults[i].SiteTitle)}
              featured={false}
              showContextMenu={false}
              serverRedirectedURL={DOMPurify.sanitize(
                results.queryResults[i].Path
              )}
              searchQuery={DOMPurify.sanitize(searchQuery)}
              deviceSettings={deviceSettings}
              synonymsApplied={results.synonymsApplied}
            />
          )
        }
      }
    } catch (error) {
      trackException(
        'Error returning all items in ResultsEngagements.tsx',
        error
      )
    }
    return items
  }

  const firstItems = useMemo(
    () => returnAllItems('first', results),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [results, resultsCombined, intl]
  )

  const restItems = useMemo(
    () => returnAllItems('rest', results),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [results, resultsCombined, intl]
  )
  return (
    <Box>
      {!hasErrorStore && !!searchQuery && (
        <>
          {deviceSettings.renderMobile ? (
            <ResultsEngagementMobile
              {...props}
              deviceSettings={deviceSettings}
              userSettings={userSettings}
              findConfigCognitiveSearchEnabled={
                findConfiguration.CognitiveSearchEnabled
              }
              cognitiveSearchEnabled={cognitiveSearchEnabled}
              showCognitiveMessage={showCognitiveMessage}
              setShowCognitiveMessage={setShowCognitiveMessage}
              setUserSettings={setUserSettings}
              searchQuery={searchQuery}
            />
          ) : (
            <Box className={resultClasses.container}>
              <Box
                id={'con-resultvertical-01'}
                className={resultClasses.resultsContainer}
              >
                <SearchSuggestions
                  wildCardActive={true}
                  resultCount={totalRowCount}
                  resultsFetchend={hasResultsBeenFetched}
                />
                <ReactPlaceholder
                  ready={hasResultsBeenFetched ? true : false}
                  type="text"
                  rows={4}
                  showLoadingAnimation={true}
                  className={resultClasses.placeholder}
                >
                  <Fade
                    in={
                      hasResultsBeenFetched || Number(currentPage) > 1
                        ? true
                        : false
                    }
                    timeout={600}
                  >
                    <Box id={'con-resultvertical-section-01'}>
                      {hasError && (
                        <Box
                          id={'con-err-01'}
                          className={errorClasses.errorcontainer}
                        >
                          <Grid
                            container
                            direction="row"
                            alignItems="center"
                            wrap="nowrap"
                            className={errorClasses.gridContainer}
                          >
                            <Grid item>
                              <ListItemIcon>
                                <ErrorOutline className={errorClasses.icon} />
                              </ListItemIcon>
                            </Grid>
                            <Grid
                              item
                              className={errorClasses.messagesContainer}
                            >
                              <Typography
                                variant="body1"
                                className={errorClasses.errorHeader}
                              >
                                {hasError && (
                                  <FormattedMessage
                                    id="error_message_oi"
                                    defaultMessage="One Intranet results are currently not available."
                                  />
                                )}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      )}
                      {cognitiveSearchEnabled &&
                        showCognitiveMessage &&
                        findConfiguration.CognitiveSearchEnabled &&
                        results.synonymsApplied &&
                        results.synonymsApplied.length > 0 && (
                          <CognitiveMessage
                            synonymsApplied={results.synonymsApplied}
                            setShowCognitiveMessage={setShowCognitiveMessage}
                            userSettings={userSettings}
                            setUserSettings={setUserSettings}
                          />
                        )}
                      {results && totalRowCount > 0 && (
                        <FeaturedResults featuredResults={featuredResults} />
                      )}
                      {firstItems}
                    </Box>
                  </Fade>
                </ReactPlaceholder>
                {Number(currentPage) === 1 && (
                  <LeftWidgetContainer
                    resultCount={totalRowCount}
                    hasResultsBeenFetched={hasResultsBeenFetched}
                  />
                )}
                <ReactPlaceholder
                  ready={hasResultsBeenFetched ? true : false}
                  type="text"
                  rows={4}
                  showLoadingAnimation={true}
                  className={resultClasses.placeholder}
                >
                  <Fade
                    in={
                      hasResultsBeenFetched || Number(currentPage) > 1
                        ? true
                        : false
                    }
                    timeout={600}
                  >
                    <Box id={'con-resultvertical-section-02'}>{restItems}</Box>
                  </Fade>
                </ReactPlaceholder>
                <ReactPlaceholder
                  ready={hasResultsBeenFetched}
                  type="text"
                  rows={4}
                  showLoadingAnimation={true}
                  className={resultClasses.placeholder}
                >
                  <Fade in={hasResultsBeenFetched} timeout={600}>
                    <Box>
                      {totalRowCount != null && totalRowCount > 0 && (
                        <Pagination
                          {...props}
                          totalRecords={totalRowCount}
                          pageLimit={20}
                          pageNeighbours={4}
                          loadMore={false}
                          lastRow={lastRow}
                        />
                      )}
                    </Box>
                  </Fade>
                </ReactPlaceholder>
              </Box>
              <Box className={resultClasses.advertisementContainer}>
                <RightTopWidgetContainer
                  searchQuery={searchQuery}
                  scope={'engagements'}
                />
                <RightWidgetContainer />
              </Box>
            </Box>
          )}
        </>
      )}
      {hasErrorStore && <ErrorPage dataSource="engagements" />}
    </Box>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    featuredResults:
      ResultsEngagementsStore.selectors.getFeaturedResults(state),
    results: ResultsEngagementsStore.selectors.getResults(state),
    resultsCombined:
      ResultsEngagementsStore.selectors.getResultsCombined(state),
    totalRowCount: ResultsEngagementsStore.selectors.getTotalRowCount(state),
    lastRow: ResultsEngagementsStore.selectors.getLastRow(state),
    hasError: ResultsEngagementsStore.selectors.getHasError(state),
    executionTime: ResultsEngagementsStore.selectors.getExecutionTime(state),
    hasResultsBeenFetched:
      ResultsEngagementsStore.selectors.hasResultsBeenFetched(state),
    hasErrorStore: ResultMetaDataStore.selectors.getDataSourceHasError(
      state,
      'engagements'
    ),
    userSettings: UserSettingsStore.selectors.getUserSettings(state),
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state),
    findConfiguration: SettingsStore.selectors.getFindConfiguration(state),
    useCognitiveSearch: SettingsStore.selectors.getUseCognitiveSearch(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    setUserSettings: (userSettings: IUserSetting) =>
      dispatch(UserSettingsStore.actions.upSertUserSettings(userSettings))
  }
}

export default PageStay(
  connect(mapStateToProps, mapDispatchToProps)(ResultsEngagements)
)
