import React from 'react'
import * as Config from 'config'
import { ResultRightWidgetGenerator } from './interfaces'
import RightKCIPWidget from 'components/contents/resultpages/widgets/RightKCIPWidget'
import { trackException } from 'utils/tracking'
import { stripHtml } from 'utils/string'
import { FetchWithCache } from 'utils/api'
import { IDeviceSetting } from 'utils/deviceSettings'
import { IUserSetting } from 'utils/userSettings'
import { IFindConfiguration } from 'store/Settings/reducers'
import { IKCIPResult } from 'components/models/KCIPResult'
import { ISynonymsApplied } from 'components/models/SynonymsApplied'
import { removeStopWordsFromQuery } from 'utils/oneIntranet'
import { renewAuthorizationToken } from 'utils/token'
import { IAADState } from 'store/Auth/reducers'

export const rightKCIPWidgetGenerator: ResultRightWidgetGenerator = {
  fetch(
    aadInfo: IAADState,
    searchQuery: string,
    deviceSettings: IDeviceSetting,
    userSettings: IUserSetting,
    findConfiguration: IFindConfiguration,
    useCognitiveSearch: boolean
  ): Promise<{
    queryResults: IKCIPResult[]
    synonymsApplied: ISynonymsApplied[]
  }> {
    return new Promise<{
      queryResults: IKCIPResult[]
      synonymsApplied: ISynonymsApplied[]
    }>((resolve, reject) => {
      const fetchKCIP = async () => {
        if (
          !searchQuery ||
          searchQuery === '' ||
          searchQuery === '""' ||
          searchQuery.length < 4
        ) {
          trackException(
            'Error in fetching KCIP right widget in rightKCIPWidget.tsx',
            new Error('Empty query. Please specify a valid query text')
          )
          reject()
          return
        }

        // Get and check authentication token
        const esToken = await renewAuthorizationToken(
          aadInfo.accessToken,
          aadInfo.instance,
          aadInfo.accounts
        )

        let _searchQuery = searchQuery
        if (
          useCognitiveSearch &&
          findConfiguration.CognitiveSearchEnabled &&
          esToken !== ''
        ) {
          let searchQueryLanguage = ''
          const detectLanguageApiUrl = `${
            Config.APIM_BASE_URL
          }searchapi/detectlanguage?searchQuery=${encodeURIComponent(
            searchQuery
          )}`

          const languageResponse = await FetchWithCache(detectLanguageApiUrl, {
            method: 'POST',
            headers: {
              accept: 'application/json',
              'content-type': 'application/json',
              'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
              Authorization: `Bearer ${esToken}`
            }
          })
          if (
            languageResponse &&
            !languageResponse.hasError &&
            languageResponse.responseJSON
          ) {
            const languageJSON = languageResponse.responseJSON
            if (languageJSON && languageJSON.responseCode === 200) {
              searchQueryLanguage = languageJSON.language
            }
          }

          _searchQuery = removeStopWordsFromQuery(
            searchQuery,
            searchQueryLanguage,
            useCognitiveSearch,
            findConfiguration.CognitiveSearchEnabled
          )
        }

        let apiUrl = `${
          Config.APIM_BASE_URL
        }kcipapi/getrepossearch?name=${encodeURIComponent(_searchQuery)}`

        if (useCognitiveSearch && findConfiguration.CognitiveSearchEnabled) {
          apiUrl += '&cognitiveEnabled=true'
        }

        const response = await FetchWithCache(apiUrl, {
          method: 'GET',
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
            Authorization: `Bearer ${esToken}`
          }
        })

        let results
        if (!response || response.hasError || !response.responseJSON) {
          trackException(
            'Error in fetching KCIP right widget in rightKCIPWidget.tsx',
            new Error(
              response && response.errorResponse
                ? response.errorResponse.responseCode +
                  ':' +
                  response.errorResponse.message
                : ''
            )
          )
          reject()
          return
        } else {
          results = response.responseJSON
        }

        if (!results || !results.KCIPRepos || results.KCIPRepos.length < 3) {
          reject()
          return
        }

        const kcipResults: IKCIPResult[] = []

        results.KCIPRepos.forEach((i: any) => {
          const kcipResult: IKCIPResult = {
            name: i.Name ? i.Name : '',
            description: i.Description
              ? stripHtml(i.Description.replace('... ', ''))
              : '',
            organization: i.Organization
              ? stripHtml(i.Organization.replace('... ', ''))
              : '',
            url: i.LinkDetail
              ? findConfiguration &&
                findConfiguration.SeeAllLinks &&
                findConfiguration.SeeAllLinks['kcip']
                ? findConfiguration.SeeAllLinks['kcip'] + i.LinkDetail
                : 'https://portal.code.kpmg.com' + i.LinkDetail
              : '',
            update: i.LastUpdated ? i.LastUpdated : ''
          }

          kcipResults.push(kcipResult)
        })

        resolve({
          queryResults: kcipResults,
          synonymsApplied: results.SynonymsApplied
        })
      }

      try {
        fetchKCIP()
      } catch (error) {
        trackException('Error in fetching news in rightKCIPWidget.tsx', error)
        reject()
      }
    })
  },
  generate(
    resultsRaw: {
      queryResults: IKCIPResult[]
      synonymsApplied: ISynonymsApplied[]
    },
    searchQuery: string
  ): Promise<React.ReactElement> {
    return new Promise<React.ReactElement>((resolve, reject) => {
      const generateKCIP = () => {
        const results = resultsRaw.queryResults
        if (!results) {
          reject()
          return
        }

        const limit = 10
        if (results.length > 2) {
          let resultsList = results

          if (results.length > limit) {
            resultsList = results.slice(0, limit)
          }

          let hasContent = false
          for (let i = 0; i < resultsList.length; i++) {
            if (resultsList[i].name !== '') {
              hasContent = true
              break
            }
          }
          if (!hasContent) {
            reject()
            return
          }

          resolve(
            <RightKCIPWidget
              searchQuery={searchQuery}
              results={resultsList}
              synonymsApplied={resultsRaw.synonymsApplied}
            />
          )
        } else {
          reject()
          return
        }
      }

      try {
        generateKCIP()
      } catch (error) {
        trackException(
          'Error in generating KCIP right widget in rightKCIPWidget.tsx',
          error
        )
        reject()
      }
    })
  }
}
