export type PopupResult = {
  id: string
  title: string
  image: string
  content: string
  language: string
  button_link: string
  button_text: string
  countries: string[]
  functions: string[]
  sources: string[]
  display: PopupDisplayType
  dateStart: string | null
  dateEnd: string | null
  intervalHours: string | null
  scope?: 'OI' | 'KPMGFind' | 'All'
}

export type PopupDisplayType =
  | 'onEverySession'
  | 'oncePerInterval'
  | 'oncePerUser'

export type PopupUserSettings = {
  id: string
  lastSeen: Date
  validUntil: string | null
  display: PopupDisplayType
}

export const PopupDisplayTypes = {
  onEverySession: 'onEverySession',
  oncePerInterval: 'oncePerInterval',
  oncePerUser: 'oncePerUser'
}
