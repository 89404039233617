import { FeaturedResult } from 'components/models/FeaturedResult'
import { Store } from '..'
import { ISynonymsApplied } from 'components/models/SynonymsApplied'

const selectors = {
  getFeaturedResults: (state: Store): FeaturedResult[] =>
    state.resultsMpp.featuredResults,
  getSynonymsApplied: (state: Store): ISynonymsApplied[] =>
    state.resultsMpp.synonymsApplied,
  getResults: (state: Store): any => state.resultsMpp.results,
  getResultsCombined: (state: Store): any => state.resultsMpp.resultsCombined,
  getResultsCombinedQuery: (state: Store): any =>
    state.resultsMpp.resultsCombinedQuery,
  hasResultsBeenFetched: (state: Store): any =>
    state.resultsMpp.hasResultsBeenFetched,
  getResultCount: (state: Store): any => state.resultsMpp.resultCount,
  getExecutionTime: (state: Store): any => state.resultsMpp.executionTime,
  getCurrentPage: (state: Store): any => state.resultsMpp.currentPage,
  getFilters: (state: Store): any => state.resultsMpp.filters
}

export default selectors
