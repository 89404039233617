import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesLeftConfluenceFinlandWidget(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles(): any {
  return makeStyles((theme: Theme) => ({
    title: {
      fontSize: '18px',
      lineHeight: '20px',
      paddingTop: '1px',
      marginBottom: '0.35em',
      color: '#424242'
    }
  }))
}
