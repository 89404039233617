import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesLeftWidget(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles(): any {
  return makeStyles((theme: Theme) => ({
    container: {
      overflow: 'visible !important',
      [`${theme.breakpoints.down(1024)}`]: {
        backgroundColor: '#fff',
        margin: '0 0 10px 0',
        boxShadow: '0 1px 6px rgba(32, 33, 36, 0.28)',
        borderRadius: '4px',
        padding: '10px',
        overflow: 'hidden'
      }
    },

    cardContainer: {
      overflow: 'visible !important',
      marginLeft: 0,
      display: 'flex',
      width: '100%',
      marginBottom: '16px',
      scrollbarWidth: 'none',
      boxSizing: 'border-box',
      '&::-webkit-scrollbar': {
        display: 'none'
      },
      [`${theme.breakpoints.down(1024)} and (orientation: portrait)`]: {
        marginLeft: '-26px',
        width: 'calc(100% + 36px)',
        paddingLeft: '16px',
        marginBottom: '0px'
      }
    },
    cardLink: {
      textDecoration: 'none'
    },

    card: {
      outline: 'solid 1px #dfe1e5',
      borderRadius: '4px',
      display: 'flex',
      flexDirection: 'column',
      width: 200,
      height: 250,
      flexGrow: 0,
      marginRight: 12,
      marginTop: 8,
      marginBottom: 8,
      boxShadow: 'none',
      cursor: 'pointer',
      [`${theme.breakpoints.down(1024)}`]: {
        marginRight: 0,
        marginLeft: '10px'
      }
    },

    link: {
      '&:hover': {
        textDecoration: 'underline'
      },
      [`${theme.breakpoints.down(1024)}`]: {
        fontSize: '18px',
        lineHeight: '20px',
        paddingTop: '1px',
        marginBottom: '1px',
        color: '#005eb8'
      }
    },

    media: {
      flex: '0 0 140px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat'
    },

    spacer: {
      flex: 1
    },

    icon: {
      height: 44,
      width: 44,
      color: '#616161'
    },

    slider: {
      overflow: 'visible !important',
      width: '100%',
      '& .slick-list': {
        paddingLeft: '1px'
      }
    },

    showallContainer: {
      display: 'inline-block',
      width: 200,
      height: 250,
      marginRight: 12,
      marginTop: 8,
      marginBottom: 8,
      cursor: 'pointer'
    },

    showallContent: {
      minWidth: 200,
      height: '100%',
      flexDirection: 'column',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },

    iconButton: {
      boxDhadow: 'none',
      border: '1px solid #AECBFA',
      backgroundColor: '#fff',
      color: '#1A73E8',
      width: 56,
      height: 56
    },

    showallMessage: {
      color: 'rgba(0,0,0,.54)',
      fontSize: '14px',
      marginTop: '12px',
      textAlign: 'center',
      wordWrap: 'break-word'
    },

    mobileScrollContainer: {
      width: '100%',
      overflowX: 'auto',
      display: 'flex',
      flexDirection: 'row'
    },
    vivaEngagePosted: {
      fontSize: '12px',
      fontStyle: 'italic',
      marginTop: 20
    },
    widgetSubTitle: {
      color: 'rgba(0, 0, 0, 0.54)',
      textAlign: 'left',
      lineHeight: '20px',
      fontWeight: 'normal',
      font: 'Arial,Helvetica Neue,Helvetica,sans-serif-light',
      fontSize: 12
    },
    profilepicWrapper: {
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      padding: '0px',
      height: '100%',
      marginInlineEnd: '5px'
    }
  }))
}
