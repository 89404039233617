import { FeaturedResult } from 'components/models/FeaturedResult'
import { Store } from '..'
import { IStatisticResult } from 'components/models/StatisticResult'

const selectors = {
  getFeaturedResults: (state: Store): FeaturedResult[] =>
    state.resultsStatistics.featuredResults,
  getResults: (state: Store): IStatisticResult[] =>
    state.resultsStatistics.results,
  getResultsCombined: (state: Store): IStatisticResult[] =>
    state.resultsStatistics.resultsCombined,
  getResultsCombinedQuery: (state: Store): string =>
    state.resultsStatistics.resultsCombinedQuery,
  hasResultsBeenFetched: (state: Store): boolean =>
    state.resultsStatistics.hasResultsBeenFetched,
  getResultCount: (state: Store): number => state.resultsStatistics.resultCount,
  getExecutionTime: (state: Store): number =>
    state.resultsStatistics.executionTime,
  getCurrentPage: (state: Store): number => state.resultsStatistics.currentPage,
  getHasError: (state: Store): boolean => state.resultsStatistics.hasError,
  getLatestQuery: (state: Store): string =>
    state.resultsStatistics.resultsCombinedQuery
}

export default selectors
