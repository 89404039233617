import { FeaturedResult } from 'components/models/FeaturedResult'
import { Reducer } from 'redux'
import {
  ResultsAlexActions,
  ResultsAlexActionTypes,
  IFetchSuccess,
  IFetchSuccessCombined
} from './actions'
import { IAlexResult } from 'components/models/AlexResult'

export interface IResultsAlexResults {
  hasResultsBeenFetched: boolean
  results: Array<IAlexResult>
  resultsCombined: Array<IAlexResult>
  resultsCombinedQuery: string
  resultCount: number
  executionTime: number
  currentPage: number
  hasError: boolean
}

export interface IResultsAlexStore {
  manuals: IResultsAlexResults
  featuredResults: FeaturedResult[]
}

const singleInitialResult: IResultsAlexResults = {
  hasResultsBeenFetched: false,
  resultCount: -1,
  currentPage: 1,
  results: [],
  resultsCombined: [],
  resultsCombinedQuery: '',
  executionTime: 0,
  hasError: false
}

const initialState: IResultsAlexStore = {
  manuals: singleInitialResult,
  featuredResults: []
}

const reducers: Reducer<IResultsAlexStore, ResultsAlexActions> = (
  state: IResultsAlexStore = initialState,
  action:
    | { type: ResultsAlexActionTypes.INITIALIZE_RESULTS_ALL; payload: any }
    | ResultsAlexActions
) => {
  switch (action.type) {
    case ResultsAlexActionTypes.INITIALIZE_RESULTS_ALL:
      if (!(action as any).payload) {
        return initialState
      }
      const { resultsAlex } = (action as any).payload
      return {
        ...state,
        manuals: {
          ...resultsAlex.manuals,
          currentPage: resultsAlex.manuals.currentPage || 1
        }
      }
    case ResultsAlexActionTypes.FETCH_REQUEST:
      const { resetCombined, searchQuery } = (action as any).payload
      return {
        ...state,
        manuals: {
          ...state.manuals,
          hasResultsBeenFetched: false,
          executionTime: 0,
          resultCount: -1,
          results: [],
          resultsCombined: resetCombined ? [] : state.manuals.resultsCombined,
          resultsCombinedQuery: searchQuery
        }
      }

    case ResultsAlexActionTypes.FETCH_FAILURE:
      return {
        ...state,
        manuals: {
          ...state.manuals,
          hasResultsBeenFetched: true,
          resultCount: 0
        }
      }

    case ResultsAlexActionTypes.FETCH_SUCCESS:
      const { response, featuredResults } = (action as IFetchSuccess).payload

      return {
        ...state,
        manuals: {
          ...state.manuals,
          hasResultsBeenFetched: true,
          executionTime: response.executionTime,
          resultCount: response.resultCount,
          results: response.results,
          currentPage: response.currentPage,
          hasError: response.hasError
        },
        featuredResults:
          response.currentPage === 1 ? featuredResults : state.featuredResults
      }

    case ResultsAlexActionTypes.FETCH_SUCCESS_COMBINED:
      const { response: responseCombined } = (action as IFetchSuccessCombined)
        .payload

      return {
        ...state,
        manuals: {
          ...state.manuals,
          resultsCombined: responseCombined.results,
          hasError: responseCombined.hasError
        }
      }

    default:
      return state
  }
}

export default reducers
