import React from 'react'
import { ResultLeftWidgetGenerator } from './interfaces'
import { trackException } from 'utils/tracking'
import OfficeLocationWidget, {
  OfficeLocationResult
} from 'components/contents/resultpages/widgets/OfficeLocationWidget'
import {
  performSearch,
  transformOfficeLocation
} from 'utils/widgets/officeLocationWidget'
import { FetchWithCacheAndTracking } from 'utils/api'
import * as Config from 'config'
import { IDeviceSetting } from 'utils/deviceSettings'
import { IUserSetting } from 'utils/userSettings'
import { IFindConfiguration } from 'store/Settings/reducers'
import { IAADState } from 'store/Auth/reducers'
import { renewAuthorizationToken } from 'utils/token'

export const leftMobileOfficeLocationWidgetGenerator: ResultLeftWidgetGenerator =
  {
    fetch(
      aadInfo: IAADState,
      searchQuery: string,
      deviceSettings: IDeviceSetting,
      userSettings: IUserSetting,
      findConfiguration: IFindConfiguration
    ): Promise<OfficeLocationResult> {
      return new Promise<OfficeLocationResult>((resolve, reject) => {
        const fetchOfficeLocation = async () => {
          try {
            if (!searchQuery || searchQuery === '' || searchQuery === '""') {
              return null
            }

            const apiUrlOfficeLocations =
              `${Config.APIM_BASE_URL}searchapi/getofficelocations?` +
              `query=${encodeURIComponent(searchQuery)}`

            // Get and check authentication token
            const esToken = await renewAuthorizationToken(
              aadInfo.accessToken,
              aadInfo.instance,
              aadInfo.accounts
            )

            const response = await FetchWithCacheAndTracking(
              apiUrlOfficeLocations,
              {
                method: 'GET',
                headers: {
                  accept: 'application/json',
                  'content-type': 'application/json',
                  'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
                  Authorization: `Bearer ${esToken}`
                }
              }
            )

            if (response && !response.hasError && response.responseJSON) {
              const officeLocationResult: OfficeLocationResult = performSearch(
                searchQuery,
                response.responseJSON
              )

              if (!officeLocationResult || !officeLocationResult.name) {
                return reject('Valid')
              }

              resolve(officeLocationResult)
            }

            return reject('Valid')
          } catch (rejectedValue) {
            return reject('Valid')
          }
        }

        try {
          fetchOfficeLocation()
        } catch (error) {
          trackException(
            'Error in fetching mobile Officelocation widget.',
            error
          )
          reject()
        }
      })
    },
    generate(results: any, searchQuery: string): Promise<React.ReactElement> {
      return new Promise<React.ReactElement>((resolve, reject) => {
        const generateNews = () => {
          resolve(
            <OfficeLocationWidget
              searchQuery={searchQuery}
              results={transformOfficeLocation(results)}
            />
          )
        }

        try {
          generateNews()
        } catch (error) {
          trackException(
            'Error in generating mobile Officelocation widget.',
            error
          )
          reject()
        }
      })
    }
  }
