import { Action, AnyAction } from 'redux'
import { Store } from '..'
import AuthStore from '../Auth'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { trackException } from 'utils/tracking'
import * as Config from 'config'
import { trackEvents } from 'utils/tracking'
import { IApiStatus } from 'utils/apiStatus'
import { IDataSource } from 'constants/datasourcesConfiguration'
import { IFindConfiguration } from './reducers'
import { renewAuthorizationToken } from 'utils/token'

export enum SettingsActionTypes {
  INITIALIZE_SETTINGS = 'persist/REHYDRATE',
  SET_DEVICE_SETTINGS = 'settings/SET_DEVICE_SETTINGS',
  SET_IS_LANDSCAPE = 'settings/SET_IS_LANDSCAPE',
  SET_RENDER_MOBILE = 'settings/SET_RENDER_MOBILE',
  SET_CURRENT_DATASOURCES = 'settings/SET_CURRENT_DATASOURCES',
  SET_SHOW_SETTINGS = 'settings/SET_SHOW_SETTINGS',
  FETCH_API_STATUS_SUCCESS = 'settings/FETCH_API_STATUS_SUCCESS',
  FETCH_DATASOURCE_SETTINGS_REQUEST = 'settings/FETCH_DATASOURCE_SETTINGS_REQUEST',
  FETCH_DATASOURCE_SETTINGS_FAILURE = 'settings/FETCH_DATASOURCE_SETTINGS_FAILED',
  FETCH_DATASOURCE_SETTINGS_CRITICAL_ERROR = 'settings/FETCH_DATASOURCE_SETTINGS_CRITICAL_ERROR',
  FETCH_DATASOURCE_SETTINGS_SUCCESS = 'settings/FETCH_DATASOURCE_SETTINGS_SUCCESS',
  FETCH_FIND_CONFIGURATION_REQUEST = 'settings/FETCH_FIND_CONFIGURATION_REQUEST',
  FETCH_FIND_CONFIGURATION_FAILURE = 'settings/FETCH_FIND_CONFIGURATION_FAILURE',
  FETCH_FIND_CONFIGURATION_NOT_FOUND = 'settings/FETCH_FIND_CONFIGURATION_NOT_FOUND',
  FETCH_FIND_CONFIGURATION_SUCCESS = 'settings/FETCH_FIND_CONFIGURATION_SUCCESS',
  FETCH_FIND_CONFIGURATION_CRITICAL_ERROR = 'settings/FETCH_FIND_CONFIGURATION_CRITICAL_ERROR',
  SET_USE_COGNITIVESEARCH = 'SET_USE_COGNITIVESEARCH',
  SET_INTERNAL_BROADCAST = 'settings/SET_INTERNAL_BROADCAST'
}

export interface ISetDeviceSettings extends Action<SettingsActionTypes> {
  payload: {
    isMobile: boolean
  }
}

export interface ISetIsLandscape extends Action<SettingsActionTypes> {
  payload: {
    isLandscape: boolean
  }
}

export interface ISetRenderMobile extends Action<SettingsActionTypes> {
  payload: {
    renderMobile: boolean
  }
}

export interface ISetCurrentDataSources extends Action<SettingsActionTypes> {
  payload: {
    currentDataSources: IDataSource[]
  }
}

export interface ISetShowSettings extends Action<SettingsActionTypes> {
  payload: {
    showSettings: boolean
  }
}

export interface IFetchApiStatusSuccess extends Action<SettingsActionTypes> {
  payload: {
    apiStatus: Array<IApiStatus>
  }
}

export type IFetchDataSourceSettingsRequest = Action<SettingsActionTypes>

export interface IFetchDataSourceSettingsSuccess
  extends Action<SettingsActionTypes> {
  payload: {
    datasourceSettings: IDataSource[]
  }
}

export type IFetchDataSourceSettingsFailure = Action<SettingsActionTypes>
export type IFetchDataDourceSettingsCriticalError = Action<SettingsActionTypes>

export const fetchApiStatusSuccess = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  apiStatus: Array<IApiStatus>
): IFetchApiStatusSuccess => ({
  type: SettingsActionTypes.FETCH_API_STATUS_SUCCESS,
  payload: { apiStatus }
})

export const fetchAPIStatus = (): ThunkAction<
  Promise<void>,
  Store,
  // eslint-disable-next-line @typescript-eslint/ban-types
  {},
  AnyAction
> => {
  return async (
    // eslint-disable-next-line @typescript-eslint/ban-types
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => Store
  ) => {
    const apiUrl = `${Config.APIM_BASE_URL}statusapi/getstatusapi`

    // Get and check authentication token
    const aadInfo = AuthStore.selectors.getAADInfo(getState())
    const esToken = await renewAuthorizationToken(
      aadInfo.accessToken,
      aadInfo.instance,
      aadInfo.accounts
    )
    if (esToken !== aadInfo.accessToken) {
      AuthStore.actions.setAuthToken(esToken)
    }
    if (esToken === '') {
      return
    }

    const data = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
        Authorization: `Bearer ${esToken}`
      }
    })

    if (data.status !== 200) {
      trackException(
        'Error in fetching api status action',
        new Error(`Reponse Code: ${data.status}`)
      )

      return
    }

    const result = await data.json()

    dispatch(fetchApiStatusSuccess(result))
  }
}

export const setDeviceSettings = (isMobile: boolean): ISetDeviceSettings => ({
  type: SettingsActionTypes.SET_DEVICE_SETTINGS,
  payload: {
    isMobile: isMobile
  }
})

export const setIsLandscape = (isLandscape: boolean): ISetIsLandscape => ({
  type: SettingsActionTypes.SET_IS_LANDSCAPE,
  payload: { isLandscape: isLandscape }
})

export const setRenderMobile = (renderMobile: boolean): ISetRenderMobile => ({
  type: SettingsActionTypes.SET_RENDER_MOBILE,
  payload: { renderMobile: renderMobile }
})

export const setCurrentDataSources = (
  currentDataSources: IDataSource[]
): ISetCurrentDataSources => ({
  type: SettingsActionTypes.SET_CURRENT_DATASOURCES,
  payload: { currentDataSources: currentDataSources }
})

export const setShowSettings = (showSettings: boolean): ISetShowSettings => ({
  type: SettingsActionTypes.SET_SHOW_SETTINGS,
  payload: { showSettings: showSettings }
})

export const fetchDatasourceSettingsRequest =
  (): IFetchDataSourceSettingsRequest => ({
    type: SettingsActionTypes.FETCH_DATASOURCE_SETTINGS_REQUEST
  })

export const fetchDatasourceSettingsSuccess = (
  datasourceSettings: Array<IDataSource>
): IFetchDataSourceSettingsSuccess => ({
  type: SettingsActionTypes.FETCH_DATASOURCE_SETTINGS_SUCCESS,
  payload: { datasourceSettings }
})

export const fetchDatasourceSettingsFailure =
  (): IFetchDataSourceSettingsFailure => ({
    type: SettingsActionTypes.FETCH_DATASOURCE_SETTINGS_FAILURE
  })

export const fetchDatasourceSettingsCriticalError =
  (): IFetchFindConfigurationCriticalError => ({
    type: SettingsActionTypes.FETCH_FIND_CONFIGURATION_CRITICAL_ERROR
  })

export const fetchDatasourceSettings = (): ThunkAction<
  Promise<void>,
  Store,
  unknown,
  AnyAction
> => {
  return async (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => Store
  ) => {
    dispatch(fetchDatasourceSettingsRequest())
    try {
      trackEvents('getDatasourceSettings', {})

      const apiUrl = `${Config.APIM_BASE_URL}datasourcesettingsapi/getdatasourcesettings?scope=KPMGFind`

      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        return
      }

      const data = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${esToken}`
        }
      })
      if (data.status !== 200) {
        trackException(
          'Error in fetching find configuration',
          new Error('Error in data object after fetching find configuration')
        )
        if (data.status === 502) {
          dispatch(fetchDatasourceSettingsCriticalError())
          return
        } else {
          dispatch(fetchDatasourceSettingsFailure())
        }
      }

      const result = await data.json()

      if (!result || !result.Documents) {
        dispatch(fetchDatasourceSettingsFailure())
        return
      }
      const datasourceSettings: IDataSource[] = result.Documents.map(
        (document: any) => {
          return {
            name: document.name,
            active: document.active,
            enabledForChat: document.enabledForChat
              ? document.enabledForChat
              : false,
            allowedCountries: document.allowedCountries
              ? document.allowedCountries.split('|')
              : null
          }
        }
      )
      dispatch(fetchDatasourceSettingsSuccess(datasourceSettings))
    } catch (error) {
      dispatch(fetchDatasourceSettingsFailure())
    }
  }
}

export type IFetchFindConfigurationRequest = Action<SettingsActionTypes>
export type IFetchFindConfigurationFailure = Action<SettingsActionTypes>
export type IFetchFindConfigurationNotFound = Action<SettingsActionTypes>
export type IFetchFindConfigurationCriticalError = Action<SettingsActionTypes>

export interface IFetchFindConfigurationSuccess
  extends Action<SettingsActionTypes> {
  payload: {
    findConfigurationResult: IFindConfiguration
  }
}

export const fetchFindConfigurationRequest =
  (): IFetchFindConfigurationRequest => ({
    type: SettingsActionTypes.FETCH_FIND_CONFIGURATION_REQUEST
  })

export const fetchFindConfigurationFailure =
  (): IFetchFindConfigurationFailure => ({
    type: SettingsActionTypes.FETCH_FIND_CONFIGURATION_FAILURE
  })

export const fetchFindConfigurationCriticalError =
  (): IFetchFindConfigurationCriticalError => ({
    type: SettingsActionTypes.FETCH_FIND_CONFIGURATION_CRITICAL_ERROR
  })

export const fetchFindConfigurationNotFound =
  (): IFetchFindConfigurationNotFound => ({
    type: SettingsActionTypes.FETCH_FIND_CONFIGURATION_NOT_FOUND
  })

export const fetchFindConfigurationSuccess = (
  findConfigurationResult: IFindConfiguration
): IFetchFindConfigurationSuccess => ({
  type: SettingsActionTypes.FETCH_FIND_CONFIGURATION_SUCCESS,
  payload: { findConfigurationResult }
})

export interface ISetUseCognitiveSearch extends Action<SettingsActionTypes> {
  payload: {
    useCognitiveSearch: boolean
    useEntitiyRecognition: boolean
  }
}

export const setUseCognitiveSearch = (
  useCognitiveSearch: boolean,
  useEntitiyRecognition: boolean
): ISetUseCognitiveSearch => ({
  type: SettingsActionTypes.SET_USE_COGNITIVESEARCH,
  payload: { useCognitiveSearch, useEntitiyRecognition }
})

export interface ISetInternalBroadcast extends Action<SettingsActionTypes> {
  payload: {
    broadcast: string[]
  }
}
export const setInternalBroadcast = (
  broadcast: string[]
): ISetInternalBroadcast => ({
  type: SettingsActionTypes.SET_INTERNAL_BROADCAST,
  payload: { broadcast: broadcast }
})

// use an OR type here if there more than one actions, e.g. ISetEnabledFilter |INewFilterAction | ...
export type SettingsActions =
  | ISetDeviceSettings
  | ISetIsLandscape
  | ISetRenderMobile
  | ISetCurrentDataSources
  | ISetShowSettings
  | IFetchDataSourceSettingsSuccess
  | IFetchDataDourceSettingsCriticalError
  | IFetchApiStatusSuccess
  | IFetchFindConfigurationRequest
  | IFetchFindConfigurationFailure
  | IFetchFindConfigurationNotFound
  | IFetchFindConfigurationSuccess
  | IFetchFindConfigurationCriticalError
  | ISetUseCognitiveSearch
  | ISetInternalBroadcast
