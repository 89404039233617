import { combineReducers } from 'redux'
import Auth from './Auth'
import { IAuthStore } from './Auth/reducers'
import Filters from './Filters'
import { IFilterStore } from './Filters/reducers'
import ResultsOneIntranet from './ResultsOneIntranet'
import { IResultsOneIntranetStore } from './ResultsOneIntranet/reducers'
import ResultsEngagements from './ResultsEngagements'
import { IResultsEngagementsStore } from './ResultsEngagements/reducers'
import ResultsPeople from './ResultsPeople'
import { IResultsPeopleStore } from './ResultsPeople/reducers'
import ResultsSource from './ResultsSource'
import { IResultsSourceStore } from './ResultsSource/reducers'
import ResultsNews from './ResultsNews'
import { IResultsNewsStore } from './ResultsNews/reducers'
import ResultsStatistics from './ResultsStatistics'
import { IResultsStatisticsStore } from './ResultsStatistics/reducers'
import ResultsVideos from './ResultsVideos'
import { IResultsVideosStore } from './ResultsVideos/reducers'
import ResultsOffice365 from './ResultsOffice365'
import { IResultsOffice365Store } from './ResultsOffice365/reducers'
import ResultsKnowledgeExchange from './ResultsKnowledgeExchange'
import { IResultsKnowledgeExchangeStore } from './ResultsKnowledgeExchange/reducers'
import ResultsMarketResearch from './ResultsMarketResearch'
import { IResultsMarketResearchStore } from './ResultsMarketResearch/reducers'
import ResultsResearch from './ResultsResearch'
import { IResultsResearchStore } from './ResultsResearch/reducers'
import ResultsAlex from './ResultsAlex'
import { IResultsAlexStore } from './ResultsAlex/reducers'
import ResultsKlardenker from './ResultsKlardenker'
import { IResultsKlardenkerStore } from './ResultsKlardenker/reducers'
import ResultsEins from './ResultsEins'
import { IResultsEinsStore } from './ResultsEins/reducers'
import ResultsProjectSpaces from './ResultsProjectSpaces'
import { IResultsProjectSpacesStore } from './ResultsProjectSpaces/reducers'
import ResultsAEM from './ResultsAEM'
import { IResultsAEMStore } from './ResultsAEM/reducers'
import ResultsContentFinder from './ResultsContentFinder'
import { IResultsContentFinderStore } from './ResultsContentFinder/reducers'
import Search from './Search'
import { ISearchStore } from './Search/reducers'
import StartPage from './StartPage'
import { IStartPageStore } from './StartPage/reducers'
import Settings from './Settings'
import { ISettingsStore } from './Settings/reducers'
import UserSettings from './UserSettings'
import { IUserSettingsStore } from './UserSettings/reducers'
import Results from './Results'
import { IResultsStore } from './Results/reducers'
import Translations from './Translations'
import { ITranslationsStore } from './Translations/reducers'
import { ISearchTop10Store } from './SearchTop10/reducers'
import SearchTop10 from './SearchTop10'
import { IResultMetaDataStore } from './ResultMetaData/reducers'
import ResultMetaData from './ResultMetaData'
import { IWidgetStore } from './Widgets/reducers'
import Widgets from './Widgets'
import ResultsMpp from './ResultsMpp'
import { IResultsMppStore } from './ResultsMpp/reducers'
import { IContactUsStore } from './ContactUs/reducers'
import ContactUs from './ContactUs'
import { IResultsIbfdStore } from './ResultsIbfd/reducers'
import ResultsIbfd from './ResultsIbfd'
import ResultsImages from './ResultsImages'
import { IResultsImagesStore } from './ResultsImages/reducers'
import { IResultsKpmgUSStore } from './ResultsKpmgUS/reducers'
import ResultsKpmgUS from './ResultsKpmgUS'
import ResultsChat from './ResultsChat'
import { IResultsChatStore } from './ResultsChat/reducers'
import MSAnswers from './MSAnswers'
import { IMSAnswersStore } from './MSAnswers/reducers'

// Use an OR type here if there more than one actions, e.g. IAuthStore |INewStore | ...
export type Store = {
  auth: IAuthStore
  filters: IFilterStore
  resultsOneIntranet: IResultsOneIntranetStore
  resultsEngagements: IResultsEngagementsStore
  resultsPeople: IResultsPeopleStore
  resultsSource: IResultsSourceStore
  resultsNews: IResultsNewsStore
  resultsStatistics: IResultsStatisticsStore
  resultsVideos: IResultsVideosStore
  resultsOffice365: IResultsOffice365Store
  resultsKnowledgeExchange: IResultsKnowledgeExchangeStore
  resultsMarketResearch: IResultsMarketResearchStore
  resultsResearch: IResultsResearchStore
  resultsAlex: IResultsAlexStore
  resultsKlardenker: IResultsKlardenkerStore
  resultsEins: IResultsEinsStore
  resultsProjectSpaces: IResultsProjectSpacesStore
  resultsAEM: IResultsAEMStore
  resultsContentFinder: IResultsContentFinderStore
  resultsMpp: IResultsMppStore
  resultsIbfd: IResultsIbfdStore
  resultsImages: IResultsImagesStore
  resultsKpmgUS: IResultsKpmgUSStore
  resultsChat: IResultsChatStore
  search: ISearchStore
  startPage: IStartPageStore
  userSettings: IUserSettingsStore
  settings: ISettingsStore
  results: IResultsStore
  translations: ITranslationsStore
  searchTop10: ISearchTop10Store
  resultMetaData: IResultMetaDataStore
  widgets: IWidgetStore
  contactUs: IContactUsStore
  msanswers: IMSAnswersStore
}

export const rootReducer = combineReducers<Store>({
  auth: Auth.reducers,
  filters: Filters.reducers,
  resultsOneIntranet: ResultsOneIntranet.reducers,
  resultsEngagements: ResultsEngagements.reducers,
  resultsPeople: ResultsPeople.reducers,
  resultsSource: ResultsSource.reducers,
  resultsNews: ResultsNews.reducers,
  resultsStatistics: ResultsStatistics.reducers,
  resultsKlardenker: ResultsKlardenker.reducers,
  resultsVideos: ResultsVideos.reducers,
  resultsOffice365: ResultsOffice365.reducers,
  resultsKnowledgeExchange: ResultsKnowledgeExchange.reducers,
  resultsMarketResearch: ResultsMarketResearch.reducers,
  resultsResearch: ResultsResearch.reducers,
  resultsAlex: ResultsAlex.reducers,
  resultsEins: ResultsEins.reducers,
  resultsProjectSpaces: ResultsProjectSpaces.reducers,
  resultsAEM: ResultsAEM.reducers,
  resultsContentFinder: ResultsContentFinder.reducers,
  resultsMpp: ResultsMpp.reducers,
  resultsIbfd: ResultsIbfd.reducers,
  resultsImages: ResultsImages.reducers,
  resultsKpmgUS: ResultsKpmgUS.reducers,
  resultsChat: ResultsChat.reducers,
  search: Search.reducers,
  startPage: StartPage.reducers,
  settings: Settings.reducers,
  userSettings: UserSettings.reducers,
  results: Results.reducers,
  translations: Translations.reducers,
  searchTop10: SearchTop10.reducers,
  resultMetaData: ResultMetaData.reducers,
  contactUs: ContactUs.reducers,
  widgets: Widgets.reducers,
  msanswers: MSAnswers.reducers
})
