import React from 'react'
import * as Config from 'config'
import { stripHtml } from 'utils/string'
import { trackException } from 'utils/tracking'
import {
  getResultSummary,
  IOneIntranetResultQuery,
  oneIntranetDefaultQuery,
  removeStopWordsFromQuery
} from 'utils/oneIntranet'
import LeftIntranetWidget from 'components/contents/resultpages/widgets/LeftIntranetWidget'
import { ResultLeftWidgetGenerator } from './interfaces'
import { getSPOnlineToken, renewAuthorizationToken } from 'utils/token'
import { IDeviceSetting } from 'utils/deviceSettings'
import { IUserSetting } from 'utils/userSettings'
import { IFindConfiguration } from 'store/Settings/reducers'
import { portalOptionsIntranet } from 'constants/filters'
import { fetchRequestAndQueueFetches } from 'utils/apiQueue'
import { IOneIntranetResultStore } from 'store/ResultsOneIntranet/reducers'
import { IOneIntranetResult } from 'components/models/OneIntranetResult'
import { FetchWithCache } from 'utils/api'
import { IAADState } from 'store/Auth/reducers'

export const leftIntranetWidgetGenerator: ResultLeftWidgetGenerator = {
  fetch(
    aadInfo: IAADState,
    searchQuery: string,
    deviceSettings: IDeviceSetting,
    userSettings: IUserSetting,
    findConfiguration: IFindConfiguration,
    useCognitiveSearch: boolean
  ): Promise<IOneIntranetResultStore> {
    return new Promise<IOneIntranetResultStore>((resolve, reject) => {
      // Current production API Query
      const fetchIntranetWidgetData = async () => {
        if (!searchQuery || searchQuery === '' || searchQuery === '""') {
          trackException(
            'Error in fetching Intranet left widget in leftIntranetWidget.tsx',
            new Error('Empty query. Please specify a valid query text')
          )
          reject()
          return
        }

        let spoToken = ''
        if (Config.LOCAL_DEVELOPMENT.toString() === 'false') {
          spoToken = await getSPOnlineToken(aadInfo.instance, aadInfo.accounts)
          if (spoToken === '') {
            throw new Error(
              'Authentication: Cannot renew SPO authentication token'
            )
          }
        }

        const apiUrl = `${Config.APIM_BASE_URL}oneintranetapi/postsearchoi`

        const requestBody: IOneIntranetResultQuery = Object.assign(
          {},
          oneIntranetDefaultQuery
        )
        requestBody.rowlimit = 10
        requestBody.rowsperpage = 10
        requestBody.querytext = searchQuery
        requestBody.cognitiveEnabled = false
        requestBody.selectproperties =
          'Title,Write,Path,HitHighlightedSummary,Description,FreshDescription'

        // Get and check authentication token
        const esToken = await renewAuthorizationToken(
          aadInfo.accessToken,
          aadInfo.instance,
          aadInfo.accounts
        )

        if (
          useCognitiveSearch &&
          findConfiguration.CognitiveSearchEnabled &&
          esToken !== ''
        ) {
          let searchQueryLanguage = ''
          const detectLanguageApiUrl = `${
            Config.APIM_BASE_URL
          }searchapi/detectlanguage?searchQuery=${encodeURIComponent(
            searchQuery
          )}`

          const languageResponse = await FetchWithCache(detectLanguageApiUrl, {
            method: 'POST',
            headers: {
              accept: 'application/json',
              'content-type': 'application/json',
              'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
              Authorization: `Bearer ${esToken}`
            }
          })
          if (
            languageResponse &&
            !languageResponse.hasError &&
            languageResponse.responseJSON
          ) {
            const languageJSON = languageResponse.responseJSON
            if (languageJSON && languageJSON.responseCode === 200) {
              searchQueryLanguage = languageJSON.language
            }
          }
          requestBody.querytext = removeStopWordsFromQuery(
            searchQuery,
            searchQueryLanguage,
            useCognitiveSearch,
            findConfiguration.CognitiveSearchEnabled
          )
          requestBody.cognitiveEnabled = true
        }

        if (
          userSettings &&
          userSettings.EnableWidgetPrefiltering === true &&
          userSettings.Country &&
          userSettings.Country !== ''
        ) {
          let departmentId = ''
          const portalFilter = portalOptionsIntranet.find(
            (po: Filter) =>
              po.name.toLocaleLowerCase() ===
              userSettings.Country.toLocaleLowerCase()
          )
          if (portalFilter && portalFilter.value) {
            departmentId = portalFilter.value
            if (departmentId !== 'all')
              requestBody.origin = 'portal%%"{' + departmentId + '}"'
          }
        }
        const response = await fetchRequestAndQueueFetches(
          apiUrl,
          {
            method: 'POST',
            headers: {
              'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
              'content-type': 'application/json',
              Authorization: `Bearer ${spoToken}`,
              'Authorization-OnPrem': `${esToken}`
            },
            body: JSON.stringify(requestBody)
          },
          true,
          'widget_intranet',
          undefined,
          searchQuery
        )

        let results
        if (!response || !response.ok) {
          trackException(
            'Error in fetching Intranet left widget in leftIntranetWidget.tsx',
            new Error(
              response ? response.status + ':' + response.statusText : ''
            )
          )
          reject()
          return
        } else {
          results = await response.json()
        }

        const primaryResults =
          results && results.QueryResults ? results.QueryResults : []

        if (!primaryResults || primaryResults.length < 3) {
          reject()
          return
        }

        const oneIntranetResults: IOneIntranetResultStore = {
          queryResults: [],
          synonymsApplied: results.SynonymsApplied
            ? results.SynonymsApplied
            : []
        }

        primaryResults.forEach((i: any) => {
          const oneIntranetResult: IOneIntranetResult = {
            Title: i.Title ? stripHtml(i.Title) : '',
            Body: stripHtml(
              getResultSummary(
                i.HitHighlightedSummary,
                i.Description,
                i.FreshDescription
              )
            ).replace('...', ''),
            Path: i.Path ? i.Path : '',
            Date: i.Write ? i.Write : '',
            ServerRedirectedURL: '',
            DefaultEncodingURL: '',
            SiteTitle: '',
            ListItemID: '',
            ListID: '',
            SPWebUrl: '',
            IsLink: false,
            OriginalPath: '',
            UniqueID: '',
            ContentClass: '',
            SiteID: ''
          }

          oneIntranetResults.queryResults.push(oneIntranetResult)
        })

        resolve(oneIntranetResults)
      }

      try {
        fetchIntranetWidgetData()
      } catch (error) {
        trackException(
          'Error in fetching Intranet left widget in leftIntranetWidget.tsx',
          error
        )
        reject()
      }
    })
  },
  generate(
    results: IOneIntranetResultStore,
    searchQuery: string
  ): Promise<React.ReactElement> {
    return new Promise<React.ReactElement>((resolve, reject) => {
      const generateIntranetWidget = () => {
        const limit = 10
        if (results.queryResults.length > 2) {
          let resultsList = results

          if (results.queryResults.length > limit) {
            resultsList = {
              queryResults: results.queryResults.slice(0, limit),
              synonymsApplied: results.synonymsApplied
            }
          }

          let hasContent = false
          for (let i = 0; i < resultsList.queryResults.length; i++) {
            if (resultsList.queryResults[i].Title !== '') {
              hasContent = true
              break
            }
          }
          if (!hasContent) {
            reject()
            return
          }

          resolve(
            <LeftIntranetWidget
              searchQuery={searchQuery}
              results={resultsList}
            />
          )
        } else {
          reject()
          return
        }
      }

      try {
        generateIntranetWidget()
      } catch (error) {
        trackException(
          'Error in generating Intranet left widget in leftIntranetWidget.tsx',
          error
        )
        reject()
      }
    })
  }
}
