import React from 'react'
import * as Config from 'config'
import { ResultRightWidgetGenerator } from './interfaces'
import { trackException } from 'utils/tracking'
import { FetchWithCache } from 'utils/api'
import { IDeviceSetting } from 'utils/deviceSettings'
import { IUserSetting } from 'utils/userSettings'
import { IFindConfiguration } from 'store/Settings/reducers'
import { IVivaEngageResult } from 'components/models/VivaEngageResult'
import RightVivaEngageWidget from 'components/contents/resultpages/widgets/RightVivaEngageWidget'
import { IAADState } from 'store/Auth/reducers'
import { renewAuthorizationToken } from 'utils/token'

export const rightVivaEngageWidgetGenerator: ResultRightWidgetGenerator = {
  fetch(
    aadInfo: IAADState,
    searchQuery: string,
    deviceSettings: IDeviceSetting,
    userSettings: IUserSetting,
    findConfiguration: IFindConfiguration,
    useCognitiveSearch: boolean
  ): Promise<{
    queryResult: IVivaEngageResult[]
  }> {
    return new Promise<{
      queryResult: IVivaEngageResult[]
    }>((resolve, reject) => {
      const fetchVivaEngageResults = async () => {
        if (!searchQuery || searchQuery === '' || searchQuery === '""') {
          trackException(
            'Error in fetching VivaEngage right widget in rightVivaEngageWidget.tsx',
            new Error('Empty query. Please specify a valid query text')
          )
          reject()
          return
        }

        // Get and check authentication token
        const esToken = await renewAuthorizationToken(
          aadInfo.accessToken,
          aadInfo.instance,
          aadInfo.accounts
        )

        let apiUrl = `${Config.APIM_BASE_URL}vivaEngageAPI/getmessages?limit=10`

        const response = await FetchWithCache(apiUrl, {
          method: 'GET',
          headers: {
            'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
            'content-type': 'application/json',
            Authorization: `Bearer ${esToken}`
          }
        })

        let fetchedResults
        if (!response || response.hasError || !response.responseJSON) {
          trackException(
            'Error in fetching VivaEngage right widget in rightVivaEngageWidget.tsx',
            new Error(
              response && response.errorResponse
                ? response.errorResponse.responseCode +
                  ':' +
                  response.errorResponse.message
                : ''
            )
          )
          reject()
          return
        } else {
          fetchedResults = response.responseJSON
        }

        const results =
          fetchedResults && fetchedResults.messages
            ? fetchedResults.messages
            : []

        const references =
          fetchedResults && fetchedResults.references
            ? fetchedResults.references
            : []

        if (!results || results.length < 3) {
          reject()
          return
        }

        const vivaEngageResults: IVivaEngageResult[] = []

        results.forEach((message: IVivaEngageResult) => {
          const groupRef = references.find((ref: any) => {
            return ref.type === 'group' && ref.id === message.group_id
          })

          const userRef = references.find((ref: any) => {
            return ref.type === 'user' && ref.id === message.sender_id
          })

          if (userRef) {
            const vivaEngageResult = {
              id: message.id,
              sender: {
                id: userRef.id,
                full_name: userRef.full_name,
                web_url: userRef.web_url,
                email: userRef.email
              },
              sender_id: message.sender_id,
              published_at: message.published_at,
              web_url: message.web_url,
              group: {
                id: groupRef ? groupRef.id : '',
                full_name: groupRef ? groupRef.full_name : '',
                web_url: groupRef ? groupRef.web_url : '',
                description: groupRef ? groupRef.description : '',
                header_image_url: groupRef ? groupRef.header_image_url : ''
              },
              group_id: message.group_id,
              attachments: message.attachments,
              content_excerpt: message.content_excerpt,
              body: message.body
            }

            vivaEngageResults.push(vivaEngageResult)
          }
        })

        if (!vivaEngageResults || vivaEngageResults.length < 3) {
          reject()
          return
        }

        resolve({
          queryResult: vivaEngageResults
        })
      }

      try {
        fetchVivaEngageResults()
      } catch (error) {
        trackException(
          'Error in fetching VivaEngage in rightVivaEngageWidget.tsx',
          error
        )
        reject()
      }
    })
  },
  generate(
    resultsRaw: {
      queryResult: IVivaEngageResult[]
    },
    searchQuery: string
  ): Promise<React.ReactElement> {
    return new Promise<React.ReactElement>((resolve, reject) => {
      const generateVivaEngageResults = () => {
        const results = resultsRaw.queryResult
        if (!results) {
          reject()
        }

        let hasContent = false
        for (let i = 0; i < results.length; i++) {
          if (results[i].content_excerpt !== '') {
            hasContent = true
            break
          }
        }
        if (!hasContent) {
          reject()
          return
        }

        resolve(
          <RightVivaEngageWidget searchQuery={searchQuery} results={results} />
        )
      }

      try {
        generateVivaEngageResults()
      } catch (error) {
        trackException(
          'Error in generating VivaEngage right widget in rightVivaEngageWidget.tsx',
          error
        )
        reject()
      }
    })
  }
}
