import { ActionMetaData, CustomAction } from 'store/extension/customAction'
import { IErrorResponse } from 'utils/api'

export enum ResultMetaDataActionTypes {
  RESULT_META_FETCH_REQUEST = 'settings/RESULT_META_FETCH_REQUEST',
  RESULT_META_FETCH_SUCCESS = 'settings/RESULT_META_FETCH_SUCCESS',
  RESULT_META_FETCH_SUCCESS_COUNT = 'settings/RESULT_META_FETCH_SUCCESS_COUNT',
  RESULT_META_FETCH_SUCCESS_EXTIME = 'settings/RESULT_META_FETCH_SUCCESS_EXTIME',
  RESULT_META_HAS_ERROR = 'settings/RESULT_META_HAS_ERROR'
}

export interface IResultMetaFetchRequest
  extends CustomAction<ResultMetaDataActionTypes> {
  datasource: string
  payload: any
}
export interface IResultMetaHasError
  extends CustomAction<ResultMetaDataActionTypes> {
  datasource: string
  error: IErrorResponse
  hasOIError: boolean
}
export interface IResultMetaFetchSuccess
  extends CustomAction<ResultMetaDataActionTypes> {
  datasource: string
  payload: {
    resultsCount: number
    executionTime: number
    isUncertainCount?: boolean
  }
}

export interface IResultMetaFetchSuccessCount
  extends CustomAction<ResultMetaDataActionTypes> {
  datasource: string
  payload: {
    resCount: number
  }
}

export interface IResultMetaFetchSuccessExecutionTime
  extends CustomAction<ResultMetaDataActionTypes> {
  datasource: string
  payload: {
    exTime: number
  }
}

export const fetchRequest = (
  datasource: string,
  actionMetaData: ActionMetaData
): IResultMetaFetchRequest => ({
  type: ResultMetaDataActionTypes.RESULT_META_FETCH_REQUEST,
  datasource: datasource,
  payload: {},
  metaData: {
    ...actionMetaData,
    isStartAction: true
  }
})

export const hasError = (
  datasource: string,
  error: IErrorResponse | undefined,
  exception: Error | undefined | unknown,
  actionMetaData: ActionMetaData,
  hasOIError = false
): IResultMetaHasError => {
  if (error) {
    return {
      type: ResultMetaDataActionTypes.RESULT_META_HAS_ERROR,
      datasource: datasource,
      error: error,
      hasOIError: hasOIError,
      metaData: actionMetaData
    }
  } else {
    return {
      type: ResultMetaDataActionTypes.RESULT_META_HAS_ERROR,
      datasource: datasource,
      error: {
        message: `Error in fetching results ${datasource} action`,
        exception: exception instanceof Error ? exception : undefined,
        messageKey: 'error_unexpected_internal',
        internalError: true
      },
      hasOIError: hasOIError,
      metaData: actionMetaData
    }
  }
}

export const fetchSuccess = (
  datasource: string,
  resultMeta: {
    resultsCount: number
    executionTime: number
    isUncertainCount?: boolean
  },
  actionMetaData: ActionMetaData
): IResultMetaFetchSuccess => ({
  type: ResultMetaDataActionTypes.RESULT_META_FETCH_SUCCESS,
  datasource: datasource,
  payload: {
    resultsCount: resultMeta.resultsCount,
    executionTime: resultMeta.executionTime,
    isUncertainCount: resultMeta.isUncertainCount
  },
  metaData: actionMetaData
})

export const fetchSuccessCount = (
  datasource: string,
  resultMeta: {
    resultsCount: number
  },
  actionMetaData: ActionMetaData
): IResultMetaFetchSuccessCount => ({
  type: ResultMetaDataActionTypes.RESULT_META_FETCH_SUCCESS_COUNT,
  datasource: datasource,
  payload: {
    resCount: resultMeta.resultsCount
  },
  metaData: actionMetaData
})

export const fetchSuccessExecutionTime = (
  datasource: string,
  resultMeta: {
    executionTime: number
  },
  actionMetaData: ActionMetaData
): IResultMetaFetchSuccessExecutionTime => ({
  type: ResultMetaDataActionTypes.RESULT_META_FETCH_SUCCESS_EXTIME,
  datasource: datasource,
  payload: {
    exTime: resultMeta.executionTime
  },
  metaData: actionMetaData
})

export type ResultMetaDataActions =
  | IResultMetaFetchSuccess
  | IResultMetaHasError
  | IResultMetaFetchRequest
  | IResultMetaFetchSuccessCount
  | IResultMetaFetchSuccessExecutionTime
