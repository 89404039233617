import React from 'react'
import * as Config from 'config'
import { ResultLeftWidgetGenerator } from './interfaces'
import LeftKPMGWebsitesWidget from 'components/contents/resultpages/widgets/LeftKPMGWebsitesWidget'
import { stripHtml } from 'utils/string'
import { trackException } from 'utils/tracking'
import { FetchWithCache } from 'utils/api'
import { unifysearchQuery } from 'utils/unifysearchQuery'
import { IDeviceSetting } from 'utils/deviceSettings'
import { IUserSetting } from 'utils/userSettings'
import { IFindConfiguration } from 'store/Settings/reducers'
import { IKpmgWebsitesResult } from 'components/models/KpmgWebsiteResult'
import { IAADState } from 'store/Auth/reducers'
import { renewAuthorizationToken } from 'utils/token'

export const leftKPMGWebsitesWidgetGenerator: ResultLeftWidgetGenerator = {
  fetch(
    aadInfo: IAADState,
    searchQuery: string,
    deviceSettings: IDeviceSetting,
    userSetting: IUserSetting,
    findConfiguration: IFindConfiguration
  ): Promise<IKpmgWebsitesResult[]> {
    return new Promise<IKpmgWebsitesResult[]>((resolve, reject) => {
      const fetchKPMGWebsites = async () => {
        try {
          if (!searchQuery || searchQuery === '' || searchQuery === '""') {
            trackException(
              'Error in fetching kpmg websites api call in leftKPMGWebsitesWidget.tsx',
              new Error('Empty query. Please specify a valid query text')
            )
            reject()
            return
          }

          // Build and get aem results
          let apiUrl = `${
            Config.APIM_BASE_URL
          }aemapi/getsearch?locale=xx/en&keyword=${unifysearchQuery(
            searchQuery,
            'kpmg.com'
          )}&processFeaturedResults=false`

          apiUrl += `&limit=20`

          // Get and check authentication token
          const esToken = await renewAuthorizationToken(
            aadInfo.accessToken,
            aadInfo.instance,
            aadInfo.accounts
          )

          const response = await FetchWithCache(apiUrl, {
            method: 'GET',
            headers: {
              accept: 'application/json',
              'content-type': 'application/json',
              'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
              Authorization: `Bearer ${esToken}`
            }
          })

          let results
          if (!response || response.hasError || !response.responseJSON) {
            trackException(
              'Error in fetching kpmg websites api call in leftKPMGWebsitesWidget.tsx',
              new Error('Bad Response')
            )
            reject()
          } else {
            results = response.responseJSON
          }

          const primaryResults =
            results && results.results ? results.results : []

          const kpmgwebsiteResults: IKpmgWebsitesResult[] = []

          primaryResults.forEach((item: any) => {
            let date = ''
            if (
              item &&
              item.kpmg_article_date_time &&
              item.kpmg_article_date_time.raw &&
              item.kpmg_article_date_time.raw !== ''
            ) {
              date = item.kpmg_article_date_time.raw
            } else if (
              item &&
              item.kpmg_event_start_time &&
              item.kpmg_event_start_time.raw &&
              item.kpmg_event_start_time.raw !== ''
            ) {
              date = item.kpmg_event_start_time.raw
            } else if (
              item &&
              item.kpmg_blog_date_time &&
              item.kpmg_blog_date_time.raw &&
              item.kpmg_blog_date_time.raw !== ''
            ) {
              date = item.kpmg_blog_date_time.raw
            } else {
              date = ''
            }

            const kpmgwebsiteResult: IKpmgWebsitesResult = {
              title:
                item.kpmg_title && item.kpmg_title.raw
                  ? item.kpmg_title.raw
                  : '',
              body:
                item.kpmg_description && item.kpmg_description.raw
                  ? stripHtml(item.kpmg_description.raw)
                  : '',
              date: date,
              url: item.kpmg_url && item.kpmg_url.raw ? item.kpmg_url.raw : '',
              image:
                item.kpmg_image && item.kpmg_image.raw
                  ? `https://assets.kpmg/${item.kpmg_image.raw}`
                  : '',
              tabType:
                item.kpmg_tab_type && item.kpmg_tab_type.raw
                  ? item.kpmg_tab_type.raw
                  : ''
            }

            kpmgwebsiteResults.push(kpmgwebsiteResult)
          })

          let hasContent = false
          for (let i = 0; i < kpmgwebsiteResults.length; i++) {
            if (kpmgwebsiteResults[i].title !== '') {
              hasContent = true
              break
            }
          }

          if (!hasContent) {
            reject()
            return
          }

          resolve(kpmgwebsiteResults)
        } catch (error) {
          trackException(
            'Error in fetching kpmg websites api call in leftKPMGWebsitesWidget.tsx',
            error
          )
          reject()
        }
      }

      try {
        fetchKPMGWebsites()
      } catch (error) {
        trackException(
          'Error in fetching kpmg websites in leftKPMGWebsitesWidget.tsx',
          error
        )
        reject()
      }
    })
  },
  generate(
    results: IKpmgWebsitesResult[],
    searchQuery: string
  ): Promise<React.ReactElement> {
    return new Promise<React.ReactElement>((resolve, reject) => {
      const generateKPMGWebsites = () => {
        if (!results) {
          reject()
          return
        }

        const limit = 10
        if (results.length > 2) {
          let resultsList = results

          if (results.length > limit) {
            resultsList = results.slice(0, limit)
          }

          resolve(
            <LeftKPMGWebsitesWidget
              searchQuery={searchQuery}
              results={resultsList}
            />
          )
        } else {
          reject()
          return
        }
      }

      try {
        generateKPMGWebsites()
      } catch (error) {
        trackException(
          'Error in generating KPMG Websites left widget in leftKPMGWebsitesWidget.tsx',
          error
        )
        reject()
      }
    })
  }
}
