import React from 'react'
import { connect } from 'react-redux'
import { useIntl } from 'react-intl'
import { Store } from 'store'
import SettingsStore from 'store/Settings'
import TextResult from 'components/contents/results/TextResult'
import { formatDisplayBody } from 'utils/intranet'
import { dateFormatOptions, enterpriseSearchEmail } from 'constants/constants'
import 'Placeholder.css'
import { stripHtml } from 'utils/string'
import { useFilter } from 'utils/filters'
import { FeaturedResult } from 'components/models/FeaturedResult'
import createDOMPurify from 'dompurify'
import { Link } from '@mui/material'
import TooltipTitle from '../common/TooltipTitle'
import { trackFeaturedFeedbackClicked } from 'utils/tracking'

export interface FeaturedResultsProps {
  featuredResults: FeaturedResult[]
}

type AllProps = ReturnType<typeof mapStateToProps> & FeaturedResultsProps

function FeaturedResults(props: AllProps) {
  const { deviceSettings, featuredResults } = props

  const DOMPurify = createDOMPurify(window)

  const intl = useIntl()

  const [currentPage] = useFilter('page', '1')
  const [searchQuery] = useFilter('q')

  const buildInformationIncorrectLink = () => {
    const featuredResultsData: string[] = []
    if (featuredResults)
      featuredResults.forEach((featuredResult) => {
        const parsedBody = new DOMParser().parseFromString(
          buildDisplayBody(featuredResult),
          'text/html'
        )

        featuredResultsData.push(
          '\nKeyword: ' +
            DOMPurify.sanitize(featuredResult.BestBetKeyWordMatch) +
            '\nTitle: ' +
            DOMPurify.sanitize(featuredResult.BestBetTitle) +
            '\nUrl: ' +
            DOMPurify.sanitize(featuredResult.BestBetUrl) +
            '\nDescription: ' +
            (parsedBody.body.textContent || '') +
            '\n\n'
        )
      })

    const incorrectLink = {
      address: enterpriseSearchEmail,
      subject: intl.formatMessage({
        id: 'featured_results_correct_data_subject',
        defaultMessage: '[Content Feedback: Featured Results]'
      }),
      body: intl
        .formatMessage({
          id: 'featured_results_correct_data_body',
          defaultMessage:
            'Hi Team,\n\nI came across an inaccuracy in the Featured Results data. The right Featured Results data is:\n{featuredResults}\n\n'
        })
        .replaceAll('\\n', '\n')
        .replace('{featuredResults}', featuredResultsData.join('\n\n'))
    }

    const incorrectLinkUri = `mailto:${
      incorrectLink.address
    }?subject=${encodeURI(
      incorrectLink.subject
    )}&body=${encodeURIComponent(incorrectLink.body)}`

    return (
      <div
        style={
          deviceSettings.renderMobile
            ? {
                margin: '15px 0 20px 0',
                color: '#004e98',
                fontSize: '12px'
              }
            : {
                margin: '-25px 0 12px 0px',
                color: '#004e98',
                fontSize: '12px'
              }
        }
      >
        <Link
          data-interception={'off'}
          underline={'hover'}
          href={incorrectLinkUri}
          {...(deviceSettings.openLinksInNewTab === true
            ? { target: '_blank', rel: 'noreferrer' }
            : {})}
          onClick={() => {
            trackFeaturedFeedbackClicked(incorrectLinkUri)
          }}
          {...(!deviceSettings.isMobile
            ? {
                onAuxClick: (event: any) => {
                  trackFeaturedFeedbackClicked(incorrectLinkUri)
                }
              }
            : {})}
        >
          <TooltipTitle
            title={intl.formatMessage({
              id: 'featured_results_correct_data',
              defaultMessage: 'Information incorrect? Let us know!'
            })}
            singleLine={true}
            useCssOnly={true}
          />
        </Link>
      </div>
    )
  }

  const buildDisplayBody = (bestBetItem: FeaturedResult) => {
    // timezone into start date
    let startDateString = ''
    const sDate = bestBetItem.BestBetLastModified
      ? bestBetItem.BestBetLastModified
      : bestBetItem.BestBetStartDate
        ? bestBetItem.BestBetStartDate
        : ''

    if (sDate !== '') {
      const parsedStartDate = new Date(sDate)
      const timeOffsetInMS = parsedStartDate.getTimezoneOffset() * 60000
      parsedStartDate.setTime(parsedStartDate.getTime() - timeOffsetInMS)
      startDateString = intl.formatDate(
        DOMPurify.sanitize(parsedStartDate.toISOString()),
        dateFormatOptions
      )
    }

    const currentBody = DOMPurify.sanitize(bestBetItem.BestBetDescription) || ''

    return formatDisplayBody(startDateString, stripHtml(currentBody), intl)
  }

  return (
    <>
      {featuredResults &&
        (currentPage === '1' || deviceSettings.isMobile) &&
        featuredResults.map((bestBetItem: FeaturedResult, i: number) => {
          const resultComponent: JSX.Element = (
            <TextResult
              title={DOMPurify.sanitize(bestBetItem.BestBetTitle)}
              body={buildDisplayBody(bestBetItem)}
              url={DOMPurify.sanitize(bestBetItem.BestBetUrl)}
              featured={true}
              showContextMenu={false}
              featuredKeyword={DOMPurify.sanitize(
                bestBetItem.BestBetKeyWordMatch
              )}
              key={i}
              index={i}
              searchQuery={DOMPurify.sanitize(searchQuery)}
              deviceSettings={deviceSettings}
            />
          )

          if (
            !deviceSettings.renderMobile &&
            i === featuredResults.length - 1
          ) {
            return (
              <>
                {resultComponent}
                {buildInformationIncorrectLink()}
              </>
            )
          } else {
            return resultComponent
          }
        })}

      {deviceSettings.renderMobile &&
        featuredResults &&
        featuredResults.length > 0 && (
          <div style={{ margin: '15px 0px 20px 7px' }}>
            {buildInformationIncorrectLink()}
          </div>
        )}
    </>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state)
  }
}

export default connect(mapStateToProps)(FeaturedResults)
