import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string'
import 'Placeholder.css'
import { Store } from 'store'
import { useReactRouterQueryStringInterface } from 'utils/useQueryState'
import { IUserSetting } from 'utils/userSettings'
import {
  trackDatasourceEvent,
  trackEvents,
  trackException
} from 'utils/tracking'
import {
  cleanUpLocationUrlForHistory,
  getCurrentScope
} from 'utils/queryParams'
import UserSettingsStore from 'store/UserSettings'
import ResultsOneIntranetStore from 'store/ResultsOneIntranet'
import ResultsPeopleStore from 'store/ResultsPeople'
import ResultsNewsStore from 'store/ResultsNews'
import ResultsVideosStore from 'store/ResultsVideos'
import ResultsAEMStore from 'store/ResultsAEM'
import ResultsAlexStore from 'store/ResultsAlex'
import ResultsContentFinderStore from 'store/ResultsContentFinder'
import ResultsEinsStore from 'store/ResultsEins'
import ResultsEngagementsStore from 'store/ResultsEngagements'
import ResultsKlardenkerStore from 'store/ResultsKlardenker'
import ResultsKnowledgeExchangeStore from 'store/ResultsKnowledgeExchange'
import ResultsProjectSpacesStore from 'store/ResultsProjectSpaces'
import ResultsResearchStore from 'store/ResultsResearch'
import ResultsSourceStore from 'store/ResultsSource'
import ResultsStatisticsStore from 'store/ResultsStatistics'
import ResultsOffice365Store from 'store/ResultsOffice365'
import ResultsMppStore from 'store/ResultsMpp'
import ResultsIbfdStore from 'store/ResultsIbfd'
import ResultsMarketResearchStore from 'store/ResultsMarketResearch'
import ResultsImagesStore from 'store/ResultsImages'
import ResultsKpmgUSStore from 'store/ResultsKpmgUS'
import SettingsStore from 'store/Settings'
import { IDeviceSetting } from 'utils/deviceSettings'
import * as Filters from 'constants/filters'
import {
  getCurrentSetDatasourceFilter,
  getUrlForCurrentFilters,
  useFilter,
  parseFiltersFromUrlToStorage,
  overwriteKVFiltersWithTemporaryFilters,
  getFiltersToTrack
} from 'utils/filters'
import { getUserDataSources } from 'utils/dataSources'
import { deepEqual } from 'utils/objects'
import ResultMetaData from 'store/ResultMetaData'
import { IFindConfiguration } from 'store/Settings/reducers'
import FilterStore from 'store/Filters'
import {
  getCurrentFiltersAsKeyValue,
  getTemporaryFiltersAsKeyValue
} from 'utils/filtersAsKeyValue'
import { KPMGFindGlobalVariables } from 'store/KPMGFindGlobalVariables'
import SearchStore from 'store/Search'
import { useNonInitialEffect } from 'utils/useNonInitialEffect'
import { IResultMetaData } from 'store/ResultMetaData/reducers'
import { dataSourcesTabs } from 'constants/constants'
import MSAnswers from 'store/MSAnswers'
import { useNavigate } from 'react-router'

interface IFilter {
  [key: string]: any
}

interface IConfig {
  name: string
  fetchList: any[]
  filterConstants?: string[]
  triggerDelayedFunc?: any
  triggerDelayedParams?: any
  isCognitiveDs?: boolean
}
interface IFilterMemory {
  name: string
  lastFilters: IFilter[] | null
}
interface IDelayedFetchConfig {
  config: IConfig
  fetchFunc: any
  index: string | number
  currentFilters: { [key: string]: string }
  currentSearchQuery: string
}
interface IDelayConfig {
  delayContentFetches: boolean
  timeOutValueInMs: number
  delayedDataSources: Array<string>
  timeOutFunction: any
  delayedFetchFunctions: IDelayedFetchConfig[]
}
const memoryLastFilter: IFilterMemory[] = []

// Configuration to define the delay of the spo sources
const delayConfig: IDelayConfig = {
  delayContentFetches: false,
  timeOutValueInMs: 2000,
  delayedDataSources: [],
  timeOutFunction: null,
  delayedFetchFunctions: []
}

let refetchDataSourcesOnError = false

type AllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

function ContentFetch(props: AllProps) {
  const {
    userSettings,
    deviceSettings,
    findConfiguration,
    resultsMarketResearchCombined,
    currentDataSources,
    datasourceSettings,
    newsFilter,
    kpmgMppFilter,
    peopleOIFilter,
    ibfdFilter,
    kpmgusFilter,
    knowledgeExchangeFilter,
    hasLicenseBeenChecked,
    hasExchangeLicense,
    resultMetaData,
    currentfilters,
    temporaryFilters,
    setCurrentFilter,
    fetchResultsOneIntranet,
    fetchResultsPeople,
    fetchResultsNews,
    fetchResultsNewsFilters,
    fetchResultsAEM,
    fetchResultsVideos,
    fetchResultsAlex,
    fetchResultsContentFinder,
    fetchResultsEins,
    fetchResultsEngagements,
    fetchResultsKlardenker,
    fetchResultsKnowledgeExchange,
    fetchDocumentsResultsMarketResearch,
    fetchDocumentsCountMarketResearch,
    fetchResultsProjectSpaces,
    fetchResultsResearch,
    fetchResultsSource,
    fetchResultsStatistics,
    fetchResultsOffice365,
    fetchResultsMpp,
    fetchResultsMppFilters,
    fetchResultsIbfd,
    fetchResultsImages,
    fetchResultsKpmgUS,
    fetchBookmarks,
    fetchQnAs,
    fetchAcronyms,
    triggerOneIntranetDataSourceQuery,
    triggerEngagementsDataSourceQuery,
    triggerVideoDataSourceQuery,
    triggerPeopleDataSourceQuery,
    triggerImageDataSourceQuery,
    fetchAutocorrect,
    useCognitiveSearch,
    clearTemporaryFilter
  } = props

  const [currentCognitiveSearchEnabled, setCurrentCognitiveSearchEnabled] =
    useState<boolean>(useCognitiveSearch)
  const [currentEnableTemporaryFilters, setCurrentEnableTemporaryFilters] =
    useState<boolean>(temporaryFilters && temporaryFilters.length > 0)
  const [memoryCurrentFilters, setMemoryCurrentFilters] = useState<any>()
  const { getQueryString } = useReactRouterQueryStringInterface()
  const navigateFunction = useNavigate()

  const [memoryCurrentQueryParams, setMemoryCurrentQueryParams] =
    useState<any>()

  const queryStringFromUrl = getQueryString()
  const queryParams = queryString.parse(queryStringFromUrl) || {}
  const activeDataSource = getCurrentScope(false)

  useEffect(() => {
    // init delay Config
    if (findConfiguration && findConfiguration.QueryConfiguration) {
      delayConfig.delayContentFetches =
        findConfiguration.QueryConfiguration.delayContentFetch
      delayConfig.timeOutValueInMs =
        findConfiguration.QueryConfiguration.timeOutValueInMs
      delayConfig.delayedDataSources = findConfiguration.QueryConfiguration
        .delayDataSources
        ? findConfiguration.QueryConfiguration.delayDataSources
        : []

      refetchDataSourcesOnError =
        findConfiguration.QueryConfiguration.refetchDataSourcesOnError
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // fetch bookmarks, qnas and acronyms
    if (findConfiguration && findConfiguration.UseMSAnswers) {
      fetchBookmarks()
      fetchQnAs()
      fetchAcronyms()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    //handleUrlUpdate
    if (temporaryFilters && temporaryFilters.length > 0) {
      const queryTemp = temporaryFilters.find((f) => f.key === 'q')
      const queryCurrent = currentfilters.find((f) => f.key === 'q')
      const currentPage = currentfilters.find((f) => f.key === 'page')

      if (queryCurrent && queryTemp && queryTemp.value !== queryCurrent.value) {
        queryCurrent.value = queryTemp.value
      }

      if (currentPage && currentPage.value !== '1') {
        currentPage.value = '1'
      }
    }

    let loc = getUrlForCurrentFilters(
      temporaryFilters && temporaryFilters.length > 0
        ? temporaryFilters
        : currentfilters
    )
    if (loc) {
      loc = cleanUpLocationUrlForHistory(loc)
      if (temporaryFilters && temporaryFilters.length > 0) {
        navigateFunction(loc, { replace: true })
      } else {
        navigateFunction(loc, { replace: false })
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentfilters, temporaryFilters])

  useEffect(() => {
    if (
      !memoryCurrentQueryParams ||
      !deepEqual(queryParams, memoryCurrentQueryParams)
    ) {
      if (!currentEnableTemporaryFilters && temporaryFilters.length === 0) {
        parseFiltersFromUrlToStorage(
          queryParams,
          currentfilters,
          setCurrentFilter
        )
        setMemoryCurrentQueryParams(Object.assign({}, queryParams))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams])

  //remove temp filter on back button
  const resetTemporaryFilter = () => {
    if (currentEnableTemporaryFilters || temporaryFilters.length === 0) {
      clearTemporaryFilter()
    }
  }

  useEffect(() => {
    window.addEventListener('popstate', resetTemporaryFilter)

    return () => window.removeEventListener('popstate', resetTemporaryFilter)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [searchQuery] = useFilter('q')
  const [currentPage, setCurrentPage] = useFilter('page', '1')

  useEffect(() => {
    //global store
    KPMGFindGlobalVariables.setSearchTerm(searchQuery)

    //auto correct
    if (searchQuery)
      fetchAutocorrect(searchQuery, userSettings, findConfiguration)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery])

  const configFetchedFilters = [
    {
      dsName: 'news',
      filter: newsFilter
    },
    {
      dsName: 'kpmg mpp',
      filter: kpmgMppFilter
    },
    {
      dsName: 'people',
      filter: peopleOIFilter
    },
    {
      dsName: 'ibfd',
      filter: ibfdFilter
    },
    {
      dsName: 'kpmgus',
      filter: kpmgusFilter
    },
    {
      dsName: 'knowledgeexchange',
      filter: knowledgeExchangeFilter
    }
  ]

  const config: IConfig[] = [
    {
      name: 'intranet',
      fetchList: [fetchResultsOneIntranet],
      triggerDelayedFunc: triggerOneIntranetDataSourceQuery,
      triggerDelayedParams: [searchQuery],
      isCognitiveDs: true
    },
    {
      name: 'people',
      fetchList: [fetchResultsPeople],
      filterConstants: ['people'],
      triggerDelayedFunc: triggerPeopleDataSourceQuery,
      triggerDelayedParams: [searchQuery],
      isCognitiveDs: true
    },
    { name: 'source', fetchList: [fetchResultsSource], isCognitiveDs: true },
    {
      name: 'news',
      fetchList: [fetchResultsNews, fetchResultsNewsFilters],
      isCognitiveDs: true
    },
    {
      name: 'videos',
      fetchList: [fetchResultsVideos],
      triggerDelayedFunc: triggerVideoDataSourceQuery,
      triggerDelayedParams: [searchQuery],
      isCognitiveDs: true
    },
    {
      name: 'images',
      fetchList: [fetchResultsImages],
      triggerDelayedFunc: triggerImageDataSourceQuery,
      triggerDelayedParams: [searchQuery],
      isCognitiveDs: true
    },
    {
      name: 'kpmg.com',
      fetchList: [fetchResultsAEM],
      filterConstants: ['kpmgwebsites']
    },
    {
      name: 'alex',
      fetchList: [
        (
          searchQuery: string,
          currentPage: number,
          userSettings: IUserSetting,
          deviceSettings: IDeviceSetting,
          filters: { [key: string]: string }
        ) => {
          fetchResultsAlex(
            searchQuery,
            currentPage,
            userSettings,
            deviceSettings,
            filters
          )
        }
      ]
    },
    {
      name: 'engagements',
      fetchList: [fetchResultsEngagements],
      triggerDelayedFunc: triggerEngagementsDataSourceQuery,
      triggerDelayedParams: [searchQuery],
      isCognitiveDs: true
    },
    { name: 'klardenker', fetchList: [fetchResultsKlardenker] },
    { name: 'statistics', fetchList: [fetchResultsStatistics] },
    { name: 'contentfinder', fetchList: [fetchResultsContentFinder] },
    {
      name: 'knowledgeexchange',
      fetchList: [
        {
          func: (
            searchQuery: string,
            currentPage: number,
            userSettings: IUserSetting,
            deviceSettings: IDeviceSetting,
            filters: { [key: string]: string }
          ) => {
            fetchResultsKnowledgeExchange(
              searchQuery,
              currentPage,
              userSettings,
              deviceSettings,
              filters,
              'open'
            )
          },
          subVerticalName: 'knowledgeexchangeopen'
        },
        {
          func: (
            searchQuery: string,
            currentPage: number,
            userSettings: IUserSetting,
            deviceSettings: IDeviceSetting,
            filters: { [key: string]: string }
          ) => {
            fetchResultsKnowledgeExchange(
              searchQuery,
              currentPage,
              userSettings,
              deviceSettings,
              filters,
              'catalog'
            )
          },
          subVerticalName: 'knowledgeexchangecatalog'
        }
      ]
    },
    {
      name: 'marketresearch',
      fetchList: [
        fetchDocumentsCountMarketResearch,
        fetchDocumentsResultsMarketResearch
      ],
      isCognitiveDs: true
    },
    { name: 'eins', fetchList: [fetchResultsEins] },
    { name: 'project_spaces', fetchList: [fetchResultsProjectSpaces] },
    { name: 'research', fetchList: [fetchResultsResearch] },
    {
      name: 'kpmg mpp',
      fetchList: [fetchResultsMpp, fetchResultsMppFilters],
      isCognitiveDs: true
    },
    { name: dataSourcesTabs.ibfd, fetchList: [fetchResultsIbfd] },
    {
      name: dataSourcesTabs.kpmgus,
      fetchList: [fetchResultsKpmgUS],
      isCognitiveDs: true
    },
    {
      name: 'office365',
      fetchList: [
        {
          func: (
            searchQuery: string,
            currentPage: number,
            userSettings: IUserSetting,
            deviceSettings: IDeviceSetting,
            filters: { [key: string]: string },
            currentDataSourcesName: string
          ) => {
            fetchResultsOffice365(
              searchQuery,
              currentPage,
              userSettings,
              deviceSettings,
              findConfiguration,
              filters,
              'mails',
              hasLicenseBeenChecked,
              hasExchangeLicense,
              currentDataSourcesName
            )
          },
          subVerticalName: 'office365mails'
        },
        {
          func: (
            searchQuery: string,
            currentPage: number,
            userSettings: IUserSetting,
            deviceSettings: IDeviceSetting,
            filters: { [key: string]: string },
            currentDataSourcesName: string
          ) => {
            fetchResultsOffice365(
              searchQuery,
              currentPage,
              userSettings,
              deviceSettings,
              findConfiguration,
              filters,
              'files',
              hasLicenseBeenChecked,
              hasExchangeLicense,
              currentDataSourcesName
            )
          },
          subVerticalName: 'office365files'
        },
        {
          func: (
            searchQuery: string,
            currentPage: number,
            userSettings: IUserSetting,
            deviceSettings: IDeviceSetting,
            filters: { [key: string]: string },
            currentDataSourcesName: string
          ) => {
            fetchResultsOffice365(
              searchQuery,
              currentPage,
              userSettings,
              deviceSettings,
              findConfiguration,
              filters,
              'calendar',
              hasLicenseBeenChecked,
              hasExchangeLicense,
              currentDataSourcesName
            )
          },
          subVerticalName: 'office365calendar'
        },
        {
          func: (
            searchQuery: string,
            currentPage: number,
            userSettings: IUserSetting,
            deviceSettings: IDeviceSetting,
            filters: { [key: string]: string },
            currentDataSourcesName: string
          ) => {
            fetchResultsOffice365(
              searchQuery,
              currentPage,
              userSettings,
              deviceSettings,
              findConfiguration,
              filters,
              'sites',
              hasLicenseBeenChecked,
              hasExchangeLicense,
              currentDataSourcesName
            )
          },
          subVerticalName: 'office365sites'
        },
        {
          func: (
            searchQuery: string,
            currentPage: number,
            userSettings: IUserSetting,
            deviceSettings: IDeviceSetting,
            filters: { [key: string]: string },
            currentDataSourcesName: string
          ) => {
            fetchResultsOffice365(
              searchQuery,
              currentPage,
              userSettings,
              deviceSettings,
              findConfiguration,
              filters,
              'teams',
              hasLicenseBeenChecked,
              hasExchangeLicense,
              currentDataSourcesName
            )
          },
          subVerticalName: 'office365teams'
        }
      ],
      isCognitiveDs: true
    }
  ]

  /**
   * Check datasource is enabled
   * @param dsName name of the datasource
   */
  const isDatasourceEnabled = (dsName: string) => {
    // In case find is open directly through a link, it can be the case that the currentdatasources are still empty
    // (Timing issue with setting up the currentdatasources in the usersettings.tsx and not triggering the effect hook inside this component after the update is done)
    // For this as fallback the currentdatasources are directly generated inside this method
    if (!currentDataSources || currentDataSources.length === 0) {
      const dataSources = getUserDataSources(
        userSettings,
        deviceSettings,
        datasourceSettings
      )

      return dataSources.find(
        (currentDataSources: any) =>
          currentDataSources.name.toLowerCase() === dsName.toLowerCase() &&
          currentDataSources.enabled
      )
    } else {
      return currentDataSources.find(
        (currentDataSources: any) =>
          currentDataSources.name.toLowerCase() === dsName.toLowerCase() &&
          currentDataSources.enabled
      )
    }
  }

  /**
   * Get current set filter keynames for datasource
   * @param availableFilterKeyNames filter constant names
   * @param currentFilter current set filter
   */
  const getCurrentSetDatasourcesFilters = (
    dsName: string,
    availableFilterKeyNames: string[] | undefined,
    currentFilter: IFilter
  ): string[] => {
    const currentSetDatasourcesFilters: string[] = []
    if (availableFilterKeyNames) {
      availableFilterKeyNames.forEach((availableFilterKeyName: string) => {
        const availableFilters = (Filters as IFilter)[
          availableFilterKeyName
            .toLowerCase()
            .replace(' ', '_')
            .replace('kpmg.us', 'kpmgus')
        ]

        // Append additional fetched filters
        configFetchedFilters.forEach((configFetchedFilter) => {
          if (
            dsName.replace('kpmg.us', 'kpmgus') === configFetchedFilter.dsName
          ) {
            availableFilters.forEach((availableFilter: IFilter) => {
              if (availableFilter.key in configFetchedFilter.filter) {
                if (configFetchedFilter.filter['hasResultsBeenFetched']) {
                  configFetchedFilter.filter[availableFilter.key].forEach(
                    (newFilter: IFilter) => {
                      availableFilter.options.push(newFilter)
                    }
                  )
                } else {
                  availableFilter.skipOptionValidation = true
                }
              }
            })
          }
        })

        if (availableFilters) {
          const currentSetDatasourceFilters = getCurrentSetDatasourceFilter(
            currentFilter,
            availableFilters,
            userSettings.EnablePrefiltering
          )

          currentSetDatasourceFilters.forEach((currentSetFilterKey: string) => {
            currentSetDatasourcesFilters.push(currentSetFilterKey)
          })
        }

        // Bug 1706199: market research not fetching on redirect from right widget
        if (
          availableFilterKeyName.toLowerCase().replace(' ', '_') ===
            'marketresearch' &&
          currentFilter &&
          currentFilter.ptkr
        ) {
          currentSetDatasourcesFilters.push('ptkr')
        }
      })
    }

    return currentSetDatasourcesFilters
  }

  /**
   * Check datasource needs a refresh related to his filters
   * @param dsName name of the datasource
   * @param filters current set filter
   * @param includeSpecialFilters the additional filters that must be applied for the check
   * @param isActiveDataSource flag that indicates if the datasource is currently active, if not the special page filter will always be 1
   */
  const applyDatasourceFilterChanges = (
    dsName: string,
    filters: IFilter,
    includeSpecialFilters: string[],
    isActiveDataSource = true
  ) => {
    //collect filter constant names for datasource tab (also sub categories)
    let availableFilterKeyNames: string[] | undefined = [dsName.toLowerCase()]

    const datasourceConfig = config.find(
      (dsitem: any) =>
        dsitem.name === dsName.toLowerCase() &&
        dsitem.filterConstants &&
        dsitem.filterConstants.length > 0
    )
    if (datasourceConfig) {
      availableFilterKeyNames = datasourceConfig.filterConstants
    }

    //get current set filter keynames for datasource
    const currentSetDatasourcesFilters = getCurrentSetDatasourcesFilters(
      dsName,
      availableFilterKeyNames,
      filters
    )

    //append filters which are not set in constants (e.g. page, q)
    includeSpecialFilters.forEach((specialFilterKey: string) => {
      currentSetDatasourcesFilters.push(specialFilterKey)
    })

    //prevent double rendering if page parameter is missing.
    if (!filters.page) {
      filters = {
        page: '1',
        ...filters
      }
    }

    //generate a clean list to compare with
    const filtersToCheck: IFilter[] = []
    for (const key in filters) {
      if (currentSetDatasourcesFilters.includes(key)) {
        // for non active datasources
        // set the page value always to one for the check
        // so no reload is done on paging in the active datasource
        if (!isActiveDataSource && key === 'page') {
          filtersToCheck.push({
            key: key,
            value: '1'
          })
        } else {
          filtersToCheck.push({
            key: key,
            value: filters[key]
          })
        }
      }
    }

    let filtersChanges = false

    //check memory for changes
    let memoryFound = false
    memoryLastFilter.forEach((memoryItem: IFilterMemory) => {
      if (memoryItem.name.toLowerCase() === dsName.toLowerCase()) {
        memoryFound = true
        if (
          !memoryItem.lastFilters ||
          !filterItemsEqual(filtersToCheck, memoryItem.lastFilters)
        ) {
          filtersChanges = true //filter changed, compared to last state
          memoryItem.lastFilters = filtersToCheck
        }
      }
    })

    if (!memoryFound) {
      //set true for initial load, memory current state
      filtersChanges = true
      memoryLastFilter.push({
        name: dsName.toLowerCase(),
        lastFilters: filtersToCheck
      })
    }

    return filtersChanges
  }

  const getLastSearchQueryFormMemory = (
    dsName: string | undefined
  ): string | undefined => {
    return memoryLastFilter
      .find(
        (item: IFilterMemory) =>
          item.name.toLocaleLowerCase() === dsName?.toLocaleLowerCase()
      )
      ?.lastFilters?.find((item: IFilter) => item.key === 'q')?.value
  }

  const searchQueryChanged = (
    lastSearchQuery: string | undefined,
    filters: IFilter
  ) => {
    if (lastSearchQuery) {
      return lastSearchQuery !== filters['q']
    } else {
      return true
    }
  }

  /**
   * Compare IFilter lists
   * @param enabledFilters current set filters
   * @param lastFilters previously set filters
   */
  const filterItemsEqual = (
    enabledFilters: IFilter[],
    lastFilters: IFilter[]
  ) => {
    //compare filter lists by filter values, because the order of items inside of lists can change
    const filterKeysChecked: string[] = []

    for (const enabledFilter of enabledFilters) {
      let currentKeyFound = false
      for (const lastFilter of lastFilters) {
        if (enabledFilter.key === lastFilter.key) {
          if (enabledFilter.value !== lastFilter.value) {
            return false //one filter value mismatch, items not equal
          }
          currentKeyFound = true
          filterKeysChecked.push(enabledFilter.key)
          break //filter-key found, skip last filter key search
        }
      }
      if (!currentKeyFound) {
        return false //one current set filterkey not found in memory, items not equal
      }
    }

    //search for removed filters
    for (const lastFilter of lastFilters) {
      if (
        !filterKeysChecked.find(
          (filterKeyChecked: string) => lastFilter.key === filterKeyChecked
        )
      ) {
        return false //one last filter key not found in current, items not equal
      }
    }
    return true //filter items are equal
  }

  const runDelayedFetches = () => {
    delayConfig.delayedFetchFunctions.forEach((d: IDelayedFetchConfig) => {
      d.fetchFunc(
        d.currentSearchQuery,
        1,
        userSettings,
        deviceSettings,
        d.currentFilters,
        findConfiguration
      )
    })

    delayConfig.delayedFetchFunctions = []
  }

  const checkNonActiveDataSource = (
    currentDataSource: string,
    delayEnabled = true
  ) => {
    // load non active datasources with page=1
    const nonActiveDatasource = config.filter(
      (dsitem: any) => dsitem.name !== currentDataSource
    )

    nonActiveDatasource.forEach((dsitem) => {
      // Check and run only for active datasources
      // Otherwise check value will be added also for currently inactive datasources
      // After they get enabled thorugh settings menu, data will be never fetched until new query or filterchange
      if (isDatasourceEnabled(dsitem.name)) {
        if (dsitem.name === 'knowledgeexchange') {
          if (
            currentDataSource &&
            !currentDataSource.startsWith('knowledgeexchange')
          ) {
            dsitem.fetchList.forEach((fetchFunc: any) => {
              try {
                const datasourceFilterChanged: boolean =
                  applyDatasourceFilterChanges(
                    fetchFunc.subVerticalName,
                    getCurrentFiltersAsKeyValue(),
                    [],
                    false
                  )

                if (datasourceFilterChanged) {
                  fetchFunc.func(
                    searchQuery,
                    1,
                    userSettings,
                    deviceSettings,
                    Object.assign({}, getCurrentFiltersAsKeyValue()),
                    currentDataSource
                  )
                }
              } catch (error) {
                trackException(
                  'Error in fetching results for ' +
                    fetchFunc.subVerticalName +
                    ' in ContentFetch.tsx',
                  error
                )
              }
            })
          }
        } else if (dsitem.name === 'office365') {
          dsitem.fetchList.forEach((fetchFunc: any) => {
            try {
              const datasourceFilterChanged: boolean =
                applyDatasourceFilterChanges(
                  fetchFunc.subVerticalName,
                  getCurrentFiltersAsKeyValue(),
                  ['q', 'page'],
                  false
                )

              if (datasourceFilterChanged) {
                fetchFunc.func(
                  searchQuery,
                  1,
                  userSettings,
                  deviceSettings,
                  Object.assign({}, getCurrentFiltersAsKeyValue()),
                  currentDataSource
                )
              }
            } catch (error) {
              trackException(
                'Error in fetching results for ' +
                  fetchFunc.subVerticalName +
                  ' in ContentFetch.tsx',
                error
              )
            }
          })
        } else {
          // as special filter parameter page must be included otherwise a fetch for datasource is happening everytime:
          // -on leaving vertical
          // -on entering vertical
          // the saved page value in memory for non activte datasources will be always 1
          const datasourceFilterChanged: boolean = applyDatasourceFilterChanges(
            dsitem.name,
            getCurrentFiltersAsKeyValue(),
            ['q', 'page'],
            false
          )

          if (datasourceFilterChanged) {
            dsitem.fetchList.forEach(
              (fetchFunc: any, validationIndex: number) => {
                try {
                  if (
                    delayEnabled &&
                    delayConfig &&
                    delayConfig.delayContentFetches &&
                    delayConfig.delayedDataSources.includes(dsitem.name) &&
                    delayConfig.delayedDataSources.includes(currentDataSource)
                  ) {
                    // Filter out this entry and replace it
                    delayConfig.delayedFetchFunctions =
                      delayConfig.delayedFetchFunctions.filter(
                        (dF: IDelayedFetchConfig) => {
                          return (
                            dF.config.name !== dsitem.name ||
                            dF.index !== validationIndex
                          )
                        }
                      )

                    delayConfig.delayedFetchFunctions.push({
                      config: dsitem,
                      fetchFunc,
                      index: validationIndex,
                      currentFilters: Object.assign(
                        {},
                        getCurrentFiltersAsKeyValue()
                      ),
                      currentSearchQuery: searchQuery
                    })

                    if (
                      dsitem.triggerDelayedFunc &&
                      dsitem.triggerDelayedParams
                    ) {
                      dsitem.triggerDelayedFunc(searchQuery)
                    }
                  } else {
                    fetchFunc(
                      searchQuery,
                      1,
                      userSettings,
                      deviceSettings,
                      Object.assign({}, getCurrentFiltersAsKeyValue()),
                      findConfiguration
                    )
                  }
                } catch (error) {
                  trackException(
                    'Error in fetching results for ' +
                      dsitem.name +
                      ' in ContentFetch.tsx',
                    error
                  )
                }
              }
            )
          }
        }
      }
    })
  }

  useEffect(() => {
    const currentDataSource = getCurrentScope(false)
    const pageNumber = Number(currentPage)

    // Reset page for Market Research
    if (currentDataSource === 'marketresearch' && pageNumber > 1) {
      if (
        resultsMarketResearchCombined &&
        resultsMarketResearchCombined.length === 0
      ) {
        setCurrentPage('1')
      }
    }

    if (!searchQuery) {
      return
    }

    // If query params are not changed, stop processing
    if (!memoryCurrentFilters) {
      setMemoryCurrentFilters({
        ds: currentDataSource,
        qp: JSON.parse(
          JSON.stringify(
            temporaryFilters && temporaryFilters.length > 0
              ? temporaryFilters
              : currentfilters
          )
        )
      })
    } else {
      if (
        deepEqual(memoryCurrentFilters, {
          ds: currentDataSource,
          qp:
            temporaryFilters && temporaryFilters.length > 0
              ? temporaryFilters
              : currentfilters
        }) &&
        currentCognitiveSearchEnabled === useCognitiveSearch &&
        currentEnableTemporaryFilters ===
          !!(temporaryFilters && temporaryFilters.length > 0)
      ) {
        // Bug 806836
        // all non active datasource must be checked at this point
        // so in case datasources gets activated in the settings menu are fetched once
        // don't delay the fetch in this case
        checkNonActiveDataSource(currentDataSource, false)
        return
      } else {
        setMemoryCurrentFilters({
          ds: currentDataSource,
          qp: JSON.parse(
            JSON.stringify(
              temporaryFilters && temporaryFilters.length > 0
                ? temporaryFilters
                : currentfilters
            )
          )
        })
        if (currentCognitiveSearchEnabled !== useCognitiveSearch) {
          config.forEach((dsitem) => {
            // Fetch all enabled datasource where the cognitive search mode is supported
            if (isDatasourceEnabled(dsitem.name) && dsitem.isCognitiveDs) {
              if (
                dsitem.name === 'office365' ||
                dsitem.name === 'knowledgeexchange'
              ) {
                dsitem.fetchList.forEach((fetchFunc: any) => {
                  try {
                    fetchFunc.func(
                      searchQuery,
                      1,
                      userSettings,
                      deviceSettings,
                      Object.assign({}, getCurrentFiltersAsKeyValue()),
                      ''
                    )
                  } catch (error) {
                    trackException(
                      'Error in fetching results for ' +
                        fetchFunc.subVerticalName +
                        ' in ContentFetch.tsx',
                      error
                    )
                  }
                })
              } else {
                dsitem.fetchList.forEach((fetchFunc: any) => {
                  try {
                    fetchFunc(
                      searchQuery,
                      1,
                      userSettings,
                      deviceSettings,
                      Object.assign({}, getCurrentFiltersAsKeyValue()),
                      findConfiguration
                    )
                  } catch (error) {
                    trackException(
                      'Error in fetching results for ' +
                        dsitem.name +
                        ' in ContentFetch.tsx',
                      error
                    )
                  }
                })
              }
            }
          })
          setCurrentCognitiveSearchEnabled(useCognitiveSearch)
        }
        setCurrentEnableTemporaryFilters(
          temporaryFilters && temporaryFilters.length > 0
        )
      }
    }

    if (delayConfig.delayContentFetches && delayConfig.timeOutFunction) {
      clearTimeout(delayConfig.timeOutFunction)
    }

    // load active datasource with currentpage
    if (currentDataSource) {
      const activeDatasource = config.find(
        (dsitem: any) =>
          dsitem.name ===
          (currentDataSource.startsWith('office365')
            ? 'office365'
            : currentDataSource.startsWith('knowledgeexchange')
            ? 'knowledgeexchange'
            : currentDataSource)
      )

      // Bug: 508110
      // To track if the search is distinct, the lastSearchQuery must be extracted from the memory filter list
      // before it gets updated by the method applyDatasourceFilterChanges.
      // This extracted searchquery will be used by the method searchQueryChanged
      // to identify if the search is distinct or not (searchquery has changed).
      const lastSearchQuery = getLastSearchQueryFormMemory(
        activeDatasource?.name
      )

      if (
        activeDatasource &&
        applyDatasourceFilterChanges(
          activeDatasource.name === 'office365' ||
            activeDatasource.name === 'knowledgeexchange'
            ? currentDataSource
            : activeDatasource.name,
          temporaryFilters && temporaryFilters.length > 0
            ? getTemporaryFiltersAsKeyValue()
            : getCurrentFiltersAsKeyValue(),
          ['q', 'page']
        )
      ) {
        const fetchFilter = overwriteKVFiltersWithTemporaryFilters(
          getCurrentFiltersAsKeyValue(),
          temporaryFilters
        )

        //get current set filter keynames for datasource
        const filtersToTrack = getFiltersToTrack(
          activeDatasource.name === 'office365' ||
            activeDatasource.name === 'knowledgeexchange'
            ? currentDataSource
            : activeDatasource.name,
          fetchFilter
        )

        trackDatasourceEvent(searchQuery, filtersToTrack)

        let fetchListToUse = activeDatasource.fetchList

        // Prevent fetch for office365 and knowledge exchange
        if (
          activeDatasource.name === 'office365' ||
          activeDatasource.name === 'knowledgeexchange'
        ) {
          fetchListToUse = []

          // reset Autosuggest
          KPMGFindGlobalVariables.setIsAutoSuggestQuery(false)
        }

        fetchListToUse.forEach((fetchFunc: any) => {
          try {
            const distinct = searchQueryChanged(lastSearchQuery, fetchFilter)
            fetchFunc(
              searchQuery,
              pageNumber,
              userSettings,
              deviceSettings,
              Object.assign({}, fetchFilter),
              findConfiguration
            ).then(() => {
              let dataSourceResult = null

              if (resultMetaData && resultMetaData.find) {
                dataSourceResult = resultMetaData.find(
                  (resultMetaItem: any) =>
                    resultMetaItem.datasource === activeDatasource.name
                )
              }

              if (dataSourceResult && dataSourceResult.resultsCount === 0) {
                trackEvents('zero_result_queries', {
                  searchTerm: searchQuery,
                  tab:
                    currentDataSource.toLocaleLowerCase() === 'kpmg mpp'
                      ? 'mpp'
                      : currentDataSource.toLocaleLowerCase(),
                  enabledFilters: filtersToTrack,
                  distinct: distinct
                })
              }
            })

            // reset Autosuggest
            KPMGFindGlobalVariables.setIsAutoSuggestQuery(false)
          } catch (error) {
            trackException(
              'Error in fetching results for ' +
                currentDataSource +
                ' in ContentFetch.tsx',
              error
            )
          }
        })
      }
    }

    checkNonActiveDataSource(currentDataSource)

    if (delayConfig.delayContentFetches) {
      delayConfig.timeOutFunction = window.setTimeout(() => {
        runDelayedFetches()
      }, delayConfig.timeOutValueInMs)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentfilters, temporaryFilters, currentDataSources, userSettings])

  // Trigger refetch in case of an OI error
  useNonInitialEffect(() => {
    if (activeDataSource && refetchDataSourcesOnError) {
      if (!resultMetaData || !resultMetaData.find) return

      const resultMetaDataObject: IResultMetaData = resultMetaData.find(
        (rm: IResultMetaData) => rm.datasource === activeDataSource
      )

      if (!resultMetaDataObject || !resultMetaDataObject.hasOIError) return

      const dsConfig = config.find((c: IConfig) => c.name === activeDataSource)

      if (!dsConfig) return

      dsConfig.fetchList.forEach((fetchFunc: any) => {
        try {
          fetchFunc(
            searchQuery,
            1,
            userSettings,
            deviceSettings,
            Object.assign({}, getCurrentFiltersAsKeyValue()),
            findConfiguration
          )
        } catch (e) {}
      })
    }
  }, [activeDataSource])

  return <></>
}

const mapStateToProps = (state: Store) => {
  return {
    userSettings: UserSettingsStore.selectors.getUserSettings(state),
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state),
    findConfiguration: SettingsStore.selectors.getFindConfiguration(state),
    currentDataSources: SettingsStore.selectors.getCurrentDataSources(state),
    datasourceSettings: SettingsStore.selectors.getDatasourceSettings(state),
    hasExchangeLicense:
      ResultsOffice365Store.selectors.hasExchangeLicense(state),
    resultsOneIntranetCombined:
      ResultsOneIntranetStore.selectors.getResultsCombined(state),
    resultsMarketResearchCombined:
      ResultsMarketResearchStore.selectors.getResultsCombined(state),
    hasLicenseBeenChecked:
      ResultsOffice365Store.selectors.hasLicenseBeenChecked(state),
    newsFilter: ResultsNewsStore.selectors.getFilters(state),
    kpmgMppFilter: ResultsMppStore.selectors.getFilters(state),
    peopleOIFilter: ResultsPeopleStore.selectors.getFilters(state),
    kpmgusFilter: ResultsKpmgUSStore.selectors.getFilters(state),
    ibfdFilter: ResultsIbfdStore.selectors.getFilters(state),
    resultMetaData: ResultMetaData.selectors.getResultMeta(state),
    currentfilters: FilterStore.selectors.getCurrentFilters(state),
    temporaryFilters: FilterStore.selectors.getTemporaryFilters(state),
    useCognitiveSearch: SettingsStore.selectors.getUseCognitiveSearch(state),
    knowledgeExchangeFilter: ResultsKnowledgeExchangeStore.selectors.getFilters(
      state,
      'open'
    )
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchResultsOneIntranet: (
      searchQuery: string,
      currentPage: number,
      userSettings: IUserSetting,
      deviceSettings: IDeviceSetting,
      filters: { [key: string]: string },
      findConfiguration: IFindConfiguration
    ) =>
      dispatch(
        ResultsOneIntranetStore.actions.fetchResultsOneIntranet(
          searchQuery,
          currentPage,
          userSettings,
          deviceSettings,
          filters,
          findConfiguration
        )
      ),
    fetchResultsPeople: (
      searchQuery: string,
      currentPage: number,
      userSettings: IUserSetting,
      deviceSettings: IDeviceSetting,
      filters: { [key: string]: string },
      findConfiguration: IFindConfiguration
    ) =>
      dispatch(
        ResultsPeopleStore.actions.fetchResultsPeople(
          searchQuery,
          currentPage,
          userSettings,
          deviceSettings,
          filters,
          findConfiguration
        )
      ),
    fetchResultsNews: (
      searchQuery: string,
      currentPage: number,
      userSettings: IUserSetting,
      deviceSettings: IDeviceSetting,
      filters: { [key: string]: string },
      findConfiguration: IFindConfiguration
    ) =>
      dispatch(
        ResultsNewsStore.actions.fetchResultsNews(
          searchQuery,
          currentPage,
          userSettings,
          deviceSettings,
          filters,
          findConfiguration
        )
      ),
    fetchResultsNewsFilters: (
      searchQuery: string,
      currentPage: number,
      userSettings: IUserSetting,
      deviceSettings: IDeviceSetting,
      filters: { [key: string]: string },
      findConfiguration: IFindConfiguration
    ) =>
      dispatch(
        ResultsNewsStore.actions.fetchResultsNewsFilters(
          searchQuery,
          currentPage,
          filters,
          findConfiguration
        )
      ),
    fetchResultsVideos: (
      searchQuery: string,
      currentPage: number,
      userSettings: IUserSetting,
      deviceSettings: IDeviceSetting,
      filters: { [key: string]: string },
      findConfiguration: IFindConfiguration
    ) =>
      dispatch(
        ResultsVideosStore.actions.fetchResultsVideos(
          searchQuery,
          currentPage,
          userSettings,
          deviceSettings,
          filters,
          findConfiguration
        )
      ),
    fetchResultsImages: (
      searchQuery: string,
      currentPage: number,
      userSettings: IUserSetting,
      deviceSettings: IDeviceSetting,
      filters: { [key: string]: string },
      findConfiguration: IFindConfiguration
    ) =>
      dispatch(
        ResultsImagesStore.actions.fetchResultsImages(
          searchQuery,
          currentPage,
          userSettings,
          deviceSettings,
          filters,
          findConfiguration
        )
      ),
    fetchResultsAEM: (
      searchQuery: string,
      currentPage: number,
      userSettings: IUserSetting,
      deviceSettings: IDeviceSetting,
      filters: { [key: string]: string }
    ) =>
      dispatch(
        ResultsAEMStore.actions.fetchResultsAEM(
          searchQuery,
          currentPage,
          deviceSettings,
          userSettings,
          filters
        )
      ),
    fetchResultsAlex: (
      searchQuery: string,
      currentPage: number,
      userSettings: IUserSetting,
      deviceSettings: IDeviceSetting,
      filters: { [key: string]: string }
    ) =>
      dispatch(
        ResultsAlexStore.actions.fetchResultsAlex(
          searchQuery,
          currentPage,
          userSettings,
          deviceSettings,
          filters
        )
      ),
    fetchResultsContentFinder: (
      searchQuery: string,
      currentPage: number,
      deviceSettings: IDeviceSetting
    ) =>
      dispatch(
        ResultsContentFinderStore.actions.fetchResultsContentFinder(
          searchQuery,
          currentPage,
          deviceSettings
        )
      ),
    fetchResultsEins: (
      searchQuery: string,
      currentPage: number,
      deviceSettings: IDeviceSetting
    ) =>
      dispatch(
        ResultsEinsStore.actions.fetchResultsEins(
          searchQuery,
          currentPage,
          deviceSettings
        )
      ),
    fetchResultsEngagements: (
      searchQuery: string,
      currentPage: number,
      userSettings: IUserSetting,
      deviceSettings: IDeviceSetting,
      filters: {
        [key: string]: string
      },
      findConfiguration: IFindConfiguration
    ) =>
      dispatch(
        ResultsEngagementsStore.actions.fetchResultsEngagements(
          searchQuery,
          currentPage,
          userSettings,
          deviceSettings,
          filters,
          findConfiguration
        )
      ),
    fetchResultsKlardenker: (
      searchQuery: string,
      currentPage: number,
      userSettings: IUserSetting,
      deviceSettings: IDeviceSetting,
      filters: { [key: string]: string }
    ) =>
      dispatch(
        ResultsKlardenkerStore.actions.fetchResultsKlardenker(
          searchQuery,
          currentPage,
          userSettings,
          deviceSettings,
          filters
        )
      ),
    fetchResultsKnowledgeExchange: (
      searchQuery: string,
      currentPage: number,
      userSettings: IUserSetting,
      deviceSettings: IDeviceSetting,
      filters: { [key: string]: string },
      type: any
    ) =>
      dispatch(
        ResultsKnowledgeExchangeStore.actions.fetchResultsKnowledgeExchange(
          searchQuery,
          currentPage,
          deviceSettings,
          filters,
          type,
          userSettings
        )
      ),
    fetchDocumentsResultsMarketResearch: (
      searchQuery: string,
      currentPage: number,
      userSettings: IUserSetting,
      deviceSettings: IDeviceSetting,
      filters: { [key: string]: string },
      findConfiguration: IFindConfiguration
    ) =>
      dispatch(
        ResultsMarketResearchStore.actions.fetchDocumentsResultsMarketResearch(
          searchQuery,
          currentPage,
          userSettings,
          deviceSettings,
          filters,
          findConfiguration
        )
      ),
    fetchDocumentsCountMarketResearch: (
      searchQuery: string,
      currentPage: number,
      userSettings: IUserSetting,
      deviceSettings: IDeviceSetting,
      filters: { [key: string]: string }
    ) =>
      dispatch(
        ResultsMarketResearchStore.actions.fetchDocumentsCountMarketResearch(
          searchQuery,
          currentPage,
          userSettings,
          deviceSettings,
          filters
        )
      ),
    fetchResultsProjectSpaces: (
      searchQuery: string,
      currentPage: number,
      deviceSettings: IDeviceSetting
    ) =>
      dispatch(
        ResultsProjectSpacesStore.actions.fetchResultsProjectSpaces(
          searchQuery,
          currentPage,
          deviceSettings
        )
      ),
    fetchResultsResearch: (
      searchQuery: string,
      currentPage: number,
      deviceSettings: IDeviceSetting,
      userSettings: IUserSetting
    ) =>
      dispatch(
        ResultsResearchStore.actions.fetchResultsResearch(
          searchQuery,
          currentPage,
          deviceSettings,
          userSettings
        )
      ),
    fetchResultsSource: (
      searchQuery: string,
      currentPage: number,
      userSettings: IUserSetting,
      deviceSettings: IDeviceSetting,
      filters: { [key: string]: string },
      findConfiguration: IFindConfiguration
    ) =>
      dispatch(
        ResultsSourceStore.actions.fetchResultsSource(
          searchQuery,
          currentPage,
          userSettings,
          deviceSettings,
          filters,
          findConfiguration
        )
      ),
    fetchResultsStatistics: (
      searchQuery: string,
      currentPage: number,
      userSettings: IUserSetting,
      deviceSettings: IDeviceSetting,
      filters: { [key: string]: string }
    ) =>
      dispatch(
        ResultsStatisticsStore.actions.fetchResultsStatistics(
          searchQuery,
          currentPage,
          userSettings,
          deviceSettings,
          filters
        )
      ),
    fetchResultsOffice365: (
      searchQuery: string,
      currentPage: number,
      userSettings: IUserSetting,
      deviceSettings: IDeviceSetting,
      findConfiguration: IFindConfiguration,
      filters: { [key: string]: string },
      type: any,
      hasLicenseBeenChecked: boolean,
      hasExchangeLicense: boolean,
      currentDataSourceName: string
    ) =>
      dispatch(
        ResultsOffice365Store.actions.fetchResultsOffice365(
          searchQuery,
          currentPage,
          userSettings,
          deviceSettings,
          findConfiguration,
          filters,
          type,
          hasLicenseBeenChecked,
          hasExchangeLicense,
          false,
          currentDataSourceName
        )
      ),
    fetchResultsMpp: (
      searchQuery: string,
      currentPage: number,
      userSettings: IUserSetting,
      deviceSettings: IDeviceSetting,
      filters: {
        [key: string]: string
      },
      findConfiguration: IFindConfiguration
    ) =>
      dispatch(
        ResultsMppStore.actions.fetchResultsMpp(
          searchQuery,
          currentPage,
          userSettings,
          deviceSettings,
          filters,
          findConfiguration
        )
      ),
    fetchResultsMppFilters: (
      searchQuery: string,
      currentPage: number,
      userSettings: IUserSetting,
      deviceSettings: IDeviceSetting,
      filters: {
        [key: string]: string
      }
    ) =>
      dispatch(
        ResultsMppStore.actions.fetchResultsMppFilters(
          searchQuery,
          currentPage,
          userSettings,
          deviceSettings,
          filters
        )
      ),
    fetchResultsIbfd: (
      searchQuery: string,
      currentPage: number,
      userSettings: IUserSetting,
      deviceSettings: IDeviceSetting,
      filters: { [key: string]: string }
    ) =>
      dispatch(
        ResultsIbfdStore.actions.fetchResultsIbfd(
          searchQuery,
          currentPage,
          userSettings,
          deviceSettings,
          filters
        )
      ),
    fetchResultsKpmgUS: (
      searchQuery: string,
      currentPage: number,
      userSettings: IUserSetting,
      deviceSettings: IDeviceSetting,
      filters: { [key: string]: string },
      findConfiguration: IFindConfiguration
    ) =>
      dispatch(
        ResultsKpmgUSStore.actions.fetchResultsKpmgUS(
          searchQuery,
          currentPage,
          userSettings,
          deviceSettings,
          filters,
          findConfiguration
        )
      ),
    triggerOneIntranetDataSourceQuery: (searchQuery: string) =>
      dispatch(
        ResultsOneIntranetStore.actions.triggerOneIntranetDataSourceQuery(
          searchQuery
        )
      ),
    triggerEngagementsDataSourceQuery: (searchQuery: string) =>
      dispatch(
        ResultsEngagementsStore.actions.triggerEngagementsDataSourceQuery(
          searchQuery
        )
      ),
    triggerVideoDataSourceQuery: (searchQuery: string) =>
      dispatch(
        ResultsVideosStore.actions.triggerVideoDataSourceQuery(searchQuery)
      ),
    triggerImageDataSourceQuery: (searchQuery: string) =>
      dispatch(
        ResultsImagesStore.actions.triggerImageDataSourceQuery(searchQuery)
      ),
    triggerPeopleDataSourceQuery: (searchQuery: string) =>
      dispatch(
        ResultsPeopleStore.actions.triggerPeopleDataSourceQuery(searchQuery)
      ),
    setCurrentFilter: (currentFilter: CurrentFilter[]) =>
      dispatch(FilterStore.actions.setCurrentFilter(currentFilter)),
    fetchAutocorrect: (
      searchQuery: string,
      userSettings: IUserSetting,
      findConfiguration: IFindConfiguration
    ) =>
      dispatch(
        SearchStore.actions.fetchAutocorrect(
          searchQuery,
          userSettings,
          findConfiguration
        )
      ),
    fetchBookmarks: () => dispatch(MSAnswers.actions.fetchBookmarks()),
    fetchQnAs: () => dispatch(MSAnswers.actions.fetchqnas()),
    fetchAcronyms: () => dispatch(MSAnswers.actions.fetchacronyms()),
    clearTemporaryFilter: () =>
      dispatch(FilterStore.actions.clearTemporaryFilter())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentFetch)
