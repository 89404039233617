import React, {
  Dispatch,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import ReactPlaceholder from 'react-placeholder'
import { connect } from 'react-redux'
import { Box, Fade, Grid, ListItemIcon, Typography } from '@mui/material'
import 'Placeholder.css'
import { Store } from 'store'
import UserSettingsStore from 'store/UserSettings'
import SettingsStore from 'store/Settings'
import ResultMetaDataStore from 'store/ResultMetaData'
import ResultsVideosStore from 'store/ResultsVideos'
import PageStay from 'components/HOC/PageStay'
import ErrorPage from 'components/contents/common/ErrorPage'
import ResultsVideosMobile from './ResultsVideosMobile'
import Video from '../results/VideoResult'
import Pagination from '../Pagination'
import { trackException } from 'utils/tracking'
import { useReactRouterQueryStringInterface } from 'utils/useQueryState'
import { authorizeResultPage } from 'utils/authorization'
import createDOMPurify from 'dompurify'
import { dataSourcesTabs } from 'constants/constants'
import SearchSuggestions from 'components/contents/common/SearchSuggestions'
import { getStylesResultsVideos } from 'styles/contents/resultpages/ResultsVideos'
import FeaturedResults from './FeaturedResults'
import RightTopWidgetContainer from './widgetscontainer/RightTopWidgetContainer'
import { FormattedMessage, useIntl } from 'react-intl'
import RightWidgetContainer from './widgetscontainer/RightWidgetContainer'
import { getStylesResults } from 'styles/contents/resultpages/Results'
import { useFilter } from 'utils/filters'
import { getStylesErrorPage } from 'styles/contents/common/ErrorPage'
import { ErrorOutline } from '@mui/icons-material'
import { IUserSetting } from 'utils/userSettings'
import { CognitiveMessage } from '../common/CognitiveMessage'
import { KPMGFindGlobalVariables } from 'store/KPMGFindGlobalVariables'
import { IResult } from 'store/ResultsVideos/reducers'
import { useLocation } from 'react-router-dom'

export interface ResultsVideosProps {
  appliedFilters: any
}

type AllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  ResultsVideosProps

function ResultsVideos(props: AllProps) {
  const {
    featuredResults,
    results,
    resultsCombined,
    hasError,
    totalRowCount,
    lastRow,
    hasResultsBeenFetched,
    hasErrorStore,
    userSettings,
    deviceSettings,
    findConfiguration,
    setUserSettings,
    useCognitiveSearch
  } = props

  const [cognitiveSearchEnabled, setCognitiveSearchEnabled] =
    useState(useCognitiveSearch)
  const [showCognitiveMessage, setShowCognitiveMessage] = useState(
    userSettings.ShowCognitiveMessage
  )

  const DOMPurify = createDOMPurify(window)
  const resultClasses = getStylesResults()
  const errorClasses = getStylesErrorPage()
  const classes = getStylesResultsVideos()
  const { getQueryString } = useReactRouterQueryStringInterface()
  const intl = useIntl()
  const queryStringFromUrl = getQueryString()
  const [currentPage] = useFilter('page', '1')
  const [searchQuery] = useFilter('q')
  const location = useLocation()

  useEffect(() => {
    KPMGFindGlobalVariables.setCurrentTab(dataSourcesTabs.videos)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(
    () => {
      setCognitiveSearchEnabled(useCognitiveSearch)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [useCognitiveSearch]
  )

  useEffect(
    () => {
      setShowCognitiveMessage(userSettings.ShowCognitiveMessage)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userSettings.ShowCognitiveMessage]
  )

  const setup = useCallback(() => {
    try {
      authorizeResultPage(
        'Videos',
        userSettings,
        deviceSettings,
        DOMPurify.sanitize(location && location.search ? location.search : '')
      )
    } catch (error) {
      trackException('Error in fetching results in ResultsVideos.tsx', error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryStringFromUrl])

  useEffect(() => {
    setup()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryStringFromUrl])

  const returnAllItems = (type: string, _results: IResult) => {
    if (
      !_results ||
      !_results.queryResults ||
      _results.queryResults.length < 1
    ) {
      return []
    }

    const items = []

    try {
      const firstResults =
        _results.queryResults.length < 4 ? _results.queryResults.length : 3

      if (type === 'first') {
        for (let i = 0; i < firstResults; i++) {
          items.push(
            <Grid item xs={6} sm={4}>
              <Video
                title={DOMPurify.sanitize(_results.queryResults[i].Title)}
                subtitle={'Intranet'}
                image={DOMPurify.sanitize(
                  _results.queryResults[i].PictureThumbnailUrl
                )}
                siteId={DOMPurify.sanitize(_results.queryResults[i].SiteID)}
                webId={DOMPurify.sanitize(_results.queryResults[i].WebID)}
                listId={DOMPurify.sanitize(_results.queryResults[i].ListID)}
                listItemId={DOMPurify.sanitize(
                  _results.queryResults[i].ListItemID
                )}
                publishDate={DOMPurify.sanitize(_results.queryResults[i].Date)}
                url={DOMPurify.sanitize(_results.queryResults[i].Path)}
                size={DOMPurify.sanitize(_results.queryResults[i].Size)}
                deviceSettings={deviceSettings}
                index={i}
                viewsLifeTime={_results.queryResults[i].ViewsLifeTime}
              />
            </Grid>
          )
        }
      } else {
        for (let i = firstResults; i < _results.queryResults.length; i++) {
          items.push(
            <Grid item xs={6} sm={4}>
              <Video
                title={DOMPurify.sanitize(_results.queryResults[i].Title)}
                subtitle={'Intranet'}
                image={DOMPurify.sanitize(
                  _results.queryResults[i].PictureThumbnailUrl
                )}
                siteId={DOMPurify.sanitize(_results.queryResults[i].SiteID)}
                webId={DOMPurify.sanitize(_results.queryResults[i].WebID)}
                listId={DOMPurify.sanitize(_results.queryResults[i].ListID)}
                listItemId={DOMPurify.sanitize(
                  _results.queryResults[i].ListItemID
                )}
                publishDate={DOMPurify.sanitize(_results.queryResults[i].Date)}
                url={DOMPurify.sanitize(_results.queryResults[i].Path)}
                size={DOMPurify.sanitize(_results.queryResults[i].Size)}
                deviceSettings={deviceSettings}
                index={i}
                viewsLifeTime={_results.queryResults[i].ViewsLifeTime}
              />
            </Grid>
          )
        }
      }
    } catch (error) {
      trackException('Error returning all items in ResultsVideos.tsx', error)
    }
    return items
  }

  const firstItems = useMemo(
    () => returnAllItems('first', results),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [results, resultsCombined, intl]
  )

  const restItems = useMemo(
    () => returnAllItems('rest', results),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [results, resultsCombined, intl]
  )

  return (
    <Box>
      {!hasErrorStore && !!searchQuery && (
        <>
          {deviceSettings.renderMobile ? (
            <ResultsVideosMobile
              {...props}
              deviceSettings={deviceSettings}
              userSettings={userSettings}
              findConfiguration={findConfiguration}
              cognitiveSearchEnabled={cognitiveSearchEnabled}
              showCognitiveMessage={showCognitiveMessage}
              setShowCognitiveMessage={setShowCognitiveMessage}
              setUserSettings={setUserSettings}
            />
          ) : (
            <Box className={resultClasses.container}>
              <Box
                id={'con-resultvertical-01'}
                className={resultClasses.resultsContainer}
              >
                <SearchSuggestions
                  wildCardActive={true}
                  resultCount={totalRowCount}
                  resultsFetchend={hasResultsBeenFetched}
                />
                <ReactPlaceholder
                  ready={hasResultsBeenFetched ? true : false}
                  type="text"
                  rows={4}
                  showLoadingAnimation={true}
                  className={resultClasses.placeholder}
                >
                  <Fade
                    in={
                      hasResultsBeenFetched || Number(currentPage) > 1
                        ? true
                        : false
                    }
                    timeout={600}
                  >
                    <Box id={'con-resultvertical-section-01'}>
                      {hasError && (
                        <Box
                          id={'con-err-01'}
                          className={errorClasses.errorcontainer}
                        >
                          <Grid
                            container
                            direction="row"
                            alignItems="center"
                            wrap="nowrap"
                            className={errorClasses.gridContainer}
                          >
                            <Grid item>
                              <ListItemIcon>
                                <ErrorOutline className={errorClasses.icon} />
                              </ListItemIcon>
                            </Grid>
                            <Grid
                              item
                              className={errorClasses.messagesContainer}
                            >
                              <Typography
                                variant="body1"
                                className={errorClasses.errorHeader}
                              >
                                {hasError && (
                                  <FormattedMessage
                                    id="error_message_oi"
                                    defaultMessage="One Intranet results are currently not available."
                                  />
                                )}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      )}
                      {cognitiveSearchEnabled &&
                        showCognitiveMessage &&
                        findConfiguration.CognitiveSearchEnabled &&
                        results.synonymsApplied &&
                        results.synonymsApplied.length > 0 && (
                          <CognitiveMessage
                            synonymsApplied={results.synonymsApplied}
                            setShowCognitiveMessage={setShowCognitiveMessage}
                            userSettings={userSettings}
                            setUserSettings={setUserSettings}
                          />
                        )}
                      {results && totalRowCount > 0 && (
                        <FeaturedResults featuredResults={featuredResults} />
                      )}
                      <Grid
                        container
                        spacing={deviceSettings.renderMobile ? 1 : 3}
                        className={classes.grid}
                      >
                        {firstItems}
                      </Grid>
                    </Box>
                  </Fade>
                </ReactPlaceholder>
                <ReactPlaceholder
                  ready={hasResultsBeenFetched ? true : false}
                  type="text"
                  rows={4}
                  showLoadingAnimation={true}
                  className={resultClasses.placeholder}
                >
                  <Fade
                    in={
                      hasResultsBeenFetched || Number(currentPage) > 1
                        ? true
                        : false
                    }
                    timeout={600}
                  >
                    <Box id={'con-resultvertical-section-02'}>
                      {' '}
                      <Grid
                        container
                        spacing={deviceSettings.renderMobile ? 1 : 3}
                        className={classes.grid}
                      >
                        {restItems}
                      </Grid>
                    </Box>
                  </Fade>
                </ReactPlaceholder>
                <ReactPlaceholder
                  ready={hasResultsBeenFetched}
                  type="text"
                  rows={4}
                  showLoadingAnimation={true}
                  className={resultClasses.placeholder}
                >
                  <Fade in={hasResultsBeenFetched} timeout={600}>
                    <Box>
                      {totalRowCount != null && totalRowCount > 0 && (
                        <Pagination
                          {...props}
                          totalRecords={totalRowCount}
                          pageLimit={21}
                          pageNeighbours={4}
                          loadMore={false}
                          lastRow={lastRow}
                        />
                      )}
                    </Box>
                  </Fade>
                </ReactPlaceholder>
              </Box>
              <Box className={resultClasses.advertisementContainer}>
                <RightTopWidgetContainer
                  searchQuery={searchQuery}
                  scope={'videos'}
                />
                <RightWidgetContainer />
              </Box>
            </Box>
          )}
        </>
      )}
      {hasErrorStore && <ErrorPage dataSource="videos" />}
    </Box>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    featuredResults: ResultsVideosStore.selectors.getFeaturedResults(state),
    results: ResultsVideosStore.selectors.getResults(state),
    resultsCombined: ResultsVideosStore.selectors.getResultsCombined(state),
    totalRowCount: ResultsVideosStore.selectors.getTotalRowCount(state),
    lastRow: ResultsVideosStore.selectors.getLastRow(state),
    hasError: ResultsVideosStore.selectors.getHasOIError(state),
    executionTime: ResultsVideosStore.selectors.getExecutionTime(state),
    hasResultsBeenFetched:
      ResultsVideosStore.selectors.hasResultsBeenFetched(state),
    hasErrorStore: ResultMetaDataStore.selectors.getDataSourceHasError(
      state,
      'videos'
    ),
    userSettings: UserSettingsStore.selectors.getUserSettings(state),
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state),
    findConfiguration: SettingsStore.selectors.getFindConfiguration(state),
    useCognitiveSearch: SettingsStore.selectors.getUseCognitiveSearch(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    setUserSettings: (userSettings: IUserSetting) =>
      dispatch(UserSettingsStore.actions.upSertUserSettings(userSettings))
  }
}

export default PageStay(
  connect(mapStateToProps, mapDispatchToProps)(ResultsVideos)
)
