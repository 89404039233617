import React, { Dispatch } from 'react'
import { IconButton } from '@mui/material'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import { getStylesArrows } from 'styles/contents/common/Arrows'

export interface LeftArrowProps {
  className?: string
  onClick?: Dispatch<any>
}

export function LeftArrow(props: LeftArrowProps): JSX.Element {
  const { className, onClick } = props
  const classes = getStylesArrows()
  return (
    <div
      id="btn-left-01"
      role="button"
      className={`${classes.sliderButtonLeft} ${className}`}
      onClick={onClick}
    >
      <IconButton className={classes.sliderButton} onClick={onClick}>
        <KeyboardArrowLeft />
      </IconButton>
    </div>
  )
}

export interface RightArrowProps {
  className?: string
  onClick?: Dispatch<any>
}

export function RightArrow(props: RightArrowProps): JSX.Element {
  const { className, onClick } = props
  const classes = getStylesArrows()
  return (
    <div
      id="btn-right-01"
      className={`${classes.sliderButtonRight} ${className}`}
      onClick={onClick}
    >
      <IconButton className={classes.sliderButton} onClick={onClick}>
        <KeyboardArrowRight />
      </IconButton>
    </div>
  )
}
