import React, { useLayoutEffect, useRef, useState } from 'react'
import { Tooltip } from '@mui/material'
import LinesEllipsis from 'react-lines-ellipsis'
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'

export interface ITooltipTitleProps {
  title?: string
  singleLine?: boolean
  additionalClass?: string
  wrapperClass?: string
  useCssOnly?: boolean
  checkSizeOnTitleChange?: boolean
}

const TooltipTitle = (props: ITooltipTitleProps): JSX.Element => {
  const {
    title,
    singleLine,
    additionalClass,
    wrapperClass,
    useCssOnly,
    checkSizeOnTitleChange /** Bug 2133040: tooltip must be checked on title change, the effect is not triggered in case only text is changed without rerendering the complete component */
  } = props

  const [overflowing, setOverflowing] = useState(false)
  const titleItemRef = useRef(null)
  const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis)

  const handleReflow = (rleState: any) => {
    const { clamped } = rleState
    setOverflowing(clamped)
  }

  const isCssOverFlowing = (element: HTMLElement) => {
    return (
      element.scrollHeight > element.clientHeight ||
      element.scrollWidth > element.clientWidth
    )
  }

  useLayoutEffect(
    () => {
      if (useCssOnly) {
        const updateSize = () => {
          if (titleItemRef && titleItemRef.current) {
            const isOverflowing = isCssOverFlowing(titleItemRef.current)
            setOverflowing(isOverflowing)
          }
        }
        window.addEventListener('resize', updateSize)
        updateSize()
        return () => window.removeEventListener('resize', updateSize)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    checkSizeOnTitleChange && useCssOnly ? [title] : []
  )

  if (useCssOnly)
    return (
      <Tooltip title={title} disableHoverListener={!overflowing}>
        <div
          ref={titleItemRef}
          {...(additionalClass ? { className: additionalClass } : {})}
          style={{
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
          }}
        >
          {title}
        </div>
      </Tooltip>
    )

  return (
    <Tooltip title={title} disableHoverListener={!overflowing}>
      <div {...(wrapperClass ? { className: wrapperClass } : {})}>
        <ResponsiveEllipsis
          {...(additionalClass
            ? { className: additionalClass }
            : (title && title.indexOf(' ') === -1) || !singleLine
              ? {
                  style: {
                    whiteSpace: 'pre-wrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    overflowWrap: 'break-word',
                    wordBreak: 'break-word',
                    wordWrap: 'break-word'
                  }
                }
              : {
                  style: {
                    whiteSpace: 'pre-wrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden'
                  }
                })}
          onReflow={handleReflow}
          basedOn={
            (title && title.indexOf(' ') === -1) || !singleLine
              ? 'letters'
              : 'words'
          }
          text={title}
          maxLine={singleLine ? '1' : '2'}
        />
      </div>
    </Tooltip>
  )
}

export default TooltipTitle
