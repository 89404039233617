import {
  IDataDataSourceDefinition,
  dataSourceOrderConfiguration
} from 'constants/dataSourcesOrderConfiguration'
//import { datasourcesFunctionOrder } from 'constants/datasourcesFunctionOrder'
import {
  datasourcesConfiguration,
  IDataSource
} from 'constants/datasourcesConfiguration'
import { IUserSetting } from './userSettings'
import { IDeviceSetting } from './deviceSettings'
import { IDataSourceOrderCountry } from 'constants/datasourcesOrderCountry'

/**
 * Filters the user data sources
 * @param userSettings The user settings
 * @param deviceSettings   The device settings
 * @param datasourceSettings   The loaded datasource settings
 * @param updateUserSettings   Update the usersettings, datasource order and disabled datasources by config
 */
export function getUserDataSources(
  userSettings: IUserSetting,
  deviceSettings: IDeviceSetting,
  datasourceSettings: IDataSource[],
  updateUserSettings = false
): any[] {
  const dataSourcesOrder: any[] | null = userSettings.DataSourceOrder
    ? JSON.parse(userSettings.DataSourceOrder)
    : null

  const dataSourcesDisabled: any[] | null = userSettings.DataSourceDisabled
    ? JSON.parse(userSettings.DataSourceDisabled)
    : null

  let filteredOrderedDSListByUserSettings: IDataSource[] = []

  filteredOrderedDSListByUserSettings =
    getUserAvailableDatasourcesByOrderConfig(userSettings)

  // Order datasorces by user settings
  const sortedDataSources = sortDatasourceByUserOrder(
    filteredOrderedDSListByUserSettings,
    dataSourcesOrder
  )

  const newDataSources =
    sortedDataSources.length > 0
      ? sortedDataSources
      : filteredOrderedDSListByUserSettings

  // Disable or Enable datasources by user settings
  disableEnableDatasourceByUser(newDataSources, dataSourcesDisabled)

  // Filter datasources by criteria config
  checkDatasourceCriteria(newDataSources, deviceSettings, datasourceSettings)

  // Update userSettings
  if (updateUserSettings) {
    userSettings.DataSourceOrder = newDataSources
      ? JSON.stringify(
          newDataSources.map((ds: any) => {
            return ds.name
          })
        )
      : JSON.stringify([])

    userSettings.DataSourceDisabled = newDataSources
      ? JSON.stringify(
          newDataSources.map((ds: any) => {
            if (ds.enabled) return ''
            else return ds.name
          })
        )
      : JSON.stringify([])
  }

  return newDataSources
}

/**
 * If the current datasource has been disabled, navigate to the first enabled one.
 * @param currentLocation The current Location
 * @param currentDatasources The current Data Sources
 */
export function navigateToEnabledDatasource(
  currentLocation: string,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  currentDatasources: any
): void {
  if (currentLocation !== '') {
    const dataSources = currentDatasources.filter(
      (ds: any) => currentLocation.indexOf(ds.path) >= 0
    )
    if (dataSources && dataSources.length === 1) {
      if (!dataSources[0].enabled) {
        const enabledDataSources = currentDatasources.filter(
          (ds: any) => ds.enabled
        )
        if (enabledDataSources && enabledDataSources.length > 0) {
          const newURL = currentLocation.replace(
            dataSources[0].path,
            enabledDataSources[0].path
          )
          window.location.href = newURL
        }
      }
    }
  }
}

export const getUserAvailableDatasourcesByOrderConfig = (
  userSettings: IUserSetting
): IDataSource[] => {
  let resultDataSources: Array<IDataDataSourceDefinition> | undefined =
    undefined

  // Validate and check for the sorting order to be applied

  // At first check for a country definition
  let cByCountry = undefined
  if (userSettings.Country) {
    cByCountry = dataSourceOrderConfiguration.byCountry.find(
      (countryConfig: IDataSourceOrderCountry) => {
        return countryConfig.countries.includes(userSettings.Country)
      }
    )
  }

  // Check for a function definition
  let fByFunctionCountry = undefined
  let fByFunction = undefined
  if (userSettings.Function && userSettings.Country) {
    // get all defintion for the current function
    fByFunctionCountry = dataSourceOrderConfiguration.byFunction.find(
      (funcConfig) => {
        if (funcConfig.countries)
          return (
            funcConfig.functions.includes(userSettings.Function) &&
            funcConfig.countries.includes(userSettings.Country)
          )
        return false
      }
    )
  }

  if (userSettings.Function) {
    fByFunction = dataSourceOrderConfiguration.byFunction.find((funcConfig) => {
      if (!funcConfig.countries)
        return funcConfig.functions.includes(userSettings.Function)
      return false
    })
  }

  // Check for a department definition
  let dbyDepartmentFunctionCountry
  let dByDepartmentFunction
  let dByDepartment
  if (
    userSettings.Department &&
    userSettings.Country &&
    userSettings.Function
  ) {
    // get all defintion for the current department
    dbyDepartmentFunctionCountry =
      dataSourceOrderConfiguration.byDepartment.find((funcConfig) => {
        if (funcConfig.functions && funcConfig.countries)
          return (
            funcConfig.department.includes(userSettings.Department) &&
            funcConfig.functions.includes(userSettings.Function) &&
            funcConfig.countries.includes(userSettings.Country)
          )
        return false
      })
  }
  if (userSettings.Department && userSettings.Function) {
    dByDepartmentFunction = dataSourceOrderConfiguration.byDepartment.find(
      (funcConfig) => {
        if (funcConfig.functions && !funcConfig.countries)
          return (
            funcConfig.department.includes(userSettings.Department) &&
            funcConfig.functions.includes(userSettings.Function)
          )
        return false
      }
    )
  }

  if (userSettings.Department) {
    dByDepartment = dataSourceOrderConfiguration.byDepartment.find(
      (funcConfig) => {
        if (!funcConfig.functions && !funcConfig.countries)
          return funcConfig.department.includes(userSettings.Department)
        return false
      }
    )
  }

  if (!resultDataSources && dbyDepartmentFunctionCountry)
    resultDataSources = dbyDepartmentFunctionCountry.sources

  if (!resultDataSources && fByFunctionCountry)
    resultDataSources = fByFunctionCountry.sources

  if (!resultDataSources && cByCountry) resultDataSources = cByCountry.sources

  if (!resultDataSources && dByDepartmentFunction)
    resultDataSources = dByDepartmentFunction.sources

  if (!resultDataSources && fByFunction) resultDataSources = fByFunction.sources

  if (!resultDataSources && dByDepartment)
    resultDataSources = dByDepartment.sources

  // As fallback take defaul config
  if (!resultDataSources)
    resultDataSources = dataSourceOrderConfiguration.default

  // Collect datasources via sources in right order
  const countryFilteredDSList: Array<IDataSource> = []

  resultDataSources.forEach((ds: IDataDataSourceDefinition) => {
    if (!ds.hidden) {
      const dataSourceConfigObject = datasourcesConfiguration.find(
        (dsConfig) => {
          return dsConfig.name === ds.name
        }
      )

      if (dataSourceConfigObject) {
        // In case the user settings didn't have any Disabled datasource
        // disable the datasource in case it's defined in the custom order
        if (!userSettings.DataSourceDisabled && ds.disabled) {
          dataSourceConfigObject.enabled = false
        }

        countryFilteredDSList.push(Object.assign({}, dataSourceConfigObject))
      }
    }
  })

  return countryFilteredDSList
}

export const checkDatasourceCriteria = (
  dataSourceList: any[],
  deviceSettings: IDeviceSetting,
  datasourceSettings: IDataSource[]
) => {
  const isDesktop: boolean = !deviceSettings.isMobile
  const deletedDatasourcesByCriteriaList: any[] = []
  for (const datasource of dataSourceList) {
    const criteriaRule = datasourcesConfiguration.find(
      (c: IDataSource) => c.name === datasource.name
    )
    if (criteriaRule) {
      // Test active
      let active = false
      if (datasourceSettings && datasourceSettings.length > 0) {
        // Datasource settings have been retrieved from cosmos db.
        // If current datasource is part of datasourceSettings, it is active
        const dsActive = datasourceSettings.find(
          (c: IDataSource) =>
            c.name.toLowerCase() === datasource.name.toLowerCase() &&
            c.active === true
        )
        if (dsActive) {
          datasource.enabledForChat = dsActive.enabledForChat
          active = true
        } else {
          datasource.enabledForChat = false
        }
      } else {
        // Default to the solution file active property
        active = criteriaRule.active
      }
      if (active) {
        // Test device
        let deviceMatch = !criteriaRule.if.device
        if (criteriaRule.if.device) {
          criteriaRule.if.device.find(
            (device: any) => device === 'Mobile' && deviceSettings.renderMobile
          )
            ? (deviceMatch = true)
            : criteriaRule.if.device.find(
                (device: any) => device === 'Desktop' && isDesktop
              )
            ? (deviceMatch = true)
            : criteriaRule.if.device.find((device: any) => device === 'All')
            ? (deviceMatch = true)
            : (deviceMatch = false)
        }
        if (deviceMatch) {
          // Test browser
          const browserMatch = !(
            criteriaRule.ifNot && criteriaRule.ifNot.browser
          )
          if (criteriaRule.ifNot && criteriaRule.ifNot.browser) {
            // Logic for ifnot, currently unused
          }
          if (!browserMatch) {
            deletedDatasourcesByCriteriaList.push(datasource)
          }
        } else {
          deletedDatasourcesByCriteriaList.push(datasource)
        }
      } else {
        deletedDatasourcesByCriteriaList.push(datasource)
      }
    }
  }

  // Remove datasources by criteria
  deletedDatasourcesByCriteriaList.forEach((value, index) => {
    const deleteIndex = dataSourceList.findIndex(
      (item: any) => item.name === value.name
    )
    dataSourceList.splice(deleteIndex, 1)
  })
}

const disableEnableDatasourceByUser = (
  datasourceList: any[],
  datasourcesDisabled: any[] | null
) => {
  if (datasourcesDisabled && datasourcesDisabled.length > 0) {
    // Disable all datasources that are disabled through usersettings
    for (const dataSourceName of datasourcesDisabled) {
      const dataSource = datasourceList.find((ds) => ds.name === dataSourceName)
      if (dataSource) dataSource.enabled = false
    }

    // Enable all datasources that are not disabled through userSettings
    datasourceList.forEach((ds) => {
      if (datasourcesDisabled.indexOf(ds.name) === -1) {
        ds.enabled = true
      }
    })
  }
}

const sortDatasourceByUserOrder = (
  datasourceList: any[],
  dataSourcesOrder: any[] | null
) => {
  const sortedDataSources: IDataSource[] = []

  if (
    dataSourcesOrder &&
    dataSourcesOrder.length > 0 &&
    datasourceList.length > 0
  ) {
    // Add sorted filtered datasources
    for (const dataSourceOrderName of dataSourcesOrder) {
      if (dataSourceOrderName) {
        const datasource = datasourceList.find(
          (ds: any) => ds.name === dataSourceOrderName
        )
        if (datasource) {
          sortedDataSources.push(datasource)
        }
      }
    }

    // Add remaining non sorted filtered datasources
    for (const datasource of datasourceList) {
      if (
        !sortedDataSources.some(
          (ds: any) =>
            datasource &&
            ds.name === datasource.name &&
            ds.path === datasource.path
        )
      ) {
        sortedDataSources.push(datasource)
      }
    }
  }

  return sortedDataSources
}
