import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesResultsMarketResearch(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles(): any {
  return makeStyles((theme: Theme) => ({
    container: {
      display: 'flex',
      flexDirection: 'row',
      paddingLeft: '155px',
      flexWrap: 'nowrap',
      msOverflowY: 'hidden',
      [`${theme.breakpoints.down(1024)}`]: {
        display: 'none'
      }
    },
    mobileContainer: {
      display: 'none',
      [`${theme.breakpoints.down(1024)}`]: {
        paddingLeft: '8px',
        paddingRight: '8px',
        display: 'flex',
        wordBreak: 'break-word',
        flexDirection: 'column',
        flexWrap: 'nowrap'
      }
    },
    resultsContainer: {
      width: 632,
      flexShrink: 0,
      paddingLeft: 8,
      paddingRight: 16,
      marginRight: 36,
      [`${theme.breakpoints.down(1024)}`]: {
        width: '100%',
        paddingLeft: 0,
        paddingRight: 0
      }
    },
    numberOfResults: {
      marginBottom: '16px'
    },
    placeholder: {
      marginBottom: '16px'
    },
    advertisementContainer: {
      flex: 1,
      maxWidth: 466,
      flexShrink: 0,
      display: 'flex',
      flexDirection: 'column',
      [`${theme.breakpoints.down(1310)}`]: {
        flex: '0 0 366px'
      },
      [`${theme.breakpoints.down(1220)}`]: {
        flex: '0 0 278px'
      },
      //1024+padding 155px
      [`${theme.breakpoints.down(1179)}`]: {
        display: 'none'
      }
    },
    separator: {
      background: '#dfe1e5',
      height: 1,
      marginTop: 20,
      marginBottom: 20,
      marginLeft: -20,
      marginRight: -20
    },
    downloadContainer: {
      marginTop: '1em',
      marginLeft: '.5em'
    },
    downloadCircularProgress: {
      marginLeft: '1em'
    }
  }))
}
