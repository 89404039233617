import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesResultsO365(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles(): any {
  return makeStyles((theme: Theme) => ({
    resultTableContainer: {
      paddingLeft: 8,
      [`${theme.breakpoints.down(1024)}`]: {
        paddingLeft: 0
      }
    },

    spellCheck: {
      marginLeft: 8
    },

    leftWidgetContainer: {
      marginTop: 15,
      marginBottom: 15
    },
    resultTable: {
      fontSize: '16px',
      fontWeight: 'bold',
      '& tr > td': {
        verticalAlign: 'middle',
        padding: '6px 6px 6px 6px'
      },
      '& tr > td:last-Child': {
        paddingRight: '6px'
      },
      '& tr > td:first-Child': {
        paddingLeft: '0px',
        paddingRight: '0px'
      },
      [`${theme.breakpoints.down(1024)}`]: {
        fontWeight: 'normal'
      }
    }
  }))
}
