import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import {
  ApplicationInsights,
  IExceptionTelemetry,
  SeverityLevel
} from '@microsoft/applicationinsights-web'
import * as Config from 'config'
import { IErrorResponse } from './api'
import { IUserSetting } from './userSettings'
import { AdResult } from 'components/models/AdResult'
import { KPMGFindGlobalVariables } from 'store/KPMGFindGlobalVariables'
import { getCurrentScope } from './queryParams'
import { dataSourcesTabs } from 'constants/constants'
import { isJestUnittestRunning } from './unitTests'

// Global interface
declare global {
  interface Window {
    tab: any
    enabledFilters: any
    searchTerm: any
    userName: any
    personInfoFirstName: any
    personInfoLastName: any
    personInfoCountry: any
    personInfoCity: any
    personInfoFunction: any
    personInfoDepartment: any
    playTour: any
    setShowDisabledPopup: any
    setDisabledPopupDatasource: any
    isAutoSuggestQuery?: boolean
    loginTimeToApplication?: number
    tabOpenSince?: any
  }
}

// App Insights Global class
export class ApplicationInsightsGlobal {
  private static instance: ApplicationInsights

  private constructor() {
    // This class wraps an ApplicationInsights object to make it a singleton
    // Objects of this class should not be instantiated,
    // Use `getInstance()` to get an `ApplicationInsights` objects instead
  }

  // Gets the App Insights instance
  static getInstance(): ApplicationInsights {
    if (
      !ApplicationInsightsGlobal.instance &&
      Config.LOCAL_DEVELOPMENT.toString() === 'false'
    ) {
      const reactPlugin = new ReactPlugin()
      ApplicationInsightsGlobal.instance = new ApplicationInsights({
        config: {
          instrumentationKey: Config.APP_INSIGHTS_INTRUMENTATION_KEY,
          extensions: [reactPlugin],
          enableAutoRouteTracking: true,
          isStorageUseDisabled: true
        }
      })
      ApplicationInsightsGlobal.instance.loadAppInsights()
    }
    return ApplicationInsightsGlobal.instance
  }
}

// Get App Insights Instance
const appInsights: ApplicationInsights = ApplicationInsightsGlobal.getInstance()

/**
 * Tracks a given Event and its time of occurrence
 * @param propertyName The name of the Event that is supposed to be tracked
 * @param parameters object of parameters to be passed to Application Insight
 */
export function trackEvents(
  propertyName: string,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  parameters: any,
  flush = false
): void {
  if (Config.LOCAL_DEVELOPMENT.toString() === 'false') {
    // Make sure username is defined when tracking.
    // Undefined username can potentially occur when the MSAL login has not
    // been fully completed yet.
    // Ensure that country exists to prevent logging of empty default parameters.
    if (
      typeof KPMGFindGlobalVariables.getUserName() !== 'string' ||
      typeof KPMGFindGlobalVariables.getPersonInfoCountry() !== 'string' ||
      KPMGFindGlobalVariables.getPersonInfoCountry() === ''
    ) {
      return
    }

    const timestamp = Date.now()

    const defaultParams = {
      country: KPMGFindGlobalVariables.getPersonInfoCountry(),
      city: KPMGFindGlobalVariables.getPersonInfoCity(),
      function: KPMGFindGlobalVariables.getPersonInfoFunction(),
      department: KPMGFindGlobalVariables.getPersonInfoDepartment()
    }

    appInsights.trackEvent({
      name: propertyName,
      properties: { ...defaultParams, ...parameters, timestamp }
    })
    if (flush) appInsights.flush(false) //send all telemetry synced (not async) for links or leave page events
  } else {
    if (!isJestUnittestRunning()) console.log(propertyName, parameters)
  }
}
/**
 * Tracks an exception
 */
export function trackException(
  message: string,
  exception: Error | unknown,
  errorResponse: IErrorResponse | null = null
): void {
  //filter TypeError: Cancelled (happens on redirect while loading, or user cancelled the open session)
  if (
    exception instanceof TypeError &&
    exception.message &&
    exception.message.toLocaleLowerCase() === 'cancelled'
  ) {
    return
  }

  if (Config.LOCAL_DEVELOPMENT.toString() === 'false') {
    const exceptionTelemetry: IExceptionTelemetry = {
      exception: exception instanceof Error ? exception : new Error(message),
      properties: {
        'KPMG Find Message': message
      },
      severityLevel: SeverityLevel.Error
    }

    if (errorResponse && exceptionTelemetry.properties) {
      exceptionTelemetry.properties['KPMG Find Response'] = errorResponse
    }

    appInsights.trackException(exceptionTelemetry)
  }
}

/**
 * Tracks a page view
 */
export function trackPageView(): void {
  if (Config.LOCAL_DEVELOPMENT.toString() === 'false') {
    appInsights.trackPageView()
  }
}

/**
 * Tracking in case the application gets closed
 */
export function handleApplicationClose(): void {
  const timeNow = Date.now()

  // Track the time the user stays on the current page
  if (KPMGFindGlobalVariables.getTabOpenSince()) {
    trackEvents(
      'stayOnPage',
      {
        time: timeNow - KPMGFindGlobalVariables.getTabOpenSince(),
        tab: KPMGFindGlobalVariables.getCurrentTab(),
        searchTerm: KPMGFindGlobalVariables.getSearchTerm()
      },
      true
    )
  }

  // Track the time the user stays inside the application
  if (KPMGFindGlobalVariables.getLoginTimeToApplication()) {
    trackEvents(
      'stayOnPage',
      {
        time: timeNow - KPMGFindGlobalVariables.getLoginTimeToApplication(),
        tab: 'global'
      },
      true
    )
  }
}

export interface IExternalLinkParams {
  title: string
  url: string
  index?: number | undefined
  featured?: boolean
  wasRightClicked?: boolean
  widgetKey?: string
  body?: string
  featuredKeyword?: string
}

export interface IFeaturedShownParams extends IExternalLinkParams {
  featuredKeyword?: string
}
export interface IWidgetShownParams {
  widgetType: string
  widgetPane: string
}

function trackLinkClicked(
  eventMessage: string,
  params: IExternalLinkParams,
  flush = true
): void {
  trackEvents(
    eventMessage,
    {
      ...params,
      tab: KPMGFindGlobalVariables.getCurrentTab(),
      enabledFilters: KPMGFindGlobalVariables.getEnabledFilters(),
      searchTerm: KPMGFindGlobalVariables.getSearchTerm()
    },
    flush
  )
}

export function externalLinkClicked(params: IExternalLinkParams): void {
  trackLinkClicked('externalLinkClicked', params)
}

export function trackRightWidgetClick(params: IExternalLinkParams): void {
  trackLinkClicked('rightWidgetClick', params)
}

export function trackLeftWidgetClick(params: IExternalLinkParams): void {
  trackLinkClicked('leftWidgetClick', params)
}

export function trackReturnToSharePointClick(
  params: IExternalLinkParams
): void {
  trackLinkClicked('returnToClassicSharePointClick', params)
}

/**
 * Event for tracking the Adword clicks
 * @param adItem The ad item information
 * @param wasRightClicked bool that indicates a right click
 */
export function trackAdClick(
  adItem: AdResult,
  link: string | null,
  wasRightClicked: boolean
): void {
  if (!adItem) return

  const params = {
    title: adItem ? adItem.title : '',
    url: link ? link : '',
    wasRightClicked: wasRightClicked
  }

  trackLinkClicked('adWords-clicked', params, true)
}

/**
 * Event for tracking the Adword Feedback clicks
 * @param adItem The ad item information
 * @param wasRightClicked bool that indicates a right click
 */
export function trackAdFeedbackClick(
  adItem: AdResult,
  link: string | null,
  wasRightClicked: boolean
): void {
  if (!adItem) return

  const params = {
    title: adItem ? adItem.title : '',
    url: link ? link : '',
    wasRightClicked: wasRightClicked
  }

  trackLinkClicked('adWords-feedback-clicked', params, true)
}

/**
 * Track the event the a widget is shown
 * @param params The parameters to track
 */
export function trackWidgetShown(params: IWidgetShownParams): void {
  trackEvents(
    'widget-shown',
    {
      ...params,
      tab: KPMGFindGlobalVariables.getCurrentTab(),
      enabledFilters: KPMGFindGlobalVariables.getEnabledFilters(),
      searchTerm: KPMGFindGlobalVariables.getSearchTerm()
    },
    false
  )
}

/**
 * Track the event the a featured result is shown
 * @param params The parameters to track
 */
export function trackFeaturedShown(params: IFeaturedShownParams): void {
  const scope = getCurrentScope(false)
  if (!scope) return

  trackEvents(
    'featured-shown',
    {
      ...params,
      tab: getTabTypeByScope(scope),
      enabledFilters: KPMGFindGlobalVariables.getEnabledFilters(),
      searchTerm: KPMGFindGlobalVariables.getSearchTerm(),
      distinct: KPMGFindGlobalVariables.getFeaturedResultDistinct(params.title)
    },
    false
  )
}

/**
 * Track the event the a featured result feedback is clicked
 * @param params The parameters to track
 */
export function trackFeaturedFeedbackClicked(incorrectLinkUrI: string): void {
  const scope = getCurrentScope(false)
  if (!scope) return

  trackEvents(
    'featured-feedback-clicked',
    {
      incorrectLinkUrI: incorrectLinkUrI,
      tab: getTabTypeByScope(scope),
      enabledFilters: KPMGFindGlobalVariables.getEnabledFilters(),
      searchTerm: KPMGFindGlobalVariables.getSearchTerm()
    },
    false
  )
}

/**
 * Track usersettings
 * @param userSettings The current usersettings
 * @param changed Additional tracking if the settings have changed
 */
export function trackUserSettings(
  userSettings: IUserSetting,
  changed: boolean
): void {
  const userSettingsToTrack = {
    id: userSettings.id,
    Version: userSettings.Version,
    EnablePrefiltering: userSettings.EnablePrefiltering,
    EnableWidgetPrefiltering: userSettings.EnableWidgetPrefiltering,
    Country: userSettings.Country,
    FirstLogin: userSettings.FirstLogin,
    City: userSettings.City,
    Function: userSettings.Function,
    Department: userSettings.Department,
    PinFilters: userSettings.PinFilters,
    Language: userSettings.Language,
    LanguageManuallySeleted: userSettings.LanguageManuallySeleted,
    DataSourceOrder: userSettings.DataSourceOrder,
    DataSourceDisabled: userSettings.DataSourceDisabled,
    PremiumEnabled: userSettings.PremiumEnabled,
    LastVisits: userSettings.LastVisits
  }

  trackEvents(
    'user-settings',
    { ...userSettingsToTrack, changed: changed },
    false
  )
}

/**
 * Track the event a datasource is shown.
 * This triggers a unique search
 * In this case a search event must be tracked
 * @param searchTerm The current search Term to track
 * @param currentFiters The filters to track
 */
export const trackDatasourceEvent = (searchTerm: any, currentFiters: any) => {
  const scope = getCurrentScope(false)
  if (!scope) return

  // For the searchTerm and the filters the global variables can't be used
  // The update for the new values is done in the SearchField, after the search event is tracked
  const searchTrackingParams = {
    searchTerm: searchTerm,
    tab: getTabTypeByScope(scope),
    enabledFilters: currentFiters,
    distinct: KPMGFindGlobalVariables.getDatasourceDistinct(scope),
    isAutoSuggest: KPMGFindGlobalVariables.getIsAutoSuggestQuery()
  }

  trackEvents('search', searchTrackingParams)
}

const getTabTypeByScope = (scope: any): string => {
  switch (scope) {
    case 'intranet':
      return dataSourcesTabs.oneintranet
    case 'engagements':
      return dataSourcesTabs.engagements
    case 'people':
      return dataSourcesTabs.people
    case 'statistics':
      return dataSourcesTabs.statistics
    case 'source':
      return dataSourcesTabs.source
    case 'videos':
      return dataSourcesTabs.videos
    case 'news':
      return dataSourcesTabs.news
    case 'knowledgeexchangeopen':
      return dataSourcesTabs.knowledgeexchangeopen
    case 'knowledgeexchangecatalog':
      return dataSourcesTabs.knowledgeexchangecatalog
    case 'alex':
    case 'alexfeatured':
    case 'alextopics':
    case 'alexmanuals':
    case 'alexsearch':
      return dataSourcesTabs.alex
    case 'klardenker':
      return dataSourcesTabs.klardenker
    case 'kpmg.com':
      return dataSourcesTabs.aem
    case 'eins':
      return dataSourcesTabs.eins
    case 'project_spaces':
      return dataSourcesTabs.project_spaces
    case 'contentfinder':
      return dataSourcesTabs.contentfinder
    case 'office365files':
      return dataSourcesTabs.o365files
    case 'office365mails':
      return dataSourcesTabs.o365mails
    case 'office365calendar':
      return dataSourcesTabs.o365calendar
    case 'office365sites':
      return dataSourcesTabs.o365sites
    case 'office365teams':
      return dataSourcesTabs.o365teams
    case 'kpmg mpp':
      return dataSourcesTabs.kpmg_mpp
    case 'marketresearch':
      return dataSourcesTabs.marketresearch
    default:
      return scope.toLocaleLowerCase()
  }
}
