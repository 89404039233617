import { Reducer } from 'redux'
import { ResultsActionTypes, IStoreHistory, ResultsActions } from './actions'

export interface IResultsStore {
  history: {
    [key: string]: any
  }
}

const initialState: IResultsStore = {
  history: {}
}

const reducers: Reducer<IResultsStore, ResultsActions> = (
  state: IResultsStore = initialState,
  action: ResultsActions
) => {
  switch (action.type) {
    case ResultsActionTypes.STORE_RESULT_HISTORY: {
      const { resultUrl, content } = (action as IStoreHistory).payload
      return {
        ...state,
        history: {
          ...state.history,
          [resultUrl]: content ? content : {}
        }
      }
    }
    case ResultsActionTypes.REMOVE_RESULT_HISTORY: {
      const { resultUrl } = (action as IStoreHistory).payload

      const { history } = state
      delete history[resultUrl]
      return {
        ...state,
        history
      }
    }
    default:
      return state
  }
}

export default reducers
