import React from 'react'
import * as Config from 'config'
import { ResultLeftWidgetGenerator } from './interfaces'
import LeftVideoWidget from 'components/contents/resultpages/widgets/LeftVideoWidget'
import { trackException } from 'utils/tracking'
import { FetchWithCache } from 'utils/api'
import { getSPOnlineToken, renewAuthorizationToken } from 'utils/token'
import {
  IOneIntranetResultQuery,
  removeStopWordsFromQuery,
  videosDefaultquery
} from 'utils/oneIntranet'
import { portalOptionsIntranet } from 'constants/filters'
import { stripHtml } from 'utils/string'
import { IDeviceSetting } from 'utils/deviceSettings'
import { IUserSetting } from 'utils/userSettings'
import { IFindConfiguration } from 'store/Settings/reducers'
import { IVideoResult } from 'components/models/VideoResult'
import { IAADState } from 'store/Auth/reducers'

export const leftVideosWidgetGenerator: ResultLeftWidgetGenerator = {
  fetch(
    aadInfo: IAADState,
    searchQuery: string,
    deviceSettings: IDeviceSetting,
    userSettings: IUserSetting,
    findConfiguration: IFindConfiguration,
    useCognitiveSearch: boolean
  ): Promise<IVideoResult[]> {
    return new Promise<IVideoResult[]>((resolve, reject) => {
      const fetchVideos = async () => {
        if (!searchQuery || searchQuery === '' || searchQuery === '""') {
          trackException(
            'Error in fetching Videos left widget in leftVideosWidget.tsx',
            new Error('Empty query. Please specify a valid query text')
          )
          reject()
          return
        }

        let spoToken = ''
        if (Config.LOCAL_DEVELOPMENT.toString() === 'false') {
          spoToken = await getSPOnlineToken(aadInfo.instance, aadInfo.accounts)
          if (spoToken === '') {
            throw new Error(
              'Authentication: Cannot renew SPO authentication token'
            )
          }
        }

        const apiUrl = `${Config.APIM_BASE_URL}oneintranetapi/postsearchoi`

        const requestBody: IOneIntranetResultQuery = Object.assign(
          {},
          videosDefaultquery
        )
        requestBody.rowlimit = 10
        requestBody.rowsperpage = 10
        requestBody.documentType = 'Video'
        requestBody.querytext = searchQuery
        requestBody.cognitiveEnabled = false

        // Get and check authentication token
        const esToken = await renewAuthorizationToken(
          aadInfo.accessToken,
          aadInfo.instance,
          aadInfo.accounts
        )

        if (
          useCognitiveSearch &&
          findConfiguration.CognitiveSearchEnabled &&
          esToken !== ''
        ) {
          let searchQueryLanguage = ''
          const detectLanguageApiUrl = `${
            Config.APIM_BASE_URL
          }searchapi/detectlanguage?searchQuery=${encodeURIComponent(
            searchQuery
          )}`

          const languageResponse = await FetchWithCache(detectLanguageApiUrl, {
            method: 'POST',
            headers: {
              accept: 'application/json',
              'content-type': 'application/json',
              'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
              Authorization: `Bearer ${esToken}`
            }
          })
          if (
            languageResponse &&
            !languageResponse.hasError &&
            languageResponse.responseJSON
          ) {
            const languageJSON = languageResponse.responseJSON
            if (languageJSON && languageJSON.responseCode === 200) {
              searchQueryLanguage = languageJSON.language
            }
          }
          requestBody.querytext = removeStopWordsFromQuery(
            searchQuery,
            searchQueryLanguage,
            useCognitiveSearch,
            findConfiguration.CognitiveSearchEnabled
          )
          requestBody.cognitiveEnabled = true
        }

        if (
          userSettings &&
          userSettings.EnableWidgetPrefiltering === true &&
          userSettings.Country &&
          userSettings.Country !== ''
        ) {
          let departmentId = ''
          const portalFilter = portalOptionsIntranet.find(
            (po: Filter) =>
              po.name.toLocaleLowerCase() ===
              userSettings.Country.toLocaleLowerCase()
          )
          if (portalFilter && portalFilter.value) {
            departmentId = portalFilter.value
            if (departmentId !== 'all')
              requestBody.origin = 'portal%%"{' + departmentId + '}"'
          }
        }

        const response = await FetchWithCache(
          apiUrl,
          {
            method: 'POST',
            headers: {
              'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
              'content-type': 'application/json',
              Authorization: `Bearer ${spoToken}`,
              'Authorization-OnPrem': `${esToken}`
            },
            body: JSON.stringify(requestBody)
          },
          undefined,
          true,
          'widget_videos',
          undefined,
          searchQuery
        )

        let results
        if (!response || response.hasError) {
          if (
            !response ||
            !response.errorResponse ||
            response.errorResponse.responseCode !== 499
          ) {
            trackException(
              'Error in fetching Intranet left widget in leftVideosWidget.tsx',
              new Error(
                response
                  ? response.errorResponse
                    ? response.errorResponse.responseCode +
                      ':' +
                      response.errorResponse.message
                    : ''
                  : ''
              )
            )
          }
          reject()
          return
        } else {
          results = response.responseJSON
        }

        const primaryResults =
          results && results.QueryResults ? results.QueryResults : []

        if (!primaryResults || primaryResults.length < 3) {
          reject()
          return
        }

        const videoResults: IVideoResult[] = primaryResults.map((item: any) => {
          const videoResult: IVideoResult = {
            Title: item.Title ? stripHtml(item.Title) : '',
            Path: item.DefaultEncodingURL
              ? item.DefaultEncodingURL
              : item.Path
              ? item.Path
              : '',
            PictureThumbnailUrl: item.PictureThumbnailUrl
              ? item.PictureThumbnailUrl
              : '',
            SiteID: item.SiteID ? item.SiteID : '',
            WebID: item.WebID ? item.WebID : '',
            ListID: item.ListID ? item.ListID : '',
            ListItemID: item.ListItemID ? item.ListItemID : '',
            Date: item.Write ? item.Write : '',
            Size: item.Size ? item.Size : ''
          }

          return videoResult
        })

        let hasContent = false
        for (let i = 0; i < videoResults.length; i++) {
          if (videoResults[i].Title !== '') {
            hasContent = true
            break
          }
        }
        if (!hasContent) {
          reject()
          return
        }

        resolve(videoResults)
      }

      try {
        fetchVideos()
      } catch (error) {
        trackException(
          'Error in fetching Videos left widget in leftVideosWidget.tsx',
          error
        )
        reject()
      }
    })
  },
  generate(
    results: IVideoResult[],
    searchQuery: string
  ): Promise<React.ReactElement> {
    return new Promise<React.ReactElement>((resolve, reject) => {
      const generateVideos = () => {
        const limit = 10
        if (results.length > 2) {
          let videoResults = results

          if (results.length > limit) {
            videoResults = results.slice(0, limit)
          }

          resolve(
            <LeftVideoWidget results={videoResults} query={searchQuery} />
          )
        } else {
          reject()
          return
        }
      }

      try {
        generateVideos()
      } catch (error) {
        trackException(
          'Error in generating Videos left widget in leftVideosWidget.tsx',
          error
        )
        reject()
      }
    })
  }
}
