import { makeStyles } from '@mui/styles'

export function getStylesGlobalErrorPage(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles() {
  return makeStyles(() => ({
    browserErrorMessage: {
      position: 'absolute',
      right: 0,
      left: '50%',
      top: '50%',
      width: '365px',
      height: '400px',
      marginTop: '-200px',
      marginLeft: '-182.5px',
      textAlign: 'left'
    },
    browserErrorMessageImage: {
      width: '360px'
    },
    errorHeader: {
      fontSize: '22px',
      color: 'black',
      marginTop: '20px'
    },
    errorSubHeader: {
      color: '#424242',
      fontWeight: 'lighter',
      fontFamily: 'Arial,Helvetica Neue,Helvetica,sans-serif-light',
      margin: '10px 0px',
      lineHeight: '1.5',
      fontSize: '16px'
    },
    errorText: {
      fontSize: '16px',
      fontFamily: 'Arial,sans-serif',
      color: 'rgba(0, 0, 0,0.54)',
      fontWeight: 400,
      lineHeight: '20.02px',
      marginBottom: '7px',
      filter: 'alpha(opacity=50)',
      zoom: '1'
    },
    link: {
      color: '#004e98',
      textDecoration: 'none',
      cursor: 'pointer'
    }
  }))
}
