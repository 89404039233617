import React, { Dispatch, useMemo } from 'react'
import { Box, Fade } from '@mui/material'
import { connect } from 'react-redux'
import ReactPlaceholder from 'react-placeholder'
import { Store } from 'store'
import SettingsStore from 'store/Settings'
import ResultsOffice365Store from 'store/ResultsOffice365'
import { trackException } from 'utils/tracking'
import Pagination from 'components/contents/Pagination'
import { ChatMessageSourceResponse, HitsResponse } from 'utils/o365'
import 'Placeholder.css'
import SearchSuggestions from 'components/contents/common/SearchSuggestions'
import O365FilterBar from './O365FilterBar'
import FeaturedResults from '../FeaturedResults'
import LeftWidgetContainer from '../widgetscontainer/LeftWidgetContainer'
import { getStylesResults } from 'styles/contents/resultpages/Results'
import { getStylesResultsO365 } from 'styles/contents/resultpages/ResultsO365'
import { useFilter } from 'utils/filters'
import createDOMPurify from 'dompurify'
import { TeamResult } from 'components/contents/results/TeamResult'
import { CognitiveMessage } from 'components/contents/common/CognitiveMessage'
import { IUserSetting } from 'utils/userSettings'
import ErrorPage from 'components/contents/common/ErrorPage'
import { IAADState } from 'store/Auth/reducers'

export interface ResultsTeamsProps {
  datasources: any
  resultsCount: number
  hasErrorStore: boolean
  searchQuery: string
  blockChatInformationFetch: boolean
  aadInfo: IAADState
  moreResultsAvailable: boolean
  firstRender: boolean
  userSettings: IUserSetting
  findConfigCognitiveSearchEnabled: boolean
  cognitiveSearchEnabled: boolean
  showCognitiveMessage: boolean
  setShowCognitiveMessage: Dispatch<boolean>
  setUserSettings: Dispatch<IUserSetting>
}

type AllProps = ReturnType<typeof mapStateToProps> & ResultsTeamsProps

function ResultsTeamsMobile(props: AllProps) {
  const {
    extendedResults,
    resultsCombined,
    resultsCount,
    hasResultsBeenFetched,
    datasources,
    deviceSettings,
    hasErrorStore,
    searchQuery,
    blockChatInformationFetch,
    aadInfo,
    moreResultsAvailable,
    firstRender,
    userSettings,
    findConfigCognitiveSearchEnabled,
    cognitiveSearchEnabled,
    showCognitiveMessage,
    setShowCognitiveMessage,
    setUserSettings
  } = props
  const DOMPurify = createDOMPurify(window)
  const resultClasses = getStylesResults()
  const o365Classes = getStylesResultsO365()
  const [currentPage] = useFilter('page', '1')

  const returnAllItems = (type: string) => {
    if (!resultsCombined || resultsCombined.length < 1) {
      return []
    }

    const items = []

    try {
      const firstResults =
        resultsCombined.length < 4 ? resultsCombined.length : 3

      if (type === 'first') {
        for (let i = 0; i < firstResults; i++) {
          const teamItem = resultsCombined[
            i
          ] as HitsResponse<ChatMessageSourceResponse>
          items.push(
            <TeamResult
              lastModifiedDateTime={DOMPurify.sanitize(
                teamItem.resource.lastModifiedDateTime
              )}
              description={DOMPurify.sanitize(teamItem.summary)}
              key={i}
              index={i}
              deviceSettings={deviceSettings}
              searchQuery={DOMPurify.sanitize(searchQuery)}
              from={DOMPurify.sanitize(
                teamItem.resource.from.emailAddress.name
              )}
              upn={DOMPurify.sanitize(
                teamItem.resource.from.emailAddress.address
              )}
              channelId={
                teamItem.resource.channelIdentity
                  ? teamItem.resource.channelIdentity.channelId
                  : ''
              }
              teamId={
                teamItem.resource.channelIdentity
                  ? teamItem.resource.channelIdentity.teamId
                  : ''
              }
              chatId={teamItem.resource.chatId}
              aadInfo={aadInfo}
              blockChatInformationFetch={blockChatInformationFetch}
              messageId={teamItem.resource.id ? teamItem.resource.id : ''}
              synonymsApplied={extendedResults.synonymsApplied}
            />
          )
        }
      } else {
        for (let i = firstResults; i < resultsCombined.length; i++) {
          const teamItem = resultsCombined[
            i
          ] as HitsResponse<ChatMessageSourceResponse>
          items.push(
            <TeamResult
              lastModifiedDateTime={DOMPurify.sanitize(
                teamItem.resource.lastModifiedDateTime
              )}
              description={DOMPurify.sanitize(teamItem.summary)}
              key={i}
              index={i}
              deviceSettings={deviceSettings}
              searchQuery={DOMPurify.sanitize(searchQuery)}
              from={DOMPurify.sanitize(
                teamItem.resource.from.emailAddress.name
              )}
              upn={DOMPurify.sanitize(
                teamItem.resource.from.emailAddress.address
              )}
              channelId={
                teamItem.resource.channelIdentity
                  ? teamItem.resource.channelIdentity.channelId
                  : ''
              }
              teamId={
                teamItem.resource.channelIdentity
                  ? teamItem.resource.channelIdentity.teamId
                  : ''
              }
              chatId={teamItem.resource.chatId}
              aadInfo={aadInfo}
              blockChatInformationFetch={blockChatInformationFetch}
              messageId={teamItem.resource.id ? teamItem.resource.id : ''}
              synonymsApplied={extendedResults.synonymsApplied}
            />
          )
        }
      }
    } catch (error) {
      trackException(
        'Error returning all items in ResultSitesMobile.tsx',
        error
      )
    }
    return items
  }

  const firstItems = useMemo(
    () => returnAllItems('first'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resultsCombined, extendedResults]
  )

  const restItems = useMemo(
    () => returnAllItems('rest'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resultsCombined, extendedResults]
  )

  return (
    <Box className={resultClasses.container}>
      <Box className={resultClasses.resultsContainer}>
        {!hasErrorStore && (
          <Box className={o365Classes.spellCheck}>
            <SearchSuggestions
              wildCardActive={true}
              resultCount={resultsCount}
              resultsFetchend={hasResultsBeenFetched}
            />
          </Box>
        )}
        {cognitiveSearchEnabled &&
          showCognitiveMessage &&
          extendedResults.synonymsApplied &&
          findConfigCognitiveSearchEnabled &&
          extendedResults.synonymsApplied.length > 0 && (
            <CognitiveMessage
              synonymsApplied={extendedResults.synonymsApplied}
              setShowCognitiveMessage={setShowCognitiveMessage}
              userSettings={userSettings}
              setUserSettings={setUserSettings}
            />
          )}
        <O365FilterBar {...props} scope={'Teams'} datasources={datasources} />
        {!hasErrorStore && (
          <Box className={o365Classes.resultTableContainer}>
            <ReactPlaceholder
              ready={
                hasResultsBeenFetched ||
                Number(DOMPurify.sanitize(currentPage)) > 1
                  ? true
                  : false
              }
              type="text"
              rows={4}
              showLoadingAnimation={true}
              className={resultClasses.placeholder}
            >
              <Fade
                in={
                  hasResultsBeenFetched ||
                  Number(DOMPurify.sanitize(currentPage)) > 1
                    ? true
                    : false
                }
                timeout={600}
              >
                <Box>
                  {resultsCombined && resultsCount > 0 && !firstRender && (
                    <FeaturedResults
                      featuredResults={extendedResults.featuredResults}
                    />
                  )}
                  {firstItems}
                </Box>
              </Fade>
            </ReactPlaceholder>
            {hasResultsBeenFetched &&
            (resultsCount < 1 || resultsCombined.length < 1) ? null : (
              <LeftWidgetContainer
                resultCount={resultsCount}
                hasResultsBeenFetched={hasResultsBeenFetched}
              />
            )}
            <ReactPlaceholder
              ready={
                hasResultsBeenFetched ||
                Number(DOMPurify.sanitize(currentPage)) > 1
              }
              type="text"
              rows={4}
              showLoadingAnimation={true}
              className={resultClasses.placeholder}
            >
              <Fade
                in={
                  hasResultsBeenFetched ||
                  Number(DOMPurify.sanitize(currentPage)) > 1
                }
                timeout={600}
              >
                <Box>
                  {restItems}
                  {resultsCombined != null &&
                    resultsCombined.length > 0 &&
                    moreResultsAvailable && (
                      <Pagination
                        {...props}
                        totalRecords={
                          moreResultsAvailable ? resultsCount + 1 : resultsCount
                        }
                        pageLimit={20}
                        pageNeighbours={4}
                      />
                    )}
                </Box>
              </Fade>
            </ReactPlaceholder>
            <ReactPlaceholder
              ready={hasResultsBeenFetched ? hasResultsBeenFetched : false}
              type="text"
              rows={4}
              showLoadingAnimation={true}
              className={resultClasses.placeholder}
            >
              <Box />
            </ReactPlaceholder>
            <ReactPlaceholder
              ready={hasResultsBeenFetched ? hasResultsBeenFetched : false}
              type="text"
              rows={4}
              showLoadingAnimation={true}
              className={resultClasses.placeholder}
            >
              <Box />
            </ReactPlaceholder>
          </Box>
        )}

        {hasErrorStore && (
          <ErrorPage
            dataSource="office365"
            origin={'teams'}
            isContainerWrapped={true}
          />
        )}
      </Box>
    </Box>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    extendedResults: ResultsOffice365Store.selectors.getExtendedResults(
      state,
      'teams'
    ),
    resultsCombined: ResultsOffice365Store.selectors.getResultsCombined(
      state,
      'teams'
    ),
    hasResultsBeenFetched:
      ResultsOffice365Store.selectors.hasResultsBeenFetched(state, 'teams'),
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state)
  }
}

export default connect(mapStateToProps)(ResultsTeamsMobile)
