import React, { useState, useEffect, Dispatch } from 'react'
import SettingsStore from 'store/Settings'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { Store } from 'store'
import { Box, Grid } from '@mui/material'
import Link from '@mui/material/Link'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp'
import UserSettingsStore from 'store/UserSettings'
import highlightText, { prepareHighlightWords } from 'utils/highlightText'
import { trackWidgetShown, trackRightWidgetClick } from 'utils/tracking'
import { removeQueryParam } from 'utils/queryParams'
import { dateFormatOptions } from 'constants/constants'
import { WidgetKey } from 'constants/widgets'
import SeeAllButton from './common/SeeAllButton'
import { getStylesRightWidget } from 'styles/contents/resultpages/widgets/RightWidget'
import { getUrlParameterForCurrentFilters, useFilter } from 'utils/filters'
import { getStylesRightSourceWidget } from 'styles/contents/resultpages/widgets/RightSourceWidget'
import createDOMPurify from 'dompurify'
import TooltipTitle from 'components/contents/common/TooltipTitle'
import FilterStore from 'store/Filters'
import { ISourceResult } from 'components/models/SourceResult'
import { ISynonymsApplied } from 'components/models/SynonymsApplied'
import LinesEllipsis from 'react-lines-ellipsis'
import HTMLEllipsis from 'react-lines-ellipsis/lib/html'
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'

export interface RightSourceWidgetProps {
  searchQuery: any
  results: ISourceResult[]
  synonymsApplied: ISynonymsApplied[]
}

type AllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RightSourceWidgetProps

function RightSourceWidget(props: AllProps): JSX.Element {
  const {
    searchQuery,
    results,
    deviceSettings,
    currentDataSources,
    currentFilters,
    clearTemporaryFilter,
    synonymsApplied
  } = props

  const DOMPurify = createDOMPurify(window)

  const navigateFunction = useNavigate()
  const rightWidgetClasses = getStylesRightWidget()
  const classes = getStylesRightSourceWidget()

  const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis)
  const ResponsiveHTMLEllipsis = responsiveHOC()(HTMLEllipsis)

  const [itemsShown, setItemsShown] = useState(3)
  const [dataSourceEnabled, setDataSourceEnabled] = useState(false)
  const [currentPage] = useFilter('page', '1')

  const showTen = () => {
    setItemsShown(10)
  }

  const showThree = () => {
    setItemsShown(3)
  }

  React.useEffect(() => {
    showThree()
  }, [currentPage])

  React.useEffect(() => {
    trackWidgetShown({
      widgetType: WidgetKey.source,
      widgetPane: 'right'
    })
  }, [])

  const intl = useIntl()

  const headline = intl.formatMessage({
    id: 'source_on',
    defaultMessage: 'Source in'
  })

  // If widget prefiltering is enabled, add it to the see all link
  const seeAllLink = `/results/source/${getUrlParameterForCurrentFilters(
    currentFilters,
    [{ key: 'page', value: '1' }]
  )}`

  useEffect(() => {
    const sourceDatasource = currentDataSources.find(
      (ds) => ds.name.toLowerCase() === WidgetKey.source
    )
    const sourceDatasourceEnabled = sourceDatasource
      ? sourceDatasource.enabled
      : false

    setDataSourceEnabled(sourceDatasourceEnabled)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDataSources])

  return (
    <Box
      id={'con-widget-right-source'}
      className={
        results.length > 3
          ? rightWidgetClasses.widgetAreaExtend
          : rightWidgetClasses.widgetArea
      }
    >
      <h2 className={rightWidgetClasses.widgetHeadlineH2}>
        <ResponsiveEllipsis
          className={rightWidgetClasses.widgetHeadline}
          basedOn="words"
          text={headline}
          maxLine="2"
        />
      </h2>
      {results
        .slice(0, itemsShown)
        .map((item: ISourceResult, index: number) => (
          <Box key={'rn' + index} className={classes.widgetItem}>
            <Link
              underline={'hover'}
              key={'rnl1' + index}
              onClick={() => {
                removeQueryParam(navigateFunction, 'cntx')
                trackRightWidgetClick({
                  title: DOMPurify.sanitize(item.title),
                  url: DOMPurify.sanitize(item.url),
                  index,
                  widgetKey: WidgetKey.source,
                  body: DOMPurify.sanitize(item.body)
                })
              }}
              {...(!deviceSettings.isMobile
                ? {
                    onAuxClick: (event: any) => {
                      removeQueryParam(navigateFunction, 'cntx')
                      trackRightWidgetClick({
                        title: DOMPurify.sanitize(item.title),
                        url: DOMPurify.sanitize(item.url),
                        index,
                        widgetKey: WidgetKey.source,
                        body: DOMPurify.sanitize(item.body),
                        wasRightClicked: event?.button === 2 ? true : false
                      })
                    }
                  }
                : {})}
              href={DOMPurify.sanitize(item.url)}
              data-node-title={DOMPurify.sanitize(item.title)}
              data-node-index={index}
              data-node-click-type="rightWidgetClick"
              data-node-widget-key={WidgetKey.source}
              {...(deviceSettings.openLinksInNewTab === true
                ? { target: '_blank', rel: 'noreferrer' }
                : {})}
            >
              <TooltipTitle
                title={DOMPurify.sanitize(item.title?.trim())}
                key={'rnd1' + index}
                singleLine={true}
                additionalClass={`${rightWidgetClasses.widgetTitle} ${classes.widgetTitle}`}
              />
            </Link>
            <ResponsiveHTMLEllipsis
              className={`${rightWidgetClasses.widgetDescription} ${rightWidgetClasses.widgetDescriptionExtended} ${classes.widgetDescription}`}
              basedOn="words"
              unsafeHTML={highlightText(
                DOMPurify.sanitize(item.body?.trim()),
                prepareHighlightWords(
                  DOMPurify.sanitize(searchQuery),
                  DOMPurify.sanitize(item.body?.trim()),
                  synonymsApplied
                ),
                true
              )}
              maxLine="2"
            />
            <ResponsiveEllipsis
              className={rightWidgetClasses.widgetFooter}
              basedOn="words"
              text={
                item.date
                  ? intl.formatDate(
                      DOMPurify.sanitize(item.date),
                      dateFormatOptions
                    )
                  : ''
              }
              maxLine="1"
            />
          </Box>
        ))}
      {results.length > 3 && (
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          className={rightWidgetClasses.footerGrid}
        >
          {dataSourceEnabled && results.length >= 10 && (
            <SeeAllButton
              searchQuery={DOMPurify.sanitize(searchQuery)}
              url={seeAllLink}
              widgetKey={WidgetKey.source}
              onClickCallback={clearTemporaryFilter}
            />
          )}
          {itemsShown === 3 && (
            <Grid
              item
              id={'btn-right-widget-down-01'}
              className={
                dataSourceEnabled
                  ? rightWidgetClasses.arrowContainerSeeAll
                  : rightWidgetClasses.arrowContainer
              }
              tabIndex={0}
              onClick={showTen}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  showTen()
                }
              }}
            >
              <KeyboardArrowDown className={rightWidgetClasses.arrowItem} />
            </Grid>
          )}
          {itemsShown === 10 && (
            <Grid
              item
              id={'btn-right-widget-up-01'}
              className={
                dataSourceEnabled
                  ? rightWidgetClasses.arrowContainerSeeAllExpanded
                  : rightWidgetClasses.arrowContainerExpanded
              }
              tabIndex={0}
              onClick={showThree}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  showThree()
                }
              }}
            >
              <KeyboardArrowUp className={rightWidgetClasses.arrowItem} />
            </Grid>
          )}
        </Grid>
      )}
    </Box>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    userSettings: UserSettingsStore.selectors.getUserSettings(state),
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state),
    currentDataSources: SettingsStore.selectors.getCurrentDataSources(state),
    currentFilters: FilterStore.selectors.getCurrentFilters(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    clearTemporaryFilter: () =>
      dispatch(FilterStore.actions.clearTemporaryFilter())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RightSourceWidget)
