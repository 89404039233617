import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesRightTopWidgetContainer(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles(): any {
  return makeStyles((theme: Theme) => ({
    resultWidgetPlaceholder: {
      marginLeft: 16,
      marginBottom: 16
    }
  }))
}
