import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesLeftPeopleAlsoSearchedWidget(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles(): any {
  return makeStyles((theme: Theme) => ({
    ellipsisSingleLine: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis'
    },
    ellipsisMultiLines: {
      position: 'relative',
      lineHeight: '20px',
      maxHeight: '60px',
      overflow: 'hidden',
      paddingRight: '1em',
      wordWrap: 'break-word', // older browsers
      transform: 'translateZ(0)', //fix for rendering problems ie11 : 470395
      '&::before': {
        position: 'absolute',
        content: '"..."',
        right: 0,
        bottom: 0
      },
      '&::after': {
        content: '""',
        position: 'absolute',
        right: 0,
        width: '1em',
        height: '1em',
        background: 'white',
        marginTop: '0.2em'
      }
    },
    widgetArea: {
      marginBottom: 20,
      [`${theme.breakpoints.down(1024)}`]: {
        padding: '20px'
      }
    },
    widgetHeadlineH2: {
      color: '#424242',
      textAlign: 'left',
      fontWeight: 'normal',
      font: 'Arial,Helvetica Neue,Helvetica,sans-serif-light',
      fontSize: 22,
      marginBlockStart: 0,
      marginTop: 0
    },
    widgetHeadline: {
      whiteSpace: 'pre-wrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    },
    widgetItem: {
      padding: '8px 0px',
      '&:hover': {
        backgroundColor: 'lightgray'
      },
      display: 'table',
      width: '100%'
    },
    widgetTitleWrapper: {
      display: 'table-row'
    },
    widgetTitle: {
      color: '#424242',
      textAlign: 'left',
      fontWeight: 'lighter',
      font: 'Arial,Helvetica Neue,Helvetica,sans-serif-light',
      fontSize: '16px',
      whiteSpace: 'pre-wrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      width: '50%',
      maxWidth: '400px',
      display: 'table-cell',
      cursor: 'pointer'
    },
    widgetTitleMobile: {
      width: '45%',
      maxWidth: '135px'
    },
    widgetImages: {
      display: 'table-cell',
      textAlign: 'right',
      minWidth: '180px',
      '& svg': {
        paddingRight: '2px'
      }
    },
    widgetImageActive: {
      '& svg': {
        color: '#005eb8',
        cursor: 'pointer',
        '@media (forced-colors: active)': {
          forcedColorAdjust: 'none',
          color: 'CanvasText'
        }
      }
    },
    widgetImage: {
      '& svg': {
        color: '#5f6368',
        cursor: 'pointer',
        '&:hover': {
          color: '#005eb8'
        },
        '@media (forced-colors: active)': {
          forcedColorAdjust: 'none',
          color: 'LinkText'
        }
      }
    },
    spacer: {
      flex: 1,
      minWidth: '20px',
      borderTop: 'solid 1px #dfe1e5'
    },
    title: {
      fontSize: '18px',
      lineHeight: '20px',
      paddingTop: '1px',
      marginBottom: '1em',
      color: '#424242'
    }
  }))
}
