import React from 'react'
import RightTopAlexWidget from 'components/contents/resultpages/widgets/RightTopAlexWidget'
import { FetchWithCache, FetchWithCacheAndTracking } from 'utils/api'
import * as Config from 'config'
import { alexResultTypes } from 'constants/constants'
import { trackException } from 'utils/tracking'
import { renewAuthorizationToken } from 'utils/token'
import { IAADState } from 'store/Auth/reducers'

export interface IAlexWidgetResultItem {
  result: any[]
  activeDomains: string[]
}

export interface IAlexWidgetResults {
  alextopics: IAlexWidgetResultItem
  alexfeatured: IAlexWidgetResultItem
  alexsearch: IAlexWidgetResultItem
}
/**
 * Generate Alex TopRight widget based on search query
 * @param searchQuery The search query
 * @return a promise of the generated left widget react component
 */
export async function generateAlexTopRightWidget(
  searchQuery: string,
  aadInfo: IAADState
): Promise<React.ReactElement | null> {
  try {
    if (!searchQuery || searchQuery === '' || searchQuery === '""') {
      return null
    }

    // Get and check authentication token
    const esToken = await renewAuthorizationToken(
      aadInfo.accessToken,
      aadInfo.instance,
      aadInfo.accounts
    )

    // First make a request to get needed parameters for the query
    const paramQueryUrl = `${Config.APIM_BASE_URL}alexapi/getqueryparameters`
    const paramResponse = await FetchWithCache(paramQueryUrl, {
      method: 'GET',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
        Authorization: `Bearer ${esToken}`
      }
    })

    if (
      !paramResponse ||
      paramResponse.hasError ||
      !paramResponse.responseJSON
    ) {
      trackException(
        'Error fetching alex query parameters (TopRightAlexWidget)',
        new Error('Error fetching alex query parameters (TopRightAlexWidget)'),
        null
      )
    }

    //fetch all searchtypes
    const fetchAlexResultTypes: string[] = [
      alexResultTypes.featured,
      alexResultTypes.topics,
      alexResultTypes.search
    ]
    const promiseArray: Array<Promise<any>> = []

    fetchAlexResultTypes.forEach((alexResultType: string) => {
      promiseArray.push(
        performSingleFetch(searchQuery, alexResultType, paramResponse, esToken)
      )
    })

    //compute fetch results

    const resultArray: IAlexWidgetResults = {
      alextopics: { result: [], activeDomains: [] },
      alexfeatured: { result: [], activeDomains: [] },
      alexsearch: { result: [], activeDomains: [] }
    }
    await Promise.all(promiseArray).then((responses: any[]) => {
      responses.forEach((response: any) => {
        if (
          response &&
          response.response &&
          !response.response.hasError &&
          response.response.responseJSON
        ) {
          const result = response.response.responseJSON

          //alexResultType response
          switch (response.alexResultType) {
            case alexResultTypes.featured:
              resultArray.alexfeatured.result =
                result && result.searchResultModel
                  ? result.searchResultModel.featuredContent
                    ? result.searchResultModel.featuredContent.items
                    : []
                  : []
              resultArray.alexfeatured.activeDomains = response.activeDomains
                ? response.activeDomains
                : ['INTL']
              break
            case alexResultTypes.topics:
              resultArray.alextopics.result =
                result && result.searchResultModel
                  ? result.searchResultModel.topicOverviewContent
                    ? result.searchResultModel.topicOverviewContent.items
                    : []
                  : []
              resultArray.alextopics.activeDomains = response.activeDomains
                ? response.activeDomains
                : ['INTL']
              break
            case alexResultTypes.search:
              resultArray.alexsearch.result =
                result && result.searchResultModel
                  ? result.searchResultModel.searchContent
                    ? result.searchResultModel.searchContent.items
                    : []
                  : []
              resultArray.alexsearch.activeDomains = response.activeDomains
                ? response.activeDomains
                : ['INTL']
              break
          }
        }
      })
    })

    return (
      <RightTopAlexWidget searchQuery={searchQuery} results={resultArray} />
    )
  } catch (rejectedValue) {
    return null
  }
}

const performSingleFetch = async (
  searchQuery: string,
  alexResultType: string,
  paramResponse: any,
  authToken: string
) =>
  new Promise(async (resolve) => {
    let searchType = ''
    switch (alexResultType) {
      case alexResultTypes.featured:
        searchType = 'ALEXKAEGFEATURED'
        break
      case alexResultTypes.topics:
        searchType = 'TOPIC'
        break
      case alexResultTypes.manuals:
        searchType = 'KAEG'
        break
      case alexResultTypes.search:
        searchType = 'ALEX'
        break
      default:
        searchType = 'ALEX'
    }

    let applicableCountries = []
    let auditingStandards = []
    let versions = []
    let activeDomains = []

    if (
      !paramResponse ||
      paramResponse.hasError ||
      !paramResponse.responseJSON
    ) {
      applicableCountries.push('International')
      auditingStandards.push('ISA')
    } else {
      applicableCountries = paramResponse.responseJSON.applicableCountries
      auditingStandards = paramResponse.responseJSON.auditingStandards
      versions = paramResponse.responseJSON.versions
      activeDomains = paramResponse.responseJSON.activeDomains
    }

    // Build and get results alex data source
    let apiUrl = `${
      Config.APIM_BASE_URL
    }alexapi/searchdatawithallfilters?searchTerm=${encodeURIComponent(
      searchQuery
    )}&startPage=1&endPage=1&searchType=${searchType}&applicableCountries=${applicableCountries.join(
      '%'
    )}&auditingStandards=${auditingStandards.join(
      '%'
    )}&processFeaturedResults=false`

    if (versions && versions.length > 0) {
      apiUrl += `&versions=${versions.join('%')}`
    }

    if (activeDomains && activeDomains.length > 0) {
      apiUrl += `&activeDomains=${activeDomains.join('%')}`
    }
    apiUrl += '&sortOrder=Relevance'
    const response = await FetchWithCacheAndTracking(apiUrl, {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
        Authorization: `Bearer ${authToken}`
      }
    })

    resolve({
      alexResultType: alexResultType,
      response: response,
      activeDomains: activeDomains
    })
  })
