import React, { useMemo } from 'react'
import ReactPlaceholder from 'react-placeholder'
import { Box, Fade } from '@mui/material'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import 'Placeholder.css'
import { Store } from 'store'
import ResultsIbfdStore from 'store/ResultsIbfd'
import TextResult from 'components/contents/results/TextResult'
import Pagination from '../Pagination'
import { trackException } from 'utils/tracking'
import { dateFormatOptions } from 'constants/constants'
import SearchSuggestions from 'components/contents/common/SearchSuggestions'
import { capitalizeFirstLetter } from 'utils/string'
import { IDeviceSetting } from 'utils/deviceSettings'
import FeaturedResults from './FeaturedResults'
import LeftWidgetContainer from './widgetscontainer/LeftWidgetContainer'
import { getStylesResults } from 'styles/contents/resultpages/Results'
import { useFilter } from 'utils/filters'
import createDOMPurify from 'dompurify'
import { formatDisplayBody } from 'utils/intranet'

interface ResultsIbfdMobileProps {
  searchQuery: any
  deviceSettings: IDeviceSetting
}

type AllMobileProps = ReturnType<typeof mapStateToProps> &
  ResultsIbfdMobileProps

function ResultsIbfdMobile(props: AllMobileProps) {
  const {
    hasResultsBeenFetched,
    resultsCount,
    featuredResults,
    resultsCombined,
    searchQuery,
    deviceSettings
  } = props
  const DOMPurify = createDOMPurify(window)
  const resultClasses = getStylesResults()
  const intl = useIntl()
  const [currentPage] = useFilter('page', '1')

  const buildTabType = (resultRegion: string[], resultCountry: string[]) => {
    const resultStringRegion =
      resultRegion && resultRegion.length > 0 ? resultRegion[0] : ''

    const resultStringCountry =
      resultCountry && resultCountry.length > 0 ? resultCountry[0] : ''
    const tabType =
      resultStringRegion +
      (resultStringRegion && resultStringCountry ? ' › ' : '') +
      resultStringCountry

    if (!tabType) return ' '
    return tabType
  }

  const returnAllItems = (type: string) => {
    if (!resultsCombined || resultsCombined.length < 1) {
      return []
    }

    const items = []

    try {
      const firstResults =
        resultsCombined.length < 4 ? resultsCombined.length : 3
      if (type === 'first') {
        for (let i = 0; i < firstResults; i++) {
          const currentDate = resultsCombined[i].date
            ? intl.formatDate(
                new Date(
                  DOMPurify.sanitize(resultsCombined[i].date).replace(
                    /(\d{4})(\d{2})(\d{2})/,
                    '$1/$2/$3'
                  )
                ).toString(),
                dateFormatOptions
              )
            : ''
          const currentBody =
            DOMPurify.sanitize(resultsCombined[i].snippet) || ''
          const displayBody = formatDisplayBody(currentDate, currentBody, intl)
          items.push(
            <TextResult
              title={capitalizeFirstLetter(
                DOMPurify.sanitize(resultsCombined[i].title)
              )}
              body={displayBody}
              url={DOMPurify.sanitize(resultsCombined[i].resultUrl)}
              featured={false}
              showContextMenu={false}
              key={i}
              index={i}
              searchQuery={DOMPurify.sanitize(searchQuery)}
              deviceSettings={deviceSettings}
              tabType={buildTabType(
                resultsCombined[i].region,
                resultsCombined[i].country
              )}
            />
          )
        }
      } else {
        for (let i = firstResults; i < resultsCombined.length; i++) {
          const currentDate = resultsCombined[i].date
            ? intl.formatDate(
                new Date(
                  DOMPurify.sanitize(resultsCombined[i].date).replace(
                    /(\d{4})(\d{2})(\d{2})/,
                    '$1/$2/$3'
                  )
                ).toString(),
                dateFormatOptions
              )
            : ''
          const currentBody =
            DOMPurify.sanitize(resultsCombined[i].snippet) || ''
          const displayBody = formatDisplayBody(currentDate, currentBody, intl)
          items.push(
            <TextResult
              title={capitalizeFirstLetter(
                DOMPurify.sanitize(resultsCombined[i].title)
              )}
              body={displayBody}
              url={DOMPurify.sanitize(resultsCombined[i].resultUrl)}
              featured={false}
              showContextMenu={false}
              key={i}
              index={i}
              searchQuery={DOMPurify.sanitize(searchQuery)}
              deviceSettings={deviceSettings}
              tabType={buildTabType(
                resultsCombined[i].region,
                resultsCombined[i].country
              )}
            />
          )
        }
      }
    } catch (error) {
      trackException(
        'Error returning all items in ResultsIbfdMobile.tsx',
        error
      )
    }
    return items
  }

  const firstItems = useMemo(
    () => returnAllItems('first'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resultsCombined, intl]
  )

  const restItems = useMemo(
    () => returnAllItems('rest'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resultsCombined, intl]
  )

  return (
    <Box className={resultClasses.container}>
      <Box className={resultClasses.resultsContainer}>
        <SearchSuggestions
          wildCardActive={true}
          resultCount={resultsCount}
          resultsFetchend={hasResultsBeenFetched}
        />

        <ReactPlaceholder
          ready={
            hasResultsBeenFetched || Number(currentPage) > 1 ? true : false
          }
          type="text"
          rows={4}
          showLoadingAnimation={true}
          className={resultClasses.placeholder}
        >
          <Fade
            in={hasResultsBeenFetched || Number(currentPage) > 1 ? true : false}
            timeout={600}
          >
            <Box>
              {resultsCombined && resultsCount > 0 && (
                <FeaturedResults featuredResults={featuredResults} />
              )}
              {firstItems}
            </Box>
          </Fade>
        </ReactPlaceholder>
        {hasResultsBeenFetched &&
        (resultsCount < 1 || resultsCombined.length < 1) ? null : (
          <LeftWidgetContainer
            resultCount={resultsCount}
            hasResultsBeenFetched={hasResultsBeenFetched}
          />
        )}
        <ReactPlaceholder
          ready={hasResultsBeenFetched || Number(currentPage) > 1}
          type="text"
          rows={4}
          showLoadingAnimation={true}
          className={resultClasses.placeholder}
        >
          <Fade
            in={hasResultsBeenFetched || Number(currentPage) > 1}
            timeout={600}
          >
            <Box>
              {restItems}
              {resultsCount != null &&
                resultsCount > 0 &&
                hasResultsBeenFetched && (
                  <Pagination
                    {...props}
                    totalRecords={resultsCount}
                    pageLimit={20}
                    pageNeighbours={4}
                  />
                )}
            </Box>
          </Fade>
        </ReactPlaceholder>
        <ReactPlaceholder
          ready={hasResultsBeenFetched ? hasResultsBeenFetched : false}
          type="text"
          rows={4}
          showLoadingAnimation={true}
          className={resultClasses.placeholder}
        >
          <Box />
        </ReactPlaceholder>
      </Box>
    </Box>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    featuredResults: ResultsIbfdStore.selectors.getFeaturedResults(state),
    resultsCombined: ResultsIbfdStore.selectors.getResultsCombined(state),
    resultsCount: ResultsIbfdStore.selectors.getResultCount(state),
    executionTime: ResultsIbfdStore.selectors.getExecutionTime(state),
    hasResultsBeenFetched:
      ResultsIbfdStore.selectors.hasResultsBeenFetched(state)
  }
}

export default connect(mapStateToProps)(ResultsIbfdMobile)
