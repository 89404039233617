import { useCallback, useMemo, useState, useEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { trackException } from './tracking'

// Implementation inspired from the use-query-state library
type ValueType = string | string[] | number | boolean | Date
type QueryStateValue = string | string[]
type QueryStateResetValue = null | undefined
type QueryState = Record<string, QueryStateValue>
type QueryStateMerge = Record<string, QueryStateValue | QueryStateResetValue>

export const EMPTY_ARRAY_STRING = '[\u00A0]'

export function stripLeadingHashOrQuestionMark(s = ''): string {
  if (s && (s.indexOf('?') === 0 || s.indexOf('#') === 0)) {
    return s.slice(1)
  }
  return s
}

// Implementation can be replaced with the queryString library, but I didn't want to depend on it
export function parseQueryState(queryString: string): QueryState | null {
  const queryState: QueryState = {}
  const params = new URLSearchParams(
    stripLeadingHashOrQuestionMark(queryString)
  )

  params.forEach((value, key) => {
    if (key in queryState.constructor.prototype) {
      return console.warn(
        `parseQueryState | invalid key "${key}" will be ignored`
      )
    }

    if (key in queryState) {
      const queryStateForKey = queryState[key]

      if (Array.isArray(queryStateForKey)) {
        queryStateForKey.push(value)
      } else {
        queryState[key] = [queryStateForKey, value]
      }
    } else {
      queryState[key] = value
    }
  })

  return Object.keys(queryState).length ? queryState : null
}

export function createMergedQuery(...queryStates: QueryStateMerge[]): any {
  const mergedQueryStates: QueryStateMerge = Object.assign({}, ...queryStates)
  const params = new URLSearchParams()

  Object.entries(mergedQueryStates).forEach(([key, value]) => {
    // entries with null or undefined values are removed from the query string
    if (value === null || value === undefined) {
      return
    }

    if (Array.isArray(value)) {
      if (value.length) {
        value.forEach((v) => {
          params.append(key, encodeURIComponent(v) || '')
        })
      } else {
        params.append(key, EMPTY_ARRAY_STRING)
      }
    } else {
      params.append(key, encodeURIComponent(value))
    }
  })
  let mergedQuery = ''
  params.forEach(function (value, key) {
    if (mergedQuery !== '') {
      mergedQuery += '&'
    }
    mergedQuery += key + '=' + value
  })
  return mergedQuery
}

export function toQueryStateValue(
  value: ValueType | any
): QueryStateValue | null {
  if (Array.isArray(value)) {
    return value.map((v) => v.toString())
  } else if (value || value === '' || value === false || value === 0) {
    if (value instanceof Date) {
      return value.toJSON()
    }

    switch (typeof value) {
      case 'string':
      case 'number':
      case 'boolean':
        return value.toString()
      default:
        break
    }
  }
  return null
}

export const newStringArray: () => string[] = () => []

export function parseQueryStateValue<T>(
  value: QueryStateValue,
  defaultValue: T
): ValueType | null {
  const defaultValueType = typeof defaultValue

  if (Array.isArray(defaultValue)) {
    // special case of empty array saved in query string to keep it distinguishable from ['']
    if (value === EMPTY_ARRAY_STRING) {
      return []
    }
    return newStringArray().concat(value)
  }

  if (typeof value !== 'string' && !Array.isArray(value)) {
    return null
  }

  if (defaultValue instanceof Date) {
    const valueAsDate = new Date(value.toString())

    if (!isNaN(valueAsDate.valueOf())) {
      return valueAsDate
    }
  }

  switch (defaultValueType) {
    case 'string':
      return value.toString()
    case 'number':
      const num = Number(value)
      return num || num === 0 ? num : null
    case 'boolean':
      if (value === 'true') {
        return true
      } else if (value === 'false') {
        return false
      }
      break
    default:
  }
  return null
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function sameAsJsonString(compareValueA: any, compareValueB: any) {
  return JSON.stringify(compareValueA) === JSON.stringify(compareValueB)
}

export type QueryString = string

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type SetQueryStateFn<T> = (
  newState: QueryStateMerge,
  opts?: SetQueryStringOptions
) => void

export type SetQueryStateItemFn<T> = (
  newValue: T,
  opts?: SetQueryStringOptions
) => void

export interface QueryStringInterface {
  getQueryString: () => QueryString
  setQueryString: (
    newQueryString: QueryString,
    opts: SetQueryStringOptions
  ) => void
  getLocationPath?: () => string
}

export interface SetQueryStringOptions {
  method?: 'replace' | 'push'
}

export type QueryStateOpts = {
  stripDefaults?: boolean
  queryStringInterface?: QueryStringInterface
}

export type QueryStateOptsSetInterface = {
  stripDefaults?: boolean
}

export function useQueryStateObj<T extends QueryState>(
  defaultQueryState: T
): [QueryState, SetQueryStateFn<T>] {
  const reactRouterQueryHandler = useReactRouterQueryStringInterface()
  const queryString = reactRouterQueryHandler.getQueryString()
  const [, setLatestMergedQueryString] = useState<string>()
  const queryState = useMemo(
    () => ({
      ...defaultQueryState,
      ...parseQueryState(queryString)
    }),
    [defaultQueryState, queryString]
  )

  const ref = useRef({
    defaultQueryState,
    reactRouterQueryHandler
  })

  const setQueryState: SetQueryStateFn<T> = useCallback((newState, opts) => {
    const { defaultQueryState, reactRouterQueryHandler } = ref.current
    const stripOverwrite: QueryStateMerge = {}

    // when a params are set to the same value as in the defaults
    // we remove them to avoid having two URLs reproducing the same state unless stripDefaults === false
    Object.entries(newState).forEach(([key]) => {
      if (defaultQueryState[key] === newState[key]) {
        stripOverwrite[key] = null
      }
    })

    // retrieve the last value (by re-executing the search getter)
    const currentQueryState: QueryState = {
      ...defaultQueryState,
      ...parseQueryState(reactRouterQueryHandler.getQueryString())
    }

    const mergedQueryString = createMergedQuery(
      currentQueryState || {},
      newState,
      stripOverwrite
    )

    reactRouterQueryHandler.setQueryString(mergedQueryString, opts || {})

    // triggers an update (in case the QueryStringInterface misses to do so)
    setLatestMergedQueryString(mergedQueryString)
  }, [])

  useEffect(() => {
    ref.current = {
      defaultQueryState,
      reactRouterQueryHandler
    }
  })

  return [queryState, setQueryState]
}

export function useReactRouterQueryStringInterface(): QueryStringInterface {
  const location = useLocation()
  const navigateFunction = useNavigate()

  return {
    getQueryString: () =>
      location.search ? location.search.replace('?', '') : '',
    setQueryString: (newQueryString, { method = 'replace' }) => {
      navigateFunction(`${newQueryString}`, { replace: method === 'replace' })
    },
    getLocationPath: () => location.pathname
  }
}

export default function useQueryState<T>(
  itemName: string,
  defaultValue: T
): [T, SetQueryStateItemFn<T>] {
  // defaultValue is not allowed to be changed after init
  // eslint-disable-next-line @typescript-eslint/no-extra-semi
  ;[defaultValue] = useState(defaultValue)
  const defaultQueryStateValue = toQueryStateValue(defaultValue)
  const defaultQueryState = useMemo(() => {
    return defaultQueryStateValue
      ? {
          [itemName]: defaultQueryStateValue
        }
      : {}
  }, [itemName, defaultQueryStateValue])

  if (defaultQueryStateValue === null) {
    trackException(
      'unsupported defaultValue',
      new Error('unsupported defaultValue')
    )
    throw new Error('unsupported defaultValue')
  }

  const [queryState, setQueryState] = useQueryStateObj(defaultQueryState)
  const setQueryStateItem: SetQueryStateItemFn<T> = useCallback(
    (newValue, opts) => {
      // stringify the given value (or array of strings)
      let newQueryStateValue = toQueryStateValue(newValue)

      // warn when value type is not supported (do not warn when null was passed explicitly)
      if (
        (newQueryStateValue === null && newValue !== newQueryStateValue) ||
        !(
          newQueryStateValue !== null &&
          typeof parseQueryStateValue(newQueryStateValue, defaultValue) ===
            typeof defaultValue
        )
      ) {
        console.warn(
          'value of ' +
            JSON.stringify(newValue) +
            ' is not supported. "' +
            itemName +
            '" will reset to default value',
          defaultValue
        )
        newQueryStateValue = null
      }

      // when new value is equal to default, we call setQueryState with a null value to reset query string
      // arrays have to be compared json stringified, other values can compared by value
      if (
        Array.isArray(defaultValue) &&
        sameAsJsonString(newValue, defaultValue)
      ) {
        newQueryStateValue = null
      } else if (newValue === defaultValue) {
        newQueryStateValue = null
      }

      setQueryState({ [itemName]: newQueryStateValue }, opts)
    },
    [defaultValue, itemName, setQueryState]
  )

  // fallback to default value
  let value = defaultValue
  const queryStateItem = queryState[itemName]
  let queryStateValue = null

  if (queryStateItem || queryStateItem === '') {
    queryStateValue = parseQueryStateValue(queryStateItem, defaultValue)
  }

  if (
    queryStateValue !== null &&
    typeof queryStateValue === typeof defaultValue
  ) {
    value = queryStateValue as any
  }

  return [value, setQueryStateItem]
}
