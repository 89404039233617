import React, { useCallback, useEffect, useMemo } from 'react'
import { Box, Fade } from '@mui/material'
import { useIntl } from 'react-intl'
import ReactPlaceholder from 'react-placeholder'
import { connect } from 'react-redux'
import 'Placeholder.css'
import { Store } from 'store'
import ResultsContentFinderStore from 'store/ResultsContentFinder'
import ResultMetaDataStore from 'store/ResultMetaData'
import UserSettingsStore from 'store/UserSettings'
import SettingsStore from 'store/Settings'
import PageStay from 'components/HOC/PageStay'
import ErrorPage from 'components/contents/common/ErrorPage'
import { ContentFinderResult } from 'components/contents/results/ContentFinderResult'
import RightTopWidgetContainer from 'components/contents/resultpages/widgetscontainer/RightTopWidgetContainer'
import Pagination from '../Pagination'
import { trackException } from 'utils/tracking'
import { useReactRouterQueryStringInterface } from 'utils/useQueryState'
import { authorizeResultPage } from 'utils/authorization'
import createDOMPurify from 'dompurify'
import { dataSourcesTabs } from 'constants/constants'
import LeftWidgetContainer from './widgetscontainer/LeftWidgetContainer'
import RightWidgetContainer from './widgetscontainer/RightWidgetContainer'
import { getStylesResults } from 'styles/contents/resultpages/Results'
import { useFilter } from 'utils/filters'
import { KPMGFindGlobalVariables } from 'store/KPMGFindGlobalVariables'
import { useLocation } from 'react-router-dom'

export interface ResultsContentFinderProps {}

type AllProps = ReturnType<typeof mapStateToProps> & ResultsContentFinderProps

function ResultsContentFinder(props: AllProps) {
  const {
    results,
    resultsCount,
    hasResultsBeenFetched,
    hasErrorStore,
    userSettings,
    deviceSettings
  } = props
  const DOMPurify = createDOMPurify(window)
  const resultClasses = getStylesResults()
  const { getQueryString } = useReactRouterQueryStringInterface()
  const intl = useIntl()

  const [currentPage] = useFilter('page', '1')
  const [searchQuery] = useFilter('q')
  const location = useLocation()

  useEffect(() => {
    KPMGFindGlobalVariables.setCurrentTab(dataSourcesTabs.contentfinder)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setup = useCallback(() => {
    try {
      authorizeResultPage(
        'ContentFinder',
        userSettings,
        deviceSettings,
        DOMPurify.sanitize(location && location.search ? location.search : '')
      )
    } catch (error) {
      trackException(
        'Error in fetching results in ResultsContentFinder.tsx',
        error
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getQueryString()])

  useEffect(() => {
    setup()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getQueryString(), intl])

  const returnAllItems = (type: string) => {
    if (!results || !results.titles || results.titles.length < 1) {
      return []
    }

    const {
      titles,
      pictureUrls,
      links,
      dates,
      fileSizes,
      fileNames,
      descriptions,
      likes,
      views
    } = results

    const items = []

    try {
      const firstResults = titles.length < 4 ? titles.length : 3

      if (type === 'first') {
        for (let i = 0; i < firstResults; i++) {
          items.push(
            <ContentFinderResult
              title={`${DOMPurify.sanitize(titles[i])}`}
              pictureUrl={DOMPurify.sanitize(pictureUrls[i])}
              link={DOMPurify.sanitize(links[i])}
              date={DOMPurify.sanitize(dates[i])}
              fileSize={DOMPurify.sanitize(fileSizes[i])}
              fileName={DOMPurify.sanitize(fileNames[i])}
              description={DOMPurify.sanitize(descriptions[i])}
              likes={DOMPurify.sanitize(likes[i])}
              views={DOMPurify.sanitize(views[i])}
              key={i}
              index={i}
              searchQuery={DOMPurify.sanitize(searchQuery)}
              deviceSettings={deviceSettings}
            />
          )
        }
      } else {
        for (let i = firstResults; i < titles.length; i++) {
          items.push(
            <ContentFinderResult
              title={`${DOMPurify.sanitize(titles[i])}`}
              pictureUrl={DOMPurify.sanitize(pictureUrls[i])}
              link={DOMPurify.sanitize(links[i])}
              date={DOMPurify.sanitize(dates[i])}
              fileSize={DOMPurify.sanitize(fileSizes[i])}
              fileName={DOMPurify.sanitize(fileNames[i])}
              description={DOMPurify.sanitize(descriptions[i])}
              likes={DOMPurify.sanitize(likes[i])}
              views={DOMPurify.sanitize(views[i])}
              key={i}
              index={i}
              searchQuery={DOMPurify.sanitize(searchQuery)}
              deviceSettings={deviceSettings}
            />
          )
        }
      }
    } catch (error) {
      trackException(
        'Error returning all items in ResultsContentFinder.tsx',
        error
      )
    }
    return items
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const firstItems = useMemo(() => returnAllItems('first'), [results, intl])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const restItems = useMemo(() => returnAllItems('rest'), [results, intl])

  return (
    <Box>
      {!hasErrorStore && !!searchQuery && (
        <Box className={resultClasses.container}>
          <Box
            id={'con-resultvertical-01'}
            className={resultClasses.resultsContainer}
          >
            <ReactPlaceholder
              ready={hasResultsBeenFetched ? hasResultsBeenFetched : false}
              type="text"
              rows={4}
              showLoadingAnimation={true}
              className={resultClasses.placeholder}
            >
              <Fade
                in={hasResultsBeenFetched ? hasResultsBeenFetched : false}
                timeout={600}
              >
                <Box id={'con-resultvertical-section-01'}>{firstItems}</Box>
              </Fade>
            </ReactPlaceholder>
            {Number(currentPage) === 1 && (
              <LeftWidgetContainer
                resultCount={resultsCount}
                hasResultsBeenFetched={hasResultsBeenFetched}
              />
            )}
            <ReactPlaceholder
              ready={hasResultsBeenFetched}
              type="text"
              rows={4}
              showLoadingAnimation={true}
              className={resultClasses.placeholder}
            >
              <Fade in={hasResultsBeenFetched} timeout={600}>
                <Box id={'con-resultvertical-section-02'}>
                  {restItems}
                  {resultsCount != null && resultsCount > 0 && (
                    <Pagination
                      {...props}
                      totalRecords={resultsCount}
                      pageLimit={20}
                      pageNeighbours={4}
                    />
                  )}
                </Box>
              </Fade>
            </ReactPlaceholder>
            <ReactPlaceholder
              ready={hasResultsBeenFetched ? hasResultsBeenFetched : false}
              type="text"
              rows={4}
              showLoadingAnimation={true}
              className={resultClasses.placeholder}
            >
              <Box />
            </ReactPlaceholder>
            <ReactPlaceholder
              ready={hasResultsBeenFetched ? hasResultsBeenFetched : false}
              type="text"
              rows={4}
              showLoadingAnimation={true}
              className={resultClasses.placeholder}
            >
              <Box />
            </ReactPlaceholder>
          </Box>
          <Box className={resultClasses.advertisementContainer}>
            <RightTopWidgetContainer
              searchQuery={searchQuery}
              scope={'contentfinder'}
            />
            <RightWidgetContainer />
          </Box>
        </Box>
      )}
      {hasErrorStore && <ErrorPage dataSource="contentfinder" />}
    </Box>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    results: ResultsContentFinderStore.selectors.getResults(state),
    resultsCount: ResultsContentFinderStore.selectors.getResultCount(state),
    executionTime: ResultsContentFinderStore.selectors.getExecutionTime(state),
    hasResultsBeenFetched:
      ResultsContentFinderStore.selectors.hasResultsBeenFetched(state),
    hasErrorStore: ResultMetaDataStore.selectors.getDataSourceHasError(
      state,
      'contentfinder'
    ),
    userSettings: UserSettingsStore.selectors.getUserSettings(state),
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state)
  }
}

export default PageStay(connect(mapStateToProps)(ResultsContentFinder))
