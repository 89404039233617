import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesSiteResult(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles() {
  return makeStyles((theme: Theme) => ({
    result: {
      marginBottom: '32px',
      [`${theme.breakpoints.down(1024)}`]: {
        backgroundColor: '#fff',
        margin: '0 0 10px 0',
        boxShadow: '0 2px 6px rgba(32, 33, 36, 0.28)',
        borderRadius: '4px',
        paddingTop: '12px',
        paddingBottom: '12px',
        paddingLeft: '16px',
        paddingRight: '16px',
        fontFamility: 'Roboto, HelveticaNeue, Arial, sans-serif'
      }
    },
    title: {
      fontSize: '20px',
      paddingTop: '4px',
      marginBottom: '3px',
      color: '#004e98',
      width: '100%',
      [`${theme.breakpoints.down(1024)}`]: {
        fontSize: '18px',
        lineHeight: '20px',
        marginBottom: '12px',
        fontWeight: '400',
        fontFamility: 'Roboto, HelveticaNeue, Arial, sans-serif'
      }
    },
    urlBreadcrumbs: {
      fontSize: '14px',
      padding: '1px 0px',
      lineHeight: '1.3',
      color: '#3C4043',
      marginRight: '7px'
    },
    link: {
      display: 'flex',
      flexDirection: 'row',
      flex: 1,
      textDecoration: 'none'
    },
    body: {
      fontSize: '14px',
      lineHeight: '20px',
      wordWrap: 'break-word', // older browsers
      overflowWrap: 'break-word', // css3,
      color: '#3c4043',
      [`${theme.breakpoints.down(1024)}`]: {
        display: 'none'
      }
    },
    urlWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    resultCollapse: {
      display: 'none',
      [`${theme.breakpoints.down(1024)}`]: {
        display: 'block',
        '& .MuiCollapse-wrapper': {
          display: 'block'
        }
      }
    },
    mobileCollapseIcon: {
      display: 'none',
      [`${theme.breakpoints.down(1024)}`]: {
        display: 'inline-flex'
      }
    },
    mobileBody: {
      [`${theme.breakpoints.down(1024)}`]: {
        fontSize: '14px',
        lineHeight: '20px',
        wordWrap: 'break-word', // older browsers
        overflowWrap: 'break-word', // css3,
        color: '#3c4043'
      }
    }
  }))
}
