import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesKxFilterBar(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles() {
  return makeStyles((theme: Theme) => ({
    root: {
      marginBottom: '10px',
      [`${theme.breakpoints.down(813)}`]: {
        marginTop: '0px',
        marginBottom: '20px',
        padding: '0 0px',
        width: '100%'
      },
      '@media (orientation: portrait) ': {},
      '@media (orientation: landscape)': {}
    },
    gridList: {
      overflow: 'visible !important',
      zIndex: 2,
      flexWrap: 'nowrap',
      backgroundColor: 'white',
      width: '100%',
      flexGrow: 0,
      flexShrink: 0
    },
    iconMenu: {
      width: '3px'
    },
    iconContainer: {
      display: 'flex',
      flexWrap: 'nowrap',
      marginInlineStart: '-11px'
    },
    startIconContainer: {
      margin: '2px',
      padding: 0,
      display: 'flex',
      flexWrap: 'nowrap',
      [`${theme.breakpoints.down(350)}`]: {
        overflow: 'hidden'
      }
    },
    icon: {
      float: 'left',
      [`${theme.breakpoints.down(1024)}`]: {
        display: 'none'
      }
    },
    result: {
      zIndex: 1,
      flex: 1,
      height: '50px',
      lineHeight: '50px',
      color: 'grey',
      fontSize: 14,

      display: 'inline-block',
      whiteSpace: 'nowrap',
      position: 'absolute',
      [`${theme.breakpoints.down(825)}`]: {
        marginLeft: 10,
        marginTop: 24
      }
    }
  }))
}
