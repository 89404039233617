import { Reducer } from 'redux'
import {
  ResultsContentFinderActions,
  ResultsContentFinderActionTypes,
  IFetchSuccess
} from './actions'

export interface IContentFinderResult {
  titles: Array<any>
  pictureUrls: Array<any>
  links: Array<any>
  dates: Array<any>
  fileSizes: Array<any>
  fileNames: Array<any>
  descriptions: Array<any>
  likes: Array<any>
  views: Array<any>
}
export interface IResultsContentFinderStore {
  hasResultsBeenFetched: boolean
  results: IContentFinderResult
  resultsCombined: IContentFinderResult
  resultsCombinedQuery: string
  resultCount: number
  executionTime: number
  currentPage: number
}

const resultsInitialState = () => ({
  titles: [],
  pictureUrls: [],
  links: [],
  dates: [],
  fileSizes: [],
  fileNames: [],
  descriptions: [],
  likes: [],
  views: []
})

const initialState: IResultsContentFinderStore = {
  hasResultsBeenFetched: false,
  resultCount: -1,
  results: resultsInitialState(),
  resultsCombined: resultsInitialState(),
  resultsCombinedQuery: '',
  executionTime: 0,
  currentPage: 1
}

const reducers: Reducer<
  IResultsContentFinderStore,
  ResultsContentFinderActions
> = (
  state: IResultsContentFinderStore = initialState,
  action:
    | ResultsContentFinderActions
    | { type: 'SET_CURRENT_PAGE'; payload: any }
) => {
  switch (action.type) {
    case 'SET_CURRENT_PAGE':
      return {
        ...state,
        currentPage: action.payload || 0
      }
    case ResultsContentFinderActionTypes.INITIALIZE_RESULTS_CONTENTFINDER:
      if (!(action as any).payload) {
        return initialState
      }
      const { resultsContentFinder } = (action as any).payload
      return {
        ...state,
        currentPage: resultsContentFinder.currentPage || 1
      }
    case ResultsContentFinderActionTypes.FETCH_REQUEST:
      const { resetCombined, searchQuery } = (action as any).payload
      return {
        ...state,
        hasResultsBeenFetched: false,
        executionTime: 0,
        resultCount: -1,
        results: resultsInitialState(),
        resultsCombined: resetCombined
          ? resultsInitialState()
          : state.resultsCombined,
        resultsCombinedQuery: searchQuery
      }
    case ResultsContentFinderActionTypes.FETCH_FAILURE:
      return {
        ...state,
        hasResultsBeenFetched: true,
        resultCount: 0
      }
    case ResultsContentFinderActionTypes.FETCH_SUCCESS:
      const { response } = (action as IFetchSuccess).payload
      return {
        ...state,
        hasResultsBeenFetched: true,
        executionTime: response.executionTime,
        resultCount: response.resultCount,
        results: {
          titles: response.titles,
          pictureUrls: response.pictureUrls,
          links: response.links,
          dates: response.dates,
          fileSizes: response.fileSizes,
          fileNames: response.fileNames,
          descriptions: response.descriptions,
          likes: response.likes,
          views: response.views
        },
        currentPage: response.currentPage
      }
    case ResultsContentFinderActionTypes.FETCH_SUCCESS_COMBINED:
      const { response: responseCombined } = (action as IFetchSuccess).payload
      return {
        ...state,
        resultsCombined: {
          titles: responseCombined.titles,
          pictureUrls: responseCombined.pictureUrls,
          links: responseCombined.links,
          dates: responseCombined.dates,
          fileSizes: responseCombined.fileSizes,
          fileNames: responseCombined.fileNames,
          descriptions: responseCombined.descriptions,
          likes: responseCombined.likes,
          views: responseCombined.views
        }
      }
    default:
      return state
  }
}

export default reducers
