import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesRightServiceNowAUWidget(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles(): any {
  return makeStyles((theme: Theme) => ({
    widgetText: {
      width: '100%',
      whiteSpace: 'pre-wrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    },
    widgetTitle: {
      fontWeight: 'lighter'
    },
    widgetDescription: {
      paddingTop: '5px'
    }
  }))
}
