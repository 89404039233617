import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Box, Card, CardContent, Grid, Typography } from '@mui/material'
import Link from '@mui/material/Link'
import { trackLeftWidgetClick } from 'utils/tracking'
import { dateFormatOptions } from 'constants/constants'
import { WidgetKey } from 'constants/widgets'
import createDOMPurify from 'dompurify'
import TooltipTitle from 'components/contents/common/TooltipTitle'
import { IVivaEngageResult } from 'components/models/VivaEngageResult'
import { IDeviceSetting } from 'utils/deviceSettings'
import { getProfilePicture } from 'utils/msgraphProfilePicture'
import PeoplePlaceholder from 'images/people_placeholder.png'
import { makeStyles } from '@mui/styles'
import { CustomDescription } from 'components/contents/common/CustomDescription'
import { getStylesLeftWidget } from 'styles/contents/resultpages/widgets/LeftWidget'
import { IAADState } from 'store/Auth/reducers'

export interface LefttVivaEngageWidgetResultProps {
  item: IVivaEngageResult
  deviceSettings: IDeviceSetting
  index: number
  aadInfo: IAADState
}

export default function LeftVivaEngageWidgetResult(
  props: LefttVivaEngageWidgetResultProps
): JSX.Element {
  const { item, deviceSettings, index, aadInfo } = props

  const DOMPurify = createDOMPurify(window)

  const leftWidgetClasses = getStylesLeftWidget()

  const [pictureUrl, setPictureUrl] = React.useState('')

  const intl = useIntl()

  const userProfileStyles = makeStyles(() => ({
    peopleProfilepic: {
      width: '40px',
      height: '40px',
      borderRadius: '50%',
      backgroundImage: `url('${
        pictureUrl
          ? pictureUrl.replace('MThumb', 'LThumb').split(' ').join('%20')
          : ''
      }'),url('${PeoplePlaceholder}') !important`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      marginBottom: '5px'
    },
    defaultProfilepic: {
      backgroundImage: 'url(' + PeoplePlaceholder + ')'
    }
  }))
  const userProfileClasses = userProfileStyles()

  const loadProfilePic = async () => {
    const pictureUrl = await getProfilePicture(
      item.sender.email,
      null,
      deviceSettings,
      aadInfo
    )
    setPictureUrl(pictureUrl)
  }

  useEffect(() => {
    loadProfilePic()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div key={'ln' + index}>
      <Box key={'lnb1' + index} style={{ width: 20 }} />
      <Link
        key={'lnl' + index}
        onClick={() => {
          trackLeftWidgetClick({
            title: DOMPurify.sanitize(item.web_url),
            url: DOMPurify.sanitize(item.web_url),
            widgetKey: WidgetKey.vivaengage,
            index: index
          })
        }}
        {...(!deviceSettings.isMobile
          ? {
              onAuxClick: (event: any) => {
                trackLeftWidgetClick({
                  title: DOMPurify.sanitize(item.web_url),
                  url: DOMPurify.sanitize(item.web_url),
                  widgetKey: WidgetKey.vivaengage,
                  index: index,
                  wasRightClicked: event?.button === 2 ? true : false
                })
              }
            }
          : {})}
        href={DOMPurify.sanitize(item.web_url)}
        className={leftWidgetClasses.cardLink}
        {...(deviceSettings.openLinksInNewTab === true
          ? { target: '_blank', rel: 'noreferrer' }
          : {})}
      >
        <Card key={'lnc' + index} className={leftWidgetClasses.card}>
          <CardContent
            key={'lncc' + index}
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Grid container direction={'row'}>
              <Grid item className={leftWidgetClasses.profilepicWrapper}>
                <div
                  className={
                    userProfileClasses.peopleProfilepic +
                    ' ' +
                    userProfileClasses.defaultProfilepic
                  }
                />
              </Grid>
              <Grid item width={'60%'}>
                <Typography
                  key={'lnt1' + index}
                  variant="body1"
                  color="primary"
                  component="div"
                  style={{
                    lineHeight: '1em',
                    height: '2em'
                  }}
                >
                  <TooltipTitle
                    title={DOMPurify.sanitize(item.sender.full_name.trim())}
                    key={'lnd1' + index}
                    singleLine={false}
                  />
                </Typography>
                <Typography
                  className={leftWidgetClasses.widgetSubTitle}
                  component="div"
                >
                  {`${intl.formatDate(
                    DOMPurify.sanitize(item.published_at),
                    dateFormatOptions
                  )}`}
                </Typography>
              </Grid>
            </Grid>

            <Typography
              key={'lnt2' + index}
              variant="body2"
              color="textSecondary"
              component="div"
              style={{
                maxHeight: 140,
                minHeight: 140,
                maxWidth: 164,
                overflow: 'hidden'
              }}
            >
              <CustomDescription
                descriptionValue={DOMPurify.sanitize(
                  item.content_excerpt.trim()
                )}
                maxLine="8"
              />
            </Typography>

            <TooltipTitle
              title={`${DOMPurify.sanitize(
                item.group.full_name !== ''
                  ? item.group.full_name
                  : intl.formatMessage({
                      id: 'vivaengage_storyline',
                      defaultMessage: 'Storyline'
                    })
              )}`}
              key={'lst2' + index}
              singleLine={true}
              additionalClass={leftWidgetClasses.vivaEngagePosted}
            />
          </CardContent>
        </Card>
      </Link>
    </div>
  )
}
