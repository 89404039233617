import React, { useEffect, useRef } from 'react'
import { Box } from '@mui/material'
import { getUrlParameterForCurrentFilters } from 'utils/filters'
import { getStylesTopRightChatWidget } from 'styles/contents/resultpages/widgets/TopRightChatWidget'
import { getStylesResultsChat } from 'styles/contents/resultpages/ResultsChat'
import { IChatRequest } from 'components/models/Chat'
import ChatMessage from 'components/contents/resultpages/chat/ChatMessage'
import ChatPrompt from 'components/contents/resultpages/chat/ChatPrompt'
import LaunchIcon from '@mui/icons-material/Launch'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Store } from 'store'
import ChatStore from 'store/ResultsChat'
import FilterStore from 'store/Filters'

export interface TopChatWidgetProps {
  currentDataSource: string
}

type AllProps = TopChatWidgetProps & ReturnType<typeof mapStateToProps>

const TopRightChatWidget = ({
  chatRequestList,
  currentfilters,
  currentDataSource
}: AllProps): any => {
  const classes = getStylesTopRightChatWidget()
  const resultChatClasses = getStylesResultsChat()

  //auto scroll chat area to bottom
  const chatAnswerContainerRef = useRef()
  useEffect(() => {
    if (chatAnswerContainerRef && chatAnswerContainerRef.current)
      (chatAnswerContainerRef.current as any).scrollTop = (
        chatAnswerContainerRef.current as any
      ).scrollHeight
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatAnswerContainerRef.current, chatRequestList])

  const urlParameter = getUrlParameterForCurrentFilters(currentfilters, [
    {
      key: 'page',
      value: '1'
    },
    {
      key: 'cntx',
      value: ''
    },
    {
      key: 'chatds',
      value: currentDataSource
    }
  ])

  return (
    <Box className={classes.widgetContainer}>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row-reverse',
          marginBottom: '10px'
        }}
      >
        <Link
          className={classes.link}
          to={`/results/chat/${urlParameter ? urlParameter : ''}`}
          style={{ textDecoration: 'none' }}
        >
          <LaunchIcon />
        </Link>
      </Box>
      <div className={resultChatClasses.chatContainer}>
        <Box
          className={resultChatClasses.chatContentContainer}
          ref={chatAnswerContainerRef}
          style={{ flex: '1 0 38vh' }}
        >
          {chatRequestList.map((item: IChatRequest) => {
            return (
              <>
                <ChatMessage type={'request'} item={item} />
                <ChatMessage type={'answer'} item={item} />
              </>
            )
          })}
        </Box>
        <Box className={resultChatClasses.chatPromptContainer}>
          <ChatPrompt
            autoFocus={false}
            compactView={true}
            initialDataSource={currentDataSource}
          />
        </Box>
      </div>
    </Box>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    chatRequestList: ChatStore.selectors.getChatRequests(state),
    currentfilters: FilterStore.selectors.getCurrentFilters(state)
  }
}

export default connect(mapStateToProps)(TopRightChatWidget)
