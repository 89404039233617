import React from 'react'
import * as Config from 'config'
import { ResultRightWidgetGenerator } from './interfaces'
import RightKlardenkerWidget from 'components/contents/resultpages/widgets/RightKlardenkerWidget'
import { stripHtml } from 'utils/string'
import { trackException } from 'utils/tracking'
import { FetchWithCache } from 'utils/api'
import { IDeviceSetting } from 'utils/deviceSettings'
import { IUserSetting } from 'utils/userSettings'
import { IFindConfiguration } from 'store/Settings/reducers'
import { IKlardenkerResult } from 'components/models/KlardenkerResult'
import { IAADState } from 'store/Auth/reducers'
import { renewAuthorizationToken } from 'utils/token'

export const rightKlardenkerWidgetGenerator: ResultRightWidgetGenerator = {
  fetch(
    aadInfo: IAADState,
    searchQuery: string,
    deviceSettings: IDeviceSetting,
    userSettings: IUserSetting,
    findConfiguration: IFindConfiguration
  ): Promise<IKlardenkerResult[]> {
    return new Promise<IKlardenkerResult[]>((resolve, reject) => {
      const fetchKlardenker = async () => {
        if (!searchQuery || searchQuery === '' || searchQuery === '""') {
          trackException(
            'Error in fetching Klardenker right widget in rightKlardenkerWidget.tsx',
            new Error('Empty query. Please specify a valid query text')
          )
          reject()
          return
        }
        const getAPIUrlByOrigin = (searchQuery: string): string => {
          const apiUrl = `${
            Config.APIM_BASE_URL
          }klardenkerapi/getsearch?search=${encodeURIComponent(
            searchQuery
          )}&processFeaturedResults=false`

          return apiUrl
        }

        const sourceUrl = getAPIUrlByOrigin(searchQuery)

        // Get and check authentication token
        const esToken = await renewAuthorizationToken(
          aadInfo.accessToken,
          aadInfo.instance,
          aadInfo.accounts
        )

        const response = await FetchWithCache(sourceUrl, {
          method: 'GET',
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
            Authorization: `Bearer ${esToken}`
          }
        })

        let results
        if (!response || response.hasError || !response.responseJSON) {
          trackException(
            'Error in fetching Klardenker right widget in rightKlardenkerWidget.tsx',
            new Error(
              response && response.errorResponse
                ? response.errorResponse.responseCode +
                  ':' +
                  response.errorResponse.message
                : ''
            )
          )
          reject()
          return
        } else {
          results = response.responseJSON
        }

        if (!results || !results.body || results.body.length < 3) {
          reject()
          return
        }

        const klardenkerResults: IKlardenkerResult[] = []

        results.body.forEach((item: any) => {
          const title =
            item.title && item.title.rendered
              ? stripHtml(item.title.rendered)
              : ''
          const body =
            item.excerpt && item.excerpt.rendered
              ? stripHtml(item.excerpt.rendered)
              : ''
          const date = item.date ? item.date : ''
          const resultUrl = item.link ? item.link : ''
          let imageUrl = ''
          if (
            item._embedded &&
            item._embedded['wp:featuredmedia'] &&
            item._embedded['wp:featuredmedia'][0] &&
            item._embedded['wp:featuredmedia'][0].media_details &&
            item._embedded['wp:featuredmedia'][0].media_details.sizes &&
            item._embedded['wp:featuredmedia'][0].media_details.sizes
              .thumbnail &&
            item._embedded['wp:featuredmedia'][0].media_details.sizes.thumbnail
              .source_url
          ) {
            imageUrl =
              item._embedded['wp:featuredmedia'][0].media_details.sizes
                .thumbnail.source_url
          } else {
            imageUrl = ''
          }

          klardenkerResults.push({
            id: item.id ? item.id : '',
            title: title,
            body: body,
            date: date,
            url: resultUrl,
            image: imageUrl
          })
        })

        resolve(klardenkerResults)
      }

      try {
        fetchKlardenker()
      } catch (error) {
        trackException(
          'Error in fetching Klardenker right widget in rightKlardenkerWidget.tsx',
          error
        )
        reject()
      }
    })
  },
  generate(
    results: IKlardenkerResult[],
    query: string
  ): Promise<React.ReactElement> {
    return new Promise<React.ReactElement>((resolve, reject) => {
      const generateKlardenker = () => {
        let hasContent = false
        for (let i = 0; i < results.length; i++) {
          if (results[i].title !== '') {
            hasContent = true
            break
          }
        }
        if (!hasContent) {
          reject()
          return
        }

        resolve(<RightKlardenkerWidget searchQuery={query} results={results} />)
      }

      try {
        generateKlardenker()
      } catch (error) {
        trackException(
          'Error in generating Klardenker right widget in rightKlardenkerWidget.tsx',
          error
        )
        reject()
      }
    })
  }
}
