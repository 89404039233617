import React, { useEffect } from 'react'
import { Input } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { useIntl } from 'react-intl'
import { useDebounce } from 'utils/debounce'
import { getStylesFilterMenuSearchInput } from 'styles/contents/FilterMenuSearchInput'
import createDOMPurify from 'dompurify'

export interface FilterMenuSearchInputProps {
  handleSearch: (debouncedSearch: string) => void
  handleMenuKeyDown: () => void
  anchorEl: any
}

export default function FilterMenuSearchInput(
  props: FilterMenuSearchInputProps
): JSX.Element {
  const classes = getStylesFilterMenuSearchInput()
  const intl = useIntl()
  const [search, setSearch] = React.useState<null | string>('')
  const debouncedSearchTerm = useDebounce(search, 250)
  const DOMPurify = createDOMPurify(window)
  useEffect(() => {
    if (!Boolean(props.anchorEl)) {
      setSearch(null)
    }
  }, [props.anchorEl])

  useEffect(
    () => {
      props.handleSearch(debouncedSearchTerm)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedSearchTerm]
  )

  function handleMenuKeyDown(event: any) {
    event.stopPropagation()
    if (event.keyCode === 40) {
      event.preventDefault()
      props.handleMenuKeyDown()
    }
  }

  function handleSearchInputChange(event: any) {
    setSearch(DOMPurify.sanitize(event.target.value))
  }

  return (
    <div className={classes.menuSearchBar}>
      <SearchIcon style={{ color: '#666' }}></SearchIcon>
      <Input
        className={classes.inputField}
        placeholder={intl.formatMessage({
          id: 'search',
          defaultMessage: 'Search'
        })}
        onChange={handleSearchInputChange}
        disableUnderline={true}
        onKeyDown={handleMenuKeyDown}
        type={'search'}
        inputProps={{ 'aria-label': 'description', size: 200 }}
      />
    </div>
  )
}
