import { Action, AnyAction } from 'redux'
import { Store } from '..'
import AuthStore from '../Auth'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { trackException } from 'utils/tracking'
import * as Config from 'config'
import { renewAuthorizationToken } from 'utils/token'
import { IMSAnswerModel } from './reducers'

export enum MSAnswersActionTypes {
  FETCH_BOOKMARK_REQUEST = 'msanswers/FETCH_BOOKMARK_REQUEST',
  FETCH_BOOKMARK_FAILURE = 'msanswers/FETCH_DATASOURCE_SETTINGS_FAILED',
  FETCH_BOOKMARK_SUCCESS = 'msanswers/FETCH_DATASOURCE_SETTINGS_SUCCESS',
  FETCH_QNA_REQUEST = 'msanswers/FETCH_QNA_REQUEST',
  FETCH_QNA_FAILURE = 'msanswers/FETCH_QNA_FAILURE',
  FETCH_QNA_SUCCESS = 'msanswers/FETCH_QNA_SUCCESS',
  FETCH_ACRONYM_REQUEST = 'msanswers/FETCH_ACRONYM_REQUEST',
  FETCH_ACRONYM_FAILURE = 'msanswers/FETCH_ACRONYM_FAILURE',
  FETCH_ACRONYM_SUCCESS = 'msanswers/FETCH_ACRONYM_SUCCESS'
}

/*** bookmarks */

export type IFetchBookmarkRequest = Action<MSAnswersActionTypes>

export interface IFetchBookmarkSuccess extends Action<MSAnswersActionTypes> {
  payload: {
    bookmarks: IMSAnswerModel[]
  }
}

export type IFetchBookmarkFailure = Action<MSAnswersActionTypes>

export const fetchBookmarkRequest = (): IFetchBookmarkRequest => ({
  type: MSAnswersActionTypes.FETCH_BOOKMARK_REQUEST
})

export const fetchBookmarkSuccess = (
  bookmarks: Array<IMSAnswerModel>
): IFetchBookmarkSuccess => ({
  type: MSAnswersActionTypes.FETCH_BOOKMARK_SUCCESS,
  payload: { bookmarks }
})

export const fetchBookmarkFailure = (): IFetchBookmarkFailure => ({
  type: MSAnswersActionTypes.FETCH_BOOKMARK_FAILURE
})

export const fetchBookmarks = (): ThunkAction<
  Promise<void>,
  Store,
  unknown,
  AnyAction
> => {
  return async (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => Store
  ) => {
    dispatch(fetchBookmarkRequest())
    try {
      const apiUrl = `${Config.APIM_BASE_URL}adminentitiesapi/getbookmarks`

      // Get and check authentication token
      const state = getState()
      const aadInfo = AuthStore.selectors.getAADInfo(state)
      const authToken = AuthStore.selectors.getAuthToken(state)
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== authToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        return
      }

      const data = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          accept: 'application/json;odata.metadata=minimal',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${esToken}`
        }
      })
      if (data.status !== 200) {
        trackException(
          'Error in fetching bookmarks',
          new Error('Error in data object after fetching bookmarks')
        )

        dispatch(fetchBookmarkFailure())
      }

      const result = await data.json()

      if (!result || !result.value) {
        dispatch(fetchBookmarkFailure())
        return
      }

      const bookmarks: IMSAnswerModel[] = result.value

      dispatch(fetchBookmarkSuccess(bookmarks))
    } catch (error) {
      dispatch(fetchBookmarkFailure())
    }
  }
}

/*** qnas */

export type IFetchQnARequest = Action<MSAnswersActionTypes>

export interface IFetchQnASuccess extends Action<MSAnswersActionTypes> {
  payload: {
    qnas: IMSAnswerModel[]
  }
}

export type IFetchQnAFailure = Action<MSAnswersActionTypes>

export const fetchQnARequest = (): IFetchQnARequest => ({
  type: MSAnswersActionTypes.FETCH_QNA_REQUEST
})

export const fetchQnASuccess = (
  qnas: Array<IMSAnswerModel>
): IFetchQnASuccess => ({
  type: MSAnswersActionTypes.FETCH_QNA_SUCCESS,
  payload: { qnas }
})

export const fetchQnAFailure = (): IFetchQnAFailure => ({
  type: MSAnswersActionTypes.FETCH_QNA_FAILURE
})

export const fetchqnas = (): ThunkAction<
  Promise<void>,
  Store,
  unknown,
  AnyAction
> => {
  return async (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => Store
  ) => {
    dispatch(fetchQnARequest())
    try {
      const apiUrl = `${Config.APIM_BASE_URL}adminentitiesapi/getqnas`

      // Get and check authentication token
      const state = getState()
      const aadInfo = AuthStore.selectors.getAADInfo(state)
      const authToken = AuthStore.selectors.getAuthToken(state)
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== authToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        return
      }

      const data = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          accept: 'application/json;odata.metadata=minimal',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${esToken}`
        }
      })
      if (data.status !== 200) {
        trackException(
          'Error in fetching qnas',
          new Error('Error in data object after fetching qnas')
        )

        dispatch(fetchQnAFailure())
      }

      const result = await data.json()

      if (!result || !result.value) {
        dispatch(fetchQnAFailure())
        return
      }

      const qnas: IMSAnswerModel[] = result.value

      dispatch(fetchQnASuccess(qnas))
    } catch (error) {
      dispatch(fetchQnAFailure())
    }
  }
}

/*** acronyms */

export type IFetchAcronymRequest = Action<MSAnswersActionTypes>

export interface IFetchAcronymSuccess extends Action<MSAnswersActionTypes> {
  payload: {
    acronyms: IMSAnswerModel[]
  }
}

export type IFetchAcronymFailure = Action<MSAnswersActionTypes>

export const fetchAcronymRequest = (): IFetchAcronymRequest => ({
  type: MSAnswersActionTypes.FETCH_ACRONYM_REQUEST
})

export const fetchAcronymSuccess = (
  acronyms: Array<IMSAnswerModel>
): IFetchAcronymSuccess => ({
  type: MSAnswersActionTypes.FETCH_ACRONYM_SUCCESS,
  payload: { acronyms }
})

export const fetchAcronymFailure = (): IFetchAcronymFailure => ({
  type: MSAnswersActionTypes.FETCH_ACRONYM_FAILURE
})

export const fetchacronyms = (): ThunkAction<
  Promise<void>,
  Store,
  unknown,
  AnyAction
> => {
  return async (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => Store
  ) => {
    dispatch(fetchAcronymRequest())
    try {
      const apiUrl = `${Config.APIM_BASE_URL}adminentitiesapi/getacronyms`

      // Get and check authentication token
      const state = getState()
      const aadInfo = AuthStore.selectors.getAADInfo(state)
      const authToken = AuthStore.selectors.getAuthToken(state)
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== authToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        return
      }

      const data = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          accept: 'application/json;odata.metadata=minimal',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${esToken}`
        }
      })
      if (data.status !== 200) {
        trackException(
          'Error in fetching acronyms',
          new Error('Error in data object after fetching acronyms')
        )

        dispatch(fetchAcronymFailure())
      }

      const result = await data.json()

      if (!result || !result.value) {
        dispatch(fetchAcronymFailure())
        return
      }

      const acronyms: IMSAnswerModel[] = result.value

      dispatch(fetchAcronymSuccess(acronyms))
    } catch (error) {
      dispatch(fetchAcronymFailure())
    }
  }
}

// use an OR type here if there more than one actions, e.g. ISetEnabledFilter |INewFilterAction | ...
export type MSAnswersActions =
  | IFetchBookmarkRequest
  | IFetchBookmarkSuccess
  | IFetchBookmarkFailure
  | IFetchQnARequest
  | IFetchQnASuccess
  | IFetchQnAFailure
  | IFetchAcronymRequest
  | IFetchAcronymSuccess
  | IFetchAcronymFailure
