import { Store } from '..'

const selectors = {
  getResults: (state: Store): any => state.resultsProjectSpaces.results,
  getResultsCombined: (state: Store): any =>
    state.resultsProjectSpaces.resultsCombined,
  getResultsCombinedQuery: (state: Store): any =>
    state.resultsProjectSpaces.resultsCombinedQuery,
  hasResultsBeenFetched: (state: Store): any =>
    state.resultsProjectSpaces.hasResultsBeenFetched,
  getResultCount: (state: Store): any => state.resultsProjectSpaces.resultCount,
  getExecutionTime: (state: Store): any =>
    state.resultsProjectSpaces.executionTime,
  getCurrentPage: (state: Store): any => state.resultsProjectSpaces.currentPage
}

export default selectors
