import React from 'react'
import { connect } from 'react-redux'
import { Box, Typography } from '@mui/material'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Link from '@mui/material/Link'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { Store } from 'store'
import SettingsStore from 'store/Settings'
import {
  trackWidgetShown,
  trackException,
  trackLeftWidgetClick
} from 'utils/tracking'
import highlightText, { prepareHighlightWords } from 'utils/highlightText'
import { removeQueryParam } from 'utils/queryParams'
import { WidgetKey } from 'constants/widgets'
import 'styles/slick_carousel/slick-theme.css'
import 'styles/slick_carousel/slick.css'
import { getStylesLeftWidget } from 'styles/contents/resultpages/widgets/LeftWidget'
import createDOMPurify from 'dompurify'
import TooltipTitle from 'components/contents/common/TooltipTitle'
import { IServiceNowChResult } from 'components/models/ServiceNowChResult'
import { getStylesLeftServiceNowChWidget } from 'styles/contents/resultpages/widgets/LeftServiceNowChWidget'
import { ISynonymsApplied } from 'components/models/SynonymsApplied'
import { CustomDescription } from 'components/contents/common/CustomDescription'
import LeftWidgetSlider from './common/LeftWidgetSlider'

export interface LeftServiceNowChWidgetProps {
  searchQuery: any
  results: IServiceNowChResult[]
  synonymsApplied: ISynonymsApplied[]
}

type AllProps = ReturnType<typeof mapStateToProps> & LeftServiceNowChWidgetProps

function LeftServiceNowChWidget(props: AllProps): JSX.Element {
  const { searchQuery, results, deviceSettings, synonymsApplied } = props

  const DOMPurify = createDOMPurify(window)

  const navigateFunction = useNavigate()
  const leftWidgetClasses = getStylesLeftWidget()
  const classes = getStylesLeftServiceNowChWidget()

  const intl = useIntl()

  React.useEffect(() => {
    trackWidgetShown({
      widgetType: WidgetKey.servicenowch,
      widgetPane: 'left'
    })
  }, [])

  let slides: JSX.Element[] = []
  try {
    slides = results.map((result: IServiceNowChResult, i) => (
      <div key={'li' + i}>
        <Box key={'lib1' + i} style={{ width: 20 }} />
        <Link
          key={'lil1' + i}
          onClick={() => {
            removeQueryParam(navigateFunction, 'cntx')
            trackLeftWidgetClick({
              title: DOMPurify.sanitize(result.title),
              url: DOMPurify.sanitize(result.link),
              widgetKey: WidgetKey.servicenowch,
              index: i
            })
          }}
          {...(!deviceSettings.isMobile
            ? {
                onAuxClick: (event: any) => {
                  removeQueryParam(navigateFunction, 'cntx')
                  trackLeftWidgetClick({
                    title: DOMPurify.sanitize(result.title),
                    url: DOMPurify.sanitize(result.link),
                    widgetKey: WidgetKey.servicenowch,
                    index: i,
                    wasRightClicked: event?.button === 2 ? true : false
                  })
                }
              }
            : {})}
          href={DOMPurify.sanitize(result.link)}
          className={leftWidgetClasses.cardLink}
          {...(deviceSettings.openLinksInNewTab === true
            ? { target: '_blank', rel: 'noreferrer' }
            : {})}
        >
          <Card key={'lic' + i} className={leftWidgetClasses.card}>
            <CardContent
              key={'licc' + i}
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <Typography
                key={'lit1' + i}
                variant="body1"
                color="primary"
                component="div"
                style={{
                  minHeight: 48
                }}
              >
                <TooltipTitle
                  title={DOMPurify.sanitize(result.title?.trim())}
                  key={'lnd1' + i}
                />
              </Typography>
              <Typography
                key={'lit2' + i}
                variant="body2"
                color="textSecondary"
                component="div"
                style={{
                  maxHeight: 160,
                  minHeight: 160,
                  maxWidth: 164,
                  overflow: 'hidden'
                }}
              >
                <CustomDescription
                  descriptionValue={
                    result.snippet
                      ? highlightText(
                          DOMPurify.sanitize(result.snippet?.trim()),
                          prepareHighlightWords(
                            DOMPurify.sanitize(searchQuery),
                            DOMPurify.sanitize(result.snippet?.trim()),
                            synonymsApplied
                          ),
                          true
                        )
                      : ''
                  }
                  maxLine="6"
                />
              </Typography>
              <Box key={'lib2' + i} className={leftWidgetClasses.spacer} />
              <Typography
                key={'lit3' + i}
                variant="body2"
                color="textSecondary"
                component="div"
                style={{ fontStyle: 'italic' }}
              >
                {DOMPurify.sanitize(result.number)}
              </Typography>
            </CardContent>
          </Card>
        </Link>
      </div>
    ))
  } catch (error) {
    trackException(
      'Error in parsing slides in LeftServiceNowChWidget.tsx',
      error
    )
  }

  if (deviceSettings.renderMobile) {
    slides.push(<Box style={{ flex: '0 0 10px' }} />)
  }

  return (
    <Box
      id={'con-widget-left-servicenowch'}
      className={leftWidgetClasses.container}
    >
      {results.length > 0 && (
        <>
          <Typography
            variant="h6"
            color="primary"
            component="p"
            className={classes.title}
            gutterBottom
          >
            {intl.formatMessage({
              id: 'service_now_ch_on',
              defaultMessage: 'Related Service Now'
            })}
          </Typography>
          <Box>
            <LeftWidgetSlider slides={slides} resultsLength={results.length} />
          </Box>
        </>
      )}
    </Box>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state)
  }
}

export default connect(mapStateToProps)(LeftServiceNowChWidget)
