import React from 'react'
import OfficeLocationWidget, {
  OfficeLocationResult
} from 'components/contents/resultpages/widgets/OfficeLocationWidget'
import { FetchWithCacheAndTracking } from 'utils/api'
import { latinize } from '../latinize'
import * as Config from 'config'
import { IAADState } from 'store/Auth/reducers'
import { renewAuthorizationToken } from 'utils/token'

/**
 * Generate Office location widget based on search query
 * @param searchQuery The search query
 * @return a promise of the generated left widget react component
 */
export async function generateOfficeLocationWidget(
  searchQuery: string,
  aadInfo: IAADState
): Promise<React.ReactElement | null> {
  try {
    if (!searchQuery || searchQuery === '' || searchQuery === '""') {
      return null
    }

    const apiUrlOfficeLocations =
      `${Config.APIM_BASE_URL}searchapi/getofficelocations?` +
      `query=${encodeURIComponent(searchQuery)}`

    // Get and check authentication token
    const esToken = await renewAuthorizationToken(
      aadInfo.accessToken,
      aadInfo.instance,
      aadInfo.accounts
    )

    const response = await FetchWithCacheAndTracking(apiUrlOfficeLocations, {
      method: 'GET',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
        Authorization: `Bearer ${esToken}`
      }
    })

    if (response && !response.hasError && response.responseJSON) {
      const officeLocationResult: OfficeLocationResult = performSearch(
        searchQuery,
        response.responseJSON
      )

      if (
        !officeLocationResult ||
        !officeLocationResult.name ||
        !officeLocationResult.offices ||
        officeLocationResult.offices.length === 0
      ) {
        return null
      }

      return (
        <OfficeLocationWidget
          searchQuery={searchQuery}
          results={transformOfficeLocation(officeLocationResult)}
        />
      )
    }

    return null
  } catch (rejectedValue) {
    return null
  }
}

export function performSearch(
  query: string,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  citiesFromKPMGCountries: any
): any {
  try {
    const searchQuery = latinize(query)
      .replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\\{\\}\\[\]\\\\/]/gi, '')
      .toLocaleLowerCase()

    if (
      citiesFromKPMGCountries.Documents &&
      citiesFromKPMGCountries.Documents.length > 0
    ) {
      const foundCountry = citiesFromKPMGCountries.Documents[0]

      if (foundCountry.cities && foundCountry.cities.length > 0) {
        const foundCity = foundCountry.cities[0]

        let isValid = false

        // validate the finding agains the current searchquery
        // if this is not done more or less any query will
        // trigger the office location widget to appear
        foundCountry.searchableCities.forEach((cityTranslation: string) => {
          const cityName: string = cityTranslation.replace(
            /[`~!@#$%^&*()_|+\-=?;:'",.<>\\{\\}\\[\]\\\\/]/gi,
            ''
          )
          if (searchQuery.includes(latinize(cityName).toLowerCase().trim()))
            isValid = true
        })

        foundCountry.searchableOffices.forEach((offices: string) => {
          const officeName: string = offices.replace(
            /[`~!@#$%^&*()_|+\-=?;:'",.<>\\{\\}\\[\]\\\\/]/gi,
            ''
          )
          if (searchQuery.includes(latinize(officeName).toLowerCase().trim()))
            isValid = true
        })

        if (!isValid) {
          return {}
        }

        const result: OfficeLocationResult = {
          name: foundCity.name,
          longitude: foundCity.longitude,
          latitude: foundCity.latitude,
          offices: foundCity.offices.map((office: any) => {
            return {
              ...office,
              country: foundCountry.name,
              cityName: foundCity.name
            }
          })
        }

        mergeSimilarOffices(result)

        return result
      }
    }

    return {}
  } catch (error) {
    return error
  }
}

export async function getCitiesFromKPMGCountries(
  searchQuery: string,
  authToken: string
): Promise<any> {
  searchQuery = searchQuery.split('"').join('').split('\\').join('').slice()
  const apiUrlTransCountry =
    `${Config.APIM_BASE_URL}translationsapi/getcountrytranslations?` +
    `city=${encodeURIComponent(latinize(searchQuery))}`

  const response = await FetchWithCacheAndTracking(apiUrlTransCountry, {
    method: 'GET',
    headers: {
      accept: 'application/json',
      'content-type': 'application/json',
      'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
      Authorization: `Bearer ${authToken}`
    }
  })

  let citiesFromKPMGCountries: any = {}
  if (response && !response.hasError && response.responseJSON) {
    citiesFromKPMGCountries = response.responseJSON
    // As distinct is not enabled in cosmos db sql api, we might have duplicate documents.
    // Filter duplicates out.
    citiesFromKPMGCountries.Documents =
      citiesFromKPMGCountries.Documents.filter(
        (arr: any, index: number, self: any) =>
          index === self.findIndex((t: any) => t.id === arr.id)
      )
  }
  return citiesFromKPMGCountries
}

function mergeSimilarOffices(result: any) {
  const mutableResult = result
  const addresses = new Set<string>()
  if (mutableResult.offices) {
    mutableResult.offices = mutableResult.offices.filter((office: any) => {
      if (office && office.streetAdd1) {
        const containsAddress = addresses.has(office.streetAdd1)
        addresses.add(office.streetAdd1)
        return !containsAddress
      } else {
        return false
      }
    })
  }
  return mutableResult
}

export function transformOfficeLocation(
  officeLocation: OfficeLocationResult
): OfficeLocationResult {
  return {
    ...officeLocation,
    latitude: officeLocation.latitude,
    longitude: officeLocation.longitude,
    offices: officeLocation.offices
      ? officeLocation.offices.map((office: any) => ({
          ...office,
          name: extractLinkTagContent(office.name)
        }))
      : []
  }
}

function extractLinkTagContent(link: string) {
  const div = document.createElement('div')
  div.innerHTML = link
  const linkChild = div.firstChild
  return linkChild && linkChild.textContent ? linkChild.textContent : ''
}
