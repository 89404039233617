import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Store } from 'store'
import SettingsStore from 'store/Settings'
import { useIntl } from 'react-intl'
import { Box, Grid } from '@mui/material'
import Link from '@mui/material/Link'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp'
import { useNavigate } from 'react-router-dom'
import { WidgetKey } from 'constants/widgets'
import { removeQueryParam } from 'utils/queryParams'
import highlightText, { prepareHighlightWords } from 'utils/highlightText'
import { trackWidgetShown, trackRightWidgetClick } from 'utils/tracking'
import { getStylesRightWidget } from 'styles/contents/resultpages/widgets/RightWidget'
import { useFilter } from 'utils/filters'
import createDOMPurify from 'dompurify'
import TooltipTitle from 'components/contents/common/TooltipTitle'
import { IServiceNowChResult } from 'components/models/ServiceNowChResult'
import { getStylesRightServiceNowChWidget } from 'styles/contents/resultpages/widgets/RightServiceNowChWidget'
import { ISynonymsApplied } from 'components/models/SynonymsApplied'
import LinesEllipsis from 'react-lines-ellipsis'
import HTMLEllipsis from 'react-lines-ellipsis/lib/html'
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'

export interface RightServiceNowChWidgetProps {
  searchQuery: any
  results: IServiceNowChResult[]
  synonymsApplied: ISynonymsApplied[]
}

type AllProps = ReturnType<typeof mapStateToProps> &
  RightServiceNowChWidgetProps

function RightServiceNowChWidget(props: AllProps): JSX.Element {
  const { searchQuery, results, deviceSettings, synonymsApplied } = props

  const DOMPurify = createDOMPurify(window)

  const navigateFunction = useNavigate()
  const rightWidgetClasses = getStylesRightWidget()
  const classes = getStylesRightServiceNowChWidget()

  const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis)
  const ResponsiveHTMLEllipsis = responsiveHOC()(HTMLEllipsis)

  const [itemsShown, setItemsShown] = useState(3)
  const [currentPage] = useFilter('page', '1')

  const showTen = () => {
    setItemsShown(10)
  }

  const showThree = () => {
    setItemsShown(3)
  }

  React.useEffect(() => {
    showThree()
  }, [currentPage])

  React.useEffect(() => {
    trackWidgetShown({
      widgetType: WidgetKey.servicenowch,
      widgetPane: 'right'
    })
  }, [])

  const intl = useIntl()

  const headline = intl.formatMessage({
    id: 'service_now_ch_on',
    defaultMessage: 'Related Service Now'
  })

  return (
    <Box
      id={'con-widget-right-service-now-ch'}
      className={
        results.length > 3
          ? rightWidgetClasses.widgetAreaExtend
          : rightWidgetClasses.widgetArea
      }
    >
      <h2 className={rightWidgetClasses.widgetHeadlineH2}>
        <ResponsiveEllipsis
          className={rightWidgetClasses.widgetHeadline}
          basedOn="words"
          text={headline}
          maxLine="2"
        />
      </h2>
      {results
        .slice(0, itemsShown)
        .map((result: IServiceNowChResult, index: number) => (
          <Box key={'rm' + index} className={rightWidgetClasses.widgetItem}>
            <Box key={'rmb1' + index} className={classes.widgetText}>
              <Link
                underline={'hover'}
                key={'rml1' + index}
                onClick={() => {
                  removeQueryParam(navigateFunction, 'cntx')
                  trackRightWidgetClick({
                    title: DOMPurify.sanitize(result.title),
                    url: DOMPurify.sanitize(result.link),
                    index,
                    widgetKey: WidgetKey.servicenowch
                  })
                }}
                {...(!deviceSettings.isMobile
                  ? {
                      onAuxClick: (event: any) => {
                        removeQueryParam(navigateFunction, 'cntx')
                        trackRightWidgetClick({
                          title: DOMPurify.sanitize(result.title),
                          url: DOMPurify.sanitize(result.link),
                          index,
                          widgetKey: WidgetKey.servicenowch,
                          wasRightClicked: event?.button === 2 ? true : false
                        })
                      }
                    }
                  : {})}
                href={DOMPurify.sanitize(result.link)}
                data-node-title={DOMPurify.sanitize(result.title)}
                data-node-index={index}
                data-node-click-type="rightWidgetClick"
                data-node-widget-key={WidgetKey.servicenowch}
                {...(deviceSettings.openLinksInNewTab === true
                  ? { target: '_blank', rel: 'noreferrer' }
                  : {})}
              >
                <TooltipTitle
                  title={DOMPurify.sanitize(result.title.trim())}
                  key={'rmd1' + index}
                  singleLine={true}
                  additionalClass={`${rightWidgetClasses.widgetTitle} ${classes.widgetTitle}`}
                />
              </Link>
              <ResponsiveHTMLEllipsis
                className={`${rightWidgetClasses.widgetDescription} ${classes.widgetDescription}`}
                basedOn="words"
                unsafeHTML={highlightText(
                  DOMPurify.sanitize(result.snippet?.trim()),
                  prepareHighlightWords(
                    DOMPurify.sanitize(searchQuery),
                    DOMPurify.sanitize(result.snippet?.trim()),
                    synonymsApplied
                  ),
                  true
                )}
                maxLine="2"
              />
              <ResponsiveEllipsis
                className={rightWidgetClasses.widgetFooterContent}
                basedOn="words"
                text={DOMPurify.sanitize(result.number?.trim())}
                maxLine="1"
              />
            </Box>
          </Box>
        ))}
      {results.length > 3 && (
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          className={rightWidgetClasses.footerGrid}
        >
          {itemsShown === 3 && (
            <Grid
              item
              id={'btn-right-widget-down-01'}
              className={rightWidgetClasses.arrowContainer}
              tabIndex={0}
              onClick={showTen}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  showTen()
                }
              }}
            >
              <KeyboardArrowDown className={rightWidgetClasses.arrowItem} />
            </Grid>
          )}
          {itemsShown === 10 && (
            <Grid
              item
              id={'btn-right-widget-up-01'}
              className={rightWidgetClasses.arrowContainerExpanded}
              tabIndex={0}
              onClick={showThree}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  showThree()
                }
              }}
            >
              <KeyboardArrowUp className={rightWidgetClasses.arrowItem} />
            </Grid>
          )}
        </Grid>
      )}
    </Box>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state)
  }
}

export default connect(mapStateToProps)(RightServiceNowChWidget)
