import React, { Dispatch } from 'react'
import { connect } from 'react-redux'
import { useIntl } from 'react-intl'
import Tour from 'reactour'
import { getStylesTour } from 'styles/Tour'
import { Button } from '@mui/material'
import { IUserSetting } from 'utils/userSettings'
import { Store } from 'store'
import UserSettingsStore from 'store/UserSettings'
import SettingsStore from 'store/Settings'
import { disableBodyScroll } from '@fluentui/react'

interface FindTourProps {
  setTourOpen: Dispatch<boolean>
  tourOpen: boolean
}

type AllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  FindTourProps

function FindTour(props: AllProps): JSX.Element {
  const {
    userSettings,
    setUserSettings,
    setTourOpen,
    tourOpen,
    setInternalBroadcast
  } = props

  const intl = useIntl()
  const tourClasses = getStylesTour()

  const tourClose = () => {
    //close
    setTourOpen(false)
  }

  const tourAfterOpen = (target: any) => {
    //scroll to top
    window.scrollTo(0, 0)

    userSettings.PlayTour = false
    setUserSettings(userSettings)

    //disable scroll if possible
    disableBodyScroll()
  }

  const tourConfig = [
    ////////////////
    //datasources
    {
      //ok
      selector: '[data-tut="reactour__tabs"]',
      content: intl.formatMessage({
        id: 'tour_tabs',
        defaultMessage: 'Select data sets to search'
      }),
      action: () => {
        setInternalBroadcast(['closeDatasourceMoreMenu'])
      }
    },
    ////////////////
    //datasource more button (+menu)
    {
      selector: '[data-tut="reactour__morebtn"]',
      highlightedSelectors: ['[data-tut="reactour__moremenu"]'],
      mutationObservables: ['[data-tut="reactour__moremenu"]'],
      content: intl.formatMessage({
        id: 'tour_more',
        defaultMessage: 'Select additional data sets to search'
      }),
      action: () => {
        setInternalBroadcast(['openDatasourceMoreMenu'])
      }
    },
    ////////////////
    //filter button
    {
      selector: '[data-tut="reactour__filter"]',
      content: intl.formatMessage({
        id: 'tour_filter',
        defaultMessage: 'Use filters to refine search results'
      }),
      action: () => {
        setInternalBroadcast([
          'closeDatasourceMoreMenu',
          'openFilterBar',
          'closeFilterMenu_orderBy'
        ])
      }
    },
    ////////////////
    //filter sorting relevance (+ menu)
    {
      selector: '[id="flt-orderBy-01"]',
      mutationObservables: ['[data-tut="reactour_filterbarsub_orderBy"]'],
      highlightedSelectors: ['[data-tut="reactour_filterbarsub_orderBy"]'],
      content: intl.formatMessage({
        id: 'tour_filter_sorting',
        defaultMessage: 'Sort search results'
      }),
      action: () => {
        setInternalBroadcast([
          'openFilterMenu_orderBy',
          'closeResultMenu_text_0'
        ])
      }
    },
    ////////////////
    //result menu
    {
      selector: '[data-tut="reactour__resultmenubtn_text_0"]',
      mutationObservables: ['[data-tut="reactour__resultmenu_text_0"]'],
      highlightedSelectors: ['[data-tut="reactour__resultmenu_text_0"]'],
      content: intl.formatMessage({
        id: 'tour_resultmenu',
        defaultMessage: 'Explore Action menu for individual results'
      }),
      action: () => {
        setInternalBroadcast([
          'closeFilterMenu_orderBy',
          'openResultMenu_text_0'
        ])
      }
    },
    ////////////////
    //settings button
    {
      selector: '[data-tut="reactour__settings"]',
      content: intl.formatMessage({
        id: 'tour_settings',
        defaultMessage:
          'Customize personal preferences, results prefiltering, data sources visibility, order'
      }),
      action: () => {
        setInternalBroadcast(['closeResultMenu_text_0'])
      }
    },
    ////////////////
    //footer options TODO
    {
      selector: '[data-tut="reactour__footer_links"]',
      content: intl.formatMessage({
        id: 'tour_footer',
        defaultMessage: 'Explore additional options in footer'
      }),
      action: () => {
        setInternalBroadcast(['closeModalContactUs'])
      }
    },
    ////////////////
    //contact modal
    {
      selector: '[data-tut="reactour__modal_contactus"]',
      mutationObservables: ['[data-tut="reactour__modal_contactus"]'],
      highlightedSelectors: ['[data-tut="reactour__modal_contactus"]'],
      content: intl.formatMessage({
        id: 'tour_contactus',
        defaultMessage: 'Use Contact menu to explore Enterprise search services'
      }),
      action: () => {
        setInternalBroadcast(['openModalContactUs'])
      }
    }
  ]

  return (
    <>
      <div
        onClick={() => {
          tourClose()
        }}
        className={tourClasses.reactourClose}
      >
        CLICK TO CLOSE
      </div>
      <Tour
        onRequestClose={tourClose}
        steps={tourConfig}
        isOpen={tourOpen}
        maskClassName={tourClasses.reactourMask}
        className={tourClasses.reactourHelper}
        rounded={10}
        accentColor="#004e98"
        disableDotsNavigation={false}
        disableKeyboardNavigation={false}
        showNavigation={true}
        showCloseButton={true}
        lastStepNextButton={
          <Button fullWidth color="primary" variant="contained">
            Close
          </Button>
        }
        showNavigationNumber={true}
        showNumber={true}
        onAfterOpen={tourAfterOpen}
      />
    </>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    userSettings: UserSettingsStore.selectors.getUserSettings(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    setUserSettings: (userSettings: IUserSetting) =>
      dispatch(UserSettingsStore.actions.upSertUserSettings(userSettings)),
    setInternalBroadcast: (broadcast: string[]) =>
      dispatch(SettingsStore.actions.setInternalBroadcast(broadcast))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FindTour)
