import React, { useState, useEffect } from 'react'
import { Box } from '@mui/material'
import Link from '@mui/material/Link'
import { useNavigate } from 'react-router-dom'
import { WidgetKey } from 'constants/widgets'
import { removeQueryParam } from 'utils/queryParams'
import highlightText, { prepareHighlightWords } from 'utils/highlightText'
import { getStylesRightKlardenkerWidget } from 'styles/contents/resultpages/widgets/RightKlardenkerWidget'
import { getStylesRightWidget } from 'styles/contents/resultpages/widgets/RightWidget'
import createDOMPurify from 'dompurify'
import TooltipTitle from 'components/contents/common/TooltipTitle'
import FilePlaceholder from 'images/file_placeholder_small.png'
import HTMLEllipsis from 'react-lines-ellipsis/lib/html'
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'
import { IKlardenkerResult } from 'components/models/KlardenkerResult'
import { IDeviceSetting } from 'utils/deviceSettings'
import { trackRightWidgetClick } from 'utils/tracking'
import { getKlardenkerImage } from 'utils/klardenker'

export interface IRightKlardenkerWidgetResultProps {
  searchQuery: any
  item: IKlardenkerResult
  index: number
  deviceSettings: IDeviceSetting
  authToken: string
}

type AllProps = IRightKlardenkerWidgetResultProps

function RightKlardenkerWidgetResult(props: AllProps): JSX.Element {
  const { searchQuery, item, index, deviceSettings, authToken } = props

  const [imageUrlFallBack, setImageUrlFallBack] = useState(item.image)

  useEffect(() => {
    // Lazy load the image result
    if (!deviceSettings.renderMobile && !imageUrlFallBack && item.id) {
      getKlardenkerImage(item.id, authToken).then(
        (lazyLoadedImageUrl: string | null) => {
          if (lazyLoadedImageUrl) {
            setImageUrlFallBack(lazyLoadedImageUrl)
          }
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const DOMPurify = createDOMPurify(window)

  const navigateFunction = useNavigate()
  const rightWidgetClasses = getStylesRightWidget()
  const classes = getStylesRightKlardenkerWidget()
  const ResponsiveHTMLEllipsis = responsiveHOC()(HTMLEllipsis)

  return (
    <Box key={'rik' + index} className={rightWidgetClasses.widgetItem}>
      <Box key={'rikb1' + index} className={classes.widgetText}>
        <Link
          underline={'hover'}
          key={'rikl' + index}
          onClick={() => {
            removeQueryParam(navigateFunction, 'cntx')
            trackRightWidgetClick({
              title: DOMPurify.sanitize(item.title),
              url: DOMPurify.sanitize(item.url),
              index,
              widgetKey: WidgetKey.klardenker
            })
          }}
          {...(!deviceSettings.isMobile
            ? {
                onAuxClick: (event: any) => {
                  removeQueryParam(navigateFunction, 'cntx')
                  trackRightWidgetClick({
                    title: DOMPurify.sanitize(item.title),
                    url: DOMPurify.sanitize(item.url),
                    index,
                    widgetKey: WidgetKey.klardenker,
                    wasRightClicked: event?.button === 2 ? true : false
                  })
                }
              }
            : {})}
          href={DOMPurify.sanitize(item.url)}
          data-node-title={DOMPurify.sanitize(item.title)}
          data-node-index={index}
          data-node-click-type="rightWidgetClick"
          data-node-widget-key={WidgetKey.klardenker}
          {...(deviceSettings.openLinksInNewTab === true
            ? { target: '_blank', rel: 'noreferrer' }
            : {})}
        >
          <TooltipTitle
            title={DOMPurify.sanitize(item.title.trim())}
            key={'rikd1' + index}
            singleLine={true}
            additionalClass={`${rightWidgetClasses.widgetTitle} ${classes.widgetTitle}`}
          />
        </Link>
        <ResponsiveHTMLEllipsis
          className={rightWidgetClasses.widgetDescription}
          basedOn="words"
          unsafeHTML={highlightText(
            DOMPurify.sanitize(item.body?.trim()),
            prepareHighlightWords(
              DOMPurify.sanitize(searchQuery),
              DOMPurify.sanitize(item.body?.trim()),
              []
            ),
            true
          )}
          maxLine="1"
        />
      </Box>
      <Box key={'rikb2' + index} className={rightWidgetClasses.spacer} />
      <div
        key={'rikd3' + index}
        className={classes.widgetImage}
        style={{
          backgroundImage:
            imageUrlFallBack != null && imageUrlFallBack.length > 0
              ? `url("${DOMPurify.sanitize(
                  imageUrlFallBack
                )}"),url("${FilePlaceholder}")`
              : `url("${FilePlaceholder}")`,
          backgroundColor: '#7787a1'
        }}
      />
    </Box>
  )
}

export default RightKlardenkerWidgetResult
