import React, { Dispatch, useMemo } from 'react'
import { Box, Fade } from '@mui/material'
import { connect } from 'react-redux'
import ReactPlaceholder from 'react-placeholder'
import { Store } from 'store'
import SettingsStore from 'store/Settings'
import ResultsOffice365Store from 'store/ResultsOffice365'
import { trackException } from 'utils/tracking'
import Pagination from 'components/contents/Pagination'
import { CalendarResult } from 'components/contents/results/CalendarResult'
import { HitsResponse, CalendarSourceResponse } from 'utils/o365'
import 'Placeholder.css'
import SearchSuggestions from 'components/contents/common/SearchSuggestions'
import O365FilterBar from './O365FilterBar'
import FeaturedResults from '../FeaturedResults'
import { useIntl } from 'react-intl'
import LeftWidgetContainer from '../widgetscontainer/LeftWidgetContainer'
import { getStylesResults } from 'styles/contents/resultpages/Results'
import { getStylesResultsO365 } from 'styles/contents/resultpages/ResultsO365'
import { useFilter } from 'utils/filters'
import createDOMPurify from 'dompurify'
import { CognitiveMessage } from 'components/contents/common/CognitiveMessage'
import { IUserSetting } from 'utils/userSettings'
import ErrorPage from 'components/contents/common/ErrorPage'

export interface ResultCalendarProps {
  datasources: any
  resultsCount: number
  searchQuery: string
  hasErrorStore: boolean
  hasLicenseBeenChecked: boolean
  hasExchangeLicense: boolean
  isPagingError: boolean
  moreResultsAvailable: boolean
  firstRender: boolean
  userSettings: IUserSetting
  findConfigCognitiveSearchEnabled: boolean
  cognitiveSearchEnabled: boolean
  showCognitiveMessage: boolean
  setShowCognitiveMessage: Dispatch<boolean>
  setUserSettings: Dispatch<IUserSetting>
}

type AllProps = ReturnType<typeof mapStateToProps> & ResultCalendarProps

function ResultCalendarMobile(props: AllProps) {
  const {
    extendedResults,
    resultsCombined,
    resultsCount,
    hasResultsBeenFetched,
    datasources,
    deviceSettings,
    hasErrorStore,
    hasLicenseBeenChecked,
    hasExchangeLicense,
    isPagingError,
    moreResultsAvailable,
    firstRender,
    userSettings,
    findConfigCognitiveSearchEnabled,
    cognitiveSearchEnabled,
    showCognitiveMessage,
    setShowCognitiveMessage,
    setUserSettings,
    searchQuery
  } = props

  const DOMPurify = createDOMPurify(window)
  const intl = useIntl()
  const resultClasses = getStylesResults()
  const o365Classes = getStylesResultsO365()
  const [currentPage] = useFilter('page', '1')

  const returnAllItems = (type: string) => {
    if (!resultsCombined || resultsCombined.length < 1) {
      return []
    }

    const items = []

    try {
      const firstResults =
        resultsCombined.length < 4 ? resultsCombined.length : 3

      if (type === 'first') {
        for (let i = 0; i < firstResults; i++) {
          const calendarItem = resultsCombined[
            i
          ] as HitsResponse<CalendarSourceResponse>
          items.push(
            <CalendarResult
              id={DOMPurify.sanitize(calendarItem.hitId)}
              endDateTime={
                calendarItem.resource.end
                  ? DOMPurify.sanitize(calendarItem.resource.end.dateTime)
                  : ''
              }
              startDateTime={
                calendarItem.resource.start
                  ? DOMPurify.sanitize(calendarItem.resource.start.dateTime)
                  : ''
              }
              subject={DOMPurify.sanitize(calendarItem.resource.subject)}
              summary={DOMPurify.sanitize(calendarItem.summary)}
              key={i}
              index={i}
              isOpen={i < 3 && Number(DOMPurify.sanitize(currentPage)) === 1}
              deviceSettings={deviceSettings}
              synonymsApplied={extendedResults.synonymsApplied}
              searchQuery={searchQuery}
            />
          )
        }
      } else {
        for (let i = firstResults; i < resultsCombined.length; i++) {
          const calendarItem = resultsCombined[
            i
          ] as HitsResponse<CalendarSourceResponse>
          items.push(
            <CalendarResult
              id={DOMPurify.sanitize(calendarItem.hitId)}
              endDateTime={
                calendarItem.resource.end
                  ? DOMPurify.sanitize(calendarItem.resource.end.dateTime)
                  : ''
              }
              startDateTime={
                calendarItem.resource.start
                  ? DOMPurify.sanitize(calendarItem.resource.start.dateTime)
                  : ''
              }
              subject={DOMPurify.sanitize(calendarItem.resource.subject)}
              summary={DOMPurify.sanitize(calendarItem.summary)}
              key={i}
              index={i}
              isOpen={i < 3 && Number(currentPage) === 1}
              deviceSettings={deviceSettings}
              synonymsApplied={extendedResults.synonymsApplied}
              searchQuery={searchQuery}
            />
          )
        }
      }
    } catch (error) {
      trackException(
        'Error returning all items in ResultsOffice365Mobile.tsx',
        error
      )
    }
    return items
  }

  const firstItems = useMemo(
    () => returnAllItems('first'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resultsCombined, extendedResults, intl]
  )
  const restItems = useMemo(
    () => returnAllItems('rest'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resultsCombined, extendedResults, intl]
  )

  const calendarError = hasLicenseBeenChecked && !hasExchangeLicense

  return (
    <Box className={resultClasses.container}>
      <Box
        id={'con-resultvertical-01'}
        className={resultClasses.resultsContainer}
      >
        {!hasErrorStore && !calendarError && !isPagingError && (
          <Box className={o365Classes.spellCheck}>
            <SearchSuggestions
              wildCardActive={true}
              resultCount={resultsCount}
              resultsFetchend={hasResultsBeenFetched}
            />
          </Box>
        )}
        {cognitiveSearchEnabled &&
          showCognitiveMessage &&
          extendedResults.synonymsApplied &&
          findConfigCognitiveSearchEnabled &&
          extendedResults.synonymsApplied.length > 0 && (
            <CognitiveMessage
              synonymsApplied={extendedResults.synonymsApplied}
              setShowCognitiveMessage={setShowCognitiveMessage}
              userSettings={userSettings}
              setUserSettings={setUserSettings}
            />
          )}
        <O365FilterBar
          {...props}
          scope={'Calendar'}
          datasources={datasources}
        />

        {!hasErrorStore && !calendarError && (
          <Box className={o365Classes.resultTableContainer}>
            <ReactPlaceholder
              ready={
                hasResultsBeenFetched ||
                Number(DOMPurify.sanitize(currentPage)) > 1
                  ? true
                  : false
              }
              type="text"
              rows={4}
              showLoadingAnimation={true}
              className={resultClasses.placeholder}
            >
              <Fade
                in={
                  hasResultsBeenFetched ||
                  Number(DOMPurify.sanitize(currentPage)) > 1
                    ? true
                    : false
                }
                timeout={600}
              >
                <Box>
                  {resultsCombined && resultsCount > 0 && !firstRender && (
                    <FeaturedResults
                      featuredResults={extendedResults.featuredResults}
                    />
                  )}
                  {firstItems}
                </Box>
              </Fade>
            </ReactPlaceholder>
            {hasResultsBeenFetched &&
            (resultsCount < 1 || resultsCombined.length < 1) ? null : (
              <LeftWidgetContainer
                resultCount={resultsCount}
                hasResultsBeenFetched={hasResultsBeenFetched}
              />
            )}
            <ReactPlaceholder
              ready={hasResultsBeenFetched || Number(currentPage) > 1}
              type="text"
              rows={4}
              showLoadingAnimation={true}
              className={resultClasses.placeholder}
            >
              <Fade
                in={hasResultsBeenFetched || Number(currentPage) > 1}
                timeout={600}
              >
                <Box>
                  {restItems}
                  {resultsCombined != null &&
                    resultsCombined.length > 0 &&
                    moreResultsAvailable && (
                      <Pagination
                        {...props}
                        totalRecords={
                          moreResultsAvailable ? resultsCount + 1 : resultsCount
                        }
                        pageLimit={20}
                        pageNeighbours={4}
                      />
                    )}
                </Box>
              </Fade>
            </ReactPlaceholder>
            <ReactPlaceholder
              ready={hasResultsBeenFetched ? hasResultsBeenFetched : false}
              type="text"
              rows={4}
              showLoadingAnimation={true}
              className={resultClasses.placeholder}
            >
              <Box />
            </ReactPlaceholder>
            <ReactPlaceholder
              ready={hasResultsBeenFetched ? hasResultsBeenFetched : false}
              type="text"
              rows={4}
              showLoadingAnimation={true}
              className={resultClasses.placeholder}
            >
              <Box />
            </ReactPlaceholder>
          </Box>
        )}

        {(hasErrorStore || calendarError || isPagingError) && (
          <ErrorPage
            dataSource="office365"
            origin={'calendar'}
            isContainerWrapped={true}
            {...(calendarError
              ? {
                  customMessage: {
                    type: 'info',
                    content: (
                      <>
                        Unfortunately you don't have a calendar in Microsoft 365
                        so far or your country does not allow searching your
                        calendar.
                      </>
                    )
                  }
                }
              : isPagingError
              ? {
                  customMessage: {
                    type: 'info',
                    content: (
                      <>
                        Unfortunately paging is currently not working. The
                        calendar endpoint from Microsoft 365 has an issue.
                      </>
                    )
                  }
                }
              : {})}
          />
        )}
      </Box>
    </Box>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    extendedResults: ResultsOffice365Store.selectors.getExtendedResults(
      state,
      'calendar'
    ),
    resultsCombined: ResultsOffice365Store.selectors.getResultsCombined(
      state,
      'calendar'
    ),
    hasResultsBeenFetched:
      ResultsOffice365Store.selectors.hasResultsBeenFetched(state, 'calendar'),
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state)
  }
}

export default connect(mapStateToProps)(ResultCalendarMobile)
