import { FeaturedResult } from 'components/models/FeaturedResult'
import { Store } from '..'
import { IResult } from './reducers'

const selectors = {
  getFeaturedResults: (state: Store): FeaturedResult[] =>
    state.resultsEngagements.featuredResults,
  getResults: (state: Store): IResult => state.resultsEngagements.results,
  getResultsCombined: (state: Store): IResult =>
    state.resultsEngagements.resultsCombined,
  getResultsCombinedQuery: (state: Store): string =>
    state.resultsEngagements.resultsCombinedQuery,
  hasResultsBeenFetched: (state: Store): boolean =>
    state.resultsEngagements.hasResultsBeenFetched,
  getTotalRowCount: (state: Store): number =>
    state.resultsEngagements.totalRowCount,
  getExecutionTime: (state: Store): number =>
    state.resultsEngagements.executionTime,
  getLastRow: (state: Store): number => state.resultsEngagements.lastRow,
  getHasError: (state: Store): boolean => state.resultsEngagements.hasError,
  getLatestQuery: (state: Store): string =>
    state.resultsEngagements.resultsCombinedQuery
}

export default selectors
