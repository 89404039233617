import { AnyAction } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { Store } from '..'
import * as Config from 'config'
import { getSPOnlineToken, renewAuthorizationToken } from 'utils/token'
import { FetchWithCache } from 'utils/api'
import Selectors from './selectors'
import ResultMetaDataStore from 'store/ResultMetaData'
import { IUserSetting } from 'utils/userSettings'
import { IDeviceSetting } from 'utils/deviceSettings'
import { IFindConfiguration } from 'store/Settings/reducers'
import {
  IOneIntranetResultQuery,
  imagesDefaultQuery,
  prepareFilters,
  removeStopWordsFromQuery
} from 'utils/oneIntranet'
import {
  ActionMetaData,
  CustomAction,
  getActionMetaData
} from 'store/extension/customAction'
import AuthStore from 'store/Auth'
import { FeaturedResult } from 'components/models/FeaturedResult'
import { KPMGFindGlobalVariables } from 'store/KPMGFindGlobalVariables'
import SettingsStore from 'store/Settings'
import { IImageResult } from 'components/models/ImageResult'
import { ISynonymsApplied } from 'components/models/SynonymsApplied'

export enum ResultsImagesActionTypes {
  FETCH_REQUEST = 'resultsImages/FETCH_REQUEST',
  FETCH_SUCCESS = 'resultsImages/FETCH_SUCCESS',
  FETCH_SUCCESS_COMBINED = 'resultsImages/FETCH_SUCCESS_COMBINED',
  FETCH_FAILURE = 'resultImages/FETCH_FAILURE',
  INITIALIZE_RESULTS_IMAGES = 'persist/REHYDRATE'
}

export interface IFetchRequest extends CustomAction<ResultsImagesActionTypes> {
  payload: {
    resetCombined: boolean
    searchQuery: string
  }
}
export type IFetchFailure = CustomAction<ResultsImagesActionTypes>
export interface IFetchSuccess extends CustomAction<ResultsImagesActionTypes> {
  payload: {
    response: {
      queryResults: IImageResult[]
      synonymsApplied: ISynonymsApplied[]
      totalRowCount: number
      lastRow: number
      hasError: boolean
      executionTime: number
    }
    featuredResults: FeaturedResult[]
    currentPage: number
  }
}
export interface IFetchSuccessCombined
  extends CustomAction<ResultsImagesActionTypes> {
  payload: {
    response: {
      queryResults: IImageResult[]
      synonymsApplied: ISynonymsApplied[]
      lastRow: number
      hasError: boolean
    }
  }
}

export const fetchRequest = (
  resetCombined: boolean,
  searchQuery: string,
  actionMetaData: ActionMetaData
): IFetchRequest => ({
  type: ResultsImagesActionTypes.FETCH_REQUEST,
  payload: {
    resetCombined: resetCombined,
    searchQuery: searchQuery
  },
  metaData: {
    ...actionMetaData,
    isStartAction: true
  }
})
export const fetchSuccess = (
  response: {
    queryResults: IImageResult[]
    synonymsApplied: ISynonymsApplied[]
    totalRowCount: number
    lastRow: number
    hasError: boolean
    executionTime: number
  },
  featuredResults: FeaturedResult[],
  currentPage: number,
  actionMetaData: ActionMetaData
): IFetchSuccess => ({
  type: ResultsImagesActionTypes.FETCH_SUCCESS,
  payload: {
    response,
    featuredResults,
    currentPage
  },
  metaData: actionMetaData
})

export const fetchSuccessCombined = (
  response: {
    queryResults: IImageResult[]
    synonymsApplied: ISynonymsApplied[]
    lastRow: number
    hasError: boolean
  },
  actionMetaData: ActionMetaData
): IFetchSuccessCombined => ({
  type: ResultsImagesActionTypes.FETCH_SUCCESS_COMBINED,
  payload: { response },
  metaData: actionMetaData
})
export const fetchFailure = (
  actionMetaData: ActionMetaData
): IFetchFailure => ({
  type: ResultsImagesActionTypes.FETCH_FAILURE,
  metaData: actionMetaData
})

export const fetchResultsImages = (
  searchQuery: string,
  currentPage: number,
  userSettings: IUserSetting,
  deviceSettings: IDeviceSetting,
  filters: {
    [key: string]: string
  },
  findConfiguration: IFindConfiguration
  // eslint-disable-next-line @typescript-eslint/ban-types
): ThunkAction<Promise<void>, Store, {}, AnyAction> => {
  return async (
    // eslint-disable-next-line @typescript-eslint/ban-types
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => Store
  ) => {
    const actionMetaData = getActionMetaData('images')

    dispatch(ResultMetaDataStore.actions.fetchRequest('images', actionMetaData))
    const resultsCombinedQuery = Selectors.getResultsCombinedQuery(getState())
    if (resultsCombinedQuery !== searchQuery) {
      if (resultsCombinedQuery === '') {
        dispatch(fetchRequest(false, searchQuery, actionMetaData))
      } else {
        dispatch(fetchRequest(true, searchQuery, actionMetaData))
      }
    } else {
      dispatch(fetchRequest(false, searchQuery, actionMetaData))
    }
    try {
      if (!searchQuery || searchQuery === '' || searchQuery === '""') {
        dispatch(fetchFailure(actionMetaData))
        return
      }

      const t0 = performance.now()

      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        throw new Error(
          'Authentication: Cannot renew Enterprise Search authentication token'
        )
      }

      let spoToken = ''
      if (Config.LOCAL_DEVELOPMENT.toString() === 'false') {
        spoToken = await getSPOnlineToken(aadInfo.instance, aadInfo.accounts)
        if (spoToken === '') {
          throw new Error(
            'Authentication: Cannot renew SPO authentication token'
          )
        }
      }

      const apiUrl = `${Config.APIM_BASE_URL}oneintranetapi/postsearchoi`

      const requestBody: IOneIntranetResultQuery = Object.assign(
        {},
        imagesDefaultQuery
      )

      const detectLanguageApiUrl = `${
        Config.APIM_BASE_URL
      }searchapi/detectlanguage?searchQuery=${encodeURIComponent(searchQuery)}`

      const useCognitiveSearch = SettingsStore.selectors.getUseCognitiveSearch(
        getState()
      )
      let searchQueryLanguage = ''
      if (useCognitiveSearch && findConfiguration.CognitiveSearchEnabled) {
        const languageResponse = await FetchWithCache(detectLanguageApiUrl, {
          method: 'POST',
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
            Authorization: `Bearer ${esToken}`
          }
        })

        if (
          languageResponse &&
          !languageResponse.hasError &&
          languageResponse.responseJSON
        ) {
          const languageJSON = languageResponse.responseJSON
          if (languageJSON && languageJSON.responseCode === 200) {
            searchQueryLanguage = languageJSON.language
          }
        }
        requestBody.cognitiveEnabled = true
      }

      requestBody.querytext = removeStopWordsFromQuery(
        searchQuery,
        searchQueryLanguage,
        useCognitiveSearch,
        findConfiguration.CognitiveSearchEnabled
      )

      const bodyObject = prepareFilters(
        requestBody,
        filters,
        (currentPage || 1) * 21 - 21,
        userSettings,
        'images',
        useCognitiveSearch
      )

      // Add default size image filter
      if (!filters || !filters['size']) {
        if (requestBody.genericOIFilter)
          requestBody.genericOIFilter.push('Size:range(35000, max)')
        else requestBody.genericOIFilter = ['Size:range(35000, max)']
      }

      // Enable Feature Result fetch only on page 1
      bodyObject.processFeaturedResults =
        (currentPage || 1) === 1 ? true : false

      const body = JSON.stringify(bodyObject)

      const hasError = Selectors.getHasError(getState())

      const response = await FetchWithCache(
        apiUrl,
        {
          method: 'POST',
          headers: {
            'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
            'content-type': 'application/json',
            Authorization: `Bearer ${spoToken}`,
            'Authorization-OnPrem': `${esToken}`
          },
          body: body
        },
        hasError,
        true,
        actionMetaData.transactionType,
        KPMGFindGlobalVariables.getCurrentTab() === 'images',
        searchQuery
      )

      let results
      if (!response || response.hasError || !response.responseJSON) {
        let hasOIError = false
        if (
          response.errorResponse &&
          (response.errorResponse.responseCode === 400 ||
            response.errorResponse.responseCode === 500) &&
          (currentPage || 1) === 1 &&
          !response.errorResponse.internalError
        ) {
          hasOIError = true
        }

        dispatch(
          ResultMetaDataStore.actions.hasError(
            'images',
            response.errorResponse,
            undefined,
            actionMetaData,
            hasOIError
          )
        )
        dispatch(fetchFailure(actionMetaData))
        return
      } else {
        results = response.responseJSON
      }

      const featuredResults =
        results && results.FeaturedResults ? results.FeaturedResults : []

      let t1 = performance.now()
      if (response.ESTimeInQueue) {
        try {
          t1 = t1 - response.ESTimeInQueue
        } catch {}
      }

      const executionTime = t1 - t0

      const r: any[] = []

      results.QueryResults.map((res: any) => {
        let encodedTitle = encodeURIComponent(res.Title.trim())
        encodedTitle = encodedTitle
          .split('%E2')
          .join('')
          .split('%A0')
          .join('')
          .split('%80')
          .join('')
          .split('%8D')
          .join('')
        if (encodedTitle !== '') {
          r.push(res)
        }
        return false
      })

      results.QueryResults = r

      const queryResults: IImageResult[] = []

      results.QueryResults.forEach((res: any) => {
        const imageResult: IImageResult = {
          Title: res.Title,
          Path: res.DefaultEncodingURL ? res.DefaultEncodingURL : res.Path,
          PictureThumbnailUrl: res.PictureThumbnailUrl,
          SiteID: res.SiteID,
          WebID: res.WebID,
          ListItemID: res.ListItemID,
          ListID: res.ListID,
          Write: res.Write,
          ModifiedBy: res.ModifiedBy
        }

        queryResults.push(imageResult)
      })

      const totalRowCount: number = results.TotalRowCount

      const lastRow = results.LastRow
      const hasNewError = results.hasError
      const synonymsApplied = results.SynonymsApplied

      const resultsCombined = Selectors.getResultsCombined(getState())
      if ((currentPage || 1) === 1) {
        dispatch(
          fetchSuccessCombined(
            {
              queryResults,
              synonymsApplied,
              lastRow,
              hasError: hasNewError
            },
            actionMetaData
          )
        )
      } else {
        dispatch(
          fetchSuccessCombined(
            {
              queryResults: [...resultsCombined.queryResults, ...queryResults],
              synonymsApplied: [
                ...resultsCombined.synonymsApplied,
                ...synonymsApplied
              ],
              lastRow,
              hasError: hasNewError
            },
            actionMetaData
          )
        )
      }

      dispatch(
        fetchSuccess(
          {
            queryResults,
            synonymsApplied,
            totalRowCount,
            lastRow,
            hasError: hasNewError,
            executionTime
          },
          featuredResults,
          currentPage || 1,
          actionMetaData
        )
      )

      dispatch(
        ResultMetaDataStore.actions.fetchSuccess(
          'images',
          {
            executionTime: executionTime,
            resultsCount: totalRowCount
          },
          actionMetaData
        )
      )
    } catch (error) {
      dispatch(
        ResultMetaDataStore.actions.hasError(
          'images',
          undefined,
          error,
          actionMetaData
        )
      )
      dispatch(fetchFailure(actionMetaData))
    }
  }
}

export const triggerImageDataSourceQuery = (
  searchQuery: string
  // eslint-disable-next-line @typescript-eslint/ban-types
): ThunkAction<Promise<void>, Store, {}, AnyAction> => {
  return async (
    // eslint-disable-next-line @typescript-eslint/ban-types
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => Store
  ) => {
    const actionMetaData = getActionMetaData('images')

    dispatch(ResultMetaDataStore.actions.fetchRequest('images', actionMetaData))
    const resultsCombinedQuery = Selectors.getResultsCombinedQuery(getState())
    if (resultsCombinedQuery !== searchQuery) {
      if (resultsCombinedQuery === '') {
        dispatch(fetchRequest(false, searchQuery, actionMetaData))
      } else {
        dispatch(fetchRequest(true, searchQuery, actionMetaData))
      }
    } else {
      dispatch(fetchRequest(false, searchQuery, actionMetaData))
    }

    return
  }
}

export type ResultsImagesActions = IFetchRequest | IFetchFailure | IFetchFailure
