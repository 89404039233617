import React from 'react'
import { Typography, Link, Box, Grid } from '@mui/material'
import { externalLinkClicked } from 'utils/tracking'
import { IDeviceSetting } from 'utils/deviceSettings'
import { getStylesTextResult } from 'styles/results/TextResult'
import TooltipTitle from '../common/TooltipTitle'
import highlightText, { prepareHighlightWords } from 'utils/highlightText'
import { IAnswer } from '../resultpages/MSAnswersResults'
import { CustomDescription } from '../common/CustomDescription'
//const pdfjsViewer: any = require('pdfjs-dist/web/pdf_viewer');

interface IMSAnswerResultProps {
  answerResult: IAnswer
  deviceSettings: IDeviceSetting
  url: string
  title: string
  body: string
  index: number
  searchQuery: string
  standsFor: string
}

export function MSAnswerResult(props: IMSAnswerResultProps): JSX.Element {
  const {
    answerResult,
    deviceSettings,
    url,
    title,
    body,
    index,
    searchQuery,
    standsFor
  } = props

  const classes = getStylesTextResult()
  const titleToShow =
    answerResult.type === 'Acronym' && standsFor ? standsFor : title

  return (
    <>
      <Grid container>
        <Grid item xs>
          <Box className={classes.result}>
            <div className={classes.urlWrapper}>
              <b className={classes.b}>{`${answerResult.type}${
                answerResult.type === 'Acronym' ? `: ${title}` : ''
              }`}</b>
            </div>

            {url ? (
              <Link
                underline={'hover'}
                className={classes.link}
                {...(deviceSettings.openLinksInNewTab === true
                  ? { target: '_blank', rel: 'noreferrer' }
                  : {})}
                href={url}
                onClick={(event: any) => {
                  externalLinkClicked({
                    title: titleToShow,
                    url,
                    index,
                    featured: true
                  })
                }}
                {...(!deviceSettings.isMobile
                  ? {
                      onAuxClick: (event: any) => {
                        externalLinkClicked({
                          title: titleToShow,
                          url,
                          index,
                          featured: true,
                          wasRightClicked: event?.button === 2 ? true : false
                        })
                      }
                    }
                  : {})}
              >
                <Typography
                  variant="h5"
                  color="primary"
                  className={classes.title}
                  component="div"
                >
                  <TooltipTitle
                    title={titleToShow ? titleToShow : ''}
                    singleLine={true}
                  />
                </Typography>
              </Link>
            ) : (
              <Typography
                variant="h5"
                color="primary"
                className={classes.title}
                component="div"
              >
                <TooltipTitle
                  title={titleToShow ? titleToShow : ''}
                  singleLine={true}
                />
              </Typography>
            )}

            <Typography
              variant="body2"
              color="secondary"
              gutterBottom
              className={`${classes.body} txt-resultdescription-01`}
              component="div"
            >
              <CustomDescription
                descriptionValue={
                  body
                    ? highlightText(
                        body.replace(/(\r\n|\n|\r)/gm, ' '),
                        prepareHighlightWords(
                          searchQuery,
                          body.replace(/(\r\n|\n|\r)/gm, ' ')
                        ),
                        true
                      )
                    : ''
                }
              />
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}
