export const WidgetKey = {
  intranet: 'intranet',
  infographics: 'infographics',
  kcip: 'kcip',
  klardenker: 'klardenker',
  kpmgWebsites: 'kpmgwebsites',
  meercat: 'meercat',
  news: 'news',
  oneDrive: 'onedrive',
  people: 'people',
  searchTop10: 'searchTop10',
  thoughtleadership: 'thoughtleadership',
  source: 'source',
  engagements: 'engagements',
  mobileOfficeLocation: 'mobileOfficeLocation',
  peopleAlsoSearched: 'peopleAlsoSearched',
  confluenceFi: 'confluencefi',
  statistics: 'statistics',
  video: 'video',
  servicenowch: 'servicenowch',
  metrics: 'metrics',
  servicenowau: 'servicenowau',
  vivaengage: 'vivaengage'
}
