import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesImproveResults(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles() {
  return makeStyles((theme: Theme) => ({
    dialogContainer: {
      height: 'auto',
      [`${theme.breakpoints.down(1024)}`]: {
        '& .MuiDialog-paper': {
          margin: 0
        },
        '& .MuiDialog-paperScrollPaper': {
          maxHeight: '100%',
          height: '100%'
        }
      }
    },
    dialogContainerTransparent: {
      height: 'auto',
      '& .MuiBackdrop-root': {
        background: 'none !important'
      },
      [`${theme.breakpoints.down(1024)}`]: {
        '& .MuiDialog-paper': {
          margin: 0
        },
        '& .MuiDialog-paperScrollPaper': {
          maxHeight: '100%',
          height: '100%'
        }
      }
    },
    contact: {
      flex: 1,
      color: '#424242',
      fontSize: '16px',
      paddingTop: '10px !important',
      paddingBottom: '8px !important'
    },
    dialogActions: {
      background: '#f6f7fA',
      padding: '16px 29px',
      justifyContent: 'space-between',
      '& > span': {
        color: '#424242',
        fontSize: '14px'
      }
    },
    dialogContent: {
      background: '#f6f7fA',
      height: '600px',
      display: 'flex',
      flexDirection: 'column',
      padding: '8px 28px !important',
      maxWidth: '600px',
      '& span': {
        color: '#424242',
        fontSize: '16px'
      },
      [`${theme.breakpoints.down(1024)}`]: {
        padding: '8px 8px'
      }
    },
    dialogContentGrid: {
      marginTop: '0 !important',
      width: 'calc(100% + 6px) !important',
      marginLeft: '-6px !important',
      [`${theme.breakpoints.down(1024)}`]: {
        width: '100%',
        marginLeft: 0
      }
    },
    dialogTitle: {
      background: '#005eb8',
      color: '#ffffff',
      textAlign: 'center',
      fontSize: '20px'
    },
    icon: {
      marginRight: 20
    },
    itemImage: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: 8,
      marginBottom: 8
    },
    closeButton: {
      border: '1px solid #005eb8',
      background: 'transparent'
    },
    docType: {
      cursor: 'pointer',
      border: '1px solid #ebebeb',
      borderRadius: '2px',
      color: '#3c4043',
      display: 'inline-block',
      fontSize: '10px',
      fontWeight: 'normal',
      height: '14px',
      letterSpacing: '0.75px',
      lineHeight: '14px',
      padding: '0 3px',
      textAlign: 'center',
      verticalAlign: 'middle',
      textTransform: 'uppercase',
      marginLeft: '4px',
      marginBlockStart: '-2px',
      marginBlockEnd: '0px'
    },
    sectionStarter: {
      marginTop: '6px !important'
    },
    gridItem: {
      paddingTop: '7px !important',
      paddingBottom: '8px !important',
      paddingLeft: '12px !important',
      paddingRight: '12px !important'
    },
    gridDescription: {
      padding: '12px !important'
    },
    contactUs: {
      color: '#005eb8 !important',
      textDecoration: 'none',
      cursor: 'pointer'
    }
  }))
}
