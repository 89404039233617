import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesFeedbackLong(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles() {
  return makeStyles((theme: Theme) => ({
    description: {
      color: '#424242',
      textAlign: 'center',
      fontSize: '16px'
    },
    ratingContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      height: 60,
      paddingBottom: 6
    },
    feedbackActions: {
      color: '#ffffff',
      background: '#f6f7fA',
      padding: '40px 24px 24px',
      justifyContent: 'space-between'
    },
    thumbButton: {
      height: 100,
      width: 100
    },
    thumbIcon: {
      height: 50,
      width: 50
    },
    textField: {
      width: '100%'
    },
    ratingStar: {
      color: '#e36877'
    },
    dialogTitle: {
      color: '#ffffff',
      background: '#004e98',
      textAlign: 'center',
      verticalAlign: 'center'
    },
    titleText: {
      color: '#ffffff',
      textAlign: 'center'
    },
    dialogContent: {
      background: '#f6f7fA',
      paddingTop: '18px !important'
    },
    dialogSubmitText: {
      display: 'flex',
      color: 'lightgrey',
      fontSize: '12px',
      [`${theme.breakpoints.down(1024)}`]: {
        margin: 'auto 1em auto auto',
        fontSize: 11
      }
    },
    dialogCancelButton: {
      marginRight: 10
    },
    dialogActions: {
      flexShrink: 0,
      [`${theme.breakpoints.down(1024)}`]: {
        display: 'flex',
        justifyContent: 'flex-end',
        flex: 1,
        marginLeft: '0px !important'
      },
      '&> button:first-child': {
        marginRight: '8px'
      }
    },
    dialogSubmitTextBreak: {
      display: 'none'
    }
  }))
}
