import {
  dataSourcesCountryOrder,
  IDataSourceOrderCountry
} from './datasourcesOrderCountry'
import {
  dataSourcesDepartmentOrder,
  IDataSourceOrderDepartment
} from './datasourcesOrderDepartment'
import {
  dataSourcesFunctionOrder,
  IDataSourceOrderFunction
} from './datasourcesOrderFunction'

export interface IDataDataSourceDefinition {
  name: string
  disabled?: boolean //disable datasource, can be enabled via settingsmenu
  hidden?: boolean //hide datasource, cannot be enabled via settingsmenu
}

export interface IDataSourceOrderBaseObject {
  sources: Array<IDataDataSourceDefinition>
}

export interface IDateSourceOrderConfiguration {
  default: Array<IDataDataSourceDefinition>
  byCountry: Array<IDataSourceOrderCountry>
  byFunction: Array<IDataSourceOrderFunction>
  byDepartment: Array<IDataSourceOrderDepartment>
}

export const dataSourceOrderConfiguration: IDateSourceOrderConfiguration = {
  default: [
    { name: 'Intranet' },
    { name: 'Engagements' },
    { name: 'People' },
    { name: 'Office365' },
    { name: 'Source' },
    { name: 'News' },
    { name: 'Statistics' },
    { name: 'Videos' },
    { name: 'Research' },
    { name: 'Alex' },
    { name: 'kpmg.com' },
    { name: 'ContentFinder' },
    { name: 'KPMG MPP' },
    { name: 'MarketResearch' },
    { name: 'IBFD' },
    { name: 'Images' },
    { name: 'Chat' }
  ],
  byCountry: dataSourcesCountryOrder,
  byFunction: dataSourcesFunctionOrder,
  byDepartment: dataSourcesDepartmentOrder
}
