import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesFilterMenuStyledMenu(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

export function getStyles(): any {
  return makeStyles((theme: Theme) => ({
    paper: {
      boxShadow:
        '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
      width: '220px',
      maxWidth: 'none',
      maxHeight: 'none',
      [`${theme.breakpoints.down(1024)}`]: {
        marginTop: '3vw',
        width: '220px'
      },
      '& .MuiMenu-list': {
        width: '100%',
        paddingRight: '8px'
      },
      '& .MuiList-padding': {
        paddingTop: '0px',
        paddingBottom: '0px'
      }
    },
    list: {
      width: 'auto !important',
      paddingRight: '0 !important'
    }
  }))
}
