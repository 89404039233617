import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesRightMetricsWidget(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles(): any {
  return makeStyles((theme: Theme) => ({
    widgetItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '4px 8px',
      '&:hover': {
        backgroundColor: 'lightgray'
      },
      cursor: 'pointer'
    },
    widgetItemIcon: {
      marginTop: '2px'
    },
    widgetTitle: {
      lineHeight: '20px',
      fontSize: '14px',
      color: '#000',
      minWidth: '210px',
      marginLeft: '5px'
    },
    row: {
      height: '35px'
    },
    titleCell: {
      border: 0,
      padding: '5px 8px',
      width: '30%',
      fontSize: '18px'
    },
    contentCell: {
      border: 0,
      padding: 0
    },
    metricSeriesContainer: {
      width: '100%',
      height: '180px',
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: '#dfe1e5',
      marginBottom: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    searchMetricContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      justifyContent: 'space-around',
      alignItems: 'center',
      fontSize: '14px',
      margin: '0 1em 2em 1em'
    },
    searchMetricItem: {
      textAlign: 'center',
      marginTop: '5px'
    },
    chartLine: {
      '@media (forced-colors: active)': {
        forcedColorAdjust: 'none',
        stroke: 'CanvasText'
      }
    },
    chartLabel: {
      '@media (forced-colors: active)': {
        forcedColorAdjust: 'none',
        fill: 'CanvasText !important'
      }
    }
  }))
}
