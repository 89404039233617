import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesRightSourceWidget(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles(): any {
  return makeStyles((theme: Theme) => ({
    widgetTitle: {
      lineHeight: '20px'
    },
    widgetDescription: {
      lineHeight: '20px',
      maxHeight: '40px'
    }
  }))
}
