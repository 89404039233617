import React, { useState, Dispatch, useEffect } from 'react'
import { connect } from 'react-redux'
import { Store } from 'store'
import { FormattedMessage } from 'react-intl'
import createDOMPurify from 'dompurify'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import NotesIcon from '@mui/icons-material/Notes'
import TranslationsStore from 'store/Translations'
import UserSettingsStore from 'store/UserSettings'
import 'Placeholder.css'
import { getStylesFaq } from 'styles/contents/Faq'

export interface FaqProps {
  open: boolean
  onClose: () => void
}

type AllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  FaqProps

function Faq(props: AllProps) {
  const classes = getStylesFaq()

  const DOMPurify = createDOMPurify(window)

  const [faqItems, setFaqItems] = useState([])
  const [faqLanguage, setFaqLanguage] = useState('')

  const { open, onClose, getFaqTranslations, faqTranslations, userSettings } =
    props

  useEffect(() => {
    if (
      !faqTranslations ||
      faqTranslations.length === 0 ||
      (userSettings.Language || 'en') !== faqLanguage
    ) {
      getFaqTranslations(userSettings.Language || 'en')
      setFaqLanguage(userSettings.Language || 'en')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  useEffect(() => {
    if (faqTranslations && faqTranslations.length > 0) {
      setFaqItems(faqTranslations)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [faqTranslations])

  const [expandedIndex, setExpandedIndex] = useState(0)

  return (
    <Dialog open={open} onClose={onClose} className={classes.dialogContainer}>
      <DialogTitle className={classes.dialogTitle}>FAQ</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {faqItems &&
          faqItems.map((item: any, index: number) => (
            <Accordion
              key={index}
              expanded={expandedIndex === index}
              // eslint-disable-next-line @typescript-eslint/ban-types
              onChange={(event: React.ChangeEvent<{}>, isExpanded: boolean) => {
                setExpandedIndex(isExpanded ? index : -1)
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                {item.image != null && (
                  <img
                    className={classes.image}
                    src={DOMPurify.sanitize(item.image)}
                    alt="Summary"
                  />
                )}
                {item.image == null && <NotesIcon className={classes.image} />}
                <Typography className={classes.title}>
                  {DOMPurify.sanitize(item.title)}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  className={classes.description}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(item.description, {
                      ADD_ATTR: ['target']
                    })
                  }}
                />
              </AccordionDetails>
            </Accordion>
          ))}
        <Box className={classes.spacer} />
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          onClick={onClose}
          color="primary"
          className={classes.closeButton}
        >
          <FormattedMessage id="ok" defaultMessage="Ok" />
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    faqTranslations: TranslationsStore.selectors.getFaqTranslations(state),
    userSettings: UserSettingsStore.selectors.getUserSettings(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    getFaqTranslations: (language: string) =>
      dispatch(TranslationsStore.actions.fetchFaqTranslations(language))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Faq)
