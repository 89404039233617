import { IKpmgWebsitesResult } from 'components/models/KpmgWebsiteResult'
import { Store } from '..'
import { FeaturedResult } from 'components/models/FeaturedResult'

const selectors = {
  getResults: (state: Store): IKpmgWebsitesResult[] => state.resultsAEM.results,
  getResultsCombined: (state: Store): IKpmgWebsitesResult[] =>
    state.resultsAEM.resultsCombined,
  getResultsCombinedQuery: (state: Store): string =>
    state.resultsAEM.resultsCombinedQuery,
  hasResultsBeenFetched: (state: Store): boolean =>
    state.resultsAEM.hasResultsBeenFetched,
  getResultCount: (state: Store): number => state.resultsAEM.resultCount,
  getExecutionTime: (state: Store): number => state.resultsAEM.executionTime,
  getCurrentPage: (state: Store): number => state.resultsAEM.currentPage,
  getFeaturedResults: (state: Store): FeaturedResult[] =>
    state.resultsAEM.featuredResults,
  getHasError: (state: Store): boolean => state.resultsAEM.hasError,
  getLatestQuery: (state: Store): string =>
    state.resultsAEM.resultsCombinedQuery
}

export default selectors
