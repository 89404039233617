import React, { useCallback, useEffect, useMemo } from 'react'
import { Box, Fade } from '@mui/material'
import { useIntl } from 'react-intl'
import ReactPlaceholder from 'react-placeholder'
import { connect } from 'react-redux'
import 'Placeholder.css'
import { Store } from 'store'
import UserSettingsStore from 'store/UserSettings'
import SettingsStore from 'store/Settings'
import ResultMetaDataStore from 'store/ResultMetaData'
import ResultsStatisticsStore from 'store/ResultsStatistics'
import PageStay from 'components/HOC/PageStay'
import ErrorPage from 'components/contents/common/ErrorPage'
import { StatsResult } from 'components/contents/results/StatsResult'
import ResultsStatisticsMobile from './ResultsStatisticsMobile'
import Pagination from '../Pagination'
import { trackException } from 'utils/tracking'
import { useReactRouterQueryStringInterface } from 'utils/useQueryState'
import { authorizeResultPage } from 'utils/authorization'
import createDOMPurify from 'dompurify'
import SearchSuggestions from 'components/contents/common/SearchSuggestions'
import FeaturedResults from './FeaturedResults'
import { dataSourcesTabs, dateFormatOptions } from 'constants/constants'
import RightTopWidgetContainer from './widgetscontainer/RightTopWidgetContainer'
import LeftWidgetContainer from './widgetscontainer/LeftWidgetContainer'
import RightWidgetContainer from './widgetscontainer/RightWidgetContainer'
import { getStylesResults } from 'styles/contents/resultpages/Results'
import { useFilter } from 'utils/filters'
import { KPMGFindGlobalVariables } from 'store/KPMGFindGlobalVariables'
import { formatDisplayBody } from 'utils/intranet'
import { useLocation } from 'react-router-dom'

export interface ResultsStatisticsProps {
  appliedFilters: any
}

type AllProps = ReturnType<typeof mapStateToProps> & ResultsStatisticsProps

function ResultsStatistics(props: AllProps) {
  const {
    featuredResults,
    results,
    resultsCount,
    hasResultsBeenFetched,
    hasErrorStore,
    userSettings,
    deviceSettings
  } = props
  const DOMPurify = createDOMPurify(window)
  const resultClasses = getStylesResults()
  const { getQueryString } = useReactRouterQueryStringInterface()
  const intl = useIntl()
  const queryStringFromUrl = getQueryString()
  const [currentPage] = useFilter('page', '1')
  const [searchQuery] = useFilter('q')
  const location = useLocation()

  const [premium, setPremium] = useFilter('premium', '')
  useEffect(() => {
    KPMGFindGlobalVariables.setCurrentTab(dataSourcesTabs.statistics)

    if (!premium) {
      setPremium(userSettings.PremiumEnabled ? '1' : '0')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setup = useCallback(() => {
    try {
      authorizeResultPage(
        'Statistics',
        userSettings,
        deviceSettings,
        DOMPurify.sanitize(location && location.search ? location.search : '')
      )
    } catch (error) {
      trackException(
        'Error in fetching results in ResultsStatistics.tsx',
        error
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryStringFromUrl])

  useEffect(() => {
    setup()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryStringFromUrl])

  const returnAllItems = (type: string) => {
    if (!results || results.length < 1) {
      return []
    }

    const items = []

    try {
      const firstResults = results.length < 4 ? results.length : 3
      if (type === 'first') {
        for (let i = 0; i < firstResults; i++) {
          const currentDate =
            results[i].date && results[i].date !== ''
              ? intl.formatDate(
                  DOMPurify.sanitize(results[i].date),
                  dateFormatOptions
                )
              : ''

          const currentBody = DOMPurify.sanitize(results[i].body) || ''
          const displayBody = formatDisplayBody(currentDate, currentBody, intl)
          items.push(
            <StatsResult
              image={
                results[i].image ? DOMPurify.sanitize(results[i].image) : ''
              }
              title={DOMPurify.sanitize(results[i].title)}
              body={displayBody}
              url={DOMPurify.sanitize(results[i].link)}
              sources={DOMPurify.sanitize(
                results[i].sources &&
                  results[i].sources.length > 0 &&
                  results[i].sources[0].title
                  ? results[i].sources[0].title
                  : ''
              )}
              premium={DOMPurify.sanitize(results[i].premium)}
              key={i}
              index={i}
              searchQuery={DOMPurify.sanitize(searchQuery)}
              deviceSettings={deviceSettings}
            />
          )
        }
      } else {
        for (let i = firstResults; i < results.length; i++) {
          const currentDate =
            results[i].date && results[i].date !== ''
              ? intl.formatDate(
                  DOMPurify.sanitize(results[i].date),
                  dateFormatOptions
                )
              : ''

          const currentBody = DOMPurify.sanitize(results[i].body) || ''
          const displayBody = formatDisplayBody(currentDate, currentBody, intl)
          items.push(
            <StatsResult
              image={
                results[i].image ? DOMPurify.sanitize(results[i].image) : ''
              }
              title={DOMPurify.sanitize(results[i].title)}
              body={displayBody}
              url={DOMPurify.sanitize(results[i].link)}
              sources={DOMPurify.sanitize(
                results[i].sources &&
                  results[i].sources.length > 0 &&
                  results[i].sources[0].title
                  ? results[i].sources[0].title
                  : ''
              )}
              premium={DOMPurify.sanitize(results[i].premium)}
              key={i}
              index={i}
              searchQuery={DOMPurify.sanitize(searchQuery)}
              deviceSettings={deviceSettings}
            />
          )
        }
      }
    } catch (error) {
      trackException(
        'Error returning all items in ResultsStatistics.tsx',
        error
      )
    }
    return items
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const firstItems = useMemo(() => returnAllItems('first'), [results, intl])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const restItems = useMemo(() => returnAllItems('rest'), [results, intl])

  return (
    <Box>
      {!hasErrorStore && !!searchQuery && (
        <>
          {deviceSettings.renderMobile ? (
            <ResultsStatisticsMobile
              searchQuery={searchQuery}
              deviceSettings={deviceSettings}
            />
          ) : (
            <Box className={resultClasses.container}>
              <Box
                id={'con-resultvertical-01'}
                className={resultClasses.resultsContainer}
              >
                <SearchSuggestions
                  wildCardActive={false}
                  resultCount={resultsCount}
                  resultsFetchend={hasResultsBeenFetched}
                />
                <ReactPlaceholder
                  ready={hasResultsBeenFetched ? hasResultsBeenFetched : false}
                  type="text"
                  rows={4}
                  showLoadingAnimation={true}
                  className={resultClasses.placeholder}
                >
                  <Fade
                    in={hasResultsBeenFetched ? hasResultsBeenFetched : false}
                    timeout={600}
                  >
                    <Box id={'con-resultvertical-section-01'}>
                      {results && resultsCount > 0 && (
                        <FeaturedResults featuredResults={featuredResults} />
                      )}
                      {firstItems}
                    </Box>
                  </Fade>
                </ReactPlaceholder>
                {Number(currentPage) === 1 && (
                  <LeftWidgetContainer
                    resultCount={resultsCount}
                    hasResultsBeenFetched={hasResultsBeenFetched}
                  />
                )}
                <ReactPlaceholder
                  ready={hasResultsBeenFetched}
                  type="text"
                  rows={4}
                  showLoadingAnimation={true}
                  className={resultClasses.placeholder}
                >
                  <Fade in={hasResultsBeenFetched} timeout={600}>
                    <Box id={'con-resultvertical-section-02'}>
                      {restItems}
                      {resultsCount != null && resultsCount > 0 && (
                        <Pagination
                          {...props}
                          totalRecords={resultsCount}
                          pageLimit={20}
                          pageNeighbours={4}
                        />
                      )}
                    </Box>
                  </Fade>
                </ReactPlaceholder>
                <ReactPlaceholder
                  ready={hasResultsBeenFetched ? hasResultsBeenFetched : false}
                  type="text"
                  rows={4}
                  showLoadingAnimation={true}
                  className={resultClasses.placeholder}
                >
                  <Box />
                </ReactPlaceholder>
                <ReactPlaceholder
                  ready={hasResultsBeenFetched ? hasResultsBeenFetched : false}
                  type="text"
                  rows={4}
                  showLoadingAnimation={true}
                  className={resultClasses.placeholder}
                >
                  <Box />
                </ReactPlaceholder>
              </Box>
              <Box className={resultClasses.advertisementContainer}>
                <RightTopWidgetContainer
                  searchQuery={searchQuery}
                  scope={'statistics'}
                />
                <RightWidgetContainer />
              </Box>
            </Box>
          )}
        </>
      )}
      {hasErrorStore && <ErrorPage dataSource="statistics" />}
    </Box>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    featuredResults: ResultsStatisticsStore.selectors.getFeaturedResults(state),
    results: ResultsStatisticsStore.selectors.getResults(state),
    resultsCount: ResultsStatisticsStore.selectors.getResultCount(state),
    executionTime: ResultsStatisticsStore.selectors.getExecutionTime(state),
    hasResultsBeenFetched:
      ResultsStatisticsStore.selectors.hasResultsBeenFetched(state),
    hasErrorStore: ResultMetaDataStore.selectors.getDataSourceHasError(
      state,
      'statistics'
    ),
    userSettings: UserSettingsStore.selectors.getUserSettings(state),
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state)
  }
}

export default PageStay(connect(mapStateToProps)(ResultsStatistics))
