import { ContactUsTile } from 'components/models/ContactUsTile'
import { Store } from '..'

const selectors = {
  getContactUsTiles: (state: Store): ContactUsTile[] =>
    state.contactUs.contactUsTiles,
  hasBeenFetched: (state: Store): boolean => state.contactUs.hasBeenFetched,
  hasError: (state: Store): boolean => state.contactUs.hasError
}

export default selectors
