import { getGraphAuthToken, renewAuthorizationToken } from './token'
import * as Config from 'config'
import { IDeviceSetting } from './deviceSettings'
import { IAADState } from 'store/Auth/reducers'

export enum ChatType {
  ONEONONE = 'oneOnOne',
  MEETING = 'meeting',
  GROUP = 'group',
  UNKOWNFUTUREVALue = 'unknownFutureValue'
}

interface IInformation {
  title: string
  url: string
  description: string
}

interface IChatInformation {
  topic: string
  url: string
  chatType: ChatType
}

export interface IChatInformations {
  team: IInformation | null
  channel: IInformation | null
  chat: IChatInformation | null
  isValid: boolean
  fetched: boolean
}

export async function getChannelInformations(
  chatId: string,
  channelId: string,
  teamId: string,
  aadInfo: IAADState,
  deviceSettings: IDeviceSetting
): Promise<IChatInformations> {
  try {
    let graphToken = ''
    // Get and check authentication token
    if (Config.LOCAL_DEVELOPMENT.toString() === 'false') {
      graphToken = await getGraphAuthToken(aadInfo.instance, aadInfo.accounts)
    }

    // Get and check authentication token
    const esToken = await renewAuthorizationToken(
      aadInfo.accessToken,
      aadInfo.instance,
      aadInfo.accounts
    )

    let graphAPIUrl = `${Config.APIM_BASE_URL}msgraphapi/getchannelinformations?chatId=${chatId}`

    if (channelId && teamId) {
      graphAPIUrl += `&channelId=${channelId}&teamId=${teamId}`
    }

    const channelInformationResponse = await fetch(graphAPIUrl, {
      method: 'GET',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
        Authorization: `Bearer ${graphToken}`,
        JWTToken: esToken
      }
    })

    if (!channelInformationResponse || !channelInformationResponse.ok) {
      return {
        team: null,
        channel: null,
        chat: null,
        isValid: false,
        fetched: true
      }
    } else {
      const responseJson = await channelInformationResponse.json()
      return {
        team: responseJson.team,
        channel: responseJson.channel,
        chat: responseJson.chat,
        isValid:
          (responseJson.channel && responseJson.channel.url) ||
          (responseJson.chat && responseJson.chat.url),
        fetched: true
      }
    }
  } catch (error) {
    return {
      team: null,
      channel: null,
      chat: null,
      isValid: false,
      fetched: true
    }
  }
}
