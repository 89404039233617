import { IKpmgUSResult } from 'components/models/KpmgUSResult'
import { Store } from '..'
import { FeaturedResult } from 'components/models/FeaturedResult'
import { ISynonymsApplied } from 'components/models/SynonymsApplied'

const selectors = {
  getFeaturedResults: (state: Store): FeaturedResult[] =>
    state.resultsKpmgUS.featuredResults,
  getSynonymsApplied: (state: Store): ISynonymsApplied[] =>
    state.resultsKpmgUS.synonymsApplied,
  getResults: (state: Store): IKpmgUSResult[] => state.resultsKpmgUS.results,
  getResultsCombined: (state: Store): IKpmgUSResult[] =>
    state.resultsKpmgUS.resultsCombined,
  getResultsCombinedQuery: (state: Store): string =>
    state.resultsKpmgUS.resultsCombinedQuery,
  hasResultsBeenFetched: (state: Store): boolean =>
    state.resultsKpmgUS.hasResultsBeenFetched,
  getResultCount: (state: Store): number => state.resultsKpmgUS.resultCount,
  getExecutionTime: (state: Store): number => state.resultsKpmgUS.executionTime,
  getFilters: (state: Store): any => state.resultsKpmgUS.filters
}

export default selectors
