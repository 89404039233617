import { makeStyles } from '@mui/styles'
export function getStylesTour(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles() {
  return makeStyles((theme) => ({
    reactourMask: {
      opacity: '0.7'
    },

    reactourClose: {
      position: 'fixed',
      zIndex: 999999,
      color: '#fff',
      background: 'rgba(0, 0, 0, 0.46)',
      padding: '10px 20px',
      fontSize: '22px',
      borderRadius: '100px',
      top: '50%',
      marginLeft: 'auto',
      marginRight: 'auto',
      left: 0,
      right: 0,
      textAlign: 'center',
      width: '200px'
    },

    reactourHelper: {
      '& .reactour__close': {
        width: '22px !important',
        height: '22px !important',

        top: '6px !important',
        right: '6px !important',

        padding: '6px 6px !important'
      },
      background: 'rgba(0, 0, 0, 0.46)',
      color: 'white',
      '& [data-tour-elem="left-arrow"] svg': {
        color: '#646464'
      },
      '& [data-tour-elem="right-arrow"] svg': {
        color: '#646464'
      },

      /*'& [data-tour-elem="left-arrow"]:not([disabled]):hover svg': {
        color: '#fff !important'
      },
      '& [data-tour-elem="right-arrow"]:not([disabled]):hover svg': {
        color: '#fff !important'
      },*/

      '& [data-tour-elem="left-arrow"][disabled] svg': {
        color: '#caccce !important'
      },
      '& [data-tour-elem="right-arrow"][disabled] svg': {
        color: '#caccce !important'
      }
    }
  }))
}
