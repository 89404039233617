import React from 'react'
import { Box, Button, IconButton, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { getStylesPopups } from 'styles/contents/Popups'
import { PopupResult } from 'components/models/PopupResult'
import createDOMPurify from 'dompurify'
import { IDeviceSetting } from 'utils/deviceSettings'

export interface IPopupProps {
  popupResult?: PopupResult
  open: boolean
  onClose: () => void
  deviceSettings: IDeviceSetting
}

const Popup = (props: IPopupProps): any => {
  const { popupResult, open, onClose, deviceSettings } = props
  const DOMPurify = createDOMPurify(window)
  const classes = getStylesPopups()

  return !open || !popupResult ? null : (
    <div className={classes.container}>
      <Box className={classes.slideContainer}>
        <div
          className={classes.img}
          style={
            popupResult && popupResult.image
              ? {
                  backgroundImage: `url(${DOMPurify.sanitize(
                    popupResult.image,
                    {
                      USE_PROFILES: { html: false }
                    }
                  )})`,
                  backgroundPosition: 'center',
                  minHeight: '284px'
                }
              : {}
          }
        >
          <IconButton className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <Box
          className={classes.content}
          style={
            popupResult && popupResult.image
              ? {
                  maxHeight: '350px'
                }
              : { maxHeight: '634px', paddingTop: 0 }
          }
        >
          <Typography variant="h5" className={classes.title}>
            {popupResult
              ? DOMPurify.sanitize(popupResult.title, {
                  USE_PROFILES: { html: false }
                })
              : ''}
          </Typography>
          <Box
            className={classes.contentContainer}
            dangerouslySetInnerHTML={{
              __html:
                popupResult && popupResult.content
                  ? deviceSettings.openLinksInNewTab
                    ? DOMPurify.sanitize(popupResult.content, {
                        USE_PROFILES: { html: false }
                      }).replaceAll('<a ', '<a target="_blank" ')
                    : DOMPurify.sanitize(popupResult.content, {
                        USE_PROFILES: { html: true },
                        ADD_ATTR: ['target']
                      })
                  : ''
            }}
          />

          <Box className={classes.getStartedButtonContainer}>
            <Button
              id={'btn-popup-01'}
              variant="contained"
              size="large"
              color="primary"
              className={classes.getStartedButton}
              onClick={() => {
                if (popupResult && popupResult.button_link)
                  window.open(
                    DOMPurify.sanitize(popupResult.button_link, {
                      USE_PROFILES: { html: false }
                    }),
                    '_blank'
                  )

                onClose()
              }}
            >
              {popupResult
                ? DOMPurify.sanitize(popupResult.button_text, {
                    USE_PROFILES: { html: false }
                  })
                : ''}
            </Button>
          </Box>
        </Box>
      </Box>
    </div>
  )
}

export default Popup
