import React from 'react'
import { connect } from 'react-redux'
import { Store } from 'store'
import MSAnswerStore from 'store/MSAnswers'
import 'Placeholder.css'
import { useFilter } from 'utils/filters'
import createDOMPurify from 'dompurify'
import { IMSAnswerModel } from 'store/MSAnswers/reducers'
import SettingsStore from 'store/Settings'
import { MSAnswerResult } from '../results/MSAnswerResult'

export interface MSAnswersResultsProps {
  hasFeaturedResults: boolean
}

export interface IAnswer {
  type: string
  data: IMSAnswerModel
}

type AllProps = ReturnType<typeof mapStateToProps> & MSAnswersResultsProps

function MSAnswersResults(props: AllProps) {
  const { bookmarks, qnas, acronyms, deviceSettings } = props

  const DOMPurify = createDOMPurify(window)

  const [currentPage] = useFilter('page', '1')
  const [searchQuery] = useFilter('q')

  function matchContains(value: string, searchValue: string): boolean {
    let includeResult = false
    try {
      const pattern = '\\b' + value + '\\w?\\b'
      const regex = new RegExp(pattern, 'g')
      const matches = searchValue.match(regex)
      if (matches && matches.length > 0) {
        includeResult = true
      } else if (searchValue.length >= 3 && searchValue.indexOf(value) >= 0) {
        includeResult = true
      }

      const pattern2 = '\\b' + searchValue + '\\w?\\b'
      const regex2 = new RegExp(pattern2, 'g')
      const matches2 = value.match(regex2)
      if (matches2 && matches2.length > 0) {
        includeResult = true
      } else if (value.length >= 3 && value.indexOf(searchValue) >= 0) {
        includeResult = true
      }
    } catch {}
    return includeResult
  }

  function filterByKeyword(
    keywordsToCheck: Array<string>,
    searchValue: string
  ): boolean {
    let _includeResult = false
    try {
      searchValue = searchValue.toLowerCase()

      // Staging has fields ending with 0, production without 0)

      keywordsToCheck.some((keyWord) => {
        if (
          keyWord &&
          searchValue.length > 4 &&
          matchContains(keyWord.toLowerCase(), searchValue) === true
        ) {
          _includeResult = true
          return true
        }

        return false
      })
    } catch {}

    return _includeResult
  }

  const getItemsBySearchQuery = (
    items: IMSAnswerModel[],
    searchValue: string
  ): IMSAnswerModel[] => {
    const results = items.filter((item) => {
      if (item.state !== 'published') {
        return false
      }

      if (item.keywords && item.keywords.keywords) {
        return filterByKeyword(item.keywords.keywords, searchValue)
      }

      return false
    })

    return results
  }

  const getAcronymsBySearchQuery = (
    items: IMSAnswerModel[],
    searchValue: string
  ): IMSAnswerModel[] => {
    const results = items.filter((item) => {
      if (item.state !== 'published') {
        return false
      }

      return (
        item.displayName &&
        searchValue &&
        item.displayName.toLowerCase() === searchValue.toLowerCase()
      )
    })

    return results
  }

  const getAnswersItems = (): IAnswer[] => {
    let result: IAnswer[] = []

    if (bookmarks && bookmarks.length > 0) {
      result = [
        ...result,
        ...getItemsBySearchQuery(bookmarks, searchQuery).map(
          (item: IMSAnswerModel) => {
            return { type: 'Bookmark', data: item }
          }
        )
      ]
    }

    if (qnas && qnas.length > 0) {
      result = [
        ...result,
        ...getItemsBySearchQuery(qnas, searchQuery).map(
          (item: IMSAnswerModel) => {
            return { type: 'QnA', data: item }
          }
        )
      ]
    }

    if (acronyms && acronyms.length > 0) {
      result = [
        ...result,
        ...getAcronymsBySearchQuery(acronyms, searchQuery).map(
          (item: IMSAnswerModel) => {
            return { type: 'Acronym', data: item }
          }
        )
      ]
    }

    if (result.length > 3) {
      return result
        .sort((item1: IAnswer, item2: IAnswer) => {
          return Math.random() < 0.5 ? -1 : 1
        })
        .splice(0, 3)
    }

    return result
  }

  const answersToRender = getAnswersItems()

  return (
    <>
      {answersToRender &&
        (currentPage === '1' || deviceSettings.isMobile) &&
        answersToRender.map((answer: IAnswer, i: number) => {
          const currentBody =
            DOMPurify.sanitize(
              answer.data.description ? answer.data.description : ''
            ) || ''

          return (
            <MSAnswerResult
              answerResult={answer}
              title={DOMPurify.sanitize(answer.data.displayName)}
              body={currentBody}
              url={DOMPurify.sanitize(
                answer.data.webUrl ? answer.data.webUrl : ''
              )}
              standsFor={DOMPurify.sanitize(
                answer.data.standsFor ? answer.data.standsFor : ''
              )}
              key={i}
              index={i}
              searchQuery={DOMPurify.sanitize(searchQuery)}
              deviceSettings={deviceSettings}
            />
          )
        })}
    </>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state),
    bookmarks: MSAnswerStore.selectors.getBookmarks(state),
    qnas: MSAnswerStore.selectors.getQnAs(state),
    acronyms: MSAnswerStore.selectors.getAcronyms(state)
  }
}

export default connect(mapStateToProps)(MSAnswersResults)
