import React, { Dispatch, useMemo } from 'react'
import ReactPlaceholder from 'react-placeholder'
import { Box, Fade } from '@mui/material'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import 'Placeholder.css'
import { Store } from 'store'
import ResultsKpmgUSStore from 'store/ResultsKpmgUS'
import TextResult from 'components/contents/results/TextResult'
import Pagination from '../Pagination'
import { trackException } from 'utils/tracking'
import { dateFormatOptions } from 'constants/constants'
import SearchSuggestions from 'components/contents/common/SearchSuggestions'
import { capitalizeFirstLetter, filterFileNames } from 'utils/string'
import { IDeviceSetting } from 'utils/deviceSettings'
import FeaturedResults from './FeaturedResults'
import { formatDisplayBody } from 'utils/intranet'
import LeftWidgetContainer from './widgetscontainer/LeftWidgetContainer'
import { getStylesResults } from 'styles/contents/resultpages/Results'
import { useFilter } from 'utils/filters'
import { ISeoCheckerData } from 'utils/seoChecker'
import createDOMPurify from 'dompurify'
import { IUserSetting } from 'utils/userSettings'
import { CognitiveMessage } from '../common/CognitiveMessage'

interface ResultsKpmgUsMobileProps {
  searchQuery: any
  setSeoCheckerModal: Dispatch<boolean>
  setSEOCheckerModalData: Dispatch<ISeoCheckerData>
  deviceSettings: IDeviceSetting
  userSettings: IUserSetting
  findConfigCognitiveSearchEnabled: boolean
  cognitiveSearchEnabled: boolean
  showCognitiveMessage: boolean
  setShowCognitiveMessage: Dispatch<boolean>
  setUserSettings: Dispatch<IUserSetting>
}

type AllMobileProps = ReturnType<typeof mapStateToProps> &
  ResultsKpmgUsMobileProps

function ResultsKpmgUsMobile(props: AllMobileProps) {
  const {
    hasResultsBeenFetched,
    totalRowCount,
    featuredResults,
    resultsCombined,
    searchQuery,
    setSeoCheckerModal,
    setSEOCheckerModalData,
    deviceSettings,
    userSettings,
    findConfigCognitiveSearchEnabled,
    cognitiveSearchEnabled,
    showCognitiveMessage,
    setShowCognitiveMessage,
    setUserSettings,
    synonymsApplied
  } = props
  const DOMPurify = createDOMPurify(window)
  const resultClasses = getStylesResults()
  const intl = useIntl()
  const [currentPage] = useFilter('page', '1')

  const returnAllItems = (type: string) => {
    if (!resultsCombined || resultsCombined.length < 1) {
      return []
    }

    const items = []

    try {
      const firstResults =
        resultsCombined.length < 4 ? resultsCombined.length : 3

      if (type === 'first') {
        for (let i = 0; i < firstResults; i++) {
          const currentDate = resultsCombined[i].Date
            ? intl.formatDate(
                DOMPurify.sanitize(resultsCombined[i].Date),
                dateFormatOptions
              )
            : ''
          const currentBody =
            DOMPurify.sanitize(resultsCombined[i].Description) || ''
          const displayBody = formatDisplayBody(currentDate, currentBody, intl)
          items.push(
            <TextResult
              title={filterFileNames(
                capitalizeFirstLetter(
                  DOMPurify.sanitize(resultsCombined[i].Title)
                )
              )}
              body={displayBody}
              url={DOMPurify.sanitize(resultsCombined[i].Url)}
              featured={false}
              showContextMenu={true}
              key={i}
              index={i}
              searchQuery={DOMPurify.sanitize(searchQuery)}
              setSeoCheckerModal={setSeoCheckerModal}
              setSEOCheckerModalData={setSEOCheckerModalData}
              deviceSettings={deviceSettings}
              synonymsApplied={synonymsApplied}
              disableGoToFolder={true}
            />
          )
        }
      } else {
        for (let i = firstResults; i < resultsCombined.length; i++) {
          const currentDate = resultsCombined[i].Date
            ? intl.formatDate(
                DOMPurify.sanitize(resultsCombined[i].Date),
                dateFormatOptions
              )
            : ''
          const currentBody =
            DOMPurify.sanitize(resultsCombined[i].Description) || ''
          const displayBody = formatDisplayBody(currentDate, currentBody, intl)
          items.push(
            <TextResult
              title={filterFileNames(
                capitalizeFirstLetter(
                  DOMPurify.sanitize(resultsCombined[i].Title)
                )
              )}
              body={displayBody}
              url={DOMPurify.sanitize(resultsCombined[i].Url)}
              featured={false}
              showContextMenu={true}
              key={i}
              index={i}
              searchQuery={DOMPurify.sanitize(searchQuery)}
              setSeoCheckerModal={setSeoCheckerModal}
              setSEOCheckerModalData={setSEOCheckerModalData}
              deviceSettings={deviceSettings}
              synonymsApplied={synonymsApplied}
              disableGoToFolder={true}
            />
          )
        }
      }
    } catch (error) {
      trackException(
        'Error returning all items in ResultsKpmgUsMobile.tsx',
        error
      )
    }
    return items
  }

  const firstItems = useMemo(
    () => returnAllItems('first'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resultsCombined, intl]
  )
  const restItems = useMemo(
    () => returnAllItems('rest'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resultsCombined, intl]
  )

  return (
    <Box className={resultClasses.container}>
      <Box className={resultClasses.resultsContainer}>
        <SearchSuggestions
          wildCardActive={true}
          resultCount={totalRowCount}
          resultsFetchend={hasResultsBeenFetched}
        />

        <ReactPlaceholder
          ready={
            hasResultsBeenFetched || Number(currentPage) > 1 ? true : false
          }
          type="text"
          rows={4}
          showLoadingAnimation={true}
          className={resultClasses.placeholder}
        >
          <Fade
            in={hasResultsBeenFetched || Number(currentPage) > 1 ? true : false}
            timeout={600}
          >
            <Box>
              {cognitiveSearchEnabled &&
                showCognitiveMessage &&
                synonymsApplied &&
                findConfigCognitiveSearchEnabled &&
                synonymsApplied.length > 0 && (
                  <CognitiveMessage
                    synonymsApplied={synonymsApplied}
                    setShowCognitiveMessage={setShowCognitiveMessage}
                    userSettings={userSettings}
                    setUserSettings={setUserSettings}
                  />
                )}
              {resultsCombined && totalRowCount > 0 && (
                <FeaturedResults featuredResults={featuredResults} />
              )}
              {firstItems}
            </Box>
          </Fade>
        </ReactPlaceholder>
        {hasResultsBeenFetched &&
        (totalRowCount < 1 || resultsCombined.length < 1) ? null : (
          <LeftWidgetContainer
            resultCount={totalRowCount}
            hasResultsBeenFetched={hasResultsBeenFetched}
          />
        )}
        <ReactPlaceholder
          ready={hasResultsBeenFetched || Number(currentPage) > 1}
          type="text"
          rows={4}
          showLoadingAnimation={true}
          className={resultClasses.placeholder}
        >
          <Fade
            in={hasResultsBeenFetched || Number(currentPage) > 1}
            timeout={600}
          >
            <Box>
              {restItems}
              {totalRowCount != null &&
                totalRowCount > 0 &&
                hasResultsBeenFetched && (
                  <Pagination
                    {...props}
                    totalRecords={totalRowCount}
                    pageLimit={20}
                    pageNeighbours={4}
                    loadMore={true}
                  />
                )}
            </Box>
          </Fade>{' '}
        </ReactPlaceholder>
        <ReactPlaceholder
          ready={hasResultsBeenFetched ? hasResultsBeenFetched : false}
          type="text"
          rows={4}
          showLoadingAnimation={true}
          className={resultClasses.placeholder}
        >
          <Box />
        </ReactPlaceholder>
      </Box>
    </Box>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    featuredResults: ResultsKpmgUSStore.selectors.getFeaturedResults(state),
    synonymsApplied: ResultsKpmgUSStore.selectors.getSynonymsApplied(state),
    resultsCombined: ResultsKpmgUSStore.selectors.getResultsCombined(state),
    totalRowCount: ResultsKpmgUSStore.selectors.getResultCount(state),
    executionTime: ResultsKpmgUSStore.selectors.getExecutionTime(state),
    hasResultsBeenFetched:
      ResultsKpmgUSStore.selectors.hasResultsBeenFetched(state)
  }
}

export default connect(mapStateToProps)(ResultsKpmgUsMobile)
