import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesResultsChat(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles(): any {
  return makeStyles((theme: Theme) => ({
    chatContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
      marginBottom: '1em',
      flex: '0 1 0%'
    },
    chatContentContainer: {
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
      flex: '1 0',
      maxHeight: '70vh'
    },
    chatPromptContainer: {
      flex: '0 0 auto'
    }
  }))
}
