import createDOMPurify from 'dompurify'

export default function getValidUrl(url = ''): string {
  let newUrl = window.decodeURIComponent(url)
  newUrl = newUrl.trim().replace(/\s/g, '')

  if (/^(:\/\/)/.test(newUrl)) {
    return `http${newUrl}`
  }
  if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
    return `http://${newUrl}`
  }

  return newUrl
}

export function filterParameterFromUrl(
  url: string,
  removeParameter: string[]
): string {
  const DOMPurify = createDOMPurify(window)
  const locationHash = DOMPurify.sanitize(url)
  const locationComponents = locationHash.split('?')
  const params = new URLSearchParams(locationComponents[1])

  const deleteKeys: string[] = []
  params.forEach((value, key) => {
    if (removeParameter.includes(key)) {
      deleteKeys.push(key)
    }
  })

  deleteKeys.forEach((key: string) => {
    params.delete(key)
  })

  return locationComponents[0] + '?' + params.toString()
}
