import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesRightPeopleWidget(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles(): any {
  return makeStyles((theme: Theme) => ({
    widgetText: {
      width: 'calc(100% - 70px)',
      whiteSpace: 'pre-wrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    },
    widgetTitle: {
      fontWeight: 'lighter'
    },
    widgetImage: {
      height: 50,
      width: 50,
      borderRadius: '50%',
      objectFit: 'cover',
      backgroundSize: 'cover !important',
      backgroundRepeat: 'no-repeat !important',
      backgroundPosition: 'center !important'
    }
  }))
}
