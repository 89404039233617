import { FeaturedResult } from 'components/models/FeaturedResult'
import { Store } from '..'
import { ISynonymsApplied } from 'components/models/SynonymsApplied'
import { INewsResult } from 'components/models/NewsResult'

const selectors = {
  getFeaturedResults: (state: Store): FeaturedResult[] =>
    state.resultsNews.featuredResults,
  getSynonymsApplied: (state: Store): ISynonymsApplied[] =>
    state.resultsNews.synonymsApplied,
  getResults: (state: Store): INewsResult[] => state.resultsNews.results,
  getResultsCombined: (state: Store): INewsResult[] =>
    state.resultsNews.resultsCombined,
  getResultsCombinedQuery: (state: Store): string =>
    state.resultsNews.resultsCombinedQuery,
  getFilters: (state: Store): any => state.resultsNews.filters,
  hasResultsBeenFetched: (state: Store): boolean =>
    state.resultsNews.hasResultsBeenFetched,
  getResultCount: (state: Store): number => state.resultsNews.resultCount,
  getExecutionTime: (state: Store): number => state.resultsNews.executionTime,
  getCurrentPage: (state: Store): number => state.resultsNews.currentPage,
  getHasError: (state: Store): boolean => state.resultsNews.hasError,
  getLatestQuery: (state: Store): string =>
    state.resultsNews.resultsCombinedQuery
}

export default selectors
