import React, { Dispatch, useMemo } from 'react'
import ReactPlaceholder from 'react-placeholder'
import { Box, Fade, Grid, ListItemIcon, Typography } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import { connect } from 'react-redux'
import 'Placeholder.css'
import { Store } from 'store'
import ResultsOneIntranetStore from 'store/ResultsOneIntranet'
import TextResult from 'components/contents/results/TextResult'
import Pagination from '../Pagination'
import { trackException } from 'utils/tracking'
import { dateFormatOptions } from 'constants/constants'
import SearchSuggestions from 'components/contents/common/SearchSuggestions'
import { capitalizeFirstLetter, filterFileNames } from 'utils/string'
import { IDeviceSetting } from 'utils/deviceSettings'
import FeaturedResults from './FeaturedResults'
import { formatDisplayBody } from 'utils/intranet'
import LeftWidgetContainer from './widgetscontainer/LeftWidgetContainer'
import { getStylesResults } from 'styles/contents/resultpages/Results'
import { getStylesErrorPage } from 'styles/contents/common/ErrorPage'
import { ErrorOutline } from '@mui/icons-material'
import { useFilter } from 'utils/filters'
import { ISeoCheckerData } from 'utils/seoChecker'
import createDOMPurify from 'dompurify'
import { IUserSetting } from 'utils/userSettings'
import { CognitiveMessage } from '../common/CognitiveMessage'
import MSAnswersResults from './MSAnswersResults'

interface ResultsOneIntranetMobileProps {
  searchQuery: any
  setSeoCheckerModal: Dispatch<boolean>
  setSEOCheckerModalData: Dispatch<ISeoCheckerData>
  deviceSettings: IDeviceSetting
  userSettings: IUserSetting
  cognitiveSearchEnabled: boolean
  findConfigCognitiveSearchEnabled: boolean
  showCognitiveMessage: boolean
  setShowCognitiveMessage: Dispatch<boolean>
  setUserSettings: Dispatch<IUserSetting>
}

type AllMobileProps = ReturnType<typeof mapStateToProps> &
  ResultsOneIntranetMobileProps

function ResultsOneIntranetMobile(props: AllMobileProps) {
  const {
    hasResultsBeenFetched,
    totalRowCount,
    lastRow,
    featuredResults,
    resultsCombined,
    hasError,
    searchQuery,
    setSeoCheckerModal,
    setSEOCheckerModalData,
    deviceSettings,
    userSettings,
    cognitiveSearchEnabled,
    findConfigCognitiveSearchEnabled,
    showCognitiveMessage,
    setShowCognitiveMessage,
    setUserSettings
  } = props
  const DOMPurify = createDOMPurify(window)
  const resultClasses = getStylesResults()
  const errorClasses = getStylesErrorPage()
  const intl = useIntl()
  const [currentPage] = useFilter('page', '1')

  const returnAllItems = (type: string) => {
    if (
      !resultsCombined ||
      !resultsCombined.queryResults ||
      resultsCombined.queryResults.length < 1
    ) {
      return []
    }

    const items = []

    try {
      const firstResults =
        resultsCombined.queryResults.length < 4
          ? resultsCombined.queryResults.length
          : 3

      if (type === 'first') {
        for (let i = 0; i < firstResults; i++) {
          const currentDate = resultsCombined.queryResults[i].Date
            ? intl.formatDate(
                DOMPurify.sanitize(resultsCombined.queryResults[i].Date),
                dateFormatOptions
              )
            : ''
          const currentBody =
            DOMPurify.sanitize(resultsCombined.queryResults[i].Body) || ''
          const displayBody = formatDisplayBody(currentDate, currentBody, intl)
          items.push(
            <TextResult
              title={filterFileNames(
                capitalizeFirstLetter(
                  DOMPurify.sanitize(resultsCombined.queryResults[i].Title)
                )
              )}
              body={displayBody}
              url={DOMPurify.sanitize(resultsCombined.queryResults[i].Path)}
              featured={false}
              showContextMenu={true}
              key={i}
              index={i}
              searchQuery={DOMPurify.sanitize(searchQuery)}
              setSeoCheckerModal={setSeoCheckerModal}
              setSEOCheckerModalData={setSEOCheckerModalData}
              deviceSettings={deviceSettings}
              serverRedirectedURL={DOMPurify.sanitize(
                resultsCombined.queryResults[i].ServerRedirectedURL
              )}
              isLink={DOMPurify.sanitize(
                resultsCombined.queryResults[i].IsLink
              )}
              siteTitle={DOMPurify.sanitize(
                resultsCombined.queryResults[i].SiteTitle
              )}
              originalUrl={DOMPurify.sanitize(
                resultsCombined.queryResults[i].OriginalPath
              )}
              attachmentLinks={resultsCombined.queryResults[i].AttachmentUrls}
              synonymsApplied={resultsCombined.synonymsApplied}
              shortcutUrl={DOMPurify.sanitize(
                resultsCombined.queryResults[i].ShortcutUrl
              )}
            />
          )
        }
      } else {
        for (
          let i = firstResults;
          i < resultsCombined.queryResults.length;
          i++
        ) {
          const currentDate = resultsCombined.queryResults[i].Date
            ? intl.formatDate(
                DOMPurify.sanitize(resultsCombined.queryResults[i].Date),
                dateFormatOptions
              )
            : ''
          const currentBody =
            DOMPurify.sanitize(resultsCombined.queryResults[i].Body) || ''
          const displayBody = formatDisplayBody(currentDate, currentBody, intl)
          items.push(
            <TextResult
              title={filterFileNames(
                capitalizeFirstLetter(
                  DOMPurify.sanitize(resultsCombined.queryResults[i].Title)
                )
              )}
              body={displayBody}
              url={DOMPurify.sanitize(resultsCombined.queryResults[i].Path)}
              featured={false}
              showContextMenu={true}
              key={i}
              index={i}
              searchQuery={DOMPurify.sanitize(searchQuery)}
              setSeoCheckerModal={setSeoCheckerModal}
              setSEOCheckerModalData={setSEOCheckerModalData}
              deviceSettings={deviceSettings}
              serverRedirectedURL={DOMPurify.sanitize(
                resultsCombined.queryResults[i].ServerRedirectedURL
              )}
              isLink={DOMPurify.sanitize(
                resultsCombined.queryResults[i].IsLink
              )}
              siteTitle={DOMPurify.sanitize(
                resultsCombined.queryResults[i].SiteTitle
              )}
              originalUrl={DOMPurify.sanitize(
                resultsCombined.queryResults[i].OriginalPath
              )}
              attachmentLinks={resultsCombined.queryResults[i].AttachmentUrls}
              synonymsApplied={resultsCombined.synonymsApplied}
              shortcutUrl={DOMPurify.sanitize(
                resultsCombined.queryResults[i].ShortcutUrl
              )}
            />
          )
        }
      }
    } catch (error) {
      trackException(
        'Error returning all items in ResultsOneIntranetMobile.tsx',
        error
      )
    }
    return items
  }

  const firstItems = useMemo(
    () => returnAllItems('first'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resultsCombined, intl]
  )
  const restItems = useMemo(
    () => returnAllItems('rest'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resultsCombined, intl]
  )

  return (
    <Box className={resultClasses.container}>
      <Box className={resultClasses.resultsContainer}>
        <SearchSuggestions
          wildCardActive={true}
          resultCount={totalRowCount}
          resultsFetchend={hasResultsBeenFetched}
        />
        <ReactPlaceholder
          ready={
            hasResultsBeenFetched || Number(currentPage) > 1 ? true : false
          }
          type="text"
          rows={4}
          showLoadingAnimation={true}
          className={resultClasses.placeholder}
        >
          <Fade
            in={hasResultsBeenFetched || Number(currentPage) > 1 ? true : false}
            timeout={600}
          >
            <Box>
              {hasError && (
                <Box id={'con-err-01'} className={errorClasses.errorcontainer}>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    wrap="nowrap"
                    className={errorClasses.gridContainer}
                  >
                    <Grid item>
                      <ListItemIcon>
                        <ErrorOutline className={errorClasses.icon} />
                      </ListItemIcon>
                    </Grid>
                    <Grid item className={errorClasses.messagesContainer}>
                      <Typography
                        variant="body1"
                        className={errorClasses.errorHeader}
                      >
                        {hasError && (
                          <FormattedMessage
                            id="error_message_oi"
                            defaultMessage="One Intranet results are currently not available."
                          />
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              )}
              {cognitiveSearchEnabled &&
                showCognitiveMessage &&
                resultsCombined.synonymsApplied &&
                findConfigCognitiveSearchEnabled &&
                resultsCombined.synonymsApplied.length > 0 && (
                  <CognitiveMessage
                    synonymsApplied={resultsCombined.synonymsApplied}
                    setShowCognitiveMessage={setShowCognitiveMessage}
                    userSettings={userSettings}
                    setUserSettings={setUserSettings}
                  />
                )}
              {resultsCombined && totalRowCount > 0 && (
                <>
                  <MSAnswersResults
                    hasFeaturedResults={
                      featuredResults && featuredResults.length > 0
                    }
                  />
                  <FeaturedResults featuredResults={featuredResults} />
                </>
              )}
              {firstItems}
            </Box>
          </Fade>
        </ReactPlaceholder>
        {hasResultsBeenFetched &&
        (totalRowCount < 1 ||
          resultsCombined.queryResults.length < 1) ? null : (
          <LeftWidgetContainer
            resultCount={totalRowCount}
            hasResultsBeenFetched={hasResultsBeenFetched}
          />
        )}
        <ReactPlaceholder
          ready={hasResultsBeenFetched || Number(currentPage) > 1}
          type="text"
          rows={4}
          showLoadingAnimation={true}
          className={resultClasses.placeholder}
        >
          <Fade
            in={hasResultsBeenFetched || Number(currentPage) > 1}
            timeout={600}
          >
            <Box>
              {restItems}
              {totalRowCount != null &&
                totalRowCount > 0 &&
                hasResultsBeenFetched && (
                  <Pagination
                    {...props}
                    totalRecords={totalRowCount}
                    pageLimit={20}
                    pageNeighbours={4}
                    loadMore={true}
                    lastRow={lastRow}
                  />
                )}
            </Box>
          </Fade>{' '}
        </ReactPlaceholder>
        <ReactPlaceholder
          ready={hasResultsBeenFetched ? hasResultsBeenFetched : false}
          type="text"
          rows={4}
          showLoadingAnimation={true}
          className={resultClasses.placeholder}
        >
          <Box />
        </ReactPlaceholder>
      </Box>
    </Box>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    featuredResults:
      ResultsOneIntranetStore.selectors.getFeaturedResults(state),
    resultsCombined:
      ResultsOneIntranetStore.selectors.getResultsCombined(state),
    hasError: ResultsOneIntranetStore.selectors.getHasError(state),
    totalRowCount: ResultsOneIntranetStore.selectors.getTotalRowCount(state),
    lastRow: ResultsOneIntranetStore.selectors.getLastRow(state),
    executionTime: ResultsOneIntranetStore.selectors.getExecutionTime(state),
    hasResultsBeenFetched:
      ResultsOneIntranetStore.selectors.hasResultsBeenFetched(state)
  }
}

export default connect(mapStateToProps)(ResultsOneIntranetMobile)
