import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesLeftVideosWidget(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles(): any {
  return makeStyles((theme: Theme) => ({
    media: {
      flex: '0 0 105px'
    },

    icon: {
      height: 44,
      width: 44,
      color: '#ffffff',
      opacity: '0.9'
    }
  }))
}
