import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesChatMessage(): any {
  const renderStyles = getStyles()
  return renderStyles()
}
export function getThemeChatMessage(): any {
  return {
    messageSx: {
      height: 'auto',
      borderRadius: '4px',
      '& .MuiChip-label': {
        display: 'block',
        whiteSpace: 'normal',
        paddingLeft: '0',
        paddingRight: '0',
        overflow: 'initial'
      }
    }
  }
}

function getStyles(): any {
  return makeStyles((theme: Theme) => ({
    container: {
      marginBottom: '1em',
      maxWidth: '85%'
    },

    //request content
    requestContent: {
      padding: '14px'
    },

    //answer content
    answerContent: {
      marginBottom: '14px',
      padding: '14px 14px 0 14px'
    },
    answerContentItem: {
      marginBottom: '1em',
      '&> table': {
        width: '100%',
        borderCollapse: 'collapse'
      },
      '&>table, th, td': {
        border: '1px solid #bdbdbd'
      }
    },
    answerSourceIndex: {
      display: 'inline',
      color: '#004e98',
      marginInlineStart: '.1em'
    },
    // answer sources
    containerAnswerSources: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center'
    },
    answerContentWrapper: {
      display: 'flex',
      flexDirection: 'column',

      '&:hover': {
        '&> .answerActionGroupWrapper': {
          display: 'block'
        }
      }
    },
    answerActionGroupWrapper: {
      alignSelf: 'end',
      position: 'relative',

      display: 'none'
    },
    answerActionGroup: {
      position: 'absolute',
      right: '14px',
      top: '-28px',
      zIndex: 1000,

      border: '1px solid #bdbdbd',
      boxShadow: 'none',
      '&> button': {
        backgroundColor: '#fff',
        color: '#004e98',
        '&:hover': {
          backgroundColor: '#fff'
        }
      }
    }
  }))
}
