import { Reducer } from 'redux'
import {
  FilterActionTypes,
  FilterActions,
  ISetEnabledFilter,
  IClearRecentFitlers
} from './actions'
import * as availableFilters from 'constants/filters'

export interface IFilterStore {
  defaultFilterOptions: any
  currentFilters: CurrentFilter[]
  temporaryFilters: CurrentFilter[]
  recentFilters: { [key: string]: string }
  lastModifiedRangeFilter: {
    from: string | null
    to: string | null
  }
}

const initialState: IFilterStore = {
  defaultFilterOptions: availableFilters,
  currentFilters: [],
  temporaryFilters: [],
  recentFilters: {},
  lastModifiedRangeFilter: {
    from: null,
    to: null
  }
}

const reducers: Reducer<IFilterStore, FilterActions> = (
  state: IFilterStore = initialState,
  action:
    | FilterActions
    | { type: FilterActionTypes.INITIALIZE_FILTER; payload: any }
) => {
  switch (action.type) {
    case FilterActionTypes.INITIALIZE_FILTER:
      if (!action.payload) {
        return initialState
      }

      const { filters } = action.payload

      return {
        ...state,
        recentFilters: filters.recentFilters || initialState.recentFilters
      }
    case FilterActionTypes.RESET_FILTERS:
      return {
        ...state,
        ...initialState
      }
    case FilterActionTypes.SET_ENABLED_FILTERS:
      const newCurrentFilters = (action as ISetEnabledFilter).payload.filter
      const newCurrentFilterList = Array.isArray(newCurrentFilters)
        ? newCurrentFilters
        : [newCurrentFilters]

      const newList: CurrentFilter[] = []
      let changed = false

      newCurrentFilterList.forEach((newCurrentFilter: CurrentFilter) => {
        const targetFilter = state.currentFilters.find(
          (item: CurrentFilter) => item.key === newCurrentFilter.key
        )

        if (!targetFilter) {
          //add
          newList.push(newCurrentFilter)
          changed = true
        } else {
          //update
          if (targetFilter.value !== newCurrentFilter.value) {
            targetFilter.value = newCurrentFilter.value
            changed = true
          }
        }
      })

      if (changed) {
        const cleanedList = [...state.currentFilters, ...newList].filter(
          (item: CurrentFilter) => !!item.value
        )
        return {
          ...state,
          currentFilters: cleanedList
        }
      } else {
        return state
      }
    case FilterActionTypes.OVERRIDE_ENABLED_FILTERS:
      return {
        ...state,
        // not sure how to make TS detect the payload without casting
        currentFilters: (action as ISetEnabledFilter).payload.filter
      }
    case FilterActionTypes.SET_RECENT_FILTERS: {
      const [filter, option] = Object.entries(
        (action as ISetEnabledFilter).payload.filter
      )[0]
      const stateMirror: any = { ...state }

      if (option !== '' && option !== 'all') {
        if (stateMirror.recentFilters[filter]) {
          if (stateMirror.recentFilters[filter].indexOf(option) === -1) {
            if (stateMirror.recentFilters[filter].length >= 4) {
              stateMirror.recentFilters[filter] = [
                ...(stateMirror as any).recentFilters[filter].slice(1, 4),
                option
              ]
            } else {
              stateMirror.recentFilters[filter] = [
                ...(stateMirror as any).recentFilters[filter],
                option
              ]
            }
          }
        } else {
          stateMirror.recentFilters[filter] = [option]
        }
      }

      return {
        ...state,
        ...stateMirror
      }
    }
    case FilterActionTypes.CLEAR_RECENT_FILTERS: {
      const { filter } = (action as IClearRecentFitlers).payload
      const stateMirror = { ...state }

      delete stateMirror.recentFilters[filter]

      return {
        ...state,
        ...stateMirror
      }
    }
    case FilterActionTypes.SET_TEMPORARY_FILTERS:
      const newTemporaryFilters = (action as ISetEnabledFilter).payload.filter
      const newTemporaryFilterList = Array.isArray(newTemporaryFilters)
        ? newTemporaryFilters
        : [newTemporaryFilters]

      const newTemporaryList: CurrentFilter[] = []
      let temporaryChanged = false

      newTemporaryFilterList.forEach((newCurrentFilter: CurrentFilter) => {
        const targetFilter = state.temporaryFilters.find(
          (item: CurrentFilter) => item.key === newCurrentFilter.key
        )

        if (!targetFilter) {
          //add
          newTemporaryList.push(newCurrentFilter)
          temporaryChanged = true
        } else {
          //update
          if (targetFilter.value !== newCurrentFilter.value) {
            targetFilter.value = newCurrentFilter.value
            temporaryChanged = true
          }
        }
      })

      if (temporaryChanged) {
        const cleanedTemporaryListList = [
          ...state.temporaryFilters,
          ...newTemporaryList
        ].filter((item: CurrentFilter) => !!item.value)

        if (cleanedTemporaryListList.length > 0) {
          const qFilter = state.currentFilters.find(
            (item: CurrentFilter) => item.key === 'q'
          )

          if (
            qFilter &&
            !cleanedTemporaryListList.find(
              (item: CurrentFilter) => item.key === 'q'
            )
          )
            cleanedTemporaryListList.push({ ...qFilter })

          if (
            !cleanedTemporaryListList.find(
              (item: CurrentFilter) => item.key === 'page'
            )
          )
            cleanedTemporaryListList.push({ key: 'page', value: '1' })

          const premiumFilter = state.currentFilters.find(
            (item: CurrentFilter) => item.key === 'premium'
          )
          if (
            premiumFilter &&
            !cleanedTemporaryListList.find(
              (item: CurrentFilter) => item.key === 'premium'
            )
          )
            cleanedTemporaryListList.push({ ...premiumFilter })
        }

        return {
          ...state,
          temporaryFilters: cleanedTemporaryListList
        }
      } else {
        return state
      }
    case FilterActionTypes.CLEAR_TEMPORARY_FILTERS: {
      if (state.temporaryFilters.length > 0)
        return {
          ...state,
          temporaryFilters: initialState.temporaryFilters
        }
      return state
    }
    default:
      return state
  }
}

export default reducers
