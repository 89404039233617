import { Store } from '..'
import { TabTypes } from './actions'

const selectors = {
  getExtendedResults: (state: Store, type: TabTypes): any => {
    return {
      ...state.resultsOffice365.extendedResults,
      featuredResults: state.resultsOffice365[type].featuredResults
    }
  },
  getResults: (state: Store, type: TabTypes): any =>
    state.resultsOffice365[type].results,
  getResultsCombined: (state: Store, type: TabTypes): any =>
    state.resultsOffice365[type].resultsCombined,
  getFeatureResults: (state: Store, type: TabTypes): any =>
    state.resultsOffice365[type].featuredResults,
  getResultsCombinedQuery: (state: Store, type: TabTypes): any =>
    state.resultsOffice365[type].resultsCombinedQuery,
  hasResultsBeenFetched: (state: Store, type: TabTypes): any =>
    state.resultsOffice365[type].hasResultsBeenFetched,
  getResultCount: (state: Store, type: TabTypes): any =>
    state.resultsOffice365[type].resultCount,
  getExecutionTime: (state: Store, type: TabTypes): any =>
    state.resultsOffice365[type].executionTime,
  getCurrentPage: (state: Store, type: TabTypes): any =>
    state.resultsOffice365[type].currentPage,
  hasExchangeLicense: (state: Store): any =>
    state.resultsOffice365.licenseAvailable.isAvailable,
  hasLicenseBeenChecked: (state: Store): any =>
    state.resultsOffice365.licenseAvailable.hasBeenChecked,
  areMoreResultsAvailable: (state: Store, type: TabTypes): any =>
    state.resultsOffice365[type].moreResultsAvailable,
  getHasError: (state: Store, type: TabTypes): boolean =>
    state.resultsOffice365[type].hasError
}

export default selectors
