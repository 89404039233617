import React from 'react'
import {
  Box,
  Collapse,
  IconButton,
  Tooltip,
  Link,
  Grid,
  Typography
} from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { MailSourceResponse } from 'utils/o365'
import highlightedText, { prepareHighlightWords } from 'utils/highlightText'
import { externalLinkClicked } from 'utils/tracking'
import { IDeviceSetting } from 'utils/deviceSettings'
import { dateFormatOptions } from 'constants/constants'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import { getStylesMailResults } from 'styles/results/MailResults'
import { ISynonymsApplied } from 'components/models/SynonymsApplied'
import LinesEllipsis from 'react-lines-ellipsis'
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'

interface IMobileProps {
  item: MailSourceResponse
  isOpen: boolean
  searchQuery: string
  deviceSettings: IDeviceSetting
  open: boolean
  senderName: string
  setOpen: React.Dispatch<boolean>
  index: number
  synonymsApplied: ISynonymsApplied[]
}

export function MailMobileResult(props: IMobileProps): JSX.Element {
  const {
    item,
    searchQuery,
    deviceSettings,
    open,
    setOpen,
    senderName,
    index,
    synonymsApplied
  } = props
  const classes = getStylesMailResults()
  const intl = useIntl()
  const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis)

  return (
    <Grid container direction="column" className={classes.mailMobileContainer}>
      <Grid container justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Link
            className={classes.mobileResultDate}
            href={item.webLink}
            onClick={() => {
              externalLinkClicked({
                title: item.subject,
                url: item.webLink,
                index: index
              })
            }}
            {...(deviceSettings.openLinksInNewTab === true
              ? { target: '_blank', rel: 'noreferrer' }
              : {})}
          >
            <Typography variant="body1" color="primary">
              {item.receivedDateTime
                ? intl.formatDate(
                    item.receivedDateTime.replace('Z', ''),
                    dateFormatOptions
                  )
                : ''}
            </Typography>
          </Link>
          <Typography
            style={{
              paddingLeft: 2,
              paddingRight: 2
            }}
          >
            {'›'}
          </Typography>
          <Tooltip enterTouchDelay={300} title={senderName}>
            <Link
              className={classes.mobileResultSender}
              href={item.webLink}
              onClick={() => {
                externalLinkClicked({
                  title: item.subject,
                  url: item.webLink,
                  index: index
                })
              }}
              {...(deviceSettings.openLinksInNewTab === true
                ? { target: '_blank', rel: 'noreferrer' }
                : {})}
            >
              <ResponsiveEllipsis
                style={{
                  whiteSpace: 'pre-wrap',
                  textOverflow: 'ellipsis',
                  textDecoration: 'none',
                  overflow: 'hidden'
                }}
                basedOn="words"
                text={senderName ? senderName.trim() : ''}
                maxLine="1"
              />
            </Link>
          </Tooltip>
        </Box>
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => setOpen(!open)}
        >
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </Grid>
      <Grid className={classes.mobileResultSubject}>
        <Tooltip enterTouchDelay={300} title={item.subject}>
          <Link
            className={classes.mailTitleContainer}
            href={item.webLink}
            onClick={() => {
              externalLinkClicked({
                title: item.subject,
                url: item.webLink,
                index: index
              })
            }}
            {...(deviceSettings.openLinksInNewTab === true
              ? { target: '_blank', rel: 'noreferrer' }
              : {})}
          >
            {item.hasAttachments && (
              <AttachFileIcon
                className={classes.mailAttachmentIconMobile}
                viewBox="5 0 24 24"
              />
            )}
            <ResponsiveEllipsis
              style={{
                whiteSpace: 'pre-wrap',
                textOverflow: 'ellipsis',
                textDecoration: 'none',
                overflow: 'hidden'
              }}
              basedOn="words"
              text={item.subject ? item.subject.trim() : ''}
              maxLine="1"
            />
          </Link>
        </Tooltip>
      </Grid>
      <Grid>
        <Collapse
          in={open}
          timeout="auto"
          unmountOnExit
          className={classes.resultCollapse}
        >
          <Box className={classes.mailResultBody}>
            {item.bodyPreview ? (
              highlightedText(
                item.bodyPreview,
                prepareHighlightWords(
                  searchQuery,
                  item.bodyPreview,
                  synonymsApplied
                ),
                false
              )
            ) : (
              <FormattedMessage
                id="missing_description_text"
                defaultMessage="Description is not available"
              />
            )}
          </Box>
        </Collapse>
      </Grid>
    </Grid>
  )
}
