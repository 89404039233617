import React, { Dispatch, useEffect, useState } from 'react'
import { CardMedia, Typography } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import Tooltip from '@mui/material/Tooltip'
import CheckIcon from '@mui/icons-material/Check'
import { Icon } from '@fluentui/react/lib/Icon'
import { getFileTypeIconProps } from '@uifabric/file-type-icons'
import { useIntl } from 'react-intl'
import DetectableOverflow from 'react-detectable-overflow'
import { isFilterUrlValueSet, useFilter } from 'utils/filters'
import { getStylesFilterMenuItems } from 'styles/contents/FilterMenuItems'
import FilterMenuItemsDateRange from './FilterMenuItemsDateRange'
import { getCurrentScope } from 'utils/queryParams'

export interface FilterMenuItemsProps {
  filter: FilterCategory
  setRecentFilters: Dispatch<any>
  setCurrentPage: Dispatch<any>
  handleClose: any
  selectedFilter: string
  setSelectedFilter: any
  menuItemRef: any
  option: any
  index: number
  isLastRecentItem?: boolean
  isFirstMenuItem: boolean
  modifiedRangeFilters: any
}

export default function FilterMenuItems(
  props: FilterMenuItemsProps
): JSX.Element {
  const {
    filter,
    setRecentFilters,
    setCurrentPage,
    handleClose,
    selectedFilter,
    setSelectedFilter,
    menuItemRef,
    option,
    index,
    isLastRecentItem,
    isFirstMenuItem,
    modifiedRangeFilters
  } = props

  const [showToolTip, setShowToolTip] = useState<boolean[]>([])
  const [filterUrlValue, setFilterUrlValue] = useFilter(filter.key, '')

  //Fix for 806865 - Tooltip Filter texts for long filter values are visible only after scrolling downwards and then back
  //This fix triggers re-rendering of the tooltip component
  const [renderToolTips, setRenderToolTips] = useState(false)

  const intl = useIntl()
  const classes = getStylesFilterMenuItems()
  const scope = getCurrentScope(false)

  const resultCountFormatter = (count = 0) => {
    if (Math.abs(count) > 999999) {
      return `${(Math.abs(count) / 1000000).toFixed(1)}m`
    } else if (Math.abs(count) > 999) {
      return `${(Math.abs(count) / 1000).toFixed(1)}k`
    } else {
      return Math.abs(count)
    }
  }

  const handleSelection = (event: any) => {
    handleClose()

    let filterUrlValueTmp: string
    if (isFilterUrlValueSet(filter, filterUrlValue, option.key)) {
      filterUrlValueTmp = filterUrlValue
        .split('|')
        .filter((filterKey) => filterKey && filterKey !== option.key)
        .join('|')
      option['selected'] = false
    } else {
      filterUrlValueTmp =
        option.key === 'all'
          ? ''
          : filter.multiselect
            ? filterUrlValue
              ? filterUrlValue + ',' + option.key
              : option.key
            : option.key
      setRecentFilters({
        [filter.key]: option.key
      })
      option['selected'] = true

      // In case the filter key is last modified and the option key is not customRange
      // reset the saved last modified filter values
      if (filter.key === 'lastmodified' && option.key !== 'customRange') {
        modifiedRangeFilters.from = null
        modifiedRangeFilters.to = null
      }
    }

    setFilterUrlValue(filterUrlValueTmp)

    if (filterUrlValueTmp || filterUrlValueTmp === '') {
      setSelectedFilter(option.key)
    }

    // reset pagination
    setCurrentPage(1)
  }

  const handleMultiSelection = (event: any) => {
    option['selected'] = !option['selected']

    let selectedFiltersArray = selectedFilter ? selectedFilter.split('|') : []

    // Options get selected add it from the string
    // Options gets deselected remove it from the string
    if (option['selected'] && selectedFiltersArray.indexOf(option.key) === -1) {
      selectedFiltersArray.push(option.key)
    } else if (!option['selected']) {
      selectedFiltersArray = selectedFiltersArray.filter(
        (f: string) => f !== option.key
      )
    }

    setSelectedFilter(selectedFiltersArray.join('|'))
  }

  const tooltipTitle = intl.formatMessage({
    id:
      option && option.name
        ? option.name.toLowerCase().replace(/\s/g, '_')
        : 'no_results',
    defaultMessage: option && option.name ? option.name : 'No results'
  })

  const renderMenuItem = () => (
    <div
      className={classes.menuItem}
      style={{
        borderBottom: isLastRecentItem ? '1px solid #e0e0e0' : ''
      }}
    >
      <div className={classes.pictureContainer}>
        {option.image && (
          <CardMedia
            className={classes.picture}
            component="img"
            image={
              option.image
                ? process.env.PUBLIC_URL + '/assets/' + option.image + '.svg'
                : undefined
            }
          />
        )}
        {option.fileTypeIcon && !option.image && (
          <>
            {filter.key === 'imageType' && option.fileTypeIcon ? (
              <CardMedia
                className={classes.picture}
                component="img"
                image={
                  process.env.PUBLIC_URL +
                  '/filters/' +
                  option.fileTypeIcon +
                  '.png'
                }
              />
            ) : (
              <Icon
                className={classes.pictureFileType}
                {...getFileTypeIconProps({
                  extension: option.fileTypeIcon,
                  size: 16
                })}
              />
            )}
          </>
        )}
      </div>
      <div
        className={
          scope === 'news'
            ? [classes.textContainer, classes.textContainerNews].join(' ')
            : classes.textContainer
        }
        style={{
          width: 'auto'
        }}
      >
        <DetectableOverflow
          className={showToolTip[index] ? classes.textDetectableOverflow : ''}
          onChange={(isOverflowing: boolean) => {
            showToolTip[index] = isOverflowing
            setShowToolTip(showToolTip)
            setRenderToolTips(true)
          }}
        >
          {tooltipTitle}
        </DetectableOverflow>
      </div>

      {option.resultCount && !option.selected && (
        <div className={classes.badge}>
          <Typography className={classes.badgeCount}>
            {`${scope === 'news' ? '≈ ' : ''}${resultCountFormatter(
              option.resultCount
            )}`}
          </Typography>
        </div>
      )}
      {(option.selected ||
        (option.key === 'all' &&
          filterUrlValue === '' &&
          (selectedFilter === '' || selectedFilter === undefined))) && (
        <div className={classes.checkBox}>
          <CheckIcon fontSize={'small'}> </CheckIcon>
        </div>
      )}
    </div>
  )

  useEffect(() => {
    return () => {
      setRenderToolTips(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {option && option.key !== 'customRange' && (
        <MenuItem
          tabIndex={0}
          disableTouchRipple
          disableRipple
          style={option.style}
          ref={(ref: HTMLLIElement | null) => {
            if (isFirstMenuItem) {
              menuItemRef.current = ref
            }
          }}
          onKeyDown={(event) => {
            if (event.which === 13 || event.keyCode === 13) {
              filter.multiselect
                ? handleMultiSelection(event)
                : handleSelection(event)
              event.stopPropagation()
            }
            if (event.key === 'Tab') {
              event.stopPropagation()
            }
          }}
          classes={{ root: classes.rootMenuItem, gutters: classes.gutters }}
          key={option.key}
        >
          {renderToolTips ? (
            <Tooltip
              title={showToolTip[index] ? tooltipTitle : ''}
              placement="top"
              arrow
            >
              <div
                onClick={
                  filter.multiselect ? handleMultiSelection : handleSelection
                }
                className={classes.menuItemContainer}
              >
                {renderMenuItem()}
              </div>
            </Tooltip>
          ) : (
            <div
              onClick={
                filter.multiselect ? handleMultiSelection : handleSelection
              }
              className={classes.menuItemContainer}
            >
              {renderMenuItem()}
            </div>
          )}
        </MenuItem>
      )}
      {option && option.key === 'customRange' && (
        <FilterMenuItemsDateRange
          filter={filter}
          setCurrentPage={setCurrentPage}
          handleClose={handleClose}
          option={option}
          modifiedRangeFilters={modifiedRangeFilters}
        />
      )}
    </>
  )
}
