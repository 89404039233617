import { IChatRequest } from 'components/models/Chat'
import { Store } from '..'

const selectors = {
  getChatRequests: (state: Store): IChatRequest[] =>
    state.resultsChat.chatRequestList,
  getHasError: (state: Store): boolean => state.resultsChat.hasError
}

export default selectors
