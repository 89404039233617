import React from 'react'
import * as Config from 'config'
import { ResultRightWidgetGenerator } from './interfaces'
import RightKPMGWebsitesWidget from 'components/contents/resultpages/widgets/RightKPMGWebsitesWidget'
import { stripHtml } from 'utils/string'
import { trackException } from 'utils/tracking'
import { FetchWithCache } from 'utils/api'
import { unifysearchQuery } from 'utils/unifysearchQuery'
import { IDeviceSetting } from 'utils/deviceSettings'
import { IUserSetting } from 'utils/userSettings'
import { IFindConfiguration } from 'store/Settings/reducers'
import { IKpmgWebsitesResult } from 'components/models/KpmgWebsiteResult'
import { IAADState } from 'store/Auth/reducers'
import { renewAuthorizationToken } from 'utils/token'

export const rightKPMGWebsitesWidgetGenerator: ResultRightWidgetGenerator = {
  fetch(
    aadInfo: IAADState,
    searchQuery: string,
    deviceSettings: IDeviceSetting,
    userSettings: IUserSetting,
    findConfiguration: IFindConfiguration
  ): Promise<IKpmgWebsitesResult[]> {
    return new Promise<IKpmgWebsitesResult[]>((resolve, reject) => {
      const fetchKPMGWebsites = async () => {
        try {
          if (!searchQuery || searchQuery === '' || searchQuery === '""') {
            trackException(
              'Error in fetching KPMG Websites right widget in rightKPMGWebsitesWidget.tsx',
              new Error('Empty query. Please specify a valid query text')
            )
            reject()
            return
          }

          // Build and get aem results
          let apiUrl = `${
            Config.APIM_BASE_URL
          }aemapi/getsearch?locale=xx/en&keyword=${unifysearchQuery(
            searchQuery,
            'kpmg.com'
          )}&processFeaturedResults=false`

          apiUrl += `&limit=20`

          // Get and check authentication token
          const esToken = await renewAuthorizationToken(
            aadInfo.accessToken,
            aadInfo.instance,
            aadInfo.accounts
          )

          const response = await FetchWithCache(apiUrl, {
            method: 'GET',
            headers: {
              accept: 'application/json',
              'content-type': 'application/json',
              'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
              Authorization: `Bearer ${esToken}`
            }
          })

          let results
          if (!response || response.hasError || !response.responseJSON) {
            trackException(
              'Error in fetching kpmg websites api call in rightKPMGWebsitesWidget.tsx',
              new Error(
                response && response.errorResponse
                  ? response.errorResponse.responseCode +
                    ':' +
                    response.errorResponse.message
                  : ''
              )
            )
            reject()
            return
          } else {
            results = response.responseJSON
          }

          let primaryResults = results && results.results ? results.results : []

          if (primaryResults.length > 2) {
            if (primaryResults.length > 10) {
              primaryResults = primaryResults.slice(0, 10)
            }

            const kpmgwebsiteResults: IKpmgWebsitesResult[] = []

            primaryResults.forEach((item: any) => {
              let date = ''
              if (
                item &&
                item.kpmg_article_date_time &&
                item.kpmg_article_date_time.raw &&
                item.kpmg_article_date_time.raw !== ''
              ) {
                date = item.kpmg_article_date_time.raw
              } else if (
                item &&
                item.kpmg_event_start_time &&
                item.kpmg_event_start_time.raw &&
                item.kpmg_event_start_time.raw !== ''
              ) {
                date = item.kpmg_event_start_time.raw
              } else if (
                item &&
                item.kpmg_blog_date_time &&
                item.kpmg_blog_date_time.raw &&
                item.kpmg_blog_date_time.raw !== ''
              ) {
                date = item.kpmg_blog_date_time.raw
              } else {
                date = ''
              }

              const kpmgwebsiteResult: IKpmgWebsitesResult = {
                title:
                  item.kpmg_title && item.kpmg_title.raw
                    ? item.kpmg_title.raw
                    : '',
                body:
                  item.kpmg_description && item.kpmg_description.raw
                    ? stripHtml(item.kpmg_description.raw)
                    : '',
                date: date,
                url:
                  item.kpmg_url && item.kpmg_url.raw ? item.kpmg_url.raw : '',
                image:
                  item.kpmg_image && item.kpmg_image.raw
                    ? `https://assets.kpmg/${item.kpmg_image.raw}`
                    : '',
                tabType:
                  item.kpmg_tab_type && item.kpmg_tab_type.raw
                    ? item.kpmg_tab_type.raw
                    : ''
              }

              kpmgwebsiteResults.push(kpmgwebsiteResult)
            })

            resolve(kpmgwebsiteResults)
          } else {
            reject()
          }
        } catch (error) {
          trackException(
            'Error in fetching kpmg websites api call in rightKPMGWebsitesWidget.tsx',
            error
          )
          reject()
        }
      }

      try {
        fetchKPMGWebsites()
      } catch (error) {
        trackException(
          'Error in fetching kpmg websites in rightKPMGWebsitesWidget.tsx',
          error
        )
        reject()
      }
    })
  },
  generate(
    results: IKpmgWebsitesResult[],
    searchQuery: string
  ): Promise<React.ReactElement> {
    return new Promise<React.ReactElement>((resolve, reject) => {
      const generateNews = () => {
        if (!results) {
          reject()
          return
        }

        let hasContent = false
        for (let i = 0; i < results.length; i++) {
          if (results[i].title !== '') {
            hasContent = true
            break
          }
        }
        if (!hasContent) {
          reject()
          return
        }

        resolve(
          <RightKPMGWebsitesWidget
            searchQuery={searchQuery}
            results={results}
          />
        )
      }

      try {
        generateNews()
      } catch (error) {
        trackException(
          'Error in generating KPMG Websites right widget in rightKPMGWebsiteWidget.tsx',
          error
        )
        reject()
      }
    })
  }
}
