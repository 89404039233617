import React, { Dispatch, useMemo } from 'react'
import { Box, Fade } from '@mui/material'
import ReactPlaceholder from 'react-placeholder'
import { connect } from 'react-redux'
import 'Placeholder.css'
import { Store } from 'store'
import ResultsPeopleStore from 'store/ResultsPeople'
import PeopleResult from 'components/contents/results/PeopleResult'
import Pagination from '../Pagination'
import { trackException } from 'utils/tracking'
import SearchSuggestions from 'components/contents/common/SearchSuggestions'
import { IDeviceSetting } from 'utils/deviceSettings'
import FeaturedResults from './FeaturedResults'
import { useIntl } from 'react-intl'
import LeftWidgetContainer from './widgetscontainer/LeftWidgetContainer'
import { getStylesResults } from 'styles/contents/resultpages/Results'
import { useFilter } from 'utils/filters'
import createDOMPurify from 'dompurify'
import { IUserSetting } from 'utils/userSettings'
import { CognitiveMessage } from '../common/CognitiveMessage'

interface ResultsPeopleMobileProps {
  searchQuery: any
  deviceSettings: IDeviceSetting
  userSettings: IUserSetting
  cognitiveSearchEnabled: boolean
  findConfigCognitiveSearchEnabled: boolean
  showCognitiveMessage: boolean
  setShowCognitiveMessage: Dispatch<boolean>
  setUserSettings: Dispatch<IUserSetting>
}

type AllMobileProps = ReturnType<typeof mapStateToProps> &
  ResultsPeopleMobileProps

function ResultsPeopleMobile(props: AllMobileProps) {
  const {
    hasResultsBeenFetched,
    resultsCount,
    featuredResults,
    resultsCombined,
    searchQuery,
    deviceSettings,
    synonymsApplied,
    userSettings,
    cognitiveSearchEnabled,
    findConfigCognitiveSearchEnabled,
    showCognitiveMessage,
    setShowCognitiveMessage,
    setUserSettings
  } = props
  const DOMPurify = createDOMPurify(window)
  const intl = useIntl()
  const resultClasses = getStylesResults()
  const [currentPage] = useFilter('page', '1')

  const returnAllItems = (type: string) => {
    if (!resultsCombined || resultsCombined.length < 1) {
      return []
    }

    const items = []

    try {
      const firstResults =
        resultsCombined.length < 4 ? resultsCombined.length : 3

      if (type === 'first') {
        for (let i = 0; i < firstResults; i++) {
          items.push(
            <PeopleResult
              upn={DOMPurify.sanitize(resultsCombined[i].userPrincipalName)}
              title={DOMPurify.sanitize(resultsCombined[i].displayName)}
              body={DOMPurify.sanitize(resultsCombined[i].body)}
              url={DOMPurify.sanitize(resultsCombined[i].url)}
              key={i}
              index={i}
              jobTitle={DOMPurify.sanitize(resultsCombined[i].jobTitle)}
              country={DOMPurify.sanitize(resultsCombined[i].country)}
              imageUrl={DOMPurify.sanitize(resultsCombined[i].pictureUrl)}
              firstName={''}
              email={DOMPurify.sanitize(resultsCombined[i].mail)}
              department={DOMPurify.sanitize(resultsCombined[i].department)}
              phoneNumber={DOMPurify.sanitize(resultsCombined[i].workPhone)}
              mobileNumber={DOMPurify.sanitize(resultsCombined[i].mobilePhone)}
              officeNumber={DOMPurify.sanitize(resultsCombined[i].officeNumber)}
              searchQuery={DOMPurify.sanitize(searchQuery)}
              deviceSettings={deviceSettings}
              synonymsApplied={synonymsApplied}
            />
          )
        }
      } else {
        for (let i = firstResults; i < resultsCombined.length; i++) {
          items.push(
            <PeopleResult
              upn={DOMPurify.sanitize(resultsCombined[i].userPrincipalName)}
              title={DOMPurify.sanitize(resultsCombined[i].displayName)}
              body={DOMPurify.sanitize(resultsCombined[i].body)}
              url={DOMPurify.sanitize(resultsCombined[i].url)}
              key={i}
              index={i}
              jobTitle={DOMPurify.sanitize(resultsCombined[i].jobTitle)}
              country={DOMPurify.sanitize(resultsCombined[i].country)}
              imageUrl={DOMPurify.sanitize(resultsCombined[i].pictureUrl)}
              firstName={''}
              email={DOMPurify.sanitize(resultsCombined[i].mail)}
              department={DOMPurify.sanitize(resultsCombined[i].department)}
              phoneNumber={DOMPurify.sanitize(resultsCombined[i].workPhone)}
              mobileNumber={DOMPurify.sanitize(resultsCombined[i].mobilePhone)}
              officeNumber={DOMPurify.sanitize(resultsCombined[i].officeNumber)}
              searchQuery={DOMPurify.sanitize(searchQuery)}
              deviceSettings={deviceSettings}
              synonymsApplied={synonymsApplied}
            />
          )
        }
      }
    } catch (error) {
      trackException(
        'Error returning all items in ResultsPeopleMobile.tsx',
        error
      )
    }
    return items
  }

  const firstItems = useMemo(
    () => returnAllItems('first'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resultsCombined, intl]
  )
  const restItems = useMemo(
    () => returnAllItems('rest'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resultsCombined, intl]
  )

  return (
    <Box className={resultClasses.container}>
      <Box className={resultClasses.resultsContainer}>
        <SearchSuggestions
          wildCardActive={true}
          resultCount={resultsCount}
          resultsFetchend={hasResultsBeenFetched}
        />
        <ReactPlaceholder
          ready={
            hasResultsBeenFetched || Number(currentPage) > 1 ? true : false
          }
          type="text"
          rows={4}
          showLoadingAnimation={true}
          className={resultClasses.placeholder}
        >
          <Fade
            in={hasResultsBeenFetched || Number(currentPage) > 1 ? true : false}
            timeout={600}
          >
            <Box>
              {cognitiveSearchEnabled &&
                showCognitiveMessage &&
                synonymsApplied &&
                findConfigCognitiveSearchEnabled &&
                synonymsApplied.length > 0 && (
                  <CognitiveMessage
                    synonymsApplied={synonymsApplied}
                    setShowCognitiveMessage={setShowCognitiveMessage}
                    userSettings={userSettings}
                    setUserSettings={setUserSettings}
                  />
                )}
              {resultsCombined && resultsCount > 0 && (
                <FeaturedResults featuredResults={featuredResults} />
              )}
              {firstItems}
            </Box>
          </Fade>
        </ReactPlaceholder>
        {hasResultsBeenFetched &&
        (resultsCount < 1 || resultsCombined.length < 1) ? null : (
          <LeftWidgetContainer
            resultCount={resultsCount}
            hasResultsBeenFetched={hasResultsBeenFetched}
          />
        )}
        <ReactPlaceholder
          ready={hasResultsBeenFetched || Number(currentPage) > 1}
          type="text"
          rows={4}
          showLoadingAnimation={true}
          className={resultClasses.placeholder}
        >
          <Fade
            in={hasResultsBeenFetched || Number(currentPage) > 1}
            timeout={600}
          >
            <Box>
              {restItems}
              {resultsCount != null &&
                resultsCount > 0 &&
                hasResultsBeenFetched && (
                  <Pagination
                    {...props}
                    totalRecords={resultsCount}
                    pageLimit={20}
                    pageNeighbours={4}
                  />
                )}
            </Box>
          </Fade>
        </ReactPlaceholder>
        <ReactPlaceholder
          ready={hasResultsBeenFetched ? hasResultsBeenFetched : false}
          type="text"
          rows={4}
          showLoadingAnimation={true}
          className={resultClasses.placeholder}
        >
          <Box />
        </ReactPlaceholder>
      </Box>
    </Box>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    featuredResults: ResultsPeopleStore.selectors.getFeaturedResults(state),
    resultsCombined: ResultsPeopleStore.selectors.getResultsCombined(state),
    resultsCount: ResultsPeopleStore.selectors.getResultCount(state),
    executionTime: ResultsPeopleStore.selectors.getExecutionTime(state),
    hasResultsBeenFetched:
      ResultsPeopleStore.selectors.hasResultsBeenFetched(state),
    synonymsApplied: ResultsPeopleStore.selectors.getSynonymsApplied(state)
  }
}

export default connect(mapStateToProps)(ResultsPeopleMobile)
