import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesRightSearchTop10Widget(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles(): any {
  return makeStyles((theme: Theme) => ({
    widgetItem: {
      display: 'flex',
      flexDirection: 'row',
      padding: '8px 24px',
      '&:hover': {
        backgroundColor: 'lightgray'
      },
      cursor: 'pointer'
    },
    widgetText: {
      width: '100%',
      whiteSpace: 'pre-wrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      alignItems: 'center'
    },
    widgetTitle: {
      color: '#000',
      fontWeight: 'lighter',
      width: '100%'
    },
    widgetContent: { overflow: 'hidden' },
    widgetArrow: {
      color: 'rgba(0, 0, 0, 0.54)',
      margin: 'auto',
      position: 'relative',
      width: '30px',
      '&>svg': {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
      }
    },
    spacer: {
      minWidth: '20px'
    }
  }))
}
