/**
 * Compares if two objects are deeply equal, recursively.
 * @param object1 The first object
 * @param object2 The second object
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function deepEqual(object1: any, object2: any): boolean {
  const keys1 = Object.keys(object1)
  const keys2 = Object.keys(object2)

  if (keys1.length !== keys2.length) {
    return false
  }

  for (const key of keys1) {
    const val1 = object1[key]
    const val2 = object2[key]
    const areObjects =
      val1 && typeof val1 === 'object' && val2 && typeof val2 === 'object'
    if (
      (areObjects && !deepEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false
    }
  }

  return true
}
