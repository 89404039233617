import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import Link from '@mui/material/Link'
import highlightText, { prepareHighlightWords } from 'utils/highlightText'
import { removeQueryParam } from 'utils/queryParams'
import { trackRightWidgetClick } from 'utils/tracking'
import { WidgetKey } from 'constants/widgets'
import FilePlaceholder from 'images/file_placeholder.png'
import { getStylesRightThoughtLeadershipWidget } from 'styles/contents/resultpages/widgets/RightThoughtLeadershipWidget'
import { getStylesRightWidget } from 'styles/contents/resultpages/widgets/RightWidget'
import { IDeviceSetting } from 'utils/deviceSettings'
import createDOMPurify from 'dompurify'
import { getPicture } from 'utils/msgraphThumbnailPicture'
import AuthStore from 'store/Auth'
import { Store } from 'store'
import { connect } from 'react-redux'
import TooltipTitle from 'components/contents/common/TooltipTitle'
import { ISynonymsApplied } from 'components/models/SynonymsApplied'
import HTMLEllipsis from 'react-lines-ellipsis/lib/html'
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'

export interface RightThoughtLeadershipResultProps {
  title: string
  documentUrl: string
  abstract: string
  image: string
  footer: string
  deviceSettings: IDeviceSetting
  searchQuery: string
  index: number
  synonymsApplied: ISynonymsApplied[]
}

type AllProps = ReturnType<typeof mapStateToProps> &
  RightThoughtLeadershipResultProps

function RightThoughtLeadershipResult(props: AllProps): JSX.Element {
  const {
    title,
    documentUrl,
    abstract,
    image,
    footer,
    deviceSettings,
    searchQuery,
    index,
    aadInfo,
    synonymsApplied
  } = props

  const DOMPurify = createDOMPurify(window)

  const [pictureUrl, setPictureUrl] = React.useState(image)

  const navigateFunction = useNavigate()
  const rightWidgetClasses = getStylesRightWidget()
  const classes = getStylesRightThoughtLeadershipWidget()

  const ResponsiveHTMLEllipsis = responsiveHOC()(HTMLEllipsis)

  const loadPicture = async () => {
    const pictureUrl = await getPicture(image, deviceSettings, aadInfo)
    setPictureUrl(DOMPurify.sanitize(pictureUrl))
  }

  useEffect(() => {
    loadPicture()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const imageClick = (e: any, linkUrl: any) => {
    if (deviceSettings.openLinksInNewTab === true) {
      window.open(linkUrl)
    } else {
      window.location.href = linkUrl
    }
  }

  const setPlaceholderImage = (e: any) => {
    e.target.src = FilePlaceholder
  }

  return (
    //<LazyLoad offset={150}>
    <Box key={'rtl' + index}>
      <Box key={'rtlb1' + index} className={classes.widgetContent}>
        <Box key={'rtlb2' + index} className={classes.widgetText}>
          <Link
            underline={'hover'}
            key={'rtll1' + index}
            onClick={() => {
              removeQueryParam(navigateFunction, 'cntx')
              trackRightWidgetClick({
                title: DOMPurify.sanitize(title),
                url: DOMPurify.sanitize(documentUrl),
                index,
                widgetKey: 'thoughtLeadership',
                body: DOMPurify.sanitize(abstract)
              })
            }}
            {...(!deviceSettings.isMobile
              ? {
                  onAuxClick: (event: any) => {
                    removeQueryParam(navigateFunction, 'cntx')
                    trackRightWidgetClick({
                      title: DOMPurify.sanitize(title),
                      url: DOMPurify.sanitize(documentUrl),
                      index,
                      widgetKey: 'thoughtLeadership',
                      body: DOMPurify.sanitize(abstract),
                      wasRightClicked: event?.button === 2 ? true : false
                    })
                  }
                }
              : {})}
            href={
              documentUrl.indexOf('.pdf') === -1
                ? `${DOMPurify.sanitize(documentUrl)}?web=1`
                : DOMPurify.sanitize(documentUrl)
            }
            data-node-title={DOMPurify.sanitize(title)}
            data-node-index={index}
            data-node-click-type="rightWidgetClick"
            data-node-widget-key={WidgetKey.thoughtleadership}
            {...(deviceSettings.openLinksInNewTab === true
              ? { target: '_blank', rel: 'noreferrer' }
              : {})}
          >
            <TooltipTitle
              title={DOMPurify.sanitize(title?.trim())}
              key={'rtld1' + index}
              singleLine={true}
              additionalClass={`${rightWidgetClasses.widgetTitle} ${classes.widgetTitle}`}
            />
          </Link>
          <ResponsiveHTMLEllipsis
            className={`${rightWidgetClasses.widgetDescription} ${rightWidgetClasses.widgetDescriptionExtended}`}
            basedOn="words"
            unsafeHTML={highlightText(
              DOMPurify.sanitize(abstract),
              prepareHighlightWords(
                DOMPurify.sanitize(searchQuery),
                DOMPurify.sanitize(abstract),
                synonymsApplied
              ),
              true
            )}
            maxLine="2"
          />
          <Typography
            key={'rtlt1' + index}
            className={rightWidgetClasses.widgetFooterContent}
          >
            {footer ? DOMPurify.sanitize(footer) : ''}
          </Typography>
        </Box>
        <Box key={'rtlb3' + index} className={rightWidgetClasses.spacer} />
        <div
          key={'rtld3' + index}
          style={{
            backgroundImage:
              pictureUrl !== null && pictureUrl !== ''
                ? `url("${DOMPurify.sanitize(
                    pictureUrl
                  )}"),url("${FilePlaceholder}")`
                : `url("${FilePlaceholder}")`
          }}
          className={classes.widgetImage}
          title={title}
          onClick={(e) => {
            imageClick(e, DOMPurify.sanitize(documentUrl))
          }}
          onError={(e) => {
            setPlaceholderImage(e)
          }}
        />
      </Box>
    </Box>
    //</LazyLoad>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    aadInfo: AuthStore.selectors.getAADInfo(state)
  }
}

export default connect(mapStateToProps)(RightThoughtLeadershipResult)
