import { FeaturedResult } from 'components/models/FeaturedResult'
import { Reducer } from 'redux'
import {
  ResultsIbfdActions,
  ResultsIbfdActionTypes,
  IFetchSuccess,
  IFetchSuccessCombined,
  IFetchFiltersSuccess,
  IFetchFailure
} from './actions'
import { IIbfdResult } from 'components/models/IbfdResult'

export interface IIbfdFilterResults {
  currentQuery: string
  countriesFilter: Filter[]
  regionFilter: Filter[]
  orgFilter: Filter[]
  topicFilter: Filter[]
  hasResultsBeenFetched: boolean
  hasError: boolean
}

export interface IResultsIbfdStore {
  hasResultsBeenFetched: boolean
  featuredResults: FeaturedResult[]
  results: IIbfdResult[]
  resultsCombined: IIbfdResult[]
  resultsCombinedQuery: string
  resultCount: number
  executionTime: number
  currentPage: number
  filters: IIbfdFilterResults
  hasCountryError: boolean
}

const initialResultFiltersState = () => ({
  currentQuery: '',
  countriesFilter: [],
  regionFilter: [],
  orgFilter: [],
  topicFilter: [],
  hasResultsBeenFetched: false,
  hasError: false
})

const initialState: IResultsIbfdStore = {
  hasResultsBeenFetched: false,
  resultCount: -1,
  featuredResults: [],
  results: [],
  resultsCombined: [],
  resultsCombinedQuery: '',
  filters: initialResultFiltersState(),
  executionTime: 0,
  currentPage: 1,
  hasCountryError: false
}

const reducers: Reducer<IResultsIbfdStore, ResultsIbfdActions> = (
  state: IResultsIbfdStore = initialState,
  action: ResultsIbfdActions | { type: 'SET_CURRENT_PAGE'; payload: any }
) => {
  switch (action.type) {
    case 'SET_CURRENT_PAGE':
      return {
        ...state,
        currentPage: action.payload || 0
      }
    case ResultsIbfdActionTypes.INITIALIZE_RESULTS_IBFD:
      if (!(action as any).payload) {
        return initialState
      }
      const { resultsStatistics } = (action as any).payload
      return {
        ...state,
        currentPage: resultsStatistics.currentPage || 1
      }
    case ResultsIbfdActionTypes.FETCH_REQUEST:
      const { resetCombined, searchQuery } = (action as any).payload
      return {
        ...state,
        hasResultsBeenFetched: false,
        executionTime: 0,
        resultCount: -1,
        results: [],
        resultsCombined: resetCombined ? [] : state.resultsCombined,
        resultsCombinedQuery: searchQuery
      }
    case ResultsIbfdActionTypes.FETCH_FAILURE:
      const { isCountryError } = (action as IFetchFailure).payload
      return {
        ...state,
        hasResultsBeenFetched: true,
        resultCount: 0,
        hasCountryError: isCountryError ? isCountryError : false
      }
    case ResultsIbfdActionTypes.FETCH_SUCCESS:
      const { response, featuredResults } = (action as IFetchSuccess).payload
      return {
        ...state,
        hasResultsBeenFetched: true,
        executionTime: response.executionTime,
        resultCount: response.resultCount,
        results: response.queryResults,
        featuredResults:
          response.currentPage === 1 ? featuredResults : state.featuredResults,
        currentPage: response.currentPage
      }
    case ResultsIbfdActionTypes.FETCH_SUCCESS_COMBINED:
      const { response: responseCpmbined } = (action as IFetchSuccessCombined)
        .payload
      return {
        ...state,
        resultCount: responseCpmbined.resultCount,
        resultsCombined: responseCpmbined.queryResults
      }
    case ResultsIbfdActionTypes.FETCH_FILTERS_REQUEST:
      const { resetFilters } = (action as any).payload
      return {
        ...state,
        hasError: false,
        filters: {
          ...(resetFilters ? initialResultFiltersState() : state.filters),
          hasResultsBeenFetched: false,
          hasError: false
        }
      }
    case ResultsIbfdActionTypes.FETCH_FILTERS_FAILURE:
      return {
        ...state,
        hasResultsBeenFetched: true,
        resultCount: 0,
        filters: {
          ...initialResultFiltersState(),
          hasResultsBeenFetched: true
        }
        // TODO: evaluate whether it is suffiecient to reset the results key to it's initial state
        // on failure only. Before it was also reset in the case ResultsNewsActionTypes.FETCH_REQUEST which
        // led to the filters not being filled in properly due to the fact that the corresponding keys and
        // values are not present in the store anymore / yet.
      }
    case ResultsIbfdActionTypes.FETCH_FILTERS_SUCCESS:
      const { responseFilters } = (action as IFetchFiltersSuccess).payload
      return {
        ...state,
        hasError: false,
        filters: {
          hasResultsBeenFetched: true,
          hasError: false,
          currentQuery: responseFilters.filters.currentQuery,
          countriesFilter: responseFilters.filters.countriesFilter,
          regionFilter: responseFilters.filters.regionFilter,
          orgFilter: responseFilters.filters.orgFilter,
          topicFilter: responseFilters.filters.topicFilter
        }
      }
    default:
      return state
  }
}

export default reducers
