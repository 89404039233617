import React from 'react'
import * as Config from 'config'
import { ResultRightWidgetGenerator } from './interfaces'
import { trackException } from 'utils/tracking'
import { ISearchTop10 } from 'store/SearchTop10/reducers'
import RightSearchTop10Widget from 'components/contents/resultpages/widgets/RightSearchTop10Widget'
import { IDeviceSetting } from 'utils/deviceSettings'
import { IUserSetting } from 'utils/userSettings'
import { IAADState } from 'store/Auth/reducers'
import { renewAuthorizationToken } from 'utils/token'

export const rightSearchTop10WidgetGenerator: ResultRightWidgetGenerator = {
  fetch(
    aadInfo: IAADState,
    searchQuery: string,
    deviceSettings: IDeviceSetting,
    userSettings: IUserSetting
  ): Promise<ISearchTop10[]> {
    return new Promise<ISearchTop10[]>((resolve, reject) => {
      const fetchSearchTop10 = async () => {
        try {
          if (!searchQuery || searchQuery === '' || searchQuery === '""') {
            trackException(
              'Error in fetching search top 10 right widget in rightSearchTop10Widget.tsx',
              new Error('Empty query. Please specify a valid query text')
            )
            reject()
            return
          }

          const apiUrl = `${Config.APIM_BASE_URL}searchapi/gettop10?country=${
            userSettings.Country ? userSettings.Country : ''
          }&function=${
            userSettings.Function ? userSettings.Function : ''
          }&origin=KPMGFind`

          // Get and check authentication token
          const esToken = await renewAuthorizationToken(
            aadInfo.accessToken,
            aadInfo.instance,
            aadInfo.accounts
          )

          const data = await fetch(apiUrl, {
            method: 'GET',
            headers: {
              accept: 'application/json',
              'content-type': 'application/json',
              'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
              Authorization: `Bearer ${esToken}`
            }
          })

          if (data.status !== 200) {
            reject()
            return
          }

          const result = await data.json()

          // Don't show the widget in case less than 10 results are returned
          if (!result || result.length < 10) {
            reject()
            return
          }

          let searchTop10Result = new Array<ISearchTop10>()

          if (result) {
            searchTop10Result = result.map((item: ISearchTop10) => {
              return {
                searchQuery: decodeURIComponent(item.searchQuery),
                count: item.count,
                function: item.function,
                country: item.country
              }
            })
          }

          resolve(searchTop10Result)
        } catch (error) {
          trackException(
            'Error in generating SearchTop10 widget in rightSearchTop10Widget.tsx',
            error
          )
        }
        reject()
        return
      }

      try {
        fetchSearchTop10()
      } catch (error) {
        trackException(
          'Error in fetching SearchTop10 widget in rightSearchTop10Widget.tsx',
          error
        )
        reject()
      }
    })
  },
  generate(
    results: ISearchTop10[],
    searchQuery: string
  ): Promise<React.ReactElement> {
    return new Promise<React.ReactElement>((resolve, reject) => {
      const generateSearchTop10Widget = () => {
        resolve(
          <RightSearchTop10Widget searchQuery={searchQuery} results={results} />
        )
      }

      try {
        generateSearchTop10Widget()
      } catch (error) {
        trackException(
          'Error in generating SearchTop10 widget in rightSearchTop10Widget.tsx',
          error
        )
        reject()
      }
    })
  }
}
