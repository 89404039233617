import React from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'

import { Typography, Link, Box, Grid } from '@mui/material'
import * as stringUtils from 'utils/string'
import { externalLinkClicked } from 'utils/tracking'
import highlightedText, { prepareHighlightWords } from 'utils/highlightText'
import { getCurrentScope, removeQueryParam } from 'utils/queryParams'
import { generateUrlBreadcrumbs, stripHtml } from 'utils/string'
import { useSelector, useDispatch } from 'react-redux'
import ResultsStore from 'store/Results'
import { getStylesAlexResult } from 'styles/results/AlexResult'
import { IDeviceSetting } from 'utils/deviceSettings'
import { dateFormatOptions } from 'constants/constants'
import TooltipTitle from '../common/TooltipTitle'
import { CustomDescription } from '../common/CustomDescription'
import { IAlexResult } from 'components/models/AlexResult'
import mime from 'mime'

interface AlexResultProps {
  result: IAlexResult
  key: number
  index: number
  serverRedirectedURL?: string
  disableNoWrap?: boolean
  searchQuery?: string
  deviceSettings: IDeviceSetting
}

export function AlexResult(props: AlexResultProps): JSX.Element {
  const {
    result,
    index,
    disableNoWrap,
    searchQuery,
    serverRedirectedURL,
    deviceSettings
  } = props

  const navigateFunction = useNavigate()
  const classes = getStylesAlexResult()
  const scope = getCurrentScope(false)

  const intl = useIntl()

  let docTypeFlair: string | null = null
  let urlBreadcrumbs = ''

  const maxBreadCrumbLength = deviceSettings.renderMobile ? 35 : 79

  const generateAlexManualUrl = (item: any | null) => {
    if (item.url) {
      return item.url
    }

    // in case no docid is present, nothing can be done
    if (!item.tocRef) {
      return ''
    }
    return `https://alex.kpmg.com/AROWeb/document/manuals/toc?tocref=${item.tocRef}&cntry=${item.tocCountry}&chunkid=${item.docId}`
  }

  const generateDisplayUrl = (item: any, intl: any) => {
    const currentDate = item.createdDate
      ? intl.formatDate(item.createdDate, dateFormatOptions)
      : ''
    const currentSource = item.sources ? item.sources.join(' | ') : ''

    return `${currentDate}${
      currentDate && currentSource && currentSource.length > 0 ? ' › ' : ''
    }${currentSource}`
  }

  let displayUrl = ''
  let url = ''
  let body = ''
  let title = ''
  const mimeType = result.mimeType

  title = result.displayText
    ? result.displayText
    : result.title
      ? result.title
      : ''

  body = result.synopsis ? stripHtml(result.synopsis) : ''
  displayUrl = generateDisplayUrl(result, intl)
  url = generateAlexManualUrl(result)

  const [isVisited, setVisited] = React.useState(
    useSelector((state: any) =>
      ResultsStore.selectors.isResultVisited(state, url)
    )
  )
  const dispatch = useDispatch()
  const setIsVisited = () => {
    if (!isVisited) {
      dispatch(ResultsStore.actions.storeResultHistory(url))
      setVisited(true)
    }
  }

  if (url) {
    if (mimeType && mimeType !== 'text/html') {
      docTypeFlair = mime.getExtension(mimeType)
    }

    // Build URL Breadcrumbs
    urlBreadcrumbs = generateUrlBreadcrumbs(scope, url, false)
    if (urlBreadcrumbs.length > maxBreadCrumbLength) {
      urlBreadcrumbs = stringUtils.truncate(urlBreadcrumbs, maxBreadCrumbLength)
    }
  }

  return (
    <>
      <Grid container>
        <Grid item xs>
          <Box className={classes.result}>
            <div className={classes.urlWrapper}>
              {!displayUrl && urlBreadcrumbs.length > 0 && (
                <Typography
                  variant="body1"
                  display="inline"
                  className={
                    disableNoWrap
                      ? classes.urlBreadcrumbsWordWrap
                      : classes.urlBreadcrumbs
                  }
                >
                  {urlBreadcrumbs}
                </Typography>
              )}
              {displayUrl && (
                <Typography
                  variant="body1"
                  display="inline"
                  className={
                    disableNoWrap
                      ? classes.urlBreadcrumbsWordWrap
                      : classes.urlBreadcrumbs
                  }
                >
                  {displayUrl}
                </Typography>
              )}
              {docTypeFlair && (
                <Typography
                  variant="caption"
                  display="inline"
                  color="secondary"
                  className={`${classes.docType} ${classes.docTypeWithMargin}`}
                >
                  {docTypeFlair}
                </Typography>
              )}
            </div>

            <Link
              className={classes.link}
              {...(deviceSettings.openLinksInNewTab === true
                ? { target: '_blank', rel: 'noreferrer' }
                : {})}
              href={
                serverRedirectedURL && serverRedirectedURL !== ''
                  ? serverRedirectedURL
                  : url
              }
              onClick={(event: any) => {
                setIsVisited()
                removeQueryParam(navigateFunction, 'cntx')
                externalLinkClicked({ title, url, index })
              }}
              {...(!deviceSettings.isMobile
                ? {
                    onAuxClick: (event: any) => {
                      setIsVisited()
                      removeQueryParam(navigateFunction, 'cntx')
                      externalLinkClicked({
                        title,
                        url,
                        index,
                        wasRightClicked: event?.button === 2 ? true : false
                      })
                    }
                  }
                : {})}
            >
              <Typography
                variant="h5"
                color="primary"
                className={`${classes.title} ${
                  isVisited ? classes.isVisitedResult : ''
                }`}
                component="div"
              >
                <TooltipTitle title={title ? title : ''} singleLine={true} />
              </Typography>
            </Link>
            <Typography
              variant="body2"
              color="secondary"
              gutterBottom
              className={`${classes.body} txt-resultdescription-01`}
              component="div"
            >
              <CustomDescription
                descriptionValue={
                  body
                    ? highlightedText(
                        body.replace(/(\r\n|\n|\r)/gm, ' '),
                        prepareHighlightWords(
                          searchQuery,
                          body.replace(/(\r\n|\n|\r)/gm, ' '),
                          []
                        ),
                        true
                      )
                    : ''
                }
              />
            </Typography>
            {result.tocTitle && (
              <Typography
                variant="body2"
                color="secondary"
                gutterBottom
                className={classes.tocTitle}
                component="div"
              >
                {result.tocTitle}
              </Typography>
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  )
}
