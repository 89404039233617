import React from 'react'
import * as Config from 'config'
import { ResultLeftWidgetGenerator } from './interfaces'
import LeftNewsWidget from 'components/contents/resultpages/widgets/LeftNewsWidget'
import { stripHtml } from 'utils/string'
import { trackException } from 'utils/tracking'
import { buildRefinementFilters } from 'utils/filters'
import { FetchWithCache } from 'utils/api'
import * as Filters from 'constants/filters'
import { unifysearchQuery } from 'utils/unifysearchQuery'
import { IDeviceSetting } from 'utils/deviceSettings'
import { IUserSetting } from 'utils/userSettings'
import { IFindConfiguration } from 'store/Settings/reducers'
import { INewsResult } from 'components/models/NewsResult'
import { ISynonymsApplied } from 'components/models/SynonymsApplied'
import { renewAuthorizationToken } from 'utils/token'
import { removeStopWordsFromQuery } from 'utils/oneIntranet'
import { IAADState } from 'store/Auth/reducers'

export const leftNewsWidgetGenerator: ResultLeftWidgetGenerator = {
  fetch(
    aadInfo: IAADState,
    searchQuery: string,
    deviceSettings: IDeviceSetting,
    userSettings: IUserSetting,
    findConfiguration: IFindConfiguration,
    useCognitiveSearch: boolean
  ): Promise<{
    queryResults: INewsResult[]
    synonymsApplied: ISynonymsApplied[]
  }> {
    return new Promise<{
      queryResults: INewsResult[]
      synonymsApplied: ISynonymsApplied[]
    }>((resolve, reject) => {
      const fetchNews = async () => {
        if (!searchQuery || searchQuery === '' || searchQuery === '""') {
          trackException(
            'Error in fetching News left widget in leftNewsWidget.tsx',
            new Error('Empty query. Please specify a valid query text')
          )
          reject()
          return
        }

        const appliedFilters: any = {}

        // Get and check authentication token
        const esToken = await renewAuthorizationToken(
          aadInfo.accessToken,
          aadInfo.instance,
          aadInfo.accounts
        )

        let _searchQuery = searchQuery
        if (
          useCognitiveSearch &&
          findConfiguration.CognitiveSearchEnabled &&
          esToken !== ''
        ) {
          let searchQueryLanguage = ''
          const detectLanguageApiUrl = `${
            Config.APIM_BASE_URL
          }searchapi/detectlanguage?searchQuery=${encodeURIComponent(
            searchQuery
          )}`

          const languageResponse = await FetchWithCache(detectLanguageApiUrl, {
            method: 'POST',
            headers: {
              accept: 'application/json',
              'content-type': 'application/json',
              'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
              Authorization: `Bearer ${esToken}`
            }
          })
          if (
            languageResponse &&
            !languageResponse.hasError &&
            languageResponse.responseJSON
          ) {
            const languageJSON = languageResponse.responseJSON
            if (languageJSON && languageJSON.responseCode === 200) {
              searchQueryLanguage = languageJSON.language
            }
          }

          _searchQuery = removeStopWordsFromQuery(
            searchQuery,
            searchQueryLanguage,
            useCognitiveSearch,
            findConfiguration.CognitiveSearchEnabled
          )
        }

        let apiUrl = ''
        const refinementFilters = buildRefinementFilters(
          appliedFilters,
          Filters.news
        )

        if (refinementFilters.length > 0) {
          apiUrl = `${
            Config.APIM_BASE_URL
          }lexisnexisapi/getsearch/?query=${unifysearchQuery(
            _searchQuery,
            'news'
          )}&expand=false&skip=0${refinementFilters}&top=20&groupDuplicates=ModerateSimilarity&processFeaturedResults=false`
        } else {
          apiUrl = `${
            Config.APIM_BASE_URL
          }lexisnexisapi/getsearch/?query=${unifysearchQuery(
            _searchQuery,
            'news'
          )}&expand=false&skip=0&top=20&groupDuplicates=ModerateSimilarity&processFeaturedResults=false`
        }

        if (useCognitiveSearch && findConfiguration.CognitiveSearchEnabled) {
          apiUrl += '&cognitiveEnabled=true'
        }

        const response = await FetchWithCache(apiUrl, {
          method: 'GET',
          headers: {
            'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
            'content-type': 'application/json',
            Authorization: `Bearer ${esToken}`
          }
        })

        let results
        if (!response || response.hasError || !response.responseJSON) {
          trackException(
            'Error in fetching News left widget in leftNewsWidget.tsx',
            new Error(
              response && response.errorResponse
                ? response.errorResponse.responseCode +
                  ':' +
                  response.errorResponse.message
                : ''
            )
          )
          reject()
          return
        } else {
          results = response.responseJSON
        }

        if (!results || !results.News || results.News.length < 3) {
          reject()
          return
        } else if (results.News.length > 10) {
          results.News = results.News.slice(0, 10)
        }

        const resultsList: INewsResult[] = results.News.map((i: any) => {
          const newsResult: INewsResult = {
            Title: i.Title ? i.Title : '',
            Summary: i.Summary ? stripHtml(i.Summary.replace('... ', '')) : '',
            Link: i.Link ? i.Link : '',
            Date: i.Date ? i.Date : '',
            Source: i.Source && i.Source.Name ? i.Source.Name : ''
          }
          return newsResult
        })

        resolve({
          queryResults: resultsList,
          synonymsApplied: results.SynonymsApplied
        })
      }

      try {
        fetchNews()
      } catch (error) {
        trackException(
          'Error in fetching News left widget in leftNewsWidget.tsx',
          error
        )
        reject()
      }
    })
  },
  generate(
    resultsRaw: {
      queryResults: INewsResult[]
      synonymsApplied: ISynonymsApplied[]
    },
    searchQuery: string
  ): Promise<React.ReactElement> {
    return new Promise<React.ReactElement>((resolve, reject) => {
      const generateNews = () => {
        const results = resultsRaw.queryResults
        const limit = 10
        if (results.length > 2) {
          let resultsList = results

          if (results.length > limit) {
            resultsList = results.slice(0, limit)
          }
          let hasContent = false
          for (let i = 0; i < results.length; i++) {
            if (results[i].Title !== '') {
              hasContent = true
              break
            }
          }
          if (!hasContent) {
            reject()
            return
          }

          resolve(
            <LeftNewsWidget
              query={searchQuery}
              results={resultsList}
              synonymsApplied={resultsRaw.synonymsApplied}
            />
          )
        } else {
          reject()
          return
        }
      }

      try {
        generateNews()
      } catch (error) {
        trackException(
          'Error in generating News left widget in leftNewsWidget.tsx',
          error
        )
        reject()
      }
    })
  }
}
