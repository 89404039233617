import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesErrorPage(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles() {
  return makeStyles((theme: Theme) => ({
    messagesContainer: {
      width: '100%',
      wordBreak: 'break-word',
      paddingTop: 10,
      paddingBottom: 10
    },
    gridContainer: {
      position: 'relative'
    },
    detailsIcon: {
      position: 'absolute',
      top: 0,
      right: 0
    },
    detailsBoxTextLabel: {
      fontSize: 14,
      width: 95
    },
    detailsBoxText: {
      fontSize: 13
    },
    datailsRowContainer: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      padding: '2px 0px'
    },
    detailscontainer: {
      marginLeft: 56,
      border: '1px solid #dfe1e5',
      marginBottom: 10,
      padding: 5,
      borderRadius: 4,
      marginTop: 10
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest
      })
    },
    expandOpen: {
      transform: 'rotate(180deg)'
    },

    container: {
      marginBottom: 16,
      borderRadius: 4,
      padding: '10px 20px',
      textAlign: 'left',
      color: '#611a15',
      fontWeight: 'lighter',
      font: 'Arial,Helvetica Neue,Helvetica,sans-serif-light'
    },
    containerInfo: {
      color: '#3c4043 !important'
    },

    info: {
      backgroundColor: '#F6F7FA' //gray
    },
    error: {
      backgroundColor: '#fdecea', //red
      '& a': {
        color: '#f5544a',
        fontWeight: 'bold',
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline'
        }
      }
    },
    warning: {
      backgroundColor: '#FDECD6' //yellow
    },

    infoIcon: {
      color: '#5F6368' //gray
    },
    errorIcon: {
      color: '#f5544a' //red
    },
    warningIcon: {
      color: '#f68d2e' //yellow
    },

    title: {
      fontSize: '14px',
      margin: '5px 0'
    },
    header: {
      fontSize: '22px',
      margin: '5px 0'
    },
    content: {
      fontSize: '14px',
      margin: '5px 0'
    },
    footer: {
      fontSize: '14px',
      margin: '5px 0'
    }
  }))
}
