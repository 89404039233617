import React from 'react'
import {
  Box,
  Grid,
  Typography,
  Link,
  IconButton,
  Collapse
} from '@mui/material'
import { externalLinkClicked } from 'utils/tracking'
import { FormattedMessage, useIntl } from 'react-intl'
import { dateFormatOptions } from 'constants/constants'
import { IDeviceSetting } from 'utils/deviceSettings'
import { getStylesSiteResult } from 'styles/results/SiteResult'
import { stripHtml } from 'utils/string'
import highlightedText, { prepareHighlightWords } from 'utils/highlightText'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import TooltipTitle from '../common/TooltipTitle'
import { ISynonymsApplied } from 'components/models/SynonymsApplied'

export interface SiteResultProps {
  name: string
  webUrl: string
  createdDateTime: string
  lastModifiedDateTime?: string
  description?: string
  key: string | number
  index: number
  deviceSettings: IDeviceSetting
  searchQuery: string
  isOpen: boolean
  synonymsApplied: ISynonymsApplied[]
}

export function SiteResult(props: SiteResultProps): JSX.Element {
  const {
    name,
    webUrl,
    createdDateTime,
    lastModifiedDateTime,
    description,
    deviceSettings,
    searchQuery,
    isOpen,
    index,
    synonymsApplied
  } = props

  const [open, setOpen] = React.useState(isOpen)
  const classes = getStylesSiteResult()
  const intl = useIntl()

  return (
    <Grid container>
      <Grid item xs>
        <Box className={classes.result}>
          <div className={classes.urlWrapper}>
            <Typography variant="body1" className={classes.urlBreadcrumbs}>
              {`${intl.formatDate(
                lastModifiedDateTime
                  ? lastModifiedDateTime.replace('Z', '')
                  : createdDateTime,
                dateFormatOptions
              )}`}
            </Typography>
            <IconButton
              aria-label="expand row"
              size="small"
              className={classes.mobileCollapseIcon}
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </div>
          <Link
            underline={'hover'}
            className={classes.link}
            {...(deviceSettings.openLinksInNewTab === true
              ? { target: '_blank', rel: 'noreferrer' }
              : {})}
            href={webUrl}
            onClick={() => {
              externalLinkClicked({
                title: name,
                url: webUrl,
                index: index
              })
            }}
            {...(!deviceSettings.isMobile
              ? {
                  onAuxClick: (event: any) => {
                    externalLinkClicked({
                      title: name,
                      url: webUrl,
                      index: index,
                      wasRightClicked: event?.button === 2 ? true : false
                    })
                  }
                }
              : {})}
          >
            <Typography
              data-node-subject={name}
              variant="h5"
              noWrap
              color="primary"
              className={classes.title}
            >
              <TooltipTitle title={name ? name : ''} singleLine={true} />
            </Typography>
          </Link>
          <Typography
            variant="body2"
            color="secondary"
            gutterBottom
            className={`${classes.body} txt-resultdescription-01`}
            component="div"
          >
            {description ? (
              highlightedText(
                stripHtml(description),
                prepareHighlightWords(
                  searchQuery,
                  stripHtml(description),
                  synonymsApplied
                ),
                false
              )
            ) : (
              <FormattedMessage
                id="missing_description_text"
                defaultMessage="Description is not available"
              />
            )}
          </Typography>
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
            className={classes.resultCollapse}
          >
            <Typography
              variant="body2"
              color="secondary"
              gutterBottom
              className={classes.mobileBody}
              component="div"
            >
              {description ? (
                highlightedText(
                  stripHtml(description),
                  prepareHighlightWords(
                    searchQuery,
                    stripHtml(description),
                    synonymsApplied
                  ),
                  false
                )
              ) : (
                <FormattedMessage
                  id="missing_description_text"
                  defaultMessage="Description is not available"
                />
              )}
            </Typography>
          </Collapse>
        </Box>
      </Grid>
    </Grid>
  )
}
