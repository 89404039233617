import { FeaturedResult } from 'components/models/FeaturedResult'
import { Store } from '..'
import { IResult } from './reducers'

const selectors = {
  getFeaturedResults: (state: Store): FeaturedResult[] =>
    state.resultsVideos.featuredResults,
  getResults: (state: Store): IResult => state.resultsVideos.results,
  getResultsCombined: (state: Store): IResult =>
    state.resultsVideos.resultsCombined,
  getResultsCombinedQuery: (state: Store): string =>
    state.resultsVideos.resultsCombinedQuery,
  hasResultsBeenFetched: (state: Store): boolean =>
    state.resultsVideos.hasResultsBeenFetched,
  getTotalRowCount: (state: Store): number => state.resultsVideos.totalRowCount,
  getExecutionTime: (state: Store): number => state.resultsVideos.executionTime,
  getLastRow: (state: Store): number => state.resultsVideos.lastRow,
  getHasOIError: (state: Store): boolean => state.resultsVideos.hasOIError,
  getHasError: (state: Store): boolean => state.resultsVideos.hasError,
  getLatestQuery: (state: Store): string =>
    state.resultsVideos.resultsCombinedQuery
}

export default selectors
