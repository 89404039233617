import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesLeftStatistaWidget(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles(): any {
  return makeStyles((theme: Theme) => ({
    premiumFlare: {
      color: '#fff',
      background: '#7cc302',
      position: 'absolute',
      right: '0px',
      top: '0px',
      width: '14px',
      height: '14px',
      lineHeight: '14px',
      textAlign: 'center'
    },
    premiumFlareIcon: { lineHeight: '13px' },
    statistaCard: {
      psoition: 'relative'
    }
  }))
}
