import { isValidPrefilterCountry } from './filters'
import * as Constants from 'constants/constants'
import findVersions from 'constants/findVersions'
import { trackException } from './tracking'
import { PopupUserSettings } from 'components/models/PopupResult'
import { KPMGFindGlobalVariables } from 'store/KPMGFindGlobalVariables'
import React from 'react'
import { IErrorType } from 'components/contents/common/ErrorPage'

export interface IUserSettingStore {
  id: string
  Version: string
  EnablePrefiltering: boolean | undefined
  EnableWidgetPrefiltering: boolean | undefined
  PlayTour: boolean
  StorageVersion: string
  Country: string
  FirstLogin: number
  City: string
  Function: string
  PinFilters: boolean
  Language: string
  LanguageManuallySeleted: boolean
  DataSourceOrder: string
  DataSourceDisabled: string
  IntroductionShown: boolean
  FeedbackShortShowed: boolean
  FeedbackLongShowed: boolean
  PremiumEnabled: boolean | undefined
  TrackingAccepted: boolean
  Department: string
  LastVisits: number[]
  FeedbackShortShownTime: number
  FeedbackLongDelayed: number
  FeedbackLongDelayedOn: number
  FeedbackShortDelayed: number
  FeedbackShortDelayedOn: number
  PopupList: PopupUserSettings[]
  CognitiveSearchEnabled: boolean
  EntityRecognitionEnabled: boolean
  ShowCognitiveMessage: boolean
  DisabledWidgets: string[]
  OpenLinksInNewTab: boolean
}

interface IUserSettingLocalStore extends IUserSettingStore {
  upn: string
  PictureUrl: string
  DisplayName: string
  FirstName: string
  LastName: string
  UseLocalSettings: boolean
  ForceNoUpdate: boolean
  ForceRightWidget: string | null
  ForceLeftWidget: string | null
  SimulateAPIError: IErrorType | null
}

export interface IUserSetting extends IUserSettingLocalStore {
  initialLoaded: boolean
}

export const initialUserSettingState = (): IUserSetting => ({
  DisplayName: '',
  FirstName: '',
  LastName: '',
  PictureUrl: '',
  initialLoaded: false,
  UseLocalSettings: false,
  ForceNoUpdate: false,
  id: initialUserSettingsForStore.id,
  upn: '',
  Version: initialUserSettingsForStore.Version,
  EnablePrefiltering: undefined,
  EnableWidgetPrefiltering: undefined,
  PlayTour: initialUserSettingsForStore.PlayTour,
  StorageVersion: initialUserSettingsForStore.StorageVersion,
  Country: initialUserSettingsForStore.Country,
  FirstLogin: initialUserSettingsForStore.FirstLogin,
  City: initialUserSettingsForStore.City,
  Function: initialUserSettingsForStore.Function,
  Department: initialUserSettingsForStore.Department,
  PinFilters: initialUserSettingsForStore.PinFilters,
  Language: initialUserSettingsForStore.Language,
  LanguageManuallySeleted: initialUserSettingsForStore.LanguageManuallySeleted,
  DataSourceOrder: initialUserSettingsForStore.DataSourceOrder,
  DataSourceDisabled: initialUserSettingsForStore.DataSourceDisabled,
  IntroductionShown: initialUserSettingsForStore.IntroductionShown,
  FeedbackShortShowed: initialUserSettingsForStore.FeedbackShortShowed,
  FeedbackLongShowed: initialUserSettingsForStore.FeedbackLongShowed,
  PremiumEnabled: undefined,
  TrackingAccepted: initialUserSettingsForStore.TrackingAccepted,
  LastVisits: initialUserSettingsForStore.LastVisits,
  FeedbackShortShownTime: initialUserSettingsForStore.FeedbackShortShownTime,
  FeedbackLongDelayed: initialUserSettingsForStore.FeedbackLongDelayed,
  FeedbackLongDelayedOn: initialUserSettingsForStore.FeedbackLongDelayedOn,
  FeedbackShortDelayed: initialUserSettingsForStore.FeedbackShortDelayed,
  FeedbackShortDelayedOn: initialUserSettingsForStore.FeedbackShortDelayedOn,
  PopupList: initialUserSettingsForStore.PopupList,
  CognitiveSearchEnabled: initialUserSettingsForStore.CognitiveSearchEnabled,
  EntityRecognitionEnabled:
    initialUserSettingsForStore.EntityRecognitionEnabled,
  ShowCognitiveMessage: initialUserSettingsForStore.ShowCognitiveMessage,
  ForceLeftWidget: null,
  ForceRightWidget: null,
  DisabledWidgets: initialUserSettingsForStore.DisabledWidgets,
  OpenLinksInNewTab: initialUserSettingsForStore.OpenLinksInNewTab,
  SimulateAPIError: null
})

const initialUserSettingsForStore: IUserSettingStore = {
  id: '',
  Version: findVersions.version,
  EnablePrefiltering: true,
  EnableWidgetPrefiltering: true,
  PlayTour: true,
  StorageVersion: findVersions.userSettings,
  Country: '',
  FirstLogin: 0,
  City: '',
  Function: '',
  Department: '',
  PinFilters: true,
  Language: '',
  LanguageManuallySeleted: false,
  DataSourceOrder: '',
  DataSourceDisabled: '',
  IntroductionShown: false,
  FeedbackShortShowed: false,
  FeedbackLongShowed: false,
  PremiumEnabled: false,
  TrackingAccepted: false,
  LastVisits: [],
  FeedbackShortShownTime: 0,
  FeedbackLongDelayed: 0,
  FeedbackLongDelayedOn: 0,
  FeedbackShortDelayed: 0,
  FeedbackShortDelayedOn: 0,
  PopupList: [],
  CognitiveSearchEnabled: false,
  EntityRecognitionEnabled: false,
  ShowCognitiveMessage: true,
  DisabledWidgets: [],
  OpenLinksInNewTab: false
}

export function checkPremiumEnabled(country: string): boolean {
  return Constants.statistaPremiumCountries.includes(country)
}

export function initializeUserSettings(userSettings: IUserSetting): boolean {
  let updateUserSettingsNeeded = false

  // Populate EnablePrefiltering
  if (userSettings.EnablePrefiltering === undefined) {
    userSettings.EnablePrefiltering = isValidPrefilterCountry(
      userSettings.Country
    )
    updateUserSettingsNeeded = true
  }

  // Populate premium enabled if undefined
  if (userSettings.PremiumEnabled === undefined) {
    userSettings.PremiumEnabled = checkPremiumEnabled(userSettings.Country)
    updateUserSettingsNeeded = true
  }

  // After this is done check all userSettings properties
  // If the values are not defined set them to default values
  let key: keyof IUserSettingStore
  for (key in initialUserSettingsForStore) {
    if (userSettings[key] === undefined) {
      // eslint-disable-next-line @typescript-eslint/no-extra-semi
      ;(userSettings as any)[key] = initialUserSettingsForStore[key]
      updateUserSettingsNeeded = true
    }
  }

  return updateUserSettingsNeeded
}

export function updateAdditionalUserSettingProperties(
  userSettings: IUserSetting
): void {
  KPMGFindGlobalVariables.setUserName(userSettings.upn)
  KPMGFindGlobalVariables.setPersonInfoCountry(userSettings.Country)
  KPMGFindGlobalVariables.setPersonInfoCity(userSettings.City)
  KPMGFindGlobalVariables.setPersonInfoFunction(userSettings.Function)
  KPMGFindGlobalVariables.setPersonInfoDepartment(userSettings.Department)
  KPMGFindGlobalVariables.setSimulateAPIError(userSettings.SimulateAPIError)
}

export function getUserSettingsFromLocalStorage(): Promise<IUserSetting | null> {
  return new Promise<IUserSetting | null>(async (resolve) => {
    try {
      const userSettingsFromLocalStorage = localStorage.getItem(
        'userSettings_localStorage'
      )

      if (userSettingsFromLocalStorage) {
        resolve(JSON.parse(userSettingsFromLocalStorage))
      } else {
        resolve(null)
      }
    } catch {
      resolve(null)
    }
  })
}

export function saveUserSettingsToLocalStorage(
  userSettings: IUserSetting
): void {
  try {
    const userSettingsForLocalStore: IUserSettingLocalStore = {
      id: userSettings.id,
      upn: userSettings.upn,
      Version: userSettings.Version,
      EnablePrefiltering: userSettings.EnablePrefiltering,
      EnableWidgetPrefiltering: userSettings.EnableWidgetPrefiltering,
      PlayTour: userSettings.PlayTour,
      StorageVersion: userSettings.StorageVersion,
      Country: userSettings.Country,
      FirstLogin: userSettings.FirstLogin,
      City: userSettings.City,
      Function: userSettings.Function,
      Department: userSettings.Department,
      PinFilters: userSettings.PinFilters,
      Language: userSettings.Language,
      LanguageManuallySeleted: userSettings.LanguageManuallySeleted,
      DataSourceOrder: userSettings.DataSourceOrder,
      DataSourceDisabled: userSettings.DataSourceDisabled,
      IntroductionShown: userSettings.IntroductionShown,
      FeedbackShortShowed: userSettings.FeedbackShortShowed,
      FeedbackLongShowed: userSettings.FeedbackLongShowed,
      PremiumEnabled: userSettings.PremiumEnabled,
      TrackingAccepted: userSettings.TrackingAccepted,
      PictureUrl: userSettings.PictureUrl,
      DisplayName: userSettings.DisplayName,
      FirstName: userSettings.FirstName,
      LastName: userSettings.LastName,
      UseLocalSettings: userSettings.UseLocalSettings,
      ForceNoUpdate: userSettings.ForceNoUpdate,
      LastVisits: userSettings.LastVisits,
      FeedbackShortShownTime: userSettings.FeedbackShortShownTime,
      FeedbackLongDelayed: userSettings.FeedbackLongDelayed,
      FeedbackLongDelayedOn: userSettings.FeedbackLongDelayedOn,
      FeedbackShortDelayed: userSettings.FeedbackShortDelayed,
      FeedbackShortDelayedOn: userSettings.FeedbackShortDelayedOn,
      PopupList: userSettings.PopupList,
      CognitiveSearchEnabled: userSettings.CognitiveSearchEnabled,
      EntityRecognitionEnabled: userSettings.EntityRecognitionEnabled,
      ShowCognitiveMessage: userSettings.ShowCognitiveMessage,
      ForceLeftWidget: userSettings.ForceLeftWidget,
      ForceRightWidget: userSettings.ForceRightWidget,
      DisabledWidgets: userSettings.DisabledWidgets,
      OpenLinksInNewTab: userSettings.OpenLinksInNewTab,
      SimulateAPIError: userSettings.SimulateAPIError
    }

    const userSettingsForLocalStorageString = JSON.stringify(
      userSettingsForLocalStore,
      null,
      2
    )

    localStorage.setItem(
      'userSettings_localStorage',
      userSettingsForLocalStorageString
    )
  } catch {}
}

export function syncAndCleanLocalStorage(
  currentStorageVersion: string,
  userSettings: IUserSetting
): boolean {
  // Check if the current Storage Version is 1.0.0
  // for all other versions this is not needed
  if (currentStorageVersion !== '1.0.0') {
    return false
  }

  // Check if the localStorage version is set,
  // if so sync use settings and remove all other setting values
  if (localStorage.getItem('localStorageVersion')) {
    try {
      const language = localStorage.getItem('language')
      if (language !== null) {
        userSettings.Language = language
        localStorage.removeItem('language')
      }
      const languageManuallySelected = localStorage.getItem(
        'language-manually-selected'
      )
      if (languageManuallySelected !== null) {
        userSettings.LanguageManuallySeleted =
          languageManuallySelected === 'true'
        localStorage.removeItem('language-manually-selected')
      }
      const pinFilters = localStorage.getItem('pin-filters')
      if (pinFilters !== null) {
        userSettings.PinFilters = pinFilters === 'true'
        localStorage.removeItem('pin-filters')
      }
      const dataSourcesOrder = localStorage.getItem('datasources-order')
      if (dataSourcesOrder !== null) {
        userSettings.DataSourceOrder = dataSourcesOrder
        localStorage.removeItem('datasources-order')
      }
      const dataSourcesDisabled = localStorage.getItem('datasources-disabled')
      if (dataSourcesDisabled !== null) {
        userSettings.DataSourceDisabled = dataSourcesDisabled
        localStorage.removeItem('datasources-disabled')
      }
      const enablePrefiltering = localStorage.getItem('enable-prefiltering')
      if (enablePrefiltering !== null) {
        userSettings.EnablePrefiltering = enablePrefiltering === 'true'
        localStorage.removeItem('enable-prefiltering')
      }
      const enableWidgetPrefiltering = localStorage.getItem(
        'enable-widget-prefiltering'
      )
      if (enableWidgetPrefiltering !== null) {
        userSettings.EnableWidgetPrefiltering =
          enableWidgetPrefiltering === 'true'
        localStorage.removeItem('enable-widget-prefiltering')
      }
      const premiumEnabled = localStorage.getItem('premiumEnabled')
      if (premiumEnabled !== null) {
        userSettings.PremiumEnabled = premiumEnabled === 'true'
        localStorage.removeItem('premiumEnabled')
      }
      const shouldPlayTour = localStorage.getItem('should-play-tour')
      if (shouldPlayTour !== null) {
        userSettings.PlayTour = shouldPlayTour === 'true'
        localStorage.removeItem('should-play-tour')
      }
      const trackingAccepted = localStorage.getItem('tracking-accepted')
      if (trackingAccepted !== null) {
        userSettings.TrackingAccepted = trackingAccepted === 'true'
        localStorage.removeItem('tracking-accepted')
      }
      const introductionShown = localStorage.getItem('introduction-shown')
      if (introductionShown !== null) {
        userSettings.IntroductionShown = introductionShown === 'true'
        localStorage.removeItem('introduction-shown')
      }
      const lastVisited = localStorage.getItem('lastVisited')
      if (lastVisited !== null) {
        userSettings.FirstLogin = parseInt(lastVisited)
        localStorage.removeItem('lastVisited')
      }
      const feedbackShortShowed = localStorage.getItem('feedbackShortShowed')
      if (feedbackShortShowed !== null) {
        userSettings.FeedbackShortShowed = feedbackShortShowed === 'true'
        localStorage.removeItem('feedbackShortShowed')
      }
      const feedbackLongShowed = localStorage.getItem('feedbackLongShowed')
      if (feedbackLongShowed !== null) {
        userSettings.FeedbackLongShowed = feedbackLongShowed === 'true'
        localStorage.removeItem('feedbackLongShowed')
      }
    } catch (e) {
      trackException('Error when syncing local storage user settings', e)
    }
    localStorage.removeItem('upgrade-info-seen')
    localStorage.removeItem('person-info')
    localStorage.removeItem('localStorageVersion')
    return true
  }
  return false
}

export function updateLastVisits(
  userSettings: IUserSetting,
  maxSize: number
): void {
  if (!maxSize) {
    return
  }

  //append last visits
  const currentTimestamp = new Date().getTime()
  if (userSettings.LastVisits) {
    userSettings.LastVisits = userSettings.LastVisits.slice(0, maxSize)
    if (userSettings.LastVisits.length >= maxSize) {
      userSettings.LastVisits.shift()
    }
  } else {
    userSettings.LastVisits = []
  }

  userSettings.LastVisits.push(currentTimestamp)
}

export function usePrevious(value: any) {
  const ref = React.useRef(value)

  React.useEffect(() => {
    ref.current = value
  })

  return ref.current
}
