import { Reducer } from 'redux'
import {
  SettingsActionTypes,
  SettingsActions,
  ISetDeviceSettings,
  ISetIsLandscape,
  ISetRenderMobile,
  ISetCurrentDataSources,
  ISetShowSettings,
  IFetchApiStatusSuccess,
  IFetchDataSourceSettingsSuccess,
  IFetchFindConfigurationSuccess,
  ISetUseCognitiveSearch,
  ISetInternalBroadcast
} from './actions'
import { IDeviceSetting } from 'utils/deviceSettings'
import { IDataSource } from 'constants/datasourcesConfiguration'
import { initQueueSettings } from 'utils/apiQueue'
import { deepEqual } from 'utils/objects'

export interface IKPMGFunction {
  id: string
  description: string
}

export interface IFindConfiguration {
  FeedbackRequiredVisits: number
  FeedbackLongWeeksAfterShort: number
  FeedbackMaxDelays: number
  CustomSourceError: boolean
  CognitiveSearchEnabled: boolean
  EntityRecognitionEnabled: boolean
  UseImprovedSharingDialog: boolean
  KPMGFunction: IKPMGFunction[]
  QueryConfiguration: {
    delayContentFetch: boolean
    timeOutValueInMs: number
    delayDataSources: Array<string>
    refetchDataSourcesOnError: boolean
    queueSPOCalls: boolean
    maxConcurrentCalls: number
  }
  PoweredBy: {
    image: string
    link: string
    text: string
    active: boolean
  }
  SeeAllLinks: { [key: string]: string }
  UseMSAnswers: boolean | undefined
  DisabledWidgets: Array<string> | undefined
}

// datasourcesettings are used to override local datasource active property with the ones from Cosmos DB
// currentdatasources are currently only used from the settings page
export interface ISettingsStore {
  deviceSettings: IDeviceSetting
  datasourceSettings: IDataSource[]
  hasDataSourceSettingsBeenFetched: boolean
  currentDataSources: IDataSource[]
  showSettings: boolean
  findConfiguration: IFindConfiguration
  hasFindConfigurationBeenFetched: boolean
  hasFindConfigurationCriticalError: boolean
  hasDatasourceSettingsCriticalError: boolean
  UseCognitiveSearch: boolean
  UseEntitiyRecognition: boolean
  internalBroadcast: string[]
}

const initialFindConfigurationState: IFindConfiguration = {
  FeedbackRequiredVisits: 0,
  FeedbackLongWeeksAfterShort: 0,
  FeedbackMaxDelays: 0,
  CustomSourceError: false,
  CognitiveSearchEnabled: false,
  EntityRecognitionEnabled: false,
  UseImprovedSharingDialog: true,
  KPMGFunction: [],
  QueryConfiguration: {
    delayContentFetch: false,
    timeOutValueInMs: 2000,
    delayDataSources: [],
    refetchDataSourcesOnError: false,
    queueSPOCalls: false,
    maxConcurrentCalls: 3
  },
  PoweredBy: {
    image: '',
    link: '',
    text: '',
    active: false
  },
  SeeAllLinks: {},
  UseMSAnswers: undefined,
  DisabledWidgets: undefined
}

const initialState: ISettingsStore = {
  deviceSettings: {
    isLandscape: false,
    isMobile: false,
    renderMobile: false
  },
  datasourceSettings: [],
  hasDataSourceSettingsBeenFetched: false,
  currentDataSources: [],
  showSettings: false,
  findConfiguration: initialFindConfigurationState,
  hasFindConfigurationBeenFetched: false,
  hasFindConfigurationCriticalError: false,
  hasDatasourceSettingsCriticalError: false,
  UseCognitiveSearch: false,
  UseEntitiyRecognition: false,
  internalBroadcast: []
}

const reducers: Reducer<ISettingsStore, SettingsActions> = (
  state: ISettingsStore = initialState,
  action: SettingsActions | { type: 'INITIALIZE_SETTINGS'; payload: any }
) => {
  switch (action.type) {
    case SettingsActionTypes.FETCH_API_STATUS_SUCCESS:
      const { apiStatus } = (action as IFetchApiStatusSuccess).payload

      return {
        ...state,
        currentDataSources: state.currentDataSources.map((dataSource) => {
          const dataSourceStatus = apiStatus.find(
            (api) => api.apiId === dataSource.apiId
          )

          if (dataSourceStatus) {
            dataSource.healthStatus = dataSourceStatus.healthStatus
          }

          return dataSource
        })
      }
    case SettingsActionTypes.INITIALIZE_SETTINGS:
      if (!(action as any).payload) {
        return initialState
      }
      return state
    case SettingsActionTypes.SET_DEVICE_SETTINGS:
      const { isMobile } = (action as ISetDeviceSettings).payload
      return {
        ...state,
        deviceSettings: {
          ...state.deviceSettings,
          isMobile: isMobile
        }
      }
    case SettingsActionTypes.SET_IS_LANDSCAPE:
      const { isLandscape } = (action as ISetIsLandscape).payload
      return {
        ...state,
        deviceSettings: {
          ...state.deviceSettings,
          isLandscape: isLandscape
        }
      }
    case SettingsActionTypes.SET_RENDER_MOBILE:
      const { renderMobile } = (action as ISetRenderMobile).payload
      return {
        ...state,
        deviceSettings: {
          ...state.deviceSettings,
          renderMobile: renderMobile
        }
      }
    case SettingsActionTypes.SET_CURRENT_DATASOURCES:
      const { currentDataSources } = (action as ISetCurrentDataSources).payload

      // don't overwrite already fetch api status informations
      // this can happen if the datasources get changed after the userinformations are available
      if (state.currentDataSources && state.currentDataSources.length > 0) {
        return {
          ...state,
          currentDataSources: currentDataSources.map((ds: IDataSource) => {
            const alreadyExistingDataSource = state.currentDataSources.find(
              (existingDs: IDataSource) => ds.path === existingDs.path
            )

            if (
              alreadyExistingDataSource &&
              alreadyExistingDataSource.healthStatus
            ) {
              ds.healthStatus = alreadyExistingDataSource.healthStatus
            }

            return ds
          })
        }
      } else {
        return {
          ...state,
          currentDataSources: currentDataSources
        }
      }
    case SettingsActionTypes.SET_SHOW_SETTINGS:
      const { showSettings } = (action as ISetShowSettings).payload
      return {
        ...state,
        showSettings: showSettings
      }
    case SettingsActionTypes.FETCH_DATASOURCE_SETTINGS_REQUEST:
      return {
        ...state,
        datasourceSettings: [],
        hasDataSourceSettingsBeenFetched: false,
        hasDatasourceSettingsCriticalError: false
      }
    case SettingsActionTypes.FETCH_DATASOURCE_SETTINGS_FAILURE:
      return {
        ...state,
        datasourceSettings: [],
        hasDataSourceSettingsBeenFetched: true,
        hasDatasourceSettingsCriticalError: false
      }
    case SettingsActionTypes.FETCH_DATASOURCE_SETTINGS_CRITICAL_ERROR:
      return {
        ...state,
        findConfiguration: initialFindConfigurationState,
        hasFindConfigurationBeenFetched: true,
        hasFindConfigurationCriticalError: true
      }
    case SettingsActionTypes.FETCH_DATASOURCE_SETTINGS_SUCCESS:
      const { datasourceSettings } = (action as IFetchDataSourceSettingsSuccess)
        .payload
      return {
        ...state,
        datasourceSettings: datasourceSettings,
        hasDataSourceSettingsBeenFetched: true,
        hasDatasourceSettingsCriticalError: false
      }
    case SettingsActionTypes.FETCH_FIND_CONFIGURATION_REQUEST:
      return {
        ...state,
        findConfiguration: initialFindConfigurationState,
        hasFindConfigurationBeenFetched: false,
        hasFindConfigurationCriticalError: false
      }
    case SettingsActionTypes.FETCH_FIND_CONFIGURATION_FAILURE:
      return {
        ...state,
        findConfiguration: initialFindConfigurationState,
        hasFindConfigurationBeenFetched: true,
        hasFindConfigurationCriticalError: false
      }
    case SettingsActionTypes.FETCH_FIND_CONFIGURATION_CRITICAL_ERROR:
      return {
        ...state,
        findConfiguration: initialFindConfigurationState,
        hasFindConfigurationBeenFetched: true,
        hasFindConfigurationCriticalError: true
      }
    case SettingsActionTypes.FETCH_FIND_CONFIGURATION_NOT_FOUND:
      return {
        ...state,
        findConfiguration: initialFindConfigurationState,
        hasFindConfigurationBeenFetched: true,
        hasFindConfigurationCriticalError: false
      }
    case SettingsActionTypes.FETCH_FIND_CONFIGURATION_SUCCESS:
      const { findConfigurationResult } = (
        action as IFetchFindConfigurationSuccess
      ).payload

      initQueueSettings(findConfigurationResult)

      return {
        ...state,
        findConfiguration: findConfigurationResult,
        hasFindConfigurationBeenFetched: true,
        hasFindConfigurationCriticalError: false
      }
    case SettingsActionTypes.SET_USE_COGNITIVESEARCH:
      const { useCognitiveSearch, useEntitiyRecognition } = (
        action as ISetUseCognitiveSearch
      ).payload
      return {
        ...state,
        UseCognitiveSearch: useCognitiveSearch,
        UseEntitiyRecognition: useEntitiyRecognition
      }
    case SettingsActionTypes.SET_INTERNAL_BROADCAST:
      const { broadcast } = (action as ISetInternalBroadcast).payload
      let _blockBroadcast = false
      if (state.internalBroadcast.length === broadcast.length) {
        _blockBroadcast = deepEqual(broadcast, state.internalBroadcast)
      }

      if (!_blockBroadcast) {
        return {
          ...state,
          internalBroadcast: broadcast
        }
      } else {
        return state
      }
    default:
      return state
  }
}

export default reducers
