// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react'
import { trackEvents } from 'utils/tracking'
import { KPMGFindGlobalVariables } from 'store/KPMGFindGlobalVariables'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function PageStay(WrappedComponent) {
  return class extends Component {
    constructor(props) {
      super(props)
      this.mounted = 0
      this.unmounted = 0
    }

    componentDidMount() {
      this.mounted = Date.now()
      KPMGFindGlobalVariables.setTabOpenSince(Date.now())
    }

    componentWillUnmount() {
      this.unmounted = Date.now()
      trackEvents('stayOnPage', {
        time: this.unmounted - this.mounted,
        tab: KPMGFindGlobalVariables.getCurrentTab(),
        searchTerm: KPMGFindGlobalVariables.getSearchTerm()
      })
    }

    render() {
      return <WrappedComponent {...this.props} />
    }
  }
}
