import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  Typography
} from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { getStylesConsent } from 'styles/contents/Consent'

export interface ConsentProps {
  open: boolean
  onClose: () => void
}

export default function Consent(props: ConsentProps): JSX.Element {
  const classes = getStylesConsent()
  const { open, onClose } = props

  return (
    <Dialog open={open}>
      <DialogTitle>
        <FormattedMessage
          id="telemetry_information"
          defaultMessage="Telemetry Information"
        />
      </DialogTitle>
      <DialogContent className={classes.container}>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.description}
        >
          <FormattedMessage
            id="consent_message"
            defaultMessage="To optimize the search experience, KPMG Find collects anonymous usage data.
                By using this tool you consent to KPMG creating an anonymous search profile for you."
          />
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          <FormattedMessage
            id="accept_and_proceed"
            defaultMessage="Accept and proceed"
          />
        </Button>
      </DialogActions>
    </Dialog>
  )
}
