import React, {
  Dispatch,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import ReactPlaceholder from 'react-placeholder'
import { Box, Fade } from '@mui/material'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import 'Placeholder.css'
import { Store } from 'store'
import ResultsSourceStore from 'store/ResultsSource'
import ResultMetaDataStore from 'store/ResultMetaData'
import UserSettingsStore from 'store/UserSettings'
import SettingsStore from 'store/Settings'
import PageStay from 'components/HOC/PageStay'
import ErrorPage from 'components/contents/common/ErrorPage'
import TextResult from 'components/contents/results/TextResult'
import ResultsSourceMobile from './ResultsSourceMobile'
import Pagination from '../Pagination'
import { trackException } from 'utils/tracking'
import { useReactRouterQueryStringInterface } from 'utils/useQueryState'
import { authorizeResultPage } from 'utils/authorization'
import createDOMPurify from 'dompurify'
import { dateFormatOptions, dataSourcesTabs } from 'constants/constants'
import SearchSuggestions from 'components/contents/common/SearchSuggestions'
import { capitalizeFirstLetter } from 'utils/string'
import FeaturedResults from './FeaturedResults'
import RightTopWidgetContainer from './widgetscontainer/RightTopWidgetContainer'
import LeftWidgetContainer from './widgetscontainer/LeftWidgetContainer'
import RightWidgetContainer from './widgetscontainer/RightWidgetContainer'
import { getStylesResults } from 'styles/contents/resultpages/Results'
import { useFilter } from 'utils/filters'
import { KPMGFindGlobalVariables } from 'store/KPMGFindGlobalVariables'
import { CognitiveMessage } from '../common/CognitiveMessage'
import { IUserSetting } from 'utils/userSettings'
import { formatDisplayBody } from 'utils/intranet'
import { useLocation } from 'react-router-dom'

export interface ResultsSourceProps {
  appliedFilters: any
}

type AllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  ResultsSourceProps

function ResultsSource(props: AllProps) {
  const {
    featuredResults,
    synonymsApplied,
    results,
    resultsCount,
    hasResultsBeenFetched,
    hasErrorStore,
    userSettings,
    deviceSettings,
    findConfiguration,
    setUserSettings,
    useCognitiveSearch
  } = props

  const DOMPurify = createDOMPurify(window)
  const resultClasses = getStylesResults()
  const { getQueryString } = useReactRouterQueryStringInterface()
  const intl = useIntl()
  const queryStringFromUrl = getQueryString()

  const [currentPage] = useFilter('page', '1')
  const [searchQuery] = useFilter('q')
  const location = useLocation()

  const [cognitiveSearchEnabled, setCognitiveSearchEnabled] =
    useState(useCognitiveSearch)
  const [showCognitiveMessage, setShowCognitiveMessage] = useState(
    userSettings.ShowCognitiveMessage
  )

  useEffect(() => {
    KPMGFindGlobalVariables.setCurrentTab(dataSourcesTabs.source)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(
    () => {
      setCognitiveSearchEnabled(useCognitiveSearch)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [useCognitiveSearch]
  )

  useEffect(
    () => {
      setShowCognitiveMessage(userSettings.ShowCognitiveMessage)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userSettings.ShowCognitiveMessage]
  )

  const setup = useCallback(() => {
    try {
      authorizeResultPage(
        'Source',
        userSettings,
        deviceSettings,
        DOMPurify.sanitize(location && location.search ? location.search : '')
      )
    } catch (error) {
      trackException('Error in fetching results in ResultsSource.tsx', error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryStringFromUrl])

  useEffect(() => {
    setup()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryStringFromUrl])

  const returnAllItems = (type: string) => {
    if (!results || results.length < 1) {
      return []
    }

    const items = []

    try {
      const firstResults = results.length < 4 ? results.length : 3

      if (type === 'first') {
        for (let i = 0; i < firstResults; i++) {
          const currentDate = results[i].date
            ? intl.formatDate(
                DOMPurify.sanitize(results[i].date.replace('Z', '')),
                dateFormatOptions
              )
            : ''
          const currentBody = DOMPurify.sanitize(results[i].body) || ''
          const displayBody = formatDisplayBody(currentDate, currentBody, intl)
          items.push(
            <TextResult
              title={capitalizeFirstLetter(
                DOMPurify.sanitize(results[i].title)
              )}
              body={displayBody}
              url={DOMPurify.sanitize(results[i].url)}
              featured={false}
              showContextMenu={true}
              key={i}
              index={i}
              searchQuery={DOMPurify.sanitize(searchQuery)}
              deviceSettings={deviceSettings}
              synonymsApplied={synonymsApplied}
              timeStamp={results[i].timestamp}
            />
          )
        }
      } else {
        for (let i = firstResults; i < results.length; i++) {
          const currentDate = results[i].date
            ? intl.formatDate(
                DOMPurify.sanitize(results[i].date.replace('Z', '')),
                dateFormatOptions
              )
            : ''
          const currentBody = DOMPurify.sanitize(results[i].body) || ''
          const displayBody = formatDisplayBody(currentDate, currentBody, intl)
          items.push(
            <TextResult
              title={capitalizeFirstLetter(
                DOMPurify.sanitize(results[i].title)
              )}
              body={displayBody}
              url={DOMPurify.sanitize(results[i].url)}
              featured={false}
              showContextMenu={true}
              key={i}
              index={i}
              searchQuery={DOMPurify.sanitize(searchQuery)}
              deviceSettings={deviceSettings}
              synonymsApplied={synonymsApplied}
              timeStamp={results[i].timestamp}
            />
          )
        }
      }
    } catch (error) {
      trackException('Error returning all items in ResultsSource.tsx', error)
    }
    return items
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const firstItems = useMemo(() => returnAllItems('first'), [results, intl])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const restItems = useMemo(() => returnAllItems('rest'), [results, intl])

  return (
    <Box>
      {!hasErrorStore && !!searchQuery && (
        <>
          {deviceSettings.renderMobile ? (
            <ResultsSourceMobile
              searchQuery={searchQuery}
              deviceSettings={deviceSettings}
              userSettings={userSettings}
              findConfigCognitiveSearchEnabled={
                findConfiguration.CognitiveSearchEnabled
              }
              cognitiveSearchEnabled={cognitiveSearchEnabled}
              showCognitiveMessage={showCognitiveMessage}
              setShowCognitiveMessage={setShowCognitiveMessage}
              setUserSettings={setUserSettings}
            />
          ) : (
            <Box className={resultClasses.container}>
              <Box
                id={'con-resultvertical-01'}
                className={resultClasses.resultsContainer}
              >
                <SearchSuggestions
                  wildCardActive={true}
                  resultCount={resultsCount}
                  resultsFetchend={hasResultsBeenFetched}
                />

                <ReactPlaceholder
                  ready={hasResultsBeenFetched ? hasResultsBeenFetched : false}
                  type="text"
                  rows={4}
                  showLoadingAnimation={true}
                  className={resultClasses.placeholder}
                >
                  <Fade
                    in={hasResultsBeenFetched ? hasResultsBeenFetched : false}
                    timeout={600}
                  >
                    <Box id={'con-resultvertical-section-01'}>
                      {cognitiveSearchEnabled &&
                        showCognitiveMessage &&
                        findConfiguration.CognitiveSearchEnabled &&
                        synonymsApplied &&
                        synonymsApplied.length > 0 && (
                          <CognitiveMessage
                            synonymsApplied={synonymsApplied}
                            setShowCognitiveMessage={setShowCognitiveMessage}
                            userSettings={userSettings}
                            setUserSettings={setUserSettings}
                          />
                        )}
                      {results && resultsCount > 0 && (
                        <FeaturedResults featuredResults={featuredResults} />
                      )}
                      {firstItems}
                    </Box>
                  </Fade>
                </ReactPlaceholder>
                {Number(currentPage) === 1 && (
                  <LeftWidgetContainer
                    resultCount={resultsCount}
                    hasResultsBeenFetched={hasResultsBeenFetched}
                  />
                )}
                <ReactPlaceholder
                  ready={hasResultsBeenFetched}
                  type="text"
                  rows={4}
                  showLoadingAnimation={true}
                  className={resultClasses.placeholder}
                >
                  <Fade in={hasResultsBeenFetched} timeout={600}>
                    <Box id={'con-resultvertical-section-02'}>
                      {restItems}
                      {resultsCount != null && resultsCount > 0 && (
                        <Pagination
                          {...props}
                          totalRecords={resultsCount}
                          pageLimit={20}
                          pageNeighbours={4}
                        />
                      )}
                    </Box>
                  </Fade>{' '}
                </ReactPlaceholder>
                <ReactPlaceholder
                  ready={hasResultsBeenFetched ? hasResultsBeenFetched : false}
                  type="text"
                  rows={4}
                  showLoadingAnimation={true}
                  className={resultClasses.placeholder}
                >
                  <Box />
                </ReactPlaceholder>
              </Box>
              <Box className={resultClasses.advertisementContainer}>
                <RightTopWidgetContainer
                  searchQuery={searchQuery}
                  scope={'source'}
                />
                <RightWidgetContainer />
              </Box>
            </Box>
          )}
        </>
      )}

      {hasErrorStore && (
        <ErrorPage
          dataSource="source"
          {...(findConfiguration.CustomSourceError === true
            ? {
                customMessage: {
                  type: 'info',

                  content: (
                    <>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: intl.formatMessage({
                            id: 'error_message_custom_source',
                            defaultMessage:
                              'Sorry, but you seem to <b>lack the permissions</b> required \
                              to access Source via its API. <br /> <br /> This is likely a \
                              side effect of the <b>Source modernization</b> that went \
                              live recently. \
                              <br /> \
                              Once the Source platform reintroduces a working API \
                              interface, this vertical will become operational again. \
                              <br /> <br /> If you believe that you should have API access \
                              already, <b>please consult the KPMG Source team</b> directly \
                              to investigate further.'
                          })
                        }}
                      />
                    </>
                  )
                }
              }
            : {})}
        />
      )}
    </Box>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    featuredResults: ResultsSourceStore.selectors.getFeaturedResults(state),
    synonymsApplied: ResultsSourceStore.selectors.getSynonymsApplied(state),
    results: ResultsSourceStore.selectors.getResults(state),
    resultsCount: ResultsSourceStore.selectors.getResultCount(state),
    executionTime: ResultsSourceStore.selectors.getExecutionTime(state),
    hasResultsBeenFetched:
      ResultsSourceStore.selectors.hasResultsBeenFetched(state),
    hasErrorStore: ResultMetaDataStore.selectors.getDataSourceHasError(
      state,
      'source'
    ),
    userSettings: UserSettingsStore.selectors.getUserSettings(state),
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state),
    findConfiguration: SettingsStore.selectors.getFindConfiguration(state),
    useCognitiveSearch: SettingsStore.selectors.getUseCognitiveSearch(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    setUserSettings: (userSettings: IUserSetting) =>
      dispatch(UserSettingsStore.actions.upSertUserSettings(userSettings))
  }
}

export default PageStay(
  connect(mapStateToProps, mapDispatchToProps)(ResultsSource)
)
