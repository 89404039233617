import React, { useState, useEffect, Dispatch } from 'react'
import { connect } from 'react-redux'
import { Store } from 'store'
import SettingsStore from 'store/Settings'
import { useIntl } from 'react-intl'
import { Box, Grid } from '@mui/material'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp'
import { WidgetKey } from 'constants/widgets'
import { trackWidgetShown } from 'utils/tracking'
import SeeAllButton from './common/SeeAllButton'
import { getStylesRightWidget } from 'styles/contents/resultpages/widgets/RightWidget'
import { getUrlParameterForCurrentFilters, useFilter } from 'utils/filters'
import createDOMPurify from 'dompurify'
import FilterStore from 'store/Filters'
import LinesEllipsis from 'react-lines-ellipsis'
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'
import { IKlardenkerResult } from 'components/models/KlardenkerResult'
import RightKlardenkerWidgetResult from './RightKlardenkerWidgetResult'
import AuthStore from 'store/Auth'

export interface RightKlardenkerWidgetProps {
  searchQuery: any
  results: IKlardenkerResult[]
}

type AllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RightKlardenkerWidgetProps

function RightKlardenkerWidget(props: AllProps): JSX.Element {
  const {
    searchQuery,
    results,
    deviceSettings,
    currentDataSources,
    currentFilters,
    clearTemporaryFilter,
    authToken
  } = props

  const DOMPurify = createDOMPurify(window)
  const rightWidgetClasses = getStylesRightWidget()
  const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis)
  const [itemsShown, setItemsShown] = useState(3)
  const [dataSourceEnabled, setDataSourceEnabled] = useState(false)
  const [currentPage] = useFilter('page', '1')

  const showTen = () => {
    setItemsShown(10)
  }

  const showThree = () => {
    setItemsShown(3)
  }

  React.useEffect(() => {
    showThree()
  }, [currentPage])

  React.useEffect(() => {
    trackWidgetShown({
      widgetType: WidgetKey.klardenker,
      widgetPane: 'right'
    })
  }, [])

  const intl = useIntl()

  const headline = intl.formatMessage({
    id: 'klardenker_on',
    defaultMessage: 'Related Klardenker articles'
  })

  useEffect(() => {
    const newsDatasource = currentDataSources.find(
      (ds) => ds.name.toLowerCase() === WidgetKey.klardenker
    )
    const newsDatasourceEnabled = newsDatasource
      ? newsDatasource.enabled
      : false

    setDataSourceEnabled(newsDatasourceEnabled)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDataSources])

  const seeAllLink = `/results/klardenker/${getUrlParameterForCurrentFilters(
    currentFilters,
    [{ key: 'page', value: '1' }]
  )}`

  return (
    <Box
      id={'con-widget-right-klardenker'}
      className={
        results.length > 3
          ? rightWidgetClasses.widgetAreaExtend
          : rightWidgetClasses.widgetArea
      }
    >
      <h2 className={rightWidgetClasses.widgetHeadlineH2}>
        <ResponsiveEllipsis
          className={rightWidgetClasses.widgetHeadline}
          basedOn="words"
          text={headline}
          maxLine="2"
        />
      </h2>
      {results
        .slice(0, itemsShown)
        .map((item: IKlardenkerResult, index: number) => (
          <RightKlardenkerWidgetResult
            searchQuery={searchQuery}
            item={item}
            index={index}
            deviceSettings={deviceSettings}
            authToken={authToken}
          />
        ))}
      {results.length > 3 && (
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          className={rightWidgetClasses.footerGrid}
        >
          {dataSourceEnabled && results.length >= 10 && (
            <SeeAllButton
              searchQuery={DOMPurify.sanitize(searchQuery)}
              url={seeAllLink}
              widgetKey={WidgetKey.klardenker}
              onClickCallback={clearTemporaryFilter}
            />
          )}
          {itemsShown === 3 && (
            <Grid
              item
              id={'btn-right-widget-down-01'}
              className={
                dataSourceEnabled
                  ? rightWidgetClasses.arrowContainerSeeAll
                  : rightWidgetClasses.arrowContainer
              }
              tabIndex={0}
              onClick={showTen}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  showTen()
                }
              }}
            >
              <KeyboardArrowDown className={rightWidgetClasses.arrowItem} />
            </Grid>
          )}
          {itemsShown === 10 && (
            <Grid
              item
              id={'btn-right-widget-up-01'}
              className={
                dataSourceEnabled
                  ? rightWidgetClasses.arrowContainerSeeAllExpanded
                  : rightWidgetClasses.arrowContainerExpanded
              }
              tabIndex={0}
              onClick={showThree}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  showThree()
                }
              }}
            >
              <KeyboardArrowUp className={rightWidgetClasses.arrowItem} />
            </Grid>
          )}
        </Grid>
      )}
    </Box>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state),
    currentDataSources: SettingsStore.selectors.getCurrentDataSources(state),
    currentFilters: FilterStore.selectors.getCurrentFilters(state),
    authToken: AuthStore.selectors.getAuthToken(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    clearTemporaryFilter: () =>
      dispatch(FilterStore.actions.clearTemporaryFilter())
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RightKlardenkerWidget)
