import { trackException } from 'utils/tracking'
import isURL from 'validator/lib/isURL'

/**
 * Filter filenames from string
 * @param  The title that should be filtered
 */
export function filterFileNames(title: string): string {
  return trim(
    title
      .replace(/_/g, ' ')
      .replace(/\.pptx|\.ppt|\.docx|\.doc|\.xlsx|\.xls|\.pdf/g, '')
  )
}

/**
 * trim strings
 * @param input The input that should be trimed
 */
export function trim(input: string): string {
  return input.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '')
}

/**
 * Truncate a string to a given length after the last full word
 * @param input The input that should be truncated
 * @param length The length to which the input should be truncated
 */
export function truncate(
  input: string,
  length: number,
  appendEllipsis = true
): string {
  const appendix = input.length > length && appendEllipsis ? '...' : ''

  const lastSpaceIndex = (input + ' ').lastIndexOf(' ', length)

  return (
    input.substring(0, lastSpaceIndex > 0 ? lastSpaceIndex : length) + appendix
  )
}

/**
 * Transform a given input string array to first letter uppercase
 * @param input The input that should be transformed
 */
export function capitalizeFirstLetterArray(input: string[]): string[] {
  return input.map((item: string) => capitalizeFirstLetter(item))
}

/**
 * Transform a given input string to first letter uppercase
 * @param input The input that should be transformed
 */
export function capitalizeFirstLetter(input: string): string {
  return input.charAt(0).toUpperCase() + input.substring(1)
}

export function spacesVisualization(input: string, threshold: number): string {
  const index = input.indexOf(' ')
  if (input.length > threshold && (index === -1 || index > threshold)) {
    return input.substring(0, threshold) + ' ' + input.substring(threshold)
  } else {
    return input
  }
}

/**
 * Transform a given input string to title case
 * @param input The input that should be transformed
 */
export function toTitleCase(input: string): string {
  const inputWords = input.toLowerCase().split(' ')
  const transformedWords = []
  for (const word of inputWords) {
    transformedWords.push(capitalizeFirstLetter(word))
  }
  return transformedWords.join(' ')
}

/**
 * Retrieve the initials from a name
 * @param input The name from which to retrieve initials from
 */
export function toInitials(input: string): string {
  let initials = ''
  const components: string[] = input.split(' ')
  if (components.length >= 2) {
    initials = `${components[0][0].toUpperCase()}${components[1][0].toUpperCase()}`
  }
  return initials
}

/**
 * Strip html tags from a given string and normalizes spetial chars.
 * @param input The html string that should be stripped
 */
export function stripHtml(html: string): string {
  if (!html) {
    return ''
  }
  const strippingElement = document.createElement('DIV')
  html = html.replace(/<ddd\/>/g, '...')
  html = html.replace(/\n/g, ' ')
  strippingElement.innerHTML = html
  let input = strippingElement.textContent || strippingElement.innerText || ''
  // Remove all bad encoded received characters
  // https://en.wikipedia.org/wiki/List_of_Unicode_characters
  // From Data Link Escape onward.
  // eslint-disable-next-line no-control-regex
  input = input.replace(/[\u0010-\u001F]/g, '')
  return input
}

/**
 * Generates random Guid, used in nonce and state fields within MS Teams authentication.
 */
export function generateGuid(): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    // eslint-disable-next-line no-mixed-operators
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

/**
 * Builds querystring from map of query parameters
 * @param queryParams Parameters to encode to be set as querystring
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function toQueryString(queryParams: any): string {
  const encodedQueryParams = []
  for (const key in queryParams) {
    encodedQueryParams.push(key + '=' + encodeURIComponent(queryParams[key]))
  }
  return encodedQueryParams.join('&')
}

/**
 * Parse hash parameters into key-value pairs
 */
export function getHashParameters(): { [key: string]: string } {
  const hashParams: { [key: string]: string } = {}
  window.location.hash
    .substr(1)
    .split('&')
    .forEach(function (item) {
      const s = item.split('='),
        k = s[0],
        v = s[1] && decodeURIComponent(s[1])
      hashParams[k] = v
    })
  return hashParams
}

/**
 * Generates the URL Breadcrumbs
 * @param currentTab The current datasource
 * @param url The url
 * @param featured Is featured
 * @param options The options, used for statistics
 */
export function generateUrlBreadcrumbs(
  currentTab: string,
  url: string,
  featured: boolean,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  options?: any
): string {
  try {
    let cleanedURL: string = url

    const blacklistSectionsIntranet =
      /lib\d+|m\d+|list\d+|lists|KPMGDocumentLibrary|SitePages|Pages|DocumentLibrary|SiteAssets|\b\d+\b/i
    const blacklistSectionsSource = /Viewpage.action/i

    if (currentTab === 'source' && !featured) {
      return url
        .split('/')
        .slice(3, 6)
        .filter((section) => !blacklistSectionsSource.test(section))
        .map((word: string) => sanitize(capitalize(word)))
        .join(' › ')
    }

    if (currentTab === 'statistics' && options) {
      return `${options}`
    }

    const domainsToRemove: Array<string> = [
      'https://intra.ema.kpmg.com/sites/',
      'https://intra.aspac.kpmg.com/sites/',
      'https://intra.amr.kpmg.com/sites/',
      'https://intra.stg.kpmg.com/sites/'
    ]

    if (domainsToRemove.every((domain) => url.indexOf(domain) === -1)) {
      if (currentTab === 'intranet' || currentTab === 'source' || featured) {
        let filteredDomain
        //find & remove protocol (http, ftp, etc.) and get hostname
        url.indexOf('//') > -1
          ? (filteredDomain = url.split('/')[2])
          : (filteredDomain = url.split('/')[0])
        //find & remove port number
        filteredDomain = filteredDomain.split(':')[0]
        //find & remove "?"
        filteredDomain = filteredDomain.split('?')[0]
        filteredDomain = filteredDomain.replace('www.', '')
        return filteredDomain
      }
      return url
    }

    domainsToRemove.forEach((domain) => {
      cleanedURL = cleanedURL.replace(domain, '')
    })

    const splittedURL = cleanedURL.split('/')

    if (splittedURL.length > 1) {
      splittedURL.pop()
    }

    const [country, ...sections] = splittedURL

    let capitalizedSections = sections.map(capitalize)

    if (currentTab === 'intranet' || featured) {
      capitalizedSections = capitalizedSections.filter(
        (section) => !blacklistSectionsIntranet.test(section)
      )
    }

    return [country.toUpperCase(), ...capitalizedSections].join(' › ')
  } catch (error) {
    trackException(
      'Error in generateUrlBreadcrubms method in Result.tsx',
      error
    )
    return url
  }
}

/**
 * Parses the input string into a hash
 * @return a hash created from the input string
 */
export function hashString(input: string): number {
  let hash = 0
  for (let i = 0; i < input.length; i++) {
    hash = (Math.imul(31, hash) + input.charCodeAt(i)) | 0
  }
  return hash
}

/**
 * Validates if current location is malformed, replacing the %25 character and encoding the URL
 * @return a string containing the well formed URL or empy string
 */
export function validateCurrentLocation(): string {
  const currentLocation = window.location.href
  if (currentLocation.indexOf('%25') >= 0) {
    return encodeURI(currentLocation.replace('%25', ''))
  } else {
    return ''
  }
}

/**
 * Capitalizes the word given as an input
 * @param word The input word that should be capitalized
 */
function capitalize(word: string): string {
  try {
    const parts = word.split('-')
    return parts
      .map((part) => {
        return part.charAt(0).toUpperCase() + part.substring(1)
      })
      .join('-')
  } catch (error) {
    trackException('Error in capitalize method', error)
    return word
  }
}

/**
 * Capitalizes the first letter of each word in the given string
 * @param str The input string that should be capitalized
 */
export function capitalizeFirstLetterOfEachWord(str: string) {
  const splitStr = str.toLowerCase().split(' ')
  for (let i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
  }
  // Directly return the joined string
  return splitStr.join(' ')
}

/**
 * Sanitizes the word given as an input
 * @param word The input word that should be sanitized
 */
function sanitize(word: string): string {
  return decodeURIComponent(word).split('?')[0].replace(/\+/gm, ' ')
}

/**
 * test url is valid
 * @param url string to check
 * @returns
 */
export function isUrlValid(url: string): boolean {
  if (url) {
    if (validateEmailHref(url)) {
      return true
    }

    return isURL(url, { require_tld: false, require_protocol: true })
  }

  return false
}

const validateEmailHref = (email: string) => {
  return new RegExp(
    /(mailto:\w+)(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  ).test(email)
}
