import { AnyAction } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { Store } from '..'
import AuthStore from 'store/Auth'
import * as Config from 'config'
import { FetchWithCache } from 'utils/api'
import Selectors from './selectors'
import ResultMetaDataStore from 'store/ResultMetaData'
import { IDeviceSetting } from 'utils/deviceSettings'
import { renewAuthorizationToken } from 'utils/token'
import {
  ActionMetaData,
  CustomAction,
  getActionMetaData
} from 'store/extension/customAction'
import { IUserSetting } from 'utils/userSettings'
import { FeaturedResult } from 'components/models/FeaturedResult'

export enum ResultsResearchActionTypes {
  FETCH_REQUEST = 'resultsResearch/FETCH_REQUEST',
  FETCH_SUCCESS = 'resultsResearch/FETCH_SUCCESS',
  FETCH_SUCCESS_COMBINED = 'resultsResearch/FETCH_SUCCESS_COMBINED',
  FETCH_FAILURE = 'resultsResearch/FETCH_FAILURE',
  INITIALIZE_RESULTS_RESEARCH = 'persist/REHYDRATE'
}

export interface IFetchRequest
  extends CustomAction<ResultsResearchActionTypes> {
  payload: {
    resetCombined: boolean
    searchQuery: string
  }
}
export type IFetchFailure = CustomAction<ResultsResearchActionTypes>
export interface IFetchSuccess
  extends CustomAction<ResultsResearchActionTypes> {
  payload: {
    response: any
    featuredResults: FeaturedResult[]
  }
}
export interface IFetchSuccessCombined
  extends CustomAction<ResultsResearchActionTypes> {
  payload: {
    response: any
  }
}

export const fetchRequest = (
  resetCombined: boolean,
  searchQuery: string,
  actionMetaData: ActionMetaData
): IFetchRequest => ({
  type: ResultsResearchActionTypes.FETCH_REQUEST,
  payload: {
    resetCombined: resetCombined,
    searchQuery: searchQuery
  },
  metaData: {
    ...actionMetaData,
    isStartAction: true
  }
})
export const fetchSuccess = (
  response: any = {},
  featuredResults: FeaturedResult[],
  actionMetaData: ActionMetaData
): IFetchSuccess => ({
  type: ResultsResearchActionTypes.FETCH_SUCCESS,
  payload: { response, featuredResults },
  metaData: actionMetaData
})
export const fetchSuccessCombined = (
  response: any = {},
  actionMetaData: ActionMetaData
): IFetchSuccessCombined => ({
  type: ResultsResearchActionTypes.FETCH_SUCCESS_COMBINED,
  payload: { response },
  metaData: actionMetaData
})
export const fetchFailure = (
  actionMetaData: ActionMetaData
): IFetchFailure => ({
  type: ResultsResearchActionTypes.FETCH_FAILURE,
  metaData: actionMetaData
})

export const fetchResultsResearch = (
  searchQuery: string,
  currentPage: number,
  deviceSettings: IDeviceSetting,
  userSettings: IUserSetting
  // eslint-disable-next-line @typescript-eslint/ban-types
): ThunkAction<Promise<void>, Store, {}, AnyAction> => {
  return async (
    // eslint-disable-next-line @typescript-eslint/ban-types
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => Store
  ) => {
    const actionMetaData = getActionMetaData('research')

    dispatch(
      ResultMetaDataStore.actions.fetchRequest('research', actionMetaData)
    )
    const resultsCombinedQuery = Selectors.getResultsCombinedQuery(getState())
    if (resultsCombinedQuery !== searchQuery) {
      if (resultsCombinedQuery === '') {
        dispatch(fetchRequest(false, searchQuery, actionMetaData))
      } else {
        dispatch(fetchRequest(true, searchQuery, actionMetaData))
      }
    } else {
      dispatch(fetchRequest(false, searchQuery, actionMetaData))
    }
    try {
      if (!searchQuery || searchQuery === '' || searchQuery === '""') {
        dispatch(fetchFailure(actionMetaData))
        return
      }

      const t0 = performance.now()

      const apiUrl = `${
        Config.APIM_BASE_URL
      }lexisnexisapi/getsearch?query=${encodeURIComponent(
        searchQuery
      )}&platform='en'&limit=20&processFeaturedResults=${
        (currentPage || 1) === 1 ? true : false
      }&featuredResultsRowLimit=3&origin=KPMGFind&country=${
        userSettings.Country
      }&function=${userSettings.Function}`

      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        return
      }

      const response = await FetchWithCache(apiUrl, {
        method: 'GET',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${esToken}`
        }
      })

      let results
      if (!response || response.hasError || !response.responseJSON) {
        dispatch(
          ResultMetaDataStore.actions.hasError(
            'research',
            response.errorResponse,
            undefined,
            actionMetaData
          )
        )
        dispatch(fetchFailure(actionMetaData))
        return
      } else {
        results = response.responseJSON
      }

      const featuredResults =
        results && results.FeaturedResults ? results.FeaturedResults : []

      const t1 = performance.now()
      const executionTime = t1 - t0
      const titles =
        results && results.items ? results.items.map((i: any) => i.title) : []
      const resultBodies =
        results && results.items
          ? results.items.map((i: any) => i.description)
          : []
      const resultUrls =
        results && results.items ? results.items.map((i: any) => i.link) : []
      const imageUrls =
        results && results.items
          ? results.items.map((i: any) => i.teaser_image_urls[1].src)
          : []

      const resultCount = results.items.length || 0

      dispatch(
        fetchSuccess(
          {
            resultBodies,
            resultUrls,
            executionTime,
            titles,
            imageUrls,
            resultCount: resultCount,
            currentPage: currentPage || 1
          },
          featuredResults,
          actionMetaData
        )
      )
      dispatch(
        ResultMetaDataStore.actions.fetchSuccess(
          'research',
          {
            executionTime: executionTime,
            resultsCount: resultCount
          },
          actionMetaData
        )
      )

      const resultsCombined = Selectors.getResultsCombined(getState())
      if ((currentPage || 1) === 1) {
        dispatch(
          fetchSuccessCombined(
            {
              resultBodies,
              resultUrls,
              titles,
              imageUrls
            },
            actionMetaData
          )
        )
      } else {
        dispatch(
          fetchSuccessCombined(
            {
              resultBodies: [...resultsCombined.resultBodies, ...resultBodies],
              resultUrls: [...resultsCombined.resultUrls, ...resultUrls],
              titles: [...resultsCombined.titles, ...titles],
              imageUrls: [...resultsCombined.imageUrls, ...imageUrls]
            },
            actionMetaData
          )
        )
      }
    } catch (error) {
      dispatch(
        ResultMetaDataStore.actions.hasError(
          'research',
          undefined,
          error,
          actionMetaData
        )
      )
      dispatch(fetchFailure(actionMetaData))
    }
  }
}

export type ResultsResearchActions =
  | IFetchRequest
  | IFetchFailure
  | IFetchFailure
