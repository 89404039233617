import React, { Dispatch, useCallback, useEffect, useMemo } from 'react'
import { Box, Fade } from '@mui/material'
import { useIntl } from 'react-intl'
import ReactPlaceholder from 'react-placeholder'
import { connect } from 'react-redux'
import 'Placeholder.css'
import { Store } from 'store'
import ResultMetaDataStore from 'store/ResultMetaData'
import UserSettingsStore from 'store/UserSettings'
import SettingsStore from 'store/Settings'
import ResultsKnowledgeExchangeStore from 'store/ResultsKnowledgeExchange'
import PageStay from 'components/HOC/PageStay'
import Pagination from '../../Pagination'
import TextResult from 'components/contents/results/TextResult'
import RightTopWidgetContainer from 'components/contents/resultpages/widgetscontainer/RightTopWidgetContainer'
import { trackException } from 'utils/tracking'
import { useReactRouterQueryStringInterface } from 'utils/useQueryState'
import { authorizeResultPage } from 'utils/authorization'
import createDOMPurify from 'dompurify'
import { dataSourcesTabs, dateFormatOptions } from 'constants/constants'
import SearchSuggestions from 'components/contents/common/SearchSuggestions'
import ResultsKnowledgeExchangeMobile from './ResultCatalogMobile'
import FeaturedResults from '../FeaturedResults'
import LeftWidgetContainer from '../widgetscontainer/LeftWidgetContainer'
import RightWidgetContainer from '../widgetscontainer/RightWidgetContainer'
import { getStylesResults } from 'styles/contents/resultpages/Results'
import { useFilter } from 'utils/filters'
import { KPMGFindGlobalVariables } from 'store/KPMGFindGlobalVariables'
import { useLocation } from 'react-router-dom'
import KxFilterBar from './KxFilterBar'
import { IUserSetting } from 'utils/userSettings'
import { IDeviceSetting } from 'utils/deviceSettings'
import {
  IKnowledgeExchangeQueryParams,
  generateKnowledgeExchangeQueryParams
} from 'utils/knowledgeExchange'
import queryString from 'query-string'
import ErrorPage from 'components/contents/common/ErrorPage'

export interface ResultCatalogProps {
  searchTerm: string
  datasources: any
}

type AllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  ResultCatalogProps

function ResultCatalog(props: AllProps) {
  const {
    featuredResults,
    results,
    resultsCount,
    hasResultsBeenFetched,
    hasErrorStore,
    userSettings,
    deviceSettings,
    datasources,
    fetchResultsKnowledgeExchange
  } = props
  const DOMPurify = createDOMPurify(window)
  const resultClasses = getStylesResults()
  const { getQueryString } = useReactRouterQueryStringInterface()
  const intl = useIntl()
  const queryStringFromUrl = getQueryString()

  const [currentPage] = useFilter('page', '1')
  const [searchQuery] = useFilter('q')
  const location = useLocation()

  useEffect(() => {
    KPMGFindGlobalVariables.setCurrentTab(
      dataSourcesTabs.knowledgeexchangecatalog
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setup = useCallback(
    async (searchQuery: string, currPage: number) => {
      authorizeResultPage(
        'KnowledgeExchange',
        userSettings,
        deviceSettings,
        DOMPurify.sanitize(location && location.search ? location.search : '')
      )

      const queryParams =
        queryString.parse(DOMPurify.sanitize(queryStringFromUrl)) || {}

      const kxParams: IKnowledgeExchangeQueryParams = {
        searchQuery: searchQuery,
        page: currPage
      }

      generateKnowledgeExchangeQueryParams(queryParams, kxParams)

      fetchResultsKnowledgeExchange(
        kxParams.searchQuery,
        kxParams.page,
        deviceSettings,
        userSettings,
        queryParams
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queryStringFromUrl]
  )

  useEffect(() => {
    try {
      setup(
        DOMPurify.sanitize(searchQuery),
        Number(DOMPurify.sanitize(currentPage))
      )
    } catch (error) {
      trackException('Error in fetching results in ResultsCatalog.tsx', error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setup, intl])

  const returnAllItems = (type: string) => {
    // if no results are present, make sure to not render the empty result frames
    if (!results || results.length < 1) {
      return []
    }

    const items = []
    try {
      const firstResults = results.length < 4 ? results.length : 3

      if (type === 'first') {
        for (let i = 0; i < firstResults; i++) {
          items.push(
            <TextResult
              title={results[i].CatTitle}
              body={
                results[i].CatAbstract.trim() === ''
                  ? ''
                  : results[i].CatAbstract.trim().endsWith('.')
                  ? DOMPurify.sanitize(results[i].CatAbstract.trim())
                  : DOMPurify.sanitize(results[i].CatAbstract.trim()) + ' ...'
              }
              displayUrl={`
                ${DOMPurify.sanitize(results[i].CatFunction)}${
                results[i].CatFunction ? ' > ' : ''
              }${DOMPurify.sanitize(results[i].CatDocumentType)}${
                results[i].CatDocumentType ? ' > ' : ''
              }
             ${intl.formatDate(
               DOMPurify.sanitize(results[i].CatDocumentDate),
               dateFormatOptions
             )}`}
              url={DOMPurify.sanitize(results[i].CatURL)}
              featured={false}
              showContextMenu={false}
              disableSharing={true}
              key={i}
              index={i}
              searchQuery={DOMPurify.sanitize(searchQuery)}
              deviceSettings={deviceSettings}
              openInWeb={
                results[i].CatFileType &&
                results[i].CatFileType.toLowerCase() !== 'pdf'
                  ? true
                  : false
              }
            />
          )
        }
      } else {
        for (let i = firstResults; i < results.length; i++) {
          items.push(
            <TextResult
              title={DOMPurify.sanitize(results[i].CatTitle)}
              body={
                results[i].CatAbstract.trim() === ''
                  ? ''
                  : results[i].CatAbstract.trim().endsWith('.')
                  ? DOMPurify.sanitize(results[i].CatAbstract.trim())
                  : DOMPurify.sanitize(results[i].CatAbstract.trim()) + ' ...'
              }
              displayUrl={`
                ${DOMPurify.sanitize(results[i].CatFunction)}${
                results[i].CatFunction ? ' > ' : ''
              }${DOMPurify.sanitize(results[i].CatDocumentType)}${
                results[i].CatDocumentType ? ' > ' : ''
              }
             ${intl.formatDate(
               DOMPurify.sanitize(results[i].CatDocumentDate),
               dateFormatOptions
             )}`}
              url={DOMPurify.sanitize(results[i].CatURL)}
              featured={false}
              showContextMenu={false}
              disableSharing={true}
              key={i}
              index={i}
              searchQuery={DOMPurify.sanitize(searchQuery)}
              deviceSettings={deviceSettings}
              openInWeb={
                results[i].CatFileType &&
                results[i].CatFileType.toLowerCase() !== 'pdf'
                  ? true
                  : false
              }
            />
          )
        }
      }
    } catch (error) {
      trackException(
        'Error in returning all items in ResultsKnowledgeExchange.tsx',
        error
      )
    }
    return items
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const firstItems = useMemo(() => returnAllItems('first'), [results, intl])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const restItems = useMemo(() => returnAllItems('rest'), [results, intl])

  return (
    <Box>
      {!hasErrorStore && !!searchQuery && (
        <>
          {deviceSettings.renderMobile ? (
            <ResultsKnowledgeExchangeMobile
              {...props}
              searchQuery={searchQuery}
              deviceSettings={deviceSettings}
            />
          ) : (
            <Box className={resultClasses.container}>
              <Box
                id={'con-resultvertical-01'}
                className={resultClasses.resultsContainer}
              >
                <SearchSuggestions
                  wildCardActive={true}
                  resultCount={resultsCount}
                  resultsFetchend={hasResultsBeenFetched}
                />
                <KxFilterBar
                  {...props}
                  scope={'Catalog'}
                  datasources={datasources}
                />
                <ReactPlaceholder
                  ready={hasResultsBeenFetched || Number(currentPage) > 1}
                  type="text"
                  rows={4}
                  showLoadingAnimation={true}
                  className={resultClasses.placeholder}
                >
                  <Fade
                    in={hasResultsBeenFetched || Number(currentPage) > 1}
                    timeout={600}
                  >
                    <Box id={'con-resultvertical-section-01'}>
                      {results && resultsCount > 0 && (
                        <FeaturedResults featuredResults={featuredResults} />
                      )}
                      {firstItems}
                    </Box>
                  </Fade>
                </ReactPlaceholder>
                <LeftWidgetContainer
                  resultCount={resultsCount}
                  hasResultsBeenFetched={hasResultsBeenFetched}
                />
                <ReactPlaceholder
                  ready={hasResultsBeenFetched || Number(currentPage) > 1}
                  type="text"
                  rows={4}
                  showLoadingAnimation={true}
                  className={resultClasses.placeholder}
                >
                  <Fade
                    in={hasResultsBeenFetched || Number(currentPage) > 1}
                    timeout={600}
                  >
                    <Box id={'con-resultvertical-section-02'}>
                      {restItems}
                      {resultsCount != null && resultsCount > 0 && (
                        <Pagination
                          {...props}
                          totalRecords={resultsCount}
                          pageLimit={20}
                          pageNeighbours={4}
                          loadMore={true}
                        />
                      )}
                    </Box>
                  </Fade>
                </ReactPlaceholder>
                <ReactPlaceholder
                  ready={hasResultsBeenFetched}
                  type="text"
                  rows={4}
                  showLoadingAnimation={true}
                  className={resultClasses.placeholder}
                >
                  <Box />
                </ReactPlaceholder>
                <ReactPlaceholder
                  ready={hasResultsBeenFetched}
                  type="text"
                  rows={4}
                  showLoadingAnimation={true}
                  className={resultClasses.placeholder}
                >
                  <Box />
                </ReactPlaceholder>
              </Box>
              <Box className={resultClasses.advertisementContainer}>
                <RightTopWidgetContainer
                  searchQuery={searchQuery}
                  scope={'knowledgeexchangecatalog'}
                />
                <RightWidgetContainer />
              </Box>
            </Box>
          )}
        </>
      )}
      {hasErrorStore && (
        <ErrorPage dataSource="knowledgeexchange" origin={'catalog'} />
      )}
    </Box>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    featuredResults: ResultsKnowledgeExchangeStore.selectors.getFeatureResults(
      state,
      'catalog'
    ),
    results: ResultsKnowledgeExchangeStore.selectors.getResultsCombined(
      state,
      'catalog'
    ),
    resultsCount: ResultsKnowledgeExchangeStore.selectors.getResultCount(
      state,
      'catalog'
    ),
    executionTime: ResultsKnowledgeExchangeStore.selectors.getExecutionTime(
      state,
      'catalog'
    ),
    hasResultsBeenFetched:
      ResultsKnowledgeExchangeStore.selectors.hasResultsBeenFetched(
        state,
        'catalog'
      ),
    hasErrorStore: ResultMetaDataStore.selectors.getDataSourceHasError(
      state,
      'knowledgeexchangecatalog'
    ),
    userSettings: UserSettingsStore.selectors.getUserSettings(state),
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    fetchResultsKnowledgeExchange: (
      searchQuery: string,
      currentPage: number,
      deviceSettings: IDeviceSetting,
      userSettings: IUserSetting,
      filters: any
    ) =>
      dispatch(
        ResultsKnowledgeExchangeStore.actions.fetchResultsKnowledgeExchange(
          searchQuery,
          currentPage,
          deviceSettings,
          filters,
          'catalog',
          userSettings
        )
      ),
    setUserSettings: (userSettings: IUserSetting) =>
      dispatch(UserSettingsStore.actions.upSertUserSettings(userSettings))
  }
}

export default PageStay(
  connect(mapStateToProps, mapDispatchToProps)(ResultCatalog)
)
