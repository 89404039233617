import { emptyGuid } from 'constants/constants'

export const getShareDialogUrl = (
  spWebUrl: string | undefined,
  listId: string | undefined,
  listItemId: string | undefined
): string => {
  let shareDialogUrl = ''

  let originPath = window.location.origin

  if (!originPath) {
    originPath = window.location.protocol + '//' + window.location.hostname
  }

  shareDialogUrl =
    spWebUrl &&
    listId &&
    listId !== emptyGuid &&
    listItemId &&
    spWebUrl.startsWith(originPath)
      ? `${spWebUrl}/_layouts/15/sharedialog.aspx?listId={${listId}}&listItemId=${listItemId}&clientId=sharePoint&ma=0`
      : ''

  return shareDialogUrl
}
export const getFileNameFromUrl = (url: string) => {
  return url.substring(url.lastIndexOf('/') + 1)
}
