export type OpenSourceResponse = {
  '@odata.type': string
  createdDateTime: string
  id: string
  lastModifiedDateTime: string
  name: string
  size: number
  webUrl: string
  parentReference: {
    driveId: string
    sharepointIds: {
      listId: string
      listItemId: string
      listItemUniqueId: string
    }
  }
}

export type CatalogSourceResponse = {
  '@odata.type': string
  createdDateTime: string
  id: string
  lastModifiedDateTime: string
  receivedDateTime: string
  sentDateTime: string
  internetMessageId: string
  subject: string
  bodyPreview: string
  webLink: string
  sender: {
    emailAddress: {
      address: string
      name: string
    }
  }
  hasAttachments: boolean
}

export type HitsResponse<T> = {
  hitId: string
  rank: number
  summary?: string
  resource: T
}

export type HitsContainerResponse<T> = {
  total: number
  moreResultsAvailable: boolean
  hits: HitsResponse<T>[]
}

export type SearchResponse<T> = {
  hitsContainers: Array<HitsContainerResponse<T>>
}

export type KnowledgeExchangeOpenResponse = {
  value: Array<SearchResponse<OpenSourceResponse>>
}
export type KnowledgeCatalogResponse = {
  value: Array<SearchResponse<CatalogSourceResponse>>
}

export const extractResult = (
  results: KnowledgeExchangeOpenResponse | KnowledgeCatalogResponse
): HitsContainerResponse<any> | null => {
  // Exeptions are logged in case the value or the hitsContainers are null or undefined
  // so check if the results contains a value
  if (!results || !results.value) {
    return null
  }

  const hitsContainer = results.value[0]
  if (!hitsContainer || !hitsContainer.hitsContainers) {
    return null
  }

  const hits = hitsContainer.hitsContainers[0]
  if (!hits) {
    return null
  }

  return hits
}
export interface IKnowledgeExchangeQueryParams {
  searchQuery: string
  page: number
}

export const generateKnowledgeExchangeQueryParams = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  queryParams: any,
  initialQueryParams: IKnowledgeExchangeQueryParams
): void => {
  try {
    initialQueryParams.page = queryParams.page
      ? parseInt(queryParams.page)
      : initialQueryParams.page
    initialQueryParams.searchQuery =
      queryParams && queryParams.q
        ? queryParams.q
        : initialQueryParams.searchQuery
        ? initialQueryParams.searchQuery
        : ''
  } catch {}
}
