import React, { Dispatch, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl'
import { Grid, Typography, Slide } from '@mui/material'
import PinImage from 'images/round-pin.svg'
import PinOnImage from 'images/round-pin-on.svg'
import queryString from 'query-string'
import FilterMenu from './FilterMenu'
import FilterStore from 'store/Filters'
import { Store } from 'store'
import ResultsNewsStore from 'store/ResultsNews'
import ResultsMppStore from 'store/ResultsMpp'
import ResultsPeopleStore from 'store/ResultsPeople'
import SettingsStore from 'store/Settings'
import ResultsIbfdStore from 'store/ResultsIbfd'
import ResultsKpmgUSStore from 'store/ResultsKpmgUS'
import { useReactRouterQueryStringInterface } from 'utils/useQueryState'
import {
  isValidPrefilterCountry,
  getCountryNameFromKey,
  getCountryKeyFromName,
  areDatasourceFilterSet,
  useFilter
} from 'utils/filters'
import { getCurrentScope, getFilterOptions } from 'utils/queryParams'
import UserSettingsStore from 'store/UserSettings'

import { dataSourcesTabs } from 'constants/constants'
import { IUserSetting } from 'utils/userSettings'
import { IDeviceSetting } from 'utils/deviceSettings'
import { KPMGFindGlobalVariables } from 'store/KPMGFindGlobalVariables'
import { getTemporaryFiltersAsKeyValue } from 'utils/filtersAsKeyValue'
import FilterButton from './FilterButton'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import ResultsKnowledgeExchange from 'store/ResultsKnowledgeExchange'

export interface FilterSubMenuProps {
  recentFilters: { [key: string]: string }
  setRecentFilters: Dispatch<any>
  clearRecentFilters: Dispatch<any>
  setCurrentPage: Dispatch<any>
  showLocalFilter: boolean
  deviceSettings: IDeviceSetting
  currentResultMeta: any
  setPinned: Dispatch<any>
  pinned: boolean
  setShowFilters: Dispatch<any>
  showFilters: boolean
  classes: any
}

type AllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  FilterSubMenuProps

function FilterBarSubMenu(props: AllProps) {
  const {
    recentFilters,
    setRecentFilters,
    clearRecentFilters,
    defaultFilterOptions,
    userSettings,
    showLocalFilter,
    setUserSettings,
    deviceSettings,
    currentResultMeta,
    setPinned,
    pinned,
    setShowFilters,
    showFilters,
    classes,
    modifiedRangeFilters,
    setCurrentFilter,
    temporaryFilters,
    clearTemporaryFilter,
    news,
    kpmgMpp,
    people,
    ibfd,
    kpmgus,
    knowledgeExchange,
    knowledgeexchangecatalog,
    internalBroadcast
  } = props

  const intl = useIntl()
  const scope = getCurrentScope(false)
  const { getQueryString } = useReactRouterQueryStringInterface()
  const queryParams = queryString.parse(getQueryString())

  const [cpfValue, setCpfValue] = useFilter('country', '')
  const [ptkr] = useFilter('ptkr', '')
  const [, setCurrentPageFromUrl] = useFilter('page', '1')
  const [premiumValueFromUrl, setFilterUrlPremiumValue] = useFilter(
    'premium',
    userSettings.PremiumEnabled ? '1' : '0'
  )
  const [modifiedRangeFilter] = useFilter('lastmodified', '')
  const [published] = useFilter('published', '')
  const [searchQuery] = useFilter('q')

  const [showClearAllBtn, setShowClearAllBtn] = useState<boolean>(false)

  const [filters, setFilters] = useState<FilterCategory[]>(
    getFilterOptions(scope, defaultFilterOptions, ptkr)
  )

  const [filtersCombined, setFiltersCombined] = useState<FilterCategory[]>([])

  const prefilterPagesEnabled = ['intranet', 'videos', 'mpp']
  const enablePrefilteringFromLocalStorage = userSettings.EnablePrefiltering

  const setCurrentPage = (page = 0) => {
    setCurrentPageFromUrl(page.toString(), { method: 'push' })
  }

  const [showMoreFilters, setShowMoreFilters] = useState(false)

  useEffect(() => {
    setFilters(getFilterOptions(scope, defaultFilterOptions, ptkr))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFilterOptions, ptkr])

  useEffect(() => {
    if (showMoreFilters) {
      setShowMoreFilters(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scope])

  useEffect(() => {
    const newFilters = getFilterOptions(scope, defaultFilterOptions, ptkr)

    let someFiltersSelected = false
    if (newFilters) {
      for (const filter of newFilters) {
        if (Boolean(queryParams[filter.key])) {
          if (
            !(filter.key === 'country' && enablePrefilteringFromLocalStorage) &&
            filter.key !== 'premium'
          ) {
            setShowFilters(true)
            someFiltersSelected = true
            break
          }
        }
      }
    }

    if (!showLocalFilter && !someFiltersSelected) {
      setShowFilters(false)
    }

    setFilters(newFilters)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scope])

  // Manage CPF, if it is set in usersettings, on initial app mount i.e. page load/reload
  useEffect(() => {
    if (!userSettings) {
      return
    }
    if (enablePrefilteringFromLocalStorage && cpfValue === '') {
      if (userSettings.Country) {
        const countryKey = getCountryKeyFromName(userSettings.Country)
        setCpfValue(countryKey)
      } else {
        setCpfValue('')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSettings.Country])

  useEffect(() => {
    if (
      modifiedRangeFilter &&
      modifiedRangeFilter.indexOf('customRange') !== -1
    ) {
      const rangeString = decodeURIComponent(modifiedRangeFilter).substring(
        modifiedRangeFilter.indexOf('(') + 1,
        modifiedRangeFilter.length - 1
      )

      const splitRangeFilter = rangeString.split(',')

      if (splitRangeFilter.length === 2) {
        modifiedRangeFilters.from = splitRangeFilter[0]
          ? splitRangeFilter[0]
          : null
        modifiedRangeFilters.to = splitRangeFilter[1]
          ? splitRangeFilter[1]
          : null
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modifiedRangeFilter])

  useEffect(() => {
    if (!ptkr || ptkr === '') {
      if (filters.length > 0 && filters[filters.length - 1].key === 'ptkr') {
        filters.pop()
      }

      const _filtersCombined = [...filtersCombined]
      if (
        _filtersCombined.length > 0 &&
        _filtersCombined[_filtersCombined.length - 1].key === 'ptkr'
      ) {
        _filtersCombined.pop()
        setFiltersCombined(_filtersCombined)
      }
    } else {
      if (
        filters[filters.length - 1].key === 'ptkr' &&
        filters[filters.length - 1].options &&
        filters[filters.length - 1].options.length === 1
      ) {
        filters[filters.length - 1].options.pop()
        filters[filters.length - 1].options.push({
          name: ptkr,
          key: ptkr,
          value: ptkr,
          image: '',
          isDefaultFilter: true
        })

        setShowFilters(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ptkr])

  useEffect(() => {
    setShowFilters(showLocalFilter)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showLocalFilter])

  const getCountryPreFilterFromUrl = () => {
    let country = ''

    Object.keys(queryParams).forEach((key) => {
      if (key && 'kpmgdatacountryoforigin'.includes(key) && queryParams[key]) {
        country = queryParams[key] as string
      }
    })

    return getCountryNameFromKey(country)
  }

  const countryFromUrl = getCountryPreFilterFromUrl()
  const _validPreFilterCountry =
    KPMGFindGlobalVariables.getCurrentTab() &&
    KPMGFindGlobalVariables.getCurrentTab() === dataSourcesTabs.oneintranet
      ? isValidPrefilterCountry(countryFromUrl, true)
      : isValidPrefilterCountry(countryFromUrl)

  const validPrefilterCountry =
    userSettings.Country === countryFromUrl &&
    _validPreFilterCountry &&
    !temporaryFilters.find(
      (tF: CurrentFilter) => tF.key === 'country' && tF.value === 'none'
    )

  const countryPrefilterText =
    !!countryFromUrl &&
    userSettings.EnablePrefiltering &&
    validPrefilterCountry &&
    currentResultMeta &&
    currentResultMeta.resultsCount > 0 &&
    scope !== 'people' &&
    (!KPMGFindGlobalVariables.getCurrentTab() ||
      prefilterPagesEnabled.includes(
        KPMGFindGlobalVariables.getCurrentTab()
      )) ? (
      <div className={classes.result}>
        {!deviceSettings.renderMobile ? ', ' : ''}
        <FormattedMessage
          id="reset_filter_text"
          defaultMessage="showing results for"
        />{' '}
        {intl.formatMessage({
          id: countryFromUrl.toLowerCase().replace(/\s/g, '_'),
          defaultMessage: countryFromUrl
        })}{' '}
        (
        <div
          className={classes.resetButton}
          onClick={() => {
            setCpfValue('')
            setCurrentPage(1)
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              setCpfValue('')
              setCurrentPage(1)
            }
          }}
          tabIndex={0}
        >
          <FormattedMessage
            id="reset_filter_button"
            defaultMessage="show all results"
          />
        </div>
        )
      </div>
    ) : null

  let scopeFix: string = scope as string
  if (scopeFix === 'kpmg mpp') scopeFix = 'kpmgMpp'
  else if (scopeFix === 'kpmg.us') scopeFix = 'kpmgus'
  else if (scopeFix === 'knowledgeexchangeopen') scopeFix = 'knowledgeExchange'

  //combine static & api loaded filters
  const buildCombinedFilters = () => {
    let _filtersCombined: any
    if (filters) {
      _filtersCombined = filters.map(
        (filter: FilterCategory, index: number) => {
          return (filter = {
            ...filter,
            options: !filter.optionsKey
              ? filter.options
              : scopeFix === 'news' ||
                  scopeFix === 'kpmgMpp' ||
                  scopeFix === 'people' ||
                  scopeFix === 'ibfd' ||
                  scopeFix === 'kpmgus' ||
                  scopeFix === 'knowledgeExchange' ||
                  scopeFix === 'knowledgeexchangecatalog'
                ? (props as any)[scopeFix] &&
                  (props as any)[scopeFix][filter.optionsKey]
                  ? [...(props as any)[scopeFix][filter.optionsKey]]
                  : []
                : []
          })
        }
      )

      if (
        scopeFix === 'news' ||
        scopeFix === 'kpmgMpp' ||
        scopeFix === 'people' ||
        scopeFix === 'ibfd' ||
        scopeFix === 'kpmgus' ||
        scopeFix === 'knowledgeExchange' ||
        scopeFix === 'knowledgeexchangecatalog'
      ) {
        if ((props as any)[scopeFix]) {
          const isUpdating =
            !(props as any)[scopeFix].hasResultsBeenFetched &&
            !(props as any)[scopeFix].hasError

          _filtersCombined.forEach((filter: FilterCategory) => {
            if (
              filter.optionsKey &&
              (props as any)[scopeFix][filter.optionsKey]
            ) {
              filter.isUpdating = isUpdating
            }
          })
        }
      }
    }
    return _filtersCombined
  }

  useEffect(() => {
    setFiltersCombined(buildCombinedFilters())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filters,
    scopeFix,
    news,
    kpmgMpp,
    people,
    ibfd,
    kpmgus,
    knowledgeExchange,
    knowledgeexchangecatalog
  ])

  const [selectedFilterList, setSelectedFilterList] = React.useState<string[]>(
    new Array(buildCombinedFilters().length)
  )

  const setSelectedFilter = (value: string | string[], filterKey: string) => {
    const index = filters.findIndex(
      (filter: FilterCategory) => filter.key === filterKey
    )
    if (Array.isArray(value)) {
      selectedFilterList[index] = value.join()
    } else {
      if (value && value.indexOf('customRange(') !== -1) {
        value = value.substring(0, value.indexOf('('))
      }
      selectedFilterList[index] = value
    }

    const copyOfSlectedFilters = new Array(filtersCombined.length)
    selectedFilterList.forEach((f: any, i: number) => {
      copyOfSlectedFilters[i] = f
    })

    setSelectedFilterList(copyOfSlectedFilters)
  }

  useEffect(() => {
    const parsedQueryString = queryString.parse(getQueryString()) || {}

    if (temporaryFilters && temporaryFilters.length > 0) {
      setShowClearAllBtn(
        areDatasourceFilterSet(
          getTemporaryFiltersAsKeyValue(),
          filtersCombined,
          userSettings.EnablePrefiltering,
          ['premium']
        )
      )
    } else if (filters) {
      setShowClearAllBtn(
        areDatasourceFilterSet(
          parsedQueryString as { [key: string]: string },
          filtersCombined,
          userSettings.EnablePrefiltering,
          ['premium']
        )
      )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scope, searchQuery, queryParams, filtersCombined, temporaryFilters])

  const clearAllDatasourceFilter = () => {
    let changed = false
    const deleteFilter: CurrentFilter[] = []
    const _filtersCombined = [...filtersCombined]

    let containsPtkr = false
    for (const filter of filters) {
      if (filter.key !== 'premium') {
        deleteFilter.push({ key: filter.key, value: '' })
        changed = true
      }
      if (filter.key === 'ptkr') {
        containsPtkr = true
      }
    }

    // Delete ptkr filter when clearing
    if (containsPtkr) {
      _filtersCombined.pop()
      filters.pop()
      setFiltersCombined(_filtersCombined)
    }

    // Delete published filter
    if (published) {
      deleteFilter.push({ key: 'published', value: '' })
      changed = true
    }

    // Additionally reset the last modified custom filter values
    modifiedRangeFilters.from = null
    modifiedRangeFilters.to = null

    if (changed) {
      setSelectedFilterList(Array(_filtersCombined.length))
      deleteFilter.push({ key: 'page', value: '1' })
      setCurrentFilter(deleteFilter)
    }
  }

  const setPremiumEnabled = (enabled: boolean) => {
    if (userSettings.PremiumEnabled !== enabled) {
      userSettings.PremiumEnabled = enabled
      setUserSettings(userSettings)
      setFilterUrlPremiumValue(userSettings.PremiumEnabled ? '1' : '0')
    }
  }

  const maxFilterLength = 5
  const showAllFilterMenu =
    filtersCombined && filtersCombined.length > maxFilterLength

  return (
    <>
      <Grid
        container
        direction="row"
        style={{ width: 'auto' }}
        className={`${classes.resultContainer} ${
          !currentResultMeta ||
          currentResultMeta.resultsCount < 0 ||
          countryPrefilterText
            ? classes.resultContainerVisible
            : ''
        } ${
          !deviceSettings.renderMobile
            ? showMoreFilters && (showFilters || pinned)
              ? classes.resultContainerOnDesktopExtended
              : classes.resultContainerOnDesktop
            : ''
        }`}
      >
        <Slide
          direction="up"
          in={!showFilters && !pinned}
          mountOnEnter
          unmountOnExit
        >
          <Grid
            container
            direction="row"
            style={{ width: 'auto', height: '50px' }}
          >
            <Typography
              id={'txt-foundresults-01'}
              className={`${classes.result}`}
              component="div"
              style={{ marginLeft: '10px' }}
            >
              {!!searchQuery &&
                currentResultMeta &&
                currentResultMeta.hasError === false &&
                currentResultMeta.resultsCount < 0 && (
                  <FormattedMessage
                    id="loading_results"
                    defaultMessage="Loading results..."
                  />
                )}
              {!!searchQuery &&
                currentResultMeta &&
                currentResultMeta.hasError === false &&
                currentResultMeta.resultsCount > 0 &&
                !deviceSettings.renderMobile && (
                  <>
                    {currentResultMeta.isUncertainCount
                      ? '>'
                      : intl.formatMessage({
                          id: 'about',
                          defaultMessage: 'About'
                        })}{' '}
                    <FormattedNumber
                      value={parseInt(currentResultMeta.resultsCount)}
                    />{' '}
                    {intl.formatMessage({
                      id: 'results',
                      defaultMessage: 'results'
                    })}{' '}
                    ({(currentResultMeta.executionTime / 1000).toFixed(2)}{' '}
                    {intl.formatMessage({
                      id: 'seconds',
                      defaultMessage: 'seconds'
                    })}
                    ){countryPrefilterText}
                  </>
                )}
              {!!searchQuery &&
                currentResultMeta &&
                currentResultMeta.hasError === false &&
                currentResultMeta.resultsCount > 0 &&
                deviceSettings.renderMobile && (
                  <>
                    {!countryPrefilterText ? (
                      <>
                        {currentResultMeta.isUncertainCount
                          ? '>'
                          : intl.formatMessage({
                              id: 'about',
                              defaultMessage: 'About'
                            })}{' '}
                        <FormattedNumber
                          value={currentResultMeta.resultsCount}
                        />{' '}
                        {intl.formatMessage({
                          id: 'results',
                          defaultMessage: 'results'
                        })}{' '}
                        ({(currentResultMeta.executionTime / 1000).toFixed(2)}{' '}
                        {intl.formatMessage({
                          id: 'seconds',
                          defaultMessage: 'seconds'
                        })}
                        )
                      </>
                    ) : (
                      <Typography component="div">
                        {countryPrefilterText}
                      </Typography>
                    )}
                  </>
                )}
            </Typography>
          </Grid>
        </Slide>
        <Slide direction="down" in={showFilters || pinned} mountOnEnter>
          <div id={'con-filter-01'}>
            <Grid item className={classes.toolBox} direction="row">
              {filtersCombined &&
                filtersCombined
                  .slice(
                    0,
                    !deviceSettings.renderMobile
                      ? maxFilterLength
                      : filtersCombined.length
                  )
                  .map((filter: FilterCategory, index: number) => {
                    return (
                      <FilterMenu
                        filter={filter}
                        key={`${scope}-${index}`}
                        recentFilters={recentFilters}
                        setRecentFilters={setRecentFilters}
                        clearRecentFilters={clearRecentFilters}
                        setCurrentPage={setCurrentPage}
                        scope={scope}
                        userSettings={userSettings}
                        premiumEnabled={
                          premiumValueFromUrl === '1' ? true : false
                        }
                        setPremiumEnabled={setPremiumEnabled}
                        selectedFilter={selectedFilterList[index]}
                        setSelectedFilter={setSelectedFilter}
                        modifiedRangeFilters={modifiedRangeFilters}
                        internalBroadcast={internalBroadcast}
                      />
                    )
                  })}
              {(!filters || filters.length <= 0) && (
                <Typography className={`${classes.placeholder} `}>
                  <FormattedMessage
                    id="no_filters_available"
                    defaultMessage="Currently no filters available"
                  />
                </Typography>
              )}
              {showAllFilterMenu && !deviceSettings.renderMobile && (
                <Grid
                  item
                  className={classes.endFilterIconContainer}
                  tabIndex={0}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      setShowMoreFilters(!showMoreFilters)
                    }
                  }}
                >
                  <FilterButton
                    icon={
                      showMoreFilters ? (
                        <ExpandLess fontSize={'inherit'} />
                      ) : (
                        <ExpandMore fontSize={'inherit'} />
                      )
                    }
                    linkText="more"
                    scope={''}
                    onClick={() => {
                      setShowMoreFilters(!showMoreFilters)
                    }}
                    showActive={filtersCombined.some(
                      (filterCat: FilterCategory, index: number) => {
                        return (
                          index >= maxFilterLength && queryParams[filterCat.key]
                        )
                      }
                    )}
                  />
                </Grid>
              )}
              {showClearAllBtn && (
                <Typography
                  className={classes.clearFilterButton}
                  onClick={() => {
                    if (temporaryFilters && temporaryFilters.length > 0) {
                      clearTemporaryFilter()
                    } else {
                      clearAllDatasourceFilter()
                    }
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      if (temporaryFilters && temporaryFilters.length > 0) {
                        clearTemporaryFilter()
                      } else {
                        clearAllDatasourceFilter()
                      }
                    }
                  }}
                  tabIndex={0}
                >
                  <FormattedMessage id="clear_all" defaultMessage="Clear" />
                </Typography>
              )}

              <img
                alt="pin or unpin"
                className={classes.pinIcon}
                src={pinned ? PinOnImage : PinImage}
                onClick={() => {
                  setPinned(!pinned)
                  setShowFilters(true)
                  userSettings.PinFilters = !userSettings.PinFilters
                  setUserSettings(userSettings)
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    setPinned(!pinned)
                    setShowFilters(true)
                    userSettings.PinFilters = !userSettings.PinFilters
                    setUserSettings(userSettings)
                  }
                }}
                tabIndex={0}
              />
            </Grid>
            {showMoreFilters && !deviceSettings.renderMobile && (
              <Grid item className={classes.toolBoxExtended} direction="row">
                {filtersCombined &&
                  filtersCombined
                    .slice(maxFilterLength)
                    .map((filter: FilterCategory, index: number) => {
                      return (
                        <FilterMenu
                          filter={filter}
                          key={`${scope}-${maxFilterLength + index}`}
                          recentFilters={recentFilters}
                          setRecentFilters={setRecentFilters}
                          clearRecentFilters={clearRecentFilters}
                          setCurrentPage={setCurrentPage}
                          scope={scope}
                          userSettings={userSettings}
                          premiumEnabled={
                            premiumValueFromUrl === '1' ? true : false
                          }
                          setPremiumEnabled={setPremiumEnabled}
                          selectedFilter={
                            selectedFilterList[maxFilterLength + index]
                          }
                          setSelectedFilter={setSelectedFilter}
                          modifiedRangeFilters={modifiedRangeFilters}
                          internalBroadcast={internalBroadcast}
                        />
                      )
                    })}
              </Grid>
            )}
          </div>
        </Slide>
        {/*missingFilterName && (
          <FindNotification
            open={true}
            translationKey={'snackbar_unavailable_filter'}
            translationOption={{ missingFilterName: missingFilterName }}
            defaultMessage={`We are sorry the "${missingFilterName}" filter you applied is not available in this tab.`}
          />
        )*/}
      </Grid>
    </>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    defaultFilterOptions: FilterStore.selectors.getDefaultFilterOptions(state),
    recentFilters: FilterStore.selectors.getRecentFilters(state),
    news: ResultsNewsStore.selectors.getFilters(state),
    kpmgMpp: ResultsMppStore.selectors.getFilters(state),
    ibfd: ResultsIbfdStore.selectors.getFilters(state),
    kpmgus: ResultsKpmgUSStore.selectors.getFilters(state),
    people: ResultsPeopleStore.selectors.getFilters(state),
    userSettings: UserSettingsStore.selectors.getUserSettings(state),
    currentDataSources: SettingsStore.selectors.getCurrentDataSources(state),
    modifiedRangeFilters:
      FilterStore.selectors.getLastModifiedRangeFilters(state),
    temporaryFilters: FilterStore.selectors.getTemporaryFilters(state),
    knowledgeExchange: ResultsKnowledgeExchange.selectors.getFilters(
      state,
      'open'
    ),
    knowledgeexchangecatalog: ResultsKnowledgeExchange.selectors.getFilters(
      state,
      'catalog'
    ),
    internalBroadcast: SettingsStore.selectors.getInternalBroadcast(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    setCurrentFilter: (currentFilter: CurrentFilter[]) =>
      dispatch(FilterStore.actions.setCurrentFilter(currentFilter)),
    setRecentFilters: (filter: { [key: string]: string }) =>
      dispatch(FilterStore.actions.setRecentFilters(filter)),
    clearRecentFilters: (filter: string) =>
      dispatch(FilterStore.actions.clearRecentFilters(filter)),
    setCurrentPage: (currentPage = 0) =>
      dispatch({ type: 'SET_CURRENT_PAGE', payload: currentPage }),
    setUserSettings: (userSettings: IUserSetting) =>
      dispatch(UserSettingsStore.actions.upSertUserSettings(userSettings)),
    clearTemporaryFilter: () =>
      dispatch(FilterStore.actions.clearTemporaryFilter())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterBarSubMenu)
