import React, { useState } from 'react'
import { Fade, Box } from '@mui/material'
import ReactPlaceholder from 'react-placeholder'
import { connect } from 'react-redux'
import 'Placeholder.css'
import { Store } from 'store'
import WidgetStore from 'store/Widgets'
import { getStylesLeftWidgetContainer } from 'styles/contents/resultpages/widgetscontainer/LeftWidgetContainer'
import { useNonInitialEffect } from 'utils/useNonInitialEffect'

export interface LeftWidgetContainerProps {
  resultCount: number
  hasResultsBeenFetched: boolean
}

type AllProps = ReturnType<typeof mapStateToProps> & LeftWidgetContainerProps

function LeftWidgetContainer(props: AllProps) {
  const { widget, resultCount, hasResultsBeenFetched } = props

  //fix, widget component gets rendered (from storage) before the resultpage rendering is complete.
  //prevent unnecessary multiple rendering and event triggers
  const [renderWidget, setRenderWidget] = useState<boolean>(false)
  const classes = getStylesLeftWidgetContainer()

  useNonInitialEffect(() => {
    if (!widget || !widget.rightWidget || !hasResultsBeenFetched)
      setRenderWidget(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widget, hasResultsBeenFetched])

  return (
    <>
      {!widget.leftWidgetHasError && (
        <ReactPlaceholder
          ready={hasResultsBeenFetched && widget && widget.leftWidgetFetched}
          customPlaceholder={
            <Box className={classes.resultFramePlaceholderContainer}>
              <Box className={classes.resultFramePlaceholder} />
              <Box className={classes.resultFramePlaceholder} />
              <Box className={classes.resultFramePlaceholder} />
            </Box>
          }
        >
          {renderWidget &&
            widget.leftWidget &&
            widget.leftWidget.$$typeof !== undefined &&
            widget.leftWidget &&
            widget.leftWidgetFetched &&
            resultCount > 1 && (
              <Fade
                in={widget.leftWidgetFetched && resultCount > 1}
                timeout={600}
              >
                <Box className={classes.leftWidgetContainer}>
                  {widget.leftWidget}
                </Box>
              </Fade>
            )}
        </ReactPlaceholder>
      )}
    </>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    widget: WidgetStore.selectors.getLeftWidget(state)
  }
}

export default connect(mapStateToProps)(LeftWidgetContainer)
