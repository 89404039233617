export interface IQueueTask {
  url: string
  fetchOptions: RequestInit | undefined
  resolve: (value: Response | PromiseLike<Response>) => void
  reject: (reason?: any) => void
  identifier: string
  enterQueueTime: number
  leaveQueueTime?: number
  searchQuery?: string
}

export interface IRunningTask {
  controller: AbortController
  identifier: string
  searchQuery?: string
}

let queueTasks: IQueueTask[] = []
let runningTasks: IRunningTask[] = []
let currentRunningTasksCount = 0

export class ApiQueue {
  //tab & times
  private operations: Array<any> = []
  private runningOperations = 0

  constructor() {
    this.operations = []
    this.runningOperations = 0
    queueTasks = []
    runningTasks = []
    currentRunningTasksCount = 0
  }

  public addTask(task: IQueueTask, first: boolean): Promise<void> {
    return new Promise<void>((resolve) => {
      if (first) {
        queueTasks.unshift(task)
      } else {
        queueTasks.push(task)
      }
      resolve()
    })
  }

  public filterOutTask(
    identifier: string,
    searchQuery: string,
    isCurrentDataSource: boolean
  ): Promise<void> {
    return new Promise<void>((resolve) => {
      if (searchQuery && isCurrentDataSource) {
        queueTasks = queueTasks.filter((task: IQueueTask) => {
          if (
            task.searchQuery !== searchQuery ||
            task.identifier === identifier
          ) {
            return false
          }
          return true
        })
        resolve()
      } else {
        queueTasks = queueTasks.filter((t) => t.identifier !== identifier)
        resolve()
      }
    })
  }

  public takeTasks(maxRunningCount: number): Promise<IQueueTask[]> {
    return new Promise<IQueueTask[]>((resolve) => {
      const availableCount = maxRunningCount - currentRunningTasksCount
      if (availableCount < 1) {
        resolve([])
      }
      const tasksToRun = queueTasks.splice(0, availableCount)
      currentRunningTasksCount += tasksToRun.length
      resolve(tasksToRun)
    })
  }

  public addRunningTask(runningTask: IRunningTask): Promise<void> {
    return new Promise<void>((resolve) => {
      runningTasks.push(runningTask)
      resolve()
    })
  }

  public removeRunningTask(identifier: string): Promise<void> {
    return new Promise<void>((resolve) => {
      runningTasks = runningTasks.filter((t) => t.identifier !== identifier)
      currentRunningTasksCount--
      resolve()
    })
  }

  public getRunningTasks(
    indentifier: string,
    searchQuery: string,
    isCurrentDataSource: boolean
  ): Promise<IRunningTask[]> {
    return new Promise<IRunningTask[]>((resolve) => {
      if (searchQuery && isCurrentDataSource) {
        const result = runningTasks.filter((task: IRunningTask) => {
          if (
            task.identifier === indentifier ||
            task.searchQuery !== searchQuery
          ) {
            return true
          }

          return false
        })
        resolve(result)
      } else {
        const result = runningTasks.find(
          (task: IRunningTask) => task.identifier === indentifier
        )
        resolve(result ? [result] : [])
      }
    })
  }

  public addOperation(operationToCall: any, ...args: any) {
    return new Promise((resolve, reject) => {
      this.operations.push({
        resolve,
        operationToCall,
        args
      })
      this.tryNext()
    })
  }

  private tryNext() {
    if (!this.operations.length) {
      return
    } else if (this.runningOperations < 1) {
      const operation = this.operations.shift()
      this.runningOperations++
      operation
        .operationToCall(...operation.args)
        .then((result: any) => operation.resolve(result))
        .finally(() => {
          this.runningOperations--
          this.tryNext()
        })
    }
  }
}
