import { AnyAction } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { Store } from '..'
import * as Config from 'config'
import { stripHtml } from 'utils/string'
import { trackException } from 'utils/tracking'
import { FetchWithCache } from 'utils/api'
import Selectors from './selectors'
import ResultMetaDataStore from 'store/ResultMetaData'
import { IUserSetting } from 'utils/userSettings'
import { IDeviceSetting } from 'utils/deviceSettings'
import { unifysearchQuery } from 'utils/unifysearchQuery'
import { buildRefinementFilters } from 'utils/filters'
import * as Filters from 'constants/filters'
import { invalidManualFiltersMPP } from 'constants/constants'
import {
  ActionMetaData,
  CustomAction,
  getActionMetaData
} from 'store/extension/customAction'
import { FeaturedResult } from 'components/models/FeaturedResult'
import AuthStore from '../Auth'
import { renewAuthorizationToken } from 'utils/token'
import { availableCountryIcons } from 'constants/countriesISO'
import { IFindConfiguration } from 'store/Settings/reducers'
import { removeStopWordsFromQuery } from 'utils/oneIntranet'
import { ISynonymsApplied } from 'components/models/SynonymsApplied'
import SettingsStore from 'store/Settings'
import { IMppResult } from 'components/models/MppResult'

export enum ResultsMppActionTypes {
  FETCH_REQUEST = 'resultsMpp/FETCH_REQUEST',
  FETCH_SUCCESS = 'resultsMpp/FETCH_SUCCESS',
  FETCH_SUCCESS_COMBINED = 'resultsMpp/FETCH_SUCCESS_COMBINED',
  FETCH_FAILURE = 'resultsMpp/FETCH_FAILURE',
  FETCH_FILTERS_REQUEST = 'resultsMpp/FETCH_FILTERS_REQUEST',
  FETCH_FILTERS_SUCCESS = 'resultsMpp/FETCH_FILTERS_SUCCESS',
  FETCH_FILTERS_FAILURE = 'resultsMpp/FETCH_FILTERS_FAILURE',
  INITIALIZE_RESULTS_MPP = 'persist/REHYDRATE'
}

export interface IFetchRequest extends CustomAction<ResultsMppActionTypes> {
  payload: {
    resetCombined: boolean
    searchQuery: string
  }
}
export type IFetchFailure = CustomAction<ResultsMppActionTypes>
export interface IFetchSuccess extends CustomAction<ResultsMppActionTypes> {
  payload: {
    response: any
    featuredResults: FeaturedResult[]
    synonymsApplied: ISynonymsApplied[]
  }
}
export interface IFetchSuccessCombined
  extends CustomAction<ResultsMppActionTypes> {
  payload: {
    response: any
  }
}
export type IFetchFiltersRequest = CustomAction<ResultsMppActionTypes>
export type IFetchFiltersFailure = CustomAction<ResultsMppActionTypes>
export interface IFetchFiltersSuccess
  extends CustomAction<ResultsMppActionTypes> {
  payload: {
    responseFilters: any
  }
}

export const fetchRequest = (
  resetCombined: boolean,
  searchQuery: string,
  actionMetaData: ActionMetaData
): IFetchRequest => ({
  type: ResultsMppActionTypes.FETCH_REQUEST,
  payload: {
    resetCombined: resetCombined,
    searchQuery: searchQuery
  },
  metaData: {
    ...actionMetaData,
    isStartAction: true
  }
})
export const fetchSuccess = (
  response: any = {},
  featuredResults: FeaturedResult[],
  synonymsApplied: ISynonymsApplied[],
  actionMetaData: ActionMetaData
): IFetchSuccess => ({
  type: ResultsMppActionTypes.FETCH_SUCCESS,
  payload: { response, featuredResults, synonymsApplied },
  metaData: actionMetaData
})
export const fetchSuccessCombined = (
  response: any = {},
  actionMetaData: ActionMetaData
): IFetchSuccessCombined => ({
  type: ResultsMppActionTypes.FETCH_SUCCESS_COMBINED,
  payload: { response },
  metaData: actionMetaData
})
export const fetchFailure = (
  actionMetaData: ActionMetaData
): IFetchFailure => ({
  type: ResultsMppActionTypes.FETCH_FAILURE,
  metaData: actionMetaData
})

export const fetchFiltersRequest = (
  actionMetaData: ActionMetaData
): IFetchFiltersRequest => ({
  type: ResultsMppActionTypes.FETCH_FILTERS_REQUEST,
  metaData: {
    ...actionMetaData,
    isStartAction: true
  }
})
export const fetchFiltersSuccess = (
  responseFilters: any = {},
  actionMetaData: ActionMetaData
): IFetchFiltersSuccess => ({
  type: ResultsMppActionTypes.FETCH_FILTERS_SUCCESS,
  payload: { responseFilters },
  metaData: actionMetaData
})
export const fetchFiltersFailure = (
  actionMetaData: ActionMetaData
): IFetchFiltersFailure => ({
  type: ResultsMppActionTypes.FETCH_FILTERS_FAILURE,
  metaData: actionMetaData
})

export const fetchResultsMpp = (
  searchQuery: string,
  currentPage: number,
  userSettings: IUserSetting,
  deviceSettings: IDeviceSetting,
  filters: {
    [key: string]: string
  },
  findConfiguration: IFindConfiguration
  // eslint-disable-next-line @typescript-eslint/ban-types
): ThunkAction<Promise<void>, Store, {}, AnyAction> => {
  return async (
    // eslint-disable-next-line @typescript-eslint/ban-types
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => Store
  ) => {
    const actionMetaData = getActionMetaData('kpmgmpp')

    dispatch(
      ResultMetaDataStore.actions.fetchRequest('kpmg mpp', actionMetaData)
    )
    const resultsCombinedQuery = Selectors.getResultsCombinedQuery(getState())
    if (resultsCombinedQuery !== searchQuery) {
      if (resultsCombinedQuery === '') {
        dispatch(fetchRequest(false, searchQuery, actionMetaData))
      } else {
        dispatch(fetchRequest(true, searchQuery, actionMetaData))
      }
    } else {
      dispatch(fetchRequest(false, searchQuery, actionMetaData))
    }
    try {
      if (!searchQuery || searchQuery === '' || searchQuery === '""') {
        dispatch(fetchFailure(actionMetaData))
        return
      }

      // Prevent loading if cpf is enabled but not set because of async
      if (
        userSettings.EnablePrefiltering &&
        filters &&
        !filters.country &&
        !userSettings.Country
      ) {
        return
      }

      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        throw new Error('Authentication: Cannot renew authentication token')
      }

      const sliceStartIndex = ((currentPage || 1) - 1) * 20

      //Search?q=searchterm&manual=gqrm&country=de&serviceline=ts&filetype=docx&offset=60&limit=20
      const refinementFilters = buildRefinementFilters(
        filters,
        Filters.kpmg_mpp
      )

      const useCognitiveSearch =
        SettingsStore.selectors.getUseCognitiveSearch(getState())

      let searchQueryLanguage = ''
      if (useCognitiveSearch && findConfiguration.CognitiveSearchEnabled) {
        const detectLanguageApiUrl = `${
          Config.APIM_BASE_URL
        }searchapi/detectlanguage?searchQuery=${encodeURIComponent(
          searchQuery
        )}`

        const languageResponse = await FetchWithCache(detectLanguageApiUrl, {
          method: 'POST',
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
            Authorization: `Bearer ${esToken}`
          }
        })

        if (
          languageResponse &&
          !languageResponse.hasError &&
          languageResponse.responseJSON
        ) {
          const languageJSON = languageResponse.responseJSON
          if (languageJSON && languageJSON.responseCode === 200) {
            searchQueryLanguage = languageJSON.language
          }
        }
      }

      searchQuery = removeStopWordsFromQuery(
        searchQuery,
        searchQueryLanguage,
        useCognitiveSearch,
        findConfiguration.CognitiveSearchEnabled
      )

      let apiUrl =
        `${Config.APIM_BASE_URL}mppapi/getsearch?` +
        `q=${unifysearchQuery(searchQuery, 'mpp')}` +
        `&limit=20` +
        `&offset=${sliceStartIndex}` +
        refinementFilters +
        `&processFeaturedResults=${
          (currentPage || 1) === 1 ? true : false
        }&featuredResultsRowLimit=3&origin=KPMGFind&featuredResultsCountry=${
          userSettings.Country
        }&function=${userSettings.Function}`

      if (useCognitiveSearch && findConfiguration.CognitiveSearchEnabled) {
        apiUrl += `&cognitiveEnabled=true`
      }

      const t0 = performance.now()

      const response = await FetchWithCache(apiUrl, {
        method: 'GET',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${esToken}`
        }
      })

      let results
      if (!response || response.hasError || !response.responseJSON) {
        dispatch(
          ResultMetaDataStore.actions.hasError(
            'kpmg mpp',
            response.errorResponse,
            undefined,
            actionMetaData
          )
        )
        dispatch(fetchFailure(actionMetaData))
        return
      } else {
        results = response.responseJSON
      }

      const featuredResults =
        results && results.FeaturedResults ? results.FeaturedResults : []

      const synonymsApplied =
        results && results.SynonymsApplied ? results.SynonymsApplied : []

      const t1 = performance.now()

      let primaryResults: IMppResult[] =
        results && results.results ? results.results : []
      const resultCount = results && results.total ? parseInt(results.total) : 0

      primaryResults = primaryResults.map((item: IMppResult) => {
        return {
          title: stripHtml(item.title),
          owner: stripHtml(item.owner),
          manual: stripHtml(item.manual),
          fragment: stripHtml(item.fragment),
          filetype: item.filetype,
          url: item.url,
          additional: item.additional ? item.additional : [],
          manualTitle: item.manualTitle ? stripHtml(item.manualTitle) : ''
        }
      })

      const executionTime = t1 - t0

      dispatch(
        fetchSuccess(
          {
            results: primaryResults,
            resultCount,
            executionTime,
            currentPage: currentPage || 1
          },
          featuredResults,
          synonymsApplied,
          actionMetaData
        )
      )
      dispatch(
        ResultMetaDataStore.actions.fetchSuccess(
          'kpmg mpp',
          {
            executionTime: executionTime,
            resultsCount: resultCount
          },
          actionMetaData
        )
      )

      const resultsCombined = Selectors.getResultsCombined(getState())
      if ((currentPage || 1) === 1) {
        dispatch(
          fetchSuccessCombined(
            {
              results: primaryResults
            },
            actionMetaData
          )
        )
      } else {
        dispatch(
          fetchSuccessCombined(
            {
              results: [...resultsCombined, ...primaryResults]
            },
            actionMetaData
          )
        )
      }
    } catch (error) {
      dispatch(
        ResultMetaDataStore.actions.hasError(
          'kpmg mpp',
          undefined,
          error,
          actionMetaData
        )
      )
      dispatch(fetchFailure(actionMetaData))
    }
  }
}

export const fetchResultsMppFilters = (
  searchQuery: string,
  currentPage: number,
  userSettings: IUserSetting,
  deviceSettings: IDeviceSetting,
  filters: {
    [key: string]: string
  }
  // eslint-disable-next-line @typescript-eslint/ban-types
): ThunkAction<Promise<void>, Store, {}, AnyAction> => {
  return async (
    // eslint-disable-next-line @typescript-eslint/ban-types
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => Store
  ) => {
    const actionMetaData = getActionMetaData('kpmgmpp', 'filters')

    dispatch(fetchFiltersRequest(actionMetaData))
    try {
      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        throw new Error('Authentication: Cannot renew authentication token')
      }

      // Performs the expanded filters request
      const apiUrl = `${Config.APIM_BASE_URL}mppapi/getmanuals`

      const response = await FetchWithCache(apiUrl, {
        method: 'GET',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${esToken}`
        }
      })

      let results
      if (!response || response.hasError || !response.responseJSON) {
        dispatch(
          ResultMetaDataStore.actions.hasError(
            'kpmg mpp',
            response.errorResponse,
            undefined,
            actionMetaData
          )
        )
        dispatch(fetchFailure(actionMetaData))
        return
      } else {
        results = response.responseJSON
      }

      const manualFilters: Filter[] = []
      const countryFilters: Filter[] = []
      const servicelinesFilters: Filter[] = []
      const filetypesFilters: Filter[] = []

      if (results && results.manuals)
        results.manuals.forEach((item: any) => {
          if (item && item.countries)
            item.countries.forEach((country: any) => {
              if (
                !countryFilters.find(
                  (existingFilter: Filter) => existingFilter.key === country.id
                )
              ) {
                const image = availableCountryIcons.find(
                  (avImage: string) =>
                    avImage === country.title.toLowerCase().replaceAll(' ', '-')
                )

                countryFilters.push({
                  name: country.title,
                  key: country.id,
                  value: country.id,
                  image: image ? image : 'united-nations'
                })
              }
            })

          if (item && item.servicelines)
            item.servicelines.forEach((serviceline: any) => {
              if (
                !servicelinesFilters.find(
                  (existingFilter: Filter) =>
                    existingFilter.key === serviceline.id
                )
              )
                servicelinesFilters.push({
                  name: serviceline.title,
                  key: serviceline.id,
                  value: serviceline.id,
                  image: ''
                })
            })

          if (item && item.id) {
            if (
              !invalidManualFiltersMPP.includes(item.id) &&
              !manualFilters.find(
                (existingFilter: Filter) => existingFilter.key === item.id
              )
            )
              manualFilters.push({
                name: item.title,
                key: item.id,
                value: item.id,
                image: ''
              })
          }
        })

      if (results && results.filetypes)
        results.filetypes.forEach((filetype: any) => {
          if (
            !filetypesFilters.find(
              (existingFilter: Filter) => existingFilter.key === filetype.id
            )
          )
            filetypesFilters.push({
              name: filetype.title,
              key: filetype.id,
              value: filetype.id,
              image: ''
            })
        })

      dispatch(
        fetchFiltersSuccess(
          {
            manuals: manualFilters,
            country: countryFilters,
            filetype: filetypesFilters,
            serviceline: servicelinesFilters
          },
          actionMetaData
        )
      )
    } catch (error) {
      dispatch(fetchFailure(actionMetaData))
      dispatch(fetchFiltersFailure(actionMetaData))
      trackException('Error in fetching Results MPP filters action', error)
    }
  }
}

export type ResultsMppActions =
  | IFetchRequest
  | IFetchFailure
  | IFetchFailure
  | IFetchFiltersRequest
  | IFetchFiltersFailure
  | IFetchFiltersSuccess
