import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesTranslationDialog(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles() {
  return makeStyles((theme: Theme) => ({
    headline: {
      padding: '0 16px'
    },
    listItem: {
      color: '#424242',
      minHeight: '54px',
      overflowWrap: 'break-word',
      padding: '20px 16px 0px 16px'
    },
    iconItem: {
      minWidth: 'auto',
      margin: 'auto 0',
      [`& >svg`]: {
        margin: 'auto'
      }
    },
    iconContainer: {
      alignSelf: 'flex-start',
      display: 'flex',
      height: '32px'
    },
    select: {
      width: '100%',
      background: '#f6f7fA',
      borderRadius: 4,
      color: '#424242',
      '&& .MuiInput-input': {
        paddingLeft: 8
      }
    },
    loadingSpinner: {
      marginLeft: '5px',
      color: '#fff',
      marginTop: '-2px'
    }
  }))
}
