import { Action } from 'redux'

export enum FilterActionTypes {
  SET_ENABLED_FILTERS = 'filter/SET_ENABLED_FILTERS',
  SET_TEMPORARY_FILTERS = 'filter/SET_TEMPORARY_FILTERS',
  CLEAR_TEMPORARY_FILTERS = 'filter/CLEAR_TEMPORARY_FILTERS',
  OVERRIDE_ENABLED_FILTERS = 'filter/OVERRIDE_ENABLED_FILTERS',
  SET_INITIAL_ENABLED_FILTER_STATE = 'filter/SET_INITIAL_ENABLED_FILTER_STATE',
  SET_RECENT_FILTERS = 'filter/SET_RECENT_FILTERS',
  CLEAR_RECENT_FILTERS = 'filter/CLEAR_RECENT_FILTERS',
  SET_DEFAULT_FILTER = 'filter/SET_DEFAULT_FILTER',
  INITIALIZE_FILTER = 'persist/REHYDRATE',
  RESET_FILTERS = 'filter/RESET_FILTERS'
}

export interface ISetEnabledFilter extends Action<FilterActionTypes> {
  payload: {
    filter: CurrentFilter | CurrentFilter[]
  }
}
export interface ISetEnabledRecentFilter extends Action<FilterActionTypes> {
  payload: {
    filter: { [key: string]: string }
  }
}

export interface ISetInitialEnabledFilterState
  extends Action<FilterActionTypes> {
  payload: {
    country: string
  }
}
export interface IClearRecentFitlers extends Action<FilterActionTypes> {
  payload: {
    filter: string
  }
}
export interface ISetDefaultFilters extends Action<FilterActionTypes> {
  payload: {
    filter: string
    filterOptions: Filter[]
  }
}

export type IResetFilters = Action<FilterActionTypes>
export type IClearTemporaryFilter = Action<FilterActionTypes>

export const resetFilters = (): IResetFilters => ({
  type: FilterActionTypes.RESET_FILTERS
})

export const setCurrentFilter = (
  filter: CurrentFilter | CurrentFilter[]
): ISetEnabledFilter => {
  return {
    type: FilterActionTypes.SET_ENABLED_FILTERS,
    payload: { filter }
  }
}

export const setTemporaryFilter = (
  filter: CurrentFilter | CurrentFilter[]
): ISetEnabledFilter => {
  return {
    type: FilterActionTypes.SET_TEMPORARY_FILTERS,
    payload: { filter }
  }
}

export const clearTemporaryFilter = (): IClearTemporaryFilter => ({
  type: FilterActionTypes.CLEAR_TEMPORARY_FILTERS
})

export const overrideEnabledFilters = (
  filter: CurrentFilter
): ISetEnabledFilter => ({
  type: FilterActionTypes.OVERRIDE_ENABLED_FILTERS,
  payload: { filter }
})

export const setInitialEnabledFilters = (
  country: string
): ISetInitialEnabledFilterState => ({
  type: FilterActionTypes.SET_INITIAL_ENABLED_FILTER_STATE,
  payload: { country }
})

export const setRecentFilters = (filter: {
  [key: string]: string
}): ISetEnabledRecentFilter => ({
  type: FilterActionTypes.SET_RECENT_FILTERS,
  payload: { filter }
})

export const clearRecentFilters = (filter: string): IClearRecentFitlers => ({
  type: FilterActionTypes.CLEAR_RECENT_FILTERS,
  payload: { filter }
})

export const setDefaultFilter = (
  filter: string,
  filterOptions: Filter[]
): ISetDefaultFilters => ({
  type: FilterActionTypes.SET_DEFAULT_FILTER,
  payload: { filter, filterOptions }
})

// use an OR type here if there more than one actions, e.g. ISetEnabledFilter |INewFilterAction | ...
export type FilterActions =
  | ISetEnabledFilter
  | ISetInitialEnabledFilterState
  | IClearRecentFitlers
