import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesLeftWidgetContainer(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles(): any {
  return makeStyles((theme: Theme) => ({
    resultFramePlaceholderContainer: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: 16,
      animation: 'react-placeholder-pulse 0.75s infinite'
    },
    resultFramePlaceholder: {
      height: 250,
      width: 200,
      borderRadius: 4,
      marginRight: 8,
      background: '#CDCDCD'
    },
    leftWidgetContainer: {
      overflow: 'visible !important'
    }
  }))
}
