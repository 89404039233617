import { makeStyles } from '@mui/styles'
import { green, yellow, red, grey } from '@mui/material/colors'
import { Theme } from '@mui/material/styles'

export function getStylesSEOChecker(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles() {
  return makeStyles((theme: Theme) => ({
    dialogContainer: {
      height: 'auto',
      [`${theme.breakpoints.down(1024)}`]: {
        '& .MuiDialog-paper': {
          margin: 0
        },
        '& .MuiDialog-paperScrollPaper': {
          maxHeight: '100%',
          height: '100%'
        }
      }
    },
    contact: {
      flex: 1,
      marginTop: 32,
      color: '#424242',
      fontSize: '16px'
    },
    dialogActions: {
      background: '#fff',
      padding: '16px 24px',
      marginLeft: '3px',
      justifyContent: 'space-between',
      '& > span': {
        color: '#424242',
        fontSize: '14px'
      }
    },
    dialogContent: {
      background: '#fff',
      height: '600px',
      display: 'flex',
      flexDirection: 'column',
      '& span': {
        color: '#424242'
      },
      padding: '8px 28px',
      [`${theme.breakpoints.down(1024)}`]: {
        padding: '8px 8px'
      }
    },
    dialogContentSpan: {
      fontSize: '16px'
    },
    dialogContentHowToSpan: {
      color: '#9e9e9e !important',
      fontSize: '13px',
      cursor: 'pointer'
    },
    dialogContentSmall: {
      fontSize: '13px'
    },
    dialogTitle: {
      background: '#005eb8',
      color: '#fff',
      textAlign: 'center',
      fontSize: '20px'
    },
    icon: {
      marginRight: 20
    },
    itemImage: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: 8,
      marginBottom: 8,
      marginLeft: 12
    },
    closeButton: {
      border: '1px solid #005eb8',
      background: 'transparent'
    },
    formButton: {
      backgroundColor: '#005eb8',
      padding: '4px',
      minWidth: '50px',
      '& span': {
        color: '#fff',
        fontWeight: 'bold',
        fontSize: '11px',
        padding: '0px 5px'
      }
    },
    docType: {
      cursor: 'pointer',
      border: '1px solid #ebebeb',
      borderRadius: '2px',
      color: '#3c4043',
      display: 'inline-block',
      fontSize: '10px',
      fontWeight: 'normal',
      height: '14px',
      letterSpacing: '0.75px',
      lineHeight: '14px',
      padding: '0 3px',
      textAlign: 'center',
      verticalAlign: 'middle',
      textTransform: 'uppercase',
      marginLeft: '4px',
      marginBlockStart: '-2px',
      marginBlockEnd: '0px'
    },
    sectionStarter: {
      marginTop: '12px'
    },
    gridItem: {
      paddingTop: '6px !important',
      paddingBottom: '6px !important',
      paddingLeft: '12px !important',
      paddingRight: '12px !important'
    },
    contactUs: {
      color: '#005eb8',
      textDecoration: 'none'
    },
    textURL: {
      width: '100%',
      maxWidth: '540px'
    },
    textTerms: {
      width: '80%',
      maxWidth: '431px'
    },
    inputFields: {
      fontSize: '13px'
    },
    validationMessage: {
      flex: 1,
      marginLeft: '5px',
      paddingTop: '8px',
      paddingBottom: '8px',
      borderRadius: '4px',
      color: '#424242',
      fontSize: '13px'
    },
    greenIcon: {
      color: green[500],
      verticalAlign: 'middle',
      marginRight: '5px'
    },
    yellowIcon: {
      color: yellow[800],
      verticalAlign: 'middle',
      marginRight: '5px'
    },
    redIcon: {
      color: red[500],
      verticalAlign: 'middle',
      marginRight: '5px'
    },
    questionIcon: {
      marginRight: '5px',
      verticalAlign: 'text-bottom',
      width: '15px',
      height: '15px',
      color: grey[500]
    },
    yellowInfo: {
      color: '#f9a825 !important',
      fontSize: '12px'
    },
    redInfo: {
      color: '#f44336 !important',
      fontSize: '12px'
    },
    checkBoxSmall: {
      fontSize: '16px'
    },
    contentText: {
      width: '315px'
    }
  }))
}
