import React, { Dispatch, useCallback, useEffect, useState } from 'react'
import ReactPlaceholder from 'react-placeholder'
import { connect } from 'react-redux'
import { Box, Fade, Grid, ListItemIcon, Typography } from '@mui/material'
import 'Placeholder.css'
import { Store } from 'store'
import AuthStore from 'store/Auth'
import UserSettingsStore from 'store/UserSettings'
import SettingsStore from 'store/Settings'
import ResultMetaDataStore from 'store/ResultMetaData'
import ResultsImagesStore from 'store/ResultsImages'
import PageStay from 'components/HOC/PageStay'
import ErrorPage from 'components/contents/common/ErrorPage'
import Pagination from '../Pagination'
import { externalLinkClicked, trackException } from 'utils/tracking'
import { useReactRouterQueryStringInterface } from 'utils/useQueryState'
import { authorizeResultPage } from 'utils/authorization'
import createDOMPurify from 'dompurify'
import { dataSourcesTabs } from 'constants/constants'
import SearchSuggestions from 'components/contents/common/SearchSuggestions'
import FeaturedResults from './FeaturedResults'
import RightTopWidgetContainer from './widgetscontainer/RightTopWidgetContainer'
import { FormattedMessage } from 'react-intl'
import RightWidgetContainer from './widgetscontainer/RightWidgetContainer'
import { getStylesResults } from 'styles/contents/resultpages/Results'
import { useFilter } from 'utils/filters'
import { getStylesErrorPage } from 'styles/contents/common/ErrorPage'
import { ErrorOutline } from '@mui/icons-material'
import { IUserSetting } from 'utils/userSettings'
import { CognitiveMessage } from '../common/CognitiveMessage'
import { KPMGFindGlobalVariables } from 'store/KPMGFindGlobalVariables'
import { Gallery } from 'react-grid-gallery'
import { getFileNameFromUrl } from 'utils/resultFunctions'
import { IResultImages } from 'store/ResultsImages/reducers'
import ImageResultGallery from '../results/ImageResultGallery'
import { getPreviewThumbnail } from 'utils/msgraphThumbnailPicture'
import { useLocation } from 'react-router-dom'

export interface ResultsImagesProps {
  appliedFilters: any
  authToken: string
}
interface IPreviewThumbnailList {
  images: IPreviewThumbnail[]
  searchTerm: string
}
interface IPreviewThumbnail {
  src: string
  width: number
  height: number
  Write: string
  ModifiedBy: string
  Path: string
}

type AllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  ResultsImagesProps

function ResultsImages(props: AllProps) {
  const {
    featuredResults,
    results,
    hasError,
    totalRowCount,
    lastRow,
    hasResultsBeenFetched,
    hasErrorStore,
    userSettings,
    deviceSettings,
    findConfiguration,
    setUserSettings,
    aadInfo,
    useCognitiveSearch
  } = props

  const [cognitiveSearchEnabled, setCognitiveSearchEnabled] =
    useState(useCognitiveSearch)
  const [showCognitiveMessage, setShowCognitiveMessage] = useState(
    userSettings.ShowCognitiveMessage
  )
  const [images, setImages] = useState<IPreviewThumbnailList>({
    images: [],
    searchTerm: ''
  })
  const [imagesNew, setImagesNew] = useState<IPreviewThumbnailList>()
  const [isLoading, setIsLoading] = useState(true)

  const DOMPurify = createDOMPurify(window)
  const resultClasses = getStylesResults()
  const errorClasses = getStylesErrorPage()
  const { getQueryString } = useReactRouterQueryStringInterface()
  const queryStringFromUrl = getQueryString()
  const [currentPage] = useFilter('page', '1')
  const [searchQuery] = useFilter('q')
  const location = useLocation()

  useEffect(() => {
    KPMGFindGlobalVariables.setCurrentTab(dataSourcesTabs.images)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(
    () => {
      setCognitiveSearchEnabled(useCognitiveSearch)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [useCognitiveSearch]
  )

  useEffect(
    () => {
      setShowCognitiveMessage(userSettings.ShowCognitiveMessage)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userSettings.ShowCognitiveMessage]
  )

  const setup = useCallback(() => {
    try {
      authorizeResultPage(
        'Images',
        userSettings,
        deviceSettings,
        DOMPurify.sanitize(location && location.search ? location.search : '')
      )
    } catch (error) {
      trackException('Error in fetching results in ResultsImages.tsx', error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryStringFromUrl])

  useEffect(() => {
    setup()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryStringFromUrl])

  React.useEffect(() => {
    fetchAllImageDetails(results, searchQuery)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results])

  React.useEffect(() => {
    if (!imagesNew || imagesNew.searchTerm !== searchQuery) {
      return
    }

    let displayedImages =
      Number(currentPage) === 1 || !deviceSettings.renderMobile || !images
        ? []
        : [...images.images]

    imagesNew.images.forEach((image) => {
      displayedImages.push(image)
    })

    setImages({ images: displayedImages, searchTerm: searchQuery })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imagesNew])

  const fetchImageDetails = (results: IResultImages, index: number) => {
    return new Promise<IPreviewThumbnail | null>(async (resolve, reject) => {
      const imageResult = results.queryResults[index]
      const src = await getPreviewThumbnail(
        imageResult.PictureThumbnailUrl,
        imageResult.SiteID,
        imageResult.WebID,
        imageResult.ListID,
        imageResult.ListItemID,
        deviceSettings,
        aadInfo
      )

      const img = document.createElement('img')
      img.onload = function () {
        if ('naturalHeight' in this) {
          if (img.naturalHeight + img.naturalWidth === 0) {
            resolve(null)
            return
          }
        } else if (img.width + img.height === 0) {
          resolve(null)
          return
        }

        resolve({
          src: src,
          width: img.width,
          height: img.height,
          Write: imageResult.Write,
          ModifiedBy: imageResult.ModifiedBy,
          Path: imageResult.Path
        })
      }

      img.onerror = function () {
        resolve(null) //cannot reject => Promise.all, es5
      }

      img.src = src
    })
  }

  const fetchAllImageDetails = (results: IResultImages, searchTerm: string) => {
    setIsLoading(true)
    let newImagesList: IPreviewThumbnail[] = []
    const fetchList = []

    for (let index = 0; index < results.queryResults.length; index++) {
      //get image size
      fetchList.push(fetchImageDetails(results, index))
    }

    const newFetchedImageDetails: IPreviewThumbnail[] = []
    Promise.all(fetchList)
      .then((values) => {
        for (let iN = 0; iN < values.length; iN++) {
          const newValue = values[iN]

          if (newValue) newFetchedImageDetails.push(newValue)
        }
        newImagesList = [...newFetchedImageDetails]

        setImagesNew({ images: newImagesList, searchTerm: searchTerm })
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
      })
  }

  const handleOnClick = (index: number) => {
    if (images && images.images) {
      const item = images.images[index]

      if (item && item.src) {
        const title = getFileNameFromUrl(DOMPurify.sanitize(item.Path))
        const url = DOMPurify.sanitize(item.Path)

        externalLinkClicked({ title, url, index: index })
        window.open(url)
      }
    }
  }

  return (
    <Box>
      {!hasErrorStore && !!searchQuery && (
        <Box className={resultClasses.container}>
          <Box
            id={'con-resultvertical-01'}
            className={resultClasses.resultsContainer}
          >
            <SearchSuggestions
              wildCardActive={true}
              resultCount={totalRowCount}
              resultsFetchend={hasResultsBeenFetched}
            />

            <ReactPlaceholder
              ready={
                (hasResultsBeenFetched && !isLoading) ||
                (deviceSettings.renderMobile && Number(currentPage) > 1)
              }
              type="text"
              rows={4}
              showLoadingAnimation={true}
              className={
                deviceSettings.renderMobile
                  ? resultClasses.placeholderImages
                  : resultClasses.placeholder
              }
            >
              <Fade
                in={
                  (hasResultsBeenFetched && !isLoading) ||
                  (deviceSettings.renderMobile && Number(currentPage) > 1)
                }
                timeout={600}
              >
                <Box id={'con-resultvertical-section-01'}>
                  {hasError && (
                    <Box
                      id={'con-err-01'}
                      className={errorClasses.errorcontainer}
                    >
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        wrap="nowrap"
                        className={errorClasses.gridContainer}
                      >
                        <Grid item>
                          <ListItemIcon>
                            <ErrorOutline className={errorClasses.icon} />
                          </ListItemIcon>
                        </Grid>
                        <Grid item className={errorClasses.messagesContainer}>
                          <Typography
                            variant="body1"
                            className={errorClasses.errorHeader}
                          >
                            {hasError && (
                              <FormattedMessage
                                id="error_message_oi"
                                defaultMessage="One Intranet results are currently not available."
                              />
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                  {cognitiveSearchEnabled &&
                    showCognitiveMessage &&
                    findConfiguration.CognitiveSearchEnabled &&
                    results.synonymsApplied &&
                    results.synonymsApplied.length > 0 && (
                      <CognitiveMessage
                        synonymsApplied={results.synonymsApplied}
                        setShowCognitiveMessage={setShowCognitiveMessage}
                        userSettings={userSettings}
                        setUserSettings={setUserSettings}
                      />
                    )}
                  {results && totalRowCount > 0 && (
                    <FeaturedResults featuredResults={featuredResults} />
                  )}
                </Box>
              </Fade>
            </ReactPlaceholder>

            <ReactPlaceholder
              ready={
                (hasResultsBeenFetched && !isLoading) ||
                (deviceSettings.renderMobile && Number(currentPage) > 1)
              }
              type="text"
              rows={4}
              showLoadingAnimation={true}
              className={
                deviceSettings.renderMobile
                  ? resultClasses.placeholderImages
                  : resultClasses.placeholder
              }
            >
              <Fade
                in={
                  (hasResultsBeenFetched && !isLoading) ||
                  (deviceSettings.renderMobile && Number(currentPage) > 1)
                }
                timeout={600}
              >
                <Box id={'con-resultvertical-section-01'}>
                  <Gallery
                    images={images.images}
                    margin={8}
                    enableImageSelection={false}
                    onClick={handleOnClick}
                    thumbnailImageComponent={ImageResultGallery}
                    tileViewportStyle={(item: any) => {
                      return {
                        width: item.item.viewportWidth + 'px',
                        height: item.item.scaledHeight + 'px',
                        overflow: 'hidden',
                        backgroundColor: '#fff'
                      }
                    }}
                  />
                </Box>
              </Fade>
            </ReactPlaceholder>

            <ReactPlaceholder
              ready={hasResultsBeenFetched && !isLoading}
              type="text"
              rows={4}
              showLoadingAnimation={true}
              className={
                deviceSettings.renderMobile
                  ? resultClasses.placeholderImages
                  : resultClasses.placeholder
              }
            >
              <Fade in={hasResultsBeenFetched && !isLoading} timeout={600}>
                <Box>
                  {totalRowCount != null && totalRowCount > 0 && (
                    <Pagination
                      {...props}
                      totalRecords={totalRowCount}
                      pageLimit={21}
                      pageNeighbours={4}
                      loadMore={deviceSettings.renderMobile}
                      lastRow={lastRow}
                    />
                  )}
                </Box>
              </Fade>
            </ReactPlaceholder>
          </Box>
          {!deviceSettings.renderMobile && (
            <Box className={resultClasses.advertisementContainer}>
              <RightTopWidgetContainer
                searchQuery={searchQuery}
                scope={'images'}
              />
              <RightWidgetContainer />
            </Box>
          )}
        </Box>
      )}
      {hasErrorStore && <ErrorPage dataSource="images" />}
    </Box>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    aadInfo: AuthStore.selectors.getAADInfo(state),
    featuredResults: ResultsImagesStore.selectors.getFeaturedResults(state),
    results: ResultsImagesStore.selectors.getResults(state),
    totalRowCount: ResultsImagesStore.selectors.getTotalRowCount(state),
    lastRow: ResultsImagesStore.selectors.getLastRow(state),
    hasError: ResultsImagesStore.selectors.getHasError(state),
    executionTime: ResultsImagesStore.selectors.getExecutionTime(state),
    hasResultsBeenFetched:
      ResultsImagesStore.selectors.hasResultsBeenFetched(state),
    hasErrorStore: ResultMetaDataStore.selectors.getDataSourceHasError(
      state,
      'images'
    ),
    userSettings: UserSettingsStore.selectors.getUserSettings(state),
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state),
    findConfiguration: SettingsStore.selectors.getFindConfiguration(state),
    useCognitiveSearch: SettingsStore.selectors.getUseCognitiveSearch(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    setUserSettings: (userSettings: IUserSetting) =>
      dispatch(UserSettingsStore.actions.upSertUserSettings(userSettings))
  }
}

export default PageStay(
  connect(mapStateToProps, mapDispatchToProps)(ResultsImages)
)
