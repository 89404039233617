import React, { Dispatch, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import 'Placeholder.css'
import { Store } from 'store'
import UserSettingsStore from 'store/UserSettings'
import FilterStore from 'store/Filters'
import SettingsStore from 'store/Settings'
import WidgetStore from 'store/Widgets'
import { areDatasourceFilterSet, useFilter } from 'utils/filters'
import AuthStore from 'store/Auth'
import ResultsNewsStore from 'store/ResultsNews'
import ResultsKlardenkerStore from 'store/ResultsKlardenker'
import ResultsPeopleStore from 'store/ResultsPeople'
import ResultsSourceStore from 'store/ResultsSource'
import ResultsAEMStore from 'store/ResultsAEM'
import ResultsVideosStore from 'store/ResultsVideos'
import ResultsEngagementsStore from 'store/ResultsEngagements'
import ResultsOneIntranetStore from 'store/ResultsOneIntranet'
import ResultsStatisticsStore from 'store/ResultsStatistics'
import createDOMPurify from 'dompurify'
import { dataSourcesTabs } from 'constants/constants'
import { WidgetKey } from 'constants/widgets'
import {
  IWidgetSelectConfig,
  preSelectWidgetViaRules,
  selectWidget
} from 'utils/widgets/resultWidgets'
import { IWidgetFetched, IWidgetSelectedResults } from 'store/Widgets/actions'
import { trackException } from 'utils/tracking'
import { useReactRouterQueryStringInterface } from 'utils/useQueryState'
import queryString from 'query-string'
import { getCurrentScope, getFilterOptions } from 'utils/queryParams'
import { ActionMetaData, getActionMetaData } from 'store/extension/customAction'
import SearchStore from 'store/Search'

interface IWidgetDsResultMap {
  widgetKey: string
  dsResults: any
  ds: string
}

interface IWidgetResults {
  left: IWidgetSelectedResults | null
  right: IWidgetSelectedResults | null
}

type AllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

function WidgetsFetch(props: AllProps) {
  const {
    aadInfo,
    userSettings,
    deviceSettings,
    findConfiguration,
    currentfilters,
    widgetLeft,
    widgetRight,
    dataSourceSettings,
    statistaResultsHasError,
    statistaResults,
    statistaResultsHBF,
    statistaLastQuery,
    newsResults,
    newsSynonymsApplied,
    newsResultsHBF,
    newsResultsHasError,
    newsLastQuery,
    sourceResults,
    sourceSynonymsApplied,
    sourceResultsHBF,
    sourceResultsHasError,
    sourceLastQuery,
    peopleResults,
    peopleResultsHBF,
    peopleSynonymsApplied,
    peopleResultsHasError,
    peopleLastQuery,
    klardenkerResults,
    klardenkerResultsHBF,
    klardenkerResultsHasError,
    klardenkerLastQuery,
    kpmgcomResults,
    kpmgcomResultsHBF,
    kpmgcomResultsHasError,
    kpmgcomLastQuery,
    videosResults,
    videosResultsHBF,
    videosResultsHasError,
    videosLastQuery,
    engagementsResults,
    engagementsResultsHBF,
    engagementsResultsHasError,
    engagementsLastQuery,
    oneIntraResults,
    oneIntraResultsHBF,
    oneIntraResultsHasError,
    oneIntraLastQuery,
    setFetchedLeftWidget,
    setFetchedRightWidget,
    fetchWidgetsLeftFailure,
    fetchWidgetsRightFailure,
    hasUserSettingsBeenFetched,
    defaultFilterOptions,
    useCognitiveSearch,
    useEntityRecognition,
    hasSearchDataBeenFetched,
    entityData
  } = props

  const DOMPurify = createDOMPurify(window)
  const firstUpdate = useRef(true)
  const actionMetaData = useRef(getActionMetaData('Widgets'))
  const widgetSelectConfigList = useRef([] as IWidgetSelectConfig[])
  const { getQueryString } = useReactRouterQueryStringInterface()
  const [searchQuery] = useFilter('q')

  const currentPath = getCurrentScope(true)

  const [widgetResult, setWidgetResult] = useState<IWidgetResults>({
    left: null,
    right: null
  })

  const buildWidgetDatasourceResultsMapping = () => {
    return [
      {
        widgetKey: WidgetKey.statistics,
        dsResults: {
          hasBeenFetched: statistaResultsHBF,
          results: statistaResults,
          hasError: statistaResultsHasError,
          lastQuery: statistaLastQuery
        },
        ds: dataSourcesTabs.statistics
      },
      {
        widgetKey: WidgetKey.news,
        dsResults: {
          hasBeenFetched: newsResultsHBF,
          results: {
            queryResults: newsResults,
            synonymsApplied: newsSynonymsApplied
          },
          hasError: newsResultsHasError,
          lastQuery: newsLastQuery
        },
        ds: dataSourcesTabs.news
      },
      {
        widgetKey: WidgetKey.source,
        dsResults: {
          hasBeenFetched: sourceResultsHBF,
          results: {
            queryResults: sourceResults,
            synonymsApplied: sourceSynonymsApplied
          },
          hasError: sourceResultsHasError,
          lastQuery: sourceLastQuery
        },
        ds: dataSourcesTabs.source
      },
      {
        widgetKey: WidgetKey.people,
        dsResults: {
          hasBeenFetched: peopleResultsHBF,
          results: {
            queryResults: peopleResults,
            synonymsApplied: peopleSynonymsApplied
          },
          hasError: peopleResultsHasError,
          lastQuery: peopleLastQuery
        },
        ds: dataSourcesTabs.people
      },
      {
        widgetKey: WidgetKey.klardenker,
        dsResults: {
          hasBeenFetched: klardenkerResultsHBF,
          results: klardenkerResults,
          hasError: klardenkerResultsHasError,
          lastQuery: klardenkerLastQuery
        },
        ds: dataSourcesTabs.klardenker
      },
      {
        widgetKey: WidgetKey.kpmgWebsites,
        dsResults: {
          hasBeenFetched: kpmgcomResultsHBF,
          results: kpmgcomResults,
          hasError: kpmgcomResultsHasError,
          lastQuery: kpmgcomLastQuery
        },
        ds: dataSourcesTabs.aem
      },
      {
        widgetKey: WidgetKey.video,
        dsResults: {
          hasBeenFetched: videosResultsHBF,
          results: videosResults.queryResults,
          hasError: videosResultsHasError,
          lastQuery: videosLastQuery
        },
        ds: dataSourcesTabs.videos
      },
      {
        widgetKey: WidgetKey.engagements,
        dsResults: {
          hasBeenFetched: engagementsResultsHBF,
          results: engagementsResults,
          hasError: engagementsResultsHasError,
          lastQuery: engagementsLastQuery
        },
        ds: dataSourcesTabs.engagements
      },
      {
        widgetKey: WidgetKey.intranet,
        dsResults: {
          hasBeenFetched: oneIntraResultsHBF,
          results: oneIntraResults,
          hasError: oneIntraResultsHasError,
          lastQuery: oneIntraLastQuery
        },
        ds: dataSourcesTabs.intranet
      }
    ]
  }

  const [widgetDatasourceResultsMapping, setWidgetDatasourceResultsMapping] =
    useState<IWidgetDsResultMap[]>(buildWidgetDatasourceResultsMapping())

  const preFilteringWidgets = [
    WidgetKey.news,
    WidgetKey.video,
    WidgetKey.intranet
  ]

  const isWidgetPrefilteringEnabled = (widgetKey: string | undefined) => {
    if (!widgetKey || !userSettings.EnableWidgetPrefiltering) return false
    if (preFilteringWidgets.includes(widgetKey)) return true

    return false
  }

  useEffect(() => {
    setWidgetDatasourceResultsMapping(buildWidgetDatasourceResultsMapping())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    peopleResults,
    kpmgcomResults,
    sourceResults,
    oneIntraResults,
    klardenkerResults,
    statistaResults,
    videosResults,
    newsResults,
    engagementsResults,
    statistaResultsHasError,
    newsResultsHasError,
    sourceResultsHasError,
    peopleResultsHasError,
    klardenkerResultsHasError,
    kpmgcomResultsHasError,
    videosResultsHasError,
    engagementsResultsHasError
  ])

  /**
   * widget fetch function call´s
   * @param position
   * @param widgetKey
   */
  const handleFallback = async (
    position: 'left' | 'right',
    widgetConfig: IWidgetSelectConfig
  ) => {
    if (position === 'left') {
      widgetConfig.fetching = true

      // Fetch widget content
      const leftResult = widgetConfig.generator.fetch(
        aadInfo,
        searchQuery,
        deviceSettings,
        userSettings,
        findConfiguration,
        useCognitiveSearch
      )

      if (leftResult) {
        leftResult
          .then((lWidget: any) => {
            dispatchWidget('left', widgetConfig, lWidget)
          })
          .catch((rejectedMsg: string) => {
            //widget fetch failed, reset & choose another one
            resetSelectedWidget(position)
          })
      } else {
        //widget fetch failed, reset & choose another one
        resetSelectedWidget(position)
      }
    }

    if (position === 'right') {
      widgetConfig.fetching = true

      // Fetch widget content
      const rightResult = widgetConfig.generator.fetch(
        aadInfo,
        searchQuery,
        deviceSettings,
        userSettings,
        findConfiguration,
        useCognitiveSearch
      )

      if (rightResult) {
        rightResult
          .then((rWidget: any) => {
            dispatchWidget('right', widgetConfig, rWidget)
          })
          .catch((rejectedMsg: string) => {
            //widget fetch failed, reset & choose another one
            resetSelectedWidget(position)
          })
      } else {
        //widget fetch failed, reset & choose another one
        resetSelectedWidget(position)
      }
    }
  }

  const dispatchWidget = function (
    position: 'left' | 'right',
    widgetConfig: IWidgetSelectConfig,
    content: any
  ) {
    if (!content) {
      return
    }

    // Generate ReactElement
    const widget = widgetConfig.generator.generate(content, searchQuery)
    widget
      .then((gWidget: any) => {
        widgetConfig.fullyLoaded = true

        if (position === 'left') {
          setFetchedLeftWidget(
            {
              widget: gWidget,
              widgetConfig: widgetConfig,
              widgetDatasource: currentPath
            },
            actionMetaData.current
          )
        }

        if (position === 'right') {
          setFetchedRightWidget(
            {
              widget: gWidget,
              widgetConfig: widgetConfig,
              widgetDatasource: currentPath
            },
            actionMetaData.current
          )
        }
      })
      .catch((error: string) => {
        // Don't track an exception if the error is not defined
        // Catch block is hit on every reject inside the fetch and gerate method
        if (error) {
          trackException(
            `Error fetchWidget: ${widgetConfig.key} ${widgetConfig.type}`,
            error
          )
        }

        resetSelectedWidget(position)
      })
  }

  const resetSelectedWidget = (position: 'left' | 'right') => {
    const _widgetResult = {
      left: position === 'left' ? null : widgetResult.left,
      right: position === 'right' ? null : widgetResult.right
    }

    chooseWidgets(widgetSelectConfigList.current, _widgetResult, false)
  }

  /**
   * choose widgets for current datasource/origin
   * @param widgetSelectConfigList
   * @param widgetResultsParam
   * @param forceReset
   */
  const chooseWidgets = (
    widgetSelectConfigList: IWidgetSelectConfig[],
    widgetResultsParam: IWidgetResults,
    forceReset?: boolean
  ) => {
    const _widgetResult = {
      left: forceReset ? null : widgetResultsParam.left,
      right: forceReset ? null : widgetResultsParam.right
    }

    //select left widget
    if (!_widgetResult.left) {
      //reset widget
      setFetchedLeftWidget(
        {
          widget: null,
          widgetConfig: { fullyLoaded: false },
          widgetDatasource: currentPath
        },
        actionMetaData.current
      )

      //select widget
      const itemSelectedWidgetLeft: IWidgetSelectConfig | undefined =
        selectWidget(searchQuery, 'Left', widgetSelectConfigList)

      if (itemSelectedWidgetLeft) {
        itemSelectedWidgetLeft.fetching = false
        _widgetResult.left = {
          widgetConfig: itemSelectedWidgetLeft,
          result: undefined
        }
      } else {
        //no available widget found, dispatch failure & disable loading placeholder
        fetchWidgetsLeftFailure(actionMetaData.current)
      }
    }

    //select right widget
    if (!_widgetResult.right) {
      //reset widget
      setFetchedRightWidget(
        {
          widget: null,
          widgetConfig: { fullyLoaded: false },
          widgetDatasource: currentPath
        },
        actionMetaData.current
      )

      //select widget
      const itemSelectedWidgetRight: IWidgetSelectConfig | undefined =
        selectWidget(searchQuery, 'Right', widgetSelectConfigList)

      if (itemSelectedWidgetRight) {
        itemSelectedWidgetRight.fetching = false
        _widgetResult.right = {
          widgetConfig: itemSelectedWidgetRight,
          result: null
        }
      } else {
        //no available widget found, dispatch failure & disable loading placeholder
        fetchWidgetsRightFailure(actionMetaData.current)
      }
    }

    setWidgetResult(_widgetResult)
  }

  const getWidgetContent = async () => {
    //select widget result source (via store/fallback widget fetch function)
    const parsedQueryString = queryString.parse(getQueryString()) || {}

    //left widget
    if (
      widgetResult.left &&
      !widgetResult.left.widgetConfig?.fullyLoaded &&
      !widgetResult.left.widgetConfig?.fetching
    ) {
      //get widget<->ds mapping
      const leftWidgetData = widgetDatasourceResultsMapping.find(
        (item: IWidgetDsResultMap) =>
          item.widgetKey === widgetResult.left?.widgetConfig?.key
      )

      if (
        leftWidgetData &&
        leftWidgetData.dsResults &&
        searchQuery === leftWidgetData.dsResults.lastQuery
      ) {
        //has widget datasource filters set
        const datasourceFilterSetLeft = areDatasourceFilterSet(
          parsedQueryString as { [key: string]: string },
          getFilterOptions(leftWidgetData.ds, defaultFilterOptions, ''),
          userSettings.EnablePrefiltering,
          ['premium']
        )
        const filtersLeftApplied =
          datasourceFilterSetLeft ||
          isWidgetPrefilteringEnabled(widgetResult.left.widgetConfig?.key)

        if (
          leftWidgetData.dsResults.hasBeenFetched &&
          !leftWidgetData.dsResults.hasError &&
          !filtersLeftApplied
        ) {
          //use datasourceResults
          if (widgetResult.left.widgetConfig) {
            dispatchWidget(
              'left',
              widgetResult.left.widgetConfig,
              leftWidgetData.dsResults.results
            )
          }
        } else if (leftWidgetData.dsResults.hasError || filtersLeftApplied) {
          //use fallback

          if (widgetResult.left && widgetResult.left.widgetConfig)
            await handleFallback('left', widgetResult.left.widgetConfig)
        }
      } else {
        //use fallback

        if (widgetResult.left && widgetResult.left.widgetConfig)
          await handleFallback('left', widgetResult.left.widgetConfig)
      }
    }

    // right widget
    if (
      widgetResult.right &&
      !widgetResult.right.widgetConfig?.fullyLoaded &&
      !widgetResult.right.widgetConfig?.fetching
    ) {
      //get widget<->ds mapping
      const rightWidgetData = widgetDatasourceResultsMapping.find(
        (item: IWidgetDsResultMap) =>
          item.widgetKey === widgetResult.right?.widgetConfig?.key
      )

      if (
        rightWidgetData &&
        rightWidgetData.dsResults &&
        searchQuery === rightWidgetData.dsResults.lastQuery
      ) {
        //has widget datasource filters set
        const datasourceFilterSetRight = areDatasourceFilterSet(
          parsedQueryString as { [key: string]: string },
          getFilterOptions(rightWidgetData.ds, defaultFilterOptions, ''),
          userSettings.EnablePrefiltering,
          ['premium']
        )

        const filtersRightApplied =
          datasourceFilterSetRight ||
          isWidgetPrefilteringEnabled(widgetResult.right.widgetConfig?.key)

        if (
          rightWidgetData.dsResults.hasBeenFetched &&
          !rightWidgetData.dsResults.hasError &&
          !filtersRightApplied
        ) {
          //use datasourceResults
          if (widgetResult.right.widgetConfig) {
            dispatchWidget(
              'right',
              widgetResult.right.widgetConfig,
              rightWidgetData.dsResults.results
            )
          }
        } else if (rightWidgetData.dsResults.hasError || filtersRightApplied) {
          //use fallback

          if (widgetResult.right && widgetResult.right.widgetConfig)
            await handleFallback('right', widgetResult.right.widgetConfig)
        }
      } else {
        //use fallback

        if (widgetResult.right && widgetResult.right.widgetConfig)
          await handleFallback('right', widgetResult.right.widgetConfig)
      }
    }
  }

  React.useEffect(() => {
    getWidgetContent()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetDatasourceResultsMapping, widgetResult])

  const fetchLeftAndRightWidget = () => {
    //preselect widgets
    const sanitizedQuery = DOMPurify.sanitize(searchQuery)
    widgetSelectConfigList.current = preSelectWidgetViaRules(
      sanitizedQuery,
      deviceSettings,
      currentfilters,
      userSettings,
      dataSourceSettings,
      findConfiguration,
      useEntityRecognition && entityData ? entityData : null
    )

    actionMetaData.current = getActionMetaData('Widgets')
    chooseWidgets(widgetSelectConfigList.current, widgetResult, true)
  }

  /* Fetch left and right widgets*/
  // Two effect hooks are required to fetch the widgets everytime needed
  const fetchWidgetsHandler = () => {
    if (!searchQuery) {
      return
    }

    if (hasUserSettingsBeenFetched) {
      fetchLeftAndRightWidget()
    }
  }

  // Additionally load it when its not the first rendering in case of:
  // - searchquery has changed
  // - enable widget prefiltering has changed
  // - the country has changed
  // - the path has changed
  // - The cognitive search setting has changed
  useEffect(() => {
    // In case of first rendering skip this widget fetch, its already fetched through intial effect hook
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }

    if (!useEntityRecognition) {
      fetchWidgetsHandler()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchQuery,
    userSettings.EnableWidgetPrefiltering,
    userSettings.Country,
    currentPath,
    useEntityRecognition
  ])

  // Additionally load it when its not the first rendering in case of:
  // - searchquery has changed
  // - enable widget prefiltering has changed
  // - the country has changed
  // - the path has changed
  // - The cognitive search setting has changed
  useEffect(() => {
    // In case of first rendering skip this widget fetch, its already fetched through intial effect hook
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }

    // In case the NER request takes to long
    // reset the widgets directly on a new search
    if (useEntityRecognition && !hasSearchDataBeenFetched) {
      setFetchedLeftWidget(
        {
          widget: null,
          widgetConfig: { fullyLoaded: false },
          widgetDatasource: currentPath
        },
        actionMetaData.current
      )

      setFetchedRightWidget(
        {
          widget: null,
          widgetConfig: { fullyLoaded: false },
          widgetDatasource: currentPath
        },
        actionMetaData.current
      )
    }

    if (useEntityRecognition && hasSearchDataBeenFetched) {
      fetchWidgetsHandler()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    userSettings.EnableWidgetPrefiltering,
    userSettings.Country,
    currentPath,
    useEntityRecognition,
    hasSearchDataBeenFetched
  ])

  // Additionally load it when its not the first rendering in case of:
  useEffect(() => {
    // In case of first rendering skip this widget fetch, its already fetched through intial effect hook
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }

    fetchWidgetsHandler()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(userSettings.DisabledWidgets)])

  useEffect(() => {
    if (
      (widgetLeft &&
        widgetLeft.leftWidgetConfig &&
        widgetLeft.leftWidgetConfig.filterIsNot &&
        widgetLeft.leftWidgetConfig.filterIsNot.find((filter: CurrentFilter) =>
          currentfilters.some(
            (currentFilter: CurrentFilter) =>
              currentFilter.key === filter.key &&
              currentFilter.value === filter.value
          )
        )) ||
      (widgetRight &&
        widgetRight.rightWidgetConfig &&
        widgetRight.rightWidgetConfig.filterIsNot &&
        widgetRight.rightWidgetConfig.filterIsNot.find(
          (filter: CurrentFilter) =>
            currentfilters.some(
              (currentFilter: CurrentFilter) =>
                currentFilter.key === filter.key &&
                currentFilter.value === filter.value
            )
        ))
    )
      fetchWidgetsHandler()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentfilters])
  return <></>
}

const mapStateToProps = (state: Store) => {
  return {
    aadInfo: AuthStore.selectors.getAADInfo(state),
    hasUserSettingsBeenFetched:
      UserSettingsStore.selectors.hasUserSettingsBeenFetched(state),
    userSettings: UserSettingsStore.selectors.getUserSettings(state),
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state),
    dataSourceSettings: SettingsStore.selectors.getDatasourceSettings(state),
    findConfiguration: SettingsStore.selectors.getFindConfiguration(state),
    currentfilters: FilterStore.selectors.getCurrentFilters(state),
    widgetLeft: WidgetStore.selectors.getLeftWidget(state),
    widgetRight: WidgetStore.selectors.getRightWidget(state),

    statistaResults: ResultsStatisticsStore.selectors.getResults(state),
    statistaResultsHBF:
      ResultsStatisticsStore.selectors.hasResultsBeenFetched(state),
    statistaResultsHasError:
      ResultsStatisticsStore.selectors.getHasError(state),
    statistaLastQuery: ResultsStatisticsStore.selectors.getLatestQuery(state),

    newsResults: ResultsNewsStore.selectors.getResults(state),
    newsSynonymsApplied: ResultsNewsStore.selectors.getSynonymsApplied(state),
    newsResultsHBF: ResultsNewsStore.selectors.hasResultsBeenFetched(state),
    newsResultsHasError: ResultsNewsStore.selectors.getHasError(state),
    newsLastQuery: ResultsNewsStore.selectors.getLatestQuery(state),

    sourceResults: ResultsSourceStore.selectors.getResults(state),
    sourceSynonymsApplied:
      ResultsSourceStore.selectors.getSynonymsApplied(state),
    sourceResultsHBF: ResultsSourceStore.selectors.hasResultsBeenFetched(state),
    sourceResultsHasError: ResultsSourceStore.selectors.getHasError(state),
    sourceLastQuery: ResultsSourceStore.selectors.getLatestQuery(state),

    peopleResults: ResultsPeopleStore.selectors.getResults(state),
    peopleResultsHBF: ResultsPeopleStore.selectors.hasResultsBeenFetched(state),
    peopleSynonymsApplied:
      ResultsPeopleStore.selectors.getSynonymsApplied(state),
    peopleResultsHasError: ResultsPeopleStore.selectors.getHasError(state),
    peopleLastQuery: ResultsPeopleStore.selectors.getLatestQuery(state),

    klardenkerResults: ResultsKlardenkerStore.selectors.getResults(state),
    klardenkerResultsHBF:
      ResultsKlardenkerStore.selectors.hasResultsBeenFetched(state),
    klardenkerResultsHasError:
      ResultsKlardenkerStore.selectors.getHasError(state),
    klardenkerLastQuery: ResultsKlardenkerStore.selectors.getLatestQuery(state),

    kpmgcomResults: ResultsAEMStore.selectors.getResults(state),
    kpmgcomResultsHBF: ResultsAEMStore.selectors.hasResultsBeenFetched(state),
    kpmgcomResultsHasError: ResultsAEMStore.selectors.getHasError(state),
    kpmgcomLastQuery: ResultsAEMStore.selectors.getLatestQuery(state),

    videosResults: ResultsVideosStore.selectors.getResults(state),
    videosResultsHBF: ResultsVideosStore.selectors.hasResultsBeenFetched(state),
    videosResultsHasError: ResultsVideosStore.selectors.getHasError(state),
    videosLastQuery: ResultsVideosStore.selectors.getLatestQuery(state),

    engagementsResults: ResultsEngagementsStore.selectors.getResults(state),
    engagementsResultsHBF:
      ResultsEngagementsStore.selectors.hasResultsBeenFetched(state),
    engagementsResultsHasError:
      ResultsEngagementsStore.selectors.getHasError(state),
    engagementsLastQuery:
      ResultsEngagementsStore.selectors.getLatestQuery(state),

    oneIntraResults: ResultsOneIntranetStore.selectors.getResults(state),
    oneIntraResultsHBF:
      ResultsOneIntranetStore.selectors.hasResultsBeenFetched(state),
    oneIntraResultsHasError:
      ResultsOneIntranetStore.selectors.getHasError(state),
    oneIntraLastQuery: ResultsOneIntranetStore.selectors.getLatestQuery(state),
    defaultFilterOptions: FilterStore.selectors.getDefaultFilterOptions(state),
    useCognitiveSearch: SettingsStore.selectors.getUseCognitiveSearch(state),
    useEntityRecognition:
      SettingsStore.selectors.getUseEntityRecognition(state),
    hasSearchDataBeenFetched:
      SearchStore.selectors.hasSearchDataBeenFetched(state),
    entityData: SearchStore.selectors.getEntities(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    setFetchedLeftWidget: (
      widgetLeft: IWidgetFetched,
      actionMetaData: ActionMetaData
    ) =>
      dispatch(
        WidgetStore.actions.setFetchedLeftWidget(widgetLeft, actionMetaData)
      ),
    setFetchedRightWidget: (
      widgetRight: IWidgetFetched,
      actionMetaData: ActionMetaData
    ) =>
      dispatch(
        WidgetStore.actions.setFetchedRightWidget(widgetRight, actionMetaData)
      ),
    fetchWidgetsLeftFailure: (actionMetaData: ActionMetaData) =>
      dispatch(WidgetStore.actions.fetchWidgetsLeftFailure(actionMetaData)),
    fetchWidgetsRightFailure: (actionMetaData: ActionMetaData) =>
      dispatch(WidgetStore.actions.fetchWidgetsRightFailure(actionMetaData))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WidgetsFetch)
