import { Reducer } from 'redux'
import {
  IFetchAcronymSuccess,
  IFetchBookmarkSuccess,
  IFetchQnASuccess,
  MSAnswersActionTypes,
  MSAnswersActions
} from './actions'

export interface IMSAnswerModel {
  displayName: string
  description: string | null
  categories: Array<string>
  availabilityStartDateTime: string | null
  availabilityEndDateTime: string | null
  state: string
  isSuggested: boolean
  id: string
  webUrl: string | null
  keywords: {
    keywords: Array<string>
    reservedKeywords: Array<string>
    matchSimilarKeywords: boolean
  }
  standsFor?: string
}

// datasourcesettings are used to override local datasource active property with the ones from Cosmos DB
// currentdatasources are currently only used from the settings page
export interface IMSAnswersStore {
  bookmarks: Array<IMSAnswerModel>
  qnas: Array<IMSAnswerModel>
  acronyms: Array<IMSAnswerModel>
}

const initialState: IMSAnswersStore = {
  bookmarks: [],
  qnas: [],
  acronyms: []
}

const reducers: Reducer<IMSAnswersStore, MSAnswersActions> = (
  state: IMSAnswersStore = initialState,
  action: MSAnswersActions | { type: 'INITIALIZE_SETTINGS'; payload: any }
) => {
  switch (action.type) {
    case MSAnswersActionTypes.FETCH_BOOKMARK_REQUEST:
      return {
        ...state,
        bookmarks: []
      }
    case MSAnswersActionTypes.FETCH_BOOKMARK_FAILURE:
      return {
        ...state,
        bookmarks: []
      }
    case MSAnswersActionTypes.FETCH_BOOKMARK_SUCCESS:
      const { bookmarks } = (action as IFetchBookmarkSuccess).payload
      return {
        ...state,
        bookmarks: bookmarks
      }
    case MSAnswersActionTypes.FETCH_QNA_REQUEST:
      return {
        ...state,
        qnas: []
      }
    case MSAnswersActionTypes.FETCH_QNA_FAILURE:
      return {
        ...state,
        qnas: []
      }
    case MSAnswersActionTypes.FETCH_QNA_SUCCESS:
      const { qnas } = (action as IFetchQnASuccess).payload
      return {
        ...state,
        qnas: qnas
      }
    case MSAnswersActionTypes.FETCH_ACRONYM_REQUEST:
      return {
        ...state,
        acronyms: []
      }
    case MSAnswersActionTypes.FETCH_ACRONYM_FAILURE:
      return {
        ...state,
        acronyms: []
      }
    case MSAnswersActionTypes.FETCH_ACRONYM_SUCCESS:
      const { acronyms } = (action as IFetchAcronymSuccess).payload
      return {
        ...state,
        acronyms: acronyms
      }
    default:
      return state
  }
}

export default reducers
