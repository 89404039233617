import {
  IDataDataSourceDefinition,
  IDataSourceOrderBaseObject
} from './dataSourcesOrderConfiguration'

export interface IDataSourceOrderFunction extends IDataSourceOrderBaseObject {
  functions: Array<string>
  countries?: Array<string>
  sources: Array<IDataDataSourceDefinition>
}

export const dataSourcesFunctionOrder: Array<IDataSourceOrderFunction> = [
  {
    functions: ['OmniaFunction'],
    countries: ['Omnia'],
    sources: [
      { name: 'Engagements' },
      { name: 'Intranet' },
      { name: 'People' },
      { name: 'Office365' },
      { name: 'Source' },
      { name: 'News' },
      { name: 'Statistics' },
      { name: 'Videos' },
      { name: 'Research' },
      { name: 'Alex' },
      { name: 'kpmg.com' },
      { name: 'Klardenker' },
      { name: 'KnowledgeExchange' },
      { name: 'ContentFinder' },
      { name: 'Eins' },
      { name: 'Projekt Laufwerke' },
      { name: 'KPMG MPP' },
      { name: 'MarketResearch' },
      { name: 'IBFD' },
      { name: 'Images' },
      { name: 'Kpmg.us' },
      { name: 'Chat' }
    ]
  },
  {
    functions: ['OmniaFunction'],
    sources: [
      { name: 'People' },
      { name: 'Office365' },
      { name: 'Source' },
      { name: 'News' },
      { name: 'Statistics' },
      { name: 'Videos' },
      { name: 'Research' },
      { name: 'Alex' },
      { name: 'kpmg.com' },
      { name: 'Klardenker' },
      { name: 'KnowledgeExchange' },
      { name: 'ContentFinder' },
      { name: 'Eins' },
      { name: 'Projekt Laufwerke' },
      { name: 'KPMG MPP' },
      { name: 'MarketResearch' },
      { name: 'IBFD' },
      { name: 'Images' },
      { name: 'Kpmg.us' },
      { name: 'Chat' }
    ]
  }
]
