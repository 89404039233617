import { ISynonymsApplied } from 'components/models/SynonymsApplied'
import { Store } from '..'
import { FeaturedResult } from 'components/models/FeaturedResult'
import { IPeopleResult } from 'components/models/PeopleResult'

const selectors = {
  getFeaturedResults: (state: Store): FeaturedResult[] =>
    state.resultsPeople.featuredResults,
  getResults: (state: Store): IPeopleResult[] => state.resultsPeople.results,
  getResultsCombined: (state: Store): IPeopleResult[] =>
    state.resultsPeople.resultsCombined,
  getResultsCombinedQuery: (state: Store): string =>
    state.resultsPeople.resultsCombinedQuery,
  hasResultsBeenFetched: (state: Store): boolean =>
    state.resultsPeople.hasResultsBeenFetched,
  getSynonymsApplied: (state: Store): ISynonymsApplied[] =>
    state.resultsPeople.synonymsApplied,
  getResultCount: (state: Store): number => state.resultsPeople.resultCount,
  getExecutionTime: (state: Store): number => state.resultsPeople.executionTime,
  getCurrentPage: (state: Store): number => state.resultsPeople.currentPage,
  getFilters: (state: Store): any => state.resultsPeople.filters,
  getHasError: (state: Store): boolean => state.resultsPeople.hasError,
  getLatestQuery: (state: Store): string =>
    state.resultsPeople.resultsCombinedQuery
}

export default selectors
