import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Grid, Link } from '@mui/material'
import { trackEvents } from 'utils/tracking'
import { Link as RouterLink } from 'react-router-dom'
import { getStylesSeeAllButton } from 'styles/contents/resultpages/widgets/SeeAllButton'

export interface SeeAllButtonProps {
  url: string
  widgetKey: string
  searchQuery: string
  defaultText?: React.ReactElement
  onClickCallback?: () => void
}

export default function SeeAllButton(props: SeeAllButtonProps): JSX.Element {
  const { widgetKey, url, searchQuery, defaultText, onClickCallback } = props
  const classes = getStylesSeeAllButton()

  const trackEvent = (rightClicked: boolean) => {
    trackEvents('widget-see-all', {
      widgetType: widgetKey,
      searchTerm: searchQuery,
      wasRightClicked: rightClicked
    })
    if (onClickCallback) onClickCallback()
  }

  return (
    <Grid item className={classes.gridItem}>
      {url.startsWith('http://') || url.startsWith('https://') ? (
        <Link
          target="_blank"
          href={url}
          className={classes.linkContainer}
          onClick={() => trackEvent(false)}
          onAuxClick={(event: any) => {
            trackEvent(event && event.button === 2 ? true : false)
          }}
        >
          {defaultText ? (
            defaultText
          ) : (
            <FormattedMessage id="see_all" defaultMessage="See All" />
          )}
        </Link>
      ) : (
        <RouterLink
          to={url}
          className={classes.linkContainer}
          onClick={() => trackEvent(false)}
          onAuxClick={(event: any) => {
            trackEvent(event && event.button === 2 ? true : false)
          }}
        >
          {defaultText ? (
            defaultText
          ) : (
            <FormattedMessage id="see_all" defaultMessage="See All" />
          )}
        </RouterLink>
      )}
    </Grid>
  )
}
