import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesContactUsModal(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles() {
  return makeStyles((theme: Theme) => ({
    dialogContainer: {
      height: 'auto',
      '& .MuiDialog-paperScrollPaper': {
        width: '100%'
      },
      [`${theme.breakpoints.down(769)}`]: {
        '& .MuiDialog-paper': {
          margin: 0
        },
        '& .MuiDialog-paperScrollPaper': {
          maxHeight: '100%',
          height: '100%'
        },
        '& .MuiDialogContent-root': {
          padding: 0
        }
      }
    },
    dialogContent: {
      background: '#f6f7fA',
      maxHeight: '600px',
      [`${theme.breakpoints.down(769)} and (orientation: portrait)`]: {
        height: '100%',
        width: '100%',
        maxHeight: '100%'
      }
    },
    background: {
      background: '#f6f7fA',
      height: '100%',
      width: '100%',
      [`${theme.breakpoints.down(1024)}`]: {
        width: '100%'
      }
    },
    gridItem: {
      [`${theme.breakpoints.down(769)} and (orientation: portrait)`]: {
        paddingLeft: '20px !important',
        paddingRight: '10px'
      }
    },
    headerContainer: {
      padding: 16,
      [`${theme.breakpoints.down(769)}`]: {
        paddingTop: 24
      }
    },
    headerIcon: {
      width: '100px',
      height: '100px',
      color: '#696969'
    },
    headerDescription: {
      color: '#424242',
      paddingTop: '16px',
      paddingBottom: '16px'
    },
    contentItemContainer: {
      backgroundColor: '#fff',
      height: '100%',
      transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      borderRadius: '4px',
      boxShadow:
        '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
    },
    contentIcon: {
      paddingTop: '16px'
    },
    contentTitle: { padding: '16px 8px 0 8px' },
    contentDescription: {
      padding: '16px'
    },
    contentLink: {
      textDecoration: 'none'
    },
    dialogActions: {
      background: '#f6f7fA',
      padding: '16px 24px'
    },
    closeButton: {
      border: '1px solid #424242',
      color: '#424242',
      background: 'transparent'
    }
  }))
}
