import { Reducer } from 'redux'
import {
  ResultsProjectSpacesActions,
  ResultsProjectSpacesActionTypes,
  IFetchSuccess
} from './actions'

export interface IResultProjectSpacesItem {
  docId: string
  title: string
  synopsis: string
  url: string | null
  createdDate: string | null
  mimetype: string | null
  parentId: string | null
  parentTitle: string | null
  sources: Array<string> | null
}

export interface IResultsProjectSpacesStore {
  hasResultsBeenFetched: boolean
  results: Array<IResultProjectSpacesItem>
  resultsCombined: Array<IResultProjectSpacesItem>
  resultsCombinedQuery: string
  resultCount: number
  executionTime: number
  currentPage: number
}

const initialState: IResultsProjectSpacesStore = {
  hasResultsBeenFetched: false,
  resultCount: -1,
  currentPage: 1,
  results: [],
  resultsCombined: [],
  resultsCombinedQuery: '',
  executionTime: 0
}

const reducers: Reducer<
  IResultsProjectSpacesStore,
  ResultsProjectSpacesActions
> = (
  state: IResultsProjectSpacesStore = initialState,
  action:
    | {
        type: ResultsProjectSpacesActionTypes.INITIALIZE_RESULTS_ALL
        payload: any
      }
    | { type: 'SET_CURRENT_PAGE'; payload: any }
    | ResultsProjectSpacesActions
) => {
  switch (action.type) {
    case 'SET_CURRENT_PAGE':
      return {
        ...state,
        currentPage: action.payload || 0
      }
    case ResultsProjectSpacesActionTypes.INITIALIZE_RESULTS_ALL:
      if (!(action as any).payload) {
        return initialState
      }
      const { resultsEins } = (action as any).payload
      return {
        ...state,
        currentPage: resultsEins.currentPage || 1
      }
    case ResultsProjectSpacesActionTypes.FETCH_REQUEST:
      const { resetCombined, searchQuery } = (action as any).payload
      return {
        ...state,
        hasResultsBeenFetched: false,
        executionTime: 0,
        resultCount: -1,
        results: [],
        resultsCombined: resetCombined ? [] : state.resultsCombined,
        resultsCombinedQuery: searchQuery
      }
    case ResultsProjectSpacesActionTypes.FETCH_FAILURE:
      return {
        ...state,
        hasResultsBeenFetched: true,
        resultCount: 0
      }
    case ResultsProjectSpacesActionTypes.FETCH_SUCCESS:
      const { response } = (action as IFetchSuccess).payload
      return {
        ...state,
        hasResultsBeenFetched: true,
        executionTime: response.executionTime,
        resultCount: response.resultCount,
        results: response.results,
        currentPage: response.currentPage
      }
    case ResultsProjectSpacesActionTypes.FETCH_SUCCESS_COMBINED:
      const { response: responseCombined } = (action as IFetchSuccess).payload
      return {
        ...state,
        resultsCombined: responseCombined.results
      }
    default:
      return state
  }
}

export default reducers
