import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesSearchField(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles() {
  return makeStyles((theme: Theme) => ({
    paperBasics: {
      width: '560px'
    },
    paper: {
      height: 44,
      marginTop: '32px',
      marginBottom: '16px',
      padding: '2px 4px',
      display: 'flex',
      flexDirection: 'row',
      flex: 1,
      margin: 'auto',
      borderRadius: '22px',
      elevation: '5',
      boxShadow: 'none',
      border: 1,
      borderStyle: 'solid',
      borderColor: '#dfe1e5',
      [`${theme.breakpoints.down(1024)}`]: {
        width: '95%'
      },
      [`${theme.breakpoints.down(501)}`]: {
        fontSize: '11px',
        height: '38px',
        width: '95%',
        boxShadow: 'none !important'
      },
      [`${theme.breakpoints.down(401)}`]: {
        fontSize: '11px',
        height: '38px',
        width: '95%',
        boxShadow: 'none !important'
      },
      '&:focus-within': {
        boxShadow: '0 1px 6px 0 rgba(32,33,36,0.28)'
      }
    },
    topDimensions: {
      padding: '0px 0px',
      marginTop: '8px',
      marginBottom: '8px',
      height: '44px',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row'
    },
    scrollDimensions: {
      padding: '0px 0px',
      marginTop: '8px',
      marginBottom: '8px',
      height: '32px',
      display: 'flex',
      flexDirection: 'row'
    },
    paperNavBar: {
      width: '690px',
      display: 'flex',
      alignItems: 'center',
      borderRadius: '22px',
      elevation: '10',
      boxShadow: 'none',
      border: 1,
      borderStyle: 'solid',
      borderColor: '#dfe1e5',
      [`${theme.breakpoints.down(1024)}`]: {
        width: '100%',
        height: '39px',
        boxShadow: 'none !important'
      },
      '&:focus-within': {
        boxShadow: '0 1px 6px 0 rgba(32,33,36,0.28)'
      }
    },
    noBottomRadius: {
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0
    },
    startContainer: {
      marginLeft: 0
    },
    startContainerMobile: {
      marginLeft: 44,
      width: '113%'
    },
    searchInput: {
      marginLeft: '20px'
    },
    clearIcon: {
      color: '#757575',
      cursor: 'pointer',
      font: '27px/25px arial,sans-serif',
      alignItems: 'center',
      padding: '0 15px',
      border: 0,
      background: 'transparent',
      zIndex: 1
    },
    searchIcon: {
      padding: 10,
      color: '#9AA0A6',
      height: 20,
      width: 20,
      marginTop: 2,
      marginLeft: 4
    },
    searchIconHighlighted: {
      padding: 10,
      color: '#005eb8',
      height: 20,
      width: 20,
      marginRight: 8,
      cursor: 'pointer',
      zIndex: 10,
      [`${theme.breakpoints.down(1024)}`]: {
        color: 'white',
        height: '39px',
        backgroundColor: '#005eb8',
        borderRadius: '0 20px 20px 0',
        marginRight: '-1px',
        paddingLeft: '8px',
        paddingRight: '8px',
        border: '1px solid #005eb8',
        paddingTop: '0px',
        paddingBottom: '0px',
        width: '24px'
      }
    },
    searchIconHighlightedOnStartPage: {
      [`${theme.breakpoints.down(1024)}`]: {
        height: '44px',
        paddingTop: '0px',
        paddingBottom: '0px',
        borderRadius: '0 22px 22px 0'
      }
    },
    searchIconHighlightedNoBorder: {
      [`${theme.breakpoints.down(1024)}`]: {
        borderRadius: '0px 20px 0px 0px'
      }
    },
    cognitiveIconEnabled: {
      color: '#005eb8 !important',
      height: 30,
      width: 30,
      cursor: 'pointer',
      zIndex: 10,
      transition: 'fill 200ms linear 0ms',
      [`${theme.breakpoints.down(1024)}`]: {
        height: '39px',
        marginRight: '-1px',
        paddingLeft: '8px',
        paddingRight: '8px',
        paddingTop: '0px',
        paddingBottom: '0px',
        width: '24px'
      }
    },
    cognitiveIcon: {
      color: '#5f6368',
      height: 30,
      width: 30,
      cursor: 'pointer',
      zIndex: 10,
      transition: 'fill 200ms linear 0ms',
      [`${theme.breakpoints.down(1024)}`]: {
        height: '39px',
        marginRight: '-1px',
        paddingLeft: '8px',
        paddingRight: '8px',
        paddingTop: '0px',
        paddingBottom: '0px',
        width: '24px'
      }
    },
    cognitiveIconAnimation: {
      animation: `$cognitive-button-pulse 0.75s`
    },
    '@keyframes cognitive-button-pulse': {
      '0%': {
        opacity: 0.5
      },
      '50%': {
        opacity: 1,
        color: '#005eb8'
      },
      '100%': {
        opacity: 0.5
      }
    },
    paperSuggestionContainer: {
      borderRadius: 0,
      border: '1px solid #dfe1e5',
      borderTop: 'none',
      boxShadow: '0 1px 6px 0 rgba(32,33,36,0.28)',
      borderBottomRightRadius: '22px',
      borderBottomLeftRadius: '22px'
    },
    paperSuggestionContainerMobile: {
      borderRadius: 0,
      border: 'none',
      boxShadow: 'none'
    },
    suggestionContainer: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'nowrap',
      flexDirection: 'row',
      justifyContent: 'space-between',
      fontSize: '16px',
      paddingTop: '3px',
      paddingBottom: '3px'
    },
    suggestionContentContainerWrapper: {
      flexGrow: 1
    },
    suggestionContentContainer: {
      display: 'flex',
      flexWrap: 'nowrap',
      alignItems: 'center'
    },
    suggestionIcon: {
      fill: '#9AA0A6',
      height: 20,
      width: 20
    },
    suggestionIconMobile: {
      fill: '#757575',
      height: 24,
      width: 24
    },
    suggestionText: {
      marginLeft: '10px',
      flex: '1',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      paddingRight: '1em'
    },
    suggestionHistory: {
      color: '#609',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      paddingRight: '1em'
    },
    suggestionRemove: {
      color: '#757575',
      fontSize: '12px',
      cursor: 'pointer',
      paddingRight: '16px',
      '&:hover': {
        textDecoration: 'underline',
        color: '#005eb8'
      }
    },
    removeLabel: {
      fontSize: 13
    },
    fullScreenSearch: {
      position: 'fixed',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      marginTop: 0,
      backgroundColor: '#fff',
      borderRadius: '0',
      overflow: 'auto',
      zIndex: 1200,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      maxHeight: '999999px',
      '& > div': {
        width: '100%',
        display: 'flex',
        borderBottom: '2px solid #e5e5e5'
      }
    },
    fullScreenBackIcon: {
      padding: 10,
      color: '#3b78e6',
      height: 24,
      width: 24,
      marginTop: 2,
      paddingLeft: 17,
      paddingRight: 0
    },
    fullScreenSearchIcon: {
      padding: '12px 18px 10px 14px',
      color: '#3b78e6',
      height: 24,
      width: 24
    },
    fullScreenInputComponent: {
      display: 'flex',
      width: '100%',
      height: '46px'
    },
    fullScreenClearButton: {
      display: 'flex',
      flex: 1,
      color: '#757575',
      cursor: 'pointer',
      font: '27px/25px arial,sans-serif',
      alignItems: 'center',
      padding: '0 15px',
      border: 0,
      background: 'transparent',
      zIndex: 1
    }
  }))
}
