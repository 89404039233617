import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Route, Routes } from 'react-router-dom'
import FilterBar from '../../FilterBar'
import ResultOpen from './ResultOpen'
import ResultCatalog from './ResultCatalog'
import PageStay from 'components/HOC/PageStay'
import { Store } from 'store'
import SettingsStore from 'store/Settings'

interface ResultKnowledgeExchange365Props {
  showLocalFilter: boolean
}

type AllProps = ReturnType<typeof mapStateToProps> &
  ResultKnowledgeExchange365Props

function ResultKnowledgeExchange(props: AllProps) {
  const { showLocalFilter, deviceSettings } = props

  const [internalDataSources] = useState([
    {
      name: 'Open',
      enabled: true,
      path: '/results/knowledgeexchange'
    },
    {
      name: 'Catalog',
      enabled: true,
      path: '/results/knowledgeexchange/catalog'
    }
  ])

  return (
    <>
      <Routes>
        <Route
          path={'/catalog'}
          element={
            <>
              <FilterBar
                hasSubverticals={true}
                showLocalFilter={showLocalFilter}
                deviceSettings={deviceSettings}
              />
              <ResultCatalog {...props} datasources={internalDataSources} />
            </>
          }
        />
        <Route
          path={'/'}
          element={
            <>
              <FilterBar
                hasSubverticals={true}
                showLocalFilter={showLocalFilter}
                deviceSettings={deviceSettings}
              />
              <ResultOpen {...props} datasources={internalDataSources} />
            </>
          }
        />
      </Routes>
    </>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state)
  }
}

export default PageStay(connect(mapStateToProps, null)(ResultKnowledgeExchange))
