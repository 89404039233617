import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Typography, Link, Box, Grid } from '@mui/material'
import * as stringUtils from 'utils/string'
import { externalLinkClicked } from 'utils/tracking'
import highlightedText, { prepareHighlightWords } from 'utils/highlightText'
import { getCurrentScope, removeQueryParam } from 'utils/queryParams'
import { generateUrlBreadcrumbs } from 'utils/string'
import { IDeviceSetting } from 'utils/deviceSettings'
import { useSelector, useDispatch } from 'react-redux'
import ResultsStore from 'store/Results'
import { getStylesMPPResults } from 'styles/results/MPPResults'
import createDOMPurify from 'dompurify'
import TooltipTitle from '../common/TooltipTitle'
import { CustomDescription } from '../common/CustomDescription'
import { ISynonymsApplied } from 'components/models/SynonymsApplied'
import { IResultServiceLine } from 'components/models/MppResult'
import mime from 'mime'

interface MPPResultProps {
  title: string
  body: string
  url: string
  manualName: string
  index: number
  owner: string
  searchQuery?: string
  deviceSettings: IDeviceSetting
  serviceLines: IResultServiceLine[]
  synonymsApplied: ISynonymsApplied[]
  initialItemClick: boolean
  setInitialItemClick: (value: boolean) => void
}

export function MPPResult(props: MPPResultProps): JSX.Element {
  const {
    title,
    body,
    url,
    manualName,
    index,
    owner,
    searchQuery,
    deviceSettings,
    serviceLines,
    synonymsApplied,
    initialItemClick,
    setInitialItemClick
  } = props
  const navigateFunction = useNavigate()
  const scope = getCurrentScope(false)
  const classes = getStylesMPPResults()
  const DOMPurify = createDOMPurify(window)

  const [isVisited, setVisited] = React.useState(
    useSelector((state: any) =>
      ResultsStore.selectors.isResultVisited(state, url)
    )
  )
  const dispatch = useDispatch()

  const setIsVisited = () => {
    if (!isVisited) {
      dispatch(ResultsStore.actions.storeResultHistory(url))
      setVisited(true)
    }
  }

  let docTypeFlair: string | null = null
  let urlBreadcrumbs = ''
  const maxBreadCrumbLength = deviceSettings.isMobile ? 35 : 79

  if (url) {
    const match = url.match(new RegExp('\\.(\\w+)($|\\?)', 'g'))
    let fileType = match && match.length > 0 ? match[0] : ''
    fileType = fileType.replace(/[?\\.]/g, '')

    // Check if some special chars are inside the filetype
    if (
      fileType &&
      !fileType.match(/[^A-Za-z0-9-]+/g) &&
      mime.getType(fileType)
    ) {
      const mimeType: string = mime.getType(fileType) as string
      docTypeFlair = mime.getExtension(mimeType)
    }

    // Build URL Breadcrumbs
    urlBreadcrumbs = generateUrlBreadcrumbs(scope, url, false)

    if (urlBreadcrumbs.length > maxBreadCrumbLength) {
      urlBreadcrumbs = stringUtils.truncate(urlBreadcrumbs, maxBreadCrumbLength)
    }
  }

  return (
    <Grid container>
      <Grid item xs>
        <Box className={classes.result}>
          <div className={classes.urlWrapper}>
            {!manualName && urlBreadcrumbs.length > 0 && (
              <Typography
                variant="body1"
                display="inline"
                className={classes.urlBreadcrumbs}
              >
                {urlBreadcrumbs}
              </Typography>
            )}
            {manualName && (
              <Typography
                variant="body1"
                display="inline"
                className={classes.urlBreadcrumbs}
              >
                {manualName}
              </Typography>
            )}
            {docTypeFlair && (
              <Typography
                variant="caption"
                display="inline"
                color="secondary"
                className={`${classes.docType} ${classes.docTypeWithMargin}`}
              >
                {docTypeFlair}
              </Typography>
            )}
          </div>
          <Link
            className={classes.link}
            {...(deviceSettings.openLinksInNewTab === true ||
            initialItemClick === true
              ? { target: '_blank', rel: 'noreferrer' }
              : {})}
            href={url}
            onClick={(event: any) => {
              setIsVisited()
              removeQueryParam(navigateFunction, 'cntx')
              externalLinkClicked({
                title,
                url,
                index,
                featured: false
              })
              setInitialItemClick(false)
            }}
            {...(!deviceSettings.isMobile
              ? {
                  onAuxClick: (event: any) => {
                    setIsVisited()
                    removeQueryParam(navigateFunction, 'cntx')
                    externalLinkClicked({
                      title,
                      url,
                      index,
                      featured: false,
                      wasRightClicked: event?.button === 2 ? true : false
                    })
                  }
                }
              : {})}
          >
            <Typography
              variant="h5"
              color="primary"
              className={`${classes.title} ${
                isVisited ? classes.isVisitedResult : ''
              }`}
              component="div"
            >
              <TooltipTitle title={title ? title : ''} singleLine={true} />
            </Typography>
          </Link>
          <Typography
            variant="body2"
            color="secondary"
            gutterBottom
            className={`${classes.body} txt-resultdescription-01`}
            component="div"
          >
            <CustomDescription
              descriptionValue={
                body
                  ? highlightedText(
                      body.replace(/(\r\n|\n|\r)/gm, ' '),
                      prepareHighlightWords(
                        searchQuery,
                        body.replace(/(\r\n|\n|\r)/gm, ' '),
                        synonymsApplied
                      ),
                      true
                    )
                  : ''
              }
            />
          </Typography>
          {((serviceLines && serviceLines.length > 0) || !!owner) && (
            <Box className={classes.serviceLinesContainer}>
              {!!owner && (
                <Link
                  className={classes.serviceLineBox}
                  href={url}
                  style={{ fontWeight: 'bold' }}
                  {...(deviceSettings.openLinksInNewTab === true ||
                  initialItemClick === true
                    ? { target: '_blank', rel: 'noreferrer' }
                    : {})}
                  onClick={(event: any) => {
                    externalLinkClicked({
                      title: `${title} - ServieLine: ${owner}`,
                      url: url,
                      index,
                      featured: false
                    })
                    setInitialItemClick(false)
                  }}
                  {...(!deviceSettings.isMobile
                    ? {
                        onAuxClick: (event: any) => {
                          externalLinkClicked({
                            title: `${title} - ServieLine: ${owner}`,
                            url: url,
                            index,
                            featured: false,
                            wasRightClicked: event?.button === 2 ? true : false
                          })
                        }
                      }
                    : {})}
                >
                  {owner}
                </Link>
              )}
              {serviceLines ? (
                serviceLines.map((serviceLine) => {
                  const serviceLineTitleSanitized = DOMPurify.sanitize(
                    serviceLine.serviceline ? serviceLine.serviceline : ''
                  )
                  if (
                    serviceLineTitleSanitized !== '' &&
                    serviceLine.owner !== owner
                  ) {
                    return (
                      <Link
                        className={classes.serviceLineBox}
                        href={serviceLine.url}
                        {...(deviceSettings.openLinksInNewTab === true ||
                        initialItemClick === true
                          ? { target: '_blank', rel: 'noreferrer' }
                          : {})}
                        onClick={(event: any) => {
                          externalLinkClicked({
                            title: `${title} - ServieLine: ${serviceLineTitleSanitized}`,
                            url: serviceLine.url,
                            index,
                            featured: false
                          })
                          setInitialItemClick(false)
                        }}
                        {...(!deviceSettings.isMobile
                          ? {
                              onAuxClick: (event: any) => {
                                externalLinkClicked({
                                  title: `${title} - ServieLine: ${serviceLineTitleSanitized}`,
                                  url: serviceLine.url,
                                  index,
                                  featured: false,
                                  wasRightClicked:
                                    event?.button === 2 ? true : false
                                })
                              }
                            }
                          : {})}
                      >
                        {serviceLineTitleSanitized}
                      </Link>
                    )
                  }
                  return false
                })
              ) : (
                <></>
              )}
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  )
}
