import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesResultsFiles(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles(): any {
  return makeStyles((theme: Theme) => ({
    downloadContainer: {
      marginTop: '1em',
      marginLeft: '.5em'
    },
    downloadCircularProgress: {
      marginLeft: '1em'
    }
  }))
}
