import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesStartPage(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles() {
  return makeStyles((theme: Theme) => ({
    title: {
      fontFamily: 'KPMG',
      fontSize: '6em',
      MozUserSelect: 'none',
      WebkitUserSelect: 'none',
      msUserSelect: 'none',
      paddingLeft: '10px',
      textAlign: 'left',
      lineHeight: '1em',
      [theme.breakpoints.down(500)]: {
        fontSize: '60px',
        MozUserSelect: 'none',
        WebkitUserSelect: 'none',
        msUserSelect: 'none',
        marginTop: '6px',
        textAlign: 'start'
      }
    },
    para: {
      textAlign: 'left',
      paddingLeft: 12,
      marginTop: -8,
      marginBottom: 8,
      color: '#9AA0A6',
      [theme.breakpoints.down(500)]: {
        fontSize: '11px'
      }
    },
    root: {
      margin: '20vh 0',
      [`${theme.breakpoints.down(1024)}`]: {
        margin: '10vh 0'
      }
    },
    findBtn: {
      textTransform: 'none',
      fontWeight: 'normal',
      [theme.breakpoints.down(501)]: {
        fontSize: '15px'
      },
      [theme.breakpoints.down(401)]: {
        fontSize: '12px'
      },
      [`${theme.breakpoints.down(1024)}`]: {
        background: '#005eb8'
      }
    },
    askGoogle: {
      color: 'darkgrey',
      textTransform: 'none',
      fontWeight: 'normal',
      [theme.breakpoints.down(501)]: {
        fontSize: '15px'
      },
      [theme.breakpoints.down(401)]: {
        fontSize: '12px'
      }
    },
    logo: {
      width: 100,
      height: 100,
      marginTop: 10,
      [theme.breakpoints.down(500)]: {
        width: '60px',
        height: '60px',
        marginTop: 0
      }
    },
    center: {
      alignItems: 'center',
      justifyContent: 'center'
    },
    slider: {
      width: '80px'
    },
    buttonRow: {
      margin: 0,
      width: '100%'
    }
  }))
}
