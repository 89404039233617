import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesSettings(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles() {
  return makeStyles((theme: Theme) => ({
    dialogContainer: {
      height: 'auto',
      '& .MuiDialog-paperScrollPaper': {
        width: '100%'
      },
      [`${theme.breakpoints.down(769)}`]: {
        '& .MuiDialog-paper': {
          margin: 0
        },
        '& .MuiDialog-paperScrollPaper': {
          maxHeight: '100%',
          height: '100%'
        },
        '& .MuiDialogContent-root': {
          padding: 0
        }
      }
    },
    title: {
      fontFamily: 'KPMG',
      fontSize: '6em',
      MozUserSelect: 'none',
      WebkitUserSelect: 'none',
      msUserSelect: 'none'
    },
    root: {
      marginTop: '14vh'
    },
    find: {
      textTransform: 'none',
      fontWeight: 'normal'
    },
    google: {
      color: 'darkgrey',
      textTransform: 'none',
      fontWeight: 'normal'
    },
    logo: {
      width: 90,
      height: 90,
      marginRight: '2vw'
    },
    background: {
      background: '#f6f7fA',
      height: '100%',
      width: '100%',
      [`${theme.breakpoints.down(1024)}`]: {
        width: '100%'
      }
    },
    spacer: {
      height: 32
    },
    spacerLogout: {
      height: 16
    },
    sectionTitle: {
      color: '#424242',
      paddingBottom: 8,
      display: 'inline-block',
      marginRight: 8,
      [`${theme.breakpoints.down(769)}`]: {
        color: '#ffffff',
        background: '#005eb8',
        textAlign: 'center',
        padding: '16px 24px',
        display: 'block'
      }
    },
    sectionDescription: {
      color: '#424242',
      paddingBottom: 8,

      display: 'inline-block',
      [`${theme.breakpoints.down(769)}`]: {
        textAlign: 'center',
        display: 'block'
      }
    },
    list: {
      paddingBottom: '0px'
    },
    listHeader: {
      paddingTop: 16,
      paddingBottom: 0,
      color: '#424242'
    },
    listHeaderDescription: {
      color: '#9e9e9e'
    },
    listItem: {
      color: '#424242',
      minHeight: '54px',
      overflowWrap: 'break-word'
    },
    iconItem: {
      minWidth: 'auto',
      margin: 'auto 0',
      [`& >svg`]: {
        margin: 'auto'
      }
    },
    gridSwitchContainer: {
      display: 'flex',
      justifyContent: 'flex-end'
    },
    descriptionRow: {
      marginTop: 16,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    descriptionBuild: {
      fontSize: '10px',
      marginLeft: 16
    },
    divTable: {
      display: 'table',
      width: '100%'
    },
    divTableRow: {
      display: 'table-row'
    },
    divTableBuildCell: {
      display: 'table-cell',
      verticalAlign: 'middle'
    },
    divTableButtonsCell: {
      display: 'table-cell',
      float: 'right',
      marginRight: 16,
      [`${theme.breakpoints.down(769)}`]: {
        marginRight: 0
      }
    },
    descriptionColumn: {
      marginLeft: 16
    },
    information: {
      padding: '8px 0',
      borderRadius: 4,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      [`${theme.breakpoints.down(1024)}`]: {
        padding: '8px 15px'
      }
    },
    informationText: {
      marginLeft: 8
    },
    select: {
      width: '100%',
      background: '#f6f7fA',
      borderRadius: 4,
      color: '#424242',
      '& .MuiInput-input': {
        paddingLeft: 8
      },
      '&::before': {
        borderBottom: '1px solid transparent'
      }
    },
    dialogActions: {
      background: '#f6f7fA',
      padding: '16px 8px'
    },
    dialogContent: {
      background: '#f6f7fA',
      maxHeight: '600px',
      [`${theme.breakpoints.down(769)} and (orientation: portrait)`]: {
        height: '100%',
        width: '100%',
        maxHeight: '100%'
      }
    },
    dialogTitle: {
      background: '#005eb8',
      color: '#ffffff',
      textAlign: 'center'
    },
    dragAndDropList: {
      WebkitTouchCallout: 'none',
      WebkitUserSelect: 'none',
      KhtmlUserSelect: 'none',
      MozUserSelect: 'none',
      msUserSelect: 'none',
      userSelect: 'none'
    },
    cancelButton: {
      marginRight: 8,
      border: '1px solid #005eb8'
    },
    userInfoContainer: {
      padding: 16,
      [`${theme.breakpoints.down(769)}`]: {
        paddingTop: 24
      }
    },
    userInfoTitle: {
      paddingBottom: 16,
      paddingTop: 10,
      [`${theme.breakpoints.down(769)}`]: {
        padding: '16px 24px'
      }
    },
    userInfoDescription: {
      width: '70%',
      color: '#424242'
    },
    infoMessage: {
      '& span': {
        fontSize: '13px'
      }
    },
    iconContainer: {
      alignSelf: 'flex-start',
      display: 'flex',
      height: '32px'
    },
    personIconContainer: {
      width: 80,
      height: 80
    },
    personIcon: {
      color: 'grey',
      padding: 0
    },
    logoutContainer: {
      textAlign: 'center'
    },
    logoutBtn: {
      width: '33.3%',
      backgroundColor: '#fff',
      color: '#000',
      border: '1px solid #bc204b'
    },
    resetSettingsBtn: {
      width: '33.3%',
      backgroundColor: '#fff',
      color: '#000',
      border: '1px solid #004e98',
      marginRight: 20
    },
    switchTrack: {
      '@media (forced-colors: active)': {
        forcedColorAdjust: 'none',
        background: 'CanvasText !important'
      }
    },
    switchTrackActive: {
      '@media (forced-colors: active)': {
        forcedColorAdjust: 'none',
        background: 'Highlight !important'
      }
    },
    switchThumbActive: {
      '@media (forced-colors: active)': {
        forcedColorAdjust: 'none',
        color: 'Highlight !important'
      }
    },
    switchThumb: {
      '@media (forced-colors: active)': {
        forcedColorAdjust: 'none',
        color: 'CanvasText !important'
      }
    },
    switchThumbDisabled: {
      '@media (forced-colors: active)': {
        forcedColorAdjust: 'none',
        color: 'GrayText !important'
      }
    },
    savebtn: {
      '@media (forced-colors: active)': {
        border: '1px solid'
      }
    }
  }))
}
