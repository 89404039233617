import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesFaq(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles() {
  return makeStyles((theme: Theme) => ({
    dialogContainer: {
      height: 'auto',
      [`${theme.breakpoints.down(1024)}`]: {
        '& .MuiDialog-paper': {
          margin: 0
        },
        '& .MuiDialog-paperScrollPaper': {
          maxHeight: '100%',
          height: '100%'
        }
      }
    },
    dialog: {
      width: '600px',
      position: 'relative',
      margin: 'auto',
      padding: 0
    },
    dialogPaper: {
      minHeight: '300px',
      maxHeight: '400px'
    },
    container: {
      padding: 32,
      marginTop: 54
    },
    content: {
      padding: 16,
      color: '#424242'
    },
    title: {
      color: '#424242'
    },
    description: {
      color: '#424242'
    },
    dialogActions: {
      background: '#f6f7fA',
      padding: '16px 24px'
    },
    dialogContent: {
      background: '#f6f7fA',
      height: '600px',
      display: 'flex',
      flexDirection: 'column',
      [`${theme.breakpoints.down(1024)}`]: {
        padding: '8px 8px'
      }
    },
    dialogTitle: {
      background: '#005eb8',
      color: '#ffffff',
      textAlign: 'center'
    },
    icon: {
      marginRight: 20
    },
    image: {
      width: 24,
      height: 24,
      marginRight: 20,
      borderRadius: 4,
      color: '#424242'
    },
    spacer: {
      flex: 1
    },
    closeButton: {
      border: '1px solid #005eb8',
      background: 'transparent'
    }
  }))
}
