import { FeaturedResult } from 'components/models/FeaturedResult'
import { Store } from '..'
import { IIbfdFilterResults } from './reducers'
import { IIbfdResult } from 'components/models/IbfdResult'

const selectors = {
  getFeaturedResults: (state: Store): FeaturedResult[] =>
    state.resultsIbfd.featuredResults,
  getResults: (state: Store): IIbfdResult[] => state.resultsIbfd.results,
  getResultsCombined: (state: Store): IIbfdResult[] =>
    state.resultsIbfd.resultsCombined,
  getResultsCombinedQuery: (state: Store): any =>
    state.resultsIbfd.resultsCombinedQuery,
  hasResultsBeenFetched: (state: Store): any =>
    state.resultsIbfd.hasResultsBeenFetched,
  getResultCount: (state: Store): any => state.resultsIbfd.resultCount,
  getExecutionTime: (state: Store): any => state.resultsIbfd.executionTime,
  getCurrentPage: (state: Store): any => state.resultsIbfd.currentPage,
  getFilters: (state: Store): IIbfdFilterResults => state.resultsIbfd.filters,
  getCountryError: (state: Store): boolean => state.resultsIbfd.hasCountryError
}

export default selectors
