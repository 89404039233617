import React, { useCallback, useEffect, useMemo } from 'react'
import ReactPlaceholder from 'react-placeholder'
import { Box, Fade } from '@mui/material'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import 'Placeholder.css'
import { Store } from 'store'
import UserSettingsStore from 'store/UserSettings'
import SettingsStore from 'store/Settings'
import ResultsProjectSpacesStore from 'store/ResultsProjectSpaces'
import ResultMetaDataStore from 'store/ResultMetaData'
import ErrorPage from 'components/contents/common/ErrorPage'
import PageStay from 'components/HOC/PageStay'
import TextResult from 'components/contents/results/TextResult'
import Pagination from '../Pagination'
import { trackException } from 'utils/tracking'
import { useReactRouterQueryStringInterface } from 'utils/useQueryState'
import { authorizeResultPage } from 'utils/authorization'
import createDOMPurify from 'dompurify'
import { dateFormatOptions, dataSourcesTabs } from 'constants/constants'
import RightTopWidgetContainer from './widgetscontainer/RightTopWidgetContainer'
import LeftWidgetContainer from './widgetscontainer/LeftWidgetContainer'
import RightWidgetContainer from './widgetscontainer/RightWidgetContainer'
import { getStylesResults } from 'styles/contents/resultpages/Results'
import { useFilter } from 'utils/filters'
import { KPMGFindGlobalVariables } from 'store/KPMGFindGlobalVariables'
import { useLocation } from 'react-router-dom'

type AllProps = ReturnType<typeof mapStateToProps>

function ResultsProjectSpaces(props: AllProps) {
  const {
    results,
    resultsCount,
    hasResultsBeenFetched,
    hasErrorStore,
    userSettings,
    deviceSettings
  } = props
  const DOMPurify = createDOMPurify(window)
  const resultClasses = getStylesResults()
  const { getQueryString } = useReactRouterQueryStringInterface()
  const intl = useIntl()
  const queryStringFromUrl = getQueryString()

  const [currentPage] = useFilter('page', '1')
  const [searchQuery] = useFilter('q')
  const location = useLocation()

  useEffect(() => {
    KPMGFindGlobalVariables.setCurrentTab(dataSourcesTabs.project_spaces)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setup = useCallback(() => {
    try {
      authorizeResultPage(
        'Projekt Laufwerke',
        userSettings,
        deviceSettings,
        DOMPurify.sanitize(location && location.search ? location.search : '')
      )
    } catch (error) {
      trackException(
        'Error in fetching results in ResultsProjectSpaces.tsx',
        error
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryStringFromUrl])

  useEffect(() => {
    setup()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryStringFromUrl])

  const returnAllItems = (type: string) => {
    if (!results || results.length < 1) {
      return []
    }
    const items = []

    try {
      const firstResults = results.length < 4 ? results.length : 3

      if (type === 'first') {
        for (let i = 0; i < firstResults; i++) {
          const currentDate = results[i].createdDate
            ? intl.formatDate(
                DOMPurify.sanitize(results[i].createdDate),
                dateFormatOptions
              )
            : ''
          const currentSource = results[i].sources
            ? DOMPurify.sanitize(results[i].sources.join(' | '))
            : ''
          items.push(
            <TextResult
              title={DOMPurify.sanitize(results[i].title)}
              body={
                results[i].synopsis
                  ? DOMPurify.sanitize(results[i].synopsis)
                  : ''
              }
              url={results[i].url ? DOMPurify.sanitize(results[i].url) : ''}
              displayUrl={`${currentDate}${
                currentDate && currentSource && currentSource.length > 0
                  ? ' › '
                  : ''
              }${currentSource}`}
              featured={false}
              showContextMenu={false}
              key={i}
              index={i}
              deviceSettings={deviceSettings}
            />
          )
        }
      } else {
        for (let i = firstResults; i < results.length; i++) {
          const currentDate = results[i].createdDate
            ? intl.formatDate(
                DOMPurify.sanitize(results[i].createdDate),
                dateFormatOptions
              )
            : ''
          const currentSource = results[i].sources
            ? DOMPurify.sanitize(results[i].sources.join(' | '))
            : ''
          items.push(
            <TextResult
              title={DOMPurify.sanitize(results[i].title)}
              body={
                results[i].synopsis
                  ? DOMPurify.sanitize(results[i].synopsis)
                  : ''
              }
              url={results[i].url ? DOMPurify.sanitize(results[i].url) : ''}
              displayUrl={`${currentDate}${
                currentDate && currentSource && currentSource.length > 0
                  ? ' › '
                  : ''
              }${currentSource}`}
              featured={false}
              showContextMenu={false}
              key={i}
              index={i}
              deviceSettings={deviceSettings}
            />
          )
        }
      }
    } catch (error) {
      trackException(
        'Error returning all items in ResultsProjectSpaces.tsx',
        error
      )
    }
    return items
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const firstItems = useMemo(() => returnAllItems('first'), [results, intl])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const restItems = useMemo(() => returnAllItems('rest'), [results, intl])

  return (
    <Box>
      {!hasErrorStore && !!searchQuery && (
        <Box className={resultClasses.container}>
          <Box
            id={'con-resultvertical-01'}
            className={resultClasses.resultsContainer}
          >
            <ReactPlaceholder
              ready={hasResultsBeenFetched ? hasResultsBeenFetched : false}
              type="text"
              rows={4}
              showLoadingAnimation={true}
              className={resultClasses.placeholder}
            >
              <Fade
                in={hasResultsBeenFetched ? hasResultsBeenFetched : false}
                timeout={600}
              >
                <Box id={'con-resultvertical-section-01'}>{firstItems}</Box>
              </Fade>
            </ReactPlaceholder>
            {Number(currentPage) === 1 && (
              <LeftWidgetContainer
                resultCount={resultsCount}
                hasResultsBeenFetched={hasResultsBeenFetched}
              />
            )}
            <ReactPlaceholder
              ready={hasResultsBeenFetched}
              type="text"
              rows={4}
              showLoadingAnimation={true}
              className={resultClasses.placeholder}
            >
              <Fade in={hasResultsBeenFetched} timeout={600}>
                <Box id={'con-resultvertical-section-02'}>
                  {restItems}
                  {resultsCount != null && resultsCount > 0 && (
                    <Pagination
                      {...props}
                      totalRecords={resultsCount}
                      pageLimit={20}
                      pageNeighbours={4}
                    />
                  )}
                </Box>
              </Fade>{' '}
            </ReactPlaceholder>
            <ReactPlaceholder
              ready={hasResultsBeenFetched ? hasResultsBeenFetched : false}
              type="text"
              rows={4}
              showLoadingAnimation={true}
              className={resultClasses.placeholder}
            >
              <Box />
            </ReactPlaceholder>
          </Box>
          <Box className={resultClasses.advertisementContainer}>
            <RightTopWidgetContainer
              searchQuery={searchQuery}
              scope={'projectspaces'}
            />
            <RightWidgetContainer />
          </Box>
        </Box>
      )}
      {hasErrorStore && <ErrorPage dataSource="projectspaces" />}
    </Box>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    results: ResultsProjectSpacesStore.selectors.getResults(state),
    resultsCount: ResultsProjectSpacesStore.selectors.getResultCount(state),
    executionTime: ResultsProjectSpacesStore.selectors.getExecutionTime(state),
    hasResultsBeenFetched:
      ResultsProjectSpacesStore.selectors.hasResultsBeenFetched(state),
    hasErrorStore: ResultMetaDataStore.selectors.getDataSourceHasError(
      state,
      'projectspaces'
    ),
    userSettings: UserSettingsStore.selectors.getUserSettings(state),
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state)
  }
}

export default PageStay(connect(mapStateToProps)(ResultsProjectSpaces))
