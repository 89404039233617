import React, { useEffect, useState } from 'react'
// import LazyLoad from 'react-lazyload'
import { Typography, Link, Box, Grid, CardMedia } from '@mui/material'
import { externalLinkClicked } from 'utils/tracking'
import highlightedText, { prepareHighlightWords } from 'utils/highlightText'
import { useSelector, useDispatch } from 'react-redux'
import ResultsStore from 'store/Results'
import { getStylesKlardenkerResult } from 'styles/results/KlardenkerResult'
import { IDeviceSetting } from 'utils/deviceSettings'
import TooltipTitle from '../common/TooltipTitle'
import KlardenkerPlaceHolder from 'images/kpmg_placeholder.png'
import { CustomDescription } from '../common/CustomDescription'
import { getKlardenkerImage } from 'utils/klardenker'

export interface KlardenkerResultProps {
  id: string
  title: string
  body: string
  url: string
  imageUrl: string
  index?: any
  searchQuery?: string
  breadcrumbContent?: string
  deviceSettings: IDeviceSetting
  authToken: any
}

export function KlardenkerResult(props: KlardenkerResultProps): JSX.Element {
  const {
    id,
    title,
    body,
    url,
    imageUrl,
    index,
    searchQuery,
    breadcrumbContent,
    deviceSettings,
    authToken
  } = props
  const classes = getStylesKlardenkerResult()

  const dispatch = useDispatch()
  const [isVisited, setVisited] = React.useState(
    useSelector((state: any) =>
      ResultsStore.selectors.isResultVisited(state, url)
    )
  )
  const [imageUrlFallBack, setImageUrlFallBack] = useState(imageUrl)

  useEffect(() => {
    // Lazy load the image result
    if (!deviceSettings.renderMobile && !imageUrl && id) {
      getKlardenkerImage(id, authToken).then(
        (lazyLoadedImageUrl: string | null) => {
          if (lazyLoadedImageUrl) {
            setImageUrlFallBack(lazyLoadedImageUrl)
          }
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setIsVisited = () => {
    if (!isVisited) {
      dispatch(ResultsStore.actions.storeResultHistory(url))
      setVisited(true)
    }
  }

  return (
    // <LazyLoad offset={150}>
    <Grid container className={classes.klarWholeResult}>
      {!deviceSettings.renderMobile && (
        <Grid item xs={3}>
          <CardMedia
            className={classes.klarPreviewPic}
            style={{
              backgroundImage:
                imageUrlFallBack != null && imageUrlFallBack.length > 0
                  ? `url("${imageUrlFallBack}"), url("${imageUrlFallBack.replace(
                      '/355/',
                      '/754/'
                    )}"),url("${KlardenkerPlaceHolder}")`
                  : `url("${KlardenkerPlaceHolder}")`,
              backgroundColor: '#7787a1'
            }}
          />
        </Grid>
      )}
      <Grid
        item
        xs={deviceSettings.renderMobile ? 12 : 9}
        className={classes.klarGridItem}
      >
        <Box className={classes.result} style={{ marginBottom: 0 }}>
          <Typography
            variant="body1"
            display="inline"
            className={classes.urlBreadcrumbs}
          >
            {breadcrumbContent}
          </Typography>
          <Link
            href={url}
            underline={'hover'}
            onClick={() => {
              setIsVisited()
              externalLinkClicked({ title, url, index })
            }}
            {...(!deviceSettings.isMobile
              ? {
                  onAuxClick: (event: any) => {
                    setIsVisited()
                    externalLinkClicked({
                      title,
                      url,
                      index,
                      wasRightClicked: event?.button === 2 ? true : false
                    })
                  }
                }
              : {})}
            {...(deviceSettings.openLinksInNewTab === true
              ? { target: '_blank', rel: 'noreferrer' }
              : {})}
          >
            <Typography
              variant="h5"
              noWrap
              color="primary"
              className={`${classes.title} ${
                isVisited ? classes.isVisitedResult : ''
              }`}
              component="div"
            >
              <TooltipTitle title={title ? title : ''} singleLine={true} />
            </Typography>
          </Link>
          <Typography
            variant="body2"
            color="secondary"
            className={classes.klarBodyText}
            component="div"
          >
            <CustomDescription
              descriptionValue={
                body
                  ? highlightedText(
                      body.replace(/(\r\n|\n|\r)/gm, ' '),
                      prepareHighlightWords(
                        searchQuery,
                        body.replace(/(\r\n|\n|\r)/gm, ' '),
                        []
                      ),
                      true
                    )
                  : ''
              }
            />
          </Typography>
        </Box>
      </Grid>
    </Grid>
    // </LazyLoad>
  )
}
