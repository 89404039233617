import React, { useState, Dispatch, useEffect } from 'react'
import { connect } from 'react-redux'
import { Store } from 'store'
import { Box, Button, IconButton, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import Slider from 'react-slick'
import { LeftArrow, RightArrow } from './common/Arrows'
import TranslationsStore from 'store/Translations'
import UserSettingsStore from 'store/UserSettings'
import 'styles/slick_carousel/slick-theme.css'
import 'styles/slick_carousel/slick.css'
import { getStylesIntroduction } from 'styles/contents/Introduction'

export interface IntroductionProps {
  open: boolean
  onClose: () => void
}

type AllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IntroductionProps

function Introduction(props: AllProps) {
  const classes = getStylesIntroduction()

  const [introductionItems, setIntroductionItems] = useState([])
  const [introductionLanguage, setIntroductionLanguage] = useState('')

  const {
    open,
    onClose,
    getIntroductionTranslations,
    introductionTranslations,
    userSettings
  } = props

  useEffect(() => {
    if (
      !introductionTranslations ||
      introductionTranslations.length === 0 ||
      (userSettings.Language || 'en') !== introductionLanguage
    ) {
      getIntroductionTranslations(userSettings.Language || 'en')
      setIntroductionLanguage(userSettings.Language || 'en')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  useEffect(() => {
    if (introductionTranslations && introductionTranslations.length > 0) {
      setIntroductionItems(introductionTranslations)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [introductionTranslations])

  const introductionContainer =
    open === true ? classes.container : classes.invisible

  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    nextArrow: <RightArrow />,
    prevArrow: <LeftArrow />
  }

  return (
    <>
      {introductionItems && introductionItems.length > 0 && (
        <div className={`${introductionContainer}`}>
          <Slider className={classes.slider} {...settings}>
            {introductionItems &&
              introductionItems.map((item: any, index: number) => (
                <Box key={index} className={classes.slideContainer}>
                  <IconButton className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                  </IconButton>
                  <img
                    className={classes.img}
                    src={process.env.PUBLIC_URL + item.image}
                    alt="Introduction"
                  />
                  <Box className={classes.content}>
                    <Typography variant="h5" className={classes.title}>
                      {item.title}
                    </Typography>
                    <Typography component="p" className={classes.description}>
                      {item.description}
                    </Typography>
                    {index === introductionItems.length - 1 && (
                      <>
                        <Box className={classes.spacer} />
                        <Button
                          variant="contained"
                          size="large"
                          color="primary"
                          className={classes.getStartedButton}
                          onClick={onClose}
                        >
                          Get Started
                        </Button>
                      </>
                    )}
                  </Box>
                </Box>
              ))}
          </Slider>
        </div>
      )}
    </>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    introductionTranslations:
      TranslationsStore.selectors.getIntroductionTranslations(state),
    userSettings: UserSettingsStore.selectors.getUserSettings(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    getIntroductionTranslations: (language: string) =>
      dispatch(
        TranslationsStore.actions.fetchIntroductionTranslations(language)
      )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Introduction)
