import React, {
  Dispatch,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import { Box, CircularProgress, Fade } from '@mui/material'
import ReactPlaceholder from 'react-placeholder'
import { connect } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'
import 'Placeholder.css'
import { Store } from 'store'
import AuthStore from 'store/Auth'
import UserSettingsStore from 'store/UserSettings'
import SettingsStore from 'store/Settings'
import ResultsMarketResearchStore from 'store/ResultsMarketResearch'
import ResultMetaDataStore from 'store/ResultMetaData'
import ErrorPage from 'components/contents/common/ErrorPage'
import PageStay from 'components/HOC/PageStay'
import { MarketResearchResult } from 'components/contents/results/MarketResearchResult'
import RightTopWidgetContainer from 'components/contents/resultpages/widgetscontainer/RightTopWidgetContainer'
import ResultsMarketResearchMobile from './ResultsMarketResearchMobile'
import Pagination from '../Pagination'
import { trackException } from 'utils/tracking'
import { useReactRouterQueryStringInterface } from 'utils/useQueryState'
import { authorizeResultPage } from 'utils/authorization'
import createDOMPurify from 'dompurify'
import { dataSourcesTabs } from 'constants/constants'
import SearchSuggestions from 'components/contents/common/SearchSuggestions'
import { getStylesResultsMarketResearch } from 'styles/contents/resultpages/ResultsMarketResearch'
import FeaturedResults from './FeaturedResults'
import { dateFormatOptions } from 'constants/constants'
import * as Config from 'config'
import LeftWidgetContainer from './widgetscontainer/LeftWidgetContainer'
import RightWidgetContainer from './widgetscontainer/RightWidgetContainer'
import { getCurrentScope } from 'utils/queryParams'
import { getStylesResults } from 'styles/contents/resultpages/Results'
import { useFilter } from 'utils/filters'
import { KPMGFindGlobalVariables } from 'store/KPMGFindGlobalVariables'
import { CognitiveMessage } from '../common/CognitiveMessage'
import { IUserSetting } from 'utils/userSettings'
import { fileTypeFromBuffer } from 'file-type'
import { useLocation } from 'react-router-dom'
import mime from 'mime'

export interface ResultsMarketResearchProps {}

type AllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  ResultsMarketResearchProps

function ResultsMarketResearch(props: AllProps) {
  const {
    featuredResults,
    resultsCombined,
    hasResultsBeenFetched,
    hasErrorStore,
    userSettings,
    deviceSettings,
    resultMetaList,
    authToken,
    findConfiguration,
    setUserSettings,
    synonymsApplied,
    useCognitiveSearch
  } = props
  const DOMPurify = createDOMPurify(window)
  const resultClasses = getStylesResults()
  const classes = getStylesResultsMarketResearch()
  const { getQueryString } = useReactRouterQueryStringInterface()
  const [documentId, setDocumentId] = useFilter('mrDocumentId', '')
  const [fileType, setFileType] = useFilter('mrFileType', '')
  const [downloadMessage, setDownloadMessage] = useState(true)
  const intl = useIntl()

  const queryStringFromUrl = getQueryString()
  const [isLoadingDocument, setIsLoadingDocument] = useState(-1)

  const [currentPage] = useFilter('page', '1')
  const [searchQuery] = useFilter('q')
  const location = useLocation()

  const [cognitiveSearchEnabled, setCognitiveSearchEnabled] =
    useState(useCognitiveSearch)
  const [showCognitiveMessage, setShowCognitiveMessage] = useState(
    userSettings.ShowCognitiveMessage
  )

  const scope = getCurrentScope(false)
  let currentMeta = null
  if (resultMetaList && resultMetaList.find) {
    currentMeta = resultMetaList.find(
      (resultMetaItem: any) => resultMetaItem.datasource === scope
    )
  }
  const resultsCount = currentMeta ? currentMeta.resultsCount : 0

  useEffect(() => {
    KPMGFindGlobalVariables.setCurrentTab(dataSourcesTabs.marketresearch)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (documentId && fileType) {
      fetchDocument(documentId, fileType)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentId, fileType])

  useEffect(
    () => {
      setCognitiveSearchEnabled(useCognitiveSearch)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [useCognitiveSearch]
  )

  useEffect(
    () => {
      setShowCognitiveMessage(userSettings.ShowCognitiveMessage)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userSettings.ShowCognitiveMessage]
  )

  function fetchDocument(docId: string, docType: string): void {
    if (isLoadingDocument !== -1) return

    if (setIsLoadingDocument) setIsLoadingDocument(parseInt(docId))

    let docName: string | null = null
    let headerFileTitle: string | null = null
    let headerFileContentType: string | null = null

    const apiUrl = `${Config.APIM_BASE_URL}marketresearchapi/getdocument?docid=${docId}&doctype=${docType}`
    fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
        'content-type': 'application/json',
        Authorization: `Bearer ${authToken}`
      }
    })
      .then((res) => {
        if (setIsLoadingDocument) setIsLoadingDocument(-1)
        setDocumentId('')
        setFileType('')
        setDownloadMessage(true)
        if (res && res.headers) {
          headerFileTitle = DOMPurify.sanitize(res.headers.get('Content-Title'))
          headerFileContentType = DOMPurify.sanitize(
            res.headers.get('content-type')
          )
        }
        return res.blob()
      })
      .then(async (blob) => {
        //since the api return wrong file extensions (also as http response header),
        //we try to detect it by our own.
        const buffer = await blob.arrayBuffer()
        const realFileType = await fileTypeFromBuffer(new Uint8Array(buffer))
        let realDocType = null
        if (realFileType && realFileType.ext && realFileType.ext !== 'cfb') {
          //set file extension via file header
          docName = docId + '.' + realFileType.ext
          realDocType = realFileType.ext
        } else if (
          realFileType &&
          realFileType.ext &&
          realFileType.ext === 'cfb' &&
          headerFileContentType
        ) {
          //fallback for not accepted: MS-CFB: Microsoft Compound File Binary File Format based formats
          const mimeTypeExt = mime.getExtension(headerFileContentType)
          docName = docId + '.' + mimeTypeExt
          realDocType = mimeTypeExt
        } else if (docType !== 'activeDoc') {
          //use given filename.
          //filter out activeDoc, activeDoc stands for different file formats (htm,xls,xlsx,..)
          docName = docId + '.' + docType
          realDocType = docType
        } else {
          //fileheader & given filename failed, try to lookup via blob filetype
          const mimeType: string = mime.getType(blob.type) as string
          const mimeTypeExt = mime.getExtension(mimeType)
          if (mimeTypeExt) {
            docName = docId + '.' + mimeTypeExt
            realDocType = mimeTypeExt
          } else {
            //lookup via blob filetype failed, fallback, use http header via "Content-Title"
            let fileNameSplit = null
            if (headerFileTitle) {
              fileNameSplit = headerFileTitle.split('.')
            }
            if (headerFileTitle && fileNameSplit && fileNameSplit.length > 1) {
              docName = headerFileTitle
              realDocType = fileNameSplit[fileNameSplit.length - 1]
            } else {
              //http header failed, giving up
              docName = docId + '.' + docType
              realDocType = docType
            }
          }
        }

        if (setIsLoadingDocument) setIsLoadingDocument(-1)
        const fileURL = URL.createObjectURL(blob)
        if (
          realDocType &&
          (realDocType.toLocaleLowerCase() === 'pdf' ||
            realDocType.toLocaleLowerCase() === 'htm' ||
            realDocType.toLocaleLowerCase() === 'html')
        ) {
          window.location.href = fileURL
        } else {
          const a: HTMLAnchorElement = document.createElement(
            'a'
          ) as HTMLAnchorElement

          a.href = fileURL
          a.download = docName
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
          URL.revokeObjectURL(fileURL)
        }
      })
      .catch((err) => {
        if (setIsLoadingDocument) setIsLoadingDocument(-1)
        setDocumentId('')
        setFileType('')
        setDownloadMessage(true)
        trackException(
          'Exception fetching the Thompson Reuters document with docId' + docId,
          err
        )
      })
  }

  const setup = useCallback(() => {
    try {
      authorizeResultPage(
        'MarketResearch',
        userSettings,
        deviceSettings,
        DOMPurify.sanitize(location && location.search ? location.search : '')
      )
    } catch (error) {
      trackException(
        'Error in fetching results in ResultsMarketResearch.tsx',
        error
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryStringFromUrl])

  useEffect(() => {
    setup()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryStringFromUrl])

  const returnAllItems = (type: string) => {
    if (!resultsCombined || resultsCombined.length < 1) {
      return []
    }

    const items = []

    try {
      const firstResults =
        resultsCombined.length < 4 ? resultsCombined.length : 3

      if (type === 'first') {
        for (let i = 0; i < firstResults; i++) {
          items.push(
            <MarketResearchResult
              documentId={DOMPurify.sanitize(resultsCombined[i].Id)}
              companyName={resultsCombined[i].CompanyName}
              fileType={DOMPurify.sanitize(resultsCombined[i].FileType)}
              fileName={DOMPurify.sanitize(resultsCombined[i].FileName)}
              headline={DOMPurify.sanitize(resultsCombined[i].Headline)}
              language={DOMPurify.sanitize(resultsCombined[i].Language)}
              pages={DOMPurify.sanitize(resultsCombined[i].Pages)}
              releaseDate={
                resultsCombined[i].ReleaseDate
                  ? intl.formatDate(
                      DOMPurify.sanitize(resultsCombined[i].ReleaseDate),
                      dateFormatOptions
                    )
                  : ''
              }
              synopsis={DOMPurify.sanitize(resultsCombined[i].Synopsis)}
              url={DOMPurify.sanitize(resultsCombined[i].Link)}
              primaryTicker={DOMPurify.sanitize(
                resultsCombined[i].PrimaryTicker
              )}
              deviceSettings={deviceSettings}
              searchQuery={DOMPurify.sanitize(searchQuery)}
              isLoadingDocument={isLoadingDocument}
              setDownloadMessage={setDownloadMessage}
              index={i}
              synonymsApplied={synonymsApplied}
            />
          )
        }
      } else {
        for (let i = firstResults; i < resultsCombined.length; i++) {
          items.push(
            <MarketResearchResult
              documentId={DOMPurify.sanitize(resultsCombined[i].Id)}
              companyName={resultsCombined[i].CompanyName}
              fileType={DOMPurify.sanitize(resultsCombined[i].FileType)}
              fileName={DOMPurify.sanitize(resultsCombined[i].FileName)}
              headline={DOMPurify.sanitize(resultsCombined[i].Headline)}
              language={DOMPurify.sanitize(resultsCombined[i].Language)}
              pages={DOMPurify.sanitize(resultsCombined[i].Pages)}
              releaseDate={
                resultsCombined[i].ReleaseDate
                  ? intl.formatDate(
                      DOMPurify.sanitize(resultsCombined[i].ReleaseDate),
                      dateFormatOptions
                    )
                  : ''
              }
              synopsis={DOMPurify.sanitize(resultsCombined[i].Synopsis)}
              url={DOMPurify.sanitize(resultsCombined[i].Link)}
              primaryTicker={DOMPurify.sanitize(
                resultsCombined[i].PrimaryTicker
              )}
              deviceSettings={deviceSettings}
              searchQuery={DOMPurify.sanitize(searchQuery)}
              isLoadingDocument={isLoadingDocument}
              setDownloadMessage={setDownloadMessage}
              index={i}
              synonymsApplied={synonymsApplied}
            />
          )
        }
      }
    } catch (error) {
      trackException(
        'Error returning all items in ResultsMarketResearch.tsx',
        error
      )
    }
    return items
  }

  const firstItems = useMemo(
    () => returnAllItems('first'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resultsCombined, intl, isLoadingDocument]
  )
  const restItems = useMemo(
    () => returnAllItems('rest'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resultsCombined, intl, isLoadingDocument]
  )

  if (downloadMessage && documentId && fileType)
    return (
      <Box>
        <Box className={`${classes.container} ${classes.downloadContainer}`}>
          <FormattedMessage
            id="file_download_title"
            defaultMessage="Your download should automatically begin in a few seconds..."
          />
          <CircularProgress
            size={14}
            className={classes.downloadCircularProgress}
          />
        </Box>
      </Box>
    )

  return (
    <Box>
      {!hasErrorStore && !!searchQuery && (
        <>
          {deviceSettings.renderMobile ? (
            <ResultsMarketResearchMobile
              searchQuery={searchQuery}
              deviceSettings={deviceSettings}
              resultsCount={resultsCount}
              isLoadingDocument={isLoadingDocument}
              setDownloadMessage={setDownloadMessage}
              findConfigCognitiveSearchEnabled={
                findConfiguration.CognitiveSearchEnabled
              }
              cognitiveSearchEnabled={cognitiveSearchEnabled}
              showCognitiveMessage={showCognitiveMessage}
              setShowCognitiveMessage={setShowCognitiveMessage}
              setUserSettings={setUserSettings}
            />
          ) : (
            <Box className={resultClasses.container}>
              <Box
                id={'con-resultvertical-01'}
                className={resultClasses.resultsContainer}
              >
                <SearchSuggestions
                  wildCardActive={false}
                  resultCount={resultsCount}
                  resultsFetchend={hasResultsBeenFetched}
                />

                <ReactPlaceholder
                  ready={
                    hasResultsBeenFetched || Number(currentPage) > 1
                      ? true
                      : false
                  }
                  type="text"
                  rows={4}
                  showLoadingAnimation={true}
                  className={resultClasses.placeholder}
                >
                  <Fade
                    in={
                      hasResultsBeenFetched || Number(currentPage) > 1
                        ? true
                        : false
                    }
                    timeout={600}
                  >
                    <Box id={'con-resultvertical-section-01'}>
                      {cognitiveSearchEnabled &&
                        showCognitiveMessage &&
                        findConfiguration.CognitiveSearchEnabled &&
                        synonymsApplied &&
                        synonymsApplied.length > 0 && (
                          <CognitiveMessage
                            synonymsApplied={synonymsApplied}
                            setShowCognitiveMessage={setShowCognitiveMessage}
                            userSettings={userSettings}
                            setUserSettings={setUserSettings}
                          />
                        )}
                      {resultsCombined && resultsCount > 0 && (
                        <FeaturedResults featuredResults={featuredResults} />
                      )}
                      {firstItems}
                    </Box>
                  </Fade>
                </ReactPlaceholder>
                <LeftWidgetContainer
                  resultCount={resultsCount}
                  hasResultsBeenFetched={hasResultsBeenFetched}
                />
                <ReactPlaceholder
                  ready={
                    hasResultsBeenFetched || Number(currentPage) > 1
                      ? true
                      : false
                  }
                  type="text"
                  rows={4}
                  showLoadingAnimation={true}
                  className={resultClasses.placeholder}
                >
                  <Fade
                    in={
                      hasResultsBeenFetched || Number(currentPage) > 1
                        ? true
                        : false
                    }
                    timeout={600}
                  >
                    <Box id={'con-resultvertical-section-02'}>
                      {restItems}
                      {hasResultsBeenFetched &&
                        resultsCount != null &&
                        resultsCount > 0 && (
                          <Pagination
                            {...props}
                            totalRecords={resultsCount}
                            pageLimit={20}
                            pageNeighbours={4}
                            loadMore={true}
                          />
                        )}
                    </Box>
                  </Fade>
                </ReactPlaceholder>
                <ReactPlaceholder
                  ready={hasResultsBeenFetched || resultsCount === 0}
                  type="text"
                  rows={4}
                  showLoadingAnimation={true}
                  className={resultClasses.placeholder}
                >
                  <Box />
                </ReactPlaceholder>
                <ReactPlaceholder
                  ready={hasResultsBeenFetched || resultsCount === 0}
                  type="text"
                  rows={4}
                  showLoadingAnimation={true}
                  className={resultClasses.placeholder}
                >
                  <Box />
                </ReactPlaceholder>
              </Box>
              <Box className={resultClasses.advertisementContainer}>
                <RightTopWidgetContainer
                  searchQuery={searchQuery}
                  scope={'marketresearch'}
                />
                <RightWidgetContainer />
              </Box>
            </Box>
          )}
        </>
      )}
      {hasErrorStore && <ErrorPage dataSource="marketresearch" />}
    </Box>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    featuredResults:
      ResultsMarketResearchStore.selectors.getFeaturedResults(state),
    synonymsApplied:
      ResultsMarketResearchStore.selectors.getSynonymsApplied(state),
    resultsCombined:
      ResultsMarketResearchStore.selectors.getResultsCombined(state),
    executionTime: ResultsMarketResearchStore.selectors.getExecutionTime(state),
    hasResultsBeenFetched:
      ResultsMarketResearchStore.selectors.hasResultsBeenFetched(state),
    resultMetaList: ResultMetaDataStore.selectors.getResultMeta(state),
    hasErrorStore: ResultMetaDataStore.selectors.getDataSourceHasError(
      state,
      'marketresearch'
    ),
    authToken: AuthStore.selectors.getAuthToken(state),
    userSettings: UserSettingsStore.selectors.getUserSettings(state),
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state),
    findConfiguration: SettingsStore.selectors.getFindConfiguration(state),
    useCognitiveSearch: SettingsStore.selectors.getUseCognitiveSearch(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    setUserSettings: (userSettings: IUserSetting) =>
      dispatch(UserSettingsStore.actions.upSertUserSettings(userSettings))
  }
}

export default PageStay(
  connect(mapStateToProps, mapDispatchToProps)(ResultsMarketResearch)
)
