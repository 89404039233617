import { IAlexResult } from 'components/models/AlexResult'
import { Store } from '..'
import { FeaturedResult } from 'components/models/FeaturedResult'

const selectors = {
  getResults: (state: Store): IAlexResult[] => {
    return state.resultsAlex.manuals.results
  },
  getResultsCombined: (state: Store): IAlexResult[] => {
    return state.resultsAlex.manuals.resultsCombined
  },
  getFeaturedResults: (state: Store): FeaturedResult[] => {
    return state.resultsAlex.featuredResults
  },
  getResultsCombinedQuery: (state: Store): string => {
    return state.resultsAlex.manuals.resultsCombinedQuery
  },
  hasResultsBeenFetched: (state: Store): boolean => {
    return state.resultsAlex.manuals.hasResultsBeenFetched
  },
  getResultCount: (state: Store): number => {
    return state.resultsAlex.manuals.resultCount
  },
  getExecutionTime: (state: Store): number => {
    return state.resultsAlex.manuals.executionTime
  },
  getHasError: (state: Store): boolean => state.resultsAlex.manuals.hasError,
  getCurrentPage: (state: Store): number => {
    return state.resultsAlex.manuals.currentPage
  }
}

export default selectors
