import { AnyAction } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { Store } from '..'
import AuthStore from '../Auth'
import * as Config from 'config'
import { capitalizeFirstLetterOfEachWord, stripHtml } from 'utils/string'
import { FetchWithCache } from 'utils/api'
import Selectors from './selectors'
import ResultMetaDataStore from 'store/ResultMetaData'
import { IUserSetting } from 'utils/userSettings'
import { IDeviceSetting } from 'utils/deviceSettings'
import { renewAuthorizationToken } from 'utils/token'
import {
  ActionMetaData,
  CustomAction,
  getActionMetaData
} from 'store/extension/customAction'
import { FeaturedResult } from 'components/models/FeaturedResult'
import { prepareKpmgUSFilters } from 'utils/filters'
import * as Filters from 'constants/filters'
import { IFindConfiguration } from 'store/Settings/reducers'
import { removeStopWordsFromQuery } from 'utils/oneIntranet'
import { ISynonymsApplied } from 'components/models/SynonymsApplied'
import SettingsStore from 'store/Settings'
import { IKpmgUSResult } from 'components/models/KpmgUSResult'

export enum ResultsKpmgUSActionTypes {
  FETCH_REQUEST = 'resultKpmgUS/FETCH_REQUEST',
  FETCH_SUCCESS = 'resultKpmgUS/FETCH_SUCCESS',
  FETCH_SUCCESS_COMBINED = 'resultKpmgUS/FETCH_SUCCESS_COMBINED',
  FETCH_FAILURE = 'resultKpmgUS/FETCH_FAILURE',
  FETCH_FILTERS_REQUEST = 'resultKpmgUS/FETCH_FILTERS_REQUEST',
  FETCH_FILTERS_SUCCESS = 'resultKpmgUS/FETCH_FILTERS_SUCCESS',
  FETCH_FILTERS_FAILURE = 'resultKpmgUS/FETCH_FILTERS_FAILURE',
  INITIALIZE_RESULTS_KPMGUS = 'persist/REHYDRATE'
}

export interface IFetchRequest extends CustomAction<ResultsKpmgUSActionTypes> {
  payload: {
    resetCombined: boolean
    searchQuery: string
  }
}
export type IFetchFailure = CustomAction<ResultsKpmgUSActionTypes>
export interface IFetchSuccess extends CustomAction<ResultsKpmgUSActionTypes> {
  payload: {
    response: {
      queryResults: IKpmgUSResult[]
      resultCount: number
      executionTime: number
      currentPage: number
    }
    currentRowKpmgComUS: number
    featuredResults: FeaturedResult[]
    synonymsApplied: ISynonymsApplied[]
  }
}
export interface IFetchSuccessCombined
  extends CustomAction<ResultsKpmgUSActionTypes> {
  payload: {
    response: {
      queryResults: IKpmgUSResult[]
    }
    currentRowKpmgComUS: number
  }
}

export type IFetchFiltersRequest = CustomAction<ResultsKpmgUSActionTypes>
export type IFetchFiltersFailure = CustomAction<ResultsKpmgUSActionTypes>
export interface IFetchFiltersSuccess
  extends CustomAction<ResultsKpmgUSActionTypes> {
  payload: {
    responseFilters: any
  }
}

export const fetchRequest = (
  resetCombined: boolean,
  searchQuery: string,
  actionMetaData: ActionMetaData
): IFetchRequest => ({
  type: ResultsKpmgUSActionTypes.FETCH_REQUEST,
  payload: {
    resetCombined: resetCombined,
    searchQuery: searchQuery
  },
  metaData: {
    ...actionMetaData,
    isStartAction: true
  }
})
export const fetchSuccess = (
  response: {
    queryResults: IKpmgUSResult[]
    resultCount: number
    executionTime: number
    currentPage: number
  },
  currentRowKpmgComUS: number,
  featuredResults: FeaturedResult[],
  synonymsApplied: ISynonymsApplied[],
  actionMetaData: ActionMetaData
): IFetchSuccess => ({
  type: ResultsKpmgUSActionTypes.FETCH_SUCCESS,
  payload: {
    response,
    currentRowKpmgComUS,
    featuredResults,
    synonymsApplied
  },
  metaData: actionMetaData
})
export const fetchSuccessCombined = (
  response: {
    queryResults: IKpmgUSResult[]
  },
  currentRowKpmgComUS: number,
  actionMetaData: ActionMetaData
): IFetchSuccessCombined => ({
  type: ResultsKpmgUSActionTypes.FETCH_SUCCESS_COMBINED,
  payload: { response, currentRowKpmgComUS },
  metaData: actionMetaData
})
export const fetchFailure = (
  actionMetaData: ActionMetaData
): IFetchFailure => ({
  type: ResultsKpmgUSActionTypes.FETCH_FAILURE,
  metaData: actionMetaData
})

export const fetchFiltersRequest = (
  actionMetaData: ActionMetaData
): IFetchFiltersRequest => ({
  type: ResultsKpmgUSActionTypes.FETCH_FILTERS_REQUEST,
  metaData: {
    ...actionMetaData,
    isStartAction: true
  }
})
export const fetchFiltersSuccess = (
  responseFilters: any = [],
  actionMetaData: ActionMetaData
): IFetchFiltersSuccess => ({
  type: ResultsKpmgUSActionTypes.FETCH_FILTERS_SUCCESS,
  payload: { responseFilters },
  metaData: actionMetaData
})
export const fetchFiltersFailure = (
  actionMetaData: ActionMetaData
): IFetchFiltersFailure => ({
  type: ResultsKpmgUSActionTypes.FETCH_FILTERS_FAILURE,
  metaData: actionMetaData
})

export const fetchResultsKpmgUS = (
  searchQuery: string,
  currentPage: number,
  userSettings: IUserSetting,
  deviceSettings: IDeviceSetting,
  filters: {
    [key: string]: string
  },
  findConfiguration: IFindConfiguration
  // eslint-disable-next-line @typescript-eslint/ban-types
): ThunkAction<Promise<void>, Store, {}, AnyAction> => {
  return async (
    // eslint-disable-next-line @typescript-eslint/ban-types
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => Store
  ) => {
    const actionMetaData = getActionMetaData('kpmg.us')
    const state = getState()

    const currentRowKpmgComUS =
      state && currentPage > 1 ? state.resultsKpmgUS.currentRowKpmgComUS : 0

    dispatch(fetchFiltersRequest(actionMetaData))
    dispatch(
      ResultMetaDataStore.actions.fetchRequest('kpmg.us', actionMetaData)
    )
    const resultsCombinedQuery = Selectors.getResultsCombinedQuery(getState())
    if (resultsCombinedQuery !== searchQuery) {
      if (resultsCombinedQuery === '') {
        dispatch(fetchRequest(false, searchQuery, actionMetaData))
      } else {
        dispatch(fetchRequest(true, searchQuery, actionMetaData))
      }
    } else {
      dispatch(fetchRequest(false, searchQuery, actionMetaData))
    }
    try {
      if (!searchQuery || searchQuery === '' || searchQuery === '""') {
        dispatch(fetchFailure(actionMetaData))
        return
      }

      const t0 = performance.now()

      const apiUrl = `${Config.APIM_BASE_URL}/kpmgusapi/postsearch`

      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        throw new Error('Authentication: Cannot renew authentication token')
      }

      const useCognitiveSearch =
        SettingsStore.selectors.getUseCognitiveSearch(getState())
      let searchQueryLanguage = ''
      if (useCognitiveSearch && findConfiguration.CognitiveSearchEnabled) {
        const detectLanguageApiUrl = `${
          Config.APIM_BASE_URL
        }searchapi/detectlanguage?searchQuery=${encodeURIComponent(
          searchQuery
        )}`

        const languageResponse = await FetchWithCache(detectLanguageApiUrl, {
          method: 'POST',
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
            Authorization: `Bearer ${esToken}`
          }
        })

        if (
          languageResponse &&
          !languageResponse.hasError &&
          languageResponse.responseJSON
        ) {
          const languageJSON = languageResponse.responseJSON
          if (languageJSON && languageJSON.responseCode === 200) {
            searchQueryLanguage = languageJSON.language
          }
        }
      }

      searchQuery = removeStopWordsFromQuery(
        searchQuery,
        searchQueryLanguage,
        useCognitiveSearch,
        findConfiguration.CognitiveSearchEnabled
      )

      const body: any = prepareKpmgUSFilters(
        filters,
        Filters.kpmgus,
        currentRowKpmgComUS,
        searchQuery,
        userSettings,
        findConfiguration,
        useCognitiveSearch
      )

      const response = await FetchWithCache(apiUrl, {
        method: 'POST',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${esToken}`
        },
        body: JSON.stringify(body)
      })

      let results
      if (!response || response.hasError || !response.responseJSON) {
        dispatch(
          ResultMetaDataStore.actions.hasError(
            'kpmg.us',
            response.errorResponse,
            undefined,
            actionMetaData
          )
        )
        dispatch(fetchFailure(actionMetaData))
        return
      } else {
        results = response.responseJSON
      }

      const featuredResults =
        results && results.FeaturedResults ? results.FeaturedResults : []
      const synonymsApplied =
        results && results.SynonymsApplied ? results.SynonymsApplied : []

      const t1 = performance.now()

      const executionTime = t1 - t0

      if (results && results.Items) {
        const kpmgUSResults: IKpmgUSResult[] = []

        results.Items.forEach((i: any) => {
          const kpmgUSResult: IKpmgUSResult = {
            Title: i.Title ? stripHtml(i.Title) : '',
            Description: i.Description ? stripHtml(i.Description) : '',
            Date: i.Date ? i.Date : '',
            Url: i.Url ? i.Url : '',
            Category: i.Category ? i.Category : '',
            LineOfBusiness: i.LineOfBusiness ? i.LineOfBusiness : '',
            Topic: i.Topic ? i.Topic : ''
          }

          kpmgUSResults.push(kpmgUSResult)
        })

        const resultCount = results.TotalItems
          ? typeof results.TotalItems === 'string'
            ? Number(results.TotalItems)
            : results.TotalItems
          : 0

        //filter
        let categoryUS: any[] = []
        const categoryUSObj = results.Refiners.find(
          (item: any) => item.Name === 'CategoryUS'
        )
        if (categoryUSObj) {
          categoryUS = categoryUSObj.Options.map((item: any) => ({
            name: item.Name,
            key: item.Value,
            value: item.Value,
            image: ''
          }))
        }

        // Bug 2152523: Add filter values for category us if they are missing
        // so we don't have empty filters with the filter applied
        if (filters['categoryus']) {
          const filterValues = filters['categoryus'].split('|')

          filterValues.forEach((value: string) => {
            if (
              value &&
              !categoryUS.some((filter: any) => {
                return filter.key === value
              })
            ) {
              const name = capitalizeFirstLetterOfEachWord(value)
              categoryUS.push({
                name: name,
                key: value,
                value: value,
                image: ''
              })
            }
          })
        }

        // let lineOfBusinessUS: string[] = []
        // const lineOfBusinessUSObj = results.Refiners.find(
        //   (item: any) => item.Name === 'LineOfBusinessUS'
        // )
        // if (lineOfBusinessUSObj) {
        //   lineOfBusinessUS = lineOfBusinessUSObj.Options.map((item: any) => ({
        //     name: item.Name,
        //     key: item.Value,
        //     value: item.Value,
        //     image: ''
        //   }))
        // }

        // let topicUS: string[] = []
        // const topicUSObj = results.Refiners.find(
        //   (item: any) => item.Name === 'TopicUS'
        // )
        // if (topicUSObj) {
        //   topicUS = topicUSObj.Options.map((item: any) => ({
        //     name: item.Name,
        //     key: item.Value,
        //     value: item.Value,
        //     image: ''
        //   }))
        // }

        const newCurrentRowKpmgComUS =
          results && results.currentRowKpmgComUS
            ? results.currentRowKpmgComUS
            : 0

        dispatch(
          fetchSuccess(
            {
              queryResults: kpmgUSResults,
              resultCount,
              executionTime,
              currentPage
            },
            newCurrentRowKpmgComUS,
            featuredResults,
            synonymsApplied,
            actionMetaData
          )
        )
        dispatch(
          fetchFiltersSuccess(
            {
              categoryus: categoryUS
              // lineofbusinessus: lineOfBusinessUS,
              // topicus: topicUS
            },
            actionMetaData
          )
        )
        dispatch(
          ResultMetaDataStore.actions.fetchSuccess(
            'kpmg.us',
            {
              executionTime: executionTime,
              resultsCount: resultCount
            },
            actionMetaData
          )
        )

        const resultsCombined = Selectors.getResultsCombined(getState())
        if (currentRowKpmgComUS === 0) {
          dispatch(
            fetchSuccessCombined(
              {
                queryResults: kpmgUSResults
              },
              newCurrentRowKpmgComUS,
              actionMetaData
            )
          )
        } else {
          dispatch(
            fetchSuccessCombined(
              {
                queryResults: [...resultsCombined, ...kpmgUSResults]
              },
              newCurrentRowKpmgComUS,
              actionMetaData
            )
          )
        }
      } else {
        dispatch(
          fetchSuccess(
            {
              queryResults: [],
              resultCount: 0,
              executionTime,
              currentPage
            },
            currentRowKpmgComUS,
            featuredResults,
            synonymsApplied,
            actionMetaData
          )
        )
      }
    } catch (error) {
      dispatch(fetchFiltersFailure(actionMetaData))
      dispatch(
        ResultMetaDataStore.actions.hasError(
          'kpmg.us',
          undefined,
          error,
          actionMetaData
        )
      )
      dispatch(fetchFailure(actionMetaData))
    }
  }
}

export type ResultsKpmgUSActions =
  | IFetchRequest
  | IFetchFailure
  | IFetchFailure
  | IFetchFiltersRequest
  | IFetchFiltersFailure
  | IFetchFiltersSuccess
