import { Action } from 'redux'

export enum StartPageActionTypes {}

export type IFetchRequest = Action<StartPageActionTypes>
export type IFetchFailure = Action<StartPageActionTypes>
export interface IFetchSuccess extends Action<StartPageActionTypes> {
  payload: {
    response: any
  }
}

export type StartPageActions = IFetchRequest | IFetchFailure | IFetchFailure
