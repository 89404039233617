import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesFindNotification(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles() {
  return makeStyles((theme: Theme) => ({
    alertbox: {
      backgroundColor: 'rgba(234,170,0,1)',
      color: '#fff',
      lineHeight: '26px',

      [`${theme.breakpoints.down(1024)}`]: {
        fontSize: '12px',
        fontFamility: 'Roboto, HelveticaNeue, Arial, sans-serif',
        marginBottom: '12px'
      },

      '& .MuiAlert-icon': {
        color: '#fff',
        margin: 'auto 0',
        width: '1em',
        height: '1em'
      }
    },
    alertboxContainer: {
      display: 'flex'
    },
    alertboxMessage: {
      marginLeft: 14
    },
    alertboxButton: {
      marginLeft: 6
    }
  }))
}
