import React from 'react'
import { render } from 'react-dom'
import App from './App'
import './index.css'
import '@fontsource/roboto/latin.css'
import {
  ThemeProvider,
  createTheme,
  StyledEngineProvider
} from '@mui/material/styles'
import * as serviceWorker from './worker'
import { Provider } from 'react-redux'
import { store } from './store/configureStore'
import { Buffer } from 'buffer'
import { setUseWhatChange } from '@simbathesailor/use-what-changed'

setUseWhatChange(process.env.NODE_ENV === 'development')

window.Buffer = Buffer

// eslint-disable-next-line @typescript-eslint/no-empty-function
window.addEventListener('touchmove', function () {})

const handleDragEvent = (e: any) => {
  e.preventDefault()
  // Do something
}

// eslint-disable-next-line no-extend-native
String.prototype.repeat = function (num: number) {
  return new Array(num + 1).join(this.toString())
}

if (!String.prototype.replaceAll) {
  // eslint-disable-next-line no-extend-native
  Object.defineProperty(String.prototype, 'replaceAll', {
    value: function (str: string | RegExp, newStr: string): string {
      // If a string
      return this.replace(new RegExp(str, 'g'), newStr)
    }
  })
}

const theme = createTheme({
  palette: {
    primary: { main: '#004e98' },
    secondary: { main: '#545454' }
  },
  typography: {
    fontFamily: `Arial, sans-serif`
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          transformStyle: 'preserve-3d'
        }
      }
    }
  }
})

const rootElement = document.getElementById('root')
rootElement?.addEventListener('dragenter', handleDragEvent)

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const RootComponent = () => (
  <Provider store={store}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </StyledEngineProvider>
  </Provider>
)

render(<RootComponent />, rootElement)

serviceWorker.unregister()
