import { AnyAction } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { Store } from '..'
import AuthStore from 'store/Auth'
import * as Config from 'config'
import { FetchWithCache } from 'utils/api'
import Selectors from './selectors'
import ResultMetaDataStore from 'store/ResultMetaData'
import { IUserSetting } from 'utils/userSettings'
import { IDeviceSetting } from 'utils/deviceSettings'
import { renewAuthorizationToken } from 'utils/token'
import {
  ActionMetaData,
  CustomAction,
  getActionMetaData
} from 'store/extension/customAction'
import { FeaturedResult } from 'components/models/FeaturedResult'
import { dataSourcesTabs } from 'constants/constants'
import * as Filters from 'constants/filters'
import {
  getCountryKeyFromName,
  getCountryNameFromKey,
  removeFilterValuesDuplicates,
  sanitizeAppliedFilters,
  sortFilterValuesByName
} from 'utils/filters'
import { IIbfdResult } from 'components/models/IbfdResult'
import SettingsStore from 'store/Settings'
import { IDataSource } from 'constants/datasourcesConfiguration'
import { countriesISO } from 'constants/countriesISO'

export enum ResultsIbfdActionTypes {
  FETCH_REQUEST = 'resultIbfd/FETCH_REQUEST',
  FETCH_SUCCESS = 'resultIbfd/FETCH_SUCCESS',
  FETCH_SUCCESS_COMBINED = 'resultIbfd/FETCH_SUCCESS_COMBINED',
  FETCH_FAILURE = 'resultIbfd/FETCH_FAILURE',
  INITIALIZE_RESULTS_IBFD = 'persist/REHYDRATE',
  FETCH_FILTERS_SUCCESS = 'resultIbfd/FETCH_FILTERS_SUCCESS',
  FETCH_FILTERS_FAILURE = 'resultIbfd/FETCH_FILTERS_FAILURE',
  FETCH_FILTERS_REQUEST = 'resultIbfd/FETCH_FILTERS_REQUEST'
}

export interface IFetchRequest extends CustomAction<ResultsIbfdActionTypes> {
  payload: {
    resetCombined: boolean
    searchQuery: string
  }
}
export interface IFetchFailure extends CustomAction<ResultsIbfdActionTypes> {
  payload: {
    isCountryError: boolean | undefined
  }
}
export interface IFetchSuccess extends CustomAction<ResultsIbfdActionTypes> {
  payload: {
    response: {
      queryResults: IIbfdResult[]
      resultCount: number
      executionTime: number
      currentPage: number
    }
    featuredResults: FeaturedResult[]
  }
}
export interface IFetchSuccessCombined
  extends CustomAction<ResultsIbfdActionTypes> {
  payload: {
    response: {
      queryResults: IIbfdResult[]
      resultCount: number
    }
  }
}

export const fetchRequest = (
  resetCombined: boolean,
  searchQuery: string,
  actionMetaData: ActionMetaData
): IFetchRequest => ({
  type: ResultsIbfdActionTypes.FETCH_REQUEST,
  payload: {
    resetCombined: resetCombined,
    searchQuery: searchQuery
  },
  metaData: {
    ...actionMetaData,
    isStartAction: true
  }
})
export const fetchSuccess = (
  response: {
    queryResults: IIbfdResult[]
    resultCount: number
    executionTime: number
    currentPage: number
  },
  featuredResults: FeaturedResult[],
  actionMetaData: ActionMetaData
): IFetchSuccess => ({
  type: ResultsIbfdActionTypes.FETCH_SUCCESS,
  payload: {
    response,
    featuredResults
  },
  metaData: actionMetaData
})

export const fetchSuccessCombined = (
  response: {
    queryResults: IIbfdResult[]
    resultCount: number
  },
  actionMetaData: ActionMetaData
): IFetchSuccessCombined => ({
  type: ResultsIbfdActionTypes.FETCH_SUCCESS_COMBINED,
  payload: { response },
  metaData: actionMetaData
})
export const fetchFailure = (
  actionMetaData: ActionMetaData,
  isCountryError?: boolean
): IFetchFailure => ({
  type: ResultsIbfdActionTypes.FETCH_FAILURE,
  payload: { isCountryError },
  metaData: actionMetaData
})

export type IFetchFiltersFailure = CustomAction<ResultsIbfdActionTypes>
export interface IFetchFiltersSuccess
  extends CustomAction<ResultsIbfdActionTypes> {
  payload: {
    responseFilters: any
  }
}
export interface IFetchFiltersRequest
  extends CustomAction<ResultsIbfdActionTypes> {
  payload: {
    resetFilters: boolean
  }
}

export const fetchFiltersRequest = (
  actionMetaData: ActionMetaData,
  resetFilters?: boolean
): IFetchFiltersRequest => ({
  type: ResultsIbfdActionTypes.FETCH_FILTERS_REQUEST,
  payload: { resetFilters: Boolean(resetFilters) },
  metaData: {
    ...actionMetaData,
    isStartAction: true
  }
})

export const fetchFiltersSuccess = (
  responseFilters: any = {},
  actionMetaData: ActionMetaData
): IFetchFiltersSuccess => ({
  type: ResultsIbfdActionTypes.FETCH_FILTERS_SUCCESS,
  payload: { responseFilters },
  metaData: actionMetaData
})

export const fetchFiltersFailure = (
  actionMetaData: ActionMetaData
): IFetchFiltersFailure => ({
  type: ResultsIbfdActionTypes.FETCH_FILTERS_FAILURE,
  metaData: actionMetaData
})

export const fetchResultsIbfd = (
  searchQuery: string,
  currentPage: number,
  userSettings: IUserSetting,
  deviceSettings: IDeviceSetting,
  filters: {
    [key: string]: string
  }
  // eslint-disable-next-line @typescript-eslint/ban-types
): ThunkAction<Promise<void>, Store, {}, AnyAction> => {
  return async (
    // eslint-disable-next-line @typescript-eslint/ban-types
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => Store
  ) => {
    const actionMetaData = getActionMetaData(dataSourcesTabs.ibfd)

    // In case country is not supported once, directly return
    const hasCountryError = Selectors.getCountryError(getState())
    if (hasCountryError) {
      return
    }

    dispatch(
      ResultMetaDataStore.actions.fetchRequest(
        dataSourcesTabs.ibfd,
        actionMetaData
      )
    )

    // Check and return an error in case the country claim is not supported for ibfd
    let datasourceSettings =
      SettingsStore.selectors.getDatasourceSettings(getState())

    // Fall back in case the datasource settings are not loaded and the page is directly open on ibfd
    if (!datasourceSettings || datasourceSettings.length === 0) {
      datasourceSettings =
        SettingsStore.selectors.getCurrentDataSources(getState())
    }

    if (datasourceSettings) {
      const ibfdSource = datasourceSettings.find(
        (ds: IDataSource) => ds.name === 'IBFD'
      )

      if (
        ibfdSource &&
        ibfdSource.allowedCountries &&
        userSettings &&
        userSettings.Country
      ) {
        const countryCode = countriesISO.find(
          (ce: any) =>
            ce.Name.toLowerCase() === userSettings.Country.toLowerCase()
        )

        if (
          !countryCode ||
          !ibfdSource.allowedCountries.find(
            (cc: string) => cc.toLowerCase() === countryCode.Code.toLowerCase()
          )
        ) {
          dispatch(
            ResultMetaDataStore.actions.hasError(
              dataSourcesTabs.ibfd,
              undefined,
              new Error('Country is not supported'),
              actionMetaData
            )
          )
          dispatch(fetchFailure(actionMetaData, true))
          dispatch(fetchFiltersFailure(actionMetaData))

          return
        }
      }
    }

    dispatch(fetchFiltersRequest(actionMetaData))

    const resultsCombinedQuery = Selectors.getResultsCombinedQuery(getState())
    if (resultsCombinedQuery !== searchQuery) {
      if (resultsCombinedQuery === '') {
        dispatch(fetchRequest(false, searchQuery, actionMetaData))
      } else {
        dispatch(fetchRequest(true, searchQuery, actionMetaData))
      }
    } else {
      dispatch(fetchRequest(false, searchQuery, actionMetaData))
    }

    try {
      if (!searchQuery || searchQuery === '' || searchQuery === '""') {
        dispatch(fetchFailure(actionMetaData))
        dispatch(fetchFiltersFailure(actionMetaData))
        return
      }

      const t0 = performance.now()

      const apiUrl = `${Config.APIM_BASE_URL}ibfdapi/postsearch`

      const bodyObject: any = {
        searchQuery: searchQuery,
        page: currentPage || 1,
        processFeaturedResults: (currentPage || 1) === 1 ? true : false,
        country: userSettings ? userSettings.Country : '',
        function: userSettings ? userSettings.Function : '',
        featuredResultsOrigin: 'KPMGFind'
      }

      if (filters) {
        const sanitizedFilters = sanitizeAppliedFilters(
          Filters.ibfd,
          filters,
          [],
          true
        )

        if (sanitizedFilters.countriesFilter)
          bodyObject.countriesFilter = (
            sanitizedFilters.countriesFilter as any
          ).map((country: string) => {
            return getCountryNameFromKey(country)
          })

        if (sanitizedFilters.regionFilter)
          bodyObject.regionsFilter = sanitizedFilters.regionFilter

        if (sanitizedFilters.orgFilter)
          bodyObject.intOrgsFilter = sanitizedFilters.orgFilter

        if (sanitizedFilters.topicFilter)
          bodyObject.topicsFilter = sanitizedFilters.topicFilter

        if (filters.orderBy === 'relevance_asc') {
          bodyObject.sortProperty = 'Relevance'
          bodyObject.sortOrder = 'asc'
        } else if (filters.orderBy === 'dated') {
          bodyObject.sortProperty = 'Date'
          bodyObject.sortOrder = 'desc'
        } else if (filters.orderBy === 'datea') {
          bodyObject.sortProperty = 'Date'
          bodyObject.sortOrder = 'asc'
        }
      }

      const body = JSON.stringify(bodyObject)

      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        return
      }

      const response = await FetchWithCache(apiUrl, {
        method: 'POST',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${esToken}`
        },
        body: body
      })

      let results
      if (!response || response.hasError || !response.responseJSON) {
        dispatch(
          ResultMetaDataStore.actions.hasError(
            'ibfd',
            response.errorResponse,
            undefined,
            actionMetaData
          )
        )
        dispatch(fetchFailure(actionMetaData))
        dispatch(fetchFiltersFailure(actionMetaData))
        return
      } else {
        results = response.responseJSON
      }

      const featuredResults =
        results && results.FeaturedResults ? results.FeaturedResults : []

      const t1 = performance.now()
      const executionTime = t1 - t0

      const ibfdResults: IIbfdResult[] = []
      const primaryResults: any[] =
        results && results.Documents ? results.Documents : []

      primaryResults.forEach((item: any) => {
        ibfdResults.push({
          title: item.Title,
          snippet: item.Snippet,
          date: item.Date,
          region: item.Region,
          country: item['Country/Jurisdiction'],
          resultUrl:
            Config.IBFD_BASE_URL +
            '/1033370/kbase?Relaystate=' +
            encodeURIComponent(item.Url)
        })
      })

      // Set result number of results
      // const resultCount = results.Documents.length
      const resultCount =
        results && results.TotalDocuments ? results.TotalDocuments : 0

      //filters
      let countriesFilter: Filter[] = []
      let regionFilter: Filter[] = []
      let orgFilter: Filter[] = []
      let topicFilter: Filter[] = []

      if (results && 'Filters' in results) {
        results.Filters.forEach((filter: any) => {
          //[0].CountryFilter
          if (filter.CountryFilter)
            filter.CountryFilter.forEach((option: string) => {
              countriesFilter.push({
                name: option,
                key: getCountryKeyFromName(option),
                image: '',
                resultCount: undefined,
                value: option
              })
            })
          if (bodyObject && bodyObject.countriesFilter) {
            bodyObject.countriesFilter.forEach((option: string) => {
              if (
                countriesFilter.findIndex(
                  (country: Filter) => country.name === option
                ) === -1
              )
                countriesFilter.push({
                  name: option,
                  key: getCountryKeyFromName(option),
                  image: '',
                  resultCount: undefined,
                  value: option
                })
            })
          }
          countriesFilter = sortFilterValuesByName(countriesFilter)
          countriesFilter = removeFilterValuesDuplicates(countriesFilter)

          //[1].Region
          if (filter.Region)
            filter.Region.forEach((option: string) => {
              regionFilter.push({
                name: option,
                key: option,
                image: '',
                resultCount: undefined,
                value: option
              })
            })
          if (bodyObject && bodyObject.regionsFilter) {
            bodyObject.regionsFilter.forEach((option: string) => {
              if (
                regionFilter.findIndex(
                  (region: Filter) => region.name === option
                ) === -1
              )
                regionFilter.push({
                  name: option,
                  key: option,
                  image: '',
                  resultCount: undefined,
                  value: option
                })
            })
          }
          regionFilter = sortFilterValuesByName(regionFilter)
          regionFilter = removeFilterValuesDuplicates(regionFilter)

          //[2].InternationalOrganization
          if (filter.InternationalOrganization)
            filter.InternationalOrganization.forEach((option: string) => {
              orgFilter.push({
                name: option,
                key: option,
                image: '',
                resultCount: undefined,
                value: option
              })
            })
          if (bodyObject && bodyObject.intOrgsFilter) {
            bodyObject.intOrgsFilter.forEach((option: string) => {
              if (
                orgFilter.findIndex(
                  (organization: Filter) => organization.name === option
                ) === -1
              )
                orgFilter.push({
                  name: option,
                  key: option,
                  image: '',
                  resultCount: undefined,
                  value: option
                })
            })
          }
          orgFilter = sortFilterValuesByName(orgFilter)
          orgFilter = removeFilterValuesDuplicates(orgFilter)

          //[3].Topic
          if (filter.Topic)
            filter.Topic.forEach((option: string) => {
              topicFilter.push({
                name: option,
                key: option,
                image: '',
                resultCount: undefined,
                value: option
              })
            })
          if (bodyObject && bodyObject.topicsFilter) {
            bodyObject.topicsFilter.forEach((option: string) => {
              if (
                topicFilter.findIndex(
                  (topic: Filter) => topic.key === option
                ) === -1
              )
                topicFilter.push({
                  name: option,
                  key: option,
                  image: '',
                  resultCount: undefined,
                  value: option
                })
            })
          }
          topicFilter = sortFilterValuesByName(topicFilter)
          topicFilter = removeFilterValuesDuplicates(topicFilter)
        })
      }

      const filterObject = {
        currentQuery: searchQuery,
        countriesFilter: countriesFilter,
        regionFilter: regionFilter,
        orgFilter: orgFilter,
        topicFilter: topicFilter
      }

      setTimeout(function () {
        dispatch(
          fetchFiltersSuccess(
            {
              filters: filterObject
            },
            actionMetaData
          )
        )

        dispatch(
          fetchSuccess(
            {
              queryResults: ibfdResults,
              resultCount,
              executionTime,
              currentPage: currentPage || 1
            },
            featuredResults,
            actionMetaData
          )
        )
      }, 1000)

      dispatch(
        ResultMetaDataStore.actions.fetchSuccess(
          dataSourcesTabs.ibfd,
          {
            executionTime: executionTime,
            resultsCount: resultCount
          },
          actionMetaData
        )
      )

      const resultsCombined = Selectors.getResultsCombined(getState())
      if ((currentPage || 1) === 1) {
        setTimeout(function () {
          dispatch(
            fetchSuccessCombined(
              { queryResults: ibfdResults, resultCount },
              actionMetaData
            )
          )
        }, 1000)
      } else {
        setTimeout(function () {
          dispatch(
            fetchSuccessCombined(
              {
                queryResults: [...resultsCombined, ...ibfdResults],
                resultCount
              },
              actionMetaData
            )
          )
        }, 1000)
      }
    } catch (error) {
      dispatch(
        ResultMetaDataStore.actions.hasError(
          dataSourcesTabs.ibfd,
          undefined,
          error,
          actionMetaData
        )
      )
      dispatch(fetchFailure(actionMetaData))
      dispatch(fetchFiltersFailure(actionMetaData))
    }
  }
}

export type ResultsIbfdActions =
  | IFetchRequest
  | IFetchSuccess
  | IFetchFailure
  | IFetchFiltersSuccess
