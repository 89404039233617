import React from 'react'
import { Grid } from '@mui/material'
import { Link } from 'react-router-dom'
import FilterButton from 'components/contents/FilterButton'
import { getStylesKxFilterBar } from 'styles/contents/KxFilterBar'
import ResultsKnowledgeExchangeStore from 'store/ResultsKnowledgeExchange'
import ResultMetaDataStore from 'store/ResultMetaData'
import { Store } from 'store'
import { connect } from 'react-redux'
import { getUrlParameterForCurrentFilters } from 'utils/filters'
import FilterStore from 'store/Filters'
import createDOMPurify from 'dompurify'

export interface FilterBarProps {
  scope: any
  datasources: { name: string; enabled: boolean; icon?: any; path: string }[]
}

type AllProps = ReturnType<typeof mapStateToProps> & FilterBarProps

function KxFilterBar(props: AllProps): JSX.Element {
  const {
    scope,
    datasources,
    resultsCountOpen,
    resultsCountCatalog,
    hasErrorOpen,
    hasErrorCatalog,
    currentfilters
  } = props
  const classes = getStylesKxFilterBar()
  const DOMPurify = createDOMPurify(window)
  const urlParameter = getUrlParameterForCurrentFilters(currentfilters, [
    {
      key: 'page',
      value: '1'
    },
    {
      key: 'cntx',
      value: ''
    }
  ])

  const hasError = (dataSourceName: string): boolean => {
    switch (dataSourceName) {
      case 'Open':
        return hasErrorOpen
      case 'Catalog':
        return hasErrorCatalog
      default:
        return false
    }
  }

  const hasNoResult = (dataSourceName: string): boolean => {
    switch (dataSourceName) {
      case 'Open':
        return resultsCountOpen === 0 ? true : false
      case 'Catalog':
        return resultsCountCatalog === 0 ? true : false
      default:
        return false
    }
  }

  return (
    <div className={classes.root}>
      <Grid
        container
        className={classes.gridList}
        direction="column"
        justifyContent="flex-start"
      >
        <Grid item className={classes.iconContainer}>
          <Grid item className={classes.startIconContainer}>
            <Grid item>
              <Link
                className={classes.iconMenu}
                to={`/results/knowledgeexchange/${
                  urlParameter ? DOMPurify.sanitize(urlParameter) : ''
                }`}
                style={{ textDecoration: 'none' }}
              >
                <FilterButton
                  icon={<span />}
                  linkText="Open"
                  scope={DOMPurify.sanitize(scope)}
                  hasNoLogo
                  hasError={hasError('Open')}
                  noResults={hasNoResult('Open')}
                />
              </Link>
            </Grid>
            {datasources.slice(1, 6).map((datasource: any, index: number) => (
              <Grid item key={index}>
                <Link
                  className={classes.iconMenu}
                  to={`${DOMPurify.sanitize(datasource.path)}/${
                    urlParameter ? DOMPurify.sanitize(urlParameter) : ''
                  }`}
                  style={{ textDecoration: 'none' }}
                >
                  <FilterButton
                    icon={DOMPurify.sanitize(datasource.icon)}
                    linkText={DOMPurify.sanitize(datasource.name)}
                    scope={DOMPurify.sanitize(scope)}
                    hasNoLogo
                    hasError={hasError(DOMPurify.sanitize(datasource.name))}
                    noResults={hasNoResult(DOMPurify.sanitize(datasource.name))}
                    isLoading={false}
                  />
                </Link>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    resultsCountOpen: ResultsKnowledgeExchangeStore.selectors.getResultCount(
      state,
      'open'
    ),
    hasErrorOpen: ResultMetaDataStore.selectors.getDataSourceHasError(
      state,
      'knowledgeexchangeopen'
    ),
    resultsCountCatalog: ResultsKnowledgeExchangeStore.selectors.getResultCount(
      state,
      'catalog'
    ),
    hasErrorCatalog: ResultMetaDataStore.selectors.getDataSourceHasError(
      state,
      'knowledgeexchangecatalog'
    ),
    currentfilters: FilterStore.selectors.getCurrentFilters(state)
  }
}

export default connect(mapStateToProps)(KxFilterBar)
