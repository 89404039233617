export enum SEOCheckerTypes {
  OnlineFile = 'OnlineFile',
  OnlinePage = 'OnlinePage',
  OnlineWeb = 'OnlineWeb'
}

export interface ISeoCheckerData {
  url: string
  seoType: SEOCheckerTypes | null
  uniqueId: string | null
  siteId: string | null
}

/**
 * Check if the received title contains the received terms
 * @param searchTerms The search terms introduced by the user
 * @param title The item title
 */
export function titleContainsTerms(
  searchTerms: string[],
  title: string
): string {
  // Construct: word1 word2, word3. If word1 word2 is present, match.
  // If not, but word1 and word1 and word2 are separately present, match too.
  let numberOfMatches = 0
  let partialSubTermMatch = false
  searchTerms.map((searchTerm: string) => {
    if (title.toLowerCase().indexOf(searchTerm.trim().toLowerCase()) >= 0) {
      numberOfMatches++
    } else if (searchTerm.trim().indexOf(' ') >= 0) {
      const subTerms = searchTerm.split(' ')
      let numberOfSubTermMatches = 0
      subTerms.map((subTerm: string) => {
        if (title.toLowerCase().indexOf(subTerm.trim().toLowerCase()) >= 0) {
          numberOfSubTermMatches++
          partialSubTermMatch = true
        }
        return false
      })
      if (numberOfSubTermMatches === subTerms.length) {
        numberOfMatches++
      }
    }
    return false
  })
  let termMatches = ''
  if (numberOfMatches === 0) {
    if (partialSubTermMatch) {
      termMatches = 'some'
    } else {
      termMatches = 'none'
    }
  } else if (numberOfMatches === searchTerms.length) {
    termMatches = 'all'
  } else {
    termMatches = 'some'
  }
  return termMatches
}

/**
 * Check title quality
 * @param title The item title
 */
export function titleQuality(title: string): string {
  let tQuality = ''
  if (title.length > 0) {
    if (title.length > 77) {
      tQuality = 'Long'
    }
    const re = /(?:\.([^.]+))?$/
    const ext = re.exec(title)
    if (ext && ext[1]) {
      if (tQuality === '') {
        tQuality = 'File suffix'
      } else {
        tQuality += ', file suffix'
      }
    }
    if (title[0] !== title[0].toUpperCase()) {
      if (tQuality === '') {
        tQuality = 'Starts lowercase'
      } else {
        tQuality += ', starts lowercase'
      }
    }
    if (title.indexOf('_') >= 0) {
      if (tQuality === '') {
        tQuality = 'Contains "_"'
      } else {
        tQuality += ', contains "_"'
      }
    }
  }
  if (tQuality === '') {
    tQuality = 'Good'
  }
  return tQuality
}

/**
 * Check if the received abstract contains the received terms
 * @param searchTerms The search terms introduced by the user
 * @param abstract The item abstract
 */
export function abstractContainsTerms(
  searchTerms: string[],
  abstract: string
): string {
  if (abstract === '') {
    return 'na'
  }
  let numberOfMatches = 0
  let partialSubTermMatch = false
  searchTerms.map((searchTerm: string) => {
    if (abstract.toLowerCase().indexOf(searchTerm.trim().toLowerCase()) >= 0) {
      numberOfMatches++
    } else if (searchTerm.trim().indexOf(' ') >= 0) {
      const subTerms = searchTerm.split(' ')
      let numberOfSubTermMatches = 0
      subTerms.map((subTerm: string) => {
        if (abstract.toLowerCase().indexOf(subTerm.trim().toLowerCase()) >= 0) {
          numberOfSubTermMatches++
          partialSubTermMatch = true
        }
        return false
      })
      if (numberOfSubTermMatches === subTerms.length) {
        numberOfMatches++
      }
    }
    return false
  })
  let abstractMatches = ''
  if (numberOfMatches === 0) {
    if (partialSubTermMatch) {
      abstractMatches = 'some'
    } else {
      abstractMatches = 'none'
    }
  } else if (numberOfMatches === searchTerms.length) {
    abstractMatches = 'all'
  } else {
    abstractMatches = 'some'
  }
  return abstractMatches
}

/**
 * Check abstract quality
 * @param abstract The item abstract
 * @param title The item title
 * @param seoType The seo item type
 */
export function abstractQuality(
  abstract: string,
  title: string,
  seoType: SEOCheckerTypes | null
): string {
  let absQuality = ''
  if (abstract === '') {
    absQuality = `No ${
      seoType === SEOCheckerTypes.OnlineFile ? 'custom' : ''
    } description`
  } else {
    if (abstract.length < 5) {
      absQuality = 'Too short (min 10 words)'
    } else if (abstract.length < 10) {
      absQuality = 'Short (min 10 words)'
    }
    if (abstract === title && title.length >= 0) {
      if (absQuality === '') {
        absQuality = 'Same as title'
      } else {
        absQuality += ', same as title'
      }
    }
  }

  if (absQuality === '') {
    absQuality = 'Good'
  }
  return absQuality
}

/**
 * Check last modified date quality
 * @param lastModifiedDate The item last modified date
 * @param formattedDate The item formatted last modified date
 */
export function dateQuality(
  lastModifiedDate: string,
  formattedDate: string
): string {
  let modDate = ''
  const docuDate = new Date(lastModifiedDate)
  const twoHalfYearsAgo = new Date()
  twoHalfYearsAgo.setFullYear(twoHalfYearsAgo.getFullYear() - 2)
  twoHalfYearsAgo.setMonth(twoHalfYearsAgo.getMonth() - 6)
  const threeYearsAgo = new Date()
  threeYearsAgo.setFullYear(threeYearsAgo.getFullYear() - 3)
  if (docuDate > twoHalfYearsAgo) {
    modDate = 'green'
  } else if (docuDate <= threeYearsAgo) {
    modDate = 'red'
  } else if (docuDate <= twoHalfYearsAgo) {
    modDate = 'yellow'
  }
  modDate += 'Content last modified ' + formattedDate
  return modDate
}

/**
 * Check if the received file name contains the received terms
 * @param searchTerms The search terms introduced by the user
 * @param fileName The item file name
 */
export function fileNameContainsTerms(
  searchTerms: string[],
  fileName: string
): string {
  let numberOfMatches = 0
  let partialSubTermMatch = false
  searchTerms.map((searchTerm: string) => {
    if (fileName.toLowerCase().indexOf(searchTerm.trim().toLowerCase()) >= 0) {
      numberOfMatches++
    } else if (searchTerm.trim().indexOf(' ') >= 0) {
      const subTerms = searchTerm.split(' ')
      let numberOfSubTermMatches = 0
      subTerms.map((subTerm: string) => {
        if (fileName.toLowerCase().indexOf(subTerm.trim().toLowerCase()) >= 0) {
          numberOfSubTermMatches++
          partialSubTermMatch = true
        }
        return false
      })
      if (numberOfSubTermMatches === subTerms.length) {
        numberOfMatches++
      }
    }
    return false
  })
  let fileNameMatches = ''
  if (numberOfMatches === 0) {
    if (partialSubTermMatch) {
      fileNameMatches = 'some'
    } else {
      fileNameMatches = 'none'
    }
  } else if (numberOfMatches === searchTerms.length) {
    fileNameMatches = 'all'
  } else {
    fileNameMatches = 'some'
  }
  return fileNameMatches
}

export function getMappedTypeString(seoType: SEOCheckerTypes | null): string {
  switch (seoType) {
    case SEOCheckerTypes.OnlinePage:
      return 'Page'
    case SEOCheckerTypes.OnlineWeb:
      return 'Site'
    case SEOCheckerTypes.OnlineFile:
    default:
      return 'File'
  }
}
