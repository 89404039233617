import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesRightNewsWidget(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles(): any {
  return makeStyles((theme: Theme) => ({
    widgetText: {
      width: '100%',
      marginBottom: 16
    },
    widgetTitle: {
      lineHeight: '20px'
    },
    widgetDescription: {
      lineHeight: '20px',
      maxHeight: '40px'
    },
    widgetFooter: {
      color: 'rgba(0, 0, 0, 0.54)',
      textAlign: 'left',
      lineHeight: '20px',
      fontWeight: 'normal',
      font: 'Arial,Helvetica Neue,Helvetica,sans-serif-light',
      fontSize: 12,
      marginBottom: '16px',
      whiteSpace: 'pre-wrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    }
  }))
}
