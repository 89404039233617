import { Store } from '..'

const selectors = {
  getGlobalTranslationsVersion: (state: Store): any =>
    state.translations.globalTranslationsVersion,
  getGlobalTranslations: (state: Store): any =>
    state.translations.globalTranslations,
  getAdsTranslations: (state: Store): any => state.translations.adsTranslations,
  getPopupTranslations: (state: Store): any =>
    state.translations.popupTranslations,
  getFaqTranslationsVersion: (state: Store): any =>
    state.translations.faqTranslationsVersion,
  getFaqTranslations: (state: Store): any => state.translations.faqTranslations,
  getRoadmapTranslationsVersion: (state: Store): any =>
    state.translations.roadmapTranslationsVersion,
  getRoadmapTranslations: (state: Store): any =>
    state.translations.roadmapTranslations,
  getIntroductionTranslationsVersion: (state: Store): any =>
    state.translations.introductionTranslationsVersion,
  getIntroductionTranslations: (state: Store): any =>
    state.translations.introductionTranslations,
  hasError: (state: Store): any => state.translations.hasError,
  validTranslations: (state: Store): any => state.translations.validTranslations
}

export default selectors
