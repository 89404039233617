import { Reducer } from 'redux'
import {
  TranslationsActions,
  TranslationsActionTypes,
  IFetchVersionTranslationsSuccess,
  IFetchGlobalTranslationsSuccess,
  IFetchAdsTranslationsSuccess,
  IFetchFaqTranslationsSuccess,
  IFetchRoadmapTranslationsSuccess,
  IFetchIntroductionTranslationsSuccess,
  IFetchPopupsTranslationsSuccess
} from './actions'
import * as Constants from 'constants/constants'
import { AdResult } from 'components/models/AdResult'

export interface ITranslationsStore {
  globalTranslations: any
  globalTranslationsVersion: string
  adsTranslations: AdResult[]
  faqTranslations: any
  faqTranslationsVersion: string
  roadmapTranslations: any
  roadmapTranslationsVersion: string
  introductionTranslations: any
  introductionTranslationsVersion: any
  popupTranslations: any
  popupTranslationsVersion: any
  hasError: boolean
  validTranslations: boolean
}

export const initialGlobalTranslationsState = (): any => ({})
export const initialAdsTranslationsState = (): any => []
export const initialPopupsTranslationsState = (): any => []
export const initialFaqTranslationsState = (): any => []
export const initialRoadmapTranslationsState = (): any => []
export const initialIntroductionTranslationsState = (): any => []

const initialState: ITranslationsStore = {
  globalTranslations: initialGlobalTranslationsState(),
  globalTranslationsVersion: '0',
  adsTranslations: initialAdsTranslationsState(),
  faqTranslations: initialFaqTranslationsState(),
  faqTranslationsVersion: '0',
  roadmapTranslations: initialRoadmapTranslationsState(),
  roadmapTranslationsVersion: '0',
  introductionTranslations: initialIntroductionTranslationsState(),
  introductionTranslationsVersion: '0',
  popupTranslations: initialPopupsTranslationsState(),
  popupTranslationsVersion: '0',
  hasError: false,
  validTranslations: false
}

const reducers: Reducer<ITranslationsStore, TranslationsActions> = (
  state: ITranslationsStore = initialState,
  action: TranslationsActions
) => {
  switch (action.type) {
    case TranslationsActionTypes.FETCH_VERSION_TRANSLATIONS_REQUEST:
      return {
        ...state
      }
    case TranslationsActionTypes.FETCH_GLOBAL_TRANSLATIONS_REQUEST:
      return {
        ...state,
        globalTranslations: initialGlobalTranslationsState(),
        hasTranslationsBeenFetched: false,
        validTranslations: false
      }
    case TranslationsActionTypes.FETCH_ADS_TRANSLATIONS_REQUEST:
      return {
        ...state,
        adsTranslations: initialAdsTranslationsState(),
        hasTranslationsBeenFetched: false,
        validTranslations: false
      }
    case TranslationsActionTypes.FETCH_POPUPS_TRANSLATIONS_REQUEST:
      return {
        ...state,
        popupTranslations: initialPopupsTranslationsState(),
        hasTranslationsBeenFetched: false,
        validTranslations: false
      }
    case TranslationsActionTypes.FETCH_FAQ_TRANSLATIONS_REQUEST:
      return {
        ...state,
        faqTranslations: initialFaqTranslationsState(),
        hasTranslationsBeenFetched: false,
        validTranslations: false
      }
    case TranslationsActionTypes.FETCH_ROADMAP_TRANSLATIONS_REQUEST:
      return {
        ...state,
        roadmapTranslations: initialRoadmapTranslationsState(),
        hasTranslationsBeenFetched: false,
        validTranslations: false
      }
    case TranslationsActionTypes.FETCH_INTRODUCTION_TRANSLATIONS_REQUEST:
      return {
        ...state,
        introductionTranslations: initialIntroductionTranslationsState(),
        hasTranslationsBeenFetched: false,
        validTranslations: false
      }
    case TranslationsActionTypes.FETCH_VERSION_TRANSLATIONS_SUCCESS:
      const { translationType, version } = (
        action as IFetchVersionTranslationsSuccess
      ).payload
      switch (translationType) {
        case Constants.translationTypes.faq:
          return {
            ...state,
            faqTranslationsVersion: version
          }
        case Constants.translationTypes.global:
          return {
            ...state,
            globalTranslationsVersion: version
          }
        case Constants.translationTypes.introduction:
          return {
            ...state,
            introductionTranslationsVersion: version
          }
        case Constants.translationTypes.roadmap:
          return {
            ...state,
            roadmapTranslationsVersion: version
          }
        case Constants.translationTypes.popups:
          return {
            ...state,
            popupTranslationsVersion: version
          }
        default:
          return {
            ...state
          }
      }
    case TranslationsActionTypes.FETCH_GLOBAL_TRANSLATIONS_SUCCESS:
      const { globalResponse } = (action as IFetchGlobalTranslationsSuccess)
        .payload
      return {
        ...state,
        hasTranslationsBeenFetched: true,
        globalTranslations: globalResponse,
        hasError: false,
        validTranslations: globalResponse ? true : false
      }
    case TranslationsActionTypes.FETCH_ADS_TRANSLATIONS_SUCCESS:
      const { adsResponse } = (action as IFetchAdsTranslationsSuccess).payload
      return {
        ...state,
        hasTranslationsBeenFetched: true,
        adsTranslations: adsResponse,
        hasError: false,
        validTranslations: adsResponse ? true : false
      }
    case TranslationsActionTypes.FETCH_POPUPS_TRANSLATIONS_SUCCESS:
      const { popupsResponse } = (action as IFetchPopupsTranslationsSuccess)
        .payload
      return {
        ...state,
        hasTranslationsBeenFetched: true,
        popupTranslations: popupsResponse,
        hasError: false,
        validTranslations: popupsResponse ? true : false
      }
    case TranslationsActionTypes.FETCH_FAQ_TRANSLATIONS_SUCCESS:
      const { faqResponse } = (action as IFetchFaqTranslationsSuccess).payload
      return {
        ...state,
        hasTranslationsBeenFetched: true,
        faqTranslations: faqResponse,
        hasError: false,
        validTranslations: faqResponse ? true : false
      }
    case TranslationsActionTypes.FETCH_ROADMAP_TRANSLATIONS_SUCCESS:
      const { roadmapResponse } = (action as IFetchRoadmapTranslationsSuccess)
        .payload
      return {
        ...state,
        hasTranslationsBeenFetched: true,
        roadmapTranslations: roadmapResponse,
        hasError: false,
        validTranslations: roadmapResponse ? true : false
      }
    case TranslationsActionTypes.FETCH_INTRODUCTION_TRANSLATIONS_SUCCESS:
      const { introductionResponse } = (
        action as IFetchIntroductionTranslationsSuccess
      ).payload
      return {
        ...state,
        hasTranslationsBeenFetched: true,
        introductionTranslations: introductionResponse,
        hasError: false,
        validTranslations: introductionResponse ? true : false
      }
    case TranslationsActionTypes.FETCH_TRANSLATIONS_FAILURE:
      return {
        ...state,
        hasTranslationsBeenFetched: true,
        hasError: true,
        validTranslations: false
      }
    case TranslationsActionTypes.FETCH_TRANSLATIONS_NOTFOUND:
      return {
        ...state,
        hasTranslationsBeenFetched: true,
        hasError: false,
        validTranslations: false
      }
    default:
      return state
  }
}

export default reducers
