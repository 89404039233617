import { Store } from '..'

const selectors = {
  getUserSettings: (state: Store): any => state.userSettings.userSettings,
  hasUserSettingsBeenFetched: (state: Store): boolean =>
    state.userSettings.hasUserSettingsBeenFetched,
  hasError: (state: Store): any => state.userSettings.hasError,
  validSettings: (state: Store): any => state.userSettings.validSettings
}

export default selectors
