import {
  IResultTranslations,
  ITranslationResult
} from 'components/models/Translation'
import * as Config from 'config'
import { renewAuthorizationToken } from './token'
import { IAADState } from 'store/Auth/reducers'

export async function getTranslations(
  resultTranslations: IResultTranslations,
  aadInfo: IAADState
): Promise<IResultTranslations> {
  try {
    const esToken = await renewAuthorizationToken(
      aadInfo.accessToken,
      aadInfo.instance,
      aadInfo.accounts
    )

    const languageAPIPostTextTranslationUrl = `${
      Config.APIM_BASE_URL
    }languageapi/posttexttranslation`

    const requestBody: {
      values: {
        text: string
      }[]
      to: string
    } = {
      values: resultTranslations.values,
      to: resultTranslations.to
    }

    if (requestBody.to === 'ja-JP') requestBody.to = 'ja'
    else if (requestBody.to === 'ko-KR') requestBody.to = 'ko'
    else if (requestBody.to === 'pt-BR') requestBody.to = 'pt'
    else if (requestBody.to === 'zh') requestBody.to = 'zh-Hans'

    const languageResponse = await fetch(languageAPIPostTextTranslationUrl, {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
        Authorization: `Bearer ${esToken}`
      },
      body: JSON.stringify(requestBody)
    })

    if (!languageResponse || !languageResponse.ok) {
      resultTranslations.hasError = true
      return resultTranslations
    }

    const responseJson: ITranslationResult[] = await languageResponse.json()

    if (responseJson.length <= 0) {
      resultTranslations.hasError = true
      return resultTranslations
    }

    responseJson.forEach(
      (translationResult: ITranslationResult, index: number) => {
        if (
          translationResult.translations.length > 0 &&
          index < resultTranslations.values.length
        ) {
          resultTranslations.values[index].translatedText =
            translationResult.translations[0].text
        }
      }
    )

    resultTranslations.hasError = false
    return resultTranslations
  } catch {
    resultTranslations.hasError = true
    return resultTranslations
  }
}
