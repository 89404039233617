import { Store } from '..'
import { TabTypes } from './actions'

const selectors = {
  getExtendedResults: (state: Store, type: TabTypes): any => {
    return {
      ...state.resultsKnowledgeExchange.extendedResults,
      featuredResults: state.resultsKnowledgeExchange[type].featuredResults
    }
  },
  getResults: (state: Store, type: TabTypes): any =>
    state.resultsKnowledgeExchange[type].results,
  getResultsCombined: (state: Store, type: TabTypes): any =>
    state.resultsKnowledgeExchange[type].resultsCombined,
  getFeatureResults: (state: Store, type: TabTypes): any =>
    state.resultsKnowledgeExchange[type].featuredResults,
  getResultsCombinedQuery: (state: Store, type: TabTypes): any =>
    state.resultsKnowledgeExchange[type].resultsCombinedQuery,
  hasResultsBeenFetched: (state: Store, type: TabTypes): any =>
    state.resultsKnowledgeExchange[type].hasResultsBeenFetched,
  getResultCount: (state: Store, type: TabTypes): any =>
    state.resultsKnowledgeExchange[type].resultCount,
  getExecutionTime: (state: Store, type: TabTypes): any =>
    state.resultsKnowledgeExchange[type].executionTime,
  getCurrentPage: (state: Store, type: TabTypes): any =>
    state.resultsKnowledgeExchange[type].currentPage,
  getHasError: (state: Store, type: TabTypes): boolean =>
    state.resultsKnowledgeExchange[type].hasError,
  getFilters: (state: Store, type: TabTypes): any =>
    state.resultsKnowledgeExchange[type].filters
}

export default selectors
