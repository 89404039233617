import React, { Dispatch, useMemo } from 'react'
import { connect } from 'react-redux'
import ReactPlaceholder from 'react-placeholder'
import { Box, Fade } from '@mui/material'
import 'Placeholder.css'
import { Store } from 'store'
import { useIntl } from 'react-intl'
import ResultsMarketResearchStore from 'store/ResultsMarketResearch'
import { MarketResearchResult } from 'components/contents/results/MarketResearchResult'
import Pagination from '../Pagination'
import { trackException } from 'utils/tracking'
import SearchSuggestions from 'components/contents/common/SearchSuggestions'
import { IDeviceSetting } from 'utils/deviceSettings'
import UserSettingsStore from 'store/UserSettings'
import FeaturedResults from './FeaturedResults'
import { dateFormatOptions } from 'constants/constants'
import LeftWidgetContainer from './widgetscontainer/LeftWidgetContainer'
import { getStylesResults } from 'styles/contents/resultpages/Results'
import { useFilter } from 'utils/filters'
import createDOMPurify from 'dompurify'
import { CognitiveMessage } from '../common/CognitiveMessage'
import { IUserSetting } from 'utils/userSettings'

interface ResultsMarketResearchMobileProps {
  searchQuery: any
  deviceSettings: IDeviceSetting
  resultsCount: number
  isLoadingDocument: number
  setDownloadMessage: Dispatch<boolean>
  findConfigCognitiveSearchEnabled: boolean
  cognitiveSearchEnabled: boolean
  showCognitiveMessage: boolean
  setShowCognitiveMessage: Dispatch<boolean>
  setUserSettings: Dispatch<IUserSetting>
}

type AllMobileProps = ReturnType<typeof mapStateToProps> &
  ResultsMarketResearchMobileProps

function ResultsMarketResearchMobile(props: AllMobileProps) {
  const {
    hasResultsBeenFetched,
    resultsCount,
    featuredResults,
    resultsCombined,
    searchQuery,
    deviceSettings,
    isLoadingDocument,
    setDownloadMessage,
    userSettings,
    findConfigCognitiveSearchEnabled,
    cognitiveSearchEnabled,
    showCognitiveMessage,
    setShowCognitiveMessage,
    setUserSettings,
    synonymsApplied
  } = props
  const DOMPurify = createDOMPurify(window)
  const resultClasses = getStylesResults()
  const intl = useIntl()
  const [currentPage] = useFilter('page', '1')

  const returnAllItems = (type: string) => {
    if (!resultsCombined || resultsCombined.length < 1) {
      return []
    }

    const items = []

    try {
      const firstResults =
        resultsCombined.length < 4 ? resultsCombined.length : 3

      if (type === 'first') {
        for (let i = 0; i < firstResults; i++) {
          items.push(
            <MarketResearchResult
              documentId={DOMPurify.sanitize(resultsCombined[i].Id)}
              companyName={DOMPurify.sanitize(resultsCombined[i].CompanyName)}
              fileType={DOMPurify.sanitize(resultsCombined[i].FileType)}
              fileName={DOMPurify.sanitize(resultsCombined[i].FileName)}
              headline={DOMPurify.sanitize(resultsCombined[i].Headline)}
              language={DOMPurify.sanitize(resultsCombined[i].Language)}
              pages={DOMPurify.sanitize(resultsCombined[i].Pages)}
              releaseDate={
                resultsCombined[i].ReleaseDate
                  ? intl.formatDate(
                      DOMPurify.sanitize(resultsCombined[i].ReleaseDate),
                      dateFormatOptions
                    )
                  : ''
              }
              synopsis={DOMPurify.sanitize(resultsCombined[i].Synopsis)}
              url={DOMPurify.sanitize(resultsCombined[i].Link)}
              primaryTicker={DOMPurify.sanitize(
                resultsCombined[i].PrimaryTicker
              )}
              deviceSettings={deviceSettings}
              searchQuery={DOMPurify.sanitize(searchQuery)}
              isLoadingDocument={isLoadingDocument}
              setDownloadMessage={setDownloadMessage}
              index={i}
              synonymsApplied={synonymsApplied}
            />
          )
        }
      } else {
        for (let i = firstResults; i < resultsCombined.length; i++) {
          items.push(
            <MarketResearchResult
              documentId={DOMPurify.sanitize(resultsCombined[i].Id)}
              companyName={DOMPurify.sanitize(resultsCombined[i].CompanyName)}
              fileType={DOMPurify.sanitize(resultsCombined[i].FileType)}
              fileName={DOMPurify.sanitize(resultsCombined[i].FileName)}
              headline={DOMPurify.sanitize(resultsCombined[i].Headline)}
              language={DOMPurify.sanitize(resultsCombined[i].Language)}
              pages={DOMPurify.sanitize(resultsCombined[i].Pages)}
              releaseDate={
                resultsCombined[i].ReleaseDate
                  ? intl.formatDate(
                      DOMPurify.sanitize(resultsCombined[i].ReleaseDate),
                      dateFormatOptions
                    )
                  : ''
              }
              synopsis={DOMPurify.sanitize(resultsCombined[i].Synopsis)}
              url={DOMPurify.sanitize(resultsCombined[i].Link)}
              primaryTicker={DOMPurify.sanitize(
                resultsCombined[i].PrimaryTicker
              )}
              deviceSettings={deviceSettings}
              searchQuery={DOMPurify.sanitize(searchQuery)}
              isLoadingDocument={isLoadingDocument}
              setDownloadMessage={setDownloadMessage}
              index={i}
              synonymsApplied={synonymsApplied}
            />
          )
        }
      }
    } catch (error) {
      trackException(
        'Error returning all items in ResultsMarketResearchMobile.tsx',
        error
      )
    }
    return items
  }

  const firstItems = useMemo(
    () => returnAllItems('first'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resultsCombined, intl]
  )
  const restItems = useMemo(
    () => returnAllItems('rest'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resultsCombined, intl]
  )

  return (
    <Box className={resultClasses.container}>
      <Box className={resultClasses.resultsContainer}>
        <SearchSuggestions
          wildCardActive={false}
          resultCount={resultsCount}
          resultsFetchend={hasResultsBeenFetched}
        />

        <ReactPlaceholder
          ready={
            hasResultsBeenFetched || Number(currentPage) > 1 ? true : false
          }
          type="text"
          rows={4}
          showLoadingAnimation={true}
          className={resultClasses.placeholder}
        >
          <Fade
            in={hasResultsBeenFetched || Number(currentPage) > 1 ? true : false}
            timeout={600}
          >
            <Box>
              {cognitiveSearchEnabled &&
                showCognitiveMessage &&
                findConfigCognitiveSearchEnabled &&
                synonymsApplied &&
                synonymsApplied.length > 0 && (
                  <CognitiveMessage
                    synonymsApplied={synonymsApplied}
                    setShowCognitiveMessage={setShowCognitiveMessage}
                    userSettings={userSettings}
                    setUserSettings={setUserSettings}
                  />
                )}
              {resultsCombined && resultsCount > 0 && (
                <FeaturedResults featuredResults={featuredResults} />
              )}
              {firstItems}
            </Box>
          </Fade>
        </ReactPlaceholder>
        {hasResultsBeenFetched &&
        (resultsCount < 1 || resultsCombined.length < 1) ? null : (
          <LeftWidgetContainer
            resultCount={resultsCount}
            hasResultsBeenFetched={hasResultsBeenFetched}
          />
        )}
        <ReactPlaceholder
          ready={hasResultsBeenFetched || Number(currentPage) > 1}
          type="text"
          rows={4}
          showLoadingAnimation={true}
          className={resultClasses.placeholder}
        >
          <Fade
            in={hasResultsBeenFetched || Number(currentPage) > 1}
            timeout={600}
          >
            <Box>
              {restItems}
              {hasResultsBeenFetched &&
                resultsCount != null &&
                resultsCount > 0 && (
                  <Pagination
                    {...props}
                    totalRecords={resultsCount}
                    pageLimit={20}
                    pageNeighbours={4}
                  />
                )}
            </Box>
          </Fade>
        </ReactPlaceholder>
        <ReactPlaceholder
          ready={hasResultsBeenFetched || resultsCount === 0}
          type="text"
          rows={4}
          showLoadingAnimation={true}
          className={resultClasses.placeholder}
        >
          <Box />
        </ReactPlaceholder>
      </Box>
    </Box>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    featuredResults:
      ResultsMarketResearchStore.selectors.getFeaturedResults(state),
    synonymsApplied:
      ResultsMarketResearchStore.selectors.getSynonymsApplied(state),
    resultsCombined:
      ResultsMarketResearchStore.selectors.getResultsCombined(state),
    executionTime: ResultsMarketResearchStore.selectors.getExecutionTime(state),
    hasResultsBeenFetched:
      ResultsMarketResearchStore.selectors.hasResultsBeenFetched(state),
    userSettings: UserSettingsStore.selectors.getUserSettings(state)
  }
}

export default connect(mapStateToProps)(ResultsMarketResearchMobile)
