import React, { useCallback, useEffect, useMemo } from 'react'
import ReactPlaceholder from 'react-placeholder'
import { Box, Fade } from '@mui/material'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import 'Placeholder.css'
import { Store } from 'store'
import UserSettingsStore from 'store/UserSettings'
import SettingsStore from 'store/Settings'
import ResultsAlexStore from 'store/ResultsAlex'
import ResultMetaDataStore from 'store/ResultMetaData'
import ErrorPage from 'components/contents/common/ErrorPage'
import PageStay from 'components/HOC/PageStay'
import { AlexResult } from 'components/contents/results/AlexResult'
import Pagination from '../Pagination'
import { trackException } from 'utils/tracking'
import { useReactRouterQueryStringInterface } from 'utils/useQueryState'
import { authorizeResultPage } from 'utils/authorization'
import createDOMPurify from 'dompurify'
import { dataSourcesTabs } from 'constants/constants'
import SearchSuggestions from 'components/contents/common/SearchSuggestions'
import ResultsAlexMobile from './ResultsAlexMobile'
import RightTopWidgetContainer from './widgetscontainer/RightTopWidgetContainer'
import FeaturedResults from './FeaturedResults'
import RightWidgetContainer from './widgetscontainer/RightWidgetContainer'
import LeftWidgetContainer from './widgetscontainer/LeftWidgetContainer'
import { getStylesResults } from 'styles/contents/resultpages/Results'
import { useFilter } from 'utils/filters'
import { KPMGFindGlobalVariables } from 'store/KPMGFindGlobalVariables'
import { IAlexResult } from 'components/models/AlexResult'
import { useLocation } from 'react-router-dom'

export interface ResultsAlexProps {
  userName: any
}

type AllProps = ReturnType<typeof mapStateToProps> & ResultsAlexProps

function ResultsAlex(props: AllProps) {
  const {
    featuredResults,
    results,
    resultsCount,
    hasResultsBeenFetched,
    hasErrorStore,
    userSettings,
    deviceSettings
  } = props
  const DOMPurify = createDOMPurify(window)
  const resultClasses = getStylesResults()
  const { getQueryString } = useReactRouterQueryStringInterface()
  const intl = useIntl()
  const queryStringFromUrl = getQueryString()

  const [currentPage] = useFilter('page', '1')
  const [searchQuery] = useFilter('q')
  const location = useLocation()

  useEffect(() => {
    KPMGFindGlobalVariables.setCurrentTab(dataSourcesTabs.alex)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setup = useCallback(
    async () => {
      authorizeResultPage(
        'Alex',
        userSettings,
        deviceSettings,
        DOMPurify.sanitize(location && location.search ? location.search : '')
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queryStringFromUrl]
  )

  const sanitizeAlexResult = function (result: IAlexResult) {
    return {
      docId: DOMPurify.sanitize(result.docId),
      title: DOMPurify.sanitize(result.title),
      synopsis: DOMPurify.sanitize(result.synopsis),
      url: result.url ? DOMPurify.sanitize(result.url) : null,
      createdDate: result.createdDate
        ? DOMPurify.sanitize(result.createdDate)
        : null,
      mimeType: result.mimeType ? DOMPurify.sanitize(result.mimeType) : null,
      parentId: result.parentId ? DOMPurify.sanitize(result.parentId) : null,
      parentTitle: result.parentTitle
        ? DOMPurify.sanitize(result.parentTitle)
        : null,
      sources: result.sources
        ? result.sources.map((source: string) => {
            return DOMPurify.sanitize(source)
          })
        : null,
      featured: DOMPurify.sanitize(result.featured),
      keyword: result.keyword ? DOMPurify.sanitize(result.keyword) : null,
      killDocId: result.killDocId ? DOMPurify.sanitize(result.killDocId) : null,
      tocRef: result.tocRef ? DOMPurify.sanitize(result.tocRef) : null,
      displayText: result.displayText
        ? DOMPurify.sanitize(result.displayText)
        : null,
      tocTitle: result.tocTitle ? DOMPurify.sanitize(result.tocTitle) : null,
      tocCountry: result.tocCountry
        ? DOMPurify.sanitize(result.tocCountry)
        : null,
      tocWorkflow: result.tocWorkflow
        ? DOMPurify.sanitize(result.tocWorkflow)
        : null,
      tocVersion: result.keyword ? DOMPurify.sanitize(result.tocVersion) : null
    }
  }

  useEffect(() => {
    try {
      setup()
    } catch (error) {
      trackException('Error in fetching results in ResultsAlex.tsx', error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, intl])

  const returnAllItems = (type: string) => {
    if (!results || results.length < 1) {
      return []
    }

    const items = []
    try {
      // Todo: remove workaround for manuals after paging is working
      const startIndex =
        ((Number(DOMPurify.sanitize(currentPage)) || 1) - 1) * 25
      const firstResults =
        startIndex + (results.length < 4 ? results.length : 3)

      if (type === 'first') {
        for (let i = startIndex; i < firstResults; i++) {
          items.push(
            <AlexResult
              result={sanitizeAlexResult(results[i])}
              key={i}
              index={i}
              searchQuery={DOMPurify.sanitize(searchQuery)}
              deviceSettings={deviceSettings}
            />
          )
        }
      } else {
        // Todo: remove workaround for manuals after paging is working
        for (
          let i = firstResults;
          i < results.length &&
          i < firstResults + 25 - (results.length < 4 ? results.length : 3);
          i++
        ) {
          items.push(
            <AlexResult
              result={sanitizeAlexResult(results[i])}
              key={i}
              index={i}
              searchQuery={DOMPurify.sanitize(searchQuery)}
              deviceSettings={deviceSettings}
            />
          )
        }
      }
    } catch (error) {
      trackException('Error returning all items in ResultsAlex.tsx', error)
    }
    return items
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const firstItems = useMemo(() => returnAllItems('first'), [results, intl])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const restItems = useMemo(() => returnAllItems('rest'), [results, intl])

  return (
    <Box>
      {!hasErrorStore && !!searchQuery && (
        <>
          {deviceSettings.renderMobile ? (
            <ResultsAlexMobile
              searchQuery={searchQuery}
              deviceSettings={deviceSettings}
              sanitizeAlexResult={sanitizeAlexResult}
            />
          ) : (
            <Box className={resultClasses.container}>
              <Box
                id={'con-resultvertical-01'}
                className={resultClasses.resultsContainer}
              >
                <SearchSuggestions
                  wildCardActive={false}
                  resultCount={resultsCount}
                  resultsFetchend={hasResultsBeenFetched}
                />

                <ReactPlaceholder
                  ready={hasResultsBeenFetched ? hasResultsBeenFetched : false}
                  type="text"
                  rows={4}
                  showLoadingAnimation={true}
                  className={resultClasses.placeholder}
                >
                  <Fade
                    in={hasResultsBeenFetched ? hasResultsBeenFetched : false}
                    timeout={600}
                  >
                    <Box id={'con-resultvertical-section-01'}>
                      {results && resultsCount > 0 && (
                        <FeaturedResults featuredResults={featuredResults} />
                      )}
                      {firstItems}
                    </Box>
                  </Fade>
                </ReactPlaceholder>
                {Number(currentPage) === 1 && (
                  <LeftWidgetContainer
                    resultCount={resultsCount}
                    hasResultsBeenFetched={hasResultsBeenFetched}
                  />
                )}
                <ReactPlaceholder
                  ready={hasResultsBeenFetched}
                  type="text"
                  rows={4}
                  showLoadingAnimation={true}
                  className={resultClasses.placeholder}
                >
                  <Fade in={hasResultsBeenFetched} timeout={600}>
                    <Box id={'con-resultvertical-section-02'}>
                      {restItems}
                      {resultsCount != null && resultsCount > 0 && (
                        <Pagination
                          {...props}
                          totalRecords={resultsCount}
                          pageLimit={25}
                          pageNeighbours={4}
                        />
                      )}
                    </Box>
                  </Fade>{' '}
                </ReactPlaceholder>
                <ReactPlaceholder
                  ready={hasResultsBeenFetched ? hasResultsBeenFetched : false}
                  type="text"
                  rows={4}
                  showLoadingAnimation={true}
                  className={resultClasses.placeholder}
                >
                  <Box />
                </ReactPlaceholder>
              </Box>
              <Box className={resultClasses.advertisementContainer}>
                <RightTopWidgetContainer
                  searchQuery={searchQuery}
                  scope={'alex'}
                />
                <RightWidgetContainer />
              </Box>
            </Box>
          )}
        </>
      )}
      {hasErrorStore && <ErrorPage dataSource="alex" />}
    </Box>
  )
}

const mapStateToProps = (state: Store, props: ResultsAlexProps) => {
  return {
    featuredResults: ResultsAlexStore.selectors.getFeaturedResults(state),
    results: ResultsAlexStore.selectors.getResults(state),
    resultsCount: ResultsAlexStore.selectors.getResultCount(state),
    executionTime: ResultsAlexStore.selectors.getExecutionTime(state),
    hasResultsBeenFetched:
      ResultsAlexStore.selectors.hasResultsBeenFetched(state),
    hasErrorStore: ResultMetaDataStore.selectors.getDataSourceHasError(
      state,
      'alex'
    ),
    userSettings: UserSettingsStore.selectors.getUserSettings(state),
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state)
  }
}

export default PageStay(connect(mapStateToProps)(ResultsAlex))
