import { AnyAction } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { Store } from '..'
import AuthStore from '../Auth'
import * as Config from 'config'
import { FetchWithCache } from 'utils/api'
import Selectors from './selectors'
import ResultMetaDataStore from 'store/ResultMetaData'
import { IUserSetting } from 'utils/userSettings'
import { IDeviceSetting } from 'utils/deviceSettings'
import { alex } from 'constants/filters'
import { trackException } from 'utils/tracking'
import { renewAuthorizationToken } from 'utils/token'
import {
  ActionMetaData,
  CustomAction,
  getActionMetaData
} from 'store/extension/customAction'
import { KPMGFindGlobalVariables } from 'store/KPMGFindGlobalVariables'
import { FeaturedResult } from 'components/models/FeaturedResult'

export enum ResultsAlexActionTypes {
  FETCH_REQUEST = 'resultsAlex/FETCH_REQUEST',
  FETCH_SUCCESS = 'resultsAlex/FETCH_SUCCESS',
  FETCH_SUCCESS_COMBINED = 'resultsAlex/FETCH_SUCCESS_COMBINED',
  FETCH_SUCCESS_FIRST_PAGE_COMBINED = 'resultsAlex/FETCH_SUCCESS_FIRST_PAGE_COMBINED',
  FETCH_FAILURE = 'resultsAlex/FETCH_FAILURE',
  INITIALIZE_RESULTS_ALL = 'persist/REHYDRATE'
}

export interface IFetchRequest extends CustomAction<ResultsAlexActionTypes> {
  payload: {
    resetCombined: boolean
    searchQuery: string
  }
}
export type IFetchFailure = CustomAction<ResultsAlexActionTypes>

export interface IFetchSuccess extends CustomAction<ResultsAlexActionTypes> {
  payload: {
    response: any
    featuredResults: FeaturedResult[]
  }
}

export interface IFetchSuccessCombined
  extends CustomAction<ResultsAlexActionTypes> {
  payload: {
    response: any
  }
}

export const fetchRequest = (
  resetCombined: boolean,
  searchQuery: string,
  actionMetaData: ActionMetaData
): IFetchRequest => ({
  type: ResultsAlexActionTypes.FETCH_REQUEST,
  payload: {
    resetCombined: resetCombined,
    searchQuery: searchQuery
  },
  metaData: {
    ...actionMetaData,
    isStartAction: true
  }
})
export const fetchSuccess = (
  response: any = {},
  featuredResults: FeaturedResult[],
  actionMetaData: ActionMetaData
): IFetchSuccess => ({
  type: ResultsAlexActionTypes.FETCH_SUCCESS,
  payload: { response, featuredResults },
  metaData: actionMetaData
})
export const fetchSuccessCombined = (
  response: any = {},
  actionMetaData: ActionMetaData
): IFetchSuccessCombined => ({
  type: ResultsAlexActionTypes.FETCH_SUCCESS_COMBINED,
  payload: { response },
  metaData: actionMetaData
})

export const fetchFailure = (
  actionMetaData: ActionMetaData
): IFetchFailure => ({
  type: ResultsAlexActionTypes.FETCH_FAILURE,
  metaData: actionMetaData
})

export const fetchResultsAlex = (
  searchQuery: string,
  currentPage: number,
  userSettings: IUserSetting,
  deviceSettings: IDeviceSetting,
  filters: {
    [key: string]: string
  }
  // eslint-disable-next-line @typescript-eslint/ban-types
): ThunkAction<Promise<void>, Store, {}, AnyAction> => {
  return async (
    // eslint-disable-next-line @typescript-eslint/ban-types
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => Store
  ) => {
    const actionMetaData = getActionMetaData('alex')

    dispatch(ResultMetaDataStore.actions.fetchRequest('alex', actionMetaData))

    const resultsCombinedQuery = Selectors.getResultsCombinedQuery(getState())
    if (resultsCombinedQuery !== searchQuery) {
      if (resultsCombinedQuery === '') {
        dispatch(fetchRequest(false, searchQuery, actionMetaData))
      } else {
        dispatch(fetchRequest(true, searchQuery, actionMetaData))
      }
    } else {
      dispatch(fetchRequest(false, searchQuery, actionMetaData))
    }
    try {
      if (!searchQuery || searchQuery === '' || searchQuery === '""') {
        dispatch(fetchFailure(actionMetaData))
        return
      }

      const t0 = performance.now()

      // First make a request to get needed parameters for the query
      const paramQueryUrl = `${Config.APIM_BASE_URL}alexapi/getqueryparameters`

      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        throw new Error('Authentication: Cannot renew authentication token')
      }

      const paramResponse = await FetchWithCache(paramQueryUrl, {
        method: 'GET',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${esToken}`
        }
      })

      let applicableCountries = []
      let auditingStandards = []
      let versions = []
      let activeDomains = []

      if (
        !paramResponse ||
        paramResponse.hasError ||
        !paramResponse.responseJSON
      ) {
        trackException(
          'Error fetching alex query parameters',
          new Error('Error fetching alex query parameters'),
          null
        )
        applicableCountries.push('International')
        auditingStandards.push('ISA')
      } else {
        applicableCountries = paramResponse.responseJSON.applicableCountries
        auditingStandards = paramResponse.responseJSON.auditingStandards
        versions = paramResponse.responseJSON.versions
        activeDomains = paramResponse.responseJSON.activeDomains
      }

      const searchType = 'KAEG'

      // Build and get results alex data source
      let apiUrl = `${
        Config.APIM_BASE_URL
      }alexapi/searchdatawithallfilters?searchTerm=${encodeURIComponent(
        searchQuery
      )}&startPage=${currentPage ? currentPage : 1}&endPage=${
        currentPage ? currentPage : 1
      }&searchType=${searchType}&applicableCountries=${encodeURIComponent(
        applicableCountries.join('|')
      )}&auditingStandards=${encodeURIComponent(
        auditingStandards.join('|')
      )}&processFeaturedResults=${
        (currentPage || 1) === 1 ? true : false
      }&featuredResultsRowLimit=3&origin=KPMGFind&featuredResultsCountry=${
        userSettings.Country
      }&function=${userSettings.Function}`

      if (versions && versions.length > 0) {
        apiUrl += `&versions=${encodeURIComponent(versions.join('|'))}`
      }

      if (activeDomains && activeDomains.length > 0) {
        apiUrl += `&activeDomains=${encodeURIComponent(
          activeDomains.join('|')
        )}`
      }

      if (filters.orderBy) {
        const filterOBCat = alex.find(
          (fl: FilterCategory) => fl.key === 'orderBy'
        )
        if (filterOBCat) {
          const filterOBOpt = filterOBCat.options.find(
            (opt: Filter) => opt.key === filters.orderBy
          )
          if (filterOBOpt && filterOBOpt.value) {
            apiUrl += `&sortOrder=${filterOBOpt.value}`
          }
        }
      } else {
        apiUrl += '&sortOrder=Relevance'
      }

      const hasError = Selectors.getHasError(getState())

      const response = await FetchWithCache(
        apiUrl,
        {
          method: 'POST',
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
            Authorization: `Bearer ${esToken}`
          }
        },
        hasError,
        true,
        actionMetaData.transactionType,
        KPMGFindGlobalVariables.getCurrentTab() === 'alex',
        searchQuery
      )

      let result
      if (!response || response.hasError || !response.responseJSON) {
        dispatch(
          ResultMetaDataStore.actions.hasError(
            'alex',
            response.errorResponse,
            undefined,
            actionMetaData
          )
        )
        dispatch(fetchFailure(actionMetaData))
        return
      } else {
        result = response.responseJSON
      }

      const featuredResults =
        result && result.FeaturedResults ? result.FeaturedResults : []

      const resultArray =
        result && result.searchResultModel
          ? result.searchResultModel.manualsSearchContent
            ? result.searchResultModel.manualsSearchContent.items
            : []
          : []

      let resultCount =
        result && result.searchResultModel
          ? result.searchResultModel.combinedTotalCount
          : 0

      const t1 = performance.now()

      resultCount = parseInt(resultCount)

      const executionTime = t1 - t0
      dispatch(
        fetchSuccess(
          {
            results: resultArray,
            resultCount,
            executionTime,
            currentPage: currentPage || 1
          },
          featuredResults,
          actionMetaData
        )
      )
      dispatch(
        ResultMetaDataStore.actions.fetchSuccess(
          'alex',
          {
            executionTime: executionTime,
            resultsCount: resultCount
          },
          actionMetaData
        )
      )

      dispatch(
        fetchSuccessCombined(
          {
            results: resultArray
          },
          actionMetaData
        )
      )
    } catch (error) {
      dispatch(
        ResultMetaDataStore.actions.hasError(
          'alex',
          undefined,
          error,
          actionMetaData
        )
      )
      dispatch(fetchFailure(actionMetaData))
    }
  }
}

export type ResultsAlexActions = IFetchRequest | IFetchFailure | IFetchFailure
