import React, { useState } from 'react'
import {
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  TextField,
  Typography
} from '@mui/material'
import StarIcon from '@mui/icons-material/Star'
import { withStyles } from '@mui/styles'
import Rating from '@mui/lab/Rating'
import { FormattedMessage, useIntl } from 'react-intl'
import { trackEvents } from 'utils/tracking'
import { getStylesFeedbackLong } from 'styles/contents/FeedbackLong'
import SettingsStore from 'store/Settings'
import { connect } from 'react-redux'
import { Store } from 'store'
import UserSettingsStore from 'store/UserSettings'
import { KPMGFindGlobalVariables } from 'store/KPMGFindGlobalVariables'

const StyledRating = withStyles({
  iconFilled: {
    color: '#eaaa00'
  },
  iconHover: {
    color: '#eaaa00'
  }
})(Rating)

export interface FeedbackProps {
  open: boolean
  onClose: () => void
  onSend: () => void
  onDelay: () => void
}

type AllProps = ReturnType<typeof mapStateToProps> & FeedbackProps

function FeedbackLong(props: AllProps): JSX.Element {
  const classes = getStylesFeedbackLong()
  const intl = useIntl()
  const {
    open,
    onClose,
    onSend,
    onDelay,
    deviceSettings,
    findConfiguration,
    userSettings
  } = props
  const [rating, setRating] = useState(5)
  const [missingContent, setMissingContent] = useState('')
  const [missingFeatures, setMissingFeatures] = useState('')
  const [additionalComment, setAdditionalComment] = useState('')

  const trackFeedback = () => {
    trackEvents('feedback', {
      user_email: KPMGFindGlobalVariables.getUserName(),
      type: 'detailed',
      rating: rating > 2 ? 'positive' : 'negative',
      stars: rating,
      missing_sources: missingContent,
      missing_feature: missingFeatures,
      other_comment: additionalComment
    })
  }
  const winWidth = window.innerWidth || document.documentElement.clientWidth
  return (
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    <Dialog open={open} onClose={() => {}}>
      <DialogTitle className={classes.dialogTitle}>
        <Typography variant="h5">
          <FormattedMessage id="feedback_title" defaultMessage="Feedback" />
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography variant="body2" className={classes.description}>
          <FormattedMessage
            id="feedback_description"
            values={{
              linebreak: <br />
            }}
            defaultMessage="You have been using KPMG Find for 3 month now.{linebreak}Please give us your feedback to further enhance our service."
          />
        </Typography>
        <Box className={classes.ratingContainer}>
          <StyledRating
            value={rating}
            precision={1}
            icon={<StarIcon fontSize="large" />}
            onChange={(
              // eslint-disable-next-line @typescript-eslint/ban-types
              event: React.ChangeEvent<{}>,
              newValue: null | number
            ) => {
              setRating(newValue || 0)
            }}
          />
        </Box>
        <TextField
          label={intl.formatMessage({
            id: 'content_missing',
            defaultMessage: 'Which content are you missing?'
          })}
          margin="normal"
          inputProps={
            deviceSettings.renderMobile &&
            intl.formatMessage({
              id: 'content_missing',
              defaultMessage: 'Which content are you missing?'
            }).length > 34 &&
            winWidth < 410
              ? { style: { marginTop: 16 } }
              : {}
          }
          className={classes.textField}
          multiline
          maxRows={4}
          value={missingContent}
          onChange={(event) => setMissingContent(event.target.value)}
        />
        <TextField
          label={intl.formatMessage({
            id: 'feature_missing',
            defaultMessage: 'Which feature are you missing?'
          })}
          margin="normal"
          inputProps={
            deviceSettings.renderMobile &&
            intl.formatMessage({
              id: 'feature_missing',
              defaultMessage: 'Which feature are you missing?'
            }).length > 34 &&
            winWidth < 410
              ? { style: { marginTop: 16 } }
              : {}
          }
          className={classes.textField}
          multiline
          maxRows={4}
          value={missingFeatures}
          onChange={(event) => setMissingFeatures(event.target.value)}
        />
        <TextField
          label={intl.formatMessage({
            id: 'other_comments',
            defaultMessage: 'What else do you want to share?'
          })}
          margin="normal"
          inputProps={
            deviceSettings.renderMobile &&
            intl.formatMessage({
              id: 'other_comments',
              defaultMessage: 'What else do you want to share?'
            }).length > 34 &&
            winWidth < 410
              ? { style: { marginTop: 16 } }
              : {}
          }
          className={classes.textField}
          multiline
          maxRows={4}
          value={additionalComment}
          onChange={(event) => setAdditionalComment(event.target.value)}
        />
      </DialogContent>
      <DialogActions className={classes.feedbackActions}>
        <Box className={classes.dialogSubmitText}>
          <FormattedMessage
            id="by_sending_feedback"
            values={{
              linebreak: <br className={classes.dialogSubmitTextBreak} />
            }}
            defaultMessage="By sending feedback you agree that we collect your email {linebreak} so we are able to reach out in case of questions."
          />
        </Box>
        <Box className={classes.dialogActions}>
          <Button
            onClick={() => {
              trackFeedback()
              onSend()
              onClose()
            }}
            color="primary"
            variant="contained"
            autoFocus
            disableRipple
          >
            <FormattedMessage id="send_feedback" defaultMessage="Send" />
          </Button>
          {findConfiguration.FeedbackMaxDelays &&
            userSettings.FeedbackLongDelayed <
              findConfiguration.FeedbackMaxDelays && (
              <Button
                onClick={() => {
                  onDelay()
                  onClose()
                }}
                color="secondary"
                variant="contained"
                autoFocus
                disableRipple
              >
                <FormattedMessage
                  id="send_feedback_ask_later"
                  defaultMessage="Ask me later"
                />
              </Button>
            )}
        </Box>
      </DialogActions>
    </Dialog>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state),
    findConfiguration: SettingsStore.selectors.getFindConfiguration(state),
    userSettings: UserSettingsStore.selectors.getUserSettings(state)
  }
}

export default connect(mapStateToProps)(FeedbackLong)
