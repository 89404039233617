import { Store } from '..'
import { IAADState } from './reducers'

const selectors = {
  getAccessToken: (state: Store): any => state.auth.aad.accessToken,
  getAADInfo: (state: Store): IAADState => state.auth.aad,
  getAuthToken: (state: Store): string => state.auth.authToken
}

export default selectors
