import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesLeftPeopleWidget(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles(): any {
  return makeStyles((theme: Theme) => ({
    profilepicWrapper: {
      flex: '0 0 116px',
      display: 'flex',
      justifyContent: 'space-around',

      alignItems: 'center'
    },
    peopleTitle: {
      padding: '6px 16px 0 16px',
      paddingBottom: '0 !important'
    },
    peopleContent: {
      padding: '6px 16px',
      fontSize: '14px'
    },

    peopleControls: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      alignItems: 'center',
      padding: '8px 9px'
    },
    iconButton: {
      minWidth: '32px',
      width: '32px',
      height: '32px',
      marginLeft: '0px !important'
    },
    peopleButton: {
      textTransform: 'none',
      fontWeight: 'normal'
    },
    peopleIcon: {
      height: '20px',
      maxWidth: '20px',
      [`${theme.breakpoints.down(1024)}`]: {
        color: '#005eb8'
      }
    },
    iconTeamsCallWrapper: {
      display: 'inline-block',
      position: 'relative',
      height: '20px',
      width: '32px'
    },
    peopleTeams: {
      width: '13px',
      maxWidth: '13px',
      position: 'absolute',
      top: 0,
      right: 0
    },
    iconTeamsCallOverlay: {
      position: 'absolute',

      pointerEvents: 'none',
      height: '15px',
      maxWidth: '15px',
      bottom: 0,
      left: 0
    },
    peopleLinkedIn: {
      height: '19px',
      maxHeight: '19px'
    }
  }))
}
