import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesTopRightChatWidget(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles(): any {
  return makeStyles((theme: Theme) => ({
    widgetContainer: {
      marginLeft: 16,
      marginBottom: 16,
      outline: 'solid 1px #dfe1e5',
      borderRadius: 4,
      display: 'flex',
      flexDirection: 'column',
      boxSizing: 'border-box',
      padding: '10px 20px 5px 20px'
    },
    link: {
      color: '#005eb8'
    }
  }))
}
