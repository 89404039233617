import { Reducer } from 'redux'
import {
  ResultsChatActions,
  ResultsChatActionTypes,
  IFetchSuccess
} from './actions'
import { IChatRequest } from 'components/models/Chat'

export interface IResultsChatStore {
  chatRequestList: IChatRequest[]
  hasError: boolean
}

const initialState: IResultsChatStore = {
  chatRequestList: [],
  hasError: false
}

const reducers: Reducer<IResultsChatStore, ResultsChatActions> = (
  state: IResultsChatStore = initialState,
  action:
    | {
        type: ResultsChatActionTypes.INITIALIZE_RESULTS_CHAT
        payload: any
      }
    | ResultsChatActions
) => {
  switch (action.type) {
    case ResultsChatActionTypes.INITIALIZE_RESULTS_CHAT:
      return {
        ...state,
        ...initialState
      }
    case ResultsChatActionTypes.FETCH_REQUEST:
      const { chatRequest } = (action as any).payload
      const newChatRequestList = [...state.chatRequestList]
      if (
        !newChatRequestList.find(
          (chatRequestExisting) =>
            chatRequestExisting.Request === chatRequest.Request &&
            chatRequestExisting.RequestIndex === chatRequest.RequestIndex
        )
      ) {
        newChatRequestList.push(chatRequest)
      }
      return {
        ...state,
        chatRequestList: newChatRequestList,
        hasError: false
      }
    case ResultsChatActionTypes.FETCH_FAILURE:
      return {
        ...state,
        hasError: true
      }
    case ResultsChatActionTypes.FETCH_SUCCESS:
      const { chatRequest: _chatRequest } = (action as IFetchSuccess).payload

      const _newChatRequestList = [...state.chatRequestList]
      const currentRequest = _newChatRequestList.find(
        (_chatRequestExisting) =>
          _chatRequestExisting.RequestIndex === _chatRequest.RequestIndex
      )

      if (currentRequest && _chatRequest.Answer.AnswerResponse) {
        currentRequest.Answer = {
          ..._chatRequest.Answer,
          AnswerFetched: true
        }
      }
      return {
        ...state,
        chatRequestList: _newChatRequestList,
        hasError: false
      }
    case ResultsChatActionTypes.NEW_TOPIC:
      return {
        ...state,
        chatRequestList: [],
        hasError: false
      }
    default:
      return state
  }
}

export default reducers
