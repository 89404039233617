import React, { useEffect, useCallback, useState, useRef } from 'react'
import { Fade } from '@mui/material'
import { useIntl } from 'react-intl'
import ReactPlaceholder from 'react-placeholder'
import { connect } from 'react-redux'
import 'Placeholder.css'
import { Store } from 'store'
import AuthStore from 'store/Auth'
import TranslationsStore from 'store/Translations'
import UserSettingsStore from 'store/UserSettings'
import SettingsStore from 'store/Settings'
import { trackException } from 'utils/tracking'
import { fetchTopWidgets, ITopWidgetResults } from 'utils/widgets/resultWidgets'
import { getStylesRightTopWidgetContainer } from 'styles/contents/resultpages/widgetscontainer/RightTopWidgetContainer'
import SearchStore from 'store/Search'

export interface RightTopWidgetContainerProps {
  searchQuery: string
  scope: string
}

type AllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RightTopWidgetContainerProps

function RightTopWidgetContainer(props: AllProps) {
  const {
    searchQuery,
    adsTranslations,
    scope,
    userSettings,
    deviceSettings,
    fetchAdsTranslations,
    aadInfo,
    useEntityRecognition,
    hasSearchDataBeenFetched,
    entityData
  } = props
  const [topWidgetsLoaded, setTopWidgetsLoaded] = useState<boolean>(false)
  const [adsWidget, setAdsWidget] = useState<any>(null)
  const [chatWidget, setChatWidget] = useState<any>(null)

  const [officeLocationWidget, setOfficeLocationWidget] = useState<any>(null)
  const [companySnapshotWidget, setCompanySnapshotWidget] = useState<any>(null)
  const [alexTopRightWidget, setAlexTopRightWidget] = useState<any>(null)

  const [addsFetched, setAddsFetched] = useState<boolean>(false)
  const intl = useIntl()
  const firstUpdate = useRef(true)
  const classes = getStylesRightTopWidgetContainer()

  const fetchTopWidgetsHandler = useCallback(async () => {
    setAddsFetched(false)

    fetchAdsTranslations(
      searchQuery,
      userSettings.Country || '',
      userSettings.Function || '',
      userSettings.Language || 'en',
      scope
    )
      .then(() => {
        setAddsFetched(true)
      })
      .catch(() => {
        setAddsFetched(true)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl, userSettings.Language, searchQuery, scope])

  useEffect(() => {
    setOfficeLocationWidget(null)
    setCompanySnapshotWidget(null)
    setAdsWidget(null)
    setAlexTopRightWidget(null)
    setTopWidgetsLoaded(false)

    if (!addsFetched) {
      return
    }

    if (!searchQuery) return

    try {
      fetchTopWidgets(
        adsTranslations,
        searchQuery,
        scope.indexOf('_') !== -1
          ? scope.substring(0, scope.indexOf('_'))
          : scope,
        userSettings,
        deviceSettings,
        aadInfo,
        useEntityRecognition,
        entityData
      )
        .then((topWidgets: ITopWidgetResults) => {
          setAdsWidget(topWidgets.ads)
          setChatWidget(topWidgets.chat)
          setOfficeLocationWidget(topWidgets.office)
          setCompanySnapshotWidget(topWidgets.company)
          setAlexTopRightWidget(topWidgets.alex)
          setTopWidgetsLoaded(true)
        })
        .catch((rejectedMsg: string) => {
          setAdsWidget(null)
          setOfficeLocationWidget(null)
          setCompanySnapshotWidget(null)
          setAlexTopRightWidget(null)
        })
    } catch (error) {
      trackException(
        'Error in fetchTopWidgetData in RightTopWidgetContainer.tsx',
        error
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addsFetched])

  /* Fetch top widgets*/
  // Two effect hooks are required to fetch the widgets everytime needed

  // once on every page initial loading, so it is loaded on all result pages not only on page 1
  useEffect(() => {
    if (!useEntityRecognition) {
      fetchTopWidgetsHandler()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // additionally load it when its not the first rendering in case of:
  // - the country has changed
  // - the configured language has changed
  useEffect(() => {
    // in case of first rendering skip this widget fetch, its already fetched through intial effect hook
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }

    if (!useEntityRecognition) {
      fetchTopWidgetsHandler()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSettings.Language, searchQuery, scope])

  useEffect(() => {
    // in case of first rendering skip this widget fetch, its already fetched through intial effect hook
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }

    if (useEntityRecognition && !hasSearchDataBeenFetched) {
      setAddsFetched(false)
    }
    if (useEntityRecognition && hasSearchDataBeenFetched) {
      fetchTopWidgetsHandler()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSettings.Language, scope, hasSearchDataBeenFetched])

  return (
    <ReactPlaceholder
      ready={topWidgetsLoaded && addsFetched}
      type="text"
      rows={4}
      showLoadingAnimation={true}
      className={classes.resultWidgetPlaceholder}
      style={{ width: 'auto' }}
    >
      {topWidgetsLoaded &&
        addsFetched &&
        (adsWidget !== null ||
          chatWidget !== null ||
          officeLocationWidget !== null ||
          companySnapshotWidget !== null ||
          alexTopRightWidget !== null) && (
          <Fade in={true} timeout={600}>
            <div>
              {alexTopRightWidget}
              {adsWidget}
              {/* chatWidget */}
              {officeLocationWidget}
              {companySnapshotWidget}
            </div>
          </Fade>
        )}
    </ReactPlaceholder>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    adsTranslations: TranslationsStore.selectors.getAdsTranslations(state),
    userSettings: UserSettingsStore.selectors.getUserSettings(state),
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state),
    aadInfo: AuthStore.selectors.getAADInfo(state),
    useEntityRecognition:
      SettingsStore.selectors.getUseEntityRecognition(state),
    hasSearchDataBeenFetched:
      SearchStore.selectors.hasSearchDataBeenFetched(state),
    entityData: SearchStore.selectors.getEntities(state)
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchAdsTranslations: (
      searchQuery: string,
      country: string,
      func: string,
      language: string,
      datasource: string
    ) =>
      dispatch(
        TranslationsStore.actions.fetchAdsTranslations(
          searchQuery,
          country,
          func,
          language,
          datasource
        )
      )
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RightTopWidgetContainer)
