import { FeaturedResult } from 'components/models/FeaturedResult'
import { Reducer } from 'redux'
import {
  ResultsSourceActions,
  ResultsSourceActionTypes,
  IFetchSuccess,
  IFetchSuccessCombined
} from './actions'
import { ISynonymsApplied } from 'components/models/SynonymsApplied'
import { ISourceResult } from 'components/models/SourceResult'

export interface IResultsSourceStore {
  hasError: boolean
  hasResultsBeenFetched: boolean
  featuredResults: FeaturedResult[]
  synonymsApplied: ISynonymsApplied[]
  results: ISourceResult[]
  resultsCombined: ISourceResult[]
  resultsCombinedQuery: string
  resultCount: number
  executionTime: number
  currentPage: number
}

const initialState: IResultsSourceStore = {
  hasError: false,
  hasResultsBeenFetched: false,
  resultCount: -1,
  featuredResults: [],
  synonymsApplied: [],
  results: [],
  resultsCombined: [],
  resultsCombinedQuery: '',
  executionTime: 0,
  currentPage: 1
}

const reducers: Reducer<IResultsSourceStore, ResultsSourceActions> = (
  state: IResultsSourceStore = initialState,
  action: ResultsSourceActions | { type: 'SET_CURRENT_PAGE'; payload: any }
) => {
  switch (action.type) {
    case 'SET_CURRENT_PAGE':
      return {
        ...state,
        currentPage: action.payload || 0
      }
    case ResultsSourceActionTypes.INITIALIZE_RESULTS_SOURCE:
      if (!(action as any).payload) {
        return initialState
      }
      const { resultsSource } = (action as any).payload
      return {
        ...state,
        currentPage: resultsSource.currentPage || 1
      }
    case ResultsSourceActionTypes.FETCH_REQUEST:
      const { resetCombined, searchQuery } = (action as any).payload
      return {
        ...state,
        hasError: false,
        hasResultsBeenFetched: false,
        executionTime: 0,
        resultCount: -1,
        results: [],
        resultsCombined: resetCombined ? [] : state.resultsCombined,
        resultsCombinedQuery: searchQuery
      }
    case ResultsSourceActionTypes.FETCH_FAILURE:
      return {
        ...state,
        hasError: true,
        hasResultsBeenFetched: true,
        resultCount: 0
      }
    case ResultsSourceActionTypes.FETCH_SUCCESS:
      const { response, featuredResults, synonymsApplied } = (
        action as IFetchSuccess
      ).payload
      return {
        ...state,
        hasError: false,
        hasResultsBeenFetched: true,
        executionTime: response.executionTime,
        resultCount: response.resultCount,
        featuredResults:
          response.currentPage === 1 ? featuredResults : state.featuredResults,
        synonymsApplied:
          response.currentPage === 1 ? synonymsApplied : state.synonymsApplied,
        results: response.queryResults,
        currentPage: response.currentPage
      }
    case ResultsSourceActionTypes.FETCH_SUCCESS_COMBINED:
      const { response: responseCombined } = (action as IFetchSuccessCombined)
        .payload
      return {
        ...state,
        hasError: false,
        resultsCombined: responseCombined.queryResults
      }
    default:
      return state
  }
}

export default reducers
