import React, { Dispatch, useMemo } from 'react'
import { Box, Fade } from '@mui/material'
import { useIntl } from 'react-intl'
import ReactPlaceholder from 'react-placeholder'
import { connect } from 'react-redux'
import 'Placeholder.css'
import { Store } from 'store'
import ResultsNewsStore from 'store/ResultsNews'
import Pagination from '../Pagination'
import TextResult from 'components/contents/results/TextResult'
import { trackException } from 'utils/tracking'
import { dateFormatOptions } from 'constants/constants'
import SearchSuggestions from 'components/contents/common/SearchSuggestions'
import { IDeviceSetting } from 'utils/deviceSettings'
import FeaturedResults from './FeaturedResults'
import LeftWidgetContainer from './widgetscontainer/LeftWidgetContainer'
import { getStylesResults } from 'styles/contents/resultpages/Results'
import { useFilter } from 'utils/filters'
import createDOMPurify from 'dompurify'
import { CognitiveMessage } from '../common/CognitiveMessage'
import { IUserSetting } from 'utils/userSettings'

interface ResultsNewsMobileProps {
  searchQuery: any
  deviceSettings: IDeviceSetting
  userSettings: IUserSetting
  findConfigCognitiveSearchEnabled: boolean
  cognitiveSearchEnabled: boolean
  showCognitiveMessage: boolean
  setShowCognitiveMessage: Dispatch<boolean>
  setUserSettings: Dispatch<IUserSetting>
}

type AllMobileProps = ReturnType<typeof mapStateToProps> &
  ResultsNewsMobileProps

function ResultsNewsMobile(props: AllMobileProps) {
  const {
    resultsCount,
    featuredResults,
    resultsCombined,
    hasResultsBeenFetched,
    searchQuery,
    deviceSettings,
    userSettings,
    findConfigCognitiveSearchEnabled,
    cognitiveSearchEnabled,
    showCognitiveMessage,
    setShowCognitiveMessage,
    setUserSettings,
    synonymsApplied
  } = props
  const DOMPurify = createDOMPurify(window)
  const resultClasses = getStylesResults()
  const intl = useIntl()
  const [currentPage] = useFilter('page', '1')

  const returnAllItems = (type: string) => {
    if (!resultsCombined || resultsCombined.length < 1) {
      return []
    }

    const items = []

    try {
      const firstResults =
        resultsCombined.length < 4 ? resultsCombined.length : 3

      if (type === 'first') {
        for (let i = 0; i < firstResults; i++) {
          const currentDate = resultsCombined[i].Date
            ? intl.formatDate(
                DOMPurify.sanitize(resultsCombined[i].Date),
                dateFormatOptions
              )
            : ''
          const currentSource =
            DOMPurify.sanitize(resultsCombined[i].Source) || ''
          items.push(
            <TextResult
              title={DOMPurify.sanitize(resultsCombined[i].Title)}
              body={DOMPurify.sanitize(resultsCombined[i].Summary)}
              url={DOMPurify.sanitize(resultsCombined[i].Link)}
              displayUrl={`${currentDate}${
                currentDate && currentSource && currentSource.length > 0
                  ? ' › '
                  : ''
              }${currentSource}`}
              featured={false}
              showContextMenu={false}
              key={i}
              index={i}
              searchQuery={DOMPurify.sanitize(searchQuery)}
              deviceSettings={deviceSettings}
              synonymsApplied={synonymsApplied}
            />
          )
        }
      } else {
        for (let i = firstResults; i < resultsCombined.length; i++) {
          const currentDate = resultsCombined[i].Date
            ? intl.formatDate(
                DOMPurify.sanitize(resultsCombined[i].Date),
                dateFormatOptions
              )
            : ''
          const currentSource =
            DOMPurify.sanitize(resultsCombined[i].Source) || ''
          items.push(
            <TextResult
              title={DOMPurify.sanitize(resultsCombined[i].Title)}
              body={DOMPurify.sanitize(resultsCombined[i].Summary)}
              url={DOMPurify.sanitize(resultsCombined[i].Link)}
              displayUrl={`${currentDate}${
                currentDate && currentSource && currentSource.length > 0
                  ? ' › '
                  : ''
              }${currentSource}`}
              featured={false}
              showContextMenu={false}
              key={i}
              index={i}
              searchQuery={DOMPurify.sanitize(searchQuery)}
              deviceSettings={deviceSettings}
              synonymsApplied={synonymsApplied}
            />
          )
        }
      }
    } catch (error) {
      trackException(
        'Error returning all items in ResultsNewsMobile.tsx',
        error
      )
    }
    return items
  }

  const firstItems = useMemo(
    () => returnAllItems('first'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resultsCombined, intl]
  )

  const restItems = useMemo(
    () => returnAllItems('rest'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resultsCombined, intl]
  )

  return (
    <Box className={resultClasses.container}>
      <Box className={resultClasses.resultsContainer}>
        <SearchSuggestions
          wildCardActive={false}
          resultCount={resultsCount}
          resultsFetchend={hasResultsBeenFetched}
        />
        <ReactPlaceholder
          ready={
            hasResultsBeenFetched
              ? hasResultsBeenFetched
              : false || Number(currentPage) > 1
          }
          type="text"
          rows={4}
          showLoadingAnimation={true}
          className={resultClasses.placeholder}
        >
          <Fade
            in={
              Boolean(resultsCount != null && resultsCount > 0) ||
              Number(currentPage) > 1
                ? true
                : false
            }
            timeout={600}
          >
            <Box>
              {cognitiveSearchEnabled &&
                showCognitiveMessage &&
                synonymsApplied &&
                findConfigCognitiveSearchEnabled &&
                synonymsApplied.length > 0 && (
                  <CognitiveMessage
                    synonymsApplied={synonymsApplied}
                    setShowCognitiveMessage={setShowCognitiveMessage}
                    userSettings={userSettings}
                    setUserSettings={setUserSettings}
                  />
                )}
              {resultsCombined && resultsCount > 0 && (
                <FeaturedResults featuredResults={featuredResults} />
              )}
              {firstItems}
            </Box>
          </Fade>
        </ReactPlaceholder>
        {hasResultsBeenFetched &&
        (resultsCount < 1 || resultsCombined.length < 1) ? null : (
          <LeftWidgetContainer
            resultCount={resultsCount}
            hasResultsBeenFetched={hasResultsBeenFetched}
          />
        )}
        <ReactPlaceholder
          ready={hasResultsBeenFetched || Number(currentPage) > 1}
          type="text"
          rows={4}
          showLoadingAnimation={true}
          className={resultClasses.placeholder}
        >
          <Fade
            in={hasResultsBeenFetched || Number(currentPage) > 1}
            timeout={600}
          >
            <Box>
              {restItems}
              {resultsCount != null && resultsCount > 0 && (
                <Pagination
                  {...props}
                  totalRecords={resultsCount}
                  pageLimit={20}
                  pageNeighbours={4}
                />
              )}
            </Box>
          </Fade>
        </ReactPlaceholder>
        <ReactPlaceholder
          ready={hasResultsBeenFetched ? hasResultsBeenFetched : false}
          type="text"
          rows={4}
          showLoadingAnimation={true}
          className={resultClasses.placeholder}
        >
          <Box />
        </ReactPlaceholder>
      </Box>
    </Box>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    featuredResults: ResultsNewsStore.selectors.getFeaturedResults(state),
    resultsCombined: ResultsNewsStore.selectors.getResultsCombined(state),
    resultsCount: ResultsNewsStore.selectors.getResultCount(state),
    hasResultsBeenFetched:
      ResultsNewsStore.selectors.hasResultsBeenFetched(state),
    executionTime: ResultsNewsStore.selectors.getExecutionTime(state),
    synonymsApplied: ResultsNewsStore.selectors.getSynonymsApplied(state)
  }
}

export default connect(mapStateToProps)(ResultsNewsMobile)
