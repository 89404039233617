/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as Config from 'config'
import { isJestUnittestRunning } from 'utils/unitTests'
const logEnabled =
  Config.LOCAL_DEVELOPMENT.toString() === 'true' && !isJestUnittestRunning()

export const monitorReducerEnhancer =
  (createStore: any) => (reducer: any, initialState: any, enhancer: any) => {
    const monitoredReducer = (state: any, action: any) => {
      let start = 0
      if (logEnabled) start = performance.now()

      const newState = reducer(state, action)

      if (logEnabled) {
        const end = performance.now()
        const round = (number: number) => Math.round(number * 100) / 100
        const diff = round(end - start)
        console.log('reducer process time:', diff)
      }
      return newState
    }
    return createStore(monitoredReducer, initialState, enhancer)
  }
