function getDateTimeIsoString(dateTimeValue: Date, maxLength = -1) {
  if (maxLength !== -1) {
    return dateTimeValue.toISOString().slice(0, maxLength)
  } else {
    return dateTimeValue.toISOString()
  }
}

export function getDateValueFromOffset(
  dateDiff: number,
  maxLength = -1,
  useLocalTimeValue: boolean
): string {
  // Bug: 557648
  // In case the datasource didn't support time values
  // the current timezone offset must be used in date calculation for the request
  // otherwise results from older dates are shown
  let tzoffset = 0
  if (useLocalTimeValue) {
    tzoffset = new Date().getTimezoneOffset() * 60000 //offset in milliseconds
  }
  return getDateTimeIsoString(
    new Date(Date.now() - dateDiff - tzoffset),
    maxLength
  )
}

function getDateValueFromString(dateString: string, maxLength = -1): string {
  return getDateTimeIsoString(new Date(dateString), maxLength)
}

function getCustomRangeFilterString(
  queryStringFormatRange: string,
  queryStringFormatFrom: string,
  queryStringFormatTo: string,
  filterValue: string,
  maxLength = -1,
  addDayOffset = false
): string {
  const rangeString = filterValue.substring(
    filterValue.indexOf('(') + 1,
    filterValue.length - 1
  )

  const splitRangeFilter = rangeString.split(',')
  let queryStringFormat = ''

  if (splitRangeFilter.length === 2) {
    const filterValue = {
      dateFrom: splitRangeFilter[0],
      dateTo: splitRangeFilter[1]
    }

    if (filterValue.dateFrom && filterValue.dateTo) {
      queryStringFormat = queryStringFormatRange
    } else if (filterValue.dateFrom) {
      queryStringFormat = queryStringFormatFrom
    } else if (filterValue.dateTo) {
      queryStringFormat = queryStringFormatTo
    }

    if (!addDayOffset) {
      if (filterValue.dateFrom) {
        queryStringFormat = queryStringFormat.replace(
          /{dateFrom}/g,
          getDateValueFromString(filterValue.dateFrom, maxLength)
        )
      }
      if (filterValue.dateTo) {
        queryStringFormat = queryStringFormat.replace(
          /{dateTo}/g,
          getDateValueFromString(filterValue.dateTo, maxLength)
        )
      }
    } else {
      if (filterValue.dateFrom) {
        queryStringFormat = queryStringFormat.replace(
          /{dateFrom}/g,
          getDateValueFromString(filterValue.dateFrom, maxLength)
        )
      }
      if (filterValue.dateTo) {
        // One day is selected and the time is relevant
        // so the enddate must be increased 24h so some results are shown
        const dateTo = new Date(
          new Date(filterValue.dateTo).getTime() + 86400000
        )
        queryStringFormat = queryStringFormat.replace(
          /{dateTo}/g,
          getDateTimeIsoString(dateTo, maxLength)
        )
      }
    }
  }

  return queryStringFormat
}

function getFilterQueryValue(
  queryStringFormatRange: string,
  queryStringFormatOlderThanYear: string,
  type: string,
  maxLength = -1,
  addDayOffset = false
): string {
  switch (type) {
    case 'lastday':
      return queryStringFormatRange.replace(
        /{dateFrom}/g,
        getDateValueFromOffset(86400000, maxLength, addDayOffset)
      )
    case 'lastweek':
      return queryStringFormatRange.replace(
        /{dateFrom}/g,
        getDateValueFromOffset(604800000, maxLength, addDayOffset)
      )
    case 'lastmonth':
      return queryStringFormatRange.replace(
        /{dateFrom}/g,
        getDateValueFromOffset(2592000000, maxLength, addDayOffset)
      )
    case 'last3month':
      return queryStringFormatRange.replace(
        /{dateFrom}/g,
        getDateValueFromOffset(7776000000, maxLength, addDayOffset)
      )
    case 'lastyear':
      return queryStringFormatRange.replace(
        /{dateFrom}/g,
        getDateValueFromOffset(31536000000, maxLength, addDayOffset)
      )
    case 'olderyear':
      return queryStringFormatOlderThanYear.replace(
        /{dateTo}/g,
        getDateValueFromOffset(
          31536000000 + (addDayOffset ? 86400000 : 0),
          maxLength,
          addDayOffset
        )
      )
    default:
      return ''
  }
}

/**
 * Build modified refinement filter for sharepoint queries (intranet, engagements, videos)
 */
export function createModifiedFilterSharepoint(
  query: string,
  filterValue: string
): string {
  filterValue = decodeURIComponent(filterValue)

  if (
    filterValue.match(
      /customRange[(](?:[\d]{4}-[\d]{2}-[\d]{2})?,(?:[\d]{4}-[\d]{2}-[\d]{2}[)])?/g
    )
  ) {
    const queryValue = getCustomRangeFilterString(
      `Write:range({dateFrom}%2c{dateTo})`,
      'Write:range({dateFrom}%2cmax%2cfrom%3d"ge")',
      'Write:range(min%2c{dateTo}%2cto="lt")',
      filterValue,
      -1,
      true
    )
    query += `${query.length > 0 ? '%2c' : ''}${queryValue}`
  } else {
    const filterString = getFilterQueryValue(
      'Write:range({dateFrom}%2cmax%2cfrom%3d"ge")',
      'Write:range(min%2c{dateTo}%2cto="lt")',
      filterValue
    )

    if (filterString) {
      query += `${query.length > 0 ? '%2c' : ''}${filterString}`
    }
  }

  return query
}

/**
 * Build modified refinement filter for my data file queries
 */
export function createModifiedFilterForMyDataFiles(
  query: string,
  filterValue: string,
  fieldName = 'lastmodifiedtime'
): string {
  filterValue = decodeURIComponent(filterValue)

  if (
    filterValue.match(
      /customRange[(](?:[\d]{4}-[\d]{2}-[\d]{2})?,(?:[\d]{4}-[\d]{2}-[\d]{2}[)])?/g
    )
  ) {
    const filterString = getCustomRangeFilterString(
      `(${fieldName}>={dateFrom} AND ${fieldName}<={dateTo})`,
      `${fieldName}>={dateFrom}`,
      `${fieldName}<={dateTo}`,
      filterValue,
      10
    )
    query += filterString
  } else {
    const filterString = getFilterQueryValue(
      `${fieldName}>={dateFrom}`,
      `${fieldName}<={dateTo}`,
      filterValue,
      10,
      true
    )

    if (filterString) {
      query += filterString
    }
  }

  return query
}

/**
 * Build modified refinement filter for my data message queries
 */
export function createModifiedFilterForMyDataMails(
  query: string,
  filterValue: string,
  mailTypeFilter: string
): string {
  filterValue = decodeURIComponent(filterValue)

  if (
    filterValue.match(
      /customRange[(](?:[\d]{4}-[\d]{2}-[\d]{2})?,(?:[\d]{4}-[\d]{2}-[\d]{2}[)])?/g
    )
  ) {
    let filterString = ''
    switch (mailTypeFilter) {
      case 'send':
        filterString = getCustomRangeFilterString(
          '(sent>={dateFrom} AND sent<={dateTo})',
          'sent>={dateFrom}',
          'sent<={dateTo}',
          filterValue,
          10
        )
        break
      case 'allmails':
        filterString = getCustomRangeFilterString(
          '((received>={dateFrom} AND received<={dateTo}) OR (sent>={dateFrom} AND sent<={dateTo}))',
          '(received>={dateFrom} OR sent>={dateFrom})',
          '(received<={dateTo} OR sent<={dateTo})',
          filterValue,
          10
        )
        break
      default:
        filterString = getCustomRangeFilterString(
          '(received>={dateFrom} AND received<={dateTo})',
          'received>={dateFrom}',
          'received<={dateTo}',
          filterValue,
          10
        )
        break
    }

    query += filterString
  } else {
    let filterString = ''
    switch (mailTypeFilter) {
      case 'send':
        filterString = getFilterQueryValue(
          'sent>={dateFrom}',
          'sent<={dateTo}',
          filterValue,
          10,
          true
        )
        break
      case 'allmails':
        filterString = getFilterQueryValue(
          '(received>={dateFrom} OR sent>={dateFrom})',
          '(received<={dateTo} OR sent<={dateTo})',
          filterValue,
          10,
          true
        )
        break
      default:
        filterString = getFilterQueryValue(
          'received>={dateFrom}',
          'received<={dateTo}',
          filterValue,
          10,
          true
        )
        break
    }

    if (filterString) {
      query += filterString
    }
  }

  return query
}

/**
 * Build modified filters for param based queries (statista, news)
 */
export function createModifiedFilterAsParam(
  query: string,
  filterValue: string,
  paramFrom: string,
  paramTo: string,
  maxLength = -1,
  addDayOffset = false
): string {
  filterValue = decodeURIComponent(filterValue)
  // Set last modified filter
  if (
    filterValue.match(
      /customRange[(](?:[\d]{4}-[\d]{2}-[\d]{2})?,(?:[\d]{4}-[\d]{2}-[\d]{2}[)])?/g
    )
  ) {
    query += getCustomRangeFilterString(
      `&${paramFrom}={dateFrom}&${paramTo}={dateTo}`,
      `&${paramFrom}={dateFrom}`,
      `&${paramTo}={dateTo}`,
      filterValue,
      maxLength,
      addDayOffset
    )
  } else {
    const filterString = getFilterQueryValue(
      `&${paramFrom}={dateFrom}`,
      `&${paramTo}={dateTo}`,
      filterValue,
      maxLength,
      true
    )

    if (filterString) {
      query += filterString
    }
  }

  return query
}

export function createModifiedFilterForKnowledgeExchange(
  filterValue: string,
  maxLength = -1,
  addDayOffset = false
) {
  filterValue = decodeURIComponent(filterValue)
  let dateString = ''
  // Set last modified filter
  if (
    filterValue.match(
      /customRange[(](?:[\d]{4}-[\d]{2}-[\d]{2})?,(?:[\d]{4}-[\d]{2}-[\d]{2}[)])?/g
    )
  ) {
    dateString = getCustomRangeFilterString(
      `{dateFrom}|{dateTo}`,
      `{dateFrom}|`,
      `|{dateTo}`,
      filterValue,
      maxLength,
      addDayOffset
    )
  } else {
    dateString = getFilterQueryValue(
      `{dateFrom}|`,
      `|{dateTo}`,
      filterValue,
      maxLength,
      true
    )
  }

  if (dateString && dateString.indexOf('|') !== -1) {
    const splitted = dateString.split('|')

    return {
      dateFrom: splitted[0] ? splitted[0] : null,
      dateTo: splitted[1] ? splitted[1] : null
    }
  } else {
    return {
      dateFrom: null,
      dateTo: null
    }
  }
}

/**
 * Build modified filters for source
 */
export function createModifiedFilterSource(
  query: string,
  filterValue: string
): string {
  filterValue = decodeURIComponent(filterValue)

  // Set last modified filter
  if (
    filterValue.match(
      /customRange[(](?:[\d]{4}-[\d]{2}-[\d]{2})?,(?:[\d]{4}-[\d]{2}-[\d]{2}[)])?/g
    )
  ) {
    query += getCustomRangeFilterString(
      '+and+lastmodified%20%3E=%20{dateFrom}+and+lastmodified%20%3C=%20{dateTo}',
      '+and+lastmodified%20%3E=%20{dateFrom}',
      '+and+lastmodified%20%3C=%20{dateTo}',
      filterValue,
      10
    )
  } else {
    const filterString = getFilterQueryValue(
      '+and+lastmodified%20%3E=%20{dateFrom}',
      '+and+lastmodified%20%3C=%20{dateTo}',
      filterValue,
      10,
      true
    )

    if (filterString) {
      query += filterString
    }
  }

  return query
}
