import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesFilterMenuSearchInput(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles() {
  return makeStyles((theme: Theme) => ({
    menuSearchBar: {
      flex: 2,
      display: 'flex',
      height: '100%',
      alignItems: 'center',
      marginLeft: '10px'
    },
    inputField: {
      marginRight: 8,
      '& .MuiInputBase-input': {
        padding: 0
      },
      '& .MuiInput-input': {
        fontSize: '14px'
      }
    }
  }))
}
