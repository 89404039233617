import React, { useState, useEffect, Dispatch } from 'react'
import { connect } from 'react-redux'
import { Store } from 'store'
import SettingsStore from 'store/Settings'
import { Box, Typography, Grid } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import Link from '@mui/material/Link'
import { useIntl, FormattedMessage } from 'react-intl'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp'
import highlightText, { prepareHighlightWords } from 'utils/highlightText'
import {
  trackWidgetShown,
  trackRightWidgetClick,
  trackException,
  trackEvents
} from 'utils/tracking'
import { dateFormatOptions } from 'constants/constants'
import { WidgetKey } from 'constants/widgets'
import { getFileTypeIconProps } from '@uifabric/file-type-icons'
import { Icon } from '@fluentui/react/lib/Icon'
import { stripHtml } from 'utils/string'
import { getStylesRightOneDriveWidget } from 'styles/contents/resultpages/widgets/RightOneDriveWidget'
import { getStylesRightWidget } from 'styles/contents/resultpages/widgets/RightWidget'
import { getUrlParameterForCurrentFilters, useFilter } from 'utils/filters'
import createDOMPurify from 'dompurify'
import TooltipTitle from 'components/contents/common/TooltipTitle'
import FilterStore from 'store/Filters'
import {
  getFileExtensionFromFileName,
  getPreviewFileUrlFallBack,
  isVideoOrPhoto
} from 'utils/o365'
import * as Config from 'config'
import { getGraphAuthToken, renewAuthorizationToken } from 'utils/token'
import AuthStore from 'store/Auth'
import { ISynonymsApplied } from 'components/models/SynonymsApplied'
import LinesEllipsis from 'react-lines-ellipsis'
import HTMLEllipsis from 'react-lines-ellipsis/lib/html'
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'

export interface RightOneDriveWidgetProps {
  searchQuery: string
  results: any[]
  synonymsApplied: ISynonymsApplied[]
}

type AllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RightOneDriveWidgetProps

function RightOneDriveWidget(props: AllProps): JSX.Element {
  const {
    searchQuery,
    results,
    deviceSettings,
    currentDataSources,
    currentFilters,
    clearTemporaryFilter,
    aadInfo,
    synonymsApplied
  } = props

  const DOMPurify = createDOMPurify(window)

  const rightWidgetClasses = getStylesRightWidget()
  const classes = getStylesRightOneDriveWidget()
  const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis)
  const ResponsiveHTMLEllipsis = responsiveHOC()(HTMLEllipsis)
  const intl = useIntl()
  const [itemsShown, setItemsShown] = useState(3)
  const [dataSourceEnabled, setDataSourceEnabled] = useState(false)
  const [currentPage] = useFilter('page', '1')

  const showTen = () => {
    setItemsShown(10)
  }

  const showThree = () => {
    setItemsShown(3)
  }

  React.useEffect(() => {
    showThree()
  }, [currentPage])

  React.useEffect(() => {
    trackWidgetShown({
      widgetType: WidgetKey.oneDrive,
      widgetPane: 'right'
    })
  }, [])

  const formattedDates = (date: any) => {
    if (date && date !== '') {
      date = intl.formatDate(DOMPurify.sanitize(date), dateFormatOptions)
    }
    return date
  }

  const headline = intl.formatMessage({
    id: 'onedrive_widget_headline',
    defaultMessage: 'Related OneDrive'
  })

  useEffect(() => {
    const newsDatasource = currentDataSources.find(
      (ds) => ds.name.toLowerCase() === 'office365'
    )
    const newsDatasourceEnabled = newsDatasource
      ? newsDatasource.enabled
      : false

    setDataSourceEnabled(newsDatasourceEnabled)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDataSources])

  const seeAllLink = `/results/office365/${getUrlParameterForCurrentFilters(
    currentFilters,
    [{ key: 'page', value: '1' }]
  )}`

  async function fetchDocumentUrl(
    docId: string,
    driveId: string,
    fallBackUrl: string,
    openInNewTab: boolean
  ): Promise<void> {
    let graphToken = ''
    if (Config.LOCAL_DEVELOPMENT.toString() === 'false') {
      graphToken = await getGraphAuthToken(aadInfo.instance, aadInfo.accounts)
    }
    // Get and check authentication token
    const esToken = await renewAuthorizationToken(
      aadInfo.accessToken,
      aadInfo.instance,
      aadInfo.accounts
    )

    if (
      (graphToken && graphToken !== '') ||
      Config.LOCAL_DEVELOPMENT.toString() === 'true'
    ) {
      const apiUrl = `${Config.APIM_BASE_URL}msgraphapi/getdriveitem?driveId=${driveId}&documentId=${docId}`
      fetch(apiUrl, {
        method: 'GET',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${graphToken}`,
          JWTToken: esToken
        }
      })
        .then((res) => {
          if (res && res.status === 200) {
            res.json().then((jsonResponse) => {
              const url = DOMPurify.sanitize(jsonResponse.webUrl)

              openInNewTab
                ? window.open(url ? `${url}?web=1` : fallBackUrl)
                : (window.location.href = url ? `${url}?web=1` : fallBackUrl)
            })
          } else {
            openInNewTab
              ? window.open(fallBackUrl)
              : (window.location.href = fallBackUrl)
          }
        })
        .catch((err) => {
          trackException(
            'Exception fetching the graph file (via widget) docId' + docId,
            err
          )
          openInNewTab
            ? window.open(fallBackUrl)
            : (window.location.href = fallBackUrl)
        })
    }
  }

  return (
    <Box
      id={'con-widget-right-onedrive'}
      className={
        results.length > 3
          ? rightWidgetClasses.widgetAreaExtend
          : rightWidgetClasses.widgetArea
      }
    >
      <h2 className={rightWidgetClasses.widgetHeadlineH2}>
        <ResponsiveEllipsis
          className={rightWidgetClasses.widgetHeadline}
          basedOn="words"
          text={headline}
          maxLine="2"
        />
      </h2>

      {results.slice(0, itemsShown).map((item: any, index: number) => {
        const fileExtension = getFileExtensionFromFileName(item.resource.name)
        const validatedFileUrl = getPreviewFileUrlFallBack(
          item.resource.webUrl,
          fileExtension
        )
        const documentId = DOMPurify.sanitize(item.resource.id)
        const driveId = item.resource.parentReference
          ? DOMPurify.sanitize(item.resource.parentReference.driveId)
          : ''

        return (
          <Box key={'rod' + index} className={classes.widgetContent}>
            <Box key={'rodb1' + index} className={classes.imageContainer}>
              <Icon
                key={'rodi' + index}
                className={classes.fileResultIcon}
                {...getFileTypeIconProps({
                  extension: item.resource.name
                    ? item.resource.name.indexOf('.') !== -1
                      ? DOMPurify.sanitize(item.resource.name).split('.').pop()
                      : ''
                    : '',
                  size: 40
                })}
              />
            </Box>
            <Box key={'rodb2' + index} className={classes.widgetTextContainer}>
              <Link
                underline={'hover'}
                key={'rodl1' + index}
                className={classes.link}
                onClick={(event: any) => {
                  trackRightWidgetClick({
                    title: DOMPurify.sanitize(item.resource.name),
                    url: DOMPurify.sanitize(item.resource.webUrl),
                    index,
                    widgetKey: WidgetKey.oneDrive,
                    body: DOMPurify.sanitize(item.resource.name)
                  })

                  if (documentId && driveId && isVideoOrPhoto(fileExtension)) {
                    fetchDocumentUrl(
                      documentId,
                      driveId,
                      validatedFileUrl,
                      event.button !== 0
                    )
                    event.preventDefault()
                  }
                }}
                {...(!deviceSettings.isMobile
                  ? {
                      onAuxClick: (event: any) => {
                        trackRightWidgetClick({
                          title: DOMPurify.sanitize(item.resource.name),
                          url: DOMPurify.sanitize(item.resource.webUrl),
                          index,
                          widgetKey: WidgetKey.oneDrive,
                          body: DOMPurify.sanitize(item.resource.name),
                          wasRightClicked: event?.button === 2 ? true : false
                        })

                        if (
                          documentId &&
                          driveId &&
                          isVideoOrPhoto(fileExtension) &&
                          event.button !== 2
                        ) {
                          fetchDocumentUrl(
                            documentId,
                            driveId,
                            validatedFileUrl,
                            true
                          )
                          event.preventDefault()
                        }
                      }
                    }
                  : {})}
                href={
                  isVideoOrPhoto(fileExtension) && documentId && driveId
                    ? `${
                        window.location.hash
                      }&documentId=${documentId}&driveId=${driveId}&fallbackUrl=${encodeURIComponent(
                        validatedFileUrl
                      )}`
                    : validatedFileUrl
                }
                data-node-title={DOMPurify.sanitize(item.resource.name)}
                data-node-index={index}
                data-node-click-type="rightWidgetClick"
                data-node-widget-key={WidgetKey.oneDrive}
                {...(deviceSettings.openLinksInNewTab === true
                  ? { target: '_blank', rel: 'noreferrer' }
                  : {})}
              >
                <TooltipTitle
                  title={DOMPurify.sanitize(item.resource.name)}
                  key={'rodd1' + index}
                  singleLine={true}
                  additionalClass={`${rightWidgetClasses.widgetTitle} ${classes.widgetTitle}`}
                />
              </Link>
              <ResponsiveHTMLEllipsis
                className={`${rightWidgetClasses.widgetDescription} ${rightWidgetClasses.widgetDescriptionExtended} ${classes.widgetDescription}`}
                basedOn="words"
                unsafeHTML={highlightText(
                  stripHtml(DOMPurify.sanitize(item.summary)),
                  prepareHighlightWords(
                    DOMPurify.sanitize(searchQuery),
                    stripHtml(DOMPurify.sanitize(item.summary)),
                    synonymsApplied
                  ),
                  true
                )}
                maxLine="2"
              />
              <Typography
                key={'rodt1' + index}
                className={`${rightWidgetClasses.widgetFooterContent} ${classes.widgetFooterContent}`}
              >
                {formattedDates(item.resource.createdDateTime)}
              </Typography>
            </Box>
          </Box>
        )
      })}

      {results.length > 3 && (
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          className={rightWidgetClasses.footerGrid}
        >
          {dataSourceEnabled && results.length >= 10 && (
            <Grid
              container
              alignContent="flex-end"
              className={rightWidgetClasses.linkContainer}
            >
              <RouterLink
                to={seeAllLink}
                style={{
                  textDecoration: 'none',
                  color: '#005eb8',
                  fontSize: 14
                }}
                onClick={() => {
                  trackEvents('widget-see-all', {
                    widgetType: WidgetKey.oneDrive,
                    searchTerm: searchQuery,
                    wasRightClicked: false
                  })
                  clearTemporaryFilter()
                }}
                onAuxClick={(event: any) => {
                  trackEvents('widget-see-all', {
                    widgetType: WidgetKey.oneDrive,
                    searchTerm: searchQuery,
                    wasRightClicked: event && event.button === 2 ? true : false
                  })
                }}
              >
                <FormattedMessage id="see_all" defaultMessage="See All" />{' '}
              </RouterLink>
            </Grid>
          )}
          {itemsShown === 3 && (
            <Grid
              item
              id={'btn-right-widget-down-01'}
              className={rightWidgetClasses.arrowContainer}
              tabIndex={0}
              onClick={showTen}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  showTen()
                }
              }}
            >
              <KeyboardArrowDown className={rightWidgetClasses.arrowItem} />
            </Grid>
          )}
          {itemsShown === 10 && (
            <Grid
              item
              id={'btn-right-widget-up-01'}
              className={rightWidgetClasses.arrowContainerExpanded}
              tabIndex={0}
              onClick={showThree}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  showThree()
                }
              }}
            >
              <KeyboardArrowUp className={rightWidgetClasses.arrowItem} />
            </Grid>
          )}
        </Grid>
      )}
    </Box>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state),
    currentDataSources: SettingsStore.selectors.getCurrentDataSources(state),
    currentFilters: FilterStore.selectors.getCurrentFilters(state),
    aadInfo: AuthStore.selectors.getAADInfo(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    clearTemporaryFilter: () =>
      dispatch(FilterStore.actions.clearTemporaryFilter())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RightOneDriveWidget)
