import { AnyAction } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { Store } from '..'
import { FetchWithCache } from 'utils/api'
import Selectors from './selectors'
import { renewAuthorizationToken } from 'utils/token'
import AuthStore from '../Auth'
import * as Config from 'config'
import ResultMetaDataStore from 'store/ResultMetaData'
import { IDeviceSetting } from 'utils/deviceSettings'
import { buildFiltersKnowledgeExchange } from 'utils/filters'
import * as Filters from 'constants/filters'
import {
  ActionMetaData,
  CustomAction,
  getActionMetaData
} from 'store/extension/customAction'
import { IUserSetting } from 'utils/userSettings'
import { FeaturedResult } from 'components/models/FeaturedResult'
import { unifysearchQuery } from 'utils/unifysearchQuery'
import { IKnowledgeExchangeResult } from 'components/models/KnowledgeExchangeResult'
import { ISynonymsApplied } from 'components/models/SynonymsApplied'

export enum ResultsKnowledgeExchangeActionTypes {
  FETCH_REQUEST = 'resultsKnowledgeExchange/FETCH_REQUEST',
  FETCH_SUCCESS = 'resultsKnowledgeExchange/FETCH_SUCCESS',
  FETCH_SUCCESS_COMBINED = 'resultsKnowledgeExchange/FETCH_SUCCESS_COMBINED',
  FETCH_FAILURE = 'resultsKnowledgeExchange/FETCH_FAILURE',
  INITIALIZE_RESULTS_KNOWLEDGE_EXCHANGE = 'persist/REHYDRATE',
  FETCH_FILTERS_REQUEST = 'resultsKnowledgeExchange/FETCH_FILTERS_REQUEST',
  FETCH_FILTERS_SUCCESS = 'resultsKnowledgeExchange/FETCH_FILTERS_SUCCESS',
  FETCH_UNAVAILABLE = 'resultsKnowledgeExchange/FETCH_UNAVAILABLE',
  FETCH_AVAILABLE = 'FETCH_AVAILABLE'
}
export type TabTypes = 'open' | 'catalog'
export interface IFetchRequest
  extends CustomAction<ResultsKnowledgeExchangeActionTypes> {
  payload: {
    resetCombined: boolean
    searchQuery: string
  }
  meta: {
    type: TabTypes
  }
}
export interface IFetchFailure
  extends CustomAction<ResultsKnowledgeExchangeActionTypes> {
  meta: {
    type: TabTypes
  }
}
export interface IFetchSuccess
  extends CustomAction<ResultsKnowledgeExchangeActionTypes> {
  payload: {
    response: any
    featuredResults: FeaturedResult[]
    synonymsApplied: ISynonymsApplied[]
  }
  meta: {
    type: TabTypes
  }
}
export interface IFetchSuccessCombined
  extends CustomAction<ResultsKnowledgeExchangeActionTypes> {
  payload: {
    response: any
  }
  meta: {
    type: TabTypes
  }
}
export interface IFetchUnavailable
  extends CustomAction<ResultsKnowledgeExchangeActionTypes> {
  meta: {
    type: TabTypes
  }
}
export interface IFetchAvailable
  extends CustomAction<ResultsKnowledgeExchangeActionTypes> {
  meta: {
    type: TabTypes
  }
}

export const fetchRequest = (
  type: TabTypes,
  resetCombined: boolean,
  searchQuery: string,
  actionMetaData: ActionMetaData
): IFetchRequest => ({
  type: ResultsKnowledgeExchangeActionTypes.FETCH_REQUEST,
  payload: {
    resetCombined: resetCombined,
    searchQuery: searchQuery
  },
  meta: { type },
  metaData: {
    ...actionMetaData,
    isStartAction: true
  }
})

export const fetchSuccess = (
  response: any = {},
  featuredResults: FeaturedResult[],
  synonymsApplied: ISynonymsApplied[],
  type: TabTypes,
  actionMetaData: ActionMetaData
): IFetchSuccess => ({
  type: ResultsKnowledgeExchangeActionTypes.FETCH_SUCCESS,
  payload: { response, featuredResults, synonymsApplied },
  meta: { type },
  metaData: actionMetaData
})

export const fetchSuccessCombined = (
  response: any = {},
  type: TabTypes,
  actionMetaData: ActionMetaData
): IFetchSuccessCombined => ({
  type: ResultsKnowledgeExchangeActionTypes.FETCH_SUCCESS_COMBINED,
  payload: { response },
  meta: { type },
  metaData: actionMetaData
})

export const fetchFailure = (
  type: TabTypes,
  actionMetaData: ActionMetaData
): IFetchFailure => ({
  type: ResultsKnowledgeExchangeActionTypes.FETCH_FAILURE,
  meta: { type },
  metaData: actionMetaData
})
export const fetchUnavailable = (
  type: TabTypes,
  actionMetaData: ActionMetaData
): IFetchUnavailable => ({
  type: ResultsKnowledgeExchangeActionTypes.FETCH_UNAVAILABLE,
  meta: { type },
  metaData: actionMetaData
})
export const fetchAvailable = (
  type: TabTypes,
  actionMetaData: ActionMetaData
): IFetchAvailable => ({
  type: ResultsKnowledgeExchangeActionTypes.FETCH_AVAILABLE,
  meta: { type },
  metaData: actionMetaData
})

export interface IFetchFiltersRequest
  extends CustomAction<ResultsKnowledgeExchangeActionTypes> {
  meta: {
    type: TabTypes
  }
}
export interface IFetchFiltersSuccess
  extends CustomAction<ResultsKnowledgeExchangeActionTypes> {
  payload: {
    refinerResult: any
    searchQuery: string
  }
  meta: {
    type: TabTypes
  }
}

export const fetchFilterRequests = (
  type: TabTypes,
  actionMetaData: ActionMetaData
): IFetchFiltersRequest => ({
  type: ResultsKnowledgeExchangeActionTypes.FETCH_FILTERS_REQUEST,
  metaData: {
    ...actionMetaData,
    isStartAction: true
  },
  meta: { type }
})

export const fetchFiltersSuccess = (
  type: TabTypes,
  refinerResult: any,
  searchQuery: string,
  actionMetaData: ActionMetaData
): IFetchFiltersSuccess => ({
  type: ResultsKnowledgeExchangeActionTypes.FETCH_FILTERS_SUCCESS,
  payload: { refinerResult, searchQuery },
  metaData: actionMetaData,
  meta: { type }
})

export const fetchResultsKnowledgeExchange = (
  searchQuery: string,
  currentPage: number,
  deviceSettings: IDeviceSetting,
  filters: {
    [key: string]: string
  },
  type: TabTypes = 'open',
  userSettings: IUserSetting
  // eslint-disable-next-line @typescript-eslint/ban-types
): ThunkAction<Promise<void>, Store, {}, AnyAction> => {
  return async (
    // eslint-disable-next-line @typescript-eslint/ban-types
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => Store
  ) => {
    const actionMetaData = getActionMetaData('knowledgeexchange', type)
    const filterActionMetaData = getActionMetaData(
      'knowledgeexchange' + type,
      'filters'
    )
    dispatch(
      ResultMetaDataStore.actions.fetchRequest(
        'knowledgeexchange' + type,
        actionMetaData
      )
    )

    if (currentPage || 1) {
      dispatch(fetchFilterRequests(type, filterActionMetaData))
    }

    const resultsCombinedQuery = Selectors.getResultsCombinedQuery(
      getState(),
      type
    )
    if (resultsCombinedQuery !== searchQuery) {
      if (resultsCombinedQuery === '') {
        dispatch(fetchRequest(type, false, searchQuery, actionMetaData))
      } else {
        dispatch(fetchRequest(type, true, searchQuery, actionMetaData))
      }
    } else {
      dispatch(fetchRequest(type, false, searchQuery, actionMetaData))
    }

    try {
      if (!searchQuery || searchQuery === '' || searchQuery === '""') {
        dispatch(fetchFailure(type, actionMetaData))
        return
      }

      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        throw new Error('Authentication: Cannot renew authentication token')
      }

      const requestBody: any = {
        searchQuery: unifysearchQuery(searchQuery, 'knowledgeexchange'),
        pageSize: 20,
        pageIndex: currentPage || 1,
        processFeaturedResults: (currentPage || 1) === 1 ? true : false,
        featuredResultsRowLimit: 3,
        origin: 'KPMGFind',
        country: userSettings.Country,
        function: userSettings.Function
      }

      const t0 = performance.now()

      let knowledgeExchangeUrl = `${Config.APIM_BASE_URL}knowledgeexchangeapi/`

      let availableFilters: FilterCategory[] = []
      if (type === 'open') {
        knowledgeExchangeUrl += 'postsearch'
        availableFilters = Filters.knowledgeexchange
      } else if (type === 'catalog') {
        knowledgeExchangeUrl += 'postcatalogsearch'
        availableFilters = Filters.knowledgeexchangeCatalog
      }

      buildFiltersKnowledgeExchange(filters, availableFilters, requestBody)

      const response = await FetchWithCache(knowledgeExchangeUrl, {
        method: 'POST',
        redirect: 'follow',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          JWTToken: esToken
        },
        body: JSON.stringify(requestBody)
      })

      let resultData: any
      if (!response || response.hasError || !response.responseJSON) {
        dispatch(
          ResultMetaDataStore.actions.hasError(
            'knowledgeexchange' + type,
            response.errorResponse,
            undefined,
            actionMetaData
          )
        )
        dispatch(fetchFailure(type, actionMetaData))
        return
      } else {
        resultData = response.responseJSON
      }

      const featuredResults =
        resultData && resultData.FeaturedResults
          ? resultData.FeaturedResults
          : []

      const resultCount = resultData ? resultData.TotalResults : 0
      const knowledgeExchangeResults = resultData
        ? resultData.Results
        : new Array<IKnowledgeExchangeResult>()

      const t1 = performance.now()
      const executionTime = t1 - t0

      const generateFiltersReplaceKeys = [
        {
          local: 'kxcountry',
          remote: 'Country'
        },
        {
          local: 'FileType',
          remote: 'DocumentType'
        }
      ]

      const generateFilters = availableFilters
        .filter((item) => item.value !== 'lastmodified')
        .map((item) => {
          let useKey = item.value
          const replace = generateFiltersReplaceKeys.find(
            (itemreplace) => itemreplace.local === useKey
          )
          if (replace) {
            useKey = replace.remote
          }
          return useKey
        })

      const refinerResult: any = {
        currentQuery: searchQuery,
        ...(generateFilters.includes('DocumentType') ? { filetype: [] } : {}),
        sector: [],
        contenttype: [],
        function: [],
        serviceline: [],
        subserviceline: [],
        solution: [],
        corridor: [],
        country: []
      }

      if (resultData && resultData.refinerResult) {
        generateFilters.forEach(
          (filterKey: string) =>
            (refinerResult[
              filterKey === 'DocumentType'
                ? 'filetype'
                : filterKey.toLowerCase()
            ] = generateRefinerForKey(resultData.refinerResult, filterKey))
        )
      }

      // Bug 2152523: Make sure missing refiners are added
      // so filtering is shown to the user, even if current values are not returned
      if (filters) {
        Object.keys(filters).forEach((filterKey: string) => {
          if (filterKey !== 'lastmodified' && filters[filterKey]) {
            const filterValues = filters[filterKey].split('|')
            const refiner =
              refinerResult[filterKey === 'kxcountry' ? 'country' : filterKey]

            if (refiner) {
              filterValues.forEach((key: string) => {
                if (
                  !refiner.some((refinerValue: any) => {
                    return (refinerValue.key = key)
                  })
                ) {
                  refiner.push({
                    name: key,
                    value: key
                  })
                }
              })
            }
          }
        })
      }

      if (currentPage || 1) {
        dispatch(
          fetchFiltersSuccess(
            type,
            refinerResult,
            searchQuery,
            filterActionMetaData
          )
        )
      }
      const synonymsApplied =
        resultData && resultData.SynonymsApplied
          ? resultData.SynonymsApplied
          : []
      dispatch(
        fetchSuccess(
          {
            knowledgeExchangeResults,
            executionTime,
            currentPage: currentPage || 1,
            resultCount: resultCount
          },
          featuredResults,
          synonymsApplied,
          type,
          actionMetaData
        )
      )

      dispatch(
        ResultMetaDataStore.actions.fetchSuccess(
          'knowledgeexchange' + type,
          {
            executionTime: executionTime,
            resultsCount: resultCount
          },
          actionMetaData
        )
      )

      dispatch(
        fetchSuccessCombined(
          {
            knowledgeExchangeResults: knowledgeExchangeResults,
            resetCombined: (currentPage || 1) === 1
          },
          type,
          actionMetaData
        )
      )
    } catch (error) {
      dispatch(
        ResultMetaDataStore.actions.hasError(
          'knowledgeexchange' + type,
          undefined,
          error,
          actionMetaData
        )
      )
      dispatch(fetchFailure(type, actionMetaData))
    }
  }
}

const generateRefinerForKey = (refinerResult: any, key: string) => {
  const refiner = refinerResult.find((refiner: any) => refiner.Name === key)

  if (!refiner) {
    return []
  }

  return refiner.Options.map((opt: any) => {
    return {
      name: opt.Name,
      key: opt.Name
    }
  })
}

export type ResultsKnowledgeExchangeActions = IFetchRequest | IFetchFailure
