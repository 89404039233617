import React from 'react'
import {
  IWidgetConfig,
  IWidgetPreselectConfig,
  widgetConfigList,
  widgetPreselectList
} from './commonWidgets'
import { trackException } from 'utils/tracking'
import { generateOfficeLocationWidget } from 'utils/widgets/officeLocationWidget'
import { getAd } from 'utils/ads'
import { IDeviceSetting } from 'utils/deviceSettings'
import Ads from 'components/contents/resultpages/Ads'
import { IUserSetting } from 'utils/userSettings'
import { getCurrentScope } from 'utils/queryParams'
import { generateCompanySnapshotWidget } from './companySnapshotWidget'
import { generateAlexTopRightWidget } from './alexTopRightWidget'
import { AdResult } from 'components/models/AdResult'
import {
  datasourcesConfiguration,
  IDataSource
} from 'constants/datasourcesConfiguration'
import { dataSourcesTabs } from 'constants/constants'
import { IFindConfiguration } from 'store/Settings/reducers'
import { isArray } from '@microsoft/applicationinsights-core-js'
import TopRightChatWidget from './topRightChatWidget'
import { IEntity } from 'store/Search/reducers'
import {
  CustomEntity,
  KnownEntities,
  buildEntities,
  getWidgetsForEntities
} from 'utils/entityRecognition'
import { IAADState } from 'store/Auth/reducers'

export interface IWidgetSelectConfigLoading {
  fullyLoaded: boolean //triggers loading placeholder & rendering
}
export interface IWidgetSelectConfig
  extends IWidgetConfig,
    IWidgetSelectConfigLoading {
  forced: boolean
  used: boolean
  fetching: boolean //prevent multiple fetch calls
}

/**
 * Select and lock widget by criteria
 * @param searchQuery The search query
 * @param widgetType widgettype (left/right)
 * @param widgetSelectConfigList The widgetSelectConfigList
 * @return widget config object
 */
export function selectWidget(
  searchQuery: string,
  widgetType: string,
  widgetSelectConfigList: IWidgetSelectConfig[]
): IWidgetSelectConfig | undefined {
  let item: IWidgetSelectConfig | undefined = undefined
  // Try forced widgets
  item = widgetSelectConfigList.find(
    (widgetConfigItem: IWidgetSelectConfig) =>
      widgetType.toLocaleLowerCase() === widgetConfigItem.type &&
      widgetConfigItem.forced &&
      !isWidgetKeyInUse(widgetConfigItem.key, widgetSelectConfigList)
  )

  if (item === undefined) {
    //choose widget by weight and searchterm
    const unusedWidgetList = widgetSelectConfigList.filter(
      (widgetConfigItem: IWidgetSelectConfig) =>
        widgetType.toLocaleLowerCase() === widgetConfigItem.type &&
        !isWidgetKeyInUse(widgetConfigItem.key, widgetSelectConfigList)
    )

    //https://blobfolio.com/2019/randomizing-weighted-choices-in-javascript/
    let totalWeight = 0
    for (let i = 0; i < unusedWidgetList.length; i++) {
      totalWeight += unusedWidgetList[i].weight
    }

    // Random select by searchTermHash
    let randomIndex = Math.abs(hashString(searchQuery) % totalWeight)

    // As module returns only values from 0 - (totalWeight - 1)
    // set the calculated index randomIndex to the totalWeight, so the last
    // widget in the list can be selected
    if (randomIndex === 0) {
      randomIndex = totalWeight
    }

    let needle = 0
    for (let i = 0; i < unusedWidgetList.length; i++) {
      // Add the weight to our running total.
      needle += unusedWidgetList[i].weight
      // If this value falls within the threshold, we're done!
      if (needle >= randomIndex) {
        item = unusedWidgetList[i]
        break
      }
    }
  }

  if (item === undefined) {
    //fallback, try to find next unused widget
    item = widgetSelectConfigList.find(
      (widgetConfigItem: IWidgetSelectConfig) =>
        widgetType.toLocaleLowerCase() === widgetConfigItem.type &&
        !isWidgetKeyInUse(widgetConfigItem.key, widgetSelectConfigList)
    )
  }

  //lock widget
  if (item) {
    item.used = true
  }
  return item
}

/**
 * Preselect widget based on defined search criteria
 * @param searchQuery The search query
 * @param userCountry The user country
 * @param deviceSettings The device settings
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function preSelectWidgetViaRules(
  searchTerm: string,
  deviceSettings: IDeviceSetting,
  currentFilters: CurrentFilter[],
  userSettings: IUserSetting,
  dataSourceSettings: IDataSource[],
  findConfiguration: IFindConfiguration,
  entityData: IEntity[] | null
): IWidgetSelectConfig[] {
  let filteredWidgetPreselectList: IWidgetSelectConfig[] = []

  if (userSettings.ForceLeftWidget || userSettings.ForceRightWidget) {
    if (userSettings.ForceLeftWidget) {
      const leftWidgetConfig = widgetConfigList.find(
        (widget: IWidgetConfig) =>
          widget.key === userSettings.ForceLeftWidget && widget.type === 'left'
      )

      filteredWidgetPreselectList.push({
        ...leftWidgetConfig,
        forced: true,
        used: false
      } as IWidgetSelectConfig)
    }
    if (userSettings.ForceRightWidget) {
      const rightWidgetConfig = widgetConfigList.find(
        (widget: IWidgetConfig) =>
          widget.key === userSettings.ForceRightWidget &&
          widget.type === 'right'
      )

      filteredWidgetPreselectList.push({
        ...rightWidgetConfig,
        forced: true,
        used: false
      } as IWidgetSelectConfig)
    }
  } else {
    // Preselect Widget by entity recognition
    if (entityData) {
      const mappedEntities = getWidgetsForEntities(entityData)

      if (mappedEntities.left) {
        const leftWidgetConfig = findWidgetConfigItemByKey(
          mappedEntities.left,
          'left'
        )

        if (
          leftWidgetConfig &&
          // Country matched
          ruleWidgetCountrysMatched(leftWidgetConfig, userSettings.Country) &&
          // Device matched
          ruleWidgetDeviceMatched(leftWidgetConfig, deviceSettings) &&
          // Datasource matched
          ruleWidgetDatasourceMatched(leftWidgetConfig)
        ) {
          filteredWidgetPreselectList.push({
            ...leftWidgetConfig,
            forced: true,
            used: false
          } as IWidgetSelectConfig)
        }
      }

      if (mappedEntities.right) {
        const rightWidgetConfig = findWidgetConfigItemByKey(
          mappedEntities.right,
          'right'
        )

        if (
          rightWidgetConfig &&
          // Country matched
          ruleWidgetCountrysMatched(rightWidgetConfig, userSettings.Country) &&
          // Device matched
          ruleWidgetDeviceMatched(rightWidgetConfig, deviceSettings) &&
          // Datasource matched
          ruleWidgetDatasourceMatched(rightWidgetConfig)
        ) {
          filteredWidgetPreselectList.push({
            ...rightWidgetConfig,
            forced: true,
            used: false
          } as IWidgetSelectConfig)
        }
      }
    }

    // Preselect by searchterm & country
    widgetPreselectList.forEach((item: IWidgetPreselectConfig) => {
      const leftWidgetConfig = findWidgetConfigItemByKey(
        item.leftWidgetKey,
        'left'
      )
      const rightWidgetConfig = findWidgetConfigItemByKey(
        item.rightWidgetKey,
        'right'
      )

      if (
        // Searchterm matched
        ruleWidgetSearchtermMatched(item, searchTerm)
      ) {
        if (
          item.leftWidgetKey &&
          leftWidgetConfig &&
          // Country matched
          ruleWidgetCountrysMatched(leftWidgetConfig, userSettings.Country) &&
          // Device matched
          ruleWidgetDeviceMatched(leftWidgetConfig, deviceSettings) &&
          // Datasource matched
          ruleWidgetDatasourceMatched(leftWidgetConfig) &&
          !filteredWidgetPreselectList.find(
            (preselectItem: IWidgetSelectConfig) =>
              preselectItem.key === leftWidgetConfig.key &&
              preselectItem.type === leftWidgetConfig.type
          )
        ) {
          filteredWidgetPreselectList.push({
            ...leftWidgetConfig,
            forced: true,
            used: false
          } as IWidgetSelectConfig)
        }

        if (
          item.rightWidgetKey &&
          rightWidgetConfig &&
          // Country matched
          ruleWidgetCountrysMatched(rightWidgetConfig, userSettings.Country) &&
          // Device matched
          ruleWidgetDeviceMatched(rightWidgetConfig, deviceSettings) &&
          // Datasource matched
          ruleWidgetDatasourceMatched(rightWidgetConfig) &&
          // Filter matched
          ruleWidgetFilterMatched(rightWidgetConfig, currentFilters) &&
          !filteredWidgetPreselectList.find(
            (preselectItem: IWidgetSelectConfig) =>
              preselectItem.key === rightWidgetConfig.key &&
              preselectItem.type === rightWidgetConfig.type
          )
        ) {
          filteredWidgetPreselectList.push({
            ...rightWidgetConfig,
            forced: true,
            used: false
          } as IWidgetSelectConfig)
        }
      }
    })

    // Append non forced widgets
    widgetConfigList.forEach((item: IWidgetConfig) => {
      if (
        // If not added
        !filteredWidgetPreselectList.find(
          (preselectItem: IWidgetSelectConfig) =>
            preselectItem.key === item.key && preselectItem.type === item.type
        ) &&
        // Country matched
        ruleWidgetCountrysMatched(item, userSettings.Country) &&
        // Device matched
        ruleWidgetDeviceMatched(item, deviceSettings) &&
        // Datasource matched
        ruleWidgetDatasourceMatched(item)
      ) {
        filteredWidgetPreselectList.push({
          ...item,
          forced: false,
          used: false
        } as IWidgetSelectConfig)
      }
    })
  }

  // Filter out all widgets where the datasources are disabled
  // in cosmos db
  if (dataSourceSettings && dataSourceSettings.length > 0) {
    const dataSourcesToCheck = datasourcesConfiguration
      .map((ds) => {
        const foundDs = dataSourceSettings.find(
          (currentDs) => currentDs.name === ds.name
        )

        return {
          name: ds.name,
          mappedWidget: ds.mappedWidget,
          active: foundDs ? foundDs.active : true
        }
      })
      .filter((ds) => !ds.active && ds.mappedWidget)

    filteredWidgetPreselectList = filteredWidgetPreselectList.filter(
      (widgetConfig: IWidgetSelectConfig) => {
        let visible = true

        const foundDs = dataSourcesToCheck.find((ds: any) =>
          Array.isArray(ds.mappedWidget)
            ? ds.mappedWidget.includes(widgetConfig.key)
            : ds.mappedWidget === widgetConfig.key
        )

        if (foundDs && !foundDs.active) visible = false

        return visible
      }
    )
  }

  //filter out user disabled widgets
  if (userSettings.DisabledWidgets && userSettings.DisabledWidgets.length > 0) {
    filteredWidgetPreselectList = filteredWidgetPreselectList.filter(
      (widgetConfig: IWidgetSelectConfig) =>
        !userSettings.DisabledWidgets.includes(widgetConfig.key)
    )
  }

  // Filter out all disabled widgets from find configuration
  if (
    findConfiguration &&
    findConfiguration.DisabledWidgets &&
    isArray(findConfiguration.DisabledWidgets)
  ) {
    filteredWidgetPreselectList = filteredWidgetPreselectList.filter(
      (widgetConfig: IWidgetSelectConfig) =>
        !findConfiguration.DisabledWidgets ||
        !findConfiguration.DisabledWidgets.includes(widgetConfig.key)
    )
  }

  return filteredWidgetPreselectList
}

/**
 * Check widget key is in use (prevent showing the same content twice)
 * @param key Keyname of the widget
 * @param widgetSelectConfigList preselected widget list
 * @returns boolean is already in use
 */
const isWidgetKeyInUse = (
  key: string,
  widgetSelectConfigList: IWidgetSelectConfig[]
): boolean => {
  return !!widgetSelectConfigList.find(
    (widgetConfig: IWidgetSelectConfig) =>
      widgetConfig.key === key && widgetConfig.used
  )
}

/**
 * Check widget datasource criteria
 * @param widgetConfig widget config object
 * @returns criteria passed
 */
const ruleWidgetDatasourceMatched = (widgetConfig: IWidgetConfig): boolean => {
  // Check disallowed datasources
  let showWidget =
    !widgetConfig ||
    !widgetConfig.notInDatasources ||
    !widgetConfig.notInDatasources.includes(getCurrentScope(false))

  if (showWidget) {
    // Check allowed datasources
    showWidget =
      !widgetConfig ||
      !widgetConfig.datasources ||
      widgetConfig.datasources.includes(getCurrentScope(false))
  }
  return showWidget
}

/**
 * Check widget device criteria
 * @param widgetConfig widget config object
 * @param deviceSettings device settings
 * @returns criteria passed
 */
const ruleWidgetDeviceMatched = (
  widgetConfig: IWidgetConfig | undefined,
  deviceSettings: IDeviceSetting
): boolean => {
  return (
    !widgetConfig ||
    (widgetConfig.onlyMobile === undefined &&
      widgetConfig.notMobile === undefined) ||
    (widgetConfig.onlyMobile !== undefined &&
      widgetConfig.onlyMobile === true &&
      deviceSettings.isMobile === true) ||
    (widgetConfig.notMobile !== undefined &&
      widgetConfig.notMobile === true &&
      deviceSettings.isMobile === false)
  )
}

/**
 * Check widget country criteria
 * @param widgetConfig widget config object
 * @param userCountry user country
 * @returns criteria passed
 */
const ruleWidgetCountrysMatched = (
  widgetConfig: IWidgetConfig | undefined,
  userCountry: string
): boolean => {
  return (
    !widgetConfig ||
    !widgetConfig.country ||
    (widgetConfig.country &&
      userCountry !== '' &&
      widgetConfig.country.some(
        (_country: string) => _country.indexOf(userCountry) !== -1
      ))
  )
}

/**
 * Check widget searchterm criteria
 * @param widgetPreselectConfig widget preselect config
 * @param searchTerm search term
 * @returns criteria passed
 */
const ruleWidgetSearchtermMatched = (
  widgetPreselectConfig: IWidgetPreselectConfig,
  searchTerm: string
): boolean => {
  return widgetPreselectConfig.queryContains.some((_query: string) =>
    searchTerm.split(' ').some((searchWord: string) => {
      if (searchWord.toLowerCase() === _query.toLowerCase() || _query === '*') {
        return true
      }
      return false
    })
  )
}

const ruleWidgetFilterMatched = (
  widgetConfig: IWidgetConfig,
  currentFilters: CurrentFilter[]
): boolean => {
  let match = true
  if (widgetConfig.filterIsNot)
    widgetConfig.filterIsNot.forEach((filter: CurrentFilter) => {
      if (
        currentFilters.some(
          (currentFilter: CurrentFilter) =>
            currentFilter.key === filter.key &&
            currentFilter.value === filter.value
        )
      ) {
        match = false
      }
    })

  return match
}

/**
 * Get widgetConfig object by key & type
 * @param widgetKey widget key
 * @param widgetType widget type
 * @returns widgetConfig object
 */
const findWidgetConfigItemByKey = (
  widgetKey: string | null,
  widgetType: string
): IWidgetConfig | undefined => {
  if (!widgetKey) return

  return widgetConfigList.find(
    (widgetConfig: IWidgetConfig) =>
      widgetConfig.key === widgetKey && widgetConfig.type === widgetType
  )
}

/**
 * Parses the input string into a hash
 * @return a hash created from the input string
 */
function hashString(input: string) {
  let hash = 0
  for (let i = 0; i < input.length; i++) {
    hash = (Math.imul(31, hash) + input.charCodeAt(i)) | 0
  }
  return hash
}

/**
 * Formats the kpmg websites items top line to be displayed in UI
 * @param dates The dates
 * @param tabTypes The tab types
 * @return a formatted top line for all kpmg websites items
 */
export function formatKPMGWebsitesTopLine(
  dates: string[],
  tabTypes: string[]
): string[] {
  let kpmgWebsitesTopLines: string[] = []

  try {
    kpmgWebsitesTopLines = dates.map((date: string, index: number) => {
      let kpmgWebsitesTopLine = date
      const tabType =
        tabTypes[index] && tabTypes[index].length > 0 ? tabTypes[index] : ''
      if (date && date.length > 0 && tabType && tabType.length > 0) {
        kpmgWebsitesTopLine += ' > '
      }
      kpmgWebsitesTopLine += tabType
      return kpmgWebsitesTopLine
    })
  } catch (error) {
    trackException('Error formating KPMG Websites widget top line', error)
  }

  return kpmgWebsitesTopLines
}

export interface ITopWidgetResults {
  ads: React.ReactElement | null
  office: React.ReactElement | null
  company: React.ReactElement | null
  alex: React.ReactElement | null
  chat: React.ReactElement | null
}

export function fetchTopWidgets(
  adsTranslations: AdResult[],
  searchQuery: string,
  datasource: string,
  userSettings: IUserSetting,
  deviceSettings: IDeviceSetting,
  aadInfo: IAADState,
  useEntityRecognition: boolean,
  entityData: IEntity[],
  onClickAds?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
): Promise<ITopWidgetResults> {
  return new Promise(async (resolve) => {
    const adsWidget = await getAd(adsTranslations, searchQuery)
    // Only display office location widget in the intial active user datasource
    const dataSourcesOrder: any[] | null = userSettings.DataSourceOrder
      ? JSON.parse(userSettings.DataSourceOrder)
      : null
    const dataSourceDisabled: any[] | null = userSettings.DataSourceDisabled
      ? JSON.parse(userSettings.DataSourceDisabled)
      : null
    const filteredOrder =
      dataSourcesOrder && dataSourcesOrder.length > 0
        ? dataSourcesOrder.filter((dataSourceName: string) =>
            dataSourceDisabled
              ? !dataSourceDisabled.includes(dataSourceName)
              : true
          )
        : []
    let initialDS =
      filteredOrder && filteredOrder.length > 0
        ? filteredOrder[0].toLocaleLowerCase()
        : ''

    if (initialDS === 'kpmg mpp') {
      initialDS = 'mpp'
    }

    if (
      datasource === 'alex' &&
      (!dataSourceDisabled || !dataSourceDisabled.includes(datasource))
    ) {
      const alexToRightWidget = await generateAlexTopRightWidget(
        searchQuery,
        aadInfo
      )
      resolve({
        ads: adsWidget ? (
          <Ads
            adItem={adsWidget}
            deviceSettings={deviceSettings}
            onClick={onClickAds}
          />
        ) : null,
        chat:
          datasource !== dataSourcesTabs.chat ? (
            <TopRightChatWidget currentDataSource={datasource} />
          ) : null,
        office: null,
        company: null,
        alex: alexToRightWidget
      })
    } else if (datasource !== initialDS) {
      resolve({
        ads: adsWidget ? (
          <Ads
            adItem={adsWidget}
            deviceSettings={deviceSettings}
            onClick={onClickAds}
          />
        ) : null,
        chat:
          datasource !== dataSourcesTabs.chat ? (
            <TopRightChatWidget currentDataSource={datasource} />
          ) : null,
        office: null,
        company: null,
        alex: null
      })
    } else {
      let officeWidget = null
      let useOfficeLocationWidget = true
      let useCompanySnapShotWidget = true

      if (useEntityRecognition) {
        const entities = buildEntities(entityData)

        useOfficeLocationWidget =
          entities &&
          entities.some((e: CustomEntity) => e.key === KnownEntities.LOCATION)

        useCompanySnapShotWidget =
          entities &&
          entities.some(
            (e: CustomEntity) => e.key === KnownEntities.ORGANIZATION
          )
      }

      if (useOfficeLocationWidget) {
        officeWidget = await generateOfficeLocationWidget(searchQuery, aadInfo)
      }

      let companySnapshotWidget = null
      if (!officeWidget && useCompanySnapShotWidget) {
        companySnapshotWidget = await generateCompanySnapshotWidget(
          searchQuery,
          aadInfo
        )
      }

      resolve({
        ads: adsWidget ? (
          <Ads
            adItem={adsWidget}
            deviceSettings={deviceSettings}
            onClick={onClickAds}
          />
        ) : null,
        office: officeWidget ? officeWidget : null,
        company: companySnapshotWidget ? companySnapshotWidget : null,
        alex: null,
        chat:
          datasource !== dataSourcesTabs.chat ? (
            <TopRightChatWidget currentDataSource={datasource} />
          ) : null
      })
    }
  })
}
