import { FeaturedResult } from 'components/models/FeaturedResult'
import { Store } from '..'
import { ISynonymsApplied } from 'components/models/SynonymsApplied'
import { IMarketResearchResult } from 'components/models/MarketResearchResult'

const selectors = {
  getFeaturedResults: (state: Store): FeaturedResult[] =>
    state.resultsMarketResearch.featuredResults,
  getSynonymsApplied: (state: Store): ISynonymsApplied[] =>
    state.resultsMarketResearch.synonymsApplied,
  getResults: (state: Store): IMarketResearchResult[] =>
    state.resultsMarketResearch.results,
  getResultsCombined: (state: Store): IMarketResearchResult[] =>
    state.resultsMarketResearch.resultsCombined,
  getResultsCombinedQuery: (state: Store): string =>
    state.resultsMarketResearch.resultsCombinedQuery,
  hasResultsBeenFetched: (state: Store): boolean =>
    state.resultsMarketResearch.hasResultsBeenFetched,
  getResultCount: (state: Store): number =>
    state.resultsMarketResearch.resultCount,
  getExecutionTime: (state: Store): number =>
    state.resultsMarketResearch.executionTime,
  getCurrentPage: (state: Store): number =>
    state.resultsMarketResearch.currentPage
}

export default selectors
