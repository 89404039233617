import { useState, useEffect } from 'react'

/**
 * Applies a debounce to the received value of delay ms
 * @param value The value where to apply the debounce
 * @param delay The delay to apply
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useDebounce(value: any, delay: any): any {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [delay, value])

  return debouncedValue
}

let handlerCall: NodeJS.Timeout | null = null
export function debounceCall(func: any, funcValue: any, delay: any): any {
  if (handlerCall) clearTimeout(handlerCall)

  handlerCall = setTimeout(() => {
    func(funcValue)
  }, delay)
}
