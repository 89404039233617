import React from 'react'
import * as Config from 'config'
import { ResultRightWidgetGenerator } from './interfaces'
import RightMetricsWidget from 'components/contents/resultpages/widgets/RightMetricsWidget'
import { trackException } from 'utils/tracking'
import { FetchWithCache } from 'utils/api'
import { IDeviceSetting } from 'utils/deviceSettings'
import { IUserSetting } from 'utils/userSettings'
import { IFindConfiguration } from 'store/Settings/reducers'
import { MetricsResult } from 'components/models/MetricsResult'
import { IAADState } from 'store/Auth/reducers'
import { renewAuthorizationToken } from 'utils/token'

export const rightMetricsWidgetGenerator: ResultRightWidgetGenerator = {
  fetch(
    aadInfo: IAADState,
    searchQuery: string,
    deviceSettings: IDeviceSetting,
    userSettings: IUserSetting,
    findConfiguration: IFindConfiguration,
    useCognitiveSearch: boolean
  ): any {
    return new Promise<any>((resolve, reject) => {
      const fetchMetrics = async () => {
        if (
          !userSettings ||
          !userSettings.Country ||
          userSettings.Country === ''
        ) {
          trackException(
            'Error in fetching Metrics right widget in rightMetricsWidget.tsx',
            new Error('Empty user country. User does not have a country set')
          )
          reject()
          return
        }

        const apiUrl = `${Config.APIM_BASE_URL}appinsightsapi/getmetrics?country=${userSettings.Country}`

        // Get and check authentication token
        const esToken = await renewAuthorizationToken(
          aadInfo.accessToken,
          aadInfo.instance,
          aadInfo.accounts
        )

        const response = await FetchWithCache(apiUrl, {
          method: 'GET',
          headers: {
            'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
            'content-type': 'application/json',
            Authorization: `Bearer ${esToken}`
          }
        })

        let fetchedResults
        if (!response || response.hasError || !response.responseJSON) {
          trackException(
            'Error in fetching Metrics right widget in rightMetricsWidget.tsx',
            new Error(
              response && response.errorResponse
                ? response.errorResponse.responseCode +
                  ':' +
                  response.errorResponse.message
                : ''
            )
          )
          reject()
          return
        } else {
          fetchedResults = response.responseJSON
        }

        if (!fetchedResults) {
          reject()
          return
        }

        const result: MetricsResult = fetchedResults ? fetchedResults : []

        resolve(result)
      }

      try {
        fetchMetrics()
      } catch (error) {
        trackException(
          'Error in fetching metrics in rightMetricsWidget.tsx',
          error
        )
        reject()
      }
    })
  },
  generate(
    result: MetricsResult,
    searchQuery: string
  ): Promise<React.ReactElement> {
    return new Promise<React.ReactElement>((resolve, reject) => {
      const generateMetrics = () => {
        if (!result) {
          reject()
        }

        // Map TopSearches array to TopSearch objects
        result.TopSearches = result.TopSearches.map((topSearch: any) => {
          return {
            searchTerm: topSearch[0],
            count: topSearch[1]
          }
        })

        resolve(
          <RightMetricsWidget result={result} searchQuery={searchQuery} />
        )
      }

      try {
        generateMetrics()
      } catch (error) {
        trackException(
          'Error in generating Metrics right widget in rightMetricsWidget.tsx',
          error
        )
        reject()
      }
    })
  }
}
