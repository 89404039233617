import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesRightWidget(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles(): any {
  return makeStyles((theme: Theme) => ({
    widgetAreaExtend: {
      overflow: 'visible !important',
      marginBottom: 30,
      marginLeft: 16,
      border: 'solid 1px #dfe1e5',
      borderRadius: 4,
      padding: 20
    },
    widgetArea: {
      marginLeft: 16,
      marginBottom: 16,
      border: 'solid 1px #dfe1e5',
      borderRadius: 4,
      padding: 20
    },
    widgetHeadlineH2: {
      color: '#424242',
      textAlign: 'left',
      fontWeight: 'normal',
      font: 'Arial,Helvetica Neue,Helvetica,sans-serif-light',
      fontSize: 22,
      marginBlockStart: 0,
      marginTop: 0
    },
    widgetHeadline: {
      whiteSpace: 'pre-wrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    },
    widgetTitle: {
      color: '#005eb8',
      textAlign: 'left',
      fontWeight: 'normal',
      font: 'Arial,Helvetica Neue,Helvetica,sans-serif-light',
      fontSize: '16px',
      whiteSpace: 'pre-wrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    },
    widgetDescription: {
      color: '#3c4043',
      textAlign: 'left',
      fontWeight: 'lighter',
      font: 'Arial,Helvetica Neue,Helvetica,sans-serif-light',
      fontSize: '14px'
    },
    widgetDescriptionExtended: {
      fontWeight: 'normal',
      marginTop: '4px',
      marginBottom: '3px',
      whiteSpace: 'pre-wrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    },
    widgetDescriptionThreeLines: {
      maxHeight: '60px !important'
    },
    widgetItem: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: 16
    },
    widgetFooterContent: {
      color: 'rgba(0, 0, 0, 0.54)',
      fontWeight: 'normal',
      font: 'Arial,Helvetica Neue,Helvetica,sans-serif-light',
      fontSize: 12,
      paddingTop: '5px'
    },
    spacer: {
      flex: 1,
      minWidth: '10px'
    },
    linkContainer: {
      textAlign: 'right',
      marginTop: '15px',
      justifyContent: 'flex-end'
    },
    footerGrid: {
      position: 'relative',
      marginTop: '15px'
    },
    widgetFooter: {
      color: 'rgba(0, 0, 0, 0.54)',
      textAlign: 'left',
      lineHeight: '20px',
      fontWeight: 'normal',
      font: 'Arial,Helvetica Neue,Helvetica,sans-serif-light',
      fontSize: 12,
      marginBottom: '16px',
      whiteSpace: 'pre-wrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    },
    // Arrow
    arrowContainerSeeAll: {
      position: 'absolute',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      bottom: '-67px'
    },
    arrowContainerSeeAllExpanded: {
      position: 'absolute',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      bottom: '-67px'
    },
    arrowContainer: {
      position: 'absolute',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      bottom: '-67px'
    },
    arrowContainerExpanded: {
      position: 'absolute',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      bottom: '-67px'
    },
    arrowItem: {
      cursor: 'pointer',
      color: '#424242',
      padding: '12px',
      overflow: 'visible',
      fontSize: '1.5rem',
      textAlign: 'center',
      transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      borderRadius: '50%',
      background: '#ffffff',
      boxShadow: '0 0 0 1px rgba(0,0,0,0.04), 0 4px 8px 0 rgba(0,0,0,0.20)',
      '&:hover': {
        background: '#d2d2d2'
      },
      '@media (forced-colors: active)': {
        forcedColorAdjust: 'none',
        color: 'LinkText',
        background: 'Canvas !important',
        border: '1px solid LinkText'
      }
    }
  }))
}
