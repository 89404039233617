import React from 'react'
import * as Config from 'config'
import { ResultLeftWidgetGenerator } from './interfaces'
import LeftConfluenceFinlandWidget from 'components/contents/resultpages/widgets/LeftConfluenceFinlandWidget'
import { trackException } from 'utils/tracking'
import { FetchWithCache, FetchWithCacheAndTracking } from 'utils/api'
import { IDeviceSetting } from 'utils/deviceSettings'
import { IUserSetting } from 'utils/userSettings'
import { IFindConfiguration } from 'store/Settings/reducers'
import { unifysearchQuery } from 'utils/unifysearchQuery'
import { stripHtml } from 'utils/string'
import { renewAuthorizationToken } from 'utils/token'
import { removeStopWordsFromQuery } from 'utils/oneIntranet'
import { IAADState } from 'store/Auth/reducers'

export interface IConfluenceFiResult {
  title: string
  body: string
  entityType: string
  date: string
  url: string
}

export const leftConfluenceFinlandWidgetGenerator: ResultLeftWidgetGenerator = {
  fetch(
    aadInfo: IAADState,
    searchQuery: string,
    deviceSettings: IDeviceSetting,
    userSettings: IUserSetting,
    findConfiguration: IFindConfiguration,
    useCognitiveSearch: boolean
  ): any {
    return new Promise<any>((resolve, reject) => {
      const fetchConfluenceFinland = async () => {
        if (!searchQuery || searchQuery === '' || searchQuery === '""') {
          trackException(
            'Error in fetching Confluence Finland left widget in leftConfluenceFinlandWidget.tsx',
            new Error('Empty query. Please specify a valid query text')
          )
          reject()
          return
        }

        let apiUrl = `${
          Config.APIM_BASE_URL
        }confluencefiapi/getsearch?cql=siteSearch+~+"${unifysearchQuery(
          searchQuery,
          'confluenceFi'
        )}"+and+type+in+(%22blogpost%22%2C%22page%22%2C%22space%22)&start=0&limit=10&includeArchivedSpaces=false&excerpt=highlight`

        // Get and check authentication token
        const esToken = await renewAuthorizationToken(
          aadInfo.accessToken,
          aadInfo.instance,
          aadInfo.accounts
        )

        if (
          useCognitiveSearch &&
          findConfiguration.CognitiveSearchEnabled &&
          esToken !== ''
        ) {
          let searchQueryLanguage = ''
          const detectLanguageApiUrl = `${
            Config.APIM_BASE_URL
          }searchapi/detectlanguage?searchQuery=${encodeURIComponent(
            searchQuery
          )}`

          const languageResponse = await FetchWithCache(detectLanguageApiUrl, {
            method: 'POST',
            headers: {
              accept: 'application/json',
              'content-type': 'application/json',
              'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
              Authorization: `Bearer ${esToken}`
            }
          })
          if (
            languageResponse &&
            !languageResponse.hasError &&
            languageResponse.responseJSON
          ) {
            const languageJSON = languageResponse.responseJSON
            if (languageJSON && languageJSON.responseCode === 200) {
              searchQueryLanguage = languageJSON.language
            }
          }

          apiUrl +=
            '&cognitiveEnabled=true&searchQuery=' +
            unifysearchQuery(
              removeStopWordsFromQuery(
                searchQuery,
                searchQueryLanguage,
                useCognitiveSearch,
                findConfiguration.CognitiveSearchEnabled
              ),
              'source'
            )
        }

        const response = await FetchWithCacheAndTracking(apiUrl, {
          method: 'GET',
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
            Authorization: `Bearer ${esToken}`
          }
        })

        let results
        if (!response || response.hasError || !response.responseJSON) {
          trackException(
            'Error in fetching Confluence Finland left widget in leftConfluenceFinlandWidget.tsx',
            new Error(
              response && response.errorResponse
                ? response.errorResponse.responseCode +
                  ':' +
                  response.errorResponse.message
                : ''
            )
          )
          reject()
          return
        } else {
          results = response.responseJSON
        }

        if (!results || !results.results || results.results.length < 3) {
          reject()
          return
        }

        resolve(results)
      }

      try {
        fetchConfluenceFinland()
      } catch (error) {
        trackException(
          'Error in fetching People Also Looked For left widget in leftPeopleAlsoSearchedWidget.tsx',
          error
        )
        reject()
      }
    })
  },
  generate(resultsRaw: any, searchQuery: string): Promise<React.ReactElement> {
    return new Promise<React.ReactElement>((resolve, reject) => {
      const generatePeopleAlsoSearched = () => {
        const results = resultsRaw.results
        let confluenceFiItems: IConfluenceFiResult[] = []

        results.map((confluenceFiResult: any) => {
          const confluenceFiItem: IConfluenceFiResult = {
            title: stripHtml(confluenceFiResult.title),
            body: stripHtml(confluenceFiResult.excerpt),
            date: confluenceFiResult.lastModified,
            entityType: confluenceFiResult.entityType,
            url: confluenceFiResult.content
              ? `https://${Config.CONFLUENCE_FI_SERVICE_URL}${confluenceFiResult.content._links.webui}`
              : confluenceFiResult.url
              ? `https://${Config.CONFLUENCE_FI_SERVICE_URL}${confluenceFiResult.url}`
              : ''
          }
          confluenceFiItems.push(confluenceFiItem)
          return true
        })

        if (confluenceFiItems.length < 3) {
          reject()
        }

        if (confluenceFiItems.length > 10) {
          confluenceFiItems = confluenceFiItems.slice(0, 10)
        }

        resolve(
          <LeftConfluenceFinlandWidget
            searchQuery={searchQuery}
            results={confluenceFiItems}
            synonymsApplied={resultsRaw.SynonymsApplied}
          />
        )
      }

      try {
        generatePeopleAlsoSearched()
      } catch (error) {
        trackException(
          'Error in generating People Also Looked For left widget in leftPeopleAlsoSearchedWidget.tsx',
          error
        )
        reject()
      }
    })
  }
}
