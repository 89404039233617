import { FeaturedResult } from 'components/models/FeaturedResult'
import { Reducer } from 'redux'
import {
  ResultsMppActions,
  ResultsMppActionTypes,
  IFetchSuccess,
  IFetchFiltersSuccess,
  IFetchSuccessCombined
} from './actions'
import { ISynonymsApplied } from 'components/models/SynonymsApplied'
import { IMppResult } from 'components/models/MppResult'

export interface IResultsMppStore {
  hasResultsBeenFetched: boolean
  featuredResults: FeaturedResult[]
  synonymsApplied: ISynonymsApplied[]
  results: IMppResult[]
  resultsCombined: IMppResult[]
  resultsCombinedQuery: string
  resultCount: number
  executionTime: number
  currentPage: number
  filters: {
    manual: Array<any>
    country: Array<any>
    filetype: Array<any>
    serviceline: Array<any>
    hasResultsBeenFetched: boolean
    hasError: boolean
  }
}

const initialResultFiltersState = () => ({
  manual: [],
  country: [],
  filetype: [],
  serviceline: [],
  hasResultsBeenFetched: false,
  hasError: false
})

const initialState: IResultsMppStore = {
  hasResultsBeenFetched: false,
  resultCount: -1,
  featuredResults: [],
  synonymsApplied: [],
  results: [],
  resultsCombined: [],
  resultsCombinedQuery: '',
  executionTime: 0,
  currentPage: 1,
  filters: initialResultFiltersState()
}

const reducers: Reducer<IResultsMppStore, ResultsMppActions> = (
  state: IResultsMppStore = initialState,
  action: ResultsMppActions | { type: 'SET_CURRENT_PAGE'; payload: any }
) => {
  switch (action.type) {
    case 'SET_CURRENT_PAGE':
      return {
        ...state,
        currentPage: action.payload || 0
      }
    case ResultsMppActionTypes.INITIALIZE_RESULTS_MPP:
      if (!(action as any).payload) {
        return initialState
      }
      const { resultsMpp } = (action as any).payload
      return {
        ...state,
        currentPage: resultsMpp.currentPage || 1
      }
    case ResultsMppActionTypes.FETCH_REQUEST:
      const { resetCombined, searchQuery } = (action as any).payload
      return {
        ...state,
        hasResultsBeenFetched: false,
        executionTime: 0,
        resultCount: -1,
        results: [],
        resultsCombined: resetCombined ? [] : state.resultsCombined,
        resultsCombinedQuery: searchQuery
      }
    case ResultsMppActionTypes.FETCH_FILTERS_REQUEST:
      return {
        ...state,
        hasError: false,
        filters: {
          ...state.filters,
          hasResultsBeenFetched: false,
          hasError: false
        }
      }
    case ResultsMppActionTypes.FETCH_FAILURE:
      return {
        ...state,
        hasResultsBeenFetched: true,
        resultCount: 0
      }
    case ResultsMppActionTypes.FETCH_FILTERS_FAILURE:
      return {
        ...state,
        hasResultsBeenFetched: true,
        resultCount: 0,
        filters: {
          ...initialResultFiltersState(),
          hasResultsBeenFetched: true
        }
      }

    case ResultsMppActionTypes.FETCH_SUCCESS:
      const { response, featuredResults, synonymsApplied } = (
        action as IFetchSuccess
      ).payload
      return {
        ...state,
        hasResultsBeenFetched: true,
        executionTime: response.executionTime,
        resultCount: response.resultCount,
        results: response.results,
        featuredResults:
          response.currentPage === 1 ? featuredResults : state.featuredResults,
        synonymsApplied:
          response.currentPage === 1 ? synonymsApplied : state.synonymsApplied,
        currentPage: response.currentPage
      }
    case ResultsMppActionTypes.FETCH_FILTERS_SUCCESS:
      const { responseFilters } = (action as IFetchFiltersSuccess).payload
      return {
        ...state,
        hasError: false,
        filters: {
          hasResultsBeenFetched: true,
          hasError: false,
          manual: responseFilters.manuals,
          country: responseFilters.country,
          filetype: responseFilters.filetype,
          serviceline: responseFilters.serviceline
        }
      }

    case ResultsMppActionTypes.FETCH_SUCCESS_COMBINED:
      const { response: responseCombined } = (action as IFetchSuccessCombined)
        .payload
      return {
        ...state,
        resultsCombined: responseCombined.results
      }
    default:
      return state
  }
}

export default reducers
