import React, { useState } from 'react'
import {
  Box,
  Collapse,
  IconButton,
  Tooltip,
  Link,
  Grid,
  Typography
} from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { getFileTypeIconProps } from '@uifabric/file-type-icons'
import { Icon } from '@fluentui/react/lib/Icon'
import { FileSourceResponse, isVideoOrPhoto } from 'utils/o365'
import { stripHtml } from 'utils/string'
import { externalLinkClicked } from 'utils/tracking'
import highlightedText, { prepareHighlightWords } from 'utils/highlightText'
import { IDeviceSetting } from 'utils/deviceSettings'
import {
  dateFormatOptions,
  imageOrVideoFileExtensions
} from 'constants/constants'
import { getStylesFileResult } from 'styles/results/FileResult'
import LinesEllipsis from 'react-lines-ellipsis'
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'
import DownloadIcon from '@mui/icons-material/Download'
import { downloadSPOBlob } from 'utils/o365'
import CircularProgress from '@mui/material/CircularProgress'
import { IAADState } from 'store/Auth/reducers'

interface IMobileProps {
  item: FileSourceResponse
  summary?: string
  isOpen: boolean
  searchQuery: string
  open: boolean
  fileExtension?: string
  validatedFileUrl: string
  setOpen: React.Dispatch<boolean>
  deviceSettings: IDeviceSetting
  documentId: string
  driveId: string
  fetchDocumentUrl: (
    documentId: string,
    driveId: string,
    fallBackUrl: string,
    openInNewTab: boolean
  ) => void
  index: number
  aadInfo: IAADState
}

export function FileMobileResult(props: IMobileProps): JSX.Element {
  const {
    item,
    searchQuery,
    open,
    setOpen,
    fileExtension,
    validatedFileUrl,
    deviceSettings,
    summary,
    documentId,
    driveId,
    fetchDocumentUrl,
    index,
    aadInfo
  } = props
  const classes = getStylesFileResult()
  const intl = useIntl()
  const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis)
  const [downloadInProgress, setDownloadInProgress] = useState(false)

  const fileUrl: string =
    isVideoOrPhoto(fileExtension) && documentId && driveId
      ? `${
          window.location.hash
        }&documentId=${documentId}&driveId=${driveId}&fallbackUrl=${encodeURIComponent(
          validatedFileUrl
        )}`
      : validatedFileUrl
  const handleDownload = () => {
    if (!isVideoOrPhoto(fileExtension)) {
      downloadSPOBlob(
        aadInfo,
        fileUrl.replace('?web=1', ''),
        '',
        item.name,
        setDownloadInProgress
      )
    } else {
      let listItemUrl = item.webUrl.split('/').slice(0, 5).join('/')
      if (item.parentReference && item.parentReference.sharepointIds) {
        listItemUrl +=
          "/_api/web/lists(guid'" +
          item.parentReference.sharepointIds.listId +
          "')/items(" +
          item.parentReference.sharepointIds.listItemId +
          ')/file'
      }
      downloadSPOBlob(
        aadInfo,
        '',
        listItemUrl,
        item.name,
        setDownloadInProgress
      )
    }
  }

  return (
    <Grid container direction="column" className={classes.fileMobileContainer}>
      <Grid container justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Icon
            className={classes.fileResultIcon}
            {...getFileTypeIconProps({ extension: fileExtension, size: 16 })}
          />
          <Typography
            style={{
              marginLeft: '8px',
              fontSize: '14px'
            }}
          >
            {item.lastModifiedDateTime
              ? intl.formatDate(
                  item.lastModifiedDateTime.replace('Z', ''),
                  dateFormatOptions
                )
              : ''}
          </Typography>

          <Typography
            style={{
              paddingLeft: 2,
              paddingRight: 2
            }}
          >
            {'›'}
          </Typography>

          <Typography className={classes.fileResultSource}>
            {item.webUrl
              ? item.webUrl.includes('-my.')
                ? 'Personal'
                : 'SharePoint'
              : ''}
          </Typography>
          <Typography>
            {!downloadInProgress && !item.webUrl.includes('-my.') && (
              <DownloadIcon
                onClick={handleDownload}
                className={classes.downloadIcon}
              />
            )}
            {downloadInProgress && !item.webUrl.includes('-my.') && (
              <CircularProgress className={classes.loadingSpinner} size={14} />
            )}
          </Typography>
        </Box>

        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => setOpen(!open)}
        >
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </Grid>
      <Grid style={{ marginBottom: 8 }}>
        <Tooltip enterTouchDelay={300} title={item.name}>
          <Link
            className={classes.fileResultIcon}
            href={validatedFileUrl}
            onClick={(event: any) => {
              externalLinkClicked({
                title: item.name,
                url: validatedFileUrl,
                index: index
              })

              const isVideoOrPhoto = fileExtension
                ? imageOrVideoFileExtensions.indexOf(
                    fileExtension.toLowerCase()
                  ) !== -1
                : false

              if (documentId && driveId && isVideoOrPhoto) {
                fetchDocumentUrl(documentId, driveId, validatedFileUrl, false)
                event.preventDefault()
              }
            }}
            {...(deviceSettings.openLinksInNewTab === true
              ? { target: '_blank', rel: 'noreferrer' }
              : {})}
          >
            <ResponsiveEllipsis
              style={{
                whiteSpace: 'pre-wrap',
                textOverflow: 'ellipsis',
                textDecoration: 'none',
                overflow: 'hidden',
                cursor: 'pointer'
              }}
              basedOn="words"
              text={item.name ? item.name.trim() : ''}
              maxLine="1"
            />
          </Link>
        </Tooltip>
      </Grid>
      <Grid>
        <Collapse
          in={open}
          timeout="auto"
          unmountOnExit
          className={classes.resultCollapse}
        >
          <Box className={classes.fileResultBody}>
            {summary ? (
              highlightedText(
                stripHtml(summary),
                prepareHighlightWords(searchQuery, stripHtml(summary), []),
                false
              )
            ) : (
              <FormattedMessage
                id="missing_description_text"
                defaultMessage="Description is not available"
              />
            )}
          </Box>
          <Box className={classes.fileResultBodyPath} style={{ marginTop: 6 }}>
            <ResponsiveEllipsis
              style={{
                whiteSpace: 'pre-wrap',
                textOverflow: 'ellipsis',
                textDecoration: 'none',
                overflow: 'hidden'
              }}
              basedOn="words"
              text={item.webUrl ? item.webUrl.trim() : ''}
              maxLine="2"
            />
          </Box>
        </Collapse>
      </Grid>
    </Grid>
  )
}
