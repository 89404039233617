import * as Config from 'config'

export const getKlardenkerImage = async (
  id: string,
  authToken: string
): Promise<string | null> => {
  try {
    const apiUrl = `${
      Config.APIM_BASE_URL
    }klardenkerapi/getmedia?parent=${encodeURIComponent(
      id
    )}&processFeaturedResults=false`

    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
        Authorization: `Bearer ${authToken}`
      }
    })

    if (response && response.ok) {
      const attachmentResults = await response.json()

      if (attachmentResults && attachmentResults.length > 0) {
        const attachmentResult = attachmentResults[0]

        let imageUrl = ''
        if (
          attachmentResult.media_details &&
          attachmentResult.media_details.sizes &&
          attachmentResult.media_details.sizes.thumbnail &&
          attachmentResult.media_details.sizes.thumbnail.source_url
        ) {
          imageUrl = attachmentResult.media_details.sizes.thumbnail.source_url
        }

        return imageUrl
      }
    }

    return null
  } catch (error) {
    return null
  }
}
