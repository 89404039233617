import React from 'react'
import * as Config from 'config'
import { ResultRightWidgetGenerator } from './interfaces'
import RightInfographicsWidget from 'components/contents/resultpages/widgets/RightInfographicsWidget'
import { trackException } from 'utils/tracking'
import { stripHtml } from 'utils/string'
import { FetchWithCache } from 'utils/api'
import { IDeviceSetting } from 'utils/deviceSettings'
import { IUserSetting } from 'utils/userSettings'
import { IFindConfiguration } from 'store/Settings/reducers'
import { IInfographicsResult } from 'components/models/InfographicsResult'
import { IAADState } from 'store/Auth/reducers'
import { renewAuthorizationToken } from 'utils/token'

export const rightInfographicsWidgetGenerator: ResultRightWidgetGenerator = {
  fetch(
    aadInfo: IAADState,
    searchQuery: string,
    deviceSettings: IDeviceSetting,
    userSettings: IUserSetting,
    findConfiguration: IFindConfiguration
  ): Promise<IInfographicsResult[]> {
    return new Promise<IInfographicsResult[]>((resolve, reject) => {
      const fetchInfographics = async () => {
        if (
          !searchQuery ||
          searchQuery === '' ||
          searchQuery === '""' ||
          searchQuery.length < 4
        ) {
          trackException(
            'Error in fetching Infographics right widget in rightInfographicsWidget.tsx',
            new Error('Empty query. Please specify a valid query text')
          )
          reject()
          return
        }

        let infographicsUrl = `${
          Config.APIM_BASE_URL
        }statistaapi/getinfographics?q=${encodeURIComponent(
          searchQuery
        )}&limit=200&processFeaturedResults=false`

        if (!userSettings.PremiumEnabled) {
          infographicsUrl += `&premium=0`
        }

        // Get and check authentication token
        const esToken = await renewAuthorizationToken(
          aadInfo.accessToken,
          aadInfo.instance,
          aadInfo.accounts
        )

        const response = await FetchWithCache(infographicsUrl, {
          method: 'GET',
          headers: {
            'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
            'content-type': 'application/json',
            Authorization: `Bearer ${esToken}`
          }
        })

        let results
        if (!response || response.hasError || !response.responseJSON) {
          trackException(
            'Error in fetching Infographics left widget in leftInfographicsWidget.tsx',
            new Error(
              response && response.errorResponse
                ? response.errorResponse.responseCode +
                  ':' +
                  response.errorResponse.message
                : ''
            )
          )
          reject()
          return
        } else {
          results = response.responseJSON
        }

        if (!results || !results.items || results.items.length < 3) {
          reject()
          return
        } else if (results.items.length > 10) {
          results.items = results.items.slice(0, 10)
        }

        const infographicResults: IInfographicsResult[] = []

        results.items.forEach((i: any) => {
          const infographicResult: IInfographicsResult = {
            title: i && i.title ? i.title : '',
            url: i && i.link ? i.link : '',
            content: i.subject
              ? stripHtml(i.subject)
              : i && i.description
              ? stripHtml(i.description)
              : '',
            date: i.date ? i.date : ''
          }

          infographicResults.push(infographicResult)
        })

        resolve(infographicResults)
      }

      try {
        fetchInfographics()
      } catch (error) {
        trackException(
          'Error in fetching Infographics right widget in rightInfographicsWidget.tsx',
          error
        )
        reject()
      }
    })
  },
  generate(
    results: IInfographicsResult[],
    searchQuery: string
  ): Promise<React.ReactElement> {
    return new Promise<React.ReactElement>((resolve, reject) => {
      const generateInfographics = () => {
        let hasContent = false
        for (let i = 0; i < results.length; i++) {
          if (results[i].title !== '') {
            hasContent = true
            break
          }
        }
        if (!hasContent) {
          reject()
          return
        }

        resolve(
          <RightInfographicsWidget
            searchQuery={searchQuery}
            infographicResults={results}
          />
        )
      }

      try {
        generateInfographics()
      } catch (error) {
        trackException(
          'Error in generating Infographics right widget in rightInfographicsWidget.tsx',
          error
        )
        reject()
      }
    })
  }
}
