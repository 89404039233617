import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesSpellCheck(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles() {
  return makeStyles((theme: Theme) => ({
    spellCheckContainer: {
      margin: '0 0 24px 0'
    },
    correctedSearchTermContainer: {
      textAlign: 'left',
      fontWeight: 'normal',
      font: 'Arial,Helvetica Neue,Helvetica,sans-serif-light',
      fontSize: 18,
      marginBlockStart: 0,
      display: 'flex',
      [`${theme.breakpoints.down(1024)} and (hover: none)`]: {
        fontSize: 14
      }
    },
    linkContainer: {
      paddingLeft: '2px',
      marginLeft: '5px',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    correctedSearchTerm: {
      color: '#005eb8',
      fontStyle: 'italic',
      cursor: 'pointer',
      paddingRight: '3px'
    },
    wildCardSearchTerm: {
      color: '#005eb8',
      cursor: 'pointer'
    },
    noResultMessageContainer: {
      color: 'grey'
    },
    result: {
      whiteSpace: 'nowrap'
    },
    resetButton: {
      textDecoration: 'none',
      color: '#005eb8',
      display: 'inline',
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline'
      }
    }
  }))
}
