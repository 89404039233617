import { Reducer } from 'redux'
import { StartPageActions } from './actions'

export interface IStartPageStore {
  results: any
}

const initialState: IStartPageStore = {
  results: {}
}

const reducers: Reducer<IStartPageStore, StartPageActions> = (
  state: IStartPageStore = initialState,
  action: StartPageActions
) => {
  switch (action.type) {
    default:
      return state
  }
}

export default reducers
