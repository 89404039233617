import React from 'react'
import Link from '@mui/material/Link'
import { connect } from 'react-redux'
import { Store } from 'store'
import SettingsStore from 'store/Settings'
import { Box, Button, Typography } from '@mui/material'
import { externalLinkClicked } from 'utils/tracking'
import { removeQueryParam } from 'utils/queryParams'
import { useNavigate } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { getStylesRightWidget } from 'styles/contents/resultpages/widgets/RightWidget'
import { getAlexTopRightWidgetStyles } from 'styles/contents/resultpages/widgets/AlexTopRightWidget'
import highlightedText, { prepareHighlightWords } from 'utils/highlightText'
import createDOMPurify from 'dompurify'
import { alexResultTypes } from 'constants/constants'
import { stripHtml } from 'utils/string'
import { IAlexWidgetResults } from 'utils/widgets/alexTopRightWidget'
import TooltipTitle from 'components/contents/common/TooltipTitle'
import HTMLEllipsis from 'react-lines-ellipsis/lib/html'
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'

export interface CompanySnapshotWidgetProps {
  searchQuery: any
  results: IAlexWidgetResults
}

type AllProps = ReturnType<typeof mapStateToProps> & CompanySnapshotWidgetProps

function RightTopAlexWidget(props: AllProps): JSX.Element | null {
  const { results, deviceSettings, searchQuery, findConfiguration } = props

  const DOMPurify = createDOMPurify(window)
  const navigateFunction = useNavigate()
  const ResponsiveHTMLEllipsis = responsiveHOC()(HTMLEllipsis)

  const classes = getAlexTopRightWidgetStyles()
  const classesRight = getStylesRightWidget()

  const imagePath = 'images/alexWidgetLogo.png'
  const title = ''
  const text =
    'Alex delivers timely and complete information you need for a more effective and efficient audit.'

  // generate the internal alex url in case no url is present at the result
  const generateAlexInternalUrl = (item: any | null) => {
    if (item.url) {
      return DOMPurify.sanitize(item.url)
    }

    // use the killDocId as id, fallback is the doc id
    const docId = item.killDocId
      ? DOMPurify.sanitize(item.killDocId)
      : DOMPurify.sanitize(item.docId)

    // in case of an internal alex content page, return the page url for the alex content result
    if (item.mimeType && item.mimeType === 'text/html') {
      // add the tocRef attribute like alex does, also present in alex if empty
      return `https://alex.kpmg.com/AROWeb/document/lfc/${docId}/toc/${docId}?tocref=${DOMPurify.sanitize(
        item.tocRef
      )}`
    } else {
      return `https://alex.kpmg.com/AROWeb/document/lfc/${docId}`
    }
  }

  const generateAlexOverViewTopUrl = (item: any | null) => {
    if (item.url) {
      return DOMPurify.sanitize(item.url)
    }

    // in case no docid is present, nothing can be done
    if (!item.docId) {
      return ''
    }
    return `https://alex.kpmg.com/AROWeb/document/top/${DOMPurify.sanitize(
      item.docId
    )}`
  }

  const generateAlexManualUrl = (item: any | null) => {
    if (item.url) {
      return DOMPurify.sanitize(item.url)
    }

    // in case no docid is present, nothing can be done
    if (!item.tocRef) {
      return ''
    }
    return `https://alex.kpmg.com/AROWeb/document/manuals/toc?tocref=${
      item.tocRef
    }&cntry=${DOMPurify.sanitize(item.tocCountry)}&chunkid=${DOMPurify.sanitize(
      item.docId
    )}`
  }

  const generateSeeAllButtonLink = (
    searchQuery: string,
    applicableCountries: string,
    type: string
  ) => {
    let seeAllLink =
      'https://alex.kpmg.com/AROWeb/search;als=1;sort=Relevance;vm=2;x=1?q={searchQuery}&d={applicableCountries}&type={type}'

    if (
      findConfiguration &&
      findConfiguration.SeeAllLinks &&
      findConfiguration.SeeAllLinks['alex']
    ) {
      seeAllLink = findConfiguration.SeeAllLinks['alex']
    }

    seeAllLink = seeAllLink.replace('{searchQuery}', searchQuery)
    seeAllLink = seeAllLink.replace(
      '{applicableCountries}',
      applicableCountries
    )
    seeAllLink = seeAllLink.replace('{type}', type)

    return seeAllLink
  }

  const buildAlexResultContainer = (
    results: any[],
    alexResultType: string,
    applicableCountries: string[]
  ) => {
    let seeAllButton = ''
    let categoryTitle = null
    if (alexResultType === alexResultTypes.topics) {
      categoryTitle = (
        <>
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width="22px"
            height="1px"
            viewBox="0 0 22 1"
            className={`${classes.icon} ${classes.topicIcon}`}
          >
            <g transform="scale(0.9)">
              <g
                transform="translate(-205,-1177.6)"
                xmlns="http://www.w3.org/2000/svg"
                id="direction_1_"
              >
                <path
                  d="M226.922,1164.535c0.121-0.246,0.019-0.35-0.229-0.227l-24.521,12.044c-0.247,0.121-0.225,0.221,0.051,0.221h11.924
                              l0.025,11.975c0,0.275,0.1,0.298,0.221,0.051L226.922,1164.535z M210.68,1174.572c-0.275,0-0.299-0.35-0.052-0.471l12.597-6.295
                              c0.246-0.121,0.304-0.07,0.109,0.125l-6.843,6.068c-0.194,0.194-0.579,0.572-0.854,0.572H210.68z"
                ></path>
              </g>
            </g>
          </svg>

          <FormattedMessage
            id="alex_topic_overview_result"
            defaultMessage="Topic Overview"
          />
        </>
      )
      seeAllButton = generateSeeAllButtonLink(
        DOMPurify.sanitize(searchQuery),
        applicableCountries.join(','),
        'topic'
      )
    } else if (alexResultType === alexResultTypes.featured) {
      categoryTitle = (
        <>
          <svg
            _ngcontent-waf-c131=""
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width="22px"
            height="1px"
            viewBox="0 0 22 1"
            className={`${classes.icon} ${classes.featuredIcon}`}
          >
            <g _ngcontent-waf-c131="" transform="scale(1.0)">
              <g
                _ngcontent-waf-c131=""
                transform="translate(-494,-265.6)"
                xmlns="http://www.w3.org/2000/svg"
                id="star_2_"
              >
                <path
                  _ngcontent-waf-c131=""
                  d="M516.906,261h-9.438l-2.922-9l-2.971,9H492l7.757,5.455l-2.913,8.862l7.648-5.564l7.679,5.508l-2.909-8.85L516.906,261z"
                ></path>
              </g>
            </g>
          </svg>
          <FormattedMessage
            id="alex_featured_content_result"
            defaultMessage="Featured Content"
          />
        </>
      )
      seeAllButton = generateSeeAllButtonLink(
        DOMPurify.sanitize(searchQuery),
        applicableCountries.join(','),
        'featured'
      )
    } else {
      categoryTitle = (
        <div className={classes.iconSpace}>
          <FormattedMessage
            id="alex_search_content_result"
            defaultMessage="Search Content"
          />
        </div>
      )
      seeAllButton = generateSeeAllButtonLink(
        DOMPurify.sanitize(searchQuery),
        applicableCountries.join(','),
        'alex'
      )
    }

    return (
      <div
        className={
          alexResultType === alexResultTypes.topics
            ? classes.containerTopic
            : alexResultType === alexResultTypes.featured
            ? classes.containerFeatured
            : classes.containerSearch
        }
      >
        <div className={classes.title}>{categoryTitle}</div>

        {results.slice(0, 3).map((alexResult: any, index: number) => {
          let url = ''
          if (
            alexResultType === alexResultTypes.search ||
            alexResultType === alexResultTypes.featured
          ) {
            url = generateAlexInternalUrl(alexResult)
          } else if (alexResultType === alexResultTypes.manuals) {
            url = generateAlexManualUrl(alexResult)
          } else {
            url = generateAlexOverViewTopUrl(alexResult)
          }

          const body = alexResult.synopsis
            ? stripHtml(DOMPurify.sanitize(alexResult.synopsis))
            : ''
          return (
            <Box key={'rta' + index} className={classes.resultItem}>
              <Link
                key={'rtal1' + index}
                className={classes.link}
                {...(deviceSettings.openLinksInNewTab === true
                  ? { target: '_blank', rel: 'noreferrer' }
                  : {})}
                href={url}
                onClick={(event: any) => {
                  removeQueryParam(navigateFunction, 'cntx')
                  externalLinkClicked({ title, url, index })
                }}
                {...(!deviceSettings.isMobile
                  ? {
                      onAuxClick: (event: any) => {
                        removeQueryParam(navigateFunction, 'cntx')
                        externalLinkClicked({
                          title,
                          url,
                          index,
                          wasRightClicked: event?.button === 2 ? true : false
                        })
                      }
                    }
                  : {})}
              >
                <Typography
                  key={'rtat1' + index}
                  variant="h5"
                  color="primary"
                  className={classes.resultTitle}
                  component="div"
                >
                  <TooltipTitle
                    title={DOMPurify.sanitize(alexResult.title.trim())}
                    key={'rtad1' + index}
                    singleLine={true}
                  />
                </Typography>
              </Link>
              {body && (
                <Typography
                  key={'rtat2' + index}
                  variant="body2"
                  color="secondary"
                  gutterBottom
                  className={`${classes.resultBody} txt-resultdescription-01`}
                  component="div"
                >
                  {body && (
                    <ResponsiveHTMLEllipsis
                      style={{
                        whiteSpace: 'pre-wrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden'
                      }}
                      basedOn="words"
                      unsafeHTML={highlightedText(
                        DOMPurify.sanitize(body.replace(/(\r\n|\n|\r)/gm, ' ')),
                        prepareHighlightWords(
                          DOMPurify.sanitize(searchQuery),
                          DOMPurify.sanitize(
                            body.replace(/(\r\n|\n|\r)/gm, ' ')
                          ),
                          []
                        ),
                        true
                      )}
                      maxLine="1"
                    />
                  )}
                </Typography>
              )}
            </Box>
          )
        })}
        {results.length > 3 && (
          <div className={classes.containerLoadMore}>
            <Link
              className={classes.loadMoreButton}
              href={seeAllButton}
              target="_blank"
            >
              <Button color="primary" variant="contained">
                <FormattedMessage
                  id="more_results"
                  defaultMessage="More results"
                />
              </Button>
            </Link>
          </div>
        )}
      </div>
    )
  }

  if (
    (results.alextopics &&
      results.alextopics.result &&
      results.alextopics.result.length > 0) ||
    (results.alexfeatured &&
      results.alexfeatured.result &&
      results.alexfeatured.result.length > 0) ||
    (results.alexsearch &&
      results.alexsearch.result &&
      results.alexsearch.result.length > 0)
  ) {
    return (
      <Box className={classesRight.widgetArea} style={{ padding: 0 }}>
        <img className={classes.image} src={imagePath} alt={''} />

        <div className={classes.container}>
          {title != null && title.length > 0 && (
            <div className={classes.containerTitle}>{title}</div>
          )}
          {text != null && text.length > 0 && (
            <div
              className={classes.content}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(text)
              }}
            />
          )}

          {results.alextopics.result &&
            results.alextopics.result.length > 0 &&
            buildAlexResultContainer(
              results.alextopics.result,
              alexResultTypes.topics,
              results.alextopics.activeDomains
            )}

          {results.alexfeatured.result &&
            results.alexfeatured.result.length > 0 &&
            buildAlexResultContainer(
              results.alexfeatured.result,
              alexResultTypes.featured,
              results.alexfeatured.activeDomains
            )}

          {results.alexsearch.result &&
            results.alexsearch.result.length > 0 &&
            buildAlexResultContainer(
              results.alexsearch.result,
              alexResultTypes.search,
              results.alexsearch.activeDomains
            )}
        </div>
      </Box>
    )
  }

  return null
}

const mapStateToProps = (state: Store) => {
  return {
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state),
    currentDataSources: SettingsStore.selectors.getCurrentDataSources(state),
    findConfiguration: SettingsStore.selectors.getFindConfiguration(state)
  }
}

export default connect(mapStateToProps)(RightTopAlexWidget)
