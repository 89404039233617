import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesCompanySnapshotWidget(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles(): any {
  return makeStyles((theme: Theme) => ({
    widgetAreaExtend: {
      marginBottom: 30,
      marginLeft: 16,
      border: 'solid 1px #dfe1e5',
      borderRadius: 4,
      padding: 20
    },
    widgetArea: {
      marginBottom: 16,
      marginLeft: 16,
      border: 'solid 1px #dfe1e5',
      borderRadius: 4,
      padding: '20px 20px 10px 20px'
    },
    widgetHeadlineH2: {
      color: '#424242',
      textAlign: 'left',
      fontWeight: 'normal',
      font: 'Arial,Helvetica Neue,Helvetica,sans-serif-light',
      fontSize: 22,
      marginBlockStart: 0,
      marginTop: 0,
      marginBottom: 0
    },
    widgetHeadline: {
      whiteSpace: 'pre-wrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    },
    widgetCompany: {
      color: '#005eb8',
      overflow: 'hidden',
      fontSize: '16px',
      textAlign: 'left',
      fontWeight: 'lighter',
      whiteSpace: 'pre-wrap',
      textOverflow: 'ellipsis',
      marginBottom: '10px'
    },
    widgetContent: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: 14
    },
    widgetTextContainer: {
      overflow: 'hidden',
      whiteSpace: 'pre-wrap',
      textOverflow: 'ellipsis'
    },
    row: {
      height: '35px'
    },
    titleCell: {
      fontWeight: 'bold',
      border: 0,
      padding: 0,
      width: '30%'
    },
    contentCell: {
      border: 0,
      padding: 0
    }
  }))
}
