import React, { useMemo } from 'react'
import { Box, Fade } from '@mui/material'
import { useIntl } from 'react-intl'
import ReactPlaceholder from 'react-placeholder'
import { connect } from 'react-redux'
import 'Placeholder.css'
import { Store } from 'store'
import ResultsStatisticsStore from 'store/ResultsStatistics'
import { StatsResult } from 'components/contents/results/StatsResult'
import Pagination from '../Pagination'
import { trackException } from 'utils/tracking'
import SearchSuggestions from 'components/contents/common/SearchSuggestions'
import { IDeviceSetting } from 'utils/deviceSettings'
import FeaturedResults from './FeaturedResults'
import { dateFormatOptions } from 'constants/constants'
import LeftWidgetContainer from './widgetscontainer/LeftWidgetContainer'
import { getStylesResults } from 'styles/contents/resultpages/Results'
import { useFilter } from 'utils/filters'
import { useEffect } from 'react'
import createDOMPurify from 'dompurify'
import { formatDisplayBody } from 'utils/intranet'

interface ResultsStatisticsMobileProps {
  searchQuery: any
  deviceSettings: IDeviceSetting
}

type AllMobileProps = ReturnType<typeof mapStateToProps> &
  ResultsStatisticsMobileProps

function ResultsStatisticsMobile(props: AllMobileProps) {
  const {
    hasResultsBeenFetched,
    resultsCount,
    featuredResults,
    resultsCombined,
    searchQuery,
    deviceSettings
  } = props
  const DOMPurify = createDOMPurify(window)
  const resultClasses = getStylesResults()
  const intl = useIntl()
  const [currentPage] = useFilter('page', '1')

  const returnAllItems = (type: string) => {
    if (!resultsCombined || resultsCombined.length < 1) {
      return []
    }

    const items = []

    try {
      const firstResults =
        resultsCombined.length < 4 ? resultsCombined.length : 3

      if (type === 'first') {
        for (let i = 0; i < firstResults; i++) {
          const currentDate =
            resultsCombined[i].date && resultsCombined[i].date !== ''
              ? intl.formatDate(
                  DOMPurify.sanitize(resultsCombined[i].date),
                  dateFormatOptions
                )
              : ''

          const currentBody = DOMPurify.sanitize(resultsCombined[i].body) || ''
          const displayBody = formatDisplayBody(currentDate, currentBody, intl)
          items.push(
            <StatsResult
              image={
                resultsCombined[i].image
                  ? DOMPurify.sanitize(resultsCombined[i].image)
                  : ''
              }
              title={DOMPurify.sanitize(resultsCombined[i].title)}
              body={displayBody}
              url={DOMPurify.sanitize(resultsCombined[i].link)}
              sources={DOMPurify.sanitize(
                resultsCombined[i].sources &&
                  resultsCombined[i].sources.length > 0 &&
                  resultsCombined[i].sources[0].title
                  ? resultsCombined[i].sources[0].title
                  : ''
              )}
              premium={DOMPurify.sanitize(resultsCombined[i].premium)}
              key={i}
              index={i}
              searchQuery={DOMPurify.sanitize(searchQuery)}
              deviceSettings={deviceSettings}
            />
          )
        }
      } else {
        for (let i = firstResults; i < resultsCombined.length; i++) {
          const currentDate =
            resultsCombined[i].date && resultsCombined[i].date !== ''
              ? intl.formatDate(
                  DOMPurify.sanitize(resultsCombined[i].date),
                  dateFormatOptions
                )
              : ''

          const currentBody = DOMPurify.sanitize(resultsCombined[i].body) || ''
          const displayBody = formatDisplayBody(currentDate, currentBody, intl)
          items.push(
            <StatsResult
              image={
                resultsCombined[i].image
                  ? DOMPurify.sanitize(resultsCombined[i].image)
                  : ''
              }
              title={DOMPurify.sanitize(resultsCombined[i].title)}
              body={displayBody}
              url={DOMPurify.sanitize(resultsCombined[i].link)}
              sources={DOMPurify.sanitize(
                resultsCombined[i].sources &&
                  resultsCombined[i].sources.length > 0 &&
                  resultsCombined[i].sources[0].title
                  ? resultsCombined[i].sources[0].title
                  : ''
              )}
              premium={DOMPurify.sanitize(resultsCombined[i].premium)}
              key={i}
              index={i}
              searchQuery={DOMPurify.sanitize(searchQuery)}
              deviceSettings={deviceSettings}
            />
          )
        }
      }
    } catch (error) {
      trackException(
        'Error returning all items in ResultsStatisticsMobile.tsx',
        error
      )
    }
    return items
  }

  const firstItems = useMemo(
    () => returnAllItems('first'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resultsCombined, intl]
  )
  const restItems = useMemo(
    () => returnAllItems('rest'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resultsCombined, intl]
  )

  return (
    <Box className={resultClasses.container}>
      <Box className={resultClasses.resultsContainer}>
        <SearchSuggestions
          wildCardActive={false}
          resultCount={resultsCount}
          resultsFetchend={hasResultsBeenFetched}
        />
        <ReactPlaceholder
          ready={
            hasResultsBeenFetched || Number(currentPage) > 1 ? true : false
          }
          type="text"
          rows={4}
          showLoadingAnimation={true}
          className={resultClasses.placeholder}
        >
          <Fade
            in={hasResultsBeenFetched || Number(currentPage) > 1 ? true : false}
            timeout={600}
          >
            <Box>
              {resultsCombined && resultsCount > 0 && (
                <FeaturedResults featuredResults={featuredResults} />
              )}
              {firstItems}
            </Box>
          </Fade>
        </ReactPlaceholder>
        {hasResultsBeenFetched &&
        (resultsCount < 1 || resultsCombined.length < 1) ? null : (
          <LeftWidgetContainer
            resultCount={resultsCount}
            hasResultsBeenFetched={hasResultsBeenFetched}
          />
        )}
        <ReactPlaceholder
          ready={hasResultsBeenFetched || Number(currentPage) > 1}
          type="text"
          rows={4}
          showLoadingAnimation={true}
          className={resultClasses.placeholder}
        >
          <Fade
            in={hasResultsBeenFetched || Number(currentPage) > 1}
            timeout={600}
          >
            <Box>
              {restItems}
              {resultsCount != null &&
                resultsCount > 0 &&
                hasResultsBeenFetched && (
                  <Pagination
                    {...props}
                    totalRecords={resultsCount}
                    pageLimit={20}
                    pageNeighbours={4}
                  />
                )}
            </Box>
          </Fade>
        </ReactPlaceholder>

        <ReactPlaceholder
          ready={hasResultsBeenFetched ? hasResultsBeenFetched : false}
          type="text"
          rows={4}
          showLoadingAnimation={true}
          className={resultClasses.placeholder}
        >
          <Box />
        </ReactPlaceholder>
      </Box>
    </Box>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    featuredResults: ResultsStatisticsStore.selectors.getFeaturedResults(state),
    resultsCombined: ResultsStatisticsStore.selectors.getResultsCombined(state),
    resultsCount: ResultsStatisticsStore.selectors.getResultCount(state),
    executionTime: ResultsStatisticsStore.selectors.getExecutionTime(state),
    hasResultsBeenFetched:
      ResultsStatisticsStore.selectors.hasResultsBeenFetched(state)
  }
}

export default connect(mapStateToProps)(ResultsStatisticsMobile)
