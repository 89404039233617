import { Action, AnyAction } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { Store } from '..'
import * as Config from 'config'
import AuthStore from '../Auth'
import { renewAuthorizationToken } from 'utils/token'
import { trackEvents, trackException } from 'utils/tracking'
import * as Constants from 'constants/constants'
import {
  SupportedLanguage,
  supportedLanguages
} from 'constants/supportedLanguages'
import { AdResult } from 'components/models/AdResult'
import { FetchWithCache } from 'utils/api'
import { latinize } from 'utils/latinize'
import { PopupResult } from 'components/models/PopupResult'

export enum TranslationsActionTypes {
  FETCH_VERSION_TRANSLATIONS_REQUEST = 'translations/FETCH_VERSION_TRANSLATIONS_REQUEST',
  FETCH_VERSION_TRANSLATIONS_SUCCESS = 'translations/FETCH_VERSION_TRANSLATIONS_SUCCESS',
  FETCH_GLOBAL_TRANSLATIONS_REQUEST = 'translations/FETCH_GLOBAL_TRANSLATIONS_REQUEST',
  FETCH_GLOBAL_TRANSLATIONS_SUCCESS = 'translations/FETCH_GLOBAL_TRANSLATIONS_SUCCESS',
  FETCH_ADS_TRANSLATIONS_REQUEST = 'translations/FETCH_ADS_TRANSLATIONS_REQUEST',
  FETCH_ADS_TRANSLATIONS_SUCCESS = 'translations/FETCH_ADS_TRANSLATIONS_SUCCESS',
  FETCH_POPUPS_TRANSLATIONS_REQUEST = 'translations/FETCH_POPUPS_TRANSLATIONS_REQUEST',
  FETCH_POPUPS_TRANSLATIONS_SUCCESS = 'translations/FETCH_POPUPS_TRANSLATIONS_SUCCESS',
  FETCH_FAQ_TRANSLATIONS_REQUEST = 'translations/FETCH_FAQ_TRANSLATIONS_REQUEST',
  FETCH_FAQ_TRANSLATIONS_SUCCESS = 'translations/FETCH_FAQ_TRANSLATIONS_SUCCESS',
  FETCH_ROADMAP_TRANSLATIONS_REQUEST = 'translations/FETCH_ROADMAP_TRANSLATIONS_REQUEST',
  FETCH_ROADMAP_TRANSLATIONS_SUCCESS = 'translations/FETCH_ROADMAP_TRANSLATIONS_SUCCESS',
  FETCH_INTRODUCTION_TRANSLATIONS_REQUEST = 'translations/FETCH_INTRODUCTION_TRANSLATIONS_REQUEST',
  FETCH_INTRODUCTION_TRANSLATIONS_SUCCESS = 'translations/FETCH_INTRODUCTION_TRANSLATIONS_SUCCESS',
  FETCH_TRANSLATIONS_NOTFOUND = 'translations/FETCH_TRANSLATIONS_NOTFOUND',
  FETCH_TRANSLATIONS_FAILURE = 'translations/FETCH_TRANSLATIONS_FAILURE'
}

export type IFetchVersionTranslationsRequest = Action<TranslationsActionTypes>
export interface IFetchVersionTranslationsSuccess
  extends Action<TranslationsActionTypes> {
  payload: {
    translationType: string
    version: string
  }
}
export type IFetchGlobalTranslationsRequest = Action<TranslationsActionTypes>
export interface IFetchGlobalTranslationsSuccess
  extends Action<TranslationsActionTypes> {
  payload: {
    globalResponse: any
  }
}

export type IFetchAdsTranslationsRequest = Action<TranslationsActionTypes>
export interface IFetchAdsTranslationsSuccess
  extends Action<TranslationsActionTypes> {
  payload: {
    adsResponse: any
  }
}

export type IFetchPopupsTranslationsRequest = Action<TranslationsActionTypes>
export interface IFetchPopupsTranslationsSuccess
  extends Action<TranslationsActionTypes> {
  payload: {
    popupsResponse: any
  }
}

export type IFetchFaqTranslationsRequest = Action<TranslationsActionTypes>
export interface IFetchFaqTranslationsSuccess
  extends Action<TranslationsActionTypes> {
  payload: {
    faqResponse: any
  }
}
export type IFetchRoadmapTranslationsRequest = Action<TranslationsActionTypes>
export interface IFetchRoadmapTranslationsSuccess
  extends Action<TranslationsActionTypes> {
  payload: {
    roadmapResponse: any
  }
}
export type IFetchIntroductionTranslationsRequest =
  Action<TranslationsActionTypes>
export interface IFetchIntroductionTranslationsSuccess
  extends Action<TranslationsActionTypes> {
  payload: {
    introductionResponse: any
  }
}
export type IFetchTranslationsFailure = Action<TranslationsActionTypes>
export type IFetchTranslationsNotFound = Action<TranslationsActionTypes>

export const fetchVersionTranslationsRequest =
  (): IFetchVersionTranslationsRequest => ({
    type: TranslationsActionTypes.FETCH_VERSION_TRANSLATIONS_REQUEST
  })
export const fetchVersionTranslationsSuccess = (
  translationType: string,
  version: any = {}
): IFetchVersionTranslationsSuccess => ({
  type: TranslationsActionTypes.FETCH_VERSION_TRANSLATIONS_SUCCESS,
  payload: { translationType, version }
})
export const fetchGlobalTranslationsRequest =
  (): IFetchGlobalTranslationsRequest => ({
    type: TranslationsActionTypes.FETCH_GLOBAL_TRANSLATIONS_REQUEST
  })
export const fetchGlobalTranslationsSuccess = (
  globalResponse: any = {}
): IFetchGlobalTranslationsSuccess => ({
  type: TranslationsActionTypes.FETCH_GLOBAL_TRANSLATIONS_SUCCESS,
  payload: { globalResponse }
})

export const fetchAdsTranslationsRequest =
  (): IFetchAdsTranslationsRequest => ({
    type: TranslationsActionTypes.FETCH_ADS_TRANSLATIONS_REQUEST
  })
export const fetchAdsTranslationsSuccess = (
  adsResponse: any = {}
): IFetchAdsTranslationsSuccess => ({
  type: TranslationsActionTypes.FETCH_ADS_TRANSLATIONS_SUCCESS,
  payload: { adsResponse }
})

export const fetchPopupTranslationsRequest =
  (): IFetchPopupsTranslationsRequest => ({
    type: TranslationsActionTypes.FETCH_POPUPS_TRANSLATIONS_REQUEST
  })
export const fetchPopupTranslationsSuccess = (
  popupsResponse: any = {}
): IFetchPopupsTranslationsSuccess => ({
  type: TranslationsActionTypes.FETCH_POPUPS_TRANSLATIONS_SUCCESS,
  payload: { popupsResponse }
})

export const fetchFaqTranslationsRequest =
  (): IFetchFaqTranslationsRequest => ({
    type: TranslationsActionTypes.FETCH_FAQ_TRANSLATIONS_REQUEST
  })
export const fetchFaqTranslationsSuccess = (
  faqResponse: any = {}
): IFetchFaqTranslationsSuccess => ({
  type: TranslationsActionTypes.FETCH_FAQ_TRANSLATIONS_SUCCESS,
  payload: { faqResponse }
})
export const fetchRoadmapTranslationsRequest =
  (): IFetchRoadmapTranslationsRequest => ({
    type: TranslationsActionTypes.FETCH_ROADMAP_TRANSLATIONS_REQUEST
  })
export const fetchRoadmapTranslationsSuccess = (
  roadmapResponse: any = {}
): IFetchRoadmapTranslationsSuccess => ({
  type: TranslationsActionTypes.FETCH_ROADMAP_TRANSLATIONS_SUCCESS,
  payload: { roadmapResponse }
})
export const fetchIntroductionTranslationsRequest =
  (): IFetchIntroductionTranslationsRequest => ({
    type: TranslationsActionTypes.FETCH_INTRODUCTION_TRANSLATIONS_REQUEST
  })
export const fetchIntroductionTranslationsSuccess = (
  introductionResponse: any = {}
): IFetchIntroductionTranslationsSuccess => ({
  type: TranslationsActionTypes.FETCH_INTRODUCTION_TRANSLATIONS_SUCCESS,
  payload: { introductionResponse }
})
export const fetchTranslationsNotFound = (
  response: any = {}
): IFetchTranslationsNotFound => ({
  type: TranslationsActionTypes.FETCH_TRANSLATIONS_NOTFOUND
})

export const fetchTranslationsFailure = (): IFetchTranslationsFailure => ({
  type: TranslationsActionTypes.FETCH_TRANSLATIONS_FAILURE
})

export const fetchVersionTranslations = (
  translationType: string
): ThunkAction<Promise<void>, Store, unknown, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => Store
  ) => {
    dispatch(fetchGlobalTranslationsRequest())
    try {
      trackEvents('getVersionsTranslations', {})

      const apiUrl = `${Config.APIM_BASE_URL}translationsapi/gettranslations?language=version&type=${translationType}`

      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        return
      }

      const data = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${esToken}`
        }
      })

      if (!data || !data.ok) {
        dispatch(fetchTranslationsFailure())
        trackException(
          `Error in fetching version translations: ${translationType}`,
          new Error('Error in data object after fetching version translations')
        )
        return
      } else if (data.status === 404) {
        dispatch(fetchTranslationsNotFound())
        return
      }

      const result = await data.json()

      if (!result || !result.Documents) {
        dispatch(fetchTranslationsFailure())
        trackException(
          `Error in getting documents out of translations: ${translationType}`,
          new Error('Error in data object after fetching translations')
        )
        return
      }
      dispatch(
        fetchVersionTranslationsSuccess(
          translationType,
          result.Documents[0].value
        )
      )
    } catch (error) {
      dispatch(fetchTranslationsFailure())
      trackException(
        `Error in fetching version translations action: ${translationType}`,
        error
      )
    }
  }
}

export const fetchGlobalTranslations = (
  language: string
): ThunkAction<Promise<void>, Store, unknown, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => Store
  ) => {
    dispatch(fetchGlobalTranslationsRequest())
    try {
      trackEvents('getGlobalTranslations', {})

      const translations = getState() ? getState().translations : null
      const translationsVersion = translations
        ? translations.globalTranslationsVersion
        : '0'
      let result: any = readAndCleanUpLocalStorage(
        Constants.translationTypes.global,
        language,
        translationsVersion
      )

      if (result === null) {
        const apiUrl = `${Config.APIM_BASE_URL}translationsapi/gettranslations?language=${language}&type=${Constants.translationTypes.global}`

        // Get and check authentication token
        const aadInfo = AuthStore.selectors.getAADInfo(getState())
        const esToken = await renewAuthorizationToken(
          aadInfo.accessToken,
          aadInfo.instance,
          aadInfo.accounts
        )
        if (esToken !== aadInfo.accessToken) {
          AuthStore.actions.setAuthToken(esToken)
        }
        if (esToken === '') {
          return
        }

        const data = await fetch(apiUrl, {
          method: 'GET',
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
            Authorization: `Bearer ${esToken}`
          }
        })

        if (!data || !data.ok) {
          dispatch(fetchTranslationsFailure())
          trackException(
            'Error in fetching global translations',
            new Error('Error in data object after fetching global translations')
          )
          return
        } else if (data.status === 404) {
          dispatch(fetchTranslationsNotFound())
          return
        }

        const results = await data.json()

        if (!results || !results.Documents) {
          dispatch(fetchTranslationsFailure())
          trackException(
            'Error in getting documents out of translations',
            new Error('Error in data object after fetching translations')
          )
          return
        }

        result = []
        results.Documents.map((document: any) => {
          result.push(document)
          return ''
        })
      }

      updateLocalStorage(
        result,
        Constants.translationTypes.global,
        language,
        translationsVersion
      )

      const parsedResults: any = []
      result.map((document: any) => {
        parsedResults[document.key.replace("'", "'")] = document.value.replace(
          "'",
          "'"
        )
        return ''
      })

      dispatch(fetchGlobalTranslationsSuccess(parsedResults))
    } catch (error) {
      dispatch(fetchTranslationsFailure())
      trackException('Error in fetching global translations action', error)
    }
  }
}

export const fetchAdsTranslations = (
  searchQuery: string,
  country: string,
  func: string,
  language: string,
  datasource: string
): ThunkAction<Promise<void>, Store, unknown, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => Store
  ) => {
    dispatch(fetchAdsTranslationsRequest())
    try {
      trackEvents('getAdsTranslations', {})

      let datasourceToQuery = datasource
      if (datasource === 'knowledgeexchange') {
        datasourceToQuery = 'knowledgeexchange_open'
      } else if (datasource === 'knowledgeexchangecatalog') {
        datasourceToQuery = 'knowledgeexchange_catalog'
      }

      let apiUrl = `${
        Config.APIM_BASE_URL
      }searchapi/getadwords?query=${encodeURIComponent(
        latinize(searchQuery)
      )}&language=${language}&datasource=${datasourceToQuery}&origin=KPMGFind&top=1`

      if (country && country !== '') apiUrl += `&country=${country}`

      if (func && func !== '') apiUrl += `&function=${func}`

      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        return
      }

      const data = await FetchWithCache(apiUrl, {
        method: 'GET',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${esToken}`
        }
      })

      if (!data || (data && data.hasError)) {
        dispatch(fetchTranslationsFailure())
        trackException(
          'Error in fetching ads translations',
          new Error('Error in data object after fetching ads translations')
        )
        return Promise.resolve()
      }

      const results = data.responseJSON

      if (!results || !results.value) {
        dispatch(fetchTranslationsFailure())
        trackException(
          'Error in getting documents out of ads translations',
          new Error('Error in data object after fetching ads translations')
        )
        return Promise.resolve()
      }

      const result: AdResult[] = []
      results.value.map((ad: AdResult) => {
        result.push(ad)
        return Promise.resolve()
      })

      dispatch(fetchAdsTranslationsSuccess(result))
    } catch (error) {
      dispatch(fetchTranslationsFailure())
      trackException('Error in fetching ads translations action', error)
    }
  }
}

export const fetchPopupTranslations = (
  language: string
): ThunkAction<Promise<void>, Store, unknown, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => Store
  ) => {
    dispatch(fetchPopupTranslationsRequest())
    try {
      trackEvents('getPopupsTranslations', {})

      const translations = getState() ? getState().translations : null
      const translationsVersion = translations
        ? translations.popupTranslationsVersion
        : '0'
      let result: any = readAndCleanUpLocalStorage(
        Constants.translationTypes.popups,
        language,
        translationsVersion
      )

      if (result === null) {
        const apiUrl = `${Config.APIM_BASE_URL}translationsapi/gettranslations?language=${language}&type=${Constants.translationTypes.popups}&scope=KPMGFind`

        // Get and check authentication token
        const aadInfo = AuthStore.selectors.getAADInfo(getState())
        const esToken = await renewAuthorizationToken(
          aadInfo.accessToken,
          aadInfo.instance,
          aadInfo.accounts
        )
        if (esToken !== aadInfo.accessToken) {
          AuthStore.actions.setAuthToken(esToken)
        }
        if (esToken === '') {
          return
        }

        const data = await fetch(apiUrl, {
          method: 'GET',
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
            Authorization: `Bearer ${esToken}`
          }
        })

        if (!data || !data.ok) {
          dispatch(fetchTranslationsFailure())
          trackException(
            'Error in fetching popups translations',
            new Error('Error in data object after fetching popups translations')
          )
          return Promise.resolve()
        } else if (data.status === 404) {
          dispatch(fetchTranslationsNotFound())
          return Promise.resolve()
        }

        const results = await data.json()

        if (!results || !results.Documents) {
          dispatch(fetchTranslationsFailure())
          trackException(
            'Error in getting documents out of popups translations',
            new Error('Error in data object after fetching popups translations')
          )
          return Promise.resolve()
        }

        result = []
        results.Documents.map((document: PopupResult) => {
          result.push(document)
          return Promise.resolve()
        })
      }

      updateLocalStorage(
        result,
        Constants.translationTypes.popups,
        language,
        translationsVersion
      )

      dispatch(fetchPopupTranslationsSuccess(result))
    } catch (error) {
      dispatch(fetchTranslationsFailure())
      trackException('Error in fetching popup translations action', error)
    }
  }
}

export const fetchFaqTranslations = (
  language: string
): ThunkAction<Promise<void>, Store, unknown, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => Store
  ) => {
    dispatch(fetchFaqTranslationsRequest())
    try {
      trackEvents('getFaqTranslations', {})

      const translations = getState() ? getState().translations : null
      const translationsVersion = translations
        ? translations.faqTranslationsVersion
        : '0'
      let faqItems: any = readAndCleanUpLocalStorage(
        Constants.translationTypes.faq,
        language,
        translationsVersion
      )

      if (faqItems === null) {
        const apiUrl = `${Config.APIM_BASE_URL}translationsapi/gettranslations?language=${language}&type=${Constants.translationTypes.faq}`

        // Get and check authentication token
        const aadInfo = AuthStore.selectors.getAADInfo(getState())
        const esToken = await renewAuthorizationToken(
          aadInfo.accessToken,
          aadInfo.instance,
          aadInfo.accounts
        )
        if (esToken !== aadInfo.accessToken) {
          AuthStore.actions.setAuthToken(esToken)
        }
        if (esToken === '') {
          return
        }

        const data = await fetch(apiUrl, {
          method: 'GET',
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
            Authorization: `Bearer ${esToken}`
          }
        })

        if (!data || !data.ok) {
          dispatch(fetchTranslationsFailure())
          trackException(
            'Error in fetching faq translations',
            new Error('Error in data object after fetching faq translations')
          )
          return
        } else if (data.status === 404) {
          dispatch(fetchTranslationsNotFound())
          return
        }

        const result = await data.json()

        if (!result || !result.Documents) {
          dispatch(fetchTranslationsFailure())
          trackException(
            'Error in getting documents out of translations',
            new Error('Error in data object after fetching translations')
          )
          return
        }

        faqItems = []
        result.Documents.map((document: any) => {
          faqItems.push(document)
          return ''
        })
      }

      updateLocalStorage(
        faqItems,
        Constants.translationTypes.faq,
        language,
        translationsVersion
      )
      dispatch(fetchFaqTranslationsSuccess(faqItems))
    } catch (error) {
      dispatch(fetchTranslationsFailure())
      trackException('Error in fetching faq translations action', error)
    }
  }
}

export const fetchRoadmapTranslations = (
  language: string
): ThunkAction<Promise<void>, Store, unknown, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => Store
  ) => {
    dispatch(fetchRoadmapTranslationsRequest())
    try {
      trackEvents('getRoadmapTranslations', {})

      const translations = getState() ? getState().translations : null
      const translationsVersion = translations
        ? translations.roadmapTranslationsVersion
        : '0'
      let roadmapItems: any = readAndCleanUpLocalStorage(
        Constants.translationTypes.roadmap,
        language,
        translationsVersion
      )

      if (roadmapItems === null) {
        const apiUrl = `${Config.APIM_BASE_URL}translationsapi/gettranslations?language=${language}&type=${Constants.translationTypes.roadmap}`

        // Get and check authentication token
        const aadInfo = AuthStore.selectors.getAADInfo(getState())
        const esToken = await renewAuthorizationToken(
          aadInfo.accessToken,
          aadInfo.instance,
          aadInfo.accounts
        )
        if (esToken !== aadInfo.accessToken) {
          AuthStore.actions.setAuthToken(esToken)
        }
        if (esToken === '') {
          return
        }

        const data = await fetch(apiUrl, {
          method: 'GET',
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
            Authorization: `Bearer ${esToken}`
          }
        })

        if (!data || !data.ok) {
          dispatch(fetchTranslationsFailure())
          trackException(
            'Error in fetching roadmap translations',
            new Error(
              'Error in data object after fetching roadmap translations'
            )
          )
          return
        } else if (data.status === 404) {
          dispatch(fetchTranslationsNotFound())
          return
        }

        const result = await data.json()

        if (!result || !result.Documents) {
          dispatch(fetchTranslationsFailure())
          trackException(
            'Error in getting documents out of translations',
            new Error('Error in data object after fetching translations')
          )
          return
        }

        roadmapItems = []

        result.Documents.map((document: any) => {
          roadmapItems.push(document)
          return ''
        })
      }

      updateLocalStorage(
        roadmapItems,
        Constants.translationTypes.roadmap,
        language,
        translationsVersion
      )
      dispatch(fetchRoadmapTranslationsSuccess(roadmapItems))
    } catch (error) {
      dispatch(fetchTranslationsFailure())
      trackException('Error in fetching roadmap translations action', error)
    }
  }
}

export const fetchIntroductionTranslations = (
  language: string
): ThunkAction<Promise<void>, Store, unknown, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => Store
  ) => {
    dispatch(fetchIntroductionTranslationsRequest())
    try {
      trackEvents('getIntroductionTranslations', {})

      const translations = getState() ? getState().translations : null
      const translationsVersion = translations
        ? translations.introductionTranslationsVersion
        : '0'
      let introItems: any = readAndCleanUpLocalStorage(
        Constants.translationTypes.introduction,
        language,
        translationsVersion
      )

      if (introItems === null) {
        const apiUrl = `${Config.APIM_BASE_URL}translationsapi/gettranslations?language=${language}&type=${Constants.translationTypes.introduction}`

        // Get and check authentication token
        const aadInfo = AuthStore.selectors.getAADInfo(getState())
        const esToken = await renewAuthorizationToken(
          aadInfo.accessToken,
          aadInfo.instance,
          aadInfo.accounts
        )
        if (esToken !== aadInfo.accessToken) {
          AuthStore.actions.setAuthToken(esToken)
        }
        if (esToken === '') {
          return
        }

        const data = await fetch(apiUrl, {
          method: 'GET',
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
            Authorization: `Bearer ${esToken}`
          }
        })

        if (!data || !data.ok) {
          dispatch(fetchTranslationsFailure())
          trackException(
            'Error in fetching introduction translations',
            new Error(
              'Error in data object after fetching introduction translations'
            )
          )
          return
        } else if (data.status === 404) {
          dispatch(fetchTranslationsNotFound())
          return
        }

        const result = await data.json()

        if (!result || !result.Documents) {
          dispatch(fetchTranslationsFailure())
          trackException(
            'Error in getting documents out of translations',
            new Error('Error in data object after fetching translations')
          )
          return
        }

        introItems = []

        result.Documents.map((document: any) => {
          introItems.push(document)
          return ''
        })
      }

      updateLocalStorage(
        introItems,
        Constants.translationTypes.introduction,
        language,
        translationsVersion
      )
      dispatch(fetchIntroductionTranslationsSuccess(introItems))
    } catch (error) {
      dispatch(fetchTranslationsFailure())
      trackException(
        'Error in fetching introduction translations action',
        error
      )
    }
  }
}

const readAndCleanUpLocalStorage = (
  translationType: string,
  language: string,
  version: string
): void => {
  let result: any = null
  try {
    const translationsVersionFromLS = localStorage
      ? localStorage.getItem(translationType + '-Version')
      : ''
    const translationsFromLS = localStorage
      ? localStorage.getItem(translationType + '-' + language)
      : ''

    if (
      translationsVersionFromLS &&
      translationsVersionFromLS !== '' &&
      translationsFromLS &&
      translationsFromLS !== ''
    ) {
      if (version === translationsVersionFromLS) {
        result = JSON.parse(translationsFromLS)
      } else if (
        translationsVersionFromLS &&
        translationsVersionFromLS !== version
      ) {
        // In this case the version has changed and all existing translations for this type are not valid,
        // so remove translations for this type in other languages.
        // If this is done, the translations will be fetched on the next language change
        supportedLanguages.forEach((supportedLanguage: SupportedLanguage) => {
          if (supportedLanguage.locale !== language) {
            localStorage.removeItem(
              translationType + '-' + supportedLanguage.locale
            )
          }
        })
      }
    }
  } catch (e) {
    result = null
  }
  return result
}

const updateLocalStorage = (
  items: any[],
  translationType: string,
  language: string,
  version: string
): void => {
  localStorage.setItem(translationType + '-' + language, JSON.stringify(items))
  localStorage.setItem(translationType + '-Version', version)
}

export type TranslationsActions =
  | IFetchVersionTranslationsSuccess
  | IFetchGlobalTranslationsSuccess
  | IFetchAdsTranslationsSuccess
  | IFetchPopupsTranslationsSuccess
  | IFetchFaqTranslationsSuccess
  | IFetchRoadmapTranslationsSuccess
  | IFetchIntroductionTranslationsSuccess
  | IFetchTranslationsFailure
  | IFetchTranslationsNotFound
