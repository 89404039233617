import React from 'react'
import * as Config from 'config'
import { ResultLeftWidgetGenerator } from './interfaces'
import LeftEngagementsWidget from 'components/contents/resultpages/widgets/LeftEngagementsWidget'
import { stripHtml } from 'utils/string'
import { trackException } from 'utils/tracking'
import { FetchWithCache } from 'utils/api'
import { IDeviceSetting } from 'utils/deviceSettings'
import { IUserSetting } from 'utils/userSettings'
import { IFindConfiguration } from 'store/Settings/reducers'
import {
  engagementsDefaultQuery,
  IOneIntranetResultQuery,
  removeStopWordsFromQuery
} from 'utils/oneIntranet'
import { getSPOnlineToken, renewAuthorizationToken } from 'utils/token'
import { IEngagementResult } from 'components/models/EngagementResult'
import { IResult } from 'store/ResultsEngagements/reducers'
import { IAADState } from 'store/Auth/reducers'

export const leftEngagementsWidgetGenerator: ResultLeftWidgetGenerator = {
  fetch(
    aadInfo: IAADState,
    searchQuery: string,
    deviceSettings: IDeviceSetting,
    userSettings: IUserSetting,
    findConfiguration: IFindConfiguration,
    useCognitiveSearch: boolean
  ): Promise<IResult> {
    return new Promise<IResult>((resolve, reject) => {
      const fetchEngagements = async () => {
        if (!searchQuery || searchQuery === '' || searchQuery === '""') {
          trackException(
            'Error in fetching Engagements left widget in leftEngagmementsWidget.tsx',
            new Error('Empty query. Please specify a valid query text')
          )
          reject()
          return
        }

        let spoToken = ''
        if (Config.LOCAL_DEVELOPMENT.toString() === 'false') {
          spoToken = await getSPOnlineToken(aadInfo.instance, aadInfo.accounts)
          if (spoToken === '') {
            throw new Error(
              'Authentication: Cannot renew SPO authentication token'
            )
          }
        }

        const apiUrl = `${Config.APIM_BASE_URL}oneintranetapi/postsearchoi`

        const requestBody: IOneIntranetResultQuery = Object.assign(
          {},
          engagementsDefaultQuery
        )
        requestBody.querytext = searchQuery

        requestBody.rowlimit = 10
        requestBody.rowsperpage = 10
        requestBody.querytext = searchQuery
        requestBody.cognitiveEnabled = false
        requestBody.origin = 'engagementsall'
        requestBody.processFeaturedResults = false
        requestBody.selectproperties = 'Title,Write,Path,Description'

        if (useCognitiveSearch && findConfiguration.CognitiveSearchEnabled) {
          // Get and check authentication token
          const esToken = await renewAuthorizationToken(
            aadInfo.accessToken,
            aadInfo.instance,
            aadInfo.accounts
          )
          if (esToken !== '') {
            let searchQueryLanguage = ''
            const detectLanguageApiUrl = `${
              Config.APIM_BASE_URL
            }searchapi/detectlanguage?searchQuery=${encodeURIComponent(
              searchQuery
            )}`

            const languageResponse = await FetchWithCache(
              detectLanguageApiUrl,
              {
                method: 'POST',
                headers: {
                  accept: 'application/json',
                  'content-type': 'application/json',
                  'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
                  Authorization: `Bearer ${esToken}`
                }
              }
            )
            if (
              languageResponse &&
              !languageResponse.hasError &&
              languageResponse.responseJSON
            ) {
              const languageJSON = languageResponse.responseJSON
              if (languageJSON && languageJSON.responseCode === 200) {
                searchQueryLanguage = languageJSON.language
              }
            }
            requestBody.querytext = removeStopWordsFromQuery(
              searchQuery,
              searchQueryLanguage,
              useCognitiveSearch,
              findConfiguration.CognitiveSearchEnabled
            )
            requestBody.cognitiveEnabled = true
          }
        }

        const body = JSON.stringify(requestBody)

        const response = await FetchWithCache(
          apiUrl,
          {
            method: 'POST',
            headers: {
              'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
              'content-type': 'application/json',
              Authorization: `Bearer ${spoToken}`
            },
            body: body
          },
          undefined,
          true,
          'widget_engagements',
          undefined,
          searchQuery
        )

        let results
        if (!response || response.hasError || !response.responseJSON) {
          if (
            !response ||
            !response.errorResponse ||
            response.errorResponse.responseCode !== 499
          ) {
            trackException(
              'Error in fetching Engagements left widget in leftEngagmementsWidget.tsx',
              new Error(
                response && response.errorResponse
                  ? response.errorResponse.responseCode +
                    ':' +
                    response.errorResponse.message
                  : ''
              )
            )
          }
          reject()
          return
        } else {
          results = response.responseJSON
        }

        const primaryResults =
          results && results.QueryResults
            ? results.QueryResults.slice(0, 10)
            : []

        if (primaryResults.length < 3) {
          reject()
          return
        }

        const synonymsApplied = results.SynonymsApplied
          ? results.SynonymsApplied
          : []

        const engagementResults: IEngagementResult[] = []

        results.QueryResults.forEach((i: any) => {
          const engagementResult: IEngagementResult = {
            Body: i.Description ? stripHtml(i.Description) : '',
            Title: i.Title ? stripHtml(i.Title) : '',
            Path: i.path ? i.path : '',
            Date: i.Write ? i.Write : '',
            SiteTitle: i.SiteTitle ? i.SiteTitle : ''
          }

          engagementResults.push(engagementResult)
        })

        const resultsList = {
          queryResults: engagementResults,
          synonymsApplied: synonymsApplied
        }

        resolve(resultsList)
      }

      try {
        fetchEngagements()
      } catch (error) {
        trackException(
          'Error in fetching Engagements left widget in leftEngagmementsWidget.tsx',
          error
        )
        reject()
      }
    })
  },
  generate(results: IResult, searchQuery: string): Promise<React.ReactElement> {
    return new Promise<React.ReactElement>((resolve, reject) => {
      const fetchEngagements = async () => {
        let hasContent = false
        for (let i = 0; i < results.queryResults.length; i++) {
          if (results.queryResults[i].Title !== '') {
            hasContent = true
            break
          }
        }
        if (!hasContent) {
          reject()
          return
        }

        const limit = 10
        if (results.queryResults.length > 2) {
          let resultsList = results

          if (results.queryResults.length > limit) {
            resultsList = {
              queryResults: results.queryResults.slice(0, limit),
              synonymsApplied: results.synonymsApplied
            }
          }

          resolve(
            <LeftEngagementsWidget query={searchQuery} results={resultsList} />
          )
        } else {
          reject()
          return
        }
      }

      try {
        fetchEngagements()
      } catch (error) {
        trackException(
          'Error in generating Engagements left widget in leftEngagmementsWidget.tsx',
          error
        )
        reject()
      }
    })
  }
}
