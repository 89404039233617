import React, { useState } from 'react'
import SettingsStore from 'store/Settings'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { Store } from 'store'
import { Box, Grid } from '@mui/material'
import Link from '@mui/material/Link'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp'
import UserSettingsStore from 'store/UserSettings'
import highlightText, { prepareHighlightWords } from 'utils/highlightText'
import { trackWidgetShown, trackRightWidgetClick } from 'utils/tracking'
import { removeQueryParam } from 'utils/queryParams'
import { dateFormatOptions } from 'constants/constants'
import { WidgetKey } from 'constants/widgets'
import { getStylesRightInfographicsWidget } from 'styles/contents/resultpages/widgets/RightInfographicsWidget'
import { getStylesRightWidget } from 'styles/contents/resultpages/widgets/RightWidget'
import { useFilter } from 'utils/filters'
import createDOMPurify from 'dompurify'
import TooltipTitle from 'components/contents/common/TooltipTitle'
import LinesEllipsis from 'react-lines-ellipsis'
import HTMLEllipsis from 'react-lines-ellipsis/lib/html'
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'
import { IInfographicsResult } from 'components/models/InfographicsResult'

export interface RightInfographicsWidgetProps {
  searchQuery: any
  infographicResults: IInfographicsResult[]
}

type AllProps = ReturnType<typeof mapStateToProps> &
  RightInfographicsWidgetProps

function RightInfographicsWidget(props: AllProps): JSX.Element {
  const { searchQuery, infographicResults, deviceSettings } = props

  const DOMPurify = createDOMPurify(window)

  const navigateFunction = useNavigate()
  const rightWidgetClasses = getStylesRightWidget()
  const classes = getStylesRightInfographicsWidget()

  const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis)
  const ResponsiveHTMLEllipsis = responsiveHOC()(HTMLEllipsis)

  const [itemsShown, setItemsShown] = useState(3)
  const [currentPage] = useFilter('page', '1')

  const showTen = () => {
    setItemsShown(10)
  }

  const showThree = () => {
    setItemsShown(3)
  }

  React.useEffect(() => {
    showThree()
  }, [currentPage])

  React.useEffect(() => {
    trackWidgetShown({
      widgetType: WidgetKey.infographics,
      widgetPane: 'right'
    })
  }, [])

  const intl = useIntl()

  const headline = intl.formatMessage({
    id: 'infographics_on',
    defaultMessage: 'Related Infographics'
  })
  return (
    <Box
      id={'con-widget-right-infographics'}
      className={
        infographicResults.length > 3
          ? rightWidgetClasses.widgetAreaExtend
          : rightWidgetClasses.widgetArea
      }
    >
      <h2 className={rightWidgetClasses.widgetHeadlineH2}>
        <ResponsiveEllipsis
          className={rightWidgetClasses.widgetHeadline}
          basedOn="words"
          text={headline}
          maxLine="2"
        />
      </h2>
      {infographicResults
        .slice(0, itemsShown)
        .map((item: IInfographicsResult, index: number) => (
          <Box key={'rig' + index} className={classes.widgetText}>
            <Link
              underline={'hover'}
              key={'rigl1' + index}
              onClick={() => {
                removeQueryParam(navigateFunction, 'cntx')
                trackRightWidgetClick({
                  title: DOMPurify.sanitize(item.title),
                  url: DOMPurify.sanitize(item.url),
                  index,
                  widgetKey: WidgetKey.infographics,
                  body: DOMPurify.sanitize(item.content)
                })
              }}
              {...(!deviceSettings.isMobile
                ? {
                    onAuxClick: (event: any) => {
                      removeQueryParam(navigateFunction, 'cntx')
                      trackRightWidgetClick({
                        title: DOMPurify.sanitize(item.title),
                        url: DOMPurify.sanitize(item.url),
                        index,
                        widgetKey: WidgetKey.infographics,
                        body: DOMPurify.sanitize(item.content),
                        wasRightClicked: event?.button === 2 ? true : false
                      })
                    }
                  }
                : {})}
              href={DOMPurify.sanitize(item.url)}
              data-node-title={DOMPurify.sanitize(item.title)}
              data-node-index={index}
              data-node-click-type="rightWidgetClick"
              data-node-widget-key={WidgetKey.infographics}
              {...(deviceSettings.openLinksInNewTab === true
                ? { target: '_blank', rel: 'noreferrer' }
                : {})}
            >
              <TooltipTitle
                title={DOMPurify.sanitize(item.title?.trim())}
                key={'rigd1' + index}
                singleLine={true}
                additionalClass={`${rightWidgetClasses.widgetTitle} ${classes.widgetTitle}`}
              />
            </Link>
            <ResponsiveHTMLEllipsis
              className={`${rightWidgetClasses.widgetDescription} ${rightWidgetClasses.widgetDescriptionExtended} ${classes.widgetDescription}`}
              basedOn="words"
              unsafeHTML={highlightText(
                DOMPurify.sanitize(item.content?.trim()),
                prepareHighlightWords(
                  DOMPurify.sanitize(searchQuery),
                  DOMPurify.sanitize(item.content?.trim()),
                  []
                ),
                true
              )}
              maxLine="2"
            />
            <ResponsiveEllipsis
              className={rightWidgetClasses.widgetFooter}
              basedOn="words"
              text={DOMPurify.sanitize(
                item.date ? intl.formatDate(item.date, dateFormatOptions) : ''
              )}
              maxLine="1"
            />
          </Box>
        ))}
      {infographicResults.length > 3 && (
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          className={rightWidgetClasses.footerGrid}
        >
          {itemsShown === 3 && (
            <Grid
              item
              id={'btn-right-widget-down-01'}
              className={rightWidgetClasses.arrowContainer}
              tabIndex={0}
              onClick={showTen}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  showTen()
                }
              }}
            >
              <KeyboardArrowDown className={rightWidgetClasses.arrowItem} />
            </Grid>
          )}
          {itemsShown === 10 && (
            <Grid
              item
              id={'btn-right-widget-up-01'}
              className={rightWidgetClasses.arrowContainerExpanded}
              tabIndex={0}
              onClick={showThree}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  showThree()
                }
              }}
            >
              <KeyboardArrowUp className={rightWidgetClasses.arrowItem} />
            </Grid>
          )}
        </Grid>
      )}
    </Box>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    userSettings: UserSettingsStore.selectors.getUserSettings(state),
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state),
    currentDataSources: SettingsStore.selectors.getCurrentDataSources(state)
  }
}

export default connect(mapStateToProps)(RightInfographicsWidget)
