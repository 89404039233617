import React, {
  Dispatch,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import { connect } from 'react-redux'
import { useIntl } from 'react-intl'
import ReactPlaceholder from 'react-placeholder'
import { Box, Fade } from '@mui/material'
import 'Placeholder.css'
import { Store } from 'store'
import ResultMetaDataStore from 'store/ResultMetaData'
import ResultsMppStore from 'store/ResultsMpp'
import UserSettingsStore from 'store/UserSettings'
import SettingsStore from 'store/Settings'
import PageStay from 'components/HOC/PageStay'
import ErrorPage from 'components/contents/common/ErrorPage'
import RightTopWidgetContainer from 'components/contents/resultpages/widgetscontainer/RightTopWidgetContainer'
import Pagination from '../Pagination'
import { trackException } from 'utils/tracking'
import { useReactRouterQueryStringInterface } from 'utils/useQueryState'
import { authorizeResultPage } from 'utils/authorization'
import createDOMPurify from 'dompurify'
import { dataSourcesTabs } from 'constants/constants'
import SearchSuggestions from 'components/contents/common/SearchSuggestions'
import FeaturedResults from './FeaturedResults'
import LeftWidgetContainer from './widgetscontainer/LeftWidgetContainer'
import RightWidgetContainer from './widgetscontainer/RightWidgetContainer'
import { getStylesResults } from 'styles/contents/resultpages/Results'
import { useFilter } from 'utils/filters'
import { MPPResult } from '../results/MPPResult'
import ResultsMppMobile from './ResultsMppMobile'
import { KPMGFindGlobalVariables } from 'store/KPMGFindGlobalVariables'
import { CognitiveMessage } from '../common/CognitiveMessage'
import { IUserSetting } from 'utils/userSettings'
import { useLocation } from 'react-router-dom'

export interface ResultsEngagementsProps {}

type AllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  ResultsEngagementsProps

function ResultsMpp(props: AllProps) {
  const {
    featuredResults,
    results,
    resultsCount,
    hasResultsBeenFetched,
    hasErrorStore,
    userSettings,
    deviceSettings,
    findConfiguration,
    setUserSettings,
    synonymsApplied,
    useCognitiveSearch
  } = props
  const DOMPurify = createDOMPurify(window)
  const resultClasses = getStylesResults()
  const { getQueryString } = useReactRouterQueryStringInterface()
  const intl = useIntl()

  const queryStringFromUrl = getQueryString()

  const [currentPage] = useFilter('page', '1')
  const [searchQuery] = useFilter('q')
  const location = useLocation()

  const [cognitiveSearchEnabled, setCognitiveSearchEnabled] =
    useState(useCognitiveSearch)
  const [showCognitiveMessage, setShowCognitiveMessage] = useState(
    userSettings.ShowCognitiveMessage
  )

  const [initialItemClick, setInitialItemClick] = useState(true)

  useEffect(() => {
    KPMGFindGlobalVariables.setCurrentTab(dataSourcesTabs.kpmg_mpp)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setup = useCallback(() => {
    try {
      authorizeResultPage(
        'KPMG MPP',
        userSettings,
        deviceSettings,
        DOMPurify.sanitize(location && location.search ? location.search : '')
      )
    } catch (error) {
      trackException('Error in fetching results in ResultsMPP.tsx', error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryStringFromUrl])

  useEffect(() => {
    setup()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryStringFromUrl])

  useEffect(
    () => {
      setCognitiveSearchEnabled(useCognitiveSearch)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [useCognitiveSearch]
  )

  useEffect(
    () => {
      setShowCognitiveMessage(userSettings.ShowCognitiveMessage)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userSettings.ShowCognitiveMessage]
  )

  const returnAllItems = (type: string) => {
    if (!results || results.length < 1) {
      return []
    }
    const items = []

    try {
      const firstResults = results.length < 4 ? results.length : 3

      if (type === 'first') {
        for (let i = 0; i < firstResults; i++) {
          items.push(
            <MPPResult
              title={DOMPurify.sanitize(results[i].title)}
              body={DOMPurify.sanitize(results[i].fragment)}
              url={DOMPurify.sanitize(results[i].url)}
              owner={DOMPurify.sanitize(results[i].owner)}
              key={i}
              index={i}
              searchQuery={DOMPurify.sanitize(searchQuery)}
              deviceSettings={deviceSettings}
              serviceLines={results[i].additional}
              manualName={DOMPurify.sanitize(results[i].manualTitle)}
              synonymsApplied={synonymsApplied}
              initialItemClick={initialItemClick}
              setInitialItemClick={setInitialItemClick}
            />
          )
        }
      } else {
        for (let i = firstResults; i < results.length; i++) {
          items.push(
            <MPPResult
              title={DOMPurify.sanitize(results[i].title)}
              body={DOMPurify.sanitize(results[i].fragment)}
              url={DOMPurify.sanitize(results[i].url)}
              owner={DOMPurify.sanitize(results[i].owner)}
              key={i}
              index={i}
              searchQuery={DOMPurify.sanitize(searchQuery)}
              deviceSettings={deviceSettings}
              serviceLines={results[i].additional}
              manualName={DOMPurify.sanitize(results[i].manualTitle)}
              synonymsApplied={synonymsApplied}
              initialItemClick={initialItemClick}
              setInitialItemClick={setInitialItemClick}
            />
          )
        }
      }
    } catch (error) {
      trackException('Error returning all items in ResultsMPP.tsx', error)
    }
    return items
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const firstItems = useMemo(() => returnAllItems('first'), [results, intl])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const restItems = useMemo(() => returnAllItems('rest'), [results, intl])

  return (
    <Box>
      {!hasErrorStore && !!searchQuery && (
        <>
          {deviceSettings.renderMobile ? (
            <ResultsMppMobile
              searchQuery={searchQuery}
              deviceSettings={deviceSettings}
              userSettings={userSettings}
              findConfigCognitiveSearchEnabled={
                findConfiguration.CognitiveSearchEnabled
              }
              cognitiveSearchEnabled={cognitiveSearchEnabled}
              showCognitiveMessage={showCognitiveMessage}
              setShowCognitiveMessage={setShowCognitiveMessage}
              setUserSettings={setUserSettings}
              initialItemClick={initialItemClick}
              setInitialItemClick={setInitialItemClick}
            />
          ) : (
            <Box className={resultClasses.container}>
              <Box
                id={'con-resultvertical-01'}
                className={resultClasses.resultsContainer}
              >
                <SearchSuggestions
                  wildCardActive={false}
                  resultCount={resultsCount}
                  resultsFetchend={hasResultsBeenFetched}
                />

                <ReactPlaceholder
                  ready={hasResultsBeenFetched ? hasResultsBeenFetched : false}
                  type="text"
                  rows={4}
                  showLoadingAnimation={true}
                  className={resultClasses.placeholder}
                >
                  <Fade
                    in={hasResultsBeenFetched ? hasResultsBeenFetched : false}
                    timeout={600}
                  >
                    <Box id={'con-resultvertical-section-01'}>
                      {cognitiveSearchEnabled &&
                        showCognitiveMessage &&
                        findConfiguration.CognitiveSearchEnabled &&
                        synonymsApplied &&
                        synonymsApplied.length > 0 && (
                          <CognitiveMessage
                            synonymsApplied={synonymsApplied}
                            setShowCognitiveMessage={setShowCognitiveMessage}
                            userSettings={userSettings}
                            setUserSettings={setUserSettings}
                          />
                        )}
                      {results && resultsCount > 0 && (
                        <FeaturedResults featuredResults={featuredResults} />
                      )}
                      {firstItems}
                    </Box>
                  </Fade>
                </ReactPlaceholder>
                {Number(currentPage) === 1 && (
                  <LeftWidgetContainer
                    resultCount={resultsCount}
                    hasResultsBeenFetched={hasResultsBeenFetched}
                  />
                )}
                <ReactPlaceholder
                  ready={hasResultsBeenFetched}
                  type="text"
                  rows={4}
                  showLoadingAnimation={true}
                  className={resultClasses.placeholder}
                >
                  <Fade in={hasResultsBeenFetched} timeout={600}>
                    <Box id={'con-resultvertical-section-02'}>
                      {restItems}
                      {resultsCount != null && resultsCount > 0 && (
                        <Pagination
                          {...props}
                          totalRecords={resultsCount}
                          pageLimit={20}
                          pageNeighbours={4}
                        />
                      )}
                    </Box>
                  </Fade>
                </ReactPlaceholder>
                <ReactPlaceholder
                  ready={hasResultsBeenFetched ? hasResultsBeenFetched : false}
                  type="text"
                  rows={4}
                  showLoadingAnimation={true}
                  className={resultClasses.placeholder}
                >
                  <Box />
                </ReactPlaceholder>
                <ReactPlaceholder
                  ready={hasResultsBeenFetched ? hasResultsBeenFetched : false}
                  type="text"
                  rows={4}
                  showLoadingAnimation={true}
                  className={resultClasses.placeholder}
                >
                  <Box />
                </ReactPlaceholder>
              </Box>
              <Box className={resultClasses.advertisementContainer}>
                <RightTopWidgetContainer
                  searchQuery={searchQuery}
                  scope={'mpp'}
                />
                <RightWidgetContainer />
              </Box>
            </Box>
          )}
        </>
      )}
      {hasErrorStore && <ErrorPage dataSource="kpmg mpp" />}
    </Box>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    featuredResults: ResultsMppStore.selectors.getFeaturedResults(state),
    synonymsApplied: ResultsMppStore.selectors.getSynonymsApplied(state),
    results: ResultsMppStore.selectors.getResults(state),
    resultsCount: ResultsMppStore.selectors.getResultCount(state),
    executionTime: ResultsMppStore.selectors.getExecutionTime(state),
    hasResultsBeenFetched:
      ResultsMppStore.selectors.hasResultsBeenFetched(state),
    hasErrorStore: ResultMetaDataStore.selectors.getDataSourceHasError(
      state,
      'kpmg mpp'
    ),
    currentPage: ResultsMppStore.selectors.getCurrentPage(state),
    userSettings: UserSettingsStore.selectors.getUserSettings(state),
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state),
    findConfiguration: SettingsStore.selectors.getFindConfiguration(state),
    useCognitiveSearch: SettingsStore.selectors.getUseCognitiveSearch(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    setUserSettings: (userSettings: IUserSetting) =>
      dispatch(UserSettingsStore.actions.upSertUserSettings(userSettings))
  }
}

export default PageStay(
  connect(mapStateToProps, mapDispatchToProps)(ResultsMpp)
)
