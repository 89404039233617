import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesRightKPMGWebsitesWidget(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles(): any {
  return makeStyles((theme: Theme) => ({
    widgetContent: {
      display: 'flex',
      flexDirection: 'row'
    },
    widgetText: {
      width: 'calc(100% - 82px)'
    },
    widgetTitle: {
      lineHeight: '20px'
    },
    widgetImage: {
      width: 70,
      height: 90,
      objectFit: 'cover',
      backgroundSize: 'cover !important',
      backgroundRepeat: 'no-repeat !important',
      backgroundPosition: 'center !important',
      cursor: 'pointer',
      border: 0,
      marginBottom: 12
    }
  }))
}
