import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesRightIntranetWidget(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles(): any {
  return makeStyles((theme: Theme) => ({
    title: {
      textAlign: 'left',
      fontSize: '20px',
      paddingTop: '4px',
      marginBottom: '3px',
      color: '#004e98',
      width: '100%',
      [`${theme.breakpoints.down(1024)}`]: {
        fontSize: '18px',
        lineHeight: '20px',
        marginBottom: '12px',
        fontWeight: '400',
        fontFamility: 'Roboto, HelveticaNeue, Arial, sans-serif'
      }
    },
    body: {
      fontSize: '14px',
      lineHeight: '20px',
      wordWrap: 'break-word', // older browsers
      overflowWrap: 'break-word', // css3,
      color: '#3c4043',
      marginBottom: '16px'
    }
  }))
}
