import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesFilterMenuPeopleSearch(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles() {
  return makeStyles((theme: Theme) => ({
    menuTopBar: {
      flex: 1,
      display: 'flex',
      height: '43px',
      flexDirection: 'row',
      justifyContent: 'space-between',
      borderBottom: '1px solid #e0e0e0',
      position: 'sticky',
      top: 0,
      zIndex: 205,
      backgroundColor: '#fff'
    },
    divider: {
      margin: '0 .75em'
    },
    seperator: {
      borderLeft: '1px solid #d0d0d0',
      display: 'inline-block',
      verticalAlign: 'baseline',
      position: 'relative',
      top: '0.75em',
      float: 'none',
      height: '1em',
      width: 0
    },
    menuClearButton: {
      cursor: 'pointer',
      height: '43px',
      fontSize: '14px',
      alignItems: 'center',
      lineHeight: '43px',
      marginRight: '.75em',
      color: '#005eb8',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textAlign: 'right',
      '&::before': {
        margin: '0 .75em'
      },
      '&:hover': {
        textDecoration: 'underline'
      }
    },
    loadingSpinner: {
      color: 'rgba(0, 0, 0, 0.2)'
    },
    centerLoadingSpinner: {
      textAlign: 'center',
      padding: 5,
      height: 23
    },
    searchResultContainer: {
      overflowY: 'auto',
      width: '220px',
      height: '270px'
    },
    menuSearchBar: {
      flex: 2,
      display: 'flex',
      height: '100%',
      alignItems: 'center',
      marginLeft: '10px'
    },
    inputField: {
      marginRight: 8,
      '& .MuiInputBase-input': {
        padding: 0
      },
      '& .MuiInput-input': {
        fontSize: '14px'
      }
    },
    rootMenuItem: {
      flexDirection: 'column',
      alignItems: 'flex-start'
    },
    menuItem: {
      display: 'flex',
      flexDirection: 'column',
      whiteSpace: 'nowrap',
      justifyContent: 'flex-start',
      padding: '0.5em',
      height: '40px',
      zIndex: 200,
      cursor: 'pointer',
      fontSize: '14px'
    },
    gutters: {
      padding: 0
    },
    peopleMenuItem: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis'
    },
    peopleSelectionMail: {
      fontSize: '12px',
      color: 'rgba(0, 0, 0, 0.6)'
    },
    currentSeletecUserContainer: {
      borderBottom: '1px solid #e0e0e0',
      padding: '10px 10px',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      fontSize: '14px'
    },
    currentSeletecUser: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis'
    },
    checkBox: {
      flex: '1',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      [`${theme.breakpoints.down(1024)}`]: {
        marginRight: '10px'
      }
    }
  }))
}
