import React, { Dispatch, useEffect, useState } from 'react'
import { Store } from 'store'
import TranslationsStore from 'store/Translations'
import { connect } from 'react-redux'
import { getCurrentScope } from 'utils/queryParams'
import { IUserSetting } from 'utils/userSettings'
import {
  cleanupOldPopupUserSettings,
  getUserPopups,
  markPopupAsShown
} from 'utils/Popups'
import Popup from './Popup'
import { useLocation } from 'react-router-dom'
import UserSettingsStore from 'store/UserSettings'
import { PopupResult } from 'components/models/PopupResult'
import { IDeviceSetting } from 'utils/deviceSettings'

export interface IPopupContentProps {
  userSettings: IUserSetting
  deviceSettings: IDeviceSetting
}

type AllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IPopupContentProps

function PopupContent(props: AllProps) {
  const {
    userSettings,
    fetchPopupTranslations,
    popupTranslations,
    setUserSettings,
    deviceSettings
  } = props

  const [open, setOpen] = useState(false)
  const [currentPopupIndex, setCurrentPopupIndex] = useState(0)
  const [currentPopupResult, setCurrentPopupResult] = useState<
    PopupResult | undefined
  >()
  const [popupResults, setPopupResults] = useState<PopupResult[]>([])

  const location = useLocation()
  const scope = getCurrentScope(false)

  // fetch API once on start
  useEffect(() => {
    fetchPopupTranslations(userSettings.Language)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    let datasourceName = 'Landing_page'
    if (scope) datasourceName = scope

    const popupResults = getUserPopups(
      popupTranslations,
      datasourceName,
      userSettings
    )
    if (popupResults && popupResults.length > 0) {
      setPopupResults(popupResults)
      setCurrentPopupIndex(0)
      setCurrentPopupResult(popupResults[0])
      setOpen(true)
    }

    if (cleanupOldPopupUserSettings(popupTranslations, userSettings)) {
      setUserSettings(userSettings)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popupTranslations, location])

  const onPopupClose = () => {
    setOpen(false)
    markPopupAsShown(userSettings, currentPopupResult)
    setUserSettings(userSettings)

    if (popupResults.length > currentPopupIndex) {
      setCurrentPopupIndex(currentPopupIndex + 1)
      setCurrentPopupResult(popupResults[currentPopupIndex + 1])
      setOpen(true)
    }
  }

  return open ? (
    <Popup
      popupResult={currentPopupResult}
      open={open}
      onClose={() => {
        onPopupClose()
      }}
      deviceSettings={deviceSettings}
    />
  ) : null
}

const mapStateToProps = (state: Store) => {
  return {
    popupTranslations: TranslationsStore.selectors.getPopupTranslations(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    fetchPopupTranslations: (language: string) =>
      dispatch(TranslationsStore.actions.fetchPopupTranslations(language)),
    setUserSettings: (userSettings: IUserSetting) =>
      dispatch(UserSettingsStore.actions.upSertUserSettings(userSettings))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PopupContent)
