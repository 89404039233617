import React from 'react'
import * as Config from 'config'
import { ResultLeftWidgetGenerator } from './interfaces'
import LeftStatisticsWidget from 'components/contents/resultpages/widgets/LeftStatisticsWidget'
import { FetchWithCache } from 'utils/api'
import { trackException } from 'utils/tracking'
import { IDeviceSetting } from 'utils/deviceSettings'
import { IUserSetting } from 'utils/userSettings'
import { IFindConfiguration } from 'store/Settings/reducers'
import { IStatisticResult } from 'components/models/StatisticResult'
import { generateImage } from './statistainfographics'
import { IAADState } from 'store/Auth/reducers'
import { renewAuthorizationToken } from 'utils/token'

export const leftStatisticsWidgetGenerator: ResultLeftWidgetGenerator = {
  fetch(
    aadInfo: IAADState,
    searchQuery: string,
    deviceSettings: IDeviceSetting,
    userSettings: IUserSetting,
    findConfiguration: IFindConfiguration
  ): Promise<IStatisticResult[]> {
    return new Promise<IStatisticResult[]>((resolve, reject) => {
      const fetchStatistics = async () => {
        if (!searchQuery || searchQuery === '' || searchQuery === '""') {
          trackException(
            'Error in fetching Statistics left widget in leftStatisticsWidget.tsx',
            new Error('Empty query. Please specify a valid query text')
          )
          reject()
          return
        }
        let statsUrl = `${
          Config.APIM_BASE_URL
        }statistaapi/getstatistics?q=${encodeURIComponent(
          searchQuery
        )}&limit=200&processFeaturedResults=false`

        if (!userSettings.PremiumEnabled) {
          statsUrl += `&premium=0`
        }

        statsUrl += `&processFeaturedResults=false`

        // Get and check authentication token
        const esToken = await renewAuthorizationToken(
          aadInfo.accessToken,
          aadInfo.instance,
          aadInfo.accounts
        )

        const response = await FetchWithCache(statsUrl, {
          method: 'GET',
          headers: {
            'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
            'content-type': 'application/json',
            Authorization: `Bearer ${esToken}`
          }
        })

        let results
        if (!response || response.hasError || !response.responseJSON) {
          trackException(
            'Error in fetching Statistics left widget in leftStatisticsWidget.tsx',
            new Error(
              response && response.errorResponse
                ? response.errorResponse.responseCode +
                  ':' +
                  response.errorResponse.message
                : ''
            )
          )
          reject()
          return
        } else {
          results = response.responseJSON
        }

        if (!results || !results.items || results.items.length < 3) {
          reject()
          return
        } else if (results.items.length > 10) {
          results.items = results.items.slice(0, 10)
        }

        const statisticResults: IStatisticResult[] = []

        results.items.forEach((i: any) => {
          let imageUrl = generateImage(
            i.image_url ? i.image_url : '',
            i.teaser_image_urls
          )

          if (
            imageUrl.indexOf('http://') === -1 &&
            imageUrl.indexOf('https://') === -1
          ) {
            imageUrl = 'https://www.statista.com' + imageUrl
          }

          const statisticResult: IStatisticResult = {
            title: i.title,
            body: i.description,
            date: i.date,
            link: i.link,
            sources: i.sources,
            image: imageUrl,
            premium: i.premium === 1
          }

          statisticResults.push(statisticResult)
        })

        resolve(statisticResults)
      }

      try {
        fetchStatistics()
      } catch (error) {
        trackException(
          'Error in fetching Statistics left widget in leftStatisticsWidget.tsx',
          error
        )
        reject()
      }
    })
  },
  generate(
    results: IStatisticResult[],
    searchQuery: string
  ): Promise<React.ReactElement> {
    return new Promise<React.ReactElement>((resolve, reject) => {
      const generateStatistics = () => {
        const limit = 10
        if (results.length > 2) {
          let resultsList = results

          if (results.length > limit) {
            resultsList = results.slice(0, limit)
          }
          let hasContent = false
          for (let i = 0; i < resultsList.length; i++) {
            if (resultsList[i].title !== '') {
              hasContent = true
              break
            }
          }
          if (!hasContent) {
            reject()
            return
          }

          resolve(
            <LeftStatisticsWidget query={searchQuery} results={resultsList} />
          )
        } else {
          reject()
          return
        }
      }

      try {
        generateStatistics()
      } catch (error) {
        trackException(
          'Error in generating Statistics left widget in leftStatisticsWidget.tsx',
          error
        )
        reject()
      }
    })
  }
}
