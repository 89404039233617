import { AnyAction } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { trackException } from 'utils/tracking'
import {
  IWidgetSelectConfig,
  IWidgetSelectConfigLoading
} from 'utils/widgets/resultWidgets'
import { Store } from '..'
import { ActionMetaData, CustomAction } from 'store/extension/customAction'
import { getCurrentScope } from 'utils/queryParams'

export enum WidgetActionTypes {
  FETCH_WIDGETS = 'settings/FETCH_WIDGETS',
  FETCH_WIDGETS_LEFT = 'settings/FETCH_WIDGETS_LEFT',
  FETCH_WIDGETS_RIGHT = 'settings/FETCH_WIDGETS_RIGHT',
  LEFT_WIDGET_FETCHED = 'settings/LEFT_WIDGET_FETCHED',
  RIGHT_WIDGET_FETCHED = 'settings/RIGHT_WIDGET_FETCHED',
  FETCH_WIDGETS_LEFT_FAILURE = 'settings/FETCH_WIDGETS_LEFT_FAILURE',
  FETCH_WIDGETS_RIGHT_FAILURE = 'settings/FETCH_WIDGETS_RIGHT_FAILURE'
}

export type IFetchWidgets = CustomAction<WidgetActionTypes>
export type IFetchWidgetsLeft = CustomAction<WidgetActionTypes>
export type IFetchWidgetsRight = CustomAction<WidgetActionTypes>
export type IFetchWidgetsRightFailure = CustomAction<WidgetActionTypes>
export type IFetchWidgetsLeftFailure = CustomAction<WidgetActionTypes>

export interface IWidgetFetched {
  widget: React.ReactElement | null
  widgetConfig: IWidgetSelectConfig | IWidgetSelectConfigLoading | null
  widgetDatasource: string
}

export interface ILeftWidgetFetched extends CustomAction<WidgetActionTypes> {
  payload: {
    leftWidget: React.ReactElement | null
    leftWidgetConfig: IWidgetSelectConfig | null
  }
}

export interface IRightWidgetFetched extends CustomAction<WidgetActionTypes> {
  payload: {
    rightWidget: React.ReactElement | null
    rightWidgetConfig: IWidgetSelectConfig | null
  }
}

export const fetchWidgetsLeftFailure = (
  actionMetaData: ActionMetaData
): IFetchWidgetsLeftFailure => ({
  type: WidgetActionTypes.FETCH_WIDGETS_LEFT_FAILURE,
  metaData: actionMetaData
})

export const fetchWidgetsRightFailure = (
  actionMetaData: ActionMetaData
): IFetchWidgetsRightFailure => ({
  type: WidgetActionTypes.FETCH_WIDGETS_RIGHT_FAILURE,
  metaData: actionMetaData
})

export const fetchWidgets = (
  actionMetaData: ActionMetaData
): IFetchWidgets => ({
  type: WidgetActionTypes.FETCH_WIDGETS,
  metaData: {
    ...actionMetaData,
    isStartAction: true
  }
})

export const fetchWidgetsLeft = (
  actionMetaData: ActionMetaData
): IFetchWidgetsLeft => ({
  type: WidgetActionTypes.FETCH_WIDGETS_LEFT,
  metaData: {
    ...actionMetaData,
    isStartAction: true
  }
})

export const fetchWidgetsRight = (
  actionMetaData: ActionMetaData
): IFetchWidgetsRight => ({
  type: WidgetActionTypes.FETCH_WIDGETS_RIGHT,
  metaData: {
    ...actionMetaData,
    isStartAction: true
  }
})

export const leftWidgetFetched = (
  leftWidget: any | null,
  actionMetaData: ActionMetaData
): ILeftWidgetFetched => ({
  type: WidgetActionTypes.LEFT_WIDGET_FETCHED,
  payload: {
    leftWidget: leftWidget ? leftWidget.widget : null,
    leftWidgetConfig: leftWidget ? leftWidget.widgetConfig : null
  },
  metaData: actionMetaData
})

export const rightWidgetFetched = (
  rightWidget: any | null,
  actionMetaData: ActionMetaData
): IRightWidgetFetched => ({
  type: WidgetActionTypes.RIGHT_WIDGET_FETCHED,
  payload: {
    rightWidget: rightWidget ? rightWidget.widget : null,
    rightWidgetConfig: rightWidget ? rightWidget.widgetConfig : null
  },
  metaData: actionMetaData
})

export interface IWidgetSelectedResults {
  widgetConfig: IWidgetSelectConfig | null
  result: any
}

export const setFetchedLeftWidget = (
  widget: IWidgetFetched,
  actionMetaData: ActionMetaData
): ThunkAction<Promise<void>, Store, unknown, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => Store
  ) => {
    const currentPath = getCurrentScope(true)
    if (widget.widgetDatasource !== currentPath) {
      return
    }

    try {
      if (widget && widget.widgetConfig)
        if (widget.widgetConfig.fullyLoaded) {
          dispatch(leftWidgetFetched(widget, actionMetaData))
        } else {
          dispatch(fetchWidgetsLeft(actionMetaData))
        }
    } catch (error) {
      trackException('Error in fetchWidgetData', error)
    }
  }
}

export const setFetchedRightWidget = (
  widget: IWidgetFetched,
  actionMetaData: ActionMetaData
): ThunkAction<Promise<void>, Store, unknown, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => Store
  ) => {
    const currentPath = getCurrentScope(true)
    if (widget.widgetDatasource !== currentPath) {
      return
    }

    try {
      if (widget && widget.widgetConfig)
        if (widget.widgetConfig.fullyLoaded) {
          dispatch(rightWidgetFetched(widget, actionMetaData))
        } else {
          dispatch(fetchWidgetsRight(actionMetaData))
        }
    } catch (error) {
      trackException('Error in fetchWidgetData', error)
    }
  }
}

export type WidgetActions =
  | IFetchWidgets
  | ILeftWidgetFetched
  | IRightWidgetFetched
  | IFetchWidgetsRight
  | IFetchWidgetsLeft
