import React, { useMemo } from 'react'
import { Box, Fade } from '@mui/material'
import { useIntl } from 'react-intl'
import ReactPlaceholder from 'react-placeholder'
import { connect } from 'react-redux'
import 'Placeholder.css'
import { Store } from 'store'
import ResultsKnowledgeExchangeStore from 'store/ResultsKnowledgeExchange'
import TextResult from 'components/contents/results/TextResult'
import Pagination from '../../Pagination'
import { trackException } from 'utils/tracking'
import { dateFormatOptions } from 'constants/constants'
import SearchSuggestions from 'components/contents/common/SearchSuggestions'
import { IDeviceSetting } from 'utils/deviceSettings'
import FeaturedResults from '../FeaturedResults'
import LeftWidgetContainer from '../widgetscontainer/LeftWidgetContainer'
import { getStylesResults } from 'styles/contents/resultpages/Results'
import { useFilter } from 'utils/filters'
import createDOMPurify from 'dompurify'
import KxFilterBar from './KxFilterBar'
import ErrorPage from 'components/contents/common/ErrorPage'

interface ResultsKnowledgeExchangeMobileProps {
  searchQuery: any
  deviceSettings: IDeviceSetting
  datasources: any
  hasErrorStore: boolean
}

type AllKnowledgeExchangeMobileProps = ResultsKnowledgeExchangeMobileProps &
  ReturnType<typeof mapStateToProps>

function ResultsKnowledgeExchangeMobile(
  props: AllKnowledgeExchangeMobileProps
) {
  const {
    hasResultsBeenFetched,
    resultsCount,
    featuredResults,
    resultsCombined,
    searchQuery,
    deviceSettings,
    datasources,
    hasErrorStore
  } = props
  const DOMPurify = createDOMPurify(window)
  const resultClasses = getStylesResults()
  const intl = useIntl()
  const [currentPage] = useFilter('page', '1')

  const returnAllItems = (type: string) => {
    // if no results are present, make sure to not render the empty result frames
    if (!resultsCombined || resultsCombined.length < 1) {
      return []
    }

    const items = []
    try {
      const firstResults =
        resultsCombined.length < 4 ? resultsCombined.length : 3

      if (type === 'first') {
        for (let i = 0; i < firstResults; i++) {
          items.push(
            <TextResult
              title={DOMPurify.sanitize(resultsCombined[i].CatTitle)}
              body={
                resultsCombined[i].CatAbstract.trim() === ''
                  ? ''
                  : resultsCombined[i].CatAbstract.trim().endsWith('.')
                  ? DOMPurify.sanitize(resultsCombined[i].CatAbstract.trim())
                  : DOMPurify.sanitize(resultsCombined[i].CatAbstract.trim()) +
                    ' ...'
              }
              displayUrl={`
                ${DOMPurify.sanitize(resultsCombined[i].CatFunction)}${
                resultsCombined[i].CatFunction ? ' > ' : ''
              }${DOMPurify.sanitize(resultsCombined[i].CatDocumentType)}${
                resultsCombined[i].CatDocumentType ? ' > ' : ''
              }
             ${intl.formatDate(
               DOMPurify.sanitize(resultsCombined[i].CatDocumentDate),
               dateFormatOptions
             )}`}
              url={DOMPurify.sanitize(resultsCombined[i].CatURL)}
              featured={false}
              showContextMenu={false}
              disableSharing={true}
              key={i}
              index={i}
              searchQuery={DOMPurify.sanitize(searchQuery)}
              deviceSettings={deviceSettings}
              openInWeb={
                resultsCombined[i].CatFileType &&
                resultsCombined[i].CatFileType.toLowerCase() !== 'pdf'
                  ? true
                  : false
              }
            />
          )
        }
      } else {
        for (let i = firstResults; i < resultsCombined.length; i++) {
          items.push(
            <TextResult
              title={DOMPurify.sanitize(resultsCombined[i].CatTitle)}
              body={
                resultsCombined[i].CatAbstract.trim() === ''
                  ? ''
                  : resultsCombined[i].CatAbstract.trim().endsWith('.')
                  ? DOMPurify.sanitize(resultsCombined[i].CatAbstract.trim())
                  : DOMPurify.sanitize(resultsCombined[i].CatAbstract.trim()) +
                    ' ...'
              }
              displayUrl={`
                ${DOMPurify.sanitize(resultsCombined[i].CatFunction)}${
                resultsCombined[i].CatFunction ? ' > ' : ''
              }${DOMPurify.sanitize(resultsCombined[i].CatDocumentType)}${
                resultsCombined[i].CatDocumentType ? ' > ' : ''
              }
             ${intl.formatDate(
               DOMPurify.sanitize(resultsCombined[i].CatDocumentDate),
               dateFormatOptions
             )}`}
              url={DOMPurify.sanitize(resultsCombined[i].CatURL)}
              featured={false}
              showContextMenu={false}
              disableSharing={true}
              key={i}
              index={i}
              searchQuery={DOMPurify.sanitize(searchQuery)}
              deviceSettings={deviceSettings}
              openInWeb={
                resultsCombined[i].CatFileType &&
                resultsCombined[i].CatFileType.toLowerCase() !== 'pdf'
                  ? true
                  : false
              }
            />
          )
        }
      }
    } catch (error) {
      trackException(
        'Error in returning all items in ResultsKnowledgeExchangeMobile.tsx',
        error
      )
    }
    return items
  }

  const firstItems = useMemo(
    () => returnAllItems('first'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resultsCombined, intl]
  )
  const restItems = useMemo(
    () => returnAllItems('rest'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resultsCombined, intl]
  )

  return (
    <Box className={resultClasses.container}>
      <Box className={resultClasses.resultsContainer}>
        {!hasErrorStore && (
          <SearchSuggestions
            wildCardActive={true}
            resultCount={resultsCount}
            resultsFetchend={hasResultsBeenFetched}
          />
        )}
        <KxFilterBar {...props} scope={'Catalog'} datasources={datasources} />
        {!hasErrorStore && (
          <>
            <ReactPlaceholder
              ready={hasResultsBeenFetched || Number(currentPage) > 1}
              type="text"
              rows={4}
              showLoadingAnimation={true}
              className={resultClasses.placeholder}
            >
              <Fade
                in={hasResultsBeenFetched || Number(currentPage) > 1}
                timeout={600}
              >
                <Box>
                  {resultsCombined && resultsCount > 0 && (
                    <FeaturedResults featuredResults={featuredResults} />
                  )}
                  {firstItems}
                </Box>
              </Fade>
            </ReactPlaceholder>
            {hasResultsBeenFetched &&
            (resultsCount < 1 || resultsCombined.length < 1) ? null : (
              <LeftWidgetContainer
                resultCount={resultsCount}
                hasResultsBeenFetched={hasResultsBeenFetched}
              />
            )}
            <ReactPlaceholder
              ready={hasResultsBeenFetched || Number(currentPage) > 1}
              type="text"
              rows={4}
              showLoadingAnimation={true}
              className={resultClasses.placeholder}
            >
              <Fade
                in={hasResultsBeenFetched || Number(currentPage) > 1}
                timeout={600}
              >
                <Box>
                  {restItems}
                  {resultsCount != null && resultsCount > 0 && (
                    <Pagination
                      {...props}
                      totalRecords={resultsCount}
                      pageLimit={20}
                      pageNeighbours={4}
                    />
                  )}
                </Box>
              </Fade>
            </ReactPlaceholder>
            <ReactPlaceholder
              ready={hasResultsBeenFetched ? hasResultsBeenFetched : false}
              type="text"
              rows={4}
              showLoadingAnimation={true}
              className={resultClasses.placeholder}
            >
              <Box />
            </ReactPlaceholder>
          </>
        )}
        {hasErrorStore && (
          <ErrorPage dataSource="knowledgeexchange" origin={'catalog'} />
        )}
      </Box>
    </Box>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    featuredResults: ResultsKnowledgeExchangeStore.selectors.getFeatureResults(
      state,
      'catalog'
    ),
    resultsCombined: ResultsKnowledgeExchangeStore.selectors.getResultsCombined(
      state,
      'catalog'
    ),
    resultsCount: ResultsKnowledgeExchangeStore.selectors.getResultCount(
      state,
      'catalog'
    ),
    hasResultsBeenFetched:
      ResultsKnowledgeExchangeStore.selectors.hasResultsBeenFetched(
        state,
        'catalog'
      )
  }
}

export default connect(mapStateToProps)(ResultsKnowledgeExchangeMobile)
