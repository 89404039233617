import { FeaturedResult } from 'components/models/FeaturedResult'
import { ISynonymsApplied } from 'components/models/SynonymsApplied'
import { Reducer } from 'redux'
import {
  ResultsPeopleActions,
  ResultsPeopleActionTypes,
  IFetchSuccess,
  IFetchFiltersSuccess,
  IFetchSuccessCombined
} from './actions'
import { IPeopleResult } from 'components/models/PeopleResult'

export interface IResultsPeopleStore {
  hasError: boolean
  hasResultsBeenFetched: boolean
  featuredResults: FeaturedResult[]
  results: Array<IPeopleResult>
  resultsCombined: Array<IPeopleResult>
  resultsCombinedQuery: string
  resultCount: number
  synonymsApplied: Array<ISynonymsApplied>
  executionTime: number
  currentPage: number
  filters: {
    countries: Array<any>
    cities: Array<any>
    departments: Array<any>
    jobtitles: Array<any>
    hasResultsBeenFetched: boolean
    hasError: boolean
  }
  lastAppliedFilters: {
    [key: string]: string
  } | null
}

const resultsInitialState = () => []

const initialState: IResultsPeopleStore = {
  hasError: false,
  hasResultsBeenFetched: false,
  resultCount: -1,
  featuredResults: [],
  results: resultsInitialState(),
  resultsCombined: resultsInitialState(),
  resultsCombinedQuery: '',
  synonymsApplied: [],
  executionTime: 0,
  currentPage: 1,
  filters: {
    countries: [],
    cities: [],
    departments: [],
    jobtitles: [],
    hasResultsBeenFetched: false,
    hasError: false
  },
  lastAppliedFilters: null
}

const reducers: Reducer<IResultsPeopleStore, ResultsPeopleActions> = (
  state: IResultsPeopleStore = initialState,
  action: ResultsPeopleActions | { type: 'SET_CURRENT_PAGE'; payload: any }
) => {
  switch (action.type) {
    case 'SET_CURRENT_PAGE':
      return {
        ...state,
        currentPage: action.payload || 0
      }
    case ResultsPeopleActionTypes.INITIALIZE_RESULTS_PEOPLE:
      if (!(action as any).payload) {
        return initialState
      }
      const { resultsPeople } = (action as any).payload
      return {
        ...state,
        currentPage: resultsPeople.currentPage || 1
      }
    case ResultsPeopleActionTypes.FETCH_REQUEST:
      const { resetCombined, searchQuery } = (action as any).payload
      return {
        ...state,
        hasError: false,
        hasResultsBeenFetched: false,
        executionTime: 0,
        resultCount: -1,
        results: resultsInitialState(),
        resultsCombined: resetCombined
          ? resultsInitialState()
          : state.resultsCombined,
        resultsCombinedQuery: searchQuery
      }
    case ResultsPeopleActionTypes.FETCH_FAILURE:
      return {
        ...state,
        hasError: true,
        hasResultsBeenFetched: true,
        resultCount: 0,
        nextLink: null
      }
    case ResultsPeopleActionTypes.FETCH_SUCCESS:
      const {
        response,
        resultCount,
        synonymsApplied,
        executionTime,
        currentPage,
        featuredResults
      } = (action as IFetchSuccess).payload
      return {
        ...state,
        hasError: false,
        hasResultsBeenFetched: true,
        featuredResults:
          currentPage === 1 ? featuredResults : state.featuredResults,
        results: response,
        executionTime: executionTime,
        resultCount: resultCount,
        synonymsApplied: synonymsApplied,
        currentPage: currentPage
      }
    case ResultsPeopleActionTypes.FETCH_SUCCESS_COMBINED:
      const { response: responseCombined } = (action as IFetchSuccessCombined)
        .payload
      return {
        ...state,
        hasError: false,
        resultsCombined: responseCombined
      }
    case ResultsPeopleActionTypes.FETCH_FILTERS:
      return {
        ...state,
        filters: {
          countries: [],
          cities: [],
          departments: [],
          jobtitles: [],
          hasError: false,
          hasResultsBeenFetched: false
        }
      }
    case ResultsPeopleActionTypes.FETCH_FILTERS_SUCCESS:
      const {
        refinerResultCountries,
        refinerResultCities,
        refinerResultDepartments,
        refinerResultJobTitles,
        lastFilters
      } = (action as IFetchFiltersSuccess).payload
      return {
        ...state,
        lastAppliedFilters: lastFilters,
        filters: {
          countries: refinerResultCountries,
          cities: refinerResultCities,
          departments: refinerResultDepartments,
          jobtitles: refinerResultJobTitles,
          hasError: false,
          hasResultsBeenFetched: true
        }
      }
    case ResultsPeopleActionTypes.FETCH_FILTERS_FAILURE:
      return {
        ...state,
        filters: {
          countries: [],
          cities: [],
          departments: [],
          jobtitles: [],
          hasError: true,
          hasResultsBeenFetched: true
        }
      }
    default:
      return state
  }
}

export default reducers
