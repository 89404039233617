import { Action } from 'redux'
import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser'

export enum AuthActionTypes {
  SET_AUTH_TOKEN = 'auth/SET_AUTH_TOKEN',
  SET_AAD_INFO = 'auth/SET_AAD_INFO'
}

export interface ISetAuthToken extends Action<AuthActionTypes> {
  payload: {
    authToken: string
  }
}

export interface ISetAADInfo extends Action<AuthActionTypes> {
  payload: {
    instance: IPublicClientApplication
    accounts: AccountInfo[]
  }
}

export const setAuthToken = (authToken: string): ISetAuthToken => ({
  type: AuthActionTypes.SET_AUTH_TOKEN,
  payload: { authToken }
})

export const setAADInfo = (
  instance: IPublicClientApplication,
  accounts: AccountInfo[]
): ISetAADInfo => ({
  type: AuthActionTypes.SET_AAD_INFO,
  payload: { instance, accounts }
})

export type AuthActions = ISetAuthToken | ISetAADInfo
