import { FeaturedResult } from 'components/models/FeaturedResult'
import { ISynonymsApplied } from 'components/models/SynonymsApplied'
import { Reducer } from 'redux'
import {
  ResultsEngagementsActions,
  ResultsEngagementsActionTypes,
  IFetchSuccess,
  IFetchSuccessCombined
} from './actions'
import { IEngagementResult } from 'components/models/EngagementResult'

export interface IResult {
  queryResults: Array<IEngagementResult>
  synonymsApplied: Array<ISynonymsApplied>
}

export interface IResultsEngagementsStore {
  hasResultsBeenFetched: boolean
  featuredResults: FeaturedResult[]
  results: IResult
  resultsCombined: IResult
  resultsCombinedQuery: string
  totalRowCount: number
  lastRow: number
  hasError: boolean
  executionTime: number
}

const initialResultState = () => ({
  queryResults: [],
  synonymsApplied: []
})

const initialState: IResultsEngagementsStore = {
  hasResultsBeenFetched: false,
  featuredResults: [],
  results: initialResultState(),
  resultsCombined: initialResultState(),
  resultsCombinedQuery: '',
  totalRowCount: 0,
  lastRow: 0,
  hasError: false,
  executionTime: 0
}

const reducers: Reducer<IResultsEngagementsStore, ResultsEngagementsActions> = (
  state: IResultsEngagementsStore = initialState,
  action: ResultsEngagementsActions | { type: 'SET_CURRENT_PAGE'; payload: any }
) => {
  switch (action.type) {
    case 'SET_CURRENT_PAGE':
      return {
        ...state,
        currentPage: action.payload || 0
      }
    case ResultsEngagementsActionTypes.INITIALIZE_RESULTS_ENGAGEMENTS:
      if (!(action as any).payload) {
        return initialState
      }
      const { resultsEngagements } = (action as any).payload
      return {
        ...state,
        lastRow: resultsEngagements.lastRow || 0
      }
    case ResultsEngagementsActionTypes.FETCH_REQUEST:
      const { resetCombined, searchQuery } = (action as any).payload
      return {
        ...state,
        hasResultsBeenFetched: false,
        executionTime: 0,
        totalRowCount: 0,
        results: initialResultState(),
        resultsCombined: resetCombined
          ? initialResultState()
          : state.resultsCombined,
        lastRow: resetCombined ? 0 : state.lastRow,
        resultsCombinedQuery: searchQuery
      }
    case ResultsEngagementsActionTypes.FETCH_FAILURE:
      return {
        ...state,
        hasResultsBeenFetched: true,
        totalRowCount: 0
      }
    case ResultsEngagementsActionTypes.FETCH_SUCCESS:
      const { response, featuredResults, currentPage } = (
        action as IFetchSuccess
      ).payload
      return {
        ...state,
        hasResultsBeenFetched: true,
        executionTime: response.executionTime,
        totalRowCount: response.totalRowCount,
        featuredResults:
          currentPage === 1 ? featuredResults : state.featuredResults,
        results: {
          queryResults: response.queryResults,
          synonymsApplied: response.synonymsApplied
        },
        lastRow: response.lastRow,
        hasError: response.hasError
      }
    case ResultsEngagementsActionTypes.FETCH_SUCCESS_COMBINED:
      const { response: responseCombined } = (action as IFetchSuccessCombined)
        .payload
      return {
        ...state,
        resultsCombined: {
          queryResults: responseCombined.queryResults,
          synonymsApplied: responseCombined.synonymsApplied
        },
        lastRow: responseCombined.lastRow,
        hasError: responseCombined.hasError
      }
    default:
      return state
  }
}

export default reducers
