import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Route, Routes } from 'react-router-dom'
import FilterBar from '../../FilterBar'
import ResultCalendar from './ResultCalendar'
import ResultFiles from './ResultFiles'
import ResultMails from './ResultMails'
import ResultSites from './ResultSites'
import PageStay from 'components/HOC/PageStay'
import { Store } from 'store'
import SettingsStore from 'store/Settings'
import ResultsOffice365Store from 'store/ResultsOffice365'
import ResultTeams from './ResultTeams'

interface ResultOffice365Props {
  showLocalFilter: boolean
}

type AllProps = ReturnType<typeof mapStateToProps> & ResultOffice365Props

function ResultOffice365(props: AllProps) {
  const {
    showLocalFilter,
    moreMailResultsAvailable,
    moreCalendarResultsAvailable,
    moreTeamsResultsAvailable,
    moreSiteResultsAvailable,
    deviceSettings
  } = props

  const [internalDataSources] = useState([
    {
      name: 'Files',
      enabled: true,
      path: '/results/office365'
    },
    {
      name: 'Sites',
      enabled: true,
      path: '/results/office365/sites'
    },
    {
      name: 'Mails',
      enabled: true,
      path: '/results/office365/mails'
    },
    {
      name: 'Calendar',
      enabled: true,
      path: '/results/office365/calendar'
    },
    {
      name: 'Teams',
      enabled: true,
      path: '/results/office365/teams'
    }
  ])

  return (
    <>
      <Routes>
        <Route
          path={'/mails'}
          element={
            <>
              <FilterBar
                hasSubverticals={true}
                showLocalFilter={showLocalFilter}
                deviceSettings={deviceSettings}
              />
              <ResultMails
                {...props}
                moreResultsAvailable={moreMailResultsAvailable}
                datasources={internalDataSources}
              />
            </>
          }
        />
        <Route
          path={'/calendar'}
          element={
            <>
              <FilterBar
                hasSubverticals={true}
                showLocalFilter={showLocalFilter}
                deviceSettings={deviceSettings}
              />
              <ResultCalendar
                {...props}
                moreResultsAvailable={moreCalendarResultsAvailable}
                datasources={internalDataSources}
              />
            </>
          }
        />
        <Route
          path={'/'}
          element={
            <>
              <FilterBar
                hasSubverticals={true}
                showLocalFilter={showLocalFilter}
                deviceSettings={deviceSettings}
              />
              <ResultFiles {...props} datasources={internalDataSources} />
            </>
          }
        />
        <Route
          path={'/sites'}
          element={
            <>
              <FilterBar
                hasSubverticals={true}
                showLocalFilter={showLocalFilter}
                deviceSettings={deviceSettings}
              />
              <ResultSites
                {...props}
                datasources={internalDataSources}
                moreResultsAvailable={moreSiteResultsAvailable}
              />
            </>
          }
        />
        <Route
          path={'/teams'}
          element={
            <>
              <FilterBar
                hasSubverticals={true}
                showLocalFilter={showLocalFilter}
                deviceSettings={deviceSettings}
              />
              <ResultTeams
                {...props}
                datasources={internalDataSources}
                moreResultsAvailable={moreTeamsResultsAvailable}
              />
            </>
          }
        />
      </Routes>
    </>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    moreMailResultsAvailable:
      ResultsOffice365Store.selectors.areMoreResultsAvailable(state, 'mails'),
    moreCalendarResultsAvailable:
      ResultsOffice365Store.selectors.areMoreResultsAvailable(
        state,
        'calendar'
      ),
    moreTeamsResultsAvailable:
      ResultsOffice365Store.selectors.areMoreResultsAvailable(state, 'teams'),
    moreSiteResultsAvailable:
      ResultsOffice365Store.selectors.areMoreResultsAvailable(state, 'sites'),

    deviceSettings: SettingsStore.selectors.getDeviceSettings(state)
  }
}

export default PageStay(connect(mapStateToProps, null)(ResultOffice365))
