import React, { useEffect, useRef, useState } from 'react'
import AuthStore from 'store/Auth'
import SettingsStore from 'store/Settings'
import { Store } from 'store'
import { connect } from 'react-redux'
import { IPeopleResult } from 'components/models/PeopleResult'
import { searchPeopleResults } from 'utils/peopleSearchApi'
import { useFilter } from 'utils/filters'
import { useIntl } from 'react-intl'
import { CircularProgress, Input, MenuItem } from '@mui/material'
import { getStylesFilterMenuPeopleSearch } from 'styles/contents/FilterMenuPeopleSearch'
import SearchIcon from '@mui/icons-material/Search'
import TooltipTitle from './common/TooltipTitle'
import CheckIcon from '@mui/icons-material/Check'

export interface FilterMenuPeopleSearchProps {
  filter: FilterCategory
  handleClose: () => void
  setChecked: (checked: boolean) => void
}

type AllProps = ReturnType<typeof mapStateToProps> & FilterMenuPeopleSearchProps

function FilterMenuPeopleSearch(props: AllProps): JSX.Element {
  const { filter, deviceSettings, aadInfo, handleClose, setChecked } = props

  const [loading, setIsLoading] = useState<boolean>(false)
  const [peopleResults, setPeopleResults] = useState<IPeopleResult[]>([])
  const [filterUrlValue, setFilterUrlValue] = useFilter(filter.key, '')
  const [searchValue, setSearchValue] = useState<string>('')
  const currentCallValue = useRef(null)

  const intl = useIntl()
  const classes = getStylesFilterMenuPeopleSearch()

  const onSearch = (searchQuery: string) => {
    setIsLoading(true)
    searchPeopleResults(searchQuery, deviceSettings, aadInfo).then(
      (result: IPeopleResult[] | null) => {
        if (searchQuery === currentCallValue.current) {
          setIsLoading(false)
          setPeopleResults(result ? result : [])
        }
      }
    )
  }

  const handleClearAllFilterOptions = () => {
    handleClose()
    setFilterUrlValue('')
    setChecked(false)
  }

  const handleSelection = (userUpn: string) => {
    handleClose()
    setFilterUrlValue(userUpn)
    setChecked(true)
  }

  const rowRenderer = (peopleItem: IPeopleResult, index: number) => {
    if (!peopleItem) {
      return
    }

    return (
      <MenuItem
        disableTouchRipple
        disableRipple
        onClick={() => handleSelection(peopleItem.userPrincipalName)}
        classes={{ root: classes.rootMenuItem, gutters: classes.gutters }}
        key={index}
        id={peopleItem.mail}
      >
        <div className={classes.menuItem}>
          <TooltipTitle
            title={peopleItem.displayName}
            useCssOnly={true}
            singleLine={true}
            additionalClass={classes.peopleMenuItem}
          />
          <TooltipTitle
            title={peopleItem.mail}
            useCssOnly={true}
            singleLine={true}
            additionalClass={`${classes.peopleMenuItem} ${classes.peopleSelectionMail}`}
          />
        </div>
      </MenuItem>
    )
  }

  const handleSearchInputChange = (event: any) => {
    currentCallValue.current = event.target.value
    setSearchValue(event.target.value)
  }

  useEffect(() => {
    let getusers: any = null
    if (searchValue) {
      getusers = setTimeout(() => {
        onSearch(searchValue)
      }, 500)
    }

    return () => {
      if (getusers) {
        clearTimeout(getusers)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue])

  return (
    <>
      <div className={classes.menuTopBar}>
        <div className={classes.menuSearchBar}>
          <SearchIcon style={{ color: '#666' }}></SearchIcon>
          <Input
            className={classes.inputField}
            placeholder={intl.formatMessage({
              id: 'search',
              defaultMessage: 'Search'
            })}
            onChange={handleSearchInputChange}
            disableUnderline={true}
            type={'search'}
            inputProps={{ 'aria-label': 'description', size: 200 }}
          />
        </div>
        {filterUrlValue && (
          <div style={{ display: 'flex' }}>
            <div className={classes.divider}>
              <div className={classes.seperator}></div>
            </div>
            <div
              className={classes.menuClearButton}
              onClick={handleClearAllFilterOptions}
            >
              {intl.formatMessage({
                id: 'clear_all',
                defaultMessage: 'Clear All'
              })}
            </div>
          </div>
        )}
      </div>
      {filterUrlValue && (
        <div className={classes.currentSeletecUserContainer}>
          <TooltipTitle
            additionalClass={classes.currentSeletecUser}
            title={filterUrlValue}
            useCssOnly={true}
            singleLine={true}
          />
          <div className={classes.checkBox}>
            <CheckIcon fontSize={'small'}> </CheckIcon>
          </div>
        </div>
      )}
      <div className={classes.searchResultContainer}>
        {loading && (
          <div className={classes.centerLoadingSpinner}>
            <CircularProgress className={classes.loadingSpinner} size={15} />
          </div>
        )}
        {!loading && (
          <>
            {peopleResults.map((item: IPeopleResult, index: number) => {
              return rowRenderer(item, index)
            })}
          </>
        )}
      </div>
    </>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state),
    aadInfo: AuthStore.selectors.getAADInfo(state)
  }
}

export default connect(mapStateToProps)(FilterMenuPeopleSearch)
