import React, {
  Dispatch,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import { Box, Fade } from '@mui/material'
import { useIntl } from 'react-intl'
import ReactPlaceholder from 'react-placeholder'
import { connect } from 'react-redux'
import 'Placeholder.css'
import { Store } from 'store'
import ResultMetaDataStore from 'store/ResultMetaData'
import UserSettingsStore from 'store/UserSettings'
import SettingsStore from 'store/Settings'
import ResultsNewsStore from 'store/ResultsNews'
import ResultsNewsMobile from './ResultsNewsMobile'
import PageStay from 'components/HOC/PageStay'
import ErrorPage from 'components/contents/common/ErrorPage'
import Pagination from '../Pagination'
import TextResult from 'components/contents/results/TextResult'
import RightTopWidgetContainer from 'components/contents/resultpages/widgetscontainer/RightTopWidgetContainer'
import { trackException } from 'utils/tracking'
import { useReactRouterQueryStringInterface } from 'utils/useQueryState'
import { authorizeResultPage } from 'utils/authorization'
import createDOMPurify from 'dompurify'
import { dateFormatOptions, dataSourcesTabs } from 'constants/constants'
import SearchSuggestions from 'components/contents/common/SearchSuggestions'
import FeaturedResults from './FeaturedResults'
import LeftWidgetContainer from './widgetscontainer/LeftWidgetContainer'
import RightWidgetContainer from './widgetscontainer/RightWidgetContainer'
import { getStylesResults } from 'styles/contents/resultpages/Results'
import { useFilter } from 'utils/filters'
import { KPMGFindGlobalVariables } from 'store/KPMGFindGlobalVariables'
import { CognitiveMessage } from '../common/CognitiveMessage'
import { IUserSetting } from 'utils/userSettings'
import { useLocation } from 'react-router-dom'

export interface ResultsNewsProps {
  appliedFilters: any
}

type AllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  ResultsNewsProps

function ResultsNews(props: AllProps) {
  const {
    resultsCount,
    hasErrorStore,
    hasResultsBeenFetched,
    featuredResults,
    results,
    userSettings,
    deviceSettings,
    findConfiguration,
    setUserSettings,
    synonymsApplied,
    useCognitiveSearch
  } = props
  const DOMPurify = createDOMPurify(window)
  const resultClasses = getStylesResults()
  const intl = useIntl()
  const { getQueryString } = useReactRouterQueryStringInterface()
  const queryStringFromUrl = getQueryString()

  const [currentPage] = useFilter('page', '1')
  const [searchQuery] = useFilter('q')
  const location = useLocation()

  const [cognitiveSearchEnabled, setCognitiveSearchEnabled] =
    useState(useCognitiveSearch)
  const [showCognitiveMessage, setShowCognitiveMessage] = useState(
    userSettings.ShowCognitiveMessage
  )

  useEffect(() => {
    KPMGFindGlobalVariables.setCurrentTab(dataSourcesTabs.news)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(
    () => {
      setCognitiveSearchEnabled(useCognitiveSearch)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [useCognitiveSearch]
  )

  useEffect(
    () => {
      setShowCognitiveMessage(userSettings.ShowCognitiveMessage)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userSettings.ShowCognitiveMessage]
  )

  const setup = useCallback(() => {
    try {
      authorizeResultPage(
        'News',
        userSettings,
        deviceSettings,
        DOMPurify.sanitize(location && location.search ? location.search : '')
      )
    } catch (error) {
      trackException(
        'Error in fetching results and filters in ResultsNews.tsx',
        error
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryStringFromUrl])

  useEffect(() => {
    setup()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryStringFromUrl])

  const returnAllItems = (type: string) => {
    if (!results || results.length < 1) {
      return []
    }

    const items = []

    try {
      const firstResults = results.length < 4 ? results.length : 3

      if (type === 'first') {
        for (let i = 0; i < firstResults; i++) {
          const currentDate = results[i].Date
            ? intl.formatDate(
                DOMPurify.sanitize(results[i].Date),
                dateFormatOptions
              )
            : ''
          const currentSource = DOMPurify.sanitize(results[i].Source) || ''
          items.push(
            <TextResult
              title={DOMPurify.sanitize(results[i].Title)}
              body={DOMPurify.sanitize(results[i].Summary)}
              url={DOMPurify.sanitize(results[i].Link)}
              displayUrl={`${currentDate}${
                currentDate && currentSource && currentSource.length > 0
                  ? ' › '
                  : ''
              }${currentSource}`}
              featured={false}
              showContextMenu={false}
              key={i}
              index={i}
              searchQuery={DOMPurify.sanitize(searchQuery)}
              deviceSettings={deviceSettings}
              synonymsApplied={synonymsApplied}
            />
          )
        }
      } else {
        for (let i = firstResults; i < results.length; i++) {
          const currentDate = results[i].Date
            ? intl.formatDate(
                DOMPurify.sanitize(results[i].Date),
                dateFormatOptions
              )
            : ''
          const currentSource = DOMPurify.sanitize(results[i].Source) || ''
          items.push(
            <TextResult
              title={DOMPurify.sanitize(results[i].Title)}
              body={DOMPurify.sanitize(results[i].Summary)}
              url={DOMPurify.sanitize(results[i].Link)}
              displayUrl={`${currentDate}${
                currentDate && currentSource && currentSource.length > 0
                  ? ' › '
                  : ''
              }${currentSource}`}
              featured={false}
              showContextMenu={false}
              key={i}
              index={i}
              searchQuery={DOMPurify.sanitize(searchQuery)}
              deviceSettings={deviceSettings}
              synonymsApplied={synonymsApplied}
            />
          )
        }
      }
    } catch (error) {
      trackException('Error returning all items in ResultsNews.tsx', error)
    }
    return items
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const firstItems = useMemo(() => returnAllItems('first'), [results, intl])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const restItems = useMemo(() => returnAllItems('rest'), [results, intl])

  return (
    <Box>
      {!hasErrorStore && !!searchQuery && (
        <>
          {deviceSettings.renderMobile ? (
            <ResultsNewsMobile
              searchQuery={searchQuery}
              deviceSettings={deviceSettings}
              userSettings={userSettings}
              findConfigCognitiveSearchEnabled={
                findConfiguration.CognitiveSearchEnabled
              }
              cognitiveSearchEnabled={cognitiveSearchEnabled}
              showCognitiveMessage={showCognitiveMessage}
              setShowCognitiveMessage={setShowCognitiveMessage}
              setUserSettings={setUserSettings}
            />
          ) : (
            <Box className={resultClasses.container}>
              <Box
                id={'con-resultvertical-01'}
                className={resultClasses.resultsContainer}
              >
                <SearchSuggestions
                  wildCardActive={false}
                  resultCount={resultsCount}
                  resultsFetchend={hasResultsBeenFetched}
                />
                <ReactPlaceholder
                  ready={hasResultsBeenFetched ? hasResultsBeenFetched : false}
                  type="text"
                  rows={4}
                  showLoadingAnimation={true}
                  className={resultClasses.placeholder}
                >
                  <Fade
                    in={hasResultsBeenFetched ? hasResultsBeenFetched : false}
                    timeout={600}
                  >
                    <Box id={'con-resultvertical-section-01'}>
                      {cognitiveSearchEnabled &&
                        showCognitiveMessage &&
                        findConfiguration.CognitiveSearchEnabled &&
                        synonymsApplied &&
                        synonymsApplied.length > 0 && (
                          <CognitiveMessage
                            synonymsApplied={synonymsApplied}
                            setShowCognitiveMessage={setShowCognitiveMessage}
                            userSettings={userSettings}
                            setUserSettings={setUserSettings}
                          />
                        )}
                      {results && resultsCount > 0 && (
                        <FeaturedResults featuredResults={featuredResults} />
                      )}
                      {firstItems}
                    </Box>
                  </Fade>
                </ReactPlaceholder>
                {Number(currentPage) === 1 && (
                  <LeftWidgetContainer
                    resultCount={resultsCount}
                    hasResultsBeenFetched={hasResultsBeenFetched}
                  />
                )}
                <ReactPlaceholder
                  ready={hasResultsBeenFetched}
                  type="text"
                  rows={4}
                  showLoadingAnimation={true}
                  className={resultClasses.placeholder}
                >
                  <Fade in={hasResultsBeenFetched} timeout={600}>
                    <Box id={'con-resultvertical-section-02'}>
                      {restItems}
                      {resultsCount != null && resultsCount > 0 && (
                        <Pagination
                          {...props}
                          totalRecords={resultsCount}
                          pageLimit={20}
                          pageNeighbours={4}
                        />
                      )}
                    </Box>
                  </Fade>
                </ReactPlaceholder>
                <ReactPlaceholder
                  ready={hasResultsBeenFetched ? hasResultsBeenFetched : false}
                  type="text"
                  rows={4}
                  showLoadingAnimation={true}
                  className={resultClasses.placeholder}
                >
                  <Box />
                </ReactPlaceholder>
                <ReactPlaceholder
                  ready={hasResultsBeenFetched ? hasResultsBeenFetched : false}
                  type="text"
                  rows={4}
                  showLoadingAnimation={true}
                  className={resultClasses.placeholder}
                >
                  <Box />
                </ReactPlaceholder>
              </Box>
              <Box className={resultClasses.advertisementContainer}>
                <RightTopWidgetContainer
                  searchQuery={searchQuery}
                  scope={'news'}
                />
                <RightWidgetContainer />
              </Box>
            </Box>
          )}
        </>
      )}
      {hasErrorStore && <ErrorPage dataSource="news" />}
    </Box>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    featuredResults: ResultsNewsStore.selectors.getFeaturedResults(state),
    results: ResultsNewsStore.selectors.getResults(state),
    resultsCombined: ResultsNewsStore.selectors.getResultsCombined(state),
    resultsCount: ResultsNewsStore.selectors.getResultCount(state),
    hasResultsBeenFetched:
      ResultsNewsStore.selectors.hasResultsBeenFetched(state),
    executionTime: ResultsNewsStore.selectors.getExecutionTime(state),
    hasErrorStore: ResultMetaDataStore.selectors.getDataSourceHasError(
      state,
      'news'
    ),
    userSettings: UserSettingsStore.selectors.getUserSettings(state),
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state),
    findConfiguration: SettingsStore.selectors.getFindConfiguration(state),
    synonymsApplied: ResultsNewsStore.selectors.getSynonymsApplied(state),
    useCognitiveSearch: SettingsStore.selectors.getUseCognitiveSearch(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    setUserSettings: (userSettings: IUserSetting) =>
      dispatch(UserSettingsStore.actions.upSertUserSettings(userSettings))
  }
}

export default PageStay(
  connect(mapStateToProps, mapDispatchToProps)(ResultsNews)
)
