import { Store } from '..'

const selectors = {
  getSearchTop10: (state: Store): any => state.searchTop10.searchTop10,
  hasSearchTop10BeenFetched: (state: Store): any =>
    state.searchTop10.hasSearchTop10BeenFetched,
  hasError: (state: Store): any => state.searchTop10.hasError
}

export default selectors
