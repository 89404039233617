import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Box, Grid, IconButton, Typography } from '@mui/material'
import Link from '@mui/material/Link'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp'
import { trackRightWidgetClick } from 'utils/tracking'
import { dateFormatOptions } from 'constants/constants'
import { WidgetKey } from 'constants/widgets'
import { getStylesRightWidget } from 'styles/contents/resultpages/widgets/RightWidget'
import { getStylesRightVivaEngageWidget } from 'styles/contents/resultpages/widgets/RightVivaEngageWidget'
import createDOMPurify from 'dompurify'
import TooltipTitle from 'components/contents/common/TooltipTitle'
import LinesEllipsis from 'react-lines-ellipsis'
import HTMLEllipsis from 'react-lines-ellipsis/lib/html'
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'
import { IVivaEngageResult } from 'components/models/VivaEngageResult'
import { IDeviceSetting } from 'utils/deviceSettings'
import { getProfilePicture } from 'utils/msgraphProfilePicture'
import PeoplePlaceholder from 'images/people_placeholder.png'
import { makeStyles } from '@mui/styles'
import { IAADState } from 'store/Auth/reducers'

export interface RightVivaEngageWidgetResultProps {
  item: IVivaEngageResult
  deviceSettings: IDeviceSetting
  index: number
  aadInfo: IAADState
}

export default function RightVivaEngageWidgetResult(
  props: RightVivaEngageWidgetResultProps
): JSX.Element {
  const { item, deviceSettings, index, aadInfo } = props

  const DOMPurify = createDOMPurify(window)

  const rightWidgetClasses = getStylesRightWidget()
  const classes = getStylesRightVivaEngageWidget()

  const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis)
  const ResponsiveHTMLEllipsis = responsiveHOC()(HTMLEllipsis)

  const [expanded, setExpanded] = useState<boolean>(false)
  const [pictureUrl, setPictureUrl] = React.useState('')

  const intl = useIntl()

  const userProfileStyles = makeStyles(() => ({
    peopleProfilepic: {
      width: '35px',
      height: '35px',
      borderRadius: '50%',
      backgroundImage: `url('${
        pictureUrl
          ? pictureUrl.replace('MThumb', 'LThumb').split(' ').join('%20')
          : ''
      }'),url('${PeoplePlaceholder}') !important`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center'
    },
    defaultProfilepic: {
      backgroundImage: 'url(' + PeoplePlaceholder + ')'
    }
  }))
  const userProfileClasses = userProfileStyles()

  const loadProfilePic = async () => {
    const pictureUrl = await getProfilePicture(
      item.sender.email,
      null,
      deviceSettings,
      aadInfo
    )
    setPictureUrl(pictureUrl)
  }

  useEffect(() => {
    loadProfilePic()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box key={'rn' + index} className={classes.widgetItem}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item width={'90%'}>
          <Grid container direction={'row'}>
            <Grid item className={classes.profilepicWrapper}>
              <div
                className={
                  userProfileClasses.peopleProfilepic +
                  ' ' +
                  userProfileClasses.defaultProfilepic
                }
              />
            </Grid>
            <Grid item width="80%">
              <Link
                underline={'none'}
                key={'rnl1' + index}
                onClick={() => {
                  trackRightWidgetClick({
                    title: DOMPurify.sanitize(item.sender.full_name),
                    url: DOMPurify.sanitize(item.web_url),
                    index,
                    widgetKey: WidgetKey.vivaengage
                  })
                }}
                {...(!deviceSettings.isMobile
                  ? {
                      onAuxClick: (event: any) => {
                        trackRightWidgetClick({
                          title: DOMPurify.sanitize(item.sender.full_name),
                          url: DOMPurify.sanitize(item.web_url),
                          index,
                          widgetKey: WidgetKey.vivaengage,
                          wasRightClicked: event?.button === 2 ? true : false
                        })
                      }
                    }
                  : {})}
                href={DOMPurify.sanitize(item.web_url)}
                {...(deviceSettings.openLinksInNewTab === true
                  ? { target: '_blank', rel: 'noreferrer' }
                  : {})}
              >
                <div>
                  <TooltipTitle
                    title={DOMPurify.sanitize(item.sender.full_name.trim())}
                    key={'rnd1' + index}
                    singleLine={true}
                    additionalClass={`${rightWidgetClasses.widgetTitle} ${classes.widgetTitle}`}
                  />
                  <Typography className={classes.widgetSubTitle}>
                    {`${intl.formatDate(
                      DOMPurify.sanitize(item.published_at),
                      dateFormatOptions
                    )}`}
                  </Typography>
                </div>
              </Link>
            </Grid>
          </Grid>
        </Grid>
        {item.body && item.body.rich && (
          <Grid item>
            <IconButton
              aria-label="expand entry"
              size="small"
              className={classes.mobileCollapseIcon}
              onClick={() => setExpanded(!expanded)}
            >
              {expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </Grid>
        )}
      </Grid>
      <div>
        {!expanded && (
          <ResponsiveHTMLEllipsis
            className={`${rightWidgetClasses.widgetDescription} ${rightWidgetClasses.widgetDescriptionExtended} ${classes.widgetDescription}`}
            basedOn="words"
            unsafeHTML={DOMPurify.sanitize(item.content_excerpt.trim())}
            maxLine=""
          />
        )}
        {expanded && (
          <Typography
            className={`${classes.vivaEngageExtendedDescription}`}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(item.body.rich, {
                ADD_ATTR: ['target']
              })
            }}
          />
        )}
      </div>

      <Link
        underline={'none'}
        key={'rnl2' + index}
        onClick={() => {
          trackRightWidgetClick({
            title: DOMPurify.sanitize(
              item.group.full_name !== ''
                ? item.group.full_name
                : item.sender.full_name
            ),
            url: DOMPurify.sanitize(
              item.group.web_url ? item.group.web_url : item.web_url
            ),
            index,
            widgetKey: WidgetKey.vivaengage
          })
        }}
        {...(!deviceSettings.isMobile
          ? {
              onAuxClick: (event: any) => {
                trackRightWidgetClick({
                  title: DOMPurify.sanitize(
                    item.group.full_name !== ''
                      ? item.group.full_name
                      : item.sender.full_name
                  ),
                  url: DOMPurify.sanitize(
                    item.group.web_url !== ''
                      ? item.group.web_url
                      : item.web_url
                  ),
                  index,
                  widgetKey: WidgetKey.vivaengage,
                  wasRightClicked: event?.button === 2 ? true : false
                })
              }
            }
          : {})}
        href={DOMPurify.sanitize(
          item.group.web_url !== '' ? item.group.web_url : item.web_url
        )}
        {...(deviceSettings.openLinksInNewTab === true
          ? { target: '_blank', rel: 'noreferrer' }
          : {})}
      >
        <ResponsiveEllipsis
          className={rightWidgetClasses.widgetFooter}
          basedOn="words"
          text={`${DOMPurify.sanitize(
            item.group.full_name !== ''
              ? item.group.full_name
              : intl.formatMessage({
                  id: 'vivaengage_storyline',
                  defaultMessage: 'Storyline'
                })
          )}`}
        />
      </Link>
    </Box>
  )
}
