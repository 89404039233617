import React from 'react'
import { connect } from 'react-redux'
import { Store } from 'store'
import SettingsStore from 'store/Settings'
/* eslint-disable jsx-a11y/anchor-is-valid */
import LeftArrowIcon from '@mui/icons-material/KeyboardArrowLeft'
import RightArrowIcon from '@mui/icons-material/KeyboardArrowRight'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import { removeQueryParam } from 'utils/queryParams'
import { useNavigate } from 'react-router-dom'
import { Button, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { getStylesPagination } from 'styles/contents/Pagination'
import { useFilter } from 'utils/filters'

export interface PaginationProps {
  totalRecords: number
  pageLimit: number
  pageNeighbours: number
  loadMore?: boolean
  lastRow?: number
}
type AllProps = ReturnType<typeof mapStateToProps> & PaginationProps

function Pagination(props: AllProps): JSX.Element {
  const navigateFunction = useNavigate()

  const { totalRecords, pageLimit, pageNeighbours, deviceSettings, loadMore } =
    props

  const LEFT_PAGE = 'LEFT'
  const RIGHT_PAGE = 'RIGHT'

  const classes = getStylesPagination()
  const [currentPageStore, setCurrentPageStore] = useFilter('page', '1')

  const setCurrentPage = (page: number) => {
    setCurrentPageStore(page.toString())
  }
  const currentPage = parseInt(currentPageStore)

  const totalPages = Math.ceil(totalRecords / pageLimit)

  const range = (from: any, to: number, step = 1) => {
    let i = from
    const range = []
    while (i <= to) {
      range.push(i)
      i += step
    }
    return range
  }

  const fetchPageNumber = () => {
    /**
     * totalNumbers: the total page numbers to show on the control
     * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
     */
    const totalNumbers = pageNeighbours * 2 + 3
    const totalBlocks = totalNumbers + 2

    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, currentPage - pageNeighbours)
      const endPage = Math.min(totalPages, currentPage + pageNeighbours)
      let pages = range(startPage, endPage)

      /**
       * hasLeftSpill: has hidden pages to the left
       * hasRightSpill: has hidden pages to the right
       * spillOffset: number of hidden pages either to the left or to the right
       */
      const hasLeftSpill = startPage > 2
      const hasRightSpill = totalPages - endPage > 1
      const spillOffset = totalNumbers - (pages.length + 1)

      switch (true) {
        // handle: (1) < {5 6} [7] {8 9} (10)
        case hasLeftSpill && !hasRightSpill: {
          const extraPages = range(startPage - spillOffset, startPage - 1)
          pages = [LEFT_PAGE, ...extraPages, ...pages]
          break
        }

        // handle: (1) {2 3} [4] {5 6} > (10)
        case !hasLeftSpill && hasRightSpill: {
          const extraPages = range(endPage + 1, endPage + spillOffset)
          pages = [1, ...pages, ...extraPages, RIGHT_PAGE]
          break
        }

        // handle: (1) < {4 5} [6] {7 8} > (10)
        case hasLeftSpill && hasRightSpill:
        default: {
          pages = [LEFT_PAGE, ...pages, RIGHT_PAGE]
          break
        }
      }
      return [...pages]
    }
    return range(1, totalPages)
  }

  const pages = fetchPageNumber()

  return (
    <>
      {deviceSettings.renderMobile || loadMore ? (
        <LoadMoreResults {...props} />
      ) : (
        <div id={'con-pagination-01'} className={classes.paginationContainer}>
          <div className={classes.pagination}>
            {pages.map((page, index) => {
              if (page === LEFT_PAGE)
                return (
                  <div key={index} className={classes.pageItem}>
                    <a
                      tabIndex={0}
                      className={classes.pageLink}
                      onClick={() => {
                        setCurrentPage(currentPage - 1)
                        window.scrollTo(0, 0)
                        removeQueryParam(navigateFunction, 'cntx')
                      }}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          setCurrentPage(currentPage - 1)
                          window.scrollTo(0, 0)
                          removeQueryParam(navigateFunction, 'cntx')
                        }
                      }}
                      aria-label="Previous"
                    >
                      <LeftArrowIcon className={classes.pageLinkIcon} />
                    </a>
                  </div>
                )
              if (page === RIGHT_PAGE)
                return (
                  <div key={index} className={classes.pageItem}>
                    <a
                      tabIndex={0}
                      className={classes.pageLink}
                      onClick={() => {
                        setCurrentPage(currentPage + 1)
                        window.scrollTo(0, 0)
                        removeQueryParam(navigateFunction, 'cntx')
                      }}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          setCurrentPage(currentPage + 1)
                          window.scrollTo(0, 0)
                          removeQueryParam(navigateFunction, 'cntx')
                        }
                      }}
                      aria-label="Next"
                    >
                      <RightArrowIcon className={classes.pageLinkIcon} />
                    </a>
                  </div>
                )
              return (
                <div key={index} className={classes.pageItem}>
                  <a
                    tabIndex={0}
                    className={classes.pageLink}
                    onClick={() => {
                      setCurrentPage(page)
                      window.scrollTo(0, 0)
                      removeQueryParam(navigateFunction, 'cntx')
                    }}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        setCurrentPage(page)
                        window.scrollTo(0, 0)
                        removeQueryParam(navigateFunction, 'cntx')
                      }
                    }}
                  >
                    {page === currentPage && (
                      <b className={classes.selected}>{page}</b>
                    )}
                    {page !== currentPage && page}
                  </a>
                </div>
              )
            })}
          </div>
        </div>
      )}
    </>
  )
}

export function LoadMoreResults(props: AllProps): JSX.Element {
  const navigateFunction = useNavigate()

  const { totalRecords, pageLimit, deviceSettings } = props

  const classes = getStylesPagination()
  const [currentPageStore, setCurrentPageStore] = useFilter('page', '1')

  const setCurrentPage = (page: number) => {
    setCurrentPageStore(page.toString())
  }
  const currentPage = parseInt(currentPageStore)

  const totalPages = Math.ceil(totalRecords / pageLimit)

  const handleClick = (e: any) => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1)
      removeQueryParam(navigateFunction, 'cntx')
      e.preventDefault()
    }
  }

  return (
    <>
      {currentPage === totalPages ? null : (
        <div
          id="Container"
          className={
            !deviceSettings.renderMobile
              ? classes.paginationOneIntranetContainer
              : classes.loadMoreContainer
          }
        >
          <Button
            tabIndex={0}
            color="inherit"
            disabled={currentPage === totalPages}
            className={classes.loadMoreButton}
            variant="outlined"
            onClick={handleClick}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                handleClick(event)
              }
            }}
          >
            {deviceSettings.renderMobile && (
              <KeyboardArrowDown className={classes.loadMoreCaret} />
            )}
            <Typography className={classes.loadMoreText}>
              <FormattedMessage
                id="more_results"
                defaultMessage="More results"
              />
            </Typography>
          </Button>
        </div>
      )}
    </>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state)
  }
}

export default connect(mapStateToProps)(Pagination)
