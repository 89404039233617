import { FeaturedResult } from 'components/models/FeaturedResult'
import { Reducer } from 'redux'
import {
  ResultsResearchActions,
  ResultsResearchActionTypes,
  IFetchSuccess,
  IFetchSuccessCombined
} from './actions'

export interface IResearchResult {
  titles: Array<any>
  resultBodies: Array<any>
  resultUrls: Array<any>
  imageUrls: Array<any>
}
export interface IResultsResearchStore {
  hasResultsBeenFetched: boolean
  featuredResults: FeaturedResult[]
  results: IResearchResult
  resultsCombined: IResearchResult
  resultsCombinedQuery: string
  resultCount: number
  executionTime: number
  currentPage: number
}
const initialResultState = () => ({
  titles: [],
  resultBodies: [],
  resultUrls: [],
  imageUrls: []
})

const initialState: IResultsResearchStore = {
  hasResultsBeenFetched: false,
  resultCount: -1,
  featuredResults: [],
  results: initialResultState(),
  resultsCombined: initialResultState(),
  resultsCombinedQuery: '',
  executionTime: 0,
  currentPage: 1
}

const reducers: Reducer<IResultsResearchStore, ResultsResearchActions> = (
  state: IResultsResearchStore = initialState,
  action: ResultsResearchActions | { type: 'SET_CURRENT_PAGE'; payload: any }
) => {
  switch (action.type) {
    case 'SET_CURRENT_PAGE':
      return {
        ...state,
        currentPage: action.payload || 0
      }
    case ResultsResearchActionTypes.INITIALIZE_RESULTS_RESEARCH:
      if (!(action as any).payload) {
        return initialState
      }
      const { resultsResearch } = (action as any).payload
      return {
        ...state,
        currentPage: resultsResearch.currentPage || 1
      }
    case ResultsResearchActionTypes.FETCH_REQUEST:
      const { resetCombined, searchQuery } = (action as any).payload
      return {
        ...state,
        hasResultsBeenFetched: false,
        executionTime: 0,
        resultCount: -1,
        results: initialResultState(),
        resultsCombined: resetCombined
          ? initialResultState()
          : state.resultsCombined,
        resultsCombinedQuery: searchQuery
      }
    case ResultsResearchActionTypes.FETCH_FAILURE:
      return {
        ...state,
        hasResultsBeenFetched: true,
        resultCount: 0
      }
    case ResultsResearchActionTypes.FETCH_SUCCESS:
      const { response, featuredResults } = (action as IFetchSuccess).payload
      return {
        ...state,
        hasResultsBeenFetched: true,
        executionTime: response.executionTime,
        resultCount: response.resultCount,
        featuredResults:
          response.currentPage === 1 ? featuredResults : state.featuredResults,
        results: {
          resultBodies: response.resultBodies,
          resultUrls: response.resultUrls,
          titles: response.titles,
          imageUrls: response.imageUrls
        },
        currentPage: response.currentPage
      }
    case ResultsResearchActionTypes.FETCH_SUCCESS_COMBINED:
      const { response: responseCombined } = (action as IFetchSuccessCombined)
        .payload
      return {
        ...state,
        resultsCombined: {
          resultBodies: responseCombined.resultBodies,
          resultUrls: responseCombined.resultUrls,
          titles: responseCombined.titles,
          imageUrls: responseCombined.imageUrls
        }
      }
    default:
      return state
  }
}

export default reducers
