import { AnyAction } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { Store } from '..'
import AuthStore from '../Auth'
import * as Config from 'config'
import { stripHtml } from 'utils/string'
import { FetchWithCache } from 'utils/api'
import { marketresearch } from 'constants/filters'
import Selectors from './selectors'
import ResultMetaDataStore from 'store/ResultMetaData'
import { IUserSetting } from 'utils/userSettings'
import { IDeviceSetting } from 'utils/deviceSettings'
import { renewAuthorizationToken } from 'utils/token'
import {
  ActionMetaData,
  CustomAction,
  getActionMetaData
} from 'store/extension/customAction'
import { FeaturedResult } from 'components/models/FeaturedResult'
import { IFindConfiguration } from 'store/Settings/reducers'
import { removeStopWordsFromQuery } from 'utils/oneIntranet'
import { ISynonymsApplied } from 'components/models/SynonymsApplied'
import SettingsStore from 'store/Settings'
import { unifysearchQuery } from 'utils/unifysearchQuery'
import { IMarketResearchResult } from 'components/models/MarketResearchResult'

export enum ResultsMarketResearchActionTypes {
  FETCH_REQUEST = 'resultMarketResearch/FETCH_REQUEST',
  FETCH_SUCCESS = 'resultMarketResearch/FETCH_SUCCESS',
  FETCH_SUCCESS_COMBINED = 'resultMarketResearch/FETCH_SUCCESS_COMBINED',
  FETCH_FAILURE = 'resultMarketResearch/FETCH_FAILURE',
  FETCH_REQUEST_COUNT = 'resultMarketResearch/FETCH_REQUEST_COUNT',
  FETCH_REQUEST_COUNT_SUCCESS = 'resultMarketResearch/FETCH_REQUEST_COUNT_SUCCESS',
  FETCH_REQUEST_COUNT_FAILURE = 'resultMarketResearch/FETCH_REQUEST_COUNT_FAILURE',
  INITIALIZE_RESULTS_MARKET_RESEARCH = 'persist/REHYDRATE'
}

export interface IFetchRequest
  extends CustomAction<ResultsMarketResearchActionTypes> {
  payload: {
    resetCombined: boolean
    searchQuery: string
  }
}
export type IFetchFailure = CustomAction<ResultsMarketResearchActionTypes>
export interface IFetchSuccess
  extends CustomAction<ResultsMarketResearchActionTypes> {
  payload: {
    response: {
      queryResults: IMarketResearchResult[]
      executionTime: number
      currentPage: number
    }
    featuredResults: FeaturedResult[]
    synonymsApplied: ISynonymsApplied[]
  }
}
export interface IFetchSuccessCombined
  extends CustomAction<ResultsMarketResearchActionTypes> {
  payload: {
    response: {
      queryResults: IMarketResearchResult[]
    }
  }
}
export type IFetchRequestCount = CustomAction<ResultsMarketResearchActionTypes>
export type IFetchRequestCountFailure =
  CustomAction<ResultsMarketResearchActionTypes>
export interface IFetchRequestCountSuccess
  extends CustomAction<ResultsMarketResearchActionTypes> {
  payload: {
    resultCount: any
  }
}

export const fetchRequest = (
  resetCombined: boolean,
  searchQuery: string,
  actionMetaData: ActionMetaData
): IFetchRequest => ({
  type: ResultsMarketResearchActionTypes.FETCH_REQUEST,
  payload: {
    resetCombined: resetCombined,
    searchQuery: searchQuery
  },
  metaData: {
    ...actionMetaData,
    isStartAction: true
  }
})
export const fetchSuccess = (
  response: {
    queryResults: IMarketResearchResult[]
    executionTime: number
    currentPage: number
  },
  featuredResults: FeaturedResult[],
  synonymsApplied: ISynonymsApplied[],
  actionMetaData: ActionMetaData
): IFetchSuccess => ({
  type: ResultsMarketResearchActionTypes.FETCH_SUCCESS,
  payload: { response, featuredResults, synonymsApplied },
  metaData: actionMetaData
})
export const fetchSuccessCombined = (
  response: {
    queryResults: IMarketResearchResult[]
  },
  actionMetaData: ActionMetaData
): IFetchSuccessCombined => ({
  type: ResultsMarketResearchActionTypes.FETCH_SUCCESS_COMBINED,
  payload: { response },
  metaData: actionMetaData
})
export const fetchFailure = (
  actionMetaData: ActionMetaData
): IFetchFailure => ({
  type: ResultsMarketResearchActionTypes.FETCH_FAILURE,
  metaData: actionMetaData
})
export const fetchRequestCount = (
  actionMetaData: ActionMetaData
): IFetchRequestCount => ({
  type: ResultsMarketResearchActionTypes.FETCH_REQUEST_COUNT,
  metaData: {
    ...actionMetaData,
    isStartAction: true
  }
})
export const fetchRequestCountSuccess = (
  resultCount: any = {},
  actionMetaData: ActionMetaData
): IFetchRequestCountSuccess => ({
  type: ResultsMarketResearchActionTypes.FETCH_REQUEST_COUNT_SUCCESS,
  payload: { resultCount },
  metaData: actionMetaData
})
export const fetchRequestCountFailure = (
  actionMetaData: ActionMetaData
): IFetchRequestCountFailure => ({
  type: ResultsMarketResearchActionTypes.FETCH_REQUEST_COUNT_FAILURE,
  metaData: actionMetaData
})

export const fetchDocumentsResultsMarketResearch = (
  searchQuery: string,
  currentPage: number,
  userSettings: IUserSetting,
  deviceSettings: IDeviceSetting,
  filters: {
    [key: string]: string
  },
  findConfiguration: IFindConfiguration
  // eslint-disable-next-line @typescript-eslint/ban-types
): ThunkAction<Promise<void>, Store, {}, AnyAction> => {
  return async (
    // eslint-disable-next-line @typescript-eslint/ban-types
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => Store
  ) => {
    const actionMetaData = getActionMetaData('marketresearch')

    try {
      let lastResults = null
      if (currentPage > 1) {
        lastResults = Selectors.getResultsCombined(getState())
      }

      dispatch(
        ResultMetaDataStore.actions.fetchRequest(
          'marketresearch',
          actionMetaData
        )
      )
      const resultsCombinedQuery = Selectors.getResultsCombinedQuery(getState())
      if (resultsCombinedQuery !== searchQuery) {
        if (resultsCombinedQuery === '') {
          dispatch(fetchRequest(false, searchQuery, actionMetaData))
        } else {
          dispatch(fetchRequest(true, searchQuery, actionMetaData))
        }
      } else {
        dispatch(fetchRequest(false, searchQuery, actionMetaData))
      }

      if (!searchQuery || searchQuery === '' || searchQuery === '""') {
        dispatch(fetchFailure(actionMetaData))
        return
      }

      const t0 = performance.now()

      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        throw new Error('Authentication: Cannot renew authentication token')
      }

      const useCognitiveSearch = SettingsStore.selectors.getUseCognitiveSearch(
        getState()
      )

      let searchQueryLanguage = ''
      if (useCognitiveSearch && findConfiguration.CognitiveSearchEnabled) {
        const detectLanguageApiUrl = `${
          Config.APIM_BASE_URL
        }searchapi/detectlanguage?searchQuery=${encodeURIComponent(
          searchQuery
        )}`

        const languageResponse = await FetchWithCache(detectLanguageApiUrl, {
          method: 'POST',
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
            Authorization: `Bearer ${esToken}`
          }
        })

        if (
          languageResponse &&
          !languageResponse.hasError &&
          languageResponse.responseJSON
        ) {
          const languageJSON = languageResponse.responseJSON
          if (languageJSON && languageJSON.responseCode === 200) {
            searchQueryLanguage = languageJSON.language
          }
        }
      }

      searchQuery = removeStopWordsFromQuery(
        searchQuery,
        searchQueryLanguage,
        useCognitiveSearch,
        findConfiguration.CognitiveSearchEnabled
      )

      let apiUrl = `${
        Config.APIM_BASE_URL
      }marketresearchapi/postdocumentsearch?query=${unifysearchQuery(
        searchQuery,
        'marketresearch'
      )}&maxRows=20&processFeaturedResults=${
        (currentPage || 1) === 1 ? true : false
      }&featuredResultsRowLimit=3&origin=KPMGFind&featuredResultsCountry=${
        userSettings.Country
      }&function=${userSettings.Function}`

      // Sorting
      let orderByValue = 'DateDesc'
      if (filters.orderBy) {
        const filterOBCat = marketresearch.find(
          (fl: FilterCategory) => fl.key === 'orderBy'
        )
        if (filterOBCat) {
          const filterOBOpt = filterOBCat.options.find(
            (opt: Filter) => opt.key === filters.orderBy
          )
          if (filterOBOpt && filterOBOpt.value) {
            orderByValue = filterOBOpt.value
          }
        }
      }
      apiUrl += `&orderBy=${orderByValue}`

      // Filtering
      if (filters.countrymr && filters.countrymr !== '') {
        apiUrl += `&country=${filters.countrymr}`
      }

      if (filters.language && filters.language !== '') {
        const language = filters.language.startsWith('zh')
          ? 'zh'
          : filters.language.startsWith('pt')
          ? 'pt'
          : filters.language
        apiUrl += `&langID=${language}`
      }

      if (filters.ptkr && filters.ptkr !== '') {
        apiUrl += `&ticker=${filters.ptkr}`
      }

      // Paging
      if (currentPage > 1) {
        if (lastResults && lastResults.length > 0) {
          if (orderByValue.startsWith('DateDesc')) {
            const d = Date.parse(
              lastResults[lastResults.length - 1].ReleaseDate
            )
            apiUrl += `&hintStr=${Math.round(d / 1000)}`
          } else if (orderByValue.startsWith('DateAsc')) {
            const d = Date.parse(lastResults[lastResults.length - 1].ArriveDate)
            apiUrl += `&hintStr=${Math.round(d / 1000)}`
          } else if (orderByValue.startsWith('PrimaryTicker')) {
            apiUrl += `&hintStr=${
              lastResults[lastResults.length - 1].PrimaryTicker
            }`
          } else if (orderByValue.startsWith('Pages')) {
            apiUrl += `&hintStr=${
              lastResults[lastResults.length - 1].Pages === -1
                ? 0
                : lastResults[lastResults.length - 1].Pages
            }`
          }
          apiUrl += `&docId=${lastResults[lastResults.length - 1].Id}`
        }
      }

      // Cognitive enabled
      if (useCognitiveSearch && findConfiguration.CognitiveSearchEnabled) {
        apiUrl += `&cognitiveEnabled=true`
      }

      const response = await FetchWithCache(apiUrl, {
        method: 'POST',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${esToken}`
        }
      })

      let results
      if (!response || response.hasError || !response.responseJSON) {
        dispatch(
          ResultMetaDataStore.actions.hasError(
            'marketresearch',
            response.errorResponse,
            undefined,
            actionMetaData
          )
        )
        dispatch(fetchFailure(actionMetaData))
        return
      } else {
        results = response.responseJSON
      }

      const featuredResults =
        results && results.FeaturedResults ? results.FeaturedResults : []

      const synonymsApplied =
        results && results.SynonymsApplied ? results.SynonymsApplied : []

      const t1 = performance.now()

      const executionTime = t1 - t0

      if (results && 'Documents' in results) {
        const marketresearchResults: IMarketResearchResult[] = []

        results.Documents.forEach((i: any) => {
          const marketResearchResult: IMarketResearchResult = {
            Id: i.Id ? i.Id : -1,
            Relevance: i.Relevance ? i.Relevance : -1,
            ArriveDate: i.ArriveDate ? i.ArriveDate : '',
            ReleaseDate: i.ReleaseDate ? i.ReleaseDate : '',
            FileType: i.FileType ? i.FileType : '',
            FileName: i.FileName ? i.FileName : '',
            FileSize: i.FileSize ? i.FileSize : -1,
            CompanyName: i.CompanyName ? i.CompanyName : '',
            Headline: i.Headline ? stripHtml(i.Headline) : '',
            PrimaryTicker: i.PTck ? stripHtml(i.PTck) : '',
            Synopsis: i.Synopsis ? stripHtml(i.Synopsis) : '',
            Pages: i.Pages ? i.Pages : -1,
            Language: i.Language ? stripHtml(i.Language) : '',
            Link: i.Id
              ? 'http://docs.rkd.refinitiv.com/api/DocumentRetrieval/DocumentRetrieval.svc/docs/' +
                i.Id.toString() +
                '/' +
                i.FileType +
                (i.FileType === 'web' ? '/' : '')
              : ''
          }

          marketresearchResults.push(marketResearchResult)
        })

        dispatch(
          fetchSuccess(
            {
              queryResults: marketresearchResults,
              executionTime,
              currentPage: currentPage || 1
            },
            featuredResults,
            synonymsApplied,
            actionMetaData
          )
        )
        dispatch(
          ResultMetaDataStore.actions.fetchSuccessExecutionTime(
            'marketresearch',
            {
              executionTime: executionTime
            },
            actionMetaData
          )
        )

        const resultsCombined = Selectors.getResultsCombined(getState())
        if ((currentPage || 1) === 1) {
          dispatch(
            fetchSuccessCombined(
              {
                queryResults: marketresearchResults
              },
              actionMetaData
            )
          )
        } else {
          dispatch(
            fetchSuccessCombined(
              {
                queryResults: [...resultsCombined, ...marketresearchResults]
              },
              actionMetaData
            )
          )
        }
      } else {
        dispatch(
          fetchSuccess(
            {
              queryResults: [],
              executionTime,
              currentPage: currentPage || 1
            },
            featuredResults,
            synonymsApplied,
            actionMetaData
          )
        )
      }
    } catch (error) {
      dispatch(
        ResultMetaDataStore.actions.hasError(
          'marketresearch',
          undefined,
          error,
          actionMetaData
        )
      )
      dispatch(fetchFailure(actionMetaData))
    }
  }
}

export const fetchDocumentsCountMarketResearch = (
  searchQuery: string,
  currentPage: number,
  userSettings: IUserSetting,
  deviceSettings: IDeviceSetting,
  filters: {
    [key: string]: string
  }
  // eslint-disable-next-line @typescript-eslint/ban-types
): ThunkAction<Promise<void>, Store, {}, AnyAction> => {
  return async (
    // eslint-disable-next-line @typescript-eslint/ban-types
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => Store
  ) => {
    const actionMetaData = getActionMetaData('marketresearch', 'count')

    try {
      dispatch(fetchRequestCount(actionMetaData))

      if (!searchQuery || searchQuery === '' || searchQuery === '""') {
        dispatch(fetchRequestCountFailure(actionMetaData))
        return
      }

      let apiUrl = `${
        Config.APIM_BASE_URL
      }marketresearchapi/postdocumentsearchcount?query=${unifysearchQuery(
        searchQuery,
        'marketresearch'
      )}`

      // Filtering
      if (filters.countrymr && filters.countrymr !== '') {
        apiUrl += `&country=${filters.countrymr}`
      }

      if (filters.language && filters.language !== '') {
        const language = filters.language.startsWith('zh')
          ? 'zh'
          : filters.language.startsWith('pt')
          ? 'pt'
          : filters.language
        apiUrl += `&langID=${language}`
      }

      if (filters.ptkr && filters.ptkr !== '') {
        apiUrl += `&ticker=${filters.ptkr}`
      }

      // Get and check authentication token
      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        throw new Error('Authentication: Cannot renew authentication token')
      }

      const response = await FetchWithCache(apiUrl, {
        method: 'POST',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${esToken}`
        }
      })

      let results
      if (!response || response.hasError || !response.responseJSON) {
        dispatch(
          ResultMetaDataStore.actions.hasError(
            'marketresearch',
            response.errorResponse,
            undefined,
            actionMetaData
          )
        )
        dispatch(fetchFailure(actionMetaData))
        return
      } else {
        results = response.responseJSON
      }

      if (results && 'TotalResults' in results) {
        const resultCount = parseInt(results.TotalResults) || 0

        dispatch(
          fetchRequestCountSuccess(
            {
              resultCount: resultCount
            },
            actionMetaData
          )
        )
        dispatch(
          ResultMetaDataStore.actions.fetchSuccessCount(
            'marketresearch',
            {
              resultsCount: resultCount
            },
            actionMetaData
          )
        )
      }
    } catch (error) {
      dispatch(
        ResultMetaDataStore.actions.hasError(
          'marketresearch',
          undefined,
          error,
          actionMetaData
        )
      )
      dispatch(fetchFailure(actionMetaData))
    }
  }
}

export type ResultsMarketResearchActions =
  | IFetchRequest
  | IFetchSuccess
  | IFetchFailure
  | IFetchRequestCount
  | IFetchRequestCountSuccess
  | IFetchRequestCountFailure
