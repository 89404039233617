import { FeaturedResult } from 'components/models/FeaturedResult'
import { Store } from '..'
import { ISynonymsApplied } from 'components/models/SynonymsApplied'
import { ISourceResult } from 'components/models/SourceResult'

const selectors = {
  getFeaturedResults: (state: Store): FeaturedResult[] =>
    state.resultsSource.featuredResults,
  getSynonymsApplied: (state: Store): ISynonymsApplied[] =>
    state.resultsSource.synonymsApplied,
  getResults: (state: Store): ISourceResult[] => state.resultsSource.results,
  getResultsCombined: (state: Store): ISourceResult[] =>
    state.resultsSource.resultsCombined,
  getResultsCombinedQuery: (state: Store): string =>
    state.resultsSource.resultsCombinedQuery,
  hasResultsBeenFetched: (state: Store): boolean =>
    state.resultsSource.hasResultsBeenFetched,
  getResultCount: (state: Store): number => state.resultsSource.resultCount,
  getExecutionTime: (state: Store): number => state.resultsSource.executionTime,
  getCurrentPage: (state: Store): number => state.resultsSource.currentPage,
  getHasError: (state: Store): boolean => state.resultsSource.hasError,
  getLatestQuery: (state: Store): string =>
    state.resultsSource.resultsCombinedQuery
}

export default selectors
