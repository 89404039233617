import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesFilterMenuItems(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles() {
  return makeStyles((theme: Theme) => ({
    rootMenuItem: {
      padding: 0,
      flexDirection: 'column',
      alignItems: 'flex-start'
    },
    menuItem: {
      display: 'flex',
      flexDirection: 'row',
      whiteSpace: 'nowrap',
      justifyContent: 'flex-start',
      padding: '9px 0.8em',
      height: '19px',
      lineHeight: '20px',
      zIndex: 200,
      cursor: 'pointer',
      width: '93%',
      transition: 'background 0.3s',
      '&:hover': {
        backgroundColor: '#f7f7f7'
      },
      '&:focus': {
        backgroundColor: '#f7f7f7'
      }
    },
    gutters: {
      padding: 0
    },
    pictureContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '4px'
    },
    picture: {
      borderRadius: '50%',
      display: 'block',
      overflow: 'hidden',
      position: 'relative',
      height: '16px',
      width: '16px'
    },
    pictureFileType: {
      marginLeft: 3
    },
    textContainer: {
      minWidth: 0,
      fontSize: '14px',
      color: '#424242'
    },
    textContainerNews: {
      marginRight: '3px'
    },
    textDetectableOverflow: {
      margin: 0,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    badge: {
      color: '#424242',
      backgroundColor: '#F4F4F5',
      borderRadius: 2,
      padding: 4,
      display: 'flex',
      alignItems: 'center',
      marginLeft: 'auto'
    },
    badgeCount: {
      fontSize: 14
    },
    checkBox: {
      flex: '1',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      [`${theme.breakpoints.down(1024)}`]: {
        marginRight: '10px'
      }
    },
    menuItemContainer: {
      width: '100%'
    }
  }))
}
