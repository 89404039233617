import sessionCache from './cache'
import { trackException } from 'utils/tracking'
import { fetchRequestAndQueueFetches } from './apiQueue'
import * as Config from 'config'
import { KPMGFindGlobalVariables } from 'store/KPMGFindGlobalVariables'

const cjson = require('compressed-json')
const TIMESTAMP_KEY = '@request_cache_ts'
export const MAX_TIME_CACHE = 600000
const MAX_ITERATIONS_REDUCE_STORAGE = 100
const MAX_CACHED_CONTENT_SIZE = 4000

// Note that this function is executed asynchronously several times in parallel
// work in memory and only update localstorage on change to prevent overwriting
let TIMESTAMP_CACHE: any = null

export interface IFindResponse {
  hasError: boolean
  responseJSON?: any
  errorResponse?: IErrorResponse
  ESTimeInQueue?: number
}

export interface IErrorResponse {
  responseCode?: number
  message: string
  messageKey?: string
  isApimError?: boolean
  orginalResponseBody?: string | null
  internalError?: boolean
  exception?: Error
}

export const FetchWithCache = async (
  input: string,
  init?: RequestInit | undefined,
  hasOIError?: boolean | undefined,
  queueFetch?: boolean | undefined,
  identifier?: string,
  isCurrentDataSourceFetch?: boolean,
  searchQuery?: string
): Promise<IFindResponse> => {
  const cacheId = minifyCacheKey(input, init)
  return FetchWithCacheByCacheKey(
    cacheId,
    input,
    init,
    hasOIError,
    false,
    queueFetch,
    identifier,
    isCurrentDataSourceFetch,
    searchQuery
  )
}

export const FetchWithCacheAndTracking = async (
  input: string,
  init?: RequestInit | undefined,
  hasOIError?: boolean | undefined,
  queueFetch?: boolean | undefined,
  identifier?: string,
  isCurrentDataSourceFetch?: boolean,
  searchQuery?: string
): Promise<IFindResponse> => {
  const cacheId = minifyCacheKey(input, init)
  return FetchWithCacheByCacheKey(
    cacheId,
    input,
    init,
    hasOIError,
    true,
    queueFetch,
    identifier,
    isCurrentDataSourceFetch,
    searchQuery
  )
}

export const FetchWithCacheByCacheKey = async (
  cacheKey: string,
  queryUrl: string,
  init?: RequestInit | undefined,
  hasOIError?: boolean | undefined,
  trackExceptions?: boolean,
  queueFetch?: boolean | undefined,
  identifier?: string,
  isCurrentDataSourceFetch?: boolean,
  searchQuery?: string
): Promise<IFindResponse> => {
  const simulateAPIError = KPMGFindGlobalVariables.getSimulateAPIError()
  if (simulateAPIError) {
    return {
      hasError: true,
      errorResponse: {
        responseCode:
          simulateAPIError === 'InvalidQuery'
            ? 400
            : simulateAPIError === 'AppGtwError'
            ? 403
            : 404,
        message: 'Error simulate API error',
        messageKey: 'error_expected_simulate_internal',
        isApimError: true,
        orginalResponseBody: 'Microsoft-Azure-Application-Gateway'
      }
    }
  }

  const TIMESTAMP = Date.now()

  if (!TIMESTAMP_CACHE) {
    TIMESTAMP_CACHE = sessionCache.get(TIMESTAMP_KEY) || {}

    let someItemDeleted = false
    for (const key in TIMESTAMP_CACHE) {
      if (TIMESTAMP > TIMESTAMP_CACHE[key] + MAX_TIME_CACHE) {
        sessionCache.delete(key)
        delete TIMESTAMP_CACHE[key]
        someItemDeleted = true
      }
    }

    if (someItemDeleted) sessionCache.set(TIMESTAMP_KEY, TIMESTAMP_CACHE)
  }

  if (TIMESTAMP_CACHE[cacheKey]) {
    const isStale = TIMESTAMP > TIMESTAMP_CACHE[cacheKey] + MAX_TIME_CACHE

    const resultObj = sessionCache.get(cacheKey)

    if (resultObj && !hasOIError) {
      if (!isStale) {
        const orgResult = cjson.decompress(resultObj)
        return { hasError: false, responseJSON: orgResult }
      } else {
        sessionCache.delete(cacheKey)
        delete TIMESTAMP_CACHE[cacheKey]
      }
    } else {
      delete TIMESTAMP_CACHE[cacheKey]
    }
  }

  let response = null
  try {
    response = await fetchRequestAndQueueFetches(
      queryUrl,
      init,
      queueFetch,
      identifier,
      isCurrentDataSourceFetch,
      searchQuery
    )
    if (response && response.ok) {
      let responseJSON = null
      try {
        responseJSON = await response.json()
      } catch (error) {
        if (trackExceptions)
          trackException(
            `Error while json decode in api.ts: ${queryUrl}`,
            error
          )
        return {
          hasError: true,
          errorResponse: {
            responseCode: 500,
            message: 'Error while processing the response json in api.ts',
            messageKey: 'error_unexpected_internal',
            internalError: true,
            exception: error instanceof Error ? error : undefined
          }
        }
      }

      // Only set timestamp to session storage in case the
      // save of the the response was successfull
      if (sessionCache.set(cacheKey, cjson.compress(responseJSON))) {
        TIMESTAMP_CACHE[cacheKey] = TIMESTAMP

        // in case the timestamp key can't be set in the
        // store remove also the cacheKey
        // otherwise it will be never removed
        if (!sessionCache.set(TIMESTAMP_KEY, TIMESTAMP_CACHE)) {
          sessionCache.delete(cacheKey)
        }
      }

      const findResponse: IFindResponse = {
        hasError: false,
        responseJSON: responseJSON
      }

      if ((response as any).ESTimeInQueue) {
        findResponse.ESTimeInQueue = (response as any).ESTimeInQueue
      }

      return findResponse
    } else {
      let errorResponseJSON = null
      try {
        errorResponseJSON = await response.json()
      } catch (error) {
        if (trackExceptions)
          trackException(
            `Error while error json decode in api.ts: ${queryUrl}`,
            error
          )
        return {
          hasError: true,
          errorResponse: {
            responseCode: response ? response.status : 500,
            message: response
              ? response.statusText
              : 'Error while error json decode in api.ts',
            messageKey: 'error_unexpected_internal',
            internalError: true,
            exception: error instanceof Error ? error : undefined
          }
        }
      }

      if (!errorResponseJSON || !errorResponseJSON.responseCode) {
        return {
          hasError: true,
          errorResponse: {
            responseCode: response ? response.status : 500,
            message: "Error backend didn't return a proper error response",
            messageKey: 'error_unexpected_internal',
            isApimError: true,
            orginalResponseBody: JSON.stringify(errorResponseJSON)
          }
        }
      }

      return { hasError: true, errorResponse: errorResponseJSON }
    }
  } catch (e) {
    response = {
      hasError: true,
      errorResponse: {
        message: 'Error while fetching in api.ts',
        messageKey: 'error_unexpected_internal',
        internalError: true,
        exception: e instanceof Error ? e : undefined
      }
    }
    if (trackExceptions)
      trackException(`Error while fetching in api.ts: ${queryUrl}`, e)
  }
  return response
}

export const CleanExpiredFromCache = async (): Promise<void> => {
  if (!TIMESTAMP_CACHE) {
    TIMESTAMP_CACHE = sessionCache.get(TIMESTAMP_KEY) || {}
  }
  const TIMESTAMP = Date.now()
  for (const key in TIMESTAMP_CACHE) {
    if (TIMESTAMP > TIMESTAMP_CACHE[key] + MAX_TIME_CACHE) {
      sessionCache.delete(key)
      delete TIMESTAMP_CACHE[key]
      sessionCache.set(TIMESTAMP_KEY, TIMESTAMP_CACHE)
    }
  }
}

export const ReduceSessionStorageSize = async (): Promise<void> => {
  let size = sessionCache.size()
  let iteration = 0
  while (
    size > MAX_CACHED_CONTENT_SIZE &&
    iteration < MAX_ITERATIONS_REDUCE_STORAGE
  ) {
    if (!TIMESTAMP_CACHE) {
      TIMESTAMP_CACHE = sessionCache.get(TIMESTAMP_KEY) || {}
    }
    for (const key in TIMESTAMP_CACHE) {
      sessionCache.delete(key)
      delete TIMESTAMP_CACHE[key]
      sessionCache.set(TIMESTAMP_KEY, TIMESTAMP_CACHE)
      break
    }
    size = sessionCache.size()
    iteration++
  }
}

const minifyCacheKey = (
  input: string,
  init?: RequestInit | undefined
): string => {
  let cacheId = `find_${minifyUrl(input)}`

  if (init?.body) {
    cacheId = `${minifyBody(init.body.toString(), cacheId)}`
  }

  return cacheId
}

const minifyUrl = (url: string): string => {
  if (!url) {
    return ''
  }

  let cleanUrlKey = url

  if (cleanUrlKey.startsWith(`${Config.APIM_BASE_URL}`)) {
    cleanUrlKey = cleanUrlKey.replace(`${Config.APIM_BASE_URL}`, '')
    if (cleanUrlKey.charAt(0) === '/') {
      cleanUrlKey = cleanUrlKey.substring(1)
    }
  }

  if (cleanUrlKey.indexOf('?') !== -1) {
    const urlParams = new URLSearchParams(cleanUrlKey.split('?')[1])

    const shortParams: string[] = []
    let i = 0
    urlParams.forEach((value) => {
      if (value) {
        shortParams.push(`${i}=${value}`)
        i++
      }
    })

    cleanUrlKey = `${cleanUrlKey.split('?')[0]}?${shortParams.join('&')}`
  }

  return cleanUrlKey
}

const minifyBody = (body: string, cacheKey: string): string => {
  if (!body) {
    return cacheKey
  }

  try {
    const json = JSON.parse(body)
    cacheKey = extendCacheKey(json, cacheKey)
  } catch {}

  return cacheKey
}

const extendCacheKey = (requestBody: any, orgCacheKey: string): string => {
  const extendedKeys: string[] = []
  let i = 0
  for (const [key, value] of Object.entries(requestBody)) {
    if (value && key) {
      if (typeof value === 'object') {
        for (const [key2, value2] of Object.entries(value)) {
          if (value2 && key2) {
            extendedKeys.push(
              `${i}=${encodeURIComponent(JSON.stringify(value2))}`
            )
            i++
          }
        }
      } else {
        extendedKeys.push(`${i}=${value}`)
        i++
      }
    }
  }

  if (extendedKeys.length < 1) {
    return orgCacheKey
  }

  const extendedKey = `${orgCacheKey}${
    orgCacheKey.indexOf('?') !== -1 ? '&' : '?'
  }${extendedKeys.join('&')}`

  return extendedKey
}
