import React, { Dispatch } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link
} from '@mui/material'
import Grid from '@mui/material/Grid'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
// import ImproveResultAbstractBad from 'images/improve-result-abstract-bad.png'
// import ImproveResultAbstract from 'images/improve-result-abstract.png'
// import ImproveResultTitleBad from 'images/improve-result-title-bad.png'
// import ImproveResultTitle from 'images/improve-result-title.png'
import 'Placeholder.css'
import { getStylesImproveResults } from 'styles/contents/ImproveResults'
import { ISeoCheckerData } from 'utils/seoChecker'

export interface ImproveResultsProps {
  open: boolean
  onClose: () => void
  setSeoCheckerModal: Dispatch<boolean>
  setSEOCheckerModalData: Dispatch<ISeoCheckerData>
  isSEOCheckerModalOpen: boolean
}

export default function ImproveResults(
  props: ImproveResultsProps
): JSX.Element {
  const classes = getStylesImproveResults()
  const {
    open,
    onClose,
    setSeoCheckerModal,
    isSEOCheckerModalOpen,
    setSEOCheckerModalData
  } = props

  const handleSeoChecker = () => {
    if (!isSEOCheckerModalOpen) {
      setSEOCheckerModalData({
        url: '',
        seoType: null,
        uniqueId: null,
        siteId: null
      })
      setSeoCheckerModal(true)
    }
    onClose()
  }
  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={
        isSEOCheckerModalOpen
          ? classes.dialogContainerTransparent
          : classes.dialogContainer
      }
    >
      <DialogTitle className={classes.dialogTitle}>
        <Grid container direction="column" justifyContent="center">
          <Grid item className={classes.dialogTitle}>
            Improve findability of your content
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Grid
          className={classes.dialogContentGrid}
          container
          direction="column"
          justifyContent="center"
          wrap="nowrap"
          spacing={3}
        >
          <Grid item className={classes.gridDescription}>
            <span>
              Here are some tips and tricks how you can make sure that people
              find your content and the algorithm ranks you high.
            </span>
          </Grid>
          <Grid
            item
            className={`${classes.gridItem} ${classes.sectionStarter}`}
          >
            <span>
              1. <strong>Titles</strong>: short and meaningful (70 characters)
            </span>
            <Grid
              container
              className={classes.itemImage}
              direction="row"
              justifyContent="center"
              alignContent="center"
              alignItems="center"
            >
              <CheckCircleOutlineIcon style={{ color: '#81c784' }} />
              <div
                style={{
                  flex: 1,
                  marginLeft: 8,
                  backgroundColor: '#fff',
                  padding: 8,
                  borderRadius: 4,
                  color: '#424242',
                  fontSize: 10
                }}
              >
                ADV › GO <p className={classes.docType}>PDF</p>
                <p
                  style={{
                    fontSize: 18,
                    color: '#004e98',
                    marginBlockStart: 8,
                    marginBlockEnd: 0,
                    margin: 0
                  }}
                >
                  Educational Institutions - Shared Services Design
                </p>
              </div>
            </Grid>
            <Grid
              container
              direction="row"
              className={classes.itemImage}
              justifyContent="center"
            >
              <ErrorOutlineIcon color="error" />
              <div
                style={{
                  flex: 1,
                  marginLeft: 8,
                  backgroundColor: '#fff',
                  padding: 8,
                  borderRadius: 4,
                  color: '#424242',
                  fontSize: 10
                }}
              >
                GCK › Meetings
                <p
                  style={{
                    fontSize: 18,
                    color: '#004e98',
                    marginBlockStart: 8,
                    marginBlockEnd: 0,
                    margin: 0
                  }}
                >
                  chasd jasdvfj ajshdf ja sdhf jhads jfh ajdshf jahds jf asdf
                </p>
              </div>
            </Grid>
          </Grid>
          <Grid
            item
            className={`${classes.gridItem} ${classes.sectionStarter}`}
          >
            <span>
              2. <strong>Abstract</strong>: available and focused (220
              characters)
            </span>
            <div className={classes.itemImage}>
              <CheckCircleOutlineIcon style={{ color: '#81c784' }} />
              <div
                style={{
                  flex: 1,
                  marginLeft: 8,
                  backgroundColor: '#fff',
                  padding: 8,
                  borderRadius: 4,
                  color: '#424242',
                  fontSize: 10
                }}
              >
                10/26/2018 - A client talkbook centered on <b>blockchain's</b>{' '}
                impact on the finance function, including an overview of the
                technology, KPMG's hypotheses for the future, and key
                considerations for the CFO.
              </div>
            </div>
            <div className={classes.itemImage}>
              <ErrorOutlineIcon color="error" />
              <div
                style={{
                  flex: 1,
                  marginLeft: 8,
                  backgroundColor: '#fff',
                  padding: 8,
                  borderRadius: 4,
                  color: '#424242',
                  fontSize: 10
                }}
              >
                10/26/2018 - ...
              </div>
            </div>
          </Grid>
          <Grid
            item
            className={`${classes.gridItem} ${classes.sectionStarter}`}
          >
            <span>
              3. <strong>Include important search terms</strong> in Title,
              Abstract and Filename.
            </span>
          </Grid>
          <Grid item className={classes.gridItem}>
            <span>
              4. Manage <strong>permissions</strong> for your content
            </span>
          </Grid>
          <Grid item className={classes.gridItem}>
            <span>
              5. <strong>Hide</strong> what you don't want to surface in search
            </span>
          </Grid>
          <Grid item className={classes.gridItem}>
            <span>
              6. <strong>Archived/Remove</strong> old content that is not
              relevant anymore
            </span>
          </Grid>
          <Grid item className={classes.gridItem}>
            <span>
              7. <strong>Modify your content</strong> at least every three years
            </span>
          </Grid>
          {isSEOCheckerModalOpen === false && (
            <Grid item className={classes.contact}>
              Trying to understand the ranking of a certain result?{' '}
              <Link
                className={classes.contactUs}
                onClick={handleSeoChecker}
                component="button"
              >
                Start here
              </Link>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <span>This info is only available in English language.</span>
        <Button
          onClick={onClose}
          color="primary"
          className={classes.closeButton}
        >
          <FormattedMessage id="ok" defaultMessage="Ok" />
        </Button>
      </DialogActions>
    </Dialog>
  )
}
