import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesRightVivaEngageWidget(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles(): any {
  return makeStyles((theme: Theme) => ({
    widgetTitle: {
      lineHeight: '20px'
    },
    widgetDescription: {
      lineHeight: '20px',
      maxHeight: '40px'
    },
    vivaEngageExtendedDescription: {
      color: '#3c4043',
      fontWeight: 'lighter',
      font: 'Arial,Helvetica Neue,Helvetica,sans-serif-light',
      fontSize: '14px',
      overflowX: 'hidden',
      lineHeight: '20px',
      marginTop: '4px'
    },
    widgetSubTitle: {
      color: 'rgba(0, 0, 0, 0.54)',
      textAlign: 'left',
      lineHeight: '20px',
      fontWeight: 'normal',
      font: 'Arial,Helvetica Neue,Helvetica,sans-serif-light',
      fontSize: 12
    },
    profilepicWrapper: {
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      padding: '0px',
      height: '100%',
      marginInlineEnd: '10px'
    }
  }))
}
