import React from 'react'
import * as Config from 'config'
import { ResultLeftWidgetGenerator } from './interfaces'
import LeftPeopleAlsoSearchedWidget from 'components/contents/resultpages/widgets/LeftPeopleAlsoSearchedWidget'
import { trackException } from 'utils/tracking'
import { FetchWithCacheAndTracking } from 'utils/api'
import { IDeviceSetting } from 'utils/deviceSettings'
import { IUserSetting } from 'utils/userSettings'
import { IFindConfiguration } from 'store/Settings/reducers'
import { IPeopleAlsoSearchedResult } from 'components/models/PeopleAlsoSearchedResult'
import { IAADState } from 'store/Auth/reducers'
import { renewAuthorizationToken } from 'utils/token'

export const leftPeopleAlsoSearchedWidgetGenerator: ResultLeftWidgetGenerator =
  {
    fetch(
      aadInfo: IAADState,
      searchQuery: string,
      deviceSettings: IDeviceSetting,
      userSettings: IUserSetting,
      findConfiguration: IFindConfiguration
    ): Promise<IPeopleAlsoSearchedResult[]> {
      return new Promise<IPeopleAlsoSearchedResult[]>((resolve, reject) => {
        const fetchPeopleAlsoSearched = async () => {
          if (!searchQuery || searchQuery === '' || searchQuery === '""') {
            trackException(
              'Error in fetching people also looked for left widget in leftPeopleAlsoSearchedWidget.tsx',
              new Error('Empty query. Please specify a valid query text')
            )
            reject()
            return
          }

          const apiUrl = `${
            Config.APIM_BASE_URL
          }searchapi/getrelatedsearches?query=${encodeURIComponent(
            searchQuery
          )}&country=${
            userSettings.Country ? userSettings.Country : ''
          }&function=${
            userSettings.Function ? userSettings.Function : ''
          }&origin=KPMGFind`

          // Get and check authentication token
          const esToken = await renewAuthorizationToken(
            aadInfo.accessToken,
            aadInfo.instance,
            aadInfo.accounts
          )

          const response = await FetchWithCacheAndTracking(apiUrl, {
            method: 'GET',
            headers: {
              'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
              'content-type': 'application/json',
              Authorization: `Bearer ${esToken}`
            }
          })

          let results
          if (!response || response.hasError || !response.responseJSON) {
            trackException(
              'Error in fetching People Also Looked For left widget in leftPeopleAlsoSearchedWidget.tsx',
              new Error(
                response && response.errorResponse
                  ? response.errorResponse.responseCode +
                    ':' +
                    response.errorResponse.message
                  : ''
              )
            )
            reject()
            return
          } else {
            results = response.responseJSON
          }

          if (!results || !results.value || results.value.length < 3) {
            reject()
            return
          }

          resolve(results.value)
        }

        try {
          fetchPeopleAlsoSearched()
        } catch (error) {
          trackException(
            'Error in fetching People Also Looked For left widget in leftPeopleAlsoSearchedWidget.tsx',
            error
          )
          reject()
        }
      })
    },
    generate(
      results: IPeopleAlsoSearchedResult[],
      searchQuery: string
    ): Promise<React.ReactElement> {
      return new Promise<React.ReactElement>((resolve, reject) => {
        const generatePeopleAlsoSearched = () => {
          const items: string[] = []

          results.map((i: IPeopleAlsoSearchedResult) => {
            if (i && i.text) {
              if (
                i.text.toLocaleLowerCase().trim() !==
                  searchQuery.toLocaleLowerCase().trim() &&
                items.indexOf(i.text) === -1
              ) {
                items.push(i.text)
              }
            }
            return false
          })

          if (items.length === 0) {
            reject()
          }

          resolve(
            <LeftPeopleAlsoSearchedWidget
              searchQuery={searchQuery}
              searchQueries={items}
            />
          )
        }

        try {
          generatePeopleAlsoSearched()
        } catch (error) {
          trackException(
            'Error in generating People Also Looked For left widget in leftPeopleAlsoSearchedWidget.tsx',
            error
          )
          reject()
        }
      })
    }
  }
