import React, { useState } from 'react'
import { Fade, Link, Tooltip } from '@mui/material'
import { connect } from 'react-redux'
import 'Placeholder.css'
import { Store } from 'store'
import WidgetStore from 'store/Widgets'
import ReactPlaceholder from 'react-placeholder'
import { getStylesRightWidgetContainer } from 'styles/contents/resultpages/widgetscontainer/RightWidgetContainer'
import SettingsStore from 'store/Settings'
import { useNonInitialEffect } from 'utils/useNonInitialEffect'

type AllProps = ReturnType<typeof mapStateToProps>

function RightWidgetContainer(props: AllProps) {
  const { widget, hasFindConfigurationBeenFetched, findConfiguration } = props

  //fix, widget component gets rendered (from storage) before the resultpage rendering is complete.
  //prevent unnecessary multiple rendering and event triggers
  const [renderWidget, setRenderWidget] = useState<boolean>(false)
  const classes = getStylesRightWidgetContainer()

  useNonInitialEffect(() => {
    if (widget && widget.rightWidget) setRenderWidget(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widget])

  return (
    <>
      {!widget.rightWidgetHasError && (
        <ReactPlaceholder
          ready={widget && widget.rightWidgetFetched}
          type="text"
          rows={4}
          showLoadingAnimation={true}
          className={classes.resultWidgetPlaceholder}
          style={{ width: 'auto' }}
        >
          {renderWidget &&
            widget.rightWidget &&
            widget.rightWidget.$$typeof !== undefined &&
            widget.rightWidgetFetched && (
              <Fade in={widget.rightWidgetFetched} timeout={600}>
                <div>{widget.rightWidget}</div>
              </Fade>
            )}
        </ReactPlaceholder>
      )}
      {hasFindConfigurationBeenFetched &&
        findConfiguration &&
        findConfiguration.PoweredBy &&
        findConfiguration.PoweredBy.active && (
          <>
            <Link
              data-interception={'off'}
              href={findConfiguration.PoweredBy.link}
              target={'_blank'}
              color="inherit"
            >
              <Tooltip title={findConfiguration.PoweredBy.text}>
                <img
                  className={classes.poweredBy}
                  src={findConfiguration.PoweredBy.image}
                  alt={findConfiguration.PoweredBy.text}
                />
              </Tooltip>
            </Link>
          </>
        )}
    </>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    widget: WidgetStore.selectors.getRightWidget(state),
    findConfiguration: SettingsStore.selectors.getFindConfiguration(state),
    hasFindConfigurationBeenFetched:
      SettingsStore.selectors.gethasFindConfigurationBeenFetched(state)
  }
}

export default connect(mapStateToProps)(RightWidgetContainer)
