import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesPopups(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles() {
  return makeStyles((theme: Theme) => ({
    container: {
      margin: 0,
      padding: 0,
      left: 0,
      position: 'fixed',
      zIndex: 10000,
      top: 0,
      height: '100%',
      width: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '&.slick-arrow': {
        display: 'none'
      }
    },
    slideContainer: {
      overflowY: 'auto',
      maxHeight: '100%',
      textAlign: 'center',
      width: 500,
      backgroundColor: '#f6f7fA',
      borderRadius: 4,
      boxShadow:
        '0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)',
      [`${theme.breakpoints.down(500)}`]: {
        width: '90%',
        height: 'auto'
      }
    },
    closeButton: {
      zIndex: 100,
      color: '#424242',
      background: 'rgba(255, 255, 255, 0.2)',
      '&:hover': {
        background: 'rgba(255, 255, 255, 0.5)'
      }
    },
    content: {
      padding: '25px 25px 25px 25px',
      flexDirection: 'column',
      alignItems: 'stretch'
    },
    img: {
      objectFit: 'cover',
      borderRadius: '4px 4px 0px 0px',
      backgroundSize: 'cover',
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-end',
      padding: 8
    },
    title: {
      color: '#424242',
      marginBottom: '25px'
    },
    description: {
      color: '#424242',
      fontSize: '14px',
      textAlign: 'left',
      flex: 1,
      overflowY: 'auto',
      scrollbarWidth: 'none'
    },
    getStartedButtonContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    getStartedButton: {
      marginTop: 25,
      [`${theme.breakpoints.down(1024)}`]: {
        background: '#005eb8'
      }
    },
    list: {
      marginTop: 0,
      marginBottom: 0,
      paddingInlineStart: '40px'
    },
    listItem: {},
    contentContainer: {
      wordBreak: 'break-all'
    }
  }))
}
