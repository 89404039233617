import { FeaturedResult } from 'components/models/FeaturedResult'
import { Reducer } from 'redux'
import {
  ResultsMarketResearchActions,
  ResultsMarketResearchActionTypes,
  IFetchSuccess,
  IFetchRequestCountSuccess,
  IFetchSuccessCombined
} from './actions'
import { ISynonymsApplied } from 'components/models/SynonymsApplied'
import { IMarketResearchResult } from 'components/models/MarketResearchResult'

export interface IResultsMarketResearchStore {
  hasResultsBeenFetched: boolean
  featuredResults: FeaturedResult[]
  synonymsApplied: ISynonymsApplied[]
  results: IMarketResearchResult[]
  resultsCombined: IMarketResearchResult[]
  resultsCombinedQuery: string
  resultCount: number
  executionTime: number
  currentPage: number
}

const initialState: IResultsMarketResearchStore = {
  hasResultsBeenFetched: false,
  resultCount: -1,
  featuredResults: [],
  results: [],
  resultsCombined: [],
  resultsCombinedQuery: '',
  executionTime: 0,
  currentPage: 1,
  synonymsApplied: []
}

const reducers: Reducer<
  IResultsMarketResearchStore,
  ResultsMarketResearchActions
> = (
  state: IResultsMarketResearchStore = initialState,
  action:
    | {
        type: ResultsMarketResearchActionTypes.INITIALIZE_RESULTS_MARKET_RESEARCH
        payload: any
      }
    | { type: 'SET_CURRENT_PAGE'; payload: any }
    | ResultsMarketResearchActions
) => {
  switch (action.type) {
    case 'SET_CURRENT_PAGE':
      return {
        ...state,
        currentPage: action.payload || 0
      }
    case ResultsMarketResearchActionTypes.INITIALIZE_RESULTS_MARKET_RESEARCH:
      if (!(action as any).payload) {
        return initialState
      }
      const { resultsMarketResearch } = (action as any).payload
      return {
        ...state,
        currentPage: resultsMarketResearch.currentPage || 1
      }
    case ResultsMarketResearchActionTypes.FETCH_REQUEST:
      const { resetCombined, searchQuery } = (action as any).payload
      return {
        ...state,
        hasResultsBeenFetched: false,
        executionTime: 0,
        results: [],
        resultsCombined: resetCombined ? [] : state.resultsCombined,
        resultsCombinedQuery: searchQuery
      }
    case ResultsMarketResearchActionTypes.FETCH_FAILURE:
      return {
        ...state,
        hasResultsBeenFetched: true
      }
    case ResultsMarketResearchActionTypes.FETCH_SUCCESS:
      const { response, featuredResults, synonymsApplied } = (
        action as IFetchSuccess
      ).payload
      return {
        ...state,
        hasResultsBeenFetched: true,
        executionTime: response.executionTime,
        featuredResults:
          response.currentPage === 1 ? featuredResults : state.featuredResults,
        synonymsApplied:
          response.currentPage === 1 ? synonymsApplied : state.synonymsApplied,
        results: response.queryResults,
        currentPage: response.currentPage
      }
    case ResultsMarketResearchActionTypes.FETCH_SUCCESS_COMBINED:
      const { response: responseCombined } = (action as IFetchSuccessCombined)
        .payload
      return {
        ...state,
        resultsCombined: responseCombined.queryResults
      }
    case ResultsMarketResearchActionTypes.FETCH_REQUEST_COUNT:
      return {
        ...state,
        resultCount: -1
      }
    case ResultsMarketResearchActionTypes.FETCH_REQUEST_COUNT_FAILURE:
      return {
        ...state,
        resultCount: 0
      }
    case ResultsMarketResearchActionTypes.FETCH_REQUEST_COUNT_SUCCESS:
      const { resultCount } = (action as IFetchRequestCountSuccess).payload
      return {
        ...state,
        resultCount: resultCount
      }
    default:
      return state
  }
}

export default reducers
