import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesImageResultGallery(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles(): any {
  return makeStyles((theme: Theme) => ({
    container: {
      display: 'flex',
      flexDirection: 'row',
      paddingLeft: '155px',
      flexWrap: 'nowrap',
      msOverflowY: 'hidden',
      [`${theme.breakpoints.down(1024)}`]: {
        paddingLeft: '8px',
        paddingRight: '8px',
        display: 'flex',
        wordBreak: 'break-word',
        flexDirection: 'column',
        flexWrap: 'wrap'
      }
    },
    galleryTile: {
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: '#dfe1e5 !important',
      margin: '1px'
    },
    title: {
      fontSize: '18px',
      color: '#004e98',
      padding: '6px 0',
      lineHeight: '22px',
      '&:hover': {
        textDecoration: 'underline'
      },
      cursor: 'pointer'
    },
    img: {
      backgroundColor: '#f3f2f1'
    },
    subtitleWrapper: {
      whiteSpace: 'nowrap',
      margin: 0,
      color: 'rgba(0, 0, 0, 0.54)',
      fontSize: '0.75rem',
      fontFamily: 'Arial, sans-serif',
      fontWeight: 400,
      height: '20px',
      paddingTop: 0,
      paddingBottom: 0,
      lineHeight: 1.66
    },
    footerTable: { display: 'table', width: '100%' },
    footerTableRow: { display: 'table-row', width: '100%' },
    footerTableCell: {
      width: 'auto',
      verticalAlign: 'top'
    },
    footerDiv: {
      color: 'rgba(0, 0, 0, 0.54)',
      fontSize: '0.75rem',
      fontFamily: 'Arial, sans-serif',
      fontWeight: 400,
      height: '20px',
      paddingTop: 0,
      paddingBottom: 0,
      lineHeight: 1.66
    },
    downloadIcon: {
      float: 'left',
      color: '#3C4043',
      width: '0.75em',
      height: '0.75em',
      '&:hover': {
        cursor: 'pointer'
      }
    },
    loadingSpinner: {
      float: 'left',
      color: '#3C4043'
    }
  }))
}
