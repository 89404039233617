import { Action, AnyAction } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { Store } from '..'
import * as Config from 'config'
import AuthStore from '../Auth'
import { renewAuthorizationToken } from 'utils/token'
import { FetchWithCache } from 'utils/api'
import { trackException } from 'utils/tracking'
import { IUserSetting } from 'utils/userSettings'
import {
  ActionMetaData,
  CustomAction,
  getActionMetaData
} from 'store/extension/customAction'
import { countriesISO } from 'constants/countriesISO'
import { IEntity } from './reducers'
import { IFindConfiguration } from 'store/Settings/reducers'
import SettingsStore from 'store/Settings'

export enum SearchActionTypes {
  FETCH_AUTOCOMPLETE_REQUEST = 'search/FETCH_AUTOCOMPLETE_REQUEST',
  FETCH_AUTOCOMPLETE_SUCCESS = 'search/FETCH_AUTOCOMPLETE_SUCCESS',
  FETCH_AUTOCOMPLETE_FAILURE = 'search/FETCH_AUTOCOMPLETE_FAILURE',
  FETCH_AUTOCORRECT_REQUEST = 'search/FETCH_AUTOCORRECT_REQUEST',
  FETCH_AUTOCORRECT_SUCCESS = 'search/FETCH_AUTOCORRECT_SUCCESS',
  FETCH_AUTOCORRECT_FAILURE = 'search/FETCH_AUTOCORRECT_FAILURE',
  STORE_HISTORY_ITEM = 'search/STORE_HISTORY_ITEM',
  REMOVE_HISTORY_ITEM = 'search/REMOVE_HISTORY_ITEM',
  FETCH_SEARCH_HISTORY_SUCCESS = 'search/FETCH_SEARCH_HISTORY_SUCCESS'
}

export type IFetchRequest = CustomAction<SearchActionTypes>
export type IFetchFailure = CustomAction<SearchActionTypes>
export interface IFetchSuccess extends CustomAction<SearchActionTypes> {
  payload: {
    response: any
  }
}

export interface IFetchAutoCorrectSuccess
  extends CustomAction<SearchActionTypes> {
  payload: {
    responseAutoCorrect: string
    responseEntities: IEntity[]
  }
}

export interface IStoreHistory extends Action<SearchActionTypes> {
  payload: {
    searchQuery: string
  }
}

export interface IFetchSearchHistorySuccess extends Action<SearchActionTypes> {
  payload: {
    searchHistoryData: string[]
  }
}

export const fetchAutocompleteRequest = (
  actionMetaData: ActionMetaData
): IFetchRequest => ({
  type: SearchActionTypes.FETCH_AUTOCOMPLETE_REQUEST,
  metaData: {
    ...actionMetaData,
    isStartAction: true
  }
})

export const fetchAutocompleteSuccess = (
  response: any = {},
  actionMetaData: ActionMetaData
): IFetchSuccess => ({
  type: SearchActionTypes.FETCH_AUTOCOMPLETE_SUCCESS,
  payload: { response },
  metaData: actionMetaData
})

export const fetchAutocompleteFailure = (
  actionMetaData: ActionMetaData
): IFetchFailure => ({
  type: SearchActionTypes.FETCH_AUTOCOMPLETE_FAILURE,
  metaData: actionMetaData
})

export const fetchAutocorrectRequest = (
  actionMetaData: ActionMetaData
): IFetchRequest => ({
  type: SearchActionTypes.FETCH_AUTOCORRECT_REQUEST,
  metaData: {
    ...actionMetaData,
    isStartAction: true
  }
})

export const fetchAutocorrectSuccess = (
  responseAutoCorrect: string,
  responseEntities: IEntity[],
  actionMetaData: ActionMetaData
): IFetchAutoCorrectSuccess => ({
  type: SearchActionTypes.FETCH_AUTOCORRECT_SUCCESS,
  payload: { responseAutoCorrect, responseEntities },
  metaData: actionMetaData
})

export const fetchAutocorrectFailure = (
  actionMetaData: ActionMetaData
): IFetchFailure => ({
  type: SearchActionTypes.FETCH_AUTOCORRECT_FAILURE,
  metaData: actionMetaData
})

export const fetchSearchHistorySuccess = (
  searchHistoryData: string[]
): IFetchSearchHistorySuccess => ({
  type: SearchActionTypes.FETCH_SEARCH_HISTORY_SUCCESS,
  payload: { searchHistoryData }
})

export const storeHistory = (searchQuery: string): IStoreHistory => ({
  type: SearchActionTypes.STORE_HISTORY_ITEM,
  payload: { searchQuery }
})

export const removeHistory = (searchQuery: string): IStoreHistory => ({
  type: SearchActionTypes.REMOVE_HISTORY_ITEM,
  payload: { searchQuery }
})

export const fetchAutocorrect = (
  searchQuery: string,
  userSettings: IUserSetting,
  findConfiguration: IFindConfiguration
  // eslint-disable-next-line @typescript-eslint/ban-types
): ThunkAction<Promise<void>, Store, {}, AnyAction> => {
  return async (
    // eslint-disable-next-line @typescript-eslint/ban-types
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => Store
  ) => {
    const actionMetaData = getActionMetaData('AutoCorrect')

    dispatch(fetchAutocorrectRequest(actionMetaData))
    try {
      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        return
      }

      const countryCodeISO = countriesISO.find(
        (c) => c.Name.toLowerCase() === userSettings.Country.toLowerCase()
      )

      let autoCorrectSearchURL = `${
        Config.APIM_BASE_URL
      }autosuggestapi/getsearchquerydata?query=${encodeURIComponent(
        searchQuery
      )}`

      if (countryCodeISO) {
        autoCorrectSearchURL += '&cc=' + countryCodeISO.Code
      }

      const useEntitiyRecognition =
        SettingsStore.selectors.getUseEntityRecognition(getState())

      if (useEntitiyRecognition && findConfiguration.EntityRecognitionEnabled) {
        autoCorrectSearchURL += '&useEntityRecognition=true'
      }

      // Azure Search Autocorrect API
      const response = await FetchWithCache(autoCorrectSearchURL, {
        method: 'GET',
        headers: {
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          'content-type': 'application/json',
          Authorization: `Bearer ${esToken}`
        }
      })

      let searchDataValues
      if (!response || response.hasError || !response.responseJSON) {
        dispatch(fetchAutocorrectFailure(actionMetaData))
        return
      } else {
        searchDataValues = response.responseJSON
      }

      if (
        searchDataValues &&
        (searchDataValues['correction'] || searchDataValues['entities'])
      ) {
        dispatch(
          fetchAutocorrectSuccess(
            searchDataValues['correction']
              ? searchDataValues['correction']
              : '',
            searchDataValues['entities'] ? searchDataValues['entities'] : [],
            actionMetaData
          )
        )
      }
    } catch (error) {
      trackException('Error in fetching autocorrect data action', error)
      dispatch(fetchAutocorrectFailure(actionMetaData))
    }
  }
}

export const fetchAutocomplete = (
  searchQuery: string,
  lcidCode: string,
  lanCode: string
  // eslint-disable-next-line @typescript-eslint/ban-types
): ThunkAction<Promise<void>, Store, {}, AnyAction> => {
  return async (
    // eslint-disable-next-line @typescript-eslint/ban-types
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => Store
  ) => {
    const actionMetaData = getActionMetaData('AutoComplete')

    dispatch(fetchAutocompleteRequest(actionMetaData))
    try {
      let autoSuggestData: string[] = []
      const searchQueryFiltered = searchQuery.replace(/[^a-zA-Z0-9]+/g, '')
      if (
        searchQuery &&
        searchQuery !== '' &&
        searchQueryFiltered !== '' &&
        searchQuery.length <= 100
      ) {
        const autoSuggestSearchURL = `${
          Config.APIM_BASE_URL
        }autosuggestapi/getautosuggest?query=${encodeURIComponent(
          searchQuery
        )}&lcidcode=${lcidCode}&lancode=${lanCode}`

        // Get and check authentication token
        const aadInfo = AuthStore.selectors.getAADInfo(getState())
        const esToken = await renewAuthorizationToken(
          aadInfo.accessToken,
          aadInfo.instance,
          aadInfo.accounts
        )
        if (esToken !== aadInfo.accessToken) {
          AuthStore.actions.setAuthToken(esToken)
        }
        if (esToken === '') {
          return
        }

        // Azure Search Autosuggest API
        const response = await FetchWithCache(autoSuggestSearchURL, {
          method: 'GET',
          headers: {
            'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
            'content-type': 'application/json',
            Authorization: `Bearer ${esToken}`
          }
        })

        let autoSuggestDataValues: any
        if (!response || response.hasError || !response.responseJSON) {
          dispatch(fetchAutocompleteFailure(actionMetaData))
          return
        } else {
          autoSuggestDataValues = response.responseJSON
        }

        const filteredAutoSuggestData: any[] = []
        if (autoSuggestDataValues) {
          for (let i = 0; i < autoSuggestDataValues.length; i++) {
            if (
              filteredAutoSuggestData.length < 10 &&
              !filteredAutoSuggestData.find(
                (item) =>
                  item.key === autoSuggestDataValues[i]['text'].toLowerCase() ||
                  autoSuggestDataValues[i]['text']
                    .toLowerCase()
                    .indexOf(searchQuery.toLowerCase()) === -1
              )
            ) {
              filteredAutoSuggestData.push({
                value: autoSuggestDataValues[i]['text'],
                key: autoSuggestDataValues[i]['text'].toLowerCase()
              })
            }
          }

          // Extract, Capitalize and Sort values
          const parsedAutoSuggestData: any[] = []
          for (let i = 0; i < filteredAutoSuggestData.length; i++) {
            let autoSuggestValue = filteredAutoSuggestData[i].value
            if (autoSuggestValue && autoSuggestValue !== '') {
              autoSuggestValue = autoSuggestValue.trim()
              autoSuggestValue =
                autoSuggestValue.charAt(0).toUpperCase() +
                autoSuggestValue.slice(1)
              parsedAutoSuggestData.push(autoSuggestValue)
            }
          }
          const sortedAutoSuggestData = parsedAutoSuggestData.sort(function (
            firstValue,
            secondValue
          ) {
            if (firstValue.toLowerCase() < secondValue.toLowerCase()) return -1
            if (firstValue.toLowerCase() > secondValue.toLowerCase()) return 1
            return 0
          })

          autoSuggestData = sortedAutoSuggestData
        }
      }

      dispatch(
        fetchAutocompleteSuccess(
          {
            autoSuggestData
          },
          actionMetaData
        )
      )
    } catch (error) {
      trackException('Error in fetching autosuggest data action', error)
      dispatch(fetchAutocompleteFailure(actionMetaData))
    }
  }
}

export const fetchHistory = (
  upn: string
  // eslint-disable-next-line @typescript-eslint/ban-types
): ThunkAction<Promise<void>, Store, {}, AnyAction> => {
  return async (
    // eslint-disable-next-line @typescript-eslint/ban-types
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => Store
  ) => {
    try {
      let searchHistoryData: string[] = []

      const apiUrl = `${Config.APIM_BASE_URL}searchapi/gethistory?upn=${upn}`

      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        return
      }

      const data = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${esToken}`
        }
      })

      if (data.status !== 200) {
        trackException(
          'Error in fetching search history data action',
          new Error(`Reponse Code: ${data.status}`)
        )
        return
      }

      const searchDataValues = await data.json()

      if (searchDataValues) {
        searchHistoryData = searchDataValues.map((query: string) =>
          decodeURIComponent(query)
        )
      }

      dispatch(fetchSearchHistorySuccess(searchHistoryData))
    } catch (error) {
      trackException('Error in fetching search history data action', error)
    }
  }
}

export const storeHistoryItem = (
  searchQuery: string,
  userSettings: IUserSetting
  // eslint-disable-next-line @typescript-eslint/ban-types
): ThunkAction<Promise<void>, Store, {}, AnyAction> => {
  return async (
    // eslint-disable-next-line @typescript-eslint/ban-types
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => Store
  ) => {
    try {
      dispatch(storeHistory(searchQuery))

      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        return
      }

      const detectLanguageApiUrl = `${
        Config.APIM_BASE_URL
      }searchapi/detectlanguage?searchQuery=${encodeURIComponent(searchQuery)}`

      const languageResponse = await FetchWithCache(detectLanguageApiUrl, {
        method: 'POST',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${esToken}`
        }
      })

      let language = ''
      if (
        languageResponse &&
        !languageResponse.hasError &&
        languageResponse.responseJSON
      ) {
        const languageJSON = languageResponse.responseJSON
        if (languageJSON && languageJSON.responseCode === 200) {
          language = languageJSON.language
        }
      }

      let apiUrl = `${Config.APIM_BASE_URL}searchapi/addglobalentry?upn=${
        userSettings.upn
      }&searchQuery=${encodeURIComponent(searchQuery)}&country=${
        userSettings.Country
      }&function=${userSettings.Function}&origin=KPMGFind`

      if (language !== '') apiUrl += `&language=${language}`

      const data = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${esToken}`
        }
      })

      if (data.status !== 200 && data.status !== 201) {
        trackException(
          'Error in add search history item',
          new Error(`Reponse Code: ${data.status}`)
        )
        return
      }
    } catch (error) {
      trackException('Error in add search history item', error)
    }
  }
}

export const removeHistoryItem = (
  searchQuery: string,
  upn: string
  // eslint-disable-next-line @typescript-eslint/ban-types
): ThunkAction<Promise<void>, Store, {}, AnyAction> => {
  return async (
    // eslint-disable-next-line @typescript-eslint/ban-types
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => Store
  ) => {
    try {
      dispatch(removeHistory(searchQuery))

      const apiUrl = `${
        Config.APIM_BASE_URL
      }searchapi/deletehistoryentry?upn=${upn}&searchQuery=${encodeURIComponent(
        searchQuery
      )}`

      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        return
      }

      const data = await fetch(apiUrl, {
        method: 'DELETE',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${esToken}`
        }
      })

      if (data.status !== 200 && data.status !== 201) {
        trackException(
          'Error in remove search history item',
          new Error(`Reponse Code: ${data.status}`)
        )
        return
      }
    } catch (error) {
      trackException('Error in remove search history item', error)
    }
  }
}

export type SearchActions =
  | IFetchRequest
  | IFetchFailure
  | IFetchFailure
  | IStoreHistory
