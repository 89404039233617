import React, { Dispatch, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Chip,
  IconButton,
  InputBase,
  ListItemButton,
  MenuItem,
  List,
  Menu
} from '@mui/material'
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined'
import SendIcon from '@mui/icons-material/Send'
import { IDataSource } from 'constants/datasourcesConfiguration'
import { IDeviceSetting } from 'utils/deviceSettings'
import { connect } from 'react-redux'
import { Store } from 'store'
import PageStay from 'components/HOC/PageStay'
import ChatStore from 'store/ResultsChat'
import SettingsStore from 'store/Settings'
import CleaningServicesIcon from '@mui/icons-material/CleaningServices'
import {
  getStylesChatPrompt,
  getThemeChatPromptChip,
  getThemeChatRelatedQuestionsChip
} from 'styles/contents/resultpages/chat/ChatPrompt'
import MenuIcon from '@mui/icons-material/Menu'
import CheckIcon from '@mui/icons-material/Check'
import { chatSearchTypes } from 'constants/constants'

export interface ChatPromptProps {
  autoFocus: boolean
  compactView?: boolean
  initialDataSource?: string
}

type AllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  ChatPromptProps

function ChatPrompt(props: AllProps): JSX.Element {
  const {
    autoFocus,
    deviceSettings,
    fetchResultsChat,
    chatRequestList,
    compactView,
    newTopic,
    currentDataSources,
    initialDataSource
  } = props
  const classes = getStylesChatPrompt()

  const getInitialDataSource = (dsToSelect: string | undefined) => {
    if (!dsToSelect) return []

    const ds = currentDataSources.find(
      (ds: IDataSource) =>
        ds.name.toLowerCase() === dsToSelect &&
        ds.active &&
        ds.enabled &&
        ds.enabledForChat
    )

    if (ds) {
      return [ds.name]
    }

    return []
  }

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [searchTypeAnchorEl, setSearchTypeAnchorEl] =
    React.useState<null | HTMLElement>(null)
  const [filterDatasource, setFilterDatasource] = useState<string[]>(
    getInitialDataSource(initialDataSource)
  )
  const [searchType, setSearchType] = useState<string>(chatSearchTypes.keyword)
  const [search, setSearch] = useState('')
  const [allowRequest, setAllowRequest] = useState(true)
  const [relatedQuestionsList, setRelatedQuestionsList] = useState<
    string[] | null
  >(null)

  const handleOnSearchChange = (event: { target: { value: string } }) => {
    setSearch(event.target.value)
  }
  const handleSubmit = () => {
    if (!search) return

    setSearch('')
    submit(search)
  }

  const submit = (search: string) => {
    fetchResultsChat(search, filterDatasource, searchType, deviceSettings)
  }

  useEffect(() => {
    let _allowRequest = false
    if (
      chatRequestList.length === 0 ||
      (chatRequestList[chatRequestList.length - 1].Answer &&
        chatRequestList[chatRequestList.length - 1].Answer.AnswerFetched)
    ) {
      _allowRequest = true
    }
    setAllowRequest(_allowRequest)

    if (
      chatRequestList.length > 0 &&
      chatRequestList[chatRequestList.length - 1].Answer.AnswerFetched
    ) {
      const lastChatAnswer = chatRequestList[chatRequestList.length - 1]
      if (
        lastChatAnswer &&
        lastChatAnswer.Answer &&
        lastChatAnswer.Answer.RelatedQuestions &&
        lastChatAnswer.Answer.RelatedQuestions
      ) {
        setRelatedQuestionsList(lastChatAnswer.Answer.RelatedQuestions)
      } else {
        setRelatedQuestionsList(null)
      }
    }
  }, [chatRequestList])

  useEffect(() => {
    const ds = getInitialDataSource(initialDataSource)
    setFilterDatasource(ds)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialDataSource])

  const open = Boolean(anchorEl)
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const openSearchTpye = Boolean(searchTypeAnchorEl)
  const handleClickSearchTypeItem = (event: React.MouseEvent<HTMLElement>) => {
    setSearchTypeAnchorEl(event.currentTarget)
  }

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    dsName: string
  ) => {
    if (dsName === 'All') {
      setFilterDatasource([])
    } else if (filterDatasource.some((ds: string) => ds === dsName)) {
      setFilterDatasource(filterDatasource.filter((ds) => ds !== dsName))
    } else {
      setFilterDatasource([...filterDatasource, dsName])
    }
  }

  const handleSearchTypeMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    searchType: string
  ) => {
    setSearchType(searchType)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleCloseSearchType = () => {
    setSearchTypeAnchorEl(null)
  }

  return (
    <Box>
      {!compactView && (
        <Box className={classes.relatedQuestionsContainer}>
          {relatedQuestionsList &&
            relatedQuestionsList.map((question: string) => (
              <Chip
                color={'default'}
                size={'medium'}
                variant={'outlined'}
                sx={getThemeChatRelatedQuestionsChip()}
                label={
                  <div
                    className={classes.relatedQuestion}
                    onClick={() => {
                      submit(question)
                    }}
                  >
                    {question}
                  </div>
                }
              />
            ))}
        </Box>
      )}

      <div className={classes.containerInputBox}>
        {!compactView && (
          <Button
            className={classes.buttonNewTopic}
            variant="contained"
            onClick={() => {
              newTopic()
              setRelatedQuestionsList(null)
            }}
          >
            <CleaningServicesIcon className={classes.buttonNewTopicIcon} />
            <span className={classes.buttonNewTopicContent}>New Topic</span>
          </Button>
        )}

        <Chip
          color={'default'}
          size={'medium'}
          variant={'outlined'}
          sx={getThemeChatPromptChip()}
          label={
            <div className={classes.containerInputChip}>
              <div style={{ flexGrow: 1 }}>
                <form>
                  <div className={classes.containerInput}>
                    <List
                      component="nav"
                      aria-label="Select Search Type"
                      sx={{
                        bgcolor: 'transparent',
                        padding: 0
                      }}
                    >
                      <ListItemButton
                        id="lock-button"
                        aria-haspopup="listbox"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClickSearchTypeItem}
                        sx={{
                          padding: 0
                        }}
                      >
                        <ChatOutlinedIcon className={classes.inputTopicIcon} />
                      </ListItemButton>
                    </List>
                    <Menu
                      id="lock-menu"
                      anchorEl={searchTypeAnchorEl}
                      open={openSearchTpye}
                      onClose={handleCloseSearchType}
                      MenuListProps={{
                        disablePadding: true
                      }}
                    >
                      <MenuItem
                        key={chatSearchTypes.keyword}
                        onClick={(event) =>
                          handleSearchTypeMenuItemClick(
                            event,
                            chatSearchTypes.keyword
                          )
                        }
                        disableTouchRipple
                        disableRipple
                      >
                        <div className={classes.dsSelectionItem}>
                          <div>{chatSearchTypes.keyword}</div>
                          {searchType === chatSearchTypes.keyword && (
                            <div className={classes.checkBox}>
                              <CheckIcon fontSize={'small'}> </CheckIcon>
                            </div>
                          )}
                        </div>
                      </MenuItem>
                      <MenuItem
                        key={chatSearchTypes.hybrid}
                        onClick={(event) =>
                          handleSearchTypeMenuItemClick(
                            event,
                            chatSearchTypes.hybrid
                          )
                        }
                        disableTouchRipple
                        disableRipple
                      >
                        <div className={classes.dsSelectionItem}>
                          <div>{chatSearchTypes.hybrid}</div>
                          {searchType === chatSearchTypes.hybrid && (
                            <div className={classes.checkBox}>
                              <CheckIcon fontSize={'small'}> </CheckIcon>
                            </div>
                          )}
                        </div>
                      </MenuItem>
                    </Menu>
                    <InputBase
                      multiline
                      {...(autoFocus
                        ? { autoFocus: true }
                        : { autoFocus: false })}
                      inputRef={(input) =>
                        input && autoFocus && allowRequest && input.focus()
                      }
                      disabled={!allowRequest}
                      autoCapitalize={'false'}
                      className={classes.inputField}
                      placeholder={`Ask me anything ${
                        filterDatasource.length === 0
                          ? ''
                          : `about "${
                              filterDatasource.length > 2
                                ? `${[...filterDatasource]
                                    .splice(0, 2)
                                    .join(', ')}, ...`
                                : filterDatasource.join(', ')
                            }"`
                      }`}
                      value={search}
                      onChange={handleOnSearchChange}
                      onKeyDown={(e: any) => {
                        //on Enter
                        if (e.keyCode === 13) {
                          handleSubmit()
                        }
                      }}
                    />
                    <IconButton
                      tabIndex={0}
                      disabled={!allowRequest}
                      onClick={handleSubmit}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          handleSubmit()
                        }
                      }}
                      className={classes.btnSend}
                      style={{
                        color: allowRequest ? '#005eb8' : 'rgb(66, 66, 66)'
                      }}
                    >
                      <SendIcon />
                    </IconButton>
                    {!compactView && (
                      <div className={classes.btnSelectDatasource}>
                        <List
                          component="nav"
                          aria-label="Select Datasource"
                          sx={{
                            bgcolor: 'transparent',
                            padding: 0
                          }}
                        >
                          <ListItemButton
                            id="lock-button"
                            aria-haspopup="listbox"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClickListItem}
                            sx={{
                              padding: 0
                            }}
                          >
                            <MenuIcon />
                          </ListItemButton>
                        </List>
                        <Menu
                          id="lock-menu"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          MenuListProps={{
                            disablePadding: true
                          }}
                        >
                          <MenuItem
                            key={'All'}
                            onClick={(event) =>
                              handleMenuItemClick(event, 'All')
                            }
                            disableTouchRipple
                            disableRipple
                          >
                            <div className={classes.dsSelectionItem}>
                              <div>{'All'}</div>
                              {/* {filterDatasource.length === 0 && (
                                <div className={classes.checkBox}>
                                  <CheckIcon fontSize={'small'}> </CheckIcon>
                                </div>
                              )} */}
                            </div>
                          </MenuItem>
                          {currentDataSources
                            .filter(
                              (ds: IDataSource) =>
                                ds.active && ds.enabled && ds.enabledForChat
                            )
                            .map((option: IDataSource, index) => (
                              <MenuItem
                                key={option.name}
                                onClick={(event) =>
                                  handleMenuItemClick(event, option.name)
                                }
                                disableTouchRipple
                                disableRipple
                              >
                                <div className={classes.dsSelectionItem}>
                                  <div>{option.name}</div>
                                  {filterDatasource.indexOf(option.name) !==
                                    -1 && (
                                    <div className={classes.checkBox}>
                                      <CheckIcon fontSize={'small'}>
                                        {' '}
                                      </CheckIcon>
                                    </div>
                                  )}
                                </div>
                              </MenuItem>
                            ))}
                        </Menu>
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>
          }
        />
      </div>
    </Box>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state),
    chatRequestList: ChatStore.selectors.getChatRequests(state),
    currentDataSources: SettingsStore.selectors.getCurrentDataSources(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    fetchResultsChat: (
      searchQuery: string,
      filterDatasource: string[],
      searchType: string,
      deviceSettings: IDeviceSetting
    ) =>
      dispatch(
        ChatStore.actions.fetchResultsChat(
          searchQuery,
          filterDatasource,
          searchType,
          deviceSettings
        )
      ),
    newTopic: () => dispatch(ChatStore.actions.newTopic())
  }
}

export default PageStay(
  connect(mapStateToProps, mapDispatchToProps)(ChatPrompt)
)
