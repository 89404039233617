import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesFilterMenuItemsDateRange(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles() {
  return makeStyles((theme: Theme) => ({
    datePickerMenuItem: { borderTop: '1px solid #dfe1e5' },
    datePicker: {
      width: '100%',
      backgroundColor: 'transparent !important',
      '& button:hover': {
        backgroundColor: 'transparent'
      },
      '& label': {
        fontSize: '14px'
      },
      '& input': {
        fontSize: '14px'
      }
    },
    applyDateButton: {
      '& span': {
        color: '#424242',
        fontSize: '14px'
      }
    },
    applyDateButtonDisabled: {
      '& span': {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '14px'
      },
      cursor: 'default'
    },
    dateTimeButtonContainer: {
      display: 'flex',
      justifyContent: 'center',
      cursor: 'default'
    }
  }))
}
