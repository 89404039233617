import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesMailResults(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles() {
  return makeStyles((theme: Theme) => ({
    mailResultBody: {
      fontSize: '14px',
      marginTop: 0,
      color: 'rgba(0,0,0,0.54)',
      fontWeight: 'normal',
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word',
      wordWrap: 'break-word'
    },
    mailResultDate: {
      cursor: 'pointer',
      '& a': {
        color: '#3C4043',
        textDecoration: 'none'
      },
      '& a:hover': {
        textDecoration: 'none'
      },
      whiteSpace: 'nowrap',
      fontSize: 14
    },
    mailResultSender: {
      '& a': {
        color: '#3C4043',
        textDecoration: 'none'
      },
      '& a:hover': {
        textDecoration: 'none'
      },
      cursor: 'pointer',
      minWidth: 140,
      maxWidth: 140,
      fontSize: 14,
      textAlign: 'right'
    },
    mailResultSubject: {
      cursor: 'pointer',
      width: '100%',
      fontSize: 16
    },
    mailResultCollapseButton: {
      width: 30,
      paddingRight: 5,
      [`${theme.breakpoints.down(1024)} `]: {
        display: 'none'
      }
    },
    mailResultCollapseButtonMobile: {
      display: 'none',
      [`${theme.breakpoints.down(1024)}`]: {
        display: 'inline-flex',
        width: 30,
        paddingRight: 5
      }
    },
    resultCollapse: {
      '& .MuiCollapse-wrapper': {
        display: 'block'
      }
    },
    mailResultRowHeader: {
      '& td': {
        borderBottom: 'none'
      }
    },
    mailBodyCellCollapsed: {
      padding: '0px 6px !important'
    },
    mailBodyCellOpen: {
      padding: '0px 6px 12px 6px !important'
    },
    mailTitleContainer: {
      display: 'flex',
      textDecoration: 'none'
    },
    mailAttachmentIcon: {
      fontSize: 16,
      padding: '3px 0px'
    },
    mailAttachmentIconMobile: {
      fontSize: 13,
      padding: '3px 0px'
    },
    mailContainer: {
      [`${theme.breakpoints.down(1024)}`]: {
        display: 'none'
      }
    },
    mailMobileContainer: {
      display: 'none',
      [`${theme.breakpoints.down(1024)}`]: {
        display: 'flex',
        wordBreak: 'break-word',
        backgroundColor: '#fff',
        margin: '0 0 10px 0',
        boxShadow: '0 2px 6px rgba(32, 33, 36, 0.28)',
        borderRadius: '4px',
        paddingTop: '12px',
        paddingBottom: '12px',
        paddingLeft: '16px',
        paddingRight: '16px',
        fontFamility: 'Roboto, HelveticaNeue, Arial, sans-serif'
      }
    },
    mobileResultSender: {
      minWidth: 140,
      maxWidth: 140,
      fontSize: 14,
      textAlign: 'left',
      color: '#3C4043',
      textDecoration: 'none'
    },
    mobileResultDate: {
      color: '#3C4043',
      whiteSpace: 'nowrap',
      fontSize: 14,
      textDecoration: 'none'
    },
    mobileResultSubject: {
      whiteSpace: 'nowrap',
      fontSize: 16,
      marginBottom: 8,
      marginTop: 8
    }
  }))
}
