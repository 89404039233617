import { Store } from '..'

const selectors = {
  getCurrentFilters: (state: Store): CurrentFilter[] =>
    state.filters.currentFilters,
  getDefaultFilterOptions: (state: Store): FilterCategory[] =>
    state.filters.defaultFilterOptions,
  getRecentFilters: (state: Store): any => state.filters.recentFilters,
  getLastModifiedRangeFilters: (state: Store): any =>
    state.filters.lastModifiedRangeFilter,
  getTemporaryFilters: (state: Store): CurrentFilter[] =>
    state.filters.temporaryFilters
}

export default selectors
