import { IAADState } from 'store/Auth/reducers'
import { IDeviceSetting } from './deviceSettings'
import { getGraphAuthToken, renewAuthorizationToken } from './token'
import { trackException } from './tracking'
import * as Config from 'config'

export async function getProfilePicture(
  upn: string,
  originalPictureUrl: string | null,
  deviceSettings: IDeviceSetting,
  aadInfo: IAADState
): Promise<string> {
  try {
    let graphToken = ''
    // Get and check authentication token
    if (Config.LOCAL_DEVELOPMENT.toString() === 'false') {
      graphToken = await getGraphAuthToken(aadInfo.instance, aadInfo.accounts)
    }

    // Get and check authentication token
    const esToken = await renewAuthorizationToken(
      aadInfo.accessToken,
      aadInfo.instance,
      aadInfo.accounts
    )

    const cleanUPN = upn.replace('i:0#.f|membership|', '')

    const graphAPIUrl = `${Config.APIM_BASE_URL}msgraphapi/getprofilepicture?upn=${cleanUPN}`

    const pictureResponse = await fetch(graphAPIUrl, {
      method: 'GET',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
        Authorization: `Bearer ${graphToken}`,
        JWTToken: esToken
      }
    })

    if (!pictureResponse || !pictureResponse.ok) {
      return originalPictureUrl ? originalPictureUrl : ''
    } else {
      const pictureBlob = await pictureResponse.blob()
      const pictureUrl = URL.createObjectURL(pictureBlob)
      return pictureUrl
    }
  } catch (error) {
    trackException('Error loading picture thumbnail', error)
  }

  return originalPictureUrl ? originalPictureUrl : ''
}
