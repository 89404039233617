import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesFilterMenu(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles() {
  return makeStyles((theme: Theme) => ({
    menu: {
      width: '220px',
      maxHeight: '410px'
    },
    menuTopBar: {
      flex: 1,
      display: 'flex',
      height: '43px',
      flexDirection: 'row',
      justifyContent: 'space-between',
      borderBottom: '1px solid #e0e0e0',
      marginBottom: '0.5em',
      position: 'sticky',
      top: 0,
      zIndex: 205,
      backgroundColor: '#fff'
    },
    menuTopBarSmallItem: {
      display: 'flex',
      margin: '0 auto'
    },
    divider: {
      margin: '0 .75em'
    },
    seperator: {
      borderLeft: '1px solid #d0d0d0',
      display: 'inline-block',
      verticalAlign: 'baseline',
      position: 'relative',
      top: '0.75em',
      float: 'none',
      height: '1em',
      width: 0
    },
    menuClearButton: {
      cursor: 'pointer',
      height: '43px',
      fontSize: '14px',
      alignItems: 'center',
      lineHeight: '43px',
      marginRight: '.75em',
      color: '#005eb8',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textAlign: 'right',
      '&::before': {
        margin: '0 .75em'
      },
      '&:hover': {
        textDecoration: 'underline'
      }
    },
    loadingSpinner: {
      color: 'rgba(0, 0, 0, 0.2)'
    },
    centerLoadingSpinner: {
      textAlign: 'center',
      padding: 5,
      height: 23
    },
    multiSelectMenu: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    mulitSelectMenuButton: {
      cursor: 'pointer',
      fontSize: '14px',
      marginLeft: 5,
      marginRight: 5,
      marginBottom: 5,
      textTransform: 'none',

      padding: '0 16px',
      magin: '0 auto',
      height: '32px',
      '&:hover': {
        backgroundColor: '#f4f4f5'
      }
    },

    mulitSelectMenuButtonDisabled: {
      backgroundColor: '#f4f4f5'
    },

    radioGroup: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      marginLeft: '-8px'
    },
    radioButton: {
      margin: '2px 0',
      '& .MuiFormControlLabel-label': {
        fontSize: 14
      },
      '& .MuiButtonBase-root': {
        padding: '5px'
      }
    }
  }))
}
