import { trackException } from './tracking'
import * as Config from 'config'
import { stripHtml } from 'utils/string'
import { IUserSetting } from './userSettings'
import { IDeviceSetting } from './deviceSettings'
import { store } from 'store/configureStore'
import SettingsStore from 'store/Settings'
import createDOMPurify from 'dompurify'
import { IDataSource } from 'constants/datasourcesConfiguration'
import { KPMGFindGlobalVariables } from 'store/KPMGFindGlobalVariables'

/**
 * Authorizes a result page: Checks if the current user country contains the result page that is
 * being accessed, redirecting to the first enabled datasource if not
 * @param dataSourceName The data source name which is being accessed.
 * @param userSettings The current user settings
 * @param deviceSettings The current device settings
 * @param locationSearchParams The current location query params
 * @param redirect Should redirect
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function authorizeResultPage(
  dataSourceName: string,
  userSettings: IUserSetting,
  deviceSettings: IDeviceSetting,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  locationSearchParams: string,
  redirect = true
) {
  let allowRender = false
  if (dataSourceName === 'StartPage') {
    return true
  }

  const datasources = SettingsStore.selectors.getCurrentDataSources(
    store.getState()
  )

  const DOMPurify = createDOMPurify(window)

  try {
    // Check datasource is avaiable for current user
    const dataSources = datasources.filter(
      (ds: IDataSource) => dataSourceName.indexOf(ds.name) >= 0
    )
    if (dataSources && dataSources.length) {
      allowRender = true
    }

    // Is datasource enabled
    if (allowRender === true) {
      if (
        !dataSources ||
        dataSources.length === 0 ||
        (dataSources && dataSources.length === 1 && !dataSources[0].enabled)
      ) {
        allowRender = false
      }
    }
  } catch (error) {
    trackException(
      'Error in authorizeResultPage method for ' +
        dataSourceName +
        'datasource',
      error
    )
  }

  if (redirect === false) {
    return allowRender
  }

  if (allowRender === false && redirect === true) {
    // Try to find another datasource
    let redirectTo = Config.APP_URL
    const availableDs: any = datasources.find((ds: any) =>
      authorizeResultPage(
        ds.name,
        userSettings,
        deviceSettings,
        DOMPurify.sanitize(locationSearchParams),
        false
      )
    )

    const loc = stripHtml(locationSearchParams)
    if (availableDs) {
      redirectTo += '/#' + availableDs.path + loc
    }

    const setShowDisabledPopup =
      KPMGFindGlobalVariables.getFuncSetShowDisabledPopup()
    setShowDisabledPopup(true)
    if (availableDs && availableDs.name) {
      const setDisabledPopupDatasource =
        KPMGFindGlobalVariables.getFuncSetDisabledPopupDatasource()
      setDisabledPopupDatasource(availableDs.name)
    }

    window.location.hash = ''
    window.location.href = redirectTo
  }
}

/**
 * Gets the Data Source Name by window location
 * @param location The window location
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getDsNameByLocation(location: any): string {
  if (location && location.hash) {
    if (location.hash.indexOf('"/results/aem/') >= 0) {
      return 'kpmg.com'
    }
    if (location.hash.indexOf('/results/alex/') >= 0) {
      return 'Alex'
    }
    if (location.hash.indexOf('/results/all') >= 0) {
      return 'Intranet'
    }
    if (location.hash.indexOf('/results/engagements/') >= 0) {
      return 'Engagements'
    }
    if (location.hash.indexOf('/results/klardenker/') >= 0) {
      return 'Klardenker'
    }
    if (location.hash.indexOf('/results/knowledgeexchange/') >= 0) {
      return 'KnowledgeExchange'
    }
    if (location.hash.indexOf('/results/news/') >= 0) {
      return 'News'
    }
    if (location.hash.indexOf('/results/people/') >= 0) {
      return 'People'
    }
    if (location.hash.indexOf('/results/research/') >= 0) {
      return 'Research'
    }
    if (location.hash.indexOf('/results/source/') >= 0) {
      return 'Source'
    }
    if (location.hash.indexOf('/results/statistics/') >= 0) {
      return 'Statistics'
    }
    if (location.hash.indexOf('/results/videos/') >= 0) {
      return 'Videos'
    }
    if (location.hash.indexOf('/results/office365/') >= 0) {
      return 'Office365'
    }
    return 'StartPage'
  } else {
    return 'StartPage'
  }
}
