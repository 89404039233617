import React from 'react'
import { FormattedMessage } from 'react-intl'
import HTMLEllipsis from 'react-lines-ellipsis/lib/html'
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'

export interface CustomDescriptionProps {
  descriptionValue: string
  maxLine?: string
  basedOn?: 'letters' | 'words' | undefined
  customStyle?: any
}

export function CustomDescription(props: CustomDescriptionProps): JSX.Element {
  const { descriptionValue, maxLine, basedOn, customStyle } = props

  const ResponsiveHTMLEllipsis = responsiveHOC()(HTMLEllipsis)

  return (
    <>
      {descriptionValue && (
        <ResponsiveHTMLEllipsis
          {...(!customStyle
            ? {
                style: {
                  whiteSpace: 'pre-wrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden'
                }
              }
            : { style: customStyle })}
          {...(!basedOn
            ? {
                basedOn: 'words'
              }
            : { basedOn: basedOn })}
          unsafeHTML={descriptionValue}
          {...(!maxLine
            ? {
                maxLine: '3'
              }
            : { maxLine: maxLine })}
        />
      )}
      {!descriptionValue && (
        <FormattedMessage
          id="missing_description_text"
          defaultMessage="Description is not available"
        />
      )}
    </>
  )
}
