import React, { useState, useEffect, Dispatch } from 'react'
import Link from '@mui/material/Link'
import { connect } from 'react-redux'
import { Store } from 'store'
import SettingsStore from 'store/Settings'
import { Box, Typography } from '@mui/material'
import { getStylesCompanySnapshotWidget } from 'styles/contents/resultpages/widgets/CompanySnapshotWidget'
import { ITRSearchItem } from 'utils/widgets/companySnapshotWidget'
import { trackLeftWidgetClick } from 'utils/tracking'
import { removeQueryParam } from 'utils/queryParams'
import { useNavigate } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import SeeAllButton from './common/SeeAllButton'
import { IDataSource } from 'constants/datasourcesConfiguration'
import createDOMPurify from 'dompurify'
import FilterStore from 'store/Filters'
import { getUrlParameterForCurrentFilters } from 'utils/filters'
import { KPMGFindGlobalVariables } from 'store/KPMGFindGlobalVariables'
import TooltipTitle from 'components/contents/common/TooltipTitle'
export interface CompanySnapshotWidgetProps {
  searchQuery: any
  result: ITRSearchItem
}

type AllProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> &
  CompanySnapshotWidgetProps

function CompanySnapshotWidget(props: AllProps): JSX.Element {
  const intl = useIntl()
  const navigateFunction = useNavigate()
  const classes = getStylesCompanySnapshotWidget()
  const [dataSourceEnabled, setDataSourceEnabled] = useState(false)

  const {
    result,
    deviceSettings,
    searchQuery,
    currentDataSources,
    setTemporaryFilter,
    clearTemporaryFilter,
    currentfilters
  } = props

  const DOMPurify = createDOMPurify(window)

  useEffect(() => {
    const trDataSource = currentDataSources.find(
      (ds: IDataSource) => ds.name.toLowerCase() === 'marketresearch'
    )
    const trDataSourceEnabled = trDataSource ? trDataSource.enabled : false

    setDataSourceEnabled(trDataSourceEnabled)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDataSources])

  let sector = ''
  let subSector = ''
  if (result.ICBSchemeName && result.ICBSchemeName !== '') {
    const sectors = DOMPurify.sanitize(result.ICBSchemeName.split('/'))
    if (sectors && sectors.length === 4) {
      sector = sectors[2]
      subSector = sectors[3]
    }
  }

  // Create our number formatter.
  let capitalization = ''
  const mktCapCompanyUsd = DOMPurify.sanitize(result.MktCapCompanyUsd)
  if (mktCapCompanyUsd && mktCapCompanyUsd !== '') {
    capitalization = intl.formatNumber(parseInt(mktCapCompanyUsd), {
      style: 'currency',
      currency: 'USD',
      currencyDisplay: 'code',
      notation: 'compact',
      compactDisplay: 'long',
      minimumSignificantDigits: 4,
      maximumSignificantDigits: 4
    })
  }

  const seeAllLink = `/results/marketresearch/${getUrlParameterForCurrentFilters(
    currentfilters
  )}`

  // If widget prefiltering is enabled, add it to the see all link
  const setTemporaryFilters = () => {
    clearTemporaryFilter()

    setTemporaryFilter([
      {
        key: 'ptkr',
        value: DOMPurify.sanitize(result.PrimaryRIC)
      }
    ])

    KPMGFindGlobalVariables.setIsRedirectFromWidget(true)
  }

  return (
    <Box id="con-widget-right-company-snapshot" className={classes.widgetArea}>
      <h2 className={classes.widgetHeadlineH2}>
        <TooltipTitle
          title={DOMPurify.sanitize(result.CommonName)}
          useCssOnly={false}
          singleLine={false}
          additionalClass={classes.widgetHeadline}
        />
      </h2>
      {result.OrganizationWebsite && (
        <Link
          underline={'hover'}
          className={classes.linkContainer}
          {...(deviceSettings.openLinksInNewTab === true
            ? { target: '_blank', rel: 'noreferrer' }
            : {})}
          href={DOMPurify.sanitize(result.OrganizationWebsite)}
          onClick={() => {
            removeQueryParam(navigateFunction, 'cntx')
            trackLeftWidgetClick({
              title: DOMPurify.sanitize(result.CommonName),
              url: DOMPurify.sanitize(result.OrganizationWebsite),
              index: 1,
              widgetKey: 'companysnapshot',
              body: ''
            })
          }}
          {...(!deviceSettings.isMobile
            ? {
                onAuxClick: (event: any) => {
                  removeQueryParam(navigateFunction, 'cntx')
                  trackLeftWidgetClick({
                    title: DOMPurify.sanitize(result.CommonName),
                    url: DOMPurify.sanitize(result.OrganizationWebsite),
                    index: 1,
                    widgetKey: 'companysnapshot',
                    body: ''
                  })
                }
              }
            : {})}
        >
          <Typography
            color="primary"
            className={`${classes.widgetCompany}`}
            component="div"
          >
            <TooltipTitle
              title={DOMPurify.sanitize(result.OrganizationWebsite)}
              useCssOnly={true}
              singleLine={true}
              additionalClass={classes.widgetHeadline}
            />
          </Typography>
        </Link>
      )}
      <Box className={classes.widgetTextContainer}>
        <TableContainer>
          <Table aria-label="simple table">
            <TableBody>
              {result.OAOrgTypeName && result.OAOrgTypeName !== '' && (
                <TableRow
                  key={intl.formatMessage({
                    id: 'comp_snap_org_type',
                    defaultMessage: 'Org Type'
                  })}
                  className={classes.row}
                >
                  <TableCell className={classes.titleCell} scope="row">
                    {intl.formatMessage({
                      id: 'comp_snap_org_type',
                      defaultMessage: 'Org Type'
                    }) + ':'}
                  </TableCell>
                  <TableCell className={classes.contentCell} align="left">
                    {DOMPurify.sanitize(result.OAOrgTypeName) +
                      (result.OrganisationTypeName
                        ? ' (' +
                          DOMPurify.sanitize(result.OrganisationTypeName) +
                          ')'
                        : '')}
                  </TableCell>
                </TableRow>
              )}
              {result.ReutersIndustryClassification &&
                result.ReutersIndustryClassification !== '' && (
                  <TableRow
                    key={intl.formatMessage({
                      id: 'comp_snap_industry',
                      defaultMessage: 'Industry'
                    })}
                    className={classes.row}
                  >
                    <TableCell className={classes.titleCell} scope="row">
                      {intl.formatMessage({
                        id: 'comp_snap_industry',
                        defaultMessage: 'Industry'
                      }) + ':'}
                    </TableCell>
                    <TableCell className={classes.contentCell} align="left">
                      {DOMPurify.sanitize(result.ReutersIndustryClassification)}
                    </TableCell>
                  </TableRow>
                )}
              {sector !== '' && (
                <TableRow
                  key={intl.formatMessage({
                    id: 'comp_snap_sector',
                    defaultMessage: 'Sector'
                  })}
                  className={classes.row}
                >
                  <TableCell className={classes.titleCell} scope="row">
                    {intl.formatMessage({
                      id: 'comp_snap_sector',
                      defaultMessage: 'Sector'
                    }) + ':'}
                  </TableCell>
                  <TableCell className={classes.contentCell} align="left">
                    {sector}
                  </TableCell>
                </TableRow>
              )}
              {subSector !== '' && (
                <TableRow
                  key={intl.formatMessage({
                    id: 'comp_snap_sub_sector',
                    defaultMessage: 'Sub Sector'
                  })}
                  className={classes.row}
                >
                  <TableCell className={classes.titleCell} scope="row">
                    {intl.formatMessage({
                      id: 'comp_snap_sub_sector',
                      defaultMessage: 'Sub Sector'
                    }) + ':'}
                  </TableCell>
                  <TableCell className={classes.contentCell} align="left">
                    {subSector}
                  </TableCell>
                </TableRow>
              )}
              {capitalization !== '' && (
                <TableRow
                  key={intl.formatMessage({
                    id: 'comp_snap_capitalization',
                    defaultMessage: 'Capitalization'
                  })}
                  className={classes.row}
                >
                  <TableCell className={classes.titleCell} scope="row">
                    {intl.formatMessage({
                      id: 'comp_snap_capitalization',
                      defaultMessage: 'Capitalization'
                    }) + ':'}
                  </TableCell>
                  <TableCell className={classes.contentCell} align="left">
                    {capitalization}
                  </TableCell>
                </TableRow>
              )}
              {result.OrganizationStatus &&
                result.OrganizationStatus !== '' && (
                  <TableRow
                    key={intl.formatMessage({
                      id: 'comp_snap_stock_status',
                      defaultMessage: 'Stock Status'
                    })}
                    className={classes.row}
                  >
                    <TableCell className={classes.titleCell} scope="row">
                      {intl.formatMessage({
                        id: 'comp_snap_stock_status',
                        defaultMessage: 'Stock Status'
                      }) + ':'}
                    </TableCell>
                    <TableCell className={classes.contentCell} align="left">
                      {DOMPurify.sanitize(result.OrganizationStatus)}
                    </TableCell>
                  </TableRow>
                )}
              {result.RegistrationAuthorityName &&
                result.RegistrationAuthorityName !== '' && (
                  <TableRow
                    key={intl.formatMessage({
                      id: 'comp_snap_authority',
                      defaultMessage: 'Authority'
                    })}
                    className={classes.row}
                  >
                    <TableCell className={classes.titleCell} scope="row">
                      {intl.formatMessage({
                        id: 'comp_snap_authority',
                        defaultMessage: 'Authority'
                      }) + ':'}
                    </TableCell>
                    <TableCell className={classes.contentCell} align="left">
                      {DOMPurify.sanitize(result.RegistrationAuthorityName)}
                    </TableCell>
                  </TableRow>
                )}
              <TableRow
                key={intl.formatMessage({
                  id: 'comp_snap_ticker',
                  defaultMessage: 'Ticker'
                })}
                className={classes.row}
              >
                <TableCell className={classes.titleCell} scope="row">
                  {intl.formatMessage({
                    id: 'comp_snap_ticker',
                    defaultMessage: 'Ticker'
                  }) + ':'}
                </TableCell>
                <TableCell className={classes.contentCell} align="left">
                  {DOMPurify.sanitize(result.Ticker)}
                </TableCell>
              </TableRow>
              <TableRow
                key={intl.formatMessage({
                  id: 'comp_snap_ric',
                  defaultMessage: 'RIC'
                })}
                className={classes.row}
              >
                <TableCell className={classes.titleCell} scope="row">
                  {intl.formatMessage({
                    id: 'comp_snap_ric',
                    defaultMessage: 'RIC'
                  }) + ':'}
                </TableCell>
                <TableCell className={classes.contentCell} align="left">
                  {DOMPurify.sanitize(result.PrimaryRIC)}
                </TableCell>
              </TableRow>
              {result.ParentOrganisationName &&
                result.ParentOrganisationName !== '' && (
                  <TableRow
                    key={intl.formatMessage({
                      id: 'comp_snap_parent',
                      defaultMessage: 'Parent'
                    })}
                    className={classes.row}
                  >
                    <TableCell className={classes.titleCell} scope="row">
                      {intl.formatMessage({
                        id: 'comp_snap_parent',
                        defaultMessage: 'Parent'
                      }) + ':'}
                    </TableCell>
                    <TableCell className={classes.contentCell} align="left">
                      {DOMPurify.sanitize(result.ParentOrganisationName)}
                    </TableCell>
                  </TableRow>
                )}
            </TableBody>
          </Table>
        </TableContainer>
        {dataSourceEnabled &&
          result.OrganizationStatus &&
          result.OrganizationStatus === 'Listed' && (
            <div style={{ marginTop: '10px' }}>
              <SeeAllButton
                searchQuery={DOMPurify.sanitize(searchQuery)}
                url={seeAllLink}
                widgetKey="companysnapshot"
                defaultText={
                  <FormattedMessage
                    id="search_for_publications"
                    defaultMessage="Search for Publications"
                  />
                }
                onClickCallback={setTemporaryFilters}
              />
            </div>
          )}
      </Box>
    </Box>
  )
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    clearTemporaryFilter: () =>
      dispatch(FilterStore.actions.clearTemporaryFilter()),
    setTemporaryFilter: (currentFilter: CurrentFilter | CurrentFilter[]) =>
      dispatch(FilterStore.actions.setTemporaryFilter(currentFilter))
  }
}

const mapStateToProps = (state: Store) => {
  return {
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state),
    currentDataSources: SettingsStore.selectors.getCurrentDataSources(state),
    currentfilters: FilterStore.selectors.getCurrentFilters(state)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanySnapshotWidget)
