import { FeaturedResult } from 'components/models/FeaturedResult'
import { Store } from '..'
import { IOneIntranetResultStore } from './reducers'
import { ISeoResult } from 'components/models/SeoResult'

const selectors = {
  getFeaturedResults: (state: Store): FeaturedResult[] =>
    state.resultsOneIntranet.featuredResults,
  getResults: (state: Store): IOneIntranetResultStore =>
    state.resultsOneIntranet.results,
  getResultsCombined: (state: Store): IOneIntranetResultStore =>
    state.resultsOneIntranet.resultsCombined,
  getResultsCombinedQuery: (state: Store): string =>
    state.resultsOneIntranet.resultsCombinedQuery,
  getResultsSeo: (state: Store): ISeoResult =>
    state.resultsOneIntranet.resultsSeo,
  hasResultsBeenFetched: (state: Store): boolean =>
    state.resultsOneIntranet.hasResultsBeenFetched,
  hasResultsSeoBeenFetched: (state: Store): boolean =>
    state.resultsOneIntranet.hasResultsSeoBeenFetched,
  getTotalRowCount: (state: Store): number =>
    state.resultsOneIntranet.totalRowCount,
  getExecutionTime: (state: Store): number =>
    state.resultsOneIntranet.executionTime,
  getLastRow: (state: Store): number => state.resultsOneIntranet.lastRow,
  getHasError: (state: Store): boolean => state.resultsOneIntranet.hasError,
  getResultSeoCount: (state: Store): number =>
    state.resultsOneIntranet.resultSeoCount,
  getLatestQuery: (state: Store): string =>
    state.resultsOneIntranet.resultsCombinedQuery
}

export default selectors
