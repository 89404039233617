import { Store } from '..'

const selectors = {
  getResults: (state: Store): any => state.resultsEins.results,
  getResultsCombined: (state: Store): any => state.resultsEins.resultsCombined,
  getResultsCombinedQuery: (state: Store): any =>
    state.resultsEins.resultsCombinedQuery,
  hasResultsBeenFetched: (state: Store): any =>
    state.resultsEins.hasResultsBeenFetched,
  getResultCount: (state: Store): any => state.resultsEins.resultCount,
  getExecutionTime: (state: Store): any => state.resultsEins.executionTime,
  getCurrentPage: (state: Store): any => state.resultsEins.currentPage
}

export default selectors
