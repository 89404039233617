import { IPeopleResult } from 'components/models/PeopleResult'
import { IOneIntranetPeopleQuery } from './oneIntranet'
import * as Config from 'config'
import { IDeviceSetting } from './deviceSettings'
import { getSPOnlineToken, renewAuthorizationToken } from './token'
import { trackException } from './tracking'
import { IAADState } from 'store/Auth/reducers'

export const searchPeopleResults = async (
  searchQuery: string,
  deviceSettings: IDeviceSetting,
  aadInfo: IAADState
): Promise<IPeopleResult[] | null> => {
  try {
    let spoToken = ''
    if (Config.LOCAL_DEVELOPMENT.toString() === 'false') {
      spoToken = await getSPOnlineToken(aadInfo.instance, aadInfo.accounts)
    }

    // Get and check authentication token
    const esToken = await renewAuthorizationToken(
      aadInfo.accessToken,
      aadInfo.instance,
      aadInfo.accounts
    )

    const apiUrl = `${Config.APIM_BASE_URL}oneintranetapi/postpeople`
    const bodyObject: IOneIntranetPeopleQuery = {
      rowlimit: 5,
      rowsperpage: 5,
      querytext: searchQuery,
      startRow: 0,
      trimduplicates: true,
      processFeaturedResults: false,
      featuredResultsOrigin: 'KPMGFind',
      featuredResultsRowLimit: '3',
      country: '',
      function: '',
      dynamicRefiners: '',
      genericRefiners: [],
      cognitiveEnabled: false,
      city: ''
    }

    const body = JSON.stringify(bodyObject)

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
        'content-type': 'application/json',
        Authorization: `Bearer ${spoToken}`,
        'Authorization-OnPrem': `${esToken}`
      },
      body: body
    })

    if (response && response.ok) {
      let peopleResult: IPeopleResult[] = []

      const oiResult = await response.json()
      peopleResult = oiResult.QueryResults ? oiResult.QueryResults : []

      return peopleResult.map((peopleResult: IPeopleResult) => {
        peopleResult.userPrincipalName = peopleResult.userPrincipalName.replace(
          'i:0#.f|membership|',
          ''
        )
        return peopleResult
      })
    } else if (response) {
      trackException(
        'Error fetching people picker results',
        new Error(
          `Error fetching people picker results, responseCode: ${response.status}`
        )
      )
      return null
    } else {
      trackException(
        'Error fetching people picker results',
        new Error('Error fetching people picker results')
      )
      return null
    }
  } catch (error) {
    trackException('Error fetching people picker results', error)
    return null
  }
}
