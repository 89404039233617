import { Reducer } from 'redux'
import {
  UserSettingsActions,
  UserSettingsActionTypes,
  IFetchUserSettingsSuccess,
  IUpsertUserSettingsSuccess,
  IFetchUserSettingsFailure,
  IFetchUserSettingsNotFound,
  IFetchUserSettingsFromLocalStorageSuccess
} from './actions'
import { initialUserSettingState, IUserSetting } from 'utils/userSettings'
import findVersions from 'constants/findVersions'

export interface IUserSettingsStore {
  userSettings: IUserSetting
  hasUserSettingsBeenFetched: boolean
  hasError: boolean
  validSettings: boolean
}

const initialState: IUserSettingsStore = {
  userSettings: initialUserSettingState(),
  hasUserSettingsBeenFetched: false,
  hasError: false,
  validSettings: false
}

const reducers: Reducer<IUserSettingsStore, UserSettingsActions> = (
  state: IUserSettingsStore = initialState,
  action: UserSettingsActions
) => {
  switch (action.type) {
    case UserSettingsActionTypes.FETCH_USERSETTINGS_REQUEST:
      return {
        ...state,
        userSettings: initialUserSettingState(),
        hasUserSettingsBeenFetched: false,
        validSettings: false,
        hasError: false
      }
    case UserSettingsActionTypes.FETCH_USERSETTINGS_FAILURE:
      const {
        failureUpn,
        failureDisplayName,
        failureUserSettingsFromLocalStorage
      } = (action as IFetchUserSettingsFailure).payload

      return {
        ...state,
        hasUserSettingsBeenFetched: true,
        hasError: true,
        validSettings: true,
        userSettings: {
          ...(failureUserSettingsFromLocalStorage
            ? failureUserSettingsFromLocalStorage
            : state.userSettings),
          upn: failureUpn,
          DisplayName: failureDisplayName,
          initialLoaded: false,
          UseLocalSettings: false,
          ForceNoUpdate: false
        }
      }
    case UserSettingsActionTypes.FETCH_USERSETTINGS_SUCCESS:
      const { response, userUpn, userDisplayName } = (
        action as IFetchUserSettingsSuccess
      ).payload

      const isValid = response ? (response['id'] ? true : false) : false
      const storageVersionValid =
        response['StorageVersion'] === findVersions.userSettings

      if (storageVersionValid && isValid) {
        return {
          ...state,
          hasUserSettingsBeenFetched: true,
          userSettings: {
            ...response,
            upn: userUpn,
            DisplayName: userDisplayName,
            initialLoaded: true,
            UseLocalSettings: false,
            ForceNoUpdate: false
          },
          hasError: false,
          validSettings: true
        }
      } else {
        return {
          ...state,
          hasUserSettingsBeenFetched: true,
          userSettings: {
            ...response,
            upn: userUpn,
            DisplayName: userDisplayName,
            DataSourceOrder: '',
            DataSourceDisabled: '',
            City: '',
            Function: '',
            Country: '',
            PictureUrl: '',
            StorageVersion: findVersions.userSettings,
            initialLoaded: true,
            UseLocalSettings: false,
            ForceNoUpdate: false
          },
          hasError: false,
          validSettings: false
        }
      }
    case UserSettingsActionTypes.FETCH_USERSETTINGS_FROM_LOCALSTORAGE_SUCCESS:
      const { localStorageResponse, localStorageUpn, localStorageDisplayName } =
        (action as IFetchUserSettingsFromLocalStorageSuccess).payload

      return {
        ...state,
        hasUserSettingsBeenFetched: true,
        userSettings: {
          ...localStorageResponse,
          upn: localStorageUpn,
          DisplayName: localStorageDisplayName,
          initialLoaded: true
        },
        hasError: false,
        validSettings: true
      }
    case UserSettingsActionTypes.FETCH_USERSETTINGS_NOTFOUND:
      const { upn, displayName, userSettingsFromLocalStorage } = (
        action as IFetchUserSettingsNotFound
      ).payload

      return {
        ...state,
        hasUserSettingsBeenFetched: true,
        hasError: false,
        validSettings: false,
        userSettings: {
          ...(userSettingsFromLocalStorage
            ? userSettingsFromLocalStorage
            : state.userSettings),
          upn: upn,
          DisplayName: displayName,
          initialLoaded: true,
          UseLocalSettings: false,
          ForceNoUpdate: false
        }
      }
    case UserSettingsActionTypes.RESET_USER_SETTINGS:
      return {
        ...state,
        userSettings: initialUserSettingState()
      }
    case UserSettingsActionTypes.UPSERT_USERSETTINGS_SUCCESS:
      const { upSertUserSettingsResponse, upSertInitialLoaded } = (
        action as IUpsertUserSettingsSuccess
      ).payload

      return {
        ...state,
        userSettings: {
          ...upSertUserSettingsResponse,
          upn: state.userSettings.upn,
          DisplayName: state.userSettings.DisplayName,
          FirstName: state.userSettings.FirstName,
          LastName: state.userSettings.LastName,
          PictureUrl: state.userSettings.PictureUrl,
          initialLoaded: upSertInitialLoaded,
          UseLocalSettings: state.userSettings.UseLocalSettings,
          Department: state.userSettings.Department,
          ForceNoUpdate: state.userSettings.ForceNoUpdate
        }
      }
    default:
      return state
  }
}

export default reducers
