import { MetricsSeries } from 'components/models/MetricsSeries'
import { FetchWithCache } from 'utils/api'
import { trackException } from 'utils/tracking'
import { IUserSetting } from 'utils/userSettings'
import * as Config from 'config'
import { IAADState } from 'store/Auth/reducers'
import { renewAuthorizationToken } from 'utils/token'

export const getSeriesMetricsResults = async (
  aadInfo: IAADState,
  userSettings: IUserSetting,
  fetchForCountry = false
): Promise<MetricsSeries | null> => {
  try {
    let apiUrl = `${Config.APIM_BASE_URL}appinsightsapi/getseriesmetrics`

    if (userSettings.Country && fetchForCountry) {
      apiUrl += `?country=${userSettings.Country}`
    }

    // Get and check authentication token
    const esToken = await renewAuthorizationToken(
      aadInfo.accessToken,
      aadInfo.instance,
      aadInfo.accounts
    )

    const response = await FetchWithCache(apiUrl, {
      method: 'GET',
      headers: {
        'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
        'content-type': 'application/json',
        Authorization: `Bearer ${esToken}`
      }
    })

    if (!response || response.hasError || !response.responseJSON) {
      trackException(
        'Error fetching Metrics series result',
        new Error(
          response && response.errorResponse
            ? response.errorResponse.responseCode +
              ':' +
              response.errorResponse.message
            : ''
        )
      )

      return null
    } else {
      const jsonResponse = await response.responseJSON

      // check if the results contain enough data to generate a proper series, if not return null
      if (!jsonResponse.SeriesMetric || jsonResponse.SeriesMetric.length < 3) {
        return null
      }

      const result: MetricsSeries = {
        SeriesMetric: [],
        TimeSpan: ''
      }

      result.SeriesMetric = jsonResponse.SeriesMetric.map(
        (singleEntry: any) => {
          return {
            Date: new Date(singleEntry[0]),
            TotalSearches: singleEntry[1],
            TotalUsers: singleEntry[2]
          }
        }
      )

      result.TimeSpan = jsonResponse.TimeSpan
      return result
    }
  } catch (error) {
    trackException('Error fetching Metrics series result', error)
    return null
  }
}
