import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesChatPrompt(): any {
  const renderStyles = getStyles()
  return renderStyles()
}
export function getThemeChatPromptDsInput(theme: Theme): any {
  return {
    'label + &': {
      marginTop: theme.spacing(3)
    },
    '& .MuiInputBase-input': {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderEndEndRadius: '16px',
      borderStartEndRadius: '16px',
      position: 'relative',
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
      fontSize: 13,
      padding: '8px 10px 8px 20px',
      '&:focus': {
        borderRadius: 0,
        borderEndEndRadius: '16px',
        borderStartEndRadius: '16px'
      }
    }
  }
}
export function getThemeChatPromptChip(): any {
  return {
    width: '100%',
    height: 'auto',
    padding: '0 !important',
    borderRadius: '4px',
    '& .MuiChip-label': {
      display: 'block',
      whiteSpace: 'normal',
      width: '100%',
      padding: 0
    }
  }
}
export function getThemeChatRelatedQuestionsChip(): any {
  return {
    height: 'auto',
    padding: '0 !important',
    fontSize: '14px',
    marginRight: '1em',
    borderColor: '#004e98',
    borderRadius: '4px',
    '& .MuiChip-label': {
      display: 'block',
      whiteSpace: 'normal',
      width: '100%',
      padding: '0'
    }
  }
}

function getStyles(): any {
  return makeStyles((theme: Theme) => ({
    relatedQuestion: {
      padding: '5px 10px',
      cursor: 'pointer'
    },
    containerInputBox: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      marginBottom: '1em'
    },

    containerInputChip: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'nowrap',
      flexDirection: 'row',
      alignItems: 'flex-start'
    },

    containerInput: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      flexWrap: 'nowrap'
    },
    inputTopicIcon: { margin: '4px 6px 0 12px' },
    inputField: { width: '100%', border: 'none' },
    btnSend: {
      padding: 0,
      backgroundColor: 'transparent',
      marginTop: '4px',
      marginRight: '8px'
    },

    btnSelectDatasource: {
      marginRight: '1em',
      marginTop: '4px'
    },
    //new topic button
    relatedQuestionsContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      marginBottom: '1em',
      marginTop: '1em'
    },
    buttonNewTopic: {
      width: '154px',
      padding: '5px',
      borderRadius: '4px',
      fontSize: '14px',
      marginRight: '1em',
      boxShadow: 'none',
      backgroundColor: '#004e98',
      maxHeight: '37px'
    },
    buttonNewTopicIcon: {
      fontSize: '15px',
      marginRight: '5px',
      marginBottom: '3px'
    },
    buttonNewTopicContent: {
      fontSize: '13px'
    },
    checkBox: {
      flex: '1',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      [`${theme.breakpoints.down(1024)}`]: {
        marginRight: '10px'
      }
    },
    dsSelectionItem: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      alignItems: 'center',
      minWidth: '150px'
    }
  }))
}
