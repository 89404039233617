import { Store } from '..'

const selectors = {
  getResults: (state: Store): any => state.resultsContentFinder.results,
  getResultsCombined: (state: Store): any =>
    state.resultsContentFinder.resultsCombined,
  getResultsCombinedQuery: (state: Store): any =>
    state.resultsContentFinder.resultsCombinedQuery,
  hasResultsBeenFetched: (state: Store): any =>
    state.resultsContentFinder.hasResultsBeenFetched,
  getResultCount: (state: Store): any => state.resultsContentFinder.resultCount,
  getExecutionTime: (state: Store): any =>
    state.resultsContentFinder.executionTime,
  getCurrentPage: (state: Store): any => state.resultsContentFinder.currentPage
}

export default selectors
