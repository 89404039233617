import { AnyAction } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { Store } from '..'
/*import * as Config from 'config'
import AuthStore from '../Auth'
import { buildRefinementFilters } from 'utils/filters'
import { renewAuthorizationToken } from 'utils/token'
import { authProvider } from 'utils/reactad'
import { FetchWithCache } from 'utils/ad'*/
import { IDeviceSetting } from 'utils/deviceSettings'
// import * as Filters from 'constants/filters'
import Selectors from './selectors'
import ResultMetaDataStore from 'store/ResultMetaData'
import {
  ActionMetaData,
  CustomAction,
  getActionMetaData
} from 'store/extension/customAction'

export enum ResultsContentFinderActionTypes {
  FETCH_REQUEST = 'resultsContentFinder/FETCH_REQUEST',
  FETCH_SUCCESS = 'resultsContentFinder/FETCH_SUCCESS',
  FETCH_SUCCESS_COMBINED = 'resultsContentFinder/FETCH_SUCCESS_COMBINED',
  FETCH_FAILURE = 'resultsContentFinder/FETCH_FAILURE',
  INITIALIZE_RESULTS_CONTENTFINDER = 'persist/REHYDRATE'
}

export interface IFetchRequest
  extends CustomAction<ResultsContentFinderActionTypes> {
  payload: {
    resetCombined: boolean
    searchQuery: string
  }
}
export type IFetchFailure = CustomAction<ResultsContentFinderActionTypes>
export interface IFetchSuccess
  extends CustomAction<ResultsContentFinderActionTypes> {
  payload: {
    response: any
  }
}

export const fetchRequest = (
  resetCombined: boolean,
  searchQuery: string,
  actionMetaData: ActionMetaData
): IFetchRequest => ({
  type: ResultsContentFinderActionTypes.FETCH_REQUEST,
  payload: {
    resetCombined: resetCombined,
    searchQuery: searchQuery
  },
  metaData: {
    ...actionMetaData,
    isStartAction: true
  }
})
export const fetchSuccess = (
  response: any = {},
  actionMetaData: ActionMetaData
): IFetchSuccess => ({
  type: ResultsContentFinderActionTypes.FETCH_SUCCESS,
  payload: { response },
  metaData: actionMetaData
})

export const fetchSuccessCombined = (
  response: any = {},
  actionMetaData: ActionMetaData
): IFetchSuccess => ({
  type: ResultsContentFinderActionTypes.FETCH_SUCCESS_COMBINED,
  payload: { response },
  metaData: actionMetaData
})

export const fetchFailure = (
  actionMetaData: ActionMetaData
): IFetchFailure => ({
  type: ResultsContentFinderActionTypes.FETCH_FAILURE,
  metaData: actionMetaData
})

export const fetchResultsContentFinder = (
  searchQuery: string,
  currentPage: number,
  deviceSettings: IDeviceSetting
  // eslint-disable-next-line @typescript-eslint/ban-types
): ThunkAction<Promise<void>, Store, {}, AnyAction> => {
  return async (
    // eslint-disable-next-line @typescript-eslint/ban-types
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => Store
  ) => {
    const actionMetaData = getActionMetaData('contentfinder')

    dispatch(
      ResultMetaDataStore.actions.fetchRequest('contentfinder', actionMetaData)
    )
    const resultsCombinedQuery = Selectors.getResultsCombinedQuery(getState())
    if (resultsCombinedQuery !== searchQuery) {
      if (resultsCombinedQuery === '') {
        dispatch(fetchRequest(false, searchQuery, actionMetaData))
      } else {
        dispatch(fetchRequest(true, searchQuery, actionMetaData))
      }
    } else {
      dispatch(fetchRequest(false, searchQuery, actionMetaData))
    }
    try {
      if (!searchQuery || searchQuery === '' || searchQuery === '""') {
        dispatch(fetchFailure(actionMetaData))
        return
      }

      const t0 = performance.now()
      const t1 = performance.now()

      const resultCount = 4

      const titles = [
        'Global Semiconductor Industry Outlook 2020',
        'Datenqualität - das A und O der Digitalisierten',
        'Audit Präsentation 2020',
        'Audit Angebot 2020'
      ]
      const pictureUrls = [
        'externalContent/media/lightBlueFibres.jpg',
        'externalContent/media/lightBlueFibres.jpg',
        'externalContent/media/lightBlueFibres.jpg',
        'externalContent/media/lightBlueFibres.jpg'
      ]
      const links = ['google.com', 'google.com', 'google.com', 'google.com']
      const dates = ['2020', '2020', '2020', '2020']
      const fileSizes = ['0,93 MB', '1,60 MB', '1,61 MB', '5,91 MB']
      const fileNames = ['', '', 'Audit_103.pdf', 'Audit_100.pdf']
      const descriptions = [
        'Tarif mitigation and R&D planning are potential lorem ipsum',
        'Whitepaper zur Bedeutung von Datenqualitätsloremipsum',
        'Konzern- und Jahresabschlussprüfung',
        'Jahresabschlussprüfung und Konzernabschluss'
      ]
      const likes = [0, 0, 0, 1]
      const views = [1, 0, 17, 49]
      const executionTime = t1 - t0

      dispatch(
        fetchSuccess(
          {
            resultCount,
            executionTime,
            titles,
            pictureUrls,
            links,
            dates,
            fileSizes,
            fileNames,
            descriptions,
            likes,
            views,
            currentPage: currentPage || 1
          },
          actionMetaData
        )
      )
      dispatch(
        ResultMetaDataStore.actions.fetchSuccess(
          'contentfinder',
          {
            executionTime: executionTime,
            resultsCount: resultCount
          },
          actionMetaData
        )
      )

      const resultsCombined = Selectors.getResultsCombined(getState())
      if ((currentPage || 1) === 1) {
        dispatch(
          fetchSuccessCombined(
            {
              titles,
              pictureUrls,
              links,
              dates,
              fileSizes,
              fileNames,
              descriptions,
              likes,
              views
            },
            actionMetaData
          )
        )
      } else {
        dispatch(
          fetchSuccessCombined(
            {
              titles: [...resultsCombined.titles, ...titles],
              pictureUrls: [...resultsCombined.pictureUrls, ...pictureUrls],
              links: [...resultsCombined.links, ...links],
              dates: [...resultsCombined.dates, ...dates],
              fileSizes: [...resultsCombined.fileSizes, ...fileSizes],
              fileNames: [...resultsCombined.fileNames, ...fileNames],
              descriptions: [...resultsCombined.descriptions, ...descriptions],
              likes: [...resultsCombined.likes, ...likes],
              views: [...resultsCombined.views, ...views]
            },
            actionMetaData
          )
        )
      }
    } catch (error) {
      dispatch(
        ResultMetaDataStore.actions.hasError(
          'contentfinder',
          undefined,
          error,
          actionMetaData
        )
      )
      dispatch(fetchFailure(actionMetaData))
    }
  }
}

export type ResultsContentFinderActions =
  | IFetchRequest
  | IFetchFailure
  | IFetchFailure
