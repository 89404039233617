import React, { Dispatch } from 'react'
import { connect } from 'react-redux'
import { Box, IconButton, Typography } from '@mui/material'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Link from '@mui/material/Link'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Store } from 'store'
import SettingsStore from 'store/Settings'
import FilterStore from 'store/Filters'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import {
  trackWidgetShown,
  trackException,
  trackLeftWidgetClick,
  trackEvents
} from 'utils/tracking'
import { removeQueryParam } from 'utils/queryParams'
import { WidgetKey } from 'constants/widgets'
import { dateFormatOptions } from 'constants/constants'
import 'styles/slick_carousel/slick-theme.css'
import 'styles/slick_carousel/slick.css'
import { getStylesLeftWidget } from 'styles/contents/resultpages/widgets/LeftWidget'
import createDOMPurify from 'dompurify'
import TooltipTitle from 'components/contents/common/TooltipTitle'
import { getUrlParameterForCurrentFilters } from 'utils/filters'
import KPMGWebsitesPlaceHolder from 'images/kpmg_placeholder.png'
import { IKpmgWebsitesResult } from 'components/models/KpmgWebsiteResult'
import LeftWidgetSlider from './common/LeftWidgetSlider'

export interface LeftKPMGWebsitesWidgetProps {
  searchQuery: string
  results: IKpmgWebsitesResult[]
}
type AllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  LeftKPMGWebsitesWidgetProps

function LeftKPMGWebsitesWidget(props: AllProps): JSX.Element {
  const {
    results,
    searchQuery,
    deviceSettings,
    currentFilters,
    clearTemporaryFilter
  } = props

  const DOMPurify = createDOMPurify(window)

  const navigateFunction = useNavigate()
  const leftWidgetClasses = getStylesLeftWidget()

  const intl = useIntl()

  React.useEffect(() => {
    trackWidgetShown({
      widgetType: WidgetKey.kpmgWebsites,
      widgetPane: 'left'
    })
  }, [])

  let slides: JSX.Element[] = []
  try {
    slides = results.map((item: IKpmgWebsitesResult, i: number) => {
      const imageUrl = item.image ? DOMPurify.sanitize(item.image) : ''
      let smallImageUrl = imageUrl
        ? `${
            imageUrl.includes('.jpg') ? imageUrl.split('.jpg')[0] : imageUrl
          }/jcr:content/renditions/cq5dam.web.237.158.jpg`
        : ''
      if (DOMPurify.sanitize(item.tabType).toLowerCase() === 'people') {
        smallImageUrl = smallImageUrl.replace('.237.158.jpg', '.237.237.jpg')
      }

      return (
        <div key={'lkpmg' + i}>
          <Box key={'lkpmgb' + i} style={{ width: 20 }} />
          <Link
            key={'lkpmgl' + i}
            onClick={() => {
              removeQueryParam(navigateFunction, 'cntx')
              trackLeftWidgetClick({
                title: DOMPurify.sanitize(item.title),
                url: DOMPurify.sanitize(item.url),
                widgetKey: WidgetKey.kpmgWebsites,
                index: i
              })
            }}
            {...(!deviceSettings.isMobile
              ? {
                  onAuxClick: (event: any) => {
                    removeQueryParam(navigateFunction, 'cntx')
                    trackLeftWidgetClick({
                      title: DOMPurify.sanitize(item.title),
                      url: DOMPurify.sanitize(item.url),
                      widgetKey: WidgetKey.kpmgWebsites,
                      index: i,
                      wasRightClicked: event?.button === 2 ? true : false
                    })
                  }
                }
              : {})}
            href={DOMPurify.sanitize(item.url)}
            className={leftWidgetClasses.cardLink}
            {...(deviceSettings.openLinksInNewTab === true
              ? { target: '_blank', rel: 'noreferrer' }
              : {})}
          >
            <Card key={'lkpmgc' + i} className={leftWidgetClasses.card}>
              <div
                key={'lkpmgd1' + i}
                className={leftWidgetClasses.media}
                style={{
                  backgroundImage:
                    item.image != null && item.image.length > 0
                      ? `url("${smallImageUrl}"), url("${imageUrl}"),url("${KPMGWebsitesPlaceHolder}")`
                      : `url("${KPMGWebsitesPlaceHolder}")`,
                  backgroundColor: '#7787a1'
                }}
              />
              <CardContent
                key={'lkpmgcc' + i}
                style={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '8px 16px 0 16px'
                }}
              >
                <Typography
                  key={'lkpmgt1' + i}
                  variant="body1"
                  color="primary"
                  component="div"
                  style={{ minHeight: 48 }}
                >
                  <TooltipTitle
                    title={DOMPurify.sanitize(item.title?.trim())}
                    key={'lkpmgd2' + i}
                  />
                </Typography>
                <Typography
                  key={'lkpmgt2' + i}
                  variant="body2"
                  color="textSecondary"
                  component="div"
                  style={{
                    marginTop: 6,
                    fontStyle: 'italic',
                    minHeight: '20px'
                  }}
                >
                  {item.date !== ''
                    ? intl.formatDate(
                        DOMPurify.sanitize(item.date),
                        dateFormatOptions
                      )
                    : ''}
                </Typography>
                <Typography
                  key={'lkpmgt3' + i}
                  variant="body2"
                  color="textSecondary"
                  component="div"
                  style={{ fontStyle: 'italic' }}
                >
                  {DOMPurify.sanitize(item.tabType)}
                </Typography>
              </CardContent>
            </Card>
          </Link>
        </div>
      )
    })
  } catch (error) {
    trackException(
      'Error in parsing slides in LeftKPMGWebsitesWidget.tsx',
      error
    )
  }

  if (deviceSettings.renderMobile) {
    slides.push(<Box style={{ flex: '0 0 10px' }} />)
  }

  const seeAllLink = `/results/aem/${getUrlParameterForCurrentFilters(
    currentFilters,
    [{ key: 'page', value: '1' }]
  )}`

  if (deviceSettings.renderMobile && slides.length >= 8)
    slides.push(
      <Box className={leftWidgetClasses.showallContainer}>
        <Box className={leftWidgetClasses.showallContent}>
          <RouterLink
            tabIndex={-1} //focus IconButton
            to={`/results/aem/?q=${DOMPurify.sanitize(searchQuery)}`}
            style={{ textDecoration: 'none' }}
            onClick={() => {
              trackEvents('widget-see-all', {
                widgetType: WidgetKey.kpmgWebsites,
                searchTerm: DOMPurify.sanitize(searchQuery),
                wasRightClicked: false
              })

              clearTemporaryFilter()
            }}
            {...(!deviceSettings.isMobile
              ? {
                  onAuxClick: (event: any) => {
                    trackEvents('widget-see-all', {
                      widgetType: WidgetKey.kpmgWebsites,
                      searchTerm: DOMPurify.sanitize(searchQuery),
                      wasRightClicked:
                        event && event.button === 2 ? true : false
                    })
                  }
                }
              : {})}
          >
            <IconButton className={leftWidgetClasses.iconButton}>
              <ArrowForwardIcon></ArrowForwardIcon>
            </IconButton>
          </RouterLink>
          <Typography className={leftWidgetClasses.showallMessage}>
            <FormattedMessage id="show_all" defaultMessage="Show All" />
          </Typography>
        </Box>
      </Box>
    )

  return (
    <Box id={'con-widget-left-kpmg'} className={leftWidgetClasses.container}>
      {results && results.length > 0 && (
        <>
          <RouterLink
            to={seeAllLink}
            style={{ textDecoration: 'none' }}
            onClick={() => {
              trackEvents('widget-see-all', {
                widgetType: WidgetKey.kpmgWebsites,
                searchTerm: DOMPurify.sanitize(searchQuery),
                wasRightClicked: false
              })

              clearTemporaryFilter()
            }}
            {...(!deviceSettings.isMobile
              ? {
                  onAuxClick: (event: any) => {
                    trackEvents('widget-see-all', {
                      widgetType: WidgetKey.kpmgWebsites,
                      searchTerm: DOMPurify.sanitize(searchQuery),
                      wasRightClicked:
                        event && event.button === 2 ? true : false
                    })
                  }
                }
              : {})}
          >
            <Typography
              variant="h6"
              color="primary"
              component="p"
              className={leftWidgetClasses.link}
              gutterBottom
            >
              {intl.formatMessage({
                id: 'kmpgWebsites_on',
                defaultMessage: 'kpmg.com related'
              })}
            </Typography>
          </RouterLink>
          <Box>
            <LeftWidgetSlider slides={slides} resultsLength={results.length} />
          </Box>
        </>
      )}
    </Box>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state),
    currentFilters: FilterStore.selectors.getCurrentFilters(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    clearTemporaryFilter: () =>
      dispatch(FilterStore.actions.clearTemporaryFilter())
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeftKPMGWebsitesWidget)
