import React from 'react'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import PeopleIcon from '@mui/icons-material/People'
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined'
import SourceIcon from '@mui/icons-material/BlurLinear'
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo'
import NotesIcon from '@mui/icons-material/Notes'
import FindInPageIcon from '@mui/icons-material/FindInPage'
import SyncIcon from '@mui/icons-material/Sync'
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined'
import DescriptionIcon from '@mui/icons-material/Description'
import LanguageIcon from '@mui/icons-material/Language'
import BlurCircularOutlined from '@mui/icons-material/BlurCircularOutlined'
import MenuBookIcon from '@mui/icons-material/MenuBook'
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined'
import { MdLightbulbOutline } from 'react-icons/md'
import { WidgetKey } from './widgets'
import { AssuredWorkload } from '@mui/icons-material'
import ImageSearchIcon from '@mui/icons-material/ImageSearch'

export interface IDataSource {
  name: string
  active: boolean
  enabled: boolean
  enabledForChat?: boolean
  icon: JSX.Element
  path: string
  description: string
  apiId: string | null
  if: {
    device: string[]
  }
  ifNot?: {
    browser: string[]
  }
  healthStatus?: string
  noResults?: boolean
  hasError?: boolean
  group: IDatasourceGroup | IDatasourceGroup[]
  mappedWidget?: string | string[]
  allowedCountries?: string[]
}

export interface IDatasourceGroup {
  key: string
  name: string
  order: number
  function?: string
}

export const datasourcesGroups: { [key: string]: IDatasourceGroup } = {
  globalsources: { key: 'global_sources', name: 'Global Sources', order: 1 },
  localsources: { key: 'local_sources', name: 'Local Sources', order: 2 },
  advisorysources: {
    key: 'advisory_sources',
    name: 'Advisory Sources',
    order: 3,
    function: 'Advisory'
  },
  taxsources: {
    key: 'tax_sources',
    name: 'Tax Sources',
    order: 5,
    function: 'Tax'
  },
  auditsources: {
    key: 'audit_sources',
    name: 'Audit Sources',
    order: 6,
    function: 'Audit'
  },
  externalsources: {
    key: 'external_sources',
    name: 'External Sources',
    order: 4
  }
}

export const datasourcesConfiguration: Array<IDataSource> = [
  {
    name: 'Intranet',
    active: true,
    enabled: true,
    icon: <FolderOutlinedIcon fontSize={'inherit'} />,
    path: '/results/all/',
    description: 'intranet_description',
    apiId: '/apis/oneIntranetAPI',
    if: {
      device: ['All']
    },
    group: datasourcesGroups.globalsources,
    mappedWidget: WidgetKey.intranet
  },
  {
    name: 'People',
    active: true,
    enabled: true,
    icon: <PeopleIcon fontSize={'inherit'} />,
    path: '/results/people/',
    description: 'people_description',
    apiId: '/apis/oneIntranetAPI',
    if: {
      device: ['All']
    },
    group: datasourcesGroups.globalsources,
    mappedWidget: WidgetKey.people
  },
  {
    name: 'News',
    active: true,
    enabled: true,
    icon: <NotesIcon fontSize={'inherit'} />,
    path: '/results/news/',
    description: 'news_description',
    apiId: '/apis/lexisNexisAPI',
    if: {
      device: ['Desktop']
    },
    group: datasourcesGroups.externalsources,
    mappedWidget: WidgetKey.news
  },
  {
    name: 'Source',
    active: true,
    enabled: true,
    icon: <SourceIcon fontSize={'inherit'} />,
    path: '/results/source/',
    description: 'source_description',
    apiId: '/apis/sourceAPI',
    if: {
      device: ['All']
    },
    group: [datasourcesGroups.advisorysources, datasourcesGroups.globalsources],
    mappedWidget: WidgetKey.source
  },
  {
    name: 'Office365',
    active: true,
    enabled: true,
    icon: <FindInPageIcon fontSize={'inherit'} />,
    path: '/results/office365/',
    description: 'office365_description',
    apiId: '/apis/msGraphAPI',
    if: {
      device: ['All']
    },
    group: datasourcesGroups.globalsources,
    mappedWidget: WidgetKey.oneDrive
  },
  {
    name: 'Statistics',
    active: true,
    enabled: true,
    icon: <AssessmentOutlinedIcon fontSize={'inherit'} />,
    path: '/results/statistics/',
    description: 'statistics_description',
    apiId: '/apis/statistaAPI',
    if: {
      device: ['All']
    },
    group: datasourcesGroups.externalsources,
    mappedWidget: [WidgetKey.infographics, WidgetKey.statistics]
  },
  {
    name: 'Engagements',
    active: true,
    enabled: true,
    icon: <DescriptionOutlinedIcon fontSize={'inherit'} />,
    path: '/results/engagements/',
    description: 'engagements_description',
    apiId: '/apis/oneIntranetAPI',
    if: {
      device: ['Desktop']
    },
    group: datasourcesGroups.globalsources,
    mappedWidget: WidgetKey.engagements
  },
  {
    name: 'kpmg.com',
    active: true,
    enabled: true,
    icon: <LanguageIcon fontSize={'inherit'} />,
    path: '/results/aem/',
    description: 'kpmg_websites_description',
    apiId: '/apis/aemAPI',
    if: {
      device: ['All']
    },
    group: datasourcesGroups.globalsources,
    mappedWidget: WidgetKey.kpmgWebsites
  },
  {
    name: 'Klardenker',
    active: true,
    enabled: true,
    icon: <MdLightbulbOutline fontSize={'inherit'} />,
    path: '/results/klardenker/',
    description: 'klardenker_description',
    apiId: '/apis/klardenkerAPI',
    if: {
      device: ['All']
    },
    group: datasourcesGroups.localsources,
    mappedWidget: WidgetKey.klardenker
  },
  {
    name: 'Videos',
    active: true,
    enabled: true,
    icon: <OndemandVideoIcon fontSize={'inherit'} />,
    path: '/results/videos/',
    description: 'videos_description',
    apiId: '/apis/oneIntranetAPI',
    if: {
      device: ['All']
    },
    group: datasourcesGroups.globalsources,
    mappedWidget: WidgetKey.video
  },
  {
    name: 'Alex',
    active: true,
    enabled: true,
    icon: <DescriptionIcon fontSize={'inherit'} />,
    path: '/results/alex/',
    description: 'alex_description',
    apiId: '/apis/alexAPI',
    if: {
      device: ['All']
    },
    group: [datasourcesGroups.auditsources, datasourcesGroups.globalsources]
  },
  {
    name: 'KnowledgeExchange',
    active: true,
    enabled: true,
    icon: <SyncIcon fontSize={'inherit'} />,
    path: '/results/knowledgeexchange/',
    description: 'knowledge_exchange_description',
    apiId: '/apis/knowledgeExchangeAPI',
    if: {
      device: ['All']
    },
    group: datasourcesGroups.localsources
  },
  {
    name: 'Eins',
    active: false,
    enabled: false,
    icon: <MdLightbulbOutline fontSize={'inherit'} />,
    path: '/results/eins/',
    description: 'eins_description',
    apiId: '',
    if: {
      device: ['All']
    },
    group: datasourcesGroups.localsources
  },
  {
    name: 'Projekt Laufwerke',
    active: false,
    enabled: false,
    icon: <MdLightbulbOutline fontSize={'inherit'} />,
    path: '/results/project_spaces/',
    description: 'project_spaces_description',
    apiId: '',
    if: {
      device: ['All']
    },
    group: datasourcesGroups.localsources
  },
  {
    name: 'ContentFinder',
    active: false,
    enabled: false,
    icon: <OndemandVideoIcon fontSize={'inherit'} />,
    path: '/results/contentfinder/',
    description: 'contentfinder_description',
    apiId: '',
    if: {
      device: ['All']
    },
    group: [datasourcesGroups.localsources, datasourcesGroups.advisorysources]
  },
  {
    name: 'KPMG MPP',
    active: true,
    enabled: true,
    icon: <MenuBookIcon fontSize={'inherit'} />,
    path: '/results/kpmg_mpp/',
    description: 'kpmg_mpp_description',
    apiId: '/apis/mppAPI',
    if: {
      device: ['All']
    },
    group: datasourcesGroups.globalsources
  },
  {
    name: 'MarketResearch',
    active: true,
    enabled: true,
    icon: <BlurCircularOutlined fontSize={'inherit'} />,
    path: '/results/marketresearch/',
    description: 'marketresearch_description',
    apiId: '/apis/marketResearchAPI',
    if: {
      device: ['All']
    },
    group: datasourcesGroups.externalsources
  },
  {
    name: 'IBFD',
    active: true,
    enabled: true,
    icon: <AssuredWorkload fontSize={'inherit'} />,
    path: '/results/ibfd/',
    description: 'ibfd_description',
    apiId: '/apis/ibfdAPI',
    if: {
      device: ['All']
    },
    group: datasourcesGroups.externalsources,
    allowedCountries: [
      'AR',
      'AM',
      'AU',
      'AT',
      'AZ',
      'BH',
      'BB',
      'BY',
      'BE',
      'BM',
      'BG',
      'KH',
      'CA',
      'CL',
      'CN',
      'CO',
      'CR',
      'HR',
      'CY',
      'CZ',
      'DK',
      'EE',
      'FI',
      'FR',
      'GE',
      'DE',
      'GG',
      'GH',
      'GR',
      'HK',
      'HU',
      'IS',
      'IN',
      'ID',
      'IE',
      'IM',
      'IL',
      'IT',
      'CI',
      'JM',
      'JP',
      'KZ',
      'KE',
      'KR',
      'LV',
      'LU',
      'MT',
      'MU',
      'MX',
      'MA',
      'MZ',
      'NL',
      'NZ',
      'NO',
      'OM',
      'PA',
      'PE',
      'PH',
      'PL',
      'PT',
      'QA',
      'RO',
      'RS',
      'SG',
      'SI',
      'SK',
      'ZA',
      'ES',
      'SE',
      'CH',
      'TW',
      'TH',
      'TN',
      'TR',
      'UA',
      'AE',
      'GB',
      'US',
      'UY',
      'VN'
    ]
  },
  {
    name: 'Images',
    active: true,
    enabled: true,
    icon: <ImageSearchIcon fontSize={'inherit'} />,
    path: '/results/images/',
    description: 'images_description',
    apiId: '/apis/oneIntranetAPI',
    if: {
      device: ['All']
    },
    group: datasourcesGroups.globalsources
  },
  {
    name: 'Kpmg.us',
    active: true,
    enabled: true,
    icon: <FolderOutlinedIcon fontSize={'inherit'} />,
    path: '/results/kpmgus/',
    description: 'kpmgus_description',
    apiId: '/apis/kpmgUSAPI',
    if: {
      device: ['All']
    },
    group: datasourcesGroups.localsources
  },
  {
    name: 'Chat',
    active: true,
    enabled: true,
    icon: <ChatOutlinedIcon fontSize={'inherit'} />,
    path: '/results/chat/',
    description: 'chat_description',
    apiId: '/apis/chatAPI',
    if: {
      device: ['All']
    },
    group: datasourcesGroups.globalsources
  }
]
