import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesArrows(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles() {
  return makeStyles((theme: Theme) => ({
    sliderButton: {
      width: '48px',
      height: '48px',
      color: '#424242',
      background: '#ffffff',
      boxShadow: '0 0 0 1px rgba(0,0,0,0.04), 0 4px 8px 0 rgba(0,0,0,0.20)',
      '&:hover': {
        background: '#d2d2d2'
      },
      '@media (forced-colors: active)': {
        forcedColorAdjust: 'none',
        color: 'LinkText',
        background: 'Canvas !important',
        border: '1px solid LinkText'
      }
    },
    sliderButtonRight: {
      zIndex: 10,
      margin: 0,
      right: '-1px'
    },
    sliderButtonLeft: {
      zIndex: 10,
      margin: 0,
      left: '-35px'
    }
  }))
}
