import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesContentFinderResult(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles() {
  return makeStyles((theme: Theme) => ({
    title: {
      fontSize: '20px',
      paddingTop: '4px',
      marginBottom: '3px',
      color: '#004e98',
      width: '100%',
      [`${theme.breakpoints.down(1024)}`]: {
        fontSize: '18px',
        lineHeight: '20px',
        marginBottom: '12px',
        fontWeight: '400',
        fontFamility: 'Roboto, HelveticaNeue, Arial, sans-serif'
      }
    },
    b: {
      color: '#3c4043',
      fontSize: 14
    },
    contentFinderCard: {
      marginBottom: '16px',
      marginRight: '16px',
      boxShadow: 'none',
      border: 'solid 1px #dfe1e5',
      borderRadius: 4,
      width: 450,
      [`${theme.breakpoints.down(1024)}`]: {
        border: 'none',
        backgroundColor: '#fff',
        margin: '0 0 10px 0',
        boxShadow: '0 1px 6px rgba(32, 33, 36, 0.28)',
        borderRadius: '4px'
      }
    },
    contentFinderContentWrapper: {
      display: 'flex',
      height: 155
    },
    contentFinderCardFooter: {
      backgroundColor: '#f7f7f7',
      padding: '0 30px',
      height: 28
    },
    contentFinderFooterText: {
      height: 28,
      lineHeight: '28px'
    },
    contentFinderFileInfo: {
      display: 'flex',
      fontSize: 12,
      padding: '10px 0'
    },
    contentFinderFileInfoItem: {
      display: 'flex',
      marginRight: 20
    },
    contentFinderIcon: {
      height: 12,
      width: 12,
      marginRight: 4,
      [`${theme.breakpoints.down(1024)}`]: {
        color: '#005eb8'
      }
    },
    contentFinderDetails: {
      display: 'flex',
      flex: '1',
      flexDirection: 'column',
      [`${theme.breakpoints.down(1024)}`]: {
        width: '70%'
      }
    },
    contentFinderContent: {
      flex: '1 0 auto',
      paddingBottom: '0'
    },
    contentFinderBodyText: {
      display: '-webkit-box',
      '-webkit-box-orient': 'vertical',
      [`${theme.breakpoints.down(1024)}`]: {
        fontSize: '12px'
      }
    },
    isVisitedResult: {
      color: '#609',
      '&:hover': {
        textDecoration: 'underline',
        textDecorationColor: '#609'
      }
    }
  }))
}
