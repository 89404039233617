import { Store } from '..'
import { IEntity } from './reducers'

const selectors = {
  getAutocompleteResults: (state: Store): any =>
    state.search.results.autoSuggestData,
  hasResultsBeenFetched: (state: Store): any =>
    state.search.hasResultsBeenFetched,
  getHistory: (state: Store): any => state.search.history,
  hasError: (state: Store): any => state.search.hasError,
  getSpellCorrectedSearchTerm: (state: Store): any =>
    state.search.spellCorrectedSearchQuery,
  getEntities: (state: Store): IEntity[] => state.search.entities,
  hasSearchDataBeenFetched: (state: Store): boolean =>
    state.search.hasSearchDataBeenFetched
}

export default selectors
