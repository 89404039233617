import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesResultsVideos(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles(): any {
  return makeStyles((theme: Theme) => ({
    grid: {
      marginBottom: '16px'
    }
  }))
}
