import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import { useIntl } from 'react-intl'
import ReactPlaceholder from 'react-placeholder'
import { Box, Fade } from '@mui/material'
import 'Placeholder.css'
import { Store } from 'store'
import ResultsKlardenkerStore from 'store/ResultsKlardenker'
import { KlardenkerResult } from 'components/contents/results/KlardenkerResult'
import Pagination from '../Pagination'
import { trackException } from 'utils/tracking'
import SearchSuggestions from 'components/contents/common/SearchSuggestions'
import { IDeviceSetting } from 'utils/deviceSettings'
import UserSettingsStore from 'store/UserSettings'
import FeaturedResults from './FeaturedResults'
import LeftWidgetContainer from './widgetscontainer/LeftWidgetContainer'
import { getStylesResults } from 'styles/contents/resultpages/Results'
import { useFilter } from 'utils/filters'
import createDOMPurify from 'dompurify'

interface ResultsKlardenkerMobileProps {
  searchQuery: any
  deviceSettings: IDeviceSetting
  authToken: any
}

type AllMobileProps = ReturnType<typeof mapStateToProps> &
  ResultsKlardenkerMobileProps

function ResultsKlardenkerMobile(props: AllMobileProps) {
  const {
    hasResultsBeenFetched,
    resultsCount,
    featuredResults,
    resultsCombined,
    searchQuery,
    deviceSettings,
    authToken
  } = props
  const DOMPurify = createDOMPurify(window)
  const resultClasses = getStylesResults()
  const intl = useIntl()
  const [currentPage] = useFilter('page', '1')

  const returnAllItems = (type: string) => {
    // If no results are present, make sure to not render the empty result frames
    if (!resultsCombined || resultsCombined.length < 1) {
      return []
    }

    const items = []

    try {
      const firstResults =
        resultsCombined.length < 4 ? resultsCombined.length : 3

      if (type === 'first') {
        for (let i = 0; i < firstResults; i++) {
          items.push(
            <KlardenkerResult
              imageUrl={DOMPurify.sanitize(resultsCombined[i].image)}
              title={DOMPurify.sanitize(resultsCombined[i].title)}
              body={DOMPurify.sanitize(resultsCombined[i].body)}
              url={DOMPurify.sanitize(resultsCombined[i].url)}
              key={i}
              index={i}
              searchQuery={DOMPurify.sanitize(searchQuery)}
              breadcrumbContent={intl.formatDate(
                DOMPurify.sanitize(resultsCombined[i].date)
              )}
              deviceSettings={deviceSettings}
              authToken={authToken}
              id={resultsCombined[i].id}
            />
          )
        }
      } else {
        for (let i = firstResults; i < resultsCombined.length; i++) {
          if (i < resultsCombined.length) {
            items.push(
              <KlardenkerResult
                imageUrl={DOMPurify.sanitize(resultsCombined[i].image)}
                title={DOMPurify.sanitize(resultsCombined[i].title)}
                body={DOMPurify.sanitize(resultsCombined[i].body)}
                url={DOMPurify.sanitize(resultsCombined[i].url)}
                key={i}
                index={i}
                searchQuery={DOMPurify.sanitize(searchQuery)}
                breadcrumbContent={intl.formatDate(
                  DOMPurify.sanitize(resultsCombined[i].date)
                )}
                deviceSettings={deviceSettings}
                authToken={authToken}
                id={resultsCombined[i].id}
              />
            )
          }
        }
      }
    } catch (error) {
      trackException(
        'Error returning all items in ResultsKlardenkerMobile.tsx',
        error
      )
    }
    return items
  }

  const firstItems = useMemo(
    () => returnAllItems('first'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resultsCombined, intl]
  )
  const restItems = useMemo(
    () => returnAllItems('rest'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resultsCombined, intl]
  )

  return (
    <Box className={resultClasses.container}>
      <Box className={resultClasses.resultsContainer}>
        <SearchSuggestions
          wildCardActive={false}
          resultCount={resultsCount}
          resultsFetchend={hasResultsBeenFetched}
        />
        <ReactPlaceholder
          ready={
            hasResultsBeenFetched || Number(currentPage) > 1 ? true : false
          }
          type="text"
          rows={4}
          showLoadingAnimation={true}
          className={resultClasses.placeholder}
        >
          <Fade
            in={hasResultsBeenFetched || Number(currentPage) > 1 ? true : false}
            timeout={600}
          >
            <Box>
              {resultsCombined && resultsCount > 0 && (
                <FeaturedResults featuredResults={featuredResults} />
              )}
              {firstItems}
            </Box>
          </Fade>
        </ReactPlaceholder>
        {hasResultsBeenFetched &&
        (resultsCount < 1 || resultsCombined.length < 1) ? null : (
          <LeftWidgetContainer
            resultCount={resultsCount}
            hasResultsBeenFetched={hasResultsBeenFetched}
          />
        )}
        <ReactPlaceholder
          ready={hasResultsBeenFetched || Number(currentPage) > 1}
          type="text"
          rows={4}
          showLoadingAnimation={true}
          className={resultClasses.placeholder}
        >
          <Fade
            in={hasResultsBeenFetched || Number(currentPage) > 1}
            timeout={600}
          >
            <Box>
              {restItems}
              {resultsCount != null && resultsCount > 0 && (
                <Pagination
                  {...props}
                  totalRecords={resultsCount}
                  pageLimit={20}
                  pageNeighbours={4}
                />
              )}
            </Box>
          </Fade>
        </ReactPlaceholder>
        <ReactPlaceholder
          ready={hasResultsBeenFetched ? hasResultsBeenFetched : false}
          type="text"
          rows={4}
          showLoadingAnimation={true}
          className={resultClasses.placeholder}
        >
          <Box />
        </ReactPlaceholder>
        <ReactPlaceholder
          ready={hasResultsBeenFetched ? hasResultsBeenFetched : false}
          type="text"
          rows={4}
          showLoadingAnimation={true}
          className={resultClasses.placeholder}
        >
          <Box />
        </ReactPlaceholder>
      </Box>
    </Box>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    featuredResults: ResultsKlardenkerStore.selectors.getFeaturedResults(state),
    resultsCombined: ResultsKlardenkerStore.selectors.getResultsCombined(state),
    resultsCount: ResultsKlardenkerStore.selectors.getResultCount(state),
    executionTime: ResultsKlardenkerStore.selectors.getExecutionTime(state),
    hasResultsBeenFetched:
      ResultsKlardenkerStore.selectors.hasResultsBeenFetched(state),
    userSettings: UserSettingsStore.selectors.getUserSettings(state)
  }
}

export default connect(mapStateToProps)(ResultsKlardenkerMobile)
