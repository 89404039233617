import { leftEngagementsWidgetGenerator } from './leftEngagementsWidget'
import { leftNewsWidgetGenerator } from './leftNewsWidget'
import { leftPeopleWidgetGenerator } from './leftPeopleWidget'
import { leftStatisticsWidgetGenerator } from './leftStatisticsWidget'
import { leftInfographicsWidgetGenerator } from './leftInfographicsWidget'
import { leftVideosWidgetGenerator } from './leftVideosWidget'
import { leftMobileOfficeLocationWidgetGenerator } from './leftMobileOfficeLocationWidget'
import { leftIntranetWidgetGenerator } from './leftIntranetWidget'
import { leftKlardenkerWidgetGenerator } from './leftKlardenkerWidget'
import { leftKPMGWebsitesWidgetGenerator } from './leftKPMGWebsitesWidget'
import { leftPeopleAlsoSearchedWidgetGenerator } from './leftPeopleAlsoSearchedWidget'
import { leftSourceWidgetGenerator } from './leftSourceWidget'
import { leftConfluenceFinlandWidgetGenerator } from './leftConfluenceFinlandWidget'
import { rightInfographicsWidgetGenerator } from './rightInfographicsWidget'
import { rightNewsWidgetGenerator } from './rightNewsWidget'
import { rightKlardenkerWidgetGenerator } from './rightKlardenkerWidget'
import { rightThoughtLeadershipWidgetGenerator } from './rightThoughtLeadershipWidget'
import { rightMeercatWidgetGenerator } from './rightMeercatWidget'
import { rightKPMGWebsitesWidgetGenerator } from './rightKPMGWebsitesWidget'
import { rightOneDriveWidgetGenerator } from './rightOneDriveWidget'
import { rightIntranetWidgetGenerator } from './rightIntranetWidget'
import { rightSearchTop10WidgetGenerator } from './rightSearchTop10Widget'
import { rightKCIPWidgetGenerator } from './rightKCIPWidget'
import { WidgetKey } from 'constants/widgets'
import {
  ResultLeftWidgetGenerator,
  ResultRightWidgetGenerator
} from './interfaces'
import { rightPeopleWidgetGenerator } from './rightPeopleWidget'
import { rightSourceWidgetGenerator } from './rightSourceWidget'
import { leftServiceNowChWidgetGenerator } from './leftServiceNowChWidget'
import { rightServiceNowChWidgetGenerator } from './rightServiceNowChWidget'
import { rightMetricsWidgetGenerator } from './rightMetricsWidget'
import { leftServiceNowAUWidgetGenerator } from './leftServiceNowAUWidget'
import { rightServiceNowAUWidgetGenerator } from './rightServiceNowAUWidget'
import { leftVivaEngageWidgetGenerator } from './leftVivaEngageWidget'
import { rightVivaEngageWidgetGenerator } from './rightVivaEngageWidget'

export interface IWidgetConfig {
  type: string
  generator: ResultLeftWidgetGenerator | ResultRightWidgetGenerator
  key: string
  country?: string[]
  onlyMobile?: boolean
  notMobile?: boolean
  weight: number
  notInDatasources?: string[]
  datasources?: string[]
  filterIsNot?: CurrentFilter[]
}
export interface IWidgetPreselectConfig {
  queryContains: string[]
  leftWidgetKey: string | null
  rightWidgetKey: string | null
}

// Widget config list,
// Configure new widgets here, used for random and preselected widgets
export const widgetConfigList: IWidgetConfig[] = [
  {
    type: 'left',
    generator: leftEngagementsWidgetGenerator,
    key: WidgetKey.engagements,
    weight: 1,
    notMobile: true,
    datasources: [
      'intranet',
      'people',
      'kpmg.com',
      'kpmg.us',
      'source',
      'statistics',
      'marketresearch',
      'klardenker',
      'knowledgeexchangeopen',
      'knowledgeexchangecatalog',
      'ibfd'
    ],
    notInDatasources: [
      'engagements',
      'news',
      'office365files',
      'office365mails',
      'office365calendar',
      'office365sites',
      'office365teams',
      'videos',
      'alex',
      'kpmg mpp',
      'images'
    ]
  },
  {
    type: 'left',
    generator: leftNewsWidgetGenerator,
    key: WidgetKey.news,
    weight: 3,
    notMobile: true,
    datasources: [
      'intranet',
      'engagements',
      'people',
      'source',
      'statistics',
      'marketresearch',
      'klardenker',
      'knowledgeexchangeopen',
      'knowledgeexchangecatalog',
      'ibfd',
      'kpmg.us'
    ],
    notInDatasources: [
      'news',
      'office365files',
      'office365mails',
      'office365calendar',
      'office365sites',
      'office365teams',
      'kpmg.com',
      'videos',
      'alex',
      'kpmg mpp',
      'images'
    ]
  },
  {
    type: 'left',
    generator: leftPeopleWidgetGenerator,
    key: WidgetKey.people,
    weight: 2,
    datasources: [
      'intranet',
      'engagements',
      'news',
      'office365files',
      'office365mails',
      'office365calendar',
      'office365sites',
      'office365teams',
      'kpmg.com',
      'kpmg.us',
      'source',
      'alex',
      'kpmg mpp',
      'klardenker',
      'knowledgeexchangeopen',
      'knowledgeexchangecatalog'
    ],
    notInDatasources: [
      'people',
      'statistics',
      'marketresearch',
      'videos',
      'images',
      'ibfd'
    ]
  },
  {
    type: 'left',
    generator: leftStatisticsWidgetGenerator,
    key: WidgetKey.statistics,
    weight: 2,
    datasources: [
      'intranet',
      'engagements',
      'news',
      'office365files',
      'office365mails',
      'office365calendar',
      'office365sites',
      'office365teams',
      'source',
      'marketresearch',
      'klardenker',
      'knowledgeexchangeopen',
      'knowledgeexchangecatalog',
      'kpmg.us'
    ],
    notInDatasources: [
      'people',
      'kpmg.com',
      'statistics',
      'videos',
      'alex',
      'kpmg mpp',
      'images',
      'ibfd'
    ]
  },
  {
    type: 'left',
    generator: leftInfographicsWidgetGenerator,
    key: WidgetKey.infographics,
    weight: 2,
    datasources: [
      'intranet',
      'engagements',
      'news',
      'office365files',
      'office365mails',
      'office365calendar',
      'office365sites',
      'office365teams',
      'source',
      'statistics',
      'marketresearch',
      'klardenker',
      'knowledgeexchangeopen',
      'knowledgeexchangecatalog',
      'ibfd',
      'kpmg.us'
    ],
    notInDatasources: [
      'people',
      'kpmg.com',
      'videos',
      'alex',
      'kpmg mpp',
      'images'
    ]
  },
  {
    type: 'left',
    generator: leftVideosWidgetGenerator,
    key: WidgetKey.video,
    weight: 1,
    datasources: [
      'intranet',
      'office365files',
      'office365mails',
      'office365calendar',
      'office365sites',
      'office365teams',
      'kpmg.com',
      'source',
      'knowledgeexchangeopen',
      'knowledgeexchangecatalog'
    ],
    notInDatasources: [
      'engagements',
      'people',
      'news',
      'statistics',
      'marketresearch',
      'videos',
      'alex',
      'kpmg mpp',
      'klardenker',
      'images',
      'ibfd',
      'kpmg.us'
    ]
  },
  {
    type: 'left',
    generator: leftMobileOfficeLocationWidgetGenerator,
    key: WidgetKey.mobileOfficeLocation,
    onlyMobile: true,
    weight: 1,
    datasources: ['intranet', 'source'],
    notInDatasources: [
      'engagements',
      'people',
      'news',
      'office365files',
      'office365mails',
      'office365calendar',
      'office365sites',
      'office365teams',
      'kpmg.com',
      'kpmg.us',
      'statistics',
      'marketresearch',
      'videos',
      'alex',
      'kpmg mpp',
      'klardenker',
      'knowledgeexchangeopen',
      'knowledgeexchangecatalog',
      'images',
      'ibfd'
    ]
  },
  {
    type: 'left',
    generator: leftPeopleAlsoSearchedWidgetGenerator,
    key: WidgetKey.peopleAlsoSearched,
    weight: 3,
    datasources: [
      'intranet',
      'people',
      'news',
      'office365files',
      'office365mails',
      'office365calendar',
      'office365sites',
      'office365teams',
      'kpmg.com',
      'kpmg.us',
      'source',
      'statistics',
      'marketresearch',
      'alex',
      'kpmg mpp',
      'klardenker',
      'knowledgeexchangeopen',
      'knowledgeexchangecatalog',
      'ibfd'
    ],
    notInDatasources: ['engagements', 'videos', 'images']
  },
  {
    type: 'left',
    generator: leftIntranetWidgetGenerator,
    key: WidgetKey.intranet,
    weight: 1,
    datasources: [
      'news',
      'office365files',
      'office365mails',
      'office365calendar',
      'office365sites',
      'office365teams',
      'kpmg.com',
      'kpmg.us',
      'source',
      'alex',
      'kpmg mpp',
      'klardenker',
      'knowledgeexchangeopen',
      'knowledgeexchangecatalog'
    ],
    notInDatasources: [
      'intranet',
      'engagements',
      'people',
      'statistics',
      'marketresearch',
      'videos',
      'images',
      'ibfd'
    ]
  },
  {
    type: 'left',
    generator: leftKlardenkerWidgetGenerator,
    key: WidgetKey.klardenker,
    country: ['Germany'],
    weight: 1,
    datasources: [
      'intranet',
      'engagements',
      'news',
      'office365files',
      'office365mails',
      'office365calendar',
      'office365sites',
      'office365teams',
      'kpmg.com',
      'source',
      'statistics',
      'marketresearch',
      'alex',
      'ibfd'
    ],
    notInDatasources: [
      'people',
      'videos',
      'kpmg mpp',
      'klardenker',
      'knowledgeexchangeopen',
      'knowledgeexchangecatalog',
      'images',
      'kpmg.us'
    ]
  },
  {
    type: 'left',
    generator: leftKPMGWebsitesWidgetGenerator,
    key: WidgetKey.kpmgWebsites,
    weight: 1,
    datasources: [
      'intranet',
      'news',
      'source',
      'statistics',
      'marketresearch',
      'alex',
      'klardenker',
      'knowledgeexchangeopen',
      'knowledgeexchangecatalog',
      'ibfd',
      'kpmg.us'
    ],
    notInDatasources: [
      'engagements',
      'people',
      'office365files',
      'office365mails',
      'office365calendar',
      'office365sites',
      'office365teams',
      'kpmg.com',
      'videos',
      'kpmg mpp',
      'images'
    ]
  },
  {
    type: 'left',
    generator: leftSourceWidgetGenerator,
    key: WidgetKey.source,
    weight: 1,
    datasources: [
      'intranet',
      'engagements',
      'people',
      'office365files',
      'office365mails',
      'office365calendar',
      'office365sites',
      'office365teams',
      'statistics',
      'klardenker',
      'knowledgeexchangeopen',
      'knowledgeexchangecatalog',
      'ibfd',
      'kpmg.us'
    ],
    notInDatasources: [
      'source',
      'news',
      'kpmg.com',
      'videos',
      'alextopics',
      'alexmanuals',
      'alexsearch',
      'kpmg mpp',
      'marketresearch',
      'images'
    ]
  },
  {
    type: 'left',
    generator: leftConfluenceFinlandWidgetGenerator,
    key: WidgetKey.confluenceFi,
    weight: 3,
    datasources: ['intranet'],
    notInDatasources: [
      'engagements',
      'people',
      'office365files',
      'office365mails',
      'office365calendar',
      'office365sites',
      'office365teams',
      'statistics',
      'klardenker',
      'knowledgeexchangeopen',
      'knowledgeexchangecatalog',
      'source',
      'news',
      'kpmg.com',
      'videos',
      'alextopics',
      'alexmanuals',
      'alexsearch',
      'kpmg mpp',
      'marketresearch',
      'images',
      'ibfd',
      'kpmg.us'
    ],
    country: ['Finland', 'Estonia']
  },
  {
    type: 'left',
    generator: leftServiceNowChWidgetGenerator,
    key: WidgetKey.servicenowch,
    weight: 3,
    datasources: ['intranet'],
    notInDatasources: [
      'news',
      'office365files',
      'office365mails',
      'office365calendar',
      'office365sites',
      'office365teams',
      'kpmg.com',
      'videos',
      'alex',
      'kpmg mpp',
      'engagements',
      'people',
      'source',
      'statistics',
      'marketresearch',
      'klardenker',
      'knowledgeexchangeopen',
      'knowledgeexchangecatalog',
      'images',
      'ibfd',
      'kpmg.us'
    ],
    country: ['Switzerland']
  },
  {
    type: 'left',
    generator: leftServiceNowAUWidgetGenerator,
    key: WidgetKey.servicenowau,
    weight: 3,
    datasources: ['intranet'],
    notInDatasources: [
      'news',
      'office365files',
      'office365mails',
      'office365calendar',
      'office365sites',
      'office365teams',
      'kpmg.com',
      'videos',
      'alex',
      'kpmg mpp',
      'engagements',
      'people',
      'source',
      'statistics',
      'marketresearch',
      'klardenker',
      'knowledgeexchangeopen',
      'knowledgeexchangecatalog',
      'images',
      'ibfd',
      'kpmg.us'
    ],
    country: ['Australia']
  },
  {
    type: 'right',
    generator: rightNewsWidgetGenerator,
    key: WidgetKey.news,
    weight: 3,
    notMobile: true,
    datasources: [
      'intranet',
      'engagements',
      'people',
      'source',
      'statistics',
      'marketresearch',
      'videos',
      'klardenker',
      'knowledgeexchangeopen',
      'knowledgeexchangecatalog',
      'images',
      'ibfd',
      'kpmg.us'
    ],
    notInDatasources: [
      'news',
      'office365files',
      'office365mails',
      'office365calendar',
      'office365sites',
      'office365teams',
      'kpmg.com',
      'alex',
      'kpmg mpp'
    ]
  },
  {
    type: 'left',
    generator: leftVivaEngageWidgetGenerator,
    key: WidgetKey.vivaengage,
    weight: 3,
    datasources: [
      'intranet',
      'engagements',
      'people',
      'office365files',
      'office365mails',
      'office365calendar',
      'office365sites',
      'office365teams',
      'statistics',
      'klardenker',
      'knowledgeexchangeopen',
      'knowledgeexchangecatalog',
      'ibfd',
      'kpmg.us',
      'source',
      'news',
      'kpmg.com',
      'videos',
      'alextopics',
      'alexmanuals',
      'alexsearch',
      'kpmg mpp',
      'marketresearch',
      'images'
    ],
    notInDatasources: []
  },
  {
    type: 'right',
    generator: rightInfographicsWidgetGenerator,
    key: WidgetKey.infographics,
    weight: 2,
    datasources: [
      'intranet',
      'engagements',
      'news',
      'office365files',
      'office365mails',
      'office365calendar',
      'office365sites',
      'office365teams',
      'source',
      'statistics',
      'marketresearch',
      'klardenker',
      'knowledgeexchangeopen',
      'knowledgeexchangecatalog',
      'ibfd',
      'kpmg.us'
    ],
    notInDatasources: [
      'people',
      'kpmg.com',
      'videos',
      'alex',
      'kpmg mpp',
      'images'
    ]
  },
  {
    type: 'right',
    generator: rightKlardenkerWidgetGenerator,
    key: WidgetKey.klardenker,
    notInDatasources: ['klardenker'],
    country: ['Germany'],
    weight: 1
  },
  {
    type: 'right',
    generator: rightThoughtLeadershipWidgetGenerator,
    key: WidgetKey.thoughtleadership,
    weight: 2,
    datasources: [
      'intranet',
      'engagements',
      'people',
      'news',
      'office365files',
      'office365mails',
      'office365calendar',
      'office365sites',
      'office365teams',
      'kpmg.com',
      'source',
      'statistics',
      'marketresearch',
      'videos',
      'alex',
      'klardenker',
      'knowledgeexchangeopen',
      'knowledgeexchangecatalog',
      'images',
      'ibfd',
      'kpmg.us'
    ],
    notInDatasources: ['kpmg mpp'],
    filterIsNot: [{ key: 'country', value: 'GTL' }]
  },
  {
    type: 'right',
    generator: rightMeercatWidgetGenerator,
    key: WidgetKey.meercat,
    weight: 3,
    datasources: [
      'intranet',
      'engagements',
      'people',
      'kpmg.com',
      'source',
      'statistics',
      'alex',
      'kpmg mpp',
      'klardenker',
      'knowledgeexchangeopen',
      'knowledgeexchangecatalog',
      'ibfd',
      'kpmg.us'
    ],
    notInDatasources: [
      'news',
      'office365files',
      'office365mails',
      'office365calendar',
      'office365sites',
      'office365teams',
      'marketresearch',
      'videos',
      'images'
    ]
  },
  {
    type: 'right',
    generator: rightKPMGWebsitesWidgetGenerator,
    key: WidgetKey.kpmgWebsites,
    weight: 1,
    datasources: [
      'intranet',
      'news',
      'source',
      'statistics',
      'marketresearch',
      'videos',
      'alex',
      'klardenker',
      'knowledgeexchangeopen',
      'knowledgeexchangecatalog',
      'images',
      'ibfd',
      'kpmg.us'
    ],
    notInDatasources: [
      'engagements',
      'people',
      'office365files',
      'office365mails',
      'office365calendar',
      'office365sites',
      'office365teams',
      'kpmg.com',
      'kpmg mpp'
    ]
  },
  {
    type: 'right',
    generator: rightOneDriveWidgetGenerator,
    key: WidgetKey.oneDrive,
    weight: 1,
    datasources: [
      'intranet',
      'source',
      'videos',
      'alex',
      'kpmg mpp',
      'knowledgeexchangeopen',
      'knowledgeexchangecatalog',
      'images'
    ],
    notInDatasources: [
      'engagements',
      'people',
      'news',
      'kpmg.com',
      'statistics',
      'marketresearch',
      'klardenker',
      'ibfd',
      'kpmg.us',
      'office365files',
      'office365mails',
      'office365calendar',
      'office365sites',
      'office365teams'
    ]
  },
  {
    type: 'right',
    generator: rightIntranetWidgetGenerator,
    key: WidgetKey.intranet,
    weight: 1,
    datasources: [
      'news',
      'office365files',
      'office365mails',
      'office365calendar',
      'office365sites',
      'office365teams',
      'kpmg.com',
      'source',
      'videos',
      'alex',
      'kpmg mpp',
      'klardenker',
      'knowledgeexchangeopen',
      'knowledgeexchangecatalog',
      'images',
      'kpmg.us'
    ],
    notInDatasources: [
      'intranet',
      'engagements',
      'people',
      'statistics',
      'marketresearch',
      'ibfd'
    ]
  },
  {
    type: 'right',
    generator: rightSearchTop10WidgetGenerator,
    key: WidgetKey.searchTop10,
    weight: 1,
    datasources: [
      'intranet',
      'news',
      'office365files',
      'office365mails',
      'office365calendar',
      'office365sites',
      'office365teams',
      'kpmg.com',
      'source',
      'statistics',
      'marketresearch',
      'videos',
      'alex',
      'kpmg mpp',
      'klardenker',
      'knowledgeexchangeopen',
      'knowledgeexchangecatalog',
      'images',
      'ibfd',
      'kpmg.us'
    ],
    notInDatasources: ['engagements', 'people']
  },
  {
    type: 'right',
    generator: rightPeopleWidgetGenerator,
    key: WidgetKey.people,
    weight: 1,
    datasources: [
      'intranet',
      'engagements',
      'news',
      'office365files',
      'office365mails',
      'office365calendar',
      'office365sites',
      'office365teams',
      'kpmg.com',
      'source',
      'videos',
      'kpmg mpp',
      'klardenker',
      'knowledgeexchangeopen',
      'knowledgeexchangecatalog',
      'images',
      'kpmg.us'
    ],
    notInDatasources: ['people', 'statistics', 'marketresearch', 'alex', 'ibfd']
  },
  {
    type: 'right',
    generator: rightSourceWidgetGenerator,
    key: WidgetKey.source,
    weight: 1,
    datasources: [
      'intranet',
      'engagements',
      'people',
      'office365files',
      'office365mails',
      'office365calendar',
      'office365sites',
      'office365teams',
      'statistics',
      'klardenker',
      'knowledgeexchangeopen',
      'knowledgeexchangecatalog',
      'ibfd',
      'kpmg.us'
    ],
    notInDatasources: [
      'source',
      'news',
      'kpmg.com',
      'videos',
      'alextopics',
      'alexmanuals',
      'alexsearch',
      'kpmg mpp',
      'marketresearch',
      'images'
    ]
  },
  {
    type: 'right',
    generator: rightServiceNowChWidgetGenerator,
    key: WidgetKey.servicenowch,
    weight: 3,
    datasources: ['intranet'],
    notInDatasources: [
      'news',
      'office365files',
      'office365mails',
      'office365calendar',
      'office365sites',
      'office365teams',
      'kpmg.com',
      'videos',
      'alex',
      'kpmg mpp',
      'engagements',
      'people',
      'source',
      'statistics',
      'marketresearch',
      'klardenker',
      'knowledgeexchangeopen',
      'knowledgeexchangecatalog',
      'images',
      'ibfd',
      'kpmg.us'
    ],
    country: ['Switzerland']
  },
  {
    type: 'right',
    generator: rightKCIPWidgetGenerator,
    key: WidgetKey.kcip,
    weight: 3,
    datasources: ['intranet'],
    notInDatasources: [
      'source',
      'news',
      'kpmg.com',
      'videos',
      'alextopics',
      'alexmanuals',
      'alexsearch',
      'kpmg mpp',
      'marketresearch',
      'images',
      'engagements',
      'people',
      'office365files',
      'office365mails',
      'office365calendar',
      'office365sites',
      'office365teams',
      'statistics',
      'klardenker',
      'knowledgeexchangeopen',
      'knowledgeexchangecatalog',
      'ibfd',
      'kpmg.us'
    ]
  },
  {
    type: 'right',
    generator: rightMetricsWidgetGenerator,
    key: WidgetKey.metrics,
    weight: 2,
    datasources: [
      'intranet',
      'engagements',
      'people',
      'office365files',
      'office365mails',
      'office365calendar',
      'office365sites',
      'office365teams',
      'statistics',
      'klardenker',
      'knowledgeexchangeopen',
      'knowledgeexchangecatalog',
      'ibfd',
      'kpmg.us',
      'source',
      'news',
      'kpmg.com',
      'videos',
      'alextopics',
      'alexmanuals',
      'alexsearch',
      'kpmg mpp',
      'marketresearch',
      'images'
    ],
    notInDatasources: []
  },
  {
    type: 'right',
    generator: rightServiceNowAUWidgetGenerator,
    key: WidgetKey.servicenowau,
    weight: 3,
    datasources: ['intranet'],
    notInDatasources: [
      'news',
      'office365files',
      'office365mails',
      'office365calendar',
      'office365sites',
      'office365teams',
      'kpmg.com',
      'videos',
      'alex',
      'kpmg mpp',
      'engagements',
      'people',
      'source',
      'statistics',
      'marketresearch',
      'klardenker',
      'knowledgeexchangeopen',
      'knowledgeexchangecatalog',
      'images',
      'ibfd',
      'kpmg.us'
    ],
    country: ['Australia']
  },
  {
    type: 'right',
    generator: rightVivaEngageWidgetGenerator,
    key: WidgetKey.vivaengage,
    weight: 1,
    datasources: [
      'intranet',
      'engagements',
      'people',
      'office365files',
      'office365mails',
      'office365calendar',
      'office365sites',
      'office365teams',
      'statistics',
      'klardenker',
      'knowledgeexchangeopen',
      'knowledgeexchangecatalog',
      'ibfd',
      'kpmg.us',
      'source',
      'news',
      'kpmg.com',
      'videos',
      'alextopics',
      'alexmanuals',
      'alexsearch',
      'kpmg mpp',
      'marketresearch',
      'images'
    ],
    notInDatasources: []
  }
]

//Pre selected widget list
//If the search term matches, these widgets will be displayed first
//Keys with the value "null" are filled with a randomly selected widget
export const widgetPreselectList: IWidgetPreselectConfig[] = [
  {
    queryContains: [
      'project',
      'projects',
      'engagement',
      'engagements',
      'projekt',
      'projekte',
      'credential',
      'credentials'
    ], //left filter
    leftWidgetKey: WidgetKey.engagements,
    rightWidgetKey: WidgetKey.thoughtleadership
  },
  {
    queryContains: ['news', 'nachrichten'],
    leftWidgetKey: WidgetKey.news,
    rightWidgetKey: WidgetKey.kpmgWebsites
  },
  {
    queryContains: ['expert', 'experts', 'experten', 'experte'],
    leftWidgetKey: WidgetKey.people,
    rightWidgetKey: WidgetKey.thoughtleadership
  },
  {
    queryContains: ['statistic', 'statistics', 'statistik', 'statistiken'],
    leftWidgetKey: WidgetKey.statistics,
    rightWidgetKey: WidgetKey.news
  },
  {
    queryContains: ['graphics', 'info graphics', 'infographics'],
    leftWidgetKey: WidgetKey.infographics,
    rightWidgetKey: WidgetKey.news
  },
  {
    queryContains: ['video', 'videos'],
    leftWidgetKey: WidgetKey.video,
    rightWidgetKey: WidgetKey.news
  },
  {
    queryContains: ['Klardenker'],
    leftWidgetKey: null,
    rightWidgetKey: WidgetKey.klardenker
  },
  {
    queryContains: ['thought', 'leadership'],
    leftWidgetKey: null,
    rightWidgetKey: WidgetKey.thoughtleadership
  },
  {
    queryContains: ['data', 'daten'],
    leftWidgetKey: WidgetKey.statistics,
    rightWidgetKey: WidgetKey.thoughtleadership
  },
  {
    queryContains: ['solution', 'compass', 'portfolio'],
    leftWidgetKey: null,
    rightWidgetKey: WidgetKey.meercat
  },
  {
    queryContains: ['*'],
    leftWidgetKey: WidgetKey.mobileOfficeLocation,
    rightWidgetKey: null
  },
  {
    queryContains: [
      'onedrive',
      'harddrive',
      'file',
      'files',
      'my',
      'own',
      'dateien',
      'meine'
    ],
    leftWidgetKey: null,
    rightWidgetKey: WidgetKey.oneDrive
  },
  // {
  //   queryContains: ['kpmg'],
  //   leftWidgetKey: LeftWidgetKey.kpmgWebsites,
  //   leftGenerator: leftKPMGWebsitesWidgetGenerator,
  //   rightWidgetKey: RightWidgetKey.kpmgWebsites,
  //   rightGenerator: rightKPMGWebsitesWidgetGenerator,
  //   searchTermPreselected: '',
  //   inUse: false
  // }
  // this should be removed it is only be added for testing proposes
  // to make the top 10 search result widget appears
  {
    queryContains: ['supermetricswidget'],
    leftWidgetKey: WidgetKey.peopleAlsoSearched,
    rightWidgetKey: WidgetKey.thoughtleadership
  },
  {
    queryContains: ['source'],
    leftWidgetKey: WidgetKey.source,
    rightWidgetKey: null
  },
  {
    queryContains: ['rightsourcewidgettest'],
    leftWidgetKey: null,
    rightWidgetKey: WidgetKey.source
  },
  {
    queryContains: ['confluence', 'jira'],
    leftWidgetKey: WidgetKey.confluenceFi,
    rightWidgetKey: null
  },
  {
    queryContains: ['service'],
    leftWidgetKey: WidgetKey.servicenowau,
    rightWidgetKey: null
  },
  {
    queryContains: ['servicenow'],
    leftWidgetKey: null,
    rightWidgetKey: WidgetKey.servicenowau
  },
  {
    queryContains: ['search', 'metrics', 'find'],
    leftWidgetKey: null,
    rightWidgetKey: WidgetKey.metrics
  }
]
