import { imageOrVideoFileExtensions } from 'constants/constants'
import * as Config from 'config'
import { getSPOnlineToken, renewAuthorizationToken } from 'utils/token'
import { trackException } from 'utils/tracking'
import { IAADState } from 'store/Auth/reducers'

export type FileSourceResponse = {
  '@odata.type': string
  createdDateTime: string
  id: string
  lastModifiedDateTime: string
  name: string
  size: number
  webUrl: string
  parentReference: {
    driveId: string
    sharepointIds: {
      listId: string
      listItemId: string
      listItemUniqueId: string
    }
  }
}

export type MailSourceResponse = {
  '@odata.type': string
  createdDateTime: string
  id: string
  lastModifiedDateTime: string
  receivedDateTime: string
  sentDateTime: string
  internetMessageId: string
  subject: string
  bodyPreview: string
  webLink: string
  sender: {
    emailAddress: {
      address: string
      name: string
    }
  }
  hasAttachments: boolean
}

export type CalendarSourceResponse = {
  '@odata.type': string
  subject: string
  start: {
    dateTime: string
    timeZone: string
  }
  end: {
    dateTime: string
    timeZone: string
  }
}

export type SitesSourceResponse = {
  '@odata.type': string
  createdDateTime: string
  description: string
  lastModifiedDateTime: string
  name: string
  webUrl: string
}

export type ChatMessageSourceResponse = {
  '@odata.type': string
  lastModifiedDateTime: string
  importance: string
  from: {
    emailAddress: {
      address: string
      name: string
    }
  }
  channelIdentity: {
    channelId: string
    teamId: string
  }
  chatId: string
  id: string
}

export type HitsResponse<T> = {
  hitId: string
  rank: number
  summary?: string
  resource: T
}

export type HitsContainerResponse<T> = {
  total: number
  moreResultsAvailable: boolean
  hits: HitsResponse<T>[]
}

export type SearchResponse<T> = {
  hitsContainers: Array<HitsContainerResponse<T>>
}

export type Office365FileResponse = {
  value: Array<SearchResponse<FileSourceResponse>>
}
export type Office365MailResponse = {
  value: Array<SearchResponse<MailSourceResponse>>
}
export type Office365CalendarResponse = {
  value: Array<SearchResponse<CalendarSourceResponse>>
}
export type Office365SitesResponse = {
  value: Array<SearchResponse<SitesSourceResponse>>
}
export type Office365TeamsResponse = {
  value: Array<SearchResponse<ChatMessageSourceResponse>>
}

export const extractResult = (
  results:
    | Office365FileResponse
    | Office365MailResponse
    | Office365CalendarResponse
    | Office365SitesResponse
    | Office365TeamsResponse
): HitsContainerResponse<any> | null => {
  // Exeptions are logged in case the value or the hitsContainers are null or undefined
  // so check if the results contains a value
  if (!results || !results.value) {
    return null
  }

  const hitsContainer = results.value[0]
  if (!hitsContainer || !hitsContainer.hitsContainers) {
    return null
  }

  const hits = hitsContainer.hitsContainers[0]
  if (!hits) {
    return null
  }

  return hits
}

export interface IOffice365QueryParams {
  searchQuery: string
  page: number
}

export const generateOffice365QueryParams = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  queryParams: any,
  initialQueryParams: IOffice365QueryParams
): void => {
  try {
    initialQueryParams.page = queryParams.page
      ? parseInt(queryParams.page)
      : initialQueryParams.page
    initialQueryParams.searchQuery =
      queryParams && queryParams.q
        ? queryParams.q
        : initialQueryParams.searchQuery
        ? initialQueryParams.searchQuery
        : ''
  } catch {}
}

// search beta api currently only provides the direct links to the files
// so as fallback generate the open in browser link for the files as url
export const getPreviewFileUrlFallBack = (
  webUrl: string,
  fileExtension: string | undefined
) => {
  // is preview or url is empty
  // return the provided url
  if (!webUrl || webUrl.indexOf('/_layouts/15/Doc.aspx?') !== -1) {
    return webUrl
  }

  // check for file and not a displayform
  if (!fileExtension || webUrl.indexOf('/Forms/DispForm.aspx?') !== -1) {
    return webUrl
  }

  return `${webUrl}?web=1`
}

export const getFileExtensionFromFileName = (name: string) => {
  return name ? (name.indexOf('.') !== -1 ? name.split('.').pop() : '') : ''
}

export const isVideoOrPhoto = (fileExtension: string | undefined) => {
  return fileExtension
    ? imageOrVideoFileExtensions.indexOf(fileExtension.toLowerCase()) !== -1
    : false
}

export async function downloadSPOBlob(
  aadInfo: IAADState,
  dataUrl: string,
  listItemUrl: string,
  fileName: string,
  setDownloadInProgress?: any
) {
  if (setDownloadInProgress) {
    setDownloadInProgress(true)
  }
  dataUrl = dataUrl.endsWith('/')
    ? dataUrl.substring(0, dataUrl.length - 1)
    : dataUrl
  let relativeUrl = ''
  let siteUrl = ''
  let domainUrl = ''
  if (dataUrl.split('/').length > 3) {
    relativeUrl = '/' + dataUrl.split('/').slice(3).join('/')
    siteUrl = dataUrl.split('/').slice(0, 5).join('/')
    domainUrl = dataUrl.split('/').slice(0, 3).join('/')
  }

  if (listItemUrl !== '') {
    domainUrl = listItemUrl.split('/').slice(0, 3).join('/')
  }

  try {
    let spoToken = ''
    if (Config.LOCAL_DEVELOPMENT.toString() === 'false') {
      spoToken = await getSPOnlineToken(
        aadInfo.instance,
        aadInfo.accounts,
        domainUrl
      )
      if (spoToken === '') {
        throw new Error('Authentication: Cannot renew SPO authentication token')
      }
    }
    const docUrl =
      Config.LOCAL_DEVELOPMENT.toString() === 'true' || dataUrl === ''
        ? `${dataUrl}`
        : `${siteUrl}/_api/web/getfilebyserverrelativeurl('${relativeUrl}')/$value`

    let apiUrl = `${Config.APIM_BASE_URL}oneintranetapi/downloadfile?url=${docUrl}`

    if (listItemUrl !== '') {
      apiUrl += `&itemUrl=${listItemUrl}`
    }

    fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
        'content-type': 'application/json',
        Authorization: `Bearer ${spoToken}`
      }
    })
      .then((res) => {
        return res.blob()
      })
      .then(async (blob) => {
        const fileURL = URL.createObjectURL(blob)

        const a: HTMLAnchorElement = document.createElement(
          'a'
        ) as HTMLAnchorElement

        a.href = fileURL
        a.download = fileName
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        URL.revokeObjectURL(fileURL)
        if (setDownloadInProgress) {
          setDownloadInProgress(false)
        }
      })
      .catch(() => {
        if (setDownloadInProgress) {
          setDownloadInProgress(false)
        }
        // Create a tag and click it
        const a = document.createElement('a')
        a.href = docUrl
        a.download = fileName
        a.target = '_blank'
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        URL.revokeObjectURL(docUrl)
        return
      })
  } catch (err) {
    if (setDownloadInProgress) {
      setDownloadInProgress(false)
    }
    trackException('Exception fetching the SPO file with url ' + dataUrl, err)
    if (window && window.location && dataUrl && dataUrl !== '') {
      window.location.href = dataUrl
    }
  }
}

export async function getFileSize(
  dataUrl: string,
  aadInfo: IAADState
): Promise<string> {
  dataUrl = dataUrl.endsWith('/')
    ? dataUrl.substring(0, dataUrl.length - 1)
    : dataUrl
  let relativeUrl = ''
  let siteUrl = ''
  let domainUrl = ''
  if (dataUrl.split('/').length > 3) {
    relativeUrl = '/' + dataUrl.split('/').slice(3).join('/')
    relativeUrl = relativeUrl.replace('?web=1', '')
    siteUrl = dataUrl.split('/').slice(0, 5).join('/')
    domainUrl = dataUrl.split('/').slice(0, 3).join('/')
  }

  try {
    let spoToken = ''
    if (Config.LOCAL_DEVELOPMENT.toString() === 'false') {
      spoToken = await getSPOnlineToken(
        aadInfo.instance,
        aadInfo.accounts,
        domainUrl
      )
      if (spoToken === '') {
        throw new Error('Authentication: Cannot renew SPO authentication token')
      }
    }

    // Get and check authentication token
    const esToken = await renewAuthorizationToken(
      aadInfo.accessToken,
      aadInfo.instance,
      aadInfo.accounts
    )

    if (esToken === '') {
      throw new Error(
        'Authentication: Cannot renew Enterprise Search authentication token'
      )
    }

    const docUrl = `${siteUrl}/_api/web/getfilebyserverrelativeurl('${relativeUrl}')/Properties`

    const apiUrl = `${Config.APIM_BASE_URL}oneintranetapi/getfilesize?url=${docUrl}`

    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
        'content-type': 'application/json',
        Authorization: `Bearer ${spoToken}`,
        'Authorization-OnPrem': `${esToken}`
      }
    })

    if (response.status !== 200) {
      throw new Error('Error in fetching file size')
    }

    const res = await response.json()
    return res.fileSize.toString()
  } catch (err) {
    trackException(
      'Exception fetching the SPO file size with url ' + dataUrl,
      err
    )
    return '0'
  }
}
