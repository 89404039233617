import React from 'react'
// import LazyLoad from 'react-lazyload'
import { Typography, Link, Box, Grid, CardMedia } from '@mui/material'
import { externalLinkClicked } from 'utils/tracking'
import highlightedText, { prepareHighlightWords } from 'utils/highlightText'
import { IDeviceSetting } from 'utils/deviceSettings'
import { useSelector, useDispatch } from 'react-redux'
import ResultsStore from 'store/Results'
import { getStylesAEMResult } from 'styles/results/AEMResult'
import TooltipTitle from '../common/TooltipTitle'
import AEMPlaceHolder from 'images/kpmg_placeholder.png'
import { CustomDescription } from '../common/CustomDescription'

export interface AEMResultProps {
  title: string
  body: string
  url: string
  imageUrl: string
  sources?: string
  index?: any
  searchQuery?: string
  deviceSettings: IDeviceSetting
}

export function AEMResult(props: AEMResultProps): JSX.Element {
  const {
    title,
    body,
    url,
    imageUrl,
    index,
    sources,
    searchQuery,
    deviceSettings
  } = props
  const classes = getStylesAEMResult()

  const dispatch = useDispatch()
  const [isVisited, setVisited] = React.useState(
    useSelector((state: any) =>
      ResultsStore.selectors.isResultVisited(state, url)
    )
  )

  const setIsVisited = () => {
    if (!isVisited) {
      dispatch(ResultsStore.actions.storeResultHistory(url))
      setVisited(true)
    }
  }

  let smallImageUrl = imageUrl
    ? `${
        imageUrl.includes('.jpg') ? imageUrl.split('.jpg')[0] : imageUrl
      }/jcr:content/renditions/cq5dam.web.237.158.jpg`
    : ''

  if (sources && sources.toLowerCase() === 'people') {
    smallImageUrl = smallImageUrl.replace('.237.158.jpg', '.237.237.jpg')
  }

  return (
    // <LazyLoad offset={150}>
    <Grid container className={classes.aemWholeResult}>
      {!deviceSettings.isMobile && !deviceSettings.renderMobile && (
        <Grid item xs={3}>
          <CardMedia
            className={classes.aemPreviewPic}
            component="div"
            style={
              imageUrl
                ? {
                    backgroundImage: `url("${smallImageUrl}"), url("${imageUrl}"),url("${AEMPlaceHolder}")`,
                    backgroundColor: '#7787a1'
                  }
                : {
                    backgroundImage: `url("${AEMPlaceHolder}")`,
                    backgroundColor: '#7787a1'
                  }
            }
          />
        </Grid>
      )}
      <Grid
        item
        xs={deviceSettings.isMobile || deviceSettings.renderMobile ? 12 : 9}
        className={classes.aemGridItem}
      >
        <Box className={classes.result} style={{ marginBottom: 0 }}>
          {sources && sources.length > 0 && (
            <Typography
              variant="body1"
              display="inline"
              className={classes.urlBreadcrumbs}
            >
              {sources}
            </Typography>
          )}
          <Link
            className={classes.link}
            href={url}
            onClick={() => {
              setIsVisited()
              externalLinkClicked({ title, url, index })
            }}
            {...(!deviceSettings.isMobile
              ? {
                  onAuxClick: (event: any) => {
                    setIsVisited()
                    externalLinkClicked({
                      title,
                      url,
                      index,
                      wasRightClicked: event?.button === 2 ? true : false
                    })
                  }
                }
              : {})}
            {...(deviceSettings.openLinksInNewTab === true
              ? { target: '_blank', rel: 'noreferrer' }
              : {})}
          >
            <Typography
              variant="h5"
              noWrap
              color="primary"
              className={`${classes.title} ${
                isVisited ? classes.isVisitedResult : ''
              }`}
              component="div"
            >
              <TooltipTitle title={title ? title : ''} singleLine={true} />
            </Typography>
          </Link>
          <Typography
            variant="body2"
            color="secondary"
            className={`${classes.body} txt-resultdescription-01`}
            component="div"
          >
            <CustomDescription
              descriptionValue={
                body
                  ? highlightedText(
                      body.replace(/(\r\n|\n|\r)/gm, ' '),
                      prepareHighlightWords(
                        searchQuery,
                        body.replace(/(\r\n|\n|\r)/gm, ' '),
                        []
                      ),
                      true
                    )
                  : ''
              }
            />
          </Typography>
        </Box>
      </Grid>
    </Grid>
    // </LazyLoad>
  )
}
