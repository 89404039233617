import { kpmgus, portalOptionsIntranet } from 'constants/filters'
import { trackException } from 'utils/tracking'
import * as Filters from 'constants/filters'
import * as Constants from 'constants/constants'
import { countriesISO } from 'constants/countriesISO'
import {
  createModifiedFilterAsParam,
  createModifiedFilterForKnowledgeExchange,
  createModifiedFilterForMyDataFiles,
  createModifiedFilterForMyDataMails,
  createModifiedFilterSharepoint,
  getDateValueFromOffset
} from './filterslastmodified'
import FilterStore from 'store/Filters'
import { useDispatch, useSelector } from 'react-redux'
import { Store } from 'store'
import createDOMPurify from 'dompurify'
import { capitalizeFirstLetterOfEachWord, stripHtml } from './string'
import { IUserSetting } from './userSettings'
import {
  IAdvancedPostFilterKpmgUS,
  IFilterKpmgUS
} from 'store/ResultsKpmgUS/reducers'
import { IFindConfiguration } from 'store/Settings/reducers'

const excludeParametersFromUrl = ['mrDocumentId', 'mrFileType']

/**
 * Checks if the received country is a valid prefilter option
 * @param country The country to check
 */
export function isValidPrefilterCountry(
  country: string,
  checkIntranet = false
): boolean {
  if (country === null || country === undefined || country.length <= 0) {
    return false
  }
  if (checkIntranet) {
    for (const portalOption of portalOptionsIntranet) {
      if (portalOption.name.toLowerCase().includes(country.toLowerCase())) {
        return true
      } else if (
        portalOption.value &&
        portalOption.value.toLowerCase().includes(country.toLowerCase())
      ) {
        return true
      } else if (
        portalOption.key &&
        portalOption.key.toLowerCase().includes(country.toLowerCase())
      ) {
        return true
      }
    }
  } else {
    const parsedCountry = decodeURIComponent(country)
      .replace('("', '')
      .replace('")', '')
    for (const countryOption of countriesISO) {
      if (
        countryOption.Name &&
        countryOption.Name.toLowerCase().includes(parsedCountry.toLowerCase())
      ) {
        return true
      }
    }
  }
  return false
}

/**
 * Gets the country name from key
 * @param countryKey The country key
 */
export function getCountryNameFromKey(countryKey: string): string {
  const countryObject = countriesISO.find((countryOption) => {
    return (
      countryKey &&
      countryOption.Code &&
      countryOption.Code.toLowerCase().includes(countryKey.toLowerCase())
    )
  })
  if (!countryObject) {
    return countryKey
  }
  return countryObject.Name
}

/**
 * Gets the country key from name
 * @param countryName The country name
 */
export function getCountryKeyFromName(countryName: string): string {
  // First check if the country name completly match, as fallback search for a value
  // where the name is included
  let countryObject = countriesISO.find((countryOption) => {
    return (
      countryOption.Name &&
      countryOption.Name.toLowerCase() === countryName.toLowerCase()
    )
  })
  if (!countryObject) {
    countryObject = countriesISO.find((countryOption) => {
      return (
        countryOption.Name &&
        countryOption.Name.toLowerCase().includes(countryName.toLowerCase())
      )
    })

    if (!countryObject) return countryName
  }
  return countryObject.Code
}

/**
 * Try to get the department id from portal filter
 * @param appliedFilters The dictionary to parse
 * @param acceptedFilters The accepted filters
 */
export function buildPortalFilter(
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  appliedFilters: any,
  acceptedFilters: FilterCategory[]
): string {
  const sanitizedFilters = sanitizeAppliedFilters(
    acceptedFilters,
    appliedFilters
  )
  if (sanitizedFilters['DepartmentId']) {
    return sanitizedFilters['DepartmentId']
  }
  return ''
}

/**
 * Builds the refinement filters from a dictionary of applied filters
 * @param appliedFilters The dictionary to parse
 * @param acceptedFilters The accepted filters
 */
export function buildRefinementFilters(
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  appliedFilters: any,
  acceptedFilters: FilterCategory[]
): string {
  try {
    let query = ''
    const modifiableFilters = { ...appliedFilters }
    if (acceptedFilters === Filters.people) {
      const sanitizedFilters = sanitizeAppliedFilters(
        acceptedFilters,
        modifiableFilters,
        ['']
      )
      for (const filter in sanitizedFilters) {
        if (
          sanitizedFilters[filter] != null &&
          sanitizedFilters[filter] !== 'all' &&
          sanitizedFilters[filter].length > 0
        ) {
          query += `${query.length > 0 ? '%2c' : ''}${filter}${
            filter.length > 0 ? ':' : ''
          }${sanitizedFilters[filter]}`
        }
      }
      return Object.values(sanitizedFilters)
        .filter((value) => value !== null)
        .filter((value) => value !== 'all')
        .filter((value) => value !== '').length > 1
        ? `AND(${query})`
        : query
    } else if (acceptedFilters === Filters.news) {
      let query = ''
      if (appliedFilters && appliedFilters.language) {
        const languageName = getLanguageNameFromKey(appliedFilters.language)
        if (
          Object.values(Constants.lexisNexisLanguages).indexOf(languageName) >
          -1
        ) {
          appliedFilters.language = languageName
        } else {
          delete appliedFilters.language
        }
      }

      const modifiableFilters = { ...appliedFilters }
      const sanitizedFilters = sanitizeAppliedFilters(
        acceptedFilters,
        modifiableFilters,
        [],
        true
      )
      for (const filter in sanitizedFilters) {
        if (filter === 'lastmodified') {
          query = createModifiedFilterAsParam(
            query,
            sanitizedFilters[filter],
            'dateGE',
            'dateLT'
          )
        } else {
          if (
            sanitizedFilters[filter] != null &&
            sanitizedFilters[filter] !== 'all' &&
            sanitizedFilters[filter].length > 0
          ) {
            query += `&${filter}${
              filter.length > 0 ? '=' : ''
            }${encodeURIComponent(sanitizedFilters[filter])}`
          }
        }
      }
      return query
    } else if (acceptedFilters === Filters.kpmg_mpp) {
      let query = ''

      const modifiableFilters = { ...appliedFilters }
      const sanitizedFilters = sanitizeAppliedFilters(
        acceptedFilters,
        modifiableFilters,
        [],
        true
      )
      for (const filter in sanitizedFilters) {
        if (
          sanitizedFilters[filter] != null &&
          sanitizedFilters[filter] !== 'all' &&
          sanitizedFilters[filter].length > 0
        ) {
          query += `&${filter}${
            filter.length > 0 ? '=' : ''
          }${encodeURIComponent(sanitizedFilters[filter])}`
        }
      }
      return query
    } else if (
      acceptedFilters === Filters.knowledgeexchange ||
      Filters.knowledgeexchangeCatalog
    ) {
      let query = ''

      const modifiableFilters = { ...appliedFilters }
      const sanitizedFilters = sanitizeAppliedFilters(
        acceptedFilters,
        modifiableFilters,
        [],
        false
      )
      for (const filter in sanitizedFilters) {
        if (filter === 'lastmodified') {
          query = createModifiedFilterAsParam(
            query,
            sanitizedFilters[filter],
            'DocumentFromDate',
            'DocumentToDate',
            10,
            false
          )
        } else {
          if (
            sanitizedFilters[filter] != null &&
            sanitizedFilters[filter] !== 'all' &&
            sanitizedFilters[filter].length > 0
          ) {
            query += `&${filter}${
              filter.length > 0 ? '=' : ''
            }${encodeURIComponent(sanitizedFilters[filter])}`
          }
        }
      }
      return query
    } else {
      let sortingEnabled = false
      if (modifiableFilters && modifiableFilters.language) {
        const languageKey = getLanguageKeyFromName(modifiableFilters.language)
        if (languageKey !== 'all') {
          modifiableFilters.language = languageKey
        }
      }
      const sanitizedFilters = sanitizeAppliedFilters(
        acceptedFilters,
        modifiableFilters,
        ['', 'kpmgdatafiletype', 'filetype']
      )
      for (const filter in sanitizedFilters) {
        let isSortingFilter = false
        // If the sorting filter is contained in the applied filters, make sure it is not added
        // to the applied filters and the 'and'-ing of the applied filters is only performed
        // for more than two keys in the applied filter dictionary.
        if (filter === 'orderBy') {
          sortingEnabled = true
          isSortingFilter = true
        }

        if (filter === 'lastmodified') {
          query = createModifiedFilterSharepoint(
            query,
            sanitizedFilters[filter]
          )
        } else {
          if (
            sanitizedFilters[filter] != null &&
            sanitizedFilters[filter] !== 'all' &&
            sanitizedFilters[filter].length > 0 &&
            sanitizedFilters[filter].indexOf('sourceid:') !== 0 &&
            !isSortingFilter
          ) {
            query += `${query.length > 0 ? '%2c' : ''}${filter}${
              filter.length > 0 ? ':' : ''
            }${sanitizedFilters[filter]}`
          }
        }
      }
      const conjunctionThreshold = sortingEnabled ? 2 : 1
      return Object.values(sanitizedFilters)
        .filter((value) => value !== 'all')
        .filter((value) => value !== '')
        .filter((value) => value !== null)
        .filter((value) => value !== undefined).length > conjunctionThreshold
        ? `AND(${query})`
        : query
    }
  } catch (error) {
    trackException('Error building refinement filters query string', error)
    return ''
  }
}

/**
 * Sanitizes the applied filters
 * @param validFilters The valid filters
 * @param appliedFilters The applied filters
 * @param additionalAcceptedKeys The additional accepted keys
 */
export function sanitizeAppliedFilters(
  validFilters: FilterCategory[],
  appliedFilters: { [index: string]: string },
  additionalAcceptedKeys: string[] = [],
  allowInvalidValues = false
): { [index: string]: string } {
  const sanitizedFilters = { ...appliedFilters }
  const validKeys: string[] = [
    ...validFilters.reduce(
      (accumulator: string[], filterCategory: FilterCategory) => [
        ...accumulator,
        filterCategory.key
      ],
      []
    ),
    ...additionalAcceptedKeys
  ]

  for (const key of Object.keys(sanitizedFilters)) {
    if (!validKeys.includes(key)) {
      delete sanitizedFilters[key]
    } else if (validFilters) {
      const filterCategory = validFilters.find(
        // eslint-disable-next-line no-loop-func
        (vf: FilterCategory) => vf && vf.key === key
      )
      if (filterCategory && filterCategory.options) {
        if (filterCategory.multiselect) {
          const appliedkeys = sanitizedFilters[key].split('|')
          const validMultiSelectValues: string[] = []

          if (!allowInvalidValues) {
            // eslint-disable-next-line no-loop-func
            appliedkeys.forEach((singleFilterKey: string) => {
              const filterOption = filterCategory.options.find(
                (op: Filter) => op && op.key === singleFilterKey
              )

              if (filterOption && filterOption.value) {
                validMultiSelectValues.push(filterOption.value)
              }
            })
          } else {
            appliedkeys.forEach((singleFilterKey: string) => {
              validMultiSelectValues.push(singleFilterKey)
            })
          }

          // use the defined method to build the refinement string
          if (validMultiSelectValues.length > 0) {
            sanitizedFilters[filterCategory.value] =
              filterCategory.mulitSelectRefinerRule
                ? filterCategory.mulitSelectRefinerRule(validMultiSelectValues)
                : validMultiSelectValues.join(',')

            if (filterCategory.value !== filterCategory.key) {
              delete sanitizedFilters[filterCategory.key]
            }
          } else {
            delete sanitizedFilters[filterCategory.key]
          }
        } else {
          const filterOption = filterCategory.options.find(
            // eslint-disable-next-line no-loop-func
            (op: Filter) =>
              op &&
              (op.key === sanitizedFilters[key] ||
                (op.key === 'customRange' &&
                  sanitizedFilters[key].startsWith('customRange(')))
          )
          if (filterOption && filterOption.value) {
            if (filterOption.key !== 'customRange') {
              sanitizedFilters[filterCategory.value] = filterOption.value
            }

            // Don't remove the filter in case the value is the same as the key
            // except for the orderby filter which is not included in the refiners
            if (
              filterCategory.value !== filterCategory.key ||
              filterCategory.key === 'orderBy'
            ) {
              delete sanitizedFilters[filterCategory.key]
            }
          } else {
            // This is a workaround for the different working filter for news
            // The filter should not be removed overall, because the options can be empty before the query is done
            // But the orderBy filter must be removed from the refinement filter, because it is handeld separatly
            if (!allowInvalidValues || filterCategory.key === 'orderBy') {
              // In case the filter is not present in the options remove it
              delete sanitizedFilters[filterCategory.key]
            }
          }
        }
      }
    }
  }

  return sanitizedFilters
}

/**
 * Builds the office 365 filters from a dictionary of applied filters
 * @param appliedFilters The dictionary to parse
 * @param acceptedFilters The accepted filters
 * @param userName The upn of the current user
 * @param userDisplayName The display name of the current user
 */
export function buildFiltersOffice365(
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  appliedFilters: any,
  acceptedFilters: FilterCategory[],
  userName: string,
  userDisplayName: string,
  pathComponent: string
): string {
  try {
    let query = ''
    const modifiableFilters = { ...appliedFilters }
    const sanitizedFilters = sanitizeAppliedFilters(
      acceptedFilters,
      modifiableFilters,
      []
    )
    for (const filter in sanitizedFilters) {
      if (
        sanitizedFilters[filter] &&
        sanitizedFilters[filter] !== 'all' &&
        sanitizedFilters[filter].length > 0
      ) {
        query += `${query.length > 0 ? ' ' : ''}`

        if (filter === 'lastmodified' && pathComponent === 'chatmessages') {
          query = createModifiedFilterForMyDataFiles(
            query,
            sanitizedFilters[filter],
            'sent'
          )
        } else if (filter === 'lastmodified' && pathComponent === 'messages') {
          query = createModifiedFilterForMyDataMails(
            query,
            sanitizedFilters[filter],
            appliedFilters['mtype']
          )
        } else if (
          filter === 'lastmodified' &&
          (pathComponent === 'driveitems' || pathComponent === 'sites')
        ) {
          query = createModifiedFilterForMyDataFiles(
            query,
            sanitizedFilters[filter]
          )
        } else if (filter === 'receivedOrSend' || filter === 'o365ds') {
          if (userName) {
            query += sanitizedFilters[filter]
              .replace('{upn}', userName)
              .replace('{displayname}', userDisplayName)
          }
        } else {
          query += sanitizedFilters[filter]
        }
      }
    }

    // Add the seleted user to the teams from filter
    if (appliedFilters.cfrom && pathComponent === 'chatmessages') {
      query += ' from:{upn}'.replace('{upn}', appliedFilters.cfrom)
    }
    return query
  } catch (error) {
    trackException('Error building filters for offie365 query string', error)
    return ''
  }
}

/**
 * Find unavailable Filter Values
 * @param filters available filters
 * @param currentFilter current set filters
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function findUnavailableFilterValues(
  filters: FilterCategory[],
  currentFilter: {
    [key: string]: string
  }
) {
  if (filters) {
    const unavailableFilterValues: any[] = []

    //iterate given filters
    filters.forEach((filter: FilterCategory) => {
      if (!filter.isUpdating) {
        //add api loaded filters
        const filterOptions = filter.options

        //is a known filter set?
        if (currentFilter[filter.key] && filter.key !== 'cfrom') {
          const currentFilterValue = currentFilter[filter.key]

          //is filter value available?
          if (filterOptions.length > 0) {
            let foundValue = false
            filterOptions.forEach((filterOption: any) => {
              if (
                isFilterUrlValueSet(
                  filter,
                  currentFilterValue,
                  filterOption.key
                )
              ) {
                foundValue = true
              }
            })

            if (!foundValue) {
              unavailableFilterValues.push({
                name: filter.key,
                missingValue: currentFilterValue
              })
            }
          }
        }
      }
    })

    if (unavailableFilterValues.length > 0) return unavailableFilterValues
  }

  return
}

/**
 * Check for datasource filters
 * @param filters current set filters
 * @param availableFilters available filters
 */
export function areDatasourceFilterSet(
  filters: {
    [key: string]: string
  },
  availableFilters: FilterCategory[],
  cpfEnabled: boolean | undefined,
  ignoreFilter: string[] = [],
  checkAvailableValues = true
): boolean {
  const currentDatasourceFilters = getCurrentSetDatasourceFilter(
    filters,
    availableFilters,
    cpfEnabled,
    ignoreFilter,
    checkAvailableValues
  )

  return currentDatasourceFilters && currentDatasourceFilters.length > 0
}

export function getCurrentSetDatasourceFilter(
  filters: {
    [key: string]: string
  },
  availableFilters: FilterCategory[],
  cpfEnabled: boolean | undefined,
  ignoreFilter: string[] = [],
  checkAvailableValues = true
): string[] {
  const currentDatasourceFilters: string[] = []

  let unAvailableFilterValues = undefined
  if (checkAvailableValues)
    unAvailableFilterValues = findUnavailableFilterValues(
      availableFilters,
      filters
    )

  for (const availableFilterItem of availableFilters) {
    if (availableFilterItem.key in filters) {
      if (
        filters[availableFilterItem.key] &&
        !ignoreFilter.includes(availableFilterItem.key)
      ) {
        if (
          !(
            unAvailableFilterValues &&
            unAvailableFilterValues.some(
              (unAvailableFilter) =>
                availableFilterItem.key === unAvailableFilter.name
            )
          ) ||
          availableFilterItem.skipOptionValidation
        ) {
          currentDatasourceFilters.push(availableFilterItem.key)
        }
      }
    }
  }

  return currentDatasourceFilters
}

export function isFilterUrlValueSet(
  filter: FilterCategory,
  filterUrlValue: string,
  optionKey?: string | null
): boolean {
  if (filter.multiselect && optionKey) {
    return filterUrlValue
      .split('|')
      .some((filterValue: string) =>
        filter.multiselect
          ? optionKey
              .split('|')
              .some((optionValue: string) => optionValue === filterValue)
          : filterValue === optionKey
      )
  } else if (optionKey === 'customRange' && filter.key === 'lastmodified') {
    return filterUrlValue.match(
      /customRange[(](?:[\d]{4}-[\d]{2}-[\d]{2})?,(?:[\d]{4}-[\d]{2}-[\d]{2}[)])?/g
    )
      ? true
      : false
  } else {
    return filterUrlValue === optionKey
  }
}

export interface SetQueryStringOptions {
  method?: 'replace' | 'push'
}

export type SetQueryStateItemFn<T> = (
  newValue: T,
  opts?: SetQueryStringOptions
) => void

/**
 * CurrentFilter hook
 * @param key filter key
 * @param defaultValue filter default value
 * @returns
 */
export function useFilter<T>(
  key: string,
  defaultValue?: string
): [string, SetQueryStateItemFn<T>] {
  const currentFilters = useSelector(
    (state: Store) => state.filters.currentFilters
  )
  const temporaryFilters = useSelector(
    (state: Store) => state.filters.temporaryFilters
  )
  const dispatch = useDispatch()

  let targetfilter = null
  if (temporaryFilters && temporaryFilters.length > 0) {
    targetfilter = temporaryFilters.find(
      (item: CurrentFilter) => item.key === key
    )
  } else {
    targetfilter = currentFilters.find(
      (item: CurrentFilter) => item.key === key
    )
  }

  let value = ''
  if (defaultValue) value = defaultValue
  if (targetfilter) value = targetfilter.value

  const setValue = (newState: any) => {
    const currentFilter = {
      key: key,
      value: newState
    }

    if (temporaryFilters && temporaryFilters.length > 0) {
      dispatch(FilterStore.actions.setTemporaryFilter(currentFilter))
      return
    }

    dispatch(FilterStore.actions.setCurrentFilter(currentFilter))

    return
  }

  return [value, setValue]
}

/**
 * Parse url filter
 * @param queryParams queryParams
 * @param currentfilters CurrentFilter list
 * @param setCurrentFilter setCurrentFilter
 */
export const parseFiltersFromUrlToStorage = (
  queryParams: { [key: string]: any },
  currentfilters: CurrentFilter[],
  setCurrentFilter: (currentFilter: CurrentFilter[]) => void
): void => {
  const newFilter: CurrentFilter[] = []

  //update changed filters
  Object.entries(queryParams).forEach(([key, value]) => {
    const targetFilter = currentfilters.find(
      (item: CurrentFilter) => item.key === key
    )

    let urlValue = stripHtml(
      Number.isInteger(value)
        ? value.toString()
        : Array.isArray(value)
        ? value[0]
        : (value as string)
    )

    if (Array.isArray(urlValue)) {
      urlValue = urlValue[0]
    }

    if (!targetFilter || targetFilter.value !== urlValue) {
      newFilter.push({
        key: key,
        value: urlValue
      })
    }
  })

  //remove unused filters
  currentfilters.forEach((currentFilter: CurrentFilter) => {
    let found = false
    Object.entries(queryParams).forEach(([key, value]) => {
      if (currentFilter.key === key) found = true
    })

    if (!found)
      newFilter.push({
        key: currentFilter.key,
        value: '' //trigger delete
      })
  })

  setCurrentFilter(newFilter)
}

/**
 * Build url parameter based on CurrentFilter list
 * @param currentFilters CurrentFilter list
 * @param forceFilter Force filter parameter
 * @returns parameter
 */
export const getUrlParameterForCurrentFilters = (
  currentFilters: CurrentFilter[],
  forceFilters?: CurrentFilter[]
): string | null => {
  const params = new URLSearchParams()

  if (currentFilters.length === 0) {
    return null
  }

  //set query first
  currentFilters.forEach((currentFilter: CurrentFilter) => {
    if (currentFilter.key === 'q' && currentFilter.value) {
      params.set(currentFilter.key, currentFilter.value)
      return
    }
  })

  currentFilters.forEach((currentFilter: CurrentFilter) => {
    if (
      currentFilter.key !== 'q' &&
      currentFilter.value &&
      !excludeParametersFromUrl.includes(currentFilter.key)
    ) {
      params.set(currentFilter.key, currentFilter.value)
    }
  })

  if (forceFilters) {
    forceFilters.forEach((forceFilter: CurrentFilter) => {
      if (forceFilter.value) {
        params.set(forceFilter.key, forceFilter.value)
      } else {
        params.delete(forceFilter.key)
      }
    })
  }
  return '?' + params.toString()
}

/**
 * Build url and update parameter based on CurrentFilter list
 * @param currentFilters CurrentFilter list
 * @returns url
 */
export const getUrlForCurrentFilters = (
  currentFilters: CurrentFilter[]
): string | null => {
  const DOMPurify = createDOMPurify(window)
  const locationHash = DOMPurify.sanitize(window.location.hash)
  const locationComponents = locationHash.split('?')
  const params = new URLSearchParams(locationComponents[1])

  let changed = false

  //set query first
  currentFilters.forEach((currentFilter: CurrentFilter) => {
    const currentFilterUrlValue = params.get(currentFilter.key)
    if (
      currentFilter.key === 'q' &&
      currentFilterUrlValue !== currentFilter.value &&
      currentFilter.value
    ) {
      params.set(currentFilter.key, currentFilter.value)
      changed = true
      return
    }
  })

  currentFilters.forEach((currentFilter: CurrentFilter) => {
    const currentFilterUrlValue = params.get(currentFilter.key)

    if (
      currentFilter.key !== 'q' &&
      currentFilterUrlValue !== currentFilter.value &&
      currentFilter.value &&
      !excludeParametersFromUrl.includes(currentFilter.key)
    ) {
      params.set(currentFilter.key, currentFilter.value)
      changed = true
    }

    if (currentFilterUrlValue && !currentFilter.value) {
      params.delete(currentFilter.key)
      changed = true
    }
  })

  const deleteKeys: string[] = []
  params.forEach((value, key) => {
    if (!currentFilters.find((item: CurrentFilter) => item.key === key)) {
      deleteKeys.push(key)
    }
  })

  deleteKeys.forEach((key: string) => {
    params.delete(key)
    changed = true
  })

  if (!changed || params.toString().length === 0) return null
  return locationComponents[0] + '?' + params.toString()
}

export function overwriteKVFiltersWithTemporaryFilters(
  currentFiltersKV: { [key: string]: any },
  temporaryFilters: CurrentFilter[]
): { [key: string]: any } {
  //disable all filters if the user comes from any widget and overwrite
  if (temporaryFilters && temporaryFilters.length > 0) {
    const fetchFilter: { [key: string]: any } = []
    temporaryFilters.forEach((temporaryFilter: CurrentFilter) => {
      if (temporaryFilter.value !== 'none')
        fetchFilter[temporaryFilter.key] = temporaryFilter.value
    })
    return fetchFilter
  } else {
    return currentFiltersKV
  }
}

/**
 * Build post body filters for kpmg.us datasource
 * @param appliedFilters The dictionary to parse
 * @param acceptedFilters The accepted filters
 * @param page Current page number
 * @param searchQuery Current search term
 * @param userSettings Current usersettings
 * @returns url
 */
export function prepareKpmgUSFilters(
  appliedFilters: {
    [key: string]: string
  },
  acceptedFilters: FilterCategory[],
  currentRowKpmgComUS: number,
  searchQuery: string,
  userSettings: IUserSetting,
  findConfiguration: IFindConfiguration,
  useCognitiveSearch: boolean
) {
  const modifiableFilters = { ...appliedFilters }
  const sanitizedFilters = sanitizeAppliedFilters(
    acceptedFilters,
    modifiableFilters,
    [],
    true
  )

  // Last modified
  const date: string[] = ['LastModified']
  if (sanitizedFilters['lastmodified']) {
    if (sanitizedFilters['lastmodified'].indexOf('customRange') >= 0) {
      let dateFrom = ''
      let dateTo = ''
      try {
        const customRangeDates = sanitizedFilters['lastmodified']
          .replace('customRange(', '')
          .replace(')', '')
          .split(',')
        if (customRangeDates && customRangeDates.length === 2) {
          dateFrom = customRangeDates[0]
          dateTo = customRangeDates[1]
        }
      } catch (e) {
        trackException(
          'Exception parsing last modified filter in filters.ts',
          e
        )
      }

      if (dateFrom) {
        date.push(dateFrom)
      }
      if (dateTo) {
        date.push(dateTo)
      }
    }
    switch (sanitizedFilters['lastmodified']) {
      case 'customRange':
        break
      case 'lastday':
        date.push(getDateValueFromOffset(86400000, 10, true))
        date.push('')
        break
      case 'lastweek':
        date.push(getDateValueFromOffset(604800000, 10, true))
        date.push('')
        break
      case 'lastmonth':
        date.push(getDateValueFromOffset(2592000000, 10, true))
        date.push('')
        break
      case 'last3month':
        date.push(getDateValueFromOffset(7776000000, 10, true))
        date.push('')
        break
      case 'lastyear':
        date.push(getDateValueFromOffset(31536000000, 10, true))
        date.push('')
        break
      case 'olderyear':
        date.push('')
        date.push(getDateValueFromOffset(31536000000, 10, true))
        break
    }
  }

  const filtersBody = date.length === 3 ? { date: date } : {}

  const advancedPostFilter: IAdvancedPostFilterKpmgUS = {
    operator: 'AND',
    filters: []
  }

  // Category
  if (sanitizedFilters['categoryus']) {
    const appliedValues = sanitizedFilters['categoryus'].split(',')
    const filterCategory: IFilterKpmgUS = {
      field: 'Category',
      operator: 'OR',
      values: []
    }
    appliedValues.forEach((appliedValue) => {
      filterCategory.values.push(appliedValue.trim())
      filterCategory.values.push(
        capitalizeFirstLetterOfEachWord(appliedValue.trim())
      )
    })
    advancedPostFilter.filters.push(filterCategory)
  }

  // Line of Business
  if (sanitizedFilters['lineofbusinessus']) {
    const appliedValues = sanitizedFilters['lineofbusinessus'].split(',')
    const filterLineOfBusiness: IFilterKpmgUS = {
      field: 'LineOfBusiness',
      operator: 'OR',
      values: []
    }
    appliedValues.forEach((appliedValue) => {
      const lineOfBusinessFilterCategory = kpmgus.find(
        (category: FilterCategory) => category.key === 'lineofbusinessus'
      )
      if (
        lineOfBusinessFilterCategory &&
        lineOfBusinessFilterCategory.options
      ) {
        const appliedValueFilter = lineOfBusinessFilterCategory.options.find(
          (option: IFilter) => option.key === appliedValue
        )
        if (appliedValueFilter && appliedValueFilter.value) {
          filterLineOfBusiness.values.push(appliedValueFilter.value)
        }
      }
    })
    advancedPostFilter.filters.push(filterLineOfBusiness)
  }

  // Topic
  if (sanitizedFilters['topicus']) {
    const appliedValues = sanitizedFilters['topicus'].split(',')
    const filterTopic: IFilterKpmgUS = {
      field: 'Topics',
      operator: 'OR',
      values: []
    }
    appliedValues.forEach((appliedValue) => {
      const topicFilterCategory = kpmgus.find(
        (category: FilterCategory) => category.key === 'topicus'
      )
      if (topicFilterCategory && topicFilterCategory.options) {
        const appliedValueFilter = topicFilterCategory.options.find(
          (option: IFilter) => option.key === appliedValue
        )
        if (appliedValueFilter && appliedValueFilter.value) {
          filterTopic.values.push(appliedValueFilter.value)
        }
      }
    })
    advancedPostFilter.filters.push(filterTopic)
  }

  // Sorting
  let sort = {}
  if (appliedFilters['orderBy']) {
    if (appliedFilters['orderBy'] === 'dated') {
      sort = { dateTime: 'desc' }
    } else if (appliedFilters['orderBy'] === 'datea') {
      sort = { dateTime: 'asc' }
    }
  }

  return {
    searchquery: searchQuery,
    filters: filtersBody,
    advancedPostFilter:
      advancedPostFilter.filters.length === 0 ? {} : advancedPostFilter,
    sort: sort,
    rowsperpage: '20',
    currentRowKpmgComUS: currentRowKpmgComUS,
    processFeaturedResults: currentRowKpmgComUS === 0 ? true : false,
    featuredResultsOrigin: 'KPMGFind',
    country: userSettings.Country,
    function: userSettings.Function,
    cognitiveEnabled:
      useCognitiveSearch && findConfiguration.CognitiveSearchEnabled
  }
}

/**
 * Gets the language key from name
 * @param languageName The language name
 */
export function getLanguageKeyFromName(languageName: string): string {
  switch (languageName) {
    case 'Afrikaans':
      return 'af'
    case 'Albanian':
      return 'sq'
    case 'Arabic':
      return 'ar'
    case 'Armenian':
      return 'hy'
    case 'Azerbaijani':
      return 'az'
    case 'Basque':
      return 'eu'
    case 'Bengali (Bangla)':
      return 'bn'
    case 'Bengali':
      return 'bn'
    case 'Bulgarian':
      return 'bg'
    case 'Catalan':
      return 'ca'
    case 'Chinese':
      return 'zhs'
    case 'Croatian':
      return 'hr'
    case 'Czech':
      return 'cs'
    case 'Danish':
      return 'da'
    case 'Dutch':
      return 'nl'
    case 'English':
      return 'en'
    case 'Estonian':
      return 'et'
    case 'Finnish':
      return 'fi'
    case 'French':
      return 'fr'
    case 'Galician':
      return 'gl'
    case 'Georgian':
      return 'ka'
    case 'German':
      return 'de'
    case 'Greek':
      return 'el'
    case 'Gujarati':
      return 'gu'
    case 'Hebrew':
      return 'he'
    case 'Hindi':
      return 'hi'
    case 'Hungarian':
      return 'hu'
    case 'Icelandic':
      return 'is'
    case 'Indonesian':
      return 'id'
    case 'Italian':
      return 'it'
    case 'Japanese':
      return 'ja'
    case 'Kannada':
      return 'kn'
    case 'Kazakh':
      return 'kk'
    case 'Korean':
      return 'ko'
    case 'Latvian (Lettish)':
      return 'lv'
    case 'Latvian':
      return 'lv'
    case 'Lithuanian':
      return 'lt'
    case 'Macedonian':
      return 'mk'
    case 'Malay':
      return 'ms'
    case 'Malayalam':
      return 'ml'
    case 'Marathi':
      return 'mr'
    case 'Mongolian':
      return 'mn'
    case 'Nepali':
      return 'ne'
    case 'Norwegian':
      return 'no'
    case 'Polish':
      return 'pl'
    case 'Portuguese':
      return 'ptp'
    case 'Punjabi':
      return 'pa'
    case 'Romanian':
      return 'ro'
    case 'Russian':
      return 'ru'
    case 'Serbian':
      return 'sr'
    case 'Sindhi':
      return 'sd'
    case 'Slovak':
      return 'sk'
    case 'Slovenian':
      return 'sl'
    case 'Spanish':
      return 'es'
    case 'Swahili (Kiswahili)':
      return 'sw'
    case 'Swahili':
      return 'sw'
    case 'Swedish':
      return 'sv'
    case 'Tamil':
      return 'ta'
    case 'Telugu':
      return 'te'
    case 'Thai':
      return 'th'
    case 'Turkish':
      return 'tr'
    case 'Ukrainian':
      return 'uk'
    case 'Urdu':
      return 'ur'
    case 'Uzbek':
      return 'uz'
    case 'Vietnamese':
      return 'vi'
    default:
      return 'all'
  }
}

/**
 * Gets the language name from key
 * @param languageKey The language key
 */
export function getLanguageNameFromKey(languageKey: string) {
  switch (languageKey) {
    case 'af':
      return 'Afrikaans'
    case 'sq':
      return 'Albanian'
    case 'ar':
      return 'Arabic'
    case 'hy':
      return 'Armenian'
    case 'az':
      return 'Azerbaijani'
    case 'eu':
      return 'Basque'
    case 'bn':
      return 'Bengali'
    case 'bg':
      return 'Bulgarian'
    case 'ca':
      return 'Catalan'
    case 'zhs':
      return 'Chinese'
    case 'zht':
      return 'Chinese'
    case 'hr':
      return 'Croatian'
    case 'cs':
      return 'Czech'
    case 'da':
      return 'Danish'
    case 'nl':
      return 'Dutch'
    case 'en':
      return 'English'
    case 'et':
      return 'Estonian'
    case 'fi':
      return 'Finnish'
    case 'fr':
      return 'French'
    case 'gl':
      return 'Galician'
    case 'ka':
      return 'Georgian'
    case 'de':
      return 'German'
    case 'el':
      return 'Greek'
    case 'gu':
      return 'Gujarati'
    case 'he':
      return 'Hebrew'
    case 'hi':
      return 'Hindi'
    case 'hu':
      return 'Hungarian'
    case 'is':
      return 'Icelandic'
    case 'id':
      return 'Indonesian'
    case 'it':
      return 'Italian'
    case 'ja':
      return 'Japanese'
    case 'kn':
      return 'Kannada'
    case 'kk':
      return 'Kazakh'
    case 'ko':
      return 'Korean'
    case 'lv':
      return 'Latvian'
    case 'lt':
      return 'Lithuanian'
    case 'mk':
      return 'Macedonian'
    case 'ms':
      return 'Malay'
    case 'ml':
      return 'Malayalam'
    case 'mr':
      return 'Marathi'
    case 'mn':
      return 'Mongolian'
    case 'ne':
      return 'Nepali'
    case 'no':
      return 'Norwegian'
    case 'pl':
      return 'Polish'
    case 'ptp':
      return 'Portuguese'
    case 'ptb':
      return 'Portuguese'
    case 'pa':
      return 'Punjabi'
    case 'ro':
      return 'Romanian'
    case 'ru':
      return 'Russian'
    case 'sr':
      return 'Serbian'
    case 'sd':
      return 'Sindhi'
    case 'sk':
      return 'Slovak'
    case 'sl':
      return 'Slovenian'
    case 'es':
      return 'Spanish'
    case 'sw':
      return 'Swahili'
    case 'sv':
      return 'Swedish'
    case 'ta':
      return 'Tamil'
    case 'te':
      return 'Telugu'
    case 'th':
      return 'Thai'
    case 'tr':
      return 'Turkish'
    case 'uk':
      return 'Ukrainian'
    case 'ur':
      return 'Urdu'
    case 'uz':
      return 'Uzbek'
    case 'vi':
      return 'Vietnamese'
    default:
      return 'all'
  }
}
/*function sameAsJsonString(newValue: T, defaultValue: T & any[]) {
  throw new Error('Function not implemented.')
}*/

export function sortFilterOptions(options: Filter[]) {
  return options.sort(
    (n1, n2) => (n1.sort ? n1.sort : 999) - (n2.sort ? n2.sort : 999)
  )
}

interface IFilter {
  [key: string]: any
}

/**
 * Get current set filter keynames for datasource
 * @param availableFilterKeyNames filter constant names
 * @param currentFilter current set filter
 */
export const getFiltersToTrack = (
  dsName: string,
  currentFilter: IFilter
): any => {
  const currentSetDatasourcesFilters: string[] = []

  let availableFilterKeyName = dsName

  if (dsName === 'kpmg.com') availableFilterKeyName = 'kpmgwebsites'

  const availableFilters = (Filters as IFilter)[
    availableFilterKeyName
      .toLowerCase()
      .replace(' ', '_')
      .replace('kpmg.us', 'kpmgus')
  ]

  if (availableFilters) {
    availableFilters.forEach((filter: IFilter) => {
      currentSetDatasourcesFilters.push(filter.key)
    })
  }

  // Bug 1706199: market research not fetching on redirect from right widget
  if (
    availableFilterKeyName.toLowerCase().replace(' ', '_') ===
      'marketresearch' &&
    currentFilter &&
    currentFilter.ptkr
  ) {
    currentSetDatasourcesFilters.push('ptkr')
  }

  const filtersToTrack: any = {}

  Object.keys(currentFilter).forEach((key: string) => {
    if (currentSetDatasourcesFilters.includes(key) || key === 'page') {
      filtersToTrack[key] = currentFilter[key]
    }
  })

  return filtersToTrack
}

/**
 * Build refiners for knowledge Exchange
 * @param appliedFilters Currently applied filters
 * @param acceptedFilters Allowed filters
 * @param requestBody requestBody
 */
export const buildFiltersKnowledgeExchange = (
  appliedFilters: any,
  acceptedFilters: FilterCategory[],
  requestBody: any
) => {
  const filterMapping: any = {
    Function: 'kxFunction',
    ContentType: 'contentType',
    FileType: 'documentType',
    Sector: 'sector',
    ServiceLine: 'serviceLine',
    SubServiceLine: 'subServiceLine',
    Solution: 'solution',
    Corridor: 'corridor',
    kxcountry: 'kxCountry' //,
    // kxdoctype: 'kxdoctype'
  }

  const modifiableFilters = { ...appliedFilters }
  const sanitizedFilters = sanitizeAppliedFilters(
    acceptedFilters,
    modifiableFilters,
    [],
    true
  )
  for (const filter in sanitizedFilters) {
    if (filter === 'lastmodified') {
      const dateFilter = createModifiedFilterForKnowledgeExchange(
        sanitizedFilters[filter],
        10,
        false
      )

      if (dateFilter && dateFilter.dateFrom) {
        requestBody.documentFromDate = dateFilter.dateFrom
      }
      if (dateFilter && dateFilter.dateTo) {
        requestBody.documentToDate = dateFilter.dateTo
      }
    } else {
      if (
        sanitizedFilters[filter] != null &&
        sanitizedFilters[filter] !== 'all' &&
        sanitizedFilters[filter].length > 0
      ) {
        requestBody[filterMapping[filter]] = sanitizedFilters[filter]
      }
    }
  }
}

/**
 *  Sort filters array by name
 */
export const sortFilterValuesByName = (array: Filter[]): Filter[] => {
  const sorted = array.sort((a, b) => {
    if (a.name < b.name) {
      return -1
    }
    if (a.name > b.name) {
      return 1
    }
    return 0
  })
  return sorted
}

/**
 * Removes filters that are duplicate
 */
export const removeFilterValuesDuplicates = (array: Filter[]): Filter[] => {
  const filtered = array.filter(
    (item, index, self) => index === self.findIndex((t) => t.name === item.name)
  )
  return filtered
}
