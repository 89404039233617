import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Box, Grid, Typography } from '@mui/material'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { WidgetKey } from 'constants/widgets'
import { trackWidgetShown, trackRightWidgetClick } from 'utils/tracking'
import { capitalizeFirstLetter } from 'utils/string'
import { getStylesRightSearchTop10Widget } from 'styles/contents/resultpages/widgets/RightSearchTop10Widget'
import SearchIcon from '@mui/icons-material/Search'
import { ISearchTop10 } from 'store/SearchTop10/reducers'
import SeeAllButton from './common/SeeAllButton'
import { getStylesRightWidget } from 'styles/contents/resultpages/widgets/RightWidget'
import { getUrlParameterForCurrentFilters, useFilter } from 'utils/filters'
import createDOMPurify from 'dompurify'
import LinesEllipsis from 'react-lines-ellipsis'
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'
import SettingsStore from 'store/Settings'
import { connect } from 'react-redux'
import { Store } from 'store'
import { useNavigate } from 'react-router-dom'
import FilterStore from 'store/Filters'

export interface RightSearchTop10WidgetProps {
  searchQuery: any
  results: ISearchTop10[]
}

type AllProps = ReturnType<typeof mapStateToProps> & RightSearchTop10WidgetProps

function RightSearchTop10Widget(props: AllProps): JSX.Element {
  const {
    searchQuery,
    results,
    findConfiguration,
    currentDataSources,
    currentfilters
  } = props

  const DOMPurify = createDOMPurify(window)
  const navigateFunction = useNavigate()
  const rightWidgetClasses = getStylesRightWidget()
  const classes = getStylesRightSearchTop10Widget()

  const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis)

  const [itemsShown, setItemsShown] = useState(3)
  const [currentPage] = useFilter('page', '1')
  const [, setSearchQuery] = useFilter('q', '')

  const showTen = () => {
    setItemsShown(10)
  }

  const showThree = () => {
    setItemsShown(3)
  }

  React.useEffect(() => {
    showThree()
  }, [currentPage])

  // triggers the new search
  const setNewSearchQuery: any = (
    newSearchQuery: string,
    currentSearchTerm: string
  ) => {
    if (!newSearchQuery || newSearchQuery === currentSearchTerm) return
    setSearchQuery(newSearchQuery)

    const intranetDatasource = currentDataSources.find(
      (item) => item.name === 'Intranet'
    )

    if (
      intranetDatasource &&
      intranetDatasource.active &&
      intranetDatasource.enabled
    ) {
      const urlParameter = getUrlParameterForCurrentFilters(currentfilters, [
        {
          key: 'page',
          value: '1'
        },
        {
          key: 'cntx',
          value: ''
        }
      ])

      navigateFunction(
        `${intranetDatasource.path}${urlParameter ? urlParameter : ''}`
      )
    }
  }

  React.useEffect(() => {
    trackWidgetShown({
      widgetType: WidgetKey.searchTop10.toLowerCase(),
      widgetPane: 'right'
    })
  }, [])

  const intl = useIntl()
  const headline = intl.formatMessage({
    id: 'search_top10_title',
    defaultMessage: 'Top 10 searches'
  })
  const seeAllLink =
    findConfiguration &&
    findConfiguration.SeeAllLinks &&
    findConfiguration.SeeAllLinks[WidgetKey.searchTop10]
      ? findConfiguration.SeeAllLinks[WidgetKey.searchTop10]
      : `https://app.powerbi.com/groups/me/reports/40f33a4b-d30b-4566-baca-3f18ba012e1d`

  return (
    <Box
      id={'con-widget-right-searchtop10'}
      className={
        results.length > 3
          ? rightWidgetClasses.widgetAreaExtend
          : rightWidgetClasses.widgetArea
      }
    >
      <h2 className={rightWidgetClasses.widgetHeadlineH2}>
        <ResponsiveEllipsis
          className={rightWidgetClasses.widgetHeadline}
          basedOn="words"
          text={headline}
          maxLine="2"
        />
      </h2>
      {results.slice(0, itemsShown).map((item: ISearchTop10, index: number) => (
        <Box
          key={'rst' + index}
          className={classes.widgetItem}
          tabIndex={0}
          onClick={() => {
            trackRightWidgetClick({
              title: DOMPurify.sanitize(item.searchQuery),
              url: DOMPurify.sanitize(item.searchQuery),
              index,
              widgetKey: WidgetKey.searchTop10.toLowerCase()
            })
            setNewSearchQuery(
              DOMPurify.sanitize(item.searchQuery),
              DOMPurify.sanitize(searchQuery)
            )
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              trackRightWidgetClick({
                title: DOMPurify.sanitize(item.searchQuery),
                url: DOMPurify.sanitize(item.searchQuery),
                index,
                widgetKey: WidgetKey.searchTop10.toLowerCase()
              })
              setNewSearchQuery(
                DOMPurify.sanitize(item.searchQuery),
                DOMPurify.sanitize(searchQuery)
              )
            }
          }}
        >
          <SearchIcon key={'rstsi' + index} />
          <Box
            key={'rstb1' + index}
            className={`${rightWidgetClasses.spacer} ${classes.spacer} `}
          />
          <Box key={'rstb2' + index}>
            <ResponsiveEllipsis
              className={`${rightWidgetClasses.widgetTitle} ${classes.widgetTitle}`}
              basedOn="words"
              text={capitalizeFirstLetter(DOMPurify.sanitize(item.searchQuery))}
              maxLine="1"
            />
            <Typography
              key={'rstt1' + index}
              className={classes.widgetContent}
              variant="body2"
              color="textSecondary"
              component="div"
            >
              {item.country || item.function
                ? intl.formatMessage(
                    {
                      id: 'top10_count_searched',
                      defaultMessage:
                        'Searched {count} times during the past 7 days'
                    },
                    { count: item.count }
                  )
                : intl.formatMessage(
                    {
                      id: 'top10_count_global',
                      defaultMessage:
                        'Trending: Searched globally {count} times during the past 7 days'
                    },
                    { count: item.count }
                  )}
              {item.function
                ? ' ' +
                  intl.formatMessage({
                    id: 'top10_similar_profile',
                    defaultMessage: 'by users with a similar profile'
                  })
                : ''}
              {item.country
                ? ' ' +
                  intl.formatMessage({
                    id: 'top10_area',
                    defaultMessage: 'in your area'
                  })
                : ''}
            </Typography>
          </Box>
          <Box
            key={'rstb3' + index}
            className={`${rightWidgetClasses.spacer} ${classes.spacer} `}
          />
          <Box key={'rstb4' + index} className={classes.widgetArrow}>
            <ArrowForwardIosIcon />
          </Box>
        </Box>
      ))}
      {results.length > 3 && (
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          className={rightWidgetClasses.footerGrid}
        >
          {results.length >= 10 && (
            <SeeAllButton
              searchQuery={DOMPurify.sanitize(searchQuery)}
              url={seeAllLink}
              widgetKey={WidgetKey.searchTop10.toLowerCase()}
            />
          )}
          {itemsShown === 3 && (
            <Grid
              item
              id={'btn-right-widget-down-01'}
              className={rightWidgetClasses.arrowContainer}
              tabIndex={0}
              onClick={showTen}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  showTen()
                }
              }}
            >
              <KeyboardArrowDown className={rightWidgetClasses.arrowItem} />
            </Grid>
          )}
          {itemsShown === 10 && (
            <Grid
              item
              id={'btn-right-widget-up-01'}
              className={rightWidgetClasses.arrowContainerExpanded}
              tabIndex={0}
              onClick={showThree}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  showThree()
                }
              }}
            >
              <KeyboardArrowUp className={rightWidgetClasses.arrowItem} />
            </Grid>
          )}
        </Grid>
      )}
    </Box>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    findConfiguration: SettingsStore.selectors.getFindConfiguration(state),
    currentDataSources: SettingsStore.selectors.getCurrentDataSources(state),
    currentfilters: FilterStore.selectors.getCurrentFilters(state)
  }
}

export default connect(mapStateToProps)(RightSearchTop10Widget)
