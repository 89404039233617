import React, { useCallback, useEffect, useMemo } from 'react'
import ReactPlaceholder from 'react-placeholder'
import { Box, Fade } from '@mui/material'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import 'Placeholder.css'
import { Store } from 'store'
import ResultsIbfdStore from 'store/ResultsIbfd'
import ResultMetaDataStore from 'store/ResultMetaData'
import UserSettingsStore from 'store/UserSettings'
import SettingsStore from 'store/Settings'
import PageStay from 'components/HOC/PageStay'
import ErrorPage from 'components/contents/common/ErrorPage'
import TextResult from 'components/contents/results/TextResult'
import Pagination from '../Pagination'
import { trackException } from 'utils/tracking'
import { useReactRouterQueryStringInterface } from 'utils/useQueryState'
import { authorizeResultPage } from 'utils/authorization'
import createDOMPurify from 'dompurify'
import { dateFormatOptions, dataSourcesTabs } from 'constants/constants'
import SearchSuggestions from 'components/contents/common/SearchSuggestions'
import { capitalizeFirstLetter } from 'utils/string'
import FeaturedResults from './FeaturedResults'
import RightTopWidgetContainer from './widgetscontainer/RightTopWidgetContainer'
import LeftWidgetContainer from './widgetscontainer/LeftWidgetContainer'
import RightWidgetContainer from './widgetscontainer/RightWidgetContainer'
import { getStylesResults } from 'styles/contents/resultpages/Results'
import { useFilter } from 'utils/filters'
import { KPMGFindGlobalVariables } from 'store/KPMGFindGlobalVariables'
import ResultsIbfdMobile from './ResultsIbfdMobile'
import { formatDisplayBody } from 'utils/intranet'
import { useLocation } from 'react-router-dom'

export interface ResultsIbfdProps {
  appliedFilters: any
  authToken: string
}

type AllProps = ReturnType<typeof mapStateToProps> & ResultsIbfdProps

function ResultsIbfd(props: AllProps) {
  const {
    featuredResults,
    results,
    resultsCount,
    hasResultsBeenFetched,
    hasErrorStore,
    userSettings,
    deviceSettings,
    hasCountryError
  } = props
  const DOMPurify = createDOMPurify(window)
  const resultClasses = getStylesResults()
  const { getQueryString } = useReactRouterQueryStringInterface()
  const intl = useIntl()
  const queryStringFromUrl = getQueryString()

  const [currentPage] = useFilter('page', '1')
  const [searchQuery] = useFilter('q')
  const location = useLocation()

  useEffect(() => {
    KPMGFindGlobalVariables.setCurrentTab(dataSourcesTabs.ibfd)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setup = useCallback(() => {
    try {
      authorizeResultPage(
        'IBFD',
        userSettings,
        deviceSettings,
        DOMPurify.sanitize(location && location.search ? location.search : '')
      )
    } catch (error) {
      trackException('Error in fetching results in ResultsIbfd.tsx', error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryStringFromUrl])

  useEffect(() => {
    setup()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryStringFromUrl])

  const buildTabType = (resultRegion: string[], resultCountry: string[]) => {
    const resultStringRegion =
      resultRegion && resultRegion.length > 0 ? resultRegion[0] : ''

    const resultStringCountry =
      resultCountry && resultCountry.length > 0 ? resultCountry[0] : ''
    const tabType =
      resultStringRegion +
      (resultStringRegion && resultStringCountry ? ' › ' : '') +
      resultStringCountry

    if (!tabType) return ' '
    return tabType
  }

  const returnAllItems = (type: string) => {
    if (!results || results.length < 1) {
      return []
    }

    const items = []

    try {
      const firstResults = results.length < 4 ? results.length : 3

      if (type === 'first') {
        for (let i = 0; i < firstResults; i++) {
          const currentDate = results[i].date
            ? intl.formatDate(
                new Date(
                  DOMPurify.sanitize(results[i].date).replace(
                    /(\d{4})(\d{2})(\d{2})/,
                    '$1/$2/$3'
                  )
                ).toString(),
                dateFormatOptions
              )
            : ''
          const currentBody = DOMPurify.sanitize(results[i].snippet) || ''
          const displayBody = formatDisplayBody(currentDate, currentBody, intl)
          items.push(
            <TextResult
              title={capitalizeFirstLetter(
                DOMPurify.sanitize(results[i].title)
              )}
              body={displayBody}
              url={DOMPurify.sanitize(results[i].resultUrl)}
              featured={false}
              showContextMenu={false}
              key={i}
              index={i}
              searchQuery={DOMPurify.sanitize(searchQuery)}
              deviceSettings={deviceSettings}
              tabType={buildTabType(results[i].region, results[i].country)}
            />
          )
        }
      } else {
        for (let i = firstResults; i < results.length; i++) {
          const currentDate = results[i].date
            ? intl.formatDate(
                new Date(
                  DOMPurify.sanitize(results[i].date).replace(
                    /(\d{4})(\d{2})(\d{2})/,
                    '$1/$2/$3'
                  )
                ).toString(),
                dateFormatOptions
              )
            : ''
          const currentBody = DOMPurify.sanitize(results[i].snippet) || ''
          const displayBody = formatDisplayBody(currentDate, currentBody, intl)
          items.push(
            <TextResult
              title={capitalizeFirstLetter(
                DOMPurify.sanitize(results[i].title)
              )}
              body={displayBody}
              url={DOMPurify.sanitize(results[i].resultUrl)}
              featured={false}
              showContextMenu={false}
              key={i}
              index={i}
              searchQuery={DOMPurify.sanitize(searchQuery)}
              deviceSettings={deviceSettings}
              tabType={buildTabType(results[i].region, results[i].country)}
            />
          )
        }
      }
    } catch (error) {
      trackException('Error returning all items in ResultsIbfd.tsx', error)
    }
    return items
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const firstItems = useMemo(() => returnAllItems('first'), [results, intl])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const restItems = useMemo(() => returnAllItems('rest'), [results, intl])

  return (
    <Box>
      {!hasErrorStore && !!searchQuery && !hasCountryError && (
        <>
          {deviceSettings.renderMobile ? (
            <ResultsIbfdMobile
              searchQuery={searchQuery}
              deviceSettings={deviceSettings}
            />
          ) : (
            <Box className={resultClasses.container}>
              <Box
                id={'con-resultvertical-01'}
                className={resultClasses.resultsContainer}
              >
                <SearchSuggestions
                  wildCardActive={true}
                  resultCount={resultsCount}
                  resultsFetchend={hasResultsBeenFetched}
                />
                <ReactPlaceholder
                  ready={hasResultsBeenFetched ? hasResultsBeenFetched : false}
                  type="text"
                  rows={4}
                  showLoadingAnimation={true}
                  className={resultClasses.placeholder}
                >
                  <Fade
                    in={hasResultsBeenFetched ? hasResultsBeenFetched : false}
                    timeout={600}
                  >
                    <Box id={'con-resultvertical-section-01'}>
                      {results && resultsCount > 0 && (
                        <FeaturedResults featuredResults={featuredResults} />
                      )}
                      {firstItems}
                    </Box>
                  </Fade>
                </ReactPlaceholder>
                {Number(currentPage) === 1 && (
                  <LeftWidgetContainer
                    resultCount={resultsCount}
                    hasResultsBeenFetched={hasResultsBeenFetched}
                  />
                )}
                <ReactPlaceholder
                  ready={hasResultsBeenFetched}
                  type="text"
                  rows={4}
                  showLoadingAnimation={true}
                  className={resultClasses.placeholder}
                >
                  <Fade in={hasResultsBeenFetched} timeout={600}>
                    <Box id={'con-resultvertical-section-02'}>
                      {restItems}
                      {resultsCount != null && resultsCount > 0 && (
                        <Pagination
                          {...props}
                          totalRecords={resultsCount}
                          pageLimit={20}
                          pageNeighbours={4}
                        />
                      )}
                    </Box>
                  </Fade>{' '}
                </ReactPlaceholder>
                <ReactPlaceholder
                  ready={hasResultsBeenFetched ? hasResultsBeenFetched : false}
                  type="text"
                  rows={4}
                  showLoadingAnimation={true}
                  className={resultClasses.placeholder}
                >
                  <Box />
                </ReactPlaceholder>
              </Box>
              <Box className={resultClasses.advertisementContainer}>
                <RightTopWidgetContainer
                  searchQuery={searchQuery}
                  scope={'ibfd'}
                />
                <RightWidgetContainer />
              </Box>
            </Box>
          )}
        </>
      )}
      {hasErrorStore && !hasCountryError && <ErrorPage dataSource="ibfd" />}
      {hasErrorStore && hasCountryError && (
        <ErrorPage
          dataSource="ibfd"
          customMessage={{
            type: 'warning',
            showDetails: false,
            content: (
              <>
                {intl.formatMessage({
                  id: 'error_ibfd_country_notsupported',
                  defaultMessage:
                    'Unfortunately, your country is not supported by the IBFD datasource.'
                })}
              </>
            )
          }}
        />
      )}
    </Box>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    featuredResults: ResultsIbfdStore.selectors.getFeaturedResults(state),
    results: ResultsIbfdStore.selectors.getResults(state),
    resultsCount: ResultsIbfdStore.selectors.getResultCount(state),
    executionTime: ResultsIbfdStore.selectors.getExecutionTime(state),
    hasResultsBeenFetched:
      ResultsIbfdStore.selectors.hasResultsBeenFetched(state),
    hasErrorStore: ResultMetaDataStore.selectors.getDataSourceHasError(
      state,
      'ibfd'
    ),
    userSettings: UserSettingsStore.selectors.getUserSettings(state),
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state),
    hasCountryError: ResultsIbfdStore.selectors.getCountryError(state)
  }
}

export default PageStay(connect(mapStateToProps)(ResultsIbfd))
