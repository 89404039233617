import {
  CircularProgress,
  Grid,
  Link,
  Menu,
  Tooltip,
  Typography
} from '@mui/material'
import { withStyles } from '@mui/styles'
import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import FilterButton from './FilterButton'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import {
  datasourcesGroups,
  IDataSource,
  IDatasourceGroup
} from 'constants/datasourcesConfiguration'
import { MenuItem } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { removeQueryParam } from 'utils/queryParams'
import { getStylesFilterBarMoreMenu } from 'styles/contents/FilterBarMoreMenu'
import { useLocation, useNavigate } from 'react-router-dom'
import UserSettingsStore from 'store/UserSettings'
import { connect } from 'react-redux'
import { Store } from 'store'
import { getUrlParameterForCurrentFilters } from 'utils/filters'
import FilterStore from 'store/Filters'
import SettingsStore from 'store/Settings'
import Fade from '@mui/material/Fade'

interface FilterBarMoreMenuProps {
  moreDataSources: IDataSource[]
}
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11
  }
}))(Tooltip)

type AllProps = ReturnType<typeof mapStateToProps> & FilterBarMoreMenuProps

function FilterBarMoreMenu(props: AllProps): JSX.Element {
  const {
    moreDataSources,
    userSettings,
    currentfilters,
    deviceSettings,
    internalBroadcast
  } = props

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const classes = getStylesFilterBarMoreMenu()
  const navigateFunction = useNavigate()
  const location = useLocation()

  const urlParameter = getUrlParameterForCurrentFilters(currentfilters, [
    {
      key: 'page',
      value: '1'
    },
    {
      key: 'cntx',
      value: ''
    }
  ])

  //handle force open more menu
  const filterButtonWrapperRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (
      internalBroadcast &&
      internalBroadcast.includes('openDatasourceMoreMenu') &&
      anchorEl === null
    )
      setAnchorEl(filterButtonWrapperRef.current)

    if (
      internalBroadcast &&
      internalBroadcast.includes('closeDatasourceMoreMenu') &&
      anchorEl !== null
    )
      setAnchorEl(null)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalBroadcast])

  //close panel location change e.g. back button, links
  useEffect(() => {
    if (anchorEl) setAnchorEl(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  //close panel on unload component
  useEffect(() => {
    return () => {
      if (anchorEl) setAnchorEl(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const buildCategory = () => {
    const datasourcesGroupSorted: {
      group: IDatasourceGroup
      datasources: IDataSource[] | undefined
    }[] = []

    const selectedDatasources: string[] = []

    //select function categorys
    Object.keys(datasourcesGroups).forEach((key: string, index: number) => {
      if (
        datasourcesGroups[key].function &&
        datasourcesGroups[key].function?.toLocaleLowerCase() ===
          userSettings.Function.toLocaleLowerCase()
      ) {
        const datasourceGroupConfig = {
          group: datasourcesGroups[key],
          datasources: moreDataSources.filter((datasource: IDataSource) =>
            Array.isArray(datasource.group)
              ? !!datasource.group.find(
                  (item: IDatasourceGroup) =>
                    item.key === datasourcesGroups[key].key
                )
              : datasource.group.key === datasourcesGroups[key].key
          )
        }
        datasourcesGroupSorted.push(datasourceGroupConfig)
        datasourceGroupConfig.datasources.forEach((ds: IDataSource) => {
          selectedDatasources.push(ds.name)
        })
      }
    })

    //select default categorys
    Object.keys(datasourcesGroups).forEach((key: string, index: number) => {
      if (!datasourcesGroups[key].function) {
        datasourcesGroupSorted.push({
          group: datasourcesGroups[key],
          datasources: moreDataSources.filter(
            (datasource: IDataSource) =>
              (Array.isArray(datasource.group)
                ? !!datasource.group.find(
                    (item: IDatasourceGroup) =>
                      item.key === datasourcesGroups[key].key
                  )
                : datasource.group.key === datasourcesGroups[key].key) &&
              !selectedDatasources.includes(datasource.name)
          )
        })
      }
    })

    datasourcesGroupSorted.sort(function (a, b) {
      return a.group.order - b.group.order
    })

    return datasourcesGroupSorted.map((datasourcesGroup, index: number) => (
      <>
        {datasourcesGroup.datasources &&
          datasourcesGroup.datasources.length > 0 && (
            <Grid
              key={'fbmmg' + index}
              item
              {...(deviceSettings.renderMobile ? {} : { xs: 6 })}
            >
              <Typography
                key={'fbmmgt' + index}
                className={classes.datasourceGroupTitle}
                variant="h6"
                color="textSecondary"
                component="div"
                data-testid={'more_menu_group'}
              >
                <FormattedMessage
                  id={datasourcesGroup.group.key}
                  key={'fbfm' + index}
                  defaultMessage={datasourcesGroup.group.name}
                />
              </Typography>
              {datasourcesGroup.datasources?.map(
                (datasource: any, index2: number) => (
                  <MenuItem
                    tabIndex={0}
                    key={'fbmm' + index + index2}
                    classes={{
                      root: `${classes.moreMenuItem} ${
                        datasource.noResults ||
                        datasource.hasError ||
                        datasource.hasOIError
                          ? classes.noResults
                          : {}
                      }`
                    }}
                    onClick={() => {
                      setAnchorEl(null)
                      removeQueryParam(navigateFunction, 'cntx')
                      navigateFunction(
                        `${datasource.path}${urlParameter ? urlParameter : ''}`
                      )
                    }}
                    onKeyDown={(event) => {
                      if (event.key === 'Tab') {
                        event.stopPropagation()
                      }
                    }}
                  >
                    <Grid
                      key={'fbmmg' + index + index2}
                      className={classes.moreMenuItemIcon}
                    >
                      {!datasource.isLoading ? (
                        datasource.icon
                      ) : (
                        <CircularProgress
                          className={classes.loadingSpinner}
                          size={12}
                        />
                      )}
                    </Grid>
                    <LightTooltip
                      key={'fbmmlt' + index + index2}
                      title={
                        datasource.hasError || datasource.hasOIError
                          ? 'not available'
                          : datasource.noResults
                            ? 'no results'
                            : ''
                      }
                      placement="top-end"
                    >
                      <Grid key={'fbmmg2' + index + index2}>
                        <Typography
                          key={'fbmmt1' + index}
                          id={'btn-ds-' + datasource.name.toLowerCase() + '-01'}
                          className={classes.linkText}
                        >
                          <FormattedMessage
                            key={'fbmfm2' + index + index2}
                            id={datasource.name
                              .toLowerCase()
                              .replace(/\s/g, '_')}
                            defaultMessage={datasource.name}
                          />
                        </Typography>
                      </Grid>
                    </LightTooltip>
                  </MenuItem>
                )
              )}
            </Grid>
          )}
      </>
    ))
  }

  return (
    <>
      {moreDataSources.length > 0 && (
        <Link
          className={classes.moreBtn}
          data-tut="reactour__morebtn"
          component={'button'}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              setAnchorEl(event.currentTarget)
            }
          }}
        >
          <div ref={filterButtonWrapperRef}>
            <FilterButton
              icon={<MoreVertIcon fontSize={'inherit'} />}
              linkText="more"
              scope={''}
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                setAnchorEl(event.currentTarget)
              }}
            />
          </div>
        </Link>
      )}
      {Boolean(anchorEl) && (
        <Menu
          id="simple-menu"
          className={
            deviceSettings.renderMobile
              ? classes.moreMenuMobile
              : classes.moreMenu
          }
          anchorEl={anchorEl}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          open={Boolean(anchorEl)}
          onClose={() => {
            setAnchorEl(null)
          }}
          //fix width/height for tour by changing transform effect
          {...(internalBroadcast &&
          internalBroadcast.includes('openDatasourceMoreMenu')
            ? { TransitionComponent: Fade }
            : {})}
          autoFocus={true}
          disableAutoFocusItem={false}
          component={'div'}
          MenuListProps={{
            component: 'div',
            disableListWrap: true,
            autoFocus: false
          }}
        >
          <div data-tut="reactour__moremenu" tabIndex={-1}>
            <Grid
              container
              className={
                deviceSettings.renderMobile ? classes.rootMobile : classes.root
              }
              spacing={2}
            >
              {buildCategory()}
            </Grid>
          </div>
        </Menu>
      )}
    </>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    userSettings: UserSettingsStore.selectors.getUserSettings(state),
    currentfilters: FilterStore.selectors.getCurrentFilters(state),
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state),
    internalBroadcast: SettingsStore.selectors.getInternalBroadcast(state)
  }
}

export default connect(mapStateToProps)(FilterBarMoreMenu)
