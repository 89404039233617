import React, { useLayoutEffect, useRef, useState } from 'react'

export interface IMoreOnOverflowProps {
  content: string
  moreContent: JSX.Element
}

const MoreOnOverflow = (props: IMoreOnOverflowProps): JSX.Element => {
  const { content, moreContent } = props
  const [overflowing, setOverflowing] = useState(false)

  const isCssOverFlowing = (element: HTMLElement) => {
    return (
      element.scrollHeight > element.clientHeight ||
      element.scrollWidth > element.clientWidth
    )
  }

  const titleItemRef = useRef(null)
  useLayoutEffect(() => {
    const updateSize = () => {
      if (titleItemRef && titleItemRef.current) {
        const isOverflowing = isCssOverFlowing(titleItemRef.current)
        setOverflowing(isOverflowing)
      }
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [titleItemRef])

  return (
    <div
      style={{
        width: '100%',
        position: 'relative',
        whiteSpace: 'nowrap',
        display: 'flex'
      }}
    >
      <div
        ref={titleItemRef}
        style={{
          display: 'inline-block',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden'
        }}
      >
        {content}
      </div>
      {overflowing && moreContent && (
        <div
          style={{
            position: 'relative',
            display: 'inline-block',
            wordBreak: 'keep-all',
            whiteSpace: 'nowrap'
          }}
        >
          {moreContent}
        </div>
      )}
    </div>
  )
}

export default MoreOnOverflow
