import { Store } from '..'

const selectors = {
  getLeftWidget: (state: Store): any => {
    return {
      leftWidget: state.widgets.leftWidget,
      leftWidgetConfig: state.widgets.leftWidgetConfig,
      leftWidgetFetched: state.widgets.leftWidgetFetched,
      leftWidgetHasError: state.widgets.leftWidgetHasError
    }
  },
  getRightWidget: (state: Store): any => {
    return {
      rightWidget: state.widgets.rightWidget,
      rightWidgetConfig: state.widgets.rightWidgetConfig,
      rightWidgetFetched: state.widgets.rightWidgetFetched,
      rightWidgetHasError: state.widgets.rightWidgetHasError
    }
  }
}

export default selectors
