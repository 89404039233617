import { Action, AnyAction } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { Store } from '..'
import AuthStore from '../Auth'
import * as Config from 'config'
import { trackEvents, trackException } from 'utils/tracking'
import { ISearchTop10 } from './reducers'
import { renewAuthorizationToken } from 'utils/token'

export enum SearchTop10ActionTypes {
  FETCH_SEARCHTOP10_REQUEST = 'searchTop10/FETCH_SEARCHTOP10_REQUEST',
  FETCH_SEARCHTOP10_SUCCESS = 'searchTop10/FETCH_SEARCHTOP10_SUCCESS',
  FETCH_SEARCHTOP10_FAILURE = 'searchTop10/FETCH_SEARCHTOP10_FAILURE'
}

export type IFetchSearchTop10Request = Action<SearchTop10ActionTypes>

export type IFetchSearchTop10Failure = Action<SearchTop10ActionTypes>

export interface IFetchSearchTop10Success
  extends Action<SearchTop10ActionTypes> {
  payload: {
    response: any
  }
}

export type IUpdateSearchTop10Sucess = Action<SearchTop10ActionTypes>

export const fetchSearchTop10Request = (): IFetchSearchTop10Request => ({
  type: SearchTop10ActionTypes.FETCH_SEARCHTOP10_REQUEST
})

export const fetchSearchTop10Success = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  response: any
): IFetchSearchTop10Success => ({
  type: SearchTop10ActionTypes.FETCH_SEARCHTOP10_SUCCESS,
  payload: { response }
})

export const fetchSearchTop10Failure = (): IFetchSearchTop10Failure => ({
  type: SearchTop10ActionTypes.FETCH_SEARCHTOP10_FAILURE
})

export const fetchSearchTop10 = (): ThunkAction<
  Promise<void>,
  Store,
  // eslint-disable-next-line @typescript-eslint/ban-types
  {},
  AnyAction
> => {
  return async (
    // eslint-disable-next-line @typescript-eslint/ban-types
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => Store
  ) => {
    dispatch(fetchSearchTop10Request())
    try {
      trackEvents('fetchSearchTop10', {})

      const apiUrl = `${Config.APIM_BASE_URL}searchapi/gettop10&origin=KPMGFind`

      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        return
      }

      const data = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${esToken}`
        }
      })

      if (data.status !== 200) {
        dispatch(fetchSearchTop10Failure())
        trackException(
          'Error in fetching search top 10 settings',
          new Error('Error in data object after fetching search top 10')
        )
        return
      }

      const result = await data.json()

      let searchTop10Result = new Array<ISearchTop10>()

      if (result) {
        searchTop10Result = result.map((item: ISearchTop10) => {
          return {
            searchQuery: decodeURIComponent(item.searchQuery),
            count: item.count,
            function: item.function,
            country: item.country
          }
        })
      }

      dispatch(fetchSearchTop10Success(searchTop10Result))
    } catch (error) {
      dispatch(fetchSearchTop10Failure())
      trackException('Error in fetching search top 10 action', error)
    }
  }
}

export type SearchTop10Actions =
  | IFetchSearchTop10Success
  | IFetchSearchTop10Failure
  | IFetchSearchTop10Request
