import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesResults(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles(): any {
  return makeStyles((theme: Theme) => ({
    container: {
      display: 'flex',
      flexDirection: 'row',
      paddingLeft: '155px',
      flexWrap: 'nowrap',
      msOverflowY: 'hidden',
      [`${theme.breakpoints.down(1024)}`]: {
        paddingLeft: '8px',
        paddingRight: '8px',
        display: 'flex',
        wordBreak: 'break-word',
        flexDirection: 'column',
        flexWrap: 'wrap'
      }
    },
    resultsContainer: {
      overflow: 'visible !important',
      width: 632,
      flexShrink: 0,
      paddingLeft: 8,
      paddingRight: 16,
      marginRight: 36,
      [`${theme.breakpoints.down(1024)}`]: {
        paddingRight: 0,
        width: '100%',
        paddingLeft: 0,
        marginRight: 0
      }
    },
    subResultsContainer: {
      paddingLeft: 0
    },
    placeholder: {
      marginBottom: '16px'
    },
    placeholderImages: {
      marginBottom: '16px',
      paddingLeft: '8px',
      paddingRight: '8px',
      width: 'auto !important'
    },
    advertisementContainer: {
      flex: 1,
      maxWidth: 466,
      flexShrink: 0,
      display: 'flex',
      flexDirection: 'column',
      [`${theme.breakpoints.down(1350)}`]: {
        flex: '0 0 366px',
        width: 'calc(100% - 700px)'
      },
      [`${theme.breakpoints.down(1220)}`]: {
        flex: '0 0 278px'
      },
      //1024+padding 155px
      [`${theme.breakpoints.down(1179)}`]: {
        display: 'none'
      }
    },
    spacer: {
      flex: 1
    }
  }))
}
