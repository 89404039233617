import { Reducer } from 'redux'
import {
  ResultsEinsActions,
  ResultsEinsActionTypes,
  IFetchSuccess
} from './actions'

export interface IResultEinsItem {
  docId: string
  title: string
  synopsis: string
  url: string | null
  createdDate: string | null
  mimetype: string | null
  parentId: string | null
  parentTitle: string | null
  sources: Array<string> | null
}

export interface IResultsEinsStore {
  hasResultsBeenFetched: boolean
  results: Array<IResultEinsItem>
  resultsCombined: Array<IResultEinsItem>
  resultsCombinedQuery: string
  resultCount: number
  executionTime: number
  currentPage: number
}

const initialState: IResultsEinsStore = {
  hasResultsBeenFetched: false,
  resultCount: -1,
  currentPage: 1,
  results: [],
  resultsCombined: [],
  resultsCombinedQuery: '',
  executionTime: 0
}

const reducers: Reducer<IResultsEinsStore, ResultsEinsActions> = (
  state: IResultsEinsStore = initialState,
  action:
    | { type: ResultsEinsActionTypes.INITIALIZE_RESULTS_ALL; payload: any }
    | { type: 'SET_CURRENT_PAGE'; payload: any }
    | ResultsEinsActions
) => {
  switch (action.type) {
    case 'SET_CURRENT_PAGE':
      return {
        ...state,
        currentPage: action.payload || 0
      }
    case ResultsEinsActionTypes.INITIALIZE_RESULTS_ALL:
      if (!(action as any).payload) {
        return initialState
      }
      const { resultsEins } = (action as any).payload
      return {
        ...state,
        currentPage: resultsEins.currentPage || 1
      }
    case ResultsEinsActionTypes.FETCH_REQUEST:
      const { resetCombined, searchQuery } = (action as any).payload
      return {
        ...state,
        hasResultsBeenFetched: false,
        executionTime: 0,
        resultCount: -1,
        results: [],
        resultsCombined: resetCombined ? [] : state.resultsCombined,
        resultsCombinedQuery: searchQuery
      }
    case ResultsEinsActionTypes.FETCH_FAILURE:
      return {
        ...state,
        hasResultsBeenFetched: true,
        resultCount: 0
      }
    case ResultsEinsActionTypes.FETCH_SUCCESS:
      const { response } = (action as IFetchSuccess).payload
      return {
        ...state,
        hasResultsBeenFetched: true,
        executionTime: response.executionTime,
        resultCount: response.resultCount,
        results: response.results,
        currentPage: response.currentPage
      }
    case ResultsEinsActionTypes.FETCH_SUCCESS_COMBINED:
      const { response: responseCombined } = (action as IFetchSuccess).payload
      return {
        ...state,
        resultsCombined: responseCombined.results
      }
    default:
      return state
  }
}

export default reducers
