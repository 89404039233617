import React, { Dispatch } from 'react'
import { connect } from 'react-redux'
// import LazyLoad from 'react-lazyload'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link as RouterLink } from 'react-router-dom'
import { Box, Typography, IconButton } from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Store } from 'store'
import SettingsStore from 'store/Settings'
import FilterStore from 'store/Filters'
import { trackWidgetShown, trackException, trackEvents } from 'utils/tracking'
import { WidgetKey } from 'constants/widgets'
import { getStylesLeftWidget } from 'styles/contents/resultpages/widgets/LeftWidget'
import createDOMPurify from 'dompurify'
import { getUrlParameterForCurrentFilters } from 'utils/filters'
import { IKlardenkerResult } from 'components/models/KlardenkerResult'
import LeftKlardenkerWidgetResult from './LeftKlardenkerWidgetResult'
import AuthStore from 'store/Auth'
import LeftWidgetSlider from './common/LeftWidgetSlider'

export interface LeftKlardenkerWidgetProps {
  query: any
  results: IKlardenkerResult[]
}

type AllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  LeftKlardenkerWidgetProps

function LeftKlardenkerWidget(props: AllProps): JSX.Element {
  const {
    results,
    query,
    deviceSettings,
    currentFilters,
    clearTemporaryFilter,
    authToken
  } = props

  const DOMPurify = createDOMPurify(window)
  const leftWidgetClasses = getStylesLeftWidget()

  const intl = useIntl()

  React.useEffect(() => {
    trackWidgetShown({
      widgetType: WidgetKey.klardenker,
      widgetPane: 'left'
    })
  }, [])

  const maxItems = results.length > 10 ? 10 : results.length

  let slides: JSX.Element[] = []
  try {
    slides = results.slice(0, 10).map((item: IKlardenkerResult, i) => (
      // <LazyLoad offset={150}>
      <LeftKlardenkerWidgetResult
        item={item}
        index={i}
        deviceSettings={deviceSettings}
        authToken={authToken}
      />
      // </LazyLoad>
    ))
  } catch (error) {
    trackException(
      'Error in parsing slides in ResultsKlardenkerFrame.tsx',
      error
    )
  }

  if (deviceSettings.renderMobile) {
    slides.push(<Box key="mobile" style={{ flex: '0 0 10px' }} />)
  }

  if (deviceSettings.renderMobile && slides.length >= 8)
    slides.push(
      <Box className={leftWidgetClasses.showallContainer}>
        <Box className={leftWidgetClasses.showallContent}>
          <RouterLink
            tabIndex={-1} //focus IconButton
            to={`/results/klardenker/?q=${DOMPurify.sanitize(query)}`}
            style={{ textDecoration: 'none' }}
            onClick={() => {
              trackEvents('widget-see-all', {
                widgetType: WidgetKey.klardenker,
                searchTerm: DOMPurify.sanitize(query),
                wasRightClicked: false
              })

              clearTemporaryFilter()
            }}
            {...(!deviceSettings.isMobile
              ? {
                  onAuxClick: (event: any) => {
                    trackEvents('widget-see-all', {
                      widgetType: WidgetKey.klardenker,
                      searchTerm: DOMPurify.sanitize(query),
                      wasRightClicked:
                        event && event.button === 2 ? true : false
                    })
                  }
                }
              : {})}
          >
            <IconButton className={leftWidgetClasses.iconButton}>
              <ArrowForwardIcon />
            </IconButton>
          </RouterLink>
          <Typography className={leftWidgetClasses.showallMessage}>
            <FormattedMessage id="show_all" defaultMessage="Show All" />
          </Typography>
        </Box>
      </Box>
    )

  const seeAllLink = `/results/klardenker/${getUrlParameterForCurrentFilters(
    currentFilters,
    [{ key: 'page', value: '1' }]
  )}`

  return (
    <Box
      id={'con-widget-left-klardenker'}
      className={leftWidgetClasses.container}
    >
      {results && results.length > 0 && (
        <RouterLink
          to={seeAllLink}
          style={{ textDecoration: 'none' }}
          onClick={() => {
            trackEvents('widget-see-all', {
              widgetType: WidgetKey.klardenker,
              searchTerm: DOMPurify.sanitize(query),
              wasRightClicked: false
            })

            clearTemporaryFilter()
          }}
          {...(!deviceSettings.isMobile
            ? {
                onAuxClick: (event: any) => {
                  trackEvents('widget-see-all', {
                    widgetType: WidgetKey.klardenker,
                    searchTerm: DOMPurify.sanitize(query),
                    wasRightClicked: event && event.button === 2 ? true : false
                  })
                }
              }
            : {})}
        >
          <Typography
            variant="h6"
            color="primary"
            component="p"
            className={leftWidgetClasses.link}
            gutterBottom
          >
            {intl.formatMessage({
              id: 'klardenker_on',
              defaultMessage: 'Related Klardenker articles'
            })}
          </Typography>
        </RouterLink>
      )}
      <Box>
        <LeftWidgetSlider slides={slides} resultsLength={maxItems} />
      </Box>
    </Box>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state),
    currentFilters: FilterStore.selectors.getCurrentFilters(state),
    authToken: AuthStore.selectors.getAuthToken(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    clearTemporaryFilter: () =>
      dispatch(FilterStore.actions.clearTemporaryFilter())
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeftKlardenkerWidget)
