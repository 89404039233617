import React, { useState, useEffect, useRef, Dispatch } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { Box, Grid, Zoom, Link as MuiLink } from '@mui/material'
import FilterButton from './FilterButton'
import { Store } from 'store'
import SettingsStore from 'store/Settings'
import ResultMetaDataStore from 'store/ResultMetaData'
import { removeQueryParam, getCurrentScope } from 'utils/queryParams'
import UserSettingsStore from 'store/UserSettings'
import { IDeviceSetting } from 'utils/deviceSettings'
import { getStylesFilterBar } from 'styles/contents/FilterBar'
import { IDataSource } from 'constants/datasourcesConfiguration'
import FilterBarSubMenu from './FilterBarSubMenu'
import FilterBarMoreMenu from './FilterBarMoreMenu'
import { getUrlParameterForCurrentFilters } from 'utils/filters'
import FilterStore from 'store/Filters'
import { useReactRouterQueryStringInterface } from 'utils/useQueryState'

export interface FilterBarProps {
  showLocalFilter: boolean
  hasSubverticals: boolean
  deviceSettings: IDeviceSetting
}

type AllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  FilterBarProps

function FilterBar(props: AllProps) {
  const {
    userSettings,
    showLocalFilter,
    hasSubverticals,
    currentDataSources,
    resultMetaList,
    deviceSettings,
    currentfilters,
    clearTemporaryFilter,
    findConfiguration,
    internalBroadcast
  } = props

  const [showFilters, setShowFilters] = useState(showLocalFilter)
  const [pinned, setPinned] = useState<boolean>(userSettings.PinFilters)
  const [activeDataSources, setActiveDataSources] =
    useState<Array<IDataSource>>(Array<IDataSource>())
  const [moreDataSources, setMoreDataSources] =
    useState<Array<IDataSource>>(Array<IDataSource>())
  const [currentResultMeta, setCurrentResultMeta] = useState({
    resultsCount: -1,
    executionTime: 0,
    hasError: false
  })
  //const [scopeNav, setScopeNav] = useState(getCurrentScope(true))
  const scopeNav = getCurrentScope(true)
  const scope = getCurrentScope(false)
  const classes = getStylesFilterBar()
  const navigateFunction = useNavigate()

  // Bug: 984768 / readded old querystring, so navbar gets update on url changes
  // simply adding a state don't resolve the issue
  // Refactoring needed
  useReactRouterQueryStringInterface()

  const filteredDatasources = useRef(
    currentDataSources.filter((ds) => ds.enabled)
  )

  const urlParameter = getUrlParameterForCurrentFilters(currentfilters, [
    {
      key: 'page',
      value: '1'
    },
    {
      key: 'cntx',
      value: ''
    }
  ])

  //handle force open filterbar
  useEffect(() => {
    if (
      internalBroadcast &&
      internalBroadcast.includes('openFilterBar') &&
      !showFilters
    )
      setShowFilters(true)

    if (
      internalBroadcast &&
      internalBroadcast.includes('closeFilterBar') &&
      showFilters
    )
      setShowFilters(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalBroadcast])

  // calculate the navigation order
  const prepareVerticalNavigation = () => {
    if (scope) {
      const active = Array<IDataSource>()
      const more = Array<IDataSource>()

      const currentDs = filteredDatasources.current.find(
        (ds) =>
          ds.name.toLowerCase() === scope ||
          scope.startsWith(ds.name.toLowerCase())
      )

      let currentCharCount = currentDs ? currentDs.name.length : 0
      let currentDsAdded = false

      filteredDatasources.current.forEach((ds, index) => {
        if (
          !(
            ds.name.toLowerCase() === scope ||
            scope.startsWith(ds.name.toLowerCase())
          )
        ) {
          if (
            currentCharCount + ds.name.length <= 48 &&
            active.length < (currentDsAdded ? 5 : 4)
          ) {
            active.push(ds)
          } else {
            more.push(ds)
          }

          currentCharCount += ds.name.length
        } else {
          active.push(ds)
          currentDsAdded = true
        }
      })

      setActiveDataSources(active)
      setMoreDataSources(more)
    }
  }

  /**
   * Handle result counts for disabling
   */

  const getResultMetaForDataSource = (datasource: string) => {
    const resultMetaDS = datasource
    if (resultMetaList && resultMetaList.find) {
      return resultMetaList.find(
        (resultMetaItem: any) => resultMetaItem.datasource === resultMetaDS
      )
    }
  }

  const hasDatasourceNoResults = (datasource: string) => {
    const result: {
      hasResults: boolean | null
      hasError: boolean | null
      hasOIError: boolean | null
      isLoading: boolean | null
    } = {
      hasResults: null,
      hasError: null,
      hasOIError: null,
      isLoading: null
    }

    const officeSubDatasources = [
      'office365files',
      'office365mails',
      'office365calendar',
      'office365sites',
      'office365teams'
    ]

    if (datasource === 'office365') {
      let subDataSourcesToCheck: any[] = []

      subDataSourcesToCheck = officeSubDatasources

      // combine office result counts
      let isLoading = false
      let combinedResultCount = 0
      let hasFirstPageError = false
      subDataSourcesToCheck.forEach((subDataSource: string) => {
        const resultMeta = getResultMetaForDataSource(subDataSource)

        if (resultMeta) {
          if (resultMeta.resultsCount === -1) {
            // stop flickering
            isLoading = true
          }

          if (subDataSource === 'office365files' && resultMeta.hasError) {
            hasFirstPageError = true
          }

          combinedResultCount += resultMeta.resultsCount
        }
      })

      if (!isLoading) {
        result.hasError = hasFirstPageError
      }

      if (combinedResultCount === 0 && !isLoading) {
        result.hasResults = true
        result.isLoading = false
      } else if (combinedResultCount > 0 && !isLoading) {
        result.hasResults = false
        result.isLoading = false
      } else {
        result.isLoading = true
      }
    } else if (datasource === 'knowledgeexchange') {
      const kxSubDatasources = [
        'knowledgeexchangeopen',
        'knowledgeexchangecatalog'
      ]
      let subDataSourcesToCheck: any[] = []

      subDataSourcesToCheck = kxSubDatasources

      // combine kx result counts
      let isLoading = false
      let combinedResultCount = 0
      let hasFirstPageError = false
      subDataSourcesToCheck.forEach((subDataSource: string) => {
        const resultMeta = getResultMetaForDataSource(subDataSource)

        if (resultMeta) {
          if (resultMeta.resultsCount === -1) {
            // stop flickering
            isLoading = true
          }

          if (
            subDataSource === 'knowledgeexchangeopen' &&
            resultMeta.hasError
          ) {
            hasFirstPageError = true
          }

          combinedResultCount += resultMeta.resultsCount
        }
      })

      if (!isLoading) {
        result.hasError = hasFirstPageError
      }

      if (combinedResultCount === 0 && !isLoading) {
        result.hasResults = true
        result.isLoading = false
      } else if (combinedResultCount > 0 && !isLoading) {
        result.hasResults = false
        result.isLoading = false
      } else {
        result.isLoading = true
      }
    } else {
      const resultMeta = getResultMetaForDataSource(datasource)
      if (resultMeta) {
        if (resultMeta.resultsCount === -1) {
          result.isLoading = true
        } else {
          result.isLoading = false
        }

        result.hasError = resultMeta.hasError
        if (
          findConfiguration &&
          findConfiguration.QueryConfiguration &&
          findConfiguration.QueryConfiguration.refetchDataSourcesOnError
        ) {
          result.hasOIError = resultMeta.hasOIError
        }

        if (resultMeta.resultsCount === 0) {
          result.hasResults = true
        } else if (resultMeta.resultsCount > 0) {
          result.hasResults = false
        }
      }
    }

    return result
  }

  const checkStatusForAllDataSources = () => {
    //memory no results
    filteredDatasources.current.forEach((ds: any) => {
      const dsStatus = hasDatasourceNoResults(ds.name.toLowerCase())

      if (dsStatus && dsStatus.hasError !== null) {
        ds.hasError = dsStatus.hasError
      }

      if (dsStatus && dsStatus.hasResults !== null) {
        ds.noResults = dsStatus.hasResults
      }

      if (dsStatus && dsStatus.isLoading !== null) {
        ds.isLoading = dsStatus.isLoading
      }

      if (dsStatus && dsStatus.hasOIError !== null) {
        ds.hasOIError = dsStatus.hasOIError
      }
    })
  }

  /**
   * Handle the vertical navigation and datasource changes
   */

  useEffect(() => {
    // on scope change and in case the datasources have changed update the filteredDataSourceObject
    // and recalculate the vertical navigation
    filteredDatasources.current = currentDataSources
      .filter((ds) => ds.enabled)
      .map((source) => {
        return Object.assign({}, source)
      })

    prepareVerticalNavigation()

    // Update the resultCount for the fetched current datasource
    const resultMeta = getResultMetaForDataSource(scope)
    if (resultMeta) {
      setCurrentResultMeta(resultMeta)
    }

    checkStatusForAllDataSources()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultMetaList, scope, currentDataSources])

  return (
    <>
      {!hasSubverticals && scope !== '' && (
        <div className={classes.root}>
          <Grid
            container
            id={'con-tab-01'}
            className={classes.gridList}
            direction="row"
            justifyContent="flex-start"
          >
            <Grid item className={classes.iconContainer}>
              <Grid
                item
                className={classes.startIconContainer}
                data-tut="reactour__tabs"
              >
                {activeDataSources.map((datasource: any, index: number) => {
                  const active =
                    scopeNav.toLowerCase() === datasource.name.toLowerCase()

                  return (
                    <Grid item key={index}>
                      <Link
                        onClick={() => {
                          clearTemporaryFilter()
                        }}
                        to={`${datasource.path}${
                          urlParameter ? urlParameter : ''
                        }`}
                        style={{ textDecoration: 'none' }}
                      >
                        <FilterButton
                          icon={datasource.icon}
                          linkText={datasource.name}
                          noResults={
                            datasource.noResults || datasource.hasOIError
                          }
                          hasError={
                            datasource.hasError || datasource.hasOIError
                          }
                          scope={scopeNav}
                          onClick={() => {
                            removeQueryParam(navigateFunction, 'cntx')
                          }}
                          isLoading={datasource.isLoading}
                        />
                      </Link>
                      <Zoom in={active}>
                        <Box className={classes.underBar} />
                      </Zoom>
                    </Grid>
                  )
                })}
                <Grid item className={classes.endIconContainer}>
                  <Grid item>
                    {moreDataSources.length > 0 && (
                      <FilterBarMoreMenu moreDataSources={moreDataSources} />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item className={classes.seperator}></Grid>
            <Grid
              item
              data-tut="reactour__filter"
              id="tour_start_scrollto"
              className={classes.btnFilterWrapper}
            >
              <MuiLink
                component={'button'}
                style={{
                  textDecoration: 'none',
                  color: '#000',
                  marginLeft: 22
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    setShowFilters(pinned ? false : !showFilters)
                    setPinned(false)
                  }
                }}
              >
                <FilterButton
                  icon={null}
                  hasNoLogo
                  linkText="filters"
                  scope={''}
                  onClick={() => {
                    setShowFilters(pinned ? false : !showFilters)
                    setPinned(false)
                  }}
                />
              </MuiLink>
            </Grid>
          </Grid>
          <FilterBarSubMenu
            deviceSettings={deviceSettings}
            currentResultMeta={currentResultMeta}
            showLocalFilter={showLocalFilter}
            setShowFilters={setShowFilters}
            setPinned={setPinned}
            pinned={pinned}
            showFilters={showFilters}
            classes={classes}
          />
        </div>
      )}
    </>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    userSettings: UserSettingsStore.selectors.getUserSettings(state),
    currentDataSources: SettingsStore.selectors.getCurrentDataSources(state),
    resultMetaList: ResultMetaDataStore.selectors.getResultMeta(state),
    currentfilters: FilterStore.selectors.getCurrentFilters(state),
    findConfiguration: SettingsStore.selectors.getFindConfiguration(state),
    internalBroadcast: SettingsStore.selectors.getInternalBroadcast(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    clearTemporaryFilter: () =>
      dispatch(FilterStore.actions.clearTemporaryFilter())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterBar)
