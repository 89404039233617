import React, { useCallback, useEffect, useMemo } from 'react'
import { Box, Fade } from '@mui/material'
import ReactPlaceholder from 'react-placeholder'
import { connect } from 'react-redux'
import 'Placeholder.css'
import { Store } from 'store'
import ResultsResearchStore from 'store/ResultsResearch'
import ResultMetaDataStore from 'store/ResultMetaData'
import UserSettingsStore from 'store/UserSettings'
import SettingsStore from 'store/Settings'
import PageStay from 'components/HOC/PageStay'
import ErrorPage from 'components/contents/common/ErrorPage'
import { StatsResult } from 'components/contents/results/StatsResult'
import Pagination from '../Pagination'
import { trackException } from 'utils/tracking'
import 'Placeholder.css'
import { useReactRouterQueryStringInterface } from 'utils/useQueryState'
import { authorizeResultPage } from 'utils/authorization'
import createDOMPurify from 'dompurify'
import { dataSourcesTabs } from 'constants/constants'
import SearchSuggestions from 'components/contents/common/SearchSuggestions'
import RightTopWidgetContainer from './widgetscontainer/RightTopWidgetContainer'
import { useIntl } from 'react-intl'
import LeftWidgetContainer from './widgetscontainer/LeftWidgetContainer'
import RightWidgetContainer from './widgetscontainer/RightWidgetContainer'
import { getStylesResults } from 'styles/contents/resultpages/Results'
import { useFilter } from 'utils/filters'
import { KPMGFindGlobalVariables } from 'store/KPMGFindGlobalVariables'
import { useLocation } from 'react-router-dom'

export interface ResultsResearchProps {
  appliedFilters: any
}

type AllProps = ReturnType<typeof mapStateToProps> & ResultsResearchProps

function ResultsResearch(props: AllProps) {
  const {
    results,
    resultsCount,
    hasResultsBeenFetched,
    hasErrorStore,
    userSettings,
    deviceSettings
  } = props
  const DOMPurify = createDOMPurify(window)
  const resultClasses = getStylesResults()
  const { getQueryString } = useReactRouterQueryStringInterface()
  const intl = useIntl()
  const queryStringFromUrl = getQueryString()

  const [currentPage] = useFilter('page', '1')
  const [searchQuery] = useFilter('q')
  const location = useLocation()

  useEffect(() => {
    KPMGFindGlobalVariables.setCurrentTab(dataSourcesTabs.research)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setup = useCallback(() => {
    try {
      authorizeResultPage(
        'Reasearch',
        userSettings,
        deviceSettings,
        DOMPurify.sanitize(location && location.search ? location.search : '')
      )
    } catch (error) {
      trackException('Error in fetching results in ResultsResearch.tsx', error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryStringFromUrl])

  useEffect(() => {
    setup()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryStringFromUrl])

  const sliceStartIndex = (Number(currentPage) - 1) * 20
  const sliceEndIndex = Number(currentPage) * 20

  const returnAllItems = (type: string) => {
    // If no results are present, make sure to not render the empty result frames
    if (!results || !results.titles || results.titles.length < 1) {
      return []
    }

    const { titles, imageUrls, resultBodies, resultUrls } = results

    const items = []

    try {
      const firstResults = titles.length < 4 ? titles.length : 3

      if (type === 'first') {
        for (let i = sliceStartIndex; i < sliceStartIndex + firstResults; i++) {
          items.push(
            <StatsResult
              image={DOMPurify.sanitize(imageUrls[i])}
              title={DOMPurify.sanitize(titles[i])}
              body={DOMPurify.sanitize(resultBodies[i])}
              url={DOMPurify.sanitize(resultUrls[i])}
              key={i}
              index={i}
              searchQuery={DOMPurify.sanitize(searchQuery)}
              deviceSettings={deviceSettings}
            />
          )
        }
      } else {
        for (let i = sliceStartIndex + firstResults; i < sliceEndIndex; i++) {
          if (i < titles.length) {
            items.push(
              <StatsResult
                image={DOMPurify.sanitize(imageUrls[i])}
                title={DOMPurify.sanitize(titles[i])}
                body={DOMPurify.sanitize(resultBodies[i])}
                url={DOMPurify.sanitize(resultUrls[i])}
                key={i}
                index={i}
                searchQuery={DOMPurify.sanitize(searchQuery)}
                deviceSettings={deviceSettings}
              />
            )
          }
        }
      }
    } catch (error) {
      trackException('Error returning all items in ResultsResearch.tsx', error)
    }
    return items
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const firstItems = useMemo(() => returnAllItems('first'), [results, intl])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const restItems = useMemo(() => returnAllItems('rest'), [results, intl])

  return (
    <Box>
      {!hasErrorStore && !!searchQuery && (
        <Box className={resultClasses.container}>
          <Box
            id={'con-resultvertical-01'}
            className={resultClasses.resultsContainer}
          >
            <SearchSuggestions
              wildCardActive={false}
              resultCount={resultsCount}
              resultsFetchend={hasResultsBeenFetched}
            />
            <ReactPlaceholder
              ready={hasResultsBeenFetched ? hasResultsBeenFetched : false}
              type="text"
              rows={4}
              showLoadingAnimation={true}
              className={resultClasses.placeholder}
            >
              <Fade
                in={hasResultsBeenFetched ? hasResultsBeenFetched : false}
                timeout={600}
              >
                <Box id={'con-resultvertical-section-01'}>{firstItems}</Box>
              </Fade>
            </ReactPlaceholder>
            {Number(currentPage) === 1 && (
              <LeftWidgetContainer
                resultCount={resultsCount}
                hasResultsBeenFetched={hasResultsBeenFetched}
              />
            )}
            <ReactPlaceholder
              ready={hasResultsBeenFetched}
              type="text"
              rows={4}
              showLoadingAnimation={true}
              className={resultClasses.placeholder}
            >
              <Fade in={hasResultsBeenFetched} timeout={600}>
                <Box id={'con-resultvertical-section-02'}>
                  {restItems}
                  {resultsCount != null && resultsCount > 0 && (
                    <Pagination
                      {...props}
                      totalRecords={resultsCount}
                      pageLimit={20}
                      pageNeighbours={4}
                    />
                  )}
                </Box>
              </Fade>
            </ReactPlaceholder>
            <ReactPlaceholder
              ready={hasResultsBeenFetched ? hasResultsBeenFetched : false}
              type="text"
              rows={4}
              showLoadingAnimation={true}
              className={resultClasses.placeholder}
            >
              <Box />
            </ReactPlaceholder>
            <ReactPlaceholder
              ready={hasResultsBeenFetched ? hasResultsBeenFetched : false}
              type="text"
              rows={4}
              showLoadingAnimation={true}
              className={resultClasses.placeholder}
            >
              <Box />
            </ReactPlaceholder>
          </Box>
          <Box className={resultClasses.advertisementContainer}>
            <RightTopWidgetContainer
              searchQuery={searchQuery}
              scope={'research'}
            />
            <RightWidgetContainer />
          </Box>
        </Box>
      )}
      {hasErrorStore && <ErrorPage dataSource="research" />}
    </Box>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    results: ResultsResearchStore.selectors.getResults(state),
    resultsCount: ResultsResearchStore.selectors.getResultCount(state),
    executionTime: ResultsResearchStore.selectors.getExecutionTime(state),
    hasResultsBeenFetched:
      ResultsResearchStore.selectors.hasResultsBeenFetched(state),
    hasErrorStore: ResultMetaDataStore.selectors.getDataSourceHasError(
      state,
      'research'
    ),
    userSettings: UserSettingsStore.selectors.getUserSettings(state),
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state)
  }
}

export default PageStay(connect(mapStateToProps)(ResultsResearch))
