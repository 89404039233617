import { Store } from '..'
import { IResultMetaData } from './reducers'

const selectors = {
  getResultMeta: (state: Store): any[] => state.resultMetaData.resultMeta,
  getResultMetaByDataSource: (
    state: Store,
    dataSource: string
  ): IResultMetaData => {
    if (!state.resultMetaData.resultMeta) {
      return {
        datasource: dataSource,
        resultsCount: -1,
        executionTime: -1,
        hasError: false,
        hasOIError: false,
        error: null
      }
    }

    const resultObject = state.resultMetaData.resultMeta.find(
      (resultMetaItem: any) => resultMetaItem.datasource === dataSource
    )

    if (!resultObject) {
      return {
        datasource: dataSource,
        resultsCount: -1,
        executionTime: -1,
        hasError: false,
        hasOIError: false,
        error: null
      }
    }

    return resultObject
  },
  getDataSourceHasError: (state: Store, dataSource: string): boolean => {
    if (!state.resultMetaData.resultMeta) {
      return false
    }

    const resultObject = state.resultMetaData.resultMeta.find(
      (resultMetaItem: any) => resultMetaItem.datasource === dataSource
    )

    if (!resultObject) {
      return false
    }

    return resultObject.hasError
  }
}

export default selectors
