import { AdResult } from 'components/models/AdResult'
import { trackException } from 'utils/tracking'

/**
 * Gets the Ad to be displayed in the Ads Top Widget
 * @param adsTranslations Ads retrieved from Cosmos DB based on current language
 * @param searchTerm The search query
 * @param country The user country from user settings
 */
export function getAd(
  adsTranslations: AdResult[],
  searchTerm: string
): Promise<AdResult | null> {
  return new Promise(async (resolve) => {
    try {
      replaceSearchTermPlaceholder(adsTranslations, searchTerm)

      resolve(adsTranslations.length > 0 ? adsTranslations[0] : null)
    } catch (error) {
      trackException('Error getting ads', error)
      resolve(null)
    }
  })
}

/**
 * Replaces the search term placeholder in urls (links)
 *
 * @param contentForLanguage AdResult list
 * @param searchQuery Ads config
 */
function replaceSearchTermPlaceholder(
  contentForLanguage: AdResult[],
  searchTerm: string
) {
  contentForLanguage.forEach((item) => {
    if (item.link)
      searchTerm
        ? (item.link = item.link.replace(
            '{searchTerm}',
            encodeURIComponent(searchTerm)
          ))
        : (item.link = item.link.replace('{searchTerm}', ''))
  })
}
