import { useEffect, useRef } from 'react'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useIntervalEffect(callBackFunction: any, delay: number): void {
  const savedCallBackFunction = useRef<any>()

  useEffect(() => {
    savedCallBackFunction.current = callBackFunction
  }, [callBackFunction])

  useEffect(() => {
    function tick() {
      if (
        savedCallBackFunction.current &&
        typeof savedCallBackFunction.current == 'function'
      ) {
        savedCallBackFunction.current()
      }
    }

    if (delay) {
      const id = setInterval(tick, delay)
      return () => {
        clearInterval(id)
      }
    }
  }, [callBackFunction, delay])
}
