import React, { useState, Dispatch, useEffect, CSSProperties } from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@mui/material'
import Grid from '@mui/material/Grid'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import ClipLoader from 'react-spinners/ClipLoader'
import { useIntl } from 'react-intl'
import { Store } from 'store'
import ResultsOneIntranetStore from 'store/ResultsOneIntranet'
import { dateFormatOptions } from 'constants/constants'
import ImproveResults from './ImproveResults'
import * as seoCheckerUtils from 'utils/seoChecker'
import { hashString } from 'utils/string'
import 'Placeholder.css'
import { getStylesSEOChecker } from 'styles/contents/SEOChecker'
import {
  getMappedTypeString,
  ISeoCheckerData,
  SEOCheckerTypes
} from 'utils/seoChecker'
import createDOMPurify from 'dompurify'
import { IDeviceSetting } from 'utils/deviceSettings'
import SettingsStore from 'store/Settings'

export interface SEOCheckerProps {
  isImproveResultsModalOpen: boolean
  seoCheckerModalData: ISeoCheckerData
  seoCheckerModalSearch: string
  setImproveResultsModal: Dispatch<boolean>
  setSeoCheckerModal: Dispatch<boolean>
  setSEOCheckerModalData: Dispatch<ISeoCheckerData>
  open: boolean
  onClose: () => void
}

type AllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  SEOCheckerProps

function SEOChecker(props: AllProps) {
  const {
    isImproveResultsModalOpen,
    seoCheckerModalData,
    seoCheckerModalSearch,
    setImproveResultsModal,
    setSeoCheckerModal,
    setSEOCheckerModalData,
    open,
    onClose,
    fetchResultsAllSeo,
    resultsSeo,
    hasResultsSeoBeenFetched,
    resultSeoCount,
    deviceSettings
  } = props
  const DOMPurify = createDOMPurify(window)
  const [seoData, setSeoData] = useState<ISeoCheckerData>({
    url: '',
    seoType: null,
    uniqueId: null,
    siteId: null
  })
  const [contentSearchTerms, setContentSearchTerms] = useState('')
  const [termMatches, setTermMatches] = useState('')
  const [titleQuality, setTitleQuality] = useState('')
  const [abstractMatches, setAbstractMatches] = useState('')
  const [abstractQuality, setAbstractQuality] = useState('')
  const [modifiedDate, setModifiedDate] = useState('')
  const [fileNameMatches, setFileNameMatches] = useState('')
  const [updateLink, setUpdateLink] = useState('')
  const [validationMessage, setValidationMessage] = useState('')
  const [randomWaitingTime, setRandomWaitingTime] = useState(200)
  const [allLoaded, setAllLoaded] = useState('')

  const classes = getStylesSEOChecker()

  const intl = useIntl()

  const clipLoaderCSS: CSSProperties = {
    marginLeft: 14,
    marginTop: 5
  }

  const validateInputs = () => {
    setEmptyStateProperties()
    if (open === true) {
      let errorMessage = ''

      if (
        (!seoData.url || !seoData.url.startsWith('https://')) &&
        (seoData.seoType === SEOCheckerTypes.OnlineFile ||
          seoData.seoType === SEOCheckerTypes.OnlinePage)
      ) {
        errorMessage = `Content URL must point to a valid online ${getMappedTypeString(
          seoData.seoType
        )}.`
      } else if (
        (!seoData.url || !seoData.url.startsWith('https://')) &&
        seoData.seoType === SEOCheckerTypes.OnlineWeb
      ) {
        errorMessage = 'Content URL must point to a valid online Site.'
      } else if (!seoData.url || !seoData.url.startsWith('https://')) {
        errorMessage = 'Content URL must point to a valid online result.'
      }

      if (contentSearchTerms === '') {
        if (errorMessage === '') {
          errorMessage = 'Please provide any search term.'
        } else {
          errorMessage += ' Please provide any search term.'
        }
      }

      setValidationMessage(errorMessage)
      if (errorMessage === '') {
        const rWaitingTime = Math.abs(
          hashString(seoData.url + contentSearchTerms) % 700
        )
        setRandomWaitingTime(rWaitingTime)
        fetchResultsAllSeo(seoData, deviceSettings)
      } else {
        setEmptyStateProperties()
      }
    }
  }

  const redirectToUpdate = () => {
    window.open(updateLink, '_blank')
  }

  const isResultsSeoEmpty = () => {
    if (!resultsSeo) {
      return false
    } else {
      if (
        resultsSeo.date === '' &&
        resultsSeo.fileName === '' &&
        resultsSeo.lastModifiedDate === '' &&
        resultsSeo.resultBody === '' &&
        resultsSeo.resultUrl === '' &&
        resultsSeo.title === '' &&
        resultsSeo.updateLink === ''
      ) {
        return true
      } else {
        return false
      }
    }
  }

  const setEmptyStateProperties = () => {
    setTermMatches('')
    setTitleQuality('')
    setAbstractMatches('')
    setAbstractQuality('')
    setModifiedDate('')
    setFileNameMatches('')
    setUpdateLink('')
    setAllLoaded('')
  }

  useEffect(() => {
    if (open === false) {
      setEmptyStateProperties()
      setValidationMessage('')
    } else {
      setSeoData({
        url: seoCheckerModalData.url,
        seoType: seoCheckerModalData.seoType,
        uniqueId: seoCheckerModalData.uniqueId
          ? seoCheckerModalData.uniqueId
          : '',
        siteId: seoCheckerModalData.siteId ? seoCheckerModalData.siteId : ''
      })
      setContentSearchTerms(seoCheckerModalSearch ? seoCheckerModalSearch : '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  useEffect(() => {
    if (
      !resultsSeo ||
      (hasResultsSeoBeenFetched === true && resultSeoCount === 0)
    ) {
      setValidationMessage(
        'There has been an error retrieving the item. Please check the provided URL'
      )
    } else {
      if (!isResultsSeoEmpty()) {
        setAllLoaded('false')
        const searchTerms: string[] = contentSearchTerms.split(',')
        if (
          searchTerms &&
          searchTerms.length > 0 &&
          searchTerms.indexOf('') === -1
        ) {
          // 1- Check if title contains search terms
          const tMatches: string = seoCheckerUtils.titleContainsTerms(
            searchTerms,
            DOMPurify.sanitize(resultsSeo.title)
          )
          setTermMatches(tMatches)
          // 2- Check title quality
          setTimeout(function () {
            const tQuality: string = seoCheckerUtils.titleQuality(
              DOMPurify.sanitize(resultsSeo.title)
            )
            setTitleQuality(tQuality)

            // 3-  Check for terms matches within Abstract
            setTimeout(function () {
              const absMatches: string = seoCheckerUtils.abstractContainsTerms(
                searchTerms,
                DOMPurify.sanitize(resultsSeo.resultBody)
              )
              setAbstractMatches(absMatches)

              // 4- Abstract quality
              setTimeout(function () {
                const absQuality: string = seoCheckerUtils.abstractQuality(
                  DOMPurify.sanitize(resultsSeo.resultBody),
                  DOMPurify.sanitize(resultsSeo.title),
                  seoData.seoType
                )
                setAbstractQuality(absQuality)

                // 5- Modified date quality
                setTimeout(function () {
                  if (
                    resultsSeo.lastModifiedDate &&
                    resultsSeo.lastModifiedDate !== ''
                  ) {
                    const docuDate = new Date(
                      DOMPurify.sanitize(resultsSeo.lastModifiedDate)
                    )
                    const modDate: string = seoCheckerUtils.dateQuality(
                      DOMPurify.sanitize(resultsSeo.lastModifiedDate),
                      intl.formatDate(docuDate, dateFormatOptions)
                    )
                    setModifiedDate(modDate)
                  }

                  // 6- Check for term matches within FileName
                  setTimeout(function () {
                    const fNameMatches: string =
                      seoCheckerUtils.fileNameContainsTerms(
                        searchTerms,
                        DOMPurify.sanitize(resultsSeo.fileName)
                      )
                    setFileNameMatches(fNameMatches)
                    setAllLoaded('true')
                    setTimeout(function () {
                      setUpdateLink(DOMPurify.sanitize(resultsSeo.updateLink))
                    }, randomWaitingTime)
                  }, randomWaitingTime)
                }, randomWaitingTime)
              }, randomWaitingTime)
            }, randomWaitingTime)
          }, randomWaitingTime)
        } else {
          setEmptyStateProperties()
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasResultsSeoBeenFetched])

  return (
    <>
      <Dialog open={open} onClose={onClose} className={classes.dialogContainer}>
        <DialogTitle className={classes.dialogTitle}>
          <Grid container direction="column" justifyContent="center">
            <Grid item className={classes.dialogTitle}>
              Intranet Search Engine Optimization
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            spacing={3}
            style={{ marginTop: 8 }}
          >
            <Grid item>
              <span className={classes.dialogContentSpan}>
                <strong>Check</strong> how well your document or page is
                optimized for the Intranet Search.
              </span>
              <br />
              <br />
              <span className={classes.dialogContentSmall}>
                Enter your URL and add search terms you would like your content
                to be found with.
              </span>
              <br />
              <br />
              <TextField
                id="content-url"
                className={classes.textURL}
                label="URL to your content"
                defaultValue={seoCheckerModalData.url}
                onChange={(event) => {
                  setSeoData({
                    url: event.target.value,
                    seoType: seoData.seoType,
                    uniqueId: null,
                    siteId: null
                  })
                }}
                InputProps={{
                  classes: { input: classes.inputFields }
                }}
              />
              <br />
              <br />
              <TextField
                id="content-search-terms"
                className={classes.textTerms}
                label="Search term(s)"
                defaultValue={
                  seoCheckerModalSearch ? seoCheckerModalSearch : ''
                }
                onChange={(event) => setContentSearchTerms(event.target.value)}
                InputProps={{
                  classes: { input: classes.inputFields }
                }}
              />
              <br />
              <br />
              <Button
                onClick={validateInputs}
                color="primary"
                variant="contained"
                className={classes.formButton}
              >
                Check
              </Button>
            </Grid>
            {validationMessage !== '' && (
              <div className={classes.itemImage}>
                <ErrorOutlineIcon color="error" />
                <div className={classes.validationMessage}>
                  {validationMessage}
                </div>
              </div>
            )}
            <Grid
              item
              className={`${classes.gridItem} ${classes.sectionStarter}`}
            >
              {termMatches !== '' && (
                <>
                  <span className={classes.dialogContentSpan}>
                    <strong>Results for your content:</strong>
                  </span>
                  <br />
                  <br />
                  {termMatches === 'all' && (
                    <Grid container direction="row">
                      <Grid item>
                        <CheckCircleOutlineIcon className={classes.greenIcon} />
                      </Grid>
                      <Grid item className={classes.contentText}>
                        <span className={classes.dialogContentSpan}>
                          Title contains search terms
                        </span>
                      </Grid>
                    </Grid>
                  )}
                  {termMatches === 'some' && (
                    <Grid container direction="row">
                      <Grid item>
                        <RemoveCircleOutlineOutlinedIcon
                          className={classes.yellowIcon}
                        />
                      </Grid>
                      <Grid item className={classes.contentText}>
                        <span className={classes.dialogContentSpan}>
                          Title contains search terms
                        </span>
                      </Grid>
                      <Grid item>
                        <span
                          className={`${classes.dialogContentSpan} ${classes.yellowInfo}`}
                        >
                          Some terms missing
                        </span>
                      </Grid>
                    </Grid>
                  )}
                  {termMatches === 'none' && (
                    <Grid container direction="row">
                      <Grid item>
                        <ErrorOutlineIcon className={classes.redIcon} />
                      </Grid>
                      <Grid item className={classes.contentText}>
                        <span className={classes.dialogContentSpan}>
                          Title contains search terms
                        </span>
                      </Grid>
                      <Grid item>
                        <span
                          className={`${classes.dialogContentSpan} ${classes.redInfo}`}
                        >
                          All terms missing
                        </span>
                      </Grid>
                    </Grid>
                  )}
                </>
              )}
              {titleQuality !== '' && (
                <>
                  {titleQuality === 'Good' ? (
                    <Grid container direction="row">
                      <Grid item>
                        <CheckCircleOutlineIcon className={classes.greenIcon} />
                      </Grid>
                      <Grid item className={classes.contentText}>
                        <span className={classes.dialogContentSpan}>
                          Title quality
                        </span>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container direction="row">
                      <Grid item>
                        <RemoveCircleOutlineOutlinedIcon
                          className={classes.yellowIcon}
                        />
                      </Grid>
                      <Grid item className={classes.contentText}>
                        <span className={classes.dialogContentSpan}>
                          Title quality
                        </span>
                      </Grid>
                      <Grid item>
                        <span
                          className={`${classes.dialogContentSpan} ${classes.yellowInfo}`}
                        >
                          {titleQuality}
                        </span>
                      </Grid>
                    </Grid>
                  )}
                </>
              )}
              {abstractMatches !== '' && (
                <>
                  {abstractMatches === 'na' && (
                    <Grid container direction="row">
                      <Grid item>
                        <ErrorOutlineIcon className={classes.redIcon} />
                      </Grid>
                      <Grid item className={classes.contentText}>
                        <span className={classes.dialogContentSpan}>
                          {`${
                            seoData.seoType === SEOCheckerTypes.OnlineFile
                              ? 'Custom'
                              : ''
                          } Description contains search terms`}
                        </span>
                      </Grid>
                      <Grid item>
                        <span
                          className={`${classes.dialogContentSpan} ${classes.redInfo}`}
                        >
                          {`No ${
                            seoData.seoType === SEOCheckerTypes.OnlineFile
                              ? 'custom'
                              : ''
                          } description`}
                        </span>
                      </Grid>
                    </Grid>
                  )}
                  {abstractMatches === 'all' && (
                    <Grid container direction="row">
                      <Grid item>
                        <CheckCircleOutlineIcon className={classes.greenIcon} />
                      </Grid>
                      <Grid item className={classes.contentText}>
                        <span className={classes.dialogContentSpan}>
                          {`${
                            seoData.seoType === SEOCheckerTypes.OnlineFile
                              ? 'Custom'
                              : ''
                          } Description contains search terms`}
                        </span>
                      </Grid>
                    </Grid>
                  )}
                  {abstractMatches === 'some' && (
                    <Grid container direction="row">
                      <Grid item>
                        <RemoveCircleOutlineOutlinedIcon
                          className={classes.yellowIcon}
                        />
                      </Grid>
                      <Grid item className={classes.contentText}>
                        <span className={classes.dialogContentSpan}>
                          {`${
                            seoData.seoType === SEOCheckerTypes.OnlineFile
                              ? 'Custom'
                              : ''
                          } Description contains search terms`}
                        </span>
                      </Grid>
                      <Grid item>
                        <span
                          className={`${classes.dialogContentSpan} ${classes.yellowInfo}`}
                        >
                          Some terms missing
                        </span>
                      </Grid>
                    </Grid>
                  )}
                  {abstractMatches === 'none' && (
                    <Grid container direction="row">
                      <Grid item>
                        <ErrorOutlineIcon className={classes.redIcon} />
                      </Grid>
                      <Grid item className={classes.contentText}>
                        <span className={classes.dialogContentSpan}>
                          {`${
                            seoData.seoType === SEOCheckerTypes.OnlineFile
                              ? 'Custom'
                              : ''
                          } Description contains search terms`}
                        </span>
                      </Grid>
                      <Grid item>
                        <span
                          className={`${classes.dialogContentSpan} ${classes.redInfo}`}
                        >
                          All terms missing
                        </span>
                      </Grid>
                    </Grid>
                  )}
                </>
              )}
              {abstractQuality !== '' && (
                <>
                  {abstractQuality === 'Good' && (
                    <Grid container direction="row">
                      <Grid item>
                        <CheckCircleOutlineIcon className={classes.greenIcon} />
                      </Grid>
                      <Grid item className={classes.contentText}>
                        <span className={classes.dialogContentSpan}>
                          {`${
                            seoData.seoType === SEOCheckerTypes.OnlineFile
                              ? 'Custom'
                              : ''
                          } Description quality (length, content)`}
                        </span>
                      </Grid>
                    </Grid>
                  )}
                  {abstractQuality.startsWith('Short') && (
                    <Grid container direction="row">
                      <Grid item>
                        <RemoveCircleOutlineOutlinedIcon
                          className={classes.yellowIcon}
                        />
                      </Grid>
                      <Grid item className={classes.contentText}>
                        <span className={classes.dialogContentSpan}>
                          {`${
                            seoData.seoType === SEOCheckerTypes.OnlineFile
                              ? 'Custom'
                              : ''
                          } Description quality (length, content)`}
                        </span>
                      </Grid>
                      <Grid item>
                        <span
                          className={`${classes.dialogContentSpan} ${classes.yellowInfo}`}
                        >
                          {abstractQuality}
                        </span>
                      </Grid>
                    </Grid>
                  )}
                  {(abstractQuality.startsWith('Too') ||
                    abstractQuality.startsWith('Same') ||
                    abstractQuality.startsWith('No')) && (
                    <Grid container direction="row">
                      <Grid item>
                        <ErrorOutlineIcon className={classes.redIcon} />
                      </Grid>
                      <Grid item className={classes.contentText}>
                        <span className={classes.dialogContentSpan}>
                          {`${
                            seoData.seoType === SEOCheckerTypes.OnlineFile
                              ? 'Custom'
                              : ''
                          } Description quality (length, content)`}
                        </span>
                      </Grid>
                      <Grid item>
                        <span
                          className={`${classes.dialogContentSpan} ${classes.redInfo}`}
                        >
                          {abstractQuality}
                        </span>
                      </Grid>
                    </Grid>
                  )}
                </>
              )}
              {modifiedDate !== '' &&
                seoData.seoType !== SEOCheckerTypes.OnlineWeb && (
                  <>
                    {modifiedDate !== '' &&
                      modifiedDate.startsWith('green') && (
                        <Grid container direction="row">
                          <Grid item>
                            <CheckCircleOutlineIcon
                              className={classes.greenIcon}
                            />
                          </Grid>
                          <Grid item className={classes.contentText}>
                            <span className={classes.dialogContentSpan}>
                              {`${getMappedTypeString(
                                seoData.seoType
                              )} was modified within last three years`}
                            </span>
                          </Grid>
                        </Grid>
                      )}
                    {modifiedDate !== '' &&
                      modifiedDate.startsWith('yellow') && (
                        <Grid container direction="row">
                          <Grid item>
                            <RemoveCircleOutlineOutlinedIcon
                              className={classes.yellowIcon}
                            />
                          </Grid>
                          <Grid item className={classes.contentText}>
                            <span className={classes.dialogContentSpan}>
                              {`${getMappedTypeString(
                                seoData.seoType
                              )} was modified within last three years`}
                            </span>
                          </Grid>
                          <Grid item>
                            <span
                              className={`${classes.dialogContentSpan} ${classes.yellowInfo}`}
                            >
                              {modifiedDate.replace('yellow', '')}
                            </span>
                          </Grid>
                        </Grid>
                      )}
                    {modifiedDate !== '' && modifiedDate.startsWith('red') && (
                      <Grid container direction="row">
                        <Grid item>
                          <ErrorOutlineIcon className={classes.redIcon} />
                        </Grid>
                        <Grid item className={classes.contentText}>
                          <span className={classes.dialogContentSpan}>
                            {`${getMappedTypeString(
                              seoData.seoType
                            )} was modified within last three years`}
                          </span>
                        </Grid>
                        <Grid item>
                          <span
                            className={`${classes.dialogContentSpan} ${classes.redInfo}`}
                          >
                            {modifiedDate.replace('red', '')}
                          </span>
                        </Grid>
                      </Grid>
                    )}
                  </>
                )}
              {fileNameMatches !== '' &&
                seoData.seoType !== SEOCheckerTypes.OnlinePage &&
                seoData.seoType !== SEOCheckerTypes.OnlineWeb && (
                  <>
                    {fileNameMatches === 'all' && (
                      <Grid container direction="row">
                        <Grid item>
                          <CheckCircleOutlineIcon
                            className={classes.greenIcon}
                          />
                        </Grid>
                        <Grid item className={classes.contentText}>
                          <span className={classes.dialogContentSpan}>
                            File name contains search terms
                          </span>
                        </Grid>
                      </Grid>
                    )}
                    {fileNameMatches === 'some' && (
                      <Grid container direction="row">
                        <Grid item>
                          <RemoveCircleOutlineOutlinedIcon
                            className={classes.yellowIcon}
                          />
                        </Grid>
                        <Grid item className={classes.contentText}>
                          <span className={classes.dialogContentSpan}>
                            File name contains search terms
                          </span>
                        </Grid>
                        <Grid item>
                          <span
                            className={`${classes.dialogContentSpan} ${classes.yellowInfo}`}
                          >
                            Some terms missing
                          </span>
                        </Grid>
                      </Grid>
                    )}
                    {fileNameMatches === 'none' && (
                      <Grid container direction="row">
                        <Grid item>
                          <ErrorOutlineIcon className={classes.redIcon} />
                        </Grid>
                        <Grid item className={classes.contentText}>
                          <span className={classes.dialogContentSpan}>
                            File name contains search terms
                          </span>
                        </Grid>
                        <Grid item>
                          <span
                            className={`${classes.dialogContentSpan} ${classes.redInfo}`}
                          >
                            All terms missing
                          </span>
                        </Grid>
                      </Grid>
                    )}
                  </>
                )}
            </Grid>
            {allLoaded === 'false' && (
              <ClipLoader
                cssOverride={clipLoaderCSS}
                size={'1em'}
                color={'#005eb8'}
                loading={true}
              />
            )}
            {updateLink !== '' && (
              <Grid
                item
                className={`${classes.gridItem} ${classes.sectionStarter}`}
              >
                <span
                  className={classes.dialogContentSpan}
                  style={{ marginRight: '34px' }}
                >
                  {`Are you the owner of the ${getMappedTypeString(
                    seoData.seoType
                  )}? Update now!`}
                </span>
                <HelpOutlineOutlinedIcon className={classes.questionIcon} />
                <span
                  className={classes.dialogContentHowToSpan}
                  onClick={() => {
                    setImproveResultsModal(true)
                  }}
                >
                  How to optimize
                </span>
                <br />
                <br />
                <Button
                  onClick={redirectToUpdate}
                  color="primary"
                  variant="contained"
                  className={classes.formButton}
                >
                  Update
                </Button>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <span>This tool is only available in English language.</span>
          <Button
            onClick={onClose}
            color="primary"
            className={classes.closeButton}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <ImproveResults
        open={isImproveResultsModalOpen}
        isSEOCheckerModalOpen={open}
        setSeoCheckerModal={setSeoCheckerModal}
        setSEOCheckerModalData={setSEOCheckerModalData}
        onClose={() => {
          setImproveResultsModal(false)
        }}
      />
    </>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    resultsSeo: ResultsOneIntranetStore.selectors.getResultsSeo(state),
    resultSeoCount: ResultsOneIntranetStore.selectors.getResultSeoCount(state),
    hasResultsSeoBeenFetched:
      ResultsOneIntranetStore.selectors.hasResultsSeoBeenFetched(state),
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    fetchResultsAllSeo: (
      seoData: ISeoCheckerData,
      deviceSettings: IDeviceSetting
    ) =>
      dispatch(
        ResultsOneIntranetStore.actions.fetchResultsAllSeo(
          seoData,
          deviceSettings
        )
      )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SEOChecker)
