import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesSharePointShareDialog(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles() {
  return makeStyles((theme: Theme) => ({
    modalScrollableContent: {
      overflowY: 'initial',

      [`${theme.breakpoints.up(700)}`]: {
        maxHeight: '650px'
      }
    }
  }))
}
