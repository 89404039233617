import { IAADState } from 'store/Auth/reducers'
import { IDeviceSetting } from './deviceSettings'
import { getGraphAuthToken, renewAuthorizationToken } from './token'
import { trackException } from './tracking'
import * as Config from 'config'
import { emptyGuid } from 'constants/constants'

export async function getPicture(
  originalPictureUrl: string | undefined,
  deviceSettings: IDeviceSetting,
  aadInfo: IAADState
): Promise<string> {
  try {
    let graphToken = ''
    // Get and check authentication token
    if (Config.LOCAL_DEVELOPMENT.toString() === 'false') {
      graphToken = await getGraphAuthToken(aadInfo.instance, aadInfo.accounts)
    }

    // Get and check authentication token
    const esToken = await renewAuthorizationToken(
      aadInfo.accessToken,
      aadInfo.instance,
      aadInfo.accounts
    )

    if (originalPictureUrl && originalPictureUrl.indexOf('_api/v2.1') >= 0) {
      const urlEncoded = encodeURIComponent(
        originalPictureUrl.split('_api/v2.1')[1]
      )
      const graphAPIUrl = `${Config.APIM_BASE_URL}msgraphapi/getthumbnail?url=${urlEncoded}`

      const pictureResponse = await fetch(graphAPIUrl, {
        method: 'GET',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${graphToken}`,
          JWTToken: esToken
        }
      })

      if (!pictureResponse || !pictureResponse.ok) {
        return originalPictureUrl
      } else {
        const pictureBlob = await pictureResponse.blob()
        const pictureUrl = URL.createObjectURL(pictureBlob)
        return pictureUrl
      }
    }
  } catch (error) {
    trackException('Error loading video picture thumbnail', error)
  }

  return originalPictureUrl ? originalPictureUrl : ''
}

export async function getPreviewThumbnail(
  originalPictureUrl: string | undefined,
  siteId: string,
  webId: string,
  listId: string,
  listItemId: string,
  deviceSettings: IDeviceSetting,
  aadInfo: IAADState
): Promise<string> {
  try {
    let graphAPIUrl = `${Config.APIM_BASE_URL}msgraphapi/getitemthumbnail?siteId=${siteId}&listId=${listId}&itemId=${listItemId}&size=large`

    if (webId && webId !== emptyGuid) {
      graphAPIUrl += `&webId=${webId}`
    }
    // Get and check authentication token
    const esToken = await renewAuthorizationToken(
      aadInfo.accessToken,
      aadInfo.instance,
      aadInfo.accounts
    )

    const thumbnailResponse = await fetch(graphAPIUrl, {
      method: 'GET',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
        JWTToken: esToken
      }
    })

    if (!thumbnailResponse || !thumbnailResponse.ok) {
      return ''
    } else {
      const contentType = thumbnailResponse.headers.get('content-type')

      if (contentType === 'application/json') {
        const responseJson = await thumbnailResponse.json()
        if (responseJson && responseJson.redirectUrl) {
          return responseJson.redirectUrl
        }
      } else {
        const pictureBlob = await thumbnailResponse.blob()
        const _pictureUrl = URL.createObjectURL(pictureBlob)
        return _pictureUrl
      }
    }
  } catch (error) {
    return ''
  }

  return originalPictureUrl ? originalPictureUrl : ''
}
