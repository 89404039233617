import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesConsent(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles() {
  return makeStyles((theme: Theme) => ({
    container: {
      width: 400
    },
    description: {
      color: '#424242'
    }
  }))
}
