import * as Config from 'config'
import { trackException } from 'utils/tracking'
import { jwtDecode } from 'jwt-decode'
import { countriesISO } from 'constants/countriesISO'
import * as Constants from 'constants/constants'
import { IUserSetting } from './userSettings'
import { IFindConfiguration, IKPMGFunction } from 'store/Settings/reducers'
import { isSupportedCountry, parsePhoneNumber } from 'libphonenumber-js'
import { stripHtml } from './string'

interface IJWTToken {
  aio: string
  aud: string
  azp: string
  azpacr: string
  ctry: string
  exp: number
  iat: number
  iss: string
  name: string
  nbf: number
  oid: string
  preferred_username: string
  rh: string
  scp: string
  sub: string
  tid: string
  uti: string
  ver: string
  extensionAttribute7: string
  city: string
  department: string
}

/**
 * Build a localized people result url (e.g. replace portal url for US profiles)
 * @param url The URL that is supposed to be adapted
 * @param country The country for which the URL should be adapted
 */
export function buildLocalizedPeopleResultUrl(
  url: string | null,
  country: string | null
): string {
  if (!url) return ''
  let adaptedUrl = url

  if (country === 'United States') {
    const urlTokens = adaptedUrl.split('%5C')
    const searchTerm = urlTokens.length > 1 ? urlTokens[1] : ''
    adaptedUrl = Config.PEOPLE_US_BASE_URL + searchTerm
  }

  return adaptedUrl
}

/**
 * Parses all JWT Token user information into User Settings
 * @param userSettings The User Settings
 * @param token The JWT Token
 * @returns Updated User Settings
 */
export function parseUserJWTToken(
  userSettings: IUserSetting,
  token: string,
  findConfiguration: IFindConfiguration
): IUserSetting {
  try {
    const decodedToken = jwtDecode<IJWTToken>(token)
    if (decodedToken) {
      // Country
      if (decodedToken.ctry && decodedToken.ctry !== '') {
        const country = countriesISO
          .filter(
            (ce: any) =>
              ce.Code.toLowerCase() === decodedToken.ctry.toLowerCase()
          )
          .map((ce: any) => {
            return ce.Name
          })
        if (country && country.length === 1) {
          const jwtCountry = country[0]
          if (userSettings.Country !== jwtCountry) {
            userSettings.Country = jwtCountry
          }
        } else {
          trackException(
            'Error in parsing JWT country',
            new Error('JTW ctry: ' + decodedToken.ctry)
          )
        }
      }
      // Function
      if (
        decodedToken.extensionAttribute7 &&
        decodedToken.extensionAttribute7 !== '' &&
        findConfiguration &&
        findConfiguration.KPMGFunction
      ) {
        let decodedTokenFunction = ''
        findConfiguration.KPMGFunction.map((kpmgFunction: IKPMGFunction) => {
          if (kpmgFunction.id === decodedToken.extensionAttribute7) {
            decodedTokenFunction = kpmgFunction.description
          }
          return false
        })

        if (
          decodedTokenFunction !== '' &&
          userSettings.Function !== decodedTokenFunction
        ) {
          userSettings.Function = decodedTokenFunction
        }
      }
      // City
      if (
        decodedToken.city &&
        decodedToken.city !== '' &&
        userSettings.City !== decodedToken.city
      ) {
        userSettings.City = decodedToken.city
      }
      // Department
      if (
        decodedToken.department &&
        decodedToken.department !== '' &&
        userSettings.Department !== decodedToken.department
      ) {
        userSettings.Department = decodedToken.department
      }
    }
  } catch (error) {
    trackException('Error while decoding jwt token', error)
  }

  // Set country default value
  if (!userSettings.Country) {
    userSettings.Country = Constants.defaultValues.noCountry
  }

  return userSettings
}

export function getURIPhoneNumber(
  phoneNumber: string,
  countryCodeAlpha2?: string | null
): string | undefined {
  //parse phone number
  let phoneNumberMobile = undefined
  try {
    phoneNumberMobile = parsePhoneNumber(stripHtml(phoneNumber))
  } catch (error) {}

  //parse phone number - fallback via country code
  if (!phoneNumberMobile && countryCodeAlpha2) {
    //GB fix
    const _countryCodeAlpha2 =
      countryCodeAlpha2.toLocaleLowerCase() === 'uk' ? 'GB' : countryCodeAlpha2

    try {
      if (isSupportedCountry(_countryCodeAlpha2)) {
        phoneNumberMobile = parsePhoneNumber(
          stripHtml(phoneNumber),
          _countryCodeAlpha2
        )
      }
    } catch (error) {}
  }

  if (
    phoneNumberMobile &&
    phoneNumberMobile.isValid() &&
    phoneNumberMobile.getURI()
  )
    return phoneNumberMobile.getURI()

  return undefined
}
