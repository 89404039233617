import { FeaturedResult } from 'components/models/FeaturedResult'
import { Reducer } from 'redux'
import {
  ResultsNewsActions,
  ResultsNewsActionTypes,
  IFetchSuccess,
  IFetchFiltersSuccess,
  IFetchSuccessCombined
} from './actions'
import { ISynonymsApplied } from 'components/models/SynonymsApplied'
import { INewsResult } from 'components/models/NewsResult'

export interface IResultsNewsStore {
  hasError: boolean
  hasResultsBeenFetched: boolean
  featuredResults: FeaturedResult[]
  synonymsApplied: ISynonymsApplied[]
  results: INewsResult[]
  resultsCombined: INewsResult[]
  resultsCombinedQuery: string
  filters: {
    currentQuery: string
    language: Array<any>
    publicationtype: Array<any>
    publisher: Array<any>
    hasResultsBeenFetched: boolean
    hasError: boolean
  }
  resultCount: number
  executionTime: number
  currentPage: number
}

const initialResultFiltersState = () => ({
  currentQuery: '',
  language: [],
  publicationtype: [],
  publisher: [],
  hasResultsBeenFetched: false,
  hasError: false
})
const initialState: IResultsNewsStore = {
  hasError: false,
  hasResultsBeenFetched: false,
  resultCount: -1,
  currentPage: 1,
  featuredResults: [],
  synonymsApplied: [],
  results: [],
  resultsCombined: [],
  resultsCombinedQuery: '',
  filters: initialResultFiltersState(),
  executionTime: 0
}

const reducers: Reducer<IResultsNewsStore, ResultsNewsActions> = (
  state: IResultsNewsStore = initialState,
  action: ResultsNewsActions | { type: 'SET_CURRENT_PAGE'; payload: any }
) => {
  switch (action.type) {
    case 'SET_CURRENT_PAGE':
      return {
        ...state,
        currentPage: action.payload || 0
      }
    case ResultsNewsActionTypes.INITIALIZE_RESULTS_NEWS:
      if (!(action as any).payload) {
        return initialState
      }
      const { resultsNews } = (action as any).payload
      return {
        ...state,
        currentPage: resultsNews.currentPage || 1
      }
    case ResultsNewsActionTypes.FETCH_REQUEST:
      const { resetCombined, searchQuery, resetResults } = (action as any)
        .payload
      return {
        ...state,
        hasError: false,
        hasResultsBeenFetched: false,
        executionTime: 0,
        resultCount: -1,
        results: resetResults ? [] : state.results,
        resultsCombined: resetCombined ? [] : state.resultsCombined,
        resultsCombinedQuery: searchQuery
      }
    case ResultsNewsActionTypes.FETCH_FILTERS_REQUEST:
      const { resetFilters } = (action as any).payload
      return {
        ...state,
        hasError: false,
        filters: {
          ...(resetFilters ? initialResultFiltersState() : state.filters),
          hasResultsBeenFetched: false,
          hasError: false
        }
      }
    case ResultsNewsActionTypes.FETCH_FAILURE:
      return {
        ...state,
        hasError: true,
        hasResultsBeenFetched: true,
        resultCount: 0,
        results: []
      }
    case ResultsNewsActionTypes.FETCH_FILTERS_FAILURE:
      return {
        ...state,
        hasError: true,
        hasResultsBeenFetched: true,
        resultCount: 0,
        filters: {
          ...initialResultFiltersState(),
          hasResultsBeenFetched: true
        }
        // TODO: evaluate whether it is suffiecient to reset the results key to it's initial state
        // on failure only. Before it was also reset in the case ResultsNewsActionTypes.FETCH_REQUEST which
        // led to the filters not being filled in properly due to the fact that the corresponding keys and
        // values are not present in the store anymore / yet.
      }

    case ResultsNewsActionTypes.FETCH_SUCCESS:
      const { response, featuredResults, synonymsApplied } = (
        action as IFetchSuccess
      ).payload

      return {
        ...state,
        hasError: false,
        hasResultsBeenFetched: true,
        executionTime: response.executionTime,
        resultCount: response.resultCount,
        results: response.queryResults,
        featuredResults:
          response.currentPage === 1 ? featuredResults : state.featuredResults,
        synonymsApplied:
          response.currentPage === 1 ? synonymsApplied : state.synonymsApplied,
        currentPage: response.currentPage
      }
    case ResultsNewsActionTypes.FETCH_SUCCESS_COMBINED:
      const { response: responseCombined } = (action as IFetchSuccessCombined)
        .payload
      return {
        ...state,
        hasError: false,
        resultsCombined: responseCombined.queryResults
      }
    case ResultsNewsActionTypes.FETCH_FILTERS_SUCCESS:
      const { responseFilters } = (action as IFetchFiltersSuccess).payload
      return {
        ...state,
        hasError: false,
        filters: {
          hasResultsBeenFetched: true,
          hasError: false,
          currentQuery: responseFilters.filters.currentQuery,
          language: responseFilters.filters.language,
          publicationtype: responseFilters.filters.publicationtype,
          publisher: responseFilters.filters.publisher
        }
      }

    default:
      return state
  }
}

export default reducers
