import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesSeeAllButton(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles(): any {
  return makeStyles((theme: Theme) => ({
    gridItem: {
      textAlign: 'right'
    },
    linkContainer: {
      textDecoration: 'none',
      color: '#005eb8',
      fontSize: 14
    }
  }))
}
