import React from 'react'
import {
  Box,
  TableRow,
  TableCell,
  Collapse,
  IconButton,
  Tooltip,
  Link,
  Typography
} from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { MailSourceResponse } from 'utils/o365'
import highlightedText, { prepareHighlightWords } from 'utils/highlightText'
import { externalLinkClicked } from 'utils/tracking'
import { IDeviceSetting } from 'utils/deviceSettings'
import { MailMobileResult } from './MailMobileResult'
import { dateFormatOptions } from 'constants/constants'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import { getStylesMailResults } from 'styles/results/MailResults'
import TooltipTitle from '../common/TooltipTitle'
import { ISynonymsApplied } from 'components/models/SynonymsApplied'
import LinesEllipsis from 'react-lines-ellipsis'
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'

interface MailResultProps {
  item: MailSourceResponse
  isOpen: boolean
  searchQuery: string
  deviceSettings: IDeviceSetting
  index: number
  synonymsApplied: ISynonymsApplied[]
}

export function MailResult(props: MailResultProps): JSX.Element {
  const { item, searchQuery, isOpen, deviceSettings, index, synonymsApplied } =
    props
  const [open, setOpen] = React.useState(isOpen)
  const classes = getStylesMailResults()
  const intl = useIntl()

  const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis)

  // it is possible that the sender is not defined
  const senderName = item.sender
    ? item.sender.emailAddress
      ? item.sender.emailAddress.name
      : ''
    : ''

  return (
    <>
      {deviceSettings.renderMobile ? (
        <MailMobileResult
          {...props}
          deviceSettings={deviceSettings}
          open={open}
          setOpen={setOpen}
          senderName={senderName}
          synonymsApplied={synonymsApplied}
        />
      ) : (
        <div className={classes.mailContainer}>
          <TableRow className={classes.mailResultRowHeader}>
            <TableCell className={classes.mailResultCollapseButton}>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
            <TableCell
              className={classes.mailResultSubject}
              style={{ maxWidth: 0 }}
            >
              <Tooltip enterTouchDelay={300} title={item.subject}>
                <Link
                  underline={'hover'}
                  className={classes.mailTitleContainer}
                  href={item.webLink}
                  onClick={() => {
                    externalLinkClicked({
                      title: item.subject,
                      url: item.webLink,
                      index: index
                    })
                  }}
                  {...(!deviceSettings.isMobile
                    ? {
                        onAuxClick: (event: any) => {
                          externalLinkClicked({
                            title: item.subject,
                            url: item.webLink,
                            index: index,
                            wasRightClicked: event?.button === 2 ? true : false
                          })
                        }
                      }
                    : {})}
                  {...(deviceSettings.openLinksInNewTab === true
                    ? { target: '_blank', rel: 'noreferrer' }
                    : {})}
                >
                  {item.hasAttachments && (
                    <AttachFileIcon
                      className={classes.mailAttachmentIcon}
                      viewBox="5 0 24 24"
                    />
                  )}
                  <ResponsiveEllipsis
                    style={{
                      whiteSpace: 'pre-wrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden'
                    }}
                    basedOn="words"
                    text={item.subject ? item.subject.trim() : ''}
                    maxLine="1"
                  />
                </Link>
              </Tooltip>
            </TableCell>
            <TableCell className={classes.mailResultSender}>
              <Tooltip enterTouchDelay={300} title={senderName}>
                <Link
                  href={item.webLink}
                  onClick={() => {
                    externalLinkClicked({
                      title: item.subject,
                      url: item.webLink,
                      index: index
                    })
                  }}
                  {...(!deviceSettings.isMobile
                    ? {
                        onAuxClick: (event: any) => {
                          externalLinkClicked({
                            title: item.subject,
                            url: item.webLink,
                            index: index,
                            wasRightClicked: event?.button === 2 ? true : false
                          })
                        }
                      }
                    : {})}
                  {...(deviceSettings.openLinksInNewTab === true
                    ? { target: '_blank', rel: 'noreferrer' }
                    : {})}
                >
                  <TooltipTitle
                    title={senderName ? senderName.trim() : ''}
                    singleLine={true}
                  />
                </Link>
              </Tooltip>
            </TableCell>
            <TableCell className={classes.mailResultDate}>
              <Link
                href={item.webLink}
                onClick={() => {
                  externalLinkClicked({
                    title: item.subject,
                    url: item.webLink,
                    index: index
                  })
                }}
                {...(!deviceSettings.isMobile
                  ? {
                      onAuxClick: (event: any) => {
                        externalLinkClicked({
                          title: item.subject,
                          url: item.webLink,
                          index: index,
                          wasRightClicked: event?.button === 2 ? true : false
                        })
                      }
                    }
                  : {})}
                {...(deviceSettings.openLinksInNewTab === true
                  ? { target: '_blank', rel: 'noreferrer' }
                  : {})}
              >
                <Typography variant="body1" color="primary">
                  {item.receivedDateTime
                    ? intl.formatDate(
                        item.receivedDateTime.replace('Z', ''),
                        dateFormatOptions
                      )
                    : ''}
                </Typography>
              </Link>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              className={
                open ? classes.mailBodyCellOpen : classes.mailBodyCellCollapsed
              }
            ></TableCell>
            <TableCell
              colSpan={3}
              className={
                open ? classes.mailBodyCellOpen : classes.mailBodyCellCollapsed
              }
              style={{ maxWidth: 0 }}
            >
              <Collapse
                in={open}
                timeout="auto"
                unmountOnExit
                className={classes.resultCollapse}
              >
                <Box className={classes.mailResultBody}>
                  {item.bodyPreview ? (
                    highlightedText(
                      item.bodyPreview,
                      prepareHighlightWords(
                        searchQuery,
                        item.bodyPreview,
                        synonymsApplied
                      ),
                      false
                    )
                  ) : (
                    <FormattedMessage
                      id="missing_description_text"
                      defaultMessage="Description is not available"
                    />
                  )}
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </div>
      )}
    </>
  )
}
