import { makeStyles } from '@mui/styles'
import { IDeviceSetting } from 'utils/deviceSettings'
import { Theme } from '@mui/material/styles'

export function getStylesOfficeLocationWidget(
  deviceSettings: IDeviceSetting
): any {
  const renderStyles = getStyles(deviceSettings)
  return renderStyles()
}

function getStyles(deviceSettings: IDeviceSetting): any {
  return makeStyles((theme: Theme) => ({
    widgetAreaContainer: {
      border: 'solid 1px #dfe1e5',
      borderRadius: 4,
      marginLeft: deviceSettings.renderMobile ? 0 : 16,
      marginBottom: 16,
      flexShrink: 0
    },
    widgetArea: {
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 20,
      paddingBottom: 20
    },
    mapImage: {
      maxWidth: '100%',
      objectFit: 'cover',
      display: 'block',
      width: '100%',
      borderRadius: 4
    },
    widgetHeadline: {
      whiteSpace: 'pre-wrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      fontSize: 20
    },
    widgetPrimaryHeadline: {
      fontSize: 20,
      // font: 'Arial,Helvetica Neue,Helvetica,sans-serif-light',
      cursor: 'pointer',
      marginBottom: 12
    },
    widgetText: {
      width: '100%',
      marginBottom: 16,
      fontSize: 14
    },
    address: {
      color: '#424242',
      textAlign: 'left',
      // font: 'Arial,Helvetica Neue,Helvetica,sans-serif-light',
      fontWeight: 'lighter',
      whiteSpace: 'pre-wrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    },
    widgetFooter: {
      color: '#424242',
      textAlign: 'left',
      // font: 'Arial,Helvetica Neue,Helvetica,sans-serif-light',
      fontWeight: 'lighter',
      whiteSpace: 'pre-wrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    },
    row: {
      display: 'flex'
    },
    prefix: {
      whiteSpace: 'pre'
    },
    tableItemLeft: {},
    tableItemRight: {},
    divider: {
      height: 1,
      backgroundColor: '#dfe1e5',
      marginTop: 15,
      marginBottom: 15
    },
    footerGrid: {
      position: 'relative'
    },
    arrowItem: {
      cursor: 'pointer',
      color: '#424242',
      padding: '12px',
      overflow: 'visible',
      fontSize: '1.5rem',
      textAlign: 'center',
      transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      borderRadius: '50%',
      background: '#ffffff',
      boxShadow: '0 0 0 1px rgba(0,0,0,0.04), 0 4px 8px 0 rgba(0,0,0,0.20)',
      '&:hover': {
        background: '#d2d2d2'
      }
    },
    arrowContainer: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%,-50%)',
      paddingTop: '6px'
    },
    arrowContainerExpanded: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%,-50%)',
      paddingTop: '6px'
    },
    fallbackImage: {
      boxSizing: 'border-box',
      padding: '15px'
    },
    widgetFooterLink: {
      textAlign: 'end',
      width: '100%',
      marginTop: '15px'
    },
    link: {
      color: '#004e98',
      fontSize: '12px',
      display: 'inline'
    }
  }))
}
