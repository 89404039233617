import { Action, AnyAction } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { Store } from '..'
import Selectors from './selectors'
import AuthStore from 'store/Auth'
import ResultMetaDataStore from 'store/ResultMetaData'
import * as Config from 'config'
import { stripHtml } from 'utils/string'
import { trackException } from 'utils/tracking'
import {
  IOneIntranetResultQuery,
  oneIntranetDefaultQuery,
  prepareFilters,
  removeStopWordsFromQuery
} from 'utils/oneIntranet'
import { getSPOnlineToken, renewAuthorizationToken } from 'utils/token'
import { IUserSetting } from 'utils/userSettings'
import { IDeviceSetting } from 'utils/deviceSettings'
import { FetchWithCache } from 'utils/api'
import { IFindConfiguration } from 'store/Settings/reducers'
import { getResultSummary } from 'utils/oneIntranet'
import { emptyGuid, imageOrVideoFileExtensions } from 'constants/constants'
import {
  ActionMetaData,
  CustomAction,
  getActionMetaData
} from 'store/extension/customAction'
import { ISeoCheckerData, SEOCheckerTypes } from 'utils/seoChecker'
import { FeaturedResult } from 'components/models/FeaturedResult'
import { KPMGFindGlobalVariables } from 'store/KPMGFindGlobalVariables'
import SettingsStore from 'store/Settings'
import { IOneIntranetResult } from 'components/models/OneIntranetResult'
import { ISynonymsApplied } from 'components/models/SynonymsApplied'

export enum ResultsOneIntranetActionTypes {
  FETCH_REQUEST = 'resultOneIntranet/FETCH_REQUEST',
  FETCH_SUCCESS = 'resultOneIntranet/FETCH_SUCCESS',
  FETCH_SUCCESS_COMBINED = 'resultOneIntranet/FETCH_SUCCESS_COMBINED',
  FETCH_FAILURE = 'resultOneIntranet/FETCH_FAILURE',
  FETCH_SEO_REQUEST = 'resultOneIntranet/FETCH_SEO_REQUEST',
  FETCH_SEO_SUCCESS = 'resultOneIntranet/FETCH_SEO_SUCCESS',
  FETCH_SEO_FAILURE = 'resultOneIntranet/FETCH_SEO_FAILURE',
  INITIALIZE_RESULTS_ONEINTRANET = 'persist/REHYDRATE'
}

export interface IFetchRequest
  extends CustomAction<ResultsOneIntranetActionTypes> {
  payload: {
    resetCombined: boolean
    searchQuery: string
  }
}
export type IFetchFailure = CustomAction<ResultsOneIntranetActionTypes>
export interface IFetchSuccess
  extends CustomAction<ResultsOneIntranetActionTypes> {
  payload: {
    response: {
      queryResults: IOneIntranetResult[]
      synonymsApplied: ISynonymsApplied[]
      totalRowCount: number
      lastRow: number
      hasError: boolean
      executionTime: number
    }
    featuredResults: FeaturedResult[]
    currentPage: number
  }
}
export interface IFetchSuccessCombined
  extends CustomAction<ResultsOneIntranetActionTypes> {
  payload: {
    response: {
      queryResults: IOneIntranetResult[]
      synonymsApplied: ISynonymsApplied[]
      lastRow: number
      hasError: boolean
    }
  }
}
export type IFetchSeoRequest = Action<ResultsOneIntranetActionTypes>
export type IFetchSeoFailure = Action<ResultsOneIntranetActionTypes>
export interface IFetchSeoSuccess
  extends Action<ResultsOneIntranetActionTypes> {
  payload: {
    responseSeo: any
  }
}

export const fetchRequest = (
  resetCombined: boolean,
  searchQuery: string,
  actionMetaData: ActionMetaData
): IFetchRequest => ({
  type: ResultsOneIntranetActionTypes.FETCH_REQUEST,
  payload: {
    resetCombined: resetCombined,
    searchQuery: searchQuery
  },
  metaData: {
    ...actionMetaData,
    isStartAction: true
  }
})
export const fetchSuccess = (
  response: {
    queryResults: IOneIntranetResult[]
    synonymsApplied: ISynonymsApplied[]
    totalRowCount: number
    lastRow: number
    hasError: boolean
    executionTime: number
  },
  featuredResults: FeaturedResult[],
  currentPage: number,
  actionMetaData: ActionMetaData
): IFetchSuccess => ({
  type: ResultsOneIntranetActionTypes.FETCH_SUCCESS,
  payload: { response, featuredResults, currentPage },
  metaData: actionMetaData
})
export const fetchSuccessCombined = (
  response: {
    queryResults: IOneIntranetResult[]
    synonymsApplied: ISynonymsApplied[]
    lastRow: number
    hasError: boolean
  },
  actionMetaData: ActionMetaData
): IFetchSuccessCombined => ({
  type: ResultsOneIntranetActionTypes.FETCH_SUCCESS_COMBINED,
  payload: { response },
  metaData: actionMetaData
})
export const fetchFailure = (
  actionMetaData: ActionMetaData
): IFetchFailure => ({
  type: ResultsOneIntranetActionTypes.FETCH_FAILURE,
  metaData: actionMetaData
})
export const fetchSeoRequest = (): IFetchSeoRequest => ({
  type: ResultsOneIntranetActionTypes.FETCH_SEO_REQUEST
})
export const fetchSeoSuccess = (responseSeo: any = {}): IFetchSeoSuccess => ({
  type: ResultsOneIntranetActionTypes.FETCH_SEO_SUCCESS,
  payload: { responseSeo }
})
export const fetchSeoFailure = (): IFetchSeoFailure => ({
  type: ResultsOneIntranetActionTypes.FETCH_SEO_FAILURE
})

const generatePath = (item: any): string => {
  if (item.DefaultEncodingURL) {
    const defaultEncodingUrl = decodeURI(item.DefaultEncodingURL)
    const fileExtension = defaultEncodingUrl.split('.').pop() as string
    if (
      fileExtension &&
      imageOrVideoFileExtensions.indexOf(fileExtension.toLowerCase()) !== -1
    )
      return defaultEncodingUrl
  }

  if (item.FreshLinkURL && item.FreshLinkURL !== '') {
    return item.FreshLinkURL
  } else {
    // Attachment within a list
    if (item.Path.indexOf('DispForm.aspx') >= 0 && item.LinkOfficeChild) {
      const attachmentUrls = item.LinkOfficeChild.split('\n').filter(
        (a: string) => a
      )
      if (attachmentUrls && attachmentUrls.length === 1 && attachmentUrls[0]) {
        const urlToAttachment = attachmentUrls[0]

        return urlToAttachment.indexOf('?web=1') === -1
          ? `${urlToAttachment}?web=1`
          : urlToAttachment
      }
    }
  }

  if (item.DefaultEncodingURL && item.DefaultEncodingURL.endsWith('oft'))
    return item.DefaultEncodingURL

  return item.Path
}

export const fetchResultsOneIntranet = (
  searchQuery: string,
  currentPage: number,
  userSettings: IUserSetting,
  deviceSettings: IDeviceSetting,
  filters: {
    [key: string]: string
  },
  findConfiguration: IFindConfiguration
  // eslint-disable-next-line @typescript-eslint/ban-types
): ThunkAction<Promise<void>, Store, {}, AnyAction> => {
  return async (
    // eslint-disable-next-line @typescript-eslint/ban-types
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => Store
  ) => {
    const actionMetaData = getActionMetaData('intranet')

    dispatch(
      ResultMetaDataStore.actions.fetchRequest('intranet', actionMetaData)
    )

    const resultsCombinedQuery = Selectors.getResultsCombinedQuery(getState())
    if (resultsCombinedQuery !== searchQuery) {
      if (resultsCombinedQuery === '') {
        dispatch(fetchRequest(false, searchQuery, actionMetaData))
      } else {
        dispatch(fetchRequest(true, searchQuery, actionMetaData))
      }
    } else {
      dispatch(fetchRequest(false, searchQuery, actionMetaData))
    }

    try {
      if (!searchQuery || searchQuery === '' || searchQuery === '""') {
        dispatch(fetchFailure(actionMetaData))
        return
      }

      const t0 = performance.now()

      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        throw new Error(
          'Authentication: Cannot renew Enterprise Search authentication token'
        )
      }

      let spoToken = ''
      if (Config.LOCAL_DEVELOPMENT.toString() === 'false') {
        spoToken = await getSPOnlineToken(aadInfo.instance, aadInfo.accounts)
        if (spoToken === '') {
          throw new Error(
            'Authentication: Cannot renew SPO authentication token'
          )
        }
      }

      const apiUrl = `${Config.APIM_BASE_URL}oneintranetapi/postsearchoi`

      const requestBody: IOneIntranetResultQuery = Object.assign(
        {},
        oneIntranetDefaultQuery
      )

      const useCognitiveSearch = SettingsStore.selectors.getUseCognitiveSearch(
        getState()
      )

      let searchQueryLanguage = ''
      if (useCognitiveSearch && findConfiguration.CognitiveSearchEnabled) {
        const detectLanguageApiUrl = `${
          Config.APIM_BASE_URL
        }searchapi/detectlanguage?searchQuery=${encodeURIComponent(
          searchQuery
        )}`

        const languageResponse = await FetchWithCache(detectLanguageApiUrl, {
          method: 'POST',
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
            Authorization: `Bearer ${esToken}`
          }
        })

        if (
          languageResponse &&
          !languageResponse.hasError &&
          languageResponse.responseJSON
        ) {
          const languageJSON = languageResponse.responseJSON
          if (languageJSON && languageJSON.responseCode === 200) {
            searchQueryLanguage = languageJSON.language
          }
        }
        requestBody.cognitiveEnabled = true
      }

      requestBody.querytext = removeStopWordsFromQuery(
        searchQuery,
        searchQueryLanguage,
        useCognitiveSearch,
        findConfiguration.CognitiveSearchEnabled
      )

      const bodyObject = prepareFilters(
        requestBody,
        filters,
        (currentPage || 1) * 20 - 20,
        userSettings,
        'intranet',
        useCognitiveSearch
      )

      // Enable Feature Result fetch only on page 1
      bodyObject.processFeaturedResults =
        (currentPage || 1) === 1 ? true : false

      const body = JSON.stringify(bodyObject)
      const hasError = Selectors.getHasError(getState())

      const response = await FetchWithCache(
        apiUrl,
        {
          method: 'POST',
          headers: {
            'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
            'content-type': 'application/json',
            Authorization: `Bearer ${spoToken}`,
            'Authorization-OnPrem': `${esToken}`
          },
          body: body
        },
        hasError,
        true,
        actionMetaData.transactionType,
        KPMGFindGlobalVariables.getCurrentTab() === 'intranet',
        searchQuery
      )

      let results
      if (!response || response.hasError || !response.responseJSON) {
        let hasOIError = false
        if (
          response.errorResponse &&
          (response.errorResponse.responseCode === 400 ||
            response.errorResponse.responseCode === 500) &&
          (currentPage || 1) === 1 &&
          !response.errorResponse.internalError
        ) {
          hasOIError = true
        }

        dispatch(
          ResultMetaDataStore.actions.hasError(
            'intranet',
            response.errorResponse,
            undefined,
            actionMetaData,
            hasOIError
          )
        )
        dispatch(fetchFailure(actionMetaData))
        return
      } else {
        results = response.responseJSON
      }

      const featuredResults =
        results && results.FeaturedResults ? results.FeaturedResults : []

      let t1 = performance.now()
      if (response.ESTimeInQueue) {
        try {
          t1 = t1 - response.ESTimeInQueue
        } catch {}
      }

      const executionTime = t1 - t0

      const r: any[] = []

      results.QueryResults.map((res: any) => {
        let encodedTitle = encodeURIComponent(res.Title.trim())
        encodedTitle = encodedTitle
          .split('%E2')
          .join('')
          .split('%A0')
          .join('')
          .split('%80')
          .join('')
          .split('%8D')
          .join('')
        if (encodedTitle !== '') {
          r.push(res)
        }
        return false
      })

      results.QueryResults = r

      const oneIntranetResults: IOneIntranetResult[] = []

      if (results && 'QueryResults' in results) {
        results.QueryResults.forEach((i: any) => {
          const path = generatePath(i)
          let attachmentUrls = []

          if (i.LinkOfficeChild) {
            const attachUrls = i.LinkOfficeChild.split('\n').filter(
              (a: string) => a
            )
            if (attachUrls && attachUrls.length > 0) {
              attachmentUrls = attachUrls
            }
          }

          const body = getResultSummary(
            i.HitHighlightedSummary,
            i.Description,
            i.FreshDescription
          )

          let shortcutUrl = ''

          if (i.ShortcutUrl) {
            if (i.ShortcutUrl.indexOf(',') !== -1) {
              const linkSplitted = i.ShortcutUrl.split(',')
              shortcutUrl = linkSplitted[0]
            }

            shortcutUrl = i.ShortcutUrl
          }

          const oneIntranetResult: IOneIntranetResult = {
            Body: body ? stripHtml(body) : '',
            Path: path ? path : '',
            ServerRedirectedURL: i.ServerRedirectedURL
              ? i.ServerRedirectedURL
              : '',
            Title: i.Title ? stripHtml(i.Title) : '',
            Date:
              i.PublishingDate &&
              filters['published'] === 'true' &&
              filters['lastmodified']
                ? i.PublishingDate
                : i.Write
                ? i.Write
                : '',
            DefaultEncodingURL: i.DefaultEncodingURL
              ? stripHtml(i.DefaultEncodingURL)
              : '',
            SiteTitle: i.SiteTitle ? i.SiteTitle : '',
            ListItemID: i.ListItemID ? stripHtml(i.ListItemID) : '',
            ListID: i.ListID ? stripHtml(i.ListID) : '',
            SPWebUrl: i.SPWebUrl ? stripHtml(i.SPWebUrl) : '',
            IsLink: i.FreshLinkURL ? true : false,
            OriginalPath: i.Path ? i.Path : '',
            AttachmentUrls: attachmentUrls,
            UniqueID: i.UniqueID ? stripHtml(i.UniqueID) : '',
            ContentClass: i.ContentClass ? i.ContentClass : '',
            SiteID: i.SiteID ? stripHtml(i.SiteID) : '',
            ShortcutUrl: shortcutUrl,
            ViewsLifeTime: i.ViewsLifeTime ? stripHtml(i.ViewsLifeTime) : ''
          }

          oneIntranetResults.push(oneIntranetResult)
        })

        const totalRowCount = results.TotalRowCount
        const lastRow = results.LastRow
        const hasError = results.hasError

        const synonymsApplied = results.SynonymsApplied

        dispatch(
          fetchSuccess(
            {
              queryResults: oneIntranetResults,
              synonymsApplied,
              totalRowCount,
              lastRow,
              hasError,
              executionTime
            },
            featuredResults,
            currentPage || 1,
            actionMetaData
          )
        )

        const resultsCombined = Selectors.getResultsCombined(getState())
        if ((currentPage || 1) === 1) {
          dispatch(
            fetchSuccessCombined(
              {
                queryResults: oneIntranetResults,
                synonymsApplied,
                lastRow,
                hasError
              },
              actionMetaData
            )
          )
        } else {
          dispatch(
            fetchSuccessCombined(
              {
                queryResults: [
                  ...resultsCombined.queryResults,
                  ...oneIntranetResults
                ],
                synonymsApplied: [
                  ...resultsCombined.synonymsApplied,
                  ...synonymsApplied
                ],
                lastRow: lastRow,
                hasError: hasError
              },
              actionMetaData
            )
          )
        }

        dispatch(
          ResultMetaDataStore.actions.fetchSuccess(
            'intranet',
            {
              executionTime: executionTime,
              resultsCount: totalRowCount
            },
            actionMetaData
          )
        )
      }
    } catch (error) {
      dispatch(
        ResultMetaDataStore.actions.hasError(
          'intranet',
          undefined,
          error,
          actionMetaData
        )
      )
      dispatch(fetchFailure(actionMetaData))
    }
  }
}

export const fetchResultsAllSeo = (
  seoData: ISeoCheckerData,
  deviceSettings: IDeviceSetting
  // eslint-disable-next-line @typescript-eslint/ban-types
): ThunkAction<Promise<void>, Store, {}, AnyAction> => {
  return async (
    // eslint-disable-next-line @typescript-eslint/ban-types
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => Store
  ) => {
    dispatch(fetchSeoRequest())
    try {
      let queryParams = {}

      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        throw new Error(
          'Authentication: Cannot renew Enterprise Search authentication token'
        )
      }

      let spoToken = ''
      if (Config.LOCAL_DEVELOPMENT.toString() === 'false') {
        spoToken = await getSPOnlineToken(aadInfo.instance, aadInfo.accounts)
        if (spoToken === '') {
          throw new Error(
            'Authentication: Cannot renew SPO authentication token'
          )
        }
      }

      let queryText = ''

      if (seoData.seoType === SEOCheckerTypes.OnlineWeb) {
        if (seoData.siteId && seoData.siteId !== emptyGuid) {
          queryText = `SiteID:${seoData.siteId} AND ContentClass:STS_Site`
        } else {
          queryText = `Path:"${seoData.url}" AND ContentClass:STS_Site`
        }
      } else if (
        seoData.uniqueId &&
        seoData.uniqueId.replace(/[{}]/g, '') !== emptyGuid
      ) {
        queryText = `UniqueId:${seoData.uniqueId}`
      } else {
        queryText = `Path:"${seoData.url}"`
      }

      const apiUrl = `${Config.APIM_BASE_URL}oneintranetapi/postseoresults`

      queryParams = {
        method: 'POST',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${spoToken}`,
          'Authorization-OnPrem': `${esToken}`
        },
        body: JSON.stringify({
          querytext: queryText,
          selectproperties:
            'Title,Description,FreshDescription,HitHighlightedSummary,Write,Path,LastModifiedTime,Filename,ServerRedirectedURL,ServerRedirectedEmbedURL,ServerRedirectedPreviewURL,ListItemID',
          trimduplicates: false
        })
      }

      const response = await fetch(apiUrl, queryParams)

      let results
      if (!response || !response.ok) {
        dispatch(fetchSeoFailure())
        return
      } else {
        results = await response.json()
      }

      const primaryResults =
        results &&
        results.PrimaryQueryResult &&
        results.PrimaryQueryResult.RelevantResults &&
        results.PrimaryQueryResult.RelevantResults.Table &&
        results.PrimaryQueryResult.RelevantResults.Table.Rows
          ? results.PrimaryQueryResult.RelevantResults.Table.Rows
          : []

      const resultSeoCount =
        results &&
        results.PrimaryQueryResult &&
        results.PrimaryQueryResult.RelevantResults &&
        results.PrimaryQueryResult.RelevantResults.TotalRows
          ? results.PrimaryQueryResult.RelevantResults.TotalRows
          : 0

      // Set title
      let title = ''
      primaryResults
        .map((item: any) =>
          item.Cells.find((o: any) => o.Key.toLowerCase() === 'title')
        )
        .map((i: any) => (title = i.Value))

      // Set body
      let resultBody = ''
      primaryResults
        .map((item: any) => {
          return getResultSummary(
            item.Cells.find(
              (o: any) => o.Key.toLowerCase() === 'hithighlightedsummary'
            )?.Value,
            item.Cells.find((o: any) => o.Key.toLowerCase() === 'description')
              ?.Value,
            item.Cells.find(
              (o: any) => o.Key.toLowerCase() === 'freshdescription'
            )?.Value
          )
        })
        .map((i: any) => (resultBody = stripHtml(i ? i : '')))

      // Set date
      let date = ''
      primaryResults
        .map((item: any) =>
          item.Cells.find((o: any) => o.Key.toLowerCase() === 'write')
        )
        .map((i: any) => (date = i ? i.Value : ''))

      // Set result url
      let resultUrl = ''
      primaryResults
        .map((item: any) =>
          item.Cells.find((o: any) => o.Key.toLowerCase() === 'path')
        )
        .map((i: any) => (resultUrl = i ? i.Value : ''))

      // Set last modified date
      let lastModifiedDate = ''
      primaryResults
        .map((item: any) =>
          item.Cells.find(
            (o: any) => o.Key.toLowerCase() === 'lastmodifiedtime'
          )
        )
        .map((i: any) => (lastModifiedDate = i ? i.Value : ''))

      // Set file name
      let fileName = ''
      primaryResults
        .map((item: any) =>
          item.Cells.find((o: any) => o.Key.toLowerCase() === 'filename')
        )
        .map((i: any) => {
          const tokens = i ? i.Value.split('/') : undefined
          if (tokens && tokens.length > 0) {
            fileName = tokens[tokens.length - 1]
          }
          return null
        })

      // Set update link
      let updateLink = ''

      if (seoData.seoType === SEOCheckerTypes.OnlinePage) {
        updateLink = resultUrl
      } else if (seoData.seoType === SEOCheckerTypes.OnlineWeb) {
        updateLink = `${resultUrl}${
          resultUrl.endsWith('/') ? '' : '/'
        }_layouts/15/prjsetng.aspx`
      } else {
        primaryResults.map((item: any, index: number) => {
          const pathObject = item.Cells.find(
            (o: any) => o.Key.toLowerCase() === 'path'
          )
          let path = ''
          if (pathObject && pathObject.Value && pathObject.Value !== '') {
            path = pathObject.Value
          }

          // in case it is a direct link to the dispform of an item
          // change the link and return
          if (path && path.indexOf('/DispForm.aspx?') !== -1) {
            updateLink = path.replace('/DispForm.aspx?', '/EditForm.aspx?')
            return false
          }

          const listItemIdObject = item.Cells.find(
            (o: any) => o.Key.toLowerCase() === 'listitemid'
          )
          let listItemId = ''
          if (
            listItemIdObject &&
            listItemIdObject.Value &&
            listItemIdObject.Value !== ''
          ) {
            listItemId = listItemIdObject.Value
          }
          let serverRedirectedURL = ''
          const serverRedirectedURLObject = item.Cells.find(
            (o: any) => o.Key.toLowerCase() === 'serverredirectedurl'
          )
          if (
            serverRedirectedURLObject &&
            serverRedirectedURLObject.Value &&
            serverRedirectedURLObject.Value !== ''
          ) {
            serverRedirectedURL = serverRedirectedURLObject.Value
          }
          if (serverRedirectedURL === '') {
            const serverRedirectedEmbedURLObject = item.Cells.find(
              (o: any) => o.Key.toLowerCase() === 'serverredirectedembedurl'
            )
            if (
              serverRedirectedEmbedURLObject &&
              serverRedirectedEmbedURLObject.Value &&
              serverRedirectedEmbedURLObject !== ''
            ) {
              serverRedirectedURL = serverRedirectedEmbedURLObject.Value
            }
            if (serverRedirectedURL === '') {
              const serverRedirectedPreviewURLObject = item.Cells.find(
                (o: any) => o.Key.toLowerCase() === 'serverredirectedpreviewurl'
              )
              if (
                serverRedirectedPreviewURLObject &&
                serverRedirectedPreviewURLObject.Value &&
                serverRedirectedPreviewURLObject.Value !== ''
              ) {
                serverRedirectedURL = serverRedirectedPreviewURLObject.VAlue
              }
            }
          }
          if (path !== '' && listItemId !== '') {
            try {
              if (serverRedirectedURL !== '') {
                // Step 1 - Extract the site URL
                const siteUrl = serverRedirectedURL.split('_layouts/15')[0]

                // Step 2 - Extract the list  name
                const partialListName = path.replace(siteUrl, '')
                const listName = partialListName.substring(
                  0,
                  partialListName.indexOf('/')
                )

                // Step 3- Construct the Edit form url
                updateLink =
                  siteUrl + listName + '/Forms/EditForm.aspx?ID=' + listItemId
              } else {
                updateLink = `${path.substring(
                  0,
                  path.lastIndexOf('/')
                )}/Forms/EditForm.aspx?ID=${listItemId}`
              }
            } catch (e) {
              trackException(
                'Exception constructing SEO Checker Update Link',
                e
              )
            }
          }
          return false
        })
      }

      dispatch(
        fetchSeoSuccess({
          title,
          resultBody,
          date,
          resultUrl,
          lastModifiedDate,
          fileName,
          updateLink,
          resultSeoCount
        })
      )
    } catch (error) {
      dispatch(fetchSeoFailure())
    }
  }
}

export const triggerOneIntranetDataSourceQuery = (
  searchQuery: string
  // eslint-disable-next-line @typescript-eslint/ban-types
): ThunkAction<Promise<void>, Store, {}, AnyAction> => {
  return async (
    // eslint-disable-next-line @typescript-eslint/ban-types
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => Store
  ) => {
    const actionMetaData = getActionMetaData('intranet')

    dispatch(
      ResultMetaDataStore.actions.fetchRequest('intranet', actionMetaData)
    )

    const resultsCombinedQuery = Selectors.getResultsCombinedQuery(getState())
    if (resultsCombinedQuery !== searchQuery) {
      if (resultsCombinedQuery === '') {
        dispatch(fetchRequest(false, searchQuery, actionMetaData))
      } else {
        dispatch(fetchRequest(true, searchQuery, actionMetaData))
      }
    } else {
      dispatch(fetchRequest(false, searchQuery, actionMetaData))
    }

    return
  }
}

export type ResultsOneIntranetActions =
  | IFetchRequest
  | IFetchFailure
  | IFetchSeoRequest
  | IFetchSeoFailure
  | IFetchSeoSuccess
