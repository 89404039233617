import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesFileResult(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles() {
  return makeStyles((theme: Theme) => ({
    fileResultBody: {
      fontSize: '14px',
      marginTop: 0,
      color: 'rgba(0,0,0,0.54)',
      fontWeight: 'normal',
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word',
      wordWrap: 'break-word'
    },
    fileResultBodyPath: {
      fontSize: '12px',
      marginTop: 0,
      color: 'rgba(0,0,0,0.54)',
      fontWeight: 'normal',
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word',
      wordWrap: 'break-word'
    },
    fileResultDate: {
      color: '#3C4043',
      whiteSpace: 'nowrap',
      fontSize: '14px'
    },
    fileResultSource: {
      color: '#3C4043',
      whiteSpace: 'nowrap',
      fontSize: '14px'
    },
    fileContainer: {
      [`${theme.breakpoints.down(1024)}`]: {
        display: 'none'
      }
    },
    fileMobileContainer: {
      display: 'none',
      [`${theme.breakpoints.down(1024)}`]: {
        display: 'flex',
        wordBreak: 'break-word',
        backgroundColor: '#fff',
        margin: '0 0 10px 0',
        boxShadow: '0 2px 6px rgba(32, 33, 36, 0.28)',
        borderRadius: '4px',
        paddingTop: '12px',
        paddingBottom: '12px',
        paddingLeft: '16px',
        paddingRight: '16px',
        fontFamility: 'Roboto, HelveticaNeue, Arial, sans-serif'
      }
    },
    fileResultNameCell: {
      color: '#004E98',
      width: '100%',
      fontSize: 16,
      maxWidth: 0,
      whiteSpace: 'nowrap'
    },
    fileResultCollapseButton: {
      width: 30,
      paddingRight: 5
    },
    fileResultIcon: {
      verticalAlign: 'middle',
      textDecoration: 'none'
    },
    fileResultRowHeader: {
      '& td': {
        borderBottom: 'none'
      },
      [`${theme.breakpoints.down(1024)}`]: {
        display: 'none'
      }
    },
    resultCollapse: {
      '& .MuiCollapse-wrapper': {
        display: 'block'
      }
    },
    fileBodyCellCollapsed: {
      padding: '0px 6px !important'
    },
    fileBodyCellOpen: {
      padding: '0px 6px 12px 6px !important'
    },
    downloadIcon: {
      width: '0.75em',
      height: '0.75em',
      color: '#3C4043',
      marginLeft: '5px',
      verticalAlign: 'text-bottom',
      '&:hover': {
        cursor: 'pointer'
      }
    },
    loadingSpinner: {
      width: '0.75em',
      height: '0.75em',
      color: '#3C4043',
      marginLeft: '5px',
      verticalAlign: 'text-bottom'
    }
  }))
}
