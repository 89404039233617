import React, { Dispatch } from 'react'
import { connect } from 'react-redux'
import { Store } from 'store'
import SettingsStore from 'store/Settings'
import { useIntl } from 'react-intl'
import { Box, Tooltip, Typography } from '@mui/material'
import { WidgetKey } from 'constants/widgets'
import { trackWidgetShown, trackLeftWidgetClick } from 'utils/tracking'
import { capitalizeFirstLetter, spacesVisualization } from 'utils/string'
import { getStylesLeftPeopleAlsoSearchedWidget } from 'styles/contents/resultpages/widgets/LeftPeopleAlsoSearchedWidget'
import { useFilter } from 'utils/filters'
import createDOMPurify from 'dompurify'
import { IDataSource } from 'constants/datasourcesConfiguration'
import queryString from 'query-string'
import { useReactRouterQueryStringInterface } from 'utils/useQueryState'
import FilterStore from 'store/Filters'

export interface LeftPeopleAlsoSearchedWidgetProps {
  searchQuery: string
  searchQueries: string[]
}

type AllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  LeftPeopleAlsoSearchedWidgetProps

function LeftPeopleAlsoSearchedWidget(props: AllProps): JSX.Element {
  const {
    searchQuery,
    searchQueries,
    deviceSettings,
    currentDataSources,
    clearTemporaryFilter
  } = props
  const { getQueryString } = useReactRouterQueryStringInterface()
  const DOMPurify = createDOMPurify(window)
  const classes = getStylesLeftPeopleAlsoSearchedWidget()

  const [, setSearchQuery] = useFilter('q', '')

  // Triggers the new search
  const setNewSearchQuery = (
    newSearchQuery: string,
    currentSearchTerm: string
  ) => {
    if (!newSearchQuery || newSearchQuery === currentSearchTerm) return

    window.scrollTo(0, 0)
    setSearchQuery(newSearchQuery)
  }

  // Triggers the new search
  const setNewSearch = (newSearchQuery: string, dataSource: IDataSource) => {
    clearTemporaryFilter()
    const qs = queryString.parse(getQueryString()) || {}
    qs.page = '1'
    qs.q = newSearchQuery
    const newUrl =
      window.location.origin +
      '/#' +
      dataSource.path +
      '?' +
      queryString.stringify(qs)
    setSearchQuery(newSearchQuery)
    window.location.href = newUrl
  }

  React.useEffect(() => {
    trackWidgetShown({
      widgetType: WidgetKey.peopleAlsoSearched.toLowerCase(),
      widgetPane: 'left'
    })
  }, [])

  const intl = useIntl()
  return (
    <Box className={classes.widgetArea}>
      <Typography
        variant="h6"
        color="primary"
        component="p"
        className={classes.title}
        gutterBottom
      >
        {intl.formatMessage({
          id: 'people_also_searched_title',
          defaultMessage: 'People also searched'
        })}
      </Typography>
      <Box className={classes.spacer} />
      {searchQueries.slice(0, 10).map((item: string, index: number) => (
        <div key={'lpas' + index}>
          <Box key={'lpasb1' + index} className={classes.widgetItem}>
            <Box key={'lpasb2' + index} className={classes.widgetTitleWrapper}>
              <>
                <div
                  key={'lpasd1' + index}
                  className={[
                    classes.ellipsisSingleLine,
                    classes.widgetTitle,
                    deviceSettings.isMobile ? classes.widgetTitleMobile : ''
                  ].join(' ')}
                  tabIndex={0}
                  onClick={() => {
                    trackLeftWidgetClick({
                      title: DOMPurify.sanitize(item),
                      url: DOMPurify.sanitize(item),
                      index,
                      widgetKey: WidgetKey.peopleAlsoSearched.toLowerCase()
                    })
                    setNewSearchQuery(item, searchQuery)
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      trackLeftWidgetClick({
                        title: DOMPurify.sanitize(item),
                        url: DOMPurify.sanitize(item),
                        index,
                        widgetKey: WidgetKey.peopleAlsoSearched.toLowerCase()
                      })
                      setNewSearchQuery(item, searchQuery)
                    }
                  }}
                >
                  {spacesVisualization(
                    capitalizeFirstLetter(DOMPurify.sanitize(item)),
                    50
                  )}
                </div>
                <div key={'lpasd2' + index} className={classes.widgetImages}>
                  {currentDataSources.map((ds: IDataSource) => {
                    if (ds.enabled) {
                      if (window.location.hash.indexOf(ds.path) > 0) {
                        return (
                          <Tooltip
                            title={`${intl.formatMessage({
                              id: 'search_in',
                              defaultMessage: 'Search in'
                            })} ${intl.formatMessage({
                              id: ds.name.toLowerCase().replace(' ', '_'),
                              defaultMessage: ds.name
                            })}`}
                            placement="top"
                          >
                            <span
                              className={classes.widgetImageActive}
                              tabIndex={0}
                              onClick={() => {
                                trackLeftWidgetClick({
                                  title: DOMPurify.sanitize(item),
                                  url: DOMPurify.sanitize(item),
                                  index,
                                  widgetKey:
                                    WidgetKey.peopleAlsoSearched.toLowerCase()
                                })
                                setNewSearchQuery(item, searchQuery)
                              }}
                              onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                  trackLeftWidgetClick({
                                    title: DOMPurify.sanitize(item),
                                    url: DOMPurify.sanitize(item),
                                    index,
                                    widgetKey:
                                      WidgetKey.peopleAlsoSearched.toLowerCase()
                                  })
                                  setNewSearchQuery(item, searchQuery)
                                }
                              }}
                            >
                              <span>{ds.icon}</span>
                            </span>
                          </Tooltip>
                        )
                      } else {
                        return (
                          <Tooltip
                            title={`${intl.formatMessage({
                              id: 'search_in',
                              defaultMessage: 'Search in'
                            })} ${intl.formatMessage({
                              id: ds.name.toLowerCase().replace(' ', '_'),
                              defaultMessage: ds.name
                            })}`}
                            placement="top"
                          >
                            <span
                              className={classes.widgetImage}
                              tabIndex={0}
                              onClick={() => {
                                trackLeftWidgetClick({
                                  title: DOMPurify.sanitize(item),
                                  url: DOMPurify.sanitize(item),
                                  index,
                                  widgetKey:
                                    WidgetKey.peopleAlsoSearched.toLowerCase()
                                })
                                setNewSearch(item, ds)
                              }}
                              onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                  trackLeftWidgetClick({
                                    title: DOMPurify.sanitize(item),
                                    url: DOMPurify.sanitize(item),
                                    index,
                                    widgetKey:
                                      WidgetKey.peopleAlsoSearched.toLowerCase()
                                  })
                                  setNewSearch(item, ds)
                                }
                              }}
                            >
                              <span>{ds.icon}</span>
                            </span>
                          </Tooltip>
                        )
                      }
                    }
                    return null
                  })}
                </div>
              </>
            </Box>
          </Box>
          <Box key={'lpasb3' + index} className={classes.spacer} />
        </div>
      ))}
    </Box>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state),
    currentDataSources: SettingsStore.selectors.getCurrentDataSources(state)
  }
}
const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    clearTemporaryFilter: () =>
      dispatch(FilterStore.actions.clearTemporaryFilter())
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeftPeopleAlsoSearchedWidget)
