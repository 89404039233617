import {
  PopupDisplayTypes,
  PopupResult,
  PopupUserSettings
} from 'components/models/PopupResult'
import dayjs from 'dayjs'
import { IUserSetting } from './userSettings'

const localOncePerSessionMemory: PopupUserSettings[] = []

/**
 * Filter popups for current user
 * @param popupTranslations popupTranslations
 * @param currentDatasource current datasource
 * @param userSettings user settings
 * @returns filtered popup list
 */
export function getUserPopups(
  popupTranslations: PopupResult[],
  currentDatasource: string,
  userSettings: IUserSetting
): PopupResult[] {
  let userFilteredPopups: PopupResult[] = []

  //deep copy
  const popupTranslationsCopy = [...popupTranslations]

  //filter popups
  const filteredPopups = filterPopups(
    popupTranslationsCopy,
    userSettings,
    currentDatasource
  )

  //pick popups by display rules
  //oncePerUser - get last oncePerUser popup
  const oncePerUserPopup = getPopupOncePerUser(filteredPopups)
  if (oncePerUserPopup) {
    userFilteredPopups.push(oncePerUserPopup)
  }

  //get oncePerInterval
  const oncePerInterval = getPopupOncePerInterval(filteredPopups)
  if (oncePerInterval) {
    userFilteredPopups = [...userFilteredPopups, ...oncePerInterval]
  }
  //get onceEverySession
  const onceEverySession = getPopupOnceEverySession(filteredPopups)
  if (onceEverySession) {
    userFilteredPopups = [...userFilteredPopups, ...onceEverySession]
  }

  //filter already displayed
  const userPopups = filterAlreadyDisplayedPopups(
    userFilteredPopups,
    userSettings
  )

  return userPopups
}

/**
 * Filter popup translation list by language, source, country & function
 * @param popupTranslations popup translation list
 * @param userSettings user settings
 * @param currentDatasource current datasource
 * @returns filtered popup list
 */
function filterPopups(
  popupTranslations: PopupResult[],
  userSettings: IUserSetting,
  currentDatasource: string
): PopupResult[] | undefined {
  //filter language
  let filteredPopups = popupTranslations.filter(
    (item: PopupResult) =>
      item.language &&
      item.language.toLocaleLowerCase() ===
        userSettings.Language.toLocaleLowerCase()
  )

  //filter function
  filteredPopups = filteredPopups.filter(
    (item: PopupResult) =>
      item.functions &&
      //functions not set, or
      (item.functions.length === 0 ||
        //function found
        !!item.functions.find(
          (functionName: string) =>
            functionName.toLocaleLowerCase() ===
            userSettings.Function.toLocaleLowerCase()
        ))
  )

  //filter country
  filteredPopups = filteredPopups.filter(
    (item: PopupResult) =>
      item.countries &&
      //countrys not set, or
      (item.countries.length === 0 ||
        //country found
        !!item.countries.find(
          (country: string) =>
            country.toLocaleLowerCase() ===
            userSettings.Country.toLocaleLowerCase()
        ))
  )

  //filter source
  filteredPopups = filteredPopups.filter(
    (item: PopupResult) =>
      item.sources &&
      //sources not set, or
      (item.sources.length === 0 ||
        //source found
        !!item.sources.find(
          (source: string) =>
            source.toLocaleLowerCase() === currentDatasource.toLocaleLowerCase()
        ))
  )

  return filteredPopups
}

/**
 * Get once per interval popups
 * @param popupTranslations popup list
 * @returns filtered popup list
 */
function getPopupOncePerInterval(
  popupTranslations: PopupResult[] | undefined
): PopupResult[] | undefined {
  if (!popupTranslations) return

  const oncePerIntervalPopups = popupTranslations.filter(
    (item: PopupResult) =>
      item.display === PopupDisplayTypes.oncePerInterval &&
      isPopupDateValid(item)
  )

  return oncePerIntervalPopups
}

/**
 * Get once per session popups
 * @param popupTranslations popup list
 * @returns filtered popup list
 */
function getPopupOnceEverySession(
  popupTranslations: PopupResult[] | undefined
): PopupResult[] | undefined {
  if (!popupTranslations) return

  const oncePerIntervalPopups = popupTranslations.filter(
    (item: PopupResult) =>
      item.display === PopupDisplayTypes.onEverySession &&
      isPopupDateValid(item)
  )

  return oncePerIntervalPopups
}

/**
 * Get once per user popups
 * @param popupTranslations popup list
 * @returns filtered popup list
 */
export function getPopupOncePerUser(
  popupTranslations: PopupResult[] | undefined
): PopupResult | undefined {
  if (!popupTranslations) return

  //find first oncePerUserPopup
  const firstOncePerUser = popupTranslations.find(
    (item: PopupResult) =>
      item.display === PopupDisplayTypes.oncePerUser && isPopupDateValid(item)
  )

  return firstOncePerUser
}

/**
 * Is popup in current time range
 * @param popupResult popup
 * @returns is visible
 */
function isPopupDateValid(popupResult: PopupResult): boolean {
  if (popupResult) {
    return (
      (!popupResult.dateStart ||
        dayjs().diff(new Date(popupResult.dateStart), 'day', true) > 0) &&
      (!popupResult.dateEnd ||
        dayjs().diff(new Date(popupResult.dateEnd), 'day', true) - 1 < 0)
    )
  }
  return false
}

/**
 * Filter already shown popups from list
 * @param popupTranslations popup list
 * @param userSettings user settings
 * @returns filtered popup list
 */
function filterAlreadyDisplayedPopups(
  popupTranslations: PopupResult[],
  userSettings: IUserSetting
): PopupResult[] {
  popupTranslations = popupTranslations.filter(
    (item: PopupResult) =>
      !userSettings.PopupList ||
      !userSettings.PopupList.find((userSettingsPopup: PopupUserSettings) => {
        return (
          userSettingsPopup.id === item.id &&
          (!item.intervalHours ||
            dayjs().diff(new Date(userSettingsPopup.lastSeen), 'hour', true) <
              parseFloat(item.intervalHours))
        )
      })
  )

  //filter once per session popups
  popupTranslations = popupTranslations.filter(
    (item: PopupResult) =>
      !localOncePerSessionMemory.find(
        (localPopupUserSetting: PopupUserSettings) =>
          item.id === localPopupUserSetting.id
      )
  )

  return popupTranslations
}

/**
 * Remember already shown popups
 * @param userSettings user settings
 * @param popup closed popup
 */
export function markPopupAsShown(
  userSettings: IUserSetting,
  popup?: PopupResult
): void {
  if (!popup) {
    return
  }

  if (!userSettings.PopupList) {
    userSettings.PopupList = []
  }

  if (popup.display !== PopupDisplayTypes.onEverySession) {
    const popupAlreadyDisplayed = userSettings.PopupList.find(
      (popupUserSetting) => popupUserSetting.id === popup.id
    )

    //update lastseen for interval on redrawn, also validuntil (maybe updated in backend)
    if (popupAlreadyDisplayed) {
      popupAlreadyDisplayed.lastSeen = new Date()
      popupAlreadyDisplayed.validUntil = popup.dateEnd
      return
    }

    //memory shown popups
    const newPopupUserSetting: PopupUserSettings = {
      id: popup.id,
      lastSeen: new Date(),
      validUntil: popup.dateEnd,
      display: popup.display
    }
    userSettings.PopupList.push(newPopupUserSetting)
  }

  //handle once per session
  if (popup.display === PopupDisplayTypes.onEverySession) {
    const popupAlreadyDisplayed = userSettings.PopupList.find(
      (popupUserSetting) => popupUserSetting.id === popup.id
    )

    if (!popupAlreadyDisplayed) {
      //memory shown popups
      const newPopupUserSetting: PopupUserSettings = {
        id: popup.id,
        lastSeen: new Date(),
        validUntil: popup.dateEnd,
        display: popup.display
      }
      localOncePerSessionMemory.push(newPopupUserSetting)
    }
  }
}

/**
 * Delete old unused popups from usersettings list
 * @param userSettings user settings
 * @returns boolean, true if usersettings needs to be saved
 */
export function cleanupOldPopupUserSettings(
  popupTranslations: PopupResult[],
  userSettings: IUserSetting
): boolean {
  if (!userSettings.PopupList) {
    userSettings.PopupList = []
  }

  let updateNeeded = false
  //update all valid until values
  if (popupTranslations && popupTranslations.length > 0) {
    popupTranslations.forEach((popup: PopupResult) => {
      const currentPupupUserSetting = userSettings.PopupList.find(
        (popupUserSettings: PopupUserSettings) =>
          popupUserSettings.id === popup.id
      )

      if (
        currentPupupUserSetting &&
        currentPupupUserSetting.validUntil !== popup.dateEnd
      ) {
        currentPupupUserSetting.validUntil = popup.dateEnd
        updateNeeded = true
      }
    })
  }

  //filter outdated popups
  const newPopupList = userSettings.PopupList.filter(
    (popupUserSettings: PopupUserSettings) => {
      return (
        !popupUserSettings.validUntil ||
        (popupUserSettings.validUntil &&
          dayjs().diff(new Date(popupUserSettings.validUntil), 'days', true) <
            0)
      )
    }
  )

  if (userSettings.PopupList.length !== newPopupList.length) {
    userSettings.PopupList = newPopupList
    updateNeeded = true
  }
  return updateNeeded
}
