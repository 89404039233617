import { FeaturedResult } from 'components/models/FeaturedResult'
import { Reducer } from 'redux'
import {
  ResultsAEMActions,
  ResultsAEMActionTypes,
  IFetchSuccess,
  IFetchSuccessCombined
} from './actions'
import { IKpmgWebsitesResult } from 'components/models/KpmgWebsiteResult'

export interface IResultsAEMStore {
  hasError: boolean
  hasResultsBeenFetched: boolean
  featuredResults: FeaturedResult[]
  results: IKpmgWebsitesResult[]
  resultsCombined: IKpmgWebsitesResult[]
  resultsCombinedQuery: string
  resultCount: number
  executionTime: number
  currentPage: number
}

const initialState: IResultsAEMStore = {
  hasError: false,
  hasResultsBeenFetched: false,
  featuredResults: [],
  resultCount: -1,
  currentPage: 1,
  results: [],
  resultsCombined: [],
  resultsCombinedQuery: '',
  executionTime: 0
}

const reducers: Reducer<IResultsAEMStore, ResultsAEMActions> = (
  state: IResultsAEMStore = initialState,
  action:
    | { type: ResultsAEMActionTypes.INITIALIZE_RESULTS_AEM; payload: any }
    | { type: 'SET_CURRENT_PAGE'; payload: any }
    | ResultsAEMActions
) => {
  switch (action.type) {
    case 'SET_CURRENT_PAGE':
      return {
        ...state,
        currentPage: action.payload || 0
      }
    case ResultsAEMActionTypes.INITIALIZE_RESULTS_AEM:
      if (!(action as any).payload) {
        return initialState
      }
      const { resultsAEM } = (action as any).payload
      return {
        ...state,
        currentPage: resultsAEM.currentPage || 1
      }
    case ResultsAEMActionTypes.FETCH_REQUEST:
      const { resetCombined, searchQuery } = (action as any).payload
      return {
        ...state,
        hasError: false,
        hasResultsBeenFetched: false,
        executionTime: 0,
        resultCount: -1,
        results: [],
        resultsCombined: resetCombined ? [] : state.resultsCombined,
        resultsCombinedQuery: searchQuery
      }
    case ResultsAEMActionTypes.FETCH_FAILURE:
      return {
        ...state,
        hasError: true,
        hasResultsBeenFetched: true,
        resultCount: 0
      }
    case ResultsAEMActionTypes.FETCH_SUCCESS:
      const { response, featuredResults } = (action as IFetchSuccess).payload
      return {
        ...state,
        hasError: false,
        hasResultsBeenFetched: true,
        executionTime: response.executionTime,
        resultCount: response.resultCount,
        results: response.queryResults,
        featuredResults:
          response.currentPage === 1 ? featuredResults : state.featuredResults,
        currentPage: response.currentPage
      }
    case ResultsAEMActionTypes.FETCH_SUCCESS_COMBINED:
      const { response: responseCombined } = (action as IFetchSuccessCombined)
        .payload
      return {
        ...state,
        hasError: false,
        resultsCombined: responseCombined.queryResults
      }
    default:
      return state
  }
}

export default reducers
