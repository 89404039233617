import { FeaturedResult } from 'components/models/FeaturedResult'
import { Reducer } from 'redux'
import {
  ResultsKlardenkerActions,
  ResultsKlardenkerActionTypes,
  IFetchSuccess,
  IFetchSuccessCombined
} from './actions'
import { IKlardenkerResult } from 'components/models/KlardenkerResult'

export interface IResultsKlardenkerStore {
  hasError: boolean
  hasResultsBeenFetched: boolean
  featuredResults: FeaturedResult[]
  results: IKlardenkerResult[]
  resultsCombined: IKlardenkerResult[]
  resultsCombinedQuery: string
  resultCount: number
  executionTime: number
  currentPage: number
}

const initialState: IResultsKlardenkerStore = {
  hasError: false,
  hasResultsBeenFetched: false,
  resultCount: -1,
  featuredResults: [],
  results: [],
  resultsCombined: [],
  resultsCombinedQuery: '',
  executionTime: 0,
  currentPage: 1
}

const reducers: Reducer<IResultsKlardenkerStore, ResultsKlardenkerActions> = (
  state: IResultsKlardenkerStore = initialState,
  action:
    | {
        type: ResultsKlardenkerActionTypes.INITIALIZE_RESULTS_KLARDENKER
        payload: any
      }
    | { type: 'SET_CURRENT_PAGE'; payload: any }
    | ResultsKlardenkerActions
) => {
  switch (action.type) {
    case 'SET_CURRENT_PAGE':
      return {
        ...state,
        currentPage: action.payload || 0
      }
    case ResultsKlardenkerActionTypes.INITIALIZE_RESULTS_KLARDENKER:
      if (!(action as any).payload) {
        return initialState
      }
      const { resultsKlardenker } = (action as any).payload
      return {
        ...state,
        currentPage: resultsKlardenker.currentPage || 1
      }
    case ResultsKlardenkerActionTypes.FETCH_REQUEST:
      const { resetCombined, searchQuery } = (action as any).payload
      return {
        ...state,
        hasError: false,
        hasResultsBeenFetched: false,
        executionTime: 0,
        resultCount: -1,
        results: [],
        resultsCombined: resetCombined ? [] : state.resultsCombined,
        resultsCombinedQuery: searchQuery
      }
    case ResultsKlardenkerActionTypes.FETCH_FAILURE:
      return {
        ...state,
        hasError: true,
        hasResultsBeenFetched: true,
        resultCount: 0
      }
    case ResultsKlardenkerActionTypes.FETCH_SUCCESS:
      const { response, featuredResults } = (action as IFetchSuccess).payload
      return {
        ...state,
        hasError: false,
        hasResultsBeenFetched: true,
        executionTime: response.executionTime,
        resultCount: response.resultCount,
        featuredResults:
          response.currentPage === 1 ? featuredResults : state.featuredResults,
        results: response.queryResults,
        currentPage: response.currentPage
      }
    case ResultsKlardenkerActionTypes.FETCH_SUCCESS_COMBINED:
      const { response: responseCombined } = (action as IFetchSuccessCombined)
        .payload
      return {
        ...state,
        hasError: false,
        resultsCombined: responseCombined.queryResults
      }
    default:
      return state
  }
}

export default reducers
