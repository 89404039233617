import { IPeopleResult } from 'components/models/PeopleResult'
import { IDataSource } from 'constants/datasourcesConfiguration'
import { KnownEntities, buildEntities } from './entityRecognition'
import { IEntity } from 'store/Search/reducers'

export const displayNerSuggesion = (
  useCognitiveSearch: boolean,
  currentDs: string,
  currentDataSources: IDataSource[],
  peopleHasResultsBeenFetched: boolean,
  peopleResults: IPeopleResult[],
  spellCorrected: string,
  searchQuery: string,
  nerEntityData: IEntity[] | null
) => {
  //hide on spell correct message
  if (spellCorrected && searchQuery !== spellCorrected) {
    return false
  }

  // AI feature is disabled
  if (!useCognitiveSearch) {
    return false
  }

  // Search is done in the people vertical
  if (currentDs === 'people') {
    return false
  }

  // People vertical is disabled
  const isPeopleDsEnabled = !!currentDataSources.find(
    (currentDataSources: IDataSource) =>
      currentDataSources.name.toLowerCase() === 'people' &&
      currentDataSources.enabled &&
      currentDataSources.active
  )
  if (!isPeopleDsEnabled) {
    return false
  }

  // People vertical has no results
  const hasPeopleDsResults =
    peopleHasResultsBeenFetched && peopleResults.length > 0

  if (!hasPeopleDsResults) {
    return false
  }

  // NER suggestions
  const entities = buildEntities(nerEntityData)

  let peopleEntityFound = false
  for (let index = 0; index < entities.length; index++) {
    const entity = entities[index]
    if (entity && entity.key === KnownEntities.PERSON) {
      peopleEntityFound = true
      break
    }
  }

  return peopleEntityFound
}
