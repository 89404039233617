import React from 'react'
import { Typography, Link, Box, Card, CardContent } from '@mui/material'
import { makeStyles } from '@mui/styles'
import PeoplePlaceholder from 'images/people_placeholder.png'
import { externalLinkClicked } from 'utils/tracking'
import highlightedText, { prepareHighlightWords } from 'utils/highlightText'
import { removeQueryParam } from 'utils/queryParams'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import ResultsStore from 'store/Results'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import GetAppIcon from '@mui/icons-material/GetApp'
import DescriptionIcon from '@mui/icons-material/Description'
import { getStylesContentFinderResult } from 'styles/results/ContentFinderResult'
import { IDeviceSetting } from 'utils/deviceSettings'
import TooltipTitle from '../common/TooltipTitle'
import { CustomDescription } from '../common/CustomDescription'

export interface ContentFinderProps {
  title: string
  pictureUrl: string
  link: string
  date: string
  fileSize: string
  fileName: string
  description: string
  likes: number
  views: number
  index: number
  searchQuery?: string
  deviceSettings: IDeviceSetting
}

export function ContentFinderResult(props: ContentFinderProps): JSX.Element {
  const navigateFunction = useNavigate()
  const classes = getStylesContentFinderResult()
  const {
    title,
    pictureUrl,
    link,
    date,
    fileSize,
    fileName,
    description,
    likes,
    views,
    index,
    searchQuery,
    deviceSettings
  } = props

  const dispatch = useDispatch()

  const [isVisited, setVisited] = React.useState(
    useSelector((state: any) =>
      ResultsStore.selectors.isResultVisited(state, link)
    )
  )

  const setIsVisited = () => {
    if (!isVisited) {
      dispatch(ResultsStore.actions.storeResultHistory(link))
      setVisited(true)
    }
  }

  const contentFinderStyles = makeStyles((theme) => ({
    contentFinderPic: {
      flex: '0 0 116px',
      width: '116px',
      backgroundImage:
        'url(' +
        (pictureUrl
          ? pictureUrl.replace('MThumb', 'LThumb').split(' ').join('%20')
          : '') +
        '),url(' +
        PeoplePlaceholder +
        ') !important',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center'
    },
    defaultcontentFinderPic: {
      backgroundImage: 'url(' + PeoplePlaceholder + ')'
    }
  }))
  const contentFinderClasses = contentFinderStyles()

  return (
    <Card className={classes.contentFinderCard}>
      <Box className={classes.contentFinderContentWrapper}>
        <div
          className={
            contentFinderClasses.contentFinderPic +
            ' ' +
            contentFinderClasses.defaultcontentFinderPic
          }
        />
        <Box className={classes.contentFinderDetails}>
          <CardContent className={classes.contentFinderContent}>
            <Link
              underline={'hover'}
              href={link}
              onClick={() => {
                setIsVisited()
                removeQueryParam(navigateFunction, 'cntx')
                externalLinkClicked({
                  title,
                  url: link,
                  index
                })
              }}
              {...(!deviceSettings.isMobile
                ? {
                    onAuxClick: (event: any) => {
                      setIsVisited()
                      removeQueryParam(navigateFunction, 'cntx')
                      externalLinkClicked({
                        title,
                        url: link,
                        index,
                        wasRightClicked: event?.button === 2 ? true : false
                      })
                    }
                  }
                : {})}
              {...(deviceSettings.openLinksInNewTab === true
                ? { target: '_blank', rel: 'noreferrer' }
                : {})}
            >
              <Typography
                variant="h5"
                color="primary"
                className={`${classes.title} ${
                  isVisited ? classes.isVisitedResult : ''
                }`}
                component="div"
              >
                <TooltipTitle title={title ? title : ''} singleLine={true} />
              </Typography>
            </Link>
            <Box className={classes.contentFinderFileInfo}>
              {date && (
                <Box className={classes.contentFinderFileInfoItem}>
                  <CalendarTodayIcon className={classes.contentFinderIcon} />{' '}
                  {date}
                </Box>
              )}
              {fileSize && (
                <Box className={classes.contentFinderFileInfoItem}>
                  <GetAppIcon className={classes.contentFinderIcon} />{' '}
                  {fileSize}
                </Box>
              )}
              {fileName && (
                <Box className={classes.contentFinderFileInfoItem}>
                  <DescriptionIcon className={classes.contentFinderIcon} />{' '}
                  {fileName}
                </Box>
              )}
            </Box>
            <Typography
              variant="body2"
              color="secondary"
              className={classes.contentFinderBodyText}
              component="div"
            >
              <CustomDescription
                descriptionValue={highlightedText(
                  description,
                  prepareHighlightWords(searchQuery, description, []),
                  true
                )}
                maxLine="1"
              />
            </Typography>
          </CardContent>
        </Box>
      </Box>
      <Box className={classes.contentFinderCardFooter}>
        <Typography
          variant="body2"
          color="secondary"
          className={classes.contentFinderFooterText}
          component="div"
        >
          Gefällt: {likes} Mal | {views} Mal Angeschaut
        </Typography>
      </Box>
    </Card>
  )
}
