interface IImageResult {
  src: string
  width: number
}

export const generateImage = (
  baseImageUrl: string,
  teaser_image_urls: IImageResult[] | undefined | null
) => {
  let resultImageUrl = baseImageUrl

  if (teaser_image_urls && teaser_image_urls.length > 0) {
    let imageUrls = teaser_image_urls ? teaser_image_urls : []

    imageUrls = imageUrls.filter((image: IImageResult) => {
      return image.width < 180 ? false : true
    })

    if (imageUrls.length > 0) {
      const imageToUse = imageUrls
        .sort((res: IImageResult, obj: IImageResult) => {
          return obj.width > res.width ? -1 : 1
        })
        .pop()

      if (imageToUse && imageToUse.src) {
        resultImageUrl = imageToUse.src
      }
    }
  }

  if (resultImageUrl.indexOf('?') !== -1) {
    resultImageUrl = resultImageUrl.split('?')[0]
  }

  return resultImageUrl
}
