import React from 'react'
import * as Config from 'config'
import { ResultRightWidgetGenerator } from './interfaces'
import RightMeercatWidget from 'components/contents/resultpages/widgets/RightMeercatWidget'
import { stripHtml } from 'utils/string'
import { trackException } from 'utils/tracking'
import { FetchWithCache } from 'utils/api'
import { IDeviceSetting } from 'utils/deviceSettings'
import { IUserSetting } from 'utils/userSettings'
import { IFindConfiguration } from 'store/Settings/reducers'
import { getSPOnlineToken, renewAuthorizationToken } from 'utils/token'
import {
  IOneIntranetResultQuery,
  oneIntranetDefaultQuery,
  removeStopWordsFromQuery
} from 'utils/oneIntranet'
import { IMeercatResult } from 'components/models/MeercatResult'
import { ISynonymsApplied } from 'components/models/SynonymsApplied'
import { IAADState } from 'store/Auth/reducers'

export const rightMeercatWidgetGenerator: ResultRightWidgetGenerator = {
  fetch(
    aadInfo: IAADState,
    searchQuery: string,
    deviceSettings: IDeviceSetting,
    userSettings: IUserSetting,
    findConfiguration: IFindConfiguration,
    useCognitiveSearch: boolean
  ): Promise<{
    queryResults: IMeercatResult[]
    synonymsApplied: ISynonymsApplied[]
  }> {
    return new Promise<{
      queryResults: IMeercatResult[]
      synonymsApplied: ISynonymsApplied[]
    }>((resolve, reject) => {
      const fetchMeercat = async () => {
        if (!searchQuery || searchQuery === '' || searchQuery === '""') {
          trackException(
            'Error in fetching Meercat right widget in rightMeercatWidget.tsx',
            new Error('Empty query. Please specify a valid query text')
          )
          reject()
          return
        }

        let spoToken = ''
        if (Config.LOCAL_DEVELOPMENT.toString() === 'false') {
          spoToken = await getSPOnlineToken(aadInfo.instance, aadInfo.accounts)
          if (spoToken === '') {
            throw new Error(
              'Authentication: Cannot renew SPO authentication token'
            )
          }
        }

        const requestBody: IOneIntranetResultQuery = Object.assign(
          {},
          oneIntranetDefaultQuery
        )
        requestBody.querytext = searchQuery
        requestBody.rowlimit = 10
        requestBody.rowsperpage = 10
        requestBody.selectproperties = 'Title,Description,FreshTax5,Path,'
        requestBody.origin = 'solutioncompass'
        requestBody.cognitiveEnabled = false

        // Get and check authentication token
        const esToken = await renewAuthorizationToken(
          aadInfo.accessToken,
          aadInfo.instance,
          aadInfo.accounts
        )

        if (
          useCognitiveSearch &&
          findConfiguration.CognitiveSearchEnabled &&
          esToken !== ''
        ) {
          let searchQueryLanguage = ''
          const detectLanguageApiUrl = `${
            Config.APIM_BASE_URL
          }searchapi/detectlanguage?searchQuery=${encodeURIComponent(
            searchQuery
          )}`

          const languageResponse = await FetchWithCache(detectLanguageApiUrl, {
            method: 'POST',
            headers: {
              accept: 'application/json',
              'content-type': 'application/json',
              'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
              Authorization: `Bearer ${esToken}`
            }
          })
          if (
            languageResponse &&
            !languageResponse.hasError &&
            languageResponse.responseJSON
          ) {
            const languageJSON = languageResponse.responseJSON
            if (languageJSON && languageJSON.responseCode === 200) {
              searchQueryLanguage = languageJSON.language
            }
          }
          requestBody.querytext = removeStopWordsFromQuery(
            searchQuery,
            searchQueryLanguage,
            useCognitiveSearch,
            findConfiguration.CognitiveSearchEnabled
          )
          requestBody.cognitiveEnabled = true
        }

        const response = await FetchWithCache(
          `${Config.APIM_BASE_URL}oneintranetapi/postsearchoi`,
          {
            method: 'POST',
            headers: {
              'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
              'content-type': 'application/json',
              Authorization: `Bearer ${spoToken}`,
              'Authorization-OnPrem': `${esToken}`
            },
            body: JSON.stringify(requestBody)
          },
          undefined,
          true,
          'widget_meercat',
          undefined,
          searchQuery
        )

        let results
        if (!response || response.hasError || !response.responseJSON) {
          if (
            !response ||
            !response.errorResponse ||
            response.errorResponse.responseCode !== 499
          ) {
            trackException(
              'Error in fetching Meercat right widget in rightMeercatWidget.tsx',
              new Error(
                response && response.errorResponse
                  ? response.errorResponse.responseCode +
                    ':' +
                    response.errorResponse.message
                  : ''
              )
            )
          }
          reject()
          return
        } else {
          results = response.responseJSON
        }

        if (
          results &&
          results.QueryResults &&
          results.QueryResults.length > 0
        ) {
          const meercatItems: IMeercatResult[] = []
          results.QueryResults.map((result: any) => {
            if (stripHtml(result.Title).trim() !== '‎') {
              const meercatItem: IMeercatResult = {
                country: result.FreshTax5 ? result.FreshTax5 : '',
                description: result.Description
                  ? stripHtml(result.Description)
                  : '',
                link: result.Path ? result.Path : '',
                title: result.Title ? stripHtml(result.Title) : ''
              }
              meercatItems.push(meercatItem)
            }

            return true
          })
          if (meercatItems.length > 2) {
            resolve({
              queryResults: meercatItems,
              synonymsApplied: results.SynonymsApplied
            })
          } else {
            reject()
          }
        } else {
          reject()
        }
      }

      try {
        fetchMeercat()
      } catch (error) {
        trackException(
          'Error in fetching Meercat right widget in rightMeercatWidget.tsx',
          error
        )
        reject()
      }
    })
  },
  generate(
    resultsRaw: {
      queryResults: IMeercatResult[]
      synonymsApplied: ISynonymsApplied[]
    },
    query: string
  ): Promise<React.ReactElement> {
    return new Promise<React.ReactElement>((resolve, reject) => {
      const generateMeercat = () => {
        const results = resultsRaw.queryResults
        if (results) {
          resolve(
            <RightMeercatWidget
              searchQuery={query}
              meercatResults={results}
              synonymsApplied={resultsRaw.synonymsApplied}
            />
          )
        } else {
          reject()
        }
      }

      try {
        generateMeercat()
      } catch (error) {
        trackException(
          'Error in generating Meercat right widget in rightMeercatWidget.tsx',
          error
        )
        reject()
      }
    })
  }
}
