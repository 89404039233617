import React, {
  Dispatch,
  useEffect,
  useCallback,
  useRef,
  useState
} from 'react'
import { Box } from '@mui/material'
import { connect } from 'react-redux'
import 'Placeholder.css'
import { Store } from 'store'
import UserSettingsStore from 'store/UserSettings'
import SettingsStore from 'store/Settings'
import ChatStore from 'store/ResultsChat'
import PageStay from 'components/HOC/PageStay'
import ErrorPage from 'components/contents/common/ErrorPage'
import { trackException } from 'utils/tracking'
import { useReactRouterQueryStringInterface } from 'utils/useQueryState'
import { authorizeResultPage } from 'utils/authorization'
import createDOMPurify from 'dompurify'
import { dataSourcesTabs } from 'constants/constants'
import RightTopWidgetContainer from './widgetscontainer/RightTopWidgetContainer'
import RightWidgetContainer from './widgetscontainer/RightWidgetContainer'
import { getStylesResults } from 'styles/contents/resultpages/Results'
import { useFilter } from 'utils/filters'
import { ISeoCheckerData } from 'utils/seoChecker'
import { KPMGFindGlobalVariables } from 'store/KPMGFindGlobalVariables'
import { IChatRequest } from 'components/models/Chat'
import ChatMessage from './chat/ChatMessage'
import ChatPrompt from './chat/ChatPrompt'
import { getStylesResultsChat } from 'styles/contents/resultpages/ResultsChat'
import queryString from 'query-string'
import { removeQueryParam } from 'utils/queryParams'
import { useNavigate, useLocation } from 'react-router-dom'

export interface ResultsChatProps {
  appliedFilters: any
  authToken: string
  setSeoCheckerModal: Dispatch<boolean>
  setSEOCheckerModalData: Dispatch<ISeoCheckerData>
}

type AllProps = ReturnType<typeof mapStateToProps> & ResultsChatProps

function ResultsChat(props: AllProps): JSX.Element {
  const { userSettings, deviceSettings, chatRequestList } = props
  const hasErrorStore = false

  const DOMPurify = createDOMPurify(window)
  const resultClasses = getStylesResults()
  const resultChatClasses = getStylesResultsChat()

  const { getQueryString } = useReactRouterQueryStringInterface()
  const queryStringFromUrl = getQueryString()

  const [searchQuery] = useFilter('q')
  const [ds, setDs] = useState<string>('')
  const navigateFunction = useNavigate()
  const location = useLocation()

  useEffect(() => {
    KPMGFindGlobalVariables.setCurrentTab(dataSourcesTabs.chat)

    const queryParams = queryString.parse(queryStringFromUrl)
    if (queryParams && queryParams.chatds) {
      setDs(queryParams.chatds as string)
      removeQueryParam(navigateFunction, 'chatds')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setup = useCallback(() => {
    try {
      authorizeResultPage(
        'Chat',
        userSettings,
        deviceSettings,
        DOMPurify.sanitize(location && location.search ? location.search : '')
      )
    } catch (error) {
      trackException('Error in authorizeResultPage in ResultsChat.tsx', error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryStringFromUrl])

  useEffect(() => {
    setup()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryStringFromUrl])

  //auto scroll chat area to bottom
  const chatAnswerContainerRef = useRef()
  useEffect(() => {
    if (chatAnswerContainerRef && chatAnswerContainerRef.current)
      (chatAnswerContainerRef.current as any).scrollTop = (
        chatAnswerContainerRef.current as any
      ).scrollHeight
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatAnswerContainerRef.current, chatRequestList])

  return (
    <Box>
      {!hasErrorStore && !!searchQuery && (
        <Box className={resultClasses.container}>
          <Box
            id={'con-resultvertical-01'}
            className={resultClasses.resultsContainer}
          >
            <Box
              style={{
                height: '100%'
              }}
            >
              <Box
                id={'con-resultvertical-section-02'}
                style={{
                  height: '100%',
                  maxHeight: '80vh'
                }}
              >
                <div className={resultChatClasses.chatContainer}>
                  <Box
                    className={resultChatClasses.chatContentContainer}
                    ref={chatAnswerContainerRef}
                  >
                    {chatRequestList.map((item: IChatRequest) => {
                      return (
                        <>
                          <ChatMessage type={'request'} item={item} />
                          <ChatMessage type={'answer'} item={item} />
                        </>
                      )
                    })}
                  </Box>
                  <Box className={resultChatClasses.chatPromptContainer}>
                    <ChatPrompt autoFocus={true} initialDataSource={ds} />
                  </Box>
                </div>
              </Box>
            </Box>
          </Box>
          {!deviceSettings.renderMobile && (
            <Box className={resultClasses.advertisementContainer}>
              <RightTopWidgetContainer
                searchQuery={searchQuery}
                scope={'chat'}
              />
              <RightWidgetContainer />
            </Box>
          )}
        </Box>
      )}
      {hasErrorStore && <ErrorPage dataSource="chat" />}
    </Box>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    userSettings: UserSettingsStore.selectors.getUserSettings(state),
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state),
    chatRequestList: ChatStore.selectors.getChatRequests(state)
  }
}

export default PageStay(connect(mapStateToProps)(ResultsChat))
