import React, { Dispatch, useMemo } from 'react'
import { connect } from 'react-redux'
import { useIntl } from 'react-intl'
import ReactPlaceholder from 'react-placeholder'
import { Box, Fade } from '@mui/material'
import 'Placeholder.css'
import { Store } from 'store'
import ResultsMppStore from 'store/ResultsMpp'
import Pagination from '../Pagination'
import { trackException } from 'utils/tracking'
import SearchSuggestions from 'components/contents/common/SearchSuggestions'
import { IDeviceSetting } from 'utils/deviceSettings'
import FeaturedResults from './FeaturedResults'
import LeftWidgetContainer from './widgetscontainer/LeftWidgetContainer'
import { getStylesResults } from 'styles/contents/resultpages/Results'
import { useFilter } from 'utils/filters'
import { MPPResult } from '../results/MPPResult'
import createDOMPurify from 'dompurify'
import { IUserSetting } from 'utils/userSettings'
import { CognitiveMessage } from '../common/CognitiveMessage'

interface ResultsMppMobileProps {
  searchQuery: any
  deviceSettings: IDeviceSetting
  userSettings: IUserSetting
  findConfigCognitiveSearchEnabled: boolean
  cognitiveSearchEnabled: boolean
  showCognitiveMessage: boolean
  setShowCognitiveMessage: Dispatch<boolean>
  setUserSettings: Dispatch<IUserSetting>
  initialItemClick: boolean
  setInitialItemClick: (value: boolean) => void
}

type AllMobileProps = ReturnType<typeof mapStateToProps> & ResultsMppMobileProps

function ResultsMppMobile(props: AllMobileProps) {
  const {
    hasResultsBeenFetched,
    resultsCount,
    featuredResults,
    resultsCombined,
    searchQuery,
    deviceSettings,
    userSettings,
    findConfigCognitiveSearchEnabled,
    cognitiveSearchEnabled,
    showCognitiveMessage,
    setShowCognitiveMessage,
    setUserSettings,
    synonymsApplied,
    initialItemClick,
    setInitialItemClick
  } = props
  const DOMPurify = createDOMPurify(window)
  const resultClasses = getStylesResults()
  const intl = useIntl()
  const [currentPage] = useFilter('page', '1')

  const returnAllItems = (type: string) => {
    if (!resultsCombined || resultsCombined.length < 1) {
      return []
    }
    const items = []

    try {
      const firstResults =
        resultsCombined.length < 4 ? resultsCombined.length : 3

      if (type === 'first') {
        for (let i = 0; i < firstResults; i++) {
          items.push(
            <MPPResult
              title={DOMPurify.sanitize(resultsCombined[i].title)}
              body={DOMPurify.sanitize(resultsCombined[i].fragment)}
              url={DOMPurify.sanitize(resultsCombined[i].url)}
              owner={DOMPurify.sanitize(resultsCombined[i].owner)}
              key={i}
              index={i}
              searchQuery={DOMPurify.sanitize(searchQuery)}
              deviceSettings={deviceSettings}
              serviceLines={resultsCombined[i].additional}
              manualName={DOMPurify.sanitize(resultsCombined[i].manualTitle)}
              synonymsApplied={synonymsApplied}
              initialItemClick={initialItemClick}
              setInitialItemClick={setInitialItemClick}
            />
          )
        }
      } else {
        for (let i = firstResults; i < resultsCombined.length; i++) {
          items.push(
            <MPPResult
              title={DOMPurify.sanitize(resultsCombined[i].title)}
              body={DOMPurify.sanitize(resultsCombined[i].fragment)}
              url={DOMPurify.sanitize(resultsCombined[i].url)}
              owner={DOMPurify.sanitize(resultsCombined[i].owner)}
              key={i}
              index={i}
              searchQuery={DOMPurify.sanitize(searchQuery)}
              deviceSettings={deviceSettings}
              serviceLines={resultsCombined[i].additional}
              manualName={DOMPurify.sanitize(resultsCombined[i].manualTitle)}
              synonymsApplied={synonymsApplied}
              initialItemClick={initialItemClick}
              setInitialItemClick={setInitialItemClick}
            />
          )
        }
      }
    } catch (error) {
      trackException('Error returning all items in ResultsMPPMobile.tsx', error)
    }
    return items
  }

  const firstItems = useMemo(
    () => returnAllItems('first'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resultsCombined, intl]
  )

  const restItems = useMemo(
    () => returnAllItems('rest'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resultsCombined, intl]
  )

  return (
    <Box className={resultClasses.container}>
      <Box className={resultClasses.resultsContainer}>
        <SearchSuggestions
          wildCardActive={false}
          resultCount={resultsCount}
          resultsFetchend={hasResultsBeenFetched}
        />

        <ReactPlaceholder
          ready={
            hasResultsBeenFetched || Number(currentPage) > 1 ? true : false
          }
          type="text"
          rows={4}
          showLoadingAnimation={true}
          className={resultClasses.placeholder}
        >
          <Fade
            in={hasResultsBeenFetched || Number(currentPage) > 1 ? true : false}
            timeout={600}
          >
            <Box>
              {cognitiveSearchEnabled &&
                showCognitiveMessage &&
                synonymsApplied &&
                findConfigCognitiveSearchEnabled &&
                synonymsApplied.length > 0 && (
                  <CognitiveMessage
                    synonymsApplied={synonymsApplied}
                    setShowCognitiveMessage={setShowCognitiveMessage}
                    userSettings={userSettings}
                    setUserSettings={setUserSettings}
                  />
                )}
              {resultsCombined && resultsCount > 0 && (
                <FeaturedResults featuredResults={featuredResults} />
              )}
              {firstItems}
            </Box>
          </Fade>
        </ReactPlaceholder>
        {hasResultsBeenFetched &&
        (resultsCount < 1 || resultsCombined.length < 1) ? null : (
          <LeftWidgetContainer
            resultCount={resultsCount}
            hasResultsBeenFetched={hasResultsBeenFetched}
          />
        )}
        <ReactPlaceholder
          ready={hasResultsBeenFetched || Number(currentPage) > 1}
          type="text"
          rows={4}
          showLoadingAnimation={true}
          className={resultClasses.placeholder}
        >
          <Fade
            in={hasResultsBeenFetched || Number(currentPage) > 1}
            timeout={600}
          >
            <Box>
              {restItems}
              {resultsCount != null &&
                resultsCount > 0 &&
                hasResultsBeenFetched && (
                  <Pagination
                    {...props}
                    totalRecords={resultsCount}
                    pageLimit={20}
                    pageNeighbours={4}
                  />
                )}
            </Box>
          </Fade>
        </ReactPlaceholder>
        <ReactPlaceholder
          ready={hasResultsBeenFetched ? hasResultsBeenFetched : false}
          type="text"
          rows={4}
          showLoadingAnimation={true}
          className={resultClasses.placeholder}
        >
          <Box />
        </ReactPlaceholder>
      </Box>
    </Box>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    featuredResults: ResultsMppStore.selectors.getFeaturedResults(state),
    synonymsApplied: ResultsMppStore.selectors.getSynonymsApplied(state),
    resultsCombined: ResultsMppStore.selectors.getResultsCombined(state),
    resultsCount: ResultsMppStore.selectors.getResultCount(state),
    executionTime: ResultsMppStore.selectors.getExecutionTime(state),
    hasResultsBeenFetched:
      ResultsMppStore.selectors.hasResultsBeenFetched(state)
  }
}

export default connect(mapStateToProps)(ResultsMppMobile)
