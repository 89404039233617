import React, { useCallback, useEffect, useMemo } from 'react'
import { Box, Fade } from '@mui/material'
import { useIntl } from 'react-intl'
import ReactPlaceholder from 'react-placeholder'
import { connect } from 'react-redux'
import 'Placeholder.css'
import { Store } from 'store'
import AuthStore from 'store/Auth'
import UserSettingsStore from 'store/UserSettings'
import SettingsStore from 'store/Settings'
import ResultsAEMStore from 'store/ResultsAEM'
import SearchStore from 'store/Search'
import ResultMetaDataStore from 'store/ResultMetaData'
import ErrorPage from 'components/contents/common/ErrorPage'
import PageStay from 'components/HOC/PageStay'
import { AEMResult } from 'components/contents/results/AEMResult'
import Pagination from '../Pagination'
import { formatDisplayBody } from 'utils/intranet'
import { trackException } from 'utils/tracking'
import { useReactRouterQueryStringInterface } from 'utils/useQueryState'
import { authorizeResultPage } from 'utils/authorization'
import createDOMPurify from 'dompurify'
import { dateFormatOptions, dataSourcesTabs } from 'constants/constants'
import SearchSuggestions from 'components/contents/common/SearchSuggestions'
import ResultsAEMMobile from './ResultsAEMMobile'
import FeaturedResults from './FeaturedResults'
import RightTopWidgetContainer from './widgetscontainer/RightTopWidgetContainer'
import LeftWidgetContainer from './widgetscontainer/LeftWidgetContainer'
import RightWidgetContainer from './widgetscontainer/RightWidgetContainer'
import { getStylesResults } from 'styles/contents/resultpages/Results'
import { useFilter } from 'utils/filters'
import { KPMGFindGlobalVariables } from 'store/KPMGFindGlobalVariables'
import { useLocation } from 'react-router-dom'

export interface ResultsAEMProps {}

type AEMProps = ReturnType<typeof mapStateToProps> & ResultsAEMProps

function ResultsAEM(props: AEMProps) {
  const {
    authToken,
    featuredResults,
    results,
    resultsCount,
    hasResultsBeenFetched,
    hasErrorStore,
    userSettings,
    deviceSettings
  } = props
  const DOMPurify = createDOMPurify(window)
  const resultClasses = getStylesResults()
  const { getQueryString } = useReactRouterQueryStringInterface()
  const intl = useIntl()
  const queryStringFromUrl = getQueryString()

  const [currentPage] = useFilter('page', '1')
  const [searchQuery] = useFilter('q')
  const location = useLocation()

  useEffect(() => {
    KPMGFindGlobalVariables.setCurrentTab(dataSourcesTabs.aem)
  }, [])

  const setup = useCallback(
    async () => {
      try {
        authorizeResultPage(
          'kpmg.com',
          userSettings,
          deviceSettings,
          DOMPurify.sanitize(location && location.search ? location.search : '')
        )
      } catch (error) {
        trackException('Error in setup method in ResultsAEM.tsx', error)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queryStringFromUrl]
  )

  useEffect(() => {
    setup()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryStringFromUrl, authToken])

  const returnAllItems = (type: string) => {
    if (!results || results.length < 1) {
      return []
    }

    const items = []

    try {
      const firstResults = results.length < 4 ? results.length : 3

      if (type === 'first') {
        for (let i = 0; i < firstResults; i++) {
          const currentDate =
            results[i] && results[i].date !== ''
              ? intl.formatDate(
                  DOMPurify.sanitize(results[i].date),
                  dateFormatOptions
                )
              : ''
          const currentBody = DOMPurify.sanitize(results[i].body) || ''
          const displayBody = formatDisplayBody(currentDate, currentBody, intl)
          items.push(
            <AEMResult
              imageUrl={DOMPurify.sanitize(results[i].image)}
              title={DOMPurify.sanitize(results[i].title)}
              body={displayBody}
              url={DOMPurify.sanitize(results[i].url)}
              sources={DOMPurify.sanitize(results[i].tabType)}
              key={i}
              index={i}
              searchQuery={DOMPurify.sanitize(searchQuery)}
              deviceSettings={deviceSettings}
            />
          )
        }
      } else {
        for (let i = firstResults; i < results.length; i++) {
          const currentDate =
            results[i] && results[i].date !== ''
              ? intl.formatDate(
                  DOMPurify.sanitize(results[i].date),
                  dateFormatOptions
                )
              : ''
          const currentBody = DOMPurify.sanitize(results[i].body) || ''
          const displayBody = formatDisplayBody(currentDate, currentBody, intl)
          items.push(
            <AEMResult
              imageUrl={DOMPurify.sanitize(results[i].image)}
              title={DOMPurify.sanitize(results[i].title)}
              body={displayBody}
              url={DOMPurify.sanitize(results[i].url)}
              sources={DOMPurify.sanitize(results[i].tabType)}
              key={i}
              index={i}
              searchQuery={DOMPurify.sanitize(searchQuery)}
              deviceSettings={deviceSettings}
            />
          )
        }
      }
    } catch (error) {
      trackException('Error returning all items in ResultsAEM.tsx', error)
    }
    return items
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const firstItems = useMemo(() => returnAllItems('first'), [results, intl])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const restItems = useMemo(() => returnAllItems('rest'), [results, intl])

  return (
    <Box>
      {!hasErrorStore && !!searchQuery && (
        <>
          {deviceSettings.renderMobile ? (
            <ResultsAEMMobile
              searchQuery={searchQuery}
              deviceSettings={deviceSettings}
            />
          ) : (
            <Box className={resultClasses.container}>
              <Box
                id={'con-resultvertical-01'}
                className={resultClasses.resultsContainer}
              >
                <SearchSuggestions
                  wildCardActive={true}
                  resultCount={resultsCount}
                  resultsFetchend={hasResultsBeenFetched}
                />
                <ReactPlaceholder
                  ready={hasResultsBeenFetched}
                  type="text"
                  rows={4}
                  showLoadingAnimation={true}
                  className={resultClasses.placeholder}
                >
                  <Fade in={hasResultsBeenFetched} timeout={600}>
                    <Box id={'con-resultvertical-section-01'}>
                      {results && resultsCount > 0 && (
                        <FeaturedResults featuredResults={featuredResults} />
                      )}
                      {firstItems}
                    </Box>
                  </Fade>
                </ReactPlaceholder>
                {Number(currentPage) === 1 && (
                  <LeftWidgetContainer
                    resultCount={resultsCount}
                    hasResultsBeenFetched={hasResultsBeenFetched}
                  />
                )}
                <ReactPlaceholder
                  ready={hasResultsBeenFetched}
                  type="text"
                  rows={4}
                  showLoadingAnimation={true}
                  className={resultClasses.placeholder}
                >
                  <Fade in={hasResultsBeenFetched} timeout={600}>
                    <Box id={'con-resultvertical-section-02'}>
                      {restItems}
                      {resultsCount != null && resultsCount > 0 && (
                        <Pagination
                          {...props}
                          totalRecords={resultsCount}
                          pageLimit={20}
                          pageNeighbours={4}
                        />
                      )}
                    </Box>
                  </Fade>
                </ReactPlaceholder>
                <ReactPlaceholder
                  ready={hasResultsBeenFetched}
                  type="text"
                  rows={4}
                  showLoadingAnimation={true}
                  className={resultClasses.placeholder}
                >
                  <Box />
                </ReactPlaceholder>
              </Box>
              <Box className={resultClasses.advertisementContainer}>
                <RightTopWidgetContainer
                  searchQuery={searchQuery}
                  scope={'kpmg.com'}
                />
                <RightWidgetContainer />
              </Box>
            </Box>
          )}
        </>
      )}
      {hasErrorStore && <ErrorPage dataSource="kpmg.com" />}
    </Box>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    authToken: AuthStore.selectors.getAuthToken(state),
    featuredResults: ResultsAEMStore.selectors.getFeaturedResults(state),
    results: ResultsAEMStore.selectors.getResults(state),
    resultsCount: ResultsAEMStore.selectors.getResultCount(state),
    executionTime: ResultsAEMStore.selectors.getExecutionTime(state),
    hasResultsBeenFetched:
      ResultsAEMStore.selectors.hasResultsBeenFetched(state),
    history: SearchStore.selectors.getHistory(state),
    hasErrorStore: ResultMetaDataStore.selectors.getDataSourceHasError(
      state,
      'kpmg.com'
    ),
    userSettings: UserSettingsStore.selectors.getUserSettings(state),
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state)
  }
}

export default PageStay(connect(mapStateToProps)(ResultsAEM))
