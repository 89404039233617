import { FeaturedResult } from 'components/models/FeaturedResult'
import { Reducer } from 'redux'
import {
  IFetchFiltersSuccess,
  IFetchSuccess,
  IFetchSuccessCombined,
  ResultsKpmgUSActionTypes,
  ResultsKpmgUSActions
} from './actions'
import { ResultsKlardenkerActionTypes } from 'store/ResultsKlardenker/actions'
import { ISynonymsApplied } from 'components/models/SynonymsApplied'
import { IKpmgUSResult } from 'components/models/KpmgUSResult'

export interface IFilterKpmgUS {
  field: string
  operator: string
  values: string[]
}

export interface IAdvancedPostFilterKpmgUS {
  operator: string
  filters: IFilterKpmgUS[]
}

export interface IResultsKpmgUSStore {
  hasResultsBeenFetched: boolean
  featuredResults: FeaturedResult[]
  synonymsApplied: ISynonymsApplied[]
  results: IKpmgUSResult[]
  resultsCombined: IKpmgUSResult[]
  resultsCombinedQuery: string
  resultCount: number
  executionTime: number
  currentRowKpmgComUS: number
  filters: {
    categoryus: Array<any>
    // lineofbusinessus: Array<any>
    // topicus: Array<any>
    hasResultsBeenFetched: boolean
    hasError: boolean
  }
}

const initialResultFiltersState = () => ({
  categoryus: [],
  // lineofbusinessus: [],
  // topicus: [],
  hasResultsBeenFetched: false,
  hasError: false
})

const initialState: IResultsKpmgUSStore = {
  hasResultsBeenFetched: false,
  resultCount: -1,
  featuredResults: [],
  synonymsApplied: [],
  results: [],
  resultsCombined: [],
  resultsCombinedQuery: '',
  executionTime: 0,
  currentRowKpmgComUS: 0,
  filters: initialResultFiltersState()
}

const reducers: Reducer<IResultsKpmgUSStore, ResultsKpmgUSActions> = (
  state: IResultsKpmgUSStore = initialState,
  action:
    | {
        type: ResultsKlardenkerActionTypes.INITIALIZE_RESULTS_KLARDENKER
        payload: any
      }
    | { type: 'SET_CURRENT_PAGE'; payload: any }
    | ResultsKpmgUSActions
) => {
  switch (action.type) {
    case 'SET_CURRENT_PAGE':
      return {
        ...state,
        currentPage: action.payload || 0
      }
    case ResultsKpmgUSActionTypes.INITIALIZE_RESULTS_KPMGUS:
      if (!(action as any).payload) {
        return initialState
      }
      const { resultsKlardenker } = (action as any).payload
      return {
        ...state,
        currentPage: resultsKlardenker.currentPage || 1
      }
    case ResultsKpmgUSActionTypes.FETCH_REQUEST:
      const { resetCombined, searchQuery } = (action as any).payload
      return {
        ...state,
        hasResultsBeenFetched: false,
        executionTime: 0,
        resultCount: -1,
        results: [],
        resultsCombined: resetCombined ? [] : state.resultsCombined,
        resultsCombinedQuery: searchQuery
      }
    case ResultsKpmgUSActionTypes.FETCH_FAILURE:
      return {
        ...state,
        hasResultsBeenFetched: true,
        resultCount: 0
      }
    case ResultsKpmgUSActionTypes.FETCH_SUCCESS:
      const {
        response,
        currentRowKpmgComUS,
        featuredResults,
        synonymsApplied
      } = (action as IFetchSuccess).payload
      return {
        ...state,
        hasResultsBeenFetched: true,
        executionTime: response.executionTime,
        resultCount: response.resultCount,
        featuredResults:
          response.currentPage === 1 ? featuredResults : state.featuredResults,
        synonymsApplied:
          response.currentPage === 1 ? synonymsApplied : state.synonymsApplied,
        results: response.queryResults,
        currentRowKpmgComUS: currentRowKpmgComUS
      }
    case ResultsKpmgUSActionTypes.FETCH_SUCCESS_COMBINED:
      const {
        response: responseCombined,
        currentRowKpmgComUS: currentRowKpmgComUSCombined
      } = (action as IFetchSuccessCombined).payload
      return {
        ...state,
        resultsCombined: responseCombined.queryResults,
        currentRowKpmgComUS: currentRowKpmgComUSCombined
      }
    case ResultsKpmgUSActionTypes.FETCH_FILTERS_REQUEST:
      return {
        ...state,
        hasError: false,
        filters: {
          ...state.filters,
          hasResultsBeenFetched: false,
          hasError: false
        }
      }
    case ResultsKpmgUSActionTypes.FETCH_FILTERS_FAILURE:
      return {
        ...state,
        hasResultsBeenFetched: true,
        resultCount: 0,
        filters: {
          ...initialResultFiltersState(),
          hasResultsBeenFetched: true
        }
      }
    case ResultsKpmgUSActionTypes.FETCH_FILTERS_SUCCESS:
      const { responseFilters } = (action as IFetchFiltersSuccess).payload
      return {
        ...state,
        hasError: false,
        filters: {
          hasResultsBeenFetched: true,
          hasError: false,
          categoryus:
            responseFilters.categoryus && responseFilters.categoryus.length > 0
              ? responseFilters.categoryus
              : state.filters.categoryus
          // lineofbusinessus:
          //   responseFilters.lineofbusinessus &&
          //   responseFilters.lineofbusinessus.length > 0
          //     ? responseFilters.lineofbusinessus
          //     : state.filters.lineofbusinessus,
          // topicus:
          //   responseFilters.topicus && responseFilters.topicus.length > 0
          //     ? responseFilters.topicus
          //     : state.filters.topicus
        }
      }

    default:
      return state
  }
}

export default reducers
