import { Store } from '..'

const selectors = {
  getFeaturedResults: (state: Store): any =>
    state.resultsResearch.featuredResults,
  getResults: (state: Store): any => state.resultsResearch.results,
  getResultsCombined: (state: Store): any =>
    state.resultsResearch.resultsCombined,
  getResultsCombinedQuery: (state: Store): any =>
    state.resultsResearch.resultsCombinedQuery,
  hasResultsBeenFetched: (state: Store): any =>
    state.resultsResearch.hasResultsBeenFetched,
  getResultCount: (state: Store): any => state.resultsResearch.resultCount,
  getExecutionTime: (state: Store): any => state.resultsResearch.executionTime,
  getCurrentPage: (state: Store): any => state.resultsResearch.currentPage
}

export default selectors
