import { AnyAction } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { Store } from '..'
import AuthStore from 'store/Auth'
import * as Config from 'config'
import { FetchWithCache } from 'utils/api'
import { statistics } from 'constants/filters'
import Selectors from './selectors'
import ResultMetaDataStore from 'store/ResultMetaData'
import { IUserSetting } from 'utils/userSettings'
import { IDeviceSetting } from 'utils/deviceSettings'
import { createModifiedFilterAsParam } from 'utils/filterslastmodified'
import { renewAuthorizationToken } from 'utils/token'
import {
  ActionMetaData,
  CustomAction,
  getActionMetaData
} from 'store/extension/customAction'
import { FeaturedResult } from 'components/models/FeaturedResult'
import { IStatisticResult } from 'components/models/StatisticResult'
import { generateImage } from 'utils/widgets/statistainfographics'

export enum ResultsStatisticsActionTypes {
  FETCH_REQUEST = 'resultStatistics/FETCH_REQUEST',
  FETCH_SUCCESS = 'resultStatistics/FETCH_SUCCESS',
  FETCH_SUCCESS_COMBINED = 'resultStatistics/FETCH_SUCCESS_COMBINED',
  FETCH_FAILURE = 'resultStatistics/FETCH_FAILURE',
  INITIALIZE_RESULTS_STATISTICS = 'persist/REHYDRATE'
}

export interface IFetchRequest
  extends CustomAction<ResultsStatisticsActionTypes> {
  payload: {
    resetCombined: boolean
    searchQuery: string
  }
}
export type IFetchFailure = CustomAction<ResultsStatisticsActionTypes>
export interface IFetchSuccess
  extends CustomAction<ResultsStatisticsActionTypes> {
  payload: {
    response: {
      queryResults: IStatisticResult[]
      resultCount: number
      executionTime: number
      currentPage: number
    }
    featuredResults: FeaturedResult[]
  }
}
export interface IFetchSuccessCombined
  extends CustomAction<ResultsStatisticsActionTypes> {
  payload: {
    response: {
      queryResults: IStatisticResult[]
      resultCount: number
    }
  }
}

export const fetchRequest = (
  resetCombined: boolean,
  searchQuery: string,
  actionMetaData: ActionMetaData
): IFetchRequest => ({
  type: ResultsStatisticsActionTypes.FETCH_REQUEST,
  payload: {
    resetCombined: resetCombined,
    searchQuery: searchQuery
  },
  metaData: {
    ...actionMetaData,
    isStartAction: true
  }
})
export const fetchSuccess = (
  response: {
    queryResults: IStatisticResult[]
    resultCount: number
    executionTime: number
    currentPage: number
  },
  featuredResults: FeaturedResult[],
  actionMetaData: ActionMetaData
): IFetchSuccess => ({
  type: ResultsStatisticsActionTypes.FETCH_SUCCESS,
  payload: { response, featuredResults },
  metaData: actionMetaData
})

export const fetchSuccessCombined = (
  response: {
    queryResults: IStatisticResult[]
    resultCount: number
  },
  actionMetaData: ActionMetaData
): IFetchSuccessCombined => ({
  type: ResultsStatisticsActionTypes.FETCH_SUCCESS_COMBINED,
  payload: { response },
  metaData: actionMetaData
})
export const fetchFailure = (
  actionMetaData: ActionMetaData
): IFetchFailure => ({
  type: ResultsStatisticsActionTypes.FETCH_FAILURE,
  metaData: actionMetaData
})

export const fetchResultsStatistics = (
  searchQuery: string,
  currentPage: number,
  userSettings: IUserSetting,
  deviceSettings: IDeviceSetting,
  filters: {
    [key: string]: string
  }
  // eslint-disable-next-line @typescript-eslint/ban-types
): ThunkAction<Promise<void>, Store, {}, AnyAction> => {
  return async (
    // eslint-disable-next-line @typescript-eslint/ban-types
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => Store
  ) => {
    const actionMetaData = getActionMetaData('statistics')

    dispatch(
      ResultMetaDataStore.actions.fetchRequest('statistics', actionMetaData)
    )
    const resultsCombinedQuery = Selectors.getResultsCombinedQuery(getState())
    if (resultsCombinedQuery !== searchQuery) {
      if (resultsCombinedQuery === '') {
        dispatch(fetchRequest(false, searchQuery, actionMetaData))
      } else {
        dispatch(fetchRequest(true, searchQuery, actionMetaData))
      }
    } else {
      dispatch(fetchRequest(false, searchQuery, actionMetaData))
    }
    try {
      if (!searchQuery || searchQuery === '' || searchQuery === '""') {
        dispatch(fetchFailure(actionMetaData))
        return
      }

      const t0 = performance.now()

      let apiUrl = `${
        Config.APIM_BASE_URL
      }statistaapi/getstatistics?q=${encodeURIComponent(
        searchQuery
      )}&limit=200&processFeaturedResults=${true}&featuredResultsRowLimit=3&origin=KPMGFind&country=${
        userSettings.Country
      }&function=${userSettings.Function}&city=${userSettings.City}`

      if (filters.orderBy) {
        const filterOBCat = statistics.find(
          (fl: FilterCategory) => fl.key === 'orderBy'
        )
        if (filterOBCat) {
          const filterOBOpt = filterOBCat.options.find(
            (opt: Filter) => opt.key === filters.orderBy
          )
          if (filterOBOpt && filterOBOpt.value) {
            apiUrl += `&sort=${filterOBOpt.value}`
          }
        }
      }

      // Set last modified filter
      if (filters.lastmodified) {
        apiUrl = createModifiedFilterAsParam(
          apiUrl,
          filters.lastmodified,
          'date_from',
          'date_to',
          10,
          true
        )
      }

      if (!Boolean(Number(filters.premium))) {
        apiUrl += `&premium=0`
      }

      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        return
      }

      const response = await FetchWithCache(apiUrl, {
        method: 'GET',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${esToken}`
        }
      })

      let results
      if (!response || response.hasError || !response.responseJSON) {
        dispatch(
          ResultMetaDataStore.actions.hasError(
            'statistics',
            response.errorResponse,
            undefined,
            actionMetaData
          )
        )
        dispatch(fetchFailure(actionMetaData))
        return
      } else {
        results = response.responseJSON
      }

      const featuredResults =
        results && results.FeaturedResults ? results.FeaturedResults : []

      // Get primary results
      const primaryResults = results && results.items ? results.items : []

      const t1 = performance.now()
      const executionTime = t1 - t0

      const sliceStartIndex = (Number(currentPage) - 1) * 20
      const sliceEndIndex = Number(currentPage) * 20

      const statisticResults: IStatisticResult[] = []

      primaryResults.slice(sliceStartIndex, sliceEndIndex).forEach((i: any) => {
        let imageUrl = generateImage(
          i.image_url ? i.image_url : '',
          i.teaser_image_urls
        )

        if (
          imageUrl.indexOf('http://') === -1 &&
          imageUrl.indexOf('https://') === -1
        ) {
          imageUrl = 'https://www.statista.com' + imageUrl
        }

        const statisticResult: IStatisticResult = {
          title: i.title,
          body: i.description,
          date: i.date,
          link: i.link,
          sources: i.sources,
          image: imageUrl,
          premium: i.premium === 1
        }

        statisticResults.push(statisticResult)
      })

      // Set result number of results
      const resultCount = primaryResults.length
      setTimeout(function () {
        dispatch(
          fetchSuccess(
            {
              queryResults: statisticResults,
              executionTime,
              resultCount: resultCount,
              currentPage: currentPage || 1
            },
            featuredResults,
            actionMetaData
          )
        )
      }, 1000)
      dispatch(
        ResultMetaDataStore.actions.fetchSuccess(
          'statistics',
          {
            executionTime: executionTime,
            resultsCount: resultCount
          },
          actionMetaData
        )
      )

      const resultsCombined = Selectors.getResultsCombined(getState())
      if ((currentPage || 1) === 1) {
        setTimeout(function () {
          dispatch(
            fetchSuccessCombined(
              {
                queryResults: statisticResults,
                resultCount: resultCount
              },
              actionMetaData
            )
          )
        }, 1000)
      } else {
        setTimeout(function () {
          dispatch(
            fetchSuccessCombined(
              {
                queryResults: [...resultsCombined, ...statisticResults],
                resultCount: resultCount
              },
              actionMetaData
            )
          )
        }, 1000)
      }
    } catch (error) {
      dispatch(
        ResultMetaDataStore.actions.hasError(
          'statistics',
          undefined,
          error,
          actionMetaData
        )
      )
      dispatch(fetchFailure(actionMetaData))
    }
  }
}

export type ResultsStatisticsActions =
  | IFetchRequest
  | IFetchFailure
  | IFetchFailure
