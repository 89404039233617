import { trackException } from './tracking'
import * as Filters from 'constants/filters'
import { buildPortalFilter } from 'utils/filters'
import { IUserSetting } from './userSettings'
import { KPMGFindGlobalVariables } from 'store/KPMGFindGlobalVariables'
import {
  removeStopwords,
  eng,
  deu,
  spa,
  fra,
  ita,
  jpn,
  kor,
  porBr,
  rus,
  zho
} from 'stopword'

export interface IOneIntranetResultQuery {
  querytext?: string
  trimduplicates: boolean
  rowsperpage: number
  rowlimit: number
  lastRow: number
  selectproperties: string
  // Values: PDF, Powerpoint, Word, Excel, Webpage, Video, Photo
  documentType?: string
  // 2-char ISO language code: en, de, es, ...
  language?: string
  // Last modified filter
  lastmodified?: string
  dateFrom?: string
  dateTo?: string
  sourceid?: string
  // Relevance (default if not present), DateAsc, DateDesc, TitleAsc, TitleDesc
  sort?: string
  // Identifies that we access APIM from KPMG Find instead of spfx.
  // Currently KPMG Find can only query AUS endpoint for online results
  // Static, set as default to KPMGFind
  // TODO: Remove once KPMG Find is permitted into global https://spo-global.kpmg.com
  // possible values are oi, kpmgfind and thoughtleadership (right though leadership and though leadership filter in Intranet verticals)
  origin: string
  published?: string
  cognitiveEnabled: boolean
  processFeaturedResults: boolean
  featuredResultsRowLimit: string
  featuredResultsOrigin: string
  country: string
  city: string
  function: string
  imageType?: string //find api only
  genericOIFilter?: Array<string>
}

export interface IOneIntranetPeopleQuery {
  rowlimit: number
  rowsperpage: number
  querytext: string
  startRow: number
  trimduplicates: boolean
  dynamicRefiners: string
  genericRefiners: Array<string>
  cognitiveEnabled: boolean
  processFeaturedResults: boolean
  featuredResultsRowLimit: string
  featuredResultsOrigin: string
  country: string
  city: string
  function: string
}

export const oneIntranetDefaultQuery: IOneIntranetResultQuery = {
  trimduplicates: false,
  rowsperpage: 20,
  rowlimit: 20,
  lastRow: 0,
  selectproperties:
    'Title,Write,Path,FreshLinkURL,HitHighlightedSummary,Description,FreshDescription,ServerRedirectedURL,DefaultEncodingURL,SiteTitle,LinkOfficeChild,ContentClass,ListID,ListItemID,SPWebUrl,UniqueID,SiteID,ShortcutUrl,ViewsLifeTime',
  origin: 'global',
  cognitiveEnabled: false,
  processFeaturedResults: false,
  featuredResultsRowLimit: '3',
  featuredResultsOrigin: 'KPMGFind',
  country: '',
  city: '',
  function: ''
}

export const videosDefaultquery: IOneIntranetResultQuery = {
  trimduplicates: false,
  rowsperpage: 21,
  rowlimit: 21,
  lastRow: 0,
  selectproperties:
    'Path,Title,Write,DefaultEncodingURL,PictureThumbnailUrl,ListID,ListItemID,SiteID,WebID,Size,ViewsLifeTime',
  origin: 'global',
  cognitiveEnabled: false,
  processFeaturedResults: false,
  featuredResultsRowLimit: '3',
  featuredResultsOrigin: 'KPMGFind',
  country: '',
  city: '',
  function: ''
}

export const engagementsDefaultQuery: IOneIntranetResultQuery = {
  trimduplicates: false,
  rowsperpage: 20,
  rowlimit: 20,
  lastRow: 0,
  // FreshTax2: Service, FreshTax3: Industry, FrashTax1: Language
  selectproperties:
    'Title,Description,SiteTitle,Write,Path,FreshTax2,FreshTax3,FreshTax1',
  origin: 'global',
  cognitiveEnabled: false,
  processFeaturedResults: false,
  featuredResultsRowLimit: '3',
  featuredResultsOrigin: 'KPMGFind',
  country: '',
  city: '',
  function: ''
}

export const imagesDefaultQuery: IOneIntranetResultQuery = {
  trimduplicates: true,
  rowsperpage: 20,
  rowlimit: 20,
  lastRow: 0,
  selectproperties:
    'Title,Write,Path,FreshLinkURL,HitHighlightedSummary,Description,FreshDescription,ServerRedirectedURL,DefaultEncodingURL,SiteTitle,LinkOfficeChild,ContentClass,ListID,ListItemID,SPWebUrl,UniqueID,SiteID,WebID',
  origin: 'images',
  sourceid: 'de77a480-9872-429b-8003-15de69557900',
  cognitiveEnabled: false,
  processFeaturedResults: false,
  featuredResultsRowLimit: '3',
  featuredResultsOrigin: 'KPMGFind',
  country: '',
  city: '',
  function: ''
}

export function prepareFilters(
  requestBody: IOneIntranetResultQuery,
  filters: {
    [key: string]: string
  },
  lastRow: number,
  userSettings: IUserSetting,
  scope: string,
  useCognitiveSearch: boolean
): IOneIntranetResultQuery {
  // Prepare allowed filters based on scope
  let allowedFilters = Filters.intranet
  switch (scope) {
    case 'engagements':
      allowedFilters = Filters.engagements
      break
    case 'videos':
      allowedFilters = Filters.videos
      break
    case 'images':
      allowedFilters = Filters.images
      break
  }
  const allowedFilterKeys = allowedFilters.map((fc: FilterCategory) => {
    return fc.key
  })

  requestBody.lastRow = lastRow
  if (!filters) return requestBody

  // Country Filter
  if (filters['country'] !== '' && allowedFilterKeys.includes('country')) {
    const portalFilter = buildPortalFilter(filters, allowedFilters)
    if (portalFilter === 'all') {
      requestBody.origin = 'global'
    } else if (portalFilter === 'thoughtleadership') {
      requestBody.origin = 'thoughtleadership'
    } else if (portalFilter.startsWith('https://')) {
      requestBody.origin = portalFilter
    } else if (portalFilter !== '') {
      requestBody.origin = 'portal%%"{' + portalFilter + '}"'
    }
  }

  // Document Type Filter
  if (filters['file'] && allowedFilterKeys.includes('file')) {
    const splitedFileFilter = filters['file'].split('|')
    const fileType: string[] = []
    splitedFileFilter.forEach((fileFilter: string) => {
      switch (fileFilter) {
        case 'XLS':
          fileType.push('Excel')
          break
        case 'onenote':
          fileType.push('OneNote')
          break
        case 'page':
          fileType.push('WebPage')
          break
        case 'PDF':
          fileType.push('PDF')
          break
        case 'photo':
          fileType.push('Photo')
          break
        case 'PPT':
          fileType.push('PowerPoint')
          break
        case 'video':
          fileType.push('Video')
          break
        case 'DOC':
          fileType.push('Word')
          break
        case 'Email':
          fileType.push('Email')
          break
        case 'MicrosoftPublisher':
          fileType.push('MicrosoftPublisher')
          break
        case 'MicrosoftXPS':
          fileType.push('MicrosoftXPS')
          break
        case 'OpenDocument':
          fileType.push('OpenDocument')
          break
        case 'RichText':
          fileType.push('RichText')
          break
        case 'TextOnly':
          fileType.push('Text')
          break
        case 'Url':
          fileType.push('Url')
          break
        case 'Visio':
          fileType.push('Visio')
          break
        case 'Xml':
          fileType.push('Xml')
          break
        case 'Zip':
          fileType.push('Zip')
          break
        default:
          break
      }
    })

    if (fileType.length > 0) requestBody.documentType = fileType.join('|')
  }

  // Language Filter
  if (filters['language'] && allowedFilterKeys.includes('language')) {
    requestBody.language = filters['language']
  }

  // Last Modified Filter
  if (filters['lastmodified'] && allowedFilterKeys.includes('lastmodified')) {
    const lastModified = filters['lastmodified']
    if (lastModified.indexOf('customRange') >= 0) {
      try {
        requestBody.lastmodified = 'customRange'
        const customRangeDates = lastModified
          .replace('customRange(', '')
          .replace(')', '')
          .split(',')
        if (customRangeDates && customRangeDates.length === 2) {
          if (customRangeDates[0]) {
            requestBody.dateFrom = customRangeDates[0]
          }
          if (customRangeDates[1]) {
            requestBody.dateTo = customRangeDates[1]
          }
        }
      } catch (e) {
        trackException(
          'Exception parsing last modified filter in oneIntranet.ts',
          e
        )
      }
    } else {
      requestBody.lastmodified = lastModified
    }
  }

  // Sorting
  if (filters['orderBy'] && allowedFilterKeys.includes('orderBy')) {
    switch (filters['orderBy']) {
      case 'datea':
        requestBody.sort = 'DateAsc'
        break
      case 'dated':
        requestBody.sort = 'DateDesc'
        break
      case 'titlea':
        requestBody.sort = 'TitleAsc'
        break
      case 'titled':
        requestBody.sort = 'TitleDesc'
        break
      case 'viewslifetime':
        requestBody.sort = 'ViewsLifeTime'
        break
      default:
        break
    }
  }

  //imageTypes
  if (filters['imageType'] !== '' && allowedFilterKeys.includes('imageType')) {
    requestBody.imageType = filters['imageType']
  }

  // Add or remove the published option
  if (filters['published'] && scope === 'intranet') {
    requestBody.published = filters['published'].toString()
  }

  requestBody.cognitiveEnabled = useCognitiveSearch
  requestBody.country = userSettings.Country
  requestBody.function = userSettings.Function

  return requestBody
}

export function prepareCacheKey(requestBody: any): string {
  let cacheKey = '?'
  for (const [key, value] of Object.entries(requestBody)) {
    if (value) {
      if (typeof value === 'object') {
        for (const [key2, value2] of Object.entries(value)) {
          if (value2) {
            cacheKey +=
              key2 + '=' + encodeURIComponent(JSON.stringify(value2)) + '&'
          }
        }
      } else {
        cacheKey += key + '=' + value.toString() + '&'
      }
    }
  }
  if (cacheKey.endsWith('&')) {
    cacheKey = cacheKey.slice(0, -1)
  }
  return cacheKey
}

/**
 * Get result summary
 * @param HitHighlightedSummary HitHighlightedSummary MP value
 * @param description Description MP value
 * @param freshDescription FreshDescription MP value
 * @returns
 */
export const getResultSummary = (
  HitHighlightedSummary: string | undefined | null,
  description: string | undefined | null,
  freshDescription: string | undefined | null
): string => {
  if (!HitHighlightedSummary && !description && !freshDescription) {
    return ''
  }

  if (!description || description.split(' ').length < 3) {
    if (!freshDescription || freshDescription.split(' ').length < 3) {
      HitHighlightedSummary = HitHighlightedSummary
        ? HitHighlightedSummary.replace(
            /\b[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}\b/g,
            ''
          ).trim()
        : ''
      return HitHighlightedSummary
    }
    return freshDescription
  }

  return description
}

const footerString =
  '2020 KPMG International Cooperative KPMG International a Swiss entity' +
  ' Member firms of the KPMG network of independent firms are affiliated ' +
  'with KPMG International KPMG International provides no client services ' +
  'No member firm has any authority to obligate or bind KPMG International ' +
  'or any other member firm vis-a-vis their parties nor does KPMG ' +
  'International have any such authority to obligate or bind any member ' +
  'firm All rights reserved'
const footerMap = new Map()
footerString.split(' ').forEach((word: string) => footerMap.set(word, true))

export const hasTooManyWordsInCommonWithFooter = (string: string): boolean => {
  const sharedWords = (string ?? '')
    .split(' ')
    .filter((word: string) => footerMap.has(word))
  return sharedWords.length >= 7
}

/**
 * Removes the stop words from the query, in cse cognitive search is enabled, query language has been identified
 * and search is into quotation marks
 * @param searchQuery The original search query
 * @param searchQueryLanguage The search query language
 * @param userEnabledCognitiveSearch Cognitive Search is enabled by the user
 * @param findEnabledCognitiveSearch Cognitive Search is enabled by the application
 * @returns Search query without stop words
 */
export const removeStopWordsFromQuery = (
  searchQuery: string,
  searchQueryLanguage: string,
  userEnabledCognitiveSearch: boolean,
  findEnabledCognitiveSearch: boolean
) => {
  if (
    userEnabledCognitiveSearch &&
    findEnabledCognitiveSearch &&
    searchQueryLanguage !== '' &&
    !(searchQuery.startsWith('"') && searchQuery.endsWith('"'))
  ) {
    let searchTextwoStopWords = ''
    switch (searchQueryLanguage) {
      case 'de':
        searchTextwoStopWords = removeStopwords(
          searchQuery.split(' '),
          deu
        ).join(' ')
        break
      case 'en':
        searchTextwoStopWords = removeStopwords(
          searchQuery.split(' '),
          eng
        ).join(' ')
        break
      case 'es':
        searchTextwoStopWords = removeStopwords(
          searchQuery.split(' '),
          spa
        ).join(' ')
        break
      case 'fr':
        searchTextwoStopWords = removeStopwords(
          searchQuery.split(' '),
          fra
        ).join(' ')
        break
      case 'it':
        searchTextwoStopWords = removeStopwords(
          searchQuery.split(' '),
          ita
        ).join(' ')
        break
      case 'ja':
        searchTextwoStopWords = removeStopwords(
          searchQuery.split(' '),
          jpn
        ).join(' ')
        break
      case 'ko':
        searchTextwoStopWords = removeStopwords(
          searchQuery.split(' '),
          kor
        ).join(' ')
        break
      case 'pt':
        searchTextwoStopWords = removeStopwords(
          searchQuery.split(' '),
          porBr
        ).join(' ')
        break
      case 'ru':
        searchTextwoStopWords = removeStopwords(
          searchQuery.split(' '),
          rus
        ).join(' ')
        break
      case 'zh':
        searchTextwoStopWords = removeStopwords(
          searchQuery.split(' '),
          zho
        ).join(' ')
        break
    }

    KPMGFindGlobalVariables.setSearchTermStopWords(
      searchTextwoStopWords ? searchTextwoStopWords : ''
    )
    return searchTextwoStopWords ? searchTextwoStopWords : searchQuery
  } else {
    return searchQuery
  }
}
