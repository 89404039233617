/**
 * Replace single with double quote
 * @param searchQuery the searchquery
 */
const replaceSingleQuote = (searchQuery: string): string => {
  return searchQuery.replace(/'/g, '"')
}

/**
 * Replace double with single quote
 * @param searchQuery the searchquery
 */
const replaceDoubleQuote = (searchQuery: string): string => {
  return searchQuery.replace(/"/g, "'")
}

/**
 * Replace first and last double quote occurence with escaped and encoded double quotes
 */
const escapeDoubleQuotes = (searchQuery: string): string => {
  searchQuery = searchQuery.replace(/"/g, '\\"')

  return searchQuery
}

interface IConfig {
  name: string
  unifyList: any[]
  encodeSearchQuery: boolean
  filterSearchQuery?: RegExp
}

const config: IConfig[] = [
  {
    name: 'source',
    unifyList: [escapeDoubleQuotes],
    encodeSearchQuery: true
  },
  {
    name: 'confluenceFi',
    unifyList: [escapeDoubleQuotes],
    encodeSearchQuery: true
  },
  {
    name: 'kpmg.com',
    unifyList: [replaceSingleQuote],
    encodeSearchQuery: true,
    filterSearchQuery: /[#&()*+;'<>%]/g
  },
  {
    name: 'news',
    unifyList: [],
    encodeSearchQuery: true,
    filterSearchQuery: /[#[\]]/g
  },
  {
    name: 'servicenowch',
    unifyList: [],
    encodeSearchQuery: true
  },
  {
    name: 'servicenowau',
    unifyList: [],
    encodeSearchQuery: true
  },
  {
    name: 'mpp',
    unifyList: [],
    encodeSearchQuery: true,
    filterSearchQuery: /[*[\]()]/g
  },
  {
    name: 'knowledgeexchange',
    unifyList: [],
    encodeSearchQuery: false,
    filterSearchQuery: /[<>]/g
  },
  {
    name: 'marketresearch',
    unifyList: [replaceDoubleQuote],
    encodeSearchQuery: false
  }
]

export function unifysearchQuery(
  searchQuery: string,
  currentScope: string
): string {
  if (searchQuery && searchQuery.length > 0) {
    const unifySettings = config.find(
      (configObject) => configObject.name === currentScope
    )

    if (unifySettings) {
      unifySettings.unifyList.forEach((unifyFunction) => {
        searchQuery = unifyFunction(searchQuery)
      })

      if (unifySettings.filterSearchQuery)
        searchQuery = searchQuery.replace(unifySettings.filterSearchQuery, '')

      if (unifySettings.encodeSearchQuery)
        searchQuery = encodeURIComponent(searchQuery)
    }
  }

  return searchQuery
}
