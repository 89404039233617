import * as React from 'react'
import { Modal } from '@fluentui/react'
import { connect } from 'react-redux'
import { Store } from 'store'
import { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import AuthStore from 'store/Auth'
import * as Config from 'config'
import { getSPOnlineToken } from 'utils/token'
import { getStylesSharePointShareDialog } from 'styles/contents/common/SharePointShareDialog'

interface ISharePointShareDialogProps {
  isOpen: boolean
  closeDialog: (state: boolean) => void
  title: string
  defaultEncodingUrl: string
  uniqueId: string
  listId: string
  spWebUrl: string
}

type AllProps = ReturnType<typeof mapStateToProps> & ISharePointShareDialogProps

function SharePointShareDialog(props: AllProps) {
  const {
    isOpen,
    closeDialog,
    title,
    defaultEncodingUrl,
    uniqueId,
    listId,
    spWebUrl,
    aadInfo
  } = props

  const odwebpUrl = 'https://www.odwebp.svc.ms/share?smkt=en-us&share_mode=1'

  const [contentRef, setContentRef] = useState<HTMLIFrameElement | null>(null)
  const [formRef, setFormRef] = useState<HTMLFormElement | null>(null)
  const [form, setForm] = useState<React.ReactPortal | null>(null)
  const [spoToken, setSPOToken] = useState<string | null>(null)
  const [dialogSize, _setDialogSize] = useState({
    width: 330,
    height: 402,
    ready: false
  })
  const classes = getStylesSharePointShareDialog()

  // Created an ref object with dialog size to use the latest values
  // inside the resize event
  const dialogSizeRef = useRef(dialogSize)
  const setDialogSize = (newValue: any) => {
    dialogSizeRef.current = newValue
    _setDialogSize(newValue)
  }

  useEffect(() => {
    if (defaultEncodingUrl) {
      if (defaultEncodingUrl.startsWith(Config.GLOBAL_OI_TOKEN_ENDPOINT)) {
        getSPOnlineToken(aadInfo.instance, aadInfo.accounts).then(
          (spoToken: string) => {
            setSPOToken(spoToken)
          }
        )
      } else {
        const domain = new URL(defaultEncodingUrl)
        if (domain && domain.origin) {
          getSPOnlineToken(
            aadInfo.instance,
            aadInfo.accounts,
            domain.origin
          ).then((spoToken: string) => {
            setSPOToken(spoToken)

            //clean token from sessionstorage
            for (let i = 0; i < sessionStorage.length; i++) {
              const key = sessionStorage.key(i)
              if (key) {
                const target = sessionStorage.getItem(key)

                if (target && target.includes(spoToken)) {
                  try {
                    const targetData = JSON.parse(target)
                    if (
                      targetData.accessToken &&
                      targetData.accessToken === spoToken
                    ) {
                      sessionStorage.removeItem(key)
                      break
                    }
                  } catch {}
                }
              }
            }
          })
        }
      }
    }

    window.onmessage = handleOnmessageEvent
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOnmessageEvent = (n: MessageEvent) => {
    let msg = null
    if (n && n.data)
      try {
        msg = JSON.parse(n.data)
      } catch {}

    if (msg && msg.name && msg.name === 'share_ready') {
      setDialogSize({
        ...dialogSizeRef.current,
        ready: true
      })
    } else if (msg && msg.name && msg.name === 'share_dismiss') {
      closeDialog(false)
    } else if (
      msg &&
      msg.name &&
      msg.name === 'share_resize' &&
      dialogSizeRef.current.ready
    ) {
      const m = msg.height || dialogSizeRef.current.height
      setDialogSize({
        ...dialogSizeRef.current,
        width: msg.width || 330,
        height: m
      })
    }
  }

  useEffect(() => {
    if (spoToken && contentRef?.contentWindow?.document?.body) {
      const e = {
        authToken: `Bearer ${spoToken}`,
        clientId: 'searchux',
        hostOptions: { showCloseButton: true },
        itemName: title,
        itemUrl: defaultEncodingUrl,
        //correlationId: currentSiteCorrelationId,
        scenarioId: 'searchResult.File',
        uniqueId: uniqueId,
        listId: listId,
        webAbsoluteUrl: spWebUrl,
        mode: 1
      }

      const children = (
        <form id="shareform" action={odwebpUrl} method="post" ref={setFormRef}>
          <input
            type="text"
            name="context"
            value={JSON.stringify(e)}
            readOnly={true}
            style={{ display: 'none' }}
          />
        </form>
      )

      setForm(createPortal(children, contentRef?.contentWindow?.document?.body))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentRef, spoToken])

  //submit context values
  useEffect(() => {
    if (formRef) {
      formRef.submit()
    }
  }, [formRef])

  return (
    <Modal
      titleAriaId={'ShareDialog'}
      onDismiss={() => closeDialog(false)}
      isBlocking={false}
      isOpen={isOpen}
      scrollableContentClassName={classes.modalScrollableContent}
    >
      <iframe
        id={'shareFrame'}
        name={'shareFrame'}
        title={'Share'}
        className={'iframe-412'}
        ref={setContentRef}
        width={dialogSize.width}
        height={dialogSize.height}
        key={defaultEncodingUrl}
      >
        {form}
      </iframe>
    </Modal>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    aadInfo: AuthStore.selectors.getAADInfo(state)
  }
}

export default connect(mapStateToProps)(SharePointShareDialog)
