import { Reducer } from 'redux'
import {
  AuthActions,
  AuthActionTypes,
  ISetAuthToken,
  ISetAADInfo
} from './actions'
import { AccountInfo } from '@azure/msal-common'
import { IPublicClientApplication } from '@azure/msal-browser'

export interface IAADState {
  initializing: boolean
  initialized: boolean
  accessToken: string
  accounts: AccountInfo[] | null
  instance: IPublicClientApplication | null
}

export interface IAuthStore {
  authToken: string
  aad: IAADState
}

const initialState: IAuthStore = {
  authToken: '',
  aad: {
    initializing: true,
    initialized: false,
    accessToken: '',
    accounts: null,
    instance: null
  }
}

const reducers: Reducer<IAuthStore, AuthActions> = (
  state: IAuthStore = initialState,
  action
) => {
  switch (action.type) {
    case AuthActionTypes.SET_AUTH_TOKEN:
      const authToken = (action as ISetAuthToken).payload.authToken
      return {
        ...state,
        authToken: authToken
      }
    case AuthActionTypes.SET_AAD_INFO:
      const { instance, accounts } = (action as ISetAADInfo).payload
      return {
        ...state,
        aad: {
          ...state.aad,
          initializing: false,
          initialized: true,
          instance: instance,
          accounts: accounts
        }
      }
    default:
      return state
  }
}

export default reducers
