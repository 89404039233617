import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getAlexTopRightWidgetStyles(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles(): any {
  return makeStyles((theme: Theme) => ({
    image: {
      objectFit: 'contain',
      borderRadius: '4px 4px 0px 0px',
      width: '100%',
      maxWidth: 448,
      '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
        minHeight: '1px'
      }
    },
    container: {
      padding: 20
    },
    content: {
      color: '#424242',
      textAlign: 'left',
      fontWeight: 'lighter',
      font: 'Arial,Helvetica Neue,Helvetica,sans-serif-light',
      flex: 1,
      margin: '0 0 16px 0',
      lineHeight: 1.5
    },

    title: {
      fontSize: 22,
      margin: '8px 0'
    },
    containerTitle: {
      fontSize: 18,
      margin: '8px 0',
      fontWeight: 'bold'
    },
    containerTopic: {
      color: '#6d2077'
    },
    containerFeatured: {
      color: '#eaaa00'
    },
    containerSearch: {
      color: '#000'
    },

    resultTitle: {
      fontSize: 16,
      margin: '0 0 6px 0',
      width: '100%',
      color: 'inherit'
    },
    resultItem: {
      marginLeft: '2em'
    },
    resultBody: {
      fontSize: '14px',
      lineHeight: '20px',
      wordWrap: 'break-word', // older browsers
      overflowWrap: 'break-word', // css3,
      color: '#3c4043',
      paddingBottom: '1em'
    },

    containerLoadMore: {
      textAlign: 'center',
      '& button': {
        margin: '1em 0'
      }
    },

    link: {
      display: 'flex',
      flexDirection: 'row',
      flex: 1,
      color: 'inherit'
    },

    loadMoreButton: {
      textDecoration: 'none !important'
    },

    icon: {
      width: '15px',
      height: '15px',
      display: 'inline-block',
      position: 'relative',
      top: '2px',
      marginRight: '8px'
    },
    topicIcon: {
      fill: '#6d2077'
    },
    featuredIcon: {
      fill: '#eaaa00'
    },
    iconSpace: {
      marginLeft: '23px'
    }
  }))
}
