import FilterStore from 'store/Filters'
import { store } from 'store/configureStore'

/**
 * Get current filters as key/value list from store
 * @returns key/value list
 */
export function getCurrentFiltersAsKeyValue(): { [key: string]: any } {
  const keyValues: { [key: string]: any } = {}

  const state = store.getState()
  if (state && state.filters) {
    const currentFilters = FilterStore.selectors.getCurrentFilters(state)

    currentFilters.forEach((item: CurrentFilter) => {
      keyValues[item.key] = item.value
    })
  }

  return keyValues
}

/**
 * Get temporary filters as key/value list from store
 * @returns key/value list
 */
export function getTemporaryFiltersAsKeyValue(): { [key: string]: any } {
  const keyValues: { [key: string]: any } = {}

  const state = store.getState()
  if (state && state.filters) {
    const temporaryFilters = FilterStore.selectors.getTemporaryFilters(state)

    temporaryFilters.forEach((item: CurrentFilter) => {
      keyValues[item.key] = item.value
    })
  }

  return keyValues
}
