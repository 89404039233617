import { Store } from '..'

const selectors = {
  isResultVisited: (state: Store, url: string): boolean => {
    if (state.results.history[url]) return true
    return false
  }
}

export default selectors
