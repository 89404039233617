import { Reducer } from 'redux'
import {
  IResultMetaFetchSuccess,
  IResultMetaFetchSuccessCount,
  IResultMetaFetchSuccessExecutionTime,
  IResultMetaHasError,
  ResultMetaDataActions,
  ResultMetaDataActionTypes
} from './actions'
import { trackException } from 'utils/tracking'
import { IErrorResponse } from 'utils/api'

export interface IResultMetaData {
  datasource: string
  resultsCount: number
  executionTime: number
  hasError: false
  error: IErrorResponse | null
  isUncertainCount?: boolean
  hasOIError: false
}

export interface IResultMetaDataStore {
  resultMeta: Array<IResultMetaData>
}

const initialState: IResultMetaDataStore = {
  resultMeta: []
}

const reducers: Reducer<IResultMetaDataStore, ResultMetaDataActions> = (
  state: IResultMetaDataStore = initialState,
  action: any
) => {
  switch (action.type) {
    case ResultMetaDataActionTypes.RESULT_META_FETCH_REQUEST:
      const datasourceFetch = (action as IResultMetaFetchSuccess).datasource
      let dsFound = false

      const newResultMetaFetch: any[] = []
      if (state.resultMeta instanceof Array) {
        state.resultMeta.forEach((resultMetaItem: any) => {
          if (resultMetaItem.datasource === datasourceFetch) {
            resultMetaItem.resultsCount = -1
            resultMetaItem.executionTime = 0
            resultMetaItem.hasError = false
            resultMetaItem.hasOIError = false
            resultMetaItem.error = null
            dsFound = true
          }
          newResultMetaFetch.push(resultMetaItem)
        })
      }

      if (!dsFound) {
        newResultMetaFetch.push({
          datasource: datasourceFetch,
          resultsCount: -1,
          executionTime: 0,
          hasError: false,
          hasOIError: false,
          error: null,
          isUncertainCount: false
        })
      }

      return {
        ...state,
        resultMeta: newResultMetaFetch
      }
    case ResultMetaDataActionTypes.RESULT_META_FETCH_SUCCESS:
      const { resultsCount, executionTime, isUncertainCount } = (
        action as IResultMetaFetchSuccess
      ).payload
      const datasourceSuccess = (action as IResultMetaFetchSuccess).datasource

      const newResultMetaSuccess: any[] = []
      state.resultMeta.forEach((resultMetaItem: any) => {
        if (resultMetaItem.datasource === datasourceSuccess) {
          resultMetaItem.resultsCount = resultsCount
          resultMetaItem.executionTime = executionTime
          resultMetaItem.hasError = false
          resultMetaItem.hasOIError = false
          resultMetaItem.error = null
          resultMetaItem.isUncertainCount = isUncertainCount ? true : false
        }
        newResultMetaSuccess.push(resultMetaItem)
      })

      return {
        ...state,
        resultMeta: newResultMetaSuccess
      }
    case ResultMetaDataActionTypes.RESULT_META_FETCH_SUCCESS_COUNT:
      const { resCount } = (action as IResultMetaFetchSuccessCount).payload
      const dsSuccessCount = (action as IResultMetaFetchSuccessCount).datasource

      const newResultMetaSuccessCount: any[] = []
      state.resultMeta.forEach((resultMetaItem: any) => {
        if (resultMetaItem.datasource === dsSuccessCount) {
          resultMetaItem.resultsCount = resCount
          resultMetaItem.hasError = false
          resultMetaItem.hasOIError = false
          resultMetaItem.error = null
        }
        newResultMetaSuccessCount.push(resultMetaItem)
      })

      return {
        ...state,
        resultMeta: newResultMetaSuccessCount
      }
    case ResultMetaDataActionTypes.RESULT_META_FETCH_SUCCESS_EXTIME:
      const { exTime } = (action as IResultMetaFetchSuccessExecutionTime)
        .payload
      const dsSuccessExTime = (action as IResultMetaFetchSuccessExecutionTime)
        .datasource

      const newResultMetaSuccessExTime: any[] = []
      state.resultMeta.forEach((resultMetaItem: any) => {
        if (resultMetaItem.datasource === dsSuccessExTime) {
          resultMetaItem.executionTime = exTime
          resultMetaItem.hasError = false
          resultMetaItem.hasOIError = false
          resultMetaItem.error = null
        }
        newResultMetaSuccessExTime.push(resultMetaItem)
      })

      return {
        ...state,
        resultMeta: newResultMetaSuccessExTime
      }
    case ResultMetaDataActionTypes.RESULT_META_HAS_ERROR:
      const datasourceError = (action as IResultMetaHasError).datasource
      const error = (action as IResultMetaHasError).error
      const hasOIError = (action as IResultMetaHasError).hasOIError

      if (error.responseCode !== 499) {
        if (error.exception) {
          trackException(error.message, error.exception)
        } else {
          trackException(
            `Error in fetching results ${datasourceError} action`,
            new Error(
              `Backend error in fetching results for ${datasourceError}`
            ),
            error
          )
        }
      }

      const newResultMetaError: any[] = []
      state.resultMeta.forEach((resultMetaItem: any) => {
        if (resultMetaItem.datasource === datasourceError) {
          resultMetaItem.resultsCount = 0
          resultMetaItem.executionTime = 0
          resultMetaItem.hasError = true
          resultMetaItem.hasOIError = hasOIError
          resultMetaItem.error = error
        }
        newResultMetaError.push(resultMetaItem)
      })

      return {
        ...state,
        resultMeta: newResultMetaError
      }
    default:
      return state
  }
}

export default reducers
