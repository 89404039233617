import { AnyAction } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { Store } from '..'
import AuthStore from 'store/Auth'
import * as Config from 'config'
import { FetchWithCache } from 'utils/api'
import Selectors from './selectors'
import ResultMetaDataStore from 'store/ResultMetaData'
import { IDeviceSetting } from 'utils/deviceSettings'
import { renewAuthorizationToken } from 'utils/token'
import {
  ActionMetaData,
  CustomAction,
  getActionMetaData
} from 'store/extension/customAction'

export enum ResultsProjectSpacesActionTypes {
  FETCH_REQUEST = 'resultsProjectSpaces/FETCH_REQUEST',
  FETCH_SUCCESS = 'resultsProjectSpaces/FETCH_SUCCESS',
  FETCH_SUCCESS_COMBINED = 'resultsProjectSpaces/FETCH_SUCCESS_COMBINED',
  FETCH_FAILURE = 'resultsProjectSpaces/FETCH_FAILURE',
  INITIALIZE_RESULTS_ALL = 'persist/REHYDRATE'
}

export interface IFetchRequest
  extends CustomAction<ResultsProjectSpacesActionTypes> {
  payload: {
    resetCombined: boolean
    searchQuery: string
  }
}
export type IFetchFailure = CustomAction<ResultsProjectSpacesActionTypes>
export interface IFetchSuccess
  extends CustomAction<ResultsProjectSpacesActionTypes> {
  payload: {
    response: any
  }
}

export const fetchRequest = (
  resetCombined: boolean,
  searchQuery: string,
  actionMetaData: ActionMetaData
): IFetchRequest => ({
  type: ResultsProjectSpacesActionTypes.FETCH_REQUEST,
  payload: {
    resetCombined: resetCombined,
    searchQuery: searchQuery
  },
  metaData: {
    ...actionMetaData,
    isStartAction: true
  }
})
export const fetchSuccess = (
  response: any = {},
  actionMetaData: ActionMetaData
): IFetchSuccess => ({
  type: ResultsProjectSpacesActionTypes.FETCH_SUCCESS,
  payload: { response },
  metaData: actionMetaData
})
export const fetchSuccessCombined = (
  response: any = {},
  actionMetaData: ActionMetaData
): IFetchSuccess => ({
  type: ResultsProjectSpacesActionTypes.FETCH_SUCCESS_COMBINED,
  payload: { response },
  metaData: actionMetaData
})
export const fetchFailure = (
  actionMetaData: ActionMetaData
): IFetchFailure => ({
  type: ResultsProjectSpacesActionTypes.FETCH_FAILURE,
  metaData: actionMetaData
})

export const fetchResultsProjectSpaces = (
  searchQuery: string,
  currentPage: number,
  deviceSettings: IDeviceSetting
  // eslint-disable-next-line @typescript-eslint/ban-types
): ThunkAction<Promise<void>, Store, {}, AnyAction> => {
  return async (
    // eslint-disable-next-line @typescript-eslint/ban-types
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => Store
  ) => {
    const actionMetaData = getActionMetaData('projectspaces')

    dispatch(
      ResultMetaDataStore.actions.fetchRequest('projectspaces', actionMetaData)
    )

    const resultsCombinedQuery = Selectors.getResultsCombinedQuery(getState())
    if (resultsCombinedQuery !== searchQuery) {
      if (resultsCombinedQuery === '') {
        dispatch(fetchRequest(false, searchQuery, actionMetaData))
      } else {
        dispatch(fetchRequest(true, searchQuery, actionMetaData))
      }
    } else {
      dispatch(fetchRequest(false, searchQuery, actionMetaData))
    }
    try {
      if (!searchQuery || searchQuery === '' || searchQuery === '""') {
        dispatch(fetchFailure(actionMetaData))
        return
      }

      const t0 = performance.now()

      // Build and get results ProjectSpaces data source
      const apiUrl = `${
        Config.APIM_BASE_URL
      }todefine/?querytext=${encodeURIComponent(searchQuery)}`

      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        return
      }

      const response = await FetchWithCache(apiUrl, {
        method: 'GET',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${esToken}`
        }
      })

      let result
      if (!response || response.hasError || !response.responseJSON) {
        dispatch(
          ResultMetaDataStore.actions.hasError(
            'projectspaces',
            response.errorResponse,
            undefined,
            actionMetaData
          )
        )
        dispatch(fetchFailure(actionMetaData))
        return
      } else {
        result = response.responseJSON
      }

      const results = result.searchResultModel
        ? result.searchResultModel.searchContent
          ? result.searchResultModel.searchContent.items
          : []
        : []
      const resultCount = result.searchResultModel
        ? result.searchResultModel.searchContent
          ? result.searchResultModel.searchContent.totalCount
          : 0
        : 0

      const t1 = performance.now()

      const executionTime = t1 - t0
      dispatch(
        fetchSuccess(
          {
            results,
            resultCount,
            executionTime,
            currentPage: currentPage || 1
          },
          actionMetaData
        )
      )
      dispatch(
        ResultMetaDataStore.actions.fetchSuccess(
          'projectspaces',
          {
            executionTime: executionTime,
            resultsCount: resultCount
          },
          actionMetaData
        )
      )

      const resultsCombined = Selectors.getResultsCombined(getState())
      if ((currentPage || 1) === 1) {
        dispatch(
          fetchSuccessCombined(
            {
              results
            },
            actionMetaData
          )
        )
      } else {
        dispatch(
          fetchSuccessCombined(
            {
              results: [...resultsCombined, ...results]
            },
            actionMetaData
          )
        )
      }
    } catch (error) {
      dispatch(
        ResultMetaDataStore.actions.hasError(
          'projectspaces',
          undefined,
          error,
          actionMetaData
        )
      )
      dispatch(fetchFailure(actionMetaData))
    }
  }
}

export type ResultsProjectSpacesActions =
  | IFetchRequest
  | IFetchFailure
  | IFetchFailure
