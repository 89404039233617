import * as Config from 'config'
import { validateCurrentLocation } from './string'
import { ReduceSessionStorageSize } from './api'
import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser'

/**
 * Checks if the provided authorization token has expired
 * @param authToken The authorization token
 */
export function tokenHasExpired(authToken: string): boolean {
  let hasExpired = false
  if (authToken !== '') {
    const decoded = decodeToken(authToken)
    const now = new Date()
    if (new Date(decoded.exp * 1000) < now) {
      hasExpired = true
    }
  }
  return hasExpired
}

/**
 * Checks if the provided authorization token has expired
 * @param authToken The authorization token
 * @param instance The IPublicClientApplication instance
 * @param accounts The current logged in users accounts
 */
export async function renewAuthorizationToken(
  authToken: string,
  instance: IPublicClientApplication | null,
  accounts: AccountInfo[] | null
): Promise<string> {
  ReduceSessionStorageSize()
  let token = authToken
  if (tokenHasExpired(authToken) || authToken === '') {
    // Fix for malformed URL, msal auth provider breaks, we need to remove the %25 char and reload.
    const newLocation = validateCurrentLocation()
    if (newLocation !== '') window.location.href = newLocation
    if (instance && accounts && accounts.length > 0) {
      try {
        const response = await instance.acquireTokenSilent({
          scopes: [Config.ACTIVE_DIRECTORY_SCOPE],
          account: accounts[0]
        })
        if (response && response.accessToken) token = response.accessToken
      } catch (error) {
        const response = await instance.acquireTokenPopup({
          scopes: [Config.ACTIVE_DIRECTORY_SCOPE],
          account: accounts[0]
        })
        if (response && response.accessToken) token = response.accessToken
      }
    }
  }
  return token
}

/**
 * Gets the authorization token for Graph API
 * @param instance The IPublicClientApplication instance
 * @param accounts The current logged in users accounts
 */
export async function getGraphAuthToken(
  instance: IPublicClientApplication | null,
  accounts: AccountInfo[] | null
): Promise<string> {
  ReduceSessionStorageSize()

  let scopes: string[] = [
    'user.read',
    'profile',
    'calendars.read',
    'externalitem.read.all',
    'files.read.all',
    'mail.read',
    'channelmessage.read.all',
    'channelsettings.read.all',
    'chat.read',
    'teamsettings.read.all'
  ]
  if (Config.LOCAL_DEVELOPMENT) {
    scopes = [
      'user.read',
      'profile',
      'calendars.read',
      'mail.read',
      'chat.read'
    ]
  }
  let token = ''

  // Fix for malformed URL, msal auth provider breaks, we need to remove the %25 char and reload.
  const newLocation = validateCurrentLocation()
  if (newLocation !== '') window.location.href = newLocation

  if (instance && accounts && accounts.length > 0) {
    try {
      const response = await instance.acquireTokenSilent({
        scopes: scopes,
        account: accounts[0]
      })
      if (response && response.accessToken) token = response.accessToken
    } catch (error) {
      const response = await instance.acquireTokenPopup({
        scopes: scopes,
        account: accounts[0]
      })
      if (response && response.accessToken) token = response.accessToken
    }
  }
  return token
}

/**
 * Gets the authorization token for SharePoint Online
 * @param instance The IPublicClientApplication instance
 * @param accounts The current logged in users accounts
 * @param spURL Optional SharePoint Online URL, default spo-global.kpmg.com
 */
export async function getSPOnlineToken(
  instance: IPublicClientApplication | null,
  accounts: AccountInfo[] | null,
  spURL = Config.GLOBAL_OI_TOKEN_ENDPOINT
): Promise<string> {
  ReduceSessionStorageSize()
  const scopes: string[] = [`${spURL}/Sites.Search.All`]
  let token = ''

  // Fix for malformed URL, msal auth provider breaks, we need to remove the %25 char and reload.
  const newLocation = validateCurrentLocation()
  if (newLocation !== '') window.location.href = newLocation

  if (instance && accounts && accounts.length > 0) {
    try {
      const response = await instance.acquireTokenSilent({
        scopes: scopes,
        account: accounts[0]
      })
      if (response && response.accessToken) token = response.accessToken
    } catch (error) {
      const response = await instance.acquireTokenPopup({
        scopes: scopes,
        account: accounts[0]
      })
      if (response && response.accessToken) token = response.accessToken
    }
  }
  return token
}

/**
 * Decodes the received string
 * @param str The string to decode
 */
function urlBase64Decode(str: string): string {
  let output = str.replace(/-/g, '+').replace(/_/g, '/')
  switch (output.length % 4) {
    case 0:
      break
    case 2:
      output += '=='
      break
    case 3:
      output += '='
      break
    default:
      // tslint:disable-next-line:no-string-throw
      throw new Error('Illegal base64url string!')
  }
  return decodeURIComponent((window as any).escape(window.atob(output)))
}

/**
 * Decodes the received JWT token
 * @param token The JWT token to decode
 */
function decodeToken(token = ''): any {
  if (token === null || token === '') {
    return { upn: '' }
  }
  const parts = token.split('.')
  if (parts.length !== 3) {
    throw new Error('JWT must have 3 parts')
  }
  const decoded = urlBase64Decode(parts[1])
  if (!decoded) {
    throw new Error('Cannot decode the token')
  }
  return JSON.parse(decoded)
}
