import React, { Dispatch } from 'react'
import { connect } from 'react-redux'
import { Box, IconButton, Typography } from '@mui/material'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Link from '@mui/material/Link'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { Store } from 'store'
import UserSettingsStore from 'store/UserSettings'
import SettingsStore from 'store/Settings'
import {
  trackWidgetShown,
  trackException,
  trackLeftWidgetClick,
  trackEvents
} from 'utils/tracking'
import highlightText, { prepareHighlightWords } from 'utils/highlightText'
import { removeQueryParam } from 'utils/queryParams'
import { WidgetKey } from 'constants/widgets'
import 'styles/slick_carousel/slick-theme.css'
import 'styles/slick_carousel/slick.css'
import { getStylesLeftWidget } from 'styles/contents/resultpages/widgets/LeftWidget'
import createDOMPurify from 'dompurify'
import TooltipTitle from 'components/contents/common/TooltipTitle'
import FilterStore from 'store/Filters'
import {
  getCountryKeyFromName,
  getUrlParameterForCurrentFilters
} from 'utils/filters'
import { KPMGFindGlobalVariables } from 'store/KPMGFindGlobalVariables'
import { IOneIntranetResultStore } from 'store/ResultsOneIntranet/reducers'
import { CustomDescription } from 'components/contents/common/CustomDescription'
import { IOneIntranetResult } from 'components/models/OneIntranetResult'
import LeftWidgetSlider from './common/LeftWidgetSlider'

export interface LeftIntranetWidgetProps {
  searchQuery: any
  results: IOneIntranetResultStore
}

type AllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  LeftIntranetWidgetProps

function LeftIntranetWidget(props: AllProps): JSX.Element {
  const {
    searchQuery,
    results,
    deviceSettings,
    userSettings,
    currentfilters,
    clearTemporaryFilter,
    setTemporaryFilter
  } = props

  const DOMPurify = createDOMPurify(window)

  const navigateFunction = useNavigate()
  const leftWidgetClasses = getStylesLeftWidget()

  const intl = useIntl()

  React.useEffect(() => {
    trackWidgetShown({
      widgetType: WidgetKey.intranet,
      widgetPane: 'left'
    })
  }, [])

  let slides: JSX.Element[] = []
  try {
    slides = results.queryResults.map((item: IOneIntranetResult, i: number) => (
      <div key={'li' + i}>
        <Box key={'lib1' + i} style={{ width: 20 }} />
        <Link
          key={'lil1' + i}
          onClick={() => {
            removeQueryParam(navigateFunction, 'cntx')
            trackLeftWidgetClick({
              title: DOMPurify.sanitize(item.Title),
              url: DOMPurify.sanitize(item.Path),
              widgetKey: WidgetKey.intranet,
              index: i
            })
          }}
          {...(!deviceSettings.isMobile
            ? {
                onAuxClick: (event: any) => {
                  removeQueryParam(navigateFunction, 'cntx')
                  trackLeftWidgetClick({
                    title: DOMPurify.sanitize(item.Title),
                    url: DOMPurify.sanitize(item.Path),
                    widgetKey: WidgetKey.intranet,
                    index: i,
                    wasRightClicked: event?.button === 2 ? true : false
                  })
                }
              }
            : {})}
          href={DOMPurify.sanitize(item.Path)}
          className={leftWidgetClasses.cardLink}
          {...(deviceSettings.openLinksInNewTab === true
            ? { target: '_blank', rel: 'noreferrer' }
            : {})}
        >
          <Card key={'lic' + i} className={leftWidgetClasses.card}>
            <CardContent
              key={'licc' + i}
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <Typography
                key={'lit1' + i}
                variant="body1"
                color="primary"
                component="div"
                style={{
                  minHeight: 48
                }}
              >
                <TooltipTitle
                  title={DOMPurify.sanitize(item.Title?.trim())}
                  key={'lnd1' + i}
                />
              </Typography>
              <Typography
                key={'lit2' + i}
                variant="body2"
                color="textSecondary"
                component="div"
                style={{
                  maxHeight: 160,
                  minHeight: 160,
                  maxWidth: 164,
                  overflow: 'hidden'
                }}
              >
                <CustomDescription
                  descriptionValue={
                    item.Body
                      ? highlightText(
                          DOMPurify.sanitize(item.Body?.trim()),
                          prepareHighlightWords(
                            DOMPurify.sanitize(searchQuery),
                            DOMPurify.sanitize(item.Body?.trim()),
                            results.synonymsApplied
                          ),
                          true
                        )
                      : ''
                  }
                  maxLine="6"
                />
              </Typography>
              <Box key={'lib2' + i} className={leftWidgetClasses.spacer} />
              <Typography
                key={'lit3' + i}
                variant="body2"
                color="textSecondary"
                component="div"
                style={{ fontStyle: 'italic' }}
              >
                {intl.formatMessage({ id: 'origin', defaultMessage: 'Source' })}
                :{' One Intranet'}
              </Typography>
            </CardContent>
          </Card>
        </Link>
      </div>
    ))
  } catch (error) {
    trackException('Error in parsing slides in LeftIntranetWidget.tsx', error)
  }

  if (deviceSettings.renderMobile) {
    slides.push(<Box style={{ flex: '0 0 10px' }} />)
  }

  const seeAllLink = `/results/all/${getUrlParameterForCurrentFilters(
    currentfilters,
    [{ key: 'page', value: '1' }]
  )}`

  // If widget prefiltering is enabled, add it to the see all link
  const setTemporaryFilters = () => {
    clearTemporaryFilter()
    let countryCode = 'none'
    if (
      userSettings &&
      userSettings.EnableWidgetPrefiltering === true &&
      userSettings.Country &&
      userSettings.Country !== ''
    ) {
      const countryKey = getCountryKeyFromName(userSettings.Country)
      countryCode = countryKey ? countryKey : countryCode
    }

    setTemporaryFilter({
      key: 'country',
      value:
        countryCode === 'none' ? countryCode : countryCode.toLocaleUpperCase()
    })

    KPMGFindGlobalVariables.setIsRedirectFromWidget(true)
  }

  if (deviceSettings.renderMobile && slides.length >= 8)
    slides.push(
      <Box className={leftWidgetClasses.showallContainer}>
        <Box className={leftWidgetClasses.showallContent}>
          <RouterLink
            tabIndex={-1} //focus IconButton
            to={seeAllLink}
            style={{ textDecoration: 'none' }}
            onClick={() => {
              trackEvents('widget-see-all', {
                widgetType: WidgetKey.intranet,
                searchTerm: DOMPurify.sanitize(searchQuery),
                wasRightClicked: false
              })

              setTemporaryFilters()
            }}
            {...(!deviceSettings.isMobile
              ? {
                  onAuxClick: (event: any) => {
                    trackEvents('widget-see-all', {
                      widgetType: WidgetKey.intranet,
                      searchTerm: DOMPurify.sanitize(searchQuery),
                      wasRightClicked:
                        event && event.button === 2 ? true : false
                    })
                  }
                }
              : {})}
          >
            <IconButton className={leftWidgetClasses.iconButton}>
              <ArrowForwardIcon />
            </IconButton>
          </RouterLink>
          <Typography className={leftWidgetClasses.showallMessage}>
            <FormattedMessage id="show_all" defaultMessage="Show All" />
          </Typography>
        </Box>
      </Box>
    )

  return (
    <Box
      id={'con-widget-left-oneintranet'}
      className={leftWidgetClasses.container}
    >
      {results.queryResults && results.queryResults.length > 0 && (
        <>
          <RouterLink
            to={seeAllLink}
            style={{ textDecoration: 'none' }}
            onClick={() => {
              trackEvents('widget-see-all', {
                widgetType: WidgetKey.intranet,
                searchTerm: DOMPurify.sanitize(searchQuery),
                wasRightClicked: false
              })

              setTemporaryFilters()
            }}
            {...(!deviceSettings.isMobile
              ? {
                  onAuxClick: (event: any) => {
                    trackEvents('widget-see-all', {
                      widgetType: WidgetKey.intranet,
                      searchTerm: DOMPurify.sanitize(searchQuery),
                      wasRightClicked:
                        event && event.button === 2 ? true : false
                    })
                  }
                }
              : {})}
          >
            <Typography
              variant="h6"
              color="primary"
              component="p"
              className={leftWidgetClasses.link}
              gutterBottom
            >
              {intl.formatMessage({
                id: 'intranet_on',
                defaultMessage: 'Related Intranet'
              })}
            </Typography>
          </RouterLink>
          <Box>
            <LeftWidgetSlider
              slides={slides}
              resultsLength={results.queryResults.length}
            />
          </Box>
        </>
      )}
    </Box>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    userSettings: UserSettingsStore.selectors.getUserSettings(state),
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state),
    currentfilters: FilterStore.selectors.getCurrentFilters(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    clearTemporaryFilter: () =>
      dispatch(FilterStore.actions.clearTemporaryFilter()),
    setTemporaryFilter: (currentFilter: CurrentFilter) =>
      dispatch(FilterStore.actions.setTemporaryFilter(currentFilter))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LeftIntranetWidget)
