import { Store } from '..'
import { IDeviceSetting } from 'utils/deviceSettings'
import { IDataSource } from 'constants/datasourcesConfiguration'
import { IFindConfiguration } from './reducers'

const selectors = {
  getDeviceSettings: (state: Store): IDeviceSetting => {
    const result = state.settings.deviceSettings
    result.openLinksInNewTab = state.userSettings.userSettings.OpenLinksInNewTab
    return result
  },
  getDatasourceSettings: (state: Store): IDataSource[] =>
    state.settings.datasourceSettings,
  getCurrentDataSources: (state: Store): IDataSource[] =>
    state.settings.currentDataSources,
  getShowSettings: (state: Store): any => state.settings.showSettings,
  getFindConfiguration: (state: Store): IFindConfiguration =>
    state.settings.findConfiguration,
  gethasFindConfigurationBeenFetched: (state: Store): boolean =>
    state.settings.hasFindConfigurationBeenFetched,
  gethasDataSourceSettingsBeenFetched: (state: Store): boolean =>
    state.settings.hasDataSourceSettingsBeenFetched,
  getFindConfigurationCriticalError: (state: Store): boolean =>
    state.settings.hasFindConfigurationCriticalError,
  getDatasourceSettingsCriticalError: (state: Store): boolean =>
    state.settings.hasDatasourceSettingsCriticalError,
  getUseCognitiveSearch: (state: Store): boolean =>
    state.settings.UseCognitiveSearch,
  getUseEntityRecognition: (state: Store): boolean =>
    state.settings.UseEntitiyRecognition,
  getInternalBroadcast: (state: Store): string[] =>
    state.settings.internalBroadcast
}

export default selectors
