import { Reducer } from 'redux'
import { IWidgetSelectConfig } from 'utils/widgets/resultWidgets'
import {
  WidgetActionTypes,
  WidgetActions,
  ILeftWidgetFetched,
  IRightWidgetFetched
} from './actions'

export interface IWidgetStore {
  leftWidgetFetched: boolean
  leftWidget: React.ReactElement | null
  leftWidgetConfig: IWidgetSelectConfig | null
  leftWidgetHasError: boolean

  rightWidgetFetched: boolean
  rightWidget: React.ReactElement | null
  rightWidgetConfig: IWidgetSelectConfig | null
  rightWidgetHasError: boolean
}

const initialStateLeft: any = {
  leftWidgetFetched: false,
  leftWidgetConfig: null,
  leftWidget: null,
  leftWidgetHasError: false
}
const initialStateRight: any = {
  rightWidget: null,
  rightWidgetFetched: false,
  rightWidgetConfig: null,
  rightWidgetHasError: false
}

const initialState: IWidgetStore = {
  ...initialStateLeft,
  ...initialStateRight
}

const reducers: Reducer<IWidgetStore, WidgetActions> = (
  state: IWidgetStore = initialState,
  action: WidgetActions
) => {
  switch (action.type) {
    case WidgetActionTypes.FETCH_WIDGETS:
      return initialState
    case WidgetActionTypes.FETCH_WIDGETS_LEFT:
      return { ...state, ...initialStateLeft }
    case WidgetActionTypes.FETCH_WIDGETS_RIGHT:
      return { ...state, ...initialStateRight }
    case WidgetActionTypes.FETCH_WIDGETS_LEFT_FAILURE:
      return {
        ...state,
        ...initialStateLeft,
        leftWidgetHasError: true
      }

    case WidgetActionTypes.FETCH_WIDGETS_RIGHT_FAILURE:
      return {
        ...state,
        ...initialStateRight,
        rightWidgetHasError: true
      }
    case WidgetActionTypes.LEFT_WIDGET_FETCHED:
      const { leftWidget, leftWidgetConfig } = (action as ILeftWidgetFetched)
        .payload
      return {
        ...state,
        leftWidget: leftWidget,
        leftWidgetConfig: leftWidgetConfig,
        leftWidgetFetched: true,
        leftWidgetHasError: false
      }
    case WidgetActionTypes.RIGHT_WIDGET_FETCHED:
      const { rightWidget, rightWidgetConfig } = (action as IRightWidgetFetched)
        .payload
      return {
        ...state,
        rightWidget: rightWidget,
        rightWidgetConfig: rightWidgetConfig,
        rightWidgetFetched: true,
        rightWidgetHasError: false
      }

    default:
      return state
  }
}

export default reducers
