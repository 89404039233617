import React from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  GridSize,
  Link,
  Typography
} from '@mui/material'
import { FormattedMessage } from 'react-intl'
import ContactSupport from '@mui/icons-material/ContactSupport'
import { getStylesContactUsModal } from 'styles/contents/ContactUsModal'
import * as MuiIcons from '@mui/icons-material'
import { ContactUsTile } from 'components/models/ContactUsTile'

export interface IContactUsModalProps {
  contactUsTiles: ContactUsTile[]
  open: boolean
  onClose: () => void
  renderMobile: boolean
}

const ContactUsModal = (props: IContactUsModalProps): any => {
  const { contactUsTiles, open, onClose, renderMobile } = props
  const classes = getStylesContactUsModal()

  return !open ? null : (
    <Dialog
      id={'con-settings-01'}
      open={open}
      onClose={onClose}
      className={classes.dialogContainer}
    >
      <DialogContent
        className={classes.dialogContent}
        data-tut="reactour__modal_contactus"
      >
        <Box id={'con-settings-02'} className={classes.background}>
          <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            justifyContent="center"
            className={classes.headerContainer}
          >
            <div className={classes.headerIcon}>
              <ContactSupport
                fontSize="large"
                style={{ width: 'inherit', height: 'inherit' }}
              />
            </div>

            <Typography
              className={classes.headerDescription}
              align="center"
              variant="h6"
            >
              <FormattedMessage
                id="contact_us_description"
                defaultMessage="Your feedback is a very valuable way for us to learn how we can enhance our services. How can we help today?"
              />
            </Typography>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            justifyContent="center"
          >
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="stretch"
              spacing={2}
            >
              {contactUsTiles.map((contactUsTile: ContactUsTile) => {
                if (contactUsTile.isActive) {
                  let Icon
                  try {
                    Icon =
                      MuiIcons[contactUsTile.image as keyof typeof MuiIcons]
                  } catch (e: any) {}
                  const xs: GridSize = renderMobile ? 12 : 6
                  return (
                    <Grid item className={classes.gridItem} xs={xs}>
                      <Link
                        className={classes.contentLink}
                        href={contactUsTile.link}
                        target={'_blank'}
                        color="inherit"
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          flexDirection="column"
                          justifyContent="flex-start"
                          className={classes.contentItemContainer}
                        >
                          <div
                            className={`${classes.headerIcon} ${classes.contentIcon}`}
                          >
                            {Icon && (
                              <Icon
                                style={{ width: 'inherit', height: 'inherit' }}
                              />
                            )}
                          </div>
                          <Typography
                            className={classes.contentTitle}
                            align="center"
                            variant="h6"
                          >
                            {contactUsTile.title}
                          </Typography>

                          <Typography
                            className={classes.contentDescription}
                            align="center"
                            variant="body1"
                          >
                            {contactUsTile.description}
                          </Typography>
                        </Box>
                      </Link>
                    </Grid>
                  )
                }
                return ''
              })}
            </Grid>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          onClick={onClose}
          color="primary"
          className={classes.closeButton}
        >
          <FormattedMessage id="ok" defaultMessage="Ok" />
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ContactUsModal
