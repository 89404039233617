import { trackException } from 'utils/tracking'

/**
 * Formats the display body, based on input date and body, returns a ' - ' separated value if necessary
 * @param date The input date
 * @param body The input body
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function formatDisplayBody(date: any, body: any, intl: any): string {
  try {
    if (!body) {
      body = intl.formatMessage({
        id: 'missing_description_text',
        defaultMessage: 'Description is not available'
      })
    }

    let displayBody = ''
    if (date !== null && date !== '') {
      displayBody += date
      if (body !== null && body !== '') {
        displayBody += ' - ' + body
      }
    } else if (body !== null && body !== '') {
      displayBody += body
    }
    return displayBody
  } catch (error) {
    trackException('Error in formatDisplayBody method of intranet utils', error)
    return ''
  }
}
