import { enterpriseSearchEmail } from 'constants/constants'
import { IntlShape } from 'react-intl'
import { IErrorResponse } from 'utils/api'
import { capitalizeFirstLetter } from './string'

export const isInvalidQueryError = (
  errorObject: IErrorResponse | null
): boolean => {
  if (errorObject && errorObject.responseCode === 400) {
    return true
  }

  return false
}

export const isAppGatewayError = (
  errorObject: IErrorResponse | null
): boolean => {
  if (errorObject && errorObject.responseCode === 403) {
    if (
      errorObject.orginalResponseBody &&
      errorObject.orginalResponseBody.indexOf(
        'Microsoft-Azure-Application-Gateway'
      ) >= 0
    ) {
      return true
    } else if (
      errorObject.messageKey &&
      errorObject.messageKey.indexOf('error_unexpected_internal') >= 0
    ) {
      return true
    }
  }

  if (
    errorObject &&
    errorObject.exception &&
    errorObject.exception.message.indexOf('403:') >= 0
  ) {
    return true
  }

  return false
}

export const getCookie = (name: string) => {
  const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'))
  if (match && match.length > 2) {
    const id = match[2]
    if (id) {
      return id.split('|')[0]
    }
  }

  return ''
}

export const getTranslatedDatesource = (
  intl: IntlShape,
  datasourceName: string,
  origin?: string
) => {
  let translatedDatasource = intl.formatMessage({
    id: datasourceName,
    defaultMessage: datasourceName
  })
  if (origin) translatedDatasource += ' ' + capitalizeFirstLetter(origin)

  if (translatedDatasource === 'Datasource' && datasourceName === 'kpmg.com') {
    translatedDatasource = intl.formatMessage({
      id: 'kpmg_websites',
      defaultMessage: datasourceName
    })
  }
  if (translatedDatasource === 'Datasource' && datasourceName === 'kpmg mpp') {
    translatedDatasource = intl.formatMessage({
      id: 'kpmg_mpp',
      defaultMessage: datasourceName
    })
  }
  return translatedDatasource
}

export const getAppGtwErrorMailAddress = (
  searchQuery: string,
  intl: IntlShape
) => {
  const incorrectLink = {
    address: enterpriseSearchEmail,
    subject: intl.formatMessage({
      id: 'app_gateway_error_subject',
      defaultMessage: '[App Gateway Error: Search Query]'
    }),
    body: intl
      .formatMessage({
        id: 'app_gateway_error_body',
        defaultMessage:
          'Hi Team,\n\nI came across an Application Gateway issue when introducing what I believe is a correct search query:\n\n{searchQuery}\n\n'
      })
      .replaceAll('\\n', '\n')
      .replace('{searchQuery}', searchQuery)
  }

  const linkUrl = `mailto:${incorrectLink.address}?subject=${encodeURI(
    incorrectLink.subject
  )}&body=${encodeURIComponent(incorrectLink.body)}`

  return linkUrl
}
