import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesRightOneDriveWidget(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles(): any {
  return makeStyles((theme: Theme) => ({
    widgetContent: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: 14
    },
    imageContainer: {
      marginRight: 14
    },
    widgetTextContainer: {
      width: 'calc(100% - 54px)',
      overflow: 'hidden',
      whiteSpace: 'pre-wrap',
      textOverflow: 'ellipsis'
    },
    widgetTitle: {
      lineHeight: '20px'
    },
    widgetDescription: {
      wordBreak: 'break-word',
      wordWrap: 'break-word'
    },
    fileResultIcon: {
      verticalAlign: 'middle'
    },
    link: {
      display: 'block'
    },
    widgetFooterContent: {
      paddingTop: 0
    }
  }))
}
