import React from 'react'
import * as Config from 'config'
import { ResultRightWidgetGenerator } from './interfaces'
import { FetchWithCache } from '../api'
import RightThoughtLeadershipWidget from 'components/contents/resultpages/widgets/RightThoughtLeadershipWidget'
import { stripHtml } from 'utils/string'
import { trackException } from 'utils/tracking'
import { getSPOnlineToken, renewAuthorizationToken } from 'utils/token'
import {
  IOneIntranetResultQuery,
  oneIntranetDefaultQuery,
  removeStopWordsFromQuery
} from 'utils/oneIntranet'
import { IDeviceSetting } from 'utils/deviceSettings'
import { IUserSetting } from 'utils/userSettings'
import { IFindConfiguration } from 'store/Settings/reducers'
import { IThoughtLeadershipResult } from 'components/models/ThoughtLeaderShipResult'
import { ISynonymsApplied } from 'components/models/SynonymsApplied'
import { IAADState } from 'store/Auth/reducers'

export const rightThoughtLeadershipWidgetGenerator: ResultRightWidgetGenerator =
  {
    fetch(
      aadInfo: IAADState,
      searchQuery: string,
      deviceSettings: IDeviceSetting,
      userSettings: IUserSetting,
      findConfiguration: IFindConfiguration,
      useCognitiveSearch: boolean
    ): Promise<{
      queryResult: IThoughtLeadershipResult[]
      synonymsApplied: ISynonymsApplied[]
    }> {
      return new Promise<{
        queryResult: IThoughtLeadershipResult[]
        synonymsApplied: ISynonymsApplied[]
      }>((resolve, reject) => {
        // Current production API Query
        const fetchThoughtLeadership = async () => {
          if (!searchQuery || searchQuery === '' || searchQuery === '""') {
            trackException(
              'Error in fetching Thought Leadership right widget in rightThouhgtLeadershipWidget.tsx',
              new Error('Empty query. Please specify a valid query text')
            )
            reject()
            return
          }
          let spoToken = ''
          if (Config.LOCAL_DEVELOPMENT.toString() === 'false') {
            spoToken = await getSPOnlineToken(
              aadInfo.instance,
              aadInfo.accounts
            )
            if (spoToken === '') {
              throw new Error(
                'Authentication: Cannot renew SPO authentication token'
              )
            }
          }

          const apiUrl = `${Config.APIM_BASE_URL}oneintranetapi/postsearchoi`

          const requestBody: IOneIntranetResultQuery = Object.assign(
            {},
            oneIntranetDefaultQuery
          )
          requestBody.rowlimit = 10
          requestBody.rowsperpage = 10
          requestBody.origin = 'thoughtleadership'
          requestBody.selectproperties =
            'Title,Write,Path,HitHighlightedSummary,Description,FreshDescription,DefaultEncodingURL,ExcludeFromSummary,PictureThumbnailUrl,FreshTax5'
          requestBody.querytext = searchQuery
          requestBody.cognitiveEnabled = false

          // Get and check authentication token
          const esToken = await renewAuthorizationToken(
            aadInfo.accessToken,
            aadInfo.instance,
            aadInfo.accounts
          )

          if (
            useCognitiveSearch &&
            findConfiguration.CognitiveSearchEnabled &&
            esToken !== ''
          ) {
            let searchQueryLanguage = ''
            const detectLanguageApiUrl = `${
              Config.APIM_BASE_URL
            }searchapi/detectlanguage?searchQuery=${encodeURIComponent(
              searchQuery
            )}`

            const languageResponse = await FetchWithCache(
              detectLanguageApiUrl,
              {
                method: 'POST',
                headers: {
                  accept: 'application/json',
                  'content-type': 'application/json',
                  'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
                  Authorization: `Bearer ${esToken}`
                }
              }
            )
            if (
              languageResponse &&
              !languageResponse.hasError &&
              languageResponse.responseJSON
            ) {
              const languageJSON = languageResponse.responseJSON
              if (languageJSON && languageJSON.responseCode === 200) {
                searchQueryLanguage = languageJSON.language
              }
            }
            requestBody.querytext = removeStopWordsFromQuery(
              searchQuery,
              searchQueryLanguage,
              useCognitiveSearch,
              findConfiguration.CognitiveSearchEnabled
            )
            requestBody.cognitiveEnabled = true
          }

          const response = await FetchWithCache(
            apiUrl,
            {
              method: 'POST',
              headers: {
                'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
                'content-type': 'application/json',
                Authorization: `Bearer ${spoToken}`,
                'Authorization-OnPrem': `${esToken}`
              },
              body: JSON.stringify(requestBody)
            },
            undefined,
            true,
            'widget_thoughtleadership',
            undefined,
            searchQuery
          )

          let results
          if (!response || response.hasError || !response.responseJSON) {
            if (
              !response ||
              !response.errorResponse ||
              response.errorResponse.responseCode !== 499
            ) {
              trackException(
                'Error in fetching Thought Leadership right widget in rightThoughtLeadershipWidget.tsx',
                new Error(
                  response && response.errorResponse
                    ? response.errorResponse.responseCode +
                      ':' +
                      response.errorResponse.message
                    : ''
                )
              )
            }
            reject()
            return
          } else {
            results = response.responseJSON
          }

          const primaryResults =
            results && results.QueryResults ? results.QueryResults : []

          if (!primaryResults || primaryResults.length < 3) {
            reject()
            return
          }

          const thoughtLeadershipItems: IThoughtLeadershipResult[] = []

          primaryResults.forEach((result: any) => {
            // Parse abstract
            let abstract = ''
            if (result.Description && result.Description !== '') {
              abstract = result.Description
            } else if (
              result.HitHighlightedSummary &&
              result.HitHighlightedSummary !== ''
            ) {
              abstract = result.HitHighlightedSummary
            } else if (
              result.ExcludeFromSummary &&
              result.ExcludeFromSummary !== ''
            ) {
              abstract = result.ExcludeFromSummary
            } else if (
              result.FreshDescription &&
              result.FreshDescription !== ''
            ) {
              abstract = result.FreshDescription
            }

            // Parse document url
            let documentUrl = ''
            if (result.Path && result.Path !== '') {
              documentUrl = result.Path
            } else if (
              result.DefaultEncodingURL &&
              result.DefaultEncodingURL !== ''
            ) {
              documentUrl = result.DefaultEncodingURL
            }

            // Parse image url
            let imageUrl = ''
            if (
              result.PictureThumbnailUrl &&
              result.PictureThumbnailUrl !== ''
            ) {
              imageUrl = result.PictureThumbnailUrl
            }
            if (
              imageUrl.startsWith('<img') &&
              documentUrl !== null &&
              documentUrl !== ''
            ) {
              try {
                const siteUrl = new URL(documentUrl).origin
                const sourceRegex = /<img[^>]+src="([^">]+)"/g
                const source = sourceRegex.exec(imageUrl)
                if (source) {
                  imageUrl = siteUrl.replace('http://', 'https://') + source[1]
                }
              } catch (error) {
                trackException(
                  'Error in parsing imageUrl in WidgetThoughtLeadership.tsx',
                  error
                )
                imageUrl = ''
              }
            }

            // Parse date
            let date = ''
            if (result.Write && result.Write !== '') {
              date = result.Write
            }

            // Parse toolkit title
            let toolkitTitle = ''
            if (result.Title && result.Title !== '') {
              toolkitTitle = result.Title
            }

            // Parse data origin
            let dataOrigin = ''
            if (result.FreshTax5 && result.FreshTax5 !== '') {
              dataOrigin = result.FreshTax5.replaceAll(';', ',')
            }

            const item: IThoughtLeadershipResult = {
              abstract: abstract && abstract !== '' ? stripHtml(abstract) : '',
              dataOrigin: dataOrigin && dataOrigin !== '' ? dataOrigin : '',
              date: date && date !== '' ? stripHtml(date) : '',
              documentUrl: documentUrl ? documentUrl : '',
              image: imageUrl ? imageUrl : '',
              title: result.Title ? result.Title : '',
              toolkitTitle:
                toolkitTitle && toolkitTitle !== ''
                  ? stripHtml(toolkitTitle)
                  : ''
            }
            thoughtLeadershipItems.push(item)
          })

          resolve({
            queryResult: thoughtLeadershipItems,
            synonymsApplied: results.synonymApplied
          })
        }

        try {
          fetchThoughtLeadership()
        } catch (error) {
          trackException(
            'Error in fetching Thought Leadership right widget in rightThoughtLeadershipWidget.tsx',
            error
          )
          reject()
        }
      })
    },
    generate(
      resultsRaw: {
        queryResult: IThoughtLeadershipResult[]
        synonymsApplied: ISynonymsApplied[]
      },
      searchQuery: string
    ): Promise<React.ReactElement> {
      return new Promise<React.ReactElement>((resolve, reject) => {
        try {
          let hasContent = false
          for (let i = 0; i < resultsRaw.queryResult.length; i++) {
            if (resultsRaw.queryResult[i].title !== '') {
              hasContent = true
              break
            }
          }
          if (!hasContent) {
            reject()
            return
          }

          resolve(
            <RightThoughtLeadershipWidget
              searchQuery={searchQuery}
              results={resultsRaw.queryResult}
              synonymsApplied={resultsRaw.synonymsApplied}
            />
          )
        } catch (error) {
          trackException(
            'Error in generating Thought Leadership right widget in rightThoughtLeadershipWidget.tsx',
            error
          )
          reject()
        }
      })
    }
  }
