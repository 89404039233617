// KPMG Find Configuration Variables
export const ACTIVE_DIRECTORY_CLIENT_ID = '%%%AADClientID%%%'
export const ACTIVE_DIRECTORY_AUTHORITY = '%%%AADAuthority%%%'
export const ACTIVE_DIRECTORY_SCOPE = '%%%AADScope%%%'
export const APIM_BASE_URL = '%%%ApimBaseUrl%%%'
export const OCP_APIM_SUBSCRIPTION_KEY = '%%%OcpApimSubscriptionKey%%%'
export const SOURCE_SERVICE_URL = '%%%KPMG_Source_Service_URL%%%'
export const APP_INSIGHTS_INTRUMENTATION_KEY =
  '%%%AppInsightsInstrumentationKey%%%'
export const APP_URL = '%%%VanityURL%%%'
export const PEOPLE_US_BASE_URL = '%%%KPMG_Intranet_US_People_Portal_URL%%%'
export const BUILD_NUMBER = '%%%BuildNumber%%%'
export const LOCAL_DEVELOPMENT = '%%%LocalDevelopment%%%'
export const GLOBAL_OI_TOKEN_ENDPOINT = '%%%KPMG_GLOBAL_OI_TOKEN_ENDPOINT%%%'
export const CONFLUENCE_FI_SERVICE_URL = '%%%ConfluenceFinlandServiceURL%%%'
export const IBFD_BASE_URL = '%%%IBFDBaseURL%%%'
export const SERVICE_NOW_AU_BASE_URL = '%%%ServiceNowAUBaseUrl%%%'
export const SERVICE_NOW_CH_BASE_URL = '%%%ServiceNowChBaseUrl%%%'
