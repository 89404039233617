import { FeaturedResult } from 'components/models/FeaturedResult'
import { Reducer } from 'redux'
import {
  ResultsStatisticsActions,
  ResultsStatisticsActionTypes,
  IFetchSuccess,
  IFetchSuccessCombined
} from './actions'
import { IStatisticResult } from 'components/models/StatisticResult'

export interface IResultsStatisticsStore {
  hasError: boolean
  hasResultsBeenFetched: boolean
  featuredResults: FeaturedResult[]
  results: IStatisticResult[]
  resultsCombined: IStatisticResult[]
  resultsCombinedQuery: string
  resultCount: number
  executionTime: number
  currentPage: number
}

const initialState: IResultsStatisticsStore = {
  hasError: false,
  hasResultsBeenFetched: false,
  resultCount: -1,
  featuredResults: [],
  results: [],
  resultsCombined: [],
  resultsCombinedQuery: '',
  executionTime: 0,
  currentPage: 1
}

const reducers: Reducer<IResultsStatisticsStore, ResultsStatisticsActions> = (
  state: IResultsStatisticsStore = initialState,
  action: ResultsStatisticsActions | { type: 'SET_CURRENT_PAGE'; payload: any }
) => {
  switch (action.type) {
    case 'SET_CURRENT_PAGE':
      return {
        ...state,
        currentPage: action.payload || 0
      }
    case ResultsStatisticsActionTypes.INITIALIZE_RESULTS_STATISTICS:
      if (!(action as any).payload) {
        return initialState
      }
      const { resultsStatistics } = (action as any).payload
      return {
        ...state,
        currentPage: resultsStatistics.currentPage || 1
      }
    case ResultsStatisticsActionTypes.FETCH_REQUEST:
      const { resetCombined, searchQuery } = (action as any).payload
      return {
        ...state,
        hasError: false,
        hasResultsBeenFetched: false,
        executionTime: 0,
        resultCount: -1,
        results: [],
        resultsCombined: resetCombined ? [] : state.resultsCombined,
        resultsCombinedQuery: searchQuery
      }
    case ResultsStatisticsActionTypes.FETCH_FAILURE:
      return {
        ...state,
        hasError: true,
        hasResultsBeenFetched: true,
        resultCount: 0
      }
    case ResultsStatisticsActionTypes.FETCH_SUCCESS:
      const { response, featuredResults } = (action as IFetchSuccess).payload
      return {
        ...state,
        hasError: false,
        hasResultsBeenFetched: true,
        executionTime: response.executionTime,
        resultCount: response.resultCount,
        results: response.queryResults,
        featuredResults:
          response.currentPage === 1 ? featuredResults : state.featuredResults,
        currentPage: response.currentPage
      }
    case ResultsStatisticsActionTypes.FETCH_SUCCESS_COMBINED:
      const { response: responseCombined } = (action as IFetchSuccessCombined)
        .payload
      return {
        ...state,
        hasError: false,
        resultCount: responseCombined.resultCount,
        resultsCombined: responseCombined.queryResults
      }
    default:
      return state
  }
}

export default reducers
