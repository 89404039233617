import React, { Dispatch, useMemo } from 'react'
import { Box, Fade, Table, TableBody } from '@mui/material'
import { connect } from 'react-redux'
import ReactPlaceholder from 'react-placeholder'
import { Store } from 'store'
import SettingsStore from 'store/Settings'
import ResultsOffice365Store from 'store/ResultsOffice365'
import { trackException } from 'utils/tracking'
import Pagination from 'components/contents/Pagination'
import { FileResult } from 'components/contents/results/FileResult'
import { HitsResponse, FileSourceResponse } from 'utils/o365'
import 'Placeholder.css'
import SearchSuggestions from 'components/contents/common/SearchSuggestions'
import O365FilterBar from './O365FilterBar'
import FeaturedResults from '../FeaturedResults'
import { useIntl } from 'react-intl'
import LeftWidgetContainer from '../widgetscontainer/LeftWidgetContainer'
import { getStylesResults } from 'styles/contents/resultpages/Results'
import { getStylesResultsO365 } from 'styles/contents/resultpages/ResultsO365'
import { useFilter } from 'utils/filters'
import createDOMPurify from 'dompurify'
import { CognitiveMessage } from 'components/contents/common/CognitiveMessage'
import { IUserSetting } from 'utils/userSettings'
import ErrorPage from 'components/contents/common/ErrorPage'
import { IAADState } from 'store/Auth/reducers'

export interface ResultFilesProps {
  datasources: any
  resultsCount: number
  searchQuery: string
  hasErrorStore: boolean
  fetchDocumentUrl: (
    documentId: string,
    driveId: string,
    fallBackUrl: string,
    openInNewTab: boolean
  ) => void
  firstRender: boolean
  userSettings: IUserSetting
  findConfigCognitiveSearchEnabled: boolean
  cognitiveSearchEnabled: boolean
  showCognitiveMessage: boolean
  setShowCognitiveMessage: Dispatch<boolean>
  setUserSettings: Dispatch<IUserSetting>
  aadInfo: IAADState
}

type AllProps = ReturnType<typeof mapStateToProps> & ResultFilesProps

function ResultFilesMobile(props: AllProps) {
  const {
    extendedResults,
    resultsCombined,
    resultsCount,
    hasResultsBeenFetched,
    datasources,
    deviceSettings,
    searchQuery,
    hasErrorStore,
    fetchDocumentUrl,
    firstRender,
    userSettings,
    findConfigCognitiveSearchEnabled,
    cognitiveSearchEnabled,
    showCognitiveMessage,
    setShowCognitiveMessage,
    setUserSettings,
    aadInfo
  } = props

  const DOMPurify = createDOMPurify(window)
  const intl = useIntl()
  const resultClasses = getStylesResults()
  const o365Classes = getStylesResultsO365()
  const [currentPage] = useFilter('page', '1')

  const returnAllItems = (type: string) => {
    if (!resultsCombined || resultsCombined.length < 1) {
      return []
    }

    const items = []

    try {
      const firstResults =
        resultsCombined.length < 4 ? resultsCombined.length : 3

      if (type === 'first') {
        for (let i = 0; i < firstResults; i++) {
          const file = resultsCombined[i] as HitsResponse<FileSourceResponse>
          items.push(
            <FileResult
              key={i}
              searchQuery={DOMPurify.sanitize(searchQuery)}
              item={file.resource}
              summary={DOMPurify.sanitize(file.summary)}
              isOpen={i < 3 && Number(DOMPurify.sanitize(currentPage)) === 1}
              deviceSettings={deviceSettings}
              documentId={DOMPurify.sanitize(file.resource.id)}
              driveId={
                file.resource.parentReference
                  ? DOMPurify.sanitize(file.resource.parentReference.driveId)
                  : ''
              }
              fetchDocumentUrl={fetchDocumentUrl}
              index={i}
              synonymsApplied={extendedResults.synonymsApplied}
              aadInfo={aadInfo}
            />
          )
        }
      } else {
        for (let i = firstResults; i < resultsCombined.length; i++) {
          const file = resultsCombined[i] as HitsResponse<FileSourceResponse>
          items.push(
            <FileResult
              key={i}
              searchQuery={DOMPurify.sanitize(searchQuery)}
              item={file.resource}
              summary={DOMPurify.sanitize(file.summary)}
              isOpen={i < 3 && Number(DOMPurify.sanitize(currentPage)) === 1}
              deviceSettings={deviceSettings}
              documentId={DOMPurify.sanitize(file.resource.id)}
              driveId={
                file.resource.parentReference
                  ? DOMPurify.sanitize(file.resource.parentReference.driveId)
                  : ''
              }
              fetchDocumentUrl={fetchDocumentUrl}
              index={i}
              synonymsApplied={extendedResults.synonymsApplied}
              aadInfo={aadInfo}
            />
          )
        }
      }
    } catch (error) {
      trackException(
        'Error returning all items in ResultsOffice365Mobile.tsx',
        error
      )
    }
    return items
  }

  const firstItems = useMemo(
    () => returnAllItems('first'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resultsCombined, extendedResults, intl]
  )

  const restItems = useMemo(
    () => returnAllItems('rest'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resultsCombined, extendedResults, intl]
  )

  return (
    <Box className={resultClasses.container}>
      <Box className={resultClasses.resultsContainer}>
        {!hasErrorStore && (
          <Box className={o365Classes.spellCheck}>
            <SearchSuggestions
              wildCardActive={true}
              resultCount={resultsCount}
              resultsFetchend={hasResultsBeenFetched}
            />
          </Box>
        )}
        {cognitiveSearchEnabled &&
          showCognitiveMessage &&
          extendedResults.synonymsApplied &&
          findConfigCognitiveSearchEnabled &&
          extendedResults.synonymsApplied.length > 0 && (
            <CognitiveMessage
              synonymsApplied={extendedResults.synonymsApplied}
              setShowCognitiveMessage={setShowCognitiveMessage}
              userSettings={userSettings}
              setUserSettings={setUserSettings}
            />
          )}
        <O365FilterBar {...props} scope={'Files'} datasources={datasources} />
        {!hasErrorStore && (
          <Box className={o365Classes.resultTableContainer}>
            <ReactPlaceholder
              ready={
                hasResultsBeenFetched ||
                Number(DOMPurify.sanitize(currentPage)) > 1
                  ? true
                  : false
              }
              type="text"
              rows={4}
              showLoadingAnimation={true}
              className={resultClasses.placeholder}
            >
              <Fade
                in={
                  hasResultsBeenFetched ||
                  Number(DOMPurify.sanitize(currentPage)) > 1
                    ? true
                    : false
                }
                timeout={600}
              >
                <Box>
                  {resultsCombined && resultsCount > 0 && !firstRender && (
                    <FeaturedResults
                      featuredResults={extendedResults.featuredResults}
                    />
                  )}
                  {firstItems != null && firstItems.length > 0 && (
                    <Table size="small">
                      <TableBody className={o365Classes.resultTable}>
                        {firstItems}
                      </TableBody>
                    </Table>
                  )}
                </Box>
              </Fade>
            </ReactPlaceholder>
            {hasResultsBeenFetched &&
            (resultsCount < 1 || resultsCombined.length < 1) ? null : (
              <LeftWidgetContainer
                resultCount={resultsCount}
                hasResultsBeenFetched={hasResultsBeenFetched}
              />
            )}
            <ReactPlaceholder
              ready={
                hasResultsBeenFetched ||
                Number(DOMPurify.sanitize(currentPage)) > 1
              }
              type="text"
              rows={4}
              showLoadingAnimation={true}
              className={resultClasses.placeholder}
            >
              <Fade
                in={
                  hasResultsBeenFetched ||
                  Number(DOMPurify.sanitize(currentPage)) > 1
                }
                timeout={600}
              >
                <Box>
                  {restItems != null && restItems.length > 0 && (
                    <Table size="small">
                      <TableBody className={o365Classes.resultTable}>
                        {restItems}
                      </TableBody>
                    </Table>
                  )}
                  {resultsCombined != null &&
                    resultsCombined.length > 0 &&
                    resultsCount != null &&
                    resultsCount > 0 && (
                      <Pagination
                        {...props}
                        totalRecords={resultsCount}
                        pageLimit={20}
                        pageNeighbours={4}
                      />
                    )}
                </Box>
              </Fade>
            </ReactPlaceholder>
            <ReactPlaceholder
              ready={hasResultsBeenFetched ? hasResultsBeenFetched : false}
              type="text"
              rows={4}
              showLoadingAnimation={true}
              className={resultClasses.placeholder}
            >
              <Box />
            </ReactPlaceholder>
            <ReactPlaceholder
              ready={hasResultsBeenFetched ? hasResultsBeenFetched : false}
              type="text"
              rows={4}
              showLoadingAnimation={true}
              className={resultClasses.placeholder}
            >
              <Box />
            </ReactPlaceholder>
          </Box>
        )}
        {hasErrorStore && (
          <ErrorPage
            dataSource="office365"
            origin={'files'}
            isContainerWrapped={true}
          />
        )}
      </Box>
    </Box>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    extendedResults: ResultsOffice365Store.selectors.getExtendedResults(
      state,
      'files'
    ),
    resultsCombined: ResultsOffice365Store.selectors.getResultsCombined(
      state,
      'files'
    ),
    hasResultsBeenFetched:
      ResultsOffice365Store.selectors.hasResultsBeenFetched(state, 'files'),
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state)
  }
}

export default connect(mapStateToProps)(ResultFilesMobile)
