import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesCognitiveMessage(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles() {
  return makeStyles((theme: Theme) => ({
    message: {
      fontSize: '14px',
      lineHeight: '20px',
      wordWrap: 'break-word', // older browsers
      overflowWrap: 'break-word', // css3,
      color: 'grey',
      marginBottom: '15px',
      [`${theme.breakpoints.down(1024)}`]: {
        paddingLeft: '7px'
      }
    },
    messageSynonyms: {
      fontWeight: 'bold',
      fontSize: '14px',
      lineHeight: '20px',
      wordWrap: 'break-word', // older browsers
      overflowWrap: 'break-word', // css3,
      color: 'grey'
    },
    messageNoShowSynonyms: {
      fontSize: '12px',
      textDecoration: 'underline',
      cursor: 'pointer',
      lineHeight: '20px',
      wordWrap: 'break-word', // older browsers
      overflowWrap: 'break-word', // css3,
      color: 'grey'
    },
    collapse: {
      visibility: 'visible',
      minHeight: '40px !important'
    },
    arrowIcon: { verticalAlign: 'bottom', cursor: 'pointer' }
  }))
}
