import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesPagination(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles() {
  return makeStyles((theme: Theme) => ({
    paginationOneIntranetContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      marginBottom: 20
    },
    paginationContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      [`${theme.breakpoints.down(1024)}`]: {
        //Hotfix as Pagination will have to be replaced on
        display: 'none'
      }
    },
    pagination: {
      marginTop: 0,
      marginBottom: 0,
      display: 'flex'
    },
    pageItem: {
      float: 'left'
    },
    pageLink: {
      padding: '8px',
      textAlign: 'center',
      borderColor: '#ced4da',
      color: '#004e98',
      textDecoration: 'none',
      fontSize: '1rem',
      display: 'flex',
      flexDirection: 'row',
      cursor: 'pointer',
      [`${theme.breakpoints.down(1024)}`]: {
        color: '#005eb8'
      },
      '@media (forced-colors: active)': {
        forcedColorAdjust: 'none',
        color: 'LinkText'
      }
    },
    pageLinkIcon: {
      marginTop: -3
    },
    pageLinkLabel: {
      [theme.breakpoints.down(700)]: {
        display: 'none'
      }
    },
    selected: {
      color: '#0091DA',
      '@media (forced-colors: active)': {
        forcedColorAdjust: 'none',
        color: 'CanvasText'
      }
    },
    loadMoreContainer: {
      display: 'none',
      [`${theme.breakpoints.down(1024)}`]: {
        //Hotfix as Pagination will have to be replaced on
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: '8px',
        marginBottom: '8px'
      }
    },
    loadMoreButton: {
      borderRadius: '4px',
      width: '100%',
      height: '42px',
      textTransform: 'none',
      borderColor: '#dfe1e5'
    },
    loadMoreText: {
      textTransform: 'none',
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    loadMoreCaret: {
      fill: '#70757a'
    }
  }))
}
