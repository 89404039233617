import { Reducer } from 'redux'
import {
  IFetchSearchTop10Success,
  SearchTop10Actions,
  SearchTop10ActionTypes
} from './actions'

export interface ISearchTop10 {
  searchQuery: string
  count: number
  function: boolean
  country: boolean
}

export interface ISearchTop10Store {
  searchTop10: Array<ISearchTop10>
  hasSearchTop10BeenFetched: boolean
  hasError: boolean
}

const initialState: ISearchTop10Store = {
  searchTop10: new Array<ISearchTop10>(),
  hasSearchTop10BeenFetched: false,
  hasError: false
}

const reducers: Reducer<ISearchTop10Store, SearchTop10Actions> = (
  state: ISearchTop10Store = initialState,
  action: SearchTop10Actions
) => {
  switch (action.type) {
    case SearchTop10ActionTypes.FETCH_SEARCHTOP10_REQUEST:
      return {
        ...state,
        searchTop10: new Array<ISearchTop10>(),
        hasSearchTop10BeenFetched: false,
        hasError: false
      }
    case SearchTop10ActionTypes.FETCH_SEARCHTOP10_FAILURE:
      return {
        ...state,
        hasSearchTop10BeenFetched: true,
        hasError: true,
        searchTop10: new Array<ISearchTop10>()
      }
    case SearchTop10ActionTypes.FETCH_SEARCHTOP10_SUCCESS:
      const { response } = (action as IFetchSearchTop10Success).payload
      return {
        ...state,
        hasSearchTop10BeenFetched: true,
        searchTop10: response,
        hasError: false
      }
    default:
      return state
  }
}

export default reducers
