import React, { Dispatch, useEffect, useState } from 'react'
import { Box, Link } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import SearchStore from 'store/Search'
import { connect } from 'react-redux'
import { Store } from 'store'
import { getStylesSpellCheck } from 'styles/contents/common/SpellCheck'
import {
  areDatasourceFilterSet,
  getCountryNameFromKey,
  getUrlParameterForCurrentFilters,
  isValidPrefilterCountry,
  useFilter
} from 'utils/filters'
import { getCurrentScope, getFilterOptions } from 'utils/queryParams'
import FilterStore from 'store/Filters'
import queryString from 'query-string'
import { useReactRouterQueryStringInterface } from 'utils/useQueryState'
import UserSettingsStore from 'store/UserSettings'
import { dataSourcesTabs } from 'constants/constants'
import SettingsStore from 'store/Settings'
import { IDataSource } from 'constants/datasourcesConfiguration'
import ResultsPeopleStore from 'store/ResultsPeople'
import { Link as RouterLink } from 'react-router-dom'
import { displayNerSuggesion } from 'utils/searchSuggestions'

interface SearchSuggestionsProps {
  wildCardActive: boolean
  resultCount?: number
  resultsFetchend?: boolean
}

type AllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  SearchSuggestionsProps

function SearchSuggestions(props: AllProps) {
  const {
    spellCorrected,
    wildCardActive,
    resultCount,
    resultsFetchend,
    defaultFilterOptions,
    setCurrentFilter,
    userSettings,
    temporaryFilters,
    nerEntityData,
    currentDataSources,
    peopleHasResultsBeenFetched,
    peopleResults,
    useCognitiveSearch,
    currentFilters
  } = props
  const classes = getStylesSpellCheck()

  const [searchQuery, setSearchQueryCurrent] = useFilter('q', '')
  const [ptkr] = useFilter('ptkr', '')
  const [published] = useFilter('published', '')
  const prefilterPagesEnabled = ['intranet', 'videos', 'kpmg mpp']
  const [, setCpfValue] = useFilter('country', '')
  const [, setCurrentPageFromUrl] = useFilter('page', '1')
  const scope = getCurrentScope(false)
  const filters = getFilterOptions(scope, defaultFilterOptions, ptkr)
  const { getQueryString } = useReactRouterQueryStringInterface()
  const queryParams = queryString.parse(getQueryString())
  const [showPeopleRedirectSuggestion, setShowPeopleRedirectSuggestion] =
    useState<boolean>(false)
  const currentDs = getCurrentScope(false)

  useEffect(() => {
    const displayNerSuggestions = displayNerSuggesion(
      useCognitiveSearch,
      currentDs,
      currentDataSources,
      peopleHasResultsBeenFetched,
      peopleResults,
      spellCorrected,
      searchQuery,
      nerEntityData
    )
    setShowPeopleRedirectSuggestion(displayNerSuggestions)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    useCognitiveSearch,
    currentDs,
    currentDataSources,
    peopleHasResultsBeenFetched,
    peopleResults,
    spellCorrected,
    searchQuery,
    nerEntityData
  ])

  // Triggers the new search
  const setNewSearchQuery = (newSearchQuery: string) => {
    setSearchQueryCurrent(newSearchQuery)
  }

  const clearAllDatasourceFilter = () => {
    let changed = false
    const deleteFilter: CurrentFilter[] = []

    for (const filter of filters) {
      if (filter.key !== 'premium') {
        deleteFilter.push({ key: filter.key, value: '' })
        changed = true
      }
    }

    // Delete published filter
    if (published) {
      deleteFilter.push({ key: 'published', value: '' })
      changed = true
    }

    if (changed) {
      setCurrentFilter(deleteFilter)
    }
  }

  const getCountryPreFilterFromUrl = () => {
    let country = ''

    Object.keys(queryParams).forEach((key) => {
      if (key && 'kpmgdatacountryoforigin'.includes(key) && queryParams[key]) {
        country = queryParams[key] as string
      }
    })

    return getCountryNameFromKey(country)
  }

  const setCurrentPage = (page = 0) => {
    setCurrentPageFromUrl(page.toString(), { method: 'push' })
  }

  const intl = useIntl()
  const countryFromUrl = getCountryPreFilterFromUrl()
  const validPreFilterDsAndCountry =
    scope && scope === dataSourcesTabs.oneintranet
      ? isValidPrefilterCountry(countryFromUrl, true)
      : isValidPrefilterCountry(countryFromUrl)

  const validPrefilterCountry =
    userSettings.Country === countryFromUrl &&
    validPreFilterDsAndCountry &&
    !temporaryFilters.find(
      (tF: CurrentFilter) => tF.key === 'country' && tF.value === 'none'
    )

  const noResultsText =
    userSettings.EnablePrefiltering &&
    validPrefilterCountry &&
    window &&
    prefilterPagesEnabled.includes(scope) ? (
      <div className={classes.result}>
        <FormattedMessage
          id="no_result_reset_filter_text"
          defaultMessage="No Results for"
        />{' '}
        {intl.formatMessage({
          id: countryFromUrl.toLowerCase().replace(/\s/g, '_'),
          defaultMessage: countryFromUrl
        })}{' '}
        (
        <div
          tabIndex={0}
          onClick={() => {
            setCpfValue('')
            setCurrentPage(1)
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              setCpfValue('')
              setCurrentPage(1)
            }
          }}
          className={classes.resetButton}
        >
          <FormattedMessage
            id="reset_filter_button"
            defaultMessage="show all results"
          />
        </div>
        )
      </div>
    ) : null

  const refineSearch = () => {
    // are filter set for current datasource
    let dataSourceFiltersSet = false
    const parsedQueryString = queryString.parse(getQueryString()) || {}

    if (filters)
      dataSourceFiltersSet = areDatasourceFilterSet(
        parsedQueryString as { [key: string]: string },
        filters,
        userSettings.EnablePrefiltering,
        ['premium']
      )

    if (noResultsText) {
      return (
        <Box className={classes.spellCheckContainer}>
          <Box
            className={[
              classes.correctedSearchTermContainer,
              classes.noResultMessageContainer
            ].join(' ')}
          >
            {noResultsText}
          </Box>
        </Box>
      )
    }

    if (dataSourceFiltersSet) {
      //If filters are applied try the search without filters
      return (
        <Box className={classes.spellCheckContainer}>
          <Box className={classes.correctedSearchTermContainer}>
            <Link
              tabIndex={0}
              className={classes.wildCardSearchTerm}
              color="inherit"
              onClick={() => {
                clearAllDatasourceFilter()
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  clearAllDatasourceFilter()
                }
              }}
            >
              <FormattedMessage
                id="wildcard_filtered_results_for"
                defaultMessage="Unhappy with search results? Try searching without a filter."
              />
            </Link>
          </Box>
        </Box>
      )
    }

    //If no filters are set, try the search with wildcard
    if (wildCardActive && !searchQuery.endsWith('*')) {
      return (
        <Box className={classes.spellCheckContainer}>
          <Box className={classes.correctedSearchTermContainer}>
            <Link
              tabIndex={0}
              className={classes.wildCardSearchTerm}
              color="inherit"
              onClick={() => {
                setNewSearchQuery(`${searchQuery}*`)
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  setNewSearchQuery(`${searchQuery}*`)
                }
              }}
            >
              <FormattedMessage
                id="wildcard_results_for"
                defaultMessage="Unhappy with search results? Broaden the scope with *"
              />
            </Link>
          </Box>
        </Box>
      )
    }

    //If no filters are set, show no result message
    return (
      <Box className={classes.spellCheckContainer}>
        <Box
          className={[
            classes.correctedSearchTermContainer,
            classes.noResultMessageContainer
          ].join(' ')}
        >
          <FormattedMessage
            id="no_results"
            defaultMessage="No results. Please refine your search query."
          />
        </Box>
      </Box>
    )
  }

  const showPeopleRedirectSuggestionLink = () => {
    const message = intl
      .formatMessage({
        id: 'ner_search_people_instead',
        defaultMessage: 'Did you want to search in {peopleLink} instead'
      })
      .split('{peopleLink}')
    const linkText = 'People'

    const peopleDs = currentDataSources.find(
      (currentDataSources: IDataSource) =>
        currentDataSources.name.toLowerCase() === 'people'
    )

    let link = null
    if (peopleDs)
      link = `${peopleDs.path}${getUrlParameterForCurrentFilters(
        currentFilters,
        [{ key: 'page', value: '1' }]
      )}`

    if (!link) return null

    return (
      <Box className={classes.spellCheckContainer}>
        <Box
          className={classes.correctedSearchTermContainer}
          style={{ whiteSpace: 'nowrap' }}
        >
          <div>{message[0]}</div>
          <div className={classes.linkContainer} style={{ paddingLeft: 0 }}>
            <RouterLink
              className={classes.correctedSearchTerm}
              color="inherit"
              to={link}
            >
              {intl.formatMessage({
                id: linkText.toLowerCase().replace(/\s/g, '_'),

                defaultMessage: linkText
              })}
            </RouterLink>
          </div>
          <div>{message.length > 1 ? message[1] : ''}</div>
        </Box>
      </Box>
    )
  }

  return (
    <>
      {spellCorrected && searchQuery !== spellCorrected ? (
        <Box className={classes.spellCheckContainer}>
          <Box
            className={classes.correctedSearchTermContainer}
            style={{ whiteSpace: 'nowrap' }}
          >
            <div>
              <FormattedMessage
                id="spell_check_results_for"
                defaultMessage="Did you mean:"
              />
            </div>
            <div className={classes.linkContainer}>
              <Link
                tabIndex={0}
                className={classes.correctedSearchTerm}
                color="inherit"
                onClick={() => {
                  setNewSearchQuery(spellCorrected)
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    setNewSearchQuery(spellCorrected)
                  }
                }}
              >
                {spellCorrected}
              </Link>
            </div>
          </Box>
        </Box>
      ) : resultCount === 0 && resultsFetchend && searchQuery ? (
        refineSearch()
      ) : showPeopleRedirectSuggestion ? (
        showPeopleRedirectSuggestionLink()
      ) : null}
    </>
  )
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    setCurrentFilter: (currentFilter: CurrentFilter[]) =>
      dispatch(FilterStore.actions.setCurrentFilter(currentFilter))
  }
}
const mapStateToProps = (state: Store) => {
  return {
    defaultFilterOptions: FilterStore.selectors.getDefaultFilterOptions(state),
    spellCorrected: SearchStore.selectors.getSpellCorrectedSearchTerm(state),
    userSettings: UserSettingsStore.selectors.getUserSettings(state),
    temporaryFilters: FilterStore.selectors.getTemporaryFilters(state),
    nerEntityData: SearchStore.selectors.getEntities(state),
    currentDataSources: SettingsStore.selectors.getCurrentDataSources(state),
    peopleResults: ResultsPeopleStore.selectors.getResults(state),
    peopleHasResultsBeenFetched:
      ResultsPeopleStore.selectors.hasResultsBeenFetched(state),
    useCognitiveSearch: SettingsStore.selectors.getUseCognitiveSearch(state),
    currentFilters: FilterStore.selectors.getCurrentFilters(state)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchSuggestions)
