export const cognitiveVerticalPaths: string[] = [
  'results/all',
  'results/videos',
  'results/engagements',
  'results/people',
  'results/news',
  'results/marketresearch',
  'results/kpmg_mpp',
  'results/office365',
  'results/source',
  'results/kpmgus',
  'results/images'
]
