import { Action } from 'redux'

export enum ResultsActionTypes {
  INITIALIZE_RESULTS = 'persist/REHYDRATE',
  STORE_RESULT_HISTORY = 'results/STORE_RESULT_HISTORY',
  REMOVE_RESULT_HISTORY = 'results/REMOVE_RESULT_HISTORY'
}

export interface IStoreHistory extends Action<ResultsActionTypes> {
  payload: {
    resultUrl: string
    content?: any
  }
}

export const storeResultHistory = (
  resultUrl: string,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  content?: any
): IStoreHistory => ({
  type: ResultsActionTypes.STORE_RESULT_HISTORY,
  payload: { resultUrl, content }
})

export const removeResultHistory = (resultUrl: string): IStoreHistory => ({
  type: ResultsActionTypes.STORE_RESULT_HISTORY,
  payload: { resultUrl }
})

export type ResultsActions = IStoreHistory
