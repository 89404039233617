import {
  IDataDataSourceDefinition,
  IDataSourceOrderBaseObject
} from './dataSourcesOrderConfiguration'

export interface IDataSourceOrderCountry extends IDataSourceOrderBaseObject {
  countries: Array<string>
  sources: Array<IDataDataSourceDefinition>
}

export const dataSourcesCountryOrder: Array<IDataSourceOrderCountry> = [
  {
    countries: ['India'],
    sources: [
      { name: 'Intranet' },
      { name: 'KnowledgeExchange' },
      { name: 'People' },
      { name: 'News' },
      { name: 'Source' },
      { name: 'Office365' },
      { name: 'Statistics' },
      { name: 'Videos' },
      { name: 'Research' },
      { name: 'Alex' },
      { name: 'kpmg.com' },
      { name: 'Engagements' },
      { name: 'ContentFinder' },
      { name: 'KPMG MPP' },
      { name: 'MarketResearch' },
      { name: 'IBFD' },
      { name: 'Images' },
      { name: 'Chat' }
    ]
  },
  {
    countries: ['Australia'],
    sources: [
      { name: 'Intranet' },
      { name: 'Engagements' },
      { name: 'People' },
      { name: 'Office365' },
      { name: 'Source' },
      { name: 'News' },
      { name: 'Statistics' },
      { name: 'Videos' },
      { name: 'Research' },
      { name: 'Alex' },
      { name: 'kpmg.com' },
      { name: 'ContentFinder' },
      { name: 'KPMG MPP' },
      { name: 'MarketResearch' },
      { name: 'IBFD' },
      { name: 'Images' },
      { name: 'Chat' }
    ]
  },
  {
    countries: ['Germany'],
    sources: [
      { name: 'Intranet' },
      { name: 'Engagements' },
      { name: 'People' },
      { name: 'Office365' },
      { name: 'Source' },
      { name: 'News' },
      { name: 'Statistics' },
      { name: 'Videos' },
      { name: 'Research' },
      { name: 'Alex' },
      { name: 'kpmg.com' },
      { name: 'Klardenker' },
      { name: 'ContentFinder' },
      { name: 'Eins' },
      { name: 'Projekt Laufwerke' },
      { name: 'KPMG MPP' },
      { name: 'MarketResearch' },
      { name: 'IBFD' },
      { name: 'Images' },
      { name: 'Chat' }
    ]
  },
  {
    countries: ['United States'],
    sources: [
      { name: 'Intranet' },
      { name: 'Engagements' },
      { name: 'People' },
      { name: 'Office365' },
      { name: 'Source' },
      { name: 'News' },
      { name: 'Statistics' },
      { name: 'Videos' },
      { name: 'Research' },
      { name: 'Alex' },
      { name: 'kpmg.com' },
      { name: 'ContentFinder' },
      { name: 'Eins' },
      { name: 'Projekt Laufwerke' },
      { name: 'KPMG MPP' },
      { name: 'MarketResearch' },
      { name: 'IBFD' },
      { name: 'Images' },
      { name: 'Kpmg.us' },
      { name: 'Chat' }
    ]
  },
  {
    countries: ['Omnia'],
    sources: [
      { name: 'Intranet' },
      { name: 'Engagements' },
      { name: 'People' },
      { name: 'Office365' },
      { name: 'Source' },
      { name: 'News' },
      { name: 'Statistics' },
      { name: 'Videos' },
      { name: 'Research' },
      { name: 'Alex' },
      { name: 'kpmg.com' },
      { name: 'Klardenker' },
      { name: 'KnowledgeExchange' },
      { name: 'ContentFinder' },
      { name: 'Eins' },
      { name: 'Projekt Laufwerke' },
      { name: 'KPMG MPP' },
      { name: 'MarketResearch' },
      { name: 'IBFD' },
      { name: 'Images' },
      { name: 'Kpmg.us' },
      { name: 'Chat' }
    ]
  }
]
