import React from 'react'
import * as Config from 'config'
import { ResultRightWidgetGenerator } from './interfaces'
import RightOneDriveWidget from 'components/contents/resultpages/widgets/RightOneDriveWidget'
import { trackException } from 'utils/tracking'
import { HitsResponse, extractResult } from 'utils/o365'
import { getGraphAuthToken, renewAuthorizationToken } from 'utils/token'
import { IDeviceSetting } from 'utils/deviceSettings'
import { IUserSetting } from 'utils/userSettings'
import { IFindConfiguration } from 'store/Settings/reducers'
import { IOfficeRequestBody } from 'store/ResultsOffice365/actions'
import { ISynonymsApplied } from 'components/models/SynonymsApplied'
import { removeStopWordsFromQuery } from 'utils/oneIntranet'
import { FetchWithCache } from 'utils/api'
import { IAADState } from 'store/Auth/reducers'

export const rightOneDriveWidgetGenerator: ResultRightWidgetGenerator = {
  fetch(
    aadInfo: IAADState,
    searchQuery: string,
    deviceSettings: IDeviceSetting,
    userSettings: IUserSetting,
    findConfiguration: IFindConfiguration,
    useCognitiveSearch: boolean
  ): Promise<{
    queryResults: HitsResponse<any>[]
    synonymsApplied: ISynonymsApplied[]
  }> {
    return new Promise<{
      queryResults: HitsResponse<any>[]
      synonymsApplied: ISynonymsApplied[]
    }>((resolve, reject) => {
      const fetchfiles = async () => {
        try {
          if (!searchQuery || searchQuery === '' || searchQuery === '""') {
            trackException(
              'Error in fetching One Drive right widget in rightOneDriveWidget.tsx',
              new Error('Empty query. Please specify a valid query text')
            )
            reject()
            return
          }

          let graphToken = ''
          if (Config.LOCAL_DEVELOPMENT.toString() === 'false') {
            graphToken = await getGraphAuthToken(
              aadInfo.instance,
              aadInfo.accounts
            )
          }
          // Get and check authentication token
          const esToken = await renewAuthorizationToken(
            aadInfo.accessToken,
            aadInfo.instance,
            aadInfo.accounts
          )

          const requestBody: IOfficeRequestBody = {
            query: `${searchQuery} path:{oneDriveUrl}`,
            from: 0,
            size: 20,
            processFeaturedResults: false,
            featuredResultsOrigin: 'KPMGFind',
            featuredResultsRowLimit: '0',
            country: userSettings.Country,
            function: userSettings.Function,
            city: userSettings.City
          }

          if (
            useCognitiveSearch &&
            findConfiguration.CognitiveSearchEnabled &&
            esToken !== ''
          ) {
            let searchQueryLanguage = ''
            const detectLanguageApiUrl = `${
              Config.APIM_BASE_URL
            }searchapi/detectlanguage?searchQuery=${encodeURIComponent(
              searchQuery
            )}`

            const languageResponse = await FetchWithCache(
              detectLanguageApiUrl,
              {
                method: 'POST',
                headers: {
                  accept: 'application/json',
                  'content-type': 'application/json',
                  'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
                  Authorization: `Bearer ${esToken}`
                }
              }
            )
            if (
              languageResponse &&
              !languageResponse.hasError &&
              languageResponse.responseJSON
            ) {
              const languageJSON = languageResponse.responseJSON
              if (languageJSON && languageJSON.responseCode === 200) {
                searchQueryLanguage = languageJSON.language
              }
            }
            requestBody.searchQuery = removeStopWordsFromQuery(
              searchQuery,
              searchQueryLanguage,
              useCognitiveSearch,
              findConfiguration.CognitiveSearchEnabled
            )
            requestBody.cognitiveEnabled = true
          }

          const graphAPIUrl = `${Config.APIM_BASE_URL}msgraphapi/postdriveitems`

          if (graphToken !== null) {
            const response = await fetch(graphAPIUrl, {
              method: 'POST',
              headers: {
                accept: 'application/json',
                'content-type': 'application/json',
                'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
                Authorization: `Bearer ${graphToken}`,
                JWTToken: esToken
              },
              body: JSON.stringify(requestBody)
            })

            if (!response || !response.ok) {
              reject()
              return
            } else {
              const results = await response.json()
              const extractedResult = extractResult(results)
              if (
                extractedResult &&
                extractedResult.hits &&
                extractedResult.hits.length > 2
              ) {
                resolve({
                  queryResults: extractedResult.hits,
                  synonymsApplied: results.SynonymsApplied
                })
                return
              }
            }
          }
        } catch (error) {
          trackException(
            'Error in generating OneDrive right widget in rightOneDriveWidget.tsx',
            error
          )
        }
        reject()
        return
      }

      try {
        fetchfiles()
      } catch (error) {
        trackException(
          'Error in fetching OneDrive right widget in rightOneDriveWidget.tsx',
          error
        )
        reject()
      }
    })
  },
  generate(
    resultsRaw: {
      queryResults: HitsResponse<any>[]
      synonymsApplied: ISynonymsApplied[]
    },
    searchQuery: string
  ): Promise<React.ReactElement> {
    return new Promise<React.ReactElement>((resolve, reject) => {
      const generateOneDriveWidget = () => {
        if (!resultsRaw.queryResults) {
          reject()
          return
        }

        if (resultsRaw.queryResults.length < 3) {
          reject()
          return
        }

        resolve(
          <RightOneDriveWidget
            searchQuery={searchQuery}
            results={resultsRaw.queryResults}
            synonymsApplied={resultsRaw.synonymsApplied}
          />
        )
      }

      try {
        generateOneDriveWidget()
      } catch (error) {
        trackException(
          'Error in generating OneDrive right widget in rightOneDriveWidget.tsx',
          error
        )
        reject()
      }
    })
  }
}
