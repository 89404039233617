import { FeaturedResult } from 'components/models/FeaturedResult'
import { Store } from '..'
import { IKlardenkerResult } from 'components/models/KlardenkerResult'

const selectors = {
  getFeaturedResults: (state: Store): FeaturedResult[] =>
    state.resultsKlardenker.featuredResults,
  getResults: (state: Store): IKlardenkerResult[] =>
    state.resultsKlardenker.results,
  getResultsCombined: (state: Store): IKlardenkerResult[] =>
    state.resultsKlardenker.resultsCombined,
  getResultsCombinedQuery: (state: Store): string =>
    state.resultsKlardenker.resultsCombinedQuery,
  hasResultsBeenFetched: (state: Store): boolean =>
    state.resultsKlardenker.hasResultsBeenFetched,
  getResultCount: (state: Store): number => state.resultsKlardenker.resultCount,
  getExecutionTime: (state: Store): number =>
    state.resultsKlardenker.executionTime,
  getCurrentPage: (state: Store): number => state.resultsKlardenker.currentPage,
  getHasError: (state: Store): boolean => state.resultsKlardenker.hasError,
  getLatestQuery: (state: Store): string =>
    state.resultsKlardenker.resultsCombinedQuery
}

export default selectors
