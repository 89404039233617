import { FeaturedResult } from 'components/models/FeaturedResult'
import { Store } from '..'
import { IResultImages } from './reducers'

const selectors = {
  getFeaturedResults: (state: Store): FeaturedResult[] =>
    state.resultsImages.featuredResults,
  getResults: (state: Store): IResultImages => state.resultsImages.results,
  getResultsCombined: (state: Store): IResultImages =>
    state.resultsImages.resultsCombined,
  getResultsCombinedQuery: (state: Store): string =>
    state.resultsImages.resultsCombinedQuery,
  hasResultsBeenFetched: (state: Store): boolean =>
    state.resultsImages.hasResultsBeenFetched,
  getTotalRowCount: (state: Store): number => state.resultsImages.totalRowCount,
  getExecutionTime: (state: Store): number => state.resultsImages.executionTime,
  getLastRow: (state: Store): number => state.resultsImages.lastRow,
  getHasError: (state: Store): boolean => state.resultsImages.hasError
}

export default selectors
