import React, { useState } from 'react'
import {
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  IconButton,
  TextField,
  Typography
} from '@mui/material'
import SentimentSatisfiedAlt from '@mui/icons-material/SentimentSatisfiedAlt'
import SentimentVeryDissatisfied from '@mui/icons-material/SentimentVeryDissatisfied'
import { FormattedMessage, useIntl } from 'react-intl'
import { trackEvents } from 'utils/tracking'
import { getStylesFeedbackShort } from 'styles/contents/FeedbackShort'
import SettingsStore from 'store/Settings'
import { connect } from 'react-redux'
import { Store } from 'store'
import UserSettingsStore from 'store/UserSettings'
import { KPMGFindGlobalVariables } from 'store/KPMGFindGlobalVariables'

export interface FeedbackProps {
  open: boolean
  onClose: () => void
  onSend: () => void
  onDelay: () => void
}

type AllProps = ReturnType<typeof mapStateToProps> & FeedbackProps

function FeedbackShort(props: AllProps): JSX.Element {
  const classes = getStylesFeedbackShort()
  const intl = useIntl()
  const {
    open,
    onClose,
    onSend,
    onDelay,
    deviceSettings,
    findConfiguration,
    userSettings
  } = props
  const [rating, setRating] = useState('none')
  const [comment, setComment] = useState('')

  const trackFeedback = () => {
    trackEvents('feedback', {
      user_email: KPMGFindGlobalVariables.getUserName(),
      type: 'quick',
      rating: rating,
      stars: '',
      missing_sources: '',
      missing_feature: '',
      other_comment: comment
    })
  }
  const winWidth = window.innerWidth || document.documentElement.clientWidth
  return (
    <Dialog open={open} className={classes.dialogContainer}>
      <DialogTitle className={classes.feedbackDialogTitle}>
        <Typography variant="h5">
          <FormattedMessage id="feedback_title" defaultMessage="Feedback" />
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Box>
          <Box className={classes.dialogDescription}>
            <Box className={classes.dialogDescriptionText}>
              <FormattedMessage
                id="thank_using_kpmg_find"
                defaultMessage="Thank you for using KPMG Find for 2 weeks."
              />
            </Box>
            <Box className={classes.dialogDescriptionText}>
              <FormattedMessage
                id="let_us_know"
                defaultMessage="Let us know if you like it so far."
              />
            </Box>
          </Box>
          <Box className={classes.thumbsContainer}>
            <Box style={{ marginRight: 45 }}>
              <IconButton
                color="secondary"
                className={
                  rating === 'positive'
                    ? `${classes.thumbButtonClicked} ${classes.thumbButton}`
                    : classes.thumbButton
                }
                onClick={() => {
                  setRating('positive')
                }}
              >
                <SentimentSatisfiedAlt
                  className={classes.thumbIcon}
                  style={{ color: '#009A44' }}
                />
              </IconButton>
            </Box>
            <Box>
              <IconButton
                color="secondary"
                className={
                  rating === 'negative'
                    ? `${classes.thumbButtonClicked} ${classes.thumbButton}`
                    : classes.thumbButton
                }
                onClick={() => {
                  setRating('negative')
                }}
              >
                <SentimentVeryDissatisfied
                  className={classes.thumbIcon}
                  style={{ color: '#BC204B' }}
                />
              </IconButton>
            </Box>
          </Box>
          {rating === 'negative' && (
            <TextField
              id="standard-helperText"
              label={intl.formatMessage({
                id: 'how_to_improve',
                defaultMessage: 'How could we improve?'
              })}
              margin="normal"
              inputProps={
                deviceSettings.renderMobile &&
                intl.formatMessage({
                  id: 'how_to_improve',
                  defaultMessage: 'How could we improve?'
                }).length > 34 &&
                winWidth < 410
                  ? { style: { marginTop: 16 } }
                  : {}
              }
              className={classes.textField}
              value={comment}
              onChange={(event) => setComment(event.target.value)}
            />
          )}
        </Box>
      </DialogContent>
      <DialogActions className={classes.feedbackActions}>
        <Box className={classes.dialogSubmitText}>
          <FormattedMessage
            id="by_sending_feedback_short"
            values={{
              linebreak: ''
            }}
            defaultMessage="By sending feedback you agree that we log {linebreak}your email in case we need to reach out."
          />
        </Box>
        <Box className={classes.dialogActions}>
          <Button
            onClick={() => {
              trackFeedback()
              onSend()
              onClose()
            }}
            color="primary"
            variant="contained"
            autoFocus
            disableRipple
            disabled={rating === 'none'}
          >
            <FormattedMessage id="send_feedback" defaultMessage="Send" />
          </Button>
          {findConfiguration.FeedbackMaxDelays &&
            userSettings.FeedbackShortDelayed <
              findConfiguration.FeedbackMaxDelays && (
              <Button
                onClick={() => {
                  onDelay()
                  onClose()
                }}
                color="secondary"
                variant="contained"
                autoFocus
                disableRipple
              >
                <FormattedMessage
                  id="send_feedback_ask_later"
                  defaultMessage="Ask me later"
                />
              </Button>
            )}
        </Box>
      </DialogActions>
    </Dialog>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state),
    findConfiguration: SettingsStore.selectors.getFindConfiguration(state),
    userSettings: UserSettingsStore.selectors.getUserSettings(state)
  }
}

export default connect(mapStateToProps)(FeedbackShort)
