import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesIntroduction(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles() {
  return makeStyles((theme: Theme) => ({
    container: {
      margin: 0,
      padding: 0,
      left: 0,
      position: 'absolute',
      zIndex: 10000,
      top: 0,
      height: '100%',
      width: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      'slick-arrow': {
        display: 'none'
      }
    },
    invisible: {
      display: 'none'
    },
    slider: {
      position: 'absolute',
      background: '#f6f7fA',
      width: '500px',
      zIndex: 5,
      margin: ' auto',
      height: 600,
      top: '50%',
      left: '50%',
      borderRadius: 4,
      transform: 'translate(-50%, -50%)',
      display: 'flex',
      flexDirection: 'column',
      boxShadow:
        '0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)',
      [`${theme.breakpoints.down(769)} and (orientation: portrait)`]: {
        width: '90%',
        '& .slick-prev': {
          width: '48px',
          zIndex: 10001,
          marginLeft: '2em',
          height: 'auto',
          left: 0
        },
        '& .slick-next': {
          width: '48px',
          zIndex: 10001,
          marginRight: '2em',
          height: 'auto',
          right: 0
        }
      }
    },
    content: {
      paddingLeft: 50,
      paddingRight: 50
    },
    slideContainer: {
      textAlign: 'center',
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'space-evenly'
    },
    img: {
      margin: 'auto',
      width: '100%',
      marginBottom: 25,
      borderRadius: '4px 4px 0px 0px',
      flex: '0 0 300px'
    },
    closeContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end'
    },
    closeButton: {
      position: 'absolute',
      zIndex: 100,
      color: '#424242',
      right: '8px',
      top: '8px',
      background: 'rgba(255, 255, 255, 0.2)',
      '&:hover': {
        background: 'rgba(255, 255, 255, 0.5)'
      }
    },
    spacer: {
      flex: 1
    },
    title: {
      color: '#424242',
      marginBottom: '25px'
    },
    description: {
      color: '#424242',
      fontSize: '14px',
      textAlign: 'left'
    },
    getStartedButton: {
      marginTop: 25,
      [`${theme.breakpoints.down(1024)}`]: {
        background: '#005eb8'
      }
    }
  }))
}
