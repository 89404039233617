import { Reducer, combineReducers } from 'redux'
import {
  ResultsOffice365Actions,
  ResultsOffice365ActionTypes,
  IFetchSuccess,
  IFetchRequest,
  IFetchSuccessCombined
} from './actions'
import {
  FileSourceResponse,
  HitsResponse,
  CalendarSourceResponse,
  MailSourceResponse,
  SitesSourceResponse,
  ChatMessageSourceResponse
} from 'utils/o365'
import { FeaturedResult } from 'components/models/FeaturedResult'
import { ISynonymsApplied } from 'components/models/SynonymsApplied'

export interface IResultsOffice365Type<T> {
  hasError: boolean
  hasResultsBeenFetched: boolean
  results: HitsResponse<T>[]
  resultsCombined: HitsResponse<T>[]
  resultsCombinedQuery: string
  resultCount: number
  executionTime: number
  currentPage: number
  moreResultsAvailable: boolean
  currentResultCount: number
  lastPageReached: boolean
  featuredResults: FeaturedResult[]
}

export interface IExchangeLicense {
  isAvailable: boolean
  hasBeenChecked: boolean
}

export interface IExtendedResults {
  synonymsApplied: ISynonymsApplied[]
  hasResultsBeenFetched: boolean
}

export interface IResultsOffice365Store {
  files: IResultsOffice365Type<FileSourceResponse>
  mails: IResultsOffice365Type<MailSourceResponse>
  calendar: IResultsOffice365Type<CalendarSourceResponse>
  sites: IResultsOffice365Type<SitesSourceResponse>
  teams: IResultsOffice365Type<ChatMessageSourceResponse>
  licenseAvailable: IExchangeLicense
  extendedResults: IExtendedResults
}

const fileInitialState: IResultsOffice365Type<FileSourceResponse> = {
  hasError: false,
  hasResultsBeenFetched: false,
  resultCount: -1,
  results: [],
  resultsCombined: [],
  resultsCombinedQuery: '',
  currentPage: 1,
  executionTime: 0,
  moreResultsAvailable: false,
  currentResultCount: 0,
  lastPageReached: false,
  featuredResults: []
}

const fileReducers: Reducer<
  IResultsOffice365Type<FileSourceResponse>,
  ResultsOffice365Actions
> = (
  state: IResultsOffice365Type<FileSourceResponse> = fileInitialState,
  action: { type: 'SET_CURRENT_PAGE'; payload: any } | ResultsOffice365Actions
) => {
  switch (action.type) {
    case 'SET_CURRENT_PAGE':
      return {
        ...state,
        currentPage: action.payload || 0
      }
    case ResultsOffice365ActionTypes.INITIALIZE_RESULTS_OFFICE_365:
      if (!(action as any).payload) {
        return fileInitialState
      }
      const { resultsOffice365 } = (action as any).payload
      return {
        ...state,
        currentPage: resultsOffice365.files.currentPage || 1
      }
    case ResultsOffice365ActionTypes.FETCH_REQUEST:
      if (action.meta.type === 'files') {
        const { resetCombined, searchQuery } = (action as any).payload
        return {
          ...state,
          hasError: false,
          hasResultsBeenFetched: false,
          executionTime: 0,
          resultCount: -1,
          results: [],
          resultsCombined: resetCombined ? [] : state.resultsCombined,
          resultsCombinedQuery: searchQuery,
          featuredResults: resetCombined ? [] : state.featuredResults
        }
      }
      return state
    case ResultsOffice365ActionTypes.FETCH_FAILURE:
      if (action.meta.type === 'files') {
        return {
          ...state,
          hasError: true,
          hasResultsBeenFetched: true
        }
      }
      return state
    case ResultsOffice365ActionTypes.FETCH_SUCCESS:
      const { response, featuredResults } = (action as IFetchSuccess).payload
      if (action.meta.type === 'files') {
        return {
          ...state,
          hasError: false,
          hasResultsBeenFetched: true,
          executionTime: response.executionTime,
          resultCount: response.resultCount,
          results: response.results,
          currentPage: response.currentPage,
          featuredResults:
            response.currentPage === 1 ? featuredResults : state.featuredResults
        }
      }
      return state
    case ResultsOffice365ActionTypes.FETCH_SUCCESS_COMBINED:
      const { response: responseCombined } = (action as IFetchSuccessCombined)
        .payload
      if (action.meta.type === 'files') {
        return {
          ...state,
          hasError: false,
          resultsCombined: responseCombined.results
        }
      }
      return state
    default:
      return state
  }
}

const mailInitialState: IResultsOffice365Type<MailSourceResponse> = {
  hasError: false,
  hasResultsBeenFetched: false,
  resultCount: -1,
  results: [],
  resultsCombined: [],
  resultsCombinedQuery: '',
  currentPage: 1,
  executionTime: 0,
  moreResultsAvailable: false,
  currentResultCount: 0,
  lastPageReached: false,
  featuredResults: []
}

const mailReducers: Reducer<
  IResultsOffice365Type<MailSourceResponse>,
  ResultsOffice365Actions
> = (
  state: IResultsOffice365Type<MailSourceResponse> = mailInitialState,
  action: { type: 'SET_CURRENT_PAGE'; payload: any } | ResultsOffice365Actions
) => {
  switch (action.type) {
    case 'SET_CURRENT_PAGE':
      return {
        ...state,
        currentPage: action.payload || 0
      }
    case ResultsOffice365ActionTypes.INITIALIZE_RESULTS_OFFICE_365:
      if (!(action as any).payload) {
        return mailInitialState
      }
      const { resultsOffice365 } = (action as any).payload
      return {
        ...state,
        currentPage: resultsOffice365.mails.currentPage || 1
      }
    case ResultsOffice365ActionTypes.FETCH_REQUEST:
      const { fetchResponse, resetCombined, searchQuery } = (
        action as IFetchRequest
      ).payload
      if (action.meta.type === 'mails') {
        return {
          ...state,
          hasError: false,
          hasResultsBeenFetched: false,
          executionTime: 0,
          resultCount: -1,
          results: [],
          resultsCombined: resetCombined ? [] : state.resultsCombined,
          resultsCombinedQuery: searchQuery,
          moreResultsAvailable: false,
          currentResultCount:
            fetchResponse.currentPage === 1 ? 0 : state.currentResultCount,
          lastPageReached: false,
          featuredResults: resetCombined ? [] : state.featuredResults
        }
      }
      return state
    case ResultsOffice365ActionTypes.FETCH_FAILURE:
      if (action.meta.type === 'mails') {
        return {
          ...state,
          hasError: true,
          hasResultsBeenFetched: false
        }
      }
      return state
    case ResultsOffice365ActionTypes.FETCH_SUCCESS:
      const { response, featuredResults } = (action as IFetchSuccess).payload
      if (action.meta.type === 'mails') {
        let currentResultCount = state.currentResultCount

        if (response.resultCount === 0) {
          currentResultCount = 0
        } else {
          currentResultCount =
            response.resultCount + 20 * (response.currentPage - 1)

          if (currentResultCount < state.currentResultCount) {
            currentResultCount = state.currentResultCount
          }
        }

        return {
          ...state,
          hasError: false,
          hasResultsBeenFetched: true,
          executionTime: response.executionTime,
          resultCount:
            response.currentPage === 1
              ? response.resultCount
              : currentResultCount,
          results: response.results,
          currentPage: response.currentPage,
          moreResultsAvailable: response.moreResultsAvailable,
          currentResultCount:
            response.currentPage === 1
              ? response.resultCount
              : currentResultCount,
          lastPageReached: state.lastPageReached
            ? true
            : !response.moreResultsAvailable,
          featuredResults:
            response.currentPage === 1 ? featuredResults : state.featuredResults
        }
      }
      return state
    case ResultsOffice365ActionTypes.FETCH_SUCCESS_COMBINED:
      const { response: responseCombined } = (action as IFetchSuccessCombined)
        .payload
      if (action.meta.type === 'mails') {
        return {
          ...state,
          hasError: false,
          resultsCombined: responseCombined.results
        }
      }
      return state

    default:
      return state
  }
}

const calendarInitialState: IResultsOffice365Type<CalendarSourceResponse> = {
  hasError: false,
  hasResultsBeenFetched: false,
  resultCount: -1,
  results: [],
  resultsCombined: [],
  resultsCombinedQuery: '',
  currentPage: 1,
  executionTime: 0,
  moreResultsAvailable: false,
  currentResultCount: 0,
  lastPageReached: false,
  featuredResults: []
}

const calendarReducers: Reducer<
  IResultsOffice365Type<CalendarSourceResponse>,
  ResultsOffice365Actions
> = (
  state: IResultsOffice365Type<CalendarSourceResponse> = calendarInitialState,
  action: { type: 'SET_CURRENT_PAGE'; payload: any } | ResultsOffice365Actions
) => {
  switch (action.type) {
    case 'SET_CURRENT_PAGE':
      return {
        ...state,
        currentPage: action.payload || 0
      }
    case ResultsOffice365ActionTypes.INITIALIZE_RESULTS_OFFICE_365:
      if (!(action as any).payload) {
        return calendarInitialState
      }
      const { resultsOffice365 } = (action as any).payload
      return {
        ...state,
        currentPage: resultsOffice365.calendar.currentPage || 1
      }
    case ResultsOffice365ActionTypes.FETCH_REQUEST:
      const { fetchResponse, resetCombined, searchQuery } = (
        action as IFetchRequest
      ).payload
      if (action.meta.type === 'calendar') {
        return {
          ...state,
          hasError: false,
          hasResultsBeenFetched: false,
          executionTime: 0,
          resultCount: -1,
          results: [],
          resultsCombined: resetCombined ? [] : state.resultsCombined,
          resultsCombinedQuery: searchQuery,
          moreResultsAvailable: false,
          currentResultCount:
            fetchResponse.currentPage === 1 ? 0 : state.currentResultCount,
          lastPageReached: false,
          featuredResults: resetCombined ? [] : state.featuredResults
        }
      }
      return state
    case ResultsOffice365ActionTypes.FETCH_FAILURE:
      if (action.meta.type === 'calendar') {
        return {
          ...state,
          hasError: false,
          hasResultsBeenFetched: false
        }
      }
      return state
    case ResultsOffice365ActionTypes.FETCH_SUCCESS:
      const { response, featuredResults } = (action as IFetchSuccess).payload
      if (action.meta.type === 'calendar') {
        let currentResultCount = state.currentResultCount

        if (response.resultCount === 0) {
          currentResultCount = 0
        } else {
          currentResultCount =
            response.resultCount + 20 * (response.currentPage - 1)

          if (currentResultCount < state.currentResultCount) {
            currentResultCount = state.currentResultCount
          }
        }

        return {
          ...state,
          hasError: false,
          hasResultsBeenFetched: true,
          executionTime: response.executionTime,
          resultCount:
            response.currentPage === 1
              ? response.resultCount
              : currentResultCount,
          results: response.results,
          currentPage: response.currentPage,
          moreResultsAvailable: response.moreResultsAvailable,
          currentResultCount:
            response.currentPage === 1
              ? response.resultCount
              : currentResultCount,
          lastPageReached: state.lastPageReached
            ? true
            : !response.moreResultsAvailable,
          featuredResults:
            response.currentPage === 1 ? featuredResults : state.featuredResults
        }
      }
      return state
    case ResultsOffice365ActionTypes.FETCH_SUCCESS_COMBINED:
      const { response: responseCombined } = (action as IFetchSuccess).payload
      if (action.meta.type === 'calendar') {
        return {
          ...state,
          resultsCombined: responseCombined.results
        }
      }
      return state
    default:
      return state
  }
}

const siteInitialState: IResultsOffice365Type<SitesSourceResponse> = {
  hasError: false,
  hasResultsBeenFetched: false,
  resultCount: -1,
  results: [],
  resultsCombined: [],
  resultsCombinedQuery: '',
  currentPage: 1,
  executionTime: 0,
  moreResultsAvailable: false,
  currentResultCount: 0,
  lastPageReached: false,
  featuredResults: []
}

const siteReducer: Reducer<
  IResultsOffice365Type<SitesSourceResponse>,
  ResultsOffice365Actions
> = (
  state: IResultsOffice365Type<SitesSourceResponse> = siteInitialState,
  action: { type: 'SET_CURRENT_PAGE'; payload: any } | ResultsOffice365Actions
) => {
  switch (action.type) {
    case 'SET_CURRENT_PAGE':
      return {
        ...state,
        currentPage: action.payload || 0
      }
    case ResultsOffice365ActionTypes.INITIALIZE_RESULTS_OFFICE_365:
      if (!(action as any).payload) {
        return siteInitialState
      }
      const { resultsOffice365 } = (action as any).payload
      return {
        ...state,
        currentPage: resultsOffice365.sites.currentPage || 1
      }
    case ResultsOffice365ActionTypes.FETCH_REQUEST:
      if (action.meta.type === 'sites') {
        const { fetchResponse, resetCombined, searchQuery } = (action as any)
          .payload
        return {
          ...state,
          hasError: false,
          hasResultsBeenFetched: false,
          executionTime: 0,
          resultCount: -1,
          results: [],
          resultsCombined: resetCombined ? [] : state.resultsCombined,
          resultsCombinedQuery: searchQuery,
          moreResultsAvailable: false,
          currentResultCount:
            fetchResponse.currentPage === 1 ? 0 : state.currentResultCount,
          lastPageReached: false,
          featuredResults: resetCombined ? [] : state.featuredResults
        }
      }
      return state
    case ResultsOffice365ActionTypes.FETCH_FAILURE:
      if (action.meta.type === 'sites') {
        return {
          ...state,
          hasError: true,
          hasResultsBeenFetched: true
        }
      }
      return state
    case ResultsOffice365ActionTypes.FETCH_SUCCESS:
      const { response, featuredResults } = (action as IFetchSuccess).payload
      if (action.meta.type === 'sites') {
        let currentResultCount = state.currentResultCount

        if (response.resultCount === 0) {
          currentResultCount = 0
        } else {
          currentResultCount =
            response.resultCount + 20 * (response.currentPage - 1)

          if (currentResultCount < state.currentResultCount) {
            currentResultCount = state.currentResultCount
          }
        }

        return {
          ...state,
          hasError: false,
          hasResultsBeenFetched: true,
          executionTime: response.executionTime,
          resultCount:
            response.currentPage === 1
              ? response.resultCount
              : currentResultCount,
          results: response.results,
          currentPage: response.currentPage,
          moreResultsAvailable: response.moreResultsAvailable,
          currentResultCount:
            response.currentPage === 1
              ? response.resultCount
              : currentResultCount,
          lastPageReached: state.lastPageReached
            ? true
            : !response.moreResultsAvailable,
          featuredResults:
            response.currentPage === 1 ? featuredResults : state.featuredResults
        }
      }
      return state
    case ResultsOffice365ActionTypes.FETCH_SUCCESS_COMBINED:
      const { response: responseCombined } = (action as IFetchSuccess).payload
      if (action.meta.type === 'sites') {
        return {
          ...state,
          hasError: false,
          resultsCombined: responseCombined.results
        }
      }
      return state
    default:
      return state
  }
}

const teamsInitialState: IResultsOffice365Type<ChatMessageSourceResponse> = {
  hasError: false,
  hasResultsBeenFetched: false,
  resultCount: -1,
  results: [],
  resultsCombined: [],
  resultsCombinedQuery: '',
  currentPage: 1,
  executionTime: 0,
  moreResultsAvailable: false,
  currentResultCount: 0,
  lastPageReached: false,
  featuredResults: []
}

const teamsReducer: Reducer<
  IResultsOffice365Type<ChatMessageSourceResponse>,
  ResultsOffice365Actions
> = (
  state: IResultsOffice365Type<ChatMessageSourceResponse> = teamsInitialState,
  action: { type: 'SET_CURRENT_PAGE'; payload: any } | ResultsOffice365Actions
) => {
  switch (action.type) {
    case 'SET_CURRENT_PAGE':
      return {
        ...state,
        currentPage: action.payload || 0
      }
    case ResultsOffice365ActionTypes.INITIALIZE_RESULTS_OFFICE_365:
      if (!(action as any).payload) {
        return teamsInitialState
      }
      const { resultsOffice365 } = (action as any).payload
      return {
        ...state,
        currentPage: resultsOffice365.teams.currentPage || 1
      }
    case ResultsOffice365ActionTypes.FETCH_REQUEST:
      if (action.meta.type === 'teams') {
        const { fetchResponse, resetCombined, searchQuery } = (action as any)
          .payload
        return {
          ...state,
          hasError: false,
          hasResultsBeenFetched: false,
          executionTime: 0,
          resultCount: -1,
          results: [],
          resultsCombined: resetCombined ? [] : state.resultsCombined,
          resultsCombinedQuery: searchQuery,
          moreResultsAvailable: false,
          currentResultCount:
            fetchResponse.currentPage === 1 ? 0 : state.currentResultCount,
          lastPageReached: false,
          featuredResults: resetCombined ? [] : state.featuredResults
        }
      }
      return state
    case ResultsOffice365ActionTypes.FETCH_FAILURE:
      if (action.meta.type === 'teams') {
        return {
          ...state,
          hasError: true,
          hasResultsBeenFetched: true
        }
      }
      return state
    case ResultsOffice365ActionTypes.FETCH_SUCCESS:
      const { response, featuredResults } = (action as IFetchSuccess).payload
      if (action.meta.type === 'teams') {
        let currentResultCount = state.currentResultCount

        if (response.resultCount === 0) {
          currentResultCount = 0
        } else {
          currentResultCount =
            response.resultCount + 20 * (response.currentPage - 1)

          if (currentResultCount < state.currentResultCount) {
            currentResultCount = state.currentResultCount
          }
        }

        return {
          ...state,
          hasError: false,
          hasResultsBeenFetched: true,
          executionTime: response.executionTime,
          resultCount:
            response.currentPage === 1
              ? response.resultCount
              : currentResultCount,
          results: response.results,
          currentPage: response.currentPage,
          moreResultsAvailable: response.moreResultsAvailable,
          currentResultCount:
            response.currentPage === 1
              ? response.resultCount
              : currentResultCount,
          lastPageReached: state.lastPageReached
            ? true
            : !response.moreResultsAvailable,
          featuredResults:
            response.currentPage === 1 ? featuredResults : state.featuredResults
        }
      }
      return state
    case ResultsOffice365ActionTypes.FETCH_SUCCESS_COMBINED:
      const { response: responseCombined } = (action as IFetchSuccess).payload
      if (action.meta.type === 'teams') {
        return {
          ...state,
          hasError: false,
          resultsCombined: responseCombined.results
        }
      }
      return state
    default:
      return state
  }
}

const checkLicenseInitialState: IExchangeLicense = {
  isAvailable: true,
  hasBeenChecked: false
}

const checkLicenseReducers: Reducer<
  IExchangeLicense,
  ResultsOffice365Actions
> = (
  state: IExchangeLicense = checkLicenseInitialState,
  action: ResultsOffice365Actions
) => {
  switch (action.type) {
    case ResultsOffice365ActionTypes.FETCH_UNAVAILABLE:
      return {
        isAvailable: false,
        hasBeenChecked: true
      }
    case ResultsOffice365ActionTypes.FETCH_AVAILABLE:
      return {
        isAvailable: true,
        hasBeenChecked: true
      }
    default:
      return state
  }
}

const extendedResultsInitialState: IExtendedResults = {
  synonymsApplied: [],
  hasResultsBeenFetched: false
}

const extendedResultsReducer: Reducer<
  IExtendedResults,
  ResultsOffice365Actions
> = (
  state: IExtendedResults = extendedResultsInitialState,
  action: ResultsOffice365Actions
) => {
  switch (action.type) {
    case ResultsOffice365ActionTypes.FETCH_REQUEST:
      if (action.meta.type === 'files') {
        const { resetCombined } = (action as any).payload
        return {
          ...state,
          hasResultsBeenFetched: false,
          synonymsApplied: resetCombined ? [] : state.synonymsApplied
        }
      }
      return state
    case ResultsOffice365ActionTypes.FETCH_FAILURE:
      if (action.meta.type === 'files') {
        return {
          ...state,
          hasResultsBeenFetched: true,
          synonymsApplied: []
        }
      }
      return state
    case ResultsOffice365ActionTypes.FETCH_SUCCESS:
      if (action.meta.type === 'files') {
        const { response, synonymsApplied } = (action as IFetchSuccess).payload

        return {
          ...state,
          hasResultsBeenFetched: true,
          synonymsApplied:
            response.currentPage === 1 ? synonymsApplied : state.synonymsApplied
        }
      }
      return state
    default:
      return state
  }
}

export default combineReducers({
  files: fileReducers,
  mails: mailReducers,
  calendar: calendarReducers,
  licenseAvailable: checkLicenseReducers,
  sites: siteReducer,
  teams: teamsReducer,
  extendedResults: extendedResultsReducer
})
