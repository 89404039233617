import dayjs from 'dayjs'
import { IntlShape } from 'react-intl'

export function timeSince(date: string, intl: IntlShape) {
  const seconds = dayjs().diff(date, 'seconds')

  let interval = seconds / 31536000

  if (interval > 1) {
    return (
      Math.floor(interval) +
      ' ' +
      intl.formatMessage({
        id: 'timesince_years',
        defaultMessage: 'years'
      })
    )
  }
  interval = seconds / 2592000
  if (interval > 1) {
    return (
      Math.floor(interval) +
      ' ' +
      intl.formatMessage({
        id: 'timesince_months',
        defaultMessage: 'months'
      })
    )
  }
  interval = seconds / 86400
  if (interval > 1) {
    return (
      Math.floor(interval) +
      ' ' +
      intl.formatMessage({
        id: 'timesince_days',
        defaultMessage: 'days'
      })
    )
  }
  interval = seconds / 3600
  if (interval > 1) {
    return (
      Math.floor(interval) +
      ' ' +
      intl.formatMessage({
        id: 'timesince_hours',
        defaultMessage: 'hours'
      })
    )
  }
  interval = seconds / 60
  if (interval > 1) {
    return (
      Math.floor(interval) +
      ' ' +
      intl.formatMessage({
        id: 'timesince_minutes',
        defaultMessage: 'minutes'
      })
    )
  }
  return (
    Math.floor(seconds) +
    ' ' +
    intl.formatMessage({
      id: 'timesince_seconds',
      defaultMessage: 'seconds'
    })
  )
}
