import React, { useEffect } from 'react'
import {
  Box,
  Typography,
  ListItemIcon,
  Grid,
  Collapse,
  IconButton,
  Tooltip,
  Link
} from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import { Store } from 'store'
import { connect } from 'react-redux'
import ErrorOutline from '@mui/icons-material/ErrorOutline'
import { getStylesErrorPage } from 'styles/contents/common/ErrorPage'
import ResultMetaDataStore from 'store/ResultMetaData'
import { getStylesResults } from 'styles/contents/resultpages/Results'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { enterpriseSearchEmail } from 'constants/constants'
import SettingsStore from 'store/Settings'
import {
  getAppGtwErrorMailAddress,
  getCookie,
  getTranslatedDatesource,
  isAppGatewayError,
  isInvalidQueryError
} from 'utils/errors'
import { useFilter } from 'utils/filters'

export type IErrorType = 'InvalidQuery' | 'AppGtwError' | 'unavailable'

interface IErrorContent {
  type: 'error' | 'warning' | 'info' // red | yellow | gray
  showDetails?: boolean // show extended details dropdown
  title?: JSX.Element
  header?: JSX.Element
  content?: JSX.Element
  footer?: JSX.Element
}
export interface ErrorPageProps {
  dataSource: string
  origin?: string
  customMessage?: IErrorContent
  forcedType?: IErrorType
  isContainerWrapped?: boolean
}

type AllErrorPageProps = ReturnType<typeof mapStateToProps> & ErrorPageProps

function ErrorPage(props: AllErrorPageProps) {
  const {
    resultMetaData,
    deviceSettings,
    dataSource,
    origin,
    forcedType,
    customMessage,
    isContainerWrapped
  } = props

  const resultClasses = getStylesResults()
  const classes = getStylesErrorPage()
  const intl = useIntl()
  const [searchQuery] = useFilter('q')

  const [expandedDetails, setExpandedDetails] = React.useState(false)
  const [content, setContent] = React.useState<IErrorContent | undefined>(
    customMessage ? customMessage : undefined
  )
  const [errorTime, setErrorTime] = React.useState('')

  useEffect(() => {
    if (!customMessage) {
      let errorType = forcedType ? forcedType : 'unavailable'

      if (isInvalidQueryError(resultMetaData.error)) errorType = 'InvalidQuery'
      if (isAppGatewayError(resultMetaData.error)) errorType = 'AppGtwError'

      setContent(getStaticContent(errorType))
    }

    //get error time
    setErrorTime((new Date().getTime() / 1000).toFixed(0))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getStaticContent = (
    errorType: IErrorType
  ): IErrorContent | undefined => {
    //InvalidQuery
    if (errorType === 'InvalidQuery')
      return {
        type: 'warning',
        title: (
          <FormattedMessage
            id="error_message_header"
            defaultMessage="Sorry, this shouldn't have happened"
          />
        ),
        header: (
          <FormattedMessage
            id={
              resultMetaData.error?.messageKey
                ? resultMetaData.error.messageKey
                : 'error_query_invalid'
            }
            defaultMessage='"{datasource}" didn&apos;t understand your search query.'
            values={{
              datasource: getTranslatedDatesource(intl, dataSource, origin)
            }}
          />
        )
      }

    if (errorType === 'AppGtwError')
      return {
        type: 'warning',
        content: (
          <>
            <FormattedMessage
              id={'error_app_gateway'}
              defaultMessage="Sorry, Enterprise Search blocked your request for being potentially dangerous, please try another search query. If you believe the search query is correct,"
            />{' '}
            <Link
              data-interception={'off'}
              underline={'hover'}
              href={getAppGtwErrorMailAddress(searchQuery, intl)}
              {...(deviceSettings.openLinksInNewTab === true
                ? { target: '_blank', rel: 'noreferrer' }
                : {})}
            >
              <FormattedMessage id={'contact_us'} defaultMessage="contact us" />
            </Link>
          </>
        )
      }

    if (errorType === 'unavailable')
      return {
        type: 'error',
        title: (
          <FormattedMessage
            id="error_message_header"
            defaultMessage="Sorry, this shouldn't have happened"
          />
        ),
        header: (
          <FormattedMessage
            id={
              resultMetaData.error?.messageKey
                ? resultMetaData.error.messageKey ===
                    'error_unexpected_internal' ||
                  resultMetaData.error.messageKey === 'error_apim_unexpected'
                  ? 'error_unexpected'
                  : resultMetaData.error.messageKey
                : 'error_unexpected'
            }
            defaultMessage='"{datasource}" is currently not available.'
            values={{
              datasource: getTranslatedDatesource(intl, dataSource, origin)
            }}
          />
        ),
        footer: (
          <>
            <FormattedMessage
              id="error_message_footer"
              defaultMessage="We are already working on it. Please try again later or"
            />{' '}
            <Link
              data-interception={'off'}
              underline={'hover'}
              href={
                'mailto:' +
                enterpriseSearchEmail +
                '?subject=%5BKPMG_FIND_Feedback%5D&body=Dear%20team%2C%0A%0A'
              }
            >
              <FormattedMessage id="contact_us" defaultMessage="contact us" />
            </Link>
          </>
        )
      }

    return customMessage ? customMessage : undefined
  }

  const handleExpandClick = () => {
    setExpandedDetails(!expandedDetails)
  }

  if (content)
    return (
      <Box
        className={resultClasses.container}
        {...(isContainerWrapped ? { style: { paddingLeft: 0 } } : {})}
      >
        <Box className={resultClasses.resultsContainer}>
          <Box
            id={'con-err-01'}
            className={`${classes.container}  ${
              content.type === 'info' ? classes.containerInfo : ''
            } ${
              content.type === 'error'
                ? classes.error
                : content.type === 'warning'
                ? classes.warning
                : classes.info
            }`}
          >
            <Grid
              container
              direction="row"
              alignItems="center"
              wrap="nowrap"
              className={classes.gridContainer}
            >
              <Grid item>
                <ListItemIcon>
                  <ErrorOutline
                    className={`
                  ${
                    content.type === 'error'
                      ? classes.errorIcon
                      : content.type === 'warning'
                      ? classes.warningIcon
                      : classes.infoIcon
                  }`}
                  />
                </ListItemIcon>
              </Grid>

              <Grid item className={classes.messagesContainer}>
                {content.title ? (
                  <Typography variant="body1" className={classes.title}>
                    {content.title}
                  </Typography>
                ) : null}

                {content.header ? (
                  <Typography variant="body1" className={classes.header}>
                    {content.header}
                  </Typography>
                ) : null}

                {content.content ? (
                  <Typography variant="body1" className={classes.content}>
                    {content.content}
                  </Typography>
                ) : null}

                {content.footer ? (
                  <Typography variant="body1" className={classes.footer}>
                    {content.footer}
                  </Typography>
                ) : null}
              </Grid>

              {content.type !== 'info' && content.showDetails !== false ? (
                <Grid item style={{ width: '40px' }}>
                  <div className={classes.detailsIcon}>
                    <Tooltip
                      enterTouchDelay={300}
                      title={intl.formatMessage({
                        id: 'error_message_details',
                        defaultMessage: 'Expand Details'
                      })}
                    >
                      <IconButton
                        className={`${classes.expand}${
                          expandedDetails ? ' ' + classes.expandOpen : ''
                        }`}
                        onClick={handleExpandClick}
                        aria-expanded={expandedDetails}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                </Grid>
              ) : null}
            </Grid>
            <Collapse in={expandedDetails}>
              <Box className={classes.detailscontainer}>
                <div className={classes.datailsRowContainer}>
                  <div>
                    <Typography
                      variant="body1"
                      className={classes.detailsBoxTextLabel}
                    >
                      <FormattedMessage
                        id="error_message_userid"
                        defaultMessage="User Id:"
                      />
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      variant="body1"
                      className={classes.detailsBoxText}
                    >
                      {getCookie('ai_user')}
                    </Typography>
                  </div>
                </div>
                <div className={classes.datailsRowContainer}>
                  <div>
                    <Typography
                      variant="body1"
                      className={classes.detailsBoxTextLabel}
                    >
                      <FormattedMessage
                        id="error_message_sessionid"
                        defaultMessage="Session Id:"
                      />
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      variant="body1"
                      className={classes.detailsBoxText}
                    >
                      {getCookie('ai_session')}
                    </Typography>
                  </div>
                </div>
                <div className={classes.datailsRowContainer}>
                  <div>
                    <Typography
                      variant="body1"
                      className={classes.detailsBoxTextLabel}
                    >
                      <FormattedMessage
                        id="error_message_timestamp"
                        defaultMessage="Timestamp:"
                      />
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      variant="body1"
                      className={classes.detailsBoxText}
                    >
                      {errorTime}
                    </Typography>
                  </div>
                </div>

                {/* <div className={classes.datailsRowContainer}>
                <div>
                  <Typography
                    variant="body1"
                    className={classes.detailsBoxTextLabel}
                  >
                    <FormattedMessage
                      id="error_message_errorCode"
                      defaultMessage="Errorcode:"
                    />
                  </Typography>
                </div>
                <div>
                  <Typography
                    variant="body1"
                    className={classes.detailsBoxText}
                  >
                    {resultMetaData.error?.responseCode
                      ? resultMetaData.error?.responseCode
                      : '500'}
                  </Typography>
                </div>
              </div>
              <div className={classes.datailsRowContainer}>
                <div>
                  <Typography
                    variant="body1"
                    className={classes.detailsBoxTextLabel}
                  >
                    <FormattedMessage
                      id="error_message_response"
                      defaultMessage="Response:"
                    />
                  </Typography>
                </div>
                <div>
                  <Typography
                    variant="body1"
                    className={classes.detailsBoxText}
                  >
                    {resultMetaData.error?.orginalResponseBody
                      ? resultMetaData.error?.orginalResponseBody
                      : resultMetaData.error?.exception
                      ? resultMetaData.error?.exception.message
                      : ''}
                  </Typography>
                </div>
              </div> */}
              </Box>
            </Collapse>
          </Box>
        </Box>
      </Box>
    )

  return <></>
}

const mapStateToProps = (state: Store, ownProps: ErrorPageProps) => {
  return {
    resultMetaData: ResultMetaDataStore.selectors.getResultMetaByDataSource(
      state,
      ownProps.origin
        ? `${ownProps.dataSource}${ownProps.origin}`
        : ownProps.dataSource
    ),

    deviceSettings: SettingsStore.selectors.getDeviceSettings(state)
  }
}

export default connect(mapStateToProps)(ErrorPage)
